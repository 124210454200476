import React from 'react';
import { createModal } from 'react-modal-promise';
import { ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '..';

import { Flex } from '@rebass/grid';
import { TemplateFormCard } from './SelectFormModal.styles';
import { leadCaptureForm } from '../../containers/LandingPageEditorContainer/forms/lead-capture';
import { onlineWebinarForm } from '../../containers/LandingPageEditorContainer/forms/online-webinar';
import { newsletterForm } from '../../containers/LandingPageEditorContainer/forms/newsletter';
import { eventForm } from '../../containers/LandingPageEditorContainer/forms/event';

const SelectFormModal = ({ isOpen, onReject, onResolve }) => {
    // Use Modal Container in the parent component for showing the modal
    return (
        <ModalV2 
            bgColor='#fff' 
            width='40%' 
            isOpen={isOpen} 
            onRequestClose={() => onReject('rejected')}
        >
            <ModalHeader fontColor='#49484a'>
                Select Any of the template forms
                <ModalCloseButton onClick={() => onReject('rejected')} />
            </ModalHeader>
            <ModalBody>
                <Flex justifyContent='center' flexWrap='wrap' alignItems='center'>
                    <TemplateFormCard onClick={() => onResolve(leadCaptureForm)}>
                        Lead Capture
                    </TemplateFormCard>
                    <TemplateFormCard onClick={() => onResolve(onlineWebinarForm)}>
                        Online Webinar
                    </TemplateFormCard>
                    <TemplateFormCard onClick={() => onResolve(newsletterForm)}>
                        Newsletter
                    </TemplateFormCard>
                    <TemplateFormCard onClick={() => onResolve(eventForm)}>
                        Event
                    </TemplateFormCard>
                </Flex>
            </ModalBody>
        </ModalV2>
    );
};

const SelectFormPromiseModal = createModal(SelectFormModal);

export default SelectFormPromiseModal;

