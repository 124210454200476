import { Flex } from '@rebass/grid';
import React from 'react';
import AdvisorSettingsRow from '../../components/AdvisorSettingsRow/AdvisorSettingsRow';

const AdvisorSettings = ({ settingsArray, changeHandler }) => {
    settingsArray.forEach(element => {
        delete element['Re-sync'];
    });
    return (
        <Flex 
            flexDirection='column' 
            width='90%' 
            margin='auto' 
            style={{ borderRadius: '5px' }} 
        >
            {settingsArray.map((setting, index) => (
                <AdvisorSettingsRow 
                    setting={setting} 
                    changeHandler={changeHandler}
                    lastRow={index===settingsArray.length-1}
                    firstRow={index===0}
                    index={index}
                />
            ))}
        </Flex>
    );
}

export default AdvisorSettings;