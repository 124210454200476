import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from '@rebass/grid';
import { isEmpty, get } from 'lodash';
import React, { useState } from 'react';
import { Button } from '../../../../components';
import {
  SubCampaignSeparator,
  SubCampaignVerticalSep,
} from '../DripDashboardListItem/DripDashboardListItem.styles';
import AutomatedCampaignImageGenerator from './AutomatedCampaignImageGenerator';
import AutomatedCampaignInfo from './AutomatedCampaignInfo';
import { ActionsWrapper, AutomatedCampaignListItemWrapper } from './styles';
import OutsideClickHandler from 'react-outside-click-handler';
import EmailSeriesBlock from './EmailSeriesDisplayer';
import { useHistory } from 'react-router-dom';
import PreviewModal from './EmailListPreviewModal';
import ViewInfoModal from './ViewInfoModal';
import { RenameModal } from './RenameModal';
import { AutomatedCampaignDeleteModal } from './AutomatedCampaignDeleteModal';
import AcExportToPdf from './AcExportToPdf';
import { exportToPdfAc, postIgnoreCampaignAC, postStartCampaignAC } from '../../../../services/automated-campaigns';
import {  AutomatedCampaignCancelModal } from './AutomatedCampaignCancelModal';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import CascadeCampaignModal from '../CampaignMenu/CascadeCampaignModal';
import IgnoreCascadeModal from '../CampaignMenu/IgnoreCascadeModal';

const AutomatedCampignListItem = ({
  isFirst,
  category,
  campaignSetState,
  campaignRequest,
  uid,
  type,
  id,
  name,
  purpose,
  segment,
  target_page,
  start_date,
  event_date,
  send_times,
  status,
  converted_tag,
  cc_email_addresses,
  converted_list_id,
  blocks,
  automatedCampaignDetails,
  selectedCampaignTab,
  setSelectedCampaignTab,
  filters,
  ac_type,
  isEnterpriseAdvisor,
  cascaded_advisors,
  is_global_compliance,
  is_partially_completed
}) => {
  // Comp. Utils
  const history = useHistory();
  console.log('CASCADED ADVISORS - - - - - ', cascaded_advisors);

  // Comp. States
  const [showSeries, setShowSeries] = useState(false);
  const [showTopLevelActions, setShowTopLevelActions] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showViewInfo, setShowViewInfo] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [invalidEmailMsg, setInvalidEmailMsg] = useState(false);
  const [confirmationModal, toggleConfirmationModal] = useState({status: false, source: ''});
  const [loading, setLoading] = useState('');
  const [success, setSuccess] = useState(false);
  const [cascadedId, setCascadedId] = useState('');

  //   TODO: Display respective actions based on the status of campaign.
  const getTopLevelActions = () => {
    console.log('AC TYPE - - -- - ', ac_type);
    let topLevelActions = 
      (isEnterpriseAdvisor && selectedCampaignTab === 9) 
      ? [
        {
          title: 'Start Campaign',
          onClick: () => toggleConfirmationModal({status: true, source: 'start_campaign'})
        },
        {
          title: 'Preview',
          onClick: () => {
            campaignSetState({
              automatedCampaigns: { campaignId: blocks[0].ac_id },
            });
            setShowPreview(true);
          },
        },
        {
          title: 'Ignore Campaign',
          onClick: () => toggleConfirmationModal({status: true, source: 'ignore_campaign'})
        },
      ] 
      : [
        {
          title: 'Edit',
          onClick: () => {
            campaignSetState({
              automatedCampaigns: {
                campaignId: blocks && blocks[0].ac_id,
                type,
              },
              selectedIndex: 0,
              createMode: 'automated',
              isEdit: true,
            });
            history.push('/campaignV2?type=create');
          },
        },
        {
          title: 'Rename',
          onClick: () => setShowRename(true),
        },
        // TODO: Include this for V2.
        // {
        //   title: 'Delete',
        //   onClick: () => alert('Delete action'),
        // },
        {
          title: 'Preview',
          onClick: () => {
            campaignSetState({
              automatedCampaigns: { campaignId: blocks[0].ac_id },
            });
            setShowPreview(true);
          },
        },
        {
          title: 'View Info',
          onClick: () => setShowViewInfo(true),
        },
        {
          title: 'Export to PDF',
          onClick: () => setShowExport(true),
        },
        {
          title: 'Delete',
          onClick: () => setShowDelete(true),
        },
        {
          title: 'Cancel',
          onClick: () => setShowCancel(true),
        },
      ];

    if(status === 'compliance_pending' || status === 'compliance_rejected' || status === 'Completed (cancelled)') {
      topLevelActions = topLevelActions.filter((action) => action.title !== 'Rename');
    }

    if(status === 'draft') {
      topLevelActions = topLevelActions.filter((action) => action.title !== 'Export to PDF');
    }

    if(status === 'Completed (cancelled)') {
      topLevelActions = topLevelActions.filter((action) => action.title !== 'Delete');
    }



    if(status === 'compliance_pending' || status === 'compliance_rejected' || status === 'draft' || status === 'Completed (cancelled)') {
      topLevelActions = topLevelActions.filter((action) => action.title !== 'Cancel');
    }

    return status === 'active' || status === 'compliance_pending' || status === 'compliance_rejected' || status === 'Completed (cancelled)' || status === 'partially_completed'
      ? topLevelActions.filter((action) => action.title !== 'Edit')
      : topLevelActions;
  };

  const handlePdfClose = () => {
    setShowExport(false);
  }

  const handleExportSubmit = () => {
    setLoading(true);
    setErrorMsg('');
    setInvalidEmailMsg(false);
    let payload = {
      campaign_id: id,
      email_ids: JSON.stringify(
        emailList.length > 0
          ? emailList.split(',').map((email) => email.trim())
          : []
      )
    }
    exportToPdfAc(payload).then(res => {
      setLoading(false);
      if (res.result.success) {
        setIsSubmit(true);
        setTimeout(() => {
          handlePdfClose();
        }, 3000);
      } 
      else {
        setInvalidEmailMsg(true);
        setErrorMsg('Something went wrong!');
      }
    }).catch(err => {
      setLoading(false);
      setInvalidEmailMsg(true);
      setErrorMsg('Something went wrong!');
      console.log(err);
    })
  }

  const handleActionATS = async () => {
    setLoading(true);
    if(confirmationModal.source === 'start_campaign') {
      try {
        const startCampaignResponse = await postStartCampaignAC({ id });
        setLoading(false);
        console.log('START CAMPAIGN RESPONSE - - ', startCampaignResponse);
        if(get(startCampaignResponse, 'result.success', false)) {
          setSuccess(true);
          setCascadedId(get(startCampaignResponse, 'result.data.id', ''));
        }
        else
          setErrorMsg('Oops! Something went wrong. Please try again later.');
      }
      catch(err) {
        setLoading(false);
        setSuccess(false);
        setErrorMsg('Oops! Something went wrong. Please try again later.');
      }
    }
    else {
      try {
        const ignoreCampaignResponse = await postIgnoreCampaignAC({ id });
        setLoading(false);
        console.log('IGNORE CAMPAIGN RESPONSE - - -- - ', ignoreCampaignResponse);
        if(get(ignoreCampaignResponse, 'result.success', false)) {
          setSuccess(true);
          setTimeout(() => {
            campaignRequest('ats_automated');
          }, 1500)
        }
        else
          setErrorMsg('Oops! Something went wrong. Please try again later.');
      }
      catch(err) {
        setLoading(false);
        setSuccess(false);
        setErrorMsg('Oops! Something went wrong. Please try again later.');
      }
    }
  }

  const reviewCampaignHandler = () => {
    campaignSetState({
      automatedCampaigns: { campaignId: cascadedId, type: ac_type },
      selectedIndex: 0,
      createMode: 'automated',
      isEdit: true
    });
    history.push('?type=create')
  }

  const closeModalHandler = () => {
    toggleConfirmationModal({status: false, source: ''});
    setErrorMsg('');
    setSuccess(false);
  }

  return (
    <>
      <AutomatedCampaignListItemWrapper
        data-testid="campaignlist-item"
        justifyContent="space-between"
        id={uid}
        alignItems="center"
        isFirst={isFirst}
      >
        <Flex width="100vw" alignItems="center">
          <AutomatedCampaignImageGenerator media_urls={[]} />

          <AutomatedCampaignInfo
            name={name}
            start_date={start_date}
            event_date={event_date}
            status={status}
            purpose={purpose}
            id={id}
            campaignRequest={campaignRequest}
            cascaded_advisors={cascaded_advisors || []}
            is_global_compliance={is_global_compliance}
            is_partially_completed={is_partially_completed}
          />

          <ActionsWrapper
            style={{ margin: '0px 10px' }}
            alignItems="center"
            className="actions_holder"
          >
            <Button
              testId="expand-collapse"
              styles={{
                border: '1px solid #DBE0E6',
                borderRadius: '5px',
              }}
              color="blue"
              bgColor={'#eeeeee'}
              margin="20px"
              padding="4px 6px 0px 6px"
              onClick={() => setShowSeries(!showSeries)}
            >
              <img
                src={
                  showSeries
                    ? '/assets/images/drip/expand_less.svg'
                    : '/assets/images/drip/expand_more.svg'
                }
                alt=""
              />
            </Button>
            <FontAwesomeIcon
              style={{ cursor: 'pointer', fontSize: '25px' }}
              icon={faEllipsisV}
              onClick={() => setShowTopLevelActions(!showTopLevelActions)}
            />

            {showTopLevelActions && (
              <OutsideClickHandler
                onOutsideClick={() =>
                  setShowTopLevelActions(!showTopLevelActions)
                }
              >
                <Box className="actions">
                  {React.Children.toArray(
                    getTopLevelActions().map((action, index) => (
                      <p
                        className="action-item cursor-pointer"
                        onClick={() => action.onClick()}
                      >
                        {action.title}
                      </p>
                    ))
                  )}
                </Box>
              </OutsideClickHandler>
            )}
          </ActionsWrapper>
        </Flex>
      </AutomatedCampaignListItemWrapper>

      {showSeries &&
        !isEmpty(blocks) &&
        React.Children.toArray(
          blocks.map((block, index) => (
            <Flex justifyContent="flex-start">
              <div style={{ width: '10%' }}>
                <SubCampaignSeparator />
                {blocks.length - 1 !== index && <SubCampaignVerticalSep />}
              </div>

              <EmailSeriesBlock
                block={block}
                showSeries={showSeries}
                campaignSetState={campaignSetState}
                segment={segment ? segment.name : '-'}
                isEnterpriseAdvisor={isEnterpriseAdvisor}
                selectedCampaignTab={selectedCampaignTab}
              />
            </Flex>
          ))
        )}

      {showPreview && (
        <PreviewModal
          handleClose={() => setShowPreview(false)}
          isOpen={showPreview}
          campaignSetState={campaignSetState}
          campaignAction={type}
          campaignId={blocks[0].ac_id}
          automatedCampaignDetails={automatedCampaignDetails}
        />
      )}

      {showViewInfo && (
        <ViewInfoModal
          isOpen={showViewInfo}
          handleClose={() => setShowViewInfo(false)}
          blocks={blocks?.length > 0 ? blocks : []}
          segment={segment ? segment.name : '-'}
          campaignName={name}
        />
      )}

      {showRename && (
        <RenameModal
          isOpen={showRename}
          handleClose={() => setShowRename(false)}
          campaignName={name}
          campaignRequest={campaignRequest}
          campaignId={id}
        />
      )}

      {showDelete && (
        <AutomatedCampaignDeleteModal
          open={showDelete}
          handleClose={() => setShowDelete(false)}
          campaignId={id}
          campaignRequest={campaignRequest}
          

        />
      )}

      {showCancel && (
        <AutomatedCampaignCancelModal
          open={showCancel}
          closeHandler={() => setShowCancel(false)}
          campaignId={id}
          campaignRequest={campaignRequest}
          
        />
      )}



      {showExport && (
        <AcExportToPdf 
          isOpen={showExport}
          handleClose={handlePdfClose}
          title={name}
          isSubmit={isSubmit}
          errorMsg={errorMsg}
          setEmailList={setEmailList}
          setErrorMsg={setErrorMsg}
          setInvalidEmailMsg={setInvalidEmailMsg}
          emailList={emailList}
          loading={loading}
          invalidEmailMsg={invalidEmailMsg}
          handleSubmit={handleExportSubmit}
        />
      )}
      {confirmationModal.status && (
        <>
          {confirmationModal.source === 'start_campaign' ? (
            <CascadeCampaignModal
              close={closeModalHandler}
              okHandler={() => {
                toggleConfirmationModal({status: false, source: ''});
                setSelectedCampaignTab(0);
                campaignRequest({ 
                  type: ['automated'],
                  limit: 10,
                  offset: 1  
                });
              }}
              useCascadedCampaign={handleActionATS}
              loader={loading}
              success={success}
              error={errorMsg}
              reviewHandler={reviewCampaignHandler}
            />
          ) : (
            <IgnoreCascadeModal
              close={closeModalHandler}
              yesHandler={handleActionATS}
              loader={loading}
              success={success}
              error={errorMsg}
            />
          )}
        </>
      )}
    </>
  );
};

export default AutomatedCampignListItem;
