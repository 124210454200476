/* eslint-disable react-hooks/exhaustive-deps */
import { createModal } from 'react-modal-promise';
import React, { useState, useEffect } from 'react';
import { ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../components';
import { getExploreArticleData, themesData, getMyContentArticles } from '../../utils';
import { ROLE_TYPES } from '../../utils/constants.js';
import { isEmpty, get } from 'lodash';
import { connect } from 'react-redux';
import { getChannelId, getRoleType } from '../../redux/selector';
import { getLandingPages, getRowThumbnails } from '../../services/bee-editor';
import AddContentLayout from './AddContentSteps/AddContentLayout';
import AddContentCustomRow from './AddContentSteps/AddContentCustomRow';
import AddContentArticles from './AddContentSteps/AddContentArticles';
import AddContentButton from './AddContentSteps/AddContentButton';
import { actions as campaignActions } from '../../redux/modules/campaign.module';

const ArticleModal = ({ 
    isOpen, 
    onResolve, 
    onReject, 
    themeNames, 
    channelId, 
    userData, 
    bookmarkArticles, 
    recommendedArticles, 
    recommendedFetchedData, 
    myContentArticles,
    fromDashboard,
    campaignSelectedArticles,
    webinarData,
    templateType,
    automatedCampaigns,
    createMode,
    automatedFlowFromDashboard,
    selectedBlock,
    landingPagesData,
    myContentPaginationData,
    myContentPendingQueue,
    isEnterpriseAdmin,
    isEnterpriseAdvisor,
    isPremiumAdvisor,
    campaignSetState,
    campaignId
 }) => {

    const [layout, setLayout] = useState('one-column');
    const [articleType, setArticleType] = useState('bookmarked');
    const [selectedArticles, setSelectedArticles] = useState([]);
    const [articleData, setArticleData] = useState({ bookmarked: [], recommended: [], "my-content": [] });
    const [step, updateStep] = useState(0);
    const [loader, toggleLoader] = useState(false);
    const [customRows, setCustomRows] = useState([]);
    const [selectedCustomRow, setSelectedCustomRow] = useState('');
    const [selectedLandingPage, setSelectedLandingPage] = useState('');
    const [predefinedArticleId, setPredefinedArticleId] = useState(null);

    console.log(step);
    console.log(layout);

    const fetchCustomRows = ({category, tag}) => {
        getRowThumbnails({ layout: tag, category })
        .then(thumbnails => {
            console.log('CUSTOM ROWS THUMBNAILS - - - - ', thumbnails);
            if(thumbnails) 
                setCustomRows(thumbnails);
            toggleLoader(false);
        })
        .catch(err => {
            console.log('ERROR - - - -- - ', err);
            toggleLoader(false);
        });
    }

    // useEffect(() => {
    //     getBookmarkedArticles().then((res) => {
    //         if (res.result && res.result.data && res.result.data.feed) {
    //             let allBookMarkedArticles = getExploreArticleData(
    //               res.result.data,
    //               'campaign'
    //             );
    //             setArticleData({...articleData, bookmarked: allBookMarkedArticles});
    //         }
    //     }).catch(err => console.log(err));

    //     getRecommendedArticles()
    //     .then(res => {
    //         console.log('RECOMMENDED RESPONSE - -- -', res);
    //     })
    //     .catch(err => console.log('ERROR - - -- - ', err));

    //     getContentLibrary(channelId)
    //     .then(res => {
    //         console.log('CONTENT RESPONSE - - -- ', res)
    //     })
    //     .catch(err => console.log('ERROR - - -- - ', err));

    // }, []);

    useEffect(() => {
        if ((createMode === 'automated' || automatedFlowFromDashboard) && automatedCampaigns && automatedCampaigns.targetInfo && (automatedCampaigns.targetInfo.target_page_id || (automatedCampaigns.targetInfo.target_page && automatedCampaigns.targetInfo.target_page.id)) && !isEmpty(landingPagesData)) {
            const pageId = automatedCampaigns.targetInfo.target_page_id || automatedCampaigns.targetInfo.target_page.id;
            const selectedPage = landingPagesData.filter(p => p._id === pageId);
            console.log('selectedPage -', selectedPage);
            if (!isEmpty(selectedPage)) {
                setSelectedLandingPage(selectedPage[0]);
            }
        }
    }, [])
    

    useEffect(() => {
        if (fromDashboard && !isEmpty(campaignSelectedArticles)) {
            setPredefinedArticleId(campaignSelectedArticles[0].id);
        }

        var newStateObject = {
            bookmarked: [],
            'my-content': [],
            recommended: [],
        }


        console.log('RECOMMENDED FETCHED DATA', recommendedFetchedData);
        if (bookmarkArticles && !isEmpty(bookmarkArticles))
            newStateObject.bookmarked = bookmarkArticles;
        if (myContentArticles && !isEmpty(myContentArticles))
            newStateObject['my-content'] = myContentArticles;
        if (recommendedFetchedData && !isEmpty(get(recommendedFetchedData, 'data.feed', [])))
            newStateObject.recommended = getExploreArticleData(
                get(recommendedFetchedData, 'data', {})
            );
        else if (recommendedArticles && !isEmpty(recommendedArticles))
            newStateObject.recommended = recommendedArticles;

        if (webinarData && webinarData.isWebinar) {
            setLayout('webinar-block');
        }


        setArticleData(newStateObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookmarkArticles, myContentArticles, recommendedArticles, recommendedFetchedData])

    useEffect(() => {
        let category, tag;
        if (step === 0)
            setSelectedCustomRow('');
        else if (step === 1) {
            toggleLoader(true);
            if(layout === 'landing-pages' ) {
                let type = 'campaign';
                getLandingPages(type)
                .then(response => {
                    console.log('RESPONSE fetchLandingPages - - - -', response);
                    setCustomRows(get(response, 'data', []));
                    toggleLoader(false);
                })
                .catch(err => {
                    console.log('ERROR - - - - -', err);
                    toggleLoader(false);
                });
            }
            else {
                if(layout === 'webinar-block') {
                    category = 'webinar_block';
                    tag = '';
                } 
                else {
                    category = 'article_block';
                    tag = layout;
                }
                // axios.get(`https://92bm0vkugf.execute-api.us-east-2.amazonaws.com/dev/article-thumbnails?uid=${get(userData, 'uid', '')}&tag=${layout}`)
                fetchCustomRows({category, tag});
            } 
        }
        else if (step === 2 && layout === 'landing-pages') {
            toggleLoader(true);
            fetchCustomRows({category: 'landing_page', tag: ''});
        }

    }, [layout, step]);



    const handleArticleClick = item => {
        if (selectedArticles.includes(item.id))
            setSelectedArticles(selectedArticles.filter(articleId => articleId !== item.id));
        else
            setSelectedArticles([...selectedArticles, item.id]);
    }

    const handleValidation = () => {
        if (step === 1) {
            if(layout === 'landing-pages')
                return selectedLandingPage === '';
            
            return selectedCustomRow === '';
        }
        else if (step === 2) {
            if(layout === 'landing-pages')
                return selectedCustomRow === ''
            else
                return (selectedArticles.length === 0 || selectedArticles.length > 30);
        }
    }

    const loadMoreMyContent = () => {
        toggleLoader(true);
        getMyContentArticles({
            payload: {
            userType: isEnterpriseAdvisor ? 'isEnterpriseAdvisor' : isPremiumAdvisor ? 'isPremiumAdvisor' : 'isEnterpriseAdmin', 
            channelId: channelId, 
            beforeToken: null, 
            afterToken: myContentPaginationData.after,
            pendingArticles: myContentPendingQueue,
            },
            localData: {
            currentArticles: myContentArticles,
            },
            source: 'campaign',
        })
        .then(res => {
            const newState = get(res, 'newState', {});
            if(!isEmpty(newState))
                campaignSetState(newState);
            toggleLoader(false);
        })
        .catch(err => {
            console.log('******* ERROR my-content-articles [ARTICLE MODAL] ******');
            toggleLoader(false);
        })
    }

    // console.log(layout);

    return (
        <ModalV2 bgColor="#fff" width="60%" isOpen={isOpen} onRequestClose={() => onReject('rejected')}>
            <ModalHeader>
                Select Content
                <ModalCloseButton onClick={() => onReject('rejected')} />
            </ModalHeader>

            <ModalBody>
                {step === 0 ? (
                    <AddContentLayout 
                        layout={layout} 
                        setLayout={setLayout} 
                        themesData={themesData}
                        webinarData={webinarData}
                    />
                ) : step === 1 || (step === 2 && layout === 'landing-pages') ? (
                        <AddContentCustomRow 
                            layout={layout} 
                            step={step}
                            loader={loader}
                            customRows={customRows}
                            selectedLandingPage={selectedLandingPage}
                            selectedCustomRow={selectedCustomRow} 
                            setSelectedCustomRow={setSelectedCustomRow}
                            setSelectedLandingPage={setSelectedLandingPage}
                            createMode={createMode}
                            automatedFlowFromDashboard={automatedFlowFromDashboard}
                            
                            
                        />
                    ) : (
                        <AddContentArticles 
                            articleType={articleType}
                            setArticleType={setArticleType} 
                            articleData={articleData} 
                            selectedArticles={selectedArticles} 
                            themeNames={themeNames}
                            handleArticleClick={handleArticleClick}
                            showLoadMore={myContentPaginationData.after}
                            loadMoreHandler={loadMoreMyContent}
                            loader={loader}
                        />
                    )}
                {step === 2 && layout !== 'landing-pages' && selectedArticles.length > 30 && (
                    <div>* You can select only 30 articles at a time!</div>
                )}
                <AddContentButton 
                    predefinedArticleId={predefinedArticleId}
                    step={step} 
                    updateStep={updateStep}
                    layout={layout}
                    onResolve={onResolve}
                    onReject={onReject}
                    selectedCustomRow={selectedCustomRow}
                    selectedLandingPage={selectedLandingPage}
                    webinarData={webinarData}
                    userData={userData}
                    selectedArticles={selectedArticles}
                    handleValidation={handleValidation}
                    createMode={createMode}
                    automatedFlowFromDashboard={automatedFlowFromDashboard}
                    automatedCampaigns={automatedCampaigns}
                    campaignId={campaignId}
                />
            </ModalBody>
        </ModalV2>
    );
}
const mapStateToProps = (state) => {
    const roleType = getRoleType(state);
    const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
    const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
    const isEnterpriseAdmin = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;
    return {
        themeNames: state.theme.themeNames,
        channelId: getChannelId(state),
        userData: state.user.data,
        bookmarkArticles: state.campaign.bookmarkArticles,
        recommendedArticles: state.campaign.recommendedArticles,
        recommendedFetchedData: state.campaign.recommendedFetchedData,
        myContentArticles: get(state, 'campaign.myContentArticles', []),
        myContentPaginationData: get(state, 'campaign.myContentPaginationData', { before: null, after: null }),
        myContentPendingQueue: get(state, 'campaign.pendingArticles', []),
        webinarData: get(state.campaign, 'setObjectives.webinar', ''),
        templateType: state.campaign.setTemplate,
        campaignSelectedArticles: state.campaign.selectedArticles,
        fromDashboard: get(state, 'campaign.fromDashboard', false),
        automatedCampaigns: get(state, 'campaign.automatedCampaigns', {}),
        createMode: get(state, 'campaign.createMode', ''),
        automatedFlowFromDashboard: get(state, 'campaign.automatedFlowFromDashboard', ''),
        selectedBlock: get(state, 'campaign.selectedBlock', {}),
        landingPagesData: get(state, 'campaign.landingPages', []),
        campaignId: get(state, 'campaign.campaignId', ''),
        isEnterpriseAdmin,
        isEnterpriseAdvisor,
        isPremiumAdvisor
    }
};

const mapDispatchToProps = {
    campaignSetState: campaignActions.api.campaign.setstate,
  };
// const mapStateToProps = (state) => ({
//     themeNames: state.theme.themeNames,
//     channelId: getChannelId(state),
//     userData: state.user.data,
//     bookmarkArticles: state.campaign.bookmarkArticles,
//     recommendedArticles: state.campaign.recommendedArticles,
//     recommendedFetchedData: state.campaign.recommendedFetchedData,
//     myContentArticles: state.campaign.myContentArticles,
//     webinarData: get(state.campaign, 'setObjectives.webinar', ''),
//     templateType: state.campaign.setTemplate,
//     campaignSelectedArticles: state.campaign.selectedArticles,
//     fromDashboard: get(state, 'campaign.fromDashboard', false),
//     automatedCampaigns: get(state, 'campaign.automatedCampaigns', {}),
//     createMode: get(state, 'campaign.createMode', ''),
//     automatedFlowFromDashboard: get(state, 'campaign.automatedFlowFromDashboard', ''),
//     selectedBlock: get(state, 'campaign.selectedBlock', {}),
//     landingPagesData: get(state, 'campaign.landingPages', []),
//     campaignId: get(state, 'campaign.campaignId', '')
// });

const ArticlePromiseModal = createModal(connect(mapStateToProps, mapDispatchToProps)(ArticleModal));
export default ArticlePromiseModal;


