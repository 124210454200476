import React from 'react';

import { submitTopic } from '../../services/configure';
import { LoaderWrapper } from '../';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import {
  FeedbackTrigger,
  FeedbackBox,
  FeedbackHeader,
  FeedbackHeaderMinimizeIcon,
  FeedbackBody,
  Feedbacktext,
  Submit,
} from './FeedBack.styles';

class Feedback extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      shown: false,
      isSending: false,
      feedbackText: '',
      feedbackSuccessMessage: '',
    };

  }

  // onFeedbackSubmit() {
  //   const { feedbackText } = this.state;
  //   if (feedbackText) {
  //     this.setState({
  //       isSending: true,
  //     });
  //     submitTopic({
  //       subject: 'MIO Feedback from Advisor',
  //       emailBody: feedbackText,
  //     })
  //       .then(() => {
  //         this.setState({
  //           isSending: false,
  //           feedbackSuccessMessage: 'Feedback Has Been Submitted Successfully',
  //         });
  //         window.setTimeout(() => {
  //           this.setState({
  //             shown: false,
  //           });
  //         }, 2000);
  //       })
  //       .catch(() => {
  //         this.setState({
  //           isSending: false,
  //         });
  //       });
  //   }
  // };

  // handleFeedbackChange(e) {
  //   this.setState({
  //     feedbackText: e.target.value,
  //   });
  // };

  render() {
    const { isSending, feedbackSuccessMessage } = this.state;
    return (
      <div>
        <FeedbackTrigger
          className="icon dripicons-message"
          onClick={() => this.setState({ shown: !this.state.shown })}
        >
          &nbsp; <span style={{position: 'relative', bottom: '4px'}}>Feedback</span>
        </FeedbackTrigger>

        <FeedbackBox style={{ display: this.state.shown ? 'block' : '' }}>
          <FeedbackHeader
            className="icon dripicons-message"
            onClick={() => this.setState({ shown: !this.state.shown })}
            style={{
              position: 'relative',
              left: '-7%'
            }}
          >
            &nbsp; <div style={{
              position: 'absolute',
              top: '19%',
              left: '51%'
            }}>Feedback</div>
            <FeedbackHeaderMinimizeIcon style={{
              position: 'relative',
              right: '-5%',
              bottom: '5px'
            }}>
              <span>
                <FontAwesomeIcon icon={faWindowMinimize} />
              </span>
            </FeedbackHeaderMinimizeIcon>
          </FeedbackHeader>
          <FeedbackBody>
            <Feedbacktext
              placeholder="Please share your thoughts"
              onFocus={(e) => (e.target.placeholder = '')}
              onBlur={(e) =>
                (e.target.placeholder = 'Please share your thoughts')
              }
            ></Feedbacktext>
            {!isSending && feedbackSuccessMessage}
            <LoaderWrapper isLoading={isSending}>
              <Submit type="button">
                Submit
              </Submit>
            </LoaderWrapper>
          </FeedbackBody>
        </FeedbackBox>
      </div>
    );
  }
}

export default Feedback;
