import styled from 'styled-components';

export const ContactSubListWrapper = styled.div`
  height: ${({ height }) => height || 'auto'};
  max-height: ${({ height }) => height || '150px'};
  overflow-y: auto;
  width: inherit;
  padding: 10px 0px;
`;

export const ContactListButton = styled.button`
  font-size: 13px;
  font-family: 'Poppins';
  padding: 4px 30px 5px 30px;
  background-color: ${({ disabled }) => (disabled ? '#B4B4B4' : '#6351ed')};
  color: rgb(238, 238, 238);
  border: ${({ disabled }) => (disabled ? '1px solid #B4B4B4' : '#6351ed')};
  margin: 15px 0px 0px;
  cursor: pointer;
  height: 28px;
  width: auto;
`;
export const RadioInput = styled.input`
  border: 2.7px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;

  &:checked {
    background-color: ${({ selected }) => (selected ? '#6351ed' : '#ccc')};
    box-shadow: 0 0 0 1px #aaa;
  }
`;
