/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Flex } from '@rebass/grid';
import { CreateContainer } from '../CreateCampaign/CreateCampaign.styles';
import { CardSpan } from './SocialPreview.styles';
import { SelectedArticle } from './SelectedArticle';
import MioImageUpload from '../../DashboardContainer/MIOEditor/MioImageUpload';
import './style.css';
import { ScheduleCard } from './ScheduleCard';
import { ShareCopyCard } from './ShareCopyCard';
import { toBase64 } from '../../../utils';
import { Button, LoaderWrapper } from '../../../components';
import { FooterBtnsWrapper } from '../DripCampaignContainerV2.styles';
import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import { get, isEmpty } from 'lodash';
import {
  postCascadeCampaign,
  publishCampaignV2,
} from '../../../services/campaign';
import { useHistory } from 'react-router-dom';
import SelectContentPopup from './SelectContentPopup';
import { Tooltip } from '@material-ui/core';
import { dataURLtoFile } from '../../../services/dashboard/index';
import SelectContentModal from '../../DripCampaignContainer/PlanCampaign/SelectContentModal';
import { getArticleSummary } from '../../../services/dashboard';
import { getUserDetails } from '../../../services/login';
import CampaignNavigation from '../CampaignNavigation/CampaignNavigation';

// import { LoaderWrapper } from '../../Loader';

export const SocialPreview = ({
  setObjectivesTabData,
  campaignSelectedArticles,
  handleNextClick,
  handleBack,
  loader,
  getAccountId,
  campaignId,
  seriesId,
  redirectionCampaign,
  campaignScheduleTimings,
  campaignSummaries,
  campaignScheduleTypes,
  isEdit,
  curFlow,
  endCreateFlow,
  userData,
  campaignLeadReferralData,
  isadvMode,
  isAdvisorApprovalMode,
  campaignStatus,
  checkboxStatus,
  socialSelectedCampId,
  complianceApplicable,
  complianceApproved,
  socialAdvApproval,
  userRole,
  fromDashboard,
  createMode,
  reviewCampaign,
  redirectCascadeAdvisor,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  isAdmin,
  isEnterpriseAdvisor,
  loginRequest,
  stepList,
  selectedIndex,
  isGlobalCompliance,
  companyId
}) => {
  console.log(campaignSelectedArticles);
  console.log('SET OBJECTIVES TAB DATA -- - - - -', setObjectivesTabData);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectPopup, setSlectPopup] = useState(false);

  const [selectedContent, setSelectedContent] = useState(
    campaignSelectedArticles
      ? setObjectivesTabData?.webinar?.isWebinar
        ? [campaignSelectedArticles[0]]
        : campaignSelectedArticles.length === 1
        ? campaignSelectedArticles
        : []
      : []
  );
  const [skipCompliance, setSkipCompliance] = useState(true);
  const [isFetching, toggleIsFetching] = useState(false);
  const [activePlatform, setActivePlatform] = useState('fb');
  const [msgCopy, setMsgCopy] = useState({ fb: '', ln: '', tw: '' });
  const [isMsgCopyAllPlatform, setIsMsgCopyAllPlatform] = useState(
    !isEmpty(checkboxStatus)
      ? get(checkboxStatus, 'message_copy', false)
      : false
  );

  const [timingTabs, setTimingTabs] = useState({ fb: 0, ln: 0, tw: 0 });
  const [dateCol, setDateCol] = useState({ fb: '', ln: '', tw: '' });
  const [timeCol, setTimeCol] = useState({ fb: '', ln: '', tw: '' });
  const [isScheduleAllPlatform, setIsScheduleAllPlatform] = useState(true);
  // const [enableLeadGen, toggleLeadGen] = useState({fb: false, ln: false, tw: false});
  const [enableLeadGen, toggleLeadGen] = useState(false);
  const [pdfPresent, togglePdfPresent] = useState(false);
  const [selectedFbContacts, setSelectedFbContacts] = useState(
    get(setObjectivesTabData, 'fbList', [])
  );
  const [selectedLnContacts, setSelectedLnContacts] = useState(
    get(setObjectivesTabData, 'lnList', [])
  );
  const [selectedTwContacts, setSelectedTwContacts] = useState(
    get(setObjectivesTabData, 'twList', [])
  );

  const [isApprovalRequired, setIsApprovalRequired] = useState(
    isAdvisorApprovalMode
  );

  const handleSaveAndClose = () => {
    endCreateFlow();
    history.push('/campaignV2');
  };
  const handleBackButtonClick = () => {
    handleBack();
  };
  const [fileType, setFileType] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [base64Logo, setBase64Logo] = useState('');

  const onFileUploadChange = (logofile) => {
    console.log('Fired...');
    setFileType(logofile[0].name.split('.')[1]);
    setErrorMsg('');
    toBase64(logofile[0]).then((file) => {
      console.log('[FILE] - ', file);
      setBase64Logo(file);
      setSelectedContent([{ ...selectedContent[0], url: file }]);
    });
  };

  useEffect(() => {
    if (isEdit) {
      setIsScheduleAllPlatform(
        !isEmpty(checkboxStatus)
          ? get(checkboxStatus, 'schedule', false)
          : false
      );

      if (socialSelectedCampId && isEmpty(selectedContent)) {
        setSelectedContent(
          isEmpty(campaignSelectedArticles)
            ? []
            : campaignSelectedArticles.filter(
                (article) => article.id === socialSelectedCampId
              )
        );
      }
      setSkipCompliance(!complianceApplicable);
    }

    if (campaignSummaries && !isEmpty(campaignSummaries))
      setMsgCopy(campaignSummaries);

    if (campaignScheduleTimings && !isEmpty(campaignScheduleTimings)) {
      const fetchedScheduleTimings = {};
      Object.keys(campaignScheduleTimings)
        .filter((platformKey) => platformKey !== 'em')
        .forEach((platformKey) => {
          fetchedScheduleTimings[platformKey] =
            campaignScheduleTimings[platformKey];
        });
      setDateCol(fetchedScheduleTimings);
    }

    if (campaignScheduleTypes && !isEmpty(campaignScheduleTypes)) {
      const fetchedScheduleTypes = {};
      Object.keys(campaignScheduleTypes)
        .filter((platformKey) => platformKey !== 'em')
        .forEach((platformKey) => {
          fetchedScheduleTypes[platformKey] =
            campaignScheduleTypes[platformKey] === 'immediate' ? 0 : 1;
        });
      setTimingTabs(fetchedScheduleTypes);
    }
  }, []);

  useEffect(() => {
    if (
      !get(setObjectivesTabData, 'webinar.isWebinar', false) &&
      !isEmpty(selectedContent) &&
      isEmpty(get(campaignSummaries, 'fb', '')) &&
      isEmpty(get(campaignSummaries, 'tw', '')) &&
      isEmpty(get(campaignSummaries, 'ln', ''))
    ) {
      toggleIsFetching(true);
      const { id, themeId } = selectedContent[0];
      getArticleSummary({ 
        articleId: id, 
        themeId: typeof themeId === 'string' || Array.isArray(themeId) ? themeId : themeId.id 
      })
        .then((response) => {
          if (response?.result?.success) {
            const resultData = get(response, 'result.data.summaries');
            setMsgCopy({
              fb: get(
                resultData,
                'long[0].summary',
                get(resultData, 'long[0]')
              ),
              tw: get(
                resultData,
                'short[0].summary',
                get(resultData, 'short[0]')
              ),
              ln: get(
                resultData,
                'medium[0].summary',
                get(resultData, 'medium[0]')
              ),
            });
            toggleIsFetching(false);
          } else {
            toggleIsFetching(false);
          }
        })
        .catch((err) => {
          console.log('error - - -', err);
          toggleIsFetching(false);
        });
    }
  }, [selectedContent]);

  useEffect(() => {
    if (!isEmpty(selectedFbContacts)) setActivePlatform('fb');
    else if (!isEmpty(selectedLnContacts)) setActivePlatform('ln');
    else if (!isEmpty(selectedTwContacts)) setActivePlatform('tw');
  }, []);

  useEffect(() => {
    if (campaignLeadReferralData) {
      let fetchedLeadGen =
        campaignLeadReferralData[
          Object.keys(campaignLeadReferralData).filter(
            (platform) => platform !== 'em'
          )[0]
        ].leadGen;
      toggleLeadGen(fetchedLeadGen);
    }
  }, []);

  useEffect(() => {
    if (
      !isEmpty(selectedContent) &&
      selectedContent[0]?.link?.includes('article_link')
    )
      togglePdfPresent(true);
    else togglePdfPresent(false);
  }, [selectedContent]);


  useEffect(() => {
    // getUserDetails()
    // .then(res => {
      // console.log('RESPONSE USER DETAILS API -- - - ', res);
      const complianceOption = isFirmLevelAdmin && !isEmpty(firmSettings) ? 
        get(firmSettings, `firm_compliance.${[companyId]}.compliance_is`, 'always_off')
          : get(userData, 'compliance', '');
      console.log('FIRM SETTINGS - - - -- ', firmSettings);
      console.log('COMPLIANCE OPTION useEffect - -- -- ', complianceOption);
      if(campaignStatus === 'compliance_rejected' || complianceOption === 'always_on' || complianceOption === 'on')
        setSkipCompliance(false);
      else if(complianceOption === 'always_off' || complianceOption === 'off')
        setSkipCompliance(true);
    // })
    // .catch(err => console.log('ERROR FETCHING USER DETAILS - - - - -', err))
  }, []);

  console.log('setObjectivesTabData', setObjectivesTabData);
  console.log('campaignSelectedArticles', campaignSelectedArticles);

  const checkValidation = () => {
    console.log('TIMING TABS - - - ', timingTabs, 'DATE COL - - -', dateCol);
    let error = false;

    if (isEmpty(selectedContent)) error = true;

    if (get(setObjectivesTabData, 'isCascaded', 'false') === 'false' && setObjectivesTabData.webinar.wuid !== '' && isEmpty(base64Logo))
      error = true;

    if (!get(setObjectivesTabData, 'myNetwork', false)) {
      if (isEmpty(msgCopy.tw) || isEmpty(msgCopy.fb) || isEmpty(msgCopy.ln))
        error = true;
    } else if (
      (!isEmpty(selectedTwContacts) && msgCopy.tw === '') ||
      (!isEmpty(selectedFbContacts) && msgCopy.fb === '') ||
      (!isEmpty(selectedLnContacts) && msgCopy.ln === '')
    ) {
      error = true;
    }

    if (
      (!isEmpty(selectedTwContacts) &&
        timingTabs.tw === 1 &&
        dateCol.tw === '') ||
      (!isEmpty(selectedFbContacts) &&
        timingTabs.fb === 1 &&
        dateCol.fb === '') ||
      (!isEmpty(selectedLnContacts) && timingTabs.ln === 1 && dateCol.ln === '')
    ) {
      error = true;
    }

    return error;
  };

  const shareCampaign = () => {
    // let articles = !isEmpty(campaignSelectedArticles)
    //   ? campaignSelectedArticles.map((item, index) => {
    //       return {
    //         id: item.id,
    //         theme_id: item.themeId,
    //       };
    //     })
    //   : [];
    const isLeadEnabled =
      selectedContent[0]?.link?.includes('article_link') &&
      get(userData, 'details.user.enable_lead_capture', 'false') === 'true' &&
      pdfPresent;

    let articles = !isEmpty(selectedContent)
      ? selectedContent.map((item, index) => {
          console.log('ITEM -  -- - ', item);
          return get(setObjectivesTabData, 'webinar.isWebinar', false) ? {} :
           {
            id: item.id,
            theme_id: typeof item.themeId === 'string' || Array.isArray(item.themeId) ? 
              item.themeId : item.themeId.id,
          };
        })
      : [];

    console.log('articles', articles);

    ///

    let fbSNid = !isEmpty(selectedFbContacts) ? getAccountId('fb') : '';
    let lnSNid = !isEmpty(selectedLnContacts) ? getAccountId('ln') : '';
    let twSNid = !isEmpty(selectedTwContacts) ? getAccountId('tw') : '';

    console.log('fbSNid', fbSNid);
    console.log('lnSNid', lnSNid);
    console.log('twSNid', twSNid);

    let fbLists = [],
      twLists = [],
      lnLists = [];

    ///

    let fbPayload;
    let lnPayload;
    let twPayload;
    // { time: dateCol.tw + 'T' + timeCol.tw }

    twLists = !isEmpty(selectedTwContacts)
      ? selectedTwContacts.map((item, index) => {
          return {
            destination_id: item,
            ...(timingTabs.tw === 1 ? { time: dateCol.tw } : null),
          };
        })
      : [];

    twPayload = {
      articles: articles,
      override_text: msgCopy.tw,
      skip_compliance: isGlobalCompliance === 'true' ? true : skipCompliance.toString(),
      // skip_compliance:
      //   (!isEdit ||
      //     (campaignStatus !== 'compliance_rejected' &&
      //       campaignStatus !== 'scheduled')) &&
      //   userData &&
      //   get(userData, 'compliance', '') === 'optional'
      //     ? String(skipCompliance)
      //     : (userData && get(userData, 'compliance', '') === 'on') ||
      //       (isEdit &&
      //         (campaignStatus === 'compliance_rejected' ||
      //           (campaignStatus === 'scheduled' && complianceApproved)) &&
      //         userData &&
      //         get(userData, 'compliance', '') === 'optional')
      //     ? 'false'
      //     : 'true',
      ...(!isEmpty(twLists) && {
        schedule: timingTabs.tw === 0 ? 'immediate' : 'specific_time',
        schedule_time: twLists,
        snetwork_act_id: twSNid,
      }),
      lead_gen: isLeadEnabled ? enableLeadGen.toString() : 'false',
      referral: 'false',
      ...(setObjectivesTabData?.webinar?.wuid?.length > 0
        ? { webinar_id: setObjectivesTabData.webinar.wuid }
        : {}),
    };

    fbLists = !isEmpty(selectedFbContacts)
      ? selectedFbContacts.map((item, index) => {
          return {
            destination_id: item,
            ...(timingTabs.fb === 1 ? { time: dateCol.fb } : null),
          };
        })
      : [];

    fbPayload = {
      articles: articles,
      override_text: msgCopy.fb,
      skip_compliance: isGlobalCompliance === 'true' ? true : skipCompliance.toString(),
      // skip_compliance:
      //   (!isEdit ||
      //     (campaignStatus !== 'compliance_rejected' &&
      //       campaignStatus !== 'scheduled')) &&
      //   userData &&
      //   get(userData, 'compliance', '') === 'optional'
      //     ? String(skipCompliance)
      //     : (userData && get(userData, 'compliance', '') === 'on') ||
      //       (isEdit &&
      //         (campaignStatus === 'compliance_rejected' ||
      //           (campaignStatus === 'scheduled' && complianceApproved)) &&
      //         userData &&
      //         get(userData, 'compliance', '') === 'optional')
      //     ? 'false'
      //     : '',
      ...(!isEmpty(fbLists) && {
        schedule: timingTabs.fb === 0 ? 'immediate' : 'specific_time',
        schedule_time: fbLists,
        snetwork_act_id: fbSNid,
      }),
      lead_gen: isLeadEnabled ? enableLeadGen.toString() : 'false',
      referral: 'false',
      ...(setObjectivesTabData?.webinar?.wuid?.length > 0
        ? { webinar_id: setObjectivesTabData.webinar.wuid }
        : {}),
    };

    lnLists = !isEmpty(selectedLnContacts)
      ? selectedLnContacts.map((item, index) => {
          return {
            destination_id: item,
            ...(timingTabs.ln === 1 ? { time: dateCol.ln } : null),
          };
        })
      : [];

    lnPayload = {
      articles: articles,
      override_text: msgCopy.ln,
      skip_compliance: isGlobalCompliance === 'true' ? true : skipCompliance.toString(),
      ...(!isEmpty(lnLists) && {
        schedule: timingTabs.ln === 0 ? 'immediate' : 'specific_time',
        schedule_time: lnLists,
        snetwork_act_id: lnSNid,
      }),
      lead_gen: isLeadEnabled ? enableLeadGen.toString() : 'false',
      referral: 'false',
      ...(setObjectivesTabData?.webinar?.wuid?.length > 0
        ? { webinar_id: setObjectivesTabData.webinar.wuid }
        : {}),
    };

    let payload = {
      posts: JSON.stringify({
        ...(fbPayload ? { facebook: fbPayload } : null),
        ...(twPayload ? { twitter: twPayload } : null),
        ...(lnPayload ? { linkedin: lnPayload } : null),
      }),
      ...(isadvMode &&
      isAdvisorApprovalMode &&
      (!isEdit || campaignStatus === 'draft')
        ? { advisor_approval: isApprovalRequired }
        : {}),

      ...(isadvMode &&
      isAdvisorApprovalMode &&
      isEdit &&
      (campaignStatus === 'approval_rejected' ||
        campaignStatus === 'approval_pending' ||
        (campaignStatus === 'compliance_rejected' && socialAdvApproval) ||
        (campaignStatus === 'scheduled' && socialAdvApproval))
        ? { advisor_approval: true }
        : {}),
      checkbox_status: JSON.stringify({
        message_copy: isMsgCopyAllPlatform,
        schedule: isScheduleAllPlatform,
      }),
      ...(base64Logo == ''
        ? {}
        : {
            webinar_custom_image: dataURLtoFile(
              base64Logo,
              `filename.${fileType}`
            ),
          }),
    };

    console.log('payload', payload);
    setLoading(true);
    publishCampaignV2({ id: campaignId, payload })
      .then((res) => {
        setLoading(false);
        if (get(setObjectivesTabData, 'approvedToShare', 'false') === 'true') {
          const payload = {
            is_completed: true,
            ...((createMode === 'series' ||
              get(setObjectivesTabData, 'campaignType', '') === 'series') && {
              record_type: 'series',
            }),
          };
          postCascadeCampaign({ id: seriesId || campaignId, payload })
            .then((res) => {
              if (res?.result?.success) {
                let mode = get(setObjectivesTabData, 'createMode', '');
                if (get(reviewCampaign, 'fromReview', false)) {
                  redirectCascadeAdvisor();
                } else if (mode === 'oneTime' || fromDashboard)
                  history.push('/campaignV2');
                else if (mode === 'series') redirectionCampaign();
              }
            })
            .catch((err) =>
              console.log('ERROR cascading campaign - - -- -', err)
            );
        } else {
          let mode = get(setObjectivesTabData, 'createMode', '');
          if (get(reviewCampaign, 'fromReview', false)) {
            redirectCascadeAdvisor();
          } else if (mode === 'oneTime' || fromDashboard)
            history.push('/campaignV2');
          else if (mode === 'series') redirectionCampaign();
        }
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
      });
  };

  const handlePopupClose = (isAddContent) => {
    if (!isAddContent) {
      setSelectedContent([]);
    }
    setSlectPopup(false);
  };

  return (
    <Flex
      display="flex"
      flexDirection="column"
      style={{
        margin: '2% 0%',
      }}
    >
      <CampaignNavigation
        enableNext={true}
        nextHandler={handleNextClick}
        backHandler={handleBackButtonClick}
        loading={loader}
        saveAndCloseHandler={handleSaveAndClose}
        stepList={stepList}
        selectedIndex={selectedIndex}
        isLastStep={true}
        hideBackButton={curFlow && curFlow === 'both'}
        disabledraft
      />
      <Flex display="flex" flexDirection="column" width="1200px">
        <Flex
          display="flex"
          flexDirection="row"
          width="1200px"
          justifyContent="space-between"
          alignItems="center"
        >
          <CardSpan>Content Details</CardSpan>
          {/* {campaignSelectedArticles && campaignSelectedArticles.length >= 1 && ( */}
          {!fromDashboard &&
            get(setObjectivesTabData, 'isCascaded', 'false') !== 'true' && (
              <Button
                color="#6351ed"
                bgColor="#fff"
                borderRadius="5px"
                padding="7px 50px"
                margin="0px 0px 7px 0px"
                onClick={() => {
                  setSlectPopup(true);
                }}
                testId="select-social-article"
                disabled={setObjectivesTabData?.webinar?.isWebinar}
              >
                Select Content
              </Button>
            )}
        </Flex>

        <CreateContainer
          marginTop="0px"
          height="180px"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {/* {!isEmpty(campaignSelectedArticles) &&
            campaignSelectedArticles.map((item, index) => {
              return <SelectedArticle articleData={item} />;
            })} */}
          <SelectedArticle
            articleData={
              isEmpty(selectedContent) ? selectedContent : selectedContent[0]
            }
            // articleData={selectedContent}
            userData={userData}
            pdfPresent={pdfPresent}
            enableLeadGen={enableLeadGen}
            toggleLeadGen={toggleLeadGen}
            fromSocialPreview={true}
            onFileUploadChange={onFileUploadChange}
            setErrorMsg={setErrorMsg}
            setObjectivesTabData={setObjectivesTabData}
          />
        </CreateContainer>
      </Flex>
      <Flex
        display="flex"
        flexDirection="column"
        width="1200px"
        marginTop="20px"
      >
        <CardSpan>Share Copy</CardSpan>
        <CreateContainer
          flexDirection="row"
          marginTop="0px"
          alignItems="flex-start"
          justifyContent={
            get(setObjectivesTabData, 'myNetwork', false)
              ? 'center'
              : 'flex-start'
          }
          height={
            isadvMode && isAdvisorApprovalMode && !isEdit ? '625px' : '525px'
          }
        >
          <Flex display="flex" flexDirection="column">
            <Flex display="flex" flexDirection="row">
              <ShareCopyCard
                objectivesTabData={setObjectivesTabData}
                activePlatform={activePlatform}
                setActivePlatform={setActivePlatform}
                msgCopy={msgCopy}
                setMsgCopy={setMsgCopy}
                isMsgCopyAllPlatform={isMsgCopyAllPlatform}
                setIsMsgCopyAllPlatform={setIsMsgCopyAllPlatform}
                setIsApprovalRequired={setIsApprovalRequired}
                isApprovalRequired={isApprovalRequired}
                isadvMode={isadvMode}
                isAdvisorApprovalMode={isAdvisorApprovalMode}
                isEdit={isEdit}
                isFbSelected={selectedFbContacts.length !== 0 ? 1 : 0}
                isLnSelected={selectedLnContacts.length !== 0 ? 1 : 0}
                isTwSelected={selectedTwContacts.length !== 0 ? 1 : 0}
                campaignStatus={campaignStatus}
                userData={userData}
                skipCompliance={skipCompliance}
                setSkipCompliance={setSkipCompliance}
                selectedContent={selectedContent}
                isSummaryFetching={isFetching}
                disableEdit={
                  userRole !== 'admin' &&
                  get(setObjectivesTabData, 'isCascaded', 'false') === 'true'
                }
                isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
                isFirmLevelAdmin={isFirmLevelAdmin}
                firmSettings={firmSettings}
                isGlobalCompliance={isGlobalCompliance}
                companyId={companyId}
              />
              {get(setObjectivesTabData, 'myNetwork', false) && (
                <ScheduleCard
                  activePlatform={activePlatform}
                  setActivePlatform={setActivePlatform}
                  dateCol={dateCol}
                  setDateCol={setDateCol}
                  timeCol={timeCol}
                  setTimeCol={setTimeCol}
                  isScheduleAllPlatform={isScheduleAllPlatform}
                  setIsScheduleAllPlatform={setIsScheduleAllPlatform}
                  timingTabs={timingTabs}
                  setTimingTabs={setTimingTabs}
                  isEdit={isEdit}
                  isFbSelected={selectedFbContacts.length !== 0 ? 1 : 0}
                  isLnSelected={selectedLnContacts.length !== 0 ? 1 : 0}
                  isTwSelected={selectedTwContacts.length !== 0 ? 1 : 0}
                  userData={userData}
                  enableLeadGen={enableLeadGen}
                  toggleLeadGen={toggleLeadGen}
                  pdfPresent={pdfPresent}
                />
              )}
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              alignSelf="center"
              mb={
                userData &&
                userData.details &&
                userData.details.user &&
                userData.details.user.enable_lead_capture === 'true' &&
                pdfPresent
                  ? '0px'
                  : '15px'
              }
            >
              <LoaderWrapper isLoading={loading}>
                <Tooltip
                  title={
                    isEmpty(selectedContent)
                      ? 'Please select an article'
                      :  
                      (get(setObjectivesTabData, 'isCascaded', 'false') === 'false' && setObjectivesTabData.webinar.wuid !== '' &&
                        isEmpty(base64Logo))
                      ? 'Please select a custom image'
                      : ''
                  }
                >
                  <div>
                    <Button
                      borderRadius="5px"
                      padding="7px 100px"
                      onClick={() => {
                        shareCampaign();
                      }}
                      disabled={checkValidation()}
                      id="campaign_social_share"
                      margin={
                        userData &&
                        userData.details &&
                        userData.details.user &&
                        userData.details.user.enable_lead_capture === 'true' &&
                        pdfPresent
                          ? '0px'
                          : ''
                      }
                    >
                      {/* {(userData && get(userData, 'compliance', '') === 'on') ||
                      (isEdit &&
                        (campaignStatus === 'compliance_rejected' ||
                          (campaignStatus === 'scheduled' &&
                            complianceApproved)) &&
                        userData &&
                        get(userData, 'compliance', '') === 'optional')
                        ? 'Share After Compliance Approval'
                        : 'Share'} */}
                      {isGlobalCompliance === 'true' ? 'Share' : skipCompliance ? 'Share' : 'Share After Compliance Approval'}
                    </Button>
                  </div>
                </Tooltip>
              </LoaderWrapper>
            </Flex>
          </Flex>
        </CreateContainer>
      </Flex>
      {/* <FooterBtnsWrapper>
        <CampaignFooterButtons
          // enableNext={!handleValidation()}
          enableNext={true}
          nextHandler={handleNextClick}
          backHandler={handleBackButtonClick}
          loading={loader}
          saveAndCloseHandler={handleSaveAndClose}
          padding="10px 0px"
          isLastStep={true}
          hideBackButton={curFlow && curFlow === 'both'}
        />
      </FooterBtnsWrapper> */}
      {selectPopup && curFlow === 'both' && !isEmpty(campaignSelectedArticles) && (
        <SelectContentPopup
          handleClose={handlePopupClose}
          campaignSelectedArticles={campaignSelectedArticles}
          setSelectedContent={setSelectedContent}
          selectedContent={selectedContent}
        />
      )}
      {selectPopup && (curFlow === 'socialOnly' || (curFlow === 'both' && isEmpty(campaignSelectedArticles))) && (
        <SelectContentModal
          showModal={selectPopup}
          toggleModal={setSlectPopup}
          handleClose={handlePopupClose}
          campaignSelectedArticles={campaignSelectedArticles}
          setSelectedContent={setSelectedContent}
          selectedContent={selectedContent}
        />
      )}
    </Flex>
  );
};
