import styled from 'styled-components';
import { Box } from '@rebass/grid';

export const StyledWrapper = styled(Box)`
  position: relative;
  font-size: 10px;
  background-color: #c95cfc;
  color: #fff;
  margin-right: 5px;
  button {
    font-size: inherit;
    padding: 2px 5px;
  }
  :hover {
    ul.parent-ul {
      display: block;
    }
  }
  ul.parent-ul {
    display: none;
    position: absolute;
    top: calc(100%);
    right: 0%;
    margin: 0;
    padding: 0;
    margin-right: 1px;
    list-style: none;
    background-color: ${({background}) => background || '#b1a1d0'};
    border-radius: 10px;
    color: #fff;
    li {
      padding: 2px 5px;
      white-space: nowrap;
      text-align: left;
    }

    ul.child-ul {
      right: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      display: none;
      position: absolute;
      right: 100%;
      top: 0px;
      background-color: ${({background}) => background || '#b1a1d0'};
      border-radius: 10px;
      color: #fff;
      max-height: 135px;
      overflow-y: auto;
      z-index: 100;

      > li:hover {
          background-color: #1B0983 !important;
          ul.child-ul {
            display: block;
          }
      }
    }
    > li:hover {
      ul.child-ul {
        display: block;
      }
    }
    li:hover {
      color: #ffffff;
      background-color: ${({background}) => '#1B0983' || '#6351ed'};
      cursor: pointer;
      &:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      label {
        cursor: pointer;
      }
    }
  }
`;

export const SelectedItemBox = styled(Box)`
  font-size: 10px;
  color: #fff;
  background-color: ${({background}) => background || '#b1a1d0'};
  padding: 2px 5px;
  border-radius: 8px;
  margin-bottom: 3px;
  margin-right: 5px;
  button {
    height: 10px;
    width: 10px;
  }
`;
