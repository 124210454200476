import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FontAwesome = styled(FontAwesomeIcon)`
  font-size: ${(props) => (props.small ? '24px' : '28px')};
  width: ${(props) => (props.small ? '24px' : '50px !important')};
  height: ${(props) => (props.small ? '24px' : '50px !important')};
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  color: #ffffff;
  background: #6351ed;
  padding: ${(props) => (props.small ? '6px' : '12px')};
  margin: ${(props) => (props.small ? '10px 0px' : '10px')};
  cursor: pointer;
  ${({ isDiconnected }) => isDiconnected && 'background-color: #D4D4D4;'}
`;

export const ImageSpan = styled.span`
  width: ${({ iconHeight }) => iconHeight || '24'}px !important;
  height: ${({ iconHeight }) => iconHeight || '24'}px !important;
  background: #6351ed;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 70%;
    height: auto;
  }
  ${({ isDiconnected }) => isDiconnected && 'background-color: #D4D4D4;'}
`;
