import React, { useState, useEffect, useRef } from 'react';
import { get, toLower } from 'lodash';
import { Button, Input } from '../';
import {
  MioAutoCompleteWrapper,
  MioAutoCompleteDropdown,
  MioAutoCompleteInputWrapper,
  MioAutoCompleteMenuItemsWrapper,
} from './MioAutoComplete.styles';

const MioAutoComplete = ({ options, onChange }) => {
  const menuWrapper = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [filteredMenuList, setFilteredMenuList] = useState([]);

  const handleClickOutside = (e) => {
    if (menuWrapper.current && !menuWrapper.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredData = (options || []).filter(
      ({ value, label }) =>
        toLower(value).indexOf(toLower(textValue)) !== -1 ||
        toLower(label).indexOf(toLower(textValue)) !== -1
    );
    setFilteredMenuList(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue]);

  useEffect(() => {
    setFilteredMenuList(options);
  }, [options]);

  const onOptionSelect = ({ value, label }) => {
    setSelectedValue(label);
    onChange({ value, label });
    setDropdownOpen(false);
  };

  const defaultValue = get(options, '[0].label', '');
  return (
    <MioAutoCompleteWrapper ref={menuWrapper}>
      <div>
        <Button
          type="button"
          width="100%"
          onClick={() => setDropdownOpen(true)}
        >
          {selectedValue || defaultValue}
        </Button>
      </div>
      {isDropdownOpen && (
        <MioAutoCompleteDropdown>
          <MioAutoCompleteInputWrapper>
            <Input onChange={(e) => setTextValue(e.target.value)} />
          </MioAutoCompleteInputWrapper>
          <MioAutoCompleteMenuItemsWrapper>
            <ul>
              {filteredMenuList.map((option) => {
                return (
                  <li onClick={() => onOptionSelect(option)}>{option.label}</li>
                );
              })}
            </ul>
          </MioAutoCompleteMenuItemsWrapper>
        </MioAutoCompleteDropdown>
      )}
    </MioAutoCompleteWrapper>
  );
};

export default MioAutoComplete;
