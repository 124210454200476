import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box } from '@rebass/grid';

import { HeaderDiv } from './Header.styles';
import { Grid } from '../CommonStyles';
import { HeaderLogoNew } from './Header.styles';
import { logo } from '../../utils/constants';

const Header = ({ children, logoUrl, type, customLogoUrl }) => (
  <HeaderDiv id="header-div" type={type} screenWidth={window.innerWidth}>
    <Grid dashboard>
      <Flex
        width={1}
        justifyContent="space-between"
        alignItems="center"
        height="60px"
      >
        <Box paddingTop={customLogoUrl ? '6px' : '1px'} height={customLogoUrl ? '150%' : "73px"} marginLeft={logo.newNavbar ? '-11px' : ''} marginTop={logo.newNavbar ? '3px' : ''} width={logo.newNavbar ? '146px' : ''}>
         <HeaderLogoNew
            src={customLogoUrl || logo.newNavbar}
            alt="mio-logo"
          />
           </Box>
        <Box height="100%">{children}</Box>
      </Flex>
    </Grid>
  </HeaderDiv>
);

export default Header;
