/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@rebass/grid';
import { Flex } from '@rebass/grid';
import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import {
  ApprovalCard,
  RadioInput,
  RadioInputLabel,
} from '../../DripCampaignContainerV2/SocialPreview/SocialPreview.styles';
import { AutomatedCampaignFormWrapper } from '../styles';
import { connect } from 'react-redux';

import StepsFooter from './StepsFooter';
import { activateCampaign, complianceSendCampaign } from '../../../services/automated-campaigns';
import { useHistory } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import StyledTooltip from '../../../components/Tooltip';
import moment from 'moment';
import { Button } from '../../../components';

const AactivateCampaignWrapper = styled(Flex)`
  width: 75%;
  margin: 0 auto;
  flex-direction: column;

  .send_btn {
    cursor: pointer;
    font-family: 'Poppins';
    width: 100px;
    height: 40px;
    border: 0;
    background-color: #6351ed;
    border-radius: 5px;
    color: white;
  }
`;

const ActivateCampaign = ({
  currentStepIndex,
  setCurrentStepIndex,
  userData,
  automatedCampaignDetails,
  userRole,
  compliance,
  campaignSetState,
  landingPagesData,
  segmentListData,
  firmSettings,
  isFirmLevelAdmin,
  companyId
}) => {
  const [requiredApproval, setRequiredApproval] = useState(false);
  const [complianceSettings, setComplianceSettings] = useState('off');

  console.log(get(automatedCampaignDetails, 'targetInfo.is_global_compliance', 'false'));

  useEffect(() => {
    const complianceOption = isFirmLevelAdmin && !isEmpty(firmSettings) ? 
        get(firmSettings, `firm_compliance.${[companyId]}.compliance_is`, 'always_off')
          : compliance;
    setComplianceSettings(complianceOption);
    if(complianceOption === 'on' || complianceOption === 'always_on') {
      setRequiredApproval(true);
    }
  }, [])

  const getDropDownValues = (id) => {
    for(let item in landingPagesData)
        if(landingPagesData[item]._id === id)
            return landingPagesData[item].name;
    for(let item in segmentListData)
        if(segmentListData[item].id === id)
            return segmentListData[item].name;
  }


  const [reviewData, setReviewData] = useState([
    get(automatedCampaignDetails, 'targetInfo.name', null) && {
      title: 'Campaign Name',
      value: get(automatedCampaignDetails, 'targetInfo.name', ''),
    },
    get(automatedCampaignDetails, 'targetInfo.purpose', null) && {
      title: 'Purpose',
      value: get(automatedCampaignDetails, 'targetInfo.purpose', ''),
    },
    get(automatedCampaignDetails, 'targetInfo.segment_id', null) && {
      title: 'Target Segment',
      value:  getDropDownValues(automatedCampaignDetails.targetInfo.segment_id),
    },
    get(automatedCampaignDetails, 'targetInfo.target_page_id', null) && {
      title: 'Event Page',
      value: getDropDownValues(automatedCampaignDetails.targetInfo.target_page_id),
    },
    get(automatedCampaignDetails, 'targetInfo.event_date', null) && {
      title: 'Event Date',
      value: (moment(get(automatedCampaignDetails, 'targetInfo.event_date', '')).format('MM/DD/YYYY')),
    },
    get(automatedCampaignDetails, 'targetInfo.converted_tag', null) && {
      title: 'Event Registration Tag',
      value: get(automatedCampaignDetails, 'targetInfo.converted_tag', ''),
    },
  ]);

  const history = useHistory();
  const handleSend = async () => {
    const { campaignId } = automatedCampaignDetails;
    (get(automatedCampaignDetails, 'targetInfo.is_global_compliance', 'false') === 'false' && requiredApproval) ? await complianceSendCampaign(campaignId) : await activateCampaign(campaignId);
    campaignSetState({automatedDashboard : true});
    history.push('/campaignV2');
  };

  return (
    <>
      <AutomatedCampaignFormWrapper>
        <AactivateCampaignWrapper>
          <Box
            fontSize={'24px'}
            marginBottom="25px"
            style={{ fontWeight: 'bold' }}
          >
            Activate
          </Box>

          {/* TODO: UNCOMMENT this post introducing compliance workflow for ACs */}
          {get(automatedCampaignDetails, 'targetInfo.is_global_compliance', 'false') === 'false' && complianceSettings === 'optional' && (
            <>
              <Box marginBottom="25px">
                This workflow will be acitvated after any required approvals.
              </Box>
              <Box width={1}>
                <ApprovalCard margin={'0 auto'} justifyContent={'center'}>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    mr="15px"
                    mt="3px"
                  >
                    <label
                      style={{
                        color: 'black',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                      data-testid="compliance_approval_label"
                    >
                      Compliance Approval Required?
                    </label>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center" mr="5px">
                    <RadioInput
                      type="radio"
                      id="compliance_approval_yes"
                      name="compliance_approval"
                      onChange={(e) => setRequiredApproval(true)}
                      style={{ cursor: 'pointer' }}
                    />
                    <RadioInputLabel
                      htmlFor="compliance_approval_yes"
                      style={{ cursor: 'pointer' }}
                    >
                      Yes
                    </RadioInputLabel>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center">
                    <RadioInput
                      type="radio"
                      id="compliance_approval_no"
                      name="compliance_approval"
                      checked={!requiredApproval}
                      onChange={(e) => setRequiredApproval(false)}
                      style={{ cursor: 'pointer' }}
                    />
                    <RadioInputLabel
                      htmlFor="compliance_approval_no"
                      style={{ cursor: 'pointer' }}
                    >
                      No
                    </RadioInputLabel>
                  </Flex>
                </ApprovalCard>
              </Box>
            </> 
          )}
          <hr style={{ width: '100%' }} />
          <Flex width="70%" margin={'0 auto'} flexDirection={'column'}>
            {reviewData &&
              reviewData.map(
                (data) =>
                  data && (
                    <Flex
                      justifyContent={'space-between'}
                      margin="10px 0"
                      color={'#000000'}
                      fontWeight="600"
                    >
                      <Flex>
                        {data.title}
                        {data.title === 'Campaign Name' ? (
                          <StyledTooltip
                            text={
                              'This campaign will be sent to the selected segment. Any time a contact is added to that segment, they will start this automated campaign.'
                            }
                          />
                        ) : data.title === 'Event Registration Tag' ? (
                          <StyledTooltip
                            text={
                              'This tag will be applied to contacts who fill out the event registration form on the Event Page.'
                            }
                          />
                        ) : null}
                      </Flex>
                      <Flex>{data.value}</Flex>
                    </Flex>
                  )
              )}
          </Flex>
          <hr style={{ width: '100%' }} />
          <Flex width={1} justifyContent={'center'} margin="50px 0px 20px">
            <Button onClick={() => handleSend()}>
              {requiredApproval ? 'Send After Compliance Approval' : 'Send'}
            </Button>
          </Flex>
        </AactivateCampaignWrapper>
      </AutomatedCampaignFormWrapper>

      <StepsFooter
        currentStepIndex={currentStepIndex}
        setCurrentStepIndex={setCurrentStepIndex}
        handleNext={() => alert('activate this campaign')}
        disableNext={false}
        disableSaveAndClose={false}
        handleSaveAndClose={() => {
            campaignSetState({ automatedDashboard: true });
            history.push('/campaignV2');
          }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    companyId: state?.user?.data?.details?.company?.id
  }
} 

export default connect(mapStateToProps)(ActivateCampaign);
