/* eslint-disable jsx-a11y/anchor-is-valid */
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import OktaLoginWidget from './OktaLoginWidget';
import { actions as loginActions } from '../../redux/modules/login.module';
import { getRoleType, getUserRole } from '../../redux/selector';
import { ROLE_TYPES, USER_ROLE } from '../../utils';
import useQuery from '../../customHooks/useQuery';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';
import { LoginFooter, LoginLogo, LoginPanelBox, LoginWrapper } from './LoginContainer.styles';
import { Flex } from '@rebass/grid';
import { LoginBanner } from './Login.styles';
import { ErrorContainer } from '../../components';

const LoginContainerV3 = ({
    loginRequest,
    userRole,
    roleType,
    loginSetState,
    isLoginError
}) => {
    const { oktaAuth, authState } = useOktaAuth();
    const loginQuery = useQuery();
    const advisorNotify = loginQuery.get('advisor_notification');
    const history = useHistory();
    const [tokenData, setTokenData] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSuccess = (tokens) => {
        console.log(tokens);
        setTokenData(tokens);
        setLoading(true);
        loginRequest({ noRefresh: true, token: tokens?.accessToken?.accessToken });
    }

    const handleError = (error) => {
        console.log(error)
    }

    const goToDashboard = () => {
      if (isEqual(userRole, USER_ROLE.admin)) {
        history.push('/admin/dashboard');
      } else {
        history.push('/dashboard');
      }
    };
  
    const goToCampaignDashboard = () => {
      history.push('/campaignV2?advisor_notification=true');
    };
  
    const goToCompliance = () => {
      if (isEqual(userRole, USER_ROLE.admin)) {
        history.push('/admin/compliance');
      }
    };

    useEffect(() => {
      const windowBodyClassList = document.body.classList;
      if (!windowBodyClassList.contains('login')) {
        windowBodyClassList.add('login');
      }
      return () => {
        windowBodyClassList.remove('login');
      };
    }, []);

    useEffect(() => {
        if (userRole) {
            oktaAuth.handleLoginRedirect(tokenData);
            if (roleType === ROLE_TYPES.ADMIN_COMPLIANCE) {
                goToCompliance();
            } else if (advisorNotify) {
                goToCampaignDashboard();
            } else {
                goToDashboard();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole]);

    if(loading && !isLoginError && tokenData) {
      return (<div style={{
        position: 'fixed',
        top: '40%',
        left: '40%'
      }}>
        Redirecting to dashboard ...
      </div>)
    }

    return (
      <>
        <LoginWrapper
          width={1}
          height="94vh"
          alignItems="flex-start"
          justifyContent="center"
        >

          <LoginPanelBox>
            <Flex
              width="100%"
              justifyContent="flex-start"
              padding="0px 25px"
              mb="25px"
            >
              <LoginLogo
                style={{ width: '200px', textAlign: 'left', marginLeft: '-28px', marginTop: '-25px', }}
                src="/assets/images/TIFIN-Clout-Logo-Color.png"
                alt="mio-logo"
              />
            </Flex>
            {isLoginError && (
              <ErrorContainer align="center">
                Invalid Email Id or Password
              </ErrorContainer>
            )}
            <div style={{ width: '420px', margin: 'auto' }}>
              <h2
                style={{
                  fontSize: '25px',
                  fontFamily: 'Poppins',
                  fontWeight: '600 !important',
                }}
              >
                Already A Clout User?
              </h2>
              <p style={{ color: '#49484a' }}>Welcome Back!</p>
            </div>
            <OktaLoginWidget
              onSuccess={handleSuccess}
              onError={handleError}
            />
          </LoginPanelBox>
          <LoginFooter style={{ fontSize: '10px' }}>
            <a href="/terms.html" target="_bkank">
              Terms
            </a>
            &nbsp;|&nbsp;
            <a href="/privacypolicy.html" target="_bkank">
              Privacy
            </a>
          </LoginFooter>
        </LoginWrapper>
      </> 
    )
}

const mapStateToProps = (state) => ({
    userRole: getUserRole(state),
    isLoginError: state.user.isError,
    roleType: getRoleType(state),
  });

const mapDispatchToProps = {
    loginRequest: loginActions.api.login.request,
    loginSetState: loginActions.api.login.setstate,
  };

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainerV3);