import React from 'react';
import './MembersTable.css';

const membersTable = ({
    isAssetAdmin,
    advisorsList,
    changeHandler,
    toggleAllHandler,
    toggleAll,
    userData,
    width
}) => {
    return (
        <table 
            style={{ 
                border: '1px solid #cacaca', 
                borderRadius: '5px',
                fontSize: '0.7rem', 
                margin: 'auto', 
                color: '#49484a', 
                width: width || '', 
                marginTop: '30px' 
            }}
        >
            <thead 
                style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'flex-start', 
                    borderBottom: '1px solid #cacaca', 
                    paddingBottom: '5px',
                    paddingRight: '1rem' 
                }}
            >
                <th 
                    style={{ 
                        width: '34%', 
                        textAlign: 'left', 
                        paddingLeft: '1rem', 
                        paddingTop: '4px' 
                    }}
                >
                    {isAssetAdmin ? 'Members' : 'Advisors'}
                </th>
                <th style={{ width: '22%' }}>
                    <div 
                        style={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            paddingRight: advisorsList && advisorsList.length > 5 ? '10px' : '' 
                        }}
                    >
                        Share Content
                        <input 
                            className='hidden-input' 
                            name='share_content' 
                            ref={toggleAll.share_content} 
                            id='share-content' type='checkbox' 
                            onChange={e => toggleAllHandler(e)}
                        ></input>
                        <label 
                            style={{cursor: 'pointer'}} 
                            id='share-content-head' 
                            for='share-content' 
                            className='checkmark header-checkmark'
                        ></label>
                    </div>
                </th>
                <th style={{ width: '22%' }}>
                    <div 
                        style={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            paddingRight: advisorsList && advisorsList.length > 5 ? '13px' : '' 
                        }}
                    >
                        Add Content
                        <input 
                            className='hidden-input' 
                            name='add_content' 
                            ref={toggleAll.add_content} 
                            id='add-content' 
                            type='checkbox' 
                            onChange={e => toggleAllHandler(e)}
                        ></input>
                        <label 
                            style={{cursor: 'pointer'}} 
                            id='add-content-head' 
                            for='add-content' 
                            className='checkmark header-checkmark'
                        ></label>
                    </div>
                </th>
                <th style={{ width: '22%' }}>
                    <div 
                        style={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            paddingRight: advisorsList && advisorsList.length > 5 ? '18px' : '',
                            color:  !userData || !userData.enable_lead_capture || (userData.enable_lead_capture === 'false') ? '#acacac' : 'inherit'
                        }}
                    >
                        Lead & Referral Capture
                        <input 
                            className='hidden-input' 
                            name='lead_capture' 
                            ref={toggleAll.lead_capture} 
                            id='lead-capture' 
                            type='checkbox'
                            onChange={e => toggleAllHandler(e)}
                            disabled={!userData || !userData.enable_lead_capture || (userData.enable_lead_capture === 'false')}
                        ></input>
                        <label 
                            style={{
                                cursor: !userData || !userData.enable_lead_capture || (userData.enable_lead_capture === 'false') ? 'not-allowed' : 'pointer',
                                borderColor: !userData || !userData.enable_lead_capture || (userData.enable_lead_capture === 'false') ? '#acacac' : 'inherit'
                            }} 
                            id='lead-capture-head' 
                            for='lead-capture' 
                            className='checkmark header-checkmark'
                        ></label>
                    </div>
                </th>
            </thead>
            <tbody 
                style={{ 
                    display: 'block', 
                    maxHeight: '125px', 
                    overflowY: 'auto', 
                    padding: '5px 1rem 5px 0px' 
                }}
            >
                {advisorsList.map(advisor => (
                    <tr 
                        style={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            position: 'relative', 
                            height: '1.5rem', 
                            alignItems: 'baseline'
                        }}
                    >
                        <td 
                            style={{ 
                                width: '34%', 
                                textAlign: 'left', 
                                paddingLeft: '1rem', 
                                color: '#6351ed', 
                                fontfamily: 'Poppins', 
                                fontSize: '13px', 
                                fontWeight: '500' 
                            }}>
                            {advisor.first_name} {advisor.last_name}
                        </td>
                        <td style={{ width: '22%', paddingTop: '5px' }}>
                            <input 
                                className='hidden-input' 
                                name='share_content' 
                                id={advisor.id + '_share'} 
                                type='checkbox' 
                                checked={advisor.settings.share_content == 'true'} 
                                onChange={e => changeHandler(e, advisor.id)}
                            ></input>
                            <label 
                                style={{cursor: 'pointer'}} 
                                for={advisor.id + '_share'} 
                                className='checkmark'
                            ></label>
                        </td>
                        <td style={{ width: '22%', paddingTop: '5px' }}>
                            <input 
                                className='hidden-input' 
                                name='add_content' 
                                id={advisor.id + '_add'} 
                                type='checkbox' 
                                checked={advisor.settings.add_content == 'true'} 
                                onChange={e => changeHandler(e, advisor.id)}
                            ></input>
                            <label 
                                style={{cursor: 'pointer'}} 
                                for={advisor.id + '_add'} 
                                className='checkmark'
                            ></label>
                        </td>
                        <td style={{ width: '22%', paddingTop: '5px'}}>
                            <input 
                                className='hidden-input' 
                                name='lead_capture' 
                                id={advisor.id + '_lead'} 
                                type='checkbox' 
                                checked={advisor.settings.lead_capture == 'true'} 
                                disabled={!userData || !userData.enable_lead_capture || (userData.enable_lead_capture === 'false')} 
                                onChange={e => changeHandler(e, advisor.id)}
                            ></input>
                            <label 
                                style={{
                                    cursor: !userData || !userData.enable_lead_capture || (userData.enable_lead_capture === 'false') ? 'not-allowed' : 'pointer',
                                    borderColor: !userData || !userData.enable_lead_capture || (userData.enable_lead_capture === 'false') ? '#acacac' : 'inherit'
                                }} 
                                for={advisor.id + '_lead'} 
                                className='checkmark'
                            ></label>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default membersTable;