import styled from 'styled-components';
import { themesData } from '../../utils/constants';

export const FeedbackTrigger = styled.button`
  background-color: #fff !important;
  border: 1px solid #a0a0a0 !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #6351ed !important;
  font-family: 'Poppins' !important;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 1.1em;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #6351ed;
  border-radius: 5px 5px 0px 0px;
  padding: 5px 20px 5px 20px;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  position: fixed;
  left: calc(100% - 93px);
  top: 50%;
  transform: rotate(270deg);
  padding-top: 10px;

  @media (max-width: 768px) {
    margin-bottom: 0;
    width: 160px;
    right: 0;
    transform: rotate(0deg);
    position: fixed;
    z-index: 999999999;
    bottom: 0;
    left: auto;
    top: unset;
    border: 1px solid #a0a0a0 !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export const FeedbackBox = styled.div`
  background: #e4e4e5;
  box-shadow: 4px 0px 5px 0px rgba(0, 0, 0, 0.3);
  color: #6351ed !important;
  // border: 1px solid #6351ed !important;
  font-family: 'Poppins' !important;
  width: 37%;
  position: absolute;
  right: 0;
  top: 37%;
  display: none;
  z-index: 100;

  @media (max-width: 768px) {
    margin-bottom: 0;
    bottom: 0;
    top: unset;
    position: fixed;
    z-index: 999999999;
  }
`;
export const FeedbackHeader = styled.div`
  display: block;
  padding: 10px 20px;
  font-size: 14px;
  font-family: 'Poppins' !important;
  font-weight: 600;
  cursor: pointer;
  span {
    float: right;
    font-weight: bolder;
  }
`;

export const FeedbackHeaderMinimizeIcon = styled.div`
  float: right;
  display: inline-block;
  font-family: 'Poppins' !important;
`;

export const FeedbackBody = styled.div`
  background: #fff;
  padding: 30px;
  font-family: 'Poppins' !important;
  display: grid;
`;

export const Feedbacktext = styled.textarea`
  display: block;
  width: 100%;
  height: 100px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #6351ed !important;
  font-family: 'Poppins' !important;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: 600;
  margin-bottom: 12px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

export const Submit = styled.button`
  background-color: ${themesData.purpleishBlue} !important;
  border: 1px solid ${themesData.purpleishBlue} !important;
  color: #ffffff !important;
  font-family: 'Poppins' !important;
  padding: 5px 15px;
  font-size: 12px;
  width: 100px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  margin: 0 auto;
`;
