/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Flex } from '@rebass/grid';
import { Button } from '../../components';
import ThankyouPage from '../UnsubscribeContainer/ThankyouPage';
import { LoginLogo } from '../LoginContainer/LoginContainer.styles';
import useQuery from '../../customHooks/useQuery';
import axios from 'axios';
import { apiDetails, objectToFormData } from '../../services/helpers';
import './UnsubscribeContainer.styles';
import { UnsubscribeCheckbox } from './UnsubscribeContainer.styles';

const UnsubscribeContainer = ({
  onSaveClick,
}) => {

  const [openThankyouPage, setOpenThankyouPage] = useState(false);
  const [postPayload, setPostPayload] = useState({
    cloutFeature: '',
    payload: '',
  });
  //const [loader, setLoader] = useState(false);
  const query = useQuery();
  const [generalNewsletters, setGeneralNewsletters] = useState(false);
  const [ecards, setEcards] = useState(false);
  const [firmUpdates, setFirmUpdates] = useState(false);
  const [events, setEvents] = useState(false);
  const [financialAndEconomicNewsletters, setFinancialAndEconomicNewsletters] = useState(false);
  const [digitalMarketing, setDigitalMarketing] = useState(false);

  useEffect(() => {
    let cloutFeature = query.get('clout_feature');
    let payload = query.get('payload');
    setPostPayload({
      cloutFeature,
      payload,
    });
  }, []);

  const handleSubmit = () => {
    axios.post(`${apiDetails.baseUrl}callback`, objectToFormData({
      payload: postPayload.payload,
      clout_feature: postPayload.cloutFeature,
      marketing_pref_settings: JSON.stringify({
        general_newsletters: generalNewsletters ? 'true' : 'false',
        ecards: ecards ? 'true' : 'false',
        firm_updates: firmUpdates ? 'true' : 'false',
        events: events ? 'true' : 'false',
        financial_and_economic_newsletters: financialAndEconomicNewsletters ? 'true' : 'false',
        general_marketing: digitalMarketing ? 'true' : 'false'
      })
    }), {
      headers: {
        "Content-type": "multipart/form-data"
      }
    }).then(res => {
      setOpenThankyouPage(true);
    }).catch(err => {
      console.log(err);
      console.log('error');
    })
  }
  
  return (
    <Flex
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Flex width="100%" justifyContent="center">
        <LoginLogo
          style={{ width: '200px', textAlign: 'left', marginLeft: '-28px'}}
          src="/assets/images/TIFIN-Clout-Logo-Color.png"
          alt="mio-logo"
        />
      </Flex>

      <hr
        style={{
          margin: '0',
          padding: '0',
          width: '100%',
          border: '1px solid #E4E7EC'
        }}
      />
      <div
        style={{
          color: '#101828',
          fontSize: '30px',
          fontWeight: '500',
          marginTop: '20px',
          padding: '10px 10px',
          lineHeight: '34px',
          width: '1136px',
          height: '34px',
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        Manage Your Mail Preferences
      </div>

      <hr
        style={{
          border: '1px solid #E4E7EC',
          width: '80%'
        }}
      />
      {!openThankyouPage && (
        <>
          <div
            style={{
              width: '100%',
              position: 'relative',
              height: '50px',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '25px',
              textAlign: 'center',
              color: '#475467',
              padding: '5px 15px',
              marginTop: '10px',
              marginBottom: '20px',
            }}
          >
            Simply check the communications you would like to stop receiving.
            <br />
            Click "Save" when you are finished. Your email: {query.get('email')}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                color: '#101828',
                paddingLeft: '20px',
                marginLeft: '40x',
                marginBottom: '15px',
                paddingRight: '146px',
              }}
            >
              <UnsubscribeCheckbox>
              <label className='container' style={{ padding: '10px 20px', marginLeft: '20px' }}>
                <input 
                  type="checkbox" 
                  checked={generalNewsletters}
                  onChange={() => setGeneralNewsletters(!generalNewsletters)}
                />
                <span style={{ marginTop: "12px" }} className="checkmark"></span>
                <label style={{ padding: '12px', }}>General Newsletters</label>
              </label>
              </UnsubscribeCheckbox>
              <UnsubscribeCheckbox>
              <label className='container' style={{ padding: '10px 20px', marginLeft: '205px' }}>
                <input 
                  style={{ marginRight: '8px' }} 
                  type="checkbox" 
                  checked={financialAndEconomicNewsletters}
                  onChange={() => setFinancialAndEconomicNewsletters(!financialAndEconomicNewsletters)}
                />
                <span style={{ marginTop: "12px" }} className="checkmark"></span>
                <label style={{ padding: '12px', }}>Financial  and Economic Insight Newsletters</label>
              </label>
              </UnsubscribeCheckbox>
              <UnsubscribeCheckbox>
              <label className='container' style={{ padding: '10px 20px', marginLeft: '-33px' }}>
                <input 
                style={{ marginRight: '8px' }} 
                type="checkbox" 
                checked={firmUpdates}
                onChange={() => setFirmUpdates(!firmUpdates)}
              />
              <span style={{ marginTop: "12px" }} className="checkmark"></span>
                <label style={{ padding: '12px', }}>Firm Updates</label>
              </label>
              </UnsubscribeCheckbox>
              <UnsubscribeCheckbox>
              <label className='container' style={{ padding: '10px 20px', marginLeft: '-85px' }}>
                <input 
                style={{ marginRight: '8px' }} 
                type="checkbox" 
                checked={ecards}
                onChange={() => setEcards(!ecards)}
              />
              <span style={{ marginTop: "12px" }} className="checkmark"></span>
                <label style={{ padding: '12px', }}>Ecards</label>
              </label>
              </UnsubscribeCheckbox>
              <UnsubscribeCheckbox>
              <label className='container' style={{ padding: '10px 20px', marginLeft: '-87px' }}>
                <input 
                style={{ marginRight: '8px' }} 
                type="checkbox" 
                checked={events}
                onChange={() => setEvents(!events)}
              />
              <span style={{ marginTop: "12px" }} className="checkmark"></span>
                <label style={{ padding: '12px', }}>Events</label>
              </label>
              </UnsubscribeCheckbox>
              <UnsubscribeCheckbox>
              <label className='container' style={{ padding: '10px 20px', marginLeft: '160px' }}>
                <input 
                style={{ marginRight: '8px' }} 
                type="checkbox" 
                checked={ digitalMarketing}
                onChange={() => setDigitalMarketing(!digitalMarketing)}
                />
                <span style={{ marginTop: "12px" }} className="checkmark"></span>
                <label style={{ padding: '12px', }}>All Digital Marketing Communications</label>
              </label>
              </UnsubscribeCheckbox>
            </div>
          </div>
          <div style={{ justifyContent: 'center', padding: '8px 16px' }}>
            {/* <LoaderWrapper isLoading={loader}></LoaderWrapper> */}
            <Button
              styles={{ borderRadius: '4px' }}
              type="button"
              onClick={() => {
                handleSubmit()
              }}
              disabled={!generalNewsletters && !ecards && !firmUpdates && !events && !financialAndEconomicNewsletters && !digitalMarketing}
            >
              Save & Update
            </Button>
          </div>
        </>
      )}
      
      {openThankyouPage && <ThankyouPage />}
    </Flex>
  );
};

export default UnsubscribeContainer;
