import styled from 'styled-components';
import {
  Table,
  TableHeading,
  Th,
  Td,
  TableBody,
  Tr,
} from '../../../components';

export const MioIconContentWrapper = styled.div`
  float: left;
  padding: 10px;
  color: #757575;
  font-size: 12px;
  img {
    width: 55px;
    height: 55px;
    border-radius: 8px;
  }
  .channel-name {
    font-weight: bold;
  }
`;

export const ContentSettingsTable = styled(Table)`
  border-radius: 7px;
  background-color: transparent;
`;

export const ContentSettingsTableHeading = styled(TableHeading)`
  background-color: #ececec;
  color: #6351ed;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border: 0px;
`;

export const ContentSettingsTableBody = styled(TableBody)`
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
`;

export const ContentSettingsTableHeadingTh = styled(Th)`
  padding: 10px 15px;
  color: #49484a;
  font-weight: 600;
  font-family: 'Poppins';
`;

export const ContentSettingsTableHeadingTd = styled(Td)`
  padding: 10px 15px;
`;

export const ContentSettingsTableHeadingTr = styled(Tr)`
  border-bottom: 1px solid #e5e5e5;
  &:nth-child(odd) {
    background-color: white;
    .table-cell {
      &:nth-child(odd) {
      }
      &:nth-child(even) {
        background-color: white;
      }
    }
  }

  &:nth-child(even) {
    background-color: #d7d7d7;
    .table-cell {
      &:nth-child(odd) {
      }
      &:nth-child(even) {
        background-color: #cccccc;
      }
    }
  }
`;
