import React from 'react';
import { Flex, Box } from '@rebass/grid';
import { isEmpty, toLower } from 'lodash';
import { ButtonNoBorderBackground, ResponsiveImg } from '../';

import { FontAwesome, ImageSpan, AdminCheckBox, GrayCheckBox } from './SocialCheckBoxes.styles';

const SocialCheckBoxes = ({ buttons, small, pointer, onCheckBoxClick }) => {
  const handleCheckBoxClick = (e, platform) => {
    e.stopPropagation();
    onCheckBoxClick(platform);
  };

  if (!isEmpty(buttons)) {
    return (
      <Flex
        width={1}
        justifyContent={small ? 'center' : 'space-between'}
        alignItems="center"
      >
        {buttons.map((button) => {
          return (
            <Box title={button.name}  key={button.name} width={1} is="a">
              <ButtonNoBorderBackground margin={button.margin} width={button.width} padding={button.padding} onClick={button.onClick} type="button">
                {button.faIcon && (
                  <FontAwesome
                    icon={button.faIcon}
                    small={small}
                    pointer={pointer}
                    isDiconnected={button.isDiconnected}
                  />
                )}

                {button.imgUrl && (
                  <ImageSpan
                    iconHeight={button.iconHeight}
                    isDiconnected={button.isDiconnected}
                  >
                    <ResponsiveImg src={button.imgUrl} small={small} />
                  </ImageSpan>
                )}
                {button.bottomText && (
                  <div>
                    <ButtonNoBorderBackground width={button.width} padding={button.padding} disabled={button.type === 'discover' ? button.isDiconnected : false}>
                      <GrayCheckBox type={button.type} bgColor={button.bgColor} checked={button.checked}>
                        <AdminCheckBox
                          id={button.name}
                          type="checkbox"
                          className={button.checked ? 'checked' : ''}
                          checked={button.checked}
                          onChange={(e) => {
                            handleCheckBoxClick(e, button.platform)
                          }}
                          bgColor={button.bgColor}
                          style={{cursor: 'pointer', borderRadius: '4px'}}
                          disabled={button.type === 'discover' ? button.isDiconnected : false}
                        />
                        <label style={{fontFamily: 'Poppins', fontSize: button.type === 'discover' ? '12px' : '', color: button.checked ? '#6351ed' : '#707070'}}>{button.bottomText}</label>
                      </GrayCheckBox>
                    </ButtonNoBorderBackground>
                  </div>
                )}
              </ButtonNoBorderBackground>
            </Box>
          );
        })}
      </Flex>
    );
  }
  return null;
};

export default SocialCheckBoxes;