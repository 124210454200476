import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty, get, each, map } from 'lodash';
import { Flex, Box } from '@rebass/grid';
import {
  Tabs,
  TabList,
  TabTypeThree,
  LoaderWrapper,
  ButtonNoBorderBackground,
  Loader,
} from '../../components';
import Topics from './Topics';
import ExploreTab from './ExploreTab';
import TrendingTab from './TrendingTab';
import ContentLibraryTab from './ContentLibraryTab';
import AddContent from './AddContent';
import SearchResultsTab from './SearchResultsTab';
import InvestmentsTab from './InvestmentsTab';
import BookmarksTab from './BookmarksTab/BookmarksTab';
import RecentSharedTab from './RecentSharedTab';
import ScheduledTab from './ScheduledTab';
import Discover from './Discover';
import FeaturedPosts from './FeaturedPosts';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getPopularTheme,
  geYourTheme,
  getPremiumAdvisorChannelId,
  getSponsorList,
} from '../../services/dashboard';
import { getUsersList } from '../../services/analytics';
import { getContactsGroups } from '../../services/configure';

import {
  emptyMessages,
  USER_ROLE,
  ROLE_TYPES,
  company,
  getSpecificThemeItem,
  sponsoredArticleData,
} from '../../utils';
import { actions as inviteActions } from '../../redux/modules/invite.module';
import {
  actions as exploreActions,
  moreActions as exploreMoreActions,
} from '../../redux/modules/explore-tab.module';
import { actions as advisorGroupsActions } from '../../redux/modules/advisor-group.module';
import {
  actions as contentlibActions,
  moreActions as contentlibMoreActions,
} from '../../redux/modules/content-lib.module';
import { actions as analyticsActions } from '../../redux/modules/analytics.module';
import { actions as loginActions } from '../../redux/modules/login.module';
import { actions as themeActions } from '../../redux/modules/theme.module';
import { actions as channelActions } from '../../redux/modules/channel.module';
import { actions as shareActions } from '../../redux/modules/share.module';
import { moreActions as newsletterMoreActions } from '../../redux/modules/newsletter.module';
import { getChannelId, getUserRole, getRoleType, getFirmSettings } from '../../redux/selector';

import {
  StyledTabPanel,
  DashboardAdminSubTab,
  NoContentWrapper,
  AbsoluteLoader,
  ExploreRelativeDiv,
  StyledTooltip,
  HoldingsIcon,
  HoldingsWrapper,
  BorderWhiteBox,
  StyledTabPanelTwo,
} from './DashboardContainer.styles';
import MioEditor from './MIOEditor/MioEditor';
import MioEditorTab from './MIOEditor/MioEditorTab';
import SponsoredModal from './SponsoredModal/SponsoredModal';
import useQuery from '../../customHooks/useQuery';
import MyClientsTab from './MyClientsTab/MyClientsTab';
import RecommendedTab from './RecommendedTab';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import ExportPDF from '../ExportPDFContainer/ExportPDF';
import { ExportPDFButton } from '../ExportPDFContainer/ExportPDF.styles';

const PremiumAdvisorDashboardContainer = ({
  exploreArticleRequest,
  advisorGroupsRequest,
  groupsList,
  exploreArticles,
  deleteArticleRequest,
  inviteRequest,
  contentlibRequest,
  contentlibDeleteRequest,
  contentLibArticles,
  analyticsRequest,
  analyticsData,
  isAnalyticsDataFetching,
  groupsListFetching,
  exploreArticlesFetching,
  contentLibArticlesFetching,
  analyticsObject,
  selectedRepType,
  searchResults,
  themeNames,
  exploreArticleDeleting,
  contentLibArticleDeleting,
  inviteSending,
  inviteError,
  channelId,
  themesList,
  investmentResults,
  isShareBlocked,
  isDeleteBlocked,
  isAdmin,
  tickerName,
  isTicker,
  exploreSetState,
  isInvEmpty,
  selectedTickers,
  contentlibSetState,
  userData,
  loginRequest,
  imageUpload,
  portfolioId,
  roleType,
  searchFilters,
  themeSetState,
  yourTopics,
  isComplianceAdmin,
  isEnterpriseAdmin,
  myMioData,
  currentPortfolio,
  loginSetState,
  isPremiumAdvisor,
  channelData,
  holdingsType,
  channelSetState,
  handleModalOpen,
  exploreArticleError,
  shareArticleSetState,
  isChangeTheme,
  addToBucketRequest,
  removeFromNewsletterRequest,
  addToBookmarksRequest,
  removeFromBookmarksRequest,
  newsletterArticleFetching,
  bookmarksFetching,
  contentLibAddToBucketRequest,
  contentLibRemoveFromBucketRequest,
  contentLibNewsletterFetching,
  exploreNewsletterId,
  bookmarksId,
  contentLibAddToBookmarksRequest,
  contentLibRemoveFromBookmarksRequest,
  contentLibBookmarksFetching,
  contentLibBookmarksId,
  bucketCountRequest,
  contentLibBucketId,
  contentLibATSQueue,
  contentLibInNewsletter,
  contentLibBookMarkId,
  contentLibIsBookmark,
  topicDetails,
  contentLibFilter,
}) => {
  // console.log('[PREMIUM ADVISOR] rendering...');
  const history = useHistory();
  const [showInvestmentTab, setShowInvestmentTab] = useState(false);
  const [localContent, setLocalContent] = useState([]);
  const [mioContent, setMioContent] = useState([]);
  const [showSearchTab, setShowSearchTab] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedTabIndex, setTabIndex] = useState(0);
  const [trendingTopicDetails, setTrendingTopicDetails] = useState({});
  const [popularTopicDetails, setPopularTopicDetails] = useState({});
  const [clientTopics, setClientTopics] = useState({
    id: 'Qb7gwp7r',
    topicName: '3d Printing',
  });
  const [subscribedTopicDetails, setSubscribedTopicDetails] = useState({});
  const [investmentTopic, setInvestmentTopic] = useState('');
  const [selectedExploreTabIndex, setSelectedExploreTabIndex] = useState(3);
  const [selectedRecentTabIndex, setSelectedRecentTabIndex] = useState(0);
  const [selectedScheduledTabIndex, setSelectedScheduledTabIndex] = useState(0);
  const [selectedContentTabIndex, setSelectedContentTabIndex] = useState(0);
  const [isSearchFilters, setIsSearchFilters] = useState(true);
  const [mostSubscribed, setMostSubscribed] = useState([]);
  const [showArticleLoader, setShowArticleLoader] = useState(true);
  const [widgetTabIndes, setWdigetTabIndex] = useState(1);
  const [discoverTabIndex, setDiscoverTabIndex] = useState(0);
  const [premiumAdvisorChannelId, setPremiumAdvisorChannelId] = useState('');
  const [isFilteringStocksByTicker, setIsFilteringStocksByTicker] =
    useState(false);
  const [curatedTopicType, setCuratedTopicType] = useState('all');
  const [thirdPartyThemeId, setThirdPartyThemeId] = useState('');
  const [clearEditorArticles, setClearEditorArticles] = useState(false);
  const [filteredSearchEmpty, setFilteredSearchEmpty] = useState(false);
  const [clientShareBlocked, setClientShareBlocked] = useState(false);
  const [clientDetails, setClientDetails] = useState({
    id: 'SECURITY',
    contactName: 'positivly_security',
  });
  const [searchOptions, setSearchOptions] = useState([
    { platform: 'all', checked: true, isDisconnected: false },
    { platform: 'my_content', checked: true, isDisconnected: false },
    { platform: 'editor_content', checked: true, isDisconnected: false },
  ]);
  const [popularThemes, setPopularThemes] = useState([]);
  const [mostSubscribedThemes, setMostSubscribedThemes] = useState([]);
  const [openSponsoredModal, setOpenSponsoredModal] = useState(false);
  const [sponsoredArticles, setSponsoredArticles] = useState([]);
  const [showClientDropDown, setShowClientDropDown] = useState(true);
  const [selectedMyClientsDropdownOption, setSelectedMyClientsDropdownOption] =
    useState('');
  const [selectedCRMContactList, setSelectedCRMContactList] = useState({
    id: '',
    name: '',
    themes: [],
    recommendedThemes: [],
    userThemes: [],
    disabledThemes: [],
  });
  const [crmLoader, setCrmLoader] = useState(false);
  const [myClientsLocalArticles, setMyClientsLocalArticles] = useState([]);
  const [contactList, setContactList] = useState([]);
  const themeFromInvestment = investmentTopic
    ? getSpecificThemeItem(themesList, investmentTopic)
    : [];
  const query = useQuery();
  const [exploreTabDetails, setExploreTabDetails] = useState({});
  const [isExportPdfOpen, setIsExportPdfOpen] = useState({
    isSharedArticle: false,
    isRecentArticle: false,
  });

  const exportPdfHandler = (name, isOpen) => {
    setIsExportPdfOpen({ ...isExportPdfOpen, [name]: isOpen });
  };

  useEffect(() => {
    const excludeEmailList = [
      'GMAIL',
      'HUBSPOT',
      'REDTAIL',
      'OUTLOOK',
      'WEALTHBOX',
      'SALESFORCE',
    ];
    if (query.get('tab') && query.get('tab') === 'holdings') {
      onWidgetTabChange(2);
    }
    if (
      sessionStorage.getItem('tab') &&
      sessionStorage.getItem('tab') === 'my-editor'
    ) {
      onWidgetTabChange(3);
    }
    if (isPremiumAdvisor) {
      getPremiumAdvisorChannelId()
        .then(({ result }) => {
          let channelId = '';
          each(result.data, (value, key) => {
            channelId =
              result.data[key] && result.data[key].length > 0
                ? result.data[key][0].id
                : '';
          });
          setPremiumAdvisorChannelId(channelId);
          channelSetState({ premiumAdvisorChannelId: channelId });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    geYourTheme().then((response) => {
      const yourThemeResponse = get(response, 'result.data', []).filter(
        (theme) =>
          get(theme, 'subscription.status.application_status') === 'approved'
      );
      setPopularThemes(yourThemeResponse);
      themeSetState({ popularThemes: yourThemeResponse });
    });

    getPopularTheme().then((response) => {
      setMostSubscribedThemes(get(response, 'result.data', []));
      let themeResponse = get(response, 'result.data', []);
      if (themeResponse) themeResponse = themeResponse.map(({ name }) => name);
      setMostSubscribed(themeResponse);
    });

    getSponsorList().then((response) => {
      setSponsoredArticles(
        sponsoredArticleData(get(response, 'result.data', []))
      );
    });
    if (
      imageUpload ||
      (window.localStorage.getItem('ImageUpload') &&
        window.localStorage.getItem('ImageUpload') === 'true')
    ) {
      setTabIndex(0);
      setSelectedExploreTabIndex(4);
      exploreSetState({data: {}});
      exploreArticleRequest({
        params: {
          id: popularTopicDetails.id,
          source: 'mioEditorResult',
          date: '',
          mode: '',
          token: '',
          holdingsType: '',
          thirdPartyThemeId: thirdPartyThemeId,
        },
      });
    }
    getContactsGroups()
      .then((res) => {
        const contact_list = res.result.data.filter(
          (contact) => !excludeEmailList.includes(contact.name)
        );
        setContactList(contact_list);
        let count = res.result.data.filter((contact) =>
          contact.name.includes('positivly_')
        );
        if (count.length === 0) {
          setClientShareBlocked(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (popularThemes?.length === 0) {
      onExploreTabClick(1);
    }
  }, [popularThemes]);

  useEffect(() => {
    if (!isEmpty(exploreArticleError)) {
      setShowArticleLoader(false);
    }
  }, [exploreArticleError]);

  useEffect(() => {
    if ([...popularThemes, ...themeFromInvestment].length > 0) {
      const themes = map([...popularThemes, ...themeFromInvestment], 'name');
      themeSetState({ yourTopics: themes.map((theme) => theme.toUpperCase()) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popularThemes, investmentTopic]);

  useEffect(() => {
    if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 3 &&
      !_.isEmpty(exploreArticles) &&
      !_.isEmpty(exploreArticles.details)
    ) {
      let exploreData = {};
      exploreArticles?.details?.theme &&
        Object.keys(exploreArticles?.details?.theme).forEach((themeId) => {
          exploreArticles.feed.forEach((feed) => {
            const topicName = exploreArticles?.details.theme[themeId].name;
            if (feed.ids?.theme[0] === themeId) {
              if (exploreData[topicName]) {
                exploreData[topicName].feed.push(feed);
              } else {
                exploreData[topicName] = {
                  details: exploreArticles.details,
                  feed: [feed],
                };
              }
            }
          });
        });

      setExploreTabDetails(exploreData);
      setShowArticleLoader(false);
    }
  }, [exploreArticles]);

  useEffect(() => {
    if (!popularTopicDetails.id) {
      setExploreTabDetails({});
    }
  }, [popularTopicDetails]);

  const onDiscoverTabChange = (index) => {
    // console.log('onDiscoverTabChange')
    //setPopularTopicDetails({});
    //setTabIndex(0);
    if (index === 0) {
      setTabIndex(0);
      // setPopularTopicDetails({});
      onExploreTabSelect(1);
    } else if (index === 1) {
    } else if (index === 2 && discoverTabIndex !== index) {
      setTabIndex(0);
      setPopularTopicDetails({});
      setSelectedExploreTabIndex(1);
      exploreArticleRequest({
        params: {
          id: popularTopicDetails.id,
          source: 'explore',
        },
      });
    } else if (discoverTabIndex === index && !isEmpty(mostSubscribedThemes)) {
      setTabIndex(0);
      setSelectedExploreTabIndex(1);
      const initialIndex = Math.floor(mostSubscribedThemes.length / 2);
      const { id, description } = get(
        mostSubscribedThemes,
        `[${initialIndex}]`,
        {}
      );
      setPopularTopicDetails({
        id,
        topicName: description,
      });
      exploreArticleRequest({
        params: {
          id: id,
          source: 'explore',
        },
      });
    }
    setDiscoverTabIndex(index);
    // setWdigetTabIndex(index);
  };

  const checkedStatus = (platform) => {
    const platformDetails = searchOptions.find((c) => c.platform === platform);
    // console.log('[PLATFORM DETAILS] - ', platformDetails)
    // console.log('[search options] - ', searchOptions)
    return {
      isDiconnected: platformDetails.isDisconnected,
      checked: platformDetails.checked,
    };
  };

  const onCheckBoxClick = (platform) => {
    let searchOptionsCopy = [...searchOptions];
    if (platform === 'all') {
      if (!searchOptionsCopy[0].checked)
        searchOptionsCopy.forEach((option) => (option.checked = true));
      else searchOptionsCopy.forEach((option) => (option.checked = false));
    } else {
      searchOptionsCopy.every((option) => {
        if (option.platform === platform) {
          if (option.checked) searchOptionsCopy[0].checked = false;
          option.checked = !option.checked;
        }
        return option.platform !== platform;
      });
    }
    // searchOptions.forEach((item, index) => {
    //   if (item.platform === platform) {
    //     item.checked = !item.checked;
    //   }
    // });
    // const checkAllOption = searchOptions.find(s => s.platform === "all").checked;
    // if (checkAllOption) {
    //   searchOptions.forEach((item, index) => {
    //     if (item.platform !== "all") {
    //       item.checked = false;
    //       item.isDisconnected = true;
    //     }
    //   });
    // } else {
    //   searchOptions.forEach((item, index) => {
    //     item.isDisconnected = false;
    //   });
    // }
    setSearchOptions(searchOptionsCopy);
    checkedStatus(platform);
  };

  const onMyMioTabClick = () => {
    console.log('onMyMioTabClick');
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'mymio',
        date: curatedTopicType,
      },
    });
  };
  useEffect(() => {
    exploreSetState({ holdingsType: 'themes' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 1) {
      if (!isEmpty(trendingTopicDetails)) {
        setShowArticleLoader(true);
        onTrendingTabClick();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendingTopicDetails]);

  useEffect(() => {
    // if(isAdmin){
    getUsersList('all').then((data) => {
      const membersList = get(data, 'result.data.users', []);
      loginSetState({ membersList });
    });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  const onSearchClick = () => {
    setShowArticleLoader(true);
    setTabIndex(0);
    if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true'
    )
      setSelectedExploreTabIndex(7);
    else setSelectedExploreTabIndex(6);
    exploreArticleRequest({
      params: {
        id: searchValue,
        source: 'search',
        date: searchOptions,
      },
    });
    setShowSearchTab(true);
  };

  const onSubscribeTabClick = (topic) => {
    setShowArticleLoader(true);
    if (isComplianceAdmin) {
      setSelectedExploreTabIndex(1);
    }
    exploreArticleRequest({
      params: {
        id: topic && topic.length > 0 ? topic : subscribedTopicDetails.id,
        source: 'explore',
      },
    });
  };

  const onExploreTabClick = (topic) => {
    setShowArticleLoader(true);
    // console.log('onExploreTabClick')
    // console.log(popularTopicDetails.id)

    exploreArticleRequest({
      params: {
        id: topic && topic.length > 0 ? topic : popularTopicDetails.id,
        source: 'explore',
      },
    });
  };

  const onTrendingTabClick = () => {
    // console.log('onTrendingTabClick')
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: trendingTopicDetails.id,
        date: trendingTopicDetails.date,
        source: 'trending',
      },
    });
  };

  const onDeleteArticleClick = (id) => {
    let params = {};
    setShowArticleLoader(true);
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
      params = {
        deleteId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      params = {
        source: 'recommended',
      };
    }
    // else if (selectedTabIndex === 0 && selectedExploreTabIndex === 5) {
    //   params = {
    //     deleteId: id,
    //     id: trendingTopicDetails.id,
    //     source: 'mymio',
    //     date: trendingTopicDetails.date,
    //   };
    // }
    else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 0
    ) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'trending',
        date: trendingTopicDetails.date,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 2
    ) {
      params = {
        deleteId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 2 && selectedScheduledTabIndex === 1) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'recommendation_engine',
      };
    }
    // else if (selectedTabIndex === 2 && selectedScheduledTabIndex === 1) {
    //   params = {
    //     deleteId: id,
    //     id: trendingTopicDetails.id,
    //     source: 'scheduled',
    //     date: trendingTopicDetails.date,
    //     type: 'admin'
    //   };
    // }
    else if (selectedTabIndex === 2 && selectedScheduledTabIndex === 0) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'user',
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 7
    ) {
      params = {
        deleteId: id,
        id: searchValue,
        source: 'search',
        date: searchOptions,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 6
    ) {
      params = {
        deleteId: id,
        id: searchValue,
        source: 'search',
        date: searchOptions,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      params = {
        deleteId: id,
        id: currentPortfolio,
        source: 'investments',
        date: trendingTopicDetails.date,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 6
    ) {
      params = {
        deleteId: id,
        id: clientDetails.id,
        source: 'myClients',
        contactName: clientDetails.contactName,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 5
    ) {
      params = {
        deleteId: id,
        id: clientDetails.id,
        source: 'myClients',
        contactName: clientDetails.contactName,
      };
    }

    deleteArticleRequest({ params });
  };

  const onNewsletterArticleClick = (id, themeId, source) => {
    let params = {};
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
      params = {
        articleId: id,
        themeId: themeId,
        newsletterId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      params = {
        source: 'recommended',
        articleId: id,
        themeId: themeId,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 0
    ) {
      params = {
        articleId: id,
        themeId: themeId,
        newsletterId: id,
        id: trendingTopicDetails.id,
        source: 'trending',
        date: trendingTopicDetails.date,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 2
    ) {
      params = {
        articleId: id,
        themeId: themeId,
        newsletterId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 7
    ) {
      params = {
        articleId: id,
        themeId: themeId,
        id: searchValue,
        newsletterId: id,
        source: 'search',
        date: searchOptions,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 6
    ) {
      params = {
        articleId: id,
        themeId: themeId,
        id: searchValue,
        newsletterId: id,
        source: 'search',
        date: searchOptions,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      params = {
        articleId: id,
        themeId: themeId,
        id: currentPortfolio,
        newsletterId: id,
        source: 'investments',
        date: trendingTopicDetails.date,
        holdingsType: holdingsType,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 5
    ) {
      if (selectedMyClientsDropdownOption === 'financial') {
        params = {
          articleId: id,
          themeId: themeId,
          id: clientDetails.id,
          newsletterId: id,
          source: 'myClients',
          contactName: clientDetails.contactName,
        };
      } else {
        params = {
          articleId: id,
          themeId: themeId,
          id: clientDetails.id,
          newsletterId: id,
          source: 'crmClients',
          themesList: getCRMThemeIds(),
        };
      }
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 6
    ) {
      if (selectedMyClientsDropdownOption === 'financial') {
        params = {
          articleId: id,
          themeId: themeId,
          id: clientDetails.id,
          newsletterId: id,
          source: 'myClients',
          contactName: clientDetails.contactName,
        };
      } else {
        params = {
          articleId: id,
          themeId: themeId,
          id: clientDetails.id,
          newsletterId: id,
          source: 'crmClients',
          themesList: getCRMThemeIds(),
        };
      }
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 7 &&
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false'
    ) {
      params = {
        source: 'bookmarks',
        themeId: themeId,
        articleId: id,
        newsletterId: id,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 8) {
      params = {
        source: 'bookmarks',
        themeId: themeId,
        articleId: id,
        newsletterId: id,
      };
    }

    if (source === 'newsLetter') {
      console.log('SOURCE NEWSLETTER');
      addToBucketRequest({ params });
    } else {
      console.log('SOURCE BOOKMARKS');
      addToBookmarksRequest({ params });
    }
  };

  const onRemoveFromNewletterClick = (id, articleId, source) => {
    let params = {};
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
      params = {
        articleId: id,
        id: popularTopicDetails.id,
        newsletterId: articleId,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      params = {
        source: 'recommended',
        articleId: id,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 0
    ) {
      params = {
        articleId: id,
        id: trendingTopicDetails.id,
        source: 'trending',
        newsletterId: articleId,
        date: trendingTopicDetails.date,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 2
    ) {
      params = {
        articleId: id,
        id: popularTopicDetails.id,
        newsletterId: articleId,
        source: 'explore',
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 7
    ) {
      params = {
        articleId: id,
        id: searchValue,
        source: 'search',
        newsletterId: articleId,
        date: searchOptions,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 6
    ) {
      params = {
        articleId: id,
        id: searchValue,
        source: 'search',
        newsletterId: articleId,
        date: searchOptions,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      params = {
        articleId: id,
        id: currentPortfolio,
        source: 'investments',
        newsletterId: articleId,
        date: trendingTopicDetails.date,
        holdingsType: holdingsType,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 5
    ) {
      if (selectedMyClientsDropdownOption === 'financial') {
        params = {
          articleId: id,
          id: clientDetails.id,
          source: 'myClients',
          newsletterId: articleId,
          contactName: clientDetails.contactName,
        };
      } else {
        params = {
          articleId: id,
          id: clientDetails.id,
          source: 'crmClients',
          newsletterId: articleId,
          themesList: getCRMThemeIds(),
        };
      }
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 6
    ) {
      if (selectedMyClientsDropdownOption === 'financial') {
        params = {
          articleId: id,
          id: clientDetails.id,
          source: 'myClients',
          newsletterId: articleId,
          contactName: clientDetails.contactName,
        };
      } else {
        params = {
          articleId: id,
          id: clientDetails.id,
          source: 'crmClients',
          newsletterId: articleId,
          themesList: getCRMThemeIds(),
        };
      }
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 7 &&
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false'
    ) {
      params = {
        source: 'bookmarks',
        articleId: id,
        newsletterId: articleId,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 8) {
      params = {
        source: 'bookmarks',
        articleId: id,
        newsletterId: articleId,
      };
    }
    console.log('PARAMS - ', params);
    if (source === 'newsLetter') removeFromNewsletterRequest({ params });
    else removeFromBookmarksRequest({ params });
  };

  const onRecentTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'recent',
      },
    });
  };

  const onRecentLastmonthTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'recent',
        type: 'last_month',
      },
    });
  };

  const onScheduledTabClick = () => {
    console.log('onScheduledTabClick');
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(1);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'recommendation_engine',
      },
    });
  };

  const onScheduledBySelfTabClick = () => {
    console.log('onScheduledBySelfTabClick');
    setSelectedScheduledTabIndex(0);
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'user',
      },
    });
  };

  const onTabSelect = (index) => {
    console.log('onTabSelect');
    setShowArticleLoader(true);
    contentlibSetState({ data: {} });
    setTabIndex(index);
    if (index === 3) {
      setCuratedTopicType('all');
      contentlibSetState({ data: {} });
    }
  };

  const onExploreTabSelect = (index, topic) => {
    if (index === 2) {
      // contentlibRequest({ type: curatedTopicType });
      // onMyMioTabClick();
      onWidgetTabChange(0);
      setShowArticleLoader(true);
    } else if (index === 3) {
      setShowArticleLoader(true);
      onWidgetTabChange(1);
    } else if (index === 0) {
      setShowArticleLoader(true);
      exploreArticleRequest({
        params: {
          source: 'recommended',
        },
      });
    } else if (index === 4) {
      setShowArticleLoader(true);
      onInvestmentClick();
      onWidgetTabChange(2);
    } else if (index === 5) {
      if (
        userData.details &&
        userData.details.user.turnkey &&
        userData.details.user.turnkey === 'true'
      ) {
        setThirdPartyThemeId('');
        setWdigetTabIndex(3);
        exploreSetState({data: {}});
        exploreArticleRequest({
          params: {
            id: popularTopicDetails.id,
            source: 'mioEditorResult',
            date: '',
            mode: '',
            token: '',
            holdingsType: '',
            thirdPartyThemeId: '',
          },
        });
        sessionStorage.removeItem('tab');
      } else {
        if (
          selectedExploreTabIndex !== 0 &&
          selectedExploreTabIndex !== 1 &&
          selectedExploreTabIndex !== 7
        ) {
          setShowArticleLoader(false);
          console.log(
            'SELECTED EXPLORE TAB INDEX ---- ',
            selectedExploreTabIndex,
            ' ------- dropdown option -- ',
            selectedMyClientsDropdownOption
          );
          setSelectedMyClientsDropdownOption('');
          setShowClientDropDown(true);
        } else {
          if (selectedMyClientsDropdownOption === 'financial') {
            setShowArticleLoader(true);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'myClients',
                id: clientDetails.id,
              },
            });
          } else if (selectedMyClientsDropdownOption === 'crm') {
            setShowArticleLoader(true);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'crmClients',
                themesList:
                  selectedCRMContactList.themes.length > 0
                    ? selectedCRMContactList.themes.map(
                        (theme) => theme.id_hash
                      )
                    : [],
              },
            });
          } else {
            setShowArticleLoader(false);
          }
        }
        // exploreArticleRequest({
        //   params: {
        //     id: 'SECURITY',
        //     source: 'myClients',
        //   },
        // });
      }
      // setShowArticleLoader(true);
      onWidgetTabChange(3);
    } else if (index === 1) {
      setShowArticleLoader(true);
      setDiscoverTabIndex(0);
      exploreArticleRequest({
        params: {
          id: trendingTopicDetails.id,
          date: trendingTopicDetails.date,
          source: 'trending',
        },
      });
    } else if (index === 6) {
      if (
        userData.details &&
        userData.details.user.turnkey &&
        userData.details.user.turnkey === 'true'
      ) {
        if (
          selectedExploreTabIndex !== 0 &&
          selectedExploreTabIndex !== 1 &&
          selectedExploreTabIndex !== 8
        ) {
          // console.log('[EXPLORE ARTICLE REQUEST Id] - ', clientTopics.id);
          setShowArticleLoader(false);
          setSelectedMyClientsDropdownOption('');
          setShowClientDropDown(true);
        }
        if (selectedMyClientsDropdownOption === 'financial') {
          setShowArticleLoader(true);
          setCrmLoader(false);
          exploreArticleRequest({
            params: {
              source: 'myClients',
              id: clientDetails.id,
            },
          });
        } else if (selectedMyClientsDropdownOption === 'crm') {
          setShowArticleLoader(true);
          setCrmLoader(false);
          exploreArticleRequest({
            params: {
              source: 'crmClients',
              themesList:
                selectedCRMContactList.themes.length > 0
                  ? selectedCRMContactList.themes.map((theme) => theme.id_hash)
                  : [],
            },
          });
        } else {
          setShowArticleLoader(false);
        }
        // exploreArticleRequest({
        //   params: {
        //     id: 'SECURITY',
        //     source: 'myClients',
        //   },
        // });
      } else {
        setSearchOptions([
          { platform: 'all', checked: true, isDisconnected: false },
          { platform: 'my_content', checked: true, isDisconnected: false },
          { platform: 'editor_content', checked: true, isDisconnected: false },
        ]);
        setSearchValue('');
        exploreArticleRequest({
          params: {
            source: 'search',
          },
        });
      }
      onWidgetTabChange(4);
    } else if (index === 7) {
      setShowArticleLoader(true);
      if (
        userData.details &&
        userData.details.user.turnkey &&
        userData.details.user.turnkey === 'true'
      ) {
        setSearchValue('');
        exploreArticleRequest({
          params: {
            source: 'search',
          },
        });
        onWidgetTabChange(5);
      } else {
        console.log('index 7');
        exploreArticleRequest({
          params: {
            source: 'bookmarks',
          },
        });
      }
    } else if (index === 8) {
      console.log('index 7');
      exploreArticleRequest({
        params: {
          source: 'bookmarks',
        },
      });
    }
    setSelectedExploreTabIndex(index);
  };

  const onRecentTabSelect = (index) => {
    console.log('onRecentTabSelect');
    setShowArticleLoader(true);
    setSelectedRecentTabIndex(index);
  };

  const onScheduledTabSelect = (index) => {
    console.log('onScheduledTabSelect', index);
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(index);
  };

  const onContentTabSelect = (index) => {
    setShowArticleLoader(true);

    if (selectedExploreTabIndex === 1) {
      contentlibSetState({ data: {} });
    } else if (selectedExploreTabIndex === 2) {
      console.log('check');
      exploreArticleRequest({
        params: {
          id: popularTopicDetails.id,
          source: 'explore',
        },
      });
    } else if (selectedExploreTabIndex === 3) {
      onInvestmentClick();
      onWidgetTabChange(2);
    } else if (selectedExploreTabIndex === 0) {
      exploreArticleRequest({
        params: {
          id: trendingTopicDetails.id,
          date: trendingTopicDetails.date,
          source: 'trending',
        },
      });
    } else if (selectedExploreTabIndex === 4) {
      onWidgetTabChange(3);
    } else if (selectedExploreTabIndex === 5) onWidgetTabChange(4);
  };

  const onInvestmentClick = (id, holdingsType = '') => {
    console.log('onInvestmentClick');
    setTabIndex(0);
    onWidgetTabChange(2);
    setSelectedExploreTabIndex(4);
    setIsFilteringStocksByTicker(false);
    if (id && id.length > 0) {
      exploreArticleRequest({
        params: {
          id,
          source: 'investments',
          holdingsType,
        },
      });
    }
    setShowInvestmentTab(true);
  };

  const handleTickerChange = (ticker) => {
    console.log('handleTickerChange');
    setIsFilteringStocksByTicker(holdingsType === 'stocks' ? true : false);
    exploreSetState({ ticker });
  };

  const handleTopicChange = (topic) => {
    console.log('handleTopicChange');
    setShowArticleLoader(true);
    setTabIndex(0);
    setInvestmentTopic(topic);
    onExploreTabClick(topic);
  };

  const onWidgetTabChange = (index) => {
    setThirdPartyThemeId('');
    if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true'
    ) {
      if (index !== 4 && !showClientDropDown) {
        setShowClientDropDown(true);
      }
    } else if (index !== 3 && !showClientDropDown) {
      setShowClientDropDown(true);
    }
    contentlibSetState({ data: {} });
    setTabIndex(0);
    if (index === 1 && discoverTabIndex !== 2) {
      setSelectedExploreTabIndex(3);
      onExploreTabClick(1);
    } else if (
      index === 1 &&
      discoverTabIndex === 2 &&
      !isEmpty(popularThemes)
    ) {
      setSelectedExploreTabIndex(3);
      const initialIndex = Math.floor(popularThemes.length / 2);
      const { id, description } = get(popularThemes, `[${initialIndex}]`, {});
      setPopularTopicDetails({
        id,
        topicName: description,
      });
      exploreArticleRequest({
        params: {
          id: id,
          source: 'explore',
        },
      });
    } else if (
      index === 1 &&
      discoverTabIndex === 2 &&
      isEmpty(popularThemes)
    ) {
      setShowArticleLoader(true);
      exploreArticleRequest({
        params: {
          id: topicDetails.id,
          source: 'explore',
        },
      });
      setSelectedExploreTabIndex(3);
    } else if (index === 0) {
      setCuratedTopicType('all');
      // contentlibRequest({ type: 'all' });
      setSelectedExploreTabIndex(2);
    } else if (index === 3) {
      if (
        userData.details &&
        userData.details.user.turnkey &&
        userData.details.user.turnkey === 'true'
      ) {
        setThirdPartyThemeId('');
        exploreSetState({data: {}});
        exploreArticleRequest({
          params: {
            id: popularTopicDetails.id,
            source: 'mioEditorResult',
            date: '',
            mode: '',
            token: '',
            holdingsType: '',
            thirdPartyThemeId: '',
          },
        });
        sessionStorage.removeItem('tab');
      } else {
        if (widgetTabIndes !== 3) {
          setShowArticleLoader(false);
          setSelectedMyClientsDropdownOption('');
          setShowClientDropDown(true);
        } else {
          if (selectedMyClientsDropdownOption === 'financial') {
            setShowArticleLoader(true);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'myClients',
                id: clientDetails.id,
              },
            });
          } else if (selectedMyClientsDropdownOption === 'crm') {
            setShowArticleLoader(true);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'crmClients',
                themesList:
                  selectedCRMContactList.themes.length > 0
                    ? selectedCRMContactList.themes.map(
                        (theme) => theme.id_hash
                      )
                    : [],
              },
            });
          } else {
            setShowArticleLoader(false);
          }
        }
        // exploreArticleRequest({
        //   params: {
        //     id: 'SECURITY',
        //     source: 'myClients',
        //   },
        // });
      }
      setSelectedExploreTabIndex(5);
      // onExploreTabSelect(4);
    } else if (index === 4) {
      if (
        userData.details &&
        userData.details.user.turnkey &&
        userData.details.user.turnkey === 'true'
      ) {
        if (widgetTabIndes !== 4) {
          setShowArticleLoader(false);
          setSelectedMyClientsDropdownOption('');
          setShowClientDropDown(true);
        } else {
          if (selectedMyClientsDropdownOption === 'financial') {
            setShowArticleLoader(true);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'myClients',
                id: clientDetails.id,
              },
            });
          } else if (selectedMyClientsDropdownOption === 'crm') {
            setShowArticleLoader(true);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'crmClients',
                themesList:
                  selectedCRMContactList.themes.length > 0
                    ? selectedCRMContactList.themes.map(
                        (theme) => theme.id_hash
                      )
                    : [],
              },
            });
          } else {
            setShowArticleLoader(false);
          }
        }
        // exploreArticleRequest({
        //   params: {
        //     id: 'SECURITY',
        //     source: 'myClients',
        //   },
        // });
      } else {
        setSearchOptions([
          { platform: 'all', checked: true, isDisconnected: false },
          { platform: 'my_content', checked: true, isDisconnected: false },
          { platform: 'editor_content', checked: true, isDisconnected: false },
        ]);
        setSearchValue('');
        exploreArticleRequest({
          params: {
            source: 'search',
          },
        });
      }
      setSelectedExploreTabIndex(6);
    } else if (index === 5) {
      setSearchOptions([
        { platform: 'all', checked: true, isDisconnected: false },
        { platform: 'my_content', checked: true, isDisconnected: false },
        { platform: 'editor_content', checked: true, isDisconnected: false },
      ]);
      setSearchValue('');
      exploreArticleRequest({
        params: {
          source: 'search',
        },
      });
      setSelectedExploreTabIndex(7);
    } else {
      setInvestmentTopic('');
      setSelectedExploreTabIndex(4);
    }
    setWdigetTabIndex(index);
  };

  const getCRMThemeIds = () => {
    let theme_ids;
    if (selectedCRMContactList.disabledThemes.length > 0)
      theme_ids = selectedCRMContactList.themes
        .filter(
          (o) => selectedCRMContactList.disabledThemes.indexOf(o.id_hash) === -1
        )
        .map((theme) => theme.id_hash);
    else
      theme_ids = selectedCRMContactList.themes.map((theme) => theme.id_hash);
    return theme_ids;
  };

  const exploreArticleFeed = get(exploreArticles, 'feed', []);
  const showTabLoader =
    !exploreArticleDeleting &&
    exploreArticlesFetching &&
    !newsletterArticleFetching &&
    !bookmarksFetching;
  const holdingsHtmlElement = document.querySelectorAll(
    '[title="Investment Holdings"]'
  );

  if (holdingsHtmlElement && holdingsHtmlElement.length > 1) {
    holdingsHtmlElement[1].setAttribute('title', '');
  }

  return (
    <Box width={1}>
      <Flex
        flexDirection={`${window.innerWidth < 768 ? 'column' : 'row'}`}
        justifyContent="space-between"
        width={1}
        my="25px"
      >
        <Discover
          setTabIndex={setTabIndex}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          setTrendingTopicDetails={setTrendingTopicDetails}
          setShowArticleLoader={setShowArticleLoader}
          discoverTabIndex={discoverTabIndex}
          onDiscoverTabChange={onDiscoverTabChange}
          onTrendingTabClick={onTrendingTabClick}
          isShareBlocked={isShareBlocked}
          setPopularTopicDetails={setPopularTopicDetails}
          onExploreTabClick={onSubscribeTabClick}
          exploreSetState={exploreSetState}
          shareArticleSetState={shareArticleSetState}
          bucketCountRequest={bucketCountRequest}
        />
        <Topics
          exploreSetState={exploreSetState}
          onExploreTabSelect={onExploreTabSelect}
          onExploreTabClick={onExploreTabClick}
          setShowArticleLoader={setShowArticleLoader}
          setPopularTopicDetails={setPopularTopicDetails}
          popularTopicDetails={popularTopicDetails}
          setExploreTabDetails={setExploreTabDetails}
          setTabIndex={setTabIndex}
          setTrendingTopicDetails={setTrendingTopicDetails}
          trendingTopicDetails={trendingTopicDetails}
          topicFromInvestment={investmentTopic}
          onInvestmentClick={onInvestmentClick}
          tickerName={tickerName}
          themesList={themesList}
          setInvestmentTopic={setInvestmentTopic}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          isAdmin
          themeSetState={themeSetState}
          yourTopics={yourTopics}
          onWidgetTabChange={onWidgetTabChange}
          widgetTabIndes={widgetTabIndes}
          setWdigetTabIndex={setWdigetTabIndex}
          currentPortfolio={currentPortfolio}
          holdingsType={holdingsType}
          isPremiumAdvisor={true}
          curatedTopicType={curatedTopicType}
          setCuratedTopicType={setCuratedTopicType}
          contentlibSetState={contentlibSetState}
          contentlibRequest={contentlibRequest}
          onSearchClick={onSearchClick}
          setSearchValue={setSearchValue}
          channelData={channelData}
          exploreArticleRequest={exploreArticleRequest}
          setThirdPartyThemeId={setThirdPartyThemeId}
          setClearEditorArticles={setClearEditorArticles}
          discoverTabIndex={discoverTabIndex}
          onDiscoverTabChange={onDiscoverTabChange}
          onTrendingTabClick={onTrendingTabClick}
          onCheckBoxClick={onCheckBoxClick}
          checkedStatus={checkedStatus}
          setClientTopics={setClientTopics}
          userData={userData}
          searchValue={searchValue}
          thirdPartyThemeId={thirdPartyThemeId}
          showClientDropDown={showClientDropDown}
          setShowClientDropDown={setShowClientDropDown}
          contactList={contactList}
          setClientDetails={setClientDetails}
          selectedDropdownOption={selectedMyClientsDropdownOption}
          setSelectedDropdownOption={setSelectedMyClientsDropdownOption}
          setSelectedCRMContactList={setSelectedCRMContactList}
          crmLoader={crmLoader}
          setCrmLoader={setCrmLoader}
        />
        <FeaturedPosts
          setModalOpen={setOpenSponsoredModal}
          shareArticleSetState={shareArticleSetState}
          themeNames={themeNames}
          popularThemes={popularThemes}
          userData={userData}
          sponsoredArticles={sponsoredArticles}
          setSponsoredArticles={setSponsoredArticles}
          bucketCountRequest={bucketCountRequest}
        />
      </Flex>
      <SponsoredModal
        open={openSponsoredModal}
        setModalOpen={setOpenSponsoredModal}
        sponsoredArticles={sponsoredArticles}
        themeNames={themeNames}
        isDeleteBlocked={true}
        popularThemes={popularThemes}
        userData={userData}
        setSponsoredArticles={setSponsoredArticles}
        bucketCountRequest={bucketCountRequest}
      />
      <Flex width={1}>
        <Tabs
          onSelect={onTabSelect}
          selectedIndex={selectedTabIndex}
          forceRenderTabPanel
        >
          <BorderWhiteBox type="admin-tab" padding="5px 5px">
            <TabList height="31px" data-testid="articles_widget_tabsList">
              <TabTypeThree
                type="dashboard"
                currentTab={selectedTabIndex === 0}
                onClick={() => onExploreTabSelect(selectedExploreTabIndex)}
                data-testid="explore_tab"
              >
                Explore
              </TabTypeThree>
              <TabTypeThree
                type="dashboard"
                currentTab={selectedTabIndex === 1}
                onClick={onRecentTabClick}
                data-testid="recent_tab"
              >
                Recent
              </TabTypeThree>
              <TabTypeThree
                type="dashboard"
                currentTab={selectedTabIndex === 2}
                onClick={onScheduledBySelfTabClick}
                data-testid="scheduled_tab"
              >
                Scheduled
              </TabTypeThree>
              <TabTypeThree
                type="dashboard"
                currentTab={selectedTabIndex === 3}
                data-testid="add_content_tab"
              >
                Add Content
              </TabTypeThree>
            </TabList>

            {selectedTabIndex === 0 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onExploreTabSelect} forceRenderTabPanel>
                  <TabList style={{ marginBottom: '10px' }}>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                      data-testid="recommended_subtab"
                    >
                      Recommended
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      data-testid="popular_subtab"
                    >
                      Popular
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 2 ? 'active' : 'inactive'
                      }`}
                      data-testid="my_content_subtab"
                    >
                      My Content
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 3 ? 'active' : 'inactive'
                      }`}
                      data-testid="my_topics_subtab"
                    >
                      My Topics
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      hide={
                        userData &&
                        userData.details &&
                        userData.details.user &&
                        userData.details.user.personalized_tabs &&
                        userData.details.user.personalized_tabs.my_holdings ===
                          'disabled'
                      }
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 4 ? 'active' : 'inactive'
                      }`}
                      title="Investment Holdings"
                      data-testid="my_holdings_subtab"
                    >
                      My Holdings{' '}
                      {holdingsType && (
                        <HoldingsWrapper className="tooltip">
                          <HoldingsIcon
                            style={{
                              ...(holdingsType === 'stocks' && {
                                width: '9px',
                              }),
                            }}
                            src={`/assets/images/${holdingsType}.png`}
                            data-testid={`holding_img_${holdingsType}`}
                          />
                          <StyledTooltip
                            right={holdingsType === 'stocks' ? '38%' : '40%'}
                            className="tooltiptext"
                            width={holdingsType === 'stocks' ? '80px' : null}
                          >
                            {holdingsType === 'stocks'
                              ? 'STOCK SPECIFIC'
                              : 'THEMATIC'}
                          </StyledTooltip>
                        </HoldingsWrapper>
                      )}
                    </DashboardAdminSubTab>

                    {userData.details &&
                      userData.details.user.turnkey &&
                      userData.details.user.turnkey === 'true' && (
                        <DashboardAdminSubTab
                          color="#49484a"
                          padding="5px 23px 3px"
                          className={`${
                            selectedExploreTabIndex === 5
                              ? 'active'
                              : 'inactive'
                          }`}
                          data-testid="my_editor_subtab"
                        >
                          My Editor
                        </DashboardAdminSubTab>
                      )}

                    {userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'true' ? (
                      <DashboardAdminSubTab
                        hide={
                          userData &&
                          userData.details &&
                          userData.details.user &&
                          userData.details.user.personalized_tabs &&
                          userData.details.user.personalized_tabs.my_clients ===
                            'disabled'
                        }
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 6 ? 'active' : 'inactive'
                        }`}
                        data-testid="my_clients_subtab"
                      >
                        My Clients
                      </DashboardAdminSubTab>
                    ) : (
                      <DashboardAdminSubTab
                        hide={
                          userData &&
                          userData.details &&
                          userData.details.user &&
                          userData.details.user.personalized_tabs &&
                          userData.details.user.personalized_tabs.my_clients ===
                            'disabled'
                        }
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 5 ? 'active' : 'inactive'
                        }`}
                        data-testid="my_clients_subtab"
                      >
                        My Clients
                      </DashboardAdminSubTab>
                    )}

                    {userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'true' ? (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 7 ? 'active' : 'inactive'
                        }`}
                        data-testid="search_subtab"
                      >
                        Search
                      </DashboardAdminSubTab>
                    ) : (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 6 ? 'active' : 'inactive'
                        }`}
                        data-testid="search_subtab"
                      >
                        Search
                      </DashboardAdminSubTab>
                    )}

                    {userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'true' ? (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 8 ? 'active' : 'inactive'
                        }`}
                        data-testid="bookmarked_subtab"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          Bookmarked{' '}
                          <img
                            alt=""
                            style={{ marginLeft: '2px' }}
                            src="/assets/images/bookmark-filled.svg"
                          />
                        </div>
                      </DashboardAdminSubTab>
                    ) : (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 7 ? 'active' : 'inactive'
                        }`}
                        data-testid="bookmarked_subtab"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          Bookmarked{' '}
                          <img
                            alt=""
                            style={{ marginLeft: '2px' }}
                            src="/assets/images/bookmark-filled.svg"
                          />
                        </div>
                      </DashboardAdminSubTab>
                    )}
                  </TabList>

                  {selectedExploreTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(get(exploreArticles, 'feed', [])) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.mioEditorTab}
                          </NoContentWrapper>
                        )}

                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <RecommendedTab
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleDeleting={exploreArticleDeleting}
                          isShareBlocked={isShareBlocked}
                          isDeleteBlocked
                          showTabLoader={showTabLoader}
                          setShowArticleLoader={setShowArticleLoader}
                          popularThemes={popularThemes}
                          userData={userData}
                          onNewsletterArticleClick={onNewsletterArticleClick}
                          onRemoveFromNewletterClick={
                            onRemoveFromNewletterClick
                          }
                          newsletterArticleFetching={newsletterArticleFetching}
                          exploreNewsletterId={exploreNewsletterId}
                          bookmarksFetching={bookmarksFetching}
                          bookmarksId={bookmarksId}
                          hideDelete
                        />
                      )}
                    </StyledTabPanel>
                  )}

                  {selectedExploreTabIndex === 3 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                      )}
                      {console.log('SHOW ARTICLE LOADER - ', showArticleLoader, 'EXPLORE ARTICLES FETCHING - - -', exploreArticlesFetching, 'EXPLORE ARTICLES FEED - - - -', exploreArticleFeed)}
                      {/* {(showArticleLoader || exploreArticlesFetching) && !isEmpty(exploreArticleFeed) && ( */}
                      {(showArticleLoader || exploreArticlesFetching) && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && !exploreArticlesFetching && (
                        <>
                          {!_.isEmpty(exploreTabDetails) && (
                            <ExploreTab
                              selectedTopic={popularTopicDetails.topicName}
                              exploreArticles={exploreArticles}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              exploreArticleDeleting={exploreArticleDeleting}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              showTabLoader={showTabLoader}
                              setShowArticleLoader={setShowArticleLoader}
                              popularThemes={popularThemes}
                              userData={userData}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                              // setCustomMargin
                            />
                          )}
                          {_.isEmpty(exploreTabDetails) && (
                            <NoContentWrapper style={{ cursor: 'pointer' }}>
                              {emptyMessages.exploreTab}
                            </NoContentWrapper>
                          )}
                        </>
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedExploreTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                        )}
                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && !exploreArticlesFetching && (
                        <TrendingTab
                          selectedTopic={trendingTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleDeleting={exploreArticleDeleting}
                          isShareBlocked={isShareBlocked}
                          isDeleteBlocked={isDeleteBlocked}
                          setShowArticleLoader={setShowArticleLoader}
                          popularThemes={popularThemes}
                          userData={userData}
                          onNewsletterArticleClick={onNewsletterArticleClick}
                          onRemoveFromNewletterClick={
                            onRemoveFromNewletterClick
                          }
                          newsletterArticleFetching={newsletterArticleFetching}
                          exploreNewsletterId={exploreNewsletterId}
                          bookmarksFetching={bookmarksFetching}
                          bookmarksId={bookmarksId}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedExploreTabIndex === 5 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'true' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                       
                      {!exploreArticlesFetching &&
                        isEmpty(get(exploreArticles, 'feed', [])) && isEmpty(mioContent) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.mioEditorTab}
                          </NoContentWrapper>
                        )}

                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      <MioEditorTab
                        selectedTopic={popularTopicDetails.topicName}
                        exploreArticles={exploreArticles}
                        deleteArticleRequest={deleteArticleRequest}
                        exploreArticlesFetching={exploreArticlesFetching}
                        themeNames={themeNames}
                        exploreArticleDeleting={exploreArticleDeleting}
                        isShareBlocked={isShareBlocked}
                        isDeleteBlocked={isDeleteBlocked}
                        exploreArticleRequest={exploreArticleRequest}
                        setMioContent={setMioContent}
                        imageUpload={window.localStorage.getItem(
                          'ImageUpload'
                        )}
                        setShowArticleLoader={setShowArticleLoader}
                        thirdPartyThemeId={thirdPartyThemeId}
                        clearEditorArticles={clearEditorArticles}
                        setClearEditorArticles={setClearEditorArticles}
                        isChangeTheme={isChangeTheme}
                        popularThemes={popularThemes}
                        userData={userData}
                        newsletterArticleFetching={
                          newsletterArticleFetching
                        }
                        addToBucketRequest={addToBucketRequest}
                        removeFromNewsletterRequest={
                          removeFromNewsletterRequest
                        }
                        exploreNewsletterId={exploreNewsletterId}
                        addToBookmarksRequest={addToBookmarksRequest}
                        removeFromBookmarksRequest={
                          removeFromBookmarksRequest
                        }
                        bookmarksFetching={bookmarksFetching}
                        bookmarksId={bookmarksId}
                      />

                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 5 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey == 'false' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                        {console.log(selectedCRMContactList)}
                        {console.log(
                          'MESSAGE CONDITION - ',
                          (!exploreArticlesFetching &&
                            isEmpty(get(exploreArticles, 'feed', []))) ||
                            selectedMyClientsDropdownOption === ''
                        )}
                        {((!exploreArticlesFetching &&
                          !crmLoader &&
                          isEmpty(get(exploreArticles, 'feed', []))) ||
                          selectedMyClientsDropdownOption === '') && (
                          // <NoContentWrapper>{selectedMyClientsDropdownOption === 'financial' || selectedCRMContactList.id ? emptyMessages.exploreTab : 'Please connect your CRM accounts to populate content'}</NoContentWrapper>
                          <NoContentWrapper>
                            {selectedMyClientsDropdownOption === ''
                              ? 'Select from available options'
                              : selectedMyClientsDropdownOption ===
                                  'financial' ||
                                (selectedCRMContactList.id &&
                                  selectedCRMContactList.id.length > 0)
                              ? emptyMessages.exploreTab
                              : 'Please connect your CRM accounts to populate content'}
                          </NoContentWrapper>
                        )}

                        {!exploreArticlesFetching &&
                          selectedMyClientsDropdownOption === 'crm' &&
                          isEmpty(myClientsLocalArticles) &&
                          !isEmpty(get(exploreArticles, 'feed', [])) && (
                            <NoContentWrapper>
                              Please enable atleast one topic
                            </NoContentWrapper>
                          )}

                        {(showArticleLoader || exploreArticlesFetching) && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}

                        {!showTabLoader &&
                          selectedMyClientsDropdownOption !== '' && (
                            <MyClientsTab
                              setLocalContent={setLocalContent}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              contentlibRequest={contentlibRequest}
                              contentlibDeleteRequest={contentlibDeleteRequest}
                              contentLibArticles={contentLibArticles}
                              contentLibArticlesFetching={
                                contentLibArticlesFetching
                              }
                              themeNames={themeNames}
                              contentLibArticleDeleting={
                                contentLibArticleDeleting
                              }
                              isShareBlocked={
                                selectedMyClientsDropdownOption === 'financial'
                                  ? clientShareBlocked
                                  : !selectedCRMContactList.id
                              }
                              isDeleteBlocked={isDeleteBlocked}
                              showTabLoader={showTabLoader}
                              setShowArticleLoader={setShowArticleLoader}
                              curatedTopicType={curatedTopicType}
                              exploreArticles={exploreArticles}
                              userData={userData}
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                              selectedDropdownOption={
                                selectedMyClientsDropdownOption
                              }
                              selectedCRMContactList={selectedCRMContactList}
                              setMyClientsLocalArticles={
                                setMyClientsLocalArticles
                              }
                            />
                          )}
                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 2 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {/* {!contentLibArticlesFetching &&
                        premiumAdvisorChannelId &&
                        isPremiumAdvisor &&
                        isEmpty(
                          get(contentLibArticles, 'channel_content', [])
                        ) &&
                        localContent.length === 0 && (
                          <NoContentWrapper>
                            <ButtonNoBorderBackground
                              onClick={() =>
                                isShareBlocked ? null : setTabIndex(3)
                              }
                              style={{ textDecoration: 'underline' }}
                              data-testid="no-content-button"
                            >
                              {emptyMessages.notAddedByPremiumAdvisor}
                            </ButtonNoBorderBackground>
                          </NoContentWrapper>
                        )} */}
                      {/* {showArticleLoader &&
                        !isEmpty(
                          get(contentLibArticles, 'channel_content', [])
                        ) && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )} */}
                      {isPremiumAdvisor && premiumAdvisorChannelId && (
                        <ContentLibraryTab
                          setLocalContent={setLocalContent}
                          selectedTopic={popularTopicDetails.topicName}
                          contentlibRequest={contentlibRequest}
                          contentlibDeleteRequest={contentlibDeleteRequest}
                          contentlibSetState={contentlibSetState}
                          contentLibArticles={contentLibArticles}
                          contentLibArticlesFetching={
                            contentLibArticlesFetching
                          }
                          themeNames={themeNames}
                          contentLibArticleDeleting={contentLibArticleDeleting}
                          isShareBlocked={isShareBlocked}
                          isDeleteBlocked={isDeleteBlocked}
                          showTabLoader={showTabLoader}
                          setShowArticleLoader={setShowArticleLoader}
                          isPremiumAdvisor={isPremiumAdvisor}
                          channelId={premiumAdvisorChannelId}
                          myMioData={myMioData && myMioData}
                          curatedTopicType={curatedTopicType}
                          popularTopicDetails={popularTopicDetails}
                          exploreArticleRequest={exploreArticleRequest}
                          onMyMioTabClick={onMyMioTabClick}
                          popularThemes={popularThemes}
                          userData={userData}
                          contentLibAddToBucketRequest={
                            contentLibAddToBucketRequest
                          }
                          contentLibRemoveFromBucketRequest={
                            contentLibRemoveFromBucketRequest
                          }
                          newsletterArticleFetching={
                            contentLibNewsletterFetching
                          }
                          bookmarksFetching={contentLibBookmarksFetching}
                          bookmarksId={contentLibBookmarksId}
                          contentLibAddToBookmarksRequest={
                            contentLibAddToBookmarksRequest
                          }
                          contentLibRemoveFromBookmarksRequest={
                            contentLibRemoveFromBookmarksRequest
                          }
                          contentLibBucketId={contentLibBucketId}
                          contentLibInNewsletter={contentLibInNewsletter}
                          contentLibBookMarkId={contentLibBookMarkId}
                          contentLibIsBookmark={contentLibIsBookmark}
                          contentLibATSQueue={contentLibATSQueue}
                          contentLibFilter={contentLibFilter}
                        />
                      )}
                    </StyledTabPanel>
                  )}

                  {selectedExploreTabIndex === 4 &&
                    (showInvestmentTab || [4].includes(selectedTabIndex)) && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                        {showArticleLoader && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}
                        {!exploreArticlesFetching &&
                          isEmpty(get(investmentResults, 'feed')) && (
                            <NoContentWrapper>
                              <Flex
                                width={1}
                                justifyContent="center"
                                alignItems="center"
                                data-testid="no-content-message"
                              >
                                No articles found
                                {/* <Box>
                                  <AddInvetmentsButton
                                    src="/assets/images/briefcase.png"
                                    alt="add invetments"
                                    onClick={() => history.push('/portfolio')}
                                  />
                                  <StyledHeading onClick={() => history.push('/portfolio')}>
                                    Add Your Investments
                                  </StyledHeading>
                                </Box> */}
                              </Flex>
                            </NoContentWrapper>
                          )}
                        <LoaderWrapper isLoading={showTabLoader}>
                          {selectedTickers && selectedTickers.length > 0 && (
                            <InvestmentsTab
                              exploreSetState={exploreSetState}
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={trendingTopicDetails.topicName}
                              exploreArticles={investmentResults}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              handleTickerChange={handleTickerChange}
                              handleTopicChange={handleTopicChange}
                              isTicker={isTicker}
                              selectedTickers={selectedTickers}
                              isComplianceAdmin
                              tickerName={tickerName}
                              setShowArticleLoader={setShowArticleLoader}
                              holdingsType={holdingsType}
                              isFilteringStocksByTicker={
                                isFilteringStocksByTicker
                              }
                              popularThemes={popularThemes}
                              userData={userData}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                            />
                          )}
                        </LoaderWrapper>
                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 6 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey == 'true' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                        {((!exploreArticlesFetching &&
                          !crmLoader &&
                          isEmpty(get(exploreArticles, 'feed', []))) ||
                          selectedMyClientsDropdownOption === '') && (
                          // <NoContentWrapper>{selectedMyClientsDropdownOption === 'financial' || selectedCRMContactList.id ? emptyMessages.exploreTab : 'Please connect your CRM accounts to populate content'}</NoContentWrapper>
                          <NoContentWrapper>
                            {selectedMyClientsDropdownOption === ''
                              ? 'Select from available options'
                              : selectedMyClientsDropdownOption ===
                                  'financial' ||
                                (selectedCRMContactList.id &&
                                  selectedCRMContactList.id.length > 0)
                              ? emptyMessages.exploreTab
                              : 'Please connect your CRM accounts to populate content'}
                          </NoContentWrapper>
                        )}

                        {!exploreArticlesFetching &&
                          selectedMyClientsDropdownOption === 'crm' &&
                          isEmpty(myClientsLocalArticles) &&
                          !isEmpty(get(exploreArticles, 'feed', [])) && (
                            <NoContentWrapper>
                              Please enable atleast one topic
                            </NoContentWrapper>
                          )}

                        {(showArticleLoader || exploreArticleDeleting) && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}

                        {!showTabLoader &&
                          selectedMyClientsDropdownOption !== '' && (
                            <MyClientsTab
                              setLocalContent={setLocalContent}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              contentlibRequest={contentlibRequest}
                              contentlibDeleteRequest={contentlibDeleteRequest}
                              contentLibArticles={contentLibArticles}
                              contentLibArticlesFetching={
                                contentLibArticlesFetching
                              }
                              themeNames={themeNames}
                              contentLibArticleDeleting={
                                contentLibArticleDeleting
                              }
                              isShareBlocked={
                                selectedMyClientsDropdownOption === 'financial'
                                  ? clientShareBlocked
                                  : !selectedCRMContactList.id
                              }
                              isDeleteBlocked={isDeleteBlocked}
                              showTabLoader={showTabLoader}
                              setShowArticleLoader={setShowArticleLoader}
                              curatedTopicType={curatedTopicType}
                              exploreArticles={exploreArticles}
                              popularThemes={popularThemes}
                              userData={userData}
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                              selectedDropdownOption={
                                selectedMyClientsDropdownOption
                              }
                              selectedCRMContactList={selectedCRMContactList}
                              setMyClientsLocalArticles={
                                setMyClientsLocalArticles
                              }
                            />
                          )}
                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 6 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey == 'false' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                        {(isEmpty(get(searchResults, 'feed')) ||
                          filteredSearchEmpty ||
                          Object.keys(searchResults).length === 0) && (
                          <NoContentWrapper>
                            {isSearchFilters ? (
                              <div>Search Results</div>
                            ) : (
                              <div data-testid="no-content-message">No articles found</div>
                            )}
                          </NoContentWrapper>
                        )}
                        {showArticleLoader && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}

                        {!showTabLoader && (
                          <LoaderWrapper>
                            <SearchResultsTab
                              selectedTopic={trendingTopicDetails.topicName}
                              exploreArticles={searchResults}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              exploreArticleDeleting={exploreArticleDeleting}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              setIsSearchFilters={setIsSearchFilters}
                              mostSubscribed={mostSubscribed}
                              yourTopics={yourTopics}
                              setShowArticleLoader={setShowArticleLoader}
                              searchFilters={searchFilters}
                              setFilteredSearchEmpty={setFilteredSearchEmpty}
                              popularThemes={popularThemes}
                              userData={userData}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                            />
                          </LoaderWrapper>
                        )}
                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 7 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey == 'true' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                        {(isEmpty(get(searchResults, 'feed')) ||
                          filteredSearchEmpty ||
                          Object.keys(searchResults).length === 0) && (
                          <NoContentWrapper>
                            {isSearchFilters ? (
                              <div>Search Results</div>
                            ) : (
                              <div data-testid="no-content-message">No articles found</div>
                            )}
                          </NoContentWrapper>
                        )}
                        {showArticleLoader && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}

                        {!showTabLoader && (
                          <LoaderWrapper>
                            <SearchResultsTab
                              selectedTopic={trendingTopicDetails.topicName}
                              exploreArticles={searchResults}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              exploreArticleDeleting={exploreArticleDeleting}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              setIsSearchFilters={setIsSearchFilters}
                              mostSubscribed={mostSubscribed}
                              yourTopics={yourTopics}
                              setShowArticleLoader={setShowArticleLoader}
                              searchFilters={searchFilters}
                              setFilteredSearchEmpty={setFilteredSearchEmpty}
                              userData={userData}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                            />
                          </LoaderWrapper>
                        )}
                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 7 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'false' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        style={{ padding: '5px 5px' }}
                      >
                        {!showArticleLoader &&
                          !exploreArticlesFetching &&
                          isEmpty(get(exploreArticles, 'feed', [])) && (
                            <NoContentWrapper data-testid="no-content-message">
                              {emptyMessages.mioEditorTab}
                            </NoContentWrapper>
                          )}

                        {exploreArticlesFetching && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}

                        {!exploreArticlesFetching && (
                          <BookmarksTab
                            setLocalContent={setLocalContent}
                            selectedTopic={popularTopicDetails.topicName}
                            contentlibRequest={contentlibRequest}
                            contentlibDeleteRequest={contentlibDeleteRequest}
                            contentLibArticles={contentLibArticles}
                            contentLibArticlesFetching={
                              contentLibArticlesFetching
                            }
                            themeNames={themeNames}
                            contentLibArticleDeleting={
                              contentLibArticleDeleting
                            }
                            isShareBlocked={isShareBlocked}
                            isDeleteBlocked={isDeleteBlocked}
                            showTabLoader={showTabLoader}
                            setShowArticleLoader={setShowArticleLoader}
                            curatedTopicType={curatedTopicType}
                            exploreArticles={exploreArticles}
                            popularThemes={popularThemes}
                            userData={userData}
                            deleteArticleRequest={deleteArticleRequest}
                            onNewsletterArticleClick={onNewsletterArticleClick}
                            onRemoveFromNewletterClick={
                              onRemoveFromNewletterClick
                            }
                            newsletterArticleFetching={
                              newsletterArticleFetching
                            }
                            bookmarksFetching={bookmarksFetching}
                            exploreNewsletterId={exploreNewsletterId}
                            bookmarksId={bookmarksId}
                          />
                        )}
                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 8 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      style={{ padding: '5px 5px' }}
                    >
                      {!showArticleLoader &&
                        !exploreArticlesFetching &&
                        isEmpty(get(exploreArticles, 'feed', [])) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.mioEditorTab}
                          </NoContentWrapper>
                        )}

                      {exploreArticlesFetching && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!exploreArticlesFetching && (
                        <BookmarksTab
                          setLocalContent={setLocalContent}
                          selectedTopic={popularTopicDetails.topicName}
                          contentlibRequest={contentlibRequest}
                          contentlibDeleteRequest={contentlibDeleteRequest}
                          contentLibArticles={contentLibArticles}
                          contentLibArticlesFetching={
                            contentLibArticlesFetching
                          }
                          themeNames={themeNames}
                          contentLibArticleDeleting={contentLibArticleDeleting}
                          isShareBlocked={isShareBlocked}
                          isDeleteBlocked={isDeleteBlocked}
                          showTabLoader={showTabLoader}
                          setShowArticleLoader={setShowArticleLoader}
                          curatedTopicType={curatedTopicType}
                          exploreArticles={exploreArticles}
                          popularThemes={popularThemes}
                          userData={userData}
                          deleteArticleRequest={deleteArticleRequest}
                          onNewsletterArticleClick={onNewsletterArticleClick}
                          onRemoveFromNewletterClick={
                            onRemoveFromNewletterClick
                          }
                          newsletterArticleFetching={newsletterArticleFetching}
                          bookmarksFetching={bookmarksFetching}
                          exploreNewsletterId={exploreNewsletterId}
                          bookmarksId={bookmarksId}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}

            {selectedTabIndex === 1 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onRecentTabSelect} forceRenderTabPanel>
                  <TabList style={{ marginBottom: '10px' }}>
                    <DashboardAdminSubTab
                      className={`${
                        selectedRecentTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                      onClick={onRecentTabClick}
                      data-testid="all_posts_recent"
                    >
                      All posts
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      className={`${
                        selectedRecentTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      onClick={onRecentLastmonthTabClick}
                      data-testid="this_week_recent"
                    >
                      This Week
                    </DashboardAdminSubTab>
                  </TabList>

                  {selectedRecentTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.recentTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <div>
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isRecentArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <RecentSharedTab
                            selectedTopic={popularTopicDetails.topicName}
                            exploreArticles={exploreArticles}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            isShareBlocked={isShareBlocked}
                            isDeleteBlocked={isDeleteBlocked}
                            isAdmin={isAdmin}
                            showTabLoader={showTabLoader}
                            setShowArticleLoader={setShowArticleLoader}
                            isPremiumAdvisor={true}
                          />
                          {isExportPdfOpen.isRecentArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isRecentArticle}
                              setOpen={setIsExportPdfOpen}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              isAdmin={isAdmin}
                              showTabLoader={showTabLoader}
                              setShowArticleLoader={setShowArticleLoader}
                              isPremiumAdvisor={true}
                              currentPopUp={'isRecentArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedRecentTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.recentTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <div>
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isRecentArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <RecentSharedTab
                            selectedTopic={popularTopicDetails.topicName}
                            exploreArticles={exploreArticles}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            isShareBlocked={isShareBlocked}
                            isDeleteBlocked={isDeleteBlocked}
                            isAdmin={isAdmin}
                            setShowArticleLoader={setShowArticleLoader}
                            isPremiumAdvisor={true}
                          />
                          {isExportPdfOpen.isRecentArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isRecentArticle}
                              setOpen={setIsExportPdfOpen}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              isAdmin={isAdmin}
                              setShowArticleLoader={setShowArticleLoader}
                              isPremiumAdvisor={true}
                              currentPopUp={'isRecentArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 2 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onScheduledTabSelect} forceRenderTabPanel>
                  <TabList style={{ marginBottom: '10px' }}>
                    <DashboardAdminSubTab
                      className={`${
                        selectedScheduledTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledBySelfTabClick}
                      data-testid="my_scheduled_content"
                    >
                      My Scheduled Content
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      className={`${
                        selectedScheduledTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledTabClick}
                      data-testid="recommended_by_company"
                    >
                      Recommended by {company.name}
                    </DashboardAdminSubTab>
                    {/* <DashboardAdminSubTab className={`${selectedScheduledTabIndex === 1 ? 'active' : 'inactive'}`} onClick={onScheduledByCompanyTabClick}>By Company</DashboardAdminSubTab> */}
                  </TabList>

                  {selectedScheduledTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledByMioTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <div>
                          {console.log(exploreArticles)}
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isSharedArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <ScheduledTab
                            exploreArticleDeleting={exploreArticleDeleting}
                            selectedTopic={popularTopicDetails.topicName}
                            exploreArticles={exploreArticles}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            isShareBlocked={isShareBlocked}
                            isDeleteBlocked={isDeleteBlocked}
                            exploreArticleRequest={exploreArticleRequest}
                            setShowArticleLoader={setShowArticleLoader}
                            scheduleType={'recommendation_engine'}
                          />
                          {isExportPdfOpen.isSharedArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isSharedArticle}
                              setOpen={setIsExportPdfOpen}
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              exploreArticleRequest={exploreArticleRequest}
                              setShowArticleLoader={setShowArticleLoader}
                              scheduleType={'recommendation_engine'}
                              currentPopUp={'isSharedArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}

                  {selectedScheduledTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <div>
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isSharedArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <ScheduledTab
                            exploreArticleDeleting={exploreArticleDeleting}
                            selectedTopic={popularTopicDetails.topicName}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            exploreArticles={exploreArticles}
                            isShareBlocked={isShareBlocked}
                            isDeleteBlocked={isDeleteBlocked}
                            exploreArticleRequest={exploreArticleRequest}
                            setShowArticleLoader={setShowArticleLoader}
                            scheduleType={'user'}
                          />
                          {isExportPdfOpen.isSharedArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isSharedArticle}
                              setOpen={setIsExportPdfOpen}
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              isShareBlocked={isShareBlocked}
                              isDeleteBlocked={isDeleteBlocked}
                              exploreArticleRequest={exploreArticleRequest}
                              setShowArticleLoader={setShowArticleLoader}
                              scheduleType={'user'}
                              currentPopUp={'isSharedArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 3 && (
              <StyledTabPanelTwo style={{ marginBottom: '10px' }}>
                <Tabs forceRenderTabPanel>
                  <TabList>
                    {/* <DashboardAdminSubTab className={`${selectedContentTabIndex === 0 ? 'active' : 'inactive'}`}>Your Content</DashboardAdminSubTab> */}
                    <DashboardAdminSubTab
                      style={{ border: 'none' }}
                      disabled={isShareBlocked}
                    >
                      &nbsp;
                    </DashboardAdminSubTab>
                  </TabList>

                  {selectedContentTabIndex === 0 && (
                    <StyledTabPanel
                      className="react-tabs__tab-panel--selected"
                      padding="0px"
                      type="admin"
                    >
                      <AddContent
                        channelId={
                          isPremiumAdvisor ? premiumAdvisorChannelId : channelId
                        }
                        themesList={themesList}
                        setTabIndex={setTabIndex}
                        contentlibRequest={contentlibRequest}
                        contentLibArticles={contentLibArticles}
                        setSelectedExploreTabIndex={setSelectedExploreTabIndex}
                        setWdigetTabIndex={setWdigetTabIndex}
                      />
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
          </BorderWhiteBox>
        </Tabs>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  const isComplianceAdmin =
    get(ownProps, 'roleType') === ROLE_TYPES.ADMIN_COMPLIANCE;
  // console.log(isComplianceAdmin);
  const isEnterpriseAdmin =
    get(ownProps, 'roleType') === ROLE_TYPES.ADMIN_ENTERPRISE;
  // console.log(isEnterpriseAdmin)
  const isAdmin = getUserRole(state) === USER_ROLE.admin;
  // const isAdvisor = ROLE_TYPES.;
  const roleType = getRoleType(state);
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  const subscriptionType = get(state, 'subscription_type');
  // console.log('subscriptionType', subscriptionType);
  // console.log('UserRole:', getUserRole(state));
  const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', 'false') === 'true';
  const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', 'false') === 'true';
  const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });
  // console.log('FIRM SETTINGS - - -- -', firmSettings);
  return {
    groupsList: state.advisorGroups.data,
    groupsListFetching: state.advisorGroups.isFetching,
    exploreArticles: state.explore.data,
    exploreArticlesFetching: state.explore.isFetching,
    exploreArticleDeleting: state.explore.isDeleting,
    contentLibArticles: state.contentlib.data,
    contentLibArticlesFetching: state.contentlib.isFetching,
    contentLibArticleDeleting: state.contentlib.isDeleting,
    isAnalyticsDataFetching: state.analytics.isFetching,
    analyticsObject: state.analytics,
    selectedRepType: state.analytics.repType,
    searchResults: get(state, 'explore.search', {}),
    themeNames: get(state, 'theme.themeNames', {}),
    inviteSending: state.invite.isFetching,
    inviteError: state.invite.isError,
    channelId: getChannelId(state),
    themesList: state.theme.data || [],
    investmentResults: get(state, 'explore.investments', {}),
    showMio: isAdmin,
    isShareBlocked: isComplianceAdmin,
    isDeleteBlocked: isComplianceAdmin,
    isAdmin,
    tickerName: state.explore.ticker,
    isInvEmpty: state.explore.isInvEmpty,
    isTicker: state.explore.isTicker,
    selectedTickers: state.explore.selectedTickers,
    userData: state.user.data,
    portfolioId: state.explore.currentPortfolio,
    yourTopics: state.theme.yourTopics,
    isComplianceAdmin,
    isEnterpriseAdmin,
    myMioData: get(state, 'explore.mymio', {}),
    currentPortfolio: state.explore.currentPortfolio,
    channelData: get(state, 'channel.data', []),
    isPremiumAdvisor,
    holdingsType: state.explore.holdingsType,
    searchFilters: state.explore.searchFilters,
    exploreArticleError: state.explore.error,
    isChangeTheme: state.explore.isChangeTheme,
    newsletterArticleFetching: state.explore.isNewsletterFetching,
    bookmarksFetching: state.explore.isBookmarksFetching,
    contentLibNewsletterFetching: state.contentlib.isNewsletterFetching,
    exploreNewsletterId: state.explore.newsletterId,
    bookmarksId: state.explore.bookmarkArticleId,
    contentLibBookmarksFetching: state.contentlib.isBookmarksFetching,
    contentLibBookmarksId: state.contentlib.bookmarkArticleId,
    contentLibBucketId: get(state, 'contentlib.bucketId', ''),
    contentLibInNewsletter: get(state, 'contentlib.inNewsletter', null),
    contentLibBookMarkId: get(state, 'contentlib.bookmark_article_id', ''),
    contentLibIsBookmark: get(state, 'contentlib.isBookMark', null),
    contentLibATSQueue: get(state, 'contentlib.pendingArticles', []),
    contentLibFilter: get(state, 'contentlib.filter', 'all'),
    topicDetails: get(state, 'explore.topicDetails', ''),
    firmSettings: firmSettings
  };
};

const mapDispatchToProps = {
  exploreArticleRequest: exploreActions.api.explore.request,
  advisorGroupsRequest: advisorGroupsActions.api.groups.request,
  deleteArticleRequest: exploreActions.api.explore.delete,
  inviteRequest: inviteActions.api.invite.request,
  contentlibRequest: contentlibActions.api.contentlib.request,
  contentlibDeleteRequest: contentlibActions.api.contentlib.delete,
  analyticsRequest: analyticsActions.api.analytics.request,
  exploreSetState: exploreActions.api.explore.setstate,
  contentlibSetState: contentlibActions.api.contentlib.setstate,
  loginRequest: loginActions.api.login.request,
  themeSetState: themeActions.api.theme.setstate,
  loginSetState: loginActions.api.login.setstate,
  channelSetState: channelActions.api.channel.setstate,
  shareArticleSetState: shareActions.api.share.setstate,
  addToBucketRequest: exploreMoreActions.custom.explore.addToNewsletter,
  removeFromNewsletterRequest:
    exploreMoreActions.custom.explore.removeFromNewsletter,
  addToBookmarksRequest: exploreMoreActions.custom.explore.addToBookmarks,
  removeFromBookmarksRequest:
    exploreMoreActions.custom.explore.removeFromBookmarks,
  contentLibAddToBucketRequest:
    contentlibMoreActions.custom.contentlib.addToNewsletter,
  contentLibRemoveFromBucketRequest:
    contentlibMoreActions.custom.contentlib.removeFromNewsletter,
  contentLibAddToBookmarksRequest:
    contentlibMoreActions.custom.contentlib.addToBookmarks,
  contentLibRemoveFromBookmarksRequest:
    contentlibMoreActions.custom.contentlib.removeFromBookmarks,
  bucketCountRequest:
    newsletterMoreActions.custom.newsletter.bucketCountRequest,
};

PremiumAdvisorDashboardContainer.defaultProps = {
  groupsList: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PremiumAdvisorDashboardContainer);
