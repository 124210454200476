import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { SetObjectivesInput } from '../../containers/DripCampaignContainer/SetObjectives/SetObjectives.styles';


const BccField = ({ 
  isAdvisorEnterprise, 
  value, 
  setValue, 
  userData
}) => {
  

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Flex
        mt={'2%'}
        flexDirection="column"
        width={1}
        style={{ padding: '10px 40px' }}
    >
      <Flex width={1} justifyContent="center" style={{ fontSize: '12px' }}>
        BCC for all emails
      </Flex>
      {isAdvisorEnterprise && (
        <Flex
          flexDirection="column"
          width="100%"
          style={{ fontWeight: '600', padding: '10px 40px' }}
        >
          <SetObjectivesInput
            disabled
            value={!isEmpty(get(userData, 'firm_bcc_emails', [])) ? get(userData, 'firm_bcc_emails', []).join() : ''}
            placeholder="Firm Bcc Emails"
          />
        </Flex>
      )}
      <Flex
        flexDirection="column"
        width="100%"
        style={{ fontWeight: '600', padding: '10px 40px' }}
      >
        <SetObjectivesInput
          placeholder={isAdvisorEnterprise === true ? "Type in any additional email address(es) to be bcc’ed, separated by commas (Max 3)" 
          : "Type in email address(es) to be bcc’ed, separated by commas (Max 3)"}
          value={value}
          onChange={handleChange}
        />
      </Flex>
      <Flex fontSize={10} justifyContent="center" color={'black'} style>
        All emails sent from Clout will bcc this email address(es), for
        compliance archival.
      </Flex>
    </Flex>
  );
};
export default BccField;
