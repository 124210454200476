import React, { useState, useEffect } from 'react';
import { get, isEmpty, values, keys } from 'lodash';
import { connect } from 'react-redux';
import qs from 'querystring';
import { Grid } from '../components/CommonStyles';
import {
  AdminHeader,
  AdvisorHeader,
  Loader,
  InviteFromHubSpotModal,
  LinkedInPageSelectModal,
  AdvisorSidebar
} from '../components';
import {
  getChannelId,
  getUserDetails,
  getUserRole,
  getRoleType,
} from '../redux/selector';
import { actions as loginActions } from '../redux/modules/login.module';
import { actions as channelActions } from '../redux/modules/channel.module';
import { actions as themeActions } from '../redux/modules/theme.module';
import { actions as socialNetworkActions } from '../redux/modules/socialNetwork.module';
import { actions as contactsActions } from '../redux/modules/contacts.module';
import { actions as inviteActions } from '../redux/modules/invite.module';
import { getLinkedPages } from '../services/configure';
import MioImageUpload from '../containers/DashboardContainer/MIOEditor/MioImageUpload';
import MioSuccessModal from '../containers/DashboardContainer/MIOEditor/MioSuccessModal';
import { moreActions as newsletterMoreActions } from '../redux/modules/newsletter.module';
import { clearAllBucketArticles, mioEditorUploadImage } from '../services/dashboard';
import { actions as contentlibActions } from '../redux/modules/content-lib.module';
import { DeleteBucketArticlesModal } from '../containers/NewsletterContainer/NewsletterModals/NewsletterModals';
import { actions as campaignActions } from '../redux/modules/campaign.module';

const withDashboardLayout = (Component) => {
  const ComposedComponent = ({
    loginRequest,
    history,
    userName,
    userRole,
    channelRequest,
    channelData,
    themesRequest,
    themesList,
    isFetching,
    roleType,
    socialNetworkReuest,
    contactsRequest,
    contactsList,
    socialNetworkData,
    inviteRequest,
    channelId,
    inviteSending,
    contentlibSetState,
    user,
    contactsFetching,
    bucketCount,
    bucketCountRequest,
    campaignSetState
  }) => {
    const [isHubSpotModalOpen, setIsHubSpotModalOpen] = useState(false);
    const [isLinedinModalOpen, setIsLinedinModalOpen] = useState(false);
    const [linkedInPagesList, setLinkedInPagesList] = useState([]);
    const [menuToggle, setMenuToggle] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [imageUpload, setImageUpload] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    useEffect(() => {
      if (!userName) {
        loginRequest();
      }
      campaignSetState({
        campaignId: '',
        createFlow: true,
        createMode: false,
        curFlow: '',
        selectedIndex: 0,
        setObjectives: {},
        setTopics: {},
        contentWeeks: [],
        planCampaign: {},
        campaignArticles: [],
        scheduleTimings: {},
        selectedWeek: '',
        selectedDays: '',
        selectedArticles: [],
        publishedContent: {},
        defaultTemplates: [],
        setTemplate: '',
        savedTemplate: null,
        scheduleTypes: {},
        isEdit: false,
        isFromAddCampaign: false,
        summaries: null,
        seriesId: '',
        bookmarkArticles: [],
        recommendedArticles: [],
        myContentArticles: [],
        leadGenReferralData: null,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (isEmpty(channelData)) {
        channelRequest();
      }
      if (isEmpty(themesList)) {
        themesRequest();
      }
      socialNetworkReuest();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole]);

    useEffect(() => {
      contentlibSetState({data: {}});
      bucketCountRequest();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!isEmpty(socialNetworkData)) {
        const { snetwork } = qs.parse(window.location.href);

        // if (snetwork && (snetwork === 'hs' || snetwork.includes('hs'))) {
        //   setIsHubSpotModalOpen(true);
        // }
        if (window.sessionStorage.getItem('platform') && window.sessionStorage.getItem('platform') === 'ln') {
          getLinkedPages().then((response) => {
            const data = get(response, 'result.data');
            const lnPageData = get(data, 'ln');
            const lnPages = [];
            keys(get(data, 'ln')).forEach((key) => {
              const pageDetails = get(lnPageData, key);
              pageDetails.forEach((item) => {
                lnPages.push({ ...item, snId: key });
              });
            });
            if (!isEmpty(lnPages)) {
              setIsLinedinModalOpen(true);
              setLinkedInPagesList(lnPages);
            }
          });
        }
      }
    }, [socialNetworkData]);

    const HeaderComponent = userRole === 'admin' ? AdminHeader : AdvisorHeader;
    
    const handleMenuToggle = () => {
      setMenuToggle(!menuToggle);
    }

    const handleModalOpen = () => {
      setOpenUploadModal(true);
    }

    const onRequestImageClose = () => setOpenUploadModal(false);

    const handleFileUploadChange = (file) => {
      let payload = {
        media1: new File([file[0]],`${file[0].name}`),
        enable: true
      }
      mioEditorUploadImage(payload).then((res) => {
        if(res && res.result.success) {
          setOpenUploadModal(false);
          setOpenSuccessModal(true);
        }
      });
    }

    const onRequestSuccessClose = () => {
      setOpenSuccessModal(false);
      setImageUpload(true);
      window.localStorage.setItem('ImageUploadFirst', true)
      window.localStorage.setItem('ImageUpload', true)
      loginRequest();
    }

    const onAccept = () => {
      setDeleteLoading(true);
      clearAllBucketArticles().then(res => {
        setDeleteLoading(false);
        if(res.result.success) {
          window.location.reload();
        }
      }).catch(err => {
        setDeleteLoading(false);
      })
    }

    const onReject = () => {
      setOpenDeleteModal(false);
    }

    if (isFetching) {
      return <Loader height="100vh" />;
    }


    return (
      <div>
        <HeaderComponent
          userName={userName}
          pathName={history.location.pathname}
          roleType={roleType}
          handleMenuToggle={handleMenuToggle}
          handleModalOpen={handleModalOpen}
          bucketCount={bucketCount}
          setOpenDeleteModal={setOpenDeleteModal}
        />
        {console.log('ROUTER PATH - ', history)}
        <Grid style={history.location.pathname == '/theme' ? {width: '92%'} : {}}>
          {window.innerWidth < 768 ? 
            <AdvisorSidebar toggle={menuToggle} userName={userName} roleType={roleType}></AdvisorSidebar>
            :
            null
          }
          <Component roleType={roleType} />
          {/* <Feedback /> */}
          <InviteFromHubSpotModal
            isModalOpen={isHubSpotModalOpen}
            contactsRequest={contactsRequest}
            contactsList={contactsList}
            setModalOpen={setIsHubSpotModalOpen}
            inviteRequest={inviteRequest}
            channelId={channelId}
            inviteSending={inviteSending}
            contactsFetching={contactsFetching}
          />
          {/* <LinkedInPageSelectModal
            isModalOpen={isLinedinModalOpen}
            setModalOpen={setIsLinedinModalOpen}
            linkedInPagesList={linkedInPagesList}
          /> */}
          <MioImageUpload 
            openModal={openUploadModal} 
            onRequestClose={onRequestImageClose} 
            onFileUploadChange={handleFileUploadChange}
            userData={user}
          />
          <MioSuccessModal openModal={openSuccessModal} onRequestClose={onRequestSuccessClose}/>
          <DeleteBucketArticlesModal
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            onAccept={onAccept}
            onReject={onReject}
            deleteLoading={deleteLoading}
          />
        </Grid>
      </div>
    );
  };
  const mapStateToProps = (state) => {
    const { first: firstName } = get(getUserDetails(state), 'name', {});
    return {
      userName: firstName,
      userRole: getUserRole(state),
      channelData: get(state, 'channel.data', []),
      themesList: state.theme.data || [],
      isFetching: state.user.isFetching || state.theme.isFetching,
      roleType: getRoleType(state),
      contactsList: state.contacts.data || [],
      socialNetworkData: state.socialNetwork.data,
      channelId: getChannelId(state),
      inviteSending: state.invite.isFetching,
      user: state.user.data,
      contactsFetching: state.contacts.isFetching,
      bucketCount: get(state, 'newsletter.bucketCount', 0)
    };
  };

  const mapDispatchToProps = {
    loginRequest: loginActions.api.login.request,
    channelRequest: channelActions.api.channel.request,
    themesRequest: themeActions.api.theme.request,
    socialNetworkReuest: socialNetworkActions.api.socialNetwork.request,
    contactsRequest: contactsActions.api.contacts.request,
    inviteRequest: inviteActions.api.invite.request,
    contentlibSetState: contentlibActions.api.contentlib.setstate,
    bucketCountRequest: newsletterMoreActions.custom.newsletter.bucketCountRequest,
    campaignSetState: campaignActions.api.campaign.setstate,
  };
  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);
};

export default withDashboardLayout;
