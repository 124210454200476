import React from 'react';
import { ListItem, RadioButton, RadioLabel } from './RadioButton.styles';

const Radio = ({ label, id, name, onChange }) => {
  return (
    <ListItem>
      <RadioButton id={id} name={name} onChange={onChange} />
      <RadioLabel htmlFor={id}>{label}</RadioLabel>
    </ListItem>
  );
};

export default Radio;
