import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, Input } from '../';

export const EmailSettingModal = styled(Modal)`
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  position: relative;
  width: auto;
  margin: 10px;
  @media (min-width: 768px) {
    width: 600px;
    margin: 30px auto;
  }
`;
export const EmailModalHeader = styled(ModalHeader)`
  padding: 0 15px;
  text-transform: capitalize;
  color: #6351ed;
  font-weight: 700;
  text-align: center;
  font-family: 'Poppins';
`;
export const EmailModalBody = styled(ModalBody)`
  position: relative;
  padding: 15px;
`;
export const EmailModalBox = styled.div`
  width: 50%;
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
`;
export const EmailModalInput = styled(Input)``;
export const Image = styled.img`
  width: 100px;
  height: 100px;
  margin: 20px auto 25px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
`;

export const RadioButtonList = styled.ul`
  display: flex;
  margin: 0;
  padding-left: 5px;
  padding-bottom: 12px;
`;
