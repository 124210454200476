/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import { Button } from '../../../components';
import AllPlatformsContainer from '../AllPlatformsContainer/AllPlatformsContainer';
import EmailOnlyContainer from '../EmailOnlyContainer/EmailOnlyContainer';
import SocialOnlyContainer from '../SocialOnlyContainer/SocialOnlyContainer';
import { useHistory } from 'react-router-dom';
import '../../DashboardContainer/MIOEditor/MioImageUpload.styles.css';
import {
  CreateContainer,
  CreateContainerCards,
  TitleSpan,
  CardTitle,
  CardSubtitle,
  CardText,
} from './CreateCampaign.styles';
import { FooterBtnsWrapper } from '../DripCampaignContainerV2.styles';
import CreateCampaignFooter from './CreateCampaignFooter';
import {
  approvedToShareArticlesData,
  getContentLibArticleData,
  getExploreArticleData,
  getMyContentArticles,
} from '../../../utils';
import {
  getApproveToShareArticles,
  getBookmarkedArticles,
  getContentLibrary,
  getPremiumAdvisorChannelId,
  getRecommendedArticles,
} from '../../../services/dashboard';
import { connect } from 'react-redux';
import { isEmpty, uniqBy, get } from 'lodash';
import { getUserRole } from '../../../redux/selector';
// import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import { getCustomRows } from '../../../services/bee-editor';
import CampaignNextCongrats from '../CampaignNextCongrats/CampaignNextCongrats';
import CampaignCompleteCongrats from '../CampaignCompleteCongrats/CampaignCompleteCongrats';
import AutomatedCampaignsHome, {
  AUTOMATED_CAMPAIGN_ACTIONS,
} from '../../AutomatedCampaigns';

import { actions as loginActions } from '../../../redux/modules/login.module';
import { getUserDetails } from '../../../services/login';

const CreateCampaign = ({
  socialNetworkData,
  contactGroups,
  getAccountId,
  campaignId,
  setCreateFlow,
  setCreateTabs,
  campaignSetState,
  campaignCreateFlow,
  setObjectivesTabData,
  endCreateFlow,
  isPremiumAdvisor,
  userData,
  campaignSelectedArticles,
  setTemplateData,
  setTemplateBase,
  campaignScheduleTimings,
  themeNames,
  cmode,
  savedTemplate,
  editMode,
  createSelectedIndex,
  createMode,
  curFlow,
  bookmarkArticles,
  recommendedArticles,
  myContentArticles,
  isEdit,
  campaignSummaries,
  campaignScheduleTypes,
  isFromAddCampaign,
  series,
  seriesId,
  defaultTemplates,
  isadvMode,
  isAdvisorApprovalMode,
  campaignStatus,
  userRole,
  channelId,
  campaignLeadReferralData,
  checkboxStatus,
  socialSelectedCampId,
  isEnterpriseAdvisor,
  complianceApplicable,
  complianceApproved,
  emAdvApproval,
  socialAdvApproval,
  fromDashboard,
  reviewCampaign,
  detailsHandler,
  fromCreateEmail,
  automatedCampaigns,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  firmsArray,
  isAdmin,
  loginRequest,
  segmentList,
  setSegmentList,
  myContentPendingQueue,
  isGlobalCompliance,
  companyId
}) => {
  useEffect(() => {
    if (createSelectedIndex === 0 && !fromDashboard) {
      if (!isEdit) {
        campaignSetState({
          campaignId: '',
          createFlow: true,
          curFlow: fromCreateEmail ? curFlow : '',
          setTemplate: '',
          setObjectives: isFromAddCampaign ? { campaignType: 'series' } : {},
          setTopics: {},
          contentWeeks: [],
          planCampaign: {},
          campaignArticles: [],
          scheduleTimings: {},
          selectedWeek: '',
          selectedDays: '',
          selectedArticles: [],
          publishedContent: {},
          selectedIndex: 0,
          leadGenReferralData: null,
          automatedCampaigns: fromCreateEmail ? automatedCampaigns : {},
          selectedBlock: {},
          selectedBlockId: '',
          forAutomatedCampaigns: false,
          automatedFlowFromDashboard: false,
        });
      } else {
        if (editMode && editMode.type && editMode.curFlow) {
          campaignSetState({
            selectedIndex: 0,
            createMode: editMode.type,
            curFlow: editMode.curFlow,
          });
        }
      }
    } else if (createSelectedIndex === 0 && fromDashboard) {
      if (!isEdit) {
        campaignSetState({
          campaignId: '',
          createFlow: true,
          curFlow: '',
          setTemplate: '',
          setObjectives: {},
          setTopics: {},
          contentWeeks: [],
          planCampaign: {},
          campaignArticles: [],
          scheduleTimings: {},
          selectedWeek: '',
          selectedDays: '',
          publishedContent: {},
          selectedIndex: 0,
          leadGenReferralData: null,
          createMode: 'dashboard',
        });
      }
    }
  }, [editMode]);
  // const [createMode, setCreateMode] = useState(cmode ? cmode : false);
  // const [createMode, setCreateMode] = useState(false);
  // const [curFlow, setCurFlow] = useState('');
  const [fetchedUserData, setFetchedUserData] = useState(userData);
  const [bookmarkArticlesLoader, setBookmarkArticlesLoader] = useState(false);
  const [recommendedArticlesLoader, setRecommendedArticlesLoader] =
    useState(false);
  const [conListRecArticlesLoader, setConListRecArticlesLoader] =
    useState(false);
  const [myArticlesLoader, setMyArticlesLoader] = useState(false);
  // const [articlesLoader, setArticlesLoader] = useState(false);
  const history = useHistory();
  const curFlowHandler = (flow) => {
    campaignSetState({ curFlow: flow });
  };

  console.log(curFlow);

  const [isSeries, setIsSeries] = useState(false);
  const [blockCreate, toggleBlockCreate] = useState(true);

  const redirectionCampaign = () => {
    // setCreateMode(false);
    // console.log('redirectionCampaign');
    setIsSeries(true);
    campaignSetState({
      campaignId: '',
      createFlow: true,
      curFlow: '',
      setTemplate: '',
      setObjectives: {
        approvedToShare: get(setObjectivesTabData, 'approvedToShare', 'false'),
        selectedAdvisors: get(setObjectivesTabData, 'selectedAdvisors', []),
        campaignSeriesStatus: 'completed',
      },
      setTopics: {},
      contentWeeks: [],
      planCampaign: {},
      campaignArticles: [],
      scheduleTimings: {},
      selectedWeek: '',
      selectedDays: '',
      selectedArticles: [],
      publishedContent: {},
      createMode: 'series',
      selectedIndex: 0,
      leadGenReferralData: null,
    });
  };

  const redirectCascadeAdvisor = () => {
    let newReviewCampaign = {
      ...reviewCampaign,
      campaigns: reviewCampaign.campaigns.filter((c) => c !== campaignId),
    };
    campaignSetState({
      campaignId: '',
      createFlow: true,
      curFlow: '',
      setTemplate: '',
      setObjectives: {},
      setTopics: {},
      contentWeeks: [],
      planCampaign: {},
      campaignArticles: [],
      scheduleTimings: {},
      selectedWeek: '',
      selectedDays: '',
      selectedArticles: [],
      publishedContent: {},
      createMode: '',
      selectedIndex: 0,
      leadGenReferralData: null,
      reviewCampaign: newReviewCampaign,
    });
  };


  // const fetchMyContentArticles = ({ after }) => {
  //   // console.log('CONTENT LIB FILTER - - - - ', contentLibFilter);
  //   getMyContentArticles({
  //     userType: isEnterpriseAdvisor ? 'isEnterpriseAdvisor' : isPremiumAdvisor ? 'isPremiumAdvisor' : 'isEnterpriseAdmin', 
  //     channelId: channelId, 
  //     beforeToken: null, 
  //     afterToken: after,
  //     pendingArticles: myContentPendingQueue,
  //   })
  //   .then(res => {
  //     // console.log('RESPONSE - - -- - ', res);
  //     const myContentArticlesCopy = cloneDeep(myContentArticles);
  //     const updatedArticlesList = after ? myContentArticlesCopy.concat(get(res, 'articles', [])) : get(res, 'articles', []);
  //     const newState = {
  //       myContentArticles: updatedArticlesList,
  //       myContentPaginationData: get(res, 'pagination', {before: null, after: null})
  //     };
  //     setPaginationData();
  //     if(isEnterpriseAdvisor)
  //       newState.pendingArticles = get(res, 'pendingArticlesQueue', myContentPendingQueue);
  //     campaignSetState(newState);
  //     toggleLoader(false);
  //   })
  //   .catch(err => {
  //     toggleLoader(false);
  //     console.log('ERROR IN CONTENT LIB USE EFFECT');
  //   });
  // }

  useEffect(() => {
    //  setLoading(true);
    if (isEmpty(bookmarkArticles)) {
      setBookmarkArticlesLoader(true);
      getBookmarkedArticles()
        .then((res) => {
          //  setLoading(false);
          setBookmarkArticlesLoader(false);
          if (res.result && res.result.data && res.result.data.feed) {
            let allBookMarkedArticles = getExploreArticleData(
              res.result.data,
              'campaign'
            );
            if (allBookMarkedArticles.length > 0) {
              campaignSetState({ bookmarkArticles: allBookMarkedArticles });
            }
          }
        })
        .catch((err) => {
          //  setLoading(false);
          setBookmarkArticlesLoader(false);
          console.log(err);
        });
    }

    //  setLoading(true);

    if (isEmpty(recommendedArticles)) {
      setRecommendedArticlesLoader(true);
      getRecommendedArticles()
        .then((res) => {
          //  setLoading(false);
          setRecommendedArticlesLoader(false);
          if (res.result && res.result.data && res.result.data.feed) {
            let allRecommendedArticles = getExploreArticleData(
              res.result.data,
              'campaign'
            );
            // console.log(
            //   'ALL RECOMMENDED ARTICLES - -- ',
            //   allRecommendedArticles
            // );
            if (allRecommendedArticles.length > 0) {
              //  console.log('SETTING RECOMMENDED ARTICLES- --  ')
              campaignSetState({ recommendedArticles: allRecommendedArticles });
            }
          }
        })
        .catch((err) => {
          //  setLoading(false);
          setRecommendedArticlesLoader(false);
          console.log(err);
        });
    }

    if(isEmpty(myContentArticles)) {
      setMyArticlesLoader(true);
      getMyContentArticles({
        payload: {
          userType: isEnterpriseAdvisor ? 'isEnterpriseAdvisor' : isPremiumAdvisor ? 'isPremiumAdvisor' : 'isEnterpriseAdmin', 
          channelId: channelId, 
          beforeToken: null, 
          afterToken: null,
          pendingArticles: myContentPendingQueue,
        },
        localData: {
          currentArticles: [],
        },
        source: 'campaign',
      })
      .then(res => {
        setMyArticlesLoader(false);
        const newState = get(res, 'newState', {})
        if(!isEmpty(newState))
          campaignSetState(newState);
      })
      .catch(err => {
        setMyArticlesLoader(false);
        console.log('******* ERROR my-content-articles [CREATE CAMPAIGN] ******');
      })
    }

    // if (isEmpty(myContentArticles)) {
      // if (isPremiumAdvisor) {
      //   console.log('object1');
      //   //  setLoading(true);
      //   setMyArticlesLoader(true);
      //   getPremiumAdvisorChannelId()
      //     .then((res) => {
      //       // console.log('RESPONSE ');
      //       if (res && res.result && res.result.data) {
      //         const channel_id =
      //           res.result.data[Object.keys(res.result.data)[0]][0].id;
      //         getContentLibrary(channel_id)
      //           .then((res) => {
      //             //  setLoading(false);

      //             if (
      //               res.result &&
      //               res.result.data &&
      //               res.result.data.channel_content
      //             ) {
      //               // console.log(res);
      //               let allMyContentArticles = getContentLibArticleData(
      //                 res.result.data,
      //                 'campaign'
      //               );
      //               if (allMyContentArticles.length > 0) {
      //                 campaignSetState({
      //                   myContentArticles: allMyContentArticles,
      //                 });
      //               }
      //             }
      //             setMyArticlesLoader(false);
      //           })
      //           .catch((err) => {
      //             //  setLoading(false);
      //             setMyArticlesLoader(false);
      //             console.log(err);
      //           });
      //       }
      //     })
      //     .catch((err) => {
      //       //  setLoading(false);
      //       setMyArticlesLoader(false);
      //       console.log('ERROR - - - - ', err);
      //     });
      // } 
      // else {
      //   setMyArticlesLoader(true);
      //   getContentLibrary(channelId)
      //     .then((res) => {
      //       // console.log('object2');
      //       //  setLoading(false);
      //       let allMyContentArticles = [];
      //       if (
      //         res.result &&
      //         res.result.data &&
      //         res.result.data.channel_content
      //       ) {
      //         // console.log(res);
      //         allMyContentArticles = getContentLibArticleData(
      //           res.result.data,
      //           'campaign'
      //         );
      //       }

      //       if (isEnterpriseAdvisor) {
      //         getApproveToShareArticles({id: '', source: 'Approve To Share'}).then(
      //           (atsData) => {
      //             // console.log('data ats', atsData);

      //             let atsArticles =
      //               atsData.result && atsData.result.data
      //                 ? atsData.result.data
      //                 : [];
      //             // console.log('atsArticles', atsArticles);
      //             // console.log('allMyContentArticles', allMyContentArticles);
      //             let sortedArticles = uniqBy(
      //               [
      //                 ...allMyContentArticles,
      //                 ...approvedToShareArticlesData(atsArticles),
      //               ].sort(
      //                 (d1, d2) =>
      //                   new Date(d2.created_time).getTime() -
      //                   new Date(d1.created_time).getTime()
      //               ),
      //               'id'
      //             );
      //             campaignSetState({
      //               myContentArticles: sortedArticles,
      //             });
      //             // console.log('sortedArticles', sortedArticles);
      //             setMyArticlesLoader(false);
      //           }
      //         );
      //       } else {
      //         if (allMyContentArticles.length > 0) {
      //           campaignSetState({
      //             myContentArticles: allMyContentArticles,
      //           });
      //         }
      //         setMyArticlesLoader(false);
      //       }
      //     })
      //     .catch((err) => {
      //       //  setLoading(false);
      //       setMyArticlesLoader(false);
      //       console.log(err);
      //     });
      // }
    // }
  }, []);

  useEffect(() => {
    getUserDetails()
      .then((res) => {
        console.log('USER DETAILS RESPONSE -- - - -', res);
        if (res?.result?.data) setFetchedUserData(res.result.data);
      })
      .catch((err) => console.log('ERROR - - - -- ', err));
  }, [createMode]);

  const commonDivStyles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  };

  useEffect(() => {
    // For automated campaigns.
    if (
      automatedCampaigns &&
      Object.keys(automatedCampaigns).length > 0 &&
      automatedCampaigns.type
    ) {
      curFlowHandler(automatedCampaigns.type);
      platformMapper(curFlow);
    }
    getCustomRows('signature')
      .then((response) => {
        // console.log('CUSTOM ROWS RESPONSE - - - - -', response);
        if (get(response, 'data', []).length > 0) toggleBlockCreate(false);
      })
      .catch((err) => console.log('ERR- - -- - - ', err));
  }, []);

  useEffect(() => {
    campaignSetState({
      bookmarkArticles: bookmarkArticles,
      recommendedArticles: recommendedArticles,
      myContentArticles: myContentArticles,
    });
  }, [bookmarkArticles, recommendedArticles, myContentArticles]);

  const resetAutomatedCampaignState = () =>
    campaignSetState({
      automatedCampaigns: {},
      selectedBlock: {},
      selectedBlockId: '',
      forAutomatedCampaigns: false,
      automatedFlowFromDashboard: false,
      landingPages: [],
      segmentList: []
    });


  const platformMapper = (curflow) => {
    if (curFlow === 'emailOnly') {
      return (
        // <CreateEmailContainer
        //     campaignId={campaignId}
        //     setTemplateData={setTemplateData}
        //   setTemplateBase={setTemplateBase}
        //   selectedIndex={createSelectedIndex}
        //   campaignSetState={campaignSetState}
        //   defaultTemplates={defaultTemplates}
        // />
        <EmailOnlyContainer
          createMode={createMode}
          curFlowHandler={curFlowHandler}
          socialNetworkData={socialNetworkData}
          contactGroups={contactGroups}
          isPremiumAdvisor={isPremiumAdvisor}
          userData={fetchedUserData}
          campaignSetState={campaignSetState}
          campaignSelectedArticles={campaignSelectedArticles}
          setObjectivesTabData={setObjectivesTabData}
          campaignCreateFlow={campaignCreateFlow}
          setTemplateData={setTemplateData}
          setTemplateBase={setTemplateBase}
          campaignScheduleTimings={campaignScheduleTimings}
          campaignScheduleTypes={campaignScheduleTypes}
          themeNames={themeNames}
          getAccountId={getAccountId}
          campaignId={campaignId}
          endCreateFlow={endCreateFlow}
          redirectionCampaign={redirectionCampaign}
          savedTemplate={savedTemplate}
          selectedIndex={createSelectedIndex}
          isEdit={isEdit}
          isFromAddCampaign={isFromAddCampaign}
          series={series}
          seriesId={seriesId}
          defaultTemplates={defaultTemplates}
          isadvMode={isadvMode}
          isAdvisorApprovalMode={isAdvisorApprovalMode}
          campaignStatus={campaignStatus}
          userRole={userRole}
          campaignLeadReferralData={campaignLeadReferralData}
          bookmarkArticlesLoader={bookmarkArticlesLoader}
          recommendedArticlesLoader={recommendedArticlesLoader}
          myArticlesLoader={myArticlesLoader}
          complianceApplicable={complianceApplicable}
          complianceApproved={complianceApproved}
          emAdvApproval={emAdvApproval}
          conListRecArticlesLoader={conListRecArticlesLoader}
          setConListRecArticlesLoader={setConListRecArticlesLoader}
          fromDashboard={fromDashboard}
          reviewCampaign={reviewCampaign}
          redirectCascadeAdvisor={redirectCascadeAdvisor}
          isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          isFirmLevelAdmin={isFirmLevelAdmin}
          firmSettings={firmSettings}
          firmsArray={firmsArray}
          isAdmin={isAdmin}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          loginRequest={loginRequest}
          segmentList={segmentList}
          setSegmentList={setSegmentList}
          isGlobalCompliance={isGlobalCompliance}
          companyId={companyId}
        />
      );
    } else if (curflow === 'socialOnly') {
      return (
        <SocialOnlyContainer
          createMode={createMode}
          curFlowHandler={curFlowHandler}
          socialNetworkData={socialNetworkData}
          isPremiumAdvisor={isPremiumAdvisor}
          userData={fetchedUserData}
          campaignSelectedArticles={campaignSelectedArticles}
          contactGroups={contactGroups}
          setCreateFlow={setCreateFlow}
          setCreateTabs={setCreateTabs}
          campaignSetState={campaignSetState}
          campaignCreateFlow={campaignCreateFlow}
          setObjectivesTabData={setObjectivesTabData}
          getAccountId={getAccountId}
          campaignId={campaignId}
          endCreateFlow={endCreateFlow}
          redirectionCampaign={redirectionCampaign}
          isEdit={isEdit}
          campaignSummaries={campaignSummaries}
          campaignScheduleTimings={campaignScheduleTimings}
          campaignScheduleTypes={campaignScheduleTypes}
          isFromAddCampaign={isFromAddCampaign}
          series={series}
          seriesId={seriesId}
          campaignLeadReferralData={campaignLeadReferralData}
          isadvMode={isadvMode}
          isAdvisorApprovalMode={isAdvisorApprovalMode}
          campaignStatus={campaignStatus}
          bookmarkArticlesLoader={bookmarkArticlesLoader}
          recommendedArticlesLoader={recommendedArticlesLoader}
          myArticlesLoader={myArticlesLoader}
          checkboxStatus={checkboxStatus}
          socialSelectedCampId={socialSelectedCampId}
          complianceApplicable={complianceApplicable}
          complianceApproved={complianceApproved}
          socialAdvApproval={socialAdvApproval}
          userRole={userRole}
          fromDashboard={fromDashboard}
          reviewCampaign={reviewCampaign}
          redirectCascadeAdvisor={redirectCascadeAdvisor}
          isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          isFirmLevelAdmin={isFirmLevelAdmin}
          firmSettings={firmSettings}
          firmsArray={firmsArray}
          isAdmin={isAdmin}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          loginRequest={loginRequest}
          isGlobalCompliance={isGlobalCompliance}
          companyId={companyId}
        />
      );
    } else if (Object.values(AUTOMATED_CAMPAIGN_ACTIONS).includes(curFlow)) {
      return (
        <AutomatedCampaignsHome
          currentFlow={curFlow}
          campaignSetState={campaignSetState}
          userData={userData}
          // campaignId={campaignId}
          // setTemplateData={setTemplateData}
          // setTemplateBase={setTemplateBase}
          // selectedEmailCreationIndex={createSelectedIndex}
          // defaultTemplates={defaultTemplates}
        />
      );
    } else
      return (
        <AllPlatformsContainer
          createMode={createMode}
          curFlowHandler={curFlowHandler}
          socialNetworkData={socialNetworkData}
          contactGroups={contactGroups}
          isPremiumAdvisor={isPremiumAdvisor}
          userData={fetchedUserData}
          campaignSetState={campaignSetState}
          campaignSelectedArticles={campaignSelectedArticles}
          setObjectivesTabData={setObjectivesTabData}
          campaignCreateFlow={campaignCreateFlow}
          setTemplateData={setTemplateData}
          setTemplateBase={setTemplateBase}
          campaignScheduleTimings={campaignScheduleTimings}
          campaignScheduleTypes={campaignScheduleTypes}
          themeNames={themeNames}
          getAccountId={getAccountId}
          campaignId={campaignId}
          endCreateFlow={endCreateFlow}
          redirectionCampaign={redirectionCampaign}
          savedTemplate={savedTemplate}
          defaultTemplates={defaultTemplates}
          selectedIndex={createSelectedIndex}
          isEdit={isEdit}
          isFromAddCampaign={isFromAddCampaign}
          series={series}
          campaignSummaries={campaignSummaries}
          curFlow={curFlow}
          seriesId={seriesId}
          isadvMode={isadvMode}
          isAdvisorApprovalMode={isAdvisorApprovalMode}
          campaignStatus={campaignStatus}
          userRole={userRole}
          campaignLeadReferralData={campaignLeadReferralData}
          bookmarkArticlesLoader={bookmarkArticlesLoader}
          recommendedArticlesLoader={recommendedArticlesLoader}
          myArticlesLoader={myArticlesLoader}
          checkboxStatus={checkboxStatus}
          socialSelectedCampId={socialSelectedCampId}
          complianceApplicable={complianceApplicable}
          complianceApproved={complianceApproved}
          emAdvApproval={emAdvApproval}
          socialAdvApproval={socialAdvApproval}
          conListRecArticlesLoader={conListRecArticlesLoader}
          setConListRecArticlesLoader={setConListRecArticlesLoader}
          fromDashboard={fromDashboard}
          reviewCampaign={reviewCampaign}
          redirectCascadeAdvisor={redirectCascadeAdvisor}
          isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          isFirmLevelAdmin={isFirmLevelAdmin}
          firmSettings={firmSettings}
          firmsArray={firmsArray}
          isAdmin={isAdmin}
          isEnterpriseAdvisor={isEnterpriseAdvisor}
          loginRequest={loginRequest}
          segmentList={segmentList}
          setSegmentList={setSegmentList}
          isGlobalCompliance={isGlobalCompliance}
          companyId={companyId}
        />
      );
  };

  return (
    <>
      {!createMode && !get(reviewCampaign, 'fromReview', false) && (
        <Flex justifyContent="center" alignItems="center">
          <CreateContainer>
            <TitleSpan>Create A Campaign</TitleSpan>
            <Flex
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
            >
              <CreateContainerCards>
                <CardText>
                  <CardTitle>One Time</CardTitle>
                  <CardSubtitle style={{ textAlign: 'left' }}>
                    Select this option if you want to create a one-time
                    campaign.
                  </CardSubtitle>
                </CardText>
                <Button
                  borderRadius="5px"
                  id="btn_one_time"
                  bgColor="#816EF8"
                  onClick={() => {
                    // setCreateMode('oneTime');
                    campaignSetState({
                      selectedIndex: 0,
                      createMode: 'oneTime',
                      curFlow: '',
                    });
                  }}
                >
                  Create
                </Button>
              </CreateContainerCards>
              <CreateContainerCards>
                <CardText>
                  <CardTitle>Series</CardTitle>
                  <CardSubtitle style={{ textAlign: 'left' }}>
                    Select this option if you want to create a series of
                    campaigns which you want to group together logically.
                  </CardSubtitle>
                </CardText>
                <Button
                  id="btn_series"
                  borderRadius="5px"
                  bgColor="#816EF8"
                  onClick={() => {
                    // setCreateMode('series');
                    campaignSetState({
                      selectedIndex: 0,
                      createMode: 'series',
                      curFlow: '',
                    });
                  }}
                >
                  Create
                </Button>
              </CreateContainerCards>
              <CreateContainerCards>
                <CardText>
                  <CardTitle>Automated</CardTitle>
                  <CardSubtitle style={{ textAlign: 'left' }}>
                    Select this option if you want to create an automated drip
                    campaign.
                  </CardSubtitle>
                </CardText>
                <Button
                  id="btn_series"
                  borderRadius="5px"
                  bgColor="#816EF8"
                  onClick={() => {
                    // setCreateMode('series');
                    campaignSetState({
                      selectedIndex: 0,
                      createMode: 'automated',
                      curFlow: '',
                    });
                  }}
                >
                  Create
                </Button>
              </CreateContainerCards>
            </Flex>
          </CreateContainer>
        </Flex>
      )}
      {/* {console.log(
        'CREATE MODE -- - - -',
        createMode,
        'CUR FLOW -- - - -',
        curFlow
      )} */}
      {createMode && (
        <Flex justifyContent="center" alignItems="center">
          {curFlow?.length === 0 ? (
            createMode === 'automated' ? (
              <CreateContainer
                justifyContent="start"
                paddingTop="30px"
                height="auto"
              >
                <TitleSpan>Choose an automated campaign</TitleSpan>
                <CardSubtitle>
                  Select a type of automated drip campaign to customize.
                </CardSubtitle>
                <Flex
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                  width="65%"
                >
                  <CreateContainerCards>
                    <CardText>
                      <CardTitle>Welcome Clients</CardTitle>
                      <CardSubtitle>
                        Send a series of communications to new clients.
                      </CardSubtitle>
                    </CardText>

                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <div
                        style={{
                          ...commonDivStyles,
                          position: 'relative',
                          marginLeft: blockCreate ? '20px' : '',
                        }}
                      >
                        <Button
                          borderRadius="5px"
                          onClick={() => {
                            resetAutomatedCampaignState();
                            curFlowHandler(
                              AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS
                            );
                          }}
                          id="btn_welcome_clients"
                        >
                          Select
                        </Button>
                      </div>
                    </Flex>
                  </CreateContainerCards>
                  <CreateContainerCards>
                    <CardText>
                      <CardTitle>Nurture Prospects</CardTitle>
                      <CardSubtitle>Engage new prospects.</CardSubtitle>
                    </CardText>
                    <Button
                      borderRadius="5px"
                      onClick={() => {
                        resetAutomatedCampaignState();
                        curFlowHandler(
                          AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS
                        );
                      }}
                      id="btn_nurture_prospects"
                    >
                      Select
                    </Button>
                  </CreateContainerCards>
                  <CreateContainerCards>
                    <CardText>
                      <CardTitle>Event</CardTitle>
                      <CardSubtitle>
                        Promote and invite contacts to a webinar or live event.
                      </CardSubtitle>
                    </CardText>

                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <div
                        style={{
                          ...commonDivStyles,
                          position: 'relative',
                          marginLeft: blockCreate ? '20px' : '',
                        }}
                      >
                        <Button
                          borderRadius="5px"
                          onClick={() => {
                            resetAutomatedCampaignState();
                            curFlowHandler(AUTOMATED_CAMPAIGN_ACTIONS.EVENT);
                          }}
                          id="btn_event"
                        >
                          Select
                        </Button>
                      </div>
                    </Flex>
                  </CreateContainerCards>
                  <CreateContainerCards>
                    <CardText>
                      <CardTitle>Newsletters</CardTitle>
                      <CardSubtitle>
                        Send periodic newsletters to subscribers.
                      </CardSubtitle>
                    </CardText>

                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <div
                        style={{
                          ...commonDivStyles,
                          position: 'relative',
                          marginLeft: blockCreate ? '20px' : '',
                        }}
                      >
                        <Button
                          borderRadius="5px"
                          onClick={() => {
                            resetAutomatedCampaignState();
                            curFlowHandler(
                              AUTOMATED_CAMPAIGN_ACTIONS.NEWSLETTER
                            );
                          }}
                          disabled={true}
                          id="btn_newsletters"
                        >
                          {/* Select */}
                          Coming Soon...
                        </Button>
                      </div>
                    </Flex>
                  </CreateContainerCards>
                </Flex>
              </CreateContainer>
            ) : (
              <CreateContainer>
                <TitleSpan>
                  {isSeries ? 'Congrats!' : 'Create A Campaign'}
                </TitleSpan>
                {isSeries && (
                  <CardSubtitle>
                    The first campaign in the series has been scheduled. Click
                    below to create the next campaign.
                  </CardSubtitle>
                )}
                <Flex
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <CreateContainerCards>
                    <CardText>
                      <CardTitle>Email Only</CardTitle>
                      <CardSubtitle>Email only campaign</CardSubtitle>
                    </CardText>

                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <div
                        style={{
                          ...commonDivStyles,
                          position: 'relative',
                          marginLeft: blockCreate ? '20px' : '',
                        }}
                      >
                        <Button
                          borderRadius="5px"
                          bgColor="#816EF8"
                          onClick={() =>
                            campaignSetState({ curFlow: 'emailOnly' })
                          }
                          id="btn_email_only"
                          disabled={blockCreate}
                        >
                          Select
                        </Button>
                        {blockCreate && (
                          <div className="new_tooltip">
                            <img
                              src="/assets/images/information-grey.svg"
                              alt="info tooltip"
                              style={{
                                width: '15px',
                                height: '13px',
                                cursor: 'pointer',
                                marginLeft: '10px',
                                marginTop: '25px',
                              }}
                            />
                            <span
                              className="newtooltiptext"
                              style={{ fontSize: '0.6em' }}
                            >
                              Create personalised email (signature is mandatory)
                              custom rows from manage rows.
                            </span>
                          </div>
                        )}
                      </div>
                    </Flex>
                  </CreateContainerCards>
                  <CreateContainerCards>
                    <CardText>
                      <CardTitle>Social Only</CardTitle>
                      <CardSubtitle>
                        Social post only to one or more social networks
                      </CardSubtitle>
                    </CardText>
                    <Button
                      borderRadius="5px"
                      bgColor="#816EF8"
                      onClick={() =>
                        campaignSetState({ curFlow: 'socialOnly' })
                      }
                      id="btn_social_only"
                    >
                      Select
                    </Button>
                  </CreateContainerCards>
                  <CreateContainerCards>
                    <CardText>
                      <CardTitle>Both Email & Social</CardTitle>
                      <CardSubtitle>
                        Campaign includes an email sent & posts to social
                      </CardSubtitle>
                    </CardText>

                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <div
                        style={{
                          ...commonDivStyles,
                          position: 'relative',
                          marginLeft: blockCreate ? '20px' : '',
                        }}
                      >
                        <Button
                          borderRadius="5px"
                          bgColor="#816EF8"
                          onClick={() => curFlowHandler('both')}
                          id="btn_both"
                          disabled={blockCreate}
                        >
                          Select
                        </Button>
                        {blockCreate && (
                          <div className="new_tooltip">
                            <img
                              src="/assets/images/information-grey.svg"
                              alt="info tooltip"
                              style={{
                                width: '15px',
                                height: '13px',
                                cursor: 'pointer',
                                marginLeft: '10px',
                                marginTop: '25px',
                              }}
                            />
                            <span
                              className="newtooltiptext"
                              style={{ fontSize: '0.6em' }}
                            >
                              Create personalised email (signature is mandatory)
                              custom rows from manage rows.
                            </span>
                          </div>
                        )}
                      </div>
                    </Flex>
                  </CreateContainerCards>
                </Flex>
                {/* {cmode && cmode === 'series' && (
                 */}
                {isSeries && (
                  <Flex>
                    <Button
                      borderRadius="5px"
                      padding="7px 100px"
                      onClick={() => {
                        history.push('/campaignV2');
                      }}
                    >
                      I'm Done
                    </Button>
                  </Flex>
                )}
                {/* <Flex width="100%">
                <ChangeLabel onClick={() => setCreateMode(false)}>
                  Back
                </ChangeLabel>
              </Flex> */}
              </CreateContainer>
            )
          ) : (
            platformMapper(curFlow)
          )}
        </Flex>
      )}

      {!createMode &&
        get(reviewCampaign, 'fromReview', false) &&
        !isEmpty(get(reviewCampaign, 'campaigns', [])) && (
          <CampaignNextCongrats
            reviewCampaign={reviewCampaign}
            detailsHandler={detailsHandler}
          />
        )}

      {!createMode &&
        get(reviewCampaign, 'fromReview', false) &&
        isEmpty(get(reviewCampaign, 'campaigns', [])) && (
          <CampaignCompleteCongrats endCreateFlow={endCreateFlow} />
        )}
      {(!createMode || !curFlow) && (
        <FooterBtnsWrapper>
          <CreateCampaignFooter
            backHandler={() => {
              createMode
                ? campaignSetState({ createMode: false })
                : history.push('/campaignV2');
            }}
            padding="10px 0px"
          />
        </FooterBtnsWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  themeNames: state.theme.themeNames,
  contentLibArticles: state.contentlib.data,
  createSelectedIndex: state.campaign.selectedIndex,
  createMode: state.campaign.createMode,
  curFlow: state.campaign.curFlow,
  bookmarkArticles: state.campaign.bookmarkArticles,
  recommendedArticles: state.campaign.recommendedArticles,
  myContentArticles: state.campaign.myContentArticles,
  myContentPendingQueue: get(state, 'campaign.pendingArticles', []),
  isEdit: state.campaign.isEdit,
  campaignSummaries: state.campaign.summaries,
  campaignScheduleTimings: state.campaign.scheduleTimings,
  campaignScheduleTypes: state.campaign.scheduleTypes,
  series: state.campaign.series,
  seriesId: state.campaign.seriesId,
  userRole: getUserRole(state),
  campaign: state.campaign,
  campaignLeadReferralData: state.campaign.leadGenReferralData,
  checkboxStatus: state.campaign.checkbox_status,
  complianceApplicable: state.campaign.complianceApplicable,
  complianceApproved: state.campaign.complianceApproved,
  emAdvApproval: state.campaign.emAdvApproval,
  socialAdvApproval: state.campaign.socialAdvApproval,
  fromDashboard: get(state, 'campaign.fromDashboard', false),
  reviewCampaign: get(state, 'campaign.reviewCampaign', {}),
  fromCreateEmail: state.campaign.fromCreateEmail,
  automatedCampaigns: state.campaign.automatedCampaigns,
  isGlobalCompliance: get(state, 'campaign.is_global_compliance', 'false')

});

const mapDispatchToProps = {
  loginRequest: loginActions.api.login.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaign);
