import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { Button, RichTextEditor } from '../../../components';
import { CampaignModal } from '../PlanCampaign/PlanCampaign.styles';
import { SetObjectivesInput } from '../SetObjectives/SetObjectives.styles';
import CreatableSelect from 'react-select/creatable';
import { formatNewsletterDropdownItem, themesData } from '../../../utils';
import { FileUploadButton } from '@zhifez/react-file-uploader';
import { toBase64 } from '../../../utils';
import { get } from 'lodash';
import { FrolaRichTextEditor } from '../../../components/RichTextEditor/FroalaRichTextEditor';
import { ToggleButton } from '../../ConfigureContainer/screenConfigure.styles';

export const EmailModal = ({
  isOpen,
  handleModalClose,
  saveHandler,
  article,
}) => {
  console.log('EMAIL MODAL article - - - --', article);

  const [salutation, setSalutation] = useState({
    label: 'First Name',
    value: '<first_name>',
  });
  const [greeting, setGreeting] = useState('Hello');
  const [fileErrorMsg, setFileErrorMsg] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [uploadedFile, setUploadedFile] = useState('');

  const salutationTextOptions = [
    { label: 'First Name', value: '<first_name>' },
    { label: 'Last Name', value: '<last_name>' },
    { label: 'Full Name', value: '<full_name>' },
  ];

  useEffect(() => {
    if (article.email_data) {
      setSalutation(
        formatNewsletterDropdownItem(get(article.email_data, 'salutation_text', ''))
      );
      setGreeting(get(article.email_data, 'salutation', 'Hello'));
      setMessage(get(article.email_data, 'message', ''));
      setSubject(get(article.email_data, 'subject', ''));
      setUploadedFile(get(article, 'media1', ''));
    }
  }, []);

  const onFileUploadChange = (file) => {
    toBase64(file[0])
      .then((res) => {
        console.log('BASE 64 - - -', res);
        setUploadedFile(res);
      })
      .catch((err) => console.log(err));
  };

  const styles = {
    container: (base) => ({
      ...base,
      position: 'relative',
      top: '-1px',
      left: '-15px',
    }),
    control: (base) => ({
      ...base,
      minHeight: 14,
      width: '260px',
      marginLeft: '10px',
      background: 'transparent',
      fontSize: '12px',
      height: '35px',
    }),
    option: (base) => ({
      ...base,
      fontSize: '12px',
      width: '260px',
      textAlign: 'left',
    }),
    menu: (base) => ({
      ...base,
      width: '260px',
      marginLeft: '10px',
      zIndex: '3000',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      color: '#848484',
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const onSave = () => {
    const data = {
      subject: subject,
      salutation: greeting,
      salutation_text: salutation.value,
      message: message,
      placeholderText: "Type your email intro message here..."
    };
    console.log('DATA - - -- ', { data, uploadedImg: uploadedFile });
    saveHandler({ data, uploadedImg: uploadedFile });
  };

  return (
    <CampaignModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={handleModalClose}
      padding="0px"
      bgColor="#fff"
      width="60%"
    >
      <Flex width="100%" flexDirection="column">
        <Flex
          padding="20px"
          width="100%"
          justifyContent="space-between"
          style={{ borderBottom: '1px solid #CFD9E4' }}
        >
          <Flex color="#000000" fontSize="14px">
            Customize Email | 10:30 AM
          </Flex>
          <Flex>
            <FontAwesomeIcon
              color="#000000"
              icon={faTimes}
              cursor="pointer"
              onClick={handleModalClose}
            />
          </Flex>
        </Flex>
        <Flex
          width="100%"
          padding="20px 20px 5px"
          color="#000000"
          fontSize="16px"
        >
          Subject
        </Flex>
        <Flex width="100%" padding="0px 20px" color="#000000" fontSize="16px">
          <SetObjectivesInput
            placeholder="Enter here"
            width="25%"
            height="35px"
            onChange={(e) => setSubject(e.target.value)}
            defaultValue={subject}
          />
        </Flex>
        <Flex
          width="100%"
          justifyContent="space-between"
          padding="20px 20px 5px"
          color="#000000"
          fontSize="16px"
        >
          <Flex>
            Greetings (Optional)
            {/* <ToggleButton
                className="react-switch"
                onChange={() => setShowToggle(!showToggle)}
                checked={showToggle}
                onColor="#6351ed"
                offColor="#cccccc"
                offHandleColor="#fff"
                onHandleColor="#fff"
                handleDiameter={10}
                uncheckedIcon=""
                checkedIcon=""
                boxShadow=""
                activeBoxShadow=""
                type="autoPost"
                top="-7px"
                // left="5px"
            /> */}
          </Flex>
          {/* <Flex>Uploaded Image (Optional)</Flex> */}
        </Flex>
        <Flex
          width="100%"
          justifyContent="space-between"
          padding="0px 20px"
          color="#000000"
          fontSize="16px"
        >
          <SetObjectivesInput
            placeholder="Enter here"
            width="30%"
            height="35px"
            onChange={(e) => setGreeting(e.target.value)}
            defaultValue={greeting}
          />

          <Flex width="30%">
            {console.log('SALUTATION VALUE - --  ', salutation)}
            <CreatableSelect
              isClearable
              onChange={(value) => setSalutation(value)}
              options={salutationTextOptions}
              styles={styles}
              value={salutation}
              isDisabled={false}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: themesData.purpleishBlue,
                },
              })}
            />
          </Flex>
          <div style={{ width: '210px' }}></div>
          {/* <Flex width="26%">
                        <FileUploadButton
                            type="button"
                            name='logoUpload'
                            style={{
                                fontSize: '12px',
                                backgroundColor: '#F6F6F6',
                                border: 'dashed 1px #607D8B',
                                width: '210px',
                                cursor: 'pointer'
                            }}
                            onChange={(file) => onFileUploadChange(file)}
                            background="#6351ed"
                            id='logoUpload'
                            label={<div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <img src="/assets/images/upload.svg" alt="upload" />
                                <div style={{color: '#607D8B', paddingLeft: '10px'}}>jpg, png, bmp</div>
                            </div>}
                            accept=".png,.jpeg,.jpg,.svg,.bmp"
                          
                            maxSizeMB={10}
                            onError={(msg) => setFileErrorMsg(msg)}
                        />
                    </Flex> */}
        </Flex>
        <Flex
          width="100%"
          fontSize="18px"
          color="#000000"
          marginTop="10px"
          padding="10px 20px"
        >
          Message
        </Flex>
        <Flex
          className="froala-wrapper-div"
          width="100%"
          padding="5px 20px"
          height="255px"
          style={{ fontWeight: '100', color: '#000' }}
        >
          {/* <RichTextEditor
                        id='message'
                        placeHolder={'Enter here'}
                        name='message'
                        isAdmin
                        value={message}
                        setValue={value => setMessage(value)}
                        styles={{width: '100%', height: '165px'}}
                    /> */}
          <FrolaRichTextEditor
            content={message}
            setContent={setMessage}
            placeholder="Enter here"
            minHeight={149}
            maxHeight={160}
          />
        </Flex>
        <Flex
          mt="15px"
          padding="20px"
          justifyContent="flex-end"
          style={{ borderTop: '1px solid #CFD9E4' }}
        >
          <Button
            margin="0px"
            color="#fff"
            bgColor="#6250ED"
            styles={{ borderRadius: '4px' }}
            onClick={onSave}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </CampaignModal>
  );
};

export default EmailModal;
