import styled from 'styled-components';
import Modal from 'react-modal';

export const PlanCampaignWrapper = styled.div.attrs({
  className: 'plan-campaign-wrapper',
})`
  display: flex;
  flex-direction: column;
  height: 90%;
  background-color: #fff;
  padding: 10px 30px;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }

  .react-datepicker-popper {
    left: 96px !important;
  }

  .react-datepicker {
    zoom: 1.2;
  }
`;

export const CampaignModal = styled(Modal)`
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 12;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  background-color: ${({ bgColor }) => bgColor || '#F6F9FB'};
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: ${({ width }) => width || '50%'};
  text-align: center;
  padding: ${({ padding }) => padding || '40px'};
  font-weight: bold;
  @media (max-width: 768px) {
    width: 34%;
  }
`;

export const WeekdayTiles = styled.span.attrs({
  className: 'week-day-tiles',
})`
  width: auto;
  padding: 5px 10px;
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  margin-right: 15px;
  border-radius: 8px;
  border: 1px solid #6250ed;
  color: ${({ color }) => color || '#6250ED'};
  font-size: 12px;
  cursor: pointer;
`;

export const ContentSection = styled.div.attrs({
  className: 'content-section',
})`
  dipslay: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const ContentPanel = styled.div.attrs({
  className: 'content-panel',
})`
  display: flex;
  align-items: center;
  background-color: #f6f9fb;
  height: 50px;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 1px solid #cfd9e4;
  border-radius: 4px;
  justify-content: space-between;
`;

export const ContentPanelSection = styled.div.attrs({
  className: 'content-panel-section',
})`
  background-color: #fff;
  flex-direction: column;
`;

export const DayPanel = styled.div.attrs({
  className: 'day-panel',
})`
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
`;

export const DayPanelContentSection = styled.div.attrs({
  className: 'day-panel-content-section',
})`
  display: none;
`;

export const CampaignCardWrapper = styled.div.attrs({
  className: 'campaign-card-wrapper',
})`
  display: flex;
  background-color: #687e880f;
  width: 250px;
  height: ${({ isClickable }) => (isClickable ? '280px' : '325px')};
  min-width: 250px;
  min-height: ${({ isClickable }) => (isClickable ? '280px' : '325px')};
  border-radius: 10px;
  flex-direction: column;
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : '')};
`;

export const ContentModalTab = styled.div`
  border-radius: 4px;
  color: ${({ active }) => (active ? '#fff' : '#6351ed')};
  background-color: ${({ active }) => (active ? '#6351ed' : '#fff')};
  width: 33%;
  padding: 5px;
  cursor: pointer;
`;

export const DashedHorizontalSeparator = styled.div`
  width: 100%;
  border-top: 1px dashed #cfd9e4;
`;

export const LineHorizontalSeparator = styled.div`
  width: 100%;
  border-top: 1px solid #cfd9e4;
`;

export const CampaignCardTooltip = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  color: #fff;
  background-color: #000;
  border: 1px solid #fff;
  z-index: 2;
  font-family: 'Poppins';
  font-size: 10px;
  width: auto;
  height: auto;
  text-align: left;
`;
