import { Flex } from '@rebass/grid';
import { get } from 'lodash';
import React from 'react';
import { LoaderWrapper } from '../../../components';

const AddContentCustomRow = ({ 
    layout, 
    step, 
    loader, 
    customRows, 
    selectedLandingPage, 
    selectedCustomRow, 
    setSelectedCustomRow, 
    setSelectedLandingPage,
    createMode,
    automatedFlowFromDashboard,
    selectedPage

}) => {
    return (
        <div>
            <Flex width='100%' justifyContent='center' alignItems='center'>
                {layout === 'landing-pages' && step === 1 ? 'Select A Landing Page' : 'Select Custom Row Style'}
            </Flex>
            <Flex flexDirection='column' justifyContent='flex-start' alignItems='center' style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <LoaderWrapper isLoading={loader}>
                    {customRows.length > 0 ? customRows.map((row, index) => (
                        <Flex
                            style={{
                                cursor: 'pointer',
                                border:
                                    (layout === 'landing-pages' && step === 1 && get(selectedLandingPage, '_id', '') === row._id) ||
                                        selectedCustomRow === row._id ?
                                        '1px solid #6351ed'
                                        : '1px solid #cacaca',
                                borderRadius: '2px',
                                marginBottom: '12px'
                            }}
                            onClick={() =>
                                layout === 'landing-pages' && step === 1 ?
                                    setSelectedLandingPage(row) :
                                    setSelectedCustomRow(row._id)
                            }
                            id={row._id}
                            width='76%'
                            margin='auto'
                            justifyContent='center'
                            alignItems='center'
                            flexDirection='column'
                            htmlFor={`row_${index}`}
                        >
                            <img
                                alt="custom_row"
                                style={{
                                    width: '500px',
                                    height: layout === 'landing-pages' && step === 1 ? '220px' : '150px',
                                    objectFit: 'contain'
                                }}
                                src={row.thumbnail_url}
                            />
                            <div style={{ color: selectedCustomRow === row._id ? '#6351ed' : '' }}>{((createMode === 'automated' || automatedFlowFromDashboard) && layout === 'landing-pages') ? selectedLandingPage.name : row.name}</div>
                        </Flex>
                    )) : (
                        <div>
                            No Rows found for the selectedLayout
                        </div>
                    )}
                </LoaderWrapper>
            </Flex>
        </div>
    );
};

export default AddContentCustomRow;