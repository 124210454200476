/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import AddContent from '../AddContent/AddContent';
import { CreateContainer } from '../CreateCampaign/CreateCampaign.styles';
import SetObjectives from '../SetObjectives/SetObjectives';
import { Stepper } from '../Stepper/Stepper';
import { Flex } from '@rebass/grid';
import { SocialPreview } from '../SocialPreview/SocialPreview';
import { get } from 'lodash';

const SocialOnlyContainer = ({
  createMode,
  socialNetworkData,
  curFlowHandler,
  contactGroups,
  getAccountId,
  campaignId,
  setCreateFlow,
  setCreateTabs,
  campaignSetState,
  campaignCreateFlow,
  setObjectivesTabData,
  endCreateFlow,
  isPremiumAdvisor,
  campaignSelectedArticles,
  redirectionCampaign,
  isEdit,
  campaignSummaries,
  campaignScheduleTimings,
  campaignScheduleTypes,
  isFromAddCampaign,
  series,
  seriesId,
  isadvMode,
  isAdvisorApprovalMode,
  campaignStatus,
  userData,
  campaignLeadReferralData,
  bookmarkArticlesLoader,
  recommendedArticlesLoader,
  myArticlesLoader,
  checkboxStatus,
  socialSelectedCampId,
  complianceApplicable,
  complianceApproved,
  socialAdvApproval,
  userRole,
  fromDashboard,
  reviewCampaign,
  redirectCascadeAdvisor,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  firmsArray,
  isAdmin,
  isEnterpriseAdvisor,
  loginRequest,
  isGlobalCompliance,
  companyId
}) => {
  console.log('isGlobalCompliance', isGlobalCompliance);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [webinar, setWebinar] = useState(false);

  useEffect(() => {
    // console.log('selectedArticles', selectedArticles);
    if (campaignSelectedArticles) setSelectedArticles(campaignSelectedArticles);
  }, []);

  const handleNext = (webinar) => {
      setWebinar(webinar);
      if (selectedIndex < stepList.length - 1) {
        setSelectedIndex(selectedIndex + 1);
      }
    setLoading(false);
  };

  const handleBackButtonClick = () => {
    if (selectedIndex === 0) {
        curFlowHandler('');
      } else{
        setSelectedIndex(selectedIndex - 1);
      }
  };

  const stepList = [
    <span>
      Select
      <br />    
      Recipients
    </span>,
    // <span>
    //   Select
    //   <br />
    //   Content
    // </span>,
    <span>
      Customize &
      <br />
      Schedule
    </span>,
  ];

  // console.log('SOCIAL ONLY CONTAINER');

  return (
    <Flex display="flex" flexDirection="column">
      <Flex display="flex" flexDirection="column" marginBottom="30px" marginTop="80px">
        <div
          style={{
            marginBottom: '4%',
            // overflow: {selectedIndex == 0 ? '380px' : ''},
          }}
        >
          {selectedIndex === 0 && (
            <CreateContainer
              mt="0px"
              height={selectedIndex === 0 ? 'auto' : ''}
              style={{ justifyContent: 'flex-start' }}
            >
              <SetObjectives
                createMode={createMode}
                curFlow="socialOnly"
                contactGroups={contactGroups}
                setCreateFlow={setCreateFlow}
                setCreateTabs={setCreateTabs}
                socialNetworkData={socialNetworkData}
                campaignSetState={campaignSetState}
                campaignCreateFlow={campaignCreateFlow}
                setObjectivesTabData={setObjectivesTabData}
                getAccountId={getAccountId}
                campaignId={campaignId}
                endCreateFlow={endCreateFlow}
                handleNext={handleNext}
                handleBack={handleBackButtonClick}
                isEdit={isEdit}
                isFromAddCampaign={isFromAddCampaign}
                series={series}
                seriesId={seriesId}
                loading={loading}
                setLoading={setLoading}
                campaignStatus={campaignStatus}
                complianceApplicable={complianceApplicable}
                campaignScheduleTypes={campaignScheduleTypes}
                campaignScheduleTimings={campaignScheduleTimings}
                campaignLeadReferralData={campaignLeadReferralData}
                campaignSummaries={campaignSummaries}
                campaignSelectedArticles={campaignSelectedArticles}
                isAdvisorApprovalMode={isAdvisorApprovalMode}
                checkboxStatus={checkboxStatus}
                isadvMode={isadvMode}
                isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
                isFirmLevelAdmin={isFirmLevelAdmin}
                firmSettings={firmSettings}
                firmsArray={firmsArray}
                stepList={stepList}
                selectedIndex={selectedIndex}
              />
            </CreateContainer>
          )}
          {/* {selectedIndex === 1 && (
            <AddContent
              createMode={createMode}
              handleBack={handleBackButtonClick}
              handleNext={handleNext}
              isPremiumAdvisor={isPremiumAdvisor}
              campaignSetState={campaignSetState}
              curFlow="socialOnly"
              selectedArticles={selectedArticles}
              setSelectedArticles={setSelectedArticles}
              endCreateFlow={endCreateFlow}
              bookmarkArticlesLoader={bookmarkArticlesLoader}
              recommendedArticlesLoader={recommendedArticlesLoader}
              myArticlesLoader={myArticlesLoader}
            />
          )} */}
          {selectedIndex === 1 && (
            <SocialPreview
              createMode={createMode}
              curFlow="socialOnly"
              setObjectivesTabData={setObjectivesTabData}
              campaignSelectedArticles={
                get(setObjectivesTabData, 'webinar.isWebinar', false)
                  ? [
                      get(setObjectivesTabData, 'webinar', {}),
                      ...selectedArticles,
                    ]
                  : campaignSelectedArticles
              }
              handleBack={handleBackButtonClick}
              handleNext={handleNext}
              loader={loading}
              getAccountId={getAccountId}
              campaignId={campaignId}
              seriesId={seriesId}
              redirectionCampaign={redirectionCampaign}
              campaignSummaries={campaignSummaries}
              campaignScheduleTimings={campaignScheduleTimings}
              campaignScheduleTypes={campaignScheduleTypes}
              isEdit={isEdit}
              endCreateFlow={endCreateFlow}
              userData={userData}
              campaignLeadReferralData={campaignLeadReferralData}
              isadvMode={isadvMode}
              isAdvisorApprovalMode={isAdvisorApprovalMode}
              campaignStatus={campaignStatus}
              checkboxStatus={checkboxStatus}
              socialSelectedCampId={socialSelectedCampId}
              complianceApplicable={complianceApplicable}
              complianceApproved={complianceApproved}
              socialAdvApproval={socialAdvApproval}
              userRole={userRole}
              fromDashboard={fromDashboard}
              reviewCampaign={reviewCampaign}
              redirectCascadeAdvisor={redirectCascadeAdvisor}
              isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
              isFirmLevelAdmin={isFirmLevelAdmin}
              firmSettings={firmSettings}
              isAdmin={isAdmin}
              isEnterpriseAdvisor={isEnterpriseAdvisor}
              loginRequest={loginRequest}
              stepList={stepList}
              selectedIndex={selectedIndex}
              isGlobalCompliance={isGlobalCompliance}
              companyId={companyId}
            />
          )}
        </div>
      </Flex>
      {/* need to remove while api integration */}
      {/* <FooterBtnsWrapper>
        <CampaignFooterButtons
          enableNext={!handleValidation()}
          nextHandler={handleNext}
          backHandler={handleBackButton}
          loading={loading}
          saveAndCloseHandler={handleSaveAndClose}
          padding="10px 0px"
        />
      </FooterBtnsWrapper> */}
    </Flex>
  );
};

export default SocialOnlyContainer;
