import { Flex } from '@rebass/grid';
import React, { useState } from 'react'
import { OverviewTabHeading } from './EngagementTab.styles';
import { DownloadButtonFlex } from '../AnalyticsContainer.styles';
import { DownloadButton, LoaderWrapper } from '../../../components';
import { isEmpty, get } from 'lodash';
import { saveFile, getTabNameFromRepType, overviewTabSliderSettings } from '../../../utils';
import { parse } from 'json2csv';
import Slider from 'react-slick';
import { ArticleSliderWrapper } from '../../DashboardContainer/DashboardContainer.styles';
import TopLeadArticle from './TopLeadArticle';
import TopShareArticle from './TopShareArticle';
import { getDownloadCsvData } from '../../../services/analytics';
import ExportToCsv from './ExportToCsv';


const OverviewTab = ({ analyticsData, selectedRepType, analyticsObject, isAnalyticsDataFetching, handleRowClick, isAdmin, selectedContacts }) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  console.log('RENDERING OVERVIEW TAB...');
  // const onDownloadCSVClick = () => {
  //   setLoading(true);
  //   const tabName = getTabNameFromRepType(selectedRepType);
  //   const { startDate, endDate } = analyticsObject || {};
  //   const fileName = `${tabName}-${startDate}_${endDate}`;
    
  //   getDownloadCsvData({repType: selectedRepType, startDate, endDate, contact_list: selectedContacts, filters: analyticsObject.filterParams }).then(res => {
  //     saveFile(res, fileName);
  //     setLoading(false);
  //   }).catch(err => {
  //     setLoading(false);
  //   }); 
  // }

  const handleExportToCsv = () => {
    setOpenModal(true);
  }

  const handleCloseExportToCsv = () => {
    setOpenModal(false);
  }
  // const onDownloadCSVClick = () => {
  //   const tabName = getTabNameFromRepType(selectedRepType);
  //   const { startDate, endDate } = analyticsObject || {};
  //   const fileName1 = `${tabName}TopLeads-${startDate}_${endDate}`;
  //   const fileName2 = `${tabName}TopShares-${startDate}_${endDate}`;
  //   const columnName1 = 'Email';
  //   const columnName2 = 'Name';
  //   const columnName3 = 'Engagement Score';
  
  //   const columnName4 = 'Insight';
  //   const columnName5 = 'Publisher';
  //   const columnName6 = 'Engagement Score';
  //   const fieldsOne = [columnName1, columnName2, columnName3];
  //   const fieldsTwo = [columnName4, columnName5, columnName6];
  //   const parsedDataOne = parse(
  //     analyticsData.top_leads.length > 0 && analyticsData.top_leads.map((item, index) => ({
  //       [columnName1]: item.email,
  //       [columnName2]: item.name,
  //       [columnName3]: item.engagement_score,
  //     })), {
  //       fieldsOne
  //     }
  //   )

  //   const parsedDataTwo = parse(
  //     analyticsData.top_shares.length > 0 && analyticsData.top_shares.map((item, index) => ({
  //       [columnName4]: item.title,
  //       [columnName5]: item.publisher.name,
  //       [columnName6]: item.engagement_score,
  //     })), {
  //       fieldsTwo
  //     }
  //   )
  
  //   if(!isEmpty(get(analyticsData, 'overview', {}))) {
  //     saveFile(parsedDataOne, fileName1);
  //     saveFile(parsedDataTwo, fileName2);
  //   }
  // }
  return (
    <Flex width='1' flexDirection='column'>
      <Flex width='1'>
        <OverviewTabHeading>
          Most Engaged Clients/Prospects And Suggested Investments
        </OverviewTabHeading>
      </Flex>  
      <Flex width='100%' style={{height: '200px'}} >
        {
          !isEmpty(get(analyticsData, 'top_leads')) && !isEmpty(get(analyticsData, 'top_leads', []).filter(l => l.engagement_score >= 0)) ? get(analyticsData, 'top_leads', []).filter(l => l.engagement_score >= 0).map((item, index) => (
            <TopLeadArticle key={'top_lead_article_'+ index} {...item} handleRowClick={handleRowClick}/>
          ))
          : 
          <div style={{margin: 'auto', fontSize: '12px'}}>No Records Found</div>
        }
      </Flex>
      <Flex width='100%' mt='40px'>
        <OverviewTabHeading>
          Most Engaging Content And Suggested Investments
        </OverviewTabHeading>
      </Flex>
      <Flex width='100%' style={{height: '200px'}}>
        {
          !isEmpty(get(analyticsData, 'top_shares')) && !isEmpty(get(analyticsData, 'top_shares', []).filter(l => l.engagement_score >= 0)) ? get(analyticsData, 'top_shares', []).filter(s => s.engagement_score >= 0).map((item, index) => (
            <TopShareArticle {...item} handleRowClick={handleRowClick}/>
          ))
          :
          <div style={{margin: 'auto', fontSize: '12px'}}>No Records Found</div>  
        } 
      </Flex> 
      <DownloadButtonFlex justifyContent="flex-end" style={{ paddingTop: '83px' }}>
        <LoaderWrapper isLoading={loading}>
          <DownloadButton disabled={isEmpty(get(analyticsData, 'top_leads', [])) && isEmpty(get(analyticsData, 'top_shares', []))} onClick={handleExportToCsv} fontSize='18px' title='Export CSV'/>
        </LoaderWrapper>
      </DownloadButtonFlex>
      {openModal && (
        <ExportToCsv 
          handleClose={handleCloseExportToCsv}
          isOpen={openModal}
          title={'Export CSV'}
          selectedRepType={selectedRepType}
          startDate={analyticsObject?.startDate}
          endDate={analyticsObject?.endDate}
          selectedContacts={selectedContacts}
          analyticsObject={analyticsObject}
        />
      )}
    </Flex>
  )
}

export default OverviewTab;
