/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Flex, Box } from '@rebass/grid';
import {
  Modal,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ErrorContainer,
  LoaderWrapper,
} from '../';
import { isEmpty } from 'lodash';
import {
  RadioInput,
  CheckboxInput,
  RadioInputLabel,
} from './V2/CreateEmailListModal.style';

const CreateEmailListModalV1 = ({
  isModalOpen,
  setModalOpen,
  onNameListSubmit,
  selectedEmailList,
  isContactsFetching,
}) => {
  const didMountRef = useRef(null);
  const [emailListName, setEmailListName] = useState('');
  const [createEmailListError, setCreateEmailListError] = useState('');
  const [listType, setListType] = useState('prospect');
  const [defaultCheckbox, setDefaultCheckbox] = useState(false);
  useEffect(() => {
    console.log('listType', listType);
  }, [listType]);

  useEffect(() => {
    console.log('defaultCheckbox', defaultCheckbox);
  }, [defaultCheckbox]);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      if (!isContactsFetching) {
        setModalOpen(false);
      }
    }
  }, [isContactsFetching]);

  const onNameChange = (e) => {
    setEmailListName(e.target.value);
  };

  const onSubmitClick = () => {
    if (!emailListName) {
      setCreateEmailListError('Email List Name can not be blank');
      return;
    }
    if (isEmpty(selectedEmailList)) {
      setCreateEmailListError('Please select contact');
      return;
    }

    onNameListSubmit({
      listName: emailListName,
      contactType: listType,
      isDefault: defaultCheckbox,
    });
  };
  return (
    <Modal
      onRequestClose={() => setModalOpen(false)}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      size="smail"
    >
      <ModalHeader>Save List</ModalHeader>
      <ModalCloseButton onClick={() => setModalOpen(false)} />
      <ModalBody>
        <Flex flexDirection="column">
          <Box>
            <Input
              type="text"
              name="createEmailListName"
              id="createEmailListName"
              onChange={onNameChange}
            />
          </Box>
          <Flex justifyContent="center" alignItems="center" mt="10px">
            <Flex
              alignItems="center"
              justifyContent="center"
              marginRight="5px"
              // ml="25px"
            >
              <RadioInput
                type="radio"
                name="list_type"
                id="list_type_prospect"
                onClick={() => {
                  setListType('prospect');
                }}
                checked={listType === 'prospect'}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="list_type_prospect"
                style={{ cursor: 'pointer' }}
              >
                Prospects
              </RadioInputLabel>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              marginRight="5px"
              ml="10px"
            >
              <RadioInput
                type="radio"
                name="list_type"
                id="list_type_client"
                onClick={() => {
                  setListType('client');
                }}
                checked={listType === 'client'}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="list_type_client"
                style={{ cursor: 'pointer' }}
              >
                Clients
              </RadioInputLabel>
            </Flex>
            {/* <Flex
              alignItems="center"
              justifyContent="center"
              marginRight="5px"
              ml="10px"
              mt="5px"
            >
              <CheckboxInput>
                <label class="container">
                  <input
                    type="checkbox"
                    onChange={() => setDefaultCheckbox(!defaultCheckbox)}
                    checked={defaultCheckbox}
                  />
                  <span class="checkmark"></span>
                  Default
                </label>
              </CheckboxInput>
            </Flex> */}
          </Flex>
          <Box>
            <LoaderWrapper isLoading={isContactsFetching}>
              <Button
                type="button"
                onClick={onSubmitClick}
                testId="create-list-submit"
              >
                Submit
              </Button>
            </LoaderWrapper>
          </Box>
          {createEmailListError && (
            <ErrorContainer align="center">
              {createEmailListError}
            </ErrorContainer>
          )}
        </Flex>
      </ModalBody>
    </Modal>
  );
};

export default CreateEmailListModalV1;
