export const objectToFormData = (obj) => {
  const fData = new FormData();
  Object.keys(obj).forEach((key) => fData.append(key, obj[key]));
  return fData;
};

 const env = process.env.REACT_APP_API_ENV;
// const env = 'prod';

console.log(process.env.REACT_APP_API_ENV);
console.log(typeof process.env.REACT_APP_API_ENV);

export const apiDetails = {
  baseUrl:
    env === 'prod' ?
    'https://api.myclout.com/v1.3/' :
    env === 'qa' ? 
    'https://apibeta.myclout.com/v1.3/':
    env === 'stage' ?
    'https://api-stage.myclout.com/v1.3/'
    :
      // BETA URLS
    // 'http://apidev2.myclout.com/v1.3/',
    // 'http://apidev3.myclout.com/v1.3/',
    // 'http://apidev6.myclout.com/v1.3/',
    // 'http://apidev7.myclout.com/v1.3/',
    // 'http://apidev8.myclout.com/v1.3/',
    // 'http://apidev9.myclout.com/v1.3/',
    // 'http://apidev10.myclout.com/v1.3/',
    'https://api-dev.myclout.com/v1.3/',
  // 'https://apibeta.marketinsightout.com/v1.3/'

  // ENV and local URLS
  // process.env.REACT_APP_API_BASE_URL,
  // 'http://localhost:3999/',
};

export const lambdaEndpoints = {
  baseUrl:
    process.env.REACT_APP_API_ENV === 'dev' ? 
     'https://92bm0vkugf.execute-api.us-east-2.amazonaws.com/dev/':
     process.env.REACT_APP_API_ENV === 'qa' ? 
     'https://92bm0vkugf.execute-api.us-east-2.amazonaws.com/dev/':
     process.env.REACT_APP_API_ENV === 'stage' ?
     'https://e5ozhwek45.execute-api.us-east-2.amazonaws.com/stage/' :
     'https://l7l2dwuk1h.execute-api.us-east-2.amazonaws.com/production/'
};

export const thumbnailEndpoints = {
  baseUrl:
  process.env.REACT_APP_API_ENV === 'dev' 
      ? 'https://st78u1psuk.execute-api.us-east-2.amazonaws.com/dev'
      : process.env.REACT_APP_API_ENV === 'qa' ?
       'https://st78u1psuk.execute-api.us-east-2.amazonaws.com/dev'
       : process.env.REACT_APP_API_ENV === 'stage' ?
       'https://yrao7x4113.execute-api.us-east-2.amazonaws.com/stage'
      : 'https://4quo7lqiue.execute-api.us-east-2.amazonaws.com/production',
};

export const pdfGeneratorLambdaEndpoints = {
  baseUrl:
  (process.env.REACT_APP_API_ENV === 'dev' || process.env.REACT_APP_API_ENV ===  'qa')
      ? 'https://7z4ua9x4z2.execute-api.us-east-2.amazonaws.com/dev/pdf-generator'
      : process.env.REACT_APP_API_ENV === 'stage' ?
      'https://dmv9n50tyh.execute-api.us-east-2.amazonaws.com/stage/pdf-generator'
      : 'https://oe7wxrs5sk.execute-api.us-east-2.amazonaws.com/production/pdf-generator',
};

export const campaignViewEndpoints = {
  baseUrl: 'https://0o1dgjevk8.execute-api.us-east-2.amazonaws.com',
};

export const landingPagesEndpoints = {
  baseUrl:
  (process.env.REACT_APP_API_ENV === 'dev' || process.env.REACT_APP_API_ENV  === 'qa')
      ? 'https://44l7zxh5fk.execute-api.ap-south-1.amazonaws.com/dev'
      : process.env.REACT_APP_API_ENV === 'stage' ?
      'https://nihc7wmxwh.execute-api.us-east-2.amazonaws.com/stage/'
      : 'https://pp9ztqycu8.execute-api.us-east-2.amazonaws.com/production/',
};

export const automatedCampaignLambdaEndpoints = {
  baseUrl:
  (process.env.REACT_APP_API_ENV === 'dev' || process.env.REACT_APP_API_ENV === 'qa')
      ? 'https://3dmqn986fh.execute-api.us-east-2.amazonaws.com'
      : process.env.REACT_APP_API_ENV === 'stage' ?
      'https://17dfe5v4z7.execute-api.us-east-2.amazonaws.com'
      : 'https://rw8rbokkyc.execute-api.us-east-2.amazonaws.com',
};

export const diffHtmlEndPoints = {
    baseUrl : 
    (process.env.REACT_APP_API_ENV === 'dev' || process.env.REACT_APP_API_ENV === 'qa')
      ? 'https://dizgz05eq8.execute-api.ap-south-1.amazonaws.com/dev'
      : process.env.REACT_APP_API_ENV === 'stage' ? 
      'https://8fqotsmz56.execute-api.us-east-2.amazonaws.com/stage'
      : 'https://h2o8vj43vg.execute-api.us-east-2.amazonaws.com/production'
}
