import React, { useEffect, useState } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Formik } from 'formik';
import {
  Input,
  Button,
  MioDropdown,
  MioSelect,
  LoaderWrapper,
} from '../../../components';
import { ConnectMeWrapper, ToggleButton } from './Profile.styles';
import {
  InputIcon,
  AdvisorInputWrapper,
  EmailToolTipWrapper,
  AdvisorContactEmailInputWrapper,
} from '../../../components/AdvisorProfile/AdvisorProfile.styles';
import * as Yup from 'yup';
import { ErrorBox } from '../../../components/EditPassword/EditPassword.styles';
import CardModal from './CardModal';
import { get, isEmpty } from 'lodash';
import { ROLE_TYPES } from '../../../utils';

const EditProfile = ({
  data,
  onEditProfile,
  errorSchema,
  userData,
  isPremiumAdvisor,
  logo,
  socialLinks,
  roleType,
  user,
  leadCaptureSetting,
  setLeadCaptureSetting,
  loader,
}) => {
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  const [showDropdown, toggleDropdown] = useState(false);
  const [profileCard, setProfileCard] = useState(false);
  const [digitalBannerCounter, setDigitalBannerCounter] = useState(
    get(data, 'digital_banner.length', 0)
  );
  const [aboutYourselfCounter, setAboutYourselfCounter] = useState(
    get(data, 'about_me.length', 0)
  );

  console.log(roleType);
  console.log(get(user, 'details.user.is_impersonator', 'false'));
  useEffect(() => {
    if (userData && userData.timezone) setSelectedTimeZone(userData.timezone);
  }, [userData]);

  const digitalBannerHandleChange = (e, handleChange) => {
    handleChange(e);
    setDigitalBannerCounter(e.target.value.length);
  };

  const aboutYourselfHandleChange = (e, handleChange) => {
    handleChange(e);
    setAboutYourselfCounter(e.target.value.length);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          contact_email: '',
          mobile: '',
          city: '',
          timezone: '',
          about_me: '',
          digital_banner: '',
          firm_name: '',
          ...data,
        }}
        validationSchema={errorSchema}
        onSubmit={onEditProfile}
      >
        {({ handleSubmit, handleChange, errors, values, touched }) => (
          <div>
            <Flex flexDirection="column" width={1}>
              <Box width={1} mb="10px">
                <AdvisorInputWrapper>
                  <Input
                    type="text"
                    name="first_name"
                    id="first_name"
                    onChange={handleChange}
                    placeholder="First Name"
                    value={values.first_name}
                    isInputWithFloatingLabel={true}
                  />
                </AdvisorInputWrapper>
                {errors.first_name && touched.first_name ? (
                  <ErrorBox>{errors.first_name}</ErrorBox>
                ) : null}
              </Box>

              <Box width={1} mb="10px">
                <AdvisorInputWrapper>
                  <Input
                    type="text"
                    name="last_name"
                    id="last_name"
                    onChange={handleChange}
                    placeholder="Last Name"
                    value={values.last_name}
                    isInputWithFloatingLabel={true}
                  />
                </AdvisorInputWrapper>
                {errors.last_name && touched.last_name ? (
                  <ErrorBox>{errors.last_name}</ErrorBox>
                ) : null}
              </Box>

              <Box width={1} mb="10px">
                <AdvisorInputWrapper>
                  <Input
                    type="text"
                    name="firm_name"
                    id="firm_name"
                    onChange={handleChange}
                    value={values.firm_name}
                    placeholder="Enterprise Name"
                    isInputWithFloatingLabel={true}
                  />
                </AdvisorInputWrapper>
              </Box>

              <Box width={1} mb="10px">
                <Flex alignItems="baseline">
                  <AdvisorContactEmailInputWrapper>
                    <Input
                      type="email"
                      name="contact_email"
                      id="contact_email"
                      error={errors.contact_email}
                      onChange={handleChange}
                      placeholder="Contact Email"
                      value={values.contact_email}
                      isInputWithFloatingLabel={true}
                    />
                  </AdvisorContactEmailInputWrapper>

                  <div style={{ position: 'relative' }}>
                    <EmailToolTipWrapper>
                      <Flex>
                        <img
                          src="/assets/images/information-grey.svg"
                          alt="info tooltip"
                          style={{
                            width: '15px',
                            height: '13px',
                            cursor: 'pointer',
                            marginLeft: '10px',
                            marginTop: '25px',
                          }}
                        />
                      </Flex>

                      <span className="tooltip-text">
                        This email address will appear on your digital card for
                        prospective leads. It will not change your username or
                        the email that is connected for sending.
                      </span>
                    </EmailToolTipWrapper>
                  </div>
                </Flex>
              </Box>
              <Box width={1} mb="10px">
                <AdvisorInputWrapper>
                  <Input
                    type="text"
                    name="mobile"
                    id="mobile"
                    onChange={handleChange}
                    placeholder="Phone Number"
                    value={values.mobile}
                    isInputWithFloatingLabel={true}
                  />
                </AdvisorInputWrapper>
                {/* {errors.mobile && touched.mobile ? (
                  <ErrorBox>{errors.mobile}</ErrorBox>
                ) : null} */}
              </Box>

              <Box width={1} mb="10px">
                <AdvisorInputWrapper>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    onChange={handleChange}
                    value={values.city}
                    placeholder="City"
                    isInputWithFloatingLabel={true}
                  />
                </AdvisorInputWrapper>
                {errors.city && touched.city ? (
                  <ErrorBox>{errors.city}</ErrorBox>
                ) : null}
              </Box>

              <Box width={1} mb="10px" style={{ textAlign: 'left' }}>
                <label style={{ color: '#7b7b7b', fontSize: '14px' }}>
                  Timezone
                </label>
                {/* <AdvisorInputWrapper style={{marginTop: '0px'}}>
                <Input
                  type="text"
                  name="timezone"
                  id="timezone"
                  onChange={e => {
                    toggleDropdown(true);
                    handleChange(e);
                  }}
                  onFocus={() => toggleDropdown(true)}
                  value={values.timezone}
                  placeholder=""
                  isInputWithFloatingLabel={true}
                />
              </AdvisorInputWrapper>
              {console.log('userData', userData)} */}
                <div className="invite-div">
                  <div className="flex-container">
                    <MioSelect
                      id="timezone"
                      name="timezone"
                      required={true}
                      style={{
                        marginLeft: '14px',
                        marginTop: '0px',
                        width: '96%',
                        border: '1px solid gray',
                        color: 'gray',
                        paddingLeft: '5px',
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={values.timezone}
                      disabled={
                        get(user, 'details.user.is_impersonator', 'false') ===
                          'true' &&
                        (roleType === ROLE_TYPES.ADMIN_ENTERPRISE ||
                          roleType === ROLE_TYPES.ADMIN_COMPLIANCE)
                      }
                    >
                      <option value={values.timezone}>{values.timezone}</option>
                      {userData.google_timezones &&
                        Object.keys(userData.google_timezones)
                          .filter((key) => userData.google_timezones[key])
                          .map((key, index) => (
                            <option key={index} value={`${key}`}>
                              {`${userData.google_timezones[key]}: ${key}`}
                            </option>
                          ))}
                    </MioSelect>
                  </div>
                </div>

                {/* {userData && userData.available_timezones && showDropdown ? (
                  <div style={{position: 'relative', right: '0px', padding: '0px'}}>
                    <ul style={{
                      width: '285px', 
                      fontSize: '14px', 
                      maxHeight: '150px', 
                      overflowY: 'auto', 
                      position: 'absolute', 
                      listStyleType: 'none', 
                      right: '0px',
                      border: '1px solid #ddd',
                      borderTop: '0px',
                      marginTop: '0px',
                      paddingLeft: '0px',
                      borderRadius: '4px',
                    }}>
                      {userData.available_timezones.filter(timezone => timezone.toLowerCase().includes(values.timezone.toLowerCase())).map(timezone => (
                        <li 
                          style={{
                            backgroundColor: 'white', 
                            width: '100%', 
                            cursor: 'pointer', 
                            borderBottom: '1px solid #ddd', 
                            padding: '4px 5px'
                          }} 
                          onClick={() => {
                            toggleDropdown(false);
                            values.timezone = timezone;
                          }}>{timezone}</li>
                      ))}
                    </ul>
                  </div>
                ) : null} */}
              </Box>
            </Flex>

            <Flex
              flexDirection="column"
              alignItems="baseline"
              width="auto"
              justifyContent="space-between"
              style={{
                color: '#7b7b7b',
                fontSize: '13px',
                marginTop: '15px',
                position: 'absolute',
                left: '100px',
                top: '340px',
              }}
            >
              <Flex
                alignItems="baseline"
                width="100%"
                justifyContent="space-between"
                style={{
                  color: '#7b7b7b',
                  fontSize: '13px',
                  marginTop: '15px',
                }}
              >
                <label>About Yourself:</label>
                <Flex>{aboutYourselfCounter}/150</Flex>
              </Flex>
              <textarea
                maxLength="150"
                placeholder=""
                style={{
                  width: '270px',
                  height: '60px',
                  borderRadius: '4px',
                  padding: '6px 10px',
                  color: '#4E6780',
                  border: '1px solid #c9d0d8',
                  maxHeight: '125px',
                  maxWidth: '270px',
                  resize: 'none',
                }}
                id="about_me"
                name="about_me"
                onChange={(e) => aboutYourselfHandleChange(e, handleChange)}
                value={values.about_me}
              />
              {/* {errors.about_me && touched.about_me ? (
                <ErrorBox>{errors.about_me}</ErrorBox>
              ) : null} */}
            </Flex>

            <Flex
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              style={{ position: 'absolute', left: '100px', top: '452px' }}
            >
              <Flex
                alignItems="baseline"
                width="100%"
                justifyContent="space-between"
                style={{
                  color: '#7b7b7b',
                  fontSize: '13px',
                  marginTop: '15px',
                }}
              >
                <label>Digital Banner:</label>
                <Flex>{digitalBannerCounter}/125</Flex>
              </Flex>
              <textarea
                maxLength="125"
                placeholder=""
                style={{
                  width: '270px',
                  height: '60px',
                  borderRadius: '4px',
                  padding: '6px 10px',
                  color: '#4E6780',
                  border: '1px solid #c9d0d8',
                  maxHeight: '125px',
                  maxWidth: '270px',
                  resize: 'none',
                }}
                id="digital_banner"
                name="digital_banner"
                onChange={(e) => digitalBannerHandleChange(e, handleChange)}
                value={values.digital_banner}
              />
              {/* {errors.about_me && touched.about_me ? (
                <ErrorBox>{errors.about_me}</ErrorBox>
              ) : null} */}
            </Flex>
            <Flex
              flexDirection="column"
              style={{ position: 'relative', right: '158px' }}
            >
              <span
                style={{
                  color: '#7b7b7b',
                  fontSize: '10px',
                  marginTop: '15px',
                }}
              >
                Lead Capture Setting
              </span>
              {roleType === ROLE_TYPES.ADVISOR_ENTERPRISE ? (
                <Flex justifyContent="center" marginTop="10px">
                  {leadCaptureSetting ? 'Smart' : 'Classic'}
                </Flex>
              ) : (
                <Flex justifyContent="center" marginTop="10px">
                  Classic
                  <ToggleButton
                    className="react-switch"
                    onChange={() => setLeadCaptureSetting(!leadCaptureSetting)}
                    checked={leadCaptureSetting}
                    onColor="#6351ed"
                    offColor="#ccc"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    handleDiameter={10}
                    uncheckedIcon=""
                    checkedIcon=""
                    boxShadow=""
                    activeBoxShadow=""
                    type="autoPost"
                    left="1px"
                    margin="-3px 5px 0px"
                    position="absolute"
                    top="7px"
                  />
                  Smart
                  <div style={{ position: 'relative' }}>
                    <EmailToolTipWrapper>
                      <Flex>
                        <img
                          src="/assets/images/information-grey.svg"
                          alt="info tooltip"
                          style={{
                            width: '15px',
                            height: '13px',
                            cursor: 'pointer',
                            marginLeft: '10px',
                            marginTop: '6px',
                          }}
                        />
                      </Flex>

                      <span className="tooltip-text">
                        "Smart" enables one-click lead capture for your email
                        contacts. "Classic" allows them to review/edit their
                        contact details before submitting.
                      </span>
                    </EmailToolTipWrapper>
                  </div>
                </Flex>
              )}

              <Flex
                width="fitContent"
                alignItems="baseline"
                justifyContent="center"
                flexDirection="row"
              >
                <LoaderWrapper isLoading={loader}>
                  <Button
                    borderColor="#000"
                    color="#000"
                    margin="0 30px 0 0"
                    bgColor="#fff"
                    width="110px"
                    onClick={() => {
                      setProfileCard(true);
                    }}
                  >
                    Preview
                  </Button>

                  <Button onClick={handleSubmit}>Save</Button>
                </LoaderWrapper>
              </Flex>
            </Flex>

            {profileCard && (
              <CardModal
                handleModalClose={() => {
                  setProfileCard(false);
                }}
                name={values?.first_name + ' ' + values?.last_name}
                firm_name={values?.firm_name}
                mobile={values?.mobile}
                email={values?.contact_email}
                about_me={values?.about_me}
                digital_banner={
                  values?.digital_banner && values.digital_banner.length > 0
                    ? values.digital_banner
                    : values?.firm_name && values.firm_name.length > 0
                    ? `Want to hear more from ${values.firm_name}?`
                    : `Want to hear more from ${values?.first_name} ${values?.last_name}?`
                }
                logo={logo}
                socialLinks={socialLinks}
                userData={userData}
              />
            )}
          </div>
        )}
      </Formik>
    </>
  );
};

export default EditProfile;
