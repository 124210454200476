import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from '@rebass/grid';
import { camelCase, get, startCase } from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { ModalCloseButton } from '../../../../components';
import { themesData } from '../../../../utils';
import { statusColorCodes } from '../CampaignInfo/CampaignInfo';

const ViewInfoEmailBlockWrapper = styled(Box)`
  .color_black {
    color: #000000;
  }

  .view_info__email_title {
  }

  .view_info__email_block {
    padding: 5px 25px;
    border-radius: 5px;
    background-color: white;

    -webkit-box-shadow: 0px 0px 18px -6px rgba(209, 217, 230, 1);
    -moz-box-shadow: 0px 0px 18px -6px rgba(209, 217, 230, 1);
    box-shadow: 0px 0px 18px -6px rgba(209, 217, 230, 1);
  }

  .line__breaker {
    width: 100%;
    border: none;
    height: 2px;
    background: rgb(207, 217, 228);
  }
`;

const ViewInfoModal = ({
  isOpen,
  handleClose,
  blocks = [],
  campaignName,
  segment,
  individualEmailBlock = false,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        content: {
          background: '#ececec',
          height: blocks.length > 1 ? '600px' : '200px',
          overflowY: 'auto',
          padding: '15px 30px',
          width: '80vw',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <Flex justifyContent={'center'}>
        <p style={{ color: '#000000', fontSize: '18px' }}>
          {individualEmailBlock ? blocks[0].name || '-' : campaignName}
        </p>
        <ModalCloseButton onClick={handleClose} />
      </Flex>

      <Box>
        {React.Children.toArray(
          blocks.map((block, index) => (
            <ViewInfoEmailBlock
              individualEmailBlock={individualEmailBlock}
              hideLineBreak={index === blocks.length - 1}
              block={block}
              segment={segment}
            />
          ))
        )}
      </Box>
    </ReactModal>
  );
};

const getStatusForAC = (block) => {
  if(get(block, 'status', '-') !== 'NA') {
    if(!get(block, 'is_partially_completed', false)) {
      return startCase(camelCase(get(block, 'status', '-')));
    } else {
      return 'Partially Completed';
    }
  } else {
    return 'N/A';
  }
}

const ViewInfoEmailBlock = ({
  individualEmailBlock,
  hideLineBreak,
  block,
  segment,
}) => {
  const viewInfoEmailBlockTable = [
    {
      title: 'Platforms',
      value: 'Email',
    },
    {
      title: 'Destination',
      value: segment,
    },
    {
      title: 'Date/Time',
      value: block.publish_time ? moment(new Date(block.publish_time)).format(
        'Do MMMM YYYY, h:mm a'
      )  : '--',
    },
    {
      title: 'Lead / Referral Capture?',
      value: 'N/A',
    },
    {
      title: 'Email Sent / Total Contacts / Failed',
      value: block?.sent + '/' + block?.total_contacts + '/' + block?.failed,
    },
    {
      title: 'Status',
      value: getStatusForAC(block),
    },
  ];

  return (
    <ViewInfoEmailBlockWrapper>
      {!individualEmailBlock && (
        <p className="view_info__email_title color_black">
          {block?.name || '-'}
        </p>
      )}
      <Flex
        width="94%"
        justifyContent="center"
        style={{ margin: '0px 20px 0px 20px' }}
      >
        {React.Children.toArray(
          viewInfoEmailBlockTable.map((list) => (
            <Flex
              width="25%"
              justifyContent="center"
              fontSize="12px"
              color="#6351ed"
            >
              {list.title}
              {list.title === 'Status' && (
                <FontAwesomeIcon title="Please reach out to your TIFIN Clout client success partner for more details" style={{position: 'relative', cursor: 'pointer', top: '3px', left: '2px'}} icon={faQuestionCircle} />
              )}
            </Flex>
          ))
        )}
      </Flex>
      <Flex
        flexDirection="column"
        style={{
          maxHeight: '300px',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Flex width="100%" style={{ minHeight: '54px' }}>
          <Flex
            width="95%"
            justifyContent="center"
            style={{
              backgroundColor: '#fff',
              border: '1px solid #ececec',
              margin: '7px 12.3px 7px 20px',
              padding: '10px 9px 7px 0',
            }}
          >
            {React.Children.toArray(
              viewInfoEmailBlockTable.map((list) => (
                <Flex
                  width="25%"
                  justifyContent="center"
                  fontSize="12px"
                  color={
                    list.title === 'Status'
                      ? statusColorCodes[list.value.toLowerCase()]
                      : themesData.charcoalGrey
                  }
                >
                  {list.value}
                </Flex>
              ))
            )}
          </Flex>
        </Flex>
      </Flex>
      {!hideLineBreak && <hr className="line__breaker" />}
    </ViewInfoEmailBlockWrapper>
  );
};

export default ViewInfoModal;
