import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React from 'react'
import Slider from 'react-slick';
import { DisplayScore } from '../../../components';
import { getMonthDate, onImageError, overviewTabSliderSettings } from '../../../utils';
import { ArticleSliderWrapper } from '../../DashboardContainer/DashboardContainer.styles';
import { OverviewBadge, OverviewShareTitle, SponsoredWrapper, TopLeadWrapper, TopPublisherName, TopShareName } from './EngagementTab.styles';

const WebinarInsightsTab = ({
  analyticsData
}) => {

  const getEngagementMediaImage = (media) => {
    if (!isEmpty(media)) {
      console.log(`${media.path}${get(media, 'name.resized')}.${media.ext
      }`)
      return `${media.path}${get(media, 'name.resized')}.${media.ext
        }`;
    } else {
      return '';
    }
  }

  return (
    <Flex>
      {isEmpty(get(analyticsData, 'share', [])) ? (
        <div
          style={{
            fontSize: '12px',
            height: '260px',
            display: 'flex',
            margin: 'auto',
          }}
        >
          <div style={{ margin: 'auto' }}>No Records Found</div>
        </div>
      ) : (
        <ArticleSliderWrapper type="engagement" width="100%">
          <Slider {...overviewTabSliderSettings}>
            {get(analyticsData, 'share', []).map((item, index) => {
              // const trimmedPublisherName =
              //   (item.publisher.name || '').length > 20
              //     ? `${item.publisher.name.substring(0, 20)}...`
              //     : item.publisher.name;
              const trimmedTitle =
                (item.title || '').length > 50
                  ? `${item.title.substring(0, 50)}...`
                  : item.title;
              const extractedDate = getMonthDate(item.date);
              const formatted_date = `${extractedDate[0]} ${extractedDate[1]}`;
              return (
                <TopLeadWrapper
                  width="213px !important"
                  height="230px"
                  margin="14px 10px 0px 8.2px"
                  padding="0px"
                >
                  <Flex style={{ height: '99px', width: '100%' }}>
                    <img
                      alt="share"
                      src={getEngagementMediaImage(item.media_urls)}
                      width="212px"
                      onError={onImageError}
                      style={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        objectFit: 'cover',
                      }}
                    />
                  </Flex>
                  <Flex flexDirection="column" padding="8px 8px 10px 8px">
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      padding="0px 6px"
                    >
                      <Flex flexDirection="column" justifyContent="flex-start">
                        <TopPublisherName className="newsletter-title" fontWeight="bold" title={item.title}>
                          {trimmedTitle}
                        </TopPublisherName>
                      </Flex>
                      <Flex>
                        <DisplayScore score={item.engagement_score} />
                      </Flex>
                    </Flex>
                    <OverviewShareTitle height="19px" padding="5px 6px">
                      {formatted_date}
                    </OverviewShareTitle>
                    {!isEmpty(item.suggested_investments)
                      ? item.suggested_investments
                          .slice(0, 1)
                          .map((item, index) => (
                            <SponsoredWrapper mt="10px">
                              <Flex
                                width="100%"
                                justifyContent="flex-start"
                                padding="2px 0px"
                                style={{ height: '13px' }}
                              >
                                <img src={item.logo_path} style={{objectFit:'contain', objectPosition:'left'}} alt="Company" />
                              </Flex>
                              <Flex
                                width="100%"
                                fontSize="10px"
                                padding="2px 0px"
                                style={{
                                  textAlign: 'left',
                                  color: '#49484a',
                                  fontWeight: '500',
                                  whiteSpace: 'nowrap',
                                  height: '15px',
                                }}
                              >
                                <span
                                  title={item.name.length > 32 ? item.name : ''}
                                >
                                  {item.name.length > 32
                                    ? item.name.substring(0, 32) + '...'
                                    : item.name}
                                </span>
                              </Flex>
                              <Flex
                                justifyContent="space-between"
                                style={{ paddingTop: '2px' }}
                              >
                                <Flex>
                                  <span
                                    style={{
                                      color: '#49484a',
                                      fontWeight: 'bold',
                                      fontSize: '10px',
                                    }}
                                  >
                                    {item.ticker}
                                  </span>
                                  &nbsp;
                                  {item.matched_topics &&
                                    item.matched_topics.length > 0 &&
                                    item.matched_topics
                                      .slice(0, 1)
                                      .map((item, index) => (
                                        <span
                                          style={{
                                            color: '#49484a',
                                            fontSize: '8px',
                                            fontWeight: 500,
                                            border: '1px solid #49484a',
                                            borderRadius: '2px',
                                            marginRight: '4px',
                                            height: '11px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '0px 2px',
                                            marginTop: '2px',
                                          }}
                                        >
                                          {item}
                                        </span>
                                      ))}
                                </Flex>
                                {item.is_sponsored && (
                                  <OverviewBadge style={{ float: 'right' }}>
                                    <span>Sponsored</span>
                                  </OverviewBadge>
                                )}
                              </Flex>
                            </SponsoredWrapper>
                          ))
                      : null}
                  </Flex>
                </TopLeadWrapper>
              );
            })}
          </Slider>
        </ArticleSliderWrapper>
      )}
    </Flex>
  );
};

export default WebinarInsightsTab;
