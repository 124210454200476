import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import { get, isEmpty, map, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, LoaderWrapper, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../../components';
import { publishCampaignV2 } from '../../../services/campaign';
import { getContactList } from '../../../services/dashboard';
import { FilterRowChips } from '../../DripCampaignContainer/DripDashboardFilter/DripDashboardFilterPopup/DripDashboardFilterPopup.styles';
import { RecipientsListBody, RecipientsListWrapper } from '../../DripCampaignContainer/SetObjectives/SetObjectives.styles';
import { RadioInput, RadioInputLabel } from '../DripCampaignContainerV2.styles';

const ResendCampaignModal = ({
    close,
    campaignId,
    campaignData,
    socialNetworkData,
    campaignRequest,
    toggleFromSendToMore,
    // toggleEditScheduleModal,
    handleCheckboxClick,
    handleAlphabeticalSort,
    handleSelectAll,
    selectedEmailContacts,
    selectedFbContacts,
    selectedTwContacts,
    selectedLnContacts,
    selectedEmailContactsOG,
    selectedFbContactsOG,
    selectedTwContactsOG,
    selectedLnContactsOG,
    setEmailList,
    contactGroups,
    setContactGroups,
    emailList,
    campaignType,
    ascFbOrder,
    ascLnOrder,
    nextHandler,
    segment,
    segmentList,
    selectedSegments,
    setSelectedSegments,
}) => {
    // console.log('SOCIAL NETWORK DATA - - -- - - ', se);
    //  console.log('CAMPAIGN DATA - - -- - - ', campaignData);
    const [pageLoader, togglePageLoader] = useState(false);
    const [shareLoader, toggleShareLoader] = useState(false);
    const [shareError, setShareError] = useState('');
    const [listType, setListType] = useState('all');
    const history = useHistory();

    useEffect(() => {
        console.log('SEGMENT - - -', segment, 'SEGMENT LIST - - - - -', segmentList, 'SELECTED SEGMENT - - - -', selectedSegments);
        // console.log('SELECTED EMAIL CONTACTS - - -- ', selectedEmailContacts, selectedEmailContactsOG);
        if(segment && segment.length > 0) 
            setEmailList(segmentList);
        else {
            togglePageLoader(true);
            getContactList()
            .then((res) => {
                const data = get(res, 'result.data', []).filter(
                    (c) =>
                        ![
                            'GMAIL',
                            'HUBSPOT',
                            'OUTLOOK',
                            'WEALTHBOX',
                            'SALESFORCE',
                            'REDTAIL',
                        ].includes(get(c, 'name')) && get(c, 'contacts.count', 0) > 0
                );
                console.log('DATA - - -- - - - -- ', data);
                setContactGroups(data);
                setEmailList(data);
                togglePageLoader(false);
            })
            .catch((err) => {
                setContactGroups([]);
                togglePageLoader(false);
            });
        }
    }, []);

    const redirectToConfigure = platform => {
        // TODO: when admins will have campaign option
        history.push('/configure');
        window.sessionStorage.setItem('selectedIndex', 1);
        // endCreateFlow();
    };

    const isBtnChecked = item => {
        if(!isEmpty(segment))
            return selectedSegments.filter(s => s.destination_id === item.id).length > 0;
        else
            return (
                !isEmpty(selectedEmailContacts) && 
                selectedEmailContacts.filter(s => s.destination_id === item.id).length > 0
            );
    }

    const isBtnCheckedDefault = item => {
        // console.log('SELECTED EMAIL CONTACTS OG - - -', selectedEmailContactsOG, 'ITEM - - -', item);
        if(!isEmpty(segment))
            return segment.filter(seg => seg.destination_id === item.id).length > 0;
        else
            return (
                !isEmpty(selectedEmailContactsOG) && 
                selectedEmailContactsOG.filter(s => s.destination_id === item.id).length > 0
            );
    }

    

    // const handleCheckboxClick = (item, type) => {
    //     console.log('****** HANDLE CHECKBOX CLICK *****', item, selectedLnContacts, selectedTwContacts, selectedFbContacts, selectedEmailContacts);
    //     if (type === 'em') {
    //         console.log('IF CONDITION - - -- ', selectedEmailContacts.find(em => em.destination_id_hash === item.id))
    //         if(!selectedEmailContacts.find(em => em.destination_id === item.id)) {
    //             // console.log('INSIDE IF ****** ');
    //             setSelectedEmailContacts([...selectedEmailContacts, {destination_id: item.id, snetwork_act_id: get(socialNetworkData, 'em[0].accountId', '')}]);
    //         }
    //         else
    //             setSelectedEmailContacts(selectedEmailContacts.filter(em => em.destination_id !== item.id))
    //     }
    //     else if (type === 'ln') { 
    //         if(!selectedLnContacts.find(ln => ln.destination_id === item))
    //             setSelectedLnContacts([...selectedLnContacts, {destination_id: item, snetwork_act_id: get(socialNetworkData, 'ln[0].accountId', '')}]);
    //         else 
    //             setSelectedLnContacts(selectedLnContacts.filter(ln => ln.destination_id !== item))
    //     }
    //     else if (type === 'tw') {
    //         if(!selectedTwContacts.find(tw => tw.destination_id === item))
    //             setSelectedTwContacts([...selectedTwContacts, {destination_id: item, snetwork_act_id: get(socialNetworkData, 'tw[0].accountId', '')}]);
    //         else
    //             setSelectedTwContacts(selectedTwContacts.filter(tw => tw.destination_id !== item));
    //     }
    //     else {
    //         if(!selectedFbContacts.find(fb => fb.destination_id === item))
    //             setSelectedFbContacts([...selectedFbContacts, {destination_id: item, snetwork_act_id: get(socialNetworkData, 'fb[0].accountId', '')}]);
    //         else
    //             setSelectedFbContacts(selectedFbContacts.filter(fb => fb.destination_id !== item));
    //     }
    // };




    //   const shareHandler = () => {
    //     toggleShareLoader(true);
    //     setShareError('');
    //     const destination = {
    //         ...(!isEmpty(selectedEmailContacts) ? {email: selectedEmailContacts, skip_compliance: 'true'} : {}),
    //         ...(!isEmpty(selectedFbContacts) ? {facebook: selectedFbContacts, skip_compliance: 'true'} : {}),
    //         ...(!isEmpty(selectedLnContacts) ? {linkedin: selectedLnContacts, skip_compliance: 'true'} : {}),
    //         ...(!isEmpty(selectedTwContacts) ? {twitter: selectedTwContacts, skip_compliance: 'true'} : {}),
    //     }
    //     const payload = {
    //         posts: JSON.stringify(destination)
    //     }

    //     console.log('PAYLOAD - - -- ', payload);

    //     publishCampaignV2({id: campaignId, payload})
    //     .then(res => {
    //       console.log('SHARE RESPONSE -= - -- -  ', res);
    //       if(get(res, 'result.success', '')) {
    //         toggleShareLoader(false);
    //         campaignRequest();
    //     }
    //     })
    //     .catch(err => {
    //         console.log('[EDIT SCHEDULE SHARE] ERROR - -- - ', err);
    //         toggleShareLoader(false);
    //         setShareError('Some error occurred! Please try again later.');
    //     });
    //     // destination: {"email":[{"destination_id":"Z9e1abnZ","snetwork_act_id":"110763024642329183141"},{"destination_id":"pKrQeb3z","snetwork_act_id":"110763024642329183141"}],"facebook":[{"destination_id":"101009138403202","snetwork_act_id":"1211386165868703"}],"twitter":[{"destination_id":0,"snetwork_act_id":"1325709789138055170"}],"linkedin":[]}
    //   }    


    return (
        <ModalV2 bgColor='#fff' isOpen={true} onRequestClose={close} minWidth='40%'>
            <ModalHeader>
                Send To More Lists/Pages
                <ModalCloseButton onClick={close} />
            </ModalHeader>
            {/* <ModalBody></ModalBody> */}
            <ModalBody>
                <LoaderWrapper isLoading={pageLoader}>
                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        style={{ padding: '0px 10px' }}
                        mt="10px"
                    >
                        <Flex
                            display="flex"
                            flexDirection="row"
                            width="100%"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            {(campaignType === 'emailOnly' || campaignType === 'both') && (
                                <Flex
                                    flexDirection="column"
                                    width='250px'
                                    marginRight='20px'
                                >
                                    <Flex
                                        justifyContent="space-between"
                                        fontSize="14px"
                                        color="#000000"
                                        width="100%"
                                    >
                                        <Flex>
                                            {segment && segment.length > 0 ? 'Segments' : 'Emails'}
                                            {!isEmpty(get(socialNetworkData, 'em', [])) &&
                                                !isEmpty(contactGroups) &&
                                                contactGroups.length > 1 && (
                                                    <span
                                                        style={{
                                                            color: isEmpty(get(socialNetworkData, 'em', []))
                                                                ? '#AFAFAF'
                                                                : '#6250ED',
                                                            fontSize: '10px',
                                                            position: 'relative',
                                                            top: '4px',
                                                            paddingLeft: '10px',
                                                            cursor: isEmpty(get(socialNetworkData, 'em', []))
                                                                ? 'default'
                                                                : 'pointer',
                                                        }}
                                                        id="select_all_emails"
                                                        onClick={() => handleSelectAll('em')}
                                                    >
                                                        Select ALL
                                                    </span>
                                                )}
                                        </Flex>
                                        {!isEmpty(get(socialNetworkData, 'em', [])) &&
                                            !isEmpty(contactGroups) &&
                                            contactGroups.length > 1 && (
                                                <Flex
                                                    fontSize="10px"
                                                    style={{
                                                        position: 'relative',
                                                        top: '6px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleAlphabeticalSort('em')}
                                                >
                                                    <span>Sort: Alphabetical</span>
                                                    <span style={{ paddingLeft: '3px' }}>
                                                        <FontAwesomeIcon
                                                            color="#000000"
                                                            icon={faChevronDown}
                                                        />
                                                    </span>
                                                </Flex>
                                            )}
                                    </Flex>
                                    <RecipientsListWrapper padding="0px">
                                        {isEmpty(get(socialNetworkData, 'em', [])) ? (
                                            <Flex
                                                height="100%"
                                                justifyContent="center"
                                                width="100%"
                                                fontSize="12px"
                                                alignItems="center"
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#6351ed'
                                                }}
                                                onClick={() => redirectToConfigure('em')}
                                            >
                                                Please Connect to Email
                                            </Flex>
                                        ) : (
                                            <RecipientsListBody height="168px">
                                                    {!isEmpty(emailList) &&
                                                        emailList.map((item, index) => (
                                                            <>
                                                                <FilterRowChips
                                                                    style={{
                                                                        fontSize: '12px',
                                                                        padding: '5px 7px',
                                                                        backgroundColor:
                                                                            item.description === 'group_header' ? '#E3E7EE' : '',
                                                                    }}
                                                                    disabled={item.description === 'group_header' ? false : isBtnCheckedDefault(item)}
                                                                >
                                                                    <label
                                                                        className="checkbox-button"
                                                                        // style={{
                                                                        //     cursor: !isEmpty(segment) ?
                                                                        //     selectedSegments.filter(s => s.destination_id === item.id).length > 0 ? 'pointer' : 'default' :
                                                                        //     !isEmpty(selectedEmailContacts) &&
                                                                        //     selectedEmailContacts.filter(
                                                                        //         (s) => s.destination_id === item.id
                                                                        //     ).length > 0 ? 'pointer' : 'default'
                                                                        // }}
                                                                    >
                                                                        {item.description !== 'group_header' && (
                                                                            <>
                                                                                {/* {console.log('SELECTED SEGMENTS-- - -', selectedSegments, 'ITEM - - - -', item)} */}
                                                                                {/* {console.log('SELECTED EMAIL CONTACTS - - - - -', selectedEmailContacts)} */}
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="checkbox-button__input"
                                                                                    name="draft"
                                                                                    id={`email_${item.name}`}
                                                                                    onChange={() => handleCheckboxClick(item, 'em')}
                                                                                    checked={isBtnChecked(item)}
                                                                                    disabled={isBtnCheckedDefault(item)}
                                                                                    value={item.name}
                                                                                />
                                                                                <span
                                                                                    className="checkbox-button__control"
                                                                                    style={{
                                                                                        // cursor: 'pointer',
                                                                                    }}
                                                                                ></span>
                                                                            </>
                                                                        )}
                                                                        <span
                                                                            className="checkbox-button__label"
                                                                            style={{
                                                                                color:
                                                                                    item.description === 'group_header'
                                                                                        ? '#000' :
                                                                                        isBtnCheckedDefault(item) ? '#adadad'
                                                                                        : '#49484a',
                                                                                fontWeight:
                                                                                    item.description === 'group_header'
                                                                                        ? '800'
                                                                                        : '100',
                                                                                // fontWeight: '100',
                                                                                marginLeft:
                                                                                    item.description === 'group_header' ? '32px' : '',
                                                                                // cursor: 'pointer',
                                                                            }}
                                                                        >
                                                                            {item.name}
                                                                        </span>
                                                                    </label>
                                                                </FilterRowChips>
                                                            </>
                                                        ))}
                                            </RecipientsListBody>
                                        )}
                                    </RecipientsListWrapper>
                                </Flex>
                            )}
                            {(campaignType === 'socialOnly' || campaignType === 'both') && (
                                <>
                                    {!isEmpty(selectedFbContactsOG) && (
                                        <Flex flexDirection="column" width="250px" marginRight='20px'>
                                            <Flex
                                                fontSize="14px"
                                                color="#000000"
                                                // width="100%"
                                                justifyContent="space-between"
                                            >
                                                <Flex>
                                                    Facebook
                                                    {isEmpty(campaignId) &&
                                                        !isEmpty(get(socialNetworkData, 'fb', [])) &&
                                                        Object.keys(get(socialNetworkData, 'fb[0].page', []))
                                                            .length > 1 && (
                                                            <span
                                                                style={{
                                                                    color: isEmpty(get(socialNetworkData, 'fb', []))
                                                                        ? '#AFAFAF'
                                                                        : '#6250ED',
                                                                    fontSize: '10px',
                                                                    position: 'relative',
                                                                    top: '4px',
                                                                    paddingLeft: '10px',
                                                                    cursor: isEmpty(get(socialNetworkData, 'fb', []))
                                                                        ? 'default'
                                                                        : 'pointer',
                                                                }}
                                                                id="select_all_fb"
                                                                onClick={() => handleSelectAll('fb')}
                                                            >
                                                                Select ALL
                                                            </span>
                                                        )}
                                                </Flex>
                                                {!isEmpty(get(socialNetworkData, 'fb', [])) &&
                                                    Object.keys(get(socialNetworkData, 'fb[0].page', []))
                                                        .length > 1 && (
                                                        <Flex
                                                            fontSize="10px"
                                                            style={{
                                                                position: 'relative',
                                                                top: '4px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleAlphabeticalSort('fb')}
                                                        >
                                                            <span>Sort: Alphabetical</span>
                                                            <span style={{ paddingLeft: '3px' }}>
                                                                <FontAwesomeIcon
                                                                    color="#000000"
                                                                    icon={faChevronDown}
                                                                />
                                                            </span>
                                                        </Flex>
                                                    )}
                                            </Flex>
                                            <RecipientsListWrapper padding="0px">
                                                {isEmpty(get(socialNetworkData, 'fb', [])) ? (
                                                    <Flex
                                                        height="100%"
                                                        justifyContent="center"
                                                        width="100%"
                                                        fontSize="12px"
                                                        alignItems="center"
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: '#6351ed'
                                                        }}
                                                    // onClick={() => redirectToConfigure('fb')}
                                                    >
                                                        Please Connect to Facebook
                                                    </Flex>
                                                ) : (
                                                    <RecipientsListBody height="168px">
                                                        {!isEmpty(get(socialNetworkData, 'fb[0].page', [])) &&
                                                            !ascFbOrder
                                                            ? map(
                                                                get(socialNetworkData, 'fb[0].page', []),
                                                                (item, key) => (
                                                                    <>
                                                                        <FilterRowChips
                                                                            style={{
                                                                                fontSize: '12px',
                                                                                padding: '5px 7px',
                                                                            }}
                                                                            disabled={
                                                                                !isEmpty(
                                                                                    selectedFbContactsOG.filter(
                                                                                        (s) => s.destination_id === key
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            <label
                                                                                className="checkbox-button"
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="checkbox-button__input"
                                                                                    name="draft"
                                                                                    onChange={() =>
                                                                                        handleCheckboxClick(key, 'fb')
                                                                                    }
                                                                                    checked={
                                                                                        !isEmpty(
                                                                                            selectedFbContacts.filter(
                                                                                                (s) => s.destination_id === key
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        !isEmpty(
                                                                                            selectedFbContactsOG.filter(
                                                                                                (s) => s.destination_id === key
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    value={item.details.name}
                                                                                />
                                                                                <span
                                                                                    className="checkbox-button__control"
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                ></span>
                                                                                <span
                                                                                    className="checkbox-button__label"
                                                                                    style={{
                                                                                        color: !isEmpty(
                                                                                            selectedFbContactsOG.filter(
                                                                                                (s) => s.destination_id === key
                                                                                            )
                                                                                        ) ? '#adadad' : '#49484a',
                                                                                        fontWeight: '100',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    {item.details.name}
                                                                                </span>
                                                                            </label>
                                                                        </FilterRowChips>
                                                                    </>
                                                                )
                                                            )
                                                            : map(get(socialNetworkData, 'fb[0].page', []))
                                                                .sort((a, b) =>
                                                                    a.details.name.localeCompare(b.details.name)
                                                                )
                                                                .map((item, index) => (
                                                                    <>
                                                                        <FilterRowChips
                                                                            style={{
                                                                                fontSize: '12px',
                                                                                padding: '5px 0px',
                                                                            }}
                                                                            disabled={
                                                                                !isEmpty(
                                                                                    selectedFbContactsOG.filter(
                                                                                        (s) => s.destination_id === item.id
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            <label
                                                                                className="checkbox-button"
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="checkbox-button__input"
                                                                                    name="draft"
                                                                                    onChange={() =>
                                                                                        handleCheckboxClick(item.id, 'fb')
                                                                                    }
                                                                                    checked={
                                                                                        !isEmpty(
                                                                                            selectedFbContacts.filter(
                                                                                                (s) => s.destination_id === item.id
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        !isEmpty(
                                                                                            selectedFbContactsOG.filter(
                                                                                                (s) => s.destination_id === item.id
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    value={item.details.name}
                                                                                />
                                                                                <span
                                                                                    className="checkbox-button__control"
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                ></span>
                                                                                <span
                                                                                    className="checkbox-button__label"
                                                                                    style={{
                                                                                        color: !isEmpty(
                                                                                            selectedFbContactsOG.filter(
                                                                                                (s) => s.destination_id === item.id
                                                                                            )
                                                                                        ) ? '#adadad' : '#49484a',
                                                                                        fontWeight: '100',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    {item.details.name}
                                                                                </span>
                                                                            </label>
                                                                        </FilterRowChips>
                                                                    </>
                                                                ))}
                                                    </RecipientsListBody>
                                                )}
                                            </RecipientsListWrapper>
                                        </Flex>
                                    )}
                                    {!isEmpty(selectedLnContactsOG) && (
                                        <Flex flexDirection="column" width="250px" marginRight='20px'>
                                            <Flex
                                                fontSize="14px"
                                                color="#000000"
                                                // width="100%"
                                                justifyContent="space-between"
                                            >
                                                <Flex>
                                                    LinkedIn
                                                    {isEmpty(campaignId) &&
                                                        !isEmpty(get(socialNetworkData, 'ln', [])) &&
                                                        Object.keys(get(socialNetworkData, 'ln[0].page', []))
                                                            .length > 0 && (
                                                            <span
                                                                style={{
                                                                    color: isEmpty(get(socialNetworkData, 'ln', []))
                                                                        ? '#AFAFAF'
                                                                        : '#6250ED',
                                                                    fontSize: '10px',
                                                                    position: 'relative',
                                                                    top: '4px',
                                                                    paddingLeft: '10px',
                                                                    cursor: isEmpty(get(socialNetworkData, 'ln', []))
                                                                        ? 'default'
                                                                        : 'pointer',
                                                                }}
                                                                id="select_all_ln"
                                                                onClick={() => handleSelectAll('ln')}
                                                            >
                                                                Select ALL
                                                            </span>
                                                        )}
                                                </Flex>
                                                {!isEmpty(get(socialNetworkData, 'ln', [])) &&
                                                    Object.keys(get(socialNetworkData, 'ln[0].page', []))
                                                        .length > 0 && (
                                                        <Flex
                                                            fontSize="10px"
                                                            style={{
                                                                position: 'relative',
                                                                top: '4px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleAlphabeticalSort('ln')}
                                                        >
                                                            <span>Sort: Alphabetical</span>
                                                            <span style={{ paddingLeft: '3px' }}>
                                                                <FontAwesomeIcon
                                                                    color="#000000"
                                                                    icon={faChevronDown}
                                                                />
                                                            </span>
                                                        </Flex>
                                                    )}
                                            </Flex>
                                            <RecipientsListWrapper padding="0px">
                                                {isEmpty(get(socialNetworkData, 'ln', [])) ? (
                                                    <Flex
                                                        height="100%"
                                                        justifyContent="center"
                                                        width="100%"
                                                        fontSize="12px"
                                                        alignItems="center"
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: '#6351ed'
                                                        }}
                                                        onClick={() => redirectToConfigure('ln')}
                                                    >
                                                        Please Connect to LinkedIn
                                                    </Flex>
                                                ) : (
                                                    <RecipientsListBody height="168px">
                                                        <FilterRowChips
                                                            style={{ fontSize: '12px', padding: '5px 7px' }}
                                                            disabled={
                                                                !isEmpty(selectedLnContactsOG.filter((s) => s.destination_id === '0'))
                                                            }
                                                        >
                                                            <label
                                                                className="checkbox-button"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    className="checkbox-button__input"
                                                                    name="draft"
                                                                    onChange={() => handleCheckboxClick('0', 'ln')}
                                                                    checked={
                                                                        !isEmpty(selectedLnContacts.filter((s) => s.destination_id === '0'))
                                                                    }
                                                                    disabled={
                                                                        !isEmpty(selectedLnContactsOG.filter((s) => s.destination_id === '0'))
                                                                    }
                                                                    value={'news-feed'}
                                                                />
                                                                <span
                                                                    className="checkbox-button__control"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                ></span>
                                                                <span
                                                                    className="checkbox-button__label"
                                                                    style={{
                                                                        color: !isEmpty(selectedLnContactsOG.filter((s) => s.destination_id === '0')) ? '#adadad' : '#49484a',
                                                                        fontWeight: '100',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    On News Feed
                                                                </span>
                                                            </label>
                                                        </FilterRowChips>
                                                        {!isEmpty(get(socialNetworkData, 'ln[0].page', [])) &&
                                                            !ascLnOrder
                                                            ? map(
                                                                get(socialNetworkData, 'ln[0].page', []),
                                                                (item, key) => (
                                                                    <>
                                                                        <FilterRowChips
                                                                            style={{
                                                                                fontSize: '12px',
                                                                                padding: '5px 7px',
                                                                            }}
                                                                            disabled={
                                                                                !isEmpty(
                                                                                    selectedLnContactsOG.filter(
                                                                                        (s) => s.destination_id === key
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            <label
                                                                                className="checkbox-button"
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="checkbox-button__input"
                                                                                    name="draft"
                                                                                    onChange={() =>
                                                                                        handleCheckboxClick(key, 'ln')
                                                                                    }
                                                                                    checked={
                                                                                        !isEmpty(
                                                                                            selectedLnContacts.filter(
                                                                                                (s) => s.destination_id === key
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        !isEmpty(
                                                                                            selectedLnContactsOG.filter(
                                                                                                (s) => s.destination_id === key
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    value={item.details.name}
                                                                                />
                                                                                <span
                                                                                    className="checkbox-button__control"
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                ></span>
                                                                                <span
                                                                                    className="checkbox-button__label"
                                                                                    style={{
                                                                                        color: !isEmpty(
                                                                                            selectedLnContactsOG.filter(
                                                                                                (s) => s.destination_id === key
                                                                                            )
                                                                                        ) ? '#adadad' : '#49484a',
                                                                                        fontWeight: '100',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    {item.details.name}
                                                                                </span>
                                                                            </label>
                                                                        </FilterRowChips>
                                                                    </>
                                                                )
                                                            )
                                                            : map(get(socialNetworkData, 'ln[0].page', []))
                                                                .sort((a, b) =>
                                                                    a.details.name.localeCompare(b.details.name)
                                                                )
                                                                .map((item, index) => (
                                                                    <>
                                                                        <FilterRowChips
                                                                            style={{
                                                                                fontSize: '12px',
                                                                                padding: '5px 0px',
                                                                            }}
                                                                            disabled={
                                                                                !isEmpty(
                                                                                    selectedLnContactsOG.filter(
                                                                                        (s) => s.destination_id === item.id
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            <label
                                                                                className="checkbox-button"
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="checkbox-button__input"
                                                                                    name="draft"
                                                                                    onChange={() =>
                                                                                        handleCheckboxClick(item.id, 'ln')
                                                                                    }
                                                                                    checked={
                                                                                        !isEmpty(
                                                                                            selectedLnContacts.filter(
                                                                                                (s) => s.destination_id === item.id
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        !isEmpty(
                                                                                            selectedLnContactsOG.filter(
                                                                                                (s) => s.destination_id === item.id
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    value={item.details.name}
                                                                                />
                                                                                <span
                                                                                    className="checkbox-button__control"
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                ></span>
                                                                                <span
                                                                                    className="checkbox-button__label"
                                                                                    style={{
                                                                                        color: !isEmpty(
                                                                                            selectedLnContactsOG.filter(
                                                                                                (s) => s.destination_id === item.id
                                                                                            )
                                                                                        ) ? '#adadad' : '#49484a',
                                                                                        fontWeight: '100',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    {item.details.name}
                                                                                </span>
                                                                            </label>
                                                                        </FilterRowChips>
                                                                    </>
                                                                ))}
                                                    </RecipientsListBody>
                                                )}
                                            </RecipientsListWrapper>
                                        </Flex>
                                    )}
                                    {!isEmpty(selectedTwContactsOG) && (
                                        <Flex flexDirection="column" width="250px" marginRight='20px'>
                                            <Flex fontSize="14px" color="#000000" width="100%">
                                                Twitter
                                            </Flex>
                                            <RecipientsListWrapper padding="0px">
                                                {isEmpty(get(socialNetworkData, 'tw', [])) ? (
                                                    <Flex
                                                        height="100%"
                                                        justifyContent="center"
                                                        width="100%"
                                                        fontSize="12px"
                                                        alignItems="center"
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: '#6351ed'
                                                        }}
                                                        onClick={() => redirectToConfigure('tw')}
                                                    >
                                                        Please Connect to Twitter
                                                    </Flex>
                                                ) : (
                                                    <RecipientsListBody height="168px">
                                                        <FilterRowChips
                                                            style={{ fontSize: '12px', padding: '5px 7px' }}
                                                            disabled={
                                                                !isEmpty(selectedTwContactsOG.filter((s) => s.destination_id === '0'))
                                                            }
                                                        >
                                                            <label
                                                                className="checkbox-button"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    className="checkbox-button__input"
                                                                    name="draft"
                                                                    onChange={() => handleCheckboxClick('0', 'tw')}
                                                                    checked={
                                                                        !isEmpty(selectedTwContacts.filter((s) => s.destination_id === '0'))
                                                                    }
                                                                    disabled={
                                                                        !isEmpty(selectedTwContactsOG.filter((s) => s.destination_id === '0'))
                                                                    }
                                                                    id="select_all_tw"
                                                                    value="news-feed"
                                                                />
                                                                <span
                                                                    className="checkbox-button__control"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                ></span>
                                                                <span
                                                                    className="checkbox-button__label"
                                                                    style={{
                                                                        color: !isEmpty(selectedTwContactsOG.filter((s) => s.destination_id === '0')) ? '#adadad' : '#49484a',
                                                                        fontWeight: '100',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    On News Feed
                                                                </span>
                                                            </label>
                                                        </FilterRowChips>
                                                    </RecipientsListBody>
                                                )}
                                            </RecipientsListWrapper>
                                        </Flex>
                                    )}
                                </>
                            )}
                        </Flex>
                    </Flex>
                    {shareError && (
                        <div style={{ color: '#6351ed', fontSize: '10px' }}>{shareError}</div>
                    )}
                    <LoaderWrapper isLoading={shareLoader}>
                        <Flex justifyContent='flex-end' width='96%'>
                            <Button onClick={close} styles={{ marginRight: '12px' }}>Cancel</Button>
                            <Button
                                onClick={nextHandler}
                                disabled={
                                    !isEmpty(segment) ? 
                                    segment.length === selectedSegments.length :
                                    (
                                        selectedEmailContacts.length === selectedEmailContactsOG.length &&
                                        selectedLnContacts.length === selectedLnContactsOG.length &&
                                        selectedFbContacts.length === selectedFbContactsOG.length &&
                                        selectedTwContacts.length === selectedTwContactsOG.length
                                    )
                                }
                            >
                                Next
                            </Button>
                        </Flex>
                    </LoaderWrapper>
                </LoaderWrapper>
            </ModalBody>
        </ModalV2>
    )
};

export default ResendCampaignModal;