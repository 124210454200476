import React, { useState } from 'react';
import { ModalHeader, } from '../..';
import './DeleteSegmentModal.css';
import { LoaderWrapper } from '../../Loader';
//import { ModalV2 } from '../../Modal';
import { deleteSegment } from '../../../services/segment';
import { Button } from '../../../components';
import { Modal, ModalBody,  ModalCloseButton, } from '../../../components';

const DeleteSegmentModal = ({
  isModalOpen,
  setModalOpen,
  getSegmentList,
  segmentId,
  segmentName,
}) => {
  const [loader, setLoader] = useState(false);

  const [segmentErrorVisible, setSegmentErrorVisible] = useState(false);
  const [segmentErrorMessage, setSegmentErrorMessage] = useState('');

  const handleSegmentDelete = () => {
    setLoader(true);
    deleteSegment(segmentId)
      .then((res) => {
        console.log('res', res);
        getSegmentList('delete');
        setLoader(false);
        setModalOpen(false);
      })
      .catch((error) => {
        setLoader(false);
        setSegmentErrorVisible(true);
        setSegmentErrorMessage(error.response.message);
        setTimeout(() => setSegmentErrorVisible(false), 5000);
      });
  };

  return (
    <Modal
      onRequestClose={() => setModalOpen(false)}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      // bgColor="white"
       //width="75%"
       size = 'medium45'
    >
      <ModalHeader>Delete Segment</ModalHeader>
      <ModalCloseButton onClick={() => setModalOpen(false)} />
      <ModalBody>
        {/* Delete Confirmation Modal */}
        <div>Are you sure you want to delete {segmentName} ?</div>
        <div>{segmentErrorVisible && <p>{segmentErrorMessage}</p>}</div>
        <LoaderWrapper isLoading={loader}>
          <Button
            testId="submit-segment-deletion"
            margin="20px 5px 0px 0px"
            onClick={handleSegmentDelete}
          >
            Yes
          </Button>
          <Button onClick={() => setModalOpen(false)}>No</Button>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  );
};

export default DeleteSegmentModal;
