import React, { useState, useEffect } from 'react';
import { Flex, Box } from '@rebass/grid';
import {
  Table,
  TableBody,
  TableWidget,
  ResponsiveImg,
  TableHeaderButtons,
  EmailListTableBodyTd,
  EmailListTableBodyTr,
  EmailListTableHeading,
  EmailListTh,
  Tr,
  DownloadSampleDiv,
} from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { RadioInput, RadioInputLabel } from './EmailList.styles';

import CreateSegmentModal from '../../../components/SegmentModal/CreateSegmentModal/CreateSegmentModal';
import UpdateSegmentModal from '../../../components/SegmentModal/UpdateSegmentModal/UpdateSegmentModal';
import DeleteSegmentModal from '../../../components/SegmentModal/DeleteSegmentModal/DeleteSegmentModal';

import { LoaderWrapper } from '../../../components';
import { getSegments, getSegmentContactList } from '../../../services/segment';
import { getAllSegmentsCsv } from '../../../services/dashboard';
import { saveFile } from '../../../utils';

const SegmentTable = ({ segmentList }) => {
  const [segmentOptionsEnabled, setSegmentOptionsEnabled] = useState(false);

  const [segmentId, setSegmentId] = useState(null);
  const [segmentName, setSegmentName] = useState(null);

  const [segmentContactsArr, setSegmentContactsArr] = useState([]);

  const [segmentObjArr, setSegmentObjArr] = useState([]);
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);
  const [selectedSegmentObj, setSelectedSegmentObj] = useState(null);

  const [contactsLoader, isContactsLoading] = useState(false);
  const [listLoader, isListLoading] = useState(false);

  const [isSegmentCreateModalOpen, setSegmentCreateModalOpen] = useState(false);
  const [isSegmentUpdateModalOpen, setSegmentUpdateModalOpen] = useState(false);
  const [isSegmentDeleteModalOpen, setSegmentDeleteModalOpen] = useState(false);

  useEffect(() => {
    isListLoading(true);
    if (segmentList.length > 0) {
      setSegmentObjArr(segmentList);
    }
    setTimeout(() => isListLoading(false), 2000);
  }, [segmentList]);

  useEffect(() => {
    isContactsLoading(true);
    if (segmentList.length > 0) {
      setSegmentId(segmentList[0].id);
      getSegmentContacts(segmentList[0].id);
      setSegmentName(segmentList[0].name);
      setSegmentOptionsEnabled(true);
      setSelectedSegmentObj(segmentList[0])
    }
    setTimeout(() => isContactsLoading(false), 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentList]);

  const sortSegmentContacts = (isAscending) => {
    isContactsLoading(true);
    var sortedSegmentArr = segmentContactsArr.sort((a, b) => {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    });
    if (!isAscending) {
      sortedSegmentArr.reverse();
    }
    console.log(sortedSegmentArr);
    setSegmentContactsArr(sortedSegmentArr);
    setTimeout(() => isContactsLoading(false), 500);
  };

  const getSegmentList = (type) => {
    isListLoading(true);
    getSegments().then((response) => {
      if (response.result.data.length > 0) {
        // var segment_name_arr = [];
        // response.forEach((element) => {
        //   segment_name_arr.push({ id: element.id, name: element.segment_name });
        // });
        if(type === 'delete') {
          setSelectedSegmentIndex(0);
        }
        setSegmentObjArr(response.result.data);
        console.log(selectedSegmentIndex);
        console.log(response.result.data[selectedSegmentIndex])
        setSelectedSegmentObj(response.result.data[selectedSegmentIndex]);
        getSegmentContacts(response.result.data[selectedSegmentIndex].id);
      }
      isListLoading(false);
      console.log(response.result.data);
    });
  };

  const getSegmentContacts = (segmentId) => {
    console.log(segmentId)
    isContactsLoading(true);
    getSegmentContactList(segmentId)
      .then((response) => {
        setSegmentContactsArr(response.result.data || []);
      })
      .catch((error) => {
        console.log('ERROR::', error);
        setSegmentContactsArr([]);
      });
    setTimeout(() => isContactsLoading(false), 2000);
  };

  const downloadCsvHandler = () => {
    console.log('SEGMENTS -', selectedSegmentObj);
    getAllSegmentsCsv(selectedSegmentObj.id)
    .then((res) => {
      saveFile(res, selectedSegmentObj.name);
    })
    .catch((error) => console.log('ERROR--',error));
  };

  return (
    <div>
      <Flex>
        <Box width="30%" pr={2}>
          <Table height="auto" marginTop="0">
            <TableWidget>
              <span
                style={{
                  marginLeft: '9px',
                  marginRight: '10px',
                  marginTop: '9px',
                  marginBottom: '10px',
                }}
              >
                {' '}
                Segments
              </span>
              <span style={{ cursor: 'pointer' }}>
                <TableHeaderButtons type="button">
                  <ResponsiveImg
                    data-testid="add-new-segment"
                    src="/assets/images/Add-Active.svg"
                    onClick={() => setSegmentCreateModalOpen(true)}
                    style={{
                      height: '21px',
                      width: 'auto',
                      marginTop: '0px',
                      position: 'relative',
                      top: '6px',
                    }}
                  />
                </TableHeaderButtons>
                <TableHeaderButtons
                  disabled={!segmentOptionsEnabled}
                  type="button"
                >
                  <ResponsiveImg
                    src={
                      segmentOptionsEnabled
                        ? '/assets/images/edit-new11.svg'
                        : '/assets/images/Edit-inactive.svg'
                    }
                    onClick={(event) => setSegmentUpdateModalOpen(true)}
                    style={{
                      height: '21px',
                      width: 'auto',
                      marginTop: '0px',
                      position: 'relative',
                      top: '6px',
                    }}
                  />
                </TableHeaderButtons>
                <TableHeaderButtons
                  disabled={!segmentOptionsEnabled}
                  type="button"
                >
                  <ResponsiveImg
                    src={
                      segmentOptionsEnabled
                        ? '/assets/images/Delete-Active.svg'
                        : '/assets/images/Delete-Inactive.svg'
                    }
                    onClick={(event) => setSegmentDeleteModalOpen(true)}
                    style={{
                      height: '21px',
                      width: 'auto',
                      marginTop: '0px',
                      position: 'relative',
                      top: '6px',
                    }}
                  />
                </TableHeaderButtons>
                <TableHeaderButtons type="button">
                  <ResponsiveImg
                    src={
                      segmentOptionsEnabled
                        ? '/assets/images/Sync-active.svg'
                        : '/assets/images/Sync-grey.svg'
                    }
                    onClick={(event) => getSegmentList()}
                    style={{
                      height: '21px',
                      width: 'auto',
                      marginTop: '0px',
                      position: 'relative',
                      top: '6px',
                    }}
                  />
                </TableHeaderButtons>
              </span>
            </TableWidget>
            <TableBody
              data-testid="segment-list"
              height="auto"
              maxHeight="300px"
              minHeight="300px"
            >
              <LoaderWrapper isLoading={listLoader}>
                {segmentObjArr.length > 0 ? (
                  segmentObjArr.map((element, index) => (
                    <EmailListTableBodyTr
                      style={{
                        minHeight: 'auto',
                        height: 'auto',
                        flex: '0 0 auto',
                      }}
                    >
                      <EmailListTableBodyTd
                        style={
                          index === selectedSegmentIndex
                            ? { backgroundColor: '#dad6ff', textAlign: 'left' }
                            : { textAlign: 'left' }
                        }
                      >
                        <Flex
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          onClick={() => {
                            getSegmentContacts(element.id);
                            setSegmentOptionsEnabled(true);
                            setSegmentId(element.id);
                            setSegmentName(element.name);
                            setSelectedSegmentIndex(index);
                            setSelectedSegmentObj(element);
                          }}
                        >
                          <RadioInput
                            type="radio"
                            name="segment_select"
                            id="segment_select"
                            key={element.id}
                            checked={index === selectedSegmentIndex}
                          />
                          <RadioInputLabel 
                            style={{ 
                              fontSize: '1em',   
                              overflow: ' hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              maxWidth: '150px', 
                            }}
                            title={element.name}
                          >
                            {element.name}
                          </RadioInputLabel>
                        </Flex>
                      </EmailListTableBodyTd>
                    </EmailListTableBodyTr>
                  ))
                ) : (
                  <p>No records found</p>
                )}
              </LoaderWrapper>
            </TableBody>
          </Table>
        </Box>
        <Box width="70%">
          <Table height="auto">
            <TableWidget
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ marginLeft: '20px' }}> Segment information</span>
            </TableWidget>
            <EmailListTableHeading style={{ flex: '0 0 auto' }}>
              <Tr>
                <EmailListTh align="left" width="40%">
                  Email &nbsp;
                  <span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => sortSegmentContacts(true)}
                    >
                      <FontAwesomeIcon icon={faArrowUp} />
                    </span>
                    &nbsp;
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => sortSegmentContacts(false)}
                    >
                      <FontAwesomeIcon icon={faArrowDown} />
                    </span>
                  </span>
                </EmailListTh>
                <EmailListTh align="left" width="20%">
                  First Name
                </EmailListTh>
                <EmailListTh align="left" width="20%">
                  Last Name
                </EmailListTh>
                <EmailListTh align="left" width="20%">
                  Tags
                </EmailListTh>
              </Tr>
            </EmailListTableHeading>
            <TableBody
              data-testid="segment-info"
              height="auto"
              maxHeight="265px"
            >
              <LoaderWrapper isLoading={contactsLoader}>
                {segmentContactsArr.length > 0 ? (
                  segmentContactsArr.map((element) => (
                    <EmailListTableBodyTr
                      style={{
                        minHeight: 'auto',
                        height: 'auto',
                        flex: '0 0 auto',
                      }}
                    >
                      <EmailListTableBodyTd align="left" width="40%">
                        <span>{element.email}</span>
                        {element.subscription_status === 'unsubscribed' && (
                          <span
                            style={{
                              color: '#adadad',
                              fontSize: '8px',
                              paddingLeft: '10px',
                            }}
                          >
                            Unsubscribed
                          </span>
                        )}

                      </EmailListTableBodyTd>
                      <EmailListTableBodyTd align="left" width="20%">
                        <span>{element.first_name}</span>
                      </EmailListTableBodyTd>
                      <EmailListTableBodyTd align="left" width="20%">
                        <span>{element.last_name}</span>
                      </EmailListTableBodyTd>
                      <EmailListTableBodyTd align="left" width="20%">
                        <span>{element.tags.join(', ')}</span>
                      </EmailListTableBodyTd>
                    </EmailListTableBodyTr>
                  ))
                ) : (
                  <p>No records found</p>
                )}
              </LoaderWrapper>
            </TableBody>
            <Flex justifyContent="flex-end" marginTop="10px">
                  <DownloadSampleDiv
                    href="#"
                    onClick={downloadCsvHandler}
                    padding="5px 10px"
                  >
                    <i className="icon dripicons-download"></i> Download CSV
                  </DownloadSampleDiv>
                </Flex>
          </Table>
        </Box>
      </Flex>

      {isSegmentCreateModalOpen && (
        <CreateSegmentModal
          isModalOpen={isSegmentCreateModalOpen}
          setModalOpen={setSegmentCreateModalOpen}
          getSegmentList={getSegmentList}
        />
      )}

      {isSegmentDeleteModalOpen && (
        <DeleteSegmentModal
          isModalOpen={isSegmentDeleteModalOpen}
          setModalOpen={setSegmentDeleteModalOpen}
          segmentId={segmentId}
          segmentName={segmentName}
          getSegmentList={getSegmentList}
        />
      )}

      {isSegmentUpdateModalOpen && (
        <UpdateSegmentModal
          isModalOpen={isSegmentUpdateModalOpen}
          setModalOpen={setSegmentUpdateModalOpen}
          segmentId={segmentId}
          getSegmentContacts={getSegmentContacts}
          getSegmentList={getSegmentList}
          segmentObj={selectedSegmentObj}
        />
      )}
    </div>
  );
};

export default SegmentTable;
