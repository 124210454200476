import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FontAwesome = styled(FontAwesomeIcon)`
  font-size: ${(props) => (props.small ? '24px' : '28px')};
  width: ${(props) => (props.small ? '24px' : '50px !important')};
  height: ${(props) => (props.small ? '24px' : '50px !important')};
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  color: #ffffff;
  background: #6351ed;
  padding: ${(props) => (props.small ? '6px' : '12px')};
  margin: ${(props) => (props.small ? '10px 0px' : '10px')};
  cursor: pointer;
  ${({ isDiconnected }) => isDiconnected && 'background-color: #D4D4D4;'}
`;

export const ImageSpan = styled.span`
  width: ${({ iconHeight }) => iconHeight || '24'}px !important;
  height: ${({ iconHeight }) => iconHeight || '24'}px !important;
  background: #6351ed;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 70%;
    height: auto;
  }
  ${({ isDiconnected }) => isDiconnected && 'background-color: #D4D4D4;'}
`;

export const AdminCheckBox = styled.input.attrs({ type: 'checkbox' })`
  border: ${({ checked, bgColor }) =>
    checked && bgColor
      ? 'none'
      : checked && !bgColor
      ? '1px solid #6351ed'
      : '1px solid #c9c9c9'};
  opacity: 0.00000001;
  z-index: 1;
  position: relative;
  .checked::after {
    transform: rotate(-45deg) scale(1);
    margin-left: 3px;
  }
  ${({ disabled }) =>
    disabled && 'opacity: 0.5;pointer-events:none;cursor:default;border: none;'}
`;

export const GrayCheckBox = styled.div`
  position: relative;
    & label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: ${({ type }) =>
        type === 'discover' ? '5px 4px 4px 4px' : '4px'}; 
      width: 15px;
      height: 15px;
      transition: transform 0.28s ease;
      border-radius: ${({ borderRadius }) => borderRadius || '4px'};
      border: 1px solid #9a9a9a;
      z-index:0;
      background-color: ${({ checked, bgColor }) =>
        checked && bgColor ? bgColor : '#fff'};
  }
  & label:after {
      content: '';
      display: block;
      width: 6px;
      height: 4px;
      border-bottom: 1px solid #e4e4e4;
      border-left: 1px solid #e4e4e4;
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.25s;
      position: absolute;
      border-radius: ${({ borderRadius }) => borderRadius || '4px'};
      top: ${({ type }) => (type === 'discover' ? '8px' : '7px')};;
      left: 7px;
      z-index: 0;
  }
  & input.checked+label:after{
    transform: rotate(-45deg) scale(2) !important;
    margin-left:3px;
    border-bottom: ${({ checked, bgColor }) =>
      checked && bgColor ? `1px solid #fff` : '1px solid #6351ed'};
      border-left: ${({ checked, bgColor }) =>
        checked && bgColor ? `1px solid #fff` : '1px solid #6351ed'};
  }
  & input.checked+label:before{
    border: ${({ color, bgColor, checked }) =>
      color
        ? `1px solid ${color}`
        : bgColor && checked
        ? 'none'
        : '1px solid #6351ed'};
  }
  }
`;
