import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const ArticlesContainer = styled(Flex)`
  height: 348px;
  max-height: 348px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 15px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const SelectedContentContainer = styled(Flex)`
  overflow-x: hidden;
  overflow-y: auto;
  height: 385px;
  margin-top: 10px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;

export const SelectedContentTopic = styled.div`
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
