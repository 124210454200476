import { get, isEmpty } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as loginActions } from '../modules/login.module';
import { getUsersList } from '../../services/analytics';
import { clearSeassionStorage, getSalesforceSettings} from '../../utils';

import {
  authenticate,
  getMultiUserDetails,
  getUserDetails,
  socialLogin,
} from '../../services/login';

export function* loginRequest({ payload }) {
  try {
    const params = get(payload, 'params', {});
    const token = get(payload, 'token', '');
    console.log(params);
    const isRetrieveUser = isEmpty(params);
    let isSocialLogin = params && params.type === 'social';
    let isMultiLogin = params && params.type === 'multi';
    const apiCall = isRetrieveUser
      ? getUserDetails
      : isMultiLogin ?
      getMultiUserDetails
      : isSocialLogin
      ? socialLogin
      : authenticate;
    const response = yield call(apiCall, get(payload, 'params', {}), token);
    const data = get(response, 'result.data', {});
    console.log('USER DETAILS RESPONSE (from saga) - - ', data);
    const processedResponse = getSalesforceSettings(data);

    /*if(!data.only_complaince_content && data.details.user.billing.subscription_type === "na"){
      clearSeassionStorage();
    }*/
    
    if(!isEmpty(get(data, 'secondary_account.data.token', ''))) {
      sessionStorage.setItem('advisorToken', get(data, 'secondary_account.data.token', ''));
    }

    //load smartlook.js
    /*if (data.details.user) {
      const { uid, details } = data;
      window.smartlook('identify', uid, {
        uid: uid,
        name: [details.user.name.first, details.user.name.last].join(' '),
        email: sessionStorage.getItem('email'),
      });
    }*/

    if (!isRetrieveUser) {
      const token = get(data, 'token', '');
      const advisorToken = get(data, 'secondary_account.data.token', '');

      sessionStorage.setItem('token', token);
      if (advisorToken) sessionStorage.setItem('advisorToken', advisorToken);
      if (!isSocialLogin) sessionStorage.setItem('email', params.email);
    }
    yield put(loginActions.api.login.response(processedResponse));
  } catch (error) {
    yield put(loginActions.api.login.error(error));
  }
}

function* loginSaga() {
  yield all([takeLatest(loginActions.api.login.request, loginRequest)]);
}

export default loginSaga;
