/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { ButtonNoBorderBackground, DashboardArticle, LoaderWrapper } from '../../../components';
import {
  getContentLibArticleData,
  dashboardSliderSettings,
  myMioArticlesData,
  approvedToShareArticlesData,
  eventHandlers,
  getMyContentArticles,
  emptyMessages
} from '../../../utils';
import _, { isEmpty, get, uniqBy } from 'lodash';
import { ArticleSliderWrapper, NoContentWrapper } from '../DashboardContainer.styles';
import EditContentModal from './EditContentModal';

const ContentLibraryTab = ({
  selectedTopic,
  contentlibRequest,
  contentlibSetState,
  contentlibDeleteRequest,
  contentLibArticles,
  contentLibArticlesFetching,
  themeNames,
  contentLibArticleDeleting,
  isDeleteBlocked,
  setLocalContent,
  showTabLoader,
  setShowArticleLoader,
  channelId,
  isPremiumAdvisor,
  myMioData,
  curatedTopicType,
  popularTopicDetails,
  exploreArticleRequest,
  onMyMioTabClick,
  isEnterpriseAdvisor,
  isAssetAdvisor,
  exploreArticles,
  popularThemes,
  userData,
  refresh,
  contentLibAddToBucketRequest,
  contentLibRemoveFromBucketRequest,
  newsletterArticleFetching,
  bookmarksFetching,
  toggleLoaderBlocked,
  bookmarksId,
  contentLibAddToBookmarksRequest,
  contentLibRemoveFromBookmarksRequest,
  isShareBlocked,
  contentLibBucketId,
  contentLibInNewsletter,
  contentLibBookMarkId,
  contentLibIsBookmark,
  contentLibATSQueue,
  contentLibFilter,
  isEnterpriseAdmin,
}) => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [imageCounter, setImageCounter] = useState([]);
  const [contentCall, setContentCall] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [newsletterId, setNewsletterId] = useState('');
  const [bookmarks_id, setBookmarksId] = useState('');
  const [allArticles, setAllArticles] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [editModal, toggleEditModal] = useState({ status: false, articleIndex: '' });
  const [paginationData, setPaginationData] = useState({before: null, after: null});
  const [loader, toggleLoader] = useState(false);

  const getMyContentData = ({ after }) => {
    // console.log('CONTENT LIB FILTER - - - - ', contentLibFilter);
    console.log('CHANNEL ID', channelId)
    getMyContentArticles({
      payload: {
        userType: isEnterpriseAdvisor ? 'isEnterpriseAdvisor' : isPremiumAdvisor ? 'isPremiumAdvisor' : 'isEnterpriseAdmin', 
        channelId: channelId, 
        beforeToken: null, 
        afterToken: after,
        pendingArticles: contentLibATSQueue,
        filter: contentLibFilter
      },
      localData: {
        currentArticles: allArticles
      },
      source: 'dashboard'
    })
    .then(res => {
      console.log('RESPONSE contentLib - - -- - ', res);
      const updatedArticlesList = get(res, 'newLocalData.updatedArticles', allArticles);
      const updatedPagination = get(res, 'newLocalData.updatedPaginationData', paginationData);
      setAllArticles(uniqBy((updatedArticlesList), 'id'));
      setPaginationData(updatedPagination);
      if(isEnterpriseAdvisor)
        contentlibSetState(get(res, 'newState', {}));
      toggleLoader(false);
    })
    .catch(err => {
      toggleLoader(false);
      console.log('ERROR IN CONTENT LIB USE EFFECT');
    });
  }

  const handlePagination = () => {
    getMyContentData({after: paginationData.after});
  }

  dashboardSliderSettings.afterChange = (index) => {
    let articles = allArticles.slice(index, index + 4);
    if(index > sliderIndex && paginationData.after)
      handlePagination();
    const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
    articles.forEach(item => {
      eventHandlers(
        'ARTICLE_DISPLAYED', 
        popularThemes, 
        item.id, 
        'MY_CONTENT', 
        item.channelId, 
        item.title, 
        item.themeId, 
        themeNames[item.themeId], 
        item.topic,
        userEmail
      )
    })
    if(index > sliderIndex) {
      setSliderIndex(index);
      // if(contentLibArticles.pagination && contentLibArticles.pagination.after) {  
      //   contentlibRequest({mode:'after', pagination_token: contentLibArticles.pagination.after, ...((isPremiumAdvisor || isEnterpriseAdvisor || isAssetAdvisor) && channelId && {channelId}), type: curatedTopicType})
      // }
    }
  }

  // console.log(allArticles);


  //enterpriseAdmin/Admin => getContentLibrary()

  // premAdv => getMyMioArticles(source = 'all'), getContentLibrary()

  // enterAdv => getApproveToShareArticles(), getContentLibrary()



  // not executed for admin users
  // useEffect(() => {
  //   if(isPremiumAdvisor) {
  //     onMyMioTabClick();
  //   }
  //   if(isEnterpriseAdvisor || isAssetAdvisor) {
  //     setAllArticles([]);
  //     setContentCall(true);
  //     // console.log('CURATED TOPIC TYPE - ', curatedTopicType)
  //     if(curatedTopicType !== 'Approve To Share') {
  //       contentlibRequest({...((isPremiumAdvisor || isEnterpriseAdvisor || isAssetAdvisor) && channelId && {channelId}), type: curatedTopicType});
  //     } 
  //     else {
  //       // contentlibRequest({...((isPremiumAdvisor || isEnterpriseAdvisor || isAssetAdvisor) && channelId && {channelId}), type: curatedTopicType});
  //       setAllArticles([...approvedToShareArticlesData(exploreArticles)]);
  //       setShowArticleLoader(false);
  //     }
  //   } 
  //   // console.log('check');
  //   // console.log('here 1.1 useeffect', contentLibArticles);
    

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [curatedTopicType, exploreArticles]);

  // useEffect(() => {
  //   console.log('setting all articles....');
  //   setAllArticles(getContentLibArticleData(contentLibArticles));
  // }, [contentLibArticles])

  useEffect(() => {
    // console.log(newsletterId);
    if((newsletterId && newsletterId.length > 0 && !isEmpty(allArticles)) && !newsletterArticleFetching) {
      // console.log('...reaching');
      let articles = allArticles.map((item, index) => {
        if(item.id === newsletterId) {
          item.isNewsletter = contentLibInNewsletter;
          item.bucketId = contentLibBucketId
        }
        return item;
      });
      setAllArticles(uniqBy([...articles], 'id'));
      setNewsletterId('');
    }
  }, [contentLibBucketId, contentLibInNewsletter, newsletterArticleFetching])

  useEffect(() => {
    if((bookmarks_id && bookmarks_id.length > 0 && !isEmpty(allArticles))) {
      let articles = allArticles.map((item, index) => {
        if(item.id === bookmarks_id) {
          item.isBookmark = contentLibIsBookmark;
          item.bookmarkId = contentLibBookMarkId;
        }
        return item;
      });
      setAllArticles(uniqBy([...articles], 'id'));
      setBookmarksId('');
    }
  }, [contentLibIsBookmark, contentLibBookMarkId]);

  useEffect(() => {
    if(contentLibArticleDeleting && deleteId && deleteId.length > 0) {
      const articles = _.filter(allArticles,({id})=>id!==deleteId);
      setAllArticles(uniqBy(articles, 'id'));
      setDeleteId('');
    }
  }, [deleteId, contentLibArticleDeleting])


  const contentArticlesForAdmin = () => {
    // console.log('here 1.2', {contentLibArticles, allArticles});
    if(contentLibArticles && parseInt(contentLibArticles.total_article) > 1 && allArticles.length < 4) {
      setAllArticles(getContentLibArticleData(contentLibArticles));
    } 
    else if(isEmpty(contentLibArticles)) {
      setAllArticles([]);
      setSliderIndex(0);
    } else {
        setAllArticles(_.uniqBy([...allArticles, ...getContentLibArticleData(contentLibArticles)], 'id'))
    }
  }

  const contentArticlesForPremiumAdvisor = () => {
    if(isEmpty(contentLibArticles)) {
      setAllArticles([]);
      setSliderIndex(0);
    } else if(contentLibArticles && !isEmpty(contentLibArticles) && parseInt(contentLibArticles.total_article) > 1 && allArticles.length < 4) {
      setShowArticleLoader(false);
      let combinedArticles = [];
      const mioData = myMioArticlesData(myMioData);
      if(myMioData && myMioData.feed && myMioData.feed.length > 0) {
        combinedArticles = _.uniqBy([...mioData, ...getContentLibArticleData(contentLibArticles)], 'id');
        combinedArticles = combinedArticles.sort((d1, d2) => new Date(d2.created_time).getTime() - new Date(d1.created_time).getTime());
        setAllArticles(combinedArticles);
      } else {
        combinedArticles = getContentLibArticleData(contentLibArticles); 
        setAllArticles(combinedArticles);
      }
      
     
    } else {
        // console.log('here 2.1', {
        //   allArticles: _.uniqBy([...allArticles, ...getContentLibArticleData(contentLibArticles)], 'id'),
        //   mymio: getExploreArticleData(myMioData),
        //   length: myMioData
        // });
       
        if(myMioData && myMioData.feed && myMioData.feed.length === 0) {
          setAllArticles(_.uniqBy([...allArticles, ...getContentLibArticleData(contentLibArticles)], 'id'));
        } else {
          setAllArticles(_.uniqBy([...allArticles, ...getContentLibArticleData(contentLibArticles)], 'id'));
        }
        // setAllArticles(_.uniqBy([...allArticles, ...getContentLibArticleData(contentLibArticles)], 'id'));
        // console.log('here 2', {
        //   allArticles: _.uniqBy([...allArticles, ...getContentLibArticleData(contentLibArticles)], 'id'),
        //   mymio: getExploreArticleData(myMioData)
        // });
    }
    // if(getContentLibArticleData(contentLibArticles).length > 0 && isMyMioDataLoaded) {
    //   setAllArticles([...allArticles, ...getExploreArticleData(myMioData)]);
    //   setIsMyMioDataLoaded(false);
    // }

  }

  const contentArticlesForAdvisor = () => {
    if(curatedTopicType !== 'Approve To Share') {
      if(contentCall && isEmpty(get(contentLibArticles, 'channel_content', [])) && isEmpty(get(exploreArticles, 'feed', []))) {
        setAllArticles([]);
        setSliderIndex(0);
        setShowArticleLoader(false);
      } else if (contentCall && !contentLibArticlesFetching && isEmpty(get(contentLibArticles, 'channel_content', [])) && isEmpty(allArticles) && !isEmpty(get(exploreArticles, 'feed', []))) {
        setAllArticles(approvedToShareArticlesData(exploreArticles));
        setSliderIndex(0);
        setShowArticleLoader(false);
      }
        else if(contentLibArticles && !isEmpty(contentLibArticles) && parseInt(contentLibArticles.total_article) >= 1 && allArticles.length < 4) {
        setShowArticleLoader(false);
        let sortedArticles = _.uniqBy([...getContentLibArticleData(contentLibArticles), ...approvedToShareArticlesData(exploreArticles)].sort((d1, d2) => new Date(d2.created_time).getTime() - new Date(d1.created_time).getTime()), 'id');
        setAllArticles([...sortedArticles]);
      } else {
          // console.log('here 2.1', {
          //   allArticles: _.uniqBy([...allArticles, ...getContentLibArticleData(contentLibArticles)], 'id'),
          //   mymio: getExploreArticleData(myMioData),
          //   length: myMioData
          // });
     
          if(exploreArticles && exploreArticles.feed && exploreArticles.feed.length === 0) {
            setAllArticles(_.uniqBy([...allArticles, ...getContentLibArticleData(contentLibArticles)], 'id'));
          } else {
            let sortedArticles = _.uniqBy([...getContentLibArticleData(contentLibArticles), ...allArticles].sort((d1, d2) => new Date(d2.created_time).getTime() - new Date(d1.created_time).getTime()), 'id');
            setAllArticles([...sortedArticles]);
          }
      }
    }
  };


  useEffect(() => {
    toggleLoader(true);
    getMyContentData({ after: null });
  }, [contentLibFilter]);

  // useEffect(() => {
  //   if(isPremiumAdvisor){
  //     // console.log('check');
  //     contentArticlesForPremiumAdvisor();
  //   } 
  //   else if(isEnterpriseAdvisor || isAssetAdvisor) {
  //     contentArticlesForAdvisor();
  //   } 
  //   else{
  //     contentArticlesForAdmin();
  //     // contentArticlesForPremiumAdvisor();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [contentLibArticles]);

  useEffect(() => {
    if(curatedTopicType) {
      setFirstTime(false);
    }
  }, [curatedTopicType])

  useEffect(() => {
    setLocalContent(allArticles);
    if (!firstTime && allArticles.length > 4) {
      const userEmail = userData && userData.details && userData.details.user && userData.details.user.email;
      allArticles.slice(0, 4).forEach((item) => {
        eventHandlers(
          'ARTICLE_DISPLAYED',
          popularThemes,
          item.id,
          'MY_CONTENT',
          item.channelId,
          item.title,
          item.themeId,
          themeNames[item.themeId],
          item.topic,
          userEmail
        );
      });
      setFirstTime(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allArticles]);

  // console.log('allArticles CONTENT LIB TAB- ', allArticles);
  return (
    <>
      <LoaderWrapper isLoading={loader} >
        {!isEmpty(allArticles) ? (
          <ArticleSliderWrapper>
            <Slider {...dashboardSliderSettings}>
              {allArticles.map((article, index) => (
                <DashboardArticle
                  key={index}
                  isEnterpriseAdvisor={isEnterpriseAdvisor}
                  isAssetAdvisor={isAssetAdvisor}
                  fromContentLibTab={true}
                  toggleEditModal={toggleEditModal}
                  article={article}
                  bookmarksFetching={bookmarksFetching}
                  bookmarksId={bookmarksId}
                  approveToShare={article.auxiliary.approved_to_share}
                  {...{
                    ...article,
                    selectedTopic,
                    isContentLibrary: true,
                    themeNames,
                    exploreArticleDeleting: contentLibArticleDeleting,
                    isDeleteBlocked,
                    isShareBlocked,
                    index,
                    deleteArticleRequest: (id) => {
                      if(article.myClout){
                        contentlibDeleteRequest({
                          params: { deleteId: id, myClout: true, ...(channelId && {channelId})},
                        })
                      }else{
                        contentlibDeleteRequest({
                          params: { deleteId: id, ...(channelId && {channelId})},
                        })
                      }
                      setTimeout(() => {
                        setDeleteId(id);
                      }, 300);  
                    },
                    onNewsletterArticleClick: (articleId, themeId, source) => {
                      if(source === 'newsLetter') {
                        if(article.myClout){
                          contentLibAddToBucketRequest({
                            params: { articleId: articleId, themeId: themeId, myClout: true, ...(channelId && {channelId}), type: curatedTopicType},
                          })
                        }else{
                          contentLibAddToBucketRequest({
                            params: {articleId: articleId, themeId: themeId, myClout: true, ...(channelId && {channelId}), type: curatedTopicType },
                          })
                        }
                        setNewsletterId(articleId);
                      } 
                      else {
                        contentLibAddToBookmarksRequest({
                          params: {articleId: articleId, themeId: themeId, myClout: true, ...(channelId && {channelId}), type: curatedTopicType },
                        });
                        setTimeout(() => {
                          setBookmarksId(articleId);
                        }, 300);
                      } 
                    },
                    onRemoveFromNewletterClick: (bucketId, id, source) => {
                      if(source === 'newsLetter') {
                        if(article.myClout){
                          contentLibRemoveFromBucketRequest({
                            params: { articleId: bucketId,  myClout: true, ...(channelId && {channelId}), type: curatedTopicType },
                          })
                        }else{
                          contentLibRemoveFromBucketRequest({
                            params: {articleId: bucketId,  myClout: true, ...(channelId && {channelId}), type: curatedTopicType },
                          })
                        }
                        setNewsletterId(id);
                      }
                      else {
                        contentLibRemoveFromBookmarksRequest({
                          params: {articleId: bucketId, bookmarksId: id,  myClout: true, ...(channelId && {channelId}), type: curatedTopicType },
                        });
                        setTimeout(() => {
                          setBookmarksId(id);
                        }, 300);
                      }
                    },
                    setShowArticleLoader,
                    totalArticles: allArticles.length,
                    imageCounter,
                    setImageCounter,
                    section: 'MY_CONTENT',
                    newsletterArticleFetching,
                    exploreNewsletterId: newsletterId,
                    curatedTopicType: curatedTopicType
                  }}
                  index={index}
                />
              ))}
            </Slider>
          </ArticleSliderWrapper>
        ) : (
          <NoContentWrapper>
            {
              isPremiumAdvisor ? 
              emptyMessages.notAddedByPremiumAdvisor :
              isEnterpriseAdmin ? 
              emptyMessages.notAddedByAdmin :
              emptyMessages.default
            }
          </NoContentWrapper>
        )}
      </LoaderWrapper>
      {editModal.status && (
        <EditContentModal 
          articlesList={allArticles} 
          updateArticlesList={setAllArticles} 
          index={editModal.articleIndex} 
          isOpen={editModal.status}
          closeModal={() => toggleEditModal({status: false, articleIndex: ''})}
        />
      )}
    </>
  );
};

export default ContentLibraryTab;
