import React, { useState, useEffect } from 'react';
import { Flex, Box } from '@rebass/grid';
import {
  Table,
  Tr,
  TableBody,
  LoaderWrapper,
  EmailListTh,
  EmailListTableBodyTr,
  EmailListTableBodyTd,
  EmailListTableHeading,
  TableHeaderButtons,
  Button,
  TableWidget,
  ResponsiveImg,
  Modal,
  ModalCloseButton,
  ModalBody,
  EmailSearch,
  DownloadSampleDiv,
} from '../../../components';
import './EmailList.styles.css'
import { isEmpty, uniqBy, map, differenceBy, get, filter } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { syncContactList } from '../../../services/configure';
import { getContactsList } from '../../../services/configure/index';
import {
  postUpdateContactList,
  getAllContactsCSV,
  getAllUnsubscribes,
} from '../../../services/dashboard/index';
import { saveFile } from '../../../utils/common.helper';
import ListRenameModal from '../../../components/CreateEmailListModal/ListRenameModal';

const EmailListTable = ({
  data,
  onContactDelete,
  isContactsFetching,
  setSelectedEmailListItem,
  contactsRequest,
  selectedContactGroup,
  paginationData,
  navigateAfter,
  page,
  setPage,
  contactsCount,
  selectedEmailListItem,
  isDelete,
  setIsDelete,
  contactGroupsList,
  setSelectedItem,
  uploadType,
  handleEmailSort,
  sortFlag,
  customContacts,
  setModalOpen,
  setAddEmailModal,
  hsSort,
  sfSort,
  wbSort,
  rtSort,
  gmSort,
  olSort,
  onCreateListClick,
  handleContactListChange,
  setCustomizeEmailOpen,
  handleContactListUpdate,
  handleModalOpen,
  contactGroups,
  socialNetworkData,
  snetworkProvider,
  setSelectedNetwork,
  setContactOpen,
  toggleFromEdit,
  userData,
}) => {
  // console.log('contactGroups - ', contactGroupsList);
  const [isAllCheckSelected, setAllCheckSelected] = useState(false);
  const [emailTableData, setEmailTableData] = useState(data || []);
  const [confirmDeleteCGModal, setConfirmDeleteCGModal] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [emailList, setEmailList] = useState([]);
  const [emailListCheckedCount, setEmailListCheckedCount] = useState(0);
  const [isEmailListDefaultExist, setIsEmailListDefaultExist] = useState(false);
  const [isEmailDelete, setIsEmailDelete] = useState(false);
  const [isEmailListAllCheckSelected, setAllEmailListCheckSelected] =
    useState(false);
  const [deleteListType, setDeleteListType] = useState('');
  const [selectedContactGroupListItem, setSelectedContactGroupListItem] =
    useState(null);
  const [isAllContactClicked, setIsAllContactClicked] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [currentlyEditingContactList, setCurrentlyEditingContactList] =
    useState({});
  const [isLoading, setLoading] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [renameModalLoader, setRenameModalLoader] = useState(false);
 // const [emailListLoader, setEmailListLoader] = useState(false);
  // const [pageCount, setPageCount] = useState(0);

  const sortAlphaNum = (a, b) => {
    console.log(a, b);
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
      var AInt = parseInt(a, 10);
      var BInt = parseInt(b, 10);
  
      if(isNaN(AInt) && isNaN(BInt)){
          var aA = a && a.name.replace(reA, "");
          var bA = b && b.name.replace(reA, "");
          if(aA === bA) {
              var aN = parseInt(a.name.replace(reN, ""), 10);
              var bN = parseInt(b.name.replace(reN, ""), 10);
              return aN === bN ? 0 : aN > bN ? 1 : -1;
          } else {
              return aA > bA ? 1 : -1;
          }
      }else if(isNaN(AInt)){//A is not an Int
          return 1;//to make alphanumeric sort first return -1 here
      }else if(isNaN(BInt)){//B is not an Int
          return -1;//to make alphanumeric sort first return 1 here
      }else{
          return AInt > BInt ? 1 : -1;
      }
  }


  useEffect(() => {
    // console.log('DATA - ', data);
    if (isEmpty(selectedEmailListItem)) {
      setEmailTableData(data);
    } else {
      selectedEmailListItem.forEach((selectedItem) => {
        data.forEach((element) => {
          if (selectedItem.email === element.email) {
            element.checked = true;
          }
        });
      });
      setEmailTableData(data);
    }
    setIdsToDelete([]);
    setAllCheckSelected(false);
    // console.log('Selected contactGroup...', selectedContactGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    console.log('contactGroupsList', contactGroupsList);
  }, [contactGroupsList]);

  useEffect(() => {
    console.log('data', data);
  }, [data]);

  useEffect(() => {
    // let customContactsCopy = customContacts.map((item) => ({
    //   ...item,
    //   checked: false,
    // }));

    let customContactsCopy = [];

    let defaultList = filter(contactGroupsList, function (o) {
      return o.contact_type === 'default';
    });
    console.log('contactGroupsList', contactGroupsList);
    console.log('defaultList', defaultList);
    if (!isEmpty(defaultList)) {
      defaultList.unshift({
        description: 'group_header',
        external_uuid: '-1',
        id: '-1',
        name: 'Others',
        type: 'default',
        checked: defaultList.every((item) => item.checked),
      });

      customContactsCopy = customContactsCopy.concat(defaultList);
    }

    let gmailPresenceCheck = filter(contactGroupsList, function (o) {
      return o.type === 'gmail';
    });
    // console.log('contactGroups', contactGroups);
    // console.log(
    //   'gmailID',
    //   contactGroups &&
    //     contactGroups.find((c) => c.type === 'gmail') &&
    //     contactGroups.find((c) => c.type === 'gmail').id
    // );
    if (gmailPresenceCheck.length === 0) {
      gmSort.unshift({
        description: 'all_contacts',
        external_uuid: '-1',
        id:
          contactGroups &&
          contactGroups.find((c) => c.name === 'GMAIL') &&
          contactGroups.find((c) => c.name === 'GMAIL').id,
        name: 'Gmail All Contacts',
        type: 'gmail',
        checked: false,
      });
      if (
        socialNetworkData &&
        socialNetworkData['em'] &&
        socialNetworkData['em'].length > 0 &&
        socialNetworkData['em'].filter((s) => s.snetworkProvider === 'google')
          .length > 0
      ) {
        customContactsCopy = customContactsCopy.concat(gmSort);
      }
    }

    let prospectList = filter(contactGroupsList, function (o) {
      return o.contact_type === 'prospect';
    });
    console.log('prospectList', prospectList);

    if (!isEmpty(prospectList)) {
      prospectList.unshift({
        description: 'group_header',
        external_uuid: '-1',
        id: '-1',
        name: 'Prospects',
        type: 'prospect',
        checked: prospectList.every((item) => item.checked),
      });
      customContactsCopy = customContactsCopy.concat(prospectList);
    }

    let clientList = filter(contactGroupsList, function (o) {
      return o.contact_type === 'client';
    });
    console.log('clientList', clientList);

    if (!isEmpty(clientList)) {
      clientList.unshift({
        description: 'group_header',
        external_uuid: '-1',
        id: '-1',
        name: 'Clients',
        type: 'client',
        checked: clientList.every((item) => item.checked),
      });

      customContactsCopy = customContactsCopy.concat(clientList);
    }

    if (
      socialNetworkData &&
      socialNetworkData['em'] &&
      socialNetworkData['em'].length > 0 &&
      socialNetworkData['em'].filter((s) => s.snetworkProvider === 'google')
        .length > 0
    ) {
      // console.log('gmaiklconnected', customContactsCopy);
    } else {
      customContactsCopy = filter(customContactsCopy, function (o) {
        return o.type !== 'gmail';
      });
      // console.log('gmaildisconnected', customContactsCopy);
    }
    //const sortAlphaNum = ({name : a}, {name : b}) => a.localeCompare(b, 'en', { numeric: true })
   
   customContactsCopy = customContactsCopy.sort((a, b ) => sortAlphaNum(a, b))
    setEmailList(customContactsCopy.filter((item) => item));
    setEmailListCheckedCount(
      customContactsCopy.filter((item) => item.checked).length
    );
    if (selectedContactGroup) {
      setSelectedContactGroupListItem(
        customContactsCopy.find(
          (item) => item && item.id === selectedContactGroup
        )
      );
    } else {
      setSelectedContactGroupListItem(
        customContactsCopy.find((item) => item && item.is_default)
      );
    }
  }, [
    gmSort,
    selectedContactGroup,
    socialNetworkData,
    contactGroupsList,
    contactGroups,
  ]);

  // useEffect(() => {
  //   let customContactsCopy = customContacts.map((item) => ({
  //     ...item,
  //     checked: false,
  //   }));
  //   if (customContactsCopy.length > 0) {
  //     customContactsCopy.unshift({
  //       description: 'group_header',
  //       external_uuid: '-1',
  //       id: '-1',
  //       name: 'My Lists',
  //       type: customContactsCopy[0].type,
  //       checked: false,
  //     });
  //   }
  //   if (hsSort && hsSort.length > 0) {
  //     // console.log('Hubspot', hsSort);
  //     hsSort.unshift(
  //       {
  //         description: 'group_header',
  //         external_uuid: '-1',
  //         id: '-1',
  //         name: 'Hubspot',
  //         type: hsSort[0].type,
  //         checked: hsSort.every((item) => item.checked),
  //       },
  //       {
  //         description: 'all_contacts',
  //         external_uuid: '-1',
  //         id:
  //           contactGroups && contactGroups.find((c) => c.name === 'HUBSPOT').id,
  //         name: 'All Contacts',
  //         type: hsSort[0].type,
  //         checked: false,
  //       }
  //     );

  //     if (isEmpty(get(socialNetworkData, 'hs', []))) {
  //       delete hsSort[1];
  //     }
  //     customContactsCopy = customContactsCopy.concat(hsSort);
  //   }

  //   if (sfSort && sfSort.length > 0) {
  //     // console.log('Salesforce', sfSort);
  //     sfSort.unshift(
  //       {
  //         description: 'group_header',
  //         external_uuid: '-1',
  //         id: '-1',
  //         name: 'Salesforce',
  //         type: sfSort[0].type,
  //         checked: sfSort.every((item) => item.checked),
  //       },
  //       {
  //         description: 'all_contacts',
  //         external_uuid: '-1',
  //         id:
  //           contactGroups &&
  //           contactGroups.find((c) => c.name === 'SALESFORCE').id,
  //         name: 'All Contacts',
  //         type: sfSort[0].type,
  //         checked: false,
  //       }
  //     );

  //     if (isEmpty(get(socialNetworkData, 'sf', []))) {
  //       delete sfSort[1];
  //     }
  //     customContactsCopy = customContactsCopy.concat(sfSort);
  //   }
  //   if (wbSort && wbSort.length > 0) {
  //     // console.log('WealthBox', wbSort);
  //     wbSort.unshift(
  //       {
  //         description: 'group_header',
  //         external_uuid: '-1',
  //         id: '-1',
  //         name: 'WealthBox',
  //         type: wbSort[0].type,
  //         checked: wbSort.every((item) => item.checked),
  //       },
  //       {
  //         description: 'all_contacts',
  //         external_uuid: '-1',
  //         id:
  //           contactGroups &&
  //           contactGroups.find((c) => c.name === 'WEALTHBOX').id,
  //         name: 'All Contacts',
  //         type: 'wealthbox',
  //         checked: false,
  //       }
  //     );
  //     if (isEmpty(get(socialNetworkData, 'wb', []))) {
  //       delete wbSort[1];
  //     }
  //     customContactsCopy = customContactsCopy.concat(wbSort);
  //   }
  //   if (rtSort && rtSort.length > 0) {
  //     rtSort.unshift(
  //       {
  //         description: 'group_header',
  //         external_uuid: '-1',
  //         id: '-1',
  //         name: 'RedTail',
  //         type: rtSort[0].type,
  //         checked: rtSort.every((item) => item.checked),
  //       },
  //       {
  //         description: 'all_contacts',
  //         external_uuid: '-1',
  //         id:
  //           contactGroups && contactGroups.find((c) => c.name === 'REDTAIL').id,
  //         name: 'All Contacts',
  //         type: rtSort[0].type,
  //         checked: false,
  //       }
  //     );
  //     if (isEmpty(get(socialNetworkData, 'rt', []))) {
  //       delete rtSort[1];
  //     }
  //     customContactsCopy = customContactsCopy.concat(rtSort);
  //   }

  //   // if (gmSort && gmSort.length > 0) {
  //   // console.log('Gmail', gmSort);
  //   gmSort.unshift(
  //     {
  //       description: 'group_header',
  //       external_uuid: '-1',
  //       id: '-1',
  //       name: 'Gmail',
  //       type: 'gmail',
  //       checked: false,
  //     },
  //     {
  //       description: 'all_contacts',
  //       external_uuid: '-1',
  //       id:
  //         contactGroups &&
  //         contactGroups.find((c) => c.name === 'GMAIL') &&
  //         contactGroups.find((c) => c.name === 'GMAIL').id,
  //       name: 'All Contacts',
  //       type: 'gmail',
  //       checked: false,
  //     }
  //   );

  //   // console.log('Outlook', olSort);
  //   olSort.unshift(
  //     {
  //       description: 'group_header',
  //       external_uuid: '-1',
  //       id: '-1',
  //       name: 'Outlook',
  //       type: 'outlook',
  //       checked: false,
  //     },
  //     {
  //       description: 'all_contacts',
  //       external_uuid: '-1',
  //       id:
  //         contactGroups &&
  //         contactGroups.find((c) => c.name === 'OUTLOOK') &&
  //         contactGroups.find((c) => c.name === 'OUTLOOK').id,
  //       name: 'All Contacts',
  //       type: 'outlook',
  //       checked: false,
  //     }
  //   );

  //   // if(isEmpty(get(socialNetworkData, 'go', []))) {
  //   //   delete gmSort[1];
  //   // }
  //   // console.log('check', get(socialNetworkData, 'go', []));
  //   // console.log('socialNetworkData', socialNetworkData);
  //   // console.log('customContactsCopy', customContactsCopy);
  //   // console.log('selectedContactGroupListItem', selectedContactGroupListItem);

  //   if (
  //     socialNetworkData &&
  //     socialNetworkData['em'] &&
  //     socialNetworkData['em'].length > 0 &&
  //     socialNetworkData['em'].filter((s) => s.snetworkProvider === 'google')
  //       .length > 0
  //   ) {
  //     customContactsCopy = customContactsCopy.concat(gmSort);
  //   }

  //   /* if((socialNetworkData && socialNetworkData['em'] && socialNetworkData['em'].length === 0) && (customContactsCopy && customContactsCopy.length > 0)
  //       || ((socialNetworkData && socialNetworkData['em'] && socialNetworkData['em'].length > 0) && (customContactsCopy && customContactsCopy.length > 0)))
  //     {
  //       customContactsCopy = customContactsCopy.concat('');
  //     }*/
  //   // }
  //   // const groupEmailList = customContactsCopy.map(item => {
  //   //   if(item.id === selectedContactGroup) {
  //   //     item.checked = true;
  //   //   }
  //   //   return item;
  //   // })

  //   //setEmailList(customContactsCopy);
  //   setEmailList(customContactsCopy.filter((item) => item));
  //   setEmailListCheckedCount(
  //     customContactsCopy.filter((item) => item.checked).length
  //   );
  //   if (selectedContactGroup) {
  //     setSelectedContactGroupListItem(
  //       customContactsCopy.find(
  //         (item) => item && item.id === selectedContactGroup
  //       )
  //     );
  //   } else {
  //     setSelectedContactGroupListItem(
  //       customContactsCopy.find((item) => item && item.is_default)
  //     );
  //   }
  // }, [
  //   customContacts,
  //   hsSort,
  //   sfSort,
  //   wbSort,
  //   rtSort,
  //   gmSort,
  //   olSort,
  //   selectedContactGroup,
  //   socialNetworkData,
  // ]);

  useEffect(() => {
    if (emailTableData.length < 250 && page === 0) {
      setPage(0);
    }
    // console.log(page);
    if (page === 0) {
      setStartIndex(contactsCount === 0 ? 0 : 1);
      setEndIndex(
        contactsCount === 0
          ? 0
          : emailTableData.length < 250
            ? contactsCount
            : 250
      );
    } else {
      setStartIndex(page * 250 + 1);
      setEndIndex(
        (page + 1) * 250 >= contactsCount ? contactsCount : (page + 1) * 250
      );
    }

    if (!isEmpty(selectedEmailListItem)) {
      if (page === 0) {
        if (
          emailTableData.filter((e) => e.checked).length === 250 ||
          emailTableData.filter((e) => e.checked).length === contactsCount
        ) {
          setAllCheckSelected(true);
        }
      } else {
        if (
          emailTableData.filter((e) => e.checked).length === 250 ||
          emailTableData.filter((e) => e.checked).length ===
          contactsCount - page * 250
        ) {
          setAllCheckSelected(true);
        }
      }
    }

    let defaultContactId =
      !isEmpty(contactGroupsList) &&
      contactGroupsList.filter((e) => e.is_default);
    let crmIds =
      contactGroups &&
      contactGroups.filter((c) =>
        [
          'GMAIL',
          'HUBSPOT',
          'OUTLOOK',
          'WEALTHBOX',
          'SALESFORCE',
          'REDTAIL',
        ].includes(c.name)
      );
    crmIds = map(crmIds, 'id');
    if (!isEmpty(defaultContactId)) {
      setIsEmailListDefaultExist(
        defaultContactId[0].id === selectedContactGroup ||
        crmIds.includes(selectedContactGroup)
      );
    } else if (!isEmpty(crmIds)) {
      setIsEmailListDefaultExist(crmIds.includes(selectedContactGroup));
    } else {
      setIsEmailListDefaultExist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTableData, selectedContactGroup, page]);

  const onAllCheckboxClick = () => {
    const isChecked = !isAllCheckSelected;
    let removeDuplicates;
    // console.log(isChecked);
    setAllCheckSelected(!isAllCheckSelected);
    const allIds = [];
    const transformedData = emailTableData.map((list, index) => {
      allIds.push(list.email);
      return {
        ...list,
        checked: !isAllCheckSelected,
      };
    });
    setIdsToDelete(isChecked ? allIds : []);
    setEmailTableData(transformedData);
    // let previousFilteredItem = window.sessionStorage.getItem('filteredItem') || [];
    let filteredData = transformedData.filter(({ checked }) => checked) || [];
    if (!isChecked) {
      // console.log('all checked');
      removeDuplicates = differenceBy(
        selectedEmailListItem,
        emailTableData,
        'email'
      );
      setSelectedEmailListItem(removeDuplicates);
    } else {
      // console.log('not checked');
      // window.sessionStorage.setItem('filteredItem', filteredData);
      setSelectedEmailListItem(
        uniqBy([...selectedEmailListItem, ...filteredData], 'email')
      );
    }
  };

  // const sortEmailList = (isAscending) => {
  //   setEmailListLoader(true);
  //   var sortedEmailListArr = emailList.sort((a,b) => {
  //     if (a.email > b.email) {
  //       return 1;
  //     }
  //     if (a.email < b.email) {
  //       return -1;
  //     }
  //     return 0;
  //   });
  //   if (!isAscending) {
  //     sortedEmailListArr.reverse();
  //   }
  //   console.log(sortedEmailListArr);
  //   setEmailList(sortedEmailListArr);
  //   setTimeout(() => setEmailListLoader(false), 500);
  // }

  const onIndividualCheckboxClick = (itemIndex, email) => {
    // console.log('INDIVIDUAL CLICK - - - - ');
    let checkCnt = 0;
    let selectEmailList = selectedEmailListItem;
    let removeDuplicates, transformedData;
    if (selectEmailList.filter((s) => s.email === email).length > 0) {
      removeDuplicates = selectEmailList.filter((s) => s.email !== email);
      transformedData = emailTableData.map((list, index) => {
        const checked = index === itemIndex ? !list.checked : list.checked;
        if (checked) {
          setIdsToDelete([...idsToDelete, list.email]);
          checkCnt++;
        }
        return {
          ...list,
          checked,
        };
      });
      setAllCheckSelected(checkCnt === transformedData.length);
    } else {
      transformedData = emailTableData.map((list, index) => {
        const checked = index === itemIndex ? !list.checked : list.checked;
        if (checked) {
          setIdsToDelete([...idsToDelete, list.email]);
          checkCnt++;
        }
        return {
          ...list,
          checked,
        };
      });
      setAllCheckSelected(checkCnt === transformedData.length);

      // let previousFilteredItem = JSON.parse(window.sessionStorage.getItem('filteredItem')) || [];
      let filteredData = transformedData.filter(({ checked }) => checked) || [];
      // console.log(filteredData);
      // console.log(selectEmailList);
      removeDuplicates = uniqBy([...selectEmailList, ...filteredData], 'email');
    }
    // console.log(removeDuplicates);
    setSelectedEmailListItem(removeDuplicates);
    setEmailTableData(transformedData);
    // window.sessionStorage.setItem('filteredItem', JSON.stringify(filteredData));
  };

  const onDeleteClick = (event, type) => {
    event.preventDefault();
    event.stopPropagation();
    setIsEmailDelete(true);
    setDeleteListType(type);
  };

  const onDisabledDeleteButtonClick = (event, type) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onEmailListCheckBtnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const emailIsDefaultList = contactGroupsList.filter(
      (e) => e.id === selectedContactGroup
    );
    handleContactListUpdate(
      emailIsDefaultList[0].name,
      emailIsDefaultList[0].id
    );
  };

  const onIndividualEmailListCheckboxClick = (item) => {
    // console.log(emailList);
    emailList.forEach((emailItem) => {
      if (emailItem.id === item.id) {
        item.checked = !item.checked;
      }
    });

    const checkedCount = emailList.filter((item) => item.checked).length;
    setEmailListCheckedCount(checkedCount);
    setEmailList([...emailList]);
    setAllEmailListCheckSelected(emailList.length === checkedCount);
  };

  const onEmailContractRowClick = (item) => {
    // console.log('EMAIL CONTRACT ROW CLICKED - ', item);
    if (item.type === 'gmail' && item.description === 'all_contacts') {
      setIsAllContactClicked(true);
    }
    setCurrentlyEditingContactList({});
    setSelectedContactGroupListItem(item);
    handleContactListChange(item.id);
  };

  const onSelectEmailGroup = (item) => {
    console.log('onSelectEmailGroup', item);
    console.log('emailList', emailList);
    item.checked = !item.checked;

    emailList.forEach((emailItem) => {
      if (
        emailItem.contact_type === item.type &&
        (emailItem.description !== 'group_header' ||
          emailItem.description !== 'all_contacts')
      ) {
        emailItem.checked = item.checked;
      }
    });

    setAllEmailListCheckSelected(emailList.every((item) => item.checked));
    setEmailListCheckedCount(emailList.filter((item) => item.checked).length);
    // console.log('onSelectEmailGroup', emailList);
    setEmailList([...emailList]);
  };

  const handleEmailDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let selectedId = [],
      deleteContactGroup = false;
    getContactsList({
      contactGroup: selectedContactGroup
    })
      .then((response) => {
        const allEmails = response.result.data.contacts[selectedContactGroup];
        if (deleteListType === 'email') {
          let deleteContactGroups = emailList.filter(
            (c) =>
              c.description !== 'group_header' &&
              c.description !== 'all_contacts'
          );
          deleteContactGroups = deleteContactGroups.filter((c) => c.checked);
          selectedId = map(deleteContactGroups, 'id');
        } else {
          selectedId = map(
            emailTableData.filter((item) => item.checked),
            'email'
          );
          deleteContactGroup = selectedId.length === allEmails.length;
          if (deleteContactGroup) {
            // selectedId = [selectedContactGroup];
            setConfirmDeleteCGModal(true);
            return;
          }
        }
        setIsEmailDelete(false);
        setSelectedEmailListItem([]);
        onContactDelete(
          selectedId,
          deleteListType === 'email' ? true : deleteContactGroup,
          null,
          deleteListType === 'email' ? selectedId : emailTableData,
          deleteListType === 'email'
        );
        setPage(0);
      })
      .catch((err) => {
        // console.log('Error...', err);
      });
  };

  const addActiveClick = (event) => {
    event.preventDefault();
    //event.stopImmediatePropagation();
    if (
      selectedContactGroupListItem &&
      selectedContactGroupListItem.type === 'custom'
    ) {
      setAddEmailModal(true);
    }
  };

  const onPaginationClick = (type) => {
    contactsRequest({
      params: {
        contactGroup: selectedContactGroup,
        paginationData: paginationData[type],
        navigateTo: type,
        onPageClick: true,
        sync: '',
        endIndex: endIndex,
        sortOptions: sortFlag,
      },
    });
    // setPageCount(type === 'before' ? pageCount === 0 ? 0 : pageCount - 1 : pageCount + 1);
    setPage(type === 'before' ? (page === 0 ? 0 : page - 1) : page + 1);
  };

  const onSyncNowClick = () => {
    syncContactList(selectedContactGroup, 1)
      .then((res) => {
        if (res.result.success) {
          window.location.reload();
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getRowClass = (item) => {
    let className = '';
    if (item && item.id === selectedContactGroup) {
      className += 'email-list-selected ';
    }
    if (item && item.is_default) {
      className += 'email-list-default ';
    }
    return className;
  };

  const editHandler = () => {
    if (selectedEmailListItem.length > 0) {
      toggleFromEdit(true);
      setAddEmailModal(true);
    }
  };

  const reloadGroupList = (item) => {
    // console.log('NAME - ', item);
    let sNetwork;
    switch (item.toLowerCase()) {
      case 'hubspot':
        sNetwork = 'hs';
        break;
      case 'salesforce':
        sNetwork = 'sf';
        break;
      case 'wealthbox':
        sNetwork = 'wb';
        break;
      case 'redtail':
        sNetwork = 'rt';
        break;
      case 'gmail':
        sNetwork = 'gm';
        break;
      default:
        break;
    }
    // console.log('SNETWORK - ', sNetwork);
    if (sNetwork) {
      setSelectedNetwork(sNetwork);
      setContactOpen(true);
    }
  };

  const searchHandler = () => {
    contactsRequest({
      params: {
        contactGroup: selectedContactGroup,
        searchString: searchString,
      },
    });
    if (page > 0) {
      setPage(0);
    }
    // postSearchEmail(searchString, selectedContactGroupListItem.id)
    // .then(response => {
    //   console.log('RESULT DATA = ', response);
    //   if(response && response.result.data && response.result.data && response.result.data.contacts && response.result.data.contacts[selectedContactGroupListItem.id])
    //     setEmailTableData(response.result.data.contacts[selectedContactGroupListItem.id])
    // })
    // .catch(err => console.log('ERROR = ', err));
  };

  const handleContactGroupDelete = (event, option) => {
    event.preventDefault();
    event.stopPropagation();
    // console.log('deleteContactGroup - ', option === 'yes');
    let selectedId;
    if (option === 'yes') selectedId = [selectedContactGroup];
    else
      selectedId = map(
        emailTableData.filter((item) => item.checked),
        'email'
      );
    setConfirmDeleteCGModal(false);
    setIsEmailDelete(false);
    setSelectedEmailListItem([]);
    onContactDelete(
      selectedId,
      option === 'yes',
      null,
      deleteListType === 'email' ? selectedId : emailTableData,
      option === 'yes'
    );
    setPage(0);
  };

  const updateEmailListHandler = (data) => {
    setRenameModalLoader(true);
    console.log(currentlyEditingContactList);
    // let payload = {
    //   name: currentlyEditingContactList.value,
    // };
    console.log('data', data);
    let payload = {
      name: data.updatedName,
      contact_type: data.contactType,
    };
    postUpdateContactList(currentlyEditingContactList.item.id, payload)
      .then((response) => {
        console.log('RESPONSE FROM UPDATE CONTACTLIST API - ', response);
        setCurrentlyEditingContactList({});
        contactsRequest({ params: { contactGroup: selectedContactGroup } });
        setRenameModalLoader(false);
        setRenameModal(false);
      })
      .catch((err) => {
        console.log('ERROR - ', err);
        setRenameModalLoader(false);
        setRenameModal(false);
      });
  };

  const downloadCSVHandler = () => {
    console.log('CONTACTLIST - ', selectedContactGroupListItem);
    getAllContactsCSV(selectedContactGroupListItem.id)
      .then((res) => {
        saveFile(res, selectedContactGroupListItem.name);
      })
      .catch((err) => console.log('ERRR -- ', err));
  };
  // console.log('selectedEmailListItem - ', selectedEmailListItem);

  const onDownloadUnsubscribes = () => {
    setLoading(true);

    let name =
      get(userData, 'name.first', 'unknown') + get(userData, 'name.last', '');

    getAllUnsubscribes()
      .then((res) => {
        saveFile(res, `Unsubscribed_emails_${name}`);
        setLoading(false);
      })
      .catch((err) => {
        console.log('ERRR -- ', err);
        setLoading(false);
      });
  };

  return (
    <>
      {emailList && emailList.length > 0 ? (
        <Flex>
          <div style={{ position: 'absolute', right: '7%' }}>
            <Box
              style={
                window.innerWidth < 768
                  ? {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
                  : {
                    display: 'inline-flex',
                    flexDirection: 'row-reverse',
                    marginRight: '5px',
                  }
              }
              width={`${window.innerWidth < 768 ? '100%' : '100%'}`}
            >
            </Box>
          </div>
          <Box width="30%" pr={2}>
            <Table height="auto">
              <TableWidget>
                <span
                  style={{
                    marginLeft: '9px',
                    marginRight: '6px',
                    marginTop: '10px',
                    marginBottom: '8px',
                  }}
                >
                  {' '}
                  Email Lists
                </span>
                <Flex
            fontSize="10px"
            style={{
              position: 'relative',
              top: '11px',
              cursor: 'pointer',
              marginRight: '5px'
            }}
           // onClick={() => sortEmailList(!contactGroupsList)}
          >
            {/* <span marginRight= "4px">
              <span style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </span>
              &nbsp;
              <span style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </span>
            </span> */}
          </Flex>

                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleEmailSort('asc')}
                >
                  <ResponsiveImg
                    data-testid="add-new-email-list"
                    src="/assets/images/Add-Active.svg"
                    onClick={() => setModalOpen(true)}
                    style={{
                      height: '21px',
                      width: 'auto',
                      marginTop: '0px',
                      marginRight: '-6px',
                      position: 'relative',
                      top: '6px',
                    }}
                  />
                  <TableHeaderButtons
                    disabled={isEmailListDefaultExist}
                    onClick={(event) => onEmailListCheckBtnClick(event)}
                    type="button"
                  >
                    {isEmailListDefaultExist ? (
                      <ResponsiveImg
                        src="/assets/images/Tick-Inactive.svg"
                        style={{
                          height: '21px',
                          width: 'auto',
                          marginTop: '0px',
                          marginLeft: '6px',
                          marginRight: '-6px',
                          position: 'relative',
                          top: '6px',
                        }}
                      />
                    ) : (
                      <ResponsiveImg
                        src="/assets/images/Tick-Active.svg"
                        style={{
                          height: '21px',
                          width: 'auto',
                          marginTop: '0px',
                          marginLeft: '6px',
                          marginRight: '-6px',
                          position: 'relative',
                          top: '6px',
                        }}
                      />
                    )}
                  </TableHeaderButtons>
                  <TableHeaderButtons
                    disabled={emailListCheckedCount === 0}
                    type="button"
                  >
                    {emailListCheckedCount === 0 ? (
                      <ResponsiveImg
                        src="/assets/images/Delete-Inactive.svg"
                        onClick={(event) =>
                          onDisabledDeleteButtonClick(event, 'email')
                        }
                        style={{
                          height: '21px',
                          width: 'auto',
                          marginTop: '0px',
                          marginRight: '-4px',
                          position: 'relative',
                          top: '6px',
                        }}
                      />
                    ) : (
                      <ResponsiveImg
                        src="/assets/images/Delete-Active.svg"
                        onClick={(event) => onDeleteClick(event, 'email')}
                        style={{
                          height: '21px',
                          width: 'auto',
                          marginTop: '0px',
                          marginRight: '-4px',
                          position: 'relative',
                          top: '6px',
                        }}
                      />
                    )}
                  </TableHeaderButtons>

                  <TableHeaderButtons
                    onClick={(event) => onSyncNowClick(event)}
                    type="button"
                  >
                    <ResponsiveImg
                      src="/assets/images/Sync-grey.svg"
                      style={{
                        height: '21px',
                        width: 'auto',
                        marginTop: '0px',
                        marginRight: '-4px',
                        position: 'relative',
                        top: '6px',
                      }}
                    />
                  </TableHeaderButtons>
                </span>
              </TableWidget>
              <TableBody
                data-testid="email-list-data"
                height="auto"
                maxHeight="240px"
                minHeight="240px"
              >
                {!isEmpty(emailList) &&
                  emailList.map((item, index) => {
                    // if(item && item.description && item.description === 'group_header')
                    //   console.log('EMAIL LIST ITEM - ', item);
                    return (
                      <>
                        {item &&
                          item.description &&
                          item.description !== 'group_header' && (
                            <EmailListTableBodyTr
                              style={{
                                minHeight: 'auto',
                                height: 'auto',
                                flex: '0 0 auto',
                              }}
                              alternate={false}
                              key={item && item.name + index}
                              className={getRowClass(item)}
                            >
                              <EmailListTableBodyTd width="20%">
                                {/* item.description !== 'all_contacts' */}
                                {item &&
                                  item.description &&
                                  item.type !== 'gmail' && (
                                    <label className="container">
                                      <input
                                        id={`name${index}`}
                                        type="checkbox"
                                        checked={item.checked}
                                        onChange={() =>
                                          onIndividualEmailListCheckboxClick(
                                            item
                                          )
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  )}
                              </EmailListTableBodyTd>
                              <EmailListTableBodyTd
                                padding="8px 0px"
                                align="left"
                                width="80%"
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <div
                                    data-testid={
                                      item.name + '_' + item.contact_type
                                    }
                                    style={{
                                      wordWrap: 'break-word',
                                      width:
                                        item && item.is_default
                                          ? item.id === selectedContactGroup
                                            ? '55%'
                                            : '70%'
                                          : '80%',
                                    }}
                                    onClick={
                                      currentlyEditingContactList.item &&
                                        currentlyEditingContactList.item.id ===
                                        item.id
                                        ? () => { }
                                        : () => onEmailContractRowClick(item)
                                    }
                                  >
                                    {item && item.name}
                                    {/* {currentlyEditingContactList.item &&
                                  currentlyEditingContactList.item.id ===
                                    item.id ? (
                                    <Input
                                      value={currentlyEditingContactList.value}
                                      onChange={(e) =>
                                        setCurrentlyEditingContactList({
                                          ...currentlyEditingContactList,
                                          value: e.target.value,
                                        })
                                      }
                                      largeInput={true}
                                      marginBottom="0px"
                                      outerWidth="100%"
                                      height="auto"
                                      fontSize="12px"
                                      padding="0px 6px"
                                      marginRight="4px"
                                      bgColor="#fff"
                                      borderRadius="2px"
                                    />
                                  ) : (
                                    item && item.name
                                  )} */}
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      width:
                                        item && item.is_default ? '45%' : '20%',
                                    }}
                                  >
                                    {/* item.type === 'custom' && */}
                                    {item && item.id === selectedContactGroup && (
                                      <img
                                        alt="rename contact"
                                        data-testid={
                                          item.name +
                                          '_' +
                                          item.contact_type +
                                          '_rename'
                                        }
                                        src="/assets/images/Icon-metro-pencil8.svg"
                                        onClick={() => {
                                          setCurrentlyEditingContactList({
                                            item: item,
                                            value: item.name,
                                          });
                                          setRenameModal(true);
                                        }}
                                      />
                                    )}
                                    {/* {item &&
                                    item.type === 'custom' &&
                                    item.id === selectedContactGroup &&
                                    (currentlyEditingContactList.item &&
                                    currentlyEditingContactList.item.id ===
                                      item.id ? (
                                      <span onClick={updateEmailListHandler}>
                                        &#10003;
                                      </span>
                                    ) : (
                                      <img
                                        src="/assets/images/Icon-metro-pencil8.svg"
                                        onClick={() => {
                                          setCurrentlyEditingContactList({
                                            item: item,
                                            value: item.name,
                                          });
                                          setRenameModal(true);
                                        }}
                                      />
                                    ))} */}
                                    {item && item.is_default && (
                                      <>
                                        <span
                                          style={{
                                            fontSize: '8px',
                                            marginLeft: '7px',
                                            marginTop: '3px',
                                            marginRight: '3px',
                                          }}
                                        >
                                          Default
                                        </span>
                                        <ResponsiveImg
                                          src="/assets/images/tickicon-client.svg"
                                          style={{
                                            height: '15px',
                                            width: 'auto',
                                            marginTop: '3px',
                                            marginRight: '0',
                                          }}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </EmailListTableBodyTd>
                            </EmailListTableBodyTr>
                          )}
                      </>
                    );
                  })}
              </TableBody>
            </Table>

            <Flex justifyContent="flex-start" marginTop="20px" fontSize="12px">
              <LoaderWrapper
                isLoading={isLoading}
              // styles={{ marginTop: '18%', marginBottom: '18%' }}
              >
                <DownloadSampleDiv
                  href="#"
                  onClick={onDownloadUnsubscribes}
                  padding="5px 10px"
                >
                  <i className="icon dripicons-download"></i> Download
                  Unsubscribes
                </DownloadSampleDiv>
              </LoaderWrapper>
            </Flex>
          </Box>
          <Box width="70%">
            <Table height="auto">
              <TableWidget
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span style={{ marginLeft: '20px' }}> Email Addresses</span>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '50%',
                    backgroundColor: 'white',
                    border: '0px',
                    borderRadius: '2px',
                    padding: '0px 5px',
                    marginRight: '-28px',
                  }}
                >
                  <EmailSearch
                    placeholder="Search for a specific address"
                    spellCheck={false}
                    onChange={(e) => setSearchString(e.target.value)}
                    onKeyPress={(e) =>
                      e.key === 'Enter' ? searchHandler() : {}
                    }
                  />
                  <img
                    alt="search contact"
                    src="/assets/images/iconfinder_search_grey.svg"
                    style={{
                      cursor: searchString.length > 0 ? 'pointer' : 'default',
                    }}
                    onClick={
                      searchString.length > 0 ? () => searchHandler() : () => { }
                    }
                  />
                </div>
                <div
                  style={{
                    border:
                      selectedEmailListItem.length > 0 &&
                        selectedContactGroupListItem &&
                        selectedContactGroupListItem.type === 'custom'
                        ? '1px solid #fff'
                        : '0px',
                    borderRadius: '11px',
                    padding:
                      selectedEmailListItem.length > 0 &&
                        selectedContactGroupListItem &&
                        selectedContactGroupListItem.type === 'custom'
                        ? '1px 4px 0px'
                        : '3px 5px 0px',
                    marginRight: '-36px',
                    marginTop: '1px',
                    cursor:
                      selectedEmailListItem.length > 0 &&
                        selectedContactGroupListItem &&
                        selectedContactGroupListItem.type === 'custom'
                        ? 'pointer'
                        : 'default',
                    backgroundColor:
                      selectedEmailListItem.length > 0 &&
                        selectedContactGroupListItem &&
                        selectedContactGroupListItem.type === 'custom'
                        ? 'transparent'
                        : '#8a7fdc',
                  }}
                  onClick={
                    selectedEmailListItem.length > 0 &&
                      selectedContactGroupListItem &&
                      selectedContactGroupListItem.type === 'custom'
                      ? () => editHandler()
                      : () => { }
                  }
                >
                  <img
                    alt="edit contact"
                    src={
                      selectedEmailListItem.length > 0 &&
                        selectedContactGroupListItem &&
                        selectedContactGroupListItem.type === 'custom'
                        ? '/assets/images/Icon-metro-pencil8.svg'
                        : '/assets/images/Icon-metro-pencil8-disabled.svg'
                    }
                  />
                </div>
                <span
                  style={{
                    cursor: 'pointer',
                    pointerEvents:
                      selectedContactGroupListItem &&
                        selectedContactGroupListItem.type !== 'custom'
                        ? 'none'
                        : 'auto',
                  }}
                  onClick={() => handleEmailSort('asc')}
                >
                  <TableHeaderButtons
                    disabled={
                      selectedContactGroupListItem &&
                      selectedContactGroupListItem.type !== 'custom'
                    }
                    type="button"
                  >
                    {selectedContactGroupListItem &&
                      selectedContactGroupListItem.type === 'custom' ? (
                      <ResponsiveImg
                        src="/assets/images/Add-Active.svg"
                        style={{
                          height: '21px',
                          width: 'auto',
                          marginTop: '0px',
                          marginRight: '-4px',
                        }}
                        onClick={(event) => addActiveClick(event)}
                      />
                    ) : (
                      <ResponsiveImg
                        src="/assets/images/Add-Inactive.svg"
                        style={{
                          height: '21px',
                          width: 'auto',
                          marginTop: '0px',
                          marginRight: '-4px',
                          pointerEvents: 'none',
                        }}
                      />
                    )}
                  </TableHeaderButtons>

                  <TableHeaderButtons
                    disabled={
                      (emailTableData &&
                        !isEmpty(emailTableData) &&
                        emailTableData.filter((item) => item.checked).length ===
                        0) ||
                      (selectedContactGroupListItem &&
                        selectedContactGroupListItem.type !== 'custom')
                    }
                    type="button"
                  >
                    {(emailTableData &&
                      !isEmpty(emailTableData) &&
                      emailTableData.filter((item) => item.checked).length ===
                      0) ||
                      (selectedContactGroupListItem &&
                        selectedContactGroupListItem.type !== 'custom') ? (
                      <ResponsiveImg
                        src="/assets/images/Delete-Inactive.svg"
                        onClick={(event) =>
                          onDisabledDeleteButtonClick(event, 'emailAddress')
                        }
                        style={{
                          height: '21px',
                          width: 'auto',
                          marginTop: '6px',
                          marginRight: '5px',
                        }}
                      />
                    ) : (
                      <ResponsiveImg
                        src="/assets/images/Delete-Active.svg"
                        onClick={(event) =>
                          onDeleteClick(event, 'emailAddress')
                        }
                        style={{
                          height: '21px',
                          width: 'auto',
                          marginTop: '6px',
                          marginRight: '5px',
                        }}
                      />
                    )}
                  </TableHeaderButtons>
                </span>
              </TableWidget>
              <EmailListTableHeading style={{ flex: '0 0 auto' }}>
                <Tr>
                  <EmailListTh width="10%">
                    <label className="container">
                      <input
                        data-testid="list-all-select"
                        type="checkbox"
                        checked={isAllCheckSelected}
                        onChange={onAllCheckboxClick}
                      />
                      <span className="checkmark"></span>
                    </label>
                    {/*(!uploadType && contactGroupsList.find(c => c.id === selectedContactGroup) && (['hubspot', 'salesforce', 'wealthbox', 'redtail'].includes(contactGroupsList.find(c => c.id === selectedContactGroup).type)) && selectedEmailListItem.length === contactsCount) ? (
                        <DeleteEmailButtons onClick={onDeleteClick}>
                          <div class="icon dripicons-trash"></div>
                        </DeleteEmailButtons>
                      ) : (!uploadType && selectedEmailListItem.length > 0 && (['hubspot', 'salesforce', 'wealthbox', 'redtail'].includes(contactGroupsList.find(c => c.id === selectedContactGroup).type) === false)) ?
                        <DeleteEmailButtons onClick={onDeleteClick}>
                          <div class="icon dripicons-trash"></div>
                        </DeleteEmailButtons>
                        : null
                      */}
                  </EmailListTh>
                  <EmailListTh
                    align="left"
                    style={{ paddingLeft: '0px' }}
                    width="40%"
                  >
                    Email &nbsp;
                    <span>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleEmailSort('asc')}
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </span>
                      &nbsp;
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleEmailSort('desc')}
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </span>
                    </span>
                  </EmailListTh>
                  <EmailListTh align="left" width="20%">
                    First Name
                  </EmailListTh>
                  <EmailListTh align="left" width="20%">
                    Last Name
                  </EmailListTh>
                  <EmailListTh align="left" width="20%">
                    Tags
                  </EmailListTh>
                </Tr>
              </EmailListTableHeading>
              <LoaderWrapper
                isLoading={isContactsFetching}
                styles={{ marginTop: '18%', marginBottom: '18%' }}
              >
                <TableBody
                  maxHeight="206px"
                  minHeight="100px"
                  height="auto"
                  style={{ position: 'relative', bottom: '1px' }}
                >
                  {!isEmpty(emailTableData) ? (
                    emailTableData.map((item, index) => (
                      <EmailListTableBodyTr
                        alternate={false}
                        key={item.email + index}
                        style={{ flex: '0 0 auto' }}
                      >
                        <EmailListTableBodyTd width="10%">
                          <label className="container">
                            <input
                              id={`email${index}`}
                              type="checkbox"
                              checked={item.checked}
                              onChange={() =>
                                onIndividualCheckboxClick(index, item.email)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </EmailListTableBodyTd>
                        <EmailListTableBodyTd
                          width="30%"
                          align="left"
                          style={{
                            paddingLeft: '0px',
                          }}
                          className="contactListOverflow"
                        >
                          <Flex alignItems="baseline">
                            {item.email}
                            {(item.status === 'deleted' &&
                              item.subscription_status === 'unsubscribed') ||
                              (item.status === 'deleted' &&
                                item.subscription_status === 'subscribed') ? (
                              <span
                                style={{
                                  color: '#adadad',
                                  fontSize: '8px',
                                  paddingLeft: '10px',
                                }}
                              >
                                Deleted
                              </span>
                            ) : item.status === 'active' &&
                              item.subscription_status ===
                              'subscribed' ? null : (
                              <span
                                style={{
                                  color: '#adadad',
                                  fontSize: '8px',
                                  paddingLeft: '10px',
                                }}
                              >
                                Unsubscribed
                              </span>
                            )}
                          </Flex>
                        </EmailListTableBodyTd>

                        <EmailListTableBodyTd
                          align="left"
                          width="20%"
                          //className="contactListOverflow"
                        >
                          {item.name.first}
                        </EmailListTableBodyTd>
                        <EmailListTableBodyTd
                          align="left"
                          width="20%"
                         // className="contactListOverflow"
                        >
                          {item.name.last}
                        </EmailListTableBodyTd>
                        <EmailListTableBodyTd
                          align="left"
                          width="20%"
                          //className="contactListOverflow"
                        >
                          {item.tags.join(', ')}
                        </EmailListTableBodyTd>
                      </EmailListTableBodyTr>
                    ))
                  ) : (
                    <EmailListTableBodyTr
                      style={{ lineHeight: '12.8rem', paddingLeft: '13rem' }}
                    >
                      No emails found
                    </EmailListTableBodyTr>
                  )}
                </TableBody>
              </LoaderWrapper>
              <Flex width={1} mt="20px">
                <Box alignSelf="flex-start" style={{ marginLeft: '-41%' }}>
                  <Button
                    testId="create-list"
                    styles={{ border: 'none' }}
                    bgColor={
                      isEmpty(selectedEmailListItem) ? '#AFAFAF' : '#7dc4d0'
                    }
                    width="120px"
                    margin={'0px 0px 0px 219px'}
                    position="absolute"
                    disabled={isEmpty(selectedEmailListItem) ? true : false}
                    onClick={handleModalOpen}
                  >
                    Create List
                  </Button>
                </Box>
                <Box
                  width={window.innerWidth < 768 ? '55%' : '50%'}
                  style={{
                    paddingBottom: '5px',
                    //marginLeft: window.innerWidth < 768 ? '-9%' : '-15px',
                    fontSize: '14px',
                    color: '#49484a',
                    marginTop: '8px',
                    position: 'absolute',
                    marginLeft: '9px',
                  }}
                >
                  {startIndex}-{endIndex} of {contactsCount}
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: window.innerWidth < 768 ? '14%' : 'auto',
                    zIndex: 1 
                  }}
                  width={window.innerWidth < 768 ? '61%' : '43%'}
                >
                  <Box width={1}>
                    <Button
                      type="button"
                      width="96px"
                      height="34px"
                      styles={{ border: 'none' }}
                      bgColor={page < 1 ? '#AFAFAF' : '#7dc4d0'}
                      margin="0px"
                      onClick={() => onPaginationClick('before')}
                      disabled={page < 1 ? true : false}
                    >
                      Previous
                    </Button>
                  </Box>
                  <Box width={1} style={{ marginLeft: '10px' }}>
                    <Button
                      height="34px"
                      width="106px"
                      styles={{ border: 'none' }}
                      bgColor={!navigateAfter ? '#AFAFAF' : '#7dc4d0'}
                      type="button"
                      onClick={() => onPaginationClick('after')}
                      margin="0px"
                      disabled={!navigateAfter}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Flex>
              {!isEmpty(emailTableData) && (
                <Flex justifyContent="flex-end" marginTop="10px">
                  <DownloadSampleDiv
                    href="#"
                    onClick={downloadCSVHandler}
                    padding="5px 10px"
                  >
                    <i className="icon dripicons-download"></i> Download CSV
                  </DownloadSampleDiv>
                </Flex>
              )}
            </Table>
          </Box>
        </Flex>
      ) : (
        <LoaderWrapper isLoading={isContactsFetching}>
          <Box>
            <div styles={{ color: '#49484a' }}> or </div>
            <div styles={{ color: '#49484a' }}> Create/Upload Email List </div>
            <div>
              <Button
                styles={{ border: 'none' }}
                height="35px"
                onClick={() => setModalOpen(true)}
                bgColor={isEmpty(selectedEmailListItem) ? '#6B59ED' : '#6B59ED'}
                width="auto"
                margin={'10px 0px 0px 0px'}
                position="absolute"
              >
                + Create List
              </Button>
            </div>
          </Box>
        </LoaderWrapper>
      )}

      <Modal
        onRequestClose={() => setIsEmailDelete(false)}
        shouldCloseOnOverlayClick
        isOpen={isEmailDelete}
        size="smail"
      >
        <ModalCloseButton onClick={() => setIsEmailDelete(false)} />
        <ModalBody>
          <div style={{ padding: '0 30px 0 30px' }}>
            Are you sure you want to delete this List/s?
          </div>
          <Button
            margin="20px 5px 0px 0px"
            onClick={(event) => handleEmailDelete(event)}
          >
            Yes
          </Button>
          <Button onClick={() => setIsEmailDelete(false)}>No</Button>
        </ModalBody>
      </Modal>

      <Modal
        onRequestClose={() => setConfirmDeleteCGModal(false)}
        shouldCloseOnOverlayClick
        isOpen={confirmDeleteCGModal}
        size="smail"
      >
        <ModalCloseButton onClick={() => setConfirmDeleteCGModal(false)} />
        <ModalBody>
          <div>Do you want to delete the ContactList as well?</div>
          <Button
            margin="20px 5px 0px 0px"
            onClick={(event) => handleContactGroupDelete(event, 'yes')}
          >
            Yes
          </Button>
          <Button onClick={(event) => handleContactGroupDelete(event, 'no')}>
            No
          </Button>
        </ModalBody>
      </Modal>
      {renameModal && (
        <ListRenameModal
          isModalOpen={renameModal}
          setModalOpen={setRenameModal}
          listName={currentlyEditingContactList}
          updateEmailListHandler={updateEmailListHandler}
          renameModalLoader={renameModalLoader}
        />
      )}
    </>
  );
};

export default EmailListTable;
