import React, { useState } from 'react';
import {
  LoaderWrapper,
  Modal,
  ModalBody,
  ModalCloseButton,
} from '../../../../components';
import {
  get,
  isEmpty,
  startCase,
  camelCase,
  join,
  includes,
  lowerCase,
} from 'lodash';
import { updateStatusOfCampaign } from '../../../../services/campaign';
import { HoverButton } from '../../../ConfigureContainer/EmailList/EmailList.styles';

export const PauseCancelConfirmModal = ({
  handleClose,
  campaignId,
  campaignRequest,
  seriesId,
  type,
  filters,
  title,
  segment
}) => {
  const [loading, setLoading] = useState(false);
  
  const handleSubmit = () => {
    let payload = {};
    if (type === 'one_time') {
      payload.record_type = 'campaign';
    } else {
      payload.record_type = 'series';
    }
    payload.action = title === 'Pause' ? 'pause' : 'cancel';

    payload.record_type = 'campaign';

    setLoading(true);
    updateStatusOfCampaign({ id: campaignId || seriesId, payload })
      .then((res) => {
        if (res.result.success) {
          setLoading(false);
          if (isEmpty(filters)) campaignRequest();
          else campaignRequest({ ...filters });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <Modal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={true}
      size={'small'}
    >
      <ModalCloseButton onClick={handleClose} />
      <ModalBody>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#6351ed',
            fontFamily: 'Poppins',
          }}
        >
          {`Are you sure you want to ${lowerCase(title)} this campaign?`}
        </div>

        <LoaderWrapper isLoading={loading}>
          <HoverButton
            margin="9px 10px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            color="#6351ed"
            borderColor="#fff"
            onClick={() => handleSubmit(true)}
          >
            Yes
          </HoverButton>
          <HoverButton
            margin="9px 0px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            color="#6351ed"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            borderColor="#fff"
            onClick={() => handleClose()}
          >
            No
          </HoverButton>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  );
};
