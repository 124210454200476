/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import { get, find, isEmpty } from 'lodash';
import * as Yup from 'yup';
import {
  ProfileImage,
  EditButton,
} from '../../../components/AdvisorProfile/AdvisorProfile';
import {
  EditPassword,
  FileUpload,
} from '../../../components';
import { getMediaUrl, ROLE_TYPES } from '../../../utils';
import EditProfile from './EditProfile';
import ProfileEditor from '../../../components/ProfileEditor/ProfileEditor';
import { redTailLogin, updatePassword, updateProfile } from '../../../services/configure';
import { getUserDetails, getUserId } from '../../../redux/selector';
import { actions as profileActions } from '../../../redux/modules/profile.module';
import { actions as loginActions } from '../../../redux/modules/login.module';
import { actions as socialNetworkActions } from '../../../redux/modules/socialNetwork.module';
import { getSocialAccountURLs } from '../../../services/dashboard';
import { getRoleType } from '../../../redux/selector';

let lengthError = 'New password must be between 8 to 15 characters';
let emptyError = (type = '') => `Please enter ${type} password`;

const passwordErrorSchema = Yup.object().shape({
  fAUserPasswordCh: Yup.string().required(emptyError()),
  fAUserPasswordChN: Yup.string()
    .min(8, lengthError)
    .max(15, lengthError)
    .required(emptyError('New')),
  fAUserPasswordChNC: Yup.string()
    .required(emptyError('confirm'))
    .when('fAUserPasswordChN', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('fAUserPasswordChN')],
        'Both password need to be the same'
      ),
    }),
});

// const profileErrorSchema = Yup.object().shape({
//   first_name: Yup.string().required('First Name is required.'),
//   last_name: Yup.string().required('Last Name is required'),
//   mobile: Yup.string()
//     .required('Mobile Number is required')
//     .min(11, 'Invalid Mobile Number')
//     .max(16, 'Invalid Mobile Number'),
//   city: Yup.string().required('City is required'),
// });

const profileErrorSchema = (isPremiumAdvisor) => {
  return Yup.object().shape({
    first_name: Yup.string().required('First Name is required.'),
    last_name: Yup.string().required('Last Name is required'),
    // mobile: Yup.string()
    //   .required('Mobile Number is required')
    //   .min(11, 'Invalid Mobile Number')
    //   .max(16, 'Invalid Mobile Number'),
    city: Yup.string().required('City is required'),
    // about_me: Yup.string(),
  });
};

const Profile = ({
  userData,
  userId,
  profileUpdateRequest,
  loginRequest,
  socialNetworkData,
  socialNetworkDataFetching,
  socialNetworkDelete,
  setSocialAccountURL,
  socialAccountURL,
  roleType,
  socialNetworkRequest,
  userDetails,
  isPremiumAdvisor,
  user,
}) => {
  const [isEditProfile, setIsEditProfile] = useState(true);
  const [isChecked, setIsChecked] = useState(userData.auto_share);
  const [allowSharingOnMyBehalf, setAllowSharingOnMyBehalf] = useState(
    get(userData.sharing, 'allow_sharing_on_my_behalf,', '')
  );
  const [allowAutoschedulingOnMyBehalf, setAllowAutoschedulingOnMyBehalf] =
    useState(get(userData.sharing, 'allow_autoscheduling_on_my_behalf', ''));
  const [modal, setModal] = useState({
    message: '',
    open: false,
  });
  const [uploadedImage, setUploadedImage] = useState('');
  const [emailModal, setEmailModal] = useState(false);
  const [redTailModal, setRedTailModal] = useState(false);
  const [redTailError, setRedTailError] = useState(false);
  const [pswrdErrorMsg, setPswrdErrorMsg] = useState('');
  const [loader, toggleLoader] = useState(false);
  const [leadCaptureSetting, setLeadCaptureSetting] = useState(true);

  useEffect(() => {
    getSocialAccountURLs()
      .then((res) => {
        setSocialAccountURL(res.result.data);
      })
      .catch((err) => {});
  }, [setSocialAccountURL]);

  useEffect(() => {
    if(get(userData, 'lead_capture', '') === 'smart') {
      setLeadCaptureSetting(true);
    } else if(get(userData, 'lead_capture', '') === 'classic') {
      setLeadCaptureSetting(false);
    }
  }, [])

  const update = async (data, isChangePswrd) => {
    toggleLoader(true);
    const payload = {
      userId,
      data,
    };

    return new Promise(async (resolve, reject) => {
      try {
        const { result } = await updateProfile(payload);
        if (result.success) {
          await loginRequest();
          toggleLoader(false);
          resolve({ success: true });
        } else {
          toggleLoader(false);
          reject({ success: true });
        }
      } catch (err) {
        if (
          isChangePswrd &&
          get(err, 'response.data.result.error.code', '') === 1000
        ) {
          setPswrdErrorMsg('Invalid Current Password');
        }
        console.log(err);
        toggleLoader(false);
      }
    });
  };

  const updatePasswordHandler = data => {
    const payload = {
      uid: userId,
      ...data
    };
    return updatePassword(payload)
  }

  const handleChange = async (checked) => {
    setIsChecked(checked);
    await update({ auto_share: checked });

    if (!isChecked) {
      setModal({ message: 'Auto share enabled', open: true });
    }
  };

  const handleSharingOnMyBehalf = async (checked) => {
    setAllowSharingOnMyBehalf(checked);
    await update({ allow_sharing_on_my_behalf: checked });
  };

  const handleAutoSchedulingOnMyBehalf = async (checked) => {
    setAllowAutoschedulingOnMyBehalf(checked);
    await update({ allow_autoscheduling_on_my_behalf: checked });
  };
  const onEditPasswordClick = () => {
    setIsEditProfile(false);
  };

  const handleSavePasswordClick = (values) => {
    setPswrdErrorMsg('');
    toggleLoader(true);
    updatePasswordHandler(
      {
        force_password_change: false,
        current_password: encodeURIComponent(values.fAUserPasswordCh).replace(
          /'/g,
          '%27'
        ),
        password: encodeURIComponent(values.fAUserPasswordChN).replace(
          /'/g,
          '%27'
        ),
      },
      // true
    )
      .then((res) => {
        if (res && res.result.success) {
          toggleLoader(false);
          setModal({ message: 'Password changed succesfully', open: true });
          loginRequest({noRefresh: false})
        }
      })
      .catch((err) => {
        toggleLoader(false);
        setModal({ message: 'Failed to change password', open: true });
        if(get(err, 'response.data.result.error.code', '') === 1000)
          setPswrdErrorMsg('Invalid Current Password');
        else
          setPswrdErrorMsg('Failed to change password. Please try again later.');
      });
  };

  const handleAvatarChange = (file) => {
    setUploadedImage(file[0]);
  };

  const handleChangeProfile = async (values) => {
    console.log('VALUES = ', values);
    if (isEmpty(values.about_me)) values.about_me = 'empty';
    // if (isEmpty(values.digital_banner)) values.digital_banner = 'empty';
    if (isEmpty(values.firm_name)) values.firm_name = 'empty';
    delete values.email;
    await update({
      ...values,
      lead_capture: leadCaptureSetting ? 'smart' : 'classic'
    });
  };

  const { first, last } = get(userData, 'name', {});
  const city = userData ? userData.city : '';
  const mobile = userData ? userData.mobile : '';
  const email = userData ? userData.email : '';
  const contact_email = get(userData, 'extra_settings.contact_email', ''); 
  const timezone = userData ? userData.timezone : '';

  const firmName = userData && userData.firm_name ? userData.firm_name : '';
  const about = userData && userData.about_me ? userData.about_me : '';
  const digital_banner = userData && userData.digital_banner ? userData.digital_banner : ''; 

  const data = {
    first_name:
      get(userDetails, 'is_impersonator', '') === 'true'
        ? get(userDetails, 'impersonate.first_name', '')
        : first,
    last_name:
      get(userDetails, 'is_impersonator', '') === 'true'
        ? get(userDetails, 'impersonate.last_name', '')
        : last,
    city: city,
    mobile: mobile,
    email:
      get(userDetails, 'is_impersonator', '') === 'true'
        ? get(userDetails, 'impersonate.email', '')
        : email,
    contact_email : contact_email,
    timezone: timezone,
    about_me: about,
    digital_banner: digital_banner,
    firm_name: firmName 
  };
  const logo = getMediaUrl(userData.media_urls);

  const handleImage = async (imageFile) => {
    await update({
      media1: imageFile,
    });
    setUploadedImage('');
  };

  const handleSocialIconClick = (
    id,
    isDiconnected,
    platform,
    emailPlatform
  ) => {
    let link = '',
      type = '';
    if (socialAccountURL && isDiconnected === false) {
      console.log('check');
      switch (id) {
        case 'ln':
          link = socialAccountURL.ln.oauth_url;
          break;
        case 'tw':
          link = socialAccountURL.tw.oauth_url;
          break;
        case 'go':
          link = socialAccountURL.em.oauth_url.google;
          break;
        case 'ol':
          link = socialAccountURL.em.oauth_url.outlook;
          break;
        case 'fb':
          link = socialAccountURL.fb.oauth_url;
          break;
        case 'wb':
          link = socialAccountURL.wb.oauth_url;
          break;
        case 'sf':
          link = socialAccountURL.sf.oauth_url;
          break;
        case 'em':
          link = '';
          break;
        case 'hs':
          link = socialAccountURL.hs.oauth_url;
          break;
        case 'rt':
          link = '';
          type = 'redTail';
        default:
          link = '';
      }
      if (link) window.location.href = link;
      else if (type && type === 'redTail') {
        setRedTailModal(true);
      } else {
        setEmailModal(true);
      }
    } else {
      get(socialNetworkData, platform, []).forEach((item) => {
        if (
          (emailPlatform && emailPlatform === item.snetworkProvider) ||
          !emailPlatform
        ) {
          socialNetworkDelete({
            params: {
              id: `${platform}_${item.accountId}`,
            },
          });
        }
      });
    }
  };

  const handleEmailModalSubmit = () => {};

  const handleRedTailLogin = ({ username, password }) => {
    redTailLogin(username, password)
      .then((res) => {
        if (res.result.success) {
          socialNetworkRequest();
          setRedTailModal(false);
        }
      })
      .catch((err) => {
        setRedTailError(true);
      });
  };

  const getBottomText = (platform, emailPlaftform) => {
    let platformDetails = get(socialNetworkData, platform, []);

    if (emailPlaftform) {
      platformDetails = find(platformDetails, {
        snetworkProvider: emailPlaftform,
      });
    }

    const text = isEmpty(platformDetails) ? 'Connect' : 'Disconnect';
    return {
      bottomText: text,
      isDiconnected: text === 'Disconnect',
    };
  };

  const onBottomTextClick = (
    e,
    id,
    platform,
    emailPlaftform,
    isDiconnected
  ) => {
    e.stopPropagation();
    if (isDiconnected) {
      get(socialNetworkData, platform, []).forEach((item) => {
        if (
          (emailPlaftform && emailPlaftform === item.snetworkProvider) ||
          !emailPlaftform
        ) {
          socialNetworkDelete({
            params: {
              id: `${platform}_${item.accountId}`,
            },
          });
        }
      });
    } else {
      handleSocialIconClick(id, isDiconnected, platform, emailPlaftform);
    }
  };

  return (
    <div>
      <Flex
        flexDirection={`${window.innerWidth < 768 ? 'column' : 'row'}`}
        justifyContent={`${
          window.innerWidth < 768 ? 'center' : 'space-around'
        }`}
        width="70%"
        mx="auto"
        pt="40px"
        height={isPremiumAdvisor ? '675px' : null}
      >
        <Box width={`${window.innerWidth < 768 ? 1 : '50%'}`} mt={20}>
          {uploadedImage === '' ? (
            <>
              {logo.length > 0 ? (
                <ProfileImage src={logo} />
              ) : (
                <ProfileImage src={`/assets/images/profilePicDef.jpg`} />
              )}
              {!(
                get(user, 'details.user.is_impersonator', 'false') === 'true' &&
                (roleType === ROLE_TYPES.ADMIN_ENTERPRISE ||
                  roleType === ROLE_TYPES.ADMIN_COMPLIANCE)
              ) && (
                <FileUpload
                  asButton
                  name="avatar"
                  icon="icon dripicons-camera"
                  id="avatar"
                  onClick={() => {}}
                  label="Edit Picture"
                  onChange={(file) => handleAvatarChange(file)}

                  // accept =".png"
                />
              )}
            </>
          ) : (
            <ProfileEditor
              image={uploadedImage}
              handleImage={(image) => handleImage(image)}
              width={142}
              height={142}
              maxScale={5}
              background={[255, 255, 255, 1]}
            />
          )}

          <EditButton
            className="icon dripicons-pencil"
            onClick={onEditPasswordClick}
            type="button"
          >
            &nbsp;Edit Password
          </EditButton>
        </Box>
        <Box width={`${window.innerWidth < 768 ? 1 : '50%'}`} mt={20}>
          {isEditProfile && (
            <EditProfile
              errorSchema={profileErrorSchema(isPremiumAdvisor)}
              data={data}
              userData={userData}
              onEditProfile={(values) => handleChangeProfile(values)}
              isPremiumAdvisor={isPremiumAdvisor}
              logo={logo}
              socialLinks={{
                linkedIn:
                  socialNetworkData?.ln && !isEmpty(socialNetworkData?.ln)
                    ? true
                    : false,
                facebook:
                  socialNetworkData?.fb && !isEmpty(socialNetworkData?.fb)
                    ? true
                    : false,
                twitter:
                  socialNetworkData?.tw && !isEmpty(socialNetworkData?.tw)
                    ? true
                    : false,
              }}
              roleType={roleType}
              user={user}
              leadCaptureSetting={leadCaptureSetting}
              setLeadCaptureSetting={setLeadCaptureSetting}
              loader={loader}
            />
          )}
          {!isEditProfile && (
            <EditPassword
              errorSchema={passwordErrorSchema}
              onBackClick={() => {
                setIsEditProfile(true);
                setPswrdErrorMsg('');
              }}
              onSaveClick={(values) => handleSavePasswordClick(values)}
              pswrdErrorMsg={pswrdErrorMsg}
              saveLoader={loader}
            />
          )}
        </Box>
      </Flex>
      {get(userDetails, 'is_impersonator', '') === 'true' && (
        <Flex justifyContent="center" pt="125px">
          <Box>
            <span style={{ color: '#7b7b7b', fontSize: '12px' }}>
              Main Account - {get(userDetails, 'email', '')}
            </span>
          </Box>
        </Flex>
      )}
      {/* <Hr /> */}
      {/* <Flex width={`${window.innerWidth < 768 ? "81%" : '95%'}`} mx="auto">
        <Box width={1} px={2} mt={2} mb={2}>
          <div>Connect your digital platforms</div>
          <LoaderWrapper isLoading={socialNetworkDataFetching}>
            <div>
              {socialIcons.map((icon, index) => {
                const buttonDetails = getBottomText(
                  icon.platform,
                  icon.emailPlatform
                );
                const bottomText = buttonDetails.bottomText;
                const isDiconnected = buttonDetails.isDiconnected;
                return (
                  <InlineBlock
                    onClick={() => handleSocialIconClick(icon.id,isDiconnected,icon.platform,icon.emailPlatform)}
                    key={icon.id}
                    id={icon.id}
                    isDiconnected={isDiconnected} 
                    title={icon.name}
                  >
                    {icon.icon ? (
                      <Icon
                        icon={icon.icon}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: isDiconnected ? '#D4D4D4' : null,
                        }}
                      />
                    ) : (
                      <IconImage
                        pointer
                        src={icon.src}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: isDiconnected ? '#D4D4D4' : null,
                        }}
                      />
                    )}
                    <div>
                      <ButtonNoBorderBackground
                        onClick={(e) =>
                          onBottomTextClick(
                            e,
                            icon.id,
                            icon.platform,
                            icon.emailPlatform,
                            isDiconnected
                          )
                        }
                      >
                        {bottomText}
                      </ButtonNoBorderBackground>
                    </div>
                  </InlineBlock>
                );
              })}
            </div>
          </LoaderWrapper>
          <Box mt={2} mb={20}>
            &nbsp; <span style={{ fontSize: '14px'}}>MIO Recommendations?</span> &nbsp;{' '}
            <ToggleButton
              className="react-switch"
              onChange={handleAutoSchedulingOnMyBehalf}
              checked={allowAutoschedulingOnMyBehalf}
              onColor="#6351ed"
              offColor="#cccccc"
              offHandleColor="#fff"
              onHandleColor="#fff"
              handleDiameter={12}
              uncheckedIcon=""
              checkedIcon=""
              boxShadow=""
              activeBoxShadow=""
              type="configure"
            />
            &nbsp;&nbsp;
            {roleType && roleType === 'ADVISOR_ENTERPRISE' && (
              <>
                <span style={{ fontSize: '14px' }}>Allow Company to Post?</span> &nbsp;{' '}
                <ToggleButton
                  className="react-switch"
                  onChange={handleSharingOnMyBehalf}
                  checked={allowSharingOnMyBehalf}
                  onColor="#6351ed"
                  offColor="#cccccc"
                  offHandleColor="#fff"
                  onHandleColor="#fff"
                  handleDiameter={12}
                  uncheckedIcon=""
                  checkedIcon=""
                  boxShadow=""
                  activeBoxShadow=""
                  type="configure"
                />
              </>
            )}
            <CustomModal
              isOpen={modal.open}
              style={{
                overlay: {
                  backgroundColor: '#000000d1',
                },
                fontSize: 12,
              }}
            >
              <ModalHeader>
                <PremiumModalContent>
                  {modal.message}
                  <ModalCloseButton
                    onClick={() => setModal({ message: '', open: false })}
                  />
                </PremiumModalContent>
              </ModalHeader>
            </CustomModal>
            <EmailSettingsModal
              open={emailModal}
              handleClose={() => setEmailModal(false)}
              handleModalSubmit={handleEmailModalSubmit}
            />
            <RedTailModal 
              open={redTailModal}
              handleClose={() => setRedTailModal(false)}
              handleModalSubmit={handleRedTailLogin}
              modalError={redTailError}
            />
          </Box>
        </Box>
      </Flex> */}
      <Flex marginTop='25px' flexDirection='column'>
      <Flex color='rgb(123, 123, 123)' marginX='auto' fontSize='14px'>Account Email (Username): {data?.email}</Flex>
      {roleType &&
        roleType === 'ADVISOR_ENTERPRISE' &&
        userDetails &&
        userDetails.company_name && (
          <Flex justifyContent="center" pt="20px 0 30px 0">
            <Box>
              <span style={{ color: '#7b7b7b', fontSize: '12px' }}>
                Invited By: {userDetails.company_name}
              </span>
            </Box>
          </Flex>
        )}
      </Flex>
    </div>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  // const isEnterpriseAdmin = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;
  // const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  // let isEnterprise = isEnterpriseAdmin || isEnterpriseAdvisor;

  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;

  return {
    // isEnterprise,
    isPremiumAdvisor,
    userId: getUserId(state),
    userDetails: getUserDetails(state),
    user: state.user.data,
    socialNetworkData: state.socialNetwork.data,
    socialNetworkDataFetching: state.socialNetwork.isFetching,
    roleType: roleType,
  };
};

const mapDispatchToProps = {
  profileUpdateRequest: profileActions.api.profile.request,
  loginRequest: loginActions.api.login.request,
  socialNetworkDelete: socialNetworkActions.api.socialNetwork.delete,
  socialNetworkRequest: socialNetworkActions.api.socialNetwork.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);