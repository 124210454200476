import React from 'react';
import { Button, LoaderWrapper } from '../../../../../components';
import './ConnectedComponent.css';

const connectedComponent = ({ 
    showDisconnected, 
    updateConnection, 
    showDelete, 
    toggleDelete, 
    disconnectLoader, 
    deleteLoader,
    close,
    setErrorMessage 
}) => {
    return (
        <>
            {
                showDisconnected && !showDelete ? (
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <Button margin='0px 0px 5px 0px' onClick={close}>Back</Button>
                        <br/>
                        <a href='#' className='connected-delete-btn' onClick={() => toggleDelete(true)} >Delete and add a new connection</a>
                    </div>
                ) : showDelete ? (
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <LoaderWrapper isLoading={deleteLoader}>
                            <Button onClick={() => updateConnection('delete')}>Delete the connection</Button>
                        </LoaderWrapper>
                        <br/>
                        <a href='#' className='connected-delete-btn' onClick={() => {
                            setErrorMessage('');
                            toggleDelete(false);
                        }}>Back</a>
                    </div>
                ) : (
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <LoaderWrapper isLoading={disconnectLoader}>
                            <Button margin='15px 0 0 0' onClick={() => updateConnection('disconnect')}>Disconnect the connection</Button>
                        </LoaderWrapper>
                        <p style={{ color: '#555', margin: '6px auto' }}>or</p>
                        <Button margin='0 0 10px 0' onClick={() => toggleDelete(true)}>Delete the connection</Button>
                    </div>
                )
            }
        </>
    );
}

export default connectedComponent;