import React, { useState } from 'react';
import { Flex } from '@rebass/grid';
import { Button, LoaderWrapper, Modal, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../../components';
import { themesData } from '../../../utils';

const DisconnectModal = ({ isOpen, closeModalHandler, handleDisconnect }) => {
    return (
        <ModalV2
            shouldCloseOnOverlayClick
            isOpen={isOpen}
            width='50%'
            height='auto'
            onRequestClose={closeModalHandler}
            style={{ maxHeight: '90%' }}
            bgColor='#fff'
        >
            <ModalHeader fontColor={themesData.purpleishBlue}>
                Do you wish to disconnect your salesforce connection?
                <ModalCloseButton onClick={closeModalHandler} />
            </ModalHeader>
            <ModalBody>
                <Flex style={{fontWeight: '600'}} color={themesData.black}>
                    To maintain your connection click Cancel. To remove your current Salesforce connection just click the Disconnect button.
                </Flex>
                <Flex display="flex" justifyContent="center">
                    <Button color="#816EF8"
                        bgColor="#FFFFFF"
                        borderRadius="4px"
                        borderColor="rgba(16, 24, 40, 0.12)" 
                        margin="20px 10px" 
                        onClick={closeModalHandler} 
                        width="110px">
                            Cancel
                        </Button>
                    <Button bgColor="#816EF8"
                        borderRadius="4px" 
                        margin="20px 0px" 
                        onClick={handleDisconnect}>
                            Disconnect
                    </Button>
                </Flex>
            </ModalBody>
        </ModalV2>
    );
};

export default DisconnectModal;