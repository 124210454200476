import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React from 'react';
import {
  Button,
  LoaderWrapper,
} from '../../../components';
import { DripFooterSection } from '../CreateCampaign/CreateCampaign.styles';
import { Stepper } from '../Stepper/Stepper';

const CampaignNavigation = ({
  enableNext,
  nextHandler,
  backHandler,
  saveAndCloseHandler,
  loading,
  isLastStep = false,
  hideBackButton = false,
  enableBack,
  stepList,
  selectedIndex,
  disabledraft
}) => {
  return (
    <Flex justifyContent='space-between' padding='10px 0' height='85px' alignItems='center'
    style={{position: 'absolute',
    top: '88px',
    border: '1px solid rgba(16, 24, 40, 0.12)',
    left: '10px',
    width: 'calc(99% - 10px)',
    }}
    >
      <LoaderWrapper isLoading={loading}>
        <Flex>
        {!hideBackButton && (
            <Button
              id="btn_campaign_back"
              margin="0 10px"
              color="#816EF8"
              bgColor="#FFFFFF"
              borderRadius="4px"
              borderColor="rgba(16, 24, 40, 0.12)"
              height='35px'
              onClick={backHandler}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp; Back &nbsp; &nbsp;
            </Button>
          )}
        </Flex>
        <Stepper
              stepperList={stepList}
              selectedIndex={selectedIndex}
              style={{'marginLeft' : '150px', marginTop: '-2%'}}
            />
        <Flex>
          {!disabledraft && (
            <Button
              borderColor="rgba(16, 24, 40, 0.12);"
              borderRadius="4px"
              bgColor="#FFFFFF"
              color="#101828"
              margin="0px 10px"
              onClick={saveAndCloseHandler}
              disabled={!enableNext}
              height='35px'
              id="btn_campaign_draft"
            >
              Save as Draft
            </Button>
          )}
         
          {!isLastStep && (
            <Button
              bgColor="#816EF8"
              borderRadius="4px"
              margin="0px 10px"
              onClick={nextHandler}
              disabled={!enableNext}
              height='35px'
              id="btn_campaign_next"
            >
              &nbsp; Next &nbsp; &nbsp;
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          )}
        </Flex>
      </LoaderWrapper>
    </Flex>
  );
};

export default CampaignNavigation;
