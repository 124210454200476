import axios from 'axios';
import { get, isEmpty } from 'lodash';
import qs from 'query-string';
import { apiDetails } from './helpers';
import { oktaHelper, redirectToLogin, setToken } from '../utils';

//pass asAdvisor as true during admin login to get acccess to advisor related APIs
function createAPIInstance(options) {
  // Set config defaults when creating the instance

  const isReactLogin = get(options, 'isReactLogin', false);
  // console.log(oktaHelper.getAccessToken())
  const oktaToken = oktaHelper.getAccessToken();
  // console.log(oktaToken); 
  let authToken = window.sessionStorage.getItem('token') || oktaToken || get(options, 'token', '');
  // console.log(authToken);
  if (!authToken && !isReactLogin) {
    // setting token from cookie
    setToken();
  }

  const pdfAuth = get(qs.parse(qs.extract(window.location.href)), 'pdfauth');

  if (pdfAuth) {
    authToken = pdfAuth;
  }

  if (!isReactLogin && !authToken) {
    console.log('=##= no valid token');
    window.location.reload();
    return;
  }

  const advisorAuthToken = window.sessionStorage.getItem('advisorToken');
  const asAdvisor = get(options, 'asAdvisor', false);
  const extraHeader = get(options, 'extraHeader', {});

  const tokenHeader = isEmpty(authToken)
    ? {}
    : asAdvisor
    ? !!advisorAuthToken
      ? {
          Authorization: `Bearer ${advisorAuthToken}`,
          ...extraHeader,
        }
      : {
          Authorization: `Bearer ${authToken}`,
          ...extraHeader,
        }
    : {
        Authorization: `Bearer ${authToken}`,
        ...extraHeader,
      };
  // console.log('OPTIONS - - -- ', options);
  const api = axios.create({
    baseURL: apiDetails.baseUrl.trim(),
    headers: {
      'Content-Type': options && options.contentType ? options.contentType : 'multipart/form-data',
      ...tokenHeader,
    },
  });

  api.interceptors.response.use(
    (response) => {
      const errorResponse = get(response, 'data.result.error', {});
      if (!isEmpty(errorResponse)) {
        throw errorResponse;
      }
      return response.data;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        // redirectToLogin();
        // return null;
      }
      return Promise.reject(error);
    }
  );
  return api;
}

export default createAPIInstance;
