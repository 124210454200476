/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Flex } from '@rebass/grid';

import {
  TimingCard,
  InputLabel,
  RadioInputLabel,
  RadioInput,
} from '../SocialPreview/SocialPreview.styles';

import '../SocialPreview/style.css';
import {
  Button,
  DripIconButton,
  LoaderWrapper,
  MioDatePicker,
} from '../../../components';
import EmailDTListPopup from './EmailDTListPopup';
import { FooterBtnsWrapper } from '../DripCampaignContainerV2.styles';
import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import { get, isEmpty } from 'lodash';
import { postCascadeCampaign, publishCampaignV2 } from '../../../services/campaign';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  DateTimeText,
  DateTimeWrapper,
  ApprovalCard,
} from './PreviewAndSchedule.styles';
import LeadCaptureOption from '../../../components/ShareContentContainerV2/Elements/LeadCaptureOption';
import CampaignNavigation from '../CampaignNavigation/CampaignNavigation';

const PreviewAndSchedule = ({
  handleNext,
  handleBack,
  loader,
  campaignSetState,
  setObjectivesTabData,
  campaignScheduleTimings,
  endCreateFlow,
  getAccountId,
  campaignId,
  redirectionCampaign,
  campaignScheduleTypes,
  curFlow,
  isadvMode,
  isAdvisorApprovalMode,
  isEdit,
  campaignStatus,
  userData,
  selectedArticles,
  campaignLeadReferralData,
  setTemplateBase,
  complianceApplicable,
  complianceApproved,
  emAdvApproval,
  userRole,
  fromDashboard,
  createMode,
  reviewCampaign,
  redirectCascadeAdvisor,
  seriesId,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  isAdmin,
  isEnterpriseAdvisor,
  loginRequest,
  stepList,
  selectedIndex,
  isGlobalCompliance,
  companyId
}) => {
  // console.log('SELECTED ARTICLES - -- - - ', selectedArticles);
  // console.log('CAMPAIGN SCHEDULE TIMINGS - -- - - ', campaignScheduleTimings);
  console.log('SET OBJECTIVES DATA - - - - - - -', setObjectivesTabData);
  console.log('isGlobalCompliance', isGlobalCompliance);
  console.log('ADV APPROVAL - - -', isAdvisorApprovalMode, 'IS ADV MODE - - - -', isadvMode);

  const history = useHistory();
  const [timingTabs, setTimingTabs] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [dateTimeList, setDateTimeList] = useState({});
  const [isApprovalRequired, setIsApprovalRequired] = useState(
    isAdvisorApprovalMode
  );
  const [loading, setLoading] = useState(false);
  const [enableLeadGen, toggleLeadGen] = useState(false);
  const [enableReferralCapture, toggleReferralCapture] = useState(false);
  const [pdfPresent, setPdfPresent] = useState(false);
  const [skipCompliance, setSkipCompliance] = useState(true);
  const [showCompliance, toggleShowCompliance] = useState(false);

  const timeMapper = {
    0: 'immediate',
    1: 'specific_time',
    2: 'different_time',
  };

  useEffect(() => {
    if (
      campaignScheduleTimings &&
      !isEmpty(campaignScheduleTimings) &&
      campaignScheduleTimings?.em?.length > 0
    ) {
      let timingObj = {};
      if (campaignScheduleTypes.em === 'different_time') {
        let currentEmList = get(setObjectivesTabData, 'emList', []);
        let campaignScheduleList = campaignScheduleTimings.em.filter((ele) =>
          currentEmList.some((em) => em.id === ele.destination_id)
        );
        campaignScheduleList.forEach((ele) => {
          timingObj[ele.destination_id] = {
            isOpen: false,
            dateTime: ele.time,
          };
        });
      } else
        timingObj = {
          all: {
            isOpen: false,
            dateTime: campaignScheduleTimings.em[0].time,
          },
        };
      setDateTimeList(timingObj);
      setTimingTabs(campaignScheduleTypes.em === 'different_time' ? 2 : 1);
    } else if (campaignScheduleTimings &&
      !isEmpty(campaignScheduleTimings) &&
      campaignScheduleTimings?.segment?.length > 0) {
        let timingObj = {};
      if (campaignScheduleTypes.segment === 'different_time') {
        let currentEmList = get(setObjectivesTabData, 'segmentList', []);
        let campaignScheduleList = campaignScheduleTimings.segment.filter((ele) =>
          currentEmList.some((segment) => segment.id === ele.destination_id)
        );
        campaignScheduleList.forEach((ele) => {
          timingObj[ele.destination_id] = {
            isOpen: false,
            dateTime: ele.time,
          };
        });
      } else
        timingObj = {
          all: {
            isOpen: false,
            dateTime: campaignScheduleTimings.segment[0].time,
          },
        };
        setDateTimeList(timingObj);
        setTimingTabs(campaignScheduleTypes.segment === 'different_time' ? 2 : 1);
      }
      
    else 
      setTimingTabs(0);
  }, []);

  useEffect(() => {
    // getUserDetails()
    // .then(res => {
      // console.log('RESPONSE USER DETAILS API -- - - ', res);
      const complianceOption = isFirmLevelAdmin && !isEmpty(firmSettings) ? 
        get(firmSettings, `firm_compliance.${[companyId]}.compliance_is`, 'always_off') 
          : get(userData, 'compliance', '');
      console.log('FIRM SETTINGS - - - -- ', firmSettings);
      console.log('COMPLIANCE OPTION - -- -- ', complianceOption);
      if(campaignStatus === 'compliance_rejected' || complianceOption === 'always_on' || complianceOption === 'on')
        setSkipCompliance(false);
      else if(complianceOption === 'always_off' || complianceOption === 'off')
        setSkipCompliance(true);
    // })
    // .catch(err => console.log('ERROR FETCHING USER DETAILS - - -- ', err));
  }, []);

  useEffect(() => {
    console.log(
      'CAMPAIGN LEAD REFERRAL DATA - - - -',
      campaignLeadReferralData
    );
    const pdfPresent =
      selectedArticles &&
      !selectedArticles.every(
        (article) => !article.link.includes('article_link')
      );
    const leadGen = get(campaignLeadReferralData, 'em.leadGen', false);
    const referral = get(campaignLeadReferralData, 'em.referral', false);
    toggleLeadGen(leadGen);
    toggleReferralCapture(referral);

    setPdfPresent(pdfPresent);
  }, []);

  useEffect(() => {
    let show = true;
    if (campaignStatus === 'compliance_rejected')
      show = false;
    if(show) {
      if((isFirmLevelAdmin) && !isEmpty(firmSettings)) {
        show = get(firmSettings, `firm_compliance.${[companyId]}.compliance_is`, '') === 'optional';
      } 
       else {
        show = get(userData, 'compliance', '') === 'optional'
       }
        
    }
    toggleShowCompliance(show);
  }, []);

  const handleDateTimeListChange = (id, name, value, isDateTimeInput) => {
    if (isDateTimeInput) {
      setDateTimeList({
        ...dateTimeList,
        [id]: { ...dateTimeList[id], [name]: value, isOpen: false },
      });
    } else {
      setDateTimeList({
        ...dateTimeList,
        [id]: { ...dateTimeList[id], [name]: value },
      });
    }
  };

  const handleClose = (val) => {
    setIsOpen(val);
  };

  const handleNextClick = () => {
    let payloadObj;
    if(!isEmpty(get(setObjectivesTabData, 'emList', []))) {
      payloadObj = {
        email: preparePayload('email'),
      };
    } else {
      payloadObj = {
        segment: preparePayload('segment')
      }
    }
    

    const payload = {
      posts: JSON.stringify(payloadObj),
      ...(isadvMode &&
      isAdvisorApprovalMode &&
      (!isEdit || campaignStatus === 'draft')
        ? { advisor_approval: isApprovalRequired }
        : {}),
      ...(isadvMode &&
      isAdvisorApprovalMode &&
      isEdit &&
      (campaignStatus === 'approval_rejected' ||
        campaignStatus === 'approval_pending' ||
        (campaignStatus === 'compliance_rejected' && emAdvApproval) ||
        (campaignStatus === 'scheduled' && emAdvApproval))
        ? { advisor_approval: true }
        : {}),
    };

    setLoading(true);
    publishCampaignV2({ id: campaignId, payload })
    .then((res) => {
      console.log('res', res);
      setLoading(false);
      let mode = get(setObjectivesTabData, 'createMode', '');
      if(get(setObjectivesTabData, 'approvedToShare', 'false') === 'true') {
        const payload = { 
          is_completed: true,
          ...((createMode === "series" || get(setObjectivesTabData, 'campaignType', '') === "series") && {record_type: 'series'})
        };

        console.log('SERIES ID - - - -', seriesId);
        postCascadeCampaign({ id: seriesId || campaignId, payload})
        .then(res => {
          if(res?.result?.success) {
            if(get(reviewCampaign, 'fromReview', false)) {
              redirectCascadeAdvisor();
            }
            else if (curFlow && curFlow === 'both') 
              handleNext();
            else {
              if (mode === 'oneTime' || fromDashboard) 
                history.push('/campaignV2');
              else if (mode === 'series') 
                redirectionCampaign();
            }
          }
        })
        .catch(err => console.log('ERROR cascading campaign  - - - -', err));
      }
      else {
        if (curFlow && curFlow === 'both') 
          handleNext();
        else {
          if(get(reviewCampaign, 'fromReview', false)) {
            redirectCascadeAdvisor();
          }
          else if (mode === 'oneTime' || fromDashboard) 
            history.push('/campaignV2');
          else if (mode === 'series') 
            redirectionCampaign();
        }
      }
    })
    .catch((err) => {
      setLoading(false);
      console.log('err', err);
    });

    // campaignSetState({
    //   setScheduleTimings: dateTimeList,
    // });
    // handleNext();
  };

  const handleBackButtonClick = () => {
    handleBack();
  };

  const handleSaveAndClose = () => {
    // console.log('DATE TIME LIST - - -- ', dateTimeList);
    campaignSetState({
      scheduleTimings: dateTimeList,
    });
    endCreateFlow();
    history.push('/campaignV2');
  };

  const preparePayload = (type) => {
    let payload = {};
    if (type === 'email' && !isEmpty(get(setObjectivesTabData, 'emList', []))) {
      let emSNid = getAccountId('em');
      payload.snetwork_act_id = emSNid;
      payload.schedule = timeMapper[timingTabs];
      let usersTimeList = [];
      if (dateTimeList['all'] && timingTabs === 1) {
        let users = get(setObjectivesTabData, 'emList', []);
        usersTimeList = users.map((user) => {
          let obj = {};
          obj.destination_id = user.id;
          obj.time = dateTimeList['all'].dateTime;
          return obj;
        });
        console.log('OBJ', usersTimeList);
        payload.schedule_time = usersTimeList;
      } 
      else if (timingTabs === 2) {
        for (let user in dateTimeList) {
          if (user !== 'all') {
            let obj = {};
            obj.destination_id = user;
            obj.time = dateTimeList[user].dateTime;
            usersTimeList.push(obj);
          }
        }
        payload.schedule_time = usersTimeList;
      }
    } else {
      payload.schedule = timeMapper[timingTabs];
      let emSNid = getAccountId('em');
      payload.snetwork_act_id = emSNid;
      let usersTimeList = [];
      if (dateTimeList['all'] && timingTabs === 1) {
        let users = get(setObjectivesTabData, 'segmentList', []);
        usersTimeList = users.map((user) => {
          let obj = {};
          obj.destination_id = user.id;
          obj.time = dateTimeList['all'].dateTime;
          return obj;
        });
        console.log('OBJ', usersTimeList);
        payload.schedule_time = usersTimeList;
      } 
      else if (timingTabs === 2) {
        for (let user in dateTimeList) {
          if (user !== 'all') {
            let obj = {};
            obj.destination_id = user;
            obj.time = dateTimeList[user].dateTime;
            usersTimeList.push(obj);
          }
        }
        payload.schedule_time = usersTimeList;
      }
    }
    payload.skip_compliance = isGlobalCompliance === 'true' ? true : skipCompliance.toString();
      // (!isEdit ||
      //   (campaignStatus !== 'compliance_rejected' && campaignStatus !== 'scheduled')) &&
      // userData &&
      // get(userData, 'compliance', '') === 'optional'
      //   ? String(skipCompliance)
      //   : (userData && get(userData, 'compliance', '') === 'on') ||
      //     (isEdit &&
      //       (campaignStatus === 'compliance_rejected' ||
      //         (campaignStatus === 'scheduled' && complianceApproved)) &&
      //       userData &&
      //       get(userData, 'compliance', '') === 'optional')
      //   ? 'false'
      //   : 'true';
    if (
      userData &&
      userData.details &&
      userData.details.user &&
      userData.details.user.enable_lead_capture === 'true' &&
      pdfPresent
    ) {
      payload.lead_gen = enableLeadGen ? 'true' : 'false';
      payload.referral = enableReferralCapture ? 'true' : 'false';
    } else {
      payload.lead_gen = 'false';
      payload.referral = 'false';
    }
    return payload;
  };

  const handleSendValidation = () => {
    if (timingTabs === 1) {
      if (isEmpty(get(dateTimeList['all'], 'dateTime', ''))) return true;
    } else if (timingTabs === 2) {
      return handlePopUpValidation();
    }
    return false;
  };

  const handlePopUpValidation = () => {
    console.log(dateTimeList);
    let allDateTime = Object.keys(dateTimeList).filter((id) => id !== 'all');
    console.log(allDateTime);
    let isDateTime = false;
    for (let id in dateTimeList) {
      if (isEmpty(get(dateTimeList[id], 'dateTime', ''))) {
        isDateTime = true;
        break;
      }
    }
    console.log(isDateTime);
    if (
      allDateTime.length ===  (!isEmpty(get(setObjectivesTabData, 'emList', [])) ? get(setObjectivesTabData, 'emList', []).length &&
      !isDateTime &&
      get(setObjectivesTabData, 'emList', []).length : get(setObjectivesTabData, 'segmentList', []).length) && !isDateTime
    ) {
      return false;
    } else 
    return true;
  };


  return (
    <Flex flexDirection="column" padding='40px 60px' alignItems='center'>
     <CampaignNavigation
         enableNext={true}
          nextHandler={handleNextClick}
          backHandler={handleBackButtonClick}
          loading={loader}
          saveAndCloseHandler={handleSaveAndClose}
          stepList={stepList}
          selectedIndex={selectedIndex}
          isLastStep={true}
        />
      <Flex
        width="100%"
        fontSize="14px"
        color="#969fa4"
        style={{ fontWeight: 600, fontSize: '1rem' }}
        justifyContent="center"
        mb="10px"
      >
        {get(setObjectivesTabData, 'myNetwork', false) ? 'Delivery' : 'Share with Advisors'}
      </Flex>
      {get(setObjectivesTabData, 'myNetwork', false) && (
        <TimingCard style={{ width: '100%', height: 'max-content' }}>
          <Flex alignItems="center" justifyContent="center">
            <RadioInput
              type="radio"
              id="timeMode_all"
              name="timeMode"
              checked={timingTabs === 0}
              onClick={() => {
                setTimingTabs(0);
              }}
              style={{ cursor: 'pointer' }}
            />
            <RadioInputLabel htmlFor="timeMode_all" style={{ cursor: 'pointer' }}>
              Send Immediately
            </RadioInputLabel>
          </Flex>
          <hr style={{ width: '98%', margin: '10px auto' }} />
          <Flex alignItems="center" justifyContent="center">
            <RadioInput
              type="radio"
              id="timeMode_specific_time"
              name="timeMode"
              checked={timingTabs === 1}
              onClick={() => {
                setTimingTabs(1);
              }}
              style={{ cursor: 'pointer' }}
            />
            <RadioInputLabel
              htmlFor="timeMode_specific_time"
              style={{ cursor: 'pointer' }}
            >
              Schedule Date & Time
            </RadioInputLabel>
          </Flex>
          <Flex style={{ opacity: timingTabs === 1 ? '100%' : '50%' }}>
            <Flex
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
              pt="15px"
              mr="20px"
            >
              <InputLabel>Date & Time</InputLabel>
              <DateTimeWrapper width="200px">
                <DateTimeText>
                  {dateTimeList['all']?.dateTime &&
                    moment(dateTimeList['all']?.dateTime).format(
                      'MM/DD/YY, h:mm a'
                    )}
                </DateTimeText>
                <DripIconButton
                  onClick={() => handleDateTimeListChange('all', 'isOpen', true)}
                  icon="clock"
                  styles={{ marginTop: '5px' }}
                  disable={timingTabs !== 1}
                />
                <MioDatePicker
                  isOpen={dateTimeList['all']?.isOpen || false}
                  customInput={<div />}
                  minDate={new Date()}
                  onDateClose={() =>
                    handleDateTimeListChange('all', 'isOpen', false)
                  }
                  onSubmit={(dateTime) =>
                    handleDateTimeListChange('all', 'dateTime', dateTime, true)
                  }
                  scheduleValue={dateTimeList['all']?.dateTime}
                  timeZone={get(userData, 'details.user.timezone', '')}
                  isFromNewsletter
                />
              </DateTimeWrapper>
            </Flex>
            {/* <Flex
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
              pt="15px"
            >
              <InputLabel>Time</InputLabel>
              <Input
                type="time"
                id="time"
                name="time"
                disabled={timingTabs == 0 || timingTabs == 2}
                width="200px"
                value={dateTimeList['all']?.time || ''}
                onChange={(e) =>
                  handleDateTimeListChange('all', 'time', e.target.value)
                }
              />
            </Flex> */}
          </Flex>
          <hr style={{ width: '98%', margin: '16px auto 0px' }} />
          <Flex alignItems="center" justifyContent="center" my="10px">
            <RadioInput
              type="radio"
              id="timeMode_different_time"
              name="timeMode"
              checked={timingTabs === 2}
              onClick={() => {
                setTimingTabs(2);
                handleClose(true);
              }}
              style={{ cursor: 'pointer' }}
            />
            <RadioInputLabel
              htmlFor="timeMode_different_time"
              style={{ cursor: 'pointer' }}
            >
              Choose different dates/times
            </RadioInputLabel>
          </Flex>
          {timingTabs === 2 && (
            <Button
              margin="auto"
              padding="5px 10px"
              fontSize="12px"
              borderRadius="4px"
              onClick={() => setIsOpen(true)}
            >
              {!isEmpty(dateTimeList) ? 'View' : 'Set Time'}
            </Button>
          )}
          {
            userData &&
            userData.details &&
            userData.details.user &&
            userData.details.user.enable_lead_capture === 'true' &&
            pdfPresent && (
              <>
                <hr
                  style={{
                    width: '98%',
                    margin: timingTabs === 2 ? '15px auto 0px' : '0px auto',
                  }}
                />
                <LeadCaptureOption
                  selectedLeadCaptureOption={enableLeadGen}
                  selectecdReferralCaptureOption={enableReferralCapture}
                  setSelectedLeadCaptureOption={toggleLeadGen}
                  setSelectedReferralCaptureOption={toggleReferralCapture}
                  leadStyles={{
                    color: '#000000',
                    padding: '5px 0px 0px',
                    fontSize: '14px',
                  }}
                  referralStyles={{
                    color: '#000000',
                    padding: '5px 0px 0px',
                    fontSize: '14px',
                  }}
                  paddingTop="10px"
                  switchHandleTop="2.31px !important"
                />
              </>
          )}
        </TimingCard>
      )}

      {isadvMode &&
        isAdvisorApprovalMode &&  
        (!isEdit || campaignStatus === 'draft') && (
          <ApprovalCard>
            <Flex
              alignItems="center"
              justifyContent="center"
              mr="50px"
              mt="3px"
            >
              <label
                style={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
                data-testid="advisor_approval_label"
              >
                Advisor Approval Required?
              </label>
            </Flex>
            <Flex alignItems="center" justifyContent="center" mr="5px">
              <RadioInput
                type="radio"
                id="advisor_approval_yes"
                name="advisor_approval"
                checked={isApprovalRequired}
                onClick={(e) => {
                  setIsApprovalRequired(true);
                }}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="advisor_approval_yes"
                style={{ cursor: 'pointer' }}
              >
                Yes
              </RadioInputLabel>
            </Flex>
            <Flex alignItems="center" justifyContent="center">
              <RadioInput
                type="radio"
                id="advisor_approval_no"
                name="advisor_approval"
                checked={!isApprovalRequired}
                onClick={(e) => {
                  setIsApprovalRequired(false);
                }}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="advisor_approval_no"
                style={{ cursor: 'pointer' }}
              >
                No
              </RadioInputLabel>
            </Flex>
          </ApprovalCard>
        )}

      {showCompliance && isGlobalCompliance !== 'true' && (
          <ApprovalCard>
            <Flex
              alignItems="center"
              justifyContent="center"
              mr="15px"
              mt="3px"
            >
              <label
                style={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
                data-testid="compliance_approval_label"
              >
                Compliance Approval Required?
              </label>
            </Flex>
            <Flex alignItems="center" justifyContent="center" mr="5px">
              <RadioInput
                type="radio"
                id="compliance_approval_yes"
                name="compliance_approval"
                checked={!skipCompliance}
                onClick={(e) => {
                  setSkipCompliance(false);
                }}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="compliance_approval_yes"
                style={{ cursor: 'pointer' }}
              >
                Yes
              </RadioInputLabel>
            </Flex>
            <Flex alignItems="center" justifyContent="center">
              <RadioInput
                type="radio"
                id="compliance_approval_no"
                name="compliance_approval"
                checked={skipCompliance}
                onClick={(e) => {
                  setSkipCompliance(true);
                }}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="compliance_approval_no"
                style={{ cursor: 'pointer' }}
              >
                No
              </RadioInputLabel>
            </Flex>
          </ApprovalCard>
        )}

      <Flex width="100%" alignItems="center" justifyContent="center">
        <LoaderWrapper isLoading={loading}>
          <Button
            // width={
            //   (userData && get(userData, 'compliance', '') === 'on') ||
            //   (isEdit &&
            //     (campaignStatus === 'compliance_rejected' ||
            //       (campaignStatus === 'scheduled' && complianceApproved)) &&
            //     userData &&
            //     get(userData, 'compliance', '') === 'optional') ? !get(setObjectivesTabData, 'myNetwork', false) ? '100%' :
            //      '65%'
            //     : '30%'
            // }
            // width={skipCompliance ? '30%' : '65%'}
            width={skipCompliance ? '30%' : '82%'}
            borderRadius="5px"
            margin="10px 0px"
            disabled={handleSendValidation()}
            onClick={handleNextClick}
            id="campaign_email_send"
          >
            {/* {!get(setObjectivesTabData, 'myNetwork', false) ? 
              ((userData && get(userData, 'compliance', '') === 'on') || campaignStatus === 'compliance_rejected' ? 
                'Share After Compliance Approval' : 'Share') : 
            (userData && get(userData, 'compliance', '') === 'on') ||
            campaignStatus === 'compliance_rejected' ||
            (isEdit && 
              (
                campaignStatus === 'compliance_rejected' ||
                (campaignStatus === 'scheduled' && complianceApproved)
              ) &&
              userData &&
              get(userData, 'compliance', '') === 'optional')
              ? 'Send After Compliance Approval'
              : 'Send'} */}
            {console.log('SKIP COMPLIANCE - -- - - -', skipCompliance)}
            {isGlobalCompliance === 'true' ? 'Share' : skipCompliance ? 'Share' : 'Share After Compliance Approval'}
            {/* {!get(setObjectivesTabData, 'myNetwork', false) ? (skipCompliance ? 'Share' : 'Share After Compliance Approval') : (skipCompliance ? 'Send' : 'Send After Compliance Approval')} */}
          </Button>
        </LoaderWrapper>
      </Flex>
      <EmailDTListPopup
        open={isOpen}
        handleClose={() => handleClose(false)}
        handleDateTimeListChange={handleDateTimeListChange}
        dateTimeList={dateTimeList}
        users={get(setObjectivesTabData, 'emList', [])}
        handleValidation={handlePopUpValidation}
        setDateTimeList={setDateTimeList}
        userData={userData}
        selectedSegments={get(setObjectivesTabData, 'segmentList', [])}
      />
      {/* <FooterBtnsWrapper>
        <CampaignFooterButtons
          // enableNext={!handleValidation()}
          enableNext={true}
          nextHandler={handleNextClick}
          backHandler={handleBackButtonClick}
          loading={loader}
          saveAndCloseHandler={handleSaveAndClose}
          padding="10px 0px"
          isLastStep={true}
        />
      </FooterBtnsWrapper> */}
    </Flex>
  );
};

export default PreviewAndSchedule;
