import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';

const AutomatedCampaignFormWrapper = styled(Box)`
  border: ${({ forDashboardPreview }) =>
    forDashboardPreview ? '1px transparent' : '1px solid rgb(207, 217, 228)'};
  border-radius: 4px;
  width: ${({ forDashboardPreview }) => (forDashboardPreview ? '100%' : '75%')};
  margin: ${({ forDashboardPreview }) =>
    forDashboardPreview ? '0px auto' : '80px auto 50px'};
  padding: ${({ forDashboardPreview }) =>
    forDashboardPreview ? '0px 25px' : '25px'};

  .field__title {
    font-weight: 600;
    text-align: left;
    color: #000000;
  }

  .date__input {
    font-family: 'Poppins';
    cursor: pointer;
    height: 32px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #8493a6;
    padding-left: 5px;
  }
`;

const LineBreaker = styled.hr`
  width: 100%;
  margin: 20px auto 20px 0px;
`;

const StyledTextInput = styled.input.attrs({
  type: 'text',
})`
  width: ${({ width }) => width || '100%'};
  border-radius: 4px;
  border: 1px solid #8493a6;
  padding: 5px;
  height: ${({ height }) => height || ''};
  color: ${({ disabled }) => (disabled ? '#adadad' : '#000')};
  font-family: 'Poppins';
`;

const AutomatedCampaignStepsFooterSection = styled.div`
  display: flex;
  background-color: #fff;
  height: 10%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  position: fixed;
  bottom: 0;
  left: 0;
`;

const CustomizeCampaignWrapper = styled(Box)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: 0 auto;
  .email_series__wrapper,
  .post_register_actions__holder {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .cursor-pointer {
    cursor: pointer;
  }
  .email_series__wrapper {
    .vertical__line {
      width: 2px;
      height: 80%;
      background-color: red;
      z-index: -1;

      position: absolute;
      left: 50%;
      top: 40px;
      transform: translate(-50%);
    }
  }

  .line__breaker {
    width: 98%;
    border: none;
    height: 3px;
    background: rgb(207, 217, 228);
    margin: 20px auto;
  }
`;

const StyledCampaignActionBox = styled(Flex)`
  background-color: #ffffff;
  height: 30px;
  border: 1px solid rgb(207, 217, 228);
  border-radius: 4px;
  font-size: 14px;
  padding: 0 10px;
  margin: 25px auto;

  .actions__holder {
    position: relative;
  }
`;

const ActionsDropdown = styled(Box)`
  width: ${({ forWaitTimeEdit }) => (forWaitTimeEdit ? '100px' : '150px')};
  position: absolute;
  top: 15px;
  left: 20px;

  background: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  -webkit-box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  z-index: 1;

  .action-item {
    padding: 6px 0px 6px 20px;
    color: black;
    font-size: 12px;
    margin: 0px;
    text-align: left;
    transition: all 0.2s;

    :hover {
      background: #6351ed;
      color: #fff;
    }
  }
`;

const EmailSeriesBox = styled(Box)`
  padding: 10px 20px;
  margin: 10px 0px 25px;
  border-radius: 10px;
  background-color: white;

  -webkit-box-shadow: 0px 0px 18px -6px rgba(209, 217, 230, 1);
  -moz-box-shadow: 0px 0px 18px -6px rgba(209, 217, 230, 1);
  box-shadow: 0px 0px 18px -6px rgba(209, 217, 230, 1);
`;

const getSelectStyles = () => ({
  container: (base) => ({
    ...base,
    width: '100px',
    margin: '0 15px',
  }),
  control: (base) => ({
    ...base,
    height: '40px',
    minHeight: '40px',
    border: '1px solid #8493A6',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#848484',
  }),
});

export {
  AutomatedCampaignStepsFooterSection,
  AutomatedCampaignFormWrapper,
  LineBreaker,
  StyledTextInput,
  CustomizeCampaignWrapper,
  StyledCampaignActionBox,
  ActionsDropdown,
  EmailSeriesBox,
  getSelectStyles,
};
