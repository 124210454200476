import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { Button, LoaderWrapper } from '../../../components';
import { NewsletterArticle } from '../../NewsletterContainer/NewsletterArticle';
import { ModalArticlesContainer } from '../ArticleModal.styles';

const AddContentArticles = ({ 
    articleType, 
    setArticleType, 
    articleData, 
    selectedArticles, 
    themeNames,
    handleArticleClick,
    showLoadMore,
    loadMoreHandler,
    loader
}) => {
    return (
        <Flex width="98%" flexDirection='column' style={{ overflowX: 'auto', overflowY: 'hidden', margin: 'auto' }}>
            <Flex margin='auto' width='80%' justifyContent='space-evenly' alignItems='center'>
                <div
                    style={{
                        color: articleType === 'bookmarked' ? '#6351ed' : '#49484a',
                        borderBottom: articleType === 'bookmarked' ? '1px solid #6351ed' : '0px',
                        cursor: 'pointer'
                    }}
                    onClick={() => setArticleType('bookmarked')}
                >
                    Bookmarked
                </div>
                <div
                    style={{
                        color: articleType === 'recommended' ? '#6351ed' : '#49484a',
                        borderBottom: articleType === 'recommended' ? '1px solid #6351ed' : '0px',
                        cursor: 'pointer'
                    }}
                    onClick={() => setArticleType('recommended')}
                >
                    Recommended
                </div>
                <div
                    style={{
                        color: articleType === 'my-content' ? '#6351ed' : '#49484a',
                        borderBottom: articleType === 'my-content' ? '1px solid #6351ed' : '0px',
                        cursor: 'pointer'
                    }}
                    onClick={() => setArticleType('my-content')}
                >
                    My Content
                </div>
            </Flex>
            <ModalArticlesContainer>
                {!isEmpty(articleData[articleType]) ? articleData[articleType].map((item, index) => (
                    <div
                        style={{
                            textAlign: 'center',
                            marginRight: '12px',
                            marginBottom: '10px',
                            borderRadius: '8px',
                            border: selectedArticles.includes(item.id) ? '1px solid #6351ed' : '0px',
                            cursor: 'pointer'
                        }}
                    >
                        <NewsletterArticle
                            key={index}
                            articleIndex={index}
                            margin="0px"
                            {...item}
                            themeNames={themeNames}
                            newsletterArticles={selectedArticles}
                            article={item}
                            deleteBlocked
                            width='196px'
                            height='296px'
                            imgWidth='186px'
                            maxImgWidth='186px'
                            articleType={articleType}
                            handleBucketArticleClick={handleArticleClick}
                        />
                    </div>
                )) : (
                    <Flex justifyContent='center' alignItems='center' style={{ width: '100%', height: '300px' }}>No articles found</Flex>
                )}
            </ModalArticlesContainer>
            {articleType === 'my-content' && (
                <LoaderWrapper isLoading={loader}>
                    <Button 
                        margin='20px auto 0px' 
                        width='110px' 
                        onClick={loadMoreHandler}
                        disabled={!showLoadMore}
                    >
                        Load More
                    </Button>
                </LoaderWrapper>
            )}
        </Flex>
    );
};

export default AddContentArticles;