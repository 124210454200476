/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Box, Flex } from '@rebass/grid';
import { Modal, ModalHeader, ModalBody, ModalCloseButton, Button } from '../';
import { company, themesData } from '../../utils';
import { Input } from '../Form';
import { LoaderWrapper } from '../Loader';
import { MessageWrapper } from '../../containers/NewsletterContainer/PreviewTab/PreviewTab.styles';
import parse from 'html-react-parser';

const EmailPreview = ({
  isModalOpen,
  setModalOpen,
  onSave,
  themeColor,
  signature,
  disclaimer,
  logo,
  emailNameText,
  messageText,
  summaryText,
  subjectText,
  articleData,
  fromShareContent,
  pdfImage,
  isFromRecentTab,
  imageWidth,
  imageHeight,
  setImageWidth,
  setImageHeight,
  originalWidth,
  originalHeight,
  loading,
  isFromCompliance,
  isComplianceAdmin,
  useLogo,
  fromPersonalize,
  fromCustomizeEmail
}) => {
  let signatureText = signature && signature.split("\n").join("");
  signatureText = signatureText && signatureText.split('<p></p>').join("<br>");
  let disclaimerText = disclaimer && disclaimer.split("\n").join("");
  disclaimerText = disclaimerText && disclaimerText.split('<p></p>').join("<br>");

  const [salutationCss, setSalutationCss] = useState({});

  useEffect(() => {
    let parsedMessage = messageText && parse(messageText);
    parsedMessage = Array.isArray(parsedMessage) ? parsedMessage[0] : parsedMessage;
    console.log('parsedMessage',parsedMessage);
    let salutationStyle;
    if (
      parsedMessage && 
      parsedMessage.props &&
      parsedMessage.props.style
    ) {
      salutationStyle = parsedMessage.props.style;
      if(parsedMessage.props.children && 
        parsedMessage.props.children && 
        parsedMessage.props.children.type && 
        parsedMessage.props.children.props.style) {
          salutationStyle = {...salutationStyle, ...parsedMessage.props.children.props.style};
          if(parsedMessage.props.children.props.children && 
            parsedMessage.props.children.props.children.props && 
            parsedMessage.props.children.props.children.type &&
            parsedMessage.props.children.props.children.props.style
          ) {
            salutationStyle = {...salutationStyle, ...parsedMessage.props.children.props.children.props.style};
          }
      }
      console.log(salutationStyle);
      setSalutationCss(salutationStyle);
    }
  }, []);

  const handleWidthChange = (e) => {
    let width;
    if(+e.target.value > +e.target.max) {
      width = e.target.max;
    } else {
      width = e.target.value;
    }

    setImageWidth(width);
    
    let newHeight = (originalHeight/originalWidth) * width;
    setImageHeight(Math.round(newHeight));
  }

  const handleHeightChange = (e) => {
    let height;
    if(+e.target.value > +e.target.max) {
      height = e.target.max;
    } else {
      height = e.target.value;
    }
    setImageHeight(height);
    let newWidth = (originalWidth/originalHeight) * height;
    setImageWidth(Math.round(newWidth));
  }

  return (
    <Modal
      onRequestClose={() => setModalOpen(false)}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      size="medium"
    >
      <ModalHeader>
        Email Preview
        <ModalCloseButton onClick={() => setModalOpen(false)} />
      </ModalHeader>
      <ModalBody>
        <Flex
          width={1}
          height="410px"
          style={{
            overflowY: 'auto',
          }}
        >
          <table
            role="presentation"
            aria-hidden="true"
            cellspacing="0"
            cellpadding="0"
            border="0"
            align="center"
            width="800"
            height="500"
            style={{ margin: 'auto', width: '100%' }}
            className="email-container"
          >
            <tbody>
            <tr>
              <td
                align="left"
                style={{
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '16px',
                  color: '#535353',
                  lineHeight: '24.65px',
                  fontWeight: 'normal',
                  padding: '0 40px',
                  background: 'white'
                }}
              >
                {subjectText && <span style={{color: '#000000'}}>{subjectText}</span>}
              </td>
            </tr>
              <tr
                style={{
                  borderRight: `1px solid ${themeColor}`,
                  borderLeft: `1px solid ${themeColor}`,
                }}
                className="headertrCls"
                id="logotr"
              >
                <td
                  className="headertdCls"
                  align="center"
                  id="advisorlogo"
                  style={{ backgroundColor: themeColor, padding: '30px 0px' }}
                >
                  {/* {console.log('FROM personalize - - -', fromPersonalize, ' LOGO - - - ', logo, ' USE LOGO - - - ', useLogo)} */}
                  {/* {console.log('FROM customize - - -', fromCustomizeEmail, ' LOGO - - - ', logo, ' USE LOGO - - - ', useLogo)} */}
                  {(fromPersonalize || fromCustomizeEmail) && useLogo && logo && (
                    <img
                      src={logo}
                      id="brandLogoImg"
                      className="center-on-narrow bg-color"
                      aria-hidden="true"
                      width={imageWidth}
                      height={imageHeight}
                      alt=""
                      border="0"
                      align="center"
                      style={{
                        backgroundColor: themeColor,
                        color: '#fff',
                      }}
                    />
                  )}
                    {!fromPersonalize && !fromCustomizeEmail && useLogo && logo && (
                      <img
                        src={logo}
                        id="brandLogoImg"
                        className="center-on-narrow bg-color"
                        aria-hidden="true"
                        width={imageWidth}
                        height={imageHeight}
                        alt=""
                        border="0"
                        align="center"
                        style={{
                          backgroundColor: themeColor,
                          color: '#fff',
                        }}
                      />
                    )}
                     {/* <img
                      src={logo}
                      id="brandLogoImg"
                      className="center-on-narrow bg-color"
                      aria-hidden="true"
                      width={imageWidth}
                      height={imageHeight}
                      alt=""
                      border="0"
                      align="center"
                      style={{
                        backgroundColor: themeColor,
                        color: '#fff',
                      }}
                    /> */}
                  <span id="templogo"></span>
                </td>
              </tr>
              <tr
                style={{
                  borderRight: '1px solid #ddd',
                  borderTop: '0px solid #ddd',
                  borderLeft: '1px solid #ddd',
                }}
              >
                <td
                  align="center"
                  bgcolor="#7054a6"
                  className="headertdCls"
                  style={{ backgroundColor: themeColor }}
                >
                  <table
                    width="100%"
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    style={{ backgroundColor: '#ffffff' }}
                  >
                    <tbody>
                      <tr>
                        <td height="15"></td>
                      </tr>
                      <tr>
                        <td
                          align="left"
                          style={{
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: '16px',
                            color: '#535353',
                            lineHeight: '24.65px',
                            fontWeight: 'normal',
                            padding: '0 40px',
                          }}
                        >
                          {fromShareContent ? (
                            <p style={{marginBlock: '0em', color: 'black'}}>
                              <span id="salutation_text" style={salutationCss}>{emailNameText}</span>
                            </p>
                          ) : (
                            <span style={{color: '#000000'}}>{emailNameText ? emailNameText : !fromShareContent ? 'Hello XXX' : ''}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          height="10"
                          style={{ fontSize: 0, lineHeight: '0' }}
                        >
                          &nbsp;
                        </td>
                      </tr>
                          <tr>
                            <td
                              align="left"
                              style={{
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: '16px',
                                color: 'black',
                                lineHeight: '24.65px',
                                fontWeight: 'normal',
                                padding: '0 40px',
                              }}
                            >
                              {fromShareContent ? (
                                <MessageWrapper id="message" dangerouslySetInnerHTML={{ __html: messageText }}></MessageWrapper>
                              ) : (
                                <span 
                                  style={{
                                    color: 'black', 
                                    whiteSpace: messageText && messageText.length>0 ? 'pre-wrap' : ''
                                  }}
                                >
                                  {messageText || (!fromShareContent && `I would like to share this insightful article with you.`)}
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td height="20"></td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              style={{
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: '16px',
                                color: '#535353',
                                lineHeight: '24.65px',
                                fontWeight: 'normal',
                                padding: '0 40px',
                              }}
                            >
                              <span style={{color: '#000000', whiteSpace: summaryText && summaryText.length>0 ? 'pre-wrap' : ''}}>{summaryText || !fromShareContent && `No... There Is No Bond Bear Market I see it all across
                              financial media, people talking about the bond bear
                              market. Writers strike fear into the hearts of their
                              loyal readers with dramatic language, and the fear
                              that you stand to lose a considerable amount from your
                              safe investments.`}</span>
                            </td>
                          </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: '#ffffff',
                  borderRight: '1px solid #ddd',
                  borderBottom: '1px solid #ddd',
                  borderLeft: '1px solid #ddd',
                }}
              >
                <td align="center" bgcolor="#fff">
                  <table
                    width="100%"
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    style={{ backgroundColor: '#ffffff' }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            width="100%"
                            align="left"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                          >
                            <tbody>
                              <tr style={{ backgroundColor: '#ffffff' }}>
                                <td height="20"></td>
                              </tr>
                              <tr style={{ backgroundColor: '#ffffff' }}>
                                <td
                                  bgcolor="#ffffff"
                                  dir="ltr"
                                  align="center"
                                  valign="top"
                                  width="100%"
                                  style={{ padding: '10px 40px' }}
                                >
                                  <table
                                    role="presentation"
                                    aria-hidden="true"
                                    align="center"
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          width="40%"
                                          className="stack-column-center"
                                          style={{
                                            paddingTop: '10px',
                                            display: 'block',
                                          }}
                                        >
                                          <table
                                            role="presentation"
                                            aria-hidden="true"
                                            align="center"
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="100%"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  dir="ltr"
                                                  valign="top"
                                                  style={{ padding: '0 10px' }}
                                                >
                                                  <img
                                                    src={articleData && articleData.image ? articleData.image : pdfImage ? pdfImage : "/assets/images/alpha_01.jpg"}
                                                    aria-hidden="true"
                                                    width="160"
                                                    height="auto"
                                                    alt="alt_text"
                                                    border="0"
                                                    className="center-on-narrow"
                                                    style={{
                                                      height: 'auto',
                                                      background: ' #dddddd',
                                                      fontFamily:
                                                        'Poppins, sans-serif',
                                                      fontSize: '15px',
                                                      lineHeight: '20px',
                                                      color: '#555555',
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td
                                          width="60%"
                                          className="stack-column-center"
                                        >
                                          <table
                                            role="presentation"
                                            aria-hidden="true"
                                            align="center"
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="100%"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  dir="ltr"
                                                  valign="top"
                                                  style={{
                                                    fontFamily:
                                                      'Poppins, sans-serif',
                                                    fontSize: '15px',
                                                    lineHeight: '20px',
                                                    color: '#555555',
                                                    padding: '10px',
                                                    textAlign: 'left',
                                                  }}
                                                  className="center-on-narrow"
                                                >
                                                  <h2
                                                    style={{
                                                      margin: '0px 0',
                                                      fontFamily:
                                                        'Poppins, sans-serif',
                                                      fontSize: '16px',
                                                      lineHeight: '30px',
                                                      color: '#535353',
                                                      fontWeight: 'bold',
                                                    }}
                                                  >
                                                    {articleData && articleData.title ? articleData.title : 'There Is No Bond Bear Market'}
                                                  </h2>
                                                  <p
                                                    style={{
                                                      marginBottom: '5px',
                                                    }}
                                                    className="no-mob"
                                                  >
                                                    &nbsp;
                                                  </p>
                                                  <p style={{ margin: '0' }}>
                                                    &nbsp;
                                                  </p>
                                                  <table
                                                    role="presentation"
                                                    aria-hidden="true"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                    border="0"
                                                    className="center-on-narrow"
                                                    style={{ float: 'left' }}
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            borderRadius: '0px',
                                                            background:
                                                              '#222222',
                                                            textAlign: 'center',
                                                          }}
                                                          className="button-td"
                                                        >
                                                          <a
                                                            href="javascript:void(0),"
                                                            style={{
                                                              fontFamily:
                                                                'Poppins, sans-serif',
                                                              fontSize: '13px',
                                                              lineHeight: '1.1',
                                                              textAlign:
                                                                'center',
                                                              textDecoration:
                                                                'none',
                                                              display: 'block',
                                                              borderRadius:
                                                                '0px',
                                                              fontWeight:
                                                                'bold',
                                                              backgroundColor: '#ffffff',
                                                              padding: '6px 0px',
                                                              border: `3px solid ${themeColor === '#ffffff' ? '#000000' : themeColor === 'undefined' || themeColor === undefined ? '#000000' : themeColor}`,
                                                            }}
                                                            className="button-a disable read-btn"
                                                          >
                                                            <span
                                                              id="readtxt"
                                                              style={{
                                                                color:
                                                                  'rgb(0,0,0)',
                                                              }}
                                                              className="button-link"
                                                            >
                                                              &nbsp;&nbsp;&nbsp;&nbsp;Learn
                                                              More
                                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                                            </span>
                                                          </a>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  dir="ltr"
                                                  valign="top"
                                                  style={{
                                                    fontFamily:
                                                      'Poppins, sans-serif',
                                                    fontSize: '15px',
                                                    lineHeight: '20px',
                                                    color: '#555555',
                                                    padding: '10px',
                                                    textAlign: 'left',
                                                  }}
                                                  className="center-on-narrow"
                                                ></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td bgcolor="#ffffff" style={{ padding: '0 40px' }}>
                          <table
                            role="presentation"
                            aria-hidden="true"
                            cellspacing="0"
                            cellpadding="0"
                            border="0"
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    fontFamily: 'sans-serif',
                                    fontSize: '16px',
                                    color: '#757575',
                                    whiteSpace: 'pre-wrap',
                                  }}
                                  id="customSignature"
                                >
                                  <div className='signature' dangerouslySetInnerHTML={{ __html: signatureText && signatureText }}></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td bgcolor="#ffffff" height="20"></td>
                      </tr>
                      <tr>
                        <td bgcolor="#ffffff" style={{ padding: '0 40px' }}>
                          <table
                            role="presentation"
                            aria-hidden="true"
                            cellspacing="0"
                            cellpadding="0"
                            border="0"
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    padding: '0px',
                                    fontFamily: 'sans-serif',
                                    fontSize: '15px',
                                    lineHeight: '20px',
                                    color: '#757575',
                                    whiteSpace: 'pre-wrap',
                                  }}
                                  id="customDisclaimer"
                                >
                                  <div className='disclaimer' dangerouslySetInnerHTML={{ __html: disclaimerText && disclaimerText }}></div>
                                </td>
                              </tr>
                              <tr>
                                <td bgcolor="#ffffff" height="10"></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: '20px 0 10px 0',
                                    fontFamily: 'sans-serif',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    color: '#757575',
                                  }}
                                >
                                  © 2018 - 2020 {company.name}
                                </td>
                              </tr>
                              <tr>
                                <td bgcolor="#ffffff" height="10"></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: '0px',
                                    fontFamily: 'sans-serif',
                                    fontSize: '13px',
                                    lineHeight: '20px',
                                    color: '#757575',
                                  }}
                                >
                                  This message was sent to
                                  <a
                                    href=""
                                    className="disable"
                                    style={{ textDecoration: 'none' }}
                                  >
                                    <u>xxx@yyy.com</u>
                                  </a>
                                  and intended for xxx.
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: '0px 0 20px 0px',
                                    fontFamily: 'sans-serif',
                                    fontSize: '13px',
                                    lineHeight: '15px',
                                    color: '#757575',
                                  }}
                                >
                                  Not your account?
                                  <a
                                    href="javascript:void(0),"
                                    className="disable"
                                    style={{
                                      textDecoration: 'none',
                                      color: '#757575',
                                    }}
                                  >
                                    <u>Remove your email</u>
                                  </a>
                                  from this account.
                                </td>
                              </tr>
                              <tr>
                                <td bgcolor="#ffffff" height="20"></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </Flex>
        {!isFromRecentTab && !fromShareContent && (
          <Flex width={1} alignItems="center" justifyContent="center" pt="25px">
            <div style={{position: 'relative'}}>
              <Input pr="10px" color={themesData.purpleishBlue} max={originalWidth} outerWidth="75%" placeholder="Enter Image Width" isInputWithFloatingLabel type="number" name="width" value={imageWidth} onChange={handleWidthChange}/>
            </div>
            &nbsp;&nbsp;
            <div style={{position: 'relative'}}>
              <Input pr="10px" color={themesData.purpleishBlue} max={originalHeight} outerWidth="77%" placeholder="Enter Image Height" isInputWithFloatingLabel type="number" name="height" value={imageHeight} onChange={handleHeightChange}/>
            </div>
          </Flex>
        )}
        {(!isFromRecentTab && !isFromCompliance) && (
          <Flex width={1} alignItems="center" justifyContent="center">
            <LoaderWrapper isLoading={loading}>
              <Box width={1}>
                <Button onClick={onSave} disabled={(!subjectText && fromShareContent)}>Save</Button>&nbsp;&nbsp;
                <Button bgColor="#ffffff" color={themesData.purpleishBlue} onClick={() => setModalOpen(false)}>Cancel</Button>
              </Box>
            </LoaderWrapper>
          </Flex>
        )}
        {/* {(!isFromCompliance && isComplianceAdmin) && (
          <Flex width={1} alignItems="center" justifyContent="center">
            <LoaderWrapper isLoading={loading}>
              <Box width={1}>
                <Button bgColor="#ffffff" color={themesData.purpleishBlue} onClick={() => setModalOpen(false)}>Cancel</Button>
              </Box>
            </LoaderWrapper>
          </Flex>
        )} */}
      </ModalBody>
    </Modal>
  );
};

export default EmailPreview;
