import { Flex } from '@rebass/grid';
import styled from 'styled-components';


export const RadioInput = styled.input`
  border: 2px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;

  &:checked {
    background-color: #C95CFC;
    box-shadow: 0 0 0 1px #aaa;
  }
`;

export const RadioInputLabel = styled.label`
  color: #000;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
`;

export const ScrollWrapper = styled(Flex)`
  flex-direction: column;
  max-height: ${({ maxHeight }) => maxHeight || ''};
  height:  ${({ height }) => height || ''};
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  } 
  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;
