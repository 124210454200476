import React from 'react';
import withAnalyticsLayout from '../../hoc/withAnalyticsLayout';
import withAppLayout from '../../hoc/withAppLayout';
import { BlockScreen } from '../../components';
import { ROLE_TYPES } from '../../utils';

import {
  AdvisorAnalyticsContainer,
  AdminAnalyticsContainer,
  AnalyticsSummaryContainer,
} from '../../containers';

const AdminAnalyticsLayout = () => <AdminAnalyticsContainer />;

const AdvisorAnalyticsLayout = ({ roleType }) => {
  if (roleType === ROLE_TYPES.ADVISOR_FREE) {
    return <BlockScreen screen="impact" />;
  }
  return <AdvisorAnalyticsContainer />;
};

const AnalyticsSummaryLayout = ({ roleType }) => {
  if (roleType === ROLE_TYPES.ADVISOR_FREE) {
    return <BlockScreen screen="insightScore" />;
  }
  return <AnalyticsSummaryContainer />;
};

export const AdminAnalytics = withAnalyticsLayout(AdminAnalyticsLayout);

export const AdvisorAnalytics = withAnalyticsLayout(AdvisorAnalyticsLayout);

export const AnalyticsSummary = withAppLayout(AnalyticsSummaryLayout);
