import { Flex } from '@rebass/grid';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Button, ModalCloseButton, LoaderWrapper, ModalV2, ModalHeader, ModalBody } from '../../../../components';
import { getAutomatedBlocksById } from '../../../../services/automated-campaigns';
import { TemplateContainer } from '../../Preview&ViewInfoModal/Modal.styles';

const EmailBlockPreview = ({
  isOpen,
  handleClose,
  template_html,
  block_id,
  campaign_id,
}) => {
  const iframeRef = useRef(null);
  const [blockData, setBlockData] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchBlockById = async () => {
    try {
      setLoading(true);
      const { data } = await getAutomatedBlocksById(campaign_id, block_id);
      setLoading(false);
      setBlockData(data);
    } catch (error) {
      setLoading(false);
      console.log(`Error Fetching Block for the id ${block_id} --> `, error);
    }
  };

  useEffect(() => {
    fetchBlockById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalV2
      isOpen={isOpen}
      onRequestClose={handleClose}
      width='50%'
      style={{ padding: '20px' }}
    >
      <ModalHeader justifyContent={'center'}>
        <Flex
          justifyContent='center'
          style={{ color: '#000000', fontSize: '18px' }}>Preview</Flex>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>
      <ModalBody>
        <LoaderWrapper isLoading={loading}>
          {blockData.template_html ? (
            <>
              <div
                style={{
                  background: 'white',
                  padding: '10px 45px',
                  color: '#000000',
                  fontSize: '14px',
                }}
              >
                Subject: {blockData.subject}
              </div>
              <TemplateContainer>
                <iframe
                  srcDoc={blockData.template_html}
                  frameBorder="no"
                  ref={iframeRef}
                  title="Email Preview"
                />
              </TemplateContainer>
            </>
          ) : (
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              style={{ height: '300px' }}
            >
              Please create an email to preview.
            </Flex>
          )}

          <Flex justifyContent={'center'}>
            <Button onClick={handleClose}>Close</Button>
          </Flex>
        </LoaderWrapper>
      </ModalBody>
    </ModalV2>
  );
};

export default EmailBlockPreview;