/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import BeePlugin from '@mailupinc/bee-plugin';
import { beeAuth, getEmailTemplate } from '../../services/campaign';
import { postEmailTemplates } from '../../services/campaign';
import { Button, Input, LoaderWrapper, ModalBody, ModalV2 } from '../../components';
import { Flex } from '@rebass/grid';
import { themesData } from '../../utils';

import axios from 'axios';
import BeeHeader from '../../components/RichTextEditor/BeeHeader';
import ModalContainer from 'react-modal-promise';
import { lambdaEndpoints, thumbnailEndpoints } from '../../services/helpers';
import { NoContentTemplate } from '../../components/RichTextEditor/no-content-base';
import { processNoContentTemplate } from '../../components/RichTextEditor/BeeEditorHelper';


const EmailEditorContainer = ({
    userData
}) => {
    
    const history = useHistory();
    const { id } = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [modalLoader, toggleModalLoader] = useState(false);
    const [templateJson, setTemplateJson] = useState({});
    const [templateHtml, setTemplateHtml] = useState('');
    const [originalTemplate, setOriginalTemplate] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [OGTemplateName, setOGTemplateName] = useState('');
    const [mode, setMode] = useState('');
    const [createError, setCreateError] = useState('');
    const [pageLoader, togglePageLoader] = useState(true);
    const [beeInstance, setBeeInstance] = useState();
    const [loadHeader, setLoadHeader] = useState(false);
    const [isCascaded, setIsCascaded] = useState(false);

    useEffect(() => {
        const windowBodyClassList = document.body.classList;
            if (!windowBodyClassList.contains('campaign')) {
                windowBodyClassList.add('campaign');
            }
        
        return () => {
            windowBodyClassList.remove('campaign');
        };
    }, []);

    const loadEditor = template => {
        const DEFAULT_CONFIGURATION = {
            uid: get(userData, 'uid', ''),                   
            container: 'bee-plugin-container',
            language: 'en-US',
            autosave: true,
            roleHash: 'superAdminClout',
            mergeTags: [
                {name: 'First Name', value: '{$first_name}'}, 
                {name: 'Last Name', value: '{$last_name}'},
                {name: 'Full Name', value: '{$full_name}'},
                {name: 'Title', value: '{$title}'},
                {name: 'Email', value: '{$email}'}
            ],
            rowsConfiguration: {
                defaultRows: true,
                emptyRows: true,
                externalContentURLs: [
                    {
                        name: 'My Content',
                        value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=content_block&isCascaded=false`
                    },
                    {
                        name: 'My Disclaimer',
                        value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=disclaimer&isCascaded=false`
                    },
                    {
                        name: 'My Footers',
                        value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=footer&isCascaded=false`
                    }, 
                    {
                        name: 'My Headers',
                        value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=header&isCascaded=false`
                    }, 
                    {
                        name: 'My Signatures',
                        value: `${lambdaEndpoints.baseUrl}user-rows?uid=${get(userData, 'uid', '')}&category=signature&isCascaded=false`
                    } 
                ]
            },
            contentDefaults: {
                text: {
                    styles: {
                        color: '#000'
                    }
                }
            },
            onchange: msg => console.log('ONCHANGE:  ', msg),
            onLoad: () => setLoadHeader(true),
        };

        const beeEditor = new BeePlugin();
    
        beeAuth().then(res => {
            togglePageLoader(false);
            beeEditor.token = res.result.data;
            beeEditor.start({ ...DEFAULT_CONFIGURATION, onSave }, template);
            setBeeInstance(beeEditor);
            // return beeEditor.start({ ...DEFAULT_CONFIGURATION, onSave }, template);
        })
        .catch(err => {
            console.log(err)
        })
    }
    
    useEffect(() => {
        let mounted = true;
        (async () => {
            if (mounted) {
                // only try to update if we are subscribed (or mounted)
                if (id.includes('new')) {
                    setOriginalTemplate('no-content');
                    setMode('create');
                    let template = await processNoContentTemplate(NoContentTemplate);
                    loadEditor(template);
                } else {
                    getEmailTemplate(id).then(res => {
                        console.log('IS CASCADE - - - - - - ', get(res, 'result.data.templates.cascade_template_ref', ''));
                        if (get(res, 'result.success', false)) {
                            const template = JSON.parse(get(res, 'result.data.templates.template_json', {}));
                            setOriginalTemplate(get(res, 'result.data.templates.original_template', ''));
                            console.log(get(res, 'result.data.templates.original_template', ''));
                            console.log(get(res, 'result.data.templates.name', ''));
                            setTemplateName(get(res, 'result.data.templates.name', ''));
                            setOGTemplateName(get(res, 'result.data.templates.name', ''));
                            setTemplateJson(template);
                            setTemplateHtml(get(res, 'result.data.templates.template_html', ''));
                            // setMode('update');
                            setIsCascaded(get(res, 'result.data.templates.cascade_template_ref', '') !== '')
                            loadEditor(template);
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
            }
        })();
       
        return () => mounted = false;
    }, []);

    const onSave = (savedJson, savedHtml) => {
        console.log(savedJson);
        setTemplateHtml(savedHtml);
        setTemplateJson(savedJson);
        setOpenModal(true);
    }

    const createOrUpdateTemplate = updateTemplate => {
        console.log('UPDATE TEMPLATE - -- - - ', updateTemplate);
        setCreateError('');
        toggleModalLoader(true);
        let payload = {
            name: updateTemplate ? OGTemplateName : templateName,
            template_json: new File([templateJson], 'template.json'),
            template_html: new File([templateHtml], 'template.html'),
            original_template: originalTemplate,
        };

        if(updateTemplate) 
            payload.template_id = id;

            axios.post(`${thumbnailEndpoints.baseUrl}/image`, {
                html: templateHtml
            })
            .then(res => {
                payload.thumbnail_url = get(res, 'data.url', '');
                postEmailTemplates(payload)
                .then(res => {
                    if(get(res, 'result.success', false)) {
                        closeModalHandler();
                        history.push('/campaign/template');
                    }
                })
                .catch(err => {
                    toggleModalLoader(false)
                    if(get(err, 'response.data.result.error.code', null) === 3301) 
                        setCreateError('Template with same name already exists..please enter a different name');
                    else 
                        setCreateError('Oops!..something went wrong.. Try again.');
                })
            })
            .catch(err => {
                postEmailTemplates(payload)
                .then(res => {
                    if(get(res, 'result.success', false)) {
                        closeModalHandler();
                        history.push('/campaign/template');
                    }
                })
                .catch(err => {
                    toggleModalLoader(false)
                    if(get(err, 'response.data.result.error.code', null) === 3301) 
                        setCreateError('Template with same name already exists..please enter a different name');
                    else 
                        setCreateError('Oops!..something went wrong.. Try again.');
                })
            });

    }

    const closeModalHandler = () => {
        setOpenModal(false);
        toggleModalLoader(false);
        setCreateError('');
        setMode(id.includes('new') ? 'create' : '');
    }

    const getModalHeading = () => {
        if (mode === 'create' || isCascaded) {
            return  'Create Template';
        }
        else 
            return 'Do you want to update the selected template?';
    }

    return (
        <>  
            {pageLoader ? (
                <LoaderWrapper isLoading={pageLoader || true} styles={{height: '90vh'}} />
            ) : (
                <Flex flexDirection='column' width="100%">
                    <BeeHeader
                        beeEditorInstance={beeInstance}
                        enable={loadHeader}
                        oldView
                        backHandler={() => history.push('/campaign/template')}
                    />
                    <div id='bee-plugin-container' style={{width: '100%', height: '90vh'}} />
                </Flex>
            )}
            <ModalContainer />
            {/* <FooterBtnsWrapper>
                <ButtonNoBorderBackground
                    color="#000000"
                    onClick={() => history.push('/campaign/template')}
                    style={{ margin: '5px' }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    &nbsp; Back &nbsp;
                </ButtonNoBorderBackground>
            </FooterBtnsWrapper> */}
            {openModal && (
                <ModalV2 width="40%" bgColor="#fff" isOpen={openModal} onRequestClose={closeModalHandler}>
                    <ModalBody>
                        <Flex width="100%" justifyContent="center" fontSize="18px" color={themesData.black}>
                            {getModalHeading()}
                        </Flex>
                        {(mode === 'create' || isCascaded) && (
                            <Flex width="100%" mt="15px" justifyContent="center" fontSize="18px" color={themesData.black}>
                                <Input outerWidth="100%" name="templateName" value={templateName} onChange={(e) => setTemplateName(e.target.value)}/>
                            </Flex>
                        )}
                        {createError && (
                            <div style={{fontSize: '11px', color: '#6351ed', paddingTop: '10px'}}>*{createError}</div>
                        )}
                        <Flex width="100%" justifyContent="center" fontSize="18px" color={themesData.black}>
                           <LoaderWrapper isLoading={modalLoader}>
                                <Button 
                                    margin={createError ? "10px 0px 0px" : '20px 0px 0px'} 
                                    disabled={mode === 'create' ? !templateName : false}
                                    onClick={() => {
                                    if(!mode && !isCascaded)
                                        createOrUpdateTemplate('update')
                                    else
                                        createOrUpdateTemplate();
                                    }}
                                >
                                    {(!mode && !isCascaded) ? 'Yes' : 'Save'}
                                </Button>
                                <Button 
                                    margin={createError ? '10px 10px 0px' : '20px 10px 0px'} 
                                    onClick={() => {
                                    if(!mode){
                                        setMode('create');
                                        setCreateError('');
                                    }
                                    else 
                                        closeModalHandler();
                                    }}
                                >
                                    {!mode ? 'No' : 'Cancel'}
                                </Button>
                           </LoaderWrapper>
                        </Flex>
                    </ModalBody>
                </ModalV2>
            )}
        </>
    )
}

const mapStateToProps = state => ({
    userData: state.user.data
})

export default connect(mapStateToProps, null)(EmailEditorContainer);
