import moment from 'moment';
import { get, isEmpty, toLower } from 'lodash';
import apiInstance from '../api';
import { formatMomentIsoDate } from '../../utils';
import { objectToFormData } from '../helpers';

export const getAnalyticDataByRepType = ({
  repType,
  startDate,
  endDate,
  channelId,
  tabContentType,
  noChannelId,
  uid,
  engagement_id,
  email_id,
  contact_list,
  beforeData,
  afterData,
  afterToken,
  type,
  leadType,
  limit,
  lead_source,
  lead_type,
  frequencyParam,
  targetParam,
  typeParam,
  platformParam,
  contactParam,
  campaignsParam
}) => {
  console.log('API CALLED....', beforeData);
  console.log('CONTACT::', contactParam);
  let reqUrl;
  const newLimit = limit || '900';
  const currentMomentDate = moment();
  const toDate =
    repType === 'mio_score' ? formatMomentIsoDate(currentMomentDate) : endDate;
  const fromDate =
    repType === 'mio_score'
      ? formatMomentIsoDate(currentMomentDate.subtract(30, 'd'))
      : startDate;

  let filter = '';
  console.log('limit', limit);
  var frequencyArr = [],
    targetArr = [],
    typeArr = [],
    platformArr = [],
    contactArr = [],
    campaignsArr = [];
  if (frequencyParam) frequencyArr = frequencyParam;
  if (targetParam) targetArr = targetParam;
  if (typeParam) typeArr = typeParam;
  if (platformParam) platformArr = platformParam;
  if (contactParam) contactArr = contactParam;
  if (campaignsParam) campaignsArr = campaignsParam;

  // var queryString = `, "frequency":${JSON.stringify(
  //   frequencyArr
  // )}, "target": ${JSON.stringify(targetArr)}, "platform": ${JSON.stringify(
  //   platformArr
  // )}, "contact_list": ${JSON.stringify(contactArr)}}`;
  var queryString = `, "contact_list": ${JSON.stringify(contactArr)}, "campaigns": ${JSON.stringify(campaignsArr)}}`;

  if (engagement_id && !type) {
    filter = `{"engagement_id":${JSON.stringify(
      engagement_id
    )}, "limit": "${newLimit}"`;
  } else if (engagement_id && repType !== 'newsletter_share_by_articles') {
    filter = `{"engagement_id":${JSON.stringify(
      engagement_id
    )}, "limit": ${newLimit}`;
  } else if (
    engagement_id &&
    repType === 'newsletter_share_by_articles' &&
    !type
  ) {
    filter = `{"engagement_id":${JSON.stringify(
      engagement_id
    )}, "limit": ${newLimit}`;
  } else if (
    engagement_id &&
    repType === 'newsletter_share_by_articles' &&
    type
  ) {
    filter = `{"engagement_id":${JSON.stringify(
      engagement_id
    )}, "type": "${type}", "limit": ${newLimit}`;
  } else if (email_id && lead_type === 'referral' && lead_source === 'em') {
    filter = `{"email_id":"${email_id}", "limit": ${newLimit}, "lead_type": "referral"`;
  } else if (email_id) {
    if (lead_source) {
      filter = `{"email_id":"${email_id}", "limit": ${newLimit}, "lead_source": "${lead_source}", "lead_type": "leadgen"`;
    } else {
      filter = `{"email_id":"${email_id}", "limit": ${newLimit}`;
    }
  } else if (repType === 'email_share_by_leads') {
    filter = `{"limit": ${newLimit}`;
  } else if (repType === 'newsletter_share_by_articles') {
    filter = `{"limit": ${newLimit}`;
  } else if (engagement_id && type) {
    filter = `{"engagement_id":${JSON.stringify(
      engagement_id
    )}, "type": "${type}", "limit": ${newLimit}`;
  } else if (repType === 'leadgen_data' || repType === 'leadgen_data_member') {
    filter = `{"type": "${leadType}", "limit": ${newLimit}`;
  } else if (
    noChannelId ||
    ['top_members', 'topic_analysis'].includes(repType) ||
    ['Members'].includes(tabContentType)
  ) {
    filter = `{"limit": ${newLimit}`; //new filterparams
  } else if (['Impact'].includes(tabContentType)) {
    filter = `{"uids":["${uid}"]`;
  } else {
    filter = `{"limit": ${newLimit}, "channel_id":"${channelId}"`; //new filterparams
  }

  filter += queryString;

  if (beforeData) {
    reqUrl = `analytics/${fromDate}/${toDate}?rep_type=${repType}&filter=${filter}&before=${beforeData}`;
  } else if (afterData) {
    reqUrl = `analytics/${fromDate}/${toDate}?rep_type=${repType}&filter=${filter}&after=${afterData}`;
  } else if (afterToken) {
    reqUrl = `analytics/${fromDate}/${toDate}?rep_type=${repType}&filter=${filter}&after=${afterToken}`;
  } else {
    reqUrl = `analytics/${fromDate}/${toDate}?rep_type=${repType}&filter=${filter}`;
  }

  return apiInstance().get(reqUrl);
};

export const getDownloadCsvData = ({
  repType,
  startDate,
  endDate,
  engagement_id,
  email_id,
  contact_list,
  filters,
}) => {
  let reqUrl;
  const currentMomentDate = moment();
  const toDate =
    repType === 'mio_score' ? formatMomentIsoDate(currentMomentDate) : endDate;
  const fromDate =
    repType === 'mio_score'
      ? formatMomentIsoDate(currentMomentDate.subtract(30, 'd'))
      : startDate;

  console.log('FILTERS::', filters);

  var frequencyArr = [],
    targetArr = [],
    typeArr = [],
    platformArr = [],
    contactArr = [],
    campaignsArr = [];
  if (filters) {
    if (filters.frequencyParam) frequencyArr = filters.frequencyParam;
    if (filters.targetParam) targetArr = filters.targetParam;
    if (filters.typeParam) typeArr = filters.typeParam;
    if (filters.platformParam) platformArr = filters.platformParam;
    if (filters.contactParam) contactArr = filters.contactParam;
    if (filters.campaignsParam) campaignsArr = filters.campaignsParam;
  }

  // var queryString = `, "frequency":${JSON.stringify(
  //   frequencyArr
  // )}, "target": ${JSON.stringify(targetArr)}, "platform": ${JSON.stringify(
  //   platformArr
  // )}, "contact_list": ${JSON.stringify(contactArr)}}`;
  var queryString = `, "contact_list": ${JSON.stringify(contactArr)}, "campaigns": ${JSON.stringify(campaignsArr)}}`;

  let filter = '{}';
  if (engagement_id && isEmpty(contact_list)) {
    filter = `{"engagement_id":${JSON.stringify(
      engagement_id
    )}, "limit": "5000"`;
  } else if (engagement_id && !isEmpty(contact_list)) {
    filter = `{"engagement_id":${JSON.stringify(
      engagement_id
    )}, "contact_list":${JSON.stringify(contact_list)}, "limit": "5000"`;
  } else if (email_id) {
    filter = `{"email_id":"${email_id}", "limit": "5000"`;
  } else if (repType === 'email_share_by_leads' && !isEmpty(contact_list)) {
    filter = `{"contact_list":${JSON.stringify(contact_list)}, "limit": "5000"`;
  } else {
    filter = `{"limit": "5000"`;
  }
  filter += queryString;

  reqUrl = `analytics/${fromDate}/${toDate}?rep_type=${repType}&filter=${filter}&download=true`;

  return apiInstance().get(reqUrl);
};

export const getUsersList = (type) => {
  return apiInstance().get(`/company/users?filter={"type":"${type}"}`);
};

export const getAllSubscribes = (channelId) => {
  return apiInstance()
    .get(`/channel/${channelId}/subscribers/?status_filter=all`)
    .then((response) => {
      const invitedUsers = [];
      get(response, 'result.data', []).forEach((user) => {
        if (toLower(user.status) === 'applied') {
          invitedUsers.push({
            id: user.id,
            name: `${get(user, 'details.name.first')} ${get(
              user,
              'details.name.last'
            )}`,
            email: get(user, 'details.email'),
          });
        }
      });
      return invitedUsers;
    });
};

export const postUpdateRequesteduserStatus = ({
  channelId,
  status,
  userId,
}) => {
  const fData = objectToFormData({ status, userId });
  return apiInstance().post(
    `/channel/${channelId}/update/subscription/?`,
    fData
  );
};

export const downloadPdf = ({
  startDate,
  endDate,
  channelId,
  tabContentType,
  userId,
  asAdvisor,
}) => {
  let rep_type = asAdvisor ? 'user_impact_pdf' : 'impact_pdf';
  let filter;
  if (tabContentType) {
    if (tabContentType === 'Content') {
      filter = JSON.stringify({ channel_id: channelId, generate_pdf: true });
    } else if (tabContentType === 'Impact') {
      filter = `{"uids":["${userId}"],"generate_pdf":true}`;
    } else {
      filter = JSON.stringify({ generate_pdf: true });
    }
  } else {
    filter = JSON.stringify({ generate_pdf: true });
  }

  // return axios
  //   .get('http://52.14.191.68/export/pdf', {
  //     responseType: 'blob',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${window.sessionStorage.getItem('token')}`,
  //     },
  //   })
  //   .then((respose) => {
  //     saveFile(respose.data, 'impact_summary', 'pdf');
  //   });
  return apiInstance().get(
    `analytics/${startDate}/${endDate}?rep_type=${rep_type}&filter=${filter}`
  );
};

export const getImpactDetails = ({ startDate, endDate, channelId }) => {
  const filter = channelId ? { channel_id: channelId } : {};
  return apiInstance().get(
    `analytics/${startDate}/${endDate}?rep_type=impact_pdf&filter=${JSON.stringify(
      filter
    )}`
  );
};

export const getLeadReferalData = ({
  startDate,
  endDate,
  frequencyParam,
  targetParam,
  typeParam,
  platformParam,
  contactParam,
  campaignsParam
}) => {
  var frequencyArr = [],
    targetArr = [],
    typeArr = [],
    platformArr = [],
    contactArr = [],
    campaignsArr = [];
  if (frequencyParam) frequencyArr = frequencyParam;
  if (targetParam) targetArr = targetParam;
  if (typeParam) typeArr = typeParam;
  if (platformParam) platformArr = platformParam;
  if (contactParam) contactArr = contactParam;
  if (campaignsParam) campaignsArr = campaignsParam;

  // var queryString = `, "frequency":${JSON.stringify(
  //   frequencyArr
  // )}, "target": ${JSON.stringify(targetArr)}, "type": ${JSON.stringify(
  //   typeArr
  // )}, "platform": ${JSON.stringify(
  //   platformArr
  // )}, "contact_list": ${JSON.stringify(contactArr)}}`;
  var queryString = `, "contact_list": ${JSON.stringify(contactArr)}, "campaigns": ${JSON.stringify(campaignsArr)}}`;

  return apiInstance().get(
    `analytics/${startDate}/${endDate}?rep_type=email_share_by_leads&filter={"limit":900, "leads_referrals":"true", "lead_limit":900` +
      queryString
  );
};

export const getCampaignAnalytics = () => {
  return apiInstance().get(`campaign/filter`)
  // return apiInstance().get(`https://apibeta.myclout.com/v1.3/campaign/filter`)
}

export const exportToCsv = ({
  repType,
  startDate,
  endDate,
  engagement_id,
  email_id,
  contact_list,
  filters,
  user_emailids
}) => {
  let reqUrl;
  const currentMomentDate = moment();
  const toDate =
    repType === 'mio_score' ? formatMomentIsoDate(currentMomentDate) : endDate;
  const fromDate =
    repType === 'mio_score'
      ? formatMomentIsoDate(currentMomentDate.subtract(30, 'd'))
      : startDate;

  console.log('FILTERS::', filters);

  var frequencyArr = [],
    targetArr = [],
    typeArr = [],
    platformArr = [],
    contactArr = [],
    campaignsArr = [];
  if (filters) {
    if (filters.frequencyParam) frequencyArr = filters.frequencyParam;
    if (filters.targetParam) targetArr = filters.targetParam;
    if (filters.typeParam) typeArr = filters.typeParam;
    if (filters.platformParam) platformArr = filters.platformParam;
    if (filters.contactParam) contactArr = filters.contactParam;
    if (filters.campaignsParam) campaignsArr = filters.campaignsParam;
  }

  // var queryString = `, "frequency":${JSON.stringify(
  //   frequencyArr
  // )}, "target": ${JSON.stringify(targetArr)}, "platform": ${JSON.stringify(
  //   platformArr
  // )}, "contact_list": ${JSON.stringify(contactArr)}}`;
  var queryString = `, "contact_list": ${JSON.stringify(contactArr)}, "campaigns": ${JSON.stringify(campaignsArr)}}`;

  let filter = '{}';
  if (engagement_id && isEmpty(contact_list)) {
    filter = `{"engagement_id":${JSON.stringify(
      engagement_id
    )}, "limit": "5000"`;
  } else if (engagement_id && !isEmpty(contact_list)) {
    filter = `{"engagement_id":${JSON.stringify(
      engagement_id
    )}, "contact_list":${JSON.stringify(contact_list)}, "limit": "5000"`;
  } else if (email_id) {
    filter = `{"email_id":"${email_id}", "limit": "5000"`;
  } else if (repType === 'email_share_by_leads' && !isEmpty(contact_list)) {
    filter = `{"contact_list":${JSON.stringify(contact_list)}, "limit": "5000"`;
  } else {
    filter = `{"limit": "5000"`;
  }
  filter += queryString;

  reqUrl = `analytics/${fromDate}/${toDate}?rep_type=${repType}&filter=${filter}&download=true&email_ids=${user_emailids}`;
  return apiInstance().put(reqUrl)
}
