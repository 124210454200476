/* eslint-disable react-hooks/exhaustive-deps */
import {
  faChevronDown,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleButton } from '../../../containers/ConfigureContainer/screenConfigure.styles';
import { Flex, Box } from '@rebass/grid';
import { get, isEmpty, map, filter, orderBy, keys } from 'lodash';
import React, { useState, useEffect, Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { createCampaign, postCascadeCampaign,  getWebinarDetails,getWebinarList, updateCampaign, getCascadeInfoEnterpriseLevelAdmin, postCampaignCascadeEnterpriseLevel } from '../../../services/campaign';
import { getCRMContactList } from '../../../services/configure';
import { getCRMArticles } from '../../../services/dashboard';
import { connect } from 'react-redux';
import { getUserRole } from '../../../redux/selector';

import { FilterRowChips } from '../../DripCampaignContainer/DripDashboardFilter/DripDashboardFilterPopup/DripDashboardFilterPopup.styles';
import {
  RecipientsListBody,
  RecipientsListWrapper,
  SetObjectivesInput,
  WebinarWrapper,
} from '../../DripCampaignContainer/SetObjectives/SetObjectives.styles';
import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import {
  FooterBtnsWrapper,
  RadioInput,
  RadioInputLabel,
  ZoomContainer,
} from '../DripCampaignContainerV2.styles';
import { IconDiv } from '../../DashboardContainer/DashboardContainer.styles';
import ConfirmProceedModal from '../ConfirmProceedModal/ConfirmProceedModal';
import { ErrorContainer, LoaderWrapper } from '../../../components';
import {
  TabFour,
  TabsThree,
  TabListThree,
} from '../../../components/Tabs/TabsTypeThree';
import moment from 'moment';
import { getSegments } from '../../../services/segment';
import CascadeModalEnterpriseAdmin from '../CascadeModalEnterpriseAdmin/CascadeModalEnterpriseAdmin';
import CampaignNavigation from '../CampaignNavigation/CampaignNavigation';
import ReactSelect from 'react-select';

const MemberModal = React.lazy(() => import('../MemberModal/MemberModal'));

const SetObjectives = ({
  createMode,
  contactGroups,
  setCreateFlow,
  setCreateTabs,
  socialNetworkData,
  campaignSetState,
  campaignCreateFlow,
  setObjectivesTabData,
  getAccountId,
  campaignId,
  endCreateFlow,
  curFlow,
  handleNext,
  handleBack,
  isEdit,
  isFromAddCampaign,
  series,
  seriesId,
  loading,
  setLoading,
  campaignStatus,
  complianceApplicable,
  campaignScheduleTypes,
  campaignScheduleTimings,
  campaignLeadReferralData,
  campaignSummaries,
  campaignSelectedArticles,
  isAdvisorApprovalMode,
  checkboxStatus,
  isadvMode,
  setConListRecArticlesLoader,
  membersList,
  isAdmin,
  campaignsList,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  firmsArray,
  segmentList,
  setSegmentList,
  stepList,
  selectedIndex,
  userData,
  subscriptionType
}) => {
  console.log('IS ADMIN - - -', contactGroups);
  const history = useHistory();
  const [target, setTarget] = useState('prospects');
  const [ccList, setCCList] = useState('');
  const [subject, setSubject] = useState('');
  const [selectedEmailContacts, setSelectedEmailContacts] = useState([]);
  const [selectedFbContacts, setSelectedFbContacts] = useState([]);
  const [selectedLnContacts, setSelectedLnContacts] = useState([]);
  const [selectedTwContacts, setSelectedTwContacts] = useState([]);
  const [campaignName, setCampaignName] = useState('Untitled');
  const [purpose, setPurpose] = useState('');
  const [webinar, setWebinar] = useState(false);

  const [selectedWebinar, setSelectedWebinar] = useState('');
  const [webinarDetailsLoader, setWebinarDetailsLoader] = useState(false);
  const [webinarListLoader, setWebinarListLoader] = useState(false);
  const [webinar_registrants, setWebinarRegistrants] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [modalLoader, toggleModalLoader] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [OGSelectedAdvisors, setOGSelectedAdvisors] = useState([]);
  const [cascadeOption, setCascadeOption] = useState('advisors');
  const [fetchingCascadeInfo, toggleFetchingCascadeInfo] = useState(false);
  const [shareDisabled, toggleShareDisabled] = useState(false);
  const [campaignSeriesName, setCampaignSeriesName] = useState('Untitled');
  const [seriesPurpose, setSeriesPurpose] = useState('');
  const [ascEmailOrder, setAscEmailOrder] = useState(false);
  const [ascFbOrder, setAscFbOrder] = useState(false);
  const [ascLnOrder, setAscLnOrder] = useState(false);
  const [listType, setListType] = useState('all');
  const [emailList, setEmailList] = useState([]);
  const [approveToShare, setApproveToShare] = useState('false');
  const [proceed, toggleProceed] = useState(false);
  const [confirmProceedModal, toggleConfirmProceedModal] = useState(false);
  const [seriesStatus, setSeriesStatus] = useState('draft');
  const [webinarDetails, setWebinarDetails] = useState({});

  const [segmentTabActive, setSegmentTabActive] = useState(false);
  const [selectedSegmentList, setSelectedSegmentList] = useState([]);
  const [segmentListLoader, setSegmentListLoader] = useState(false);
  //     {
  //     id: 1,
  //     name: 'Webinar name1',
  //     details: {
  //       selection: 'Webinars 1',
  //       date:'',
  //       time:'',
  //       description: 'Meet Todd!',
  //       currentRegistrants: '50 Confirmed',
  //       availableRegistrants: '100 Left'
  //     }
  //  });
  const [webinarList, setWebinarList] = useState([]);
  const [webinarErrorText, setWebinarErrorText] = useState('');
  const [subscriptionTypeOptions, setSubscriptionTypeOptions] = useState([]);
  const [selectSubscriptionType, setSelectSubscriptionType] = useState();
  //     [{
  //     id: 1,
  //     name: 'Webinar name1',
  //     details: {
  //       selection: 'Webinars 1',
  //       date:'dd/mm/yyyy',
  //       time:'h:m:s',
  //       description: 'Meet Todd!',
  //       currentRegistrants: '50 Confirmed',
  //       availableRegistrants: '100 Left'
  //     }
  //  },
  //  {
  //     id: 2,
  //     name: 'Webinar name2',
  //     details: {
  //       selection: 'Webinars ',
  //       date:'dd/mm/yyyy',
  //       time:'h:m:s',
  //       description: 'Meet Arteta!',
  //       currentRegistrants: '70 Confirmed',
  //       availableRegistrants: '120 Left'
  //     }
  //  },]);
  const mappingDropdownValues = {
    general_newsletters: 'General Newsletters',
    ecards: 'Ecards',
    events: 'Events',
    firm_updates: 'Firm Updates',
    financial_and_economic_newsletters: 'Financial and Economic Insight Newsletters',
    general_marketing: 'All Digital Marketing Communications'
  }

  const getNewOptionValue = (value) =>
    value ? value : { label: '', value: '' };

  const getSelectStyles = () => ({
    container: (base) => ({
      ...base,
      width: '100%',
    }),
    control: (base) => ({
      ...base,
      height: '32px',
      minHeight: '32px',
      border: '1px solid #8493A6',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      color: '#848484',
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
  });

  const handleRadioButtonClick = (id) => {
    setWebinar(true);
    setWebinarDetailsLoader(true);
    setSelectedWebinar(id);
    // const webinar = webinarList.filter(webinar => webinar.id === id);
    getWebinarDetails({ id })
      .then((response) => {
        setWebinarDetailsLoader(false);
        if (response.result.success) {
          let responseData = get(response, 'result.data', {});
          let formattedDate = moment(
            get(response, 'result.data.start_date', ''),
            'yyyy-MM-DD'
          ).format('MM-DD-yyyy');
          formattedDate = formattedDate.split('-').join('/');
          responseData.start_date = formattedDate;
          setWebinarDetails(responseData);
        }
      })
      .catch((err) => {
        setWebinarDetailsLoader(false);
        console.log(err.message);
      });
  };

  useEffect(() => {
    let subscriptionOptions = [...subscriptionTypeOptions];
    if ((get(userData, 'details.company.marketing_pref_settings', {}))) {
      if (get(userData, 'details.company.marketing_pref_settings.general_newsletters', 'false') === 'true') {
        subscriptionOptions.push({
          value: 'general_newsletters',
          label: 'General Newsletters'
        })
      }
      if (get(userData, 'details.company.marketing_pref_settings.financial_and_economic_newsletters', 'false') === 'true') {
        subscriptionOptions.push({
          value: 'financial_and_economic_newsletters',
          label: 'Financial and Economic Insight Newsletters'
        })
      }
      if (get(userData, 'details.company.marketing_pref_settings.firm_updates', 'false') === 'true') {
        subscriptionOptions.push({
          value: 'firm_updates',
          label: 'Firm Updates'
        })
      }
      if (get(userData, 'details.company.marketing_pref_settings.ecards', 'false') === 'true') {
        subscriptionOptions.push({
          value: 'ecards',
          label: 'Ecards'
        })
      }
      if (get(userData, 'details.company.marketing_pref_settings.events', 'false') === 'true') {
        subscriptionOptions.push({
          value: 'events',
          label: 'Events'
        })
      }
      subscriptionOptions.push({
        value: 'general_marketing',
        label: 'All Digital Marketing Communications'
      });
      setSubscriptionTypeOptions(subscriptionOptions);
    }
  }, [userData]);

  useEffect(() => {
    if (!isEmpty(setObjectivesTabData?.webinar?.wuid)) {
      handleWebinarToggle(true);
    }
    if(subscriptionType) {
      setSelectSubscriptionType({
        value: subscriptionType,
        label: mappingDropdownValues[subscriptionType]
      })
    } 
  }, []);

  useEffect(() => {
    setEmailList(contactGroups);
    // let customContactsCopy = [];

    // if (listType === 'all') {
    //   let defaultList = filter(contactGroups, function (o) {
    //     return o.contact_type === 'default';
    //   });
    //   // // console.log('defaultList', defaultList);
    //   if (!isEmpty(defaultList)) {
    //     if (ascEmailOrder) {
    //       defaultList = orderBy(
    //         defaultList,
    //         [(user) => user.name.toLowerCase()],
    //         ['asc']
    //       );
    //     }

    //     defaultList.unshift({
    //       description: 'group_header',
    //       external_uuid: '-1',
    //       id: '-1',
    //       name: 'Others',
    //       type: 'default',
    //       checked: defaultList.every((item) => item.checked),
    //     });

    //     customContactsCopy = customContactsCopy.concat(defaultList);
    //   }
    // }

    // if (listType === 'all' || listType === 'prospect') {
    //   let prospectList = filter(contactGroups, function (o) {
    //     return o.contact_type === 'prospect';
    //   });
    //   // // console.log('prospectList', prospectList);

    //   if (!isEmpty(prospectList)) {
    //     if (ascEmailOrder) {
    //       prospectList = orderBy(
    //         prospectList,
    //         [(user) => user.name.toLowerCase()],
    //         ['asc']
    //       );
    //     }

    //     prospectList.unshift({
    //       description: 'group_header',
    //       external_uuid: '-1',
    //       id: '-1',
    //       name: 'Prospects',
    //       type: 'prospect',
    //       checked: prospectList.every((item) => item.checked),
    //     });

    //     customContactsCopy = customContactsCopy.concat(prospectList);
    //   }
    // }

    // if (listType === 'all' || listType === 'client') {
    //   let clientList = filter(contactGroups, function (o) {
    //     return o.contact_type === 'client';
    //   });
    //   // // console.log('clientList', clientList);

    //   if (!isEmpty(clientList)) {
    //     if (ascEmailOrder) {
    //       clientList = orderBy(
    //         clientList,
    //         [(user) => user.name.toLowerCase()],
    //         ['asc']
    //       );
    //     }

    //     clientList.unshift({
    //       description: 'group_header',
    //       external_uuid: '-1',
    //       id: '-1',
    //       name: 'Clients',
    //       type: 'client',
    //       checked: clientList.every((item) => item.checked),
    //     });

    //     customContactsCopy = customContactsCopy.concat(clientList);
    //   }
    // }
    // setEmailList(customContactsCopy.filter((item) => item));
  }, [contactGroups]);

  // useEffect(() => {
  //   setSegmentList(segmentList)
  // }, [segmentList, selectedSegmentList])

  useEffect(() => {
    if (campaignCreateFlow && !isEmpty(setObjectivesTabData)) {
      setTarget(get(setObjectivesTabData, 'target', 'prospects'));
      setSelectedEmailContacts(get(setObjectivesTabData, 'emList', []));
      setSelectedFbContacts(get(setObjectivesTabData, 'fbList', []));
      setSelectedLnContacts(get(setObjectivesTabData, 'lnList', []));
      setSelectedTwContacts(get(setObjectivesTabData, 'twList', []));
      setCampaignName(get(setObjectivesTabData, 'name', ''));
      setPurpose(get(setObjectivesTabData, 'purpose', ''));
      setSubject(get(setObjectivesTabData, 'subject', ''));
      setSelectedSegmentList(get(setObjectivesTabData, 'segmentList', []));
      if(!isEmpty(get(setObjectivesTabData, 'segmentList', []))) {
        setSegmentTabActive(true);
      }
      if (createMode === 'series') {
        setCampaignSeriesName(get(setObjectivesTabData, 'seriesName', 'abcs'));
        setSeriesPurpose(get(setObjectivesTabData, 'seriesPurpose', ''));
      }
      const ccListArray = get(setObjectivesTabData, 'ccList', []);
      if (!isEmpty(ccListArray)) setCCList(ccListArray.join(','));
      if (
        get(setObjectivesTabData, 'webinar.wuid', null) &&
        get(setObjectivesTabData, 'webinar.isWebinar', false)
      ) {
        handleRadioButtonClick(get(setObjectivesTabData, 'webinar.wuid', null));
      }
    }

    if (!isEmpty(setObjectivesTabData)) {
      setApproveToShare(get(setObjectivesTabData, 'approvedToShare', 'false'));
      setSelectedAdvisors(get(setObjectivesTabData, 'selectedAdvisors', []));
      setOGSelectedAdvisors(get(setObjectivesTabData, 'selectedAdvisors', []));
    }

    if (seriesId) {
      const campaignSeries = campaignsList.find(
        (campaign) => campaign.series_id === seriesId
      );
      if (
        campaignSeries ||
        get(setObjectivesTabData, 'campaignSeriesStatus', 'draft')
      )
        setSeriesStatus(
          campaignSeries
            ? campaignSeries.status
            : get(setObjectivesTabData, 'campaignSeriesStatus', 'draft')
        );
    }
  }, []);

  const handleCheckboxClick = (item, type) => {
    if (type === 'em') {
      if (isEmpty(selectedEmailContacts)) {
        setSelectedEmailContacts([...selectedEmailContacts, item]);
      } else if (
        !isEmpty(selectedEmailContacts) &&
        isEmpty(selectedEmailContacts.filter((s) => s.id === item.id))
      ) {
        setSelectedEmailContacts([...selectedEmailContacts, item]);
      } else {
        let removeItems = selectedEmailContacts.filter((s) => s.id !== item.id);
        setSelectedEmailContacts(removeItems);
      }
    } else if (type === 'ln') {
      if (isEmpty(selectedLnContacts)) {
        setSelectedLnContacts([...selectedLnContacts, item]);
      } else if (
        !isEmpty(selectedLnContacts) &&
        isEmpty(selectedLnContacts.filter((s) => s === item))
      ) {
        setSelectedLnContacts([...selectedLnContacts, item]);
      } else {
        let removeItems = selectedLnContacts.filter((s) => s !== item);
        setSelectedLnContacts(removeItems);
      }
    } else if (type === 'tw') {
      if (isEmpty(selectedTwContacts)) {
        setSelectedTwContacts([...selectedTwContacts, item]);
      } else if (
        !isEmpty(selectedTwContacts) &&
        isEmpty(selectedTwContacts.filter((s) => s === item))
      ) {
        setSelectedTwContacts([...selectedTwContacts, item]);
      } else {
        let removeItems = selectedTwContacts.filter((s) => s !== item);
        setSelectedTwContacts(removeItems);
      }
    } else {
      if (isEmpty(selectedFbContacts)) {
        setSelectedFbContacts([...selectedFbContacts, item]);
      } else if (
        !isEmpty(selectedFbContacts) &&
        isEmpty(selectedFbContacts.filter((s) => s === item))
      ) {
        setSelectedFbContacts([...selectedFbContacts, item]);
      } else {
        let removeItems = selectedFbContacts.filter((s) => s !== item);
        setSelectedFbContacts(removeItems);
      }
    }
  };

  const handleShareToggle = (checked) => {
    if (checked) setApproveToShare('true');
    else {
      setSelectedAdvisors(OGSelectedAdvisors);
      setApproveToShare('false');
    }
  };

  const shareValidationCheck = () => {
    // // console.log('SELECTED ADVISORS - - -- ', selectedAdvisors);
    // // console.log('IS SAME = = = =', selectedAdvisors.filter(adv => adv !== 'selectAll').length === OGSelectedAdvisors.length)
    let disableShare = false;
    // if (selectedAdvisors.includes('selectAll'))
    //   disableShare = selectedAdvisors.filter(adv => adv !== 'selectAll').length === OGSelectedAdvisors.length;
    // else if (selectedAdvisors.length !== OGSelectedAdvisors.length)
    //   disableShare = false;
    // else
    //   disableShare = selectedAdvisors.every(adv => OGSelectedAdvisors.includes(adv));
    return disableShare;
  };

  const handleAdvisorShare = () => {
    setIsOpen(false);
    setOGSelectedAdvisors(selectedAdvisors);
  };

  const fetchRecommendedArticles = () => {
    setConListRecArticlesLoader(true);
    getCRMContactList()
      .then((response) => {
        const contactList = response.result.data
          ? Object.keys(response.result.data).map((key) => {
              const recommendedThemesArray = response.result.data[key]
                .recommended_themes
                ? Object.keys(
                    response.result.data[key].recommended_themes.themes
                  ).map(
                    (theme) =>
                      response.result.data[key].recommended_themes.themes[theme]
                  )
                : [];
              const userThemesArray = response.result.data[key].user_themes
                ? Object.keys(response.result.data[key].user_themes.themes).map(
                    (theme) =>
                      response.result.data[key].user_themes.themes[theme]
                  )
                : [];
              return {
                id: key,
                name: response.result.data[key].name,
                themes:
                  userThemesArray &&
                  userThemesArray.length > 0 &&
                  userThemesArray[0]
                    ? userThemesArray
                    : recommendedThemesArray,
                recommendedThemes: recommendedThemesArray,
                userThemes: userThemesArray,
                disabledThemes: [],
              };
            })
          : [];
        fetchArticles(contactList);
      })
      .catch((err) => {
        setConListRecArticlesLoader(false);
        // console.log('ERROR - ', err);
      });
  };

  const getThemeIds = (contactListArray) => {
    let theme_ids;
    const contactListCopy = [...contactListArray];
    const selectedContactNames = selectedEmailContacts.filter(
      (ele) => ele.id !== '-1'
    );
    theme_ids = contactListCopy
      .filter((contact) =>
        selectedContactNames.some(({ id }) => id === contact.id)
      )
      .map((item) => item.themes)
      .flat()
      .map((theme) => (theme.id_hash ? theme.id_hash : []));
    return theme_ids;
  };

  const fetchArticles = (contactArray) => {
    const themesList = contactArray.length > 0 ? getThemeIds(contactArray) : [];
    getCRMArticles(themesList)
      .then((response) => {
        setConListRecArticlesLoader(false);
        if (response.result.success) {
          campaignSetState({
            recommendedFetchedData: response.result.data,
          });
        }
      })
      .catch((err) => {
        setConListRecArticlesLoader(false);
        // console.log(err);
      });
  };

  const handleNextClick = () => {
    if (
      approveToShare === 'true' &&
      selectedAdvisors.length > 0 &&
      campaignStatus &&
      campaignStatus !== 'draft' &&
      !proceed
    )
      toggleConfirmProceedModal(true);
    else createOrUpdateCampaign('next');
  };

  const handleSaveAndClose = () => {
    createOrUpdateCampaign('saveAndClose');
  };

  // const shareCampaignPayload = () => {
  //   let emSNid = !isEmpty(selectedEmailContacts)
  //     ? selectedEmailContacts
  //       .filter((ele) => ele.id !== '-1')
  //       .map((item, index) => {
  //         return {
  //           destination_id: item.id,
  //           snetwork_act_id: getAccountId('em'),
  //         };
  //       })
  //     : [];

  //   let twSNid = !isEmpty(selectedTwContacts)
  //     ? selectedTwContacts.map((item, index) => {
  //       return {
  //         destination_id: item,
  //         snetwork_act_id: getAccountId('tw'),
  //       };
  //     })
  //     : [];

  //   let fbSNid = !isEmpty(selectedFbContacts)
  //     ? selectedFbContacts.map((item, index) => {
  //       return {
  //         destination_id: item,
  //         snetwork_act_id: getAccountId('fb'),
  //       };
  //     })
  //     : [];

  //   let lnSNid = !isEmpty(selectedLnContacts)
  //     ? selectedLnContacts.map((item, index) => {
  //       return {
  //         destination_id: item,
  //         snetwork_act_id: getAccountId('ln', item),
  //       };
  //     })
  //     : [];

  //   let articles = !isEmpty(campaignSelectedArticles)
  //     ? campaignSelectedArticles.map((item, index) => {
  //       return {
  //         id: item.id,
  //         theme_id: item.themeId,
  //       };
  //     })
  //     : [];
  //   let payload = {
  //     posts: JSON.stringify({
  //       ...(curFlow !== 'socialOnly' && {
  //         email: {
  //           snetwork_act_id: emSNid.map((page) => page.destination_id),
  //           skip_compliance: !complianceApplicable,
  //           schedule: get(campaignScheduleTypes, 'em', ''),
  //           schedule_time: get(campaignScheduleTimings, 'em', {}),
  //           lead_gen: get(campaignLeadReferralData, 'em.lead_gen', false),
  //           referral: get(campaignLeadReferralData, 'em.referral', false),
  //         },
  //       }),
  //       ...(curFlow !== 'emailOnly' && {
  //         facebook: {
  //           articles: articles,
  //           snetwork_act_id: fbSNid.map((page) => page.destination_id),
  //           override_text: get(campaignSummaries, 'fb', ''),
  //           skip_compliance: !complianceApplicable,
  //           schedule: get(campaignScheduleTypes, 'fb', ''),
  //           schedule_time: get(campaignScheduleTimings, 'fb', {}),
  //           lead_gen: get(campaignLeadReferralData, 'fb.lead_gen', false),
  //           referral: get(campaignLeadReferralData, 'fb.referral', false),
  //         },
  //       }),
  //       ...(curFlow !== 'emailOnly' && {
  //         linkedin: {
  //           articles: articles,
  //           snetwork_act_id: lnSNid.map((page) => page.destination_id),
  //           override_text: get(campaignSummaries, 'ln', ''),
  //           skip_compliance: !complianceApplicable,
  //           schedule: get(campaignScheduleTypes, 'ln', ''),
  //           schedule_time: get(campaignScheduleTimings, 'ln', {}),
  //           lead_gen: get(campaignLeadReferralData, 'ln.lead_gen', false),
  //           referral: get(campaignLeadReferralData, 'ln.referral', false),
  //         },
  //       }),
  //       ...(curFlow !== 'emailOnly' && {
  //         twitter: {
  //           articles: articles,
  //           snetwork_act_id: twSNid.map((page) => page.destination_id),
  //           override_text: get(campaignSummaries, 'tw', ''),
  //           skip_compliance: !complianceApplicable,
  //           schedule: get(campaignScheduleTypes, 'tw', ''),
  //           schedule_time: get(campaignScheduleTimings, 'tw', {}),
  //           lead_gen: get(campaignLeadReferralData, 'tw.lead_gen', false),
  //           referral: get(campaignLeadReferralData, 'tw.referral', false),
  //         },
  //       }),
  //     }),
  //     ...(isadvMode && { advisor_approval: isAdvisorApprovalMode }),
  //     ...(curFlow !== 'emailOnly' && {
  //       checkbox_status: JSON.stringify(checkboxStatus),
  //     }),
  //   };

  //   return payload;
  // };

  const createOrUpdateCampaign = (mode) => {
    setLoading(true);
    let emLists = [],
      fbLists = [],
      twLists = [],
      lnLists = [],
      ccArray = [];

    if (ccList.length > 0)
      ccArray = ccList.split(',').map((email) => email.trim());
    emLists = !isEmpty(selectedEmailContacts)
      ? selectedEmailContacts
          .filter((ele) => ele.id !== '-1' && keys(ele).length > 0)
          .map((item, index) => {
            return {
              destination_id: item.id,
              snetwork_act_id: getAccountId('em'),
            };
          })
      : [];

    twLists = !isEmpty(selectedTwContacts)
      ? selectedTwContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('tw'),
          };
        })
      : [];

    fbLists = !isEmpty(selectedFbContacts)
      ? selectedFbContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('fb'),
          };
        })
      : [];

    lnLists = !isEmpty(selectedLnContacts)
      ? selectedLnContacts.map((item, index) => {
          return {
            destination_id: item,
            snetwork_act_id: getAccountId('ln', item),
          };
        })
      : [];
    console.log('SEGMENT LIST::', selectedSegmentList);
    let destination = {
      email: !segmentTabActive ? emLists : [],
      facebook: fbLists,
      twitter: twLists,
      linkedin: lnLists,
      segment: segmentTabActive && !isEmpty(selectedSegmentList) && selectedSegmentList.map(s => {
        return {
          destination_id: s.id
        }
      })
    };

    let webinarID = null;
    if (webinarDetails?.id) {
      webinarID = {
        webinar_id: encodeURIComponent(webinarDetails.id),
      };
    }
    const myNetwork =
      curFlow === 'emailOnly'
        ? !isEmpty(selectedEmailContacts.filter((ele) => ele.id !== '-1'))
        : curFlow === 'socialOnly'
        ? !(
            isEmpty(selectedFbContacts) &&
            isEmpty(selectedLnContacts) &&
            isEmpty(selectedTwContacts)
          )
        : !(
            isEmpty(selectedEmailContacts.filter((ele) => ele.id !== '-1')) &&
            isEmpty(selectedFbContacts) &&
            isEmpty(selectedLnContacts) &&
            isEmpty(selectedTwContacts)
          );

    let payload = {
      name: campaignName,
      webinar_registrants: webinar_registrants,
      ...(purpose && { purpose: purpose }),
      platforms: JSON.stringify({
        is_email: curFlow !== 'socialOnly',
        is_facebook:
          curFlow === 'emailOnly'
            ? false
            : myNetwork
            ? !isEmpty(selectedFbContacts)
            : true,
        is_linkedin:
          curFlow === 'emailOnly'
            ? false
            : myNetwork
            ? !isEmpty(selectedLnContacts)
            : true,
        is_twitter:
          curFlow === 'emailOnly'
            ? false
            : myNetwork
            ? !isEmpty(selectedTwContacts)
            : true,
      }),
      // target: target,
      // email_data: JSON.stringify({subject: subject}),
      destination: JSON.stringify(destination),
      ...webinarID,
      ...(createMode === 'series' &&
        !seriesId && {
          series: JSON.stringify({
            name: campaignSeriesName,
            purpose: seriesPurpose,
          }),
        }),
      ...(createMode === 'series' &&
        seriesId && {
          series: JSON.stringify({
            id: seriesId,
          }),
        }),
      ...(isFromAddCampaign && {
        series: JSON.stringify(series),
      }),
      // ...(curFlow!== 'socialOnly' && selectSubscriptionType && {
      //   subscription_type: selectSubscriptionType.value
      // })
    };

    console.log('PAYLOAD::', payload);

    let selectedEmailList = !isEmpty(selectedEmailContacts)
      ? selectedEmailContacts.filter(
          (ele) => ele.id !== '-1' && keys(ele).length > 0
        )
      : [];
    console.log('selectedEmailList', selectedEmailList);

    // // console.log('payload', payload);

    if (
      get(setObjectivesTabData, 'isCascaded', 'false') !== 'true' &&
      curFlow !== 'socialOnly'
    ) {
      fetchRecommendedArticles();
    }

    // console.log(selectedAdvisors);

    if (isEmpty(campaignId)) {
      createCampaign(payload)
        .then((res) => {
          if (res.result.success) {
            updateCampaign({
              id: res.result.data.campaign_id,
              payload: {
                email_data: JSON.stringify({ subject: subject }),
                cc_email_ids: JSON.stringify(ccArray.length > 0 ? ccArray : []),
                ...webinarID,
                webinar_registrants: webinar_registrants,
                approved_to_share:
                  approveToShare === 'true' && selectedAdvisors.length > 0
                    ? 'true'
                    : 'false',
              },
            })
              .then((response) => {
                // console.log('UPDATE CALLED -- - -');
                // if(isAdmin && approveToShare === 'true' && selectedAdvisors.length > 0 && createMode !== "series" && get(setObjectivesTabData, 'campaignType', '') !== "series" && !isFromAddCampaign)
                if(isAdmin && approveToShare === 'true' && selectedAdvisors.length > 0 && !isEnterpriseLevelAdmin) {
                  if(createMode === "series" || get(setObjectivesTabData, 'campaignType', '') === "series")
                    cascadeCampaignHandler(get(res.result.data, 'series_id', ''));
                  else
                    cascadeCampaignHandler(
                      get(res.result.data, 'campaign_id', '')
                    );
                }
                else if(isEnterpriseLevelAdmin) 
                  cascadeCampaignHandlerEntLvlAdmin();

                campaignSetState({
                  createFlow: true,
                  campaignId: get(res.result.data, 'campaign_id', ''),
                  seriesId: get(res.result.data, 'series_id', ''),
                  // ...(curFlow!== 'socialOnly' && selectSubscriptionType && {
                  //   subscriptionType: selectSubscriptionType.value
                  // }),
                  setObjectives: {
                    createMode: createMode,
                    name: campaignName,
                    purpose: purpose,
                    subject: subject,
                    // target: target,
                    ccList: ccArray,
                    is_facebook: get(
                      setObjectivesTabData,
                      'is_facebook',
                      'false'
                    ),
                    is_twitter: get(
                      setObjectivesTabData,
                      'is_twitter',
                      'false'
                    ),
                    is_linkedin: get(
                      setObjectivesTabData,
                      'is_linkedin',
                      'false'
                    ),
                    is_email: get(setObjectivesTabData, 'is_email', 'false'),
                    campaignType: get(setObjectivesTabData, 'campaignType', ''),
                    approvedToShare:
                      approveToShare === 'true' && selectedAdvisors.length > 0
                        ? 'true'
                        : 'false',
                    campaignSeriesStatus: seriesStatus,
                    isCascaded: get(
                      setObjectivesTabData,
                      'isCascaded',
                      'false'
                    ),
                    selectedAdvisors:
                      approveToShare === 'true' ? selectedAdvisors : [],
                    myNetwork:
                      isEmpty(selectedEmailList) &&
                      isEmpty(selectedFbContacts) &&
                      isEmpty(selectedLnContacts) &&
                      isEmpty(selectedTwContacts) &&
                      isEmpty(selectedSegmentList)
                        ? false
                        : true,
                    // emList: selectedEmailContacts,
                    emList: !segmentTabActive ? selectedEmailList : [],
                    fbList: selectedFbContacts,
                    lnList: selectedLnContacts,
                    twList: selectedTwContacts,
                    segmentList: segmentTabActive ? selectedSegmentList : [],
                    ...(createMode === 'series'
                      ? {
                          seriesName: campaignSeriesName,
                          seriesPurpose: seriesPurpose,
                        }
                      : {}),
                    webinar: {
                      wuid: selectedWebinar,
                      topic: get(webinarDetails, 'topic', ''),
                      description: get(webinarDetails, 'agenda', ''),
                      url: get(
                        setObjectivesTabData,
                        'webinar.url',
                        get(webinarDetails, 'url', '')
                      ),
                      link: get(webinarDetails, 'registration_url', ''),
                      isWebinar: webinar,
                      startDate: get(webinarDetails, 'start_date', ''),
                      startTime: get(webinarDetails, 'start_time', ''),
                      timezone: get(webinarDetails, 'timezone', ''),
                    },
                  },
                  ...(createMode === 'series' && {
                    seriesId: get(res, 'result.data.series_id', ''),
                  }),
                });
                // setLoading(false);
                if (mode === 'next') {
                  curFlow === 'socialOnly' ? handleNext(webinar) : handleNext();

                }
                else if (mode === 'saveAndClose') {
                  endCreateFlow();
                  history.push('/campaignV2');
                }
              })
              .catch((err) => {
                // console.log('error - - - ', err);
                setLoading(false);
              });
          } else setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      updateCampaign({
        id: campaignId,
        payload: {
          ...payload,
          email_data: JSON.stringify({ subject: subject }),
          cc_email_ids: JSON.stringify(ccArray.length > 0 ? ccArray : []),
          ...webinarID,
          webinar_registrants: webinar_registrants,
          approved_to_share:
            approveToShare === 'true' && selectedAdvisors.length > 0
              ? 'true'
              : 'false',
        },
      })
        .then((res) => {
          if (res.result.success) {
            // if(isAdmin && approveToShare === 'true' && selectedAdvisors.length > 0 && createMode !== "series" && get(setObjectivesTabData, 'campaignType', '') !== "series" && !isFromAddCampaign) {
            // if (isAdmin && approveToShare === 'true' && selectedAdvisors.length > 0 && !isEnterpriseLevelAdmin) {
            if (!isEnterpriseLevelAdmin) {
              if (createMode === "series" || get(setObjectivesTabData, 'campaignType', '') === "series")
                cascadeCampaignHandler(seriesId);
              else
                cascadeCampaignHandler(campaignId);
            }
            else if (isEnterpriseLevelAdmin) 
              cascadeCampaignHandlerEntLvlAdmin();
            
            campaignSetState({
              createFlow: true,
              campaignId: campaignId,
              seriesId: seriesId,
              // ...(curFlow !== 'socialOnly' && selectSubscriptionType && {
              //   subscriptionType: selectSubscriptionType.value
              // }),
              setObjectives: {
                createMode: createMode,
                name: campaignName,
                purpose: purpose,
                subject: subject,
                ccList: ccArray,
                subscriptionType,
                is_facebook: get(setObjectivesTabData, 'is_facebook', 'false'),
                is_twitter: get(setObjectivesTabData, 'is_twitter', 'false'),
                is_linkedin: get(setObjectivesTabData, 'is_linkedin', 'false'),
                is_email: get(setObjectivesTabData, 'is_email', 'false'),
                campaignType: get(setObjectivesTabData, 'campaignType', ''),
                campaignSeriesStatus: seriesStatus,
                approvedToShare:
                  approveToShare === 'true' && selectedAdvisors.length > 0
                    ? 'true'
                    : 'false',
                isCascaded: get(setObjectivesTabData, 'isCascaded', 'false'),
                selectedAdvisors:
                  approveToShare === 'true' ? selectedAdvisors : [],
                myNetwork:
                  isEmpty(selectedEmailList) &&
                  isEmpty(selectedFbContacts) &&
                  isEmpty(selectedLnContacts) &&
                  isEmpty(selectedTwContacts) &&
                  isEmpty(selectedSegmentList)
                    ? false
                    : true,
                // target: target,
                // emList: selectedEmailContacts,
                emList: !segmentTabActive ? selectedEmailList : [],
                fbList: selectedFbContacts,
                lnList: selectedLnContacts,
                twList: selectedTwContacts,
                segmentList: segmentTabActive ? selectedSegmentList : [],
                ...(createMode === 'series'
                  ? {
                      seriesName: campaignSeriesName,
                      seriesPurpose: seriesPurpose,
                    }
                  : {}),
                webinar: {
                  wuid: selectedWebinar,
                  topic: get(webinarDetails, 'topic', ''),
                  description: get(webinarDetails, 'agenda', ''),

                  url: get(
                    setObjectivesTabData,
                    'webinar.url',
                    get(webinarDetails, 'url', '')
                  ),
                  link: get(webinarDetails, 'registration_url', ''),
                  isWebinar: webinar,
                  startDate: get(webinarDetails, 'start_date', ''),
                  timezone:
                    get(webinarDetails, 'start_time', '') +
                    ' ' +
                    get(webinarDetails, 'timezone', ''),
                },
              },
            });
            // setLoading(false);
            if (mode === 'next') {
              console.log('check');
              handleNext();
            } else if (mode === 'saveAndClose') endCreateFlow();
          } else setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
      // if (campaignStatus !== 'draft' && campaignStatus !== '') {
      //   let payload = shareCampaignPayload();
      //   publishCampaignV2({ id: campaignId, payload })
      //     .then((res) => {
      //       // console.log('res', res);
      //     })
      //     .catch((err) => {
      //       // console.log('err', err);
      //     });
      // }
    }

    // console.log('APPROVE TO SHARE - - -- - ', approveToShare, 'ADVISORS LIST - - - - - - -', selectedAdvisors);

    // campaignSetState({
    //   createFlow: true,
    //   setObjectives: {
    //     createMode: createMode,
    //     name: campaignName,
    //     purpose: purpose,
    //     // target: target,
    //     emList: selectedEmailContacts,
    //     fbList: selectedFbContacts,
    //     lnList: selectedLnContacts,
    //     twList: selectedTwContacts,
    //     ...(createMode === 'series'
    //       ? { seriesName: campaignSeriesName, seriesPurpose: seriesPurpose }
    //       : {}),
    //   },
    // });
    // handleNext();
  };

  const cascadeCampaignHandler = (id_campaign) => {
    console.log(id_campaign);
    const myNetwork =
      curFlow === 'emailOnly'
        ? !isEmpty(selectedEmailContacts.filter((ele) => ele.id !== '-1'))
        : curFlow === 'socialOnly'
        ? !(
            isEmpty(selectedFbContacts) &&
            isEmpty(selectedLnContacts) &&
            isEmpty(selectedTwContacts)
          )
        : !(
            isEmpty(selectedEmailContacts.filter((ele) => ele.id !== '-1')) &&
            isEmpty(selectedFbContacts) &&
            isEmpty(selectedLnContacts) &&
            isEmpty(selectedTwContacts)
          );
    const payload = {
      advisors: selectedAdvisors.includes('selectAll')
        ? JSON.stringify(selectedAdvisors.filter((s) => s !== 'selectAll'))
        : JSON.stringify(selectedAdvisors),
      is_email: curFlow !== 'socialOnly',
      is_facebook:
        curFlow === 'emailOnly'
          ? false
          : myNetwork
          ? !isEmpty(selectedFbContacts)
          : true,
      is_linkedin:
        curFlow === 'emailOnly'
          ? false
          : myNetwork
          ? !isEmpty(selectedLnContacts)
          : true,
      is_twitter:
        curFlow === 'emailOnly'
          ? false
          : myNetwork
          ? !isEmpty(selectedTwContacts)
          : true,
      is_completed: campaignStatus && campaignStatus !== 'draft' ? true : false,
      ...((createMode === 'series' ||
        get(setObjectivesTabData, 'campaignType', '') === 'series') && {
        record_type: 'series',
      }),
    };
    postCascadeCampaign({ id: id_campaign, payload })
    .then(response => {
      // console.log('CASCADE RESPONSE - - -- - - ', response);

    })
    .catch(err =>  console.log('ERROR - - - - ', err));
  }

  const cascadeCampaignHandlerEntLvlAdmin = () => {
    const payload = {
      firms: JSON.stringify(selectedAdvisors),
      cascade_to_advisors: cascadeOption === 'advisors'
    }
    postCampaignCascadeEnterpriseLevel({
      payload, id: seriesId || campaignId, 
      campaignType: seriesId ? 'series' : 'one_time'
    })
    .then(res => {
      toggleModalLoader(false);
      setIsOpen(false);
    })
    .catch(err => {
      console.log('ERROR - - -- ', err);
      toggleModalLoader(false);
    });

  }


  const handleBackButtonClick = () => {
    campaignSetState({
      campaignId: '',
      createFlow: true,
      setObjectives: {},
      setTopics: {},
      contentWeeks: [],
      planCampaign: {},
      campaignArticles: [],
      scheduleTimings: {},
      selectedWeek: '',
      selectedDays: '',
      selectedArticles: [],
      publishedContent: {},
      curFlow: '',
      leadGenReferralData: null,
      fromDashboard: false,
      subscriptionType: ''
    });
    if (isEdit) history.push('/campaignV2');
    // endCreateFlow(true);
  };

  const onClickHandler = () => {
    setIsOpen(true);
    if (isEnterpriseLevelAdmin) {
      toggleFetchingCascadeInfo(true);
      // console.log('CAMPAIGN ID - - - ', campaignId, 'SERIES ID - - - -- ', seriesId);
      getCascadeInfoEnterpriseLevelAdmin({ id: seriesId || campaignId, type: seriesId ? 'series' : 'one_time'})
      .then(res => {
        if(res.result.success) {
          const cascadeToAdvisors = get(res, 'result.data.cascade_to_advisors', 'true') === 'true';
          setOGSelectedAdvisors(get(res, 'result.data.firm_ids', []));
          setSelectedAdvisors(get(res, 'result.data.firm_ids', []));
          setCascadeOption(cascadeToAdvisors ? 'advisors' : 'admins');
          toggleFetchingCascadeInfo(false);
        }
      })
      .catch(err => console.log('ERROR FETCHING CASCADE INFO - - -- ', err));
    }
  }



  const selectMembersHandler = (e, selectedMember) => {
    // console.log('SELECTED MEMBER - - - -', selectedMember, '  ', e.target.checked);
    let updatedAdvisorsList = [...selectedAdvisors];
    if (selectedMember.id === 'selectAll') {
      if (e.target.checked)
        updatedAdvisorsList = membersList.map((member) => member.id);
      else updatedAdvisorsList = [];
      // else
      //     updatedAdvisorsList = [...OGSelectedAdvisors];
    } else if (selectedAdvisors.includes(selectedMember.id))
      updatedAdvisorsList = [...selectedAdvisors].filter(
        (advisorId) =>
          advisorId !== selectedMember.id && advisorId !== 'selectAll'
      );
    else {
      updatedAdvisorsList = [...selectedAdvisors, selectedMember.id];
      if (updatedAdvisorsList.length === membersList.length - 1)
        updatedAdvisorsList.push(membersList[0].id);
    }
    setSelectedAdvisors(updatedAdvisorsList);
  };

  const closeModalHandler = () => {
    setSelectedAdvisors(OGSelectedAdvisors);
    // setOGSelectedAdvisors([]);
    toggleShareDisabled(false);
    setIsOpen(false);
  };

  const handleEmailToggle = (flag) => {
    if(!flag) {
      setSelectedSegmentList([]);
    } else {
      setSelectedEmailContacts([]);
    }

    setSegmentTabActive(flag);
  }

  const handleValidation = () => {
    let isNotValid = true;
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let isNameNotValid = true,
      isListNotValid = true;  

    if (!isEmpty(campaignName)) isNameNotValid = false;
    else isNameNotValid = true;

    // // console.log('createMode', createMode);
    if (createMode === 'series') {
      if ((!isEmpty(campaignSeriesName) && !isNameNotValid) || isEdit)
        isNameNotValid = false;
      else isNameNotValid = true;
    }

    const myNetworkContacts =
      !isEmpty(selectedEmailContacts) ||
      !isEmpty(selectedFbContacts) ||
      !isEmpty(selectedLnContacts) ||
      !isEmpty(selectedTwContacts) || 
      !isEmpty(selectedSegmentList);
    // if ((myNetworkContacts && (approveToShare === 'true' && !isEmpty(selectedAdvisors))) || ((approveToShare === 'true' && !isEmpty(selectedAdvisors) && createMode !== "series" && get(setObjectivesTabData, 'campaignType', '') !== "series") || (approveToShare === 'false' && myNetworkContacts) || (myNetworkContacts && (createMode === "series" || get(setObjectivesTabData, 'campaignType', '') === "series")))) {
    //   isListNotValid = false;
    // }
    // else isListNotValid = true;
    if (
      myNetworkContacts ||
      (approveToShare === 'true' && !isEmpty(selectedAdvisors))
    )
      isListNotValid = false;
    else isListNotValid = true;

    // check for both
    if (curFlow === 'both') {
      const bothMyNetwork =
        (!isEmpty(selectedEmailContacts) || !isEmpty(selectedSegmentList)) &&
        (!isEmpty(selectedFbContacts) ||
          !isEmpty(selectedLnContacts) ||
          !isEmpty(selectedTwContacts));
      if (
        bothMyNetwork ||
        (approveToShare === 'true' && !isEmpty(selectedAdvisors))
      )
        isListNotValid = false;
      else isListNotValid = true;
    }

    if (curFlow === 'emailOnly' || curFlow === 'both') {
      const isCCNotValid = !ccList
        ? false
        : !ccList
            .split(',')
            .map((em) => em.trim())
            .every((em) => isEmailRegEx.test(em.toLowerCase()));
      const isSubjectNotValid = isEmpty(subject);
      console.log(subject);
      if (
        !isNameNotValid &&
        !isListNotValid &&
        !isCCNotValid &&
        !isSubjectNotValid
        // !isEmpty(selectSubscriptionType)
      )
        isNotValid = false;
    } else {
      if (
        !isNameNotValid &&
        !isListNotValid
      )
        isNotValid = false;
    }

    console.log(isNameNotValid, isListNotValid);

    return isNotValid;
  };

  // TODO - NEW VALIDATION LOGIC
  // const handleValidationV2 = () => {
  //   const isEmailRegEx =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

  //   if (isEmpty(campaignName))
  //     return true;

  //   // // console.log('createMode', createMode);
  //   if (createMode === 'series' && !campaignSeriesName)
  //     return true;

  //   const myNetworkContacts = (
  //     !isEmpty(selectedEmailContacts) ||
  //     !isEmpty(selectedFbContacts) ||
  //     !isEmpty(selectedLnContacts) ||
  //     !isEmpty(selectedTwContacts)
  //   );

  //   if(!myNetworkContacts && selectedAdvisors.length === 0)
  //     return true;

  //   if (curFlow === 'both') {
  //     if (
  //       isEmpty(selectedEmailContacts) ||
  //       (isEmpty(selectedFbContacts) && isEmpty(selectedLnContacts) && isEmpty(selectedTwContacts))
  //     )
  //       return true;
  //   }

  //   if (curFlow === 'emailOnly' || curFlow === 'both') {
  //     const ccListValid = ccList.split(',').map((em) => em.trim()).every((em) => isEmailRegEx.test(em.toLowerCase()))
  //     if(ccList && !ccListValid)
  //       return true;

  //     if(!subject)
  //       return true;
  //   }

  //   return false;
  // }

  const handleSelectAll = (platform) => {
    // console.log('emailList', emailList);
    if (platform === 'em' && !isEmpty(get(socialNetworkData, 'em', []))) {
      if (selectedEmailContacts.length === emailList.length) {
        setSelectedEmailContacts([]);
      } else {
        // console.log('contactGroups', contactGroups);
        setSelectedEmailContacts(emailList);
      }
    }

    if (platform === 'fb' && !isEmpty(get(socialNetworkData, 'fb', []))) {
      if (
        Object.keys(get(socialNetworkData, 'fb[0].page', [])).length ===
        selectedFbContacts.length
      ) {
        setSelectedFbContacts([]);
      } else {
        setSelectedFbContacts([
          ...Object.keys(get(socialNetworkData, 'fb[0].page', [])),
        ]);
      }
    }

    if (platform === 'ln' && !isEmpty(get(socialNetworkData, 'ln', []))) {
      // // // console.log(Object.keys(get(socialNetworkData, 'ln[0].page', [])));
      if (
        Object.keys(get(socialNetworkData, 'ln[0].page', [])).length + 1 ===
        selectedLnContacts.length
      ) {
        setSelectedLnContacts([]);
      } else {
        setSelectedLnContacts([
          0,
          ...Object.keys(get(socialNetworkData, 'ln[0].page', [])),
        ]);
      }
    }
  };

  const handleAlphabeticalSort = (platform) => {
    switch (platform) {
      case 'em':
        // // // console.log('NEW STATE - - -', !ascEmailOrder);
        setAscEmailOrder(!ascEmailOrder);
        break;

      case 'fb':
        // // // console.log('NEW STATE - - -', !ascFbOrder);
        setAscFbOrder(!ascFbOrder);
        break;

      case 'ln':
        // // // console.log('NEW STATE - - -', !ascLnOrder);
        setAscLnOrder(!ascLnOrder);
        break;

      default:
        break;
    }
  };

  // const handleSaveAndClose = () => {
  //   setLoading(true);
  //   let emLists = [],
  //     fbLists = [],
  //     twLists = [],
  //     lnLists = [];
  //   emLists = !isEmpty(selectedEmailContacts)
  //     ? selectedEmailContacts.map((item, index) => {
  //         return {
  //           destination_id: item.id,
  //           snetwork_act_id: getAccountId('em'),
  //         };
  //       })
  //     : [];

  //   twLists = !isEmpty(selectedTwContacts)
  //     ? selectedTwContacts.map((item, index) => {
  //         return {
  //           destination_id: item === 0 ? null : item,
  //           snetwork_act_id: getAccountId('tw'),
  //         };
  //       })
  //     : [];

  //   fbLists = !isEmpty(selectedFbContacts)
  //     ? selectedFbContacts.map((item, index) => {
  //         return {
  //           destination_id: item === 0 ? null : item,
  //           snetwork_act_id: getAccountId('fb'),
  //         };
  //       })
  //     : [];

  //   lnLists = !isEmpty(selectedLnContacts)
  //     ? selectedLnContacts.map((item, index) => {
  //         return {
  //           destination_id: item === 0 ? null : item,
  //           snetwork_act_id: getAccountId('ln', item),
  //         };
  //       })
  //     : [];

  //   let destination = {
  //     email: emLists,
  //     facebook: fbLists,
  //     twitter: twLists,
  //     linkedin: lnLists,
  //   };
  //   let payload = {
  //     name: campaignName,
  //     purpose: purpose,
  //     // target: target,
  //     destination: JSON.stringify(destination),
  //   };

  //   if (isEmpty(campaignId)) {
  //     createCampaign(payload)
  //       .then((res) => {
  //         if (res.result.success) {
  //           setLoading(false);
  //           endCreateFlow();
  //         } else {
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //       });
  //   } else {
  //     updateCampaign({ id: campaignId, payload })
  //       .then((res) => {
  //         if (res.result.success) {
  //           setLoading(false);
  //           endCreateFlow();
  //         } else {
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //       });
  //   }
  // };

  const redirectToConfigure = (platform) => {
    // TODO: when admins will have campaign option
    history.push('/configure');
    if(platform === 'em') {
      window.sessionStorage.setItem('selectedIndex', 1);
    } else {
      window.sessionStorage.setItem('selectedIndex', 2);
    }
    
    endCreateFlow();
  };

  const emailListBox = () => {
    return (
      <Flex
        flexDirection="column"
        width={curFlow === 'both' ? '300px' : '35%'}
        marginRight="20px"
      >
        <Flex
          justifyContent="space-between"
          fontSize="14px"
          color="#000000"
          width="100%"
        >
          <Flex>
            Emails
            {!isEmpty(get(socialNetworkData, 'em', [])) &&
              !isEmpty(contactGroups) &&
              contactGroups.length > 1 && (
                <span
                  style={{
                    color: isEmpty(get(socialNetworkData, 'em', []))
                      ? '#AFAFAF'
                      : '#6250ED',
                    fontSize: '10px',
                    position: 'relative',
                    top: '4px',
                    paddingLeft: '10px',
                    cursor: isEmpty(get(socialNetworkData, 'em', []))
                      ? 'default'
                      : 'pointer',
                  }}
                  id="select_all_emails"
                  onClick={() => handleSelectAll('em')}
                >
                  Select ALL
                </span>
              )}
          </Flex>
          {!isEmpty(get(socialNetworkData, 'em', [])) &&
            !isEmpty(contactGroups) &&
            contactGroups.length > 1 && (
              <Flex
                fontSize="10px"
                style={{
                  position: 'relative',
                  top: '6px',
                  cursor: 'pointer',
                }}
                onClick={() => handleAlphabeticalSort('em')}
              >
                <span>Sort: Alphabetical</span>
                <span style={{ paddingLeft: '3px' }}>
                  <FontAwesomeIcon color="#000000" icon={faChevronDown} />
                </span>
              </Flex>
            )}
        </Flex>
        <RecipientsListWrapper padding="0px">
          {isEmpty(get(socialNetworkData, 'em', [])) ? (
            <Flex
              height="100%"
              justifyContent="center"
              width="100%"
              fontSize="12px"
              alignItems="center"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => redirectToConfigure('em')}
            >
              Please Connect to Email
            </Flex>
          ) : (
            <RecipientsListBody height="168px">
              {!isEmpty(emailList) &&
                emailList.map((item, index) => (
                  <>
                    <FilterRowChips
                      style={{
                        fontSize: '12px',
                        padding: '5px 7px',
                        backgroundColor:
                          item.description === 'group_header' ? '#E3E7EE' : '',
                      }}
                    >
                      <label
                        className="checkbox-button"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        {item.description !== 'group_header' && (
                          <>
                            <input
                              type="checkbox"
                              className="checkbox-button__input"
                              name="draft"
                              id={`email_${item.name}`}
                              onChange={() => handleCheckboxClick(item, 'em')}
                              checked={
                                !isEmpty(selectedEmailContacts) &&
                                selectedEmailContacts.filter(
                                  (s) => s.id === item.id
                                ).length > 0
                              }
                              value={item.name}
                            />
                            <span
                              className="checkbox-button__control"
                              style={{
                                cursor: 'pointer',
                              }}
                            ></span>
                          </>
                        )}
                        <span
                          className="checkbox-button__label"
                          style={{
                            color:
                              item.description === 'group_header'
                                ? '#000'
                                : '#49484a',
                            fontWeight:
                              item.description === 'group_header'
                                ? '800'
                                : '100',
                            // fontWeight: '100',
                            marginLeft:
                              item.description === 'group_header' ? '32px' : '',
                            cursor: 'pointer',
                          }}
                        >
                          {item.name}
                        </span>
                      </label>
                    </FilterRowChips>
                  </>
                ))}
            </RecipientsListBody>
          )}
        </RecipientsListWrapper>
      </Flex>
    );
  };

  const sortSegmentList = (isAscending) => {
    setSegmentListLoader(true);
    var sortedSegmentArr = segmentList.sort((a, b) => {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    });
    if (!isAscending) {
      sortedSegmentArr.reverse();
    }
    console.log(sortedSegmentArr);
    setSegmentList(sortedSegmentArr);
    setTimeout(() => setSegmentListLoader(false), 200);
  };

  const handleSegmentCheckboxClick = (item) => {
    console.log(item);
    if (isEmpty(selectedSegmentList)) {
      setSelectedSegmentList([...selectedSegmentList, item]);
    } else if (!isEmpty(selectedSegmentList) && isEmpty(selectedSegmentList.filter((s) => s.id === item.id))) {
      setSelectedSegmentList([...selectedSegmentList, item]);
    } else {
      let removeItems = selectedSegmentList.filter((s) => s.id !== item.id);
      setSelectedSegmentList(removeItems);
    }
  };

  const segmentListBox = () => {
    return (
      <Flex
        flexDirection="column"
        width={curFlow === 'both' ? '300px' : '35%'}
        marginRight="20px"
      >
        {!isEmpty(get(socialNetworkData, 'em', [])) && (
          <Flex
            justifyContent="space-between"
            fontSize="14px"
            color="#000000"
            width="100%"
            marginLeft="3px"
          >
            <Flex >Choose from your contact segments:</Flex>
            <Flex
              fontSize="10px"
              style={{
                position: 'relative',
                top: '6px',
                cursor: 'pointer',
              }}
              onClick={() => sortSegmentList(!segmentList)}
            >
              <span marginRight="5px">
                <span style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faArrowUp} />
                </span>
                &nbsp;
                <span style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faArrowDown} />
                </span>
              </span>
            </Flex>
          </Flex>
        )}

        <RecipientsListWrapper padding="0px">
          <LoaderWrapper isLoading={segmentListLoader}>
            {segmentList.length > 0 && !isEmpty(get(socialNetworkData, 'em', [])) ? (
              <RecipientsListBody height="168px">
                {segmentList.map((item, index) => (
                  <>
                    <FilterRowChips
                      style={{
                        fontSize: '12px',
                        padding: '5px 7px',
                      }}
                    >
                      <label
                        className="checkbox-button"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <input
                          type="checkbox"
                          className="checkbox-button__input"
                          name="draft"
                          id={`segment_${item.name}`}
                          onChange={() => handleSegmentCheckboxClick(item)}
                          checked={
                            !isEmpty(selectedSegmentList) &&
                              selectedSegmentList.filter(
                                  (s) => s.id === item.id
                                ).length > 0
                          }
                          value={item.name}
                        />
                        <span
                          className="checkbox-button__control"
                          style={{
                            cursor: 'pointer',
                          }}
                        ></span>
                        <span
                          className="checkbox-button__label"
                          style={{
                            color:
                              '#49484a',
                            fontWeight:
                              '100',
                            cursor: 'pointer',
                          }}
                        >
                          {item.name}
                        </span>
                      </label>
                    </FilterRowChips>
                  </>
                ))}
              </RecipientsListBody>
            ) : (
                <Flex
                  height="100%"
                  justifyContent="center"
                  width="100%"
                  fontSize="12px"
                  alignItems="center"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => isEmpty(get(socialNetworkData, 'em', [])) ? redirectToConfigure('em') : redirectToConfigure('segment')}
                >
                  {isEmpty(get(socialNetworkData, 'em', [])) ? 'Please Connect to Email' : 'Create a segment in your Contacts section'}
                </Flex>
            )}
          </LoaderWrapper>
        </RecipientsListWrapper>
      </Flex>
    );
  };

  const handleWebinarToggle = (checked) => {
    setWebinar(checked);
    if (checked === true) {
      setWebinarListLoader(true);
      setWebinarDetailsLoader(true);
      getWebinarList()
        .then((response) => {
          if (response.result.success) {
            setWebinarListLoader(false);
            setWebinarList(get(response, 'result.data', []));
            if (get(setObjectivesTabData, 'webinar.wuid', null)) {
              handleRadioButtonClick(
                get(setObjectivesTabData, 'webinar.wuid', null)
              );
              setSelectedWebinar(
                encodeURIComponent(get(setObjectivesTabData, 'webinar.wuid', null))
              );
            } else {
              handleRadioButtonClick(get(response, 'result.data[0].id', ''));
              setSelectedWebinar(get(response, 'result.data[0].id', ''));
            }
          }
        })
        .catch((err) => {
          console.log('error', err.message);
          setWebinarListLoader(false);
          setWebinarDetailsLoader(false);
          setWebinarErrorText(err.message);
        });
    } else {
      setWebinar(false);
      setWebinarDetails({});
      setSelectedWebinar('');
      setWebinarList([]);
      campaignSetState({
        selectedArticles: []
      })
    }
  };

  return (
    <div>
      <CampaignNavigation
        enableNext={!isOpen && !handleValidation()}
        nextHandler={handleNextClick}
        backHandler={handleBackButtonClick}
        loading={loading}
        saveAndCloseHandler={handleSaveAndClose}
        stepList={stepList}
        selectedIndex={selectedIndex}
      />
      <Flex
        width={curFlow === 'both' ? '1200px' : '900px'}
        padding="15px 10px"
        flexDirection="column"
      >
       
        {createMode === 'series' && !isEdit && !seriesId && (
          <Flex width="100%" ml="10px" mb="15px">
            <Flex
              flexDirection="column"
              width="35%"
              fontSize="14px"
              color="#000000"
              mr="15px"
              style={{ position: 'relative' }}
            >
              <Flex width="100%" style={{ fontWeight: '600' }}>
                Campaign Series Name
              </Flex>
              <Flex width="100%" pt="8px">
                <SetObjectivesInput
                  maxLength="100"
                  placeholder="Untitled"
                  value={campaignSeriesName}
                  onChange={(e) => setCampaignSeriesName(e.target.value)}
                  name="campaign_series_name"
                />
              </Flex>
            </Flex>
            <Flex
              width="62%"
              flexDirection="column"
              fontSize="14px"
              color="#000000"
              mr="15px"
            >
              <Flex width="100%" style={{ fontWeight: '600' }}>
                Series Purpose
              </Flex>
              <Flex width="100%" pt="8px">
                <SetObjectivesInput
                  maxLength="1000"
                  placeholder="Campaign Purpose (Optional)"
                  value={seriesPurpose}
                  onChange={(e) => setSeriesPurpose(e.target.value)}
                />
              </Flex>
            </Flex>
          </Flex>
        )}
        <Flex width="100%" ml="10px">
          <Flex
            flexDirection="column"
            width="35%"
            fontSize="14px"
            color="#000000"
            mr="15px"
            style={{ position: 'relative' }}
          >
            <Flex width="100%" style={{ fontWeight: '600' }}>
              Campaign Name
            </Flex>
            <Flex width="100%" pt="8px">
              <SetObjectivesInput
                maxLength="100"
                placeholder="Untitled"
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
                name="campaign_name"
              />
            </Flex>
          </Flex>
          <Flex
            width="62%"
            flexDirection="column"
            fontSize="14px"
            color="#000000"
            mr="15px"
          >
            <Flex width="100%" style={{ fontWeight: '600' }}>
              Purpose
            </Flex>
            <Flex width="100%" pt="8px">
              <SetObjectivesInput
                maxLength="1000"
                placeholder="Campaign Purpose (Optional)"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
              />
            </Flex>
          </Flex>
        </Flex>
        <hr style={{ width: '98%', margin: '20px auto' }} />
        {/* <Flex width="100%">
          <Flex
            flexDirection="column"
            width="15%"
            fontSize="14px"
            color="#000000"
            mr="15px"
          >
            <Flex width="100%">Target</Flex>
            <Flex width="100%" pt="8px">
              <MioRadioSpan noBorder width="auto">
                <MioRadio
                  id={'target-prospects'}
                  name="target"
                  onClick={() => setTarget('prospects')}
                  checked={target === 'prospects'}
                  margin="19% 19% 0%"
                />
                <label htmlFor="target-prospects">Prospects</label>
              </MioRadioSpan>
              <MioRadioSpan noBorder width="auto">
                <MioRadio
                  id={'target-clients'}
                  name="target"
                  onClick={() => setTarget('clients')}
                  checked={target === 'clients'}
                  margin="19% 15% 0%"
                />
                <label htmlFor="target-clients">Clients</label>
              </MioRadioSpan>
            </Flex>
          </Flex>
        </Flex> */}
        {(curFlow === 'emailOnly' || curFlow === 'both') && (
          <>
            <Flex
              flexDirection="column"
              width="100%"
              fontSize="14px"
              color="#000000"
              mr="15px"
              style={{ padding: '0px 10px' }}
            >
              <Flex width="100%" style={{ fontWeight: '600' }}>
                *Subject
              </Flex>
              <Flex width="100%" pt="8px">
                <SetObjectivesInput
                  placeholder="Type in Subject to be sent in emails"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  id="em_subject"
                  maxLength="2000"
                  readOnly={
                    get(setObjectivesTabData, 'isCascaded', 'false') === 'true'
                  }
                />
              </Flex>
            </Flex>
            <hr style={{ width: '98%', margin: '20px auto' }} />
          </>
        )}
        {isAdmin && (
          <Flex
            flexDirection="column"
            width="30%"
            fontSize="16px"
            color="#000000"
            style={{ padding: '0px 10px 10px' }}
          >
            <Flex width="100%" style={{ fontWeight: '800' }}>
              My Network
            </Flex>
          </Flex>
        )}
        {(curFlow === 'emailOnly' || curFlow === 'both') && (
          <Flex
            flexDirection="column"
            width={curFlow === 'emailOnly' ? '36.3%' : '27%'}
            fontSize="14px"
            color="#000000"
            mr="15px"
            style={{ padding: '0px 10px', width: { curFlow : 'emailOnly' ? '36.3%' : '27%' }, marginTop: "-30px" }}
          >
            {/* Old Options */}
            <>
              {/* <Flex width="100%" style={{ fontWeight: '600' }}>
                Target
              </Flex>
              <Flex
                justifyContent="flex-start"
                alignItems="flex-start"
                pt="8px"
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  marginRight="10px"
                >
                  <RadioInput
                    type="radio"
                    name="list_type"
                    id="list_type_all"
                    onClick={() => {
                      setListType('all');
                    }}
                    checked={listType === 'all'}
                    style={{ cursor: 'pointer' }}
                  />
                  <RadioInputLabel
                    htmlFor="list_type_all"
                    style={{ cursor: 'pointer' }}
                  >
                    All
                  </RadioInputLabel>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  marginRight="5px"
                // ml="25px"
                >
                  <RadioInput
                    type="radio"
                    name="list_type"
                    id="list_type_prospects"
                    onClick={() => {
                      setListType('prospect');
                    }}
                    checked={listType === 'prospect'}
                    style={{ cursor: 'pointer' }}
                  />
                  <RadioInputLabel
                    htmlFor="list_type_prospects"
                    style={{ cursor: 'pointer' }}
                  >
                    Prospects
                  </RadioInputLabel>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  marginRight="5px"
                  ml="10px"
                >
                  <RadioInput
                    type="radio"
                    name="list_type"
                    id="list_type_clients"
                    onClick={() => {
                      setListType('client');
                    }}
                    checked={listType === 'client'}
                    style={{ cursor: 'pointer' }}
                  />
                  <RadioInputLabel
                    htmlFor="list_type_clients"
                    style={{ cursor: 'pointer' }}
                  >
                    Clients
                  </RadioInputLabel>
                </Flex>
              </Flex> */}
            </>

            {/* Segment and Email List */}
            <TabsThree>
              <TabListThree bgColor="none">
                <TabFour
                  padding="5px 0px !important"
                  onClick={() => handleEmailToggle(false)}
                  style={
                    !segmentTabActive
                      ? { backgroundColor: '#6453ec', color: '#fff' }
                      : {}
                  }
                >
                  Send by Lists
                </TabFour>
                <TabFour
                  padding="5px 0px !important"
                  onClick={() => handleEmailToggle(true)}
                  style={
                    segmentTabActive
                      ? { backgroundColor: '#6453ec', color: '#fff' }
                      : {}
                  }
                >
                  Send by Segments
                </TabFour>
              </TabListThree>
            </TabsThree>
          </Flex>
        )}
        <Flex
          width="100%"
          justifyContent="space-between"
          style={{ padding: '0px 10px' }}
          mt="10px"
        >
          <Flex
            display="flex"
            flexDirection="row"
            width="100%"
            alignItems="center"
            justifyContent="flex-start"
          >
            {(curFlow === 'emailOnly' || curFlow === 'both') && (
              <>{segmentTabActive ? segmentListBox() : emailListBox()}</>
            )}
            {(curFlow === 'socialOnly' || curFlow === 'both') && (
              <>
                {(get(setObjectivesTabData, 'isCascaded', 'false') !== 'true' ||
                  get(setObjectivesTabData, 'is_facebook', 'false') ===
                    'true') && (
                  <Flex flexDirection="column" width="250px" marginRight="20px">
                    <Flex
                      fontSize="14px"
                      color="#000000"
                      // width="100%"
                      justifyContent="space-between"
                    >
                      <Flex>
                        Facebook
                        {isEmpty(campaignId) &&
                          !isEmpty(get(socialNetworkData, 'fb', [])) &&
                          Object.keys(get(socialNetworkData, 'fb[0].page', []))
                            .length > 1 && (
                            <span
                              style={{
                                color: isEmpty(get(socialNetworkData, 'fb', []))
                                  ? '#AFAFAF'
                                  : '#6250ED',
                                fontSize: '10px',
                                position: 'relative',
                                top: '4px',
                                paddingLeft: '10px',
                                cursor: isEmpty(
                                  get(socialNetworkData, 'fb', [])
                                )
                                  ? 'default'
                                  : 'pointer',
                              }}
                              id="select_all_fb"
                              onClick={() => handleSelectAll('fb')}
                            >
                              Select ALL
                            </span>
                          )}
                      </Flex>
                      {!isEmpty(get(socialNetworkData, 'fb', [])) &&
                        Object.keys(get(socialNetworkData, 'fb[0].page', []))
                          .length > 1 && (
                          <Flex
                            fontSize="10px"
                            style={{
                              position: 'relative',
                              top: '4px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleAlphabeticalSort('fb')}
                          >
                            <span>Sort: Alphabetical</span>
                            <span style={{ paddingLeft: '3px' }}>
                              <FontAwesomeIcon
                                color="#000000"
                                icon={faChevronDown}
                              />
                            </span>
                          </Flex>
                        )}
                    </Flex>
                    <RecipientsListWrapper padding="0px">
                      {isEmpty(get(socialNetworkData, 'fb', [])) ? (
                        <Flex
                          height="100%"
                          justifyContent="center"
                          width="100%"
                          fontSize="12px"
                          alignItems="center"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => redirectToConfigure('fb')}
                        >
                          Please Connect to Facebook
                        </Flex>
                      ) : (
                        <RecipientsListBody height="168px">
                          {!isEmpty(get(socialNetworkData, 'fb[0].page', [])) &&
                          !ascFbOrder
                            ? map(
                                get(socialNetworkData, 'fb[0].page', []),
                                (item, key) => (
                                  <>
                                    {/* {// console.log('KEY - - -- -', key)} */}
                                    <FilterRowChips
                                      style={{
                                        fontSize: '12px',
                                        padding: '5px 7px',
                                      }}
                                    >
                                      <label
                                        className="checkbox-button"
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="checkbox-button__input"
                                          name="draft"
                                          onChange={() =>
                                            handleCheckboxClick(key, 'fb')
                                          }
                                          checked={
                                            !isEmpty(
                                              selectedFbContacts.filter(
                                                (s) => s === key
                                              )
                                            )
                                          }
                                          value={item.details.name}
                                        />
                                        <span
                                          className="checkbox-button__control"
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                        ></span>
                                        <span
                                          className="checkbox-button__label"
                                          style={{
                                            color: '#49484a',
                                            fontWeight: '100',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {item.details.name}
                                        </span>
                                      </label>
                                    </FilterRowChips>
                                  </>
                                )
                              )
                            : map(get(socialNetworkData, 'fb[0].page', []))
                                .sort((a, b) =>
                                  a.details.name.localeCompare(b.details.name)
                                )
                                .map((item, index) => (
                                  <>
                                    <FilterRowChips
                                      style={{
                                        fontSize: '12px',
                                        padding: '5px 0px',
                                      }}
                                    >
                                      <label
                                        className="checkbox-button"
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="checkbox-button__input"
                                          name="draft"
                                          onChange={() =>
                                            handleCheckboxClick(item.id, 'fb')
                                          }
                                          checked={
                                            !isEmpty(
                                              selectedFbContacts.filter(
                                                (s) => s === item.id
                                              )
                                            )
                                          }
                                          value={item.details.name}
                                        />
                                        <span
                                          className="checkbox-button__control"
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                        ></span>
                                        <span
                                          className="checkbox-button__label"
                                          style={{
                                            color: '#49484a',
                                            fontWeight: '100',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {item.details.name}
                                        </span>
                                      </label>
                                    </FilterRowChips>
                                  </>
                                ))}
                        </RecipientsListBody>
                      )}
                    </RecipientsListWrapper>
                  </Flex>
                )}
                {(get(setObjectivesTabData, 'isCascaded', 'false') !== 'true' ||
                  get(setObjectivesTabData, 'is_linkedin', 'false') ===
                    'true') && (
                  <Flex flexDirection="column" width="250px" marginRight="20px">
                    <Flex
                      fontSize="14px"
                      color="#000000"
                      // width="100%"
                      justifyContent="space-between"
                    >
                      <Flex>
                        LinkedIn
                        {isEmpty(campaignId) &&
                          !isEmpty(get(socialNetworkData, 'ln', [])) &&
                          Object.keys(get(socialNetworkData, 'ln[0].page', []))
                            .length > 0 && (
                            <span
                              style={{
                                color: isEmpty(get(socialNetworkData, 'ln', []))
                                  ? '#AFAFAF'
                                  : '#6250ED',
                                fontSize: '10px',
                                position: 'relative',
                                top: '4px',
                                paddingLeft: '10px',
                                cursor: isEmpty(
                                  get(socialNetworkData, 'ln', [])
                                )
                                  ? 'default'
                                  : 'pointer',
                              }}
                              id="select_all_ln"
                              onClick={() => handleSelectAll('ln')}
                            >
                              Select ALL
                            </span>
                          )}
                      </Flex>
                      {!isEmpty(get(socialNetworkData, 'ln', [])) &&
                        Object.keys(get(socialNetworkData, 'ln[0].page', []))
                          .length > 0 && (
                          <Flex
                            fontSize="10px"
                            style={{
                              position: 'relative',
                              top: '4px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleAlphabeticalSort('ln')}
                          >
                            <span>Sort: Alphabetical</span>
                            <span style={{ paddingLeft: '3px' }}>
                              <FontAwesomeIcon
                                color="#000000"
                                icon={faChevronDown}
                              />
                            </span>
                          </Flex>
                        )}
                    </Flex>
                    <RecipientsListWrapper padding="0px">
                      {isEmpty(get(socialNetworkData, 'ln', [])) ? (
                        <Flex
                          height="100%"
                          justifyContent="center"
                          width="100%"
                          fontSize="12px"
                          alignItems="center"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => redirectToConfigure('ln')}
                        >
                          Please Connect to LinkedIn
                        </Flex>
                      ) : (
                        <RecipientsListBody height="168px">
                          <FilterRowChips
                            style={{ fontSize: '12px', padding: '5px 7px' }}
                          >
                            <label
                              className="checkbox-button"
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <input
                                type="checkbox"
                                className="checkbox-button__input"
                                name="draft"
                                onChange={() => handleCheckboxClick(0, 'ln')}
                                checked={
                                  !isEmpty(selectedLnContacts.filter((s) => !s))
                                }
                                value={'news-feed'}
                              />
                              <span
                                className="checkbox-button__control"
                                style={{
                                  cursor: 'pointer',
                                }}
                              ></span>
                              <span
                                className="checkbox-button__label"
                                style={{
                                  color: '#49484a',
                                  fontWeight: '100',
                                  cursor: 'pointer',
                                }}
                              >
                                On News Feed
                              </span>
                            </label>
                          </FilterRowChips>
                          {!isEmpty(get(socialNetworkData, 'ln[0].page', [])) &&
                          !ascLnOrder
                            ? map(
                                get(socialNetworkData, 'ln[0].page', []),
                                (item, key) => (
                                  <>
                                    <FilterRowChips
                                      style={{
                                        fontSize: '12px',
                                        padding: '5px 7px',
                                      }}
                                    >
                                      <label
                                        className="checkbox-button"
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="checkbox-button__input"
                                          name="draft"
                                          onChange={() =>
                                            handleCheckboxClick(key, 'ln')
                                          }
                                          checked={
                                            !isEmpty(
                                              selectedLnContacts.filter(
                                                (s) => s === key
                                              )
                                            )
                                          }
                                          value={item.details.name}
                                        />
                                        <span
                                          className="checkbox-button__control"
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                        ></span>
                                        <span
                                          className="checkbox-button__label"
                                          style={{
                                            color: '#49484a',
                                            fontWeight: '100',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {item.details.name}
                                        </span>
                                      </label>
                                    </FilterRowChips>
                                  </>
                                )
                              )
                            : map(get(socialNetworkData, 'ln[0].page', []))
                                .sort((a, b) =>
                                  a.details.name.localeCompare(b.details.name)
                                )
                                .map((item, index) => (
                                  <>
                                    <FilterRowChips
                                      style={{
                                        fontSize: '12px',
                                        padding: '5px 0px',
                                      }}
                                    >
                                      <label
                                        className="checkbox-button"
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="checkbox-button__input"
                                          name="draft"
                                          onChange={() =>
                                            handleCheckboxClick(item.id, 'ln')
                                          }
                                          checked={
                                            !isEmpty(
                                              selectedLnContacts.filter(
                                                (s) => s === item.id
                                              )
                                            )
                                          }
                                          value={item.details.name}
                                        />
                                        <span
                                          className="checkbox-button__control"
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                        ></span>
                                        <span
                                          className="checkbox-button__label"
                                          style={{
                                            color: '#49484a',
                                            fontWeight: '100',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {item.details.name}
                                        </span>
                                      </label>
                                    </FilterRowChips>
                                  </>
                                ))}
                        </RecipientsListBody>
                      )}
                    </RecipientsListWrapper>
                  </Flex>
                )}
                {(get(setObjectivesTabData, 'isCascaded', 'false') !== 'true' ||
                  get(setObjectivesTabData, 'is_twitter', 'false') ===
                    'true') && (
                  <Flex flexDirection="column" width="250px" marginRight="20px">
                    <Flex fontSize="14px" color="#000000" width="100%">
                      Twitter
                    </Flex>
                    <RecipientsListWrapper padding="0px">
                      {isEmpty(get(socialNetworkData, 'tw', [])) ? (
                        <Flex
                          height="100%"
                          justifyContent="center"
                          width="100%"
                          fontSize="12px"
                          alignItems="center"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => redirectToConfigure('tw')}
                        >
                          Please Connect to Twitter
                        </Flex>
                      ) : (
                        <RecipientsListBody height="168px">
                          <FilterRowChips
                            style={{ fontSize: '12px', padding: '5px 7px' }}
                          >
                            <label
                              className="checkbox-button"
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <input
                                type="checkbox"
                                className="checkbox-button__input"
                                name="draft"
                                onChange={() => handleCheckboxClick(0, 'tw')}
                                checked={
                                  !isEmpty(selectedTwContacts.filter((s) => !s))
                                }
                                id="select_all_tw"
                                value="news-feed"
                              />
                              <span
                                className="checkbox-button__control"
                                style={{
                                  cursor: 'pointer',
                                }}
                              ></span>
                              <span
                                className="checkbox-button__label"
                                style={{
                                  color: '#49484a',
                                  fontWeight: '100',
                                  cursor: 'pointer',
                                }}
                              >
                                On News Feed
                              </span>
                            </label>
                          </FilterRowChips>
                        </RecipientsListBody>
                      )}
                    </RecipientsListWrapper>
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </Flex>

        {/* {(curFlow === 'emailOnly' || curFlow === 'both') && (
          <>
            <hr style={{ width: '98%', margin: '20px auto' }} />
            <Flex
              flexDirection="column"
              width="100%"
              fontSize="14px"
              color="#000000"
              mr="15px"
              style={{ position: 'relative', padding: '0px 10px' }}
            >
              <Flex width="100%" style={{ fontWeight: '600' }}>
                CC (Optional)
              </Flex>
              <Flex width="100%" pt="8px">
                <SetObjectivesInput
                  placeholder="Type in email address(es) to be cc'ed, separated by commas"
                  value={ccList}
                  onChange={(e) => setCCList(e.target.value)}
                />
              </Flex>
            </Flex>
          </>
        )} */}
        {/* {curFlow !== 'socialOnly' && (
          <>
            <Flex
              flexDirection="row"
              display="flex"
              width="100%"
              fontSize="14px"
              color="#000000"
              mr="15px"
              style={{ position: 'relative', paddingTop: '33px', paddingLeft: '10px' }}
            >
              <Flex width="25%" style={{ fontWeight: '600', fontSize: '14px' }}>
                Subscription Type*
              </Flex>
              <Box width="40%" pt="8px" mb="10px" marginTop="-15px">
                <ReactSelect
                  menuPortalTarget={document.body}
                  menuPosition='fixed'
                  onChange={(newValue) => {
                    setSelectSubscriptionType(getNewOptionValue(newValue));

                  }}
                  options={subscriptionTypeOptions}
                  placeholder={'Select'}
                  styles={getSelectStyles()}
                  value={selectSubscriptionType}
                />
              </Box>
            </Flex>
            <Flex
              width="100%"
              fontSize="14px"
              color="#667085"
              mr="15px"
              style={{ position: 'relative', paddingLeft: '10px' }}
            >
              Note: Select the correct subscription type to ensure a successful campaign
            </Flex>
          </>
        )} */}
               {/* {isAdmin && createMode !== 'series' && (curFlow === 'emailOnly' || curFlow === 'socialOnly') && ( */}
        {/* {isAdmin && createMode !== "series" && get(setObjectivesTabData, 'campaignType', '') !== "series" && !isFromAddCampaign && ( */}

        <Flex pt="30px">
        {get(setObjectivesTabData, 'isCascaded', 'false') === 'false' && (
           <>
          <Flex
            flexDirection="column"
            fontSize="14px"
            color="#000000"
            mr="15px"
            ml="10px"
            style={{ position: 'relative' }}
          >
            <Flex style={{ fontWeight: '600' }}>Webinars (optional)</Flex>

            {webinar && (
              <Flex pt="15px">
               <ZoomContainer width="250px">
                  <LoaderWrapper isLoading={webinarListLoader}>
                    {webinarList &&
                      webinarList.map((item) => (
                        <Flex
                          alignItems="center"
                          justifyContent="flex-start"
                          marginRight="5px"
                          // ml="25px"
                        >
                          <RadioInput
                            type="radio"
                            name={`webinar${item.id}`}
                            id={`webinar${item.id}`}
                            onClick={() => handleRadioButtonClick(item.id)}
                            checked={item.id === selectedWebinar}
                            style={{ cursor: 'pointer' }}
                          />
                          <RadioInputLabel
                            htmlFor={`webinar${item.id}`}
                            style={{ cursor: 'pointer', color: 'gray' }}
                          >
                          <WebinarWrapper style={{'position' : 'relative'}}>
                            {item.topic.length > 20
                              ? item.topic.slice(0, 20) + '...'
                              : item.topic}
                            <span className="tooltip-text">{item.topic}</span>
                          </WebinarWrapper>
                          </RadioInputLabel>
                        </Flex>
                      ))}
                  </LoaderWrapper>
                  </ZoomContainer>
              </Flex>
            )}
          </Flex>

          <Flex
            width="62%"
            flexDirection="column"
            fontSize="10px"
            color="#6250ED"
            ml="30px"
            justifyContent="center"
            marginRight="5px"
          >
            <Flex style={{ fontWeight: '600' }}>
              {isEmpty(get(socialNetworkData, 'zm', [])) ? (
                <Flex
                  height="100%"
                  width="100%"
                  fontSize="12px"
                  alignItems="center"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => redirectToConfigure('zm')}
                >
                  Please Connect To Vendor
                </Flex>
              ) : (
                <>
                  <span
                    style={{
                      padding: '1px 0px 0px',
                      fontSize: '15px',
                      color: '#000',
                    }}
                  >
                    Add Webinar
                  </span>
                  <ToggleButton
                    className="react-switch"
                    onChange={(checked) => handleWebinarToggle(checked)}
                    checked={webinar}
                    onColor="#6351ed"
                    offColor="#ccc"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    handleDiameter={10}
                    uncheckedIcon=""
                    checkedIcon=""
                    boxShadow=""
                    activeBoxShadow=""
                    type="autoPost"
                    left="5px"
                    margin="-3px 5px 0px"
                    top="10px"
                  />
                </>
              )}
            </Flex>
            {webinar && (
              <Flex pt="13px">
                <ZoomContainer maxLength="1000" placeholder="">
                  <LoaderWrapper isLoading={webinarDetailsLoader}>
                  {
                      !webinarListLoader && (
                        isEmpty(webinarDetails) ? (
                      <Flex
                        height="100%"
                        justifyContent="center"
                        width="100%"
                        fontSize="12px"
                        alignItems="center"
                      >
                        {webinarErrorText}
                      </Flex>
                    )
                    
                    : (
                      <div style={{ fontSize: '13.5px' }}>
                        Selection: <b>{webinarDetails.topic}</b>
                        <br />
                        Date: <b>{webinarDetails.start_date}</b>
                        <br />
                        Time: <b>{webinarDetails.start_time} ({webinarDetails.timezone})</b>
                        <br />
                        Description: <b>{webinarDetails.agenda}</b>
                        <br />
                        Current Registrants: <b>{webinarDetails.total_registrants} Confirmed</b>
                        <br />
                        Joining URL: <b>{webinarDetails.join_url}</b>
                        <br />
                        Registartion URL:{' '}
                        <b style={{'wordBreak' : 'break-all'}}>{webinarDetails.registration_url}</b>
                      </div>
                    )
                      )
                  }
                  </LoaderWrapper>
                </ZoomContainer>
              </Flex>
            )}
          </Flex>
          
          </>
          )}
        </Flex>
        {(webinar && curFlow !== "socialOnly") && (
          <FilterRowChips>
            <Flex color={'black'} fontSize={12} pt={'10px'}>
              <label className="checkbox-button">
                <input
                  type="checkbox"
                  className="checkbox-button__input"
                  name="webinar_registrants"
                  value={webinar_registrants}
                  onChange={() => setWebinarRegistrants(!webinar_registrants)}
                  checked={webinar_registrants ? true : false}
                />
                <span className="checkbox-button__control"></span>
                <span className="checkbox-button__label">
                  Do not send this campaign to registrants
                </span>
              </label>
            </Flex>
          </FilterRowChips>
        )}

        
        {isAdmin && 
        !isEnterpriseLevelAdmin && 
        ( !isFirmLevelAdmin || 
          (isFirmLevelAdmin && get(firmSettings, 'firm_general.advisor_cascade_campaign', 'true') === 'true')
        ) && (
          <>
            <hr style={{ width: '98%', margin: '20px auto' }} />
            <Flex
              flexDirection="column"
              width="30%"
              fontSize="16px"
              color="#000000"
              style={{ padding: '0px 10px 10px' }}
            >
              <Flex width="100%" style={{ fontWeight: '800' }}>
                Member Network
              </Flex>
            </Flex>
            <Flex
              display="inline"
              vertical-align="middle"
              mr="15px"
              white-space="nowrap"
              style={{
                //position: 'relative',
                fontSize: '15px',
                color: '#000',
                pt: '8px',
              }}
            >
              <ToggleButton
                className="react-switch"
                onChange={(checked) => handleShareToggle(checked)}
                checked={approveToShare === 'true'}
                onColor="#6351ed"
                offColor="#ccc"
                offHandleColor="#fff"
                onHandleColor="#fff"
                handleDiameter={10}
                uncheckedIcon=""
                checkedIcon=""
                boxShadow=""
                activeBoxShadow=""
                type="autoPost"
                left="1px"
                margin="-3px 5px 0px"
                position="absolute"
                top="7px"
                disabled={
                  (seriesId ||
                    get(setObjectivesTabData, 'campaignType', '') ===
                      'series') &&
                  approveToShare === 'true' &&
                  seriesStatus !== 'draft'
                }
              />
              <Flex display="inline">Approve to Share</Flex>
              <IconDiv
                style={{
                  position: 'relative',
                  top: '-2px',
                  paddingLeft: '16px',
                  right: '9px',
                  color: '#6351ed',
                }}
                isClickable={
                  !(
                    (seriesId ||
                      get(setObjectivesTabData, 'campaignType', '') ===
                        'series') &&
                    approveToShare === 'true' &&
                    seriesStatus !== 'draft'
                  )
                }
                disable={
                  approveToShare === 'false' ||
                  ((seriesId ||
                    get(setObjectivesTabData, 'campaignType', '') ===
                      'series') &&
                    approveToShare === 'true' &&
                    seriesStatus !== 'draft')
                }
                className="icon dripicons-user-group"
                onClick={
                  approveToShare === 'false' ||
                  ((seriesId ||
                    get(setObjectivesTabData, 'campaignType', '') ===
                      'series') &&
                    approveToShare === 'true' &&
                    seriesStatus !== 'draft')
                    ? () => {}
                    : onClickHandler
                }
              />
            </Flex>
          </>
        )}
        {approveToShare === 'true' &&
          isEmpty(selectedAdvisors) &&
          !isFromAddCampaign && (
            <ErrorContainer style={{ marginTop: '3px', marginLeft: '5px' }}>
              Please select advisor/advisors from the user icon
            </ErrorContainer>
          )}

        {(seriesId ||
          get(setObjectivesTabData, 'campaignType', '') === 'series') &&
          approveToShare === 'true' &&
          seriesStatus !== 'draft' && (
            <ErrorContainer style={{ marginTop: '3px', marginLeft: '5px' }}>
              All Campaigns in this series will be approved to share
            </ErrorContainer>
          )}

        {isOpen && isEnterpriseLevelAdmin && (
          <CascadeModalEnterpriseAdmin
            firmsArray={firmsArray || []}
            closeHandler={closeModalHandler}
            selectedFirms={selectedAdvisors}
            setSelectedFirms={setSelectedAdvisors}
            cascadeOption={cascadeOption}
            setCascadeOption={setCascadeOption}
            OGSelectedFirms={OGSelectedAdvisors || []}
            loader={modalLoader}
            cascadeHandler={handleAdvisorShare}
            fetchingInfo={fetchingCascadeInfo}
          />
        )}

        {isOpen && !isEnterpriseLevelAdmin && (
          <Suspense fallback={null}>
            <MemberModal
              isOpen={isOpen}
              closeModalHandler={closeModalHandler}
              membersList={membersList}
              selectMembersHandler={selectMembersHandler}
              selectedAdvisors={selectedAdvisors}
              OGSelectedAdvisors={OGSelectedAdvisors}
              modalMessage={modalMessage}
              modalLoader={modalLoader}
              shareDisabled={shareDisabled}
              shareValidationCheck={shareValidationCheck}
              handleAdvisorShare={handleAdvisorShare}
              buttonName="Ok"
            />
          </Suspense>
        )}

        {confirmProceedModal && (
          <ConfirmProceedModal
            open={confirmProceedModal}
            clickHandler={() => {
              toggleProceed(true);
              toggleConfirmProceedModal(false);
              handleNextClick();
            }}
            close={() => toggleConfirmProceedModal(false)}
          />
        )}
      </Flex>
      {/* <FooterBtnsWrapper>
        <CampaignFooterButtons
          enableNext={!isOpen && !handleValidation()}
          nextHandler={handleNextClick}
          backHandler={handleBackButtonClick}
          loading={loading}
          saveAndCloseHandler={handleSaveAndClose}
          padding="10px 0px"
        />
      </FooterBtnsWrapper> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  const isAdmin = getUserRole(state) === 'admin';
  return {
    membersList: state.user.membersList && [
      {
        id: 'selectAll',
        details: {
          name: { email: 'Select All', first: 'Select', last: 'All' },
        },
      },
      ...state.user.membersList,
    ],
    emailData: get(state, 'user.data.details.user.email_personalization', null),
    isAdmin: isAdmin,
    campaignsDetailsData: state.campaignDetails.data,
    userData: state.user.data,
    campaignsList: state.campaign.data.campaigns,
    subscriptionType: state?.campaign?.subscriptionType
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SetObjectives);
