import React from 'react';

import { Flex } from '@rebass/grid';
import { CreateContainer } from '../CreateCampaign/CreateCampaign.styles';
import {
  ArticleTitle,
  ArticleDescription,
  ArticleLink,
} from './SocialPreview.styles';
import { onImageError } from '../../../utils';
import { get, isEmpty } from 'lodash';
import LeadCaptureOption from '../../../components/ShareContentContainerV2/Elements/LeadCaptureOption';
import {FileUpload} from '../../../components';

export const SelectedArticle = ({
  articleData,
  setSelectedContent,
  type = 'single',
  selectedContent,
  userData,
  pdfPresent,
  enableLeadGen,
  toggleLeadGen,
  fromSocialPreview,
  setObjectivesTabData,
  onFileUploadChange,
  setErrorMsg,
  idx,
}) => {
  const redirectToSelectedArticleURL = () => {
    if (articleData?.link) {
      window.open(articleData?.link, '_blank');
    }
  };
  console.log('articleData', articleData);
  return (
    <>
      {isEmpty(articleData) && (
        <Flex
          display="flex"
          flexDirection="row"
          padding="20px"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          color="#bbb"
          backgroundColor="#eee"
        >
          Content not selected
        </Flex>
      )}
      {!isEmpty(articleData) && (
        <>
          {type === 'multiple' && (
            <input
              type="radio"
              name="article"
              id="article"
              data-testid={`article_${idx}`}
              checked={articleData == selectedContent[0]}
              // defaultChecked={articleData == selectedContent[0]}
              style={{ alignSelf: 'flex-end' }}
              onClick={() => {
                setSelectedContent([articleData]);
              }}
            />
          )}
          <Flex
            display="flex"
            width="100%"
            flexDirection="row"
            padding="20px"
            style={{ position: 'relative' }}
            data-testid="selected_article"
          >
            <img
              alt=""
              src={articleData?.url}
              onError={onImageError}
              width="135px"
              height="110px"
              style={{
                position: 'relative',
                marginRight: '10px',
                marginLeft: '10px',
                borderRadius: '5px',
                objectFit: 'cover',
              }}
            />
            <Flex
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Flex
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Flex
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ArticleTitle>{articleData?.topic}</ArticleTitle>
                </Flex>
                <ArticleDescription>
                  {articleData?.description}
                </ArticleDescription>
              </Flex>
              <ArticleLink>
                {get(articleData, 'isWebinar', false) ? (
                  <a href={articleData?.link} target="_blank">
                    View Webinar Registration Page
                  </a>
                ) : (
                  (articleData.auxiliary
                    ? articleData.auxiliary.source
                      ? articleData.auxiliary.source != 'img'
                      : true
                    : true) && (
                    <a href={articleData?.link} target="_blank">
                      View article
                    </a>
                  )
                )}
              </ArticleLink>
            </Flex>
            <Flex
                  style={{
                    // justifyContent: 'center',
                    // width: '290px',
                    // position: 'absolute',
                    // right: '-5%',
                    // bottom: '0',
                    marginLeft: 'auto'
                  }}
                >
                 {
                    articleData?.isWebinar && 
          <Flex>
          {get(setObjectivesTabData, 'isCascaded', 'false') === 'false' && (
            <FileUpload
                asLabel
                margin="0px"
                name="logoUpload"
                id="logoUpload"
                label={"Upload Image"}
                onChange={(file) => onFileUploadChange(file)}
                styles={{ margin: '0px', padding : '6px' }}
                onError={(msg) => setErrorMsg(msg)}
                maxSizeMB={10}
                accept=".png,.jpeg,.jpg,.svg,.bmp"
                title="Maximum Image Size : 10MB, Maximum Image Width :165 Pixels"
              />
              )}
            </Flex>
                 }   
                </Flex>
            {userData &&
              userData.details &&
              userData.details.user &&
              userData.details.user.enable_lead_capture === 'true' &&
              pdfPresent &&
              fromSocialPreview && (
                <Flex
                  style={{
                    justifyContent: 'center',
                    width: '290px',
                    position: 'absolute',
                    right: '-5%',
                    bottom: '0',
                  }}
                >
                  <LeadCaptureOption
                    selectedLeadCaptureOption={enableLeadGen}
                    setSelectedLeadCaptureOption={toggleLeadGen}
                    leadStyles={{
                      color: '#000000',
                      padding: '5px 10px 0px',
                      fontSize: '14px',
                    }}
                    paddingTop="0px"
                    switchHandleTop="2.31px !important"
                    fromSocialPreview={true}
                    leadCaptureText="Lead Capture"
                  />
                </Flex>
              )}
          </Flex>
        </>
      )}
    </>
  );
};
