import { Box, Flex } from '@rebass/grid';
import React from 'react';
import { DripIconButton } from '../../../components';
import { SelectedContentContainer } from './AddContent.styles';
import OneColContent from './OneColContent';

const SelectedContent = ({
  selectedArticles,
  setIsSelectContent,
  handleDrag,
  handleDrop,
  setSelectedArticles,
}) => {
  

  const removeSelectedArticle = (id) => {
    let filteredArticles = selectedArticles.filter(
      (article) => article.id !== id
    );
    setSelectedArticles(filteredArticles);
  };
  

  return (
    <Flex flexDirection="column" width="250px">
      <Flex
        backgroundColor="white"
        p="10px"
        style={{
          borderBottom: '1px solid #d4e0e5',
        }}
        color="black"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box style={{ fontWeight: '600', fontSize: '0.9rem' }}>
          Selected Content
        </Box>
        
        <Box>
          <DripIconButton
            icon="cross"
            onClick={() => {
              setSelectedArticles([]);
              setIsSelectContent(false);
            }}
            styles={{ height: '24px', fontSize: '1rem', color: 'black' }}
          />
        </Box>
      </Flex>
      <SelectedContentContainer flexDirection="column" alignItems="center">
        {selectedArticles &&
          selectedArticles
            .sort((a, b) => a.order - b.order)
            .map((article) => (
              <OneColContent
                {...article}
                handleDrag={handleDrag}
                handleDrop={handleDrop}
                removeSelectedArticle={removeSelectedArticle}
              />
            ))}
      </SelectedContentContainer>
    </Flex>
  );
};

export default SelectedContent;
