import styled from 'styled-components';

import theme from '../CommonStyles/colors';
import { ResponsiveImg } from '../CommonStyles';
import { themesData } from '../../utils/constants';
import { Flex } from '@rebass/grid';

const { purpleishBlue } = themesData;

export const HeaderDiv = styled.div`
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid ${theme.color.border.primary};
  nav {
    height: 100%;
  }
  position: ${(props) => (props.screenWidth < 768 ? 'fixed' : 'static')};
  z-index: ${(props) => (props.screenWidth < 768 ? '11' : '0')};
  zoom: ${({ type }) =>
    type === 'analytics' ? '1' : type === 'campaign' ? 1.2 : ''};
  @media only screen and (min-width: 1400px) {
    zoom: ${({ type }) =>
      type === 'analytics' || type === 'campaign' ? 1.2 : ''};
  }

  @media only screen and (min-width: 1500px) {
    zoom: ${({ type }) =>
      type === 'analytics' || type === 'campaign' ? 1.3 : ''};
  }
`;

export const HeaderUl = styled.ul`
  display: flex;
  list-style: none;
  margin: 0px;
  height: 100%;
`;

export const HeaderLI = styled.li`
  height: 100%;
  margin: ${({ type }) => (type === 'sidebar' ? '1rem 1.5rem' : ' 0 1.5em')};
  a {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    ${({ selected }) =>
      selected ? `border-bottom: 2px solid ${purpleishBlue};` : ''}
  }
  i {
    display: block;
    width: 50px;
    font-size: 16px;
    img {
      width: 18px;
      height: 18px;
    }
  }
`;

export const HeaderLogo = styled(ResponsiveImg)`
  width: 111px;
  // width: 95px;
`;

export const HeaderLogoNew = styled(ResponsiveImg)`
  width: auto;
  // width: 95px;
`;

export const IconTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const ImpactTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const ImpactTooltip = styled.span.attrs({
  className: 'Impact-tooltip',
})`
  width: 140px;
  background-color: #49484a;
  color: white;
  text-align: center;
  border-radius: 3px;
  padding: 2px 4px;
  z-index: 10;
  position: absolute;
  top: 49px;
  font-size: 9px;
  box-shadow: -1px 1px 11px -1px #6351ed;
`;

export const SwitchContainer = styled(Flex)`
  border: 1px solid #cfd9e4;
  border-radius: 4px;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  width: 80%;
  ${'' /* padding: ${({ padding }) => padding || '9px'}; */}
  height: ${({ height }) => height || '470px'};
  ${'' /* margin-top: ${({ marginTop }) => marginTop || '35px'}; */}
  background-color: #f6f9fb;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
  ${'' /* margin-bottom: ${({ mb }) => mb || '25px'}; */}
`;

export const SwitchContainerCards = styled(Flex)`
  border: ${({ border }) => border || ''};  
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  width: 235px;
  padding: 20px;
  height: 280px;
  margin: 25px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  display: flex;
  flex-direction: column;
`;

export const CardText = styled(Flex)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const TitleSpan = styled(Flex)`
  color: #4e6780;
  font-size: 20px;
  margin-bottom: 15px;
`;

export const CardTitle = styled(Flex)`
  color: #000;
  font-size: 16px;
  font-weight: 800;
  margin-top: 5px;
`;

export const CardSubtitle = styled(Flex)`
  color: #708892;
  font-size: 11px;
  font-weight: 400;
  margin-top: 5px;
`;

export const DripFooterSection = styled.div.attrs({
  className: 'drip-footer-section',
})`
  display: flex;
  background-color: #fff;
  height: 10%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
