import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import NoContentLayout from '../../../components/EmailTemplates/NoContentTemplate';
import TemplateSelector from '../../../components/TemplateSelector';
import { FooterBtnsWrapper } from '../DripCampaignContainerV2.styles';
import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import CampaignNavigation from '../CampaignNavigation/CampaignNavigation';

import { get } from 'lodash';
import { updateCampaign } from '../../../services/campaign';
import CustomUserTemplate from '../../../components/EmailTemplates/CustomUserTemplate/CustomUserTemplate';
import {
  Button,
  LoaderWrapper,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../components';
import { useHistory } from 'react-router-dom';
import { NewsletterArticleWrapper } from '../../NewsletterContainer/NewsletterArticle/NewsletterArticle.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Stepper } from '../Stepper/Stepper';

const SelectTemplate = ({
  handleNext,
  handleBack,
  campaignSetState,
  loading,
  setTemplateData,
  setTemplateBase,
  endCreateFlow,
  campaignId,
  setLoading,
  setObjectivesTabData,
  defaultTemplates,
  curFlow,
  selectedIndex,
  stepList
}) => {
  const [selectedValue, setSelectedValue] = useState({
    name: '',
    baseTemplate: '',
    isCascade: false,
    owner: ''
  });
  const [warningModal, toggleWarningModal] = useState(false);
  const loneTemplate = !defaultTemplates || 
    defaultTemplates
    .filter(temp => (
      temp.name !== "no_content" && 
      temp.name !== "content" && 
      temp.original_template === "no-content"
    ))
    .sort((a, b) => new Date(b.created_time) - new Date(a.created_time)).length === 0;

    

  const history = useHistory();

  useEffect(() => {
    if (setTemplateData) {
      if (
        setTemplateData === 'no-content' ||
        setTemplateData === 'one-column' ||
        setTemplateData === 'two-columns'
      )
        setSelectedValue({
          name: setTemplateData,
          baseTemplate: setTemplateData,
          isCascade: false,
          owner: 'default'
        });
      else {
        const selectedTemplate = defaultTemplates.find(temp => temp.name === setTemplateData);
        const base_template = selectedTemplate ? selectedTemplate.original_template : '';
        const is_cascade = selectedTemplate ? selectedTemplate.is_cascade : '';
        const owner = selectedTemplate ? selectedTemplate.owner : '';
        setSelectedValue({
          name: selectedTemplate ? setTemplateData : '', 
          baseTemplate: base_template, 
          isCascade: is_cascade,
          owner: owner
        });
      }
    }
  }, []);

  const handleNextClick = () => {
    toggleWarningModal(false);
    setLoading(true);
    console.log('selectedValue',selectedValue);
    if (setTemplateData !== selectedValue.name) {
      campaignSetState({
        savedTemplate: null,
        setTemplate: selectedValue.name,
        setBaseTemplate: selectedValue.baseTemplate,
        setIsCascade: selectedValue.isCascade,
        owner: selectedValue.owner
      });
    } else
      campaignSetState({
        setTemplate: selectedValue.name,
        setBaseTemplate: selectedValue.baseTemplate,
        setIsCascade: selectedValue.isCascade,
        owner: selectedValue.owner
      });
    
    const selectedTemplate = defaultTemplates.find(template => {
      if(selectedValue.name === 'no-content')
        return template.name === 'no_content'
      else if(selectedValue.name === 'one-column' || selectedValue.name === 'two-columns')
        return template.name === 'content';
      else 
        return template.name === selectedValue.name;
    });
    const templateId = selectedTemplate.id;
    const payloadObj = {
      default_template: selectedValue.name,
      template_id: templateId
    };

    if(selectedTemplate.is_cascade)
      payloadObj.cascade_template_ref = selectedTemplate.cascade_template_ref

    // const baseTemplate = defaultTemplates
    //   .filter(temp => temp.created_by === 'user')
    //   .find(temp => temp.name === selectedValue).original_template;

    const payload = {
      content: JSON.stringify(payloadObj),
    };

    updateCampaign({ id: campaignId, payload })
      .then((response) => {
        setLoading(false);
        if (
          (selectedValue.name === 'no-content' ||
            selectedValue.baseTemplate === 'no-content') &&
          curFlow &&
          curFlow === 'email'
        )
          handleNext(false);
        else handleNext(false);
      })
      .catch((err) => {
        console.log('ERROR - - -', err);
        setLoading(false);
      });
  };

  const handleBackButtonClick = () => {
    handleBack();
  };

  const handleValidation = () => {
    return selectedValue.name.length > 0;
  };

  const handleSaveAndClose = () => {
    campaignSetState({
      setTemplate: selectedValue.name,
      setBaseTemplate: selectedValue.baseTemplate,
    });
    if (selectedValue.name) handleNextClick();
    endCreateFlow();
    history.push('/campaignV2');
  };

  const verifyTemplateChange = () => {
    if(setTemplateData && selectedValue.name && setTemplateData !== selectedValue.name)
      toggleWarningModal(true);
    else handleNextClick();
  };

  const closeModal = () => {
    setSelectedValue({ name: setTemplateData, baseTemplate: setTemplateBase });
    toggleWarningModal(false);
  };

  return (
    <Flex width="100%" padding="10px" flexDirection="column">
        <CampaignNavigation
         enableNext={handleValidation()}
          nextHandler={verifyTemplateChange}
          backHandler={handleBackButtonClick}
          loading={loading}
          saveAndCloseHandler={handleSaveAndClose}
          stepList={stepList}
          selectedIndex={selectedIndex}
      />
      <Flex
        width="100%"
        fontSize="14px"
        color="#969fa4"
        style={{ fontWeight: 600, fontSize: '1rem' }}
        justifyContent="center"
        m="10px 0"
      >
        Choose A Template For This Newsletter
      </Flex>
      <Flex justifyContent="flex-start" padding="10px 0px">
        {warningModal && (
          <Modal size='flexible' isOpen={warningModal} onRequestClose={closeModal}>
            <ModalHeader>
              <span style={{margin: '30px'}}>Changes will be lost!! Do you want to proceed?</span>
              <ModalCloseButton onClick={closeModal} />
            </ModalHeader>
            <ModalBody>
              <Flex width="100%" justifyContent="center">
                <Button
                  onClick={handleNextClick}
                  width="75px"
                  borderRadius="4px"
                  margin="7px 12px 0px 0px"
                >
                  Yes
                </Button>
                <Button
                  onClick={closeModal}
                  width="75px"
                  borderRadius="4px"
                  margin="7px 12px 0px 0px"
                >
                  No
                </Button>
              </Flex>
            </ModalBody>
          </Modal>
        )}
        <TemplateSelector
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          loneTemplate={loneTemplate}
        >
          <NoContentLayout
            value={{ name: 'no-content', baseTemplate: 'no-content', owner: 'default' }}
            isCampaign={true}
            id="no_template"
            loneTemplate={loneTemplate}
          />
          
          {defaultTemplates &&
            defaultTemplates
              .filter(temp => temp.name !== "no_content" && temp.name !== "content")
              .sort(
                (a, b) => new Date(b.created_time) - new Date(a.created_time)
              )
              .map((template) => (
                <CustomUserTemplate
                  template={template}
                  value={{
                    name: get(template, 'name', ''),
                    baseTemplate: get(template, 'original_template', ''),
                    isCascade: get(template, 'is_cascade', false),
                    owner: get(template, 'owner', '')
                  }}
                  isCampaign={true}
                  owner={get(template, 'owner', '')}
                />
              ))}
        </TemplateSelector>
      </Flex>
      {/* <FooterBtnsWrapper>
        <CampaignFooterButtons
          enableNext={handleValidation()}
          nextHandler={verifyTemplateChange}
          backHandler={handleBackButtonClick}
          loading={loading}
          saveAndCloseHandler={handleSaveAndClose}
          padding="10px 0px"
        />
      </FooterBtnsWrapper> */}
    </Flex>
  );
};
export default SelectTemplate;
