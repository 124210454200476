import React from 'react';
import { CampaignTab } from './Stepper.styles';

export const Stepper = ({ stepperList, selectedIndex, style }) => {
  return (
    <div style={style}>
      {stepperList &&
        stepperList.map((step, idx) => (
          <CampaignTab
            className={
              selectedIndex === idx
                ? 'active'
                : selectedIndex > idx
                ? 'inactive'
                : ''
            }
            disabled
            key={`_${idx}`}
          >
            {idx !== stepperList.length - 1 && (
              <div
                className={
                  selectedIndex > idx
                    ? 'line active'
                    : selectedIndex < idx
                    ? 'line'
                    : 'line inactive'
                }
              />
            )}
            <div
              className={
                selectedIndex === idx ? 'number-tag active' : 'number-tag'
              }
            >
              {idx + 1}
            </div>
            <div className="text">{step}</div>
          </CampaignTab>
        ))}
    </div>
  );
};
