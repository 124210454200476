import { Flex } from '@rebass/grid';
import { get } from 'lodash';
import React from 'react';
import { Button } from '../../../components';
import { lambdaEndpoints } from '../../../services/helpers';
import { oktaHelper } from '../../../utils';

const AddContentButtonV2 = ({ 
    predefinedArticleId, 
    step, 
    updateStep, 
    layout,
    onResolve,
    onReject,
    selectedCustomRow,
    selectedLandingPage,
    webinarData,
    userData,
    selectedArticles,
    handleValidation,
    createMode,
    automatedFlowFromDashboard,
    automatedCampaigns,
    campaignId
}) => {
    console.log(selectedLandingPage);
    const onResolveHandler = () => {
        if(predefinedArticleId && (layout === 'one-column' || layout === 'two-columns' || layout === 'one-column-reverse')) {
            if(step !== 1)
                updateStep(step + 1);
            else
                onResolve({
                    name: `Selected Articles`,
                    value: layout === 'webinar-block' ?
                        `${lambdaEndpoints.baseUrl}customized-webinar-rows?id=${selectedCustomRow}&webinarId=${get(webinarData, 'wuid', '')}&token=${window.sessionStorage.getItem('token') || oktaHelper.getAccessToken()}` :
                        `${lambdaEndpoints.baseUrl}customized-article-rows?layout=${selectedCustomRow}&articleId=${JSON.stringify(predefinedArticleId)}&uid=${get(userData, 'uid', '')}&cid=${get(userData, 'details.user.company', '')}`
                })
        } else if(layout === 'landing-pages' && step === 0 && (createMode === 'automated' || automatedFlowFromDashboard)) {
            updateStep(step + 2);
        }
        else {
            if(step === 1 && layout === 'webinar-block')
                onResolve({
                    name: `Selected Webinar Block`,
                    value: `${lambdaEndpoints.baseUrl}customized-webinar-rows?id=${selectedCustomRow}&webinarId=${get(webinarData, 'wuid', '')}&token=${window.sessionStorage.getItem('token') || oktaHelper.getAccessToken()}`
                });
            else if(step !== 2)
                updateStep(step + 1);
            else 
                onResolve({
                    name: `Selected Articles`,
                    value: layout === 'landing-pages' 
                    ? `${lambdaEndpoints.baseUrl}customized-web-page-row?pageUrl=${get(selectedLandingPage, 'redirect_page_url', 'www.google.com')}&rowId=${selectedCustomRow}&page_id=${get(selectedLandingPage, '_id', '')}&ac_id=${get(automatedCampaigns, 'campaignId', '')}&campaignId=${campaignId}` 
                    : `${lambdaEndpoints.baseUrl}customized-article-rows?layout=${selectedCustomRow}&articleId=${JSON.stringify(selectedArticles)}&uid=${get(userData, 'uid', '')}&cid=${get(userData, 'details.user.company', '')}`
                }); 
        }
    }

    return (
        <Flex justifyContent="center">
            <Button
                disabled={step === 0} 
                margin="20px 5px 20px 20px" 
                onClick={() => updateStep(step - 1)}
                borderRadius='4px'
                width='50%'
                bgColor='#ffffff'
                borderColor= '#E4E7EC'
                color= 'black'
            >
                Back
            </Button>
            <Button
                margin="20px 20px 20px 5px"
                onClick={onResolveHandler}
                disabled={handleValidation()}
                borderRadius='4px'
                width='50%'
                bgColor='#816EF8'
            >
                {step === 1 && layout === 'webinar-block' ? "Save" : step !== 2 ? 'Next' : 'Save'}
            </Button>
        </Flex>
    );
};

export default AddContentButtonV2;