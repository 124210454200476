import { Flex } from '@rebass/grid';
import React from 'react';
import { CampaignFilterButton } from '../DripDashboardFilter.styles';

const DripDashboardFilterTab = ({
  selected,
  tab,
  click,
  isadvMode,
  totalTabs,
  isEnterpriseAdvisor
}) => {
  return (
    <CampaignFilterButton
      isadvMode={isadvMode}
      selected={selected}
      onClick={click}
      name={tab.name}
      data-testid={'topTab-' + tab.name}
      totalTabs={totalTabs}
      paddingTop="2px"
      isEnterpriseAdvisor={isEnterpriseAdvisor}
    >
      <Flex justifyContent="center" alignItems="center">
        <div
          style={{
            height: '32px',
            // width: '45px',
            // paddingTop: selected ? '5px' : '2px',
            // objectFit: 'cover',
          }}
        >
          <img
            alt=""
            src={selected ? tab.img.active : tab.img.disabled}
            style={{ height: '35px', width: '35px' }}
          />
        </div>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          style={{ width: '65%' }}
        >
          <strong
            style={{ color: '#000000', paddingLeft: '5px', fontSize: '13px' }}
            data-testid={'topTab-' + tab.name + '-count'}
          >
            {tab.count}
          </strong>
        </Flex>
      </Flex>
      <Flex
        style={{ color: selected ? '#4e6780' : '#4E6780' }}
        flexDirection="column"
        alignItems="flex-start"
        padding="0px 10px"
        paddingTop={!selected && '7px'}
      >
        {tab.name?.length > 17 ? (
          tab.name?.split(' ').map((displayName) => <div>{displayName}</div>)
        ) : (
          <div>{tab.name}</div>
        )}
      </Flex>
    </CampaignFilterButton>
  );
};

export default DripDashboardFilterTab;
