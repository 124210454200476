import { Box, Flex } from '@rebass/grid';
import { camelCase, get, startCase, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState, Suspense } from 'react';
import { statusColorCodes } from '../CampaignInfo/CampaignInfo';
import { VerticalSeparator } from '../DripDashboardListItem/DripDashboardListItem.styles';
import AutomatedCampaignCascadeModal from './AutomatedCampaignCascadeModal';
import { AutomatedCampaignInfoWrapper } from './styles';
import { getUserRole } from '../../../../redux/selector';
import { actions as loginActions} from '../../../../redux/modules/login.module';
import { actions as campaignActions } from '../../../../redux/modules/campaign.module';
import { connect } from 'react-redux';
import {
  getCascadeInfoAC,
  postCascadeToAdvisorsAC,
  postCascadeToFirmAC,
} from '../../../../services/automated-campaigns';
import CascadeModalEnterpriseAdmin from '../../CascadeModalEnterpriseAdmin/CascadeModalEnterpriseAdmin';

const MemberModal = React.lazy(() => import('../../MemberModal/MemberModal'));

const AutomatedCampaignInfo = ({
  name,
  start_date,
  event_date,
  status,
  purpose,
  firmsArray,
  memberAdvisorsArray,
  membersList,
  isEnterpriseLevelAdmin,
  series_id,
  id,
  record_type,
  campaigns,
  type,
  isAdmin,
  approved_to_share,
  is_email,
  is_twitter,
  is_facebook,
  is_linkedin,
  campaignRequest,
  firmSettings,
  isFirmLevelAdmin,
  isEmailBlock = false,
  cascaded_advisors,
  isEnterpriseAdvisor,
  selectedCampaignTab,
  is_global_compliance,
  is_partially_completed
}) => {
  
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [OGSelectedAdvisors, setOGSelectedAdvisors] = useState([]);
  const [selectedFirmAdvisors, setSelectedFirmAdvisors] = useState([]);
  const [OGSelectedFirmAdvisors, setOGSelectedFirmAdvisors] = useState([]);
  const [cascadeOption, setCascadeOption] = useState('advisors');
  const [OGCascadeOption, setOGCascadeOption] = useState('');
  const [selectedFirms, setSelectedFirms] = useState([]);
  const [OGSelectedFirms, setOGSelectedFirms] = useState([]);
  const [modalLoader, toggleModalLoader] = useState(false);
  const [fetchingCascadeInfo, toggleFetchingCascadeInfo] = useState(false);
  const [shareDisabled, toggleShareDisabled] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [automatedCampaignCascadeModalOpen, setAutomatedCampaignCascadeModalOpen] = useState(false);
  const getFormattedDate = (date) =>
    date ? moment(date).format('DD MMM YYYY') : '-';

  const formated_status = () => {
    if(isEnterpriseAdvisor && selectedCampaignTab === 9) {
      return '-';
    } else if(status) {
      if(status === 'Completed (cancelled)') {
        return status;
      } else {
        return startCase(camelCase(status));
      }
    } else {
      return '-';
    }
  } 

  const cascadeClickHandler = async () => {
    toggleFetchingCascadeInfo(true);
    setAutomatedCampaignCascadeModalOpen(true);
    if(isEnterpriseLevelAdmin) {
      const cascadeInfo = await getCascadeInfoAC({ id });
      const firmIds = get(cascadeInfo, 'result.data.firm_ids', []);
      const cascadeToAdvisors = get(cascadeInfo, 'result.data.cascade_to_advisors', 'false') === 'true';
      setCascadeOption(cascadeToAdvisors ? 'advisors' : 'admins');
      setOGCascadeOption(cascadeToAdvisors ? 'advisors' : 'admins');
      console.log('CASCADE INFO - - - - ', cascadeInfo);
      const updateList = cascadeToAdvisors ? setSelectedFirmAdvisors : setSelectedFirms;
      const updateOGList = cascadeToAdvisors ? setOGSelectedFirmAdvisors : setOGSelectedFirms;
      updateList(firmIds);
      updateOGList(firmIds);
      toggleFetchingCascadeInfo(false);
    }
    setSelectedAdvisors([...cascaded_advisors]);
    setOGSelectedAdvisors([...cascaded_advisors]);
  }

  const closeModalHandler = () => {
    toggleShareDisabled(false);
    setModalMessage('');
    setAutomatedCampaignCascadeModalOpen(false);
    if (isEnterpriseLevelAdmin) setSelectedFirms([]);
    else setSelectedAdvisors([]);
  };

  // const handleAdvisorCheckboxChange = (e) => {
  //   const { value, checked } = e.target;
  //   console.log('FIRMS ARRAY - - - - - -- ', firmsArray);
  //   if (checked) {
  //     if (value === 'selectAll')
  //     setSelectedFirmAdvisors(firmsArray.map((firm) => firm.id));
  //     else setSelectedFirmAdvisors([...selectedFirmAdvisors, value]);
  //   } else {
  //     if (value === 'selectAll') setSelectedFirmAdvisors([]);
  //     else setSelectedFirmAdvisors(selectedFirmAdvisors.filter((id) => id !== value));
  //   }
  // }

  const handleEnterpriseLevelAdminShare = async () => {
    toggleModalLoader(true);
    setModalMessage('');
    if (cascadeOption === 'onlyAdvisors') 
      handleAdvisorShare();
    else {
      const payload = {
        firms: JSON.stringify(cascadeOption === 'advisors' ? selectedFirmAdvisors : selectedFirms),
        cascade_to_advisors: cascadeOption === 'advisors' ? 'true' : 'false',
      };
      try {
        const cascadeToFirmResponse = await postCascadeToFirmAC({
          payload,
          id,
        });
        toggleModalLoader(false);
        setModalMessage('Cascaded Successfully!');
        setTimeout(() => {
          setAutomatedCampaignCascadeModalOpen(false);
          setModalMessage('');
        }, 3000);
      }
      catch(err) {
        console.log('ERROR - - -- ', err);
        setModalMessage('Oops..Some error occurred! Please try again later.');
        toggleModalLoader(false);
      }
    }
  };

  const handleAdvisorShare = async () => {
    toggleModalLoader(true);
    let advisorList = selectedAdvisors;
    if (advisorList.includes('selectAll')) 
      advisorList = advisorList.filter((s) => s !== 'selectAll');
    const payload = {
      advisors: JSON.stringify(advisorList),
      is_completed: advisorList.length > 0 ? 'true' : 'false'
    }
    try {
      const cascadeToAdvResponse = await postCascadeToAdvisorsAC({ payload, id });
      if(get(cascadeToAdvResponse, 'result.success', false)) {
        toggleModalLoader(false);
        setModalMessage('Done!');
        toggleShareDisabled(true);
        setTimeout(() => {
          closeModalHandler();
          campaignRequest({ 
            type: ['automated'],
            limit: 10,
            offset: 1
          });
        }, 1500);
      }
      else {
        toggleModalLoader(false);
        setModalMessage('Campaign not shared. Check for errors');
      }
    }
    catch(err) {
      toggleModalLoader(false);
      setModalMessage('Campaign not shared. Check for errors');
    }
  };


  const shareValidationCheck = () => {
    let disableShare = true;
    if (selectedAdvisors.includes('selectAll'))
      disableShare =
        selectedAdvisors.filter((adv) => adv !== 'selectAll').length ===
        OGSelectedAdvisors.length;
    else if (selectedAdvisors.length !== OGSelectedAdvisors.length)
      disableShare = false;
    else
      disableShare = selectedAdvisors.every((adv) =>
        OGSelectedAdvisors.includes(adv)
      );
    return disableShare;
  };


  const selectMembersHandler = (e, selectedMember) => {
    let updatedAdvisorsList = [...selectedAdvisors];
    if (selectedMember.id === 'selectAll') {
      if (e.target.checked)
        updatedAdvisorsList = membersList.map((member) => member.id);
      else updatedAdvisorsList = [];
    } else if (selectedAdvisors.includes(selectedMember.id))
      updatedAdvisorsList = [...selectedAdvisors].filter(
        (advisorId) =>
          advisorId !== selectedMember.id && advisorId !== 'selectAll'
      );
    else {
      updatedAdvisorsList = [...selectedAdvisors, selectedMember.id];
      if (updatedAdvisorsList.length === membersList.length - 1)
        updatedAdvisorsList.push(membersList[0].id);
    }
    setSelectedAdvisors(updatedAdvisorsList);
  };

  return (
    <>
      <AutomatedCampaignInfoWrapper
        width={1}
        style={{ textAlign: 'left', marginLeft: '10px' }}
      >
        <Box className="campaign__name">{name}</Box>
        <Flex>
          {start_date || isEmailBlock ? (
            <Box className="date_holder">
              <span className="chunk_title">
                {isEmailBlock ? 'Date' : 'Start Date'}
              </span>{' '}
              <br />
              <span className="chunk_info">{getFormattedDate(start_date)}</span>
            </Box>
          ) : (
            <Box className="date_holder">
              <span className="chunk_title">Event Date</span> <br />
              <span className="chunk_info">{getFormattedDate(event_date)}</span>
            </Box>
          )}

          <VerticalSeparator height="45px" margin={'auto 10px auto 10px'} />
          <Box className="status_holder">
            <span className="chunk_title">Status</span> <br />
            <span
              className="chunk_info"
              style={{
                color: status ? statusColorCodes[status] : '#000000',
              }}
            >
              {formated_status()}
            </span>
            {is_global_compliance === 'true' && (
              <span
                style={{
                  width: '50px',
                  backgroundColor: '#49484a',
                  color: '#fff',
                  borderRadius: '3px',
                  padding: '3px 6px',
                  fontSize: '10px',
                  marginLeft: '8px',
                }}
              >
                Global Approved
              </span>
            )}
          </Box>
          <VerticalSeparator height="45px" margin={'auto 10px auto 10px'} />
          <Box className="type_holder">
            <span className="chunk_title">Type</span> <br />
            <Flex alignItems={'center'} marginTop="5px">
              <img
                alt=""
                src={'/assets/images/drip/content_copy.svg'}
                style={{
                  height: '20px',
                  marginRight: '10px',
                }}
              />
              <span className="chunk_info">Automated</span>
            </Flex>
          </Box>
          <VerticalSeparator height="45px" margin={'auto 10px auto 10px'} />
          {isEmailBlock && (
            <>
              <Box className="type_holder">
                <span className="chunk_title">Platforms</span> <br />
                <Flex alignItems={'center'} marginTop="5px">
                  <img
                    src="/assets/images/campaign-platform-email.svg"
                    alt=""
                    style={{
                      width: '25px',
                      height: '25px',
                    }}
                  />
                </Flex>
              </Box>
              <VerticalSeparator height="45px" margin={'auto 10px auto 10px'} />
            </>
          )}
          <Box width="30%">
            <span className="chunk_title">Purpose</span> <br />
            <span className="chunk_info" title={purpose}>
              {!isEmailBlock
                ? purpose
                : purpose
                ? purpose.length > 50
                  ? purpose.substring(0, 50).trim() + '...'
                  : purpose
                : '-'}
            </span>
          </Box>
          {/* {(isEnterpriseLevelAdmin || isAdmin) &&
            status === 'active' && 
            !isEmailBlock && (
              <Flex justifyContent="end" alignItems="flex-start" width="20%">
                <img
                  src="assets/images/forward.svg"
                  alt=""
                  onClick={cascadeClickHandler}
                  style={{
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                  }}
                />
              </Flex>
            )} */}
        </Flex>
      </AutomatedCampaignInfoWrapper>

      {automatedCampaignCascadeModalOpen && isEnterpriseLevelAdmin && (
        <CascadeModalEnterpriseAdmin 
          firmsArray={firmsArray || []}
          memberAdvisorsArray={memberAdvisorsArray}
          closeHandler={closeModalHandler}
          selectedFirms={selectedFirms}
          setSelectedFirms={setSelectedFirms}
          selectedFirmAdvisors={selectedFirmAdvisors || []}
          setSelectedFirmAdvisors={setSelectedFirmAdvisors}
          OGSelectedFirmAdvisors={OGSelectedFirmAdvisors}
          cascadeOption={cascadeOption}
          OGCascadeOption={OGCascadeOption}
          setOGCascadeOption={setOGCascadeOption}
          setCascadeOption={setCascadeOption}
          OGSelectedFirms={OGSelectedFirms}
          loader={modalLoader}
          cascadeHandler={handleEnterpriseLevelAdminShare}
          handleAdvisorShare={handleAdvisorShare}
          fetchingInfo={fetchingCascadeInfo}
          modalMessage={modalMessage}
          membersList={membersList}
          shareDisabled={shareDisabled}
          shareValidationCheck={shareValidationCheck}
          selectMembersHandler={selectMembersHandler}
          selectedAdvisors={selectedAdvisors}
          OGSelectedAdvisors={OGSelectedAdvisors}
        />  
        // <AutomatedCampaignCascadeModal
          
        // />
      )}
      {automatedCampaignCascadeModalOpen && !isEnterpriseLevelAdmin && (
        <Suspense fallback={null}>
          <MemberModal
            isOpen={automatedCampaignCascadeModalOpen}
            closeModalHandler={closeModalHandler}
            membersList={membersList}
            selectMembersHandler={selectMembersHandler}
            selectedAdvisors={selectedAdvisors}
            OGSelectedAdvisors={OGSelectedAdvisors}
            modalMessage={modalMessage}
            modalLoader={modalLoader}
            shareDisabled={shareDisabled}
            shareValidationCheck={shareValidationCheck}
            handleAdvisorShare={handleAdvisorShare}
            buttonName="Share"
            isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          />
        </Suspense>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const isAdmin = getUserRole(state) === 'admin'
  const isEnterpriseLevelAdmin = isAdmin && get(state, 'user.data.details.company.enterprise', "false") === "true";
  const isFirmLevelAdmin = isAdmin && get(state, 'user.data.details.company.firm', "false") === "true";
  return {
      isAdmin: getUserRole(state) === 'admin',
      membersList: state.user.membersList ? [
          {
            id: 'selectAll',
            details: {
              name: { email: 'Select All', first: 'Select', last: 'All' },
            },
          },
          ...state.user.membersList,
      ] : [],
      isEnterpriseLevelAdmin,
      isFirmLevelAdmin,
      firmsArray: get(state, 'user.data.details.company.firms', []),
  };
}

const mapDispatchToProps = {
  campaignSetState: campaignActions.api.campaign.setstate,
  loginSetState: loginActions.api.login.setstate,
}

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedCampaignInfo);
