import React, { useEffect, useState } from 'react';
import { Flex, Box } from '@rebass/grid';

import {
  faLinkedinIn,
  faTwitter,
  faGoogle,
  faFacebook
} from '@fortawesome/free-brands-svg-icons';
import { get, find, isEmpty } from 'lodash';
import { getSocialAccountURLs } from '../../../services/dashboard';
import { connect } from 'react-redux';

import { getUserId } from '../../../redux/selector';
import { actions as profileActions } from '../../../redux/modules/profile.module';
import { actions as loginActions } from '../../../redux/modules/login.module';
import { actions as socialNetworkActions } from '../../../redux/modules/socialNetwork.module';
import { updateProfile, emailSmtpConnect } from '../../../services/configure';
import { company, ROLE_TYPES } from '../../../utils/constants';

import {
  LoaderWrapper,
  SocialLoginButtons,
} from '../../../components';

import { ToggleButton } from '../screenConfigure.styles';
import { EmailSettingsModal } from '../../../components/';
import SendgridModal from '../../../containers/ConfigureContainer/SendgridModal/SendgridModal';
 
const Platforms = ({
  userData,
  socialAccountURL,
  setSocialAccountURL,
  roleType,
  userId,
  loginRequest,
  socialNetworkData,
  socialNetworkDelete,
  socialNetworkDataFetching,
  socialNetworkRequest,
}) => {
  const [allowSharingOnMyBehalf, setAllowSharingOnMyBehalf] = useState(
    get(userData.sharing, 'allow_sharing_on_my_behalf', '')
  );
  const [allowAutoschedulingOnMyBehalf, setAllowAutoschedulingOnMyBehalf] =
    useState(get(userData.sharing, 'allow_autoscheduling_on_my_behalf', ''));
  const [emailModal, setEmailModal] = useState(false);
  const [sendGridModal, toggleSendGridModal] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  useEffect(() => {
    getSocialAccountURLs()
      .then((res) => {
        setSocialAccountURL(res.result.data);
      })
      .catch((err) => {});
    window.sessionStorage.setItem('selectedIndex', 1);
  }, [setSocialAccountURL]);

  const update = async (data) => {
    const payload = {
      userId,
      data,
    };
    return new Promise(async (resolve, reject) => {
      const { result } = await updateProfile(payload);
      if (result.success) {
        await loginRequest();
        resolve({ success: true });
      } else {
        reject({ success: true });
      }
    });
  };

  const handleSharingOnMyBehalf = async (checked) => {
    setAllowSharingOnMyBehalf(checked);
    await update({ allow_sharing_on_my_behalf: checked });
  };

  const handleAutoSchedulingOnMyBehalf = async (checked) => {
    setAllowAutoschedulingOnMyBehalf(checked);
    await update({ allow_autoscheduling_on_my_behalf: checked });
  };

  const handleSocialIconClick = (id, network, isDisconnected) => {
    if (isDisconnected) {
      window.sessionStorage.removeItem('platform');
      get(socialNetworkData, id, []).forEach((item) => {
        if (
          (network && network === item.snetworkProvider) ||
          network.length === 0
        ) {
          socialNetworkDelete({
            params: {
              id: `${id}_${item.accountId}`,
            },
          });
        }
      });
    } else {
      if (id === 'ln') {
        window.sessionStorage.setItem('platform', 'ln');
      }
      let link = get(socialAccountURL, `${id}.oauth_url.${network}`, '');
      if (!link) {
        link = get(socialAccountURL, `${id}.oauth_url`, '');
      }

      if (link) {
        window.sessionStorage.setItem('selectedIndex', 1);
        if (link) window.location.href = link;
      }
    }
  };

  const getBottomText = (platform, emailPlaftform) => {
    let platformDetails = get(socialNetworkData, platform, []);
    if (emailPlaftform) {
      platformDetails = find(platformDetails, {
        snetworkProvider: emailPlaftform,
      });
    }
    const text = isEmpty(platformDetails) ? 'Connect' : 'Disconnect';
    let account = '';
    if (platform !== 'em' && platformDetails && platformDetails.length > 0) {
      if (platform === 'tw') {
        account = platformDetails[0].name.account;
      } else if (platform === 'ln' || platform === 'fb') {
        account =
          platformDetails[0].name.first + ' ' + platformDetails[0].name.last;
      }
    }
    if (
      platform === 'em' &&
      platformDetails &&
      (emailPlaftform === 'google' ||
        emailPlaftform === 'outlook' ||
        emailPlaftform === 'smtp')
    ) {
      account = platformDetails.email;
    }
    if (
      (platform === 'sg' || platform === 'zm') &&
      platformDetails &&
      platformDetails.length > 0
    ) {
      account = platformDetails[0].email;
    }
    return {
      bottomText: text,
      isDiconnected: text === 'Disconnect',
      account,
    };
  };

  const onBottomTextClick = (
    e,
    id,
    platform,
    emailPlaftform,
    isDiconnected
  ) => {
    e.stopPropagation();
    if (isDiconnected) {
      window.sessionStorage.removeItem('platform');
      get(socialNetworkData, platform, []).forEach((item) => {
        if (
          (emailPlaftform && emailPlaftform === item.snetworkProvider) ||
          !emailPlaftform
        ) {
          socialNetworkDelete({
            params: {
              id: `${platform}_${item.accountId}`,
            },
          });
        }
      });
    } else {
      handleSocialIconClick(id, isDiconnected, platform, emailPlaftform);
    }
  };
  const handleEmailModalSubmit = (payload) => {
    console.log(payload);
    const data = {
      encryption: payload.port,
      name: payload.name,
      password: payload.password,
      port: payload.smtpPort,
      snetwork: 'em',
      snetwork_provider: 'smtp',
      url: payload.smtpHost,
      username: payload.email,
    };

    emailSmtpConnect(data)
      .then((res) => {
        if (res && res.result.success) {
          setEmailModal(false);
          socialNetworkRequest();
        }
      })
      .catch((err) => {
        setEmailErrorMessage(err.message);
      });
  };

  const onEmailClick = (isDisconnected) => {
    if (isDisconnected) {
      get(socialNetworkData, 'em', []).forEach((item) => {
        if ('smtp' === item.snetworkProvider) {
          socialNetworkDelete({
            params: {
              id: `em_${item.accountId}`,
            },
          });
        }
      });
    } else {
      setEmailModal(true);
    }
  };

  const onSendGridClick = (isDisconnected) => {
    if (isDisconnected) toggleSendGridModal(true);
  };
  return (
    <div>
      <Flex
        width={`${window.innerWidth < 768 ? '81%' : '85%'}`}
        mx="auto"
        pt="20px"
      >
        <Box width={1} px={2} mt={2} mb={2}>
          <div>Connect my digital platforms</div>
          <LoaderWrapper isLoading={socialNetworkDataFetching}>
            <SocialLoginButtons
              onBottomTextClick={onBottomTextClick}
              buttons={[
                {
                  name: 'LinkedIn',
                  faIcon: faLinkedinIn,
                  bottomText: getBottomText('ln').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'ln',
                      '',
                      getBottomText('ln').isDiconnected
                    ),
                  isDiconnected: getBottomText('ln').isDiconnected,
                  platform: 'ln',
                  account: getBottomText('ln').account,
                },

                {
                  name: 'Twitter',
                  faIcon: faTwitter,
                  bottomText: getBottomText('tw').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'tw',
                      '',
                      getBottomText('tw').isDiconnected
                    ),
                  isDiconnected: getBottomText('tw').isDiconnected,
                  platform: 'tw',
                  account: getBottomText('tw').account,
                },
                {
                  name: 'Facebook',
                  faIcon: faFacebook,
                  bottomText: getBottomText('fb').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'fb',
                      '',
                      getBottomText('fb').isDiconnected
                    ),
                  isDiconnected: getBottomText('fb').isDiconnected,
                  platform: 'fb',
                  account: getBottomText('fb').account,
                },
                {
                  name: 'Google',
                  faIcon: faGoogle,
                  bottomText: getBottomText('em', 'google').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'em',
                      'google',
                      getBottomText('em', 'google').isDiconnected
                    ),
                  isDiconnected: getBottomText('em', 'google').isDiconnected,
                  platform: 'em',
                  emailPlaftform: 'google',
                  account: getBottomText('em', 'google').account,
                },
                {
                  name: 'Outlook',
                  imgUrl: '/assets/images/outlook-disconnected.png',
                  bottomText: getBottomText('em', 'outlook').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'em',
                      'outlook',
                      getBottomText('em', 'outlook').isDiconnected
                    ),
                  isDiconnected: getBottomText('em', 'outlook').isDiconnected,
                  iconHeight: 50,
                  platform: 'em',
                  emailPlaftform: 'outlook',
                  account: getBottomText('em', 'outlook').account,
                },

                {
                  name: 'Zoom',
                  imgUrl: getBottomText('zm').isDiconnected
                    ? '/assets/images/Zoom-connected.svg'
                    : '/assets/images/Zoom-disconnected.svg',
                  bottomText: getBottomText('zm').bottomText,
                  onClick: () =>
                    handleSocialIconClick(
                      'zm',
                      '',
                      getBottomText('zm').isDiconnected
                    ),
                  isDiconnected: getBottomText('zm').isDiconnected,
                  iconHeight: 50,
                  iconWidth: 100,
                  platform: 'zm',
                  account: getBottomText('zm').account,
                },

                {
                  name: 'Email',
                  imgUrl: getBottomText('em', 'smtp').isDiconnected
                    ? '/assets/images/email.svg'
                    : '/assets/images/envelope-gray.png',
                  bottomText: getBottomText('em', 'smtp').bottomText,
                  onClick: () =>
                    onEmailClick(getBottomText('em', 'smtp').isDiconnected),
                  isDiconnected: getBottomText('em', 'smtp').isDiconnected,
                  platform: 'em',
                  emailPlaftform: 'smtp',
                  iconHeight: 50,
                  account: getBottomText('em', 'smtp').account,
                },
                {
                  name: 'Sendgrid',
                  imgUrl:
                    socialNetworkData &&
                    socialNetworkData['sg'] &&
                    socialNetworkData['sg'].length > 0 &&
                    socialNetworkData['sg'][0].verification_status ===
                      'verified' &&
                    socialNetworkData['sg'][0].status === 'active'
                      ? '/assets/images/SendGrid.svg'
                      : '/assets/images/SendGrid-grey.svg',
                  bottomText:
                    socialNetworkData &&
                    socialNetworkData['sg'] &&
                    socialNetworkData['sg'].length > 0 &&
                    socialNetworkData['sg'][0].verification_status ===
                      'verified' &&
                    socialNetworkData['sg'][0].status === 'active'
                      ? 'Disconnect'
                      : 'Connect',
                  onClick: () => onSendGridClick(true),
                  isDiconnected:
                    socialNetworkData &&
                    socialNetworkData['sg'] &&
                    socialNetworkData['sg'].length > 0 &&
                    socialNetworkData['sg'][0].verification_status ===
                      'verified' &&
                    socialNetworkData['sg'][0].status === 'active'
                      ? true
                      : false,
                  platform: 'sg',
                  iconHeight: 50,
                  account: getBottomText('sg').account,
                },
              ]}
            />
          </LoaderWrapper>
          <Box mt={2} mb={20}>
            &nbsp;{' '}
            <span style={{ fontSize: '14px' }}>
              {company.name} Recommendations?
            </span>{' '}
            &nbsp;{' '}
            <ToggleButton
              className="react-switch"
              onChange={handleAutoSchedulingOnMyBehalf}
              checked={allowAutoschedulingOnMyBehalf}
              onColor="#6351ed"
              offColor="#cccccc"
              offHandleColor="#fff"
              onHandleColor="#fff"
              handleDiameter={12}
              uncheckedIcon=""
              checkedIcon=""
              boxShadow=""
              activeBoxShadow=""
              type="configure"
            />
            &nbsp;&nbsp;
            {roleType &&
              (roleType === 'ADVISOR_ENTERPRISE' ||
                roleType === 'ADVISOR_ASSET_MANAGER') && (
                <>
                  <span style={{ fontSize: '14px' }}>
                    Allow Company to Post?
                  </span>{' '}
                  &nbsp;{' '}
                  <ToggleButton
                    className="react-switch"
                    onChange={handleSharingOnMyBehalf}
                    checked={allowSharingOnMyBehalf}
                    onColor="#6351ed"
                    offColor="#cccccc"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    handleDiameter={12}
                    uncheckedIcon=""
                    checkedIcon=""
                    boxShadow=""
                    activeBoxShadow=""
                    type="configure"
                  />
                </>
              )}
          </Box>
        </Box>
      </Flex>
      <EmailSettingsModal
        open={emailModal}
        handleClose={() => setEmailModal(false)}
        handleModalSubmit={handleEmailModalSubmit}
        emailErrorMessage={emailErrorMessage}
      />
      {sendGridModal ? (
        <SendgridModal
          adminModal={roleType === ROLE_TYPES.ADVISOR_PREMIUM ? true : false}
          isOpen={sendGridModal}
          handleClose={() => toggleSendGridModal(false)}
        />
      ) : null}

    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: getUserId(state),
  socialNetworkData: state.socialNetwork.data,
  socialNetworkDataFetching: state.socialNetwork.isFetching,
});

const mapDispatchToProps = {
  profileUpdateRequest: profileActions.api.profile.request,
  loginRequest: loginActions.api.login.request,
  socialNetworkDelete: socialNetworkActions.api.socialNetwork.delete,
  socialNetworkRequest: socialNetworkActions.api.socialNetwork.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Platforms);
