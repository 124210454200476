/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  LoaderWrapper,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../components';
import { getCampaignDetails, getSeriesSubCampaigns } from '../../../services/campaign';
import { getMediaImage, themesData } from '../../../utils';
import CustomizeCampaign from '../../AutomatedCampaigns/CampaignCreateSteps/CustomizeCampaign';
import { CreateContainer } from '../CreateCampaign/CreateCampaign.styles';
import { Chips } from '../SocialPreview/SocialPreview.styles';
import {
  PreviewContainer,
  ReviewBody,
  ReviewHeader,
  TemplateContainer,
} from './Modal.styles';

const PreviewModal = ({
  isOpen,
  handleClose,
  campaignId,
  seriesId,
  type,
  subCampaigns,
  isCompliance,
  complianceCampaignData,
  compliancePlatform,
  diffHtml
}) => {
  console.log('HANDLE CLOSE - - -- ', handleClose)
  const [loading, setLoading] = useState(false);
  const [activePlatform, setActivePlatform] = useState('fb');
  const [curPlatformCampaign, setCurPlatformCampaign] = useState({});
  const iframeRef = useRef(null);

  //   const platformMapper = {
  //     fb: 'Facebook',
  //     tw: 'Twitter',
  //     em: 'Email',
  //     ln: 'Linkedin',
  //   };

  const curComplianceCampaignHandler = (resObj) => {
    let emList = get(resObj, 'posts.email', []);
    let twList = get(resObj, 'posts.twitter', []);
    let lnList = get(resObj, 'posts.linkedin', []);
    let fbList = get(resObj, 'posts.facebook', []);

    const templateHTML = get(resObj, 'template_html', '');
    let fetchedArticles = [];

    if (get(resObj, 'posts_details.fb', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.fb[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.tw', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.tw[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.ln', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.ln[0].articles.feed[0]', {})
      );
    else fetchedArticles = get(resObj, 'newsletter.articles.feed', []);

    let socialArticleId = '';

    if (get(resObj, 'posts.facebook.articles[0].id', ''))
      socialArticleId = get(resObj, 'posts.facebook.articles[0].id', '');
    else if (get(resObj, 'posts.twitter.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.twitter.articles[0].id', '');
    else if (get(resObj, 'posts.linkedin.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.linkedin.articles[0].id', '');

    // console.log('fetchedArticles', fetchedArticles);

    let campaign = {
      attachment: get(resObj, 'attachment', ''),
      template: templateHTML,
      subject: get(resObj, 'customized_data.subject', ''),
      em: {
        platform: get(resObj, 'is_email', 'false') === 'true',
      },
      selectedArticles: fetchedArticles
        .filter((article) => article.id === socialArticleId)
        .map((article) => ({
          id: article.id,
          url: getMediaImage(get(resObj, 'media_urls', []), 'original'),
          themeId: get(article, 'ids.theme[0]', ''),
          topic: get(article, 'auxiliary.title', ''),
          link: get(article, 'links.social_permalink', ''),
        })),
      fb: {
        platform: get(resObj, 'is_facebook', 'false') === 'true',
        summary: get(resObj, 'posts.facebook.override_text', ''),
      },
      tw: {
        platform: get(resObj, 'is_twitter', 'false') === 'true',
        summary: get(resObj, 'posts.twitter.override_text', ''),
      },
      ln: {
        platform: get(resObj, 'is_linkedin', 'false') === 'true',
        summary: get(resObj, 'posts.linkedin.override_text', ''),
      },
    };
    return campaign;
  };

  const curCampaignDetailsHandler = (resObj) => {
    let emList = get(resObj, 'email', []);
    let twList = get(resObj, 'twitter', []);
    let lnList = get(resObj, 'linkedin', []);
    let fbList = get(resObj, 'facebook', []);

    const templateHTML = get(resObj, 'template.template_html', '');
    let fetchedArticles = [];

    if (get(resObj, 'posts_details.fb', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.fb[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.tw', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.tw[0].articles.feed[0]', {})
      );
    else if (get(resObj, 'posts_details.ln', false))
      fetchedArticles.push(
        get(resObj, 'posts_details.ln[0].articles.feed[0]', {})
      );
    else fetchedArticles = get(resObj, 'newsletter.articles.feed', []);

    let socialArticleId = '';

    if (get(resObj, 'posts.facebook.articles[0].id', ''))
      socialArticleId = get(resObj, 'posts.facebook.articles[0].id', '');
    else if (get(resObj, 'posts.twitter.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.twitter.articles[0].id', '');
    else if (get(resObj, 'posts.linkedin.articles[0].id', false))
      socialArticleId = get(resObj, 'posts.linkedin.articles[0].id', '');

    // console.log('fetchedArticles', fetchedArticles);

    let campaign = {
      id: get(resObj, 'id', ''),
      campaign_id: get(resObj, 'campaign_id', ''),
      attachment: get(resObj, 'attachment', ''),
      template: templateHTML,
      subject: get(resObj, 'newsletter.customized_data.subject', ''),
      action: get(resObj, 'feedback.email.action', ''),
      em: {
        platform:
          !isEmpty(emList) || get(resObj, 'is_email', 'false') === 'true',
        timings:
          resObj?.posts?.email?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.email.schedule_time', []),
      },
      selectedArticles: fetchedArticles
        .filter((article) => article.id === socialArticleId)
        .map((article) => ({
          id: article.id,
          url: getMediaImage(get(resObj, 'media_urls', []), 'original'),
          themeId: get(article, 'ids.theme[0]', ''),
          topic: get(article, 'auxiliary.title', ''),
          link: get(article, 'links.social_permalink', ''),
        })),
      action: get(resObj, 'feedback.social.action', ''),
      fb: {
        timings:
          resObj?.posts?.facebook?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.facebook.schedule_time', []),
        platform:
          !isEmpty(fbList) || get(resObj, 'is_facebook', 'false') === 'true',
        summary: get(resObj, 'posts.facebook.override_text', ''),
      },
      tw: {
        timings:
          resObj?.posts?.twitter?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.twitter.schedule_time', []),
        platform:
          !isEmpty(twList) || get(resObj, 'is_twitter', 'false') === 'true',
        summary: get(resObj, 'posts.twitter.override_text', ''),
      },
      ln: {
        timings:
          resObj?.posts?.linkedin?.schedule === 'immediate'
            ? [{ time: 'Immediate' }]
            : get(resObj, 'posts.linkedin.schedule_time', []),
        platform:
          !isEmpty(lnList) || get(resObj, 'is_linkedin', 'false') === 'true',
        summary: get(resObj, 'posts.linkedin.override_text', ''),
      },
      url: get(resObj, 'webinar_custom_image', ''),
    };
    return campaign;
  };

  const curPlatformCampaignHandler = (activeCampaign) => {
    console.log('ACTIVE CAMPAIGN - - - - ', activeCampaign);
    if (activeCampaign) {
      setCurPlatformCampaign(activeCampaign);
      if (activeCampaign.em && activeCampaign.em.platform) {
        setActivePlatform('em');
      } else {
        for (let key in activeCampaign) {
          if (activeCampaign[key] && activeCampaign[key].platform) {
            setActivePlatform(key);
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      let resObj = {};
      console.log(complianceCampaignData);
      if (get(complianceCampaignData, 'platform', '') === 'web_page') {
        setCurPlatformCampaign({
          template: get(complianceCampaignData, 'template_html', ''),
        });
        setActivePlatform('em');
      } else if (get(complianceCampaignData, 'source_snetwork', '')) {
        setCurPlatformCampaign({
          template: get(complianceCampaignData, 'template_html', ''),
        });
        setActivePlatform('em');
      }
      else if (campaignId && !isCompliance) {
        //   setCurrentCampaignID(campaignId);
        setLoading(true);
        getCampaignDetails(campaignId)
          .then((res) => {
            setLoading(false);
            resObj = get(res, 'result.data', {});

            let campaign = curCampaignDetailsHandler(resObj);
            //   setPlatformCampaigns(campaigns);

            curPlatformCampaignHandler(campaign);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } 
      else if (type === 'series' && !isCompliance) {
        let filteredSubCampaigns = [];
        if(!isEmpty(subCampaigns))
          filteredSubCampaigns = subCampaigns.filter(campaign => campaign.status === 'completed');
        else {
          const res = await getSeriesSubCampaigns({ id: seriesId });
          const fetchedSubCampaigns = get(res, 'result.data.campaigns', []);
          filteredSubCampaigns = fetchedSubCampaigns.filter(campaign => campaign.status === 'completed')
        }
        let totalCampaigns = [];
        filteredSubCampaigns.forEach(campaign => {
          let campaigns = curCampaignDetailsHandler(campaign);
          totalCampaigns.push(...campaigns);
        });
        //   setPlatformCampaigns(totalCampaigns);
        curPlatformCampaignHandler(totalCampaigns[0]);
      } else if (isCompliance) {
        let campaign = curComplianceCampaignHandler(complianceCampaignData);
        curPlatformCampaignHandler(campaign);
      }
    })();
  }, [campaignId, type, subCampaigns, isCompliance]);

  useEffect(() => {
    if (activePlatform === 'em') {
      setTimeout(() => {
        if (iframeRef.current) {
          var css = document.createElement('style');
          var st = '::-webkit-scrollbar { width: 5px }';
          st += ' ::-webkit-scrollbar-track { background: #e9f6e6 !important }';
          st += '::-webkit-scrollbar-thumb { background: #bccdd4 !important }';
          css.appendChild(document.createTextNode(st));
          iframeRef.current.contentDocument.head.append(css);
        }
      }, 100);
    }
  }, [activePlatform]);

  return (
    <Modal
      onRequestClose={handleClose}
      isOpen={isOpen}
      size="medium"
      height={isCompliance && '95%'}
    >
      <ModalHeader paddingBottom={isCompliance && '0px'}>
        <div
          data-testid="campaign-preview-modal"
          style={{
            color: themesData.charcoalGrey,
            fontSize: isCompliance ? '1rem' : '1.5rem',
          }}
        >
          Preview
        </div>
        <ModalCloseButton onClick={handleClose} />
      </ModalHeader>

      <ModalBody
        style={{
          height: isCompliance && '85%',
          overflowY: isCompliance && 'auto',
        }}
        padding={isCompliance && '0px 15px'}
      >
        <LoaderWrapper isLoading={loading}>
          <CreateContainer
            justifyContent="flex-start"
            color="black"
            marginTop="5px"
            marginBottom="5px"
            height={isCompliance ? '58vh' : '68vh'}
            style={{ backgroundColor: 'white', width: '100%' }}
          >
            <PreviewContainer>
              {activePlatform === 'em' && (
                <div style={{ height: '85%' }}>
                  {get(complianceCampaignData, 'platform', '') !==
                    'web_page' && (
                    <div style={{ marginBottom: '10px' }}>
                      Subject: {curPlatformCampaign?.subject || ''}
                    </div>
                  )}

                  <TemplateContainer style={{ height: isCompliance && '46vh' }}>
                    <iframe
                      srcDoc={diffHtml ? diffHtml : curPlatformCampaign?.template}
                      frameBorder="no"
                      ref={iframeRef}
                      title='email_preview'
                    />
                  </TemplateContainer>
                </div>
              )}
              {(activePlatform === 'fb' ||
                activePlatform === 'ln' ||
                activePlatform === 'tw') && (
                <div
                  style={
                    curPlatformCampaign &&
                    curPlatformCampaign.attachment &&
                    curPlatformCampaign.attachment.length > 0
                      ? { height: '85%', cursor: 'pointer' }
                      : { height: '85%' }
                  }
                  onClick={
                    curPlatformCampaign &&
                    curPlatformCampaign.attachment &&
                    curPlatformCampaign.attachment.length > 0
                      ? () =>
                          window.open(curPlatformCampaign?.attachment, '_blank')
                      : null
                  }
                >
                  <Flex
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    mb="10px"
                  >
                    <img
                      alt="social"
                      src={
                        !isEmpty(curPlatformCampaign) &&
                        !isEmpty(get(curPlatformCampaign, 'url', ''))
                          ? get(curPlatformCampaign, 'url', '')
                          : !isEmpty(curPlatformCampaign.selectedArticles) ?
                            curPlatformCampaign.selectedArticles[0].url : ''
                      }
                      style={{
                        // width: 'inherit',
                        height: '200px',
                        borderRadius: '5px',
                      }}
                    />
                  </Flex>
                  <ReviewBody
                    fontWeight="700"
                    fontSize={isCompliance ? '1rem' : '1.1rem'}
                  >
                    {!isEmpty(curPlatformCampaign.selectedArticles) &&
                      curPlatformCampaign.selectedArticles[0].topic}
                  </ReviewBody>
                  <ReviewBody
                    fontSize={isCompliance ? '0.8rem' : '0.9rem'}
                    style={{
                      marginTop: isCompliance ? '5px' : '10px',
                      height: isCompliance ? '15%' : '17vh',
                      overflowY: 'auto',
                    }}
                  >
                    {curPlatformCampaign[activePlatform]?.summary}
                  </ReviewBody>
                </div>
              )}
              <div
                style={{
                  marginTop: '20px',
                  position: 'absolute',
                  bottom: ' 0',
                  left: '0px',
                }}
              >
                <Flex display="flex" flexDirection="column">
                  <Flex
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{
                      backgroundColor: '#eee',
                      borderRadius: '5px',
                      margin: '10px',
                      width: 'fit-content',
                    }}
                  >
                    {curPlatformCampaign.em && curPlatformCampaign.em.platform && (
                      <Chips
                        onClick={() => {
                          setActivePlatform('em');
                        }}
                        active={activePlatform == 'em'}
                        // style={{ marginRight: '5px' }}
                      >
                        <div className="chip" style={{ cursor: 'pointer' }}>
                          <img
                            src="/assets/images/campaign-platform-email.svg"
                            alt=""
                          />
                          Email
                        </div>
                      </Chips>
                    )}
                    {curPlatformCampaign.fb && curPlatformCampaign.fb.platform && (
                      <Chips
                        onClick={() => {
                          setActivePlatform('fb');
                        }}
                        active={activePlatform == 'fb'}
                        // style={{ marginRight: '5px' }}
                      >
                        <div className="chip">
                          <img
                            src="/assets/images/campaign-platform-facebook.png"
                            alt=""
                          />
                          Facebook
                        </div>
                      </Chips>
                    )}
                    {curPlatformCampaign.ln && curPlatformCampaign.ln.platform && (
                      <Chips
                        onClick={() => {
                          setActivePlatform('ln');
                        }}
                        active={activePlatform == 'ln'}
                        // style={{ marginRight: '5px' }}
                      >
                        <div className="chip">
                          <img
                            src="/assets/images/campaign-platform-linkedIn.png"
                            alt=""
                          />
                          LinkedIn
                        </div>
                      </Chips>
                    )}
                    {curPlatformCampaign.tw && curPlatformCampaign.tw.platform && (
                      <Chips
                        onClick={() => {
                          setActivePlatform('tw');
                        }}
                        active={activePlatform == 'tw'}
                        // style={{ marginRight: '5px' }}
                      >
                        <div className="chip">
                          <img
                            src="/assets/images/campaign-platform-twitter.png"
                            alt=""
                          />
                          Twitter
                        </div>
                      </Chips>
                    )}
                  </Flex>
                </Flex>
              </div>
            </PreviewContainer>
          </CreateContainer>
          <div
            style={{
              position: isCompliance && 'absolute',
              left: isCompliance && '0',
              right: isCompliance && '0',
            }}
          >
            <Button
              onClick={handleClose}
              margin={isCompliance && '8px'}
              fontSize={isCompliance && '10px'}
            >
              Close
            </Button>
          </div>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  );
};

export default PreviewModal;
