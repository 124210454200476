import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { Button } from '..';
import { Modal, ModalBody, ModalCloseButton } from '..';
import { ROLE_TYPES, themesData } from '../../utils';
import {
  SwitchContainerCards,
  CardTitle,
  CardSubtitle,
  CardText,
} from './Header.styles';

const SwitchProfileModal = ({
  handleModalClose,
  userData,
  loginRequest,
  roleType,
}) => {
  console.log(roleType);
  const changeUser = (token, role) => {
    if (!isEmpty(token)) {
      window.sessionStorage.setItem('token', token);
      sessionStorage.removeItem('advisorToken');
      loginRequest({ token: token, type: 'multi' });
      if (role === 'advisor') {
        setTimeout(() => {
          window.open('/dashboard', '_self');
        }, 2000);
      } else {
        window.open('/admin/dashboard', '_self');
      }
    }
  };
  return (
    <Modal
      // onRequestClose={handleModalClose}
      shouldCloseOnOverlayClick
      isOpen
      size="medium77"
      bgColor="#f6f9fb"
    >
      <ModalCloseButton onClick={handleModalClose} />
      <ModalBody>
        <Flex justifyContent="center" alignItems="center">
          {/* <SwitchContainer> */}
          {/* <TitleSpan>Create A Campaign</TitleSpan> */}
          <Flex
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
          >
            <SwitchContainerCards
              border={
                roleType === ROLE_TYPES.ADVISOR_ENTERPRISE
                  ? `2px solid ${themesData.purpleishBlue}`
                  : ''
              }
            >
              <CardText>
                <CardTitle>Advisor</CardTitle>
                <CardSubtitle style={{ textAlign: 'left' }}>
                  The View of on individual advisor to select Content,
                  personalize, and Send Camapigns. Campaigns can be created and
                  sent from this view.
                </CardSubtitle>
              </CardText>
              <Button
                borderRadius="5px"
                disabled={isEmpty(
                  get(userData, 'details.user.impersonate.tokens.general', '')
                )}
                onClick={() =>
                  changeUser(
                    get(
                      userData,
                      'details.user.impersonate.tokens.general',
                      ''
                    ),
                    'advisor'
                  )
                }
              >
                Select
              </Button>
            </SwitchContainerCards>
            <SwitchContainerCards
              border={
                roleType === ROLE_TYPES.ADMIN_COMPLIANCE
                  ? `2px solid ${themesData.purpleishBlue}`
                  : ''
              }
            >
              <CardText>
                <CardTitle>Compliance</CardTitle>
                <CardSubtitle style={{ textAlign: 'left' }}>
                  Approve and view the compliance of Clout Campaigns Campaigns
                  cannot be created or sent from this view
                </CardSubtitle>
              </CardText>
              <Button
                borderRadius="5px"
                disabled={isEmpty(
                  get(
                    userData,
                    'details.user.impersonate.tokens.compliance',
                    ''
                  )
                )}
                onClick={() =>
                  changeUser(
                    get(
                      userData,
                      'details.user.impersonate.tokens.compliance',
                      ''
                    ),
                    'admin'
                  )
                }
              >
                Select
              </Button>
            </SwitchContainerCards>
            <SwitchContainerCards
              border={
                roleType === ROLE_TYPES.ADMIN_ENTERPRISE
                  ? `2px solid ${themesData.purpleishBlue}`
                  : ''
              }
            >
              <CardText>
                <CardTitle>Administrator</CardTitle>
                <CardSubtitle style={{ textAlign: 'left' }}>
                  Manage all advisors within the organization Create campaigns
                  and cascade to 1 or more advisors. Upload and manage all
                  content
                </CardSubtitle>
              </CardText>
              <Button
                borderRadius="5px"
                disabled={isEmpty(
                  get(userData, 'details.user.impersonate.tokens.admin', '')
                )}
                onClick={() =>
                  changeUser(
                    get(userData, 'details.user.impersonate.tokens.admin', ''),
                    'admin'
                  )
                }
              >
                Select
              </Button>
            </SwitchContainerCards>
          </Flex>
          {/* </SwitchContainer> */}
        </Flex>
        {/* <Button onClick={handleModalClose}>Close</Button> */}
      </ModalBody>
    </Modal>
  );
};

export default SwitchProfileModal;
