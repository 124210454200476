import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../components';
import { themesData } from '../../../utils';
import { CreateContainer } from '../CreateCampaign/CreateCampaign.styles';
import { SelectedArticle } from './SelectedArticle';

const SelectContentPopup = ({
  handleClose,
  campaignSelectedArticles,
  setSelectedContent,
  selectedContent,
}) => {
  return (
    <Modal
      bgColor="white"
      height="70%"
      size="big83"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      isOpen
    >
      <ModalHeader
        id="header"
        style={{ textAlign: 'center', color: themesData.charcoalGrey }}
      >
        <Flex fontSize="0.9rem" color="black" style={{ fontWeight: '600' }}>
          Select Content
        </Flex>
        {/* <ModalCloseButton onClick={handleClose} size="25px" /> */}
        <hr style={{ width: '100%' }} />
      </ModalHeader>
      <ModalBody style={{ overflowY: 'auto', height: 'inherit' }}>
        {/* <Flex
          width="100%"
          justifyContent="space-evenly"
          style={{ fontSize: '1rem', color: 'black', fontWeight: '600' }}
          mb="10px"
          flexDirection="column"
        > */}
        {!isEmpty(campaignSelectedArticles) &&
          campaignSelectedArticles.map((item, index) => {
            return (
              <CreateContainer
                marginTop="0px"
                marginBottom="5px"
                height="186px"
                alignItems="flex-start"
                justifyContent="flex-start"
                onClick={() => {
                  setSelectedContent([item]);
                }}
                style={{
                  cursor: 'pointer',
                  border:
                    !isEmpty(selectedContent) &&
                    selectedContent[0] === item &&
                    '1px solid #0075ff',
                }}
              >
                <SelectedArticle
                  articleData={item}
                  setSelectedContent={setSelectedContent}
                  type="multiple"
                  selectedContent={selectedContent}
                  idx={index}
                />
              </CreateContainer>
            );
          })}
        {/* </Flex> */}

        <div
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            padding: '10px',
          }}
        >
          <hr style={{ width: '100%' }} />
          <Flex justifyContent="flex-end" py="10px">
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#4E6780"
              margin="0px 10px"
              borderColor="#4E6780"
              onClick={() => {
                handleClose(false);
              }}
              testId="article-select-cancel"
            >
              Cancel
            </Button>
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#33b21b"
              margin="0px 10px"
              borderColor="#33b21b"
              disabled={isEmpty(selectedContent)}
              onClick={() => handleClose(true)}
              testId="article-select-save"
            >
              Save
            </Button>
          </Flex>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SelectContentPopup;
