import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import qs from 'querystring';
import {
  TabFour,
  TabsThree,
  TabListThree,
} from '../../components/Tabs/TabsTypeThree';
import { TopLevelTabPanel } from '../../components';
import { AdvisorImpactTab } from './ImpactTab';
import ActivityTab from './ActivityTab';
import {
  actions as analyticsActions,
  moreActions as analyticsMoreActions,
} from '../../redux/modules/analytics.module';
import EnagagementTab from './EnagagementTab';
import LeadCaptureTab from './LeadCaptureTab/LeadCaptureTab';
import { getRoleType } from '../../redux/selector';
import { ROLE_TYPES } from '../../utils';


//AnalyticsFilter
import AnalyticsFilter from './AnalyticsFilter/AnalyticsFilter';
import { getContactsGroups } from '../../services/configure';
import { getCampaignAnalytics } from '../../services/analytics';
 
const styles = {
  tabTypeThree: {
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  buttonNoBorderBackground: { paddingTop: '3px' },
  tabListThree: { padding: '10px 10% 0 29%', border: '1px solid #e5e5e5', borderBottom: 'none', height: '50px' },
}

const AdvisorAnalyticsContainer = ({
  selectedDate,
  setSelectedDate,
  analyticsRequest,
  analyticsData,
  isAnalyticsDataFetching,
  analyticsObject,
  selectedRepType,
  selectedAnalyticsTabIndex,
  setAnalyticsTabIndex,
  userData,
  analyticsSetState,
  isAssetAdvisor,
  contactParam,
  campaignsParam
}) => {
  const [tabIndex, setTabIndex] = useState(selectedAnalyticsTabIndex);
  const [contactGroup, setContactGroup] = useState([]);
  const [campaignAnalyticsData, setCampaignAnalyticsData] = useState([]);

  const handleTabIndex = (index) => {
    setTabIndex(index);
    setAnalyticsTabIndex({ selectedTabIndex: index });
  }

  const [filters, setFilters] = useState();

  useEffect(() => {
    const { tab } = qs.parse(
      get(window.location, 'search', '').replace('?', '')
    );
    if (tab === 'activity') {
      setTabIndex(1);
    }

    //Get Contact Groups
    getContactsGroups()
      .then((res) => {
        if (res.result.success) {
          let contacts = res.result.data.filter((contact) => {
            return ![
              'GMAIL',
              'HUBSPOT',
              'OUTLOOK',
              'WEALTHBOX',
              'SALESFORCE',
              'REDTAIL',
            ].includes(get(contact, 'name')) && get(contact, 'contacts.count', 0) > 0;
          });
          setContactGroup(contacts);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCampaignAnalytics()
    .then(res => {
      if(res.result.success)
        setCampaignAnalyticsData(res.result.data)
    })
    .catch(err => console.log('ERROR CAMPAIGN ANALYTICS - - - -', err));
  }, []);

  useEffect(() => {
    setTabIndex(selectedAnalyticsTabIndex);
  }, [selectedAnalyticsTabIndex])

  return (
    <TabsThree selectedIndex={tabIndex} onSelect={handleTabIndex} forceRenderTabPanel>
      <TabListThree style={styles.tabListThree}>
        <TabFour padding='5px 0px !important' style={styles.tabTypeThree} currentTab={tabIndex === 0} type='analytics-tab'>Activity</TabFour>
        <TabFour padding='5px 0px !important' style={styles.tabTypeThree} currentTab={tabIndex === 1} type='analytics-tab'>Impact</TabFour>
        <TabFour padding='5px 0px !important' style={styles.tabTypeThree} currentTab={tabIndex === 2} type='analytics-tab'>Engagement</TabFour>
        <TabFour padding='5px 0px !important' style={styles.tabTypeThree} currentTab={tabIndex === 3} type='analytics-tab'>Leads</TabFour>
      </TabListThree>
      {tabIndex === 0 && (
        <TopLevelTabPanel style={{
          borderLeft: "1px solid #e4e4e4",
          borderRight: "1px solid #e4e4e4",
          position: 'relative'
        }}>
          <AnalyticsFilter
            setFilters={setFilters}
            filters={filters}
            analyticsSetState={analyticsSetState}
            selectedDate={selectedDate}
            selectedRepType={selectedRepType}
            analyticsRequest={analyticsRequest}
            contactGroup={contactGroup}
            isAdmin={false}
            contactParam={contactParam}
            campaignsParam={campaignsParam}
            campaignData={campaignAnalyticsData}
          />
          <ActivityTab
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            analyticsRequest={analyticsRequest}
            analyticsData={analyticsData}
            isAnalyticsDataFetching={isAnalyticsDataFetching}
            analyticsObject={analyticsObject}
            selectedRepType={selectedRepType}
          />
        </TopLevelTabPanel>
      )}
      {tabIndex === 1 && (
        <TopLevelTabPanel style={{
          borderLeft: "1px solid #e4e4e4",
          borderRight: "1px solid #e4e4e4",
          position: 'relative'
        }}>
          <AnalyticsFilter
            setFilters={setFilters}
            filters={filters}
            analyticsSetState={analyticsSetState}
            selectedDate={selectedDate}
            selectedRepType={selectedRepType}
            analyticsRequest={analyticsRequest}
            contactGroup={contactGroup}
            isAdmin={false}
            contactParam={contactParam}
            campaignsParam={campaignsParam}
            campaignData={campaignAnalyticsData}
          />
          <AdvisorImpactTab
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            analyticsRequest={analyticsRequest}
            analyticsData={analyticsData}
            isAnalyticsDataFetching={isAnalyticsDataFetching}
            analyticsObject={analyticsObject}
            selectedRepType={selectedRepType}
            contactGroup={contactGroup}
          />
        </TopLevelTabPanel>
      )}
      {tabIndex === 2 && (
        <TopLevelTabPanel style={{
          overflow: 'visible',
          borderLeft: "1px solid #e4e4e4",
          borderRight: "1px solid #e4e4e4",
          position: 'relative'
        }}>
          <AnalyticsFilter
            setFilters={setFilters}
            filters={filters}
            analyticsSetState={analyticsSetState}
            selectedDate={selectedDate}
            selectedRepType={selectedRepType}
            analyticsRequest={analyticsRequest}
            contactGroup={contactGroup}
            isAdmin={false}
            contactParam={contactParam}
            campaignsParam={campaignsParam}
            campaignData={campaignAnalyticsData}
          />
          <EnagagementTab
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedRepType={selectedRepType}
            analyticsRequest={analyticsRequest}
            analyticsData={analyticsData}
            isAnalyticsDataFetching={isAnalyticsDataFetching}
            analyticsObject={analyticsObject}
            setAnalyticsTabIndex={setAnalyticsTabIndex}
            analyticsSetState={analyticsSetState}
          />
        </TopLevelTabPanel>
      )}
      {tabIndex === 3 && (
        <TopLevelTabPanel style={{
          borderLeft: "1px solid #e4e4e4",
          borderRight: "1px solid #e4e4e4",
          position: 'relative'
        }}>
          <AnalyticsFilter
            setFilters={setFilters}
            filters={filters}
            analyticsSetState={analyticsSetState}
            selectedDate={selectedDate}
            selectedRepType={selectedRepType}
            analyticsRequest={analyticsRequest}
            contactGroup={contactGroup}
            isAdmin={false}
            contactParam={contactParam}
            campaignsParam={campaignsParam}
            campaignData={campaignAnalyticsData}
          />
          <LeadCaptureTab
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedRepType={selectedRepType}
            analyticsRequest={analyticsRequest}
            analyticsData={analyticsData}
            isAnalyticsDataFetching={isAnalyticsDataFetching}
            analyticsObject={analyticsObject}
            userData={userData}
            isAssetAdvisor={isAssetAdvisor}
          />
        </TopLevelTabPanel>
      )}
    </TabsThree>
  );
};

const mapStateToProps = (state) => {
  const analyticsData = state.analytics.data || [];
  const roleType = getRoleType(state);
  const isAssetAdvisor = roleType === ROLE_TYPES.ADVISOR_ASSET_MANAGER;
  var contactArr = [];
  if(state.analytics.filterParams){
    if(state.analytics.filterParams.contactParam) contactArr = state.analytics.filterParams.contactParam
  }
  return {
    selectedDate: state.analytics.selectedDate,
    analyticsData,
    isAnalyticsDataFetching: state.analytics.isFetching,
    analyticsObject: state.analytics,
    selectedRepType: state.analytics.repType,
    selectedAnalyticsTabIndex: state.analytics.selectedTabIndex,
    userData: state.user.data,
    isAssetAdvisor,
    contactParam: contactArr,
    campaignsParam: get(state, 'analytics.filterParams.campaignsParam', [])
  };
};

const mapDispatchToProps = {
  setSelectedDate: analyticsMoreActions.custom.analytics.selectDate,
  analyticsRequest: analyticsActions.api.analytics.request,
  setAnalyticsTabIndex: analyticsActions.api.analytics.selectedtabindex,
  analyticsSetState: analyticsActions.api.analytics.setstate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvisorAnalyticsContainer);
