import { handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';
import { getContactListForEmail, getContactListDefaultState } from '../../utils';
import { get } from 'lodash';

export const actions = createApiActions('contacts');

const reducer = handleActions(
  {
    [actions.api.contacts.request]: (state) => ({
      ...state,
      isFetching: true,
      isError: false,
    }),
    [actions.api.contacts.create]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.api.contacts.response]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: false,
      data: payload.data,
      pagination: payload.pagination,
      navigateData: payload.navigateData,
      afterData: payload.afterData,
      error: null,
      groupData: getContactListForEmail(payload.groupData),
      selectedContactGroup: payload.newContactGroup.length
        ? payload.newContactGroup
        : get(
            getContactListForEmail(payload.groupData),
            `[${
              getContactListForEmail(payload.groupData).length
                ? getContactListForEmail(payload.groupData).length - 1
                : 0
            }].id`,
            ''
          ),
      selectedContactGroupDefault: getContactListDefaultState(payload.groupData, payload.newContactGroup),
      contactGroups: payload.groupData,
      customContactGroupList: payload.groupData.filter(c => c.type === 'custom'),
      totalCount: payload.totalCount 
    }),
    [actions.api.contacts.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isError: true,
      error: payload,
    }),
    [actions.api.contacts.setstate]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [actions.api.contacts.selectedtabindex]: (state, { payload }) => ({
      ...state,
     ...payload,
    }),
  },
  { ...apiModuleDefaultState, data: [], groupData: [], selectedTabIndex: 0,error: null }
);

export default reducer;
