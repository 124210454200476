import React, { useState, useRef } from 'react';
import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Button,
  DownloadSampleDiv,
  Input,
} from '../..';
import './SegmentContactListModal.css';
import { LoaderWrapper } from '../../Loader';
import { ModalV2 } from '../../Modal';
import FileUpload from '../../FileUpload';
import Papa from 'papaparse';
import { isEmpty, includes } from 'lodash';
import { useEffect } from 'react';
import { ButtonNoBorderBackground } from '../../CommonStyles';
import { objectToFormData } from '../../../services/helpers';
import { Flex } from '@rebass/grid';

import {
  createSegmentContactList,
  uploadCsvContactList,
  createSegmentAndUploadCsvContactList,
  listAllTopics,
} from '../../../services/segment';
import { parse } from 'json2csv';

const SegmentContactListModal = ({
  isModalOpen,
  setModalOpen,
  userData,
  userId,
  retrieveGroupsList,
}) => {
  const [loader, setLoader] = useState(false);

  const [segmentName, setSegmentName] = useState('');
  const dropdownMenuRef = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [segmentErrorVisible, setSegmentErrorVisible] = useState(false);
  const [segmentErrorMessage, setSegmentErrorMessage] = useState('');

  const [file, setFile] = useState('');
  const [csvListNamePresent, setCsvListNamePresent] = useState(false);

  const [tagsPresent, setTagsPresent] = useState(false);
  const [tagModal, enableTagModal] = useState(false);
  const [topicTagsArr, setTopicTagsArr] = useState([]);
  const [contactListId, setContactListId] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [searchResultsArr, setSearchResultsArr] = useState([]);

  const [selectedArr, setSelectedArr] = useState([]);

  const [contactType, setContactType] = useState('default');
  const [emailListNamePresent, setEmailListNamePresent] = useState(false);
  const [submitButton, enableSubmitButton] = useState(false);
  const [contactTypeFlag1, setContactTypeFlag1] = useState(false);
  const [contactTypeFlag2, setContactTypeFlag2] = useState(false);
  const [recipients, setRecipients] = useState([
    { email: '', first_name: '', last_name: '', valid: false },
    { email: '', first_name: '', last_name: '', valid: false },
    { email: '', first_name: '', last_name: '', valid: false },
  ]);

  const addRecipientRow = () => {
    const recipientsList = [...recipients];
    recipientsList.push({
      email: '',
      first_name: '',
      last_name: '',
      valid: false,
    });
    setRecipients(recipientsList);
  };

  const inputChangeHandler = (source, index) => {
    const recipientsList = [...recipients];
    recipientsList[index][source.target.name] = source.target.value;
    let contact = recipientsList[index];
    if (source.target.name === 'email') {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (pattern.test(contact.email)) contact.valid = true;
      else contact.valid = false;
    }
    setRecipients(recipientsList);
  };

  const handleClickOutside = (e) => {
    if (
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(e.target)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputBlurHandler = (source, index) => {
    const recipientsList = [...recipients];
    let contact = recipientsList[index];
    if (source.target.name === 'email') {
      if (!contact.valid && contact.email) contact.hasError = true;
      else contact.hasError = false;
    }
    setRecipients(recipientsList);
  };

  const deleteRecipientRow = (index) => {
    const recipientsList = [...recipients];
    recipientsList.splice(index, 1);
    setRecipients(recipientsList);
  };

  const contactTypeFlag1Handler = () => {
    if (contactTypeFlag1) {
      setContactTypeFlag1(!contactTypeFlag1);
      setContactType('default');
    } else {
      setContactTypeFlag1(!contactTypeFlag1);
      setContactTypeFlag2(false);
      setContactType('prospect');
    }
  };

  const contactTypeFlag2Handler = () => {
    if (contactTypeFlag2) {
      setContactTypeFlag2(!contactTypeFlag2);
      setContactType('default');
    } else {
      setContactTypeFlag2(!contactTypeFlag2);
      setContactTypeFlag1(false);
      setContactType('client');
    }
  };

  const errorHandler = (payload) => {
    console.log('ERROR::', payload);
    setSegmentErrorVisible(true);
    setSegmentErrorMessage(
      payload.message || 'Network Error: Please Try again Later'
    );
    setTimeout(() => {
      setLoader(false);
      enableSubmitButton(false);
    }, 2000);
  };

  const getEmailListCsv = (values) => {
    const csvData = parse(values, {
      fields: ['first_name', 'last_name', 'email'],
    });
    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const csvFile = new File([csvBlob], 'email_list.csv', {
      type: 'text/csv',
      lastModified: new Date(),
    });
    return csvFile;
  };

  const submitHandler = () => {
    setLoader(true);
    let selectedArrPayload = [...selectedArr];
    if (contactType !== 'default') selectedArrPayload.push(contactType);

    var filePayload = objectToFormData({
      tags: JSON.stringify(selectedArrPayload),
      media1:
        recipients.filter((ele) => ele.valid).length > 0
          ? getEmailListCsv(recipients.filter((ele) => ele.valid))
          : file[0],
    });

    if (!emailListNamePresent) {
      uploadCsvContactList(contactListId, filePayload)
        .then((res) => {
          setLoader(false);
          setModalOpen(false);
          retrieveGroupsList();
        })
        .catch((err) => {
          errorHandler(err);
        });
    } else {
      createSegmentAndUploadCsvContactList(filePayload)
        .then((res) => {
          setLoader(false);
          setModalOpen(false);
          retrieveGroupsList();
        })
        .catch((err) => {
          console.log(err);
          errorHandler(err);
        });
    }
  };

  const attachTagsHandler = () => {
    if (file && emailListNamePresent) {
      enableTagModal(true);
      return;
    }
    setLoader(true);

    let selectedArrPayload = [...selectedArr];
    if (contactType !== 'default') selectedArrPayload.push(contactType);

    var filePayload = objectToFormData({
      media1:
        recipients.filter((ele) => ele.valid).length > 0
          ? getEmailListCsv(recipients.filter((ele) => ele.valid))
          : file[0],
      tags: JSON.stringify(selectedArrPayload),
    });

    const createListPayload = {
      name: segmentName,
      description: 'list created from segment',
      is_default: false,
      contact_type: contactType,
    };

    createSegmentContactList(objectToFormData(createListPayload))
      .then((res) => {
        const id = res.result.data.id;
        uploadCsvContactList(id, filePayload)
          .then((res) => {
            setContactListId(id);
            setLoader(false);
            retrieveGroupsList();
            enableTagModal(true);
          })
          .catch((err) => {
            errorHandler(err);
          });
      })
      .catch((err) => errorHandler(err));

    // submitHandler();
  };

  const errorBlock = () => {
    setSegmentErrorVisible(true);
    setSegmentErrorMessage('Please upload a valid csv file');
  };

  useEffect(() => {
    if (!tagsPresent) {
      listAllTopics().then((response) => {
        setTopicTagsArr(response.result.data);
      });
    }
  }, [tagsPresent]);

  //   useEffect(() => {
  //     if (file) {

  //     }
  //   }, [file, segmentName]);

  useEffect(() => {
    const timer = setTimeout(() => setSearchQuery(debouncedSearchQuery), 1000);
    return () => clearTimeout(timer);
  }, [debouncedSearchQuery]);

  useEffect(() => {
    setSearchResultsArr([]);
    if (searchQuery !== '') {
      setSearchResultsArr(
        topicTagsArr
          .filter((el) => el.toUpperCase().includes(searchQuery.toUpperCase()))
          .filter((el) => !selectedArr.includes(el))
      );
    }
  }, [searchQuery, selectedArr, topicTagsArr]);

  const addToArray = (element, arr, setArr) => {
    setArr([...arr, element].flat());
  };

  const removeFromArray = (element, arr, setArr) => {
    const newArr = arr.filter((item) => item !== element);
    setArr(newArr);
  };

  const checkValidation = () => {
    const validRecipients = recipients.filter((ele) => ele.valid).length > 0;
    const validListName = segmentName.length > 0;

    return (validListName && validRecipients) || (validListName && file);
  };

  const createEmailListHandler = () => {
    if (isEmpty(segmentName)) {
      setSegmentErrorMessage('Please Enter a name for the contact list');
      setSegmentErrorVisible(true);
    } else {
      setSegmentErrorVisible(false);
      setSegmentErrorMessage('');
      segmentName && tagsPresent ? submitHandler() : attachTagsHandler();
    }
  };

  const uploadModalContent = () => {
    return (
      <>
        <ModalHeader>Create Email List</ModalHeader>
        <ModalCloseButton onClick={() => setModalOpen(false)} />
        <ModalBody>
          {/* Segment Name */}
          <div
            className="list-name"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
            }}
          >
            <Input
              id="new-list-name"
              largeInput={true}
              placeholder="Enter List Name"
              value={segmentName}
              onChange={(event) => setSegmentName(event.target.value)}
              width="400px"
              disabled={csvListNamePresent}
            />
          </div>

          <hr />
          <br />
          <div className="flex-container" style={file ? { height: '0' } : {}}>
            <h3
              style={file ? { visibility: 'hidden' } : { marginRight: '7px' }}
            >
              Add Email Recipients
            </h3>
            <img
              alt=""
              style={file ? { visibility: 'hidden' } : { cursor: 'pointer' }}
              src="/assets/images/Add.svg"
              onClick={addRecipientRow}
            />
          </div>
          <ul
            className="recipients-list"
            style={file ? { visbility: 'hidden', height: '0' } : {}}
          >
            {!file &&
              recipients.map((recipient, index) => (
                <li style={{ width: '100%' }}>
                  <div className="recipient-row" style={{ width: '100%' }}>
                    <Input
                      id={'et-email-' + index}
                      largeInput={true}
                      placeholder="Email"
                      name="email"
                      value={recipient.email}
                      onChange={(evnt) => inputChangeHandler(evnt, index)}
                      onBlur={(evnt) => inputBlurHandler(evnt, index)}
                      outerWidth="40%"
                      disabled={!!file}
                      marginRight="10px"
                      width="100%"
                    />
                    <Input
                      id={'et-fn-' + index}
                      largeInput={true}
                      placeholder="First Name"
                      name="first_name"
                      value={recipient.first_name}
                      onChange={(evnt) => inputChangeHandler(evnt, index)}
                      onBlur={(evnt) => inputBlurHandler(evnt, index)}
                      outerWidth="25%"
                      disabled={!!file}
                      marginRight="10px"
                      width="100%"
                    />
                    <Input
                      id={'et-ln-' + index}
                      largeInput={true}
                      placeholder="Last Name"
                      name="last_name"
                      value={recipient.last_name}
                      onChange={(evnt) => inputChangeHandler(evnt, index)}
                      onBlur={(evnt) => inputBlurHandler(evnt, index)}
                      outerWidth="25%"
                      disabled={!!file}
                      marginRight="10px"
                      width="100%"
                    />
                    <div
                      style={{
                        border: index === 0 ? '' : '1px solid #6351ed',
                        borderRadius: '12px',
                        padding: '2px 5px 0px 4.3px',
                        backgroundColor: index === 0 ? '' : 'transparent',
                        cursor: index === 0 ? '' : 'pointer',
                      }}
                    >
                      {index !== 0 && (
                        <img
                          alt=""
                          src="/assets/images/emailList-delete.svg"
                          onClick={() => deleteRecipientRow(index)}
                        />
                      )}
                    </div>
                  </div>
                  {recipient.hasError && (
                    <div className="has-error">
                      Please enter a valid email address
                    </div>
                  )}
                </li>
              ))}
          </ul>
          <div
            className="flex-container"
            style={
              recipients[0].email === ''
                ? { alignItems: 'flex-end' }
                : { visibility: 'hidden', height: '0' }
            }
          >
            <FileUpload
              name="listUpload"
              id="listUpload"
              padding="4px 5px"
              background="#ffffff"
              fontSize="0.78rem"
              onClick={() => {}}
              styles={{
                padding: '8px 20px',
                backgroundColor: '#7dc5d0',
                border: '0px',
                color: 'white',
                borderRadius: '0',
              }}
              label="Upload CSV"
              onChange={(file) => {
                var allowedExtensions = /(\.csv)$/i;
                if (!allowedExtensions.exec(file[0]?.name)) {
                  return false;
                } else {
                  setFile(file);
                  Papa.parse(file[0], {
                    complete: function (results) {
                      var headerArr = results.data[0];
                      if (includes(headerArr, 'email')) {
                        if (includes(headerArr, 'email_list_name')) {
                          setSegmentName(
                            results.data[1][
                              results.data[0].indexOf('email_list_name')
                            ]
                          );
                          setSegmentErrorVisible(false);
                          setSegmentErrorMessage('');
                          setCsvListNamePresent(true);
                          enableSubmitButton(true);
                        } else {
                          setCsvListNamePresent(false);
                          setSegmentErrorVisible(true);
                          enableSubmitButton(false);
                          if (isEmpty(segmentName))
                            setSegmentErrorMessage(
                              'Please Enter a name for the contact list'
                            );
                          else {
                            setSegmentErrorVisible(false);
                            enableSubmitButton(true);
                            setSegmentErrorMessage('');
                          }
                        }
                      } else errorBlock();

                      if (headerArr.includes('tags')) {
                        setTagsPresent(true);
                      } else {
                        setTagsPresent(false);
                      }

                      if (headerArr.includes('email_list_name')) {
                        setEmailListNamePresent(true);
                      } else {
                        setEmailListNamePresent(false);
                      }
                    },
                  });
                }
              }}
              accept=".csv"
            />
          </div>
          <br />
          <div className="flex-container" style={{ alignItems: 'flex-end' }}>
            <DownloadSampleDiv
              download
              href="/assets/sample/segment/email_sample_basic.csv"
              padding="5px 10px"
            >
              <i className="icon dripicons-download"></i> Sample file (w/o list
              names or tags)
            </DownloadSampleDiv>
            <DownloadSampleDiv
              download
              href="/assets/sample/segment/email_sample_without_tags_with_list.csv"
              padding="5px 10px"
            >
              <i className="icon dripicons-download"></i> Sample file (w/ List
              name w/o tags)
            </DownloadSampleDiv>
            <DownloadSampleDiv
              download
              href="/assets/sample/segment/email_sample_with_tags_and_list.csv"
              padding="5px 10px"
            >
              <i className="icon dripicons-download"></i> Sample file (w/ List
              name and tags)
            </DownloadSampleDiv>
          </div>

          <div>{segmentErrorVisible && <p>{segmentErrorMessage}</p>}</div>
          <LoaderWrapper isLoading={loader}>
            <Button
              testId="submit-segment-creation"
              className="submit-btn-create-list"
              onClick={createEmailListHandler}
              disabled={!checkValidation()}
            >
              Submit
            </Button>
          </LoaderWrapper>
        </ModalBody>
      </>
    );
  };

  const tagModalContent = () => {
    return (
      <>
        <ModalHeader>
          Do you want to add any tags to the contacts in this list?
        </ModalHeader>
        Tags can be used to segment your contacts for targeted campaigns. Any
        tags you select here will be assigned to all contacts in this list.
        <ModalCloseButton
          onClick={() => (file ? submitHandler() : setModalOpen(false))}
        />
        <ModalBody>
          <Flex flexDirection="column" alignItems="flex-start">
            <h3>Contact Type</h3>
            <Flex marginRight="10px" alignItems="center">
              <Button
                margin="0px 10px 0 0"
                onClick={contactTypeFlag1Handler}
                bgColor={
                  contactTypeFlag1 ? 'rgb(99, 81, 237)' : 'rgb(212, 212, 212)'
                }
                borderColor={
                  contactTypeFlag1 ? 'rgb(99, 81, 237)' : 'rgb(212, 212, 212)'
                }
                color={contactTypeFlag1 ? '#fff' : '#000'}
              >
                Prospect
              </Button>
              <Button
                margin="0px 10px 0 0"
                style={{ border: '0px' }}
                onClick={contactTypeFlag2Handler}
                bgColor={
                  contactTypeFlag2 ? 'rgb(99, 81, 237)' : 'rgb(212, 212, 212)'
                }
                borderColor={
                  contactTypeFlag2 ? 'rgb(99, 81, 237)' : 'rgb(212, 212, 212)'
                }
                color={contactTypeFlag2 ? '#fff' : '#000'}
              >
                Client
              </Button>
            </Flex>
          </Flex>
          <div className="flex-container" style={{ justifyContent: 'left' }}>
            <h3 style={{ marginLeft: '1.5%' }}>
              Select and Search from Custom and Clout-automated Tags
            </h3>
          </div>
          {/* Search Bar */}
          <div
            ref={dropdownMenuRef}
            style={{ width: '50%', float: 'left', position: 'relative' }}
          >
            {/* <MioDropdown
              optionsWidth="100%"
              dropdownMenu={
                <ul style={{maxHeight: '200px', overflowY: 'scroll'}}>
                  {searchResultsArr.map((element) => (
                    <li
                      style={{
                        color: 'black',
                        border: 'black',
                        backgroundColor: 'white',
                        width: '100%',
                        textAlign: 'left',
                      }}
                      key={element}
                      onClick={() => {
                        addToArray(element, selectedArr, setSelectedArr);
                        removeFromArray(
                          element,
                          searchResultsArr,
                          setSearchResultsArr
                        );
                      }}
                    >
                      {element}
                    </li>
                  ))}
                </ul>
              }
            >
              <Input
                largeInput={true}
                placeholder="Start typing your search tag"
                value={debouncedSearchQuery}
                onChange={(event) =>
                  setDebouncedSearchQuery(event.target.value)
                }
                width="100%"
              />
            </MioDropdown> */}
            <div
              style={dropdownOpen ? { display: 'block' } : { display: 'none' }}
              className="search-bar-dropdown"
            >
              <ul>
                {searchResultsArr.map((element) => (
                  <li
                    key={element}
                    onClick={() => {
                      addToArray(element, selectedArr, setSelectedArr);
                      removeFromArray(
                        element,
                        searchResultsArr,
                        setSearchResultsArr
                      );
                      setDropdownOpen(false);
                    }}
                  >
                    {element}
                  </li>
                ))}
              </ul>
            </div>
            <Input
              largeInput={true}
              placeholder="Start typing your search tag"
              value={debouncedSearchQuery}
              onFocus={() => {
                setDropdownOpen(true);
              }}
              onChange={(event) => setDebouncedSearchQuery(event.target.value)}
              width="96%"
            />
          </div>
          <div style={{ width: '50%', float: 'right' }}>
            {selectedArr.length !== 0 ? (
              selectedArr.map((tagElement) => (
                <ButtonNoBorderBackground
                  background="#ececec"
                  margin="1% 2%"
                  padding="1%"
                  key={tagElement}
                >
                  {tagElement}
                  <img
                    alt=""
                    src="/assets/images/emailList-delete.svg"
                    style={{ marginLeft: '8px' }}
                    onClick={() =>
                      removeFromArray(tagElement, selectedArr, setSelectedArr)
                    }
                  />
                </ButtonNoBorderBackground>
              ))
            ) : (
              <p style={{ padding: '0' }}>No tags have been selected Yet</p>
            )}
          </div>
          <br />
          <div
            style={{
              marginTop: '4%',
            }}
          >
            {segmentErrorVisible && <p>{segmentErrorMessage}</p>}
          </div>
          <LoaderWrapper isLoading={loader}>
            <Flex
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
              style={
                segmentErrorVisible ? { marginTop: '2%' } : { marginTop: '2%' }
              }
            >
              <Button
                bgColor="#fff"
                borderColor="#708892"
                color="#708892"
                margin="20px 10px 0 0"
                onClick={() => (file ? submitHandler() : setModalOpen(false))}
              >
                No thanks
              </Button>

              <Button
                onClick={submitHandler}
                disabled={contactType === 'default' && selectedArr.length === 0}
              >
                Submit
              </Button>
            </Flex>
          </LoaderWrapper>
        </ModalBody>
      </>
    );
  };
  return (
    <ModalV2
      onRequestClose={() => setModalOpen(false)}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      bgColor="white"
      width="75%"
    >
      {tagModal ? tagModalContent() : uploadModalContent()}
    </ModalV2>
  );
};

export default SegmentContactListModal;
