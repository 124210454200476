import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  TabList,
  Tabs,
  Button,
  DateDropdown,
  LoaderWrapper,
} from '../../components';
import { NewsletterTab } from '../NewsletterContainer/NewsletterContainer.styles';
import {
  getArticlesData,
  sendReportData,
} from '../../services/exportPdf/index';
import {
  themesData,
  getScheduledArticleData,
  getRecentArticleData,
} from '../../utils';
import { TabConfig } from '../ConfigureContainer/screenConfigure.styles';
import ScheduledTab from '../DashboardContainer/ScheduledTab';
import {
  CCInput,
  NewsletterCheckBoxDiv,
} from '../NewsletterContainer/Recipients/Recipients.styles';
import RecentSharedTab from '../DashboardContainer/RecentSharedTab';
import { getDefaultStartDate } from '../../redux/modules/analytics.module';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { BottomBtns } from './ExportPDF.styles';

const ExportPDF = ({
  open,
  setOpen,
  selectedTopic,
  deleteArticleRequest,
  themeNames,
  exploreArticleDeleting,
  isDeleteBlocked,
  exploreArticleRequest,
  setShowArticleLoader,
  scheduleType,
  isShareBlocked,
  onDeleteArticleClick,
  isAdmin,
  currentPopUp,
  userData,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [exportPDFArticles, setExportPDFArticles] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [emailList, setEmailList] = useState('');
  const [exploreArticles, setExploreArticles] = useState({});
  const [loading, setLoading] = useState(false);
  const [invalidEmailMsg, setInvalidEmailMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [customDate, setCustomDate] = useState({
    selectedDate:
      currentPopUp === 'isSharedArticle' ? 'Next 7 days' : 'Last 7 days',
  });
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    let dates = customDate.selectedDate.split('-');
    let dateObject = {};
    if (dates.length > 1) {
      dateObject = {
        startDate: dates[0].trim(),
        endDate: dates[1].trim(),
      };
    } else {
      dateObject = getDefaultStartDate(customDate.selectedDate);
    }
    setExportPDFArticles({});
    setSelectAll(false);
    setLoading(true);
    getArticlesData(dateObject, currentPopUp)
      .then((response) => {
        setLoading(false);
        setExploreArticles(response);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [customDate]);

  const onTabSelect = (index) => {
    setSelectedIndex(index);
  };
  const handleClose = () => {
    setOpen(currentPopUp, false);
  };
  const handleArticleSelection = (name, value, ids) => {
    setExportPDFArticles((prevContent) => ({
      ...prevContent,
      [name]: value ? ids : value,
    }));
    setSelectAll(false);
  };

  const handleAllArticleSelection = (value) => {
    let articles =
      currentPopUp === 'isSharedArticle'
        ? getScheduledArticleData(exploreArticles)
        : getRecentArticleData(exploreArticles);
    let allArticlesIncluded = {};
    if (value) {
      for (let i = 0; i < articles.length; i++) {
        if (currentPopUp === 'isSharedArticle') {
          allArticlesIncluded[articles[i].id] = articles[i].scheduledIds;
        } else {
          allArticlesIncluded[articles[i].id] = articles[i].recentSharedIds;
        }
      }
    } else {
      for (let i = 0; i < articles.length; i++) {
        allArticlesIncluded[articles[i].id] = false;
      }
    }
    setExportPDFArticles({ ...allArticlesIncluded });
    setSelectAll(value);
  };

  const validateEmails = (value) => {
    setEmailList(value);
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    const isRecipientsValid = value
      .split(',')
      .map((email) => email.trim())
      .every((em) => pattern.test(em.toLowerCase()));
    // const validRecipients = recipients.filter((recipient) =>
    //   pattern.test(recipient)
    // );
    if (value.length == 0) {
      setErrorMsg('');
      setInvalidEmailMsg(false);
    } else if (!isRecipientsValid) {
      setInvalidEmailMsg(true);
      setErrorMsg('Invalid Email Address');
    } else {
      setInvalidEmailMsg(false);
      setErrorMsg('');
    }
  };

  const handleNextClick = () => {
    setSelectedIndex(selectedIndex + 1);
  };

  const handleBackClick = () => {
    setSelectedIndex(selectedIndex - 1);
  };

  const handleSubmit = () => {
    let articleIds = validateInputs();
    let dates = customDate.selectedDate.split('-');
    let dateObject = {};
    if (dates.length > 1) {
      dateObject = {
        startDate: dates[0].trim(),
        endDate: dates[1].trim(),
      };
    } else {
      dateObject = getDefaultStartDate(customDate.selectedDate);
    }
    setLoading(true);
    sendReportData(articleIds, emailList, dateObject)
      .then((response) => {
        setLoading(false);
        if (response.success) {
          setIsSubmit(true);
          setTimeout(() => {
            setOpen(currentPopUp, false);
          }, 3000);
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsSubmit(false);
        console.log(err);
      });
  };

  const validateInputs = () => {
    let inputArray = [];
    for (let i in exportPDFArticles) {
      if (exportPDFArticles[i]) {
        inputArray.push(...exportPDFArticles[i]);
      }
    }
    return inputArray;
  };

  useEffect(() => {
    console.log(exportPDFArticles);
  }, [exportPDFArticles]);

  return (
    <Modal
      bgColor="#e4e4e4"
      height="88%"
      size="big"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
    >
      <ModalHeader
        id="header"
        style={{ textAlign: 'center', color: themesData.charcoalGrey }}
      >
        <ModalCloseButton onClick={handleClose} />
        <TabList height="35px">
          <NewsletterTab
            className={selectedIndex === 0 ? 'active' : ''}
            disabled
          >
            <div
              className={selectedIndex > 0 ? 'line active' : 'line inactive'}
            ></div>
            <div className="number-tag" style={{ left: '9px' }}>
              1
            </div>
            <div className="text">Select Content</div>
          </NewsletterTab>

          <NewsletterTab
            className={selectedIndex === 1 ? 'active' : ''}
            disabled
          >
            <div
              className={
                selectedIndex > 1
                  ? 'line active'
                  : selectedIndex < 1
                  ? 'line'
                  : 'line inactive'
              }
            ></div>
            <div className="number-tag">2</div>
            <div className="text">Add Emails</div>
          </NewsletterTab>

          <NewsletterTab
            className={selectedIndex === 2 ? 'active' : ''}
            disabled
          >
            <div className="number-tag">3</div>
            <div className="text">Confirm & Send</div>
          </NewsletterTab>
        </TabList>
      </ModalHeader>

      <ModalBody
        style={{
          height: '480px',
          overflowX: 'hidden',
          overflowY: 'auto',
          color: 'black',
        }}
      >
        <Flex width={1}>
          <Tabs onSelect={onTabSelect} selectedIndex={selectedIndex}>
            <TabConfig noBorder minHeight="400px" background="none">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                marginTop="25px"
                marginBottom="15px"
              >
                <Flex width="30%">
                  <div>
                    <DateDropdown
                      top="0px"
                      value={customDate.selectedDate}
                      onDateChange={setCustomDate}
                      position="relative"
                      // type="analytics"
                      isExportPDF={true}
                      currentPopUp={currentPopUp}
                    />
                  </div>
                </Flex>
                <Flex width="30%" justifyContent="center">
                  <div style={{ fontSize: '0.8rem' }}>
                    Select content to include in the report
                  </div>
                </Flex>
                <Flex width="30%" justifyContent="flex-end" paddingRight="5%">
                  <Flex alignItems="center">
                    <NewsletterCheckBoxDiv
                      fontWeight="600"
                      style={{
                        display:
                          get(exploreArticles, 'feed', []).length > 0
                            ? 'block'
                            : 'none',
                      }}
                    >
                      <label
                        style={{ color: themesData.purpleishBlue }}
                        className="container"
                      >
                        <input
                          type="checkbox"
                          name="selectAll"
                          checked={selectAll}
                          onChange={(e) =>
                            handleAllArticleSelection(e.target.checked)
                          }
                        />
                        <span className="checkmark"></span>Select All
                      </label>
                    </NewsletterCheckBoxDiv>
                  </Flex>
                </Flex>
              </Flex>
              <LoaderWrapper isLoading={loading}>
                {get(exploreArticles, 'feed', []).length > 0 ? (
                  <div>
                    <div style={{ background: 'white', padding: '10px 0px' }}>
                      {currentPopUp === 'isSharedArticle' ? (
                        <ScheduledTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={selectedTopic}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={deleteArticleRequest}
                          themeNames={themeNames}
                          isShareBlocked={isShareBlocked}
                          isDeleteBlocked={isDeleteBlocked}
                          exploreArticleRequest={exploreArticleRequest}
                          setShowArticleLoader={setShowArticleLoader}
                          scheduleType={scheduleType}
                          isExportPDF={true}
                          handleArticleSelection={handleArticleSelection}
                          exportPDFArticles={exportPDFArticles}
                          styles={{
                            maxWidth: '98%',
                            margin: '0px auto',
                          }}
                        />
                      ) : (
                        <RecentSharedTab
                          selectedTopic={selectedTopic}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          isShareBlocked={isShareBlocked}
                          isDeleteBlocked={isDeleteBlocked}
                          isAdmin={isAdmin}
                          setShowArticleLoader={setShowArticleLoader}
                          isExportPDF={true}
                          handleArticleSelection={handleArticleSelection}
                          exportPDFArticles={exportPDFArticles}
                          styles={{
                            maxWidth: '98%',
                            margin: '0px auto',
                          }}
                        />
                      )}
                    </div>
                    <Flex justifyContent="center">
                      <Button
                        bgColor={validateInputs().length === 0 ? '#AFAFAF' : ''}
                        disabled={validateInputs().length === 0}
                        height="36px"
                        width="146px"
                        margin="10px auto"
                        styles={{
                          position: 'absolute',
                          bottom: '0',
                          marginBottom: '2vw',
                        }}
                        onClick={handleNextClick}
                      >
                        Next
                      </Button>
                    </Flex>
                  </div>
                ) : (
                  <div style={{ padding: '10px', color: '#6351ed' }}>
                    No Content for display
                  </div>
                )}
              </LoaderWrapper>
            </TabConfig>
            <TabConfig noBorder minHeight="400px" background="none">
              <div>
                <Flex flexDirection="column">
                  <div
                    style={{
                      marginTop: '25px',
                      fontSize: '0.8rem',
                      padding: '5px',
                    }}
                  >
                    The report will be sent to{' '}
                    {get(
                      userData,
                      'details.user.email',
                      'advisor_email_address'
                    )}
                    . List any additional email addresses to send it to.
                  </div>
                  <Flex
                    width="100%"
                    flexDirection="column"
                    fontSize="14px"
                    alignItems="center"
                    style={{ fontWeight: 600 }}
                    color={themesData.charcoalGrey}
                  >
                    <div
                      style={{
                        color: '#6351ed',
                        fontSize: '0.7rem',
                        fontWeight: '500',
                      }}
                    >
                      {errorMsg}
                    </div>
                    <CCInput
                      value={emailList}
                      spellCheck="false"
                      id="email_field"
                      name="email_field"
                      onChange={(e) => validateEmails(e.target.value)}
                      placeholder="Type in email address(es), separated by commas"
                      style={{
                        width: '80%',
                        backgroundColor: 'white',
                        color: 'black',
                      }}
                    />
                  </Flex>
                </Flex>
                <Flex justifyContent="center">
                  <BottomBtns>
                    <Button
                      bgColor={'white'}
                      height="36px"
                      width="146px"
                      margin="10px 0px"
                      onClick={handleBackClick}
                      color={'black'}
                    >
                      Back
                    </Button>
                    <Button
                      bgColor={invalidEmailMsg ? '#AFAFAF' : ''}
                      disabled={invalidEmailMsg}
                      height="36px"
                      width="146px"
                      margin="10px 5px"
                      onClick={handleNextClick}
                    >
                      Next
                    </Button>
                  </BottomBtns>
                </Flex>
              </div>
            </TabConfig>
            {selectedIndex === 2 && (
              <TabConfig noBorder minHeight="400px" background="none">
                <div>
                  <Flex flexDirection="column">
                    <div
                      style={{
                        marginTop: '25px',
                        padding: '5px',
                      }}
                    >
                      {isSubmit ? (
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          width="100%"
                          marginTop="15%"
                        >
                          <div>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="#6351ed"
                              size="3x"
                            />
                          </div>
                          <div style={{ marginLeft: '2%', color: '#6351ed' }}>
                            Success! Your report will be sent shortly.
                          </div>
                        </Flex>
                      ) : (
                        <div style={{ fontSize: '0.8rem' }}>
                          Confirm report details.
                          <br />
                          The report will be sent via email; please allow up to
                          30 minutes for report generation.
                        </div>
                      )}
                    </div>
                    {!isSubmit && (
                      <Flex
                        flexDirection="column"
                        fontSize="14px"
                        alignItems="flex-start"
                        marginLeft="15%"
                        padding="10px"
                        height="300px"
                        style={{
                          textAlign: 'left',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                        }}
                        color="#757575"
                      >
                        <div>Date Range: {customDate.selectedDate}</div>
                        <div>{validateInputs().length} content shares</div>
                        <div>
                          Send To:
                          <br />
                          <div>
                            {get(
                              userData,
                              'details.user.email',
                              'advisor_email_address'
                            )}
                          </div>
                          {emailList.split(',').map((email) => (
                            <div>{email.trim()}</div>
                          ))}
                        </div>
                      </Flex>
                    )}
                  </Flex>
                  <LoaderWrapper isLoading={loading}>
                    {!isSubmit && (
                      <Flex justifyContent="center">
                        <BottomBtns>
                          <Button
                            bgColor={'white'}
                            height="36px"
                            width="146px"
                            margin="10px 0px"
                            onClick={handleBackClick}
                            color={'black'}
                          >
                            Back
                          </Button>
                          <Button
                            bgColor={
                              isEmpty(exportPDFArticles) ? '#AFAFAF' : ''
                            }
                            disabled={isEmpty(exportPDFArticles)}
                            height="36px"
                            width="146px"
                            margin="10px 5px"
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </BottomBtns>
                      </Flex>
                    )}
                  </LoaderWrapper>
                </div>
              </TabConfig>
            )}
          </Tabs>
        </Flex>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  userData: state.user.data,
});

export default connect(mapStateToProps, null)(ExportPDF);
