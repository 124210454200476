import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Tr,
  ButtonNoBorderBackground,
  ResponsiveImg,
  SubmitTopicModal,
  CreateTopicModal,
  LoaderWrapper,
} from '../../../components';
import { actions as groupActions } from '../../../redux/modules/group.module';
import { actions as membersActions } from '../../../redux/modules/members.module';
import { getMediaUrl } from '../../../utils';

import {
  MioIconContentWrapper,
  ContentSettingsTable,
  ContentSettingsTableHeading,
  ContentSettingsTableHeadingTh,
  ContentSettingsTableHeadingTd,
  ContentSettingsTableBody,
  ContentSettingsTableHeadingTr,
} from './ChannelSettings.styles';

const ChannelSettings = ({
  retrieveGroupRequest,
  groupList,
  createGroupRequest,
  updateGroupRequest,
  themesRequest,
  themesList,
  membersRequest,
  membersList,
  channelData,
  isGroupFetching,
}) => {
  const [isSubmitModalOpen, setSubmitTopicModalOpen] = useState(false);
  const [editGroupDetails, setEditGroupDetails] = useState({});
  const [isCreateGroupModalOpen, setCreateGroupModalOpen] = useState(false);

  useEffect(() => {
    retrieveGroupRequest();
    if (isEmpty(membersList)) {
      membersRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditGroup = (groupDetails) => {
    setEditGroupDetails(groupDetails);
    if(groupDetails.id.length > 0) {
      setCreateGroupModalOpen(true);
    }
  };

  const onCreateGroupModalClose = () => {
    setEditGroupDetails({});
    setCreateGroupModalOpen(false);
  };

  return (
    <Flex width={0.8} mx="auto" flexDirection="column">
      <Flex
        width={1}
        mt="15px"
        alignItems="flex-end"
        justifyContent="flex-start"
      >
        <Box width={0.6}>
          <MioIconContentWrapper>
            <div>
              <ResponsiveImg
                src={getMediaUrl(get(channelData, 'media_urls'))}
              />
            </div>
            <div className="channel-name">{get(channelData, 'name')}</div>
            <div>{membersList.length} Members</div>
          </MioIconContentWrapper>
        </Box>
        <Box px="5px">
          <Button width='150px' onClick={() => setSubmitTopicModalOpen(true)}>
            Submit a Topic
          </Button>
        </Box>
        <Box>
          <Button width='150px' onClick={() => setCreateGroupModalOpen(true)}>
            Create Group
          </Button>
        </Box>
      </Flex>
      <Flex width={1} mt="15px">
        <ContentSettingsTable>
          <ContentSettingsTableHeading>
            <Tr alternate={false}>
              <ContentSettingsTableHeadingTh width="25%">
                Group Names
              </ContentSettingsTableHeadingTh>
              <ContentSettingsTableHeadingTh width="15%">
                Members
              </ContentSettingsTableHeadingTh>
              <ContentSettingsTableHeadingTh width="50%">
                Group Disclaimer Text
              </ContentSettingsTableHeadingTh>
              <ContentSettingsTableHeadingTh width="15%">
                Actions
              </ContentSettingsTableHeadingTh>
            </Tr>
          </ContentSettingsTableHeading>
          <ContentSettingsTableBody height="240px ">
            <LoaderWrapper isLoading={isGroupFetching}>
              {groupList.map((group) => {
                return (
                  <ContentSettingsTableHeadingTr key={group.id}>
                    <ContentSettingsTableHeadingTd
                      className="table-cell"
                      width="25%"
                    >
                      {group.name}
                    </ContentSettingsTableHeadingTd>
                    <ContentSettingsTableHeadingTd
                      className="table-cell"
                      width="15%"
                    >
                      {group.member_count}
                    </ContentSettingsTableHeadingTd>
                    <ContentSettingsTableHeadingTd
                      className="table-cell"
                      width="50%"
                    >
                      {group.disclaimer}
                    </ContentSettingsTableHeadingTd>
                    <ContentSettingsTableHeadingTd
                      className="table-cell"
                      width="10%"
                    >
                      <ButtonNoBorderBackground
                        onClick={() => onEditGroup(group)}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </ButtonNoBorderBackground>
                    </ContentSettingsTableHeadingTd>
                  </ContentSettingsTableHeadingTr>
                );
              })}
            </LoaderWrapper>
          </ContentSettingsTableBody>
        </ContentSettingsTable>
      </Flex>
      <SubmitTopicModal
        isModalOpen={isSubmitModalOpen}
        setModalOpen={setSubmitTopicModalOpen}
        themesRequest={themesRequest}
        themesList={themesList}
      />
      {isCreateGroupModalOpen && (
        <CreateTopicModal
          isModalOpen={isCreateGroupModalOpen}
          setModalOpen={setCreateGroupModalOpen}
          createGroupRequest={createGroupRequest}
          updateGroupRequest={updateGroupRequest}
          editGroupDetails={editGroupDetails}
          onModalClose={onCreateGroupModalClose}
          membersList={membersList}
          themesList={themesList}
        />
      )}
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  groupList: state.group.data || [],
  isGroupFetching: state.group.isFetching,
  themesList: state.theme.data || [],
  membersList: get(state, 'members.data.users', []),
  channelData: get(state, 'channel.data[0]', {}),
});

const mapDispatchToProps = {
  retrieveGroupRequest: groupActions.api.group.request,
  createGroupRequest: groupActions.api.group.create,
  updateGroupRequest: groupActions.api.group.update,
  membersRequest: membersActions.api.members.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelSettings);
