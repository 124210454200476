/* eslint-disable react-hooks/exhaustive-deps */
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import { Button, ButtonNoBorderBackground, MioDatePicker, MioRadio, MioRadioSpan } from '../../../components';
import { ContentPanel, ContentPanelSection, ContentSection, DayPanel, DayPanelContentSection, PlanCampaignWrapper } from './PlanCampaign.styles';
import NumberRangePicker from '../../../components/NumberRangePicker/NumberRangePicker';
import { SetTopicsHr } from '../SetTopics/SetTopics.styles';
import { SetObjectivesInput } from '../SetObjectives/SetObjectives.styles';
import { get, groupBy, isEmpty, map, uniq } from 'lodash';
import DayTimePicker from './DayTimePicker';
import CampaignCard from './CampaignCard';

import moment from 'moment';
import { publishCampaign, updateCampaign } from '../../../services/campaign';
import { getDayMonthYear, getMediaUrl } from '../../../utils';
import FrequencyModal from './FrequencyModal';
import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import PublishErrorModal from './PublishErrorModal';
import PublishSuccessModal from './PublishSuccessModal';

const PlanCampaign = ({
    setCreateTabs,
    setObjectivesTabData,
    setCreateScreenIndex,
    campaignSetState,
    campaignArticles,
    campaignId,
    contentWeeks,
    getAccountId,
    campaignScheduleTimings,
    planCampaignData,
    setCreateFlow,
    publishedContent,
    endCreateFlow
}) => {
    const [startDate, setStartDate] = useState(new Date());
    const [openDateModal, setOpenDateModal] = useState(false);
    const [duration, setDuration] = useState('4 Weeks');
    const [frequency, setFrequency] = useState('Every Week');
    const [engagementScore, setEngagementScore] = useState('60');
    const [emTime, setEmTime] = useState(['Select day & time']);
    const [fbTime, setFbTime] = useState(['Select day & time']);
    const [twTime, setTwTime] = useState(['Select day & time']);
    const [lnTime, setLnTime] = useState(['Select day & time']);
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [openTimePicker, setTimePicker] = useState(false);
    const [showTimeView, setShowTimeView] = useState(false);
    const [showContentSection, setShowContentSection] = useState(false);
    const [selectedWeek, setSelectedWeek] = useState('');
    const [weekArticlesData, setWeekArticlesData] = useState('');
    const [selectedDays, setSelectedDays] = useState([]);
    const [disableEngagementSection, setDisableEngagementSection] = useState(false);
    const [openFrequencyModal, setFrequencyModal] = useState(false);
    const [disableTimeSection, setDisableTimeSection] = useState(false);
    const [openTimeModal, setTimeModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmationLoader, toggleConfirmationLoader] = useState(false);
    const [errorModal, toggleErrorModal] = useState(false);
    const [successModal, toggleSuccessModal] = useState(false);
    const [failedPublishArticles, setFailedPublishArticles] = useState([]);
    const platformMapper = {
        em: 'Email',
        tw: 'Twitter',
        fb: 'Facebook',
        ln: 'Linkedin'
    };

    useEffect(() => {
        if (!isEmpty(contentWeeks)) {
            setDisableEngagementSection(true);
            setShowTimeView(true);
        }

        if(!isEmpty(campaignArticles)) {
            setDisableTimeSection(true);
            setShowContentSection(true);
        }
    }, [contentWeeks, campaignArticles]);

    useEffect(() => {
        if(campaignScheduleTimings) {
            const em = isEmpty(get(campaignScheduleTimings, 'emTime', [])) 
                    ? ['Select day & time'] : get(campaignScheduleTimings, 'emTime', ['Select day & time']);
            const fb = isEmpty(get(campaignScheduleTimings, 'fbTime', []))
                    ? ['Select day & time'] : get(campaignScheduleTimings, 'fbTime', ['Select day & time']);
            const tw = isEmpty(get(campaignScheduleTimings, 'twTime', []))
            ? ['Select day & time'] : get(campaignScheduleTimings, 'twTime', ['Select day & time']);
            const ln = isEmpty(get(campaignScheduleTimings, 'lnTime', []))
            ? ['Select day & time'] : get(campaignScheduleTimings, 'lnTime', ['Select day & time']);
            setEmTime(em);
            setFbTime(fb);
            setTwTime(tw);
            setLnTime(ln);
        }
       
        setStartDate(get(planCampaignData, 'startDate', new Date()));
    }, [])

    useEffect(() => {
        if(planCampaignData && planCampaignData.startDate) {
            setStartDate(planCampaignData.startDate);
        }

        if(!isEmpty(get(planCampaignData, 'frequency', ''))) {
            setFrequency(get(planCampaignData, 'frequency', ''));
        }

        if(!isEmpty(get(planCampaignData, 'duration', ''))) {
            setDuration(get(planCampaignData, 'duration', ''));
        }

        if(!isEmpty(get(planCampaignData, 'engagementScore', ''))) {
            setEngagementScore(get(planCampaignData, 'engagementScore', ''));
        }
    }, [planCampaignData])

    const handleEngagementScore = (e) => {
        setEngagementScore(e.target.value);
    }

    const handleBackButtonClick = () => {
        setCreateTabs(1);
    }


    const getFailedArticles = dataObj => {
        const failedArticles = [];
        Object.keys(dataObj).filter(key => key.includes('Week')).forEach(weekKey => {
            Object.keys(dataObj[weekKey]).forEach(platform => {
                dataObj[weekKey][platform].forEach(item => {
                    const failedArticle = get(item, 'article.feed[0]', '');
                    console.log('FAILED ARTICLE - -- - ', failedArticle);
                    if(!isEmpty(failedArticle)) {
                        const articlePresent = failedArticles.find(article => article.id===failedArticle.id);
                        if(!articlePresent){
                            failedArticles.push({
                                id: get(failedArticle, 'id'),
                                title: get(failedArticle, 'auxiliary.title', ''),
                                description: get(failedArticle, 'text', ''),
                                imgUrl: getMediaUrl(get(failedArticle, 'media_urls')),
                                platform: platformMapper[platform],
                                time: ''
                            });
                        }
                    }
                });
            });
        });
        return failedArticles;
    }

    const handleNextClick = () => {
        setLoading(true);
        publishCampaign({
            campaign_id: campaignId,
            posts: JSON.stringify(publishedContent),
        })
        .then(res => {
            const resObj = get(res, 'result.data');
            console.log('RESPONSE - - - ', res);
            if(res && res.result.success && !resObj.error) {
                console.log('TOGGLE SUCCESS MODAL TRUE...');
                toggleSuccessModal(true);
            }
            else if (resObj.error === 'repost') {
                const failedArticles = getFailedArticles(resObj);
                console.log('FAILED ARTICLES - - - -', failedArticles);
                setFailedPublishArticles(failedArticles);
                toggleErrorModal(true);
            }
            setLoading(false);
        })
        .catch(err => {
            // const failedArticles = getFailedArticles(get(err, 'response.result.data'));
            // console.log('FAILED ARTICLES - - - -', failedArticles);
            // setFailedPublishArticles(failedArticles);
            // toggleErrorModal(true);
            setLoading(false);
            console.log('ERROR - - - -', err.response);
        }); 
    }

    const triggerFrequencyConfirmation = () => {
        setFrequencyModal(true);
    }

    const triggerTimeModal = () => {
        setTimeModal(true);
    }


    const handleDurationFrequency = () => {
        toggleConfirmationLoader(true);
        if (duration === '4 Weeks') {
            if (frequency === 'Every Week') {
                campaignSetState({
                    contentWeeks: ['Week 1', 'Week 2', 'Week 3', 'Week 4']
                });
            } else {
                campaignSetState({
                    contentWeeks: ['Week 1', 'Week 3']
                });
            }
        } else {
            if (frequency === 'Every Week') {
                campaignSetState({
                    contentWeeks: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8']
                });
            } else {
                campaignSetState({
                    contentWeeks: ['Week 1', 'Week 3', 'Week 5', 'Week 7']
                })
            }
        }

        const [selectedMonth, selectedDay, selectedYear] = getDayMonthYear(
            startDate
        );
       
        let payload = {
            start_date: `${selectedYear}-${selectedMonth}-${selectedDay}T00:00:00`,
            frequency: JSON.stringify(frequency.split(' ')),
            duration: JSON.stringify(duration.split(' ')), 
            engagement_score: engagementScore
        };

        updateCampaign({id: campaignId, payload}).then((res) => {
            if(res.result.success) {
                setShowTimeView(true);
                setDisableEngagementSection(true);
                setFrequencyModal(false);
                campaignSetState({
                    planCampaign: {
                        startDate: startDate,
                        duration: duration,
                        frequency: frequency,
                        engagementScore: engagementScore
                    }
                });
                toggleConfirmationLoader(false);
            }
        }).catch(err => {
            toggleConfirmationLoader(false);
            console.log(err);
        });
    }

    const handleDayTimeSection = () => {
        setShowContentSection(true);
        toggleConfirmationLoader(true);

       const campaignSchedule = {  
            email: [],
            facebook: [],
            twitter: [],
            linkedin: []
        }

        const emArticles = !isEmpty(emTime.filter(e => e !== 'Select day & time')) ? emTime.filter(e => e !== 'Select day & time').map((item, index) => {
            campaignSchedule['email'].push({ schedule_time: item });
            return {
                platform: 'Email',
                day: item && item.split(',')[0],
                time: item && item.split(', ')[1],
                content: false,
                schedule_time: item,
                destination_snetwork: 'em',
                engagement_type: 'share',
                destination_id: null,
                contactlist_id: map(get(setObjectivesTabData, 'emList', []), 'id'),
                snetwork_act_id: getAccountId('em')
            }
        }) : [];
        const fbArticles = !isEmpty(fbTime.filter(f => f !== 'Select day & time')) ? fbTime.filter(f => f !== 'Select day & time').map((item, index) => {
            campaignSchedule['facebook'].push({ schedule_time: item });
            return {
                platform: 'Facebook',
                day: item && item.split(',')[0],
                time: item && item.split(', ')[1],
                content: false,
                schedule_time: item,
                destination_snetwork: 'fb',
                engagement_type: 'share',
                destination_id: get(setObjectivesTabData, 'fbList', null),
                contactlist_id: null,
                snetwork_act_id: getAccountId('fb')
            }
        }) : [];
        const lnArticles = !isEmpty(lnTime.filter(f => f !== 'Select day & time')) ? lnTime.filter(f => f !== 'Select day & time').map((item, index) => {
            campaignSchedule['linkedin'].push({ schedule_time: item });
            return {
                platform: 'Linkedin',
                day: item && item.split(',')[0],
                time: item && item.split(', ')[1],
                content: false,
                schedule_time: item,
                destination_snetwork: 'ln',
                engagement_type: 'share',
                destination_id: get(setObjectivesTabData, 'lnList', null),
                contactlist_id: null,
                snetwork_act_id: getAccountId('ln')
            }
        }) : [];
        const twArticles = !isEmpty(twTime.filter(f => f !== 'Select day & time')) ? twTime.filter(f => f !== 'Select day & time').map((item, index) => {
            campaignSchedule['twitter'].push({ schedule_time: item });
            return {
                platform: 'Twitter',
                day: item && item.split(',')[0],
                time: item && item.split(', ')[1],
                content: false,
                schedule_time: item,
                destination_snetwork: 'tw',
                engagement_type: 'share',
                destination_id: get(setObjectivesTabData, 'twList', null),
                contactlist_id: null,
                snetwork_act_id: getAccountId('tw')
            }
        }) : [];

        const mergePlatforms = [...emArticles, ...fbArticles, ...twArticles, ...lnArticles];  
        let finalizedArticlesData = [];
        if(!isEmpty(contentWeeks)) {
            mergePlatforms.forEach((item, index) => {
                for (let i = 0; i < contentWeeks.length; i++) {
                    finalizedArticlesData.push({
                        ...item,
                        week: contentWeeks[i]
                    })
                }
            });
            finalizedArticlesData = finalizedArticlesData.map((item, index) => {
                return {
                    ...item,
                    ref_id: index
                }
            });
        }
        
        const payload = {
            schedule: JSON.stringify(campaignSchedule)
        }
        updateCampaign({id: campaignId, payload: payload})
        .then(res => {
            if(get(res, 'result.success', false)) {
                campaignSetState({
                    campaignArticles: finalizedArticlesData,
                    scheduleTimings: {
                        emTime: emTime,
                        fbTime: fbTime,
                        twTime: twTime,
                        lnTime: lnTime,
                    }
                });
                setTimeModal(false);
                setDisableTimeSection(true);
                toggleConfirmationLoader(false);
            }
        })
        .catch(err => {
            console.log('ERROR - - -- ', err);
            toggleConfirmationLoader(false);
        });
        
    }

    const handleTimePicker = (platform) => {
        setTimePicker(true);
        setSelectedPlatform(platform);
    }

    const generateDayTimePlatforms = (data) => {
        const { selectedWeekDay, selectedHour, selectedMin, selectedTimePeriod } = data;
        let dateTime = '';
        let calculatedMin = selectedMin.length === 1 ? `0${selectedMin}` : selectedMin;
        switch (selectedPlatform) {
            case 'em':
                dateTime = `${selectedWeekDay.value}, ${selectedHour}:${calculatedMin} ${selectedTimePeriod}`;
                let removeDummyEmData = emTime.filter(e => e !== 'Select day & time');
                setEmTime([...removeDummyEmData, dateTime]);
                break;
            
            case 'fb':
                dateTime = `${selectedWeekDay.value}, ${selectedHour}:${calculatedMin} ${selectedTimePeriod}`;
                let removeDummyFbData = fbTime.filter(e => e !== 'Select day & time');
                setFbTime([...removeDummyFbData, dateTime]);
                break;
            
            case 'tw':
                dateTime = `${selectedWeekDay.value}, ${selectedHour}:${calculatedMin} ${selectedTimePeriod}`;
                let removeDummyTwData = twTime.filter(e => e !== 'Select day & time');
                setTwTime([...removeDummyTwData, dateTime]);
                break;

            case 'ln':
                dateTime = `${selectedWeekDay.value}, ${selectedHour}:${calculatedMin} ${selectedTimePeriod}`;
                let removeDummyLnData = lnTime.filter(e => e !== 'Select day & time');
                setLnTime([...removeDummyLnData, dateTime]);
                break;
            
            default:
                break;
        }
    }

    const onWeekClick = (week) => {
        if(selectedWeek === week)
            setSelectedWeek('');
        else
            setSelectedWeek(week);
        const weekData = campaignArticles.filter(c => c.week === week);
        if(!isEmpty(weekData)) {
            setWeekArticlesData(groupBy(weekData, 'day'));
        }
    }

    const onDayClick = (day) => {
        if(selectedDays.includes(day))
            setSelectedDays([...selectedDays].filter(item => item!==day));
        else
            setSelectedDays(uniq([...selectedDays, day]));
    }


    const handleDateSubmit = (date) => {
        setStartDate(date);
        setOpenDateModal(false);
    }

    const handleAssignTabChange = (selectedWeek) => {
        let days = [];
        campaignArticles.forEach((item, index) => {
            days.push(item.day.slice(0, 3));
        });
        campaignSetState({
            selectedWeek,
            selectedDays: uniq(days).join('-')
        });
        setCreateScreenIndex(1);
    }

    const checkValidation = () => {
        let isNotValid = true;
        if(!isEmpty(publishedContent) && !isEmpty(contentWeeks))
            if (Object.keys(publishedContent).length === contentWeeks.length) {
                isNotValid = false;
            }
        return isNotValid;
    }

    const handleSaveAndClose = () => {
        endCreateFlow();
    }

    const handleEnableNext = () => {
        let isValid = false;
        
        if(publishedContent && !isEmpty(Object.keys(publishedContent))) {
            isValid = true
        }

        return isValid;
    }

    const repostHandler = () => {
        publishCampaign({
            campaign_id: campaignId,
            posts: JSON.stringify(publishedContent),
            repost: 'true'
        })
        .then(res => {
            console.log('RESPONSE - - - - -', res);
            const success = get(res, 'result.success');
            if(success) {
                toggleErrorModal(false);
                toggleSuccessModal(true);
            }
        })
        .catch(err => {
            console.log('ERROR - --  -', err);
            toggleErrorModal(false);
        })
    }

    const handlePublish = () => {
        console.log('PUBLISHED CONTENT - - --  ', publishedContent);
        setLoading(true);
        publishCampaign({
            campaign_id: campaignId,
            posts: JSON.stringify(publishedContent),
        })
        .then(res => {
            const resObj = get(res, 'result.data');
            setLoading(false);
            console.log('RESPONSE - -- - ', res);
            if(res && res.success && !resObj.error)
                handleSaveAndClose();
            else if (resObj.error === 'repost') {
                const failedArticles = getFailedArticles(resObj);
                console.log('FAILED ARTICLES - - - -', failedArticles);
                setFailedPublishArticles(failedArticles);
                toggleErrorModal(true);
            }
        })
        .catch(err => {
            console.log('ERROR - -- -- ', err);
            const failedArticles = getFailedArticles(get(err, 'response.result.data', {}));
            console.log('FAILED ARTICLES - - - -', failedArticles);
            setFailedPublishArticles(failedArticles);
            toggleErrorModal(true);
            setLoading(false);
        });
    }

    const handleAddTimeSlots = (platform) => {
        if(platform === 'em') {
            setEmTime([...emTime, 'Select day & time']);
        }

        if(platform === 'fb') {
            setFbTime([...fbTime, 'Select day & time']);
        }

        if(platform === 'ln') {
            setLnTime([...lnTime, 'Select day & time']);
        }

        if(platform === 'tw') {
            setTwTime([...twTime, 'Select day & time']);
        }
    }

    const removeTimeSlots = (time, platform) => {
        if(platform === 'em') {
            let removedTime = emTime.filter(e => e !== time);
            if(isEmpty(removedTime)) {
                setEmTime(['Select day & time']);
            } else {
                setEmTime(removedTime);
            }
            
        }

        if(platform === 'fb') {
            let removedTime = fbTime.filter(e => e !== time);
            if(isEmpty(removedTime)) {
                setFbTime(['Select day & time']);
            } else {
                setFbTime(removedTime);
            }
        }

        if(platform === 'ln') {
            let removedTime = lnTime.filter(e => e !== time);
            if(isEmpty(removedTime)) {
                setLnTime(['Select day & time']);
            } else {
                setLnTime(removedTime);
            }
        }

        if(platform === 'tw') {
            let removedTime = twTime.filter(e => e !== time);
            if(isEmpty(removedTime)) {
                setTwTime(['Select day & time']);
            } else {
                setTwTime(removedTime);
            }
        }
    }

    const continueValidationTime = () => {
        const selectedPlatforms = {
            em: {
                data: setObjectivesTabData.emList,
                time: emTime
            },
            fb: {
                data: setObjectivesTabData.fbList,
                time: fbTime
            },
            tw: {
                data: setObjectivesTabData.twList,
                time: twTime
            },
            ln: {
                data: setObjectivesTabData.lnList,
                time: lnTime
            }
        };
    
        const continueEnabled = Object.keys(selectedPlatforms).every(platform => {
            let enabled = true;
            if(selectedPlatforms[platform].data.length>0) 
                enabled = selectedPlatforms[platform].time[0] !== 'Select day & time';
            return enabled;
        });
        return !continueEnabled;
    }

    
    return (
        <div style={{height: "83%"}}>
            <PlanCampaignWrapper>
                <Flex flexDirection="column" backgroundColor="#FBFBFB" pb="10px" style={{ border: '1px solid #CFD9E4', borderRadius: '4px' }}>
                    <Flex width="100%" padding="15px">
                        <Flex width="50%">
                            <Flex width="50%" flexDirection="column">
                                <Flex width="100%" fontSize="12px" color="#000000">
                                    Start Date
                                </Flex>
                                <Flex width="100%" paddingTop="10px">
                                    <SetObjectivesInput 
                                        value={startDate && moment(startDate).format('DD MMMM YYYY')}
                                        readOnly={true}
                                        width="80%"
                                    />
                                    {disableEngagementSection ? (
                                        <img
                                            src="./assets/images/campaign-calender-disabled.svg"
                                            alt=""
                                            style={{ position: 'relative', left: '-32px'}}
                                        />
                                    ) : (
                                            <img
                                                src="./assets/images/campaign-calender.svg"
                                                alt=""
                                                style={{ position: 'relative', left: '-32px', cursor: 'pointer' }}
                                                onClick={() => setOpenDateModal(true)}
                                            />
                                    )}
                                    
                                    {openDateModal && (
                                        <MioDatePicker
                                            isOpen={openDateModal}
                                            customInput={<div />}
                                            minDate={new Date()}
                                            scheduleValue={startDate}
                                            onDateClose={() => setOpenDateModal(false)}
                                            onSubmit={handleDateSubmit}
                                            popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: "130px, 0px"
                                            }}}
                                            isFromCampaign
                                            filterDate={(date) => date.getDay() === 1}
                                        />
                                    )}
                                </Flex>
                            </Flex>
                            <Flex width="50%" flexDirection="column">
                                <Flex width="100%" fontSize="12px" color="#000000">
                                    Duration
                                </Flex>
                                <Flex width="100%" paddingTop="15px">
                                    <MioRadioSpan noBorder width="auto" fontSize="13px" mr="6px" labelpl="5px">
                                        <MioRadio
                                            id='duration-four'
                                            name="duration"
                                            onClick={() => setDuration('4 Weeks')}
                                            checked={duration === '4 Weeks'}
                                            margin="20% 17% 0%"
                                            disabled={disableEngagementSection}
                                        />
                                        <label htmlFor="duration-four">4 Weeks</label>
                                    </MioRadioSpan>
                                    <MioRadioSpan noBorder width="auto" fontSize="13px" mr="6px" labelpl="5px">
                                        <MioRadio
                                            id='duration-eight'
                                            name="duration"
                                            onClick={() => setDuration('8 Weeks')}
                                            checked={duration === '8 Weeks'}
                                            margin="20% 17% 0%"
                                            disabled={disableEngagementSection}
                                        />
                                        <label htmlFor="duration-eight">8 Weeks</label>
                                    </MioRadioSpan>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex width="50%">
                            <Flex width="50%" flexDirection="column">
                                <Flex width="100%" fontSize="12px" color="#000000">
                                    Frequency
                                </Flex>
                                <Flex width="100%" paddingTop="15px">
                                    <MioRadioSpan noBorder width="auto" fontSize="13px" mr="6px" labelpl="5px">
                                        <MioRadio
                                            id='frequency-week'
                                            name="frequency"
                                            onClick={() => setFrequency('Every Week')}
                                            checked={frequency === 'Every Week'}
                                            margin="20% 17% 0%"
                                            disabled={disableEngagementSection}
                                        />
                                        <label htmlFor="frequency-week">Every Week</label>
                                    </MioRadioSpan>
                                    <MioRadioSpan noBorder width="auto" fontSize="13px" mr="6px" labelpl="5px">
                                        <MioRadio
                                            id='frequency-other-week'
                                            name="frequency"
                                            onClick={() => setFrequency('Every Other Week')}
                                            checked={frequency === 'Every Other Week'}
                                            margin="20% 17% 0%"
                                            disabled={disableEngagementSection}
                                        />
                                        <label htmlFor="frequency-other-week">Every Other Week</label>
                                    </MioRadioSpan>
                                </Flex>
                            </Flex>
                            {/* <Flex width="50%" flexDirection="column">
                                <Flex width="100%" fontSize="12px" color="#000000">
                                    Engagement scope opt-out
                                </Flex>
                                <Flex width="100%">
                                    <NumberRangePicker
                                        width="80%"
                                        handleChange={handleEngagementScore}
                                        value={engagementScore}
                                        padding="20px 0px 0px"
                                        disabled={disableEngagementSection}
                                    />
                                </Flex>
                                <Flex width="100%" fontSize="10px" color="#000000" padding="10px 0px 0px">
                                    <span>Automatically opt out after exceeding: <strong>2 emails</strong></span>
                                </Flex>
                            </Flex> */}
                        </Flex>
                    </Flex>
                    <Flex justifyContent="flex-end" padding="0px 40px">
                        <Button
                            bgColor="#fff"
                            color="#6250ED"
                            margin="0px"
                            styles={{ borderRadius: '4px' }}
                            onClick={triggerFrequencyConfirmation}
                            disabled={disableEngagementSection}
                        >
                            Continue
                        </Button>
                    </Flex>

                </Flex>
                
                {showTimeView && (
                    <Flex width="100%" padding="10px 15px" flexDirection="column" mt="10px" backgroundColor="#FBFBFB" style={{ border: '1px solid #CFD9E4', borderRadius: '4px' }}>
                        <Flex width="100%" justifyContent="space-between">
                            <Flex width="25%" flexDirection="column">
                                <Flex color="#000000" fontSize="14px">Email</Flex>
                                <Flex width="100%" paddingTop="10px" flexDirection='column'>
                                    {emTime.map((time, index) => (
                                        <Flex width='100%' mb="10px">
                                            <SetObjectivesInput 
                                                value={time} 
                                                width="80%" 
                                                placeholder="Select day & time" 
                                                readOnly={true} 
                                                disabled={isEmpty(get(setObjectivesTabData, 'emList', [])) || disableTimeSection}
                                            />
                                            {!isEmpty(time) && time !== 'Select day & time' && !isEmpty(get(setObjectivesTabData, 'emList', [])) && !disableTimeSection ? (
                                                <img
                                                    src="/assets/images/campaign-delete.svg"
                                                    alt=""
                                                    width="20"
                                                    style={{ position: 'relative', left: '-27px', cursor: 'pointer' }}
                                                    onClick={() => removeTimeSlots(time, 'em')}
                                                />
                                            ) : (isEmpty(time) || time === 'Select day & time') && !isEmpty(get(setObjectivesTabData, 'emList', [])) && !disableTimeSection ? (
                                                <img
                                                    style={{ position: 'relative', left: '-27px', cursor: 'pointer' }}
                                                    src="/assets/images/noun_clock.svg"
                                                    width="20"
                                                    alt=""
                                                    onClick={() => handleTimePicker('em')}
                                                />
                                            ) : (
                                                <img
                                                    style={{ position: 'relative', left: '-27px' }}
                                                    src="/assets/images/noun_clock_disabled.svg"
                                                    width="20"
                                                    alt=""
                                                />
                                            )}
                                        </Flex>
                                    ))}
                                </Flex>
                                <Flex justifyContent="flex-end" pr="19.5%" pt="5px">
                                    <img 
                                        src={!disableTimeSection 
                                            && !isEmpty(emTime[emTime.length - 1]) 
                                            && emTime[emTime.length - 1] !== 'Select day & time'
                                            && emTime.length <= 5 ? '/assets/images/campaign-add-time.svg' : '/assets/images/campaign-add-time-disabled.svg'}  
                                        alt='' 
                                        style={{cursor: !disableTimeSection 
                                            && !isEmpty(emTime[emTime.length - 1]) 
                                            && emTime[emTime.length - 1] !== 'Select day & time'
                                            && emTime.length <= 5 ? 'pointer' : 'default'}} 
                                        onClick={() => !disableTimeSection 
                                            && !isEmpty(emTime[emTime.length - 1]) 
                                            && emTime[emTime.length - 1] !== 'Select day & time'
                                            && emTime.length <= 5 ? handleAddTimeSlots('em') : {}}
                                    />           
                                </Flex>
                            </Flex>
                            <Flex width="25%" flexDirection="column">
                                <Flex color="#000000" fontSize="14px">Facebook</Flex>
                                <Flex width="100%" paddingTop="10px" flexDirection='column'>
                                    {fbTime.map((time, index) => (
                                        <Flex width="100%" mb="10px">
                                            <SetObjectivesInput 
                                                value={time} 
                                                width="80%" placeholder="Select day & time" 
                                                readOnly={true} 
                                                disabled={isEmpty(get(setObjectivesTabData, 'fbList', [])) || disableTimeSection}
                                            />
                                            {!isEmpty(time) && time !== 'Select day & time' && !isEmpty(get(setObjectivesTabData, 'fbList', [])) && !disableTimeSection ? (
                                                <img
                                                    src="/assets/images/campaign-delete.svg"
                                                    alt=""
                                                    width="20"
                                                    style={{ position: 'relative', left: '-27px', cursor: 'pointer' }}
                                                    onClick={() => removeTimeSlots(time, 'fb')}
                                                />
                                            ) : (isEmpty(time) || time === 'Select day & time') && !isEmpty(get(setObjectivesTabData, 'fbList', [])) && !disableTimeSection ? (
                                                <img
                                                    style={{ position: 'relative', left: '-27px', cursor: 'pointer' }}
                                                    src="/assets/images/noun_clock.svg"
                                                    width="20"
                                                    alt=""
                                                    onClick={() => handleTimePicker('fb')}
                                                />
                                            ) : (
                                                <img
                                                    style={{ position: 'relative', left: '-27px' }}
                                                    src="/assets/images/noun_clock_disabled.svg"
                                                    width="20"
                                                    alt=""
                                                />
                                            )}
                                        </Flex>
                                    ))}
                                </Flex>
                                <Flex justifyContent="flex-end" pr="19.5%" pt="5px">
                                    <img 
                                        src={!disableTimeSection 
                                            && !isEmpty(fbTime[fbTime.length - 1]) 
                                            && fbTime[fbTime.length - 1] !== 'Select day & time'
                                            && fbTime.length <= 5 ? '/assets/images/campaign-add-time.svg' : '/assets/images/campaign-add-time-disabled.svg'}  
                                        alt='' 
                                        style={{cursor: !disableTimeSection 
                                            && !isEmpty(fbTime[fbTime.length - 1]) 
                                            && fbTime[fbTime.length - 1] !== 'Select day & time'
                                            && fbTime.length <= 5 ? 'pointer' : 'default'}} 
                                        onClick={() => !disableTimeSection 
                                            && !isEmpty(fbTime[fbTime.length - 1]) 
                                            && fbTime[fbTime.length - 1] !== 'Select day & time'
                                            && fbTime.length <= 5 ? handleAddTimeSlots('fb') : {}}
                                    />           
                                </Flex>
                            </Flex>
                            <Flex width="25%" flexDirection="column">
                                <Flex color="#000000" fontSize="14px">LinkedIn</Flex>
                                <Flex width="100%" paddingTop="10px" flexDirection='column'>
                                    {lnTime.map((time, index) => (
                                        <Flex width="100%" mb="10px">
                                            <SetObjectivesInput 
                                                value={time} 
                                                width="80%" 
                                                placeholder="Select day & time" 
                                                readOnly={true} 
                                                disabled={isEmpty(get(setObjectivesTabData, 'lnList', [])) || disableTimeSection}
                                            />
                                            {!isEmpty(time) && time !== 'Select day & time' && !isEmpty(get(setObjectivesTabData, 'lnList', [])) && !disableTimeSection ? (
                                                <img
                                                    src="/assets/images/campaign-delete.svg"
                                                    alt=""
                                                    width="20"
                                                    style={{ position: 'relative', left: '-27px', cursor: 'pointer' }}
                                                    onClick={() => removeTimeSlots(time, 'ln')}
                                                />
                                            ) : (isEmpty(time) || time === 'Select day & time') && !isEmpty(get(setObjectivesTabData, 'lnList', [])) && !disableTimeSection ? (
                                                <img
                                                    style={{ position: 'relative', left: '-27px', cursor: 'pointer' }}
                                                    src="/assets/images/noun_clock.svg"
                                                    width="20"
                                                    alt=""
                                                    onClick={() => handleTimePicker('ln')}
                                                />
                                            ) : (
                                                <img
                                                    style={{ position: 'relative', left: '-27px' }}
                                                    src="/assets/images/noun_clock_disabled.svg"
                                                    width="20"
                                                    alt=""
                                                />
                                            )}
                                        </Flex>
                                    ))}
                                </Flex>
                                <Flex justifyContent="flex-end" pr="19.5%" pt="5px">
                                    <img 
                                        src={!disableTimeSection 
                                            && !isEmpty(lnTime[lnTime.length - 1]) 
                                            && lnTime[lnTime.length - 1] !== 'Select day & time'
                                            && lnTime.length <= 5 ? '/assets/images/campaign-add-time.svg' : '/assets/images/campaign-add-time-disabled.svg'}  
                                        alt='' 
                                        style={{cursor: !disableTimeSection 
                                            && !isEmpty(lnTime[lnTime.length - 1]) 
                                            && lnTime[lnTime.length - 1] !== 'Select day & time'
                                            && lnTime.length <= 5 ? 'pointer' : 'default'}} 
                                        onClick={() => !!disableTimeSection 
                                            && !isEmpty(lnTime[lnTime.length - 1]) 
                                            && lnTime[lnTime.length - 1] !== 'Select day & time'
                                            && lnTime.length <= 5 ? handleAddTimeSlots('ln') : {}}
                                    />           
                                </Flex>
                            </Flex>
                            <Flex width="25%" flexDirection="column">
                                <Flex color="#000000" fontSize="14px">Twitter</Flex>
                                <Flex width="100%" paddingTop="10px" flexDirection='column'>
                                    {twTime.map((time, index) => (
                                        <Flex width="100%" mb="10px">
                                            <SetObjectivesInput 
                                                value={time} 
                                                width="80%" 
                                                placeholder="Select day & time" 
                                                readOnly={true} 
                                                disabled={isEmpty(get(setObjectivesTabData, 'twList', [])) || disableTimeSection}
                                            />
                                            {!isEmpty(time) && time !== 'Select day & time' && !isEmpty(get(setObjectivesTabData, 'twList', [])) && !disableTimeSection ? (
                                                <img
                                                    src="/assets/images/campaign-delete.svg"
                                                    alt=""
                                                    width="20"
                                                    style={{ position: 'relative', left: '-27px', cursor: 'pointer' }}
                                                    onClick={() => removeTimeSlots(time, 'tw')}
                                                />
                                            ) : (isEmpty(time) || time === 'Select day & time') && !isEmpty(get(setObjectivesTabData, 'twList', [])) && !disableTimeSection ? (
                                                <img
                                                    style={{ position: 'relative', left: '-27px', cursor: 'pointer' }}
                                                    src="/assets/images/noun_clock.svg"
                                                    width="20"
                                                    alt=""
                                                    onClick={() => handleTimePicker('tw')}
                                                />
                                            ) : (
                                                <img
                                                    style={{ position: 'relative', left: '-27px' }}
                                                    src="/assets/images/noun_clock_disabled.svg"
                                                    width="20"
                                                    alt=""
                                                />
                                            )}
                                        </Flex>
                                    ))}
                                </Flex>
                                <Flex justifyContent="flex-end" pr="19.5%" pt="5px">
                                    <img 
                                        src={!disableTimeSection 
                                            && !isEmpty(twTime[twTime.length - 1]) 
                                            && twTime[twTime.length - 1] !== 'Select day & time'
                                            && twTime.length <= 5 ? '/assets/images/campaign-add-time.svg' : '/assets/images/campaign-add-time-disabled.svg'}  
                                        alt='' 
                                        style={{cursor: !disableTimeSection 
                                            && !isEmpty(twTime[twTime.length - 1]) 
                                            && twTime[twTime.length - 1] !== 'Select day & time'
                                            && twTime.length <= 5 ? 'pointer' : 'default'}} 
                                        onClick={() => !!disableTimeSection 
                                            && !isEmpty(twTime[twTime.length - 1]) 
                                            && twTime[twTime.length - 1] !== 'Select day & time'
                                            && twTime.length <= 5 ? handleAddTimeSlots('tw') : {}}
                                    />           
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex justifyContent="flex-end" padding="0px 25px">
                            <Button
                                bgColor="#fff"
                                color="#6250ED"
                                margin="20px 0px 0px"
                                styles={{ borderRadius: '4px' }}
                                onClick={triggerTimeModal}
                                disabled={disableTimeSection || continueValidationTime()}
                            >
                                Continue
                            </Button>
                        </Flex>
                    </Flex>
                )}
                
                {showContentSection && (
                    <ContentSection>
                        {console.log('CONTENT WEEKS - - -', contentWeeks, 'WEEK ARTICLES DATA- - - - -', weekArticlesData)}
                        {contentWeeks.map((item, index) => (
                            <>
                                <ContentPanel>
                                    <Flex width="88%" onClick={() => onWeekClick(item)} justifyContent="flex-start">
                                        <Flex color="#000000" fontSize="14px">
                                            {item}
                                        </Flex>
                                        <span style={{color: '#b4bdba', fontSize: '11px', margin: '0 10px 0px 30px', backgroundColor: '#fff', borderRadius: '4px', padding: '3px 4px'}}>Posts: {campaignArticles && campaignArticles.filter(c => c.week === item).length}</span>
                                        <span style={{color: '#b4bdba', fontSize: '11px', backgroundColor: '#f7f1e1', borderRadius: '4px', padding: '3px 4px'}}>Content Required: {campaignArticles && campaignArticles.filter(c => c.week === item).filter(ele => !ele.content).length}</span>
                                    </Flex>
                                    <Flex width="12%" alignItems="center" justifyContent="space-between">
                                        <Flex width="90%" onClick={() => handleAssignTabChange(item)} fontSize="14px">
                                            SELECT CONTENT
                                        </Flex>
                                        <Flex width="10%" onClick={() => onWeekClick(item)}>
                                            <FontAwesomeIcon color="#000000" icon={item === selectedWeek ? faChevronUp : faChevronDown}/>    
                                        </Flex>     
                                    </Flex>    
                                </ContentPanel>
                                <ContentPanelSection style={{display: item === selectedWeek ? 'flex' : 'none'}}>
                                    {!isEmpty(weekArticlesData) && 
                                        Object.keys(weekArticlesData).map((dayItem, index, items) => (
                                            <>
                                                <DayPanel style={{cursor: 'pointer'}} onClick={() => onDayClick(dayItem)}>
                                                    <Flex color="#000000" fontSize="12px">
                                                        {dayItem}
                                                        <span style={{color: '#b4bdba', fontSize: '11px', margin: '0 10px 0px 30px', backgroundColor: '#fff', borderRadius: '4px', padding: '3px 4px'}}>Posts: {weekArticlesData && weekArticlesData[dayItem] && weekArticlesData[dayItem].length}</span>
                                                        <span style={{color: '#b4bdba', fontSize: '11px', backgroundColor: '#f7f1e1', borderRadius: '4px', padding: '3px 4px'}}>Content Required: {weekArticlesData && weekArticlesData[dayItem] && weekArticlesData[dayItem].filter(element => !element.content).length}</span>
                                                    </Flex>
                                                    <div>
                                                        <ButtonNoBorderBackground textDecoration="underline">
                                                            {selectedDays.includes(dayItem) ? 'Collapse' : 'Expand'}
                                                        </ButtonNoBorderBackground>
                                                    </div>
                                                </DayPanel>
                                                <DayPanelContentSection style={{display: selectedDays.includes(dayItem) ? 'flex' : 'none'}}>
                                                    {!isEmpty(weekArticlesData) && weekArticlesData[dayItem].map((ele, index) => (
                                                        <CampaignCard {...ele} handleBtnClick={() => handleAssignTabChange(item)} />
                                                    ))}
                                                </DayPanelContentSection>
                                                {(items.length - 1) !== index && (
                                                    <SetTopicsHr />
                                                )}
                                            </>
                                        ))
                                    }
                                </ContentPanelSection>
                            </>
                        ))}
                    </ContentSection>
                )}
            </PlanCampaignWrapper>
            <CampaignFooterButtons 
                enableNext={publishedContent && !isEmpty(Object.keys(publishedContent))} 
                nextHandler={handleNextClick} 
                backHandler={handleBackButtonClick}
                isPublished
                saveAndCloseHandler={handleSaveAndClose}
                loading={loading}
                publishHandler={handlePublish}
                enablePublish={!checkValidation()}                        
            />
            <DayTimePicker
                isOpen={openTimePicker}
                selectedPlatform={selectedPlatform}
                setOpen={setTimePicker}
                handleSave={generateDayTimePlatforms}
                setSelectedPlatform={setSelectedPlatform}
            />
            {openFrequencyModal && <FrequencyModal 
                isOpen={openFrequencyModal}
                handleModalClose={() => setFrequencyModal(false)}
                onAccept={handleDurationFrequency}    
                loader={confirmationLoader}        
            />}

            {openTimeModal && <FrequencyModal 
                isOpen={openTimeModal}
                handleModalClose={() => setTimeModal(false)}
                onAccept={handleDayTimeSection}
                loader={confirmationLoader}
            />}

            {errorModal && (
                <PublishErrorModal 
                    modalOpen={errorModal} 
                    closeModal={() => toggleErrorModal(false)} 
                    failedArticles={failedPublishArticles}
                    click={repostHandler}
                />
            )}

            {successModal && (
                <PublishSuccessModal
                    modalOpen={successModal}
                    modalClose={() => toggleSuccessModal(false)}
                    redirect={handleSaveAndClose}    
                />
            )}
        
        </div>
    )
}

export default PlanCampaign
