import { get, isEmpty } from 'lodash';
import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import { actions as contactsActions } from '../modules/contacts.module';
import { getContactGroups } from '../selector';
import {
  getContactsList,
  deleteContacts,
  uploadContacts,
  getContactsGroups,
  deleteContactGroup,
  createContactGroup,
  deleteMultipleContacts
} from '../../services/configure';
import { getContactListForEmail } from '../../utils';

function* contactsRequest({ payload }) {
  try {
    const { contactGroup, type, networkId, paginationData, navigateTo, onPageClick, sync, endIndex, sortOptions, shouldDeleteGroup, searchString } = get(payload, 'params', {});
    let newContactGroup, response, groupData, pagination;
    // console.log(endIndex);
    if(contactGroup === '') {
     response = {}; 
     pagination = {};
    } else {
      response = yield call(getContactsList, {
        contactGroup, 
        type, 
        networkId, 
        pagination: paginationData, 
        navigateTo, 
        sync,
        sortOptions, 
        searchString
      });
      pagination = get(response, 'result.data.pagination', {});
    }
    
    // if(navigateTo === 'after') {
    //   response = yield call(getContactsList, contactGroup, type, networkId, pagination.after, 'after');
    // } else if(navigateTo === 'after') {
    //   response = yield call(getContactsList, contactGroup, type, networkId, pagination.after, 'before');
    // }
    if(onPageClick) {
      groupData = yield select(getContactGroups);
      console.log(groupData);
    } else {
      const groupResponse = yield call(getContactsGroups);
      groupData = get(groupResponse, 'result.data', []);
    }  
    const checkSelectedContactGroup = groupData.filter(g => g.id === contactGroup);
    // console.log(checkSelectedContactGroup);
    if(isEmpty(checkSelectedContactGroup)) {
      let newGroupData = getContactListForEmail(groupData);
      newContactGroup = newGroupData.length ? newGroupData[0].id : '';
      console.log(newContactGroup);
    } else {
      newContactGroup = contactGroup;
    }
    if(!isEmpty(newContactGroup) && shouldDeleteGroup) {
      response = yield call(getContactsList, newContactGroup, type, networkId, paginationData, navigateTo, sync, sortOptions);
      pagination = get(response, 'result.data.pagination', {});
    }

    const data = get(response, `result.data.contacts.${newContactGroup}`, {});
    const totalCount = get(response, 'result.data.contacts.count.count', 0);
    // const afterData = get(afterResponse, `result.data.contacts.${contactGroup}`, null);
    yield put(contactsActions.api.contacts.response({ data, groupData, newContactGroup, pagination, afterData: (endIndex !== undefined && ((endIndex + 250) < +totalCount) && ((endIndex + 250) !== +totalCount)  && data.length === 250) ? true : !endIndex && +totalCount > 250 ? true : navigateTo === 'before' ? true : false, totalCount: totalCount}));
  } catch (error) {
    yield put(contactsActions.api.contacts.error(error));
  }
}

function* contactsDelete({ payload }) {
  try {
    const inputParams = get(payload, 'params', {});
    const { contactGroup, emailList, shouldDeleteGroup, selectedGroup, shouldDeleteMultipleContactGroup } = inputParams;
    console.log(selectedGroup);
    let apiCall = shouldDeleteGroup ? deleteMultipleContacts : deleteContacts;
    const response = yield call(apiCall, inputParams);
    console.log(response);
    if ((response.result && response.result.success) || response === '') {
      if(selectedGroup && selectedGroup.length > 0) {
        yield put(
          contactsActions.api.contacts.request({
            params: {
              contactGroup: selectedGroup,
              shouldDeleteGroup: shouldDeleteGroup
            },
          })
        );
      } else {
        yield put(
          contactsActions.api.contacts.request({
            params: {
              contactGroup: selectedGroup,
              shouldDeleteGroup: shouldDeleteGroup
            },
          })
        );
      }
    }
  } catch (error) {
    yield put(contactsActions.api.contacts.error(error));
  }
}

function* contactsCreate({ payload }) {
  try {
    const inputParams = get(payload, 'params', {});
    const apiCall = createContactGroup;
    const response = yield call(apiCall, inputParams);
    if(response.type){
      yield put(contactsActions.api.contacts.error(response));
    }else{
      const contactGroup =
       get(response, 'result.data.id');
      if (contactGroup) {
        yield put(
          contactsActions.api.contacts.request({
            params: {
              contactGroup,
            },
          })
        );
      }
    }
  } catch (error) {
    yield put(contactsActions.api.contacts.error(error));
  }
}

function* contactsSaga() {
  yield all([
    takeLatest(contactsActions.api.contacts.request, contactsRequest),
    takeLatest(contactsActions.api.contacts.delete, contactsDelete),
    takeLatest(contactsActions.api.contacts.create, contactsCreate),
  ]);
}

export default contactsSaga;
