import React, { useState, useEffect } from 'react';
import { get, isEmpty, map } from 'lodash';
import { Flex } from '@rebass/grid';
import { Button, Flipster, LoaderWrapper, ResponsiveImg, StyledHeading } from '../../../components';
import { getApprovedThemeList, getSpecificThemeItem } from '../../../utils';
import { useHistory } from 'react-router-dom';
import { unsubscribeTheme } from '../../../services/dashboard/index';
import { IconWrapper } from '../../../components/ListItemTile/ListItemTile.styles';
import { faMinus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Topics = ({
  setTabIndex,
  setSelectedExploreTabIndex,
  onExploreTabClick,
  setPopularTopicDetails,
  themesList,
  topicFromInvestment,
  setWdigetTabIndex,
  themeSetState,
  yourTopics,
  type,
  popularTopicDetails,
  popularTabIndex
}) => {
  const [approvedThemeList, setApprovedThemeList] = useState([]);
  const [jump, setJump] = useState(0);
  const [transition, setTransition] = useState(true);
  const history = useHistory();
  const [popularThemesFetching, setTopInsightsFetching] = useState(false);
  const [popularThemes, setPopularThemes] = useState([]);
  const [toggleCarouselView, setToggleCarouselView] = useState(true);
  const [showGhostBtn, toggleGhostBtn] = useState('');
  const [topicLoader, toggleLoader] = useState('');
  const [toggleEditView, setToggleEditView] = useState(false);
  const [toggleDeleteView, setToggleDeleteView] = useState(false);
  const [selectedTiles, setSelectedTiles] = useState(new Set());
  //const [deletedTiles, setDeletedTiles] = useState(new Set());
  const [myTopics, setMyTopics] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [carouselSelected, setCarouselSelected] = useState({});
  const [selectedTilesId, setSelectedTileId] = useState(new Set());
  const [isSelected, setIsSelected] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (!isEmpty(themesList)) {
      const retrieved = getApprovedThemeList(themesList);
      const featuredTopic = themesList.findIndex(r => r.name === 'Featured Topics');
      const initialIndex = featuredTopic !== -1 ? featuredTopic : Math.floor(retrieved.length / 2);
      const { id, description } = get(retrieved, `[${initialIndex}]`, {});
      setPopularTopicDetails({
        id,
        topicName: description,
      });
      setApprovedThemeList(retrieved);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themesList]);

  useEffect(() => {
    if (topicFromInvestment) {
      setJump(approvedThemeList.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicFromInvestment, approvedThemeList]);

  useEffect(() => {
    if (popularTabIndex === 2) {
      setSelectedTiles(new Set())
      setIsSelected(false)
    }
  }, [popularTabIndex])

  useEffect(() => {
    if (selectedTiles.size === 0) {
      setIsSelected(false)
      setSelectedTileId(new Set());
      setPopularTopicDetails({
        id: '',
        topic: ''
      })
    }
  }, [selectedTiles])

  useEffect(() => {
    if (toggleCarouselView && !topicFromInvestment) {
      defaultSelection(approvedThemeList);
    }
    if (topicFromInvestment) {
      setJump(approvedThemeList.length);
    }
    if ([...approvedThemeList, ...themeFromInvestment].length > 0 && themeSetState) {
      const themes = map([...approvedThemeList, ...themeFromInvestment], 'name');
      themeSetState({ yourTopics: themes.map(theme => theme.toUpperCase()) });
    }
  }, [topicFromInvestment, approvedThemeList]);

  const defaultSelection = (topics) => {
    const featuredTopic = topics.findIndex(r => r.name === 'Featured Topics');
    const initialIndex = featuredTopic !== -1 ? featuredTopic : Math.floor(topics.length / 2);
    const { id, description } = get(topics, `[${initialIndex}]`, {});
    setPopularTopicDetails({
      id,
      topicName: description
    });
    if (featuredTopic !== -1) {
      setJump (featuredTopic);
    }
    setSelectedTiles(new Set(selectedTiles).add(description?.toLowerCase()));
    setSelectedTileId(selectedTilesId.add(id));
    setCarouselSelected({ id, topic: description?.toLowerCase() });
    onExploreTabClick(id);
  }

  const onItemClick = (id, topicName, addTiles = false) => {
    setIsSelected(false);
    setIsChecked(false);
    if (addTiles) {
      if (selectedTiles.has(topicName)) {
        const newSelectedTiles = new Set(selectedTiles);
        newSelectedTiles.delete(topicName)
        setSelectedTiles(newSelectedTiles);

        const newSelectedTilesId = new Set(selectedTilesId);
        newSelectedTilesId.delete(id)
        setSelectedTileId(newSelectedTilesId);

        setPopularTopicDetails({
          id: Array.from(selectedTilesId)?.pop() ?? '',
          topicName: Array.from(selectedTiles)?.pop() ?? ''
        });
      } else {
        setSelectedTiles(new Set(selectedTiles).add(topicName))
        setSelectedTileId(selectedTilesId.add(id));

        setPopularTopicDetails({
          id,
          topicName
        });
      }
    } else {
      setSelectedTiles(new Set().add(topicName))
      setSelectedTileId(new Set().add(id));
      setPopularTopicDetails({
        id,
        topicName
      })
      setCarouselSelected({ id, topic: topicName?.toLowerCase() })
      onExploreTabClick(id)
    }
    setTabIndex(0);
    setTransition(false);
    //onExploreTabClick(id);
  };

  const shortenTitle = title => {
    if (title.length > 7)
      return title.substr(0, 7) + '...';
    else
      return title;
  };

  const unsubscribeHandler = topic => {
    setErrorMessage('');
    toggleLoader(topic.name);
    unsubscribeTheme(topic.id)
      .then(response => {
        toggleLoader('');
        let topics = approvedThemeList.filter(top => top.id !== topic.id);
        setApprovedThemeList(topics);
        if (selectedTilesId.has(topic.id)) {  
          const newSelectedTilesId = new Set(selectedTilesId);
          newSelectedTilesId.delete(topic.id)
          setSelectedTileId(newSelectedTilesId);
          if (newSelectedTilesId.size === 0){
            defaultSelection(topics);
          }
        }
      })
      .catch(err => {
        toggleLoader('');
        setErrorMessage('*An error Occurred...please try again.');
      });

  }

  // const deleteClick = () => {
  //   const allTiles = [...approvedThemeList, ...themeFromInvestment].map(t => t.name);
  //   //setSelectedTiles(new Set(allTiles));
  //   // setDeletedTiles(new Set(selectedTiles)); //For Individual topic delete
  //   setDeletedTiles(new Set(allTiles));
  // }

  // const unDeleteClick = () => {
  //   setDeletedTiles(new Set()); //For Individual topic undo delete as well
  //   // setSelectedTiles(new Set());
  //   // setPopularTopicDetails({
  //   //   id: '', topicName: ''
  //   // })
  // }

  const themeFromInvestment = topicFromInvestment
    ? getSpecificThemeItem(themesList, topicFromInvestment)
    : [];

  useEffect(() => {
    // if (themeSetState && (approvedThemeList || themeFromInvestment) && (!yourTopics || (yourTopics && yourTopics.length !== [...approvedThemeList, ...themeFromInvestment].length))) {
    //   const yourTopics = [...approvedThemeList, ...themeFromInvestment].map(({ name }) => name);
    //   themeSetState({ yourTopics });
    // }
  }, [themeFromInvestment, approvedThemeList, themeSetState, yourTopics]);

  const enableCarouselView = (isCarouselView) => {
    //setDeletedTiles(new Set());
    if (isCarouselView) {
      setSelectedTiles(new Set().add(carouselSelected.topic?.toLowerCase()))
      setSelectedTileId(new Set().add(carouselSelected.id))
      setPopularTopicDetails({
        id: carouselSelected.id,
        topicName: carouselSelected.topic
      })
    } else {
      if (selectedTiles.size > 0) {
        setIsSelected(true);
        setIsChecked(true);
      }
    }
    setToggleCarouselView(isCarouselView);
    setTabIndex(0);
    if (type && type === 'most-subscribed') {
      setSelectedExploreTabIndex(1);
    } else {
      setSelectedExploreTabIndex(3);
      setTransition(false);
    }
    onExploreTabClick(carouselSelected.id);
  }

  return (
    <>
      <div style={{ display: toggleCarouselView ? 'block' : 'none' }}>
        <Flipster type={type} transition={transition} jump={jump}>
          <LoaderWrapper isLoading={popularThemesFetching} styles={{ width: '30px', height: '30px', margin: '40px auto' }}>
            {[...approvedThemeList, ...themeFromInvestment].length > 0 ?
              [...approvedThemeList, ...themeFromInvestment].map((theme,index) => {
                const subscriptionStatus = get(
                  theme,
                  'subscription.status.application_status'
                );
                const { id, description, media_urls } = theme;
                const mediaDetails = get(media_urls, '[0]', '');
                const url = `${mediaDetails.path}${mediaDetails.id}.${mediaDetails.ext}`;
                return (
                  <li key={index}>
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      width="fit-content"
                      mx="auto"
                    >
                      <a
                        href="javascript:void(0)"
                        onClick={() => onItemClick(id, description?.toLowerCase())}
                      >
                        <img src={url} title={description} alt={description} />
                      </a>
                      <StyledHeading width={[...approvedThemeList, ...themeFromInvestment].length === 1 ? 'auto' : '85px'} title={description.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())} fontSize={description.split(' ')[0].length > 15 ? '9px' : '11px'} height='35px'>{description}</StyledHeading>
                    </Flex>
                  </li>
                );
              })

              :
              <Flex
                justifyContent='center'
                alignItems='center'
                width="fit-content"
                mx="auto"
              >
                <h2 style={{ fontSize: '14px', wordSpacing: 'normal', cursor: 'pointer', margin: 'auto' }} onClick={() => history.push('/theme')}>Please Select a Topic</h2>
              </Flex>
            }
          </LoaderWrapper>
        </Flipster>

      </div>
      {/* {!toggleCarouselView && type !== 'most-subscribed' && <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '12px'}}>Select topics to view content</div>}       */}
      {
        !toggleCarouselView && [...approvedThemeList, ...themeFromInvestment].length === 0 && <Flex
          justifyContent='center'
          alignItems='center'
          width="fit-content"
          mx="auto"
        >
          <h2 style={{ fontSize: '14px', wordSpacing: 'normal', cursor: 'pointer', marginTop: '40px', marginLeft: '14px'}} onClick={() => history.push('/theme')}>Please Select a Topic</h2>
        </Flex>
      }
      {
        [...approvedThemeList, ...themeFromInvestment].length > 0 && <div style={{ border: '0px', borderRadius: '10px', display: !toggleCarouselView ? 'block' : 'none' }}>
          <ul
            style={{
              display: 'inline-block',
              width: '110%',
              listStyleType: 'none',
              margin: '-20px -35px 0px -40px',
              textAlign: 'left',
              paddingLeft: '30px',
              height: '137px',
              marginTop: '12px'
            }}
          >
            {[...approvedThemeList, ...themeFromInvestment].length > 0 ? [...approvedThemeList, ...themeFromInvestment].map(topic => (
              <div
                style={{
                  display: 'inline-block',
                  marginBottom: '10px',
                  position: 'relative'
                }}
                onMouseEnter={() => toggleGhostBtn(topic.name)}
                onMouseLeave={() => toggleGhostBtn('')}
                //onClick={() => deletedTiles.size === 0 ? onItemClick(topic.id, topic.name?.toLowerCase(), true) : null}
                onClick={() => onItemClick(topic.id, topic.name?.toLowerCase(), true)}
              >

                <Flex
                  justifyContent='flex-start'
                  alignItems='center'
                  style={{
                    border: '0px',
                    borderRadius: '4px',
                    backgroundColor: '#ececec',
                    color: '#6351ed',
                    fontSize: '10px',
                    padding: '5px 10px 5px 1px',
                    margin: '0px 10px',
                    height: '35px',
                    width: '101px',
                    marginRight: '0',
                    marginLeft: '5px',
                    overflow: 'hidden',
                    backgroundColor: selectedTiles.has(topic.name?.toLowerCase()) ? '#7dc5d0' : 'rgb(236, 236, 236)',
                    //opacity: deletedTiles.has(topic.name) ? 0.6 : 1
                  }}
                >
                  <img
                    src={topic.media_urls[0].path + topic.media_urls[0].id + '.' + topic.media_urls[0].ext}
                    style={{
                      width: '25px',
                      height: '25px',
                      borderRadius: '4px',
                      margin: '0px 4px'
                    }}
                  />
                  <LoaderWrapper isLoading={topicLoader === topic.name}>
                  <div className='tooltip-div tooltip-disclosure' style={{position: 'unset'}}>
                      <p style={{cursor: topic.name.length > 7 ? 'pointer': '', color: selectedTiles.has(topic.name?.toLowerCase()) ? '#ffffff' : '#49484a'}}>{shortenTitle(topic.name)}</p>
                      {topic.name.length > 7 && <span className='tooltip-text tooltiptext-disclosure' style={{left: '90px', top: '10px', whiteSpace: 'nowrap', width: 'initial', backgroundColor: '#404040', color: '#ffffff'}}>{topic.name}</span>}
                  </div>
                  </LoaderWrapper>
                </Flex>

                {/* {showGhostBtn === topic.name && deletedTiles.has(topic.name) && !topicLoader ? (

                  <div
                    style={{
                      position: 'absolute',
                      backgroundColor: '#fffc',
                      height: '35px',
                      width: '101px',
                      top: '0px',
                      left: '5px',
                      borderRadius: '4px',
                      fontSize: '10px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      paddingLeft: '9px'
                    }}
                    onClick={() => unsubscribeHandler(topic)}
                  >
                    <IconWrapper style={{ marginBottom: '9px', marginRight: '10px' }}>
                      <FontAwesomeIcon icon={faMinus} />
                    </IconWrapper>
                    <p>{topic.name}</p>
                  </div>
                ) : null} */}
              </div>
            )) : null


            }
          </ul>

          <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            //height="35px"
            onClick={() => history.push('/theme')}
            //width={window.innerWidth < 768 ? '82%' : '55px'}
            bgColor='#eeeeee' color='#7c7c7c'
            styles={{ border: 'none', background: 'none', position: 'relative', padding: '0', top: [...approvedThemeList, ...themeFromInvestment].length>=16&&'-32px' }}
            //disabled={deletedTiles.size}
          >
            <ResponsiveImg src="/assets/images/edit.svg" style={{ height: '23px', marginTop: '3px', marginLeft: '0' }} />
          </Button>

          {/* <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            height="35px"
            width={window.innerWidth < 768 ? '82%' : '55px'}
            bgColor='#eeeeee' color='#7c7c7c'
            styles={{ border: 'none', background: 'none', position: 'relative', padding: '0', marginLeft: '-22px' }}
            disabled={deletedTiles.size}
          >
            <ResponsiveImg src="/assets/images/delete1.svg"
              style={{ height: '23px', marginTop: '-5px', marginLeft: '-65px'  }} onClick={deleteClick} />
          </Button>

          <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            height="35px"
            width={window.innerWidth < 768 ? '82%' : '55px'}
            bgColor='#eeeeee' color='#7c7c7c'
            styles={{ border: 'none', background: 'none', position: 'relative', padding: '0', marginLeft: '-22px' }}
            disabled={deletedTiles.size === 0}
          >
            <ResponsiveImg src="/assets/images/delete_d1.svg"
              style={{ height: '23px', marginTop: '-5px', marginLeft: '-65px'  }} onClick={unDeleteClick} />
          </Button> */}

          <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            height="35px"
            disabled={isChecked}
            width={window.innerWidth < 768 ? '82%' : '55px'}
            styles={{ border: 'none', background: 'none', position: 'relative', 
            padding: '0', marginLeft: '17px', 
            backgroundColor: !isChecked ? '#7dc5d0': '#adadad',
            color: '#FFFFFF', height: '25px', borderRadius: '4px',
            paddingLeft: '5px',
            paddingRight: '5px',
            width: '66px', 
            top: [...approvedThemeList, ...themeFromInvestment].length>=16?'-40px':'-8px' }}
            onClick={() => {
              // if (isSelected) {
              //   setIsSelected(false);
              //   setSelectedTiles(new Set());
              //   setSelectedTileId(new Set());
              //   setPopularTopicDetails({
              //     id: '',
              //     topic: ''
              //   })
              // } else {
                setIsSelected(true);
                onExploreTabClick(Array.from(selectedTilesId)?.toString()?.replaceAll(',', '%22%2C%22'))
            //  }
          }}
          >
            {/* <ResponsiveImg src={isSelected ? "/assets/images/check1.svg" : "/assets/images/check2.svg"} style={{ height: '23px', padding: '0', marginTop: '-5px', marginLeft: '-5px' }}
            onClick={() => {
                if (isSelected) {
                  setIsSelected(false);
                  setSelectedTiles(new Set());
                  setSelectedTileId(new Set());
                  setPopularTopicDetails({
                    id: '',
                    topic: ''
                  })
                } else {
                  setIsSelected(true);
                  onExploreTabClick(Array.from(selectedTilesId)?.toString()?.replaceAll(',', '%22%2C%22'))
                }
            }
            }
            /> */}
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;Apply
          </Button>

        </div>
      }
      {
        type !== 'most-subscribed' &&
        <>
          <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            height="35px"
            onClick={() => enableCarouselView(true)}
            width={window.innerWidth < 768 ? '82%' : 'auto'}
            bgColor='#eeeeee' color='#7c7c7c'
            styles={{ border: 'none', background: 'none', position: 'absolute', bottom: '4px', right: '50px', padding: '6px 0' }}
          >
            <ResponsiveImg src="/assets/images/Carousel-fill.svg" style={{ height: '23px', width: '28px' }} />
          </Button>
          <div style={{ borderLeft: '2px solid #867AF0', position: 'absolute', bottom: '9px', right: '38px', height: '25px' }}></div>
          <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            height="35px"
            onClick={() => enableCarouselView(false)}
            width={window.innerWidth < 768 ? '82%' : 'auto'}
            bgColor='#eeeeee' color='#7c7c7c'
            styles={{ border: 'none', background: 'none', position: 'absolute', bottom: '2px', right: '6px', padding: '0' }}
          >
            <ResponsiveImg src="/assets/images/gridview-fill.svg" style={{ height: '19px', width: '28px' }} />
          </Button>
        </>
      }
    </>
  );
};

export default Topics;
