import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { each, get, isEmpty } from 'lodash';
import { Flex, Box } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Tabs,
  TabList,
  TabTypeTwo,
  TabTypeThree,
  LoaderWrapper,
  Loader,
  StyledHeading,
  SocialCheckBoxes,
} from '../../components';
import ExploreTab from './ExploreTab';
import TrendingTab from './TrendingTab';
import Personalized from './Personalized';
import Discover from './Discover';
import Popular from './Popular';
import FeaturedPosts from './FeaturedPosts';
import MyMioTab from './MyMioTab';
import RecentSharedTab from './RecentSharedTab';
import ScheduledTab from './ScheduledTab';
import SearchResultsTab from './SearchResultsTab';
import InvestmentsTab from './InvestmentsTab';
import MyClientsTab from '../DashboardContainer/MyClientsTab/MyClientsTab';
import ApproveToShareTab from './ApproveToShareTab';
import AdvisorSearch from './Discover/AdvisorSearch';
import { useHistory } from 'react-router-dom';

import {
  getPopularTheme,
  getPremiumAdvisorChannelId,
  geYourTheme,
} from '../../services/dashboard';
import { getContactsGroups, getContactsList } from '../../services/configure';
import {
  AddInvetmentsButton,
  HoldingsWrapper,
  HoldingsIcon,
  StyledTooltip,
  StyledTabPanelTwo,
  BorderWhiteBox,
} from './DashboardContainer.styles';

import { actions as inviteActions } from '../../redux/modules/invite.module';
import { actions as exploreActions } from '../../redux/modules/explore-tab.module';
import { actions as advisorGroupsActions } from '../../redux/modules/advisor-group.module';
import { actions as contentlibActions } from '../../redux/modules/content-lib.module';
import { actions as themeActions } from '../../redux/modules/theme.module';
import { actions as shareActions } from '../../redux/modules/share.module';
import { actions as channelActions } from '../../redux/modules/channel.module';
import { company, emptyMessages } from '../../utils';

import { getChannelId, getUserRole } from '../../redux/selector';

import {
  StyledTabPanel,
  NoContentWrapper,
  ActicleCount,
  DashboardAdminSubTab,
  AbsoluteLoader,
  ExploreRelativeDiv,
} from './DashboardContainer.styles';
import useQuery from '../../customHooks/useQuery';
import ContentLibraryTab from './ContentLibraryTab';
import AddContent from './AddContent';

const AssetAdvisorDashboardContainer = ({
  exploreArticleRequest,
  exploreArticles,
  deleteArticleRequest,
  themesList,
  channelData,
  exploreArticlesFetching,
  searchResults,
  investmentResults,
  themeNames,
  exploreArticleDeleting,
  myMioData,
  selectedTickers,
  exploreSetState,
  roleType,
  searchFilters,
  tickerName,
  themeSetState,
  yourTopics,
  isAdmin,
  isInvEmpty,
  currentPortfolio,
  holdingsType,
  isComplianceAdmin,
  contentlibSetState,
  contentlibRequest,
  contentlibDeleteRequest,
  contentLibArticles,
  contentLibArticlesFetching,
  contentLibArticleDeleting,
  exploreArticleError,
  shareArticleSetState,
  userData,
  channelId,
  isShareBlocked,
  isDeleteBlocked,
  channelSetState,
}) => {
  const [showInvestmentTab, setShowInvestmentTab] = useState(false);
  const [showSearchTab, setShowSearchTab] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [localContent, setLocalContent] = useState([]);
  const [moreSearchParams, setMoreSearchParams] = useState({});
  const [filteredSearchEmpty, setFilteredSearchEmpty] = useState(false);
  const [selectedTabIndex, setTabIndex] = useState(0);
  const [trendingTopicDetails, setTrendingTopicDetails] = useState({});
  const [popularTopicDetails, setPopularTopicDetails] = useState({});
  const [subscribedTopicDetails, setSubscribedTopicDetails] = useState({});
  const [investmentTopic, setInvestmentTopic] = useState('');
  const [selectedExploreTabIndex, setSelectedExploreTabIndex] = useState(2);
  const [selectedRecentTabIndex, setSelectedRecentTabIndex] = useState(0);
  const [selectedScheduledTabIndex, setSelectedScheduledTabIndex] = useState(0);
  const [isSearchFilters, setIsSearchFilters] = useState(true);
  const [mostSubscribed, setMostSubscribed] = useState([]);
  const [widgetTabIndes, setWdigetTabIndex] = useState(1);
  const [discoverTabIndex, setDiscoverTabIndex] = useState(0);
  const [isFilteringStocksByTicker, setIsFilteringStocksByTicker] =
    useState(false);
  const [showClientDropDown, setShowClientDropDown] = useState(true);
  const [curatedTopicType, setCuratedTopicType] = useState('all');
  const [clientShareBlocked, setClientShareBlocked] = useState(false);
  const [searchOptions, setSearchOptions] = useState([
    { platform: 'all', checked: true, isDisconnected: false },
    { platform: 'my_content', checked: true, isDisconnected: false },
  ]);
  const [mostSubscribedThemes, setMostSubscribedThemes] = useState([]);
  const [popularThemes, setPopularThemes] = useState([]);
  const [premiumAdvisorChannelId, setPremiumAdvisorChannelId] = useState('');
  const [contactList, setContactList] = useState([]);

  const history = useHistory();
  const query = useQuery();

  const onDiscoverTabChange = (index) => {
    setTabIndex(0);
    if (index === 0) {
      setSelectedExploreTabIndex(0);
    } else if (index === 1) {
    } else if (index === 2 && discoverTabIndex !== index) {
      setSelectedExploreTabIndex(0);
      exploreArticleRequest({
        params: {
          id: popularTopicDetails.id,
          source: 'explore',
        },
      });
    } else if (
      index === 2 &&
      discoverTabIndex === index &&
      !isEmpty(mostSubscribedThemes)
    ) {
      setSelectedExploreTabIndex(0);
      const initialIndex = Math.floor(mostSubscribedThemes.length / 2);
      const { id, description } = get(
        mostSubscribedThemes,
        `[${initialIndex}]`,
        {}
      );
      setPopularTopicDetails({
        id,
        topicName: description,
      });
      exploreArticleRequest({
        params: {
          id: id,
          source: 'explore',
        },
      });
    }
    setDiscoverTabIndex(index);
    // setWdigetTabIndex(index);
  };

  const onWidgetTabChange = (index) => {
    if (index !== 3 && !showClientDropDown) {
      setShowClientDropDown(true);
    }
    contentlibSetState({ data: {} });
    setTabIndex(0);
    if (index === 1 && discoverTabIndex !== 2) {
      setSelectedExploreTabIndex(2);
      onExploreTabClick(1);
    } else if (
      index === 1 &&
      discoverTabIndex === 2 &&
      !isEmpty(popularThemes)
    ) {
      setSelectedExploreTabIndex(2);
      const initialIndex = Math.floor(popularThemes.length / 2);
      const { id, description } = get(popularThemes, `[${initialIndex}]`, {});
      setPopularTopicDetails({
        id,
        topicName: description,
      });
      exploreArticleRequest({
        params: {
          id: id,
          source: 'explore',
        },
      });
    } else if (index === 2) {
      setInvestmentTopic('');
      setSelectedExploreTabIndex(3);
    } else if (index === 0) {
      setSelectedExploreTabIndex(1);
      onExploreTabSelect(1, '');
    } else if (index === 3) {
      setShowArticleLoader(true);
      exploreArticleRequest({
        params: {
          id: 'SECURITY',
          source: 'myClients',
        },
      });
      setSelectedExploreTabIndex(4);
    } else {
      setSearchOptions([
        { platform: 'all', checked: true, isDisconnected: false },
        { platform: 'my_content', checked: true, isDisconnected: false },
      ]);
      setSelectedExploreTabIndex(5);
      setSearchValue('');
      exploreArticleRequest({
        params: {
          source: 'search',
        },
      });
    }
    setWdigetTabIndex(index);
  };

  const checkedStatus = (platform) => {
    const platformDetails = searchOptions.find((c) => c.platform === platform);
    return {
      isDiconnected: platformDetails.isDisconnected,
      checked: platformDetails.checked,
    };
  };

  const onCheckBoxClick = (platform) => {
    let searchOptionsCopy = [...searchOptions];
    if (platform === 'all') {
      if (!searchOptionsCopy[0].checked)
        searchOptionsCopy.forEach((option) => (option.checked = true));
      else searchOptionsCopy.forEach((option) => (option.checked = false));
    } else {
      searchOptionsCopy.every((option) => {
        if (option.platform === platform) {
          if (option.checked) searchOptionsCopy[0].checked = false;
          option.checked = !option.checked;
        }
        return option.platform !== platform;
      });
    }
    // searchOptions.forEach((item, index) => {
    //   if (item.platform === platform) {
    //     item.checked = !item.checked;
    //   }
    // });
    // const checkAllOption = searchOptions.find(s => s.platform === "all").checked;
    // if (checkAllOption) {
    //   searchOptions.forEach((item, index) => {
    //     if (item.platform !== "all") {
    //       item.checked = false;
    //       item.isDisconnected = true;
    //     }
    //   });
    // } else {
    //   searchOptions.forEach((item, index) => {
    //     item.isDisconnected = false;
    //   });
    // }
    setSearchOptions(searchOptionsCopy);
    checkedStatus(platform);
  };

  const [showArticleLoader, setShowArticleLoader] = useState(true);
  useEffect(() => {
    const excludeEmailList = [
      'GMAIL',
      'HUBSPOT',
      'REDTAIL',
      'OUTLOOK',
      'WEALTHBOX',
      'SALESFORCE',
    ];
    if (query.get('tab') && query.get('tab') === 'holdings') {
      onWidgetTabChange(2);
    }
    exploreSetState({ holdingsType: 'themes' });
    getPopularTheme().then((response) => {
      setMostSubscribedThemes(get(response, 'result.data', []));
      let themeResponse = get(response, 'result.data', []);
      themeResponse = themeResponse.map(({ name }) => name);
      setMostSubscribed(themeResponse);
    });
    geYourTheme().then((response) => {
      const yourThemeResponse = get(response, 'result.data', []).filter(
        (theme) =>
          get(theme, 'subscription.status.application_status') === 'approved'
      );
      setPopularThemes(yourThemeResponse);
      themeSetState({ popularThemes: yourThemeResponse });
    });

    getContactsGroups()
      .then((res) => {
        const contact_list = res.result.data.filter(
          (contact) => !excludeEmailList.includes(contact.name)
        );
        setContactList(contact_list);
        let count = res.result.data.filter((contact) =>
          contact.name.includes('positivly_')
        );
        if (count.length === 0) {
          setClientShareBlocked(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getPremiumAdvisorChannelId().then(({ result }) => {
      let channelId = '';
      each(result.data, (value, key) => {
        channelId =
          result.data[key] && result.data[key].length > 0
            ? result.data[key][0].id
            : '';
      });
      setPremiumAdvisorChannelId(channelId);
      channelSetState({ premiumAdvisorChannelId: channelId });
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(exploreArticleError)) {
      setShowArticleLoader(false);
      exploreSetState({ isFetching: false });
    }
  }, [exploreArticleError]);
  useEffect(() => {
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 2) {
      setShowArticleLoader(true);
      if (!isEmpty(popularTopicDetails)) {
        onExploreTabClick();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popularTopicDetails]);

  useEffect(() => {
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      setShowArticleLoader(true);
      if (!isEmpty(trendingTopicDetails)) {
        onTrendingTabClick();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendingTopicDetails]);

  useEffect(() => {
    if (!searchValue) {
      setShowArticleLoader(true);
      setTabIndex(0);
      setShowSearchTab(false);
      onExploreTabClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // useEffect(() => {
  //   onApproveToShare();
  //   setShowArticleLoader(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    // if(isAdmin){
    setShowArticleLoader(true);
    // }
  }, [isAdmin]);

  const onSearchClick = (moreParams) => {
    // setShowSearchTab(true);
    setTabIndex(0);
    setSelectedExploreTabIndex(5);
    setMoreSearchParams(moreParams);
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: searchValue,
        source: 'search',
        date: searchOptions,
        ...moreParams,
      },
    });
  };

  const onApproveToShare = () => {
    setShowArticleLoader(true);
    // setSelectedExploreTabIndex(0);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'approveToShare',
        date: curatedTopicType,
      },
    });

    onExploreTabSelect(selectedExploreTabIndex);
  };

  const onSubscribeTabClick = (topic) => {
    setShowArticleLoader(true);
    if (isComplianceAdmin) {
      setSelectedExploreTabIndex(1);
    }
    exploreArticleRequest({
      params: {
        id: topic && topic.length > 0 ? topic : subscribedTopicDetails.id,
        source: 'explore',
      },
    });
  };

  const onExploreTabClick = (topic) => {
    setShowArticleLoader(true);
    setSelectedExploreTabIndex(2);
    exploreArticleRequest({
      params: {
        id: topic && topic.length > 0 ? topic : popularTopicDetails.id,
        source: 'explore',
      },
    });
  };

  const onTrendingTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: trendingTopicDetails.id,
        date: trendingTopicDetails.date,
        source: 'trending',
      },
    });
  };

  const onRecentTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'recent',
      },
    });
  };

  const onRecentLastmonthTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'recent',
        type: 'last_month',
      },
    });
  };

  const onScheduledTabClick = () => {
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(0);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'recommendation_engine',
      },
    });
  };

  const onScheduledByCompanyTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'admin',
      },
    });
  };

  const onScheduledBySelfTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'user',
      },
    });
  };

  const onInvestmentClick = (id, holdingsType = '') => {
    // if (showSearchTab) {
    //   setTabIndex(4);
    // } else {
    //   setTabIndex(3);
    // }
    setTabIndex(0);
    setSelectedExploreTabIndex(3);
    setShowArticleLoader(true);
    setIsFilteringStocksByTicker(false);
    exploreArticleRequest({
      params: {
        id,
        source: 'investments',
      },
      holdingsType,
    });
    setShowInvestmentTab(true);
    onWidgetTabChange(2);
  };

  const onMyMioTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'mymio',
      },
    });
  };

  const onExploreTabSelect = (index, topic) => {
    setShowArticleLoader(true);
    setSelectedExploreTabIndex(index);
    if (index === 1) {
      setWdigetTabIndex(0);
      setCuratedTopicType('all');
      exploreArticleRequest({
        params: {
          id: popularTopicDetails.id,
          source: 'approveToShare',
          date: 'all',
        },
      });
      // onApproveToShare();
    } else if (index === 2) {
      onWidgetTabChange(1);
      onExploreTabClick(topic);
    } else if (index === 0) {
      onTrendingTabClick();
      onDiscoverTabChange(0);
    } else if (index === 3) {
      setWdigetTabIndex(2);
      onInvestmentClick();
    } else if (index === 4) {
      exploreArticleRequest({
        params: {
          id: 'SECURITY',
          source: 'myClients',
        },
      });
      setWdigetTabIndex(3);
    } else {
      setSearchOptions([
        { platform: 'all', checked: true, isDisconnected: false },
        { platform: 'my_content', checked: true, isDisconnected: false },
      ]);
      setWdigetTabIndex(3);
      setSearchValue('');
      exploreArticleRequest({
        params: {
          source: 'search',
        },
      });
    }
  };

  const onRecentTabSelect = (index) => {
    setShowArticleLoader(true);
    setSelectedRecentTabIndex(index);
  };

  const onScheduledTabSelect = (index) => {
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(index);
  };
  const onDeleteArticleClick = (id) => {
    let params = {};
    setShowArticleLoader(true);
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 1) {
      params = {
        deleteId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      params = {
        deleteId: id,
        id: popularTopicDetails.id,
        source: 'approveToShare',
        date: curatedTopicType,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'trending',
        date: trendingTopicDetails.date,
      };
    } else if (selectedTabIndex === 2 && selectedScheduledTabIndex === 0) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'recommendation_engine',
      };
    } else if (selectedScheduledTabIndex === 1 && selectedTabIndex === 2) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'admin',
      };
    } else if (selectedScheduledTabIndex === 1 && selectedTabIndex === 2) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'user',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 5) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'mymio',
        date: trendingTopicDetails.date,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      params = {
        deleteId: id,
        id: searchValue,
        source: 'search',
        date: searchOptions,
        ...moreSearchParams,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 2) {
      params = {
        deleteId: id,
        id: currentPortfolio,
        source: 'investments',
        date: trendingTopicDetails.date,
      };
    }

    deleteArticleRequest({ params });
  };

  const onTabSelect = (index) => {
    if (selectedTabIndex == index) return false;

    if (index === 3) {
      setCuratedTopicType('all');
      contentlibSetState({ data: {} });
      exploreSetState({ data: {} });
    }

    setShowArticleLoader(true);
    setTabIndex(index);
  };

  const handleTickerChange = (ticker) => {
    // exploreArticleRequest({
    //   params: {
    //     selectedTicker: ticker,
    //     source: 'investments',
    //   },
    // });
    setIsFilteringStocksByTicker(holdingsType === 'stocks' ? true : false);
    exploreSetState({ ticker }); //filtering from list based on selected tickerName
  };

  const handleTopicChange = (topic) => {
    setShowArticleLoader(true);
    setTabIndex(1);
    setInvestmentTopic(topic);
    onExploreTabClick(topic);
  };

  const exploreArticleFeed = get(exploreArticles, 'feed', []);
  const investmentResultsFeed = get(investmentResults, 'feed', []);
  const myMioFeed = get(myMioData, 'feed', []);
  const showTabLoader = !exploreArticleDeleting && exploreArticlesFetching;
  console.log(showTabLoader, showArticleLoader);
  return (
    <Box width={1}>
      <Flex
        flexDirection={`${window.innerWidth < 768 ? 'column' : 'row'}`}
        justifyContent="space-between"
        width={1}
        my="25px"
      >
        <Discover
          setTabIndex={setTabIndex}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          setTrendingTopicDetails={setTrendingTopicDetails}
          setPopularTopicDetails={setPopularTopicDetails}
          roleType={roleType}
          exploreSetState={exploreSetState}
          setShowArticleLoader={setShowArticleLoader}
          discoverTabIndex={discoverTabIndex}
          onDiscoverTabChange={onDiscoverTabChange}
          onTrendingTabClick={onTrendingTabClick}
          onExploreTabClick={onExploreTabClick}
          isShareBlocked={
            userData.details.user.extra_settings.share_content !== 'true'
          }
          shareArticleSetState={shareArticleSetState}
        />
        <Personalized
          exploreSetState={exploreSetState}
          themesList={themesList}
          setTabIndex={setTabIndex}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          setPopularTopicDetails={setPopularTopicDetails}
          setTrendingTopicDetails={setTrendingTopicDetails}
          trendingTopicDetails={trendingTopicDetails}
          onInvestmentClick={onInvestmentClick}
          topicFromInvestment={investmentTopic}
          setInvestmentTopic={setInvestmentTopic}
          roleType={roleType}
          tickerName={tickerName}
          tabIndex={selectedTabIndex}
          themeSetState={themeSetState}
          yourTopics={yourTopics}
          onWidgetTabChange={onWidgetTabChange}
          widgetTabIndes={widgetTabIndes}
          setWdigetTabIndex={setWdigetTabIndex}
          setShowArticleLoader={setShowArticleLoader}
          currentPortfolio={currentPortfolio}
          holdingsType={holdingsType}
          curatedTopicType={curatedTopicType}
          setCuratedTopicType={setCuratedTopicType}
          popularTopicDetails={popularTopicDetails}
          exploreArticleRequest={exploreArticleRequest}
          onCheckBoxClick={onCheckBoxClick}
          checkedStatus={checkedStatus}
          onSearchClick={onSearchClick}
          setSearchValue={setSearchValue}
          channelData={channelData}
          searchValue={searchValue}
          userData={userData}
          isPremiumAdvisor={false}
          isAdmin={false}
          contentlibSetState={contentlibSetState}
          isAssetAdvisor
          showClientDropDown={showClientDropDown}
          setShowClientDropDown={setShowClientDropDown}
          contactList={contactList}
        />

        {/* <Popular
          setTabIndex={() => setTabIndex(0)}
          setPopularTopicDetails={setSubscribedTopicDetails}
          isShareBlocked
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          roleType={roleType}
          setShowArticleLoader={setShowArticleLoader} 
          onExploreTabClick={onSubscribeTabClick}
          popularTabIndex={popularTabIndex}
          onPopularTabChange={onPopularTabChange}
        /> */}
        <FeaturedPosts
          shareArticleSetState={shareArticleSetState}
          isShareBlocked
          popularThemes={popularThemes}
          themeNames={themeNames}
        />
      </Flex>
      <Flex width={1}>
        <Tabs
          onSelect={onTabSelect}
          selectedIndex={selectedTabIndex}
          forceRenderTabPanel
        >
          <BorderWhiteBox type="admin-tab" padding="5px 5px">
            <TabList height="31px">
              <TabTypeThree
                type="dashboard"
                font={window.innerWidth}
                currentTab={selectedTabIndex === 0}
                onClick={onApproveToShare}
              >
                Explore
              </TabTypeThree>
              <TabTypeThree
                type="dashboard"
                font={window.innerWidth}
                currentTab={selectedTabIndex === 1}
                onClick={onRecentTabClick}
              >
                Recent
              </TabTypeThree>
              <TabTypeThree
                type="dashboard"
                font={window.innerWidth}
                currentTab={selectedTabIndex === 2}
                onClick={onScheduledTabClick}
              >
                Scheduled
              </TabTypeThree>
              {userData.details.user.extra_settings.add_content === 'true' ? (
                <TabTypeThree
                  type="dashboard"
                  currentTab={selectedTabIndex === 3}
                >
                  Add Content
                </TabTypeThree>
              ) : null}
            </TabList>

            {selectedTabIndex === 0 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onExploreTabSelect} forceRenderTabPanel>
                  <TabList>
                    <DashboardAdminSubTab
                      color="#49484a"
                      className={`${
                        selectedExploreTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                    >
                      Popular
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      className={`${
                        selectedExploreTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                    >
                      My Content
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      className={`${
                        selectedExploreTabIndex === 2 ? 'active' : 'inactive'
                      }`}
                    >
                      My Topics
                    </DashboardAdminSubTab>
                    {/* <DashboardAdminSubTab font={window.innerWidth} className={`${selectedExploreTabIndex === 2 ? 'active' : null}`} title='Investment Holdings'>Holdings</DashboardAdminSubTab> */}
                    <DashboardAdminSubTab
                      color="#49484a"
                      className={`${
                        selectedExploreTabIndex === 3 ? 'active' : 'inactive'
                      }`}
                    >
                      My Holdings{' '}
                      {holdingsType && (
                        <HoldingsWrapper className="tooltip">
                          <HoldingsIcon
                            style={{
                              ...(holdingsType === 'stocks' && {
                                width: '9px',
                              }),
                            }}
                            src={`/assets/images/${holdingsType}.png`}
                            data-testid={`holding_img_${holdingsType}`}
                          />
                          <StyledTooltip
                            right={holdingsType === 'stocks' ? '38%' : '40%'}
                            className="tooltiptext"
                            width={holdingsType === 'stocks' ? '80px' : null}
                          >
                            {holdingsType === 'stocks'
                              ? 'STOCK SPECIFIC'
                              : 'THEMATIC'}
                          </StyledTooltip>
                        </HoldingsWrapper>
                      )}
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      font={window.innerWidth}
                      className={`${
                        selectedExploreTabIndex === 4 ? 'active' : 'inactive'
                      }`}
                    >
                      My Clients
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      font={window.innerWidth}
                      className={`${
                        selectedExploreTabIndex === 5 ? 'active' : 'inactive'
                      }`}
                    >
                      Search
                    </DashboardAdminSubTab>
                  </TabList>
                  {selectedExploreTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        !contentLibArticlesFetching &&
                        isEmpty(exploreArticleFeed) &&
                        isEmpty(
                          get(contentLibArticles, 'channel_content', [])
                        ) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                        )}
                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <ContentLibraryTab
                          setLocalContent={setLocalContent}
                          selectedTopic={popularTopicDetails.topicName}
                          contentlibRequest={contentlibRequest}
                          contentlibDeleteRequest={contentlibDeleteRequest}
                          contentLibArticles={contentLibArticles}
                          contentLibArticlesFetching={
                            contentLibArticlesFetching
                          }
                          themeNames={themeNames}
                          contentLibArticleDeleting={contentLibArticleDeleting}
                          isShareBlocked={false}
                          isDeleteBlocked={false}
                          showTabLoader={showTabLoader}
                          setShowArticleLoader={setShowArticleLoader}
                          exploreArticles={exploreArticles}
                          curatedTopicType={curatedTopicType}
                          popularTopicDetails={popularTopicDetails}
                          exploreArticleRequest={exploreArticleRequest}
                          isAssetAdvisor
                          popularThemes={popularThemes}
                          userData={userData}
                          channelId={channelId}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedExploreTabIndex === 2 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                        )}
                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <ExploreTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          isShareBlocked={
                            userData.details.user.extra_settings
                              .share_content !== 'true'
                          }
                          setShowArticleLoader={setShowArticleLoader}
                          popularThemes={popularThemes}
                          userData={userData}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedExploreTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                        )}
                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <TrendingTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={trendingTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          isShareBlocked={
                            userData.details.user.extra_settings
                              .share_content !== 'true'
                          }
                          setShowArticleLoader={setShowArticleLoader}
                          popularThemes={popularThemes}
                          userData={userData}
                        />
                      )}
                    </StyledTabPanel>
                  )}

                  {selectedExploreTabIndex === 4 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching && isEmpty(popularThemes) ? (
                        <NoContentWrapper
                          style={{ cursor: 'pointer' }}
                          onClick={() => history.push('/theme')}
                        >
                          {emptyMessages.exploreTab}
                        </NoContentWrapper>
                      ) : (
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                        )
                      )}

                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <MyClientsTab
                          setLocalContent={setLocalContent}
                          selectedTopic={popularTopicDetails.topicName}
                          contentlibRequest={contentlibRequest}
                          contentlibDeleteRequest={contentlibDeleteRequest}
                          contentLibArticles={contentLibArticles}
                          contentLibArticlesFetching={
                            contentLibArticlesFetching
                          }
                          themeNames={themeNames}
                          contentLibArticleDeleting={contentLibArticleDeleting}
                          isShareBlocked={clientShareBlocked}
                          isDeleteBlocked={isDeleteBlocked}
                          showTabLoader={showTabLoader}
                          setShowArticleLoader={setShowArticleLoader}
                          curatedTopicType={curatedTopicType}
                          exploreArticles={exploreArticles}
                          popularThemes={popularThemes}
                          userData={userData}
                        />
                      )}
                    </StyledTabPanel>
                  )}

                  {selectedExploreTabIndex === 5 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                    >
                      {!exploreArticlesFetching &&
                        (isEmpty(get(searchResults, 'feed')) ||
                          Object.keys(searchResults).length === 0) && (
                          <NoContentWrapper>
                            {!isSearchFilters ||
                            filteredSearchEmpty ||
                            (searchValue &&
                              searchResults &&
                              searchResults.feed &&
                              searchResults.feed.length > 0 &&
                              searchFilters &&
                              searchFilters.length > 0) ? (
                              <Flex
                                width={1}
                                justifyContent="center"
                                alignItems="center"
                                data-testid="no-content-message"
                              >
                                No articles found
                              </Flex>
                            ) : (
                              <div>Search Results</div>
                            )}
                          </NoContentWrapper>
                        )}
                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <LoaderWrapper>
                          <SearchResultsTab
                            exploreArticleDeleting={exploreArticleDeleting}
                            selectedTopic={trendingTopicDetails.topicName}
                            exploreArticles={searchResults}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            isShareBlocked={
                              userData.details.user.extra_settings
                                .share_content !== 'true'
                            }
                            searchFilters={searchFilters}
                            exploreSetState={exploreSetState}
                            setIsSearchFilters={setIsSearchFilters}
                            mostSubscribed={mostSubscribed}
                            yourTopics={yourTopics}
                            setShowArticleLoader={setShowArticleLoader}
                            setFilteredSearchEmpty={setFilteredSearchEmpty}
                            popularThemes={popularThemes}
                            userData={userData}
                          />
                        </LoaderWrapper>
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedExploreTabIndex === 3 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                    >
                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!exploreArticlesFetching &&
                        isEmpty(get(investmentResults, 'feed')) && (
                          <NoContentWrapper>
                            <Flex
                              width={1}
                              justifyContent="center"
                              alignItems="center"
                              data-testid="no-content-message"
                            >
                              No articles found
                              {/* <Box>
                                <AddInvetmentsButton
                                  src="/assets/images/briefcase.png"
                                  alt="add invetments"
                                  onClick={() => history.push('/portfolio')}
                                />
                                <StyledHeading onClick={() => history.push('/portfolio')}>
                                  Add Your Investments
                                </StyledHeading>
                              </Box> */}
                            </Flex>
                          </NoContentWrapper>
                        )}

                      <LoaderWrapper isLoading={showTabLoader}>
                        {selectedTickers && selectedTickers.length > 0 && (
                          <InvestmentsTab
                            exploreArticleDeleting={exploreArticleDeleting}
                            selectedTopic={trendingTopicDetails.topicName}
                            exploreArticles={investmentResults}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            handleTickerChange={handleTickerChange}
                            isShareBlocked={
                              userData.details.user.extra_settings
                                .share_content !== 'true'
                            }
                            tickerName={tickerName}
                            selectedTickers={selectedTickers}
                            exploreSetState={exploreSetState}
                            setShowArticleLoader={setShowArticleLoader}
                            holdingsType={holdingsType}
                            isFilteringStocksByTicker={
                              isFilteringStocksByTicker
                            }
                            popularThemes={popularThemes}
                            userData={userData}
                          />
                        )}
                      </LoaderWrapper>
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 1 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onRecentTabSelect} forceRenderTabPanel>
                  <TabList>
                    <DashboardAdminSubTab
                      className={`${
                        selectedRecentTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                      onClick={onRecentTabClick}
                    >
                      All posts
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      className={`${
                        selectedRecentTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      onClick={onRecentLastmonthTabClick}
                    >
                      This Month
                    </DashboardAdminSubTab>
                  </TabList>
                  {selectedRecentTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.recentTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <RecentSharedTab
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          setShowArticleLoader={setShowArticleLoader}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedRecentTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.recentTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <RecentSharedTab
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          setShowArticleLoader={setShowArticleLoader}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 2 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onScheduledTabSelect} forceRenderTabPanel>
                  <TabList>
                    <DashboardAdminSubTab
                      className={`${
                        selectedScheduledTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledTabClick}
                    >
                      By Company
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      className={`${
                        selectedScheduledTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledByCompanyTabClick}
                      data-testid="recommended_by_company"
                    >
                      Recommended by {company.name}
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      className={`${
                        selectedScheduledTabIndex === 2 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledBySelfTabClick}
                      data-testid="my_scheduled_content"
                    >
                      My Scheduled Content
                    </DashboardAdminSubTab>
                  </TabList>
                  {selectedScheduledTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledByMioTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <ScheduledTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleRequest={exploreArticleRequest}
                          setShowArticleLoader={setShowArticleLoader}
                          scheduleType={'recommendation_engine'}
                          isShareBlocked
                        />
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedScheduledTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledByCompanyTab}
                          </NoContentWrapper>
                        )}
                      <LoaderWrapper isLoading={showTabLoader}>
                        <ScheduledTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleRequest={exploreArticleRequest}
                          setShowArticleLoader={setShowArticleLoader}
                          scheduleType={'admin'}
                          isShareBlocked
                        />
                      </LoaderWrapper>
                    </StyledTabPanel>
                  )}
                  {selectedScheduledTabIndex === 2 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledTab}
                          </NoContentWrapper>
                        )}
                      {!isEmpty(exploreArticleFeed) && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <ScheduledTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleRequest={exploreArticleRequest}
                          setShowArticleLoader={setShowArticleLoader}
                          scheduleType={'user'}
                          isShareBlocked
                        />
                      )}
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 3 && (
              <StyledTabPanelTwo>
                <Tabs forceRenderTabPanel>
                  <TabList>
                    {/* <DashboardAdminSubTab className={`${selectedContentTabIndex === 0 ? 'active' : null}`}>Your Content</DashboardAdminSubTab> */}
                    <DashboardAdminSubTab
                      style={{ border: 'none' }}
                      disabled={
                        userData.details.user.extra_settings.share_content !==
                        'true'
                      }
                    >
                      &nbsp;
                    </DashboardAdminSubTab>
                  </TabList>
                  <StyledTabPanel
                    className="react-tabs__tab-panel--selected"
                    padding="0px"
                    type="admin"
                  >
                    <AddContent
                      channelId={premiumAdvisorChannelId}
                      themesList={themesList}
                      setTabIndex={setTabIndex}
                      contentlibRequest={contentlibRequest}
                      contentLibArticles={contentLibArticles}
                      userData={userData}
                      setSelectedExploreTabIndex={setSelectedExploreTabIndex}
                      isEnterpriseAdvisor
                      exploreArticleRequest={exploreArticleRequest}
                      popularTopicDetails={popularTopicDetails}
                    />
                  </StyledTabPanel>
                </Tabs>
              </StyledTabPanelTwo>
            )}
          </BorderWhiteBox>
        </Tabs>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state) => {
  const subscriptionType = get(
    state,
    'user.data.details.user.billing.subscription_type'
  );
  const advisor_subscription =
    subscriptionType === 'platform_sponsored' ||
    subscriptionType === 'asset_manager_sponsored';
  const user_role = getUserRole(state);
  const isShareBlocked = user_role === 'general' && advisor_subscription;
  console.log('advisor_subscription: ', advisor_subscription);
  console.log('isShareBlocked:', isShareBlocked);
  return {
    groupsList: state.advisorGroups.data,
    exploreArticles: state.explore.data,
    contentLibArticles: state.contentlib.data,
    themesList: state.theme.data || [],
    channelData: get(state, 'channel.data', []),
    exploreArticlesFetching: state.explore.isFetching,
    searchResults: get(state, 'explore.search', {}),
    investmentResults: get(state, 'explore.investments', {}),
    themeNames: get(state, 'theme.themeNames', {}),
    exploreArticleDeleting: state.explore.isDeleting,
    myMioData: get(state, 'explore.mymio', {}),
    selectedTickers: state.explore.selectedTickers,
    searchFilters: state.explore.searchFilters,
    isInvEmpty: state.explore.isInvEmpty,
    tickerName: state.explore.ticker,
    yourTopics: state.theme.yourTopics,
    isAdmin: state.user.data.details.user.role,
    currentPortfolio: state.explore.currentPortfolio,
    userData: state.user.data,
    holdingsType: state.explore.holdingsType,
    isShareBlocked,
    exploreArticleError: state.explore.error,
    contentLibArticlesFetching: state.contentlib.isFetching,
    contentLibArticleDeleting: state.contentlib.isDeleting,
    channelId: getChannelId(state),
  };
};

const mapDispatchToProps = {
  exploreArticleRequest: exploreActions.api.explore.request,
  advisorGroupsRequest: advisorGroupsActions.api.groups.request,
  deleteArticleRequest: exploreActions.api.explore.delete,
  inviteRequest: inviteActions.api.invite.request,
  contentlibRequest: contentlibActions.api.contentlib.request,
  contentlibDeleteRequest: contentlibActions.api.contentlib.delete,
  exploreSetState: exploreActions.api.explore.setstate,
  themeSetState: themeActions.api.theme.setstate,
  contentlibSetState: contentlibActions.api.contentlib.setstate,
  shareArticleSetState: shareActions.api.share.setstate,
  channelSetState: channelActions.api.channel.setstate,
};

AssetAdvisorDashboardContainer.defaultProps = {
  groupsList: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetAdvisorDashboardContainer);
