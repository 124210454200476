import { Box, Flex } from '@rebass/grid';
import React from 'react';
import { DripIconButton, ResponsiveImg } from '../../../components';
import { onImageError } from '../../../utils';
import { SelectedContentTopic } from './AddContent.styles';

const OneColContent = ({
  id,
  topic,
  description,
  url,
  handleDrag,
  handleDrop,
  removeSelectedArticle,
}) => {
  let draggable = true;
  // let trimmedTopic =
  //   (topic || '').length > 25 ? `${topic.substring(0, 25)}...` : topic;

  // let trimmedDesc =
  //   (description || '').length > 35
  //     ? `${description.substring(0, 35)}...`
  //     : description;

  const handleDragover = (e) => {
    if (draggable) {
      e.preventDefault();
    }
  };

  const onHandleDrag = (e) => {
    if (draggable) {
      handleDrag(e);
    }
  };

  const onDropDrag = (e) => {
    if (draggable) {
      handleDrop(e);
    }
  };

  console.log('URL - - - ', url);

  return (
    <Flex
      width="230px"
      p="10px"
      mb="10px"
      style={{
        border: '1px solid #d4e0e5',
        borderRadius: '5px',
      }}
      backgroundColor="white"
      onDragOver={(e) => handleDragover(e)}
      onDragStart={onHandleDrag}
      onDrop={onDropDrag}
      key={id}
      id={id}
    >
      <div>
        <ResponsiveImg
          src={url}
          onError={onImageError}
          style={{
            width: '60px',
            border: '1px solid #d4e0e5',
            borderRadius: '5px',
            height: '55px',
            objectFit: 'cover',
          }}
        />
      </div>
      <Flex
        flexDirection="column"
        ml="10px"
        style={{ textAlign: 'left', color: 'black' }}
        width="55%"
      >
        <SelectedContentTopic title={topic}>{topic}</SelectedContentTopic>
        {/* <div
          style={{ fontSize: '0.7rem', color: '#708892' }}
          title={description}
        >
          {trimmedDesc}
        </div> */}
      </Flex>
      <Flex>
        <Box>
          <DripIconButton
            icon="cross"
            onClick={() => removeSelectedArticle(id)}
            styles={{ height: '24px', fontSize: '1rem', color: 'black' }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default OneColContent;
