import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const CreateContainer = styled(Flex)`
  border: 1px solid #cfd9e4;
  border-radius: 4px;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  ${'' /* width: 80%; */}
  padding: ${({ padding }) => padding || '9px'};
  padding-top: ${({ paddingTop }) => paddingTop || '9px'};
  height: ${({ height }) => height || '470px'};
  margin-top: ${({ marginTop }) => marginTop || '35px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '35px'};
  background-color: #f6f9fb;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
  ${'' /* margin-bottom: ${({ mb }) => mb || '25px'}; */}
`;

export const CreateContainerCards = styled(Flex)`
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  padding: 20px;
  height: 210px;
  margin: 25px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  display: flex;
  flex-direction: column;
`;

export const CardText = styled(Flex)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const TitleSpan = styled(Flex)`
  color: #4e6780;
  font-size: 20px;
  margin-bottom: 15px;
`;

export const CardTitle = styled(Flex)`
  color: #000;
  font-size: 16px;
  font-weight: 800;
  margin-top: 5px;
`;

export const CardSubtitle = styled(Flex)`
  color: #708892;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
`;

export const DripFooterSection = styled.div.attrs({
  className: 'drip-footer-section',
})`
  display: flex;
  background-color: #fff;
  height: 10%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
