import { Flex } from '@rebass/grid';
import React from 'react';
import { Button, LoaderWrapper, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../components';

const ConfirmationModal = ({ 
    modalTitle, 
    modalWidth, 
    closeHandler, 
    clickHandler, 
    loader,
    message,
    okBtnText,
    onlyShowOk,
    noClose,
    btnMargin,
    noButton,
    mt 
}) => {
    return (
        <ModalV2 bgColor='#fff' isOpen={true} width={modalWidth} onRequestClose={!noClose ? closeHandler : {}}>
            <ModalHeader fontSize='16px' style={{marginTop: mt || ''}}>
                {modalTitle}
                {!noClose && (
                    <ModalCloseButton color='#6351ed' onClick={closeHandler} />
                )}
            </ModalHeader>
            <ModalBody>
                {message && (
                    <div style={{fontSize: '12px', color: '#49484a'}}>*{message}</div>
                )}
                {!noButton && (
                    <LoaderWrapper isLoading={loader}>
                        <Flex width='16em' margin='auto' justifyContent='space-evenly' alignItems='center'>
                            <Button width='auto' margin={btnMargin} onClick={clickHandler}>{okBtnText || 'Yes'}</Button>
                            {!onlyShowOk && (
                                <Button width='auto' margin={btnMargin} onClick={closeHandler}>No</Button>
                            )}
                        </Flex>
                    </LoaderWrapper>
                )}

            </ModalBody>
        </ModalV2>
    );
}

export default ConfirmationModal;