import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useEffect } from 'react';
import {
  faLinkedinIn,
  faTwitter,
  faGoogle,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SummaryContainer,
  ContainerList,
  ListItem,
  Overview,
  UserAccount,
  InsightScore,
  InsightScoreGraph,
  UserName,
  Image,
  OverviewTitle,
  OverviewList,
  OverlayBox,
  OverlayFooter,
  OverlayLink,
  OverviewListItem,
  OverviewInnerList,
  ListItemHeader,
  ListItemLink,
  ListItemLinkDiv,
  ListItemLinkImage,
  ListItemLinkFooter,
  InsightScoreHeader,
  InsightScoreBody,
  InsightScorePointContainer,
  InsightScoreFooterLink,
  InsightScoreContent,
  InsightScoreFooter,
  InsightScorePoint,
  ComponentSection,
  CustomModalBody,
  AnalyticsValue,
  TabsContainer,
  ChartBoxes,
  ChartBox,
  InfoBox,
  InfoBoxHeader,
  InfoBoxData,
  ChartInfo,
  ResponsiveTabs,
  LoaderContainer,
  TotalImpactMineContainer,
  PlaformLogoContainer,
} from './AnalyticsSummaryContainer.styles';
import { AdminSubTab, DashboardAdminSubTab } from '../DashboardContainer/DashboardContainer.styles';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
} from '../../components/Modal/Modal';
import {
  TabList,
  TabPanel,
  TabTypeTwo,
  TabTypeThree,
  BasicLineChart,
  Tabs,
  SocialLoginButtons,
} from '../../components';
import { getTopDetails } from '../../services/dashboard/index';
import { connect } from 'react-redux';
import { getUserDetails } from '../../redux/selector';
import { EmailSettingsModal } from '../../components/';
import { getFideatorScore } from '../../utils';

// const socialIcons = [
//   { id: 'ln', icon: faLinkedinIn },
//   { id: 'tw', icon: faTwitter },
//   { id: 'go', icon: faGoogle },
//   { id: 'ol', src: 'assets/images/outlook.png' },
//   { id: 'em', src: 'assets/images/envelope.png' },
// ];

const styles = {
  AdminSubTab: {fontSize: '14px'}
}

const AnalyticsSummaryContainer = ({ userData, socialNetworkData }) => {
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [scoreRange, setScoreRange] = useState({ max: 0, min: 0 });
  const [oneDayChange, setOneDayChange] = useState(0);
  const [showOverlay, setShowOverlay] = useState(true);
  const [overviewList, setOverviewList] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  // const [socialAccountURL, setSocialAccountURL] = useState(null);

  const getImage = (image, type) => {
    if (image && image.media_urls[0])
      return `${image.media_urls[0].path}/${image.media_urls[0].name.resized}.${image.media_urls[0].ext}`;
    if (type === 'profile') return '/assets/images/personalized.svg';
    return '/assets/images/GaBdRGkV_a7b04f53_r.jpg';
  };

  const getName = (obj) => {
    if (obj && obj.name) return obj.name;
    return 'N/A';
  };
  const updateOverviewList = (topic, platform, partner, reach) => {
    const platformFooter = platform.tw
      ? platform.ln
        ? platform.ln.mine > platform.tw.mine
          ? 'LinkedIn'
          : 'Twitter'
        : 'Twitter'
      : platform.ln
      ? 'LinkedIn'
      : 'N/A';

    let totalreach = 0;

    reach.forEach((r) => {
      _.map(_.values(r), (ro) => (totalreach += ro.mine));
    });
    if (topic || platform.length > 0 || partner || reach.length > 0) {
      let listObj = [
        {
          title: 'Top Topic',
          image: getImage(topic),
          footer: getName(topic),
        },
        {
          title: 'Top Publisher',
          image: getImage(partner),
          footer: getName(partner),
        },
        {
          id: 'tpl',
          title: 'Top Platform',
          icon: platformFooter,
          footer: platformFooter,
        },
        {
          id: 'ti',
          title: 'Top Impact',
          footer: 'Total Reach',
          mine: totalreach,
        },
      ];
      setOverviewList(listObj);
      setShowOverlay(false);
    } else {
      setOverviewList([
        {
          title: 'Top Topic',
          image: getImage(topic),
          footer: getName(topic),
        },
        {
          title: 'Top Partner',
          image: getImage(partner),
          footer: getName(partner),
        },
        {
          id: 'tpl',
          title: 'Top Platform',
          icon: platformFooter,
          footer: platformFooter,
        },
        {
          id: 'ti',
          title: 'Top Impact',
          footer: 'Total Reach',
          mine: totalreach,
        },
      ]);
      setShowOverlay(true);
    }
  };

  const fillEmptyValues = (dateFrom, data) => {
    let defArray = [];
    for (
      let d = new Date(dateFrom);
      d <= new Date();
      d.setDate(d.getDate() + 1)
    ) {
      defArray.push({ date: moment(d).format('YYYY-MM-DD'), mine: 25 });
    }
    const merged = _(defArray)
      .keyBy('date')
      .merge(_.keyBy(data, 'date'))
      .values()
      .value();
    return merged;
  };

  const convertGraphData = (dateFrom, graphdata) => {
    let scoreData = graphdata.result.data.analytics.score;

    for (let key of Object.entries(scoreData)) {
      _.assign(scoreData[key[0]], { date: key[0] });
    }
    const data = _.map(_.values(scoreData), (data) => {
      return {
        mine: data['mine'].toString(),
        ...data,
      };
    });

    const mergedArray = fillEmptyValues(dateFrom, data);

    const min = mergedArray.reduce(
      (min, p) => (p.mine < min ? p.mine : min),
      data[0].mine
    );
    const max = mergedArray.reduce(
      (max, p) => (p.mine > max ? p.mine : max),
      data[0].mine
    );
    const dayChange =
      parseInt(mergedArray[mergedArray.length - 2].mine) -
      parseInt(mergedArray[mergedArray.length - 3].mine);

    setOneDayChange(dayChange);
    setScoreRange({
      min,
      max,
    });

    return mergedArray;
  };

  useEffect(() => {
    const getTopicDetails = async () => {
      const dateTo = moment().format('YYYY-MM-DD');
      const dateFrom = moment().subtract(29, 'd').format('YYYY-MM-DD');
      const graphFrom = moment().subtract(6, 'd').format('YYYY-MM-DD');
      const [
        user_top_topic,
        user_top_partner,
        user_top_platform,
        user_total_reach,
        user_insight_score,
        socialAccounts,
      ] = await Promise.all([
        getTopDetails(dateTo, dateFrom, `user_top_topic`),
        getTopDetails(dateTo, dateFrom, `user_top_partner`),
        getTopDetails(dateTo, dateFrom, `user_top_platform`),
        getTopDetails(dateTo, dateFrom, `user_total_reach`),
        getTopDetails(dateTo, graphFrom, `user_insight_score`),
        // getSocialAccountURLs(),
      ]);

      const topic = _.values(user_top_topic.result.data.details.theme)[0];
      const platform = _.values(user_top_platform.result.data.analytics)[0];
      const partner = _.values(user_top_partner.result.data.details.channel)[0];
      const reach = _.values(user_total_reach.result.data.analytics.reach);

      updateOverviewList(topic, platform, partner, reach);

      setGraphData(convertGraphData(graphFrom, user_insight_score));
      // setSocialAccountURL(socialAccounts.result.data);
    };

    getTopicDetails();
  }, []);

  const handleGraphTabChange = (val) => {
    setGraphData(null);
    setTabIndex(val);
    const dateTo = moment().format('YYYY-MM-DD');
    const dateFrom =
      val === 0
        ? moment().subtract(6, 'd').format('YYYY-MM-DD')
        : val === 1
        ? moment().subtract(29, 'd').format('YYYY-MM-DD')
        : moment().subtract(89, 'd').format('YYYY-MM-DD');

    getTopDetails(dateTo, dateFrom, 'user_insight_score')
      .then((res) => {
        setGraphData(convertGraphData(dateFrom, res));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEmailModalSubmit = () => {};

  const getBottomText = (platform, emailPlaftform) => {
    let platformDetails = _.get(socialNetworkData, platform, []);
    if (emailPlaftform) {
      platformDetails = _.find(platformDetails, {
        snetworkProvider: emailPlaftform,
      });
    }
    const text = _.isEmpty(platformDetails) ? 'Connect' : 'Disconnect';
    return {
      bottomText: text,
      isDiconnected: text === 'Disconnect',
    };
  };

  // const handleSocialIconClick = (id) => {
  //   let link = '';
  //   if (socialAccountURL) {
  //     switch (id) {
  //       case 'ln':
  //         link = socialAccountURL.ln.oauth_url;
  //         break;
  //       case 'tw':
  //         link = socialAccountURL.tw.oauth_url;
  //         break;
  //       case 'go':
  //         link = socialAccountURL.em.oauth_url.google;
  //         break;
  //       case 'ol':
  //         link = socialAccountURL.em.oauth_url.outlook;
  //         break;
  //       case 'em':
  //         link = '';
  //         break;
  //       default:
  //         link = '';
  //     }
  //     if (link) window.location.href = link;
  //     else {
  //       console.log('opened');
  //       setEmailModal(true);
  //     }
  //   }
  // };

  return (
    <SummaryContainer>
      <ContainerList>
        <ListItem>
          <UserAccount>
            {userData.media_urls ? (
              <Image src={getImage(userData, 'profile')} alt="profile" />
            ) : (
              <Image
                src={`/assets/images/Member_icon.png`}
                alt="profile"
                style={{ height: '117px', width: '117px', borderRadius: '9%'}}
              />
            )}
            <UserName>
              {userData.name && `${userData.name.first} ${userData.name.last}`}
            </UserName>
            {/* <SocialLoginButtonsContainer> */}
            {/* {socialIcons.map((icon, index) => (
                <InlineBlock
                  onClick={() => handleSocialIconClick(icon.id)}
                  key={icon.id}
                  id={icon.id}
                >
                  {icon.icon ? (
                    <Icon icon={icon.icon} style={{ cursor: 'pointer' }} />
                  ) : (
                    <IconImage
                      pointer
                      src={icon.src}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  <div>Connect</div>
                </InlineBlock>
              ))} */}
            {/* </SocialLoginButtonsContainer> */}
            {/* <LinkedInLabel>
                <LinkedIn
                  state="fdsf78fyds7fm"
                  scope={'r_liteprofile w_member_social'}
                  clientId={LN_APP_ID}
                  onFailure={handleLinkedinClick}
                  onSuccess={handleLinkedinClick}
                  redirectUri={callbackUrl}
                />
                <FontAwesome icon={faLinkedinIn} small={true} pointer />
              </LinkedInLabel>
              <TwitterLogin
                authCallback={handleTwitterClick}
                consumerKey={TW_APP_ID}
                consumerSecret={TW_APP_SECRET}
                callbackUrl={callbackUrl}
              >
                <FontAwesome icon={faTwitter} small={true} pointer />
              </TwitterLogin>
              <GoogleLoginLabel>
                <GoogleLogin
                  clientId={GO_APP_ID}
                  onSuccess={handleGoogleClick}
                  onFailure={handleGoogleClick}
                  cookiePolicy={'single_host_origin'}
                />
                <FontAwesome icon={faGoogle} small={true} pointer />
              </GoogleLoginLabel>
              <OutlookLabel onClick={handleOutlookClick}>
                <img src="/assets/images/outlook.png" alt="outlook" />
              </OutlookLabel>
              <FontAwesome
                icon={faEnvelope}
                small={true}
                pointer
                onClick={toggleEmailModal}
              /> */}
            <SocialLoginButtons
              small={true}
              pointer
              buttons={[
                {
                  name: 'LinkedIn',
                  faIcon: faLinkedinIn,
                  // onClick: () => handleSocialIconClick('ln'),
                  isDiconnected: getBottomText('ln').isDiconnected,
                  platform: 'ln',
                },
                {
                  name: 'Twittter',
                  faIcon: faTwitter,
                  // onClick: () => handleSocialIconClick('tw'),
                  isDiconnected: getBottomText('tw').isDiconnected,
                  platform: 'tw',
                },
                {
                  name: 'Google',
                  faIcon: faGoogle,
                  // onClick: () => handleSocialIconClick('go'),
                  isDiconnected: getBottomText('em', 'google').isDiconnected,
                  platform: 'em',
                  emailPlaftform: 'google',
                },
                {
                  name: 'Outlook',
                  imgUrl: '/assets/images/outlook-disconnected.png',
                  // onClick: () => handleSocialIconClick('ol'),
                  isDiconnected: getBottomText('em', 'outlook').isDiconnected,
                  platform: 'em',
                  emailPlaftform: 'outlook',
                },
                {
                  name: 'Zoom',
                  imgUrl: '/public/assets/images/Zoom.svg',
                  // onClick: () => handleSocialIconClick('ol'),
                  isDiconnected: getBottomText('em', 'zoom').isDiconnected,
                  platform: 'em',
                  emailPlaftform: 'zoom',
                },
                {
                  name: 'Email',
                  imgUrl: '/assets/images/email.svg',
                  // onClick: () => handleSocialIconClick('em'),
                },
              ]}
            />

            <EmailSettingsModal
              open={emailModal}
              handleClose={() => setEmailModal(false)}
              handleModalSubmit={handleEmailModalSubmit}
            />
          </UserAccount>
        </ListItem>
        <ListItem>
          <Overview>
            <OverviewTitle>Overview</OverviewTitle>
            <OverviewList>
              {showOverlay && (
                <OverlayBox>
                  <OverlayFooter>
                    Start{' '}
                    <OverlayLink href="/admin/dashboard">sharing</OverlayLink>{' '}
                    to see some data.
                  </OverlayFooter>
                </OverlayBox>
              )}

              <OverviewInnerList>
                {overviewList.length !== 0 ? (
                  overviewList.map((item, index) => {
                    const path =
                      item.id === 'tpl'
                        ? '/analytics?tab=activity'
                        : '/analytics';
                    return (
                      <Link to={path}>
                        <OverviewListItem key={index}>
                          <ListItemHeader>{item.title}</ListItemHeader>
                          <ListItemLink>
                            <ListItemLinkDiv>
                              {item.id !== 'ti' ? (
                                item.id === 'tpl' ? (
                                  item.icon === 'N/A' ? (
                                    <ListItemLinkImage
                                      src="/assets/images/GaBdRGkV_a7b04f53_r.jpg"
                                      alt={item.title}
                                    />
                                  ) : (
                                    <PlaformLogoContainer>
                                      <FontAwesomeIcon
                                        style={{ color: '#fff' }}
                                        icon={
                                          item.icon === 'Twitter'
                                            ? faTwitter
                                            : faLinkedinIn
                                        }
                                        size="2x"
                                      />
                                    </PlaformLogoContainer>
                                  )
                                ) : item.image.includes('null.jpg') ? (
                                  <ListItemLinkImage
                                    src={`/assets/images/GaBdRGkV_a7b04f53_r.jpg`}
                                    alt={item.title}
                                  />
                                ) : (
                                  <ListItemLinkImage
                                    src={item.image}
                                    alt={item.title}
                                  />
                                )
                              ) : (
                                <TotalImpactMineContainer>
                                  {item.mine}
                                </TotalImpactMineContainer>
                              )}
                            </ListItemLinkDiv>

                            <ListItemLinkFooter>
                              {item.footer}
                            </ListItemLinkFooter>
                          </ListItemLink>
                        </OverviewListItem>
                      </Link>
                    );
                  })
                ) : (
                  <LoaderContainer>
                    <img
                      src="/public/assets/images/loaderV.gif"
                      alt="loader"
                      width="30px"
                      height="30px"
                    />
                  </LoaderContainer>
                )}
              </OverviewInnerList>
            </OverviewList>
          </Overview>
        </ListItem>
      </ContainerList>
      <ContainerList>
        <InsightScore style={{ width: '100%' }}>
          <InsightScoreHeader>My Clout</InsightScoreHeader>
          <div style={{ display: 'flex', width: '100%' }}>
            <InsightScoreBody style={{ width: '30%' }}>
              <InsightScorePointContainer>
                <InsightScorePoint>
                  {userData && userData.score}
                </InsightScorePoint>
              </InsightScorePointContainer>
              <InsightScoreContent>
                {`You are in Top ${getFideatorScore(userData.score)}`}
                <br />
                of the score bucket
              </InsightScoreContent>
              <InsightScoreFooter>
                <InsightScoreFooterLink onClick={() => setmodalIsOpen(true)}>
                  How is My Clout calculated
                </InsightScoreFooterLink>
                <Modal
                  onRequestClose={() => setmodalIsOpen(false)}
                  shouldCloseOnOverlayClick
                  isOpen={modalIsOpen}
                  style={{
                    overlay: {
                      backgroundColor: '#000000d1',
                    },
                  }}
                >
                  <ModalHeader>How is My Clout Calculated</ModalHeader>
                  <CustomModalBody>
                    <ComponentSection>
                      Clout is between 0-100 and it depends on how much content you share 
                      and how much your network engages with that content. 
                      It includes sharing activity, estimated reach, your network size, and
                      how often people click, view, like, retweet, or generally engage with 
                      your posts and emails. The score is calculated every day for each user, 
                      and is based on your ranking compared to other users.
                    </ComponentSection>
                  </CustomModalBody>
                  <ModalFooter>
                    <ModalCloseButton onClick={() => setmodalIsOpen(false)} />
                  </ModalFooter>
                </Modal>
              </InsightScoreFooter>
            </InsightScoreBody>
            <AnalyticsValue style={{ width: '70%' }}>
              <TabsContainer style={{border: 'none'}}>
                <Tabs onSelect={handleGraphTabChange}>
                  <TabList align='flex-start' style={{ paddingTop: '16px' }}>
                    <DashboardAdminSubTab style={styles.AdminSubTab} color='#49484a' padding='5px 23px 3px' className={`${tabIndex === 0 ? 'active' : 'inactive'}`}>Last 7 Days</DashboardAdminSubTab>
                    <DashboardAdminSubTab style={styles.AdminSubTab} color='#49484a' padding='5px 23px 3px' className={`${tabIndex === 1 ? 'active' : 'inactive'}`}>Last 30 Days</DashboardAdminSubTab>
                    <DashboardAdminSubTab style={styles.AdminSubTab} color='#49484a' padding='5px 23px 3px' className={`${tabIndex === 2 ? 'active' : 'inactive'}`}>Last 90 Days</DashboardAdminSubTab>
                  </TabList>
                  <ResponsiveTabs>
                    {Array.from(Array(3)).map((tab, index) => (
                      <TabPanel key={index}>
                        <ChartBoxes>
                          <ChartBox>
                            {graphData ? (
                              <BasicLineChart
                                data={graphData}
                                location={'insightscore'}
                              />
                            ) : (
                                <LoaderContainer>
                                  <img
                                    src="/assets/images/loaderV.gif"
                                    alt="loader"
                                    width="30px"
                                    height="30px"
                                  />
                                </LoaderContainer>
                              )}
                          </ChartBox>
                          <ChartInfo>
                            <InfoBox>
                              <InfoBoxHeader>
                                {index === 0
                                  ? '7 Day High'
                                  : index === 1
                                    ? '30 Day High'
                                    : '90 Day High'}
                              </InfoBoxHeader>

                              <InfoBoxData>{scoreRange.max}</InfoBoxData>
                            </InfoBox>
                            <InfoBox>
                              <InfoBoxHeader>1 Day Change</InfoBoxHeader>

                              <InfoBoxData>{oneDayChange}</InfoBoxData>
                            </InfoBox>
                            <InfoBox>
                              <InfoBoxHeader>
                                {index === 0
                                  ? '7 Day Low'
                                  : index === 1
                                    ? '30 Day Low'
                                    : '90 Day Low'}
                              </InfoBoxHeader>

                              <InfoBoxData>{scoreRange.min}</InfoBoxData>
                            </InfoBox>
                          </ChartInfo>
                        </ChartBoxes>
                      </TabPanel>
                    ))}
                  </ResponsiveTabs>
                </Tabs>
              </TabsContainer>
            </AnalyticsValue>
          </div>

        </InsightScore>
       
        {/* <ListItem>
          <InsightScoreGraph>
            <InsightScoreHeader>My Clout</InsightScoreHeader>
            
          </InsightScoreGraph>
        </ListItem> */}
      </ContainerList>
    </SummaryContainer>
  );
};

const mapStateToProps = (state) => ({
  userData: getUserDetails(state),
  socialNetworkData: state.socialNetwork.data,
});

export default connect(mapStateToProps)(AnalyticsSummaryContainer);
