import { Flex } from '@rebass/grid';
import React from 'react';
import { Button, ModalBody, ModalCloseButton, ModalHeader, ModalV2 } from '../../../components';

const ConfirmProceedModal = ({ close, open, clickHandler }) => {
    return (
        <ModalV2 width='30%' bgColor='#fff' isOpen={open} onRequestClose={close}>
            <ModalHeader>
                Confirm
                <ModalCloseButton onClick={close} />
            </ModalHeader>
            <ModalBody>
                Update Campaign Cascade?
                <Flex justifyContent='space-between' width='40%' margin='auto'>
                    <Button onClick={clickHandler}>Yes</Button>
                    <Button onClick={close}>No</Button>
                </Flex>
            </ModalBody>
        </ModalV2>
    )
}

export default ConfirmProceedModal;