import { Box } from '@rebass/grid';
import React from 'react';
import { Content, Disclaimer, Salutation, Template, TemplateHeader, TemplateSubTitle, TemplateTitle } from '../EmailTemplates.styles';
import { BoxTitle as BoxTitleTwoCol, ContentContainer as ContentContainerTwoCol, ContentBox as ContentBoxTwoCol } from '../TwoColumnTemplate/TwoColumnTemplate.styles';
import { BoxTitle as BoxTitleOneCol, ContentContainer as ContentContainerOneCol, ContentBox as ContentBoxOneCol } from '../OneColumnTemplate/OneColumnTemplate.styles';


const CustomUserTemplate = ({ value, selectedValue, onChange, template, owner }) => {
    return (
        <Box width='216px' marginBottom='22px' marginLeft='22px'>
            <div>
                <TemplateTitle>{template.name}</TemplateTitle>
                <TemplateSubTitle>
                    This is a user created custom template.
                </TemplateSubTitle>
            </div>
                <Template
                    onClick={() => onChange(value)}
                    border={
                        value.name === selectedValue.name ? '2px solid #6351ed' : '2px solid #adadad'
                    }
                    style={{
                        backgroundColor: 'white',
                        boxShadow:
                            value.name === selectedValue.name ? '2px 3px 10px rgb(0,0,0,0.2)' : 'none',
                        borderRadius: '5px',
                        height: '276.84px'
                    }}
                >
                {template.thumbnail_url ? (
                    <img 
                        src={template.thumbnail_url} 
                        alt='' 
                        style={{
                            width: '-webkit-fill-available', 
                            height: '-webkit-fill-available', 
                            objectFit: owner === 'clout' ? 'none' : 'cover',
                        }} 
                    />
                ) : (
                    <>
                        <TemplateHeader />
                        <Salutation>Hi First_name,</Salutation>
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                            veniam, quis nostrud
                        </div>
                        {!template.original_template || template.original_template === 'no-content' ? (
                            <Content height="80px">
                                Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                            </Content>
                        ) : template.original_template === 'one-column' ? (
                            <Content>
                                <ContentContainerOneCol>
                                    <ContentBoxOneCol />
                                    <BoxTitleOneCol>
                                        <div>Content Title</div>
                                        <div style={{ fontSize: '0.7em' }}>Content share copy</div>
                                    </BoxTitleOneCol>
                                </ContentContainerOneCol>
                                <ContentContainerOneCol style={{ marginTop: '5px' }}>
                                    <ContentBoxOneCol />
                                    <BoxTitleOneCol>
                                        <div>Content Title</div>
                                        <div style={{ fontSize: '0.7em' }}>Content share copy</div>
                                    </BoxTitleOneCol>
                                </ContentContainerOneCol>
                            </Content>
                            ) : (
                                <Content>
                                    <ContentContainerTwoCol>
                                        <ContentBoxTwoCol>
                                            <BoxTitleTwoCol>Content</BoxTitleTwoCol>
                                        </ContentBoxTwoCol>
                                        <ContentBoxTwoCol>
                                            <BoxTitleTwoCol>Content</BoxTitleTwoCol>
                                        </ContentBoxTwoCol>
                                    </ContentContainerTwoCol>
                                </Content>
                            )}
                        <div>Signature</div>
                        <Disclaimer>Disclaimer</Disclaimer>
                    </>
                )}
                </Template>
        </Box>
    );
}

export default CustomUserTemplate;