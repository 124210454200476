import React, { useState, useEffect } from 'react';

import { Flex } from '@rebass/grid';

import {
  TimingCard,
  Input,
  InputLabel,
  RadioInputLabel,
  RadioInput,
  CheckboxInput,
} from './SocialPreview.styles';

import './style.css';
import { DripIconButton, MioDatePicker } from '../../../components';
import {
  DateTimeText,
  DateTimeWrapper,
} from '../Preview&Schedule/PreviewAndSchedule.styles';
import moment from 'moment';
import LeadCaptureOption from '../../../components/ShareContentContainerV2/Elements/LeadCaptureOption';
import { get } from 'lodash';

export const ScheduleCard = ({
  activePlatform,
  dateCol,
  setDateCol,
  timeCol,
  setTimeCol,
  isScheduleAllPlatform,
  setIsScheduleAllPlatform,
  timingTabs,
  setTimingTabs,
  isEdit,
  isFbSelected,
  isLnSelected,
  isTwSelected,
  userData,
}) => {
  console.log('SCHEDULE CARD - - -- ', isEdit);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  // useEffect(() => {
  //   console.log('dateCol', dateCol);
  //   console.log('timeCol', timeCol);
  //   console.log('timingTabs', timingTabs);
  // }, [dateCol, timeCol, timingTabs]);

  const manageAllPlatformCheck = (checked) => {
    if (!checked) {
      setDateCol({
        ...dateCol,
        fb: dateCol[activePlatform],
        tw: dateCol[activePlatform],
        ln: dateCol[activePlatform],
      });
      setTimeCol({
        ...timeCol,
        fb: timeCol[activePlatform],
        tw: timeCol[activePlatform],
        ln: timeCol[activePlatform],
      });
      setTimingTabs({
        ...timingTabs,
        fb: timingTabs[activePlatform],
        tw: timingTabs[activePlatform],
        ln: timingTabs[activePlatform],
      });
    } else {
      // setMsgCopy({
      //   ...msgCopy,
      //   fb: activePlatform === 'fb' ? msgCopy[activePlatform] : '',
      //   tw: activePlatform === 'tw' ? msgCopy[activePlatform] : '',
      //   ln: activePlatform === 'ln' ? msgCopy[activePlatform] : '',
      // });
    }

    setIsScheduleAllPlatform(!isScheduleAllPlatform);
  };

  // const handleLeadGenChange = value => {
  //   console.log('VALUE - - -', value);
  //   toggleLeadGen({...enableLeadGen, [activePlatform]: value});
  // }

  // const [timingTabs, setTimingTabs] = useState(0);
  return (
    <Flex display="flex" flexDirection="column">
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        alignSelf="flex-start"
        ml="25px"
        mt="75px"
      >
        {isFbSelected + isLnSelected + isTwSelected > 1 && (
          <>
            <CheckboxInput
              type="checkbox"
              id="samePlatform"
              name="samePlatform"
              disabled={activePlatform === ''}
              onClick={() => manageAllPlatformCheck(isScheduleAllPlatform)}
              checked={isScheduleAllPlatform}
              defaultChecked={!isEdit}
            />
            <InputLabel
              fontSize="10px"
              fontWeight="400"
              color="#bbb"
              marginLeft="5px"
            >
              Use same time across platforms
            </InputLabel>
          </>
        )}
      </Flex>
      <TimingCard margin="10px 25px 0px">
        <Flex alignItems="center" justifyContent="center">
          <RadioInput
            type="radio"
            id="timeMode_all"
            name="timeMode"
            checked={timingTabs[activePlatform] === 0}
            disabled={activePlatform === ''}
            onClick={(e) => {
              if (isScheduleAllPlatform) {
                setTimingTabs({
                  ...timingTabs,
                  fb: 0,
                  ln: 0,
                  tw: 0,
                });
              } else {
                setTimingTabs({ ...timingTabs, [activePlatform]: 0 });
              }
            }}
            style={{ cursor: 'pointer' }}
          />
          <RadioInputLabel htmlFor="timeMode_all" style={{ cursor: 'pointer' }}>
            Post Immediately
          </RadioInputLabel>
        </Flex>
        <hr style={{ width: '98%', margin: '10px auto' }} />
        <Flex alignItems="center" justifyContent="center">
          <RadioInput
            type="radio"
            id="timeMode_specific_time"
            name="timeMode"
            checked={timingTabs[activePlatform] === 1}
            disabled={activePlatform === ''}
            onClick={(e) => {
              if (isScheduleAllPlatform) {
                setTimingTabs({
                  ...timingTabs,
                  fb: 1,
                  ln: 1,
                  tw: 1,
                });
              } else {
                setTimingTabs({ ...timingTabs, [activePlatform]: 1 });
              }
            }}
            style={{ cursor: 'pointer' }}
          />
          <RadioInputLabel
            htmlFor="timeMode_specific_time"
            style={{ cursor: 'pointer' }}
          >
            Schedule Date & Time
          </RadioInputLabel>
        </Flex>
        <Flex
          flexDirection="column"
          display="flex"
          style={{ opacity: timingTabs[activePlatform] == 1 ? '100%' : '50%' }}
        >
          <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            display="flex"
            pt="15px"
          >
            <InputLabel>Date & Time</InputLabel>
            <DateTimeWrapper width="250px" height="35px">
              <DateTimeText>
                {dateCol[activePlatform] &&
                  moment(dateCol[activePlatform]).format('MM/DD/YY, h:mm a')}
              </DateTimeText>
              <DripIconButton
                onClick={() => setIsDatepickerOpen(true)}
                icon="clock"
                styles={{ marginTop: '5px' }}
                disable={timingTabs[activePlatform] !== 1}
              />
              <MioDatePicker
                isOpen={isDatepickerOpen}
                customInput={<div />}
                minDate={new Date()}
                onDateClose={() => setIsDatepickerOpen(false)}
                onSubmit={(dateTime) => {
                  if (isScheduleAllPlatform) {
                    setDateCol({
                      ...dateCol,
                      fb: dateTime,
                      ln: dateTime,
                      tw: dateTime,
                    });
                  } else {
                    setDateCol({ ...dateCol, [activePlatform]: dateTime });
                  }
                  setIsDatepickerOpen(false);
                }}
                timeZone={get(userData, 'details.user.timezone', '')}
                scheduleValue={dateCol[activePlatform]}
                isFromNewsletter
              />
            </DateTimeWrapper>
            {/* <Input
              type="date"
              id="date"
              name="date"
              disabled={
                timingTabs[activePlatform] == 0 || activePlatform === ''
              }
              value={dateCol[activePlatform]}
              onChange={(e) => {
                if (isScheduleAllPlatform) {
                  setDateCol({
                    ...dateCol,
                    fb: e.target.value,
                    ln: e.target.value,
                    tw: e.target.value,
                  });
                } else {
                  setDateCol({ ...dateCol, [activePlatform]: e.target.value });
                }
              }}
            /> */}
          </Flex>

          {/* <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            display="flex"
            pt="5px"
          >
            <InputLabel>Time</InputLabel>
            <Input
              type="time"
              id="time"
              name="time"
              disabled={
                timingTabs[activePlatform] == 0 || activePlatform === ''
              }
              value={timeCol[activePlatform]}
              onChange={(e) => {
                if (isScheduleAllPlatform) {
                  setTimeCol({
                    ...timeCol,
                    fb: e.target.value,
                    ln: e.target.value,
                    tw: e.target.value,
                  });
                } else {
                  setTimeCol({ ...timeCol, [activePlatform]: e.target.value });
                }
              }}
            />
          </Flex> */}
        </Flex>
      </TimingCard>
    </Flex>
  );
};
