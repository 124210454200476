import { Flex } from '@rebass/grid';
import {
  get,
  isEmpty,
  startCase,
  camelCase,
  join,
  includes,
  remove,
  cloneDeep
} from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import {
  Button,
  DripIconButton,
  LoaderWrapper,
  MioDatePicker,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalV2,
} from '../../../../components';
import { themesData } from '../../../../utils';
import { Input } from '../../SocialPreview/SocialPreview.styles';
import {
  DateTimeText,
  DateTimeWrapper,
  EmailList,
} from '../../Preview&Schedule/PreviewAndSchedule.styles';
import {
  publishCampaignV2,
  updateStatusOfCampaign,
  viewCampaignInfo,
} from '../../../../services/campaign';

// curCampaignDetailsHandlerSeries

const RescheduleModal = ({
  campaignId,
  handleClose,
  userData,
  type,
  seriesId,
  campaignRequest,
  filters,
  fromViewInfo,
  fromSendToMore,
  campaignsDataArray,
  segment
}) => {
  const [loading, setLoading] = useState(false);
  const [dateTimeList, setDateTimeList] = useState({});

  const [emailEntries, setEmailEntries] = useState([]);
  const [facebookEntries, setFacebookEntries] = useState([]);
  const [linkedinEntries, setLinkedinEntries] = useState([]);
  const [twitterEntries, setTwitterEntries] = useState([]);
  const labels = {
    facebook: 'Facebook',
    twitter: 'Twitter',
    linkedin: 'LinkedIn',
  };
  // const [deletedEntries, setDeletedEntries] = useState({email: [], facebook: [], linkedin: [], twitter: []});
  const [isAllItemsDeleted, setIsAllItemsDeleted] = useState(false);
  const [saveLoader, toggleSaveLoader] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [deleteModal, toggleDeleteModal] = useState({status: false, newDateTime: {}})

  let timeZone = get(userData, 'details.user.timezone', '');
  let currentDate = !isEmpty(timeZone)
    ? new Date(new Date().toLocaleString('en-US', { timeZone: timeZone }))
    : new Date();

  // console.log('currentDate', moment(currentDate).unix(), timeZone);

  useEffect(() => {
    let resArray = [];
    if(fromSendToMore)
      dataFilteration(campaignsDataArray);
    else if (campaignId) {
      setLoading(true);
      viewCampaignInfo(campaignId, 'campaign')
        .then((res) => {
          setLoading(false);
          resArray = get(res, 'result.data', []);
          dataFilteration(resArray);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
    // else if (seriesId) {
    //   setLoading(true);
    //   viewCampaignInfo(seriesId, type)
    //     .then((res) => {
    //       setLoading(false);
    //       resArray = get(res, 'result.data', []);
    //       let cmp = curCampaignDetailsHandlerSeries(resArray);
    //       // let campaigns = resArray.map((campaign) =>
    //       //   curCampaignDetailsHandler(campaign)
    //       // );
    //       setCurPlatformCampaign(cmp);
    //     })
    //     .catch((err) => {
    //       setLoading(false);
    //       console.log(err);
    //     });
    // }
  }, [campaignId, seriesId]);

  const getInputStatusIcon = (state) => {
    if (state) {
      return '/assets/images/cancel.svg';
    } else {
      return '/assets/images/play_arrow.svg';
    }
  };

  const dataFilteration = (data) => {
    console.log('dataFilteration', data);
    let timingObj = {};
    // let scheduledData = data;
    let scheduledData = 
      data &&
      data.length > 0 &&
      data.filter((item) => {
        return item.status === 'scheduled' || item.status === 'paused' || item.status === 'approval_pending' || (fromSendToMore && (!item.status || item.status === 'failed'));
      });
    console.log('scheduledData', scheduledData);

    let emailData =
      scheduledData &&
      scheduledData.length > 0 &&
      scheduledData.filter((item) => {
        return item.platform === 'EMAIL';
      });
    console.log('emailData', emailData);

    let fbData =
      scheduledData &&
      scheduledData.length > 0 &&
      scheduledData.filter((item) => {
        return item.platform === 'FACEBOOK';
      });
    console.log('fbData', fbData);
    if (!isEmpty(fbData)) {
      timingObj['facebook'] = {
        isOpen: false,
        datetime: fbData.filter(fb => fb.datetime).length > 0 ? fbData.filter(fb => fb.datetime)[0].datetime : '',
        visibility_status: true,
      };
    }

    let lnData =
      scheduledData &&
      scheduledData.length > 0 &&
      scheduledData.filter((item) => {
        return item.platform === 'LINKEDIN';
      });
    console.log('lnData', lnData);
    if (!isEmpty(lnData)) {
      timingObj['linkedin'] = {
        isOpen: false,
        datetime: lnData.filter(ln => ln.datetime).length > 0 ? lnData.filter(ln => ln.datetime)[0].datetime : '',
        visibility_status: true,
      };
    }

    let twData =
      scheduledData &&
      scheduledData.length > 0 &&
      scheduledData.filter((item) => {
        return item.platform === 'TWITTER';
      });
    console.log('twData', twData);
    if (!isEmpty(twData)) {
      timingObj['twitter'] = {
        isOpen: false,
        datetime: twData[0].datetime,
        visibility_status: true,
      };
    }
    console.log('TIMING OBJ - -- - - -', timingObj);
    setEmailEntries(emailData);
    setFacebookEntries(fbData);
    setLinkedinEntries(lnData);
    setTwitterEntries(twData);

    !isEmpty(emailData) &&
      emailData.forEach((ele) => {
        timingObj[ele.destination_id] = {
          isOpen: false,
          datetime: ele.datetime,
          visibility_status: true,
        };
      });
    console.log('timingObj', timingObj);

    setDateTimeList(timingObj);
  };

  const handleDateTimeListChange = (id, name, value, isDateTimeInput) => {
    console.log('ID - -- ', id, 'NAME - -- - ', name, 'VALUE - - -- ', value, 'IS DATETIME INPUT - - -- ', isDateTimeInput);
    if (isDateTimeInput) {
      setDateTimeList({
        ...dateTimeList,
        [id]: { ...dateTimeList[id], [name]: value, isOpen: false },
      });
    } else {
      setDateTimeList({
        ...dateTimeList,
        [id]: { ...dateTimeList[id], [name]: value },
      });
    }
  };

  const extractNames = (platform) => {
    let nameArray;

    if (platform === 'facebook') {
      nameArray = facebookEntries;
    } else if (platform === 'linkedin') {
      nameArray = linkedinEntries;
    } else if (platform === 'twitter') {
      nameArray = twitterEntries;
    }

    let nameStringArr = nameArray.map((item, index) => {
      return item.destination;
    });

    let nameString = join(nameStringArr, ', ');
    // console.log('nameString', nameString);
    return nameString;
  };

  const deleteRecipientRow = (id, status) => {
    let updatedList = cloneDeep(dateTimeList);

    updatedList[id].visibility_status = !status;
    // console.log('updatedList', updatedList);
    let vstatus = [];

    !isEmpty(updatedList) &&
      Object.keys(updatedList).forEach((key) => {
        vstatus.push(updatedList[key].visibility_status);
      });
    // console.log('vstatus', vstatus);
    if (!vstatus.includes(true)) {
      if(fromViewInfo) {
        toggleDeleteModal({status: true, newDateTime: updatedList});
        console.log('FROM VIEW INFO - - - - if block');
      }
      else {
        setDateTimeList(updatedList);
        setIsAllItemsDeleted(true);
      }
    } 
    else {
      setIsAllItemsDeleted(false);
      setDateTimeList(updatedList);
    }
  };

  const returnSocialBlocks = (platform) => {
    let isPastDate =
      moment(dateTimeList[platform]?.datetime).unix() <
      moment(currentDate).unix();
    return (
      <Flex flexDirection="column">
        {/* <Flex
          width="100%"
          style={{
            fontSize: '1rem',
            color: 'black',
            fontWeight: '600',
          }}
          mb="10px"
        >
          <Flex width="50%">{labels[platform]}</Flex>
        </Flex> */}

        <Flex width="100%" alignItems="center" mb="15px">
          {/* <Flex width="50%" >{extractNames(platform)}</Flex> */}
          <Flex
            width="50%"
            style={{
              fontSize: '1rem',
              color: 'black',
              fontWeight: '600',
            }}
          >
            {labels[platform]}
          </Flex>
          <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            mr="20px"
            style={{
              opacity: dateTimeList[platform]?.visibility_status
                ? '100%'
                : '50%',
            }}
          >
            <DateTimeWrapper width="200px" isPastDate={isPastDate}>
              <DateTimeText>
                {dateTimeList[platform]?.datetime &&
                  moment(dateTimeList[platform]?.datetime).format(
                    'MM/DD/YY, h:mm a'
                  )}
              </DateTimeText>
              <DripIconButton
                onClick={() =>
                  handleDateTimeListChange(platform, 'isOpen', true)
                }
                icon={isPastDate ? 'media-record' : 'clock'}
                color={isPastDate ? '#990000' : ''}
                styles={{ marginTop: '5px' }}
                disable={!dateTimeList[platform]?.visibility_status}
              />
              <MioDatePicker
                isOpen={dateTimeList[platform]?.isOpen || false}
                customInput={<div />}
                minDate={new Date()}
                onDateClose={() =>
                  handleDateTimeListChange(platform, 'isOpen', false)
                }
                onSubmit={(datetime) =>
                  handleDateTimeListChange(platform, 'datetime', datetime, true)
                }
                scheduleValue={dateTimeList[platform]?.datetime}
                isFromNewsletter
                timeZone={get(userData, 'details.user.timezone', '')}
              />
            </DateTimeWrapper>
          </Flex>
          {!fromSendToMore && (
            <img
              alt=""
              src={getInputStatusIcon(dateTimeList[platform]?.visibility_status)}
              style={{ cursor: 'pointer' }}
              onClick={() => 
                deleteRecipientRow(
                  platform,
                  dateTimeList[platform]?.visibility_status
                )
              }
            />
          )}
        </Flex>
      </Flex>
    );
  };

  const handleValidation = () => {
    // console.log('dateTimeList', dateTimeList);
    // let status = [];

    // let iterate =
    //   !isEmpty(dateTimeList) &&
    //   Object.keys(dateTimeList).every(key => {
    //     // return dateTimeList[key].datetime
    //     console.log(`key: ${key}, value: ${dateTimeList[key]}`);
    //     if (dateTimeList[key].visibility_status)
    //       status.push(
    //         moment(dateTimeList[key].datetime).unix() <
    //           moment(currentDate).unix()
    //       );
    //   });
    // console.log('iterate', iterate);

    let emptyDateTime = Object.keys(dateTimeList).filter(ele => isEmpty(dateTimeList[ele].datetime)).length>0;
    let invalidDateTime = false;
    if(!emptyDateTime) {
      // console.log('DATE TIME LIST - - -- ', dateTimeList);
      // console.log('LN ENTRIES - - - - - -', linkedinEntries.filter(ln => !ln.status).length > 0);
      console.log('FROM SEND TO MORE - - - -', fromSendToMore);
      const filteredDateTimes = fromSendToMore ? Object.keys(dateTimeList).filter(item => {
        console.log('ITEM - -- - -', item);
        if(item === 'facebook')
          return facebookEntries.filter(fb => !fb.status).length > 0;
        else if(item === 'twitter')
          return twitterEntries.filter(tw => !tw.status).length > 0;
        else if(item === 'linkedin') {
          console.log('LINKEDIN IF RETURNS - - - ', linkedinEntries.filter(ln => !ln.status).length > 0)
          return linkedinEntries.filter(ln => !ln.status).length > 0;
        }
        else
          return emailEntries.filter(em => !em.status).length > 0;
      }) : Object.keys(dateTimeList);
      console.log('FILTERED DATETIMES - - - -- - ', filteredDateTimes);
      
      invalidDateTime = !filteredDateTimes.every(item => {
        console.log('ITEM - - - - ', moment(dateTimeList[item].datetime).unix() > moment(currentDate).unix());
        return !dateTimeList[item].visibility_status || moment(dateTimeList[item].datetime).unix() > moment(currentDate).unix();
      });
    }
    console.log('RETURN - - - -', emptyDateTime, invalidDateTime, emptyDateTime || invalidDateTime);
    return emptyDateTime || invalidDateTime;

    // if (!isEmpty(status) && includes(status, true)) 
    //   return true;
    // else 
    //   return false;
    // console.log('DATETIMELIST - - -- - ', dateTimeList);
  };

  const onResume = () => {
    // console.log('onResume');
    let payload = {};
    if (type === 'one_time') {
      payload.record_type = 'campaign';
    } else {
      payload.record_type = 'series';
    }
    payload.action = 'resume';
    payload.record_type = 'campaign';

    // console.log('dateTimeList', dateTimeList);
    // console.log('setEmailEntries', emailEntries);
    let emailObj = {};
    emailEntries.forEach((item, index) => {
      emailObj[item.destination_id] = {
        time: dateTimeList[item.destination_id].datetime,
        status: dateTimeList[item.destination_id].visibility_status
          ? 'scheduled'
          : 'deleted',
      };
    });

    let fbObj = {};
    facebookEntries.forEach((item, index) => {
      fbObj[item.destination_id] = {
        time: dateTimeList.facebook.datetime,
        status: dateTimeList.facebook.visibility_status
          ? 'scheduled'
          : 'deleted',
      };
    });

    let twObj = {};
    twitterEntries.forEach((item, index) => {
      twObj[item.destination_id] = {
        time: dateTimeList.twitter.datetime,
        status: dateTimeList.twitter.visibility_status
          ? 'scheduled'
          : 'deleted',
      };
    });

    let lnObj = {};
    linkedinEntries.forEach((item, index) => {
      lnObj[item.destination_id] = {
        time: dateTimeList.linkedin.datetime,
        status: dateTimeList.linkedin.visibility_status
          ? 'scheduled'
          : 'deleted',
      };
    });

    // console.log('emailObj', emailObj);

    payload.posts = JSON.stringify({
      ...(!isEmpty(emailObj) ? { [segment && segment.length > 0 ? 'segment' : 'email']: emailObj } : {}),
      ...(!isEmpty(fbObj) ? { facebook: fbObj } : {}),
      ...(!isEmpty(twObj) ? { twitter: twObj } : {}),
      ...(!isEmpty(lnObj) ? { linkedin: lnObj } : {}),
    });

    // console.log('opayloadbject', payload);
    setLoading(true);
    updateStatusOfCampaign({ id: campaignId || seriesId, payload })
      .then((res) => {
        if (res.result.success) {
          setLoading(false);
          if (isEmpty(filters)) campaignRequest();
          else campaignRequest({ ...filters });
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const editScheduleHandler = () => {
    toggleSaveLoader(true);
    setSaveError('');
    console.log('EMAIL ENTRIES - - -', emailEntries);
    console.log('TW ENTRIES - - ', twitterEntries);
    console.log('LN ENTRIES - -', linkedinEntries);
    console.log('FB ENTRIES - -0 -- -', facebookEntries);

    // only keep em entries that are not cancelled
    const emDateTimes = Object.keys(dateTimeList)
      .filter(dateTime => dateTime !== 'facebook' && dateTime !== 'twitter' && dateTime !== 'linkedin')
      .filter(emKey => dateTimeList[emKey].visibility_status);

    const posts = {
      ...(!isEmpty(emailEntries) ? {[segment && segment.length > 0 ? 'segment' : 'email']: {
        snetwork_act_id: emailEntries[0].snetwork_uid || emailEntries[0].snetwork_act_id,
        skip_compliance: 'true',
        schedule: 'specific_time',
        lead_gen: 'false',
        referral: 'false',
        schedule_time: fromViewInfo ? emailEntries.filter(em => emDateTimes.includes(em.destination_id)).map(em => ({
          destination_id: em.destination_id_hash, 
          time: dateTimeList[em.destination_id].datetime
        })) : emailEntries.map(em => ({
          destination_id: em.destination_id, 
          time: dateTimeList[em.destination_id].datetime
        }))
      }} : {}),
      ...(!isEmpty(facebookEntries) ? {facebook: {
        snetwork_act_id: facebookEntries[0].snetwork_uid || facebookEntries[0].snetwork_act_id,
        lead_gen: 'false',
        referral: 'false',
        skip_compliance: '',
        schedule: 'specific_time',
        override_text: facebookEntries.filter(fb => fb.custom_text)[0]?.custom_text,
        schedule_time: dateTimeList['facebook'].visibility_status ? 
          facebookEntries.map(fb => ({destination_id: fb.destination_id, time: dateTimeList.facebook.datetime})) : []
      }} : {}),
      ...(!isEmpty(twitterEntries) ? {twitter: {
        snetwork_act_id: twitterEntries[0].snetwork_uid || twitterEntries[0].snetwork_act_id,
        lead_gen: 'false',
        referral: 'false',
        skip_compliance: '',
        schedule: 'specific_time',
        override_text: twitterEntries.filter(tw => tw.custom_text)[0]?.custom_text,
        schedule_time: dateTimeList['twitter'].visibility_status ? 
          twitterEntries.map(tw => ({destination_id: tw.destination_id === '0' ? 0 : tw.destination_id, time: dateTimeList.twitter.datetime})) : []
      }} : {}),
      ...(!isEmpty(linkedinEntries) ? {linkedin: {
        snetwork_act_id: linkedinEntries[0].snetwork_uid || linkedinEntries[0].snetwork_act_id,
        lead_gen: 'false',
        referral: 'false',
        skip_compliance: '',
        schedule: 'specific_time',
        override_text: linkedinEntries.filter(ln => ln.custom_text)[0]?.custom_text,
        schedule_time: dateTimeList['linkedin'].visibility_status ? 
          linkedinEntries.map(ln => ({destination_id: ln.destination_id === '0' ? 0 : ln.destination_id, time: dateTimeList.linkedin.datetime})) : []
      }} : {}),
    }

    const payload = {
      posts: JSON.stringify(posts),
      action: 'edit_schedule'
    }

    console.log('PAYLOAD- - - - - -- - - ', posts);

    publishCampaignV2({id: campaignId, payload})
    .then(res => {
      console.log('SHARE RESPONSE -= - -- -  ', res);
      if(get(res, 'result.success', '')) {
        toggleSaveLoader(false);
        campaignRequest();
      }
    })
    .catch(err => {
      console.log('[EDIT SCHEDULE SHARE] ERROR - -- - ', err);
      toggleSaveLoader(false);
      setSaveError('Some error occurred! Please try again later.');
    });

    // posts: {"email":{"snetwork_act_id":"110763024642329183141","skip_compliance":"true","schedule":"specific_time","schedule_time":[{"destination_id":"Z9e1abnZ","time":"2022-03-18T13:53:00"}],"lead_gen":"false","referral":"false"}}
    // posts: {"facebook":{"articles":[{"id":"BL_wnxpm7bv_PnBm6DY0","theme_id":"NwBD9em2"}],"override_text":"Test Campaign NEwActions - beta 005","snetwork_act_id":"1211386165868703","skip_compliance":"","schedule":"specific_time","schedule_time":[{"destination_id":"101009138403202","time":"2022-03-18T13:54:00"}],"lead_gen":"false","referral":"false"},"twitter":{"articles":[{"id":"BL_wnxpm7bv_PnBm6DY0","theme_id":"NwBD9em2"}],"override_text":"Test Campaign NEwActions - beta 005","snetwork_act_id":"1325709789138055170","skip_compliance":"true","schedule":"specific_time","schedule_time":[{"destination_id":0,"time":"2022-03-18T13:54:00"}],"lead_gen":"false","referral":"false"}}
    // checkbox_status: {"message_copy":true,"schedule":true}
  }

  const onCancelScheduling = () => {
    let payload = {};
    if (type === 'one_time') {
      payload.record_type = 'campaign';
    } else {
      payload.record_type = 'series';
    }
    payload.action = 'cancel';
    payload.record_type = 'campaign';
    // console.log('paylaod', payload);
    setLoading(true);
    updateStatusOfCampaign({ id: campaignId || seriesId, payload })
      .then((res) => {
        if (res.result.success) {
          setLoading(false);
          if (isEmpty(filters)) campaignRequest();
          else campaignRequest({ ...filters });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const deleteAllHandler = () => {
    setIsAllItemsDeleted(true);
    setDateTimeList(deleteModal.newDateTime);
    toggleDeleteModal({status: false, newDateTime: {}});
  }


  // const deleteScheduleHandler = (platform, item) => {
  //   if(platform === 'em') 
  //     setDeletedEntries({...deletedEntries, email: [...deletedEntries.email].push(item)});
  //   else 
  //     setDeletedEntries({...deletedEntries, [platform]: ['all']})
  // }

  return (
    <Modal
      bgColor="white"
      height="70%"
      size="medium-small"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={true}
    >
      <ModalHeader
        id="header"
        style={{ textAlign: 'center', color: themesData.charcoalGrey }}
      >
        <Flex fontSize="0.9rem" color="black" style={{ fontWeight: '600' }}>
          Confirm Campaign Schedule
        </Flex>
        <ModalCloseButton onClick={handleClose} size="25px" />
        <hr style={{ width: '100%' }} />
      </ModalHeader>
      <ModalBody>
        <LoaderWrapper isLoading={loading}>
          {/* {!isEmpty(emailEntries) && (
            <Flex
              width="100%"
              style={{ fontSize: '1rem', color: 'black', fontWeight: '600' }}
              mb="10px"
            >
              <Flex width="50%">Email List</Flex>
              <Flex ml="-5px">Date & Time</Flex>
            </Flex>
          )} */}

          <EmailList width="100%">
            {console.log('EMAIL ENTRIES - - - - -- - - ', emailEntries)}
            {(emailEntries &&
              !isEmpty(dateTimeList)) ?
              fromSendToMore ? emailEntries.filter(em => !em.datetime).map((item, index) => (
                <>
                  {index === 0 && (
                    <Flex
                      width="100%"
                      style={{
                        fontSize: '1rem',
                        color: 'black',
                        fontWeight: '600',
                      }}
                      mb="10px"
                    >
                      <Flex width="50%">Email List</Flex>
                      <Flex>Date & Time</Flex>
                    </Flex>
                  )}
                  <Flex
                    width="100%"
                    //   justifyContent="space-evenly"
                    alignItems="center"
                    key={item.id}
                    mb="15px"
                  >
                    <Flex width="50%">{item.destination}</Flex>
                    <Flex
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      flexDirection="column"
                      mr="20px"
                      style={{
                        opacity: dateTimeList[item.destination_id]
                          ?.visibility_status
                          ? '100%'
                          : '40%',
                      }}
                    >
                      <DateTimeWrapper
                        width="200px"
                        isPastDate={
                          moment(
                            dateTimeList[item.destination_id]?.datetime
                          ).unix() < moment(currentDate).unix()
                        }
                      >
                        <DateTimeText>
                          {dateTimeList[item.destination_id]?.datetime &&
                            moment(
                              dateTimeList[item.destination_id]?.datetime
                            ).format('MM/DD/YY, h:mm a')}
                        </DateTimeText>
                        <DripIconButton
                          onClick={() =>
                            handleDateTimeListChange(
                              item.destination_id,
                              'isOpen',
                              true
                            )
                          }
                          icon={
                            moment(
                              dateTimeList[item.destination_id]?.datetime
                            ).unix() < moment(currentDate).unix()
                              ? 'media-record'
                              : 'clock'
                          }
                          color={
                            moment(
                              dateTimeList[item.destination_id]?.datetime
                            ).unix() < moment(currentDate).unix()
                              ? '#990000'
                              : ''
                          }
                          styles={{ marginTop: '5px' }}
                          disable={
                            !dateTimeList[item.destination_id]
                              ?.visibility_status
                          }
                        />
                        <MioDatePicker
                          isOpen={
                            dateTimeList[item.destination_id]?.isOpen || false
                          }
                          customInput={<div />}
                          minDate={new Date()}
                          onDateClose={() =>
                            handleDateTimeListChange(
                              item.destination_id,
                              'isOpen',
                              false
                            )
                          }
                          onSubmit={(datetime) =>
                            handleDateTimeListChange(
                              item.destination_id,
                              'datetime',
                              datetime,
                              true
                            )
                          }
                          scheduleValue={
                            dateTimeList[item.destination_id]?.datetime
                          }
                          isFromNewsletter
                          timeZone={get(userData, 'details.user.timezone', '')}
                        />
                      </DateTimeWrapper>
                    </Flex>
                    {!fromSendToMore && (
                      <img
                        alt=""
                        src={getInputStatusIcon(
                          dateTimeList[item.destination_id]?.visibility_status
                        )}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          deleteRecipientRow(
                            item.destination_id,
                            dateTimeList[item.destination_id]?.visibility_status
                          )
                        }
                      />
                    )}
                  </Flex>
                </>
              )) :
              emailEntries.map((item, index) => (
                <>
                  {index === 0 && (
                    <Flex
                      width="100%"
                      style={{
                        fontSize: '1rem',
                        color: 'black',
                        fontWeight: '600',
                      }}
                      mb="10px"
                    >
                      <Flex width="50%">Email List</Flex>
                      <Flex>Date & Time</Flex>
                    </Flex>
                  )}
                  <Flex
                    width="100%"
                    //   justifyContent="space-evenly"
                    alignItems="center"
                    key={item.id}
                    mb="15px"
                  >
                    <Flex width="50%">{item.destination}</Flex>
                    <Flex
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      flexDirection="column"
                      mr="20px"
                      style={{
                        opacity: dateTimeList[item.destination_id]
                          ?.visibility_status
                          ? '100%'
                          : '40%',
                      }}
                    >
                      <DateTimeWrapper
                        width="200px"
                        isPastDate={
                          moment(
                            dateTimeList[item.destination_id]?.datetime
                          ).unix() < moment(currentDate).unix()
                        }
                      >
                        <DateTimeText>
                          {dateTimeList[item.destination_id]?.datetime &&
                            moment(
                              dateTimeList[item.destination_id]?.datetime
                            ).format('MM/DD/YY, h:mm a')}
                        </DateTimeText>
                        <DripIconButton
                          onClick={() =>
                            handleDateTimeListChange(
                              item.destination_id,
                              'isOpen',
                              true
                            )
                          }
                          icon={
                            moment(
                              dateTimeList[item.destination_id]?.datetime
                            ).unix() < moment(currentDate).unix()
                              ? 'media-record'
                              : 'clock'
                          }
                          color={
                            moment(
                              dateTimeList[item.destination_id]?.datetime
                            ).unix() < moment(currentDate).unix()
                              ? '#990000'
                              : ''
                          }
                          styles={{ marginTop: '5px' }}
                          disable={
                            !dateTimeList[item.destination_id]
                              ?.visibility_status
                          }
                        />
                        <MioDatePicker
                          isOpen={
                            dateTimeList[item.destination_id]?.isOpen || false
                          }
                          customInput={<div />}
                          minDate={new Date()}
                          onDateClose={() =>
                            handleDateTimeListChange(
                              item.destination_id,
                              'isOpen',
                              false
                            )
                          }
                          onSubmit={(datetime) =>
                            handleDateTimeListChange(
                              item.destination_id,
                              'datetime',
                              datetime,
                              true
                            )
                          }
                          scheduleValue={
                            dateTimeList[item.destination_id]?.datetime
                          }
                          isFromNewsletter
                          timeZone={get(userData, 'details.user.timezone', '')}
                        />
                      </DateTimeWrapper>
                    </Flex>
                    {!fromSendToMore && (
                      <img
                        alt=""
                        src={getInputStatusIcon(
                          dateTimeList[item.destination_id]?.visibility_status
                        )}
                        style={{ cursor: 'pointer' }}
                        onClick={() => 
                          deleteRecipientRow(
                            item.destination_id,
                            dateTimeList[item.destination_id]?.visibility_status
                          )
                        }
                      />
                    )}
                  </Flex>
                </>
              )) : null}
            {!isEmpty(facebookEntries) &&
              (!fromSendToMore || !isEmpty(facebookEntries.filter(fb => !fb.datetime))) &&
              !isEmpty(dateTimeList) &&
              returnSocialBlocks('facebook')}
            {!isEmpty(linkedinEntries) &&
              (!fromSendToMore || !isEmpty(linkedinEntries.filter(ln => !ln.datetime))) && 
              !isEmpty(dateTimeList) &&
              returnSocialBlocks('linkedin')}
            {!isEmpty(twitterEntries) &&
              (!fromSendToMore || !isEmpty(twitterEntries.filter(tw => !tw.datetime))) &&
              !isEmpty(dateTimeList) &&
              returnSocialBlocks('twitter')}
          </EmailList>

          {deleteModal.status && (
            <ModalV2 
              width='40%' 
              isOpen={deleteModal.status} 
              onRequestClose={() => toggleDeleteModal({status: false, newDateTime: {}})}
            >
              <ModalHeader style={{padding: '10px'}}>
                <div style={{marginRight: '17px'}}>Deleting all schedule timings will cause the scheduled/in-progress campaign to go to draft/completed respectively. Confirm Delete?</div>
                <ModalCloseButton onClick={() => toggleDeleteModal({status: false, newDateTime: {}})} />
              </ModalHeader>
              <ModalBody>
                <Flex width='26%' justifyContent='space-between' margin='auto'>
                  <Button onClick={deleteAllHandler}>Yes</Button>
                  <Button onClick={() => toggleDeleteModal({status: false, newDateTime: {}})}>No</Button>
                </Flex>
              </ModalBody>
            </ModalV2>
          )}

          <div
            style={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
              padding: '10px',
            }}
          >
            <hr style={{ width: '100%' }} />
            {saveError && (
              <div style={{color: '#6351ed', fontSize: '10px'}}>{saveError}</div>
            )}
            <Flex justifyContent="flex-end" py="10px">
              <Button
                styles={{ borderRadius: '4px' }}
                color="#fff"
                bgColor="#4E6780"
                margin="0px 10px"
                borderColor="#4E6780"
                onClick={() => {
                  setDateTimeList({});
                  handleClose();
                }}
              >
                Cancel
              </Button>
              {!isAllItemsDeleted ? (
                <LoaderWrapper isLoading={saveLoader}>
                  <Button
                    styles={{ borderRadius: '4px' }}
                    color="#fff"
                    bgColor="#33b21b"
                    margin="0px 10px"
                    borderColor="#33b21b"
                    disabled={handleValidation()}
                    onClick={fromViewInfo || fromSendToMore ? editScheduleHandler : onResume}
                  >
                    {fromViewInfo || fromSendToMore ? 'Save' : 'Resume'}
                  </Button>
                </LoaderWrapper>
              ) : (
                <Button
                  styles={{ borderRadius: '4px' }}
                  color="#fff"
                  bgColor="#33b21b"
                  margin="0px 10px"
                  borderColor="#33b21b"
                  // disabled={handleValidation()}
                  onClick={fromViewInfo ? editScheduleHandler : onCancelScheduling}
                >
                  Confirm
                </Button>
              )}
            </Flex>
          </div>
        </LoaderWrapper>
      </ModalBody>
    </Modal>
  );
};

export default RescheduleModal;
