import { landingPagesEndpoints } from "../../../services/helpers";

export const onlineWebinarForm = {
   "structure": {
      "fields": {
         "first_name": {
            "type": "text",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "First Name"
            }
         },
         "last_name": {
            "type": "text",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "Last Name"
            }
         },
         "email": {
            "type": "email",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "Email"
            }
         },
         "phone_number": {
            "type": "tel",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "Phone Number (9994445555)",
               "pattern": "[0-9]{10,15}$"
            }
         },
         "privacy_policy": {
            "type": "checkbox",
            "label": "I agree to the Privacy Policy",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "name": "privacy-policy",
               "required": true
            }
         },
         "Register": {
            "type": "submit",
            "label": "",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "value": "Register",
               "id": "online_webinar_button"
            }
         },
         "url": {
             "type": "hidden",
             "canBeRemovedFromLayout": false,
             "attributes": {
                 "value": "",
                 "id": "login_url"
             }
         },
         "form_type": {
             "type": "hidden",
             "canBeRemovedFromLayout": false,
             "attributes": {
                 "value": "webinar_form"
             }
         }
      },
      "layout": [
         ["first_name", "url"],
         ["last_name", "form_type"],
         ["email"],
         ["phone_number"],
         ["privacy_policy"],
         ["Register"]
      ],
      "attributes": {
         "accept-charset": "UTF-8",
         "action": `${landingPagesEndpoints.baseUrl}/page-form`,
         "autocomplete": "on",
         "enctype": "multipart/form-data",
         "method": "post",
         "novalidate": false,
         "target": "_blank"
      },
      "title": "Login form",
      "description": "A BEE test form"
   },
   "style": {
       "fields":{
          "color":"#000000",
          "backgroundColor":"#ffffff",
          "outlineColor":"#3AAEE0",
          "border-top":"1px solid #D0D0D0",
          "border-right":"1px solid #D0D0D0",
          "border-bottom":"1px solid #D0D0D0",
          "border-left":"1px solid #D0D0D0",
          "padding-top":"5px",
          "padding-right":"5px",
          "padding-bottom":"5px",
          "padding-left":"5px",
          "border-radius":"4px"
       }
   }
}