import styled from 'styled-components';

export const SetObjectivesWrapper = styled.div.attrs({
  className: 'SetObjectivesWrapper',
})`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1 1 auto;
  width: 100%;
  padding: 30px 30px 0px;
  height: 90%;
  border-radius: 4px;
`;

export const WebinarWrapper = styled.div.attrs({
    className: 'webinar-wrapper',
  })`
    .tooltip-text {
      visibility: hidden;
      width: 141.4px;
      background-color: #49484a;
      color: #cccccc;
      text-align: center;
      border-radius: 6px;
      padding: 5px 5px;
      font-size: 10px;
      text-align: left;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      top: 124%;
      right: -37%;
      margin-left: -60px;
      display: flex;
      align-items: center;
    }
  
    &:hover .tooltip-text {
      visibility: visible;
    }
  `;

export const SetObjectivesInput = styled.input.attrs({
  className: 'set-objectives-input',
  type: 'text',
})`
  width: ${({ width }) => width || '100%'};
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ disabled }) => (disabled ? '#cccccc' : '#8493a6')};
  padding: 5px;
  height: ${({ height }) => height || ''};
  color: ${({ disabled }) => (disabled ? '#adadad' : '#000')};
  font-family: 'Poppins';
`;

export const RecipientsListWrapper = styled.div.attrs({
  className: 'recipients-list-wrapper',
})`
  border: 1px solid #bccdd4;
  border-radius: 4px;
  padding: ${({ padding }) => padding || '8px'};
  width: 100%;
  height: 170px;
  margin-top: 10px;
  background-color: #fff;
`;

export const RecipientsListBody = styled.div.attrs({
  className: 'recipients-list-body',
})`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height || '150px'};
  max-height: ${({ height }) => height || '150px'};
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
`;
