/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { isEmpty, get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { NewsletterArticle } from '../../NewsletterContainer/NewsletterArticle';
import { ArticlesContainer } from '../../DripCampaignContainerV2/AddContent/AddContent.styles';
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  LoaderWrapper,
} from '../../../components';
import CampaignCard from './CampaignCard';
import {
  ContentModalTab,
  DashedHorizontalSeparator,
  LineHorizontalSeparator,
} from './PlanCampaign.styles';
import { connect } from 'react-redux';
import { NewsletterArticleWrapper } from '../../NewsletterContainer/NewsletterArticle/NewsletterArticle.styles';
import { getChannelId, getRoleType } from '../../../redux/selector';
import { getMyContentArticles, ROLE_TYPES } from '../../../utils';
import { actions as campaignActions } from '../../../redux/modules/campaign.module';

const SelectContentModal = ({
  dayArticles,
  suggestedArticles,
  showModal,
  toggleModal,
  week,
  day,
  dragHandler,
  setDraggedArticle,
  bookmarkArticles,
  recommendedArticles,
  myContentArticles,
  selectedContent,
  setSelectedContent,
  themeNames,
  isEnterpriseAdvisor,
  isEnterpriseAdmin,
  isPremiumAdvisor,
  myContentPaginationData,
  myContentPendingQueue,
  campaignSetState,
  channelId
}) => {
  const [selectedContentTab, setSelectedContentTab] = useState(0);
  const [newsletterArticles, setNewsletterArticles] = useState([]);
  const [isSelectContent, setIsSelectContent] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState(selectedContent || []);
  const [curFlow, setCurFlow] = useState('socialOnly');
  const emptyCardArr = ['0', '1'];
  const [loadMoreLoader, toggleLoadMoreLoader] = useState(false);

  useEffect(() => {
      if(selectedContentTab === 0) {
        // setNewsletterArticles([]);
        setNewsletterArticles(bookmarkArticles)
      }
      else if(selectedContentTab === 1){
          setNewsletterArticles(recommendedArticles)
      }
      else if(selectedContentTab === 2){
          setNewsletterArticles(myContentArticles)
      }
  }, [selectedContentTab, myContentArticles]);


  const handleBucketArticleClick = (item) => {
    console.log('BUCKET ARTICLE CLICKED -');
    if (isEmpty(selectedArticles)) {
      item.order = 1;
      setSelectedArticles([item]);
      setIsSelectContent(true);
    } 
    else if(selectedArticles[0].id === item.id){
        setSelectedArticles([]);
        setIsSelectContent(false);
    }
    else{
        item.order = 1;
        setSelectedArticles([item]);
        setIsSelectContent(true);
    }
    // else if (
    //   !isEmpty(selectedArticles) &&
    //   selectedArticles.filter((n) => n.id === item.id).length > 0
    // ) {
    //   const articles = selectedArticles.filter((n) => n.id !== item.id);
    //   setSelectedArticles([...articles]);
    // } else {
    //   item.order = selectedArticles.length + 1;
    //   setSelectedArticles([...selectedArticles, item]);
    // }
  };

  // console.log('CALCULATED ARTICLES DATA  = = = = ', calculatedArticlesData);
  // console.log('DAY ARTICLES- - - - ', dayArticles);

  const loadMoreMyContent = () => {
    toggleLoadMoreLoader(true);
    getMyContentArticles({
        payload: {
        userType: isEnterpriseAdvisor ? 'isEnterpriseAdvisor' : isPremiumAdvisor ? 'isPremiumAdvisor' : 'isEnterpriseAdmin', 
        channelId: channelId, 
        beforeToken: null, 
        afterToken: myContentPaginationData.after,
        pendingArticles: myContentPendingQueue,
        },
        localData: {
        currentArticles: myContentArticles,
        },
        source: 'campaign',
    })
    .then(res => {
        const newState = get(res, 'newState', {});
        if(!isEmpty(newState))
            campaignSetState(newState);
        toggleLoadMoreLoader(false);
    })
    .catch(err => {
        console.log('******* ERROR my-content-articles [ARTICLE MODAL] ******');
        toggleLoadMoreLoader(false);
    })
}

  return (
    <Modal
      height="auto"
      style={{ overflowY: 'auto' }}
      size="medium68"
      shouldCloseOnOverlayClick
      isOpen={showModal}
      onRequestClose={() => toggleModal(false)}
      bgColor="#fff"
    >
      <ModalHeader
        style={{ borderBottom: '1px solid #CFD9E4', paddingTop: '3px' }}
      >
        <Flex justifyContent="flex-start" alignItems="center">
          <span
            style={{
              color: '#000000',
              fontWeight: '600',
              fontSize: '12px',
              marginRight: '12px',
            }}
          >
            Select Content
          </span>
        </Flex>
        <ModalCloseButton onClick={() => toggleModal(false)} />
      </ModalHeader>
      <ModalBody padding="10px 0px 0px">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            width="68%"
            style={{
              border: '1px solid #6351ed',
              borderRadius: '4px',
              padding: '2px 0px',
              marginBottom: '10px',
            }}
          >
            <ContentModalTab
              active={selectedContentTab === 0}
              onClick={() => setSelectedContentTab(0)}
            >
              BOOKMARKED
            </ContentModalTab>
            <ContentModalTab
              active={selectedContentTab === 1}
              onClick={() => setSelectedContentTab(1)}
            >
              RECOMMENDED
            </ContentModalTab>
            <ContentModalTab
              active={selectedContentTab === 2}
              onClick={() => setSelectedContentTab(2)}
            >
              MY CONTENT
            </ContentModalTab>
          </Flex>
          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            className="suggested-articles"
            style={{ overflowX: 'auto' }}
          >
            {/* {!isEmpty(suggestedArticles) ? (
                            <ArticleSliderWrapper right='-30px' left='-35px' width='85%' type='engagement'>
                                <Slider {...dashboardSliderSettings}>
                                    {suggestedArticles.map(article => (
                                        <CampaignCard content={article} fromContentModal={true} setDraggedArticle={setDraggedArticle} />
                                    ))}
                                </Slider>
                            </ArticleSliderWrapper>
                        ) : (
                            <span>No articles to display</span>
                        )} */}
            {newsletterArticles && newsletterArticles.length !== 0 ? (
              <ArticlesContainer
                width="100%"
                justifyContent="center"
                flexWrap="wrap"
                mt="40px"
                style={{position: 'relative'}}
              >
                {newsletterArticles.map((item, index) => (
                  <NewsletterArticle
                    key={index}
                    articleIndex={index}
                    margin="10px 23px 10px 0px"
                    {...item}
                    themeNames={themeNames}
                    // checkbox={
                    //   curFlow === 'socialOnly' && selectedArticles.length === 1
                    //     ? false
                    //     : true
                    // }
                    isFromCampaign={true}
                    article={item}
                    handleBucketArticleClick={handleBucketArticleClick}
                    newsletterArticles={selectedArticles}
                    deleteBlocked={true}
                    isCampaign={true}
                    oneSelectMode={
                      curFlow === 'socialOnly' && selectedArticles.length === 1
                    }
                    selectedContentTab={selectedContentTab}
                  />
                ))}
                {newsletterArticles.length > 0 && newsletterArticles.length % 3 !== 0 && (
                  emptyCardArr.slice(0, 3-newsletterArticles.length % 3).map(ele => (
                    <NewsletterArticleWrapper 
                      style={{backgroundColor: '#fff'}} 
                      width='230px' 
                      margin="10px 23px 10px 0px" 
                    />
                  ))
                )}
              </ArticlesContainer>
            ) : (
              <Flex style={{ width: '100%', height: '388px', justifyContent: 'center', alignItems: 'center' }}>
                No Content to show
              </Flex>
            )}
          </Flex>

          <DashedHorizontalSeparator style={{ margin: '5px 0px 15px' }} />
            <Flex justifyContent="center" alignItems="center">
                {!isEmpty(dayArticles) && dayArticles.map((ele, index) => (
                  <CampaignCard
                    {...ele}
                    fromContentModal={true}
                    dragHandler={dragHandler}
                  />
                ))}
            </Flex>
            {selectedContentTab === 2 && (
              <LoaderWrapper isLoading={loadMoreLoader}>
                <Button 
                    margin='20px auto 0px' 
                    width='110px' 
                    borderRadius='4px'
                    bgColor='#ffffff'
                    borderColor='#E4E7EC'
                    color='black' 
                    onClick={loadMoreMyContent}
                    disabled={!myContentPaginationData.after}
                >
                    Load More
                </Button>
              </LoaderWrapper>
            )}
          <LineHorizontalSeparator style={{ margin: '5px 0px 0px' }} />
            <Flex width="100%" justifyContent="flex-end">
                <Button
                  bgColor="#6351ed"
                  margin="15px 2px 10px 0px"
                  color="#fff"
                  fontSize="12px"
                  padding="5px 15px"
                  styles={{ borderRadius: '5px' }}
                  disabled={isEmpty(selectedArticles)}
                  onClick={() => {
                    setSelectedContent(selectedArticles);
                    toggleModal(false);
                  }}
                >
                {'Next >'}{' '}
                </Button>
            </Flex>
        </Flex>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  const isEnterpriseAdmin = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;

  return {
    themeNames: state.theme.themeNames,
    contentLibArticles: state.contentlib.data,
    bookmarkArticles: state.campaign.bookmarkArticles,
    recommendedArticles: state.campaign.recommendedArticles,
    myContentArticles: state.campaign.myContentArticles,
    myContentPaginationData: get(state, 'campaign.myContentPaginationData', { before: null, after: null }),
    myContentPendingQueue: get(state, 'campaign.pendingArticles', []),
    channelId: getChannelId(state),
    recommendedFetchedData: state.campaign.recommendedFetchedData,
    isEnterpriseAdmin,
    isEnterpriseAdvisor,
    isPremiumAdvisor
  }
};

const mapDispatchToProps = {
  campaignSetState: campaignActions.api.campaign.setstate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectContentModal);
