import { Flex } from '@rebass/grid';
import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
} from '../../../components';
import { themesData } from '../../../utils';
import DigitalCard from '../../ContentContainer/DigitalCard';
import { ConnectMeWrapper, ToggleButton } from './Profile.styles';

export default function CardModal({
  handleModalClose,
  name,
  firm_name,
  mobile,
  email,
  about_me,
  digital_banner,
  logo,
  socialLinks,
  userData,
}) {
  const [isMobileview, setIsMobileview] = useState(false);
  return (
    <Modal
      // onRequestClose={handleModalClose}
      shouldCloseOnOverlayClick
      isOpen
      size="flexible"
    >
      {/* <ModalCloseButton onClick={handleModalClose} /> */}
      <ModalBody>
        <Flex display="flex" alignItems="center" flexDirection="column">
          <Flex
            color="#6351ed"
            fontSize="16px"
            pb="10px"
            display="flex"
            alignItems="center"
          >
            <span>Profile Preview</span>
          </Flex>
          {isMobileview ? (
            <ConnectMeWrapper
              right="1.5%"
              width="190px"
              padding="0px 10px 10px"
              height="250px"
              border="none"
            >
              <div
                style={{
                  fontSize: '13px',
                  fontWeight: '600',
                  fontFamily: 'Poppins',
                  color: 'black',
                  padding: '10px 5px 0px',
                }}
              >
                {digital_banner}
              </div>
              <Button cursor='auto' className="mobilePdfFormButton">
                Yes, I'm interested
              </Button>
            </ConnectMeWrapper>
          ) : (
            <DigitalCard
              handleButtonClick={() => {}}
              mode="profile"
              name={name}
              firm_name={firm_name}
              mobile={mobile}
              email={email}
              about_me={about_me}
              digital_banner={digital_banner}
              logo={logo}
              linkedIn={socialLinks?.linkedIn ? socialLinks?.linkedIn : null}
              facebook={socialLinks?.facebook ? socialLinks?.facebook : null}
              twitter={socialLinks?.twitter ? socialLinks?.twitter : null}
              userData={userData}
            />
          )}
        </Flex>
        <Flex color={themesData.charcoalGrey} fontSize="12px" pt="10px">
          This is how your profile will appear to prospective leads.
        </Flex>
        <Flex
        justifyContent='center'
        marginTop='10px'
        >
          Desktop
            <ToggleButton
              className="react-switch"
              onChange={() => setIsMobileview(!isMobileview)}
              checked={isMobileview}
              onColor="#6351ed"
              offColor="#ccc"
              offHandleColor="#fff"
              onHandleColor="#fff"
              handleDiameter={10}
              uncheckedIcon=""
              checkedIcon=""
              boxShadow=""
              activeBoxShadow=""
              type="autoPost"
              left="1px"
              margin="-3px 5px 0px"
              position="absolute"
              top="7px"
              //disabled={((seriesId || get(setObjectivesTabData, 'campaignType', '') === "series") && approveToShare === 'true' && seriesStatus !== 'draft')}
            />
            Mobile
          </Flex>
        <Flex display="flex" alignItems="baseline" justifyContent="space-evenly">
          {/* <Button onClick={handleModalClose} margin="10px 0px 0px 0px">
            Save
          </Button> */}
          <Button
            onClick={handleModalClose}
            bgColor="#fff"
            color="#6351ed"
            // margin="10px 0px 0px 0px"
          >
            Close
          </Button>
        </Flex>
      </ModalBody>
    </Modal>
  );
}
