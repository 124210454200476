import { get, isEmpty } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { getApprovedToShareAC, getAutomatedCampaigns } from '../../services/automated-campaigns';
import { getCampaigns, getCascadedCampaigns } from '../../services/campaign';
import { getMediaImage } from '../../utils';
import { actions as campaignActions } from '../modules/campaign.module';

function* campaignRequest({ payload }) {
  let data, response;
  try {
    response = yield call(payload && payload.type && payload.type[0] === 'automated' ? getAutomatedCampaigns : getCampaigns, payload);
    data = get(response, 'result.data', {});
    if(payload !== 'approved_to_share' && ((payload && payload.type && payload.type[0] !== 'automated') || !payload)) {
      console.log('normal campaign');
      data.campaigns = data.campaigns
      .map((campaign) => {
        return {
          ...campaign,
          media_urls: getMediaImage(get(campaign, 'media_urls[0]', [])),
        };
      });
    }
    else if(payload && payload.type && payload.type[0] === 'automated') {
      console.log('automated campaign');
      data.automatedCampaigns = get(response, 'data', []);
      data.automated_campaigns_total_records = get(response, 'total_count', 0);
    }
    else if(payload === 'ats_automated') {
      const atsAC = yield call(getApprovedToShareAC);
      console.log('ATS AC - - - -- ', atsAC);
      data.automatedCampaigns = get(atsAC, 'result.data', [])
      .map(acCampaign => ({
        ...acCampaign,
        campaign_cascade: true,
      }));
    }
    else if(payload === 'approved_to_share') {
      const cascadedCampaigns = yield call(getCascadedCampaigns);
      data.campaigns = get(cascadedCampaigns, 'result.data', [])
      .map(campaign => ({
        ...campaign, 
        campaign_cascade: true,
        media_urls: getMediaImage(get(campaign, 'media_urls[0]', []))
      }));
    }
    console.log('SAGA DATA - - - - -', data);
    yield put(campaignActions.api.campaign.response(data));
  } 
  catch (error) {
    yield put(campaignActions.api.campaign.error(error));
  }
}

function* campaignSaga() {
  yield all([
    takeLatest(campaignActions.api.campaign.request, campaignRequest),
  ]);
}

export default campaignSaga;
