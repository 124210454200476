import React, { useState, useEffect } from 'react';
import { Flex, Box } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  FileUpload,
  ColorPicker,
  EmailPreview,
  ErrorContainer,
} from '../';
import { emailPersonalize } from '../../services/configure';
import { toBase64 } from '../../utils';
import {
  StyledBlockContent,
} from './CustomizeEmailModal.styles';
import './CustomizeEmailModal.css';
import { dataURLtoFile } from '../../services/dashboard/index';
import { LoaderWrapper } from '../Loader';
import { FrolaRichTextEditor } from '../RichTextEditor/FroalaRichTextEditor';


const CustomizeEmailModal = ({
  isOpen,
  setCustomizeEmailOpen,
  userData,
  handleEmailClose,
  isAdmin
}) => {
  const emailPersonalization = get(userData, 'email_personalization');
  const [isEmailPreviewOpen, setEmailPreviewOpen] = useState(false);
  const [themeColor, setThemeColor] = useState('#ffffff');
  const [base64Logo, setBase64Logo] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [headerText, setHeaderText] = useState(get(emailPersonalization, 'signature', ''));
  const [disclosureText, setDisclosureText] = useState(get(emailPersonalization, 'footer_text', ''));
  const [headerError, setHeaderError] = useState('');
  const [disclosureError, setdisclosureError] = useState('');
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);
  const [firstTimePreviewClick, setFirstTimePreviewClick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disclosureLength, setDisclosureLength] = useState('');
  const [signatureLength, setSignatureLength] = useState('');
  const [useLogo, toggleUseLogo] = useState(true);

  const onColorChange = (color) => {
    setThemeColor(color);
  };

  const previewClick = () => {
    if (!firstTimePreviewClick || isEmpty(base64Logo)) {
      if (get(emailPersonalization, 'image_width', '0') === '0' || get(emailPersonalization, 'image_height', '0') === '0') {
        const img = new Image();
        img.onload = function () {
          setImageWidth(this.width);
          setImageHeight(this.height);
          setOriginalWidth(this.width);
          setOriginalHeight(this.height);
        }

        img.src = base64Logo;

      } else {
        const img = new Image();
        img.onload = function () {
          setOriginalWidth(this.width);
          setOriginalHeight(this.height);
        }
        img.src = base64Logo;
        setImageWidth(get(emailPersonalization, 'image_width', 0));
        setImageHeight(get(emailPersonalization, 'image_height', 0));
      }
      setFirstTimePreviewClick(true);
    }

    setEmailPreviewOpen(true);
  };


  const onFileUploadChange = (logofile, setFieldValue) => {
    setErrorMsg('');
    console.log('[FILE] - ', logofile);
    toBase64(logofile[0]).then((file) => {
      // console.log('[FILE] - ', file);
      setBase64Logo(file);
      const img = new Image();
      img.onload = function () {
        setImageWidth(this.width);
        setImageHeight(this.height);
        setOriginalWidth(this.width);
        setOriginalHeight(this.height);
      }

      img.src = file;
      setFirstTimePreviewClick(true);
      // setFieldValue('logoUpload', file);
      // setFieldValue('logoUploadFile', logofile);
    });
  };

  // console.log('[HEADER TEXT] - ', headerText);
  // console.log('[footer TEXT] - ', disclosureText);
  const bannerColor = get(emailPersonalization, 'banner_hex_color');
  const [editorSettings, setEditorSettings] = useState(null);

  const commonDivStyles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const imageDivStyles = {
    height: '42.5px',
    width: '98px',
    backgroundColor: 'white',
    marginRight: '10px',
    fontSize: '10px',
    padding: '7px 8px',
    textAlign: 'center'
  }

  useEffect(() => {
    const form = userData && userData.email_personalization ? userData.email_personalization : null;
    // form.media_urls = null;
    const fetchedEditorSettings = userData && userData.personalization && userData.personalization.corporate_identity ? userData.personalization.corporate_identity : null;
    setEditorSettings(fetchedEditorSettings);
    // console.log('[FORM] - ', userData);
    if (form) {
      console.log('[FORM] - ', form);
      console.log('[EDITOR SETTINGS] - ', editorSettings);
      // console.log('[PATH] - ', form.logo ? form.logo.main.media_urls[0].path + form.logo.main.media_urls[0].name.system : 'Logo does not exist..');
      setHeaderText(form.signature ? form.signature : '');
      setDisclosureText(form.footer_text ? form.footer_text : '');
      setThemeColor(form.banner_hex_color);
      setBase64Logo(form.media_urls ? form.media_urls[0].path + form.media_urls[0].name.system + '.' + form.media_urls[0].ext : (fetchedEditorSettings && fetchedEditorSettings.logo) ? fetchedEditorSettings.logo.main.media_urls[0].path + fetchedEditorSettings.logo.main.media_urls[0].name.system + '.' + fetchedEditorSettings.logo.main.media_urls[0].ext : '');
      toggleUseLogo(form.use_logo === 'true');
    }
    else
      setBase64Logo(fetchedEditorSettings && fetchedEditorSettings.logo ? fetchedEditorSettings.logo.main.media_urls[0].path + fetchedEditorSettings.logo.main.media_urls[0].name.system + '.' + fetchedEditorSettings.logo.main.media_urls[0].ext : '')
  }, [editorSettings, userData]);


  // useEffect(() => {
  //   setThemeColor(bannerColor);
  //   setBase64Logo(logoUrl);
  // }, [logoUrl, bannerColor]);


  const toDataURL = (src, callback, outputFormat) => {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      var canvas = document.createElement('CANVAS');
      var ctx = canvas.getContext('2d');
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.onerror = function () {
      callback(false);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src =
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
      img.src = src;
    }
  };


  const saveHandler = () => {
    let logoFile;
    setLoading(true);
    console.log('[base64logo]', base64Logo);
    if (base64Logo !== '') {
      if (base64Logo.includes('https')) {
        console.log('INSIDE IF base64...');
        toDataURL(base64Logo, url => {
          logoFile = dataURLtoFile(url, 'filename.png');
          onEmailSave(logoFile);
        });
      }
      else {
        logoFile = dataURLtoFile(base64Logo, 'filename.png');
        onEmailSave(logoFile);
      }
    }
    else
      onEmailSave('');
  }

  const deleteImage = () => {
    setBase64Logo('');
  }


  const onEmailSave = logoFile => {
    let logoUploadFile = logoFile;
    setLoading(true);
    console.log('[LOGO FILE] - ', logoFile);
    let logoUpload = null;

    var disclosureObj = disclosureText.replace("\\", "");
    var headerObj = headerText.replace("\\", "");
    setHeaderText(headerObj);
    setDisclosureText(disclosureObj)
    
    let obj = {
      disclaimer: disclosureText !== '' ? disclosureText : '<p style="margin-block:0em;color:#000000;"></p>',
      signature: headerText !== '' ? headerText : '<p style="margin-block:0em;color:#848484;"></p>',
      image_width: imageWidth,
      image_height: imageHeight,
      use_logo: useLogo
    }
    // console.log('[LOGO FILE] - ', logoUploadFile);
    emailPersonalize({ themeColor, logoUpload, logoUploadFile, ...obj })
      .then((response) => {
        if (get(response, 'result.success')) {
          handleEmailClose(true);
          setEmailPreviewOpen(false);
          setCustomizeEmailOpen(false);
          setLoading(false);
          setSignatureLength(signatureLength);
          setDisclosureLength(disclosureLength)
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        setEmailPreviewOpen(false);
        setCustomizeEmailOpen(false);
        handleEmailClose(false, err.response.data);
        setLoading(false);
      });
  };

  // console.log('[HEADER TEXT] - ', headerText);
  // console.log('[DISCLOSURE TEXT] - ', disclosureText);

  return (
    <Modal
      onRequestClose={() => setCustomizeEmailOpen(false)}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      size="medium65"
      height="auto"
      margin='8% auto 0'
    >
      <ModalHeader type='customize-email'>
        Personalize Your Emails Sent From Clout
        <ModalCloseButton onClick={() => setCustomizeEmailOpen(false)} />
      </ModalHeader>
      <ModalBody style={{ fontSize: '0.7rem', paddingBottom: '10px !important' }}>

        <Box width={1} style={{ width: '550px', margin: 'auto' }}>
          <StyledBlockContent>
            Pickup your color here{' '}
            <ColorPicker
              onColorChange={onColorChange}
              deafultColor={bannerColor}
            />
          </StyledBlockContent>
        </Box>
        <div className="logo-div-email" style={{ ...commonDivStyles, justifyContent: 'space-between', width: '700px', margin: 'auto' }}>
          <Flex flexDirection='column' justifyContent='center' alignItems='flex-start'>
            <div style={{ ...commonDivStyles, position: 'relative' }}>
              <h3 color="#6351ed" style={{ marginRight: '8px' }}>Your Logo</h3>
              <div className='tooltip-div-email tooltip-logo-email'>
                <img src='/assets/images/information-grey.svg' alt='info tooltip' style={{ width: '11px', height: '11px', cursor: 'pointer' }} />
                <span className='tooltip-text-email tooltiptext-logo-email'>Image file must be jpeg or png, no larger than 10MB. The logo image will appear on a white background and images with transparent backgrounds may not be displayed properly.</span>
              </div>
            </div>
            <Flex justifyContent='flex-start' alignItems='center'>
              {/* <label className="logo-checkbox-container"> */}
              <input
                type="checkbox"
                checked={!useLogo}
                onChange={() => toggleUseLogo(!useLogo)}
                style={{ cursor: 'pointer' }}
                id='use_logo'
                name='use_logo'
              />
              <label style={{ cursor: 'pointer' }} htmlFor='use_logo' className="">Do not use logo.</label>
              {/* </label> */}
              {/* <span style={{marginBottom: '2px'}}>Do not use logo.</span> */}
            </Flex>
          </Flex>
          <div style={commonDivStyles}>
            <div className="image-thumbnail-email" style={base64Logo === '' ? imageDivStyles : { ...imageDivStyles, padding: '0px' }}>
              {base64Logo === '' ? 'No image uploaded' : (<img className="uploaded-image-email" src={base64Logo} alt='Email Avatar' width='100%' height='100%' />)}
            </div>
            <img src='/assets/images/delete.svg' alt='delete' style={{ marginRight: '10px', cursor: 'pointer' }} onClick={deleteImage}></img>
            <FileUpload
              asLabel
              margin='0px'
              name="logoUpload"
              id="logoUpload"
              label={<img src='/assets/images/upload.svg' alt='upload' />}
              onChange={(file) =>
                onFileUploadChange(file)
              }
              style={{ margin: '0px' }}
              onError={(msg) => setErrorMsg(msg)}
              maxSizeMB={10}
              accept=".png,.jpeg,.jpg,.svg,.bmp"
              title='Maximum Image Size : 10MB, Maximum Image Width :165 Pixels'
            />
          </div>
        </div>
        {errorMsg && (
          <Flex width='100%' justifyContent='flex-end' style={{ fontSize: '10px', color: '#6351ed' }}>*{errorMsg}</Flex>
        )}
        <hr className='hor-sep' />
        <div className="header-div-email" style={{ width: '700px', margin: 'auto' }}>
          <div style={{ ...commonDivStyles, justifyContent: 'flex-start', position: 'relative', marginBottom: '-8px', paddingRight: '5px' }}>
            <h3 color="#6351ed" style={{ marginRight: '8px' }}>Your Signature</h3>
            <div className='tooltip-div-email tooltip-header-email'>
              <img src='/assets/images/information-grey.svg' alt='info tooltip' style={{ width: '11px', height: '11px', cursor: 'pointer' }} />
              <span style={{ width: '185px' }} className='tooltip-text-email tooltiptext-header-email'>The signature that you want in your emails.</span>
            </div>
            <div style={{ paddingLeft: '10px', paddingTop: '2px' }}>{signatureLength}</div>
          </div>
          <div style={{ fontFamily: 'Arial', fontWeight: '100' }}>
            {/* <RichTextEditor
              placeHolder='Add Your Signature here'
              name='emailSignature'
              value={headerText}
              setValue={setHeaderText}
              isAdmin={userData}
              setError={setHeaderError}
              charLimit={5000}
              fromClientSettings={true}
              setLength={setSignatureLength}
            /> */}
            <FrolaRichTextEditor
              placeholder='Add Your Signature here'
              content={headerText}
              setContent={setHeaderText}
              maxHeight={135}
              minHeight={120}
              imageEditButtons={['imageReplace', 'imageRemove', '|', 'linkRemove', '-', 'imageAlt']}
              zIndex={3000}
            />
          </div>
        </div>
        <div className="disclosure-div-email" style={{ width: '700px', margin: '5px auto 10px' }}>
          <div style={{ ...commonDivStyles, justifyContent: 'flex-start', position: 'relative', marginBottom: '-9px' }}>
            <h3 color="#6351ed" style={{ marginRight: '8px' }}>Your Disclaimer Text</h3>
            <div className='tooltip-div-email tooltip-disclosure-email'>
              <img src='/assets/images/information-grey.svg' alt='info tooltip' style={{ width: '11px', height: '11px', cursor: 'pointer' }} />
              <span style={{ width: '205px' }} className='tooltip-text-email tooltiptext-disclosure-email'>The disclaimer text that you want in your emails.</span>
            </div>
            <div style={{ paddingLeft: '10px', paddingTop: '2px' }}>{disclosureLength}</div>
          </div>
          <div style={{ fontFamily: 'Arial', fontWeight: '100' }}>
            {/* <RichTextEditor
              placeHolder='Add Disclaimer Text here'
              name='emailDisclosure'
              value={disclosureText}
              setValue={setDisclosureText}
              isAdmin={userData}
              setError={setdisclosureError}
              charLimit={5000}
              fromClientSettings={true}
              setLength={setDisclosureLength}
            /> */}
            <FrolaRichTextEditor
              content={disclosureText}
              setContent={setDisclosureText}
              placeholder='Add Disclaimer Text here'
              maxHeight={95}
              minHeight={90}
              imageEditButtons={['imageReplace', 'imageRemove', '|', 'linkRemove', '-', 'imageAlt']}
            />
          </div>
        </div>
        <EmailPreview
          isModalOpen={isEmailPreviewOpen}
          setModalOpen={setEmailPreviewOpen}
          themeColor={themeColor}
          signature={headerText}
          disclaimer={disclosureText}
          logo={base64Logo !== '' ? base64Logo : editorSettings && editorSettings.logo ? editorSettings.logo.main.media_urls[0].path + editorSettings.logo.main.media_urls[0].name.system + '.' + editorSettings.logo.main.media_urls[0].ext : ''}
          onSave={saveHandler}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          setImageWidth={setImageWidth}
          setImageHeight={setImageHeight}
          originalWidth={originalWidth}
          originalHeight={originalHeight}
          loading={loading}
          fromPersonalize
          disclosureError={disclosureError}
          headerError={headerError}
          useLogo={useLogo}
        />
        {/* <div style={{marginTop: '-6px'}}> */}
        <div>
          <button disabled={!isEmpty(disclosureError) || !isEmpty(headerError)} className='btn-email' style={{ color: !isEmpty(disclosureError) || !isEmpty(headerError) ? '#adadad' : '#6351ed', background: 'none', cursor: !isEmpty(disclosureError) || !isEmpty(headerError) ? 'default' : 'pointer' }} onClick={previewClick}>Preview Changes</button>
          <LoaderWrapper isLoading={loading}>
            <Button disabled={!isEmpty(disclosureError) || !isEmpty(headerError)} margin="0px" onClick={saveHandler}>Save & Update</Button>
          </LoaderWrapper>
          {!isEmpty(disclosureError) &&
            <ErrorContainer align="center">
              Max (5000) character limit reached for Disclaimer Text
            </ErrorContainer>
          }

          {!isEmpty(headerError) &&
            <ErrorContainer align="center">
              Max (5000) character limit reached for Signature Text
            </ErrorContainer>
          }
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CustomizeEmailModal;
