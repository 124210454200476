import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faLinkedinIn,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@rebass/grid';
import { get } from 'lodash';

import {
  VerticalTabs,
  StackedBarChart,
  NumbersWithArrows,
  DownloadButton,
  Tabs,
  StyledTabListLeft,
} from '../../../components';
import {
  getActivityTabCount,
  getTabNameFromRepType,
  parseValueToCsv,
  saveFile,
} from '../../../utils';
import {
  AnalyticsVerticalTab,
  AnalyticsVerticalTabList,
  AnalyticsVerticalTabPanel,
  TabCount,
  TabLabel,
  TabContent,
  CommonVerticalTabPanel,
  DownloadButtonFlex,
  CommonTabPanel,
} from '../AnalyticsContainer.styles';
import {
  DashboardAdminSubTab,
} from '../../DashboardContainer/DashboardContainer.styles';
import { useEffect } from 'react';

const filterDataByPlatform = (data, filterBy) => {
  return (data || []).map((item) => {
    const total = get(item, `total.${filterBy}.others`, 0);
    const value = get(item, `value.${filterBy}.mine`, 0);
    return {
      id: item.id,
      name: item.name,
      total,
      value,
    };
  });
};

const styles = {
  adminSubTab: { fontSize: '14px', marginBottom: '20px' },
};

const ActivityTab = ({
  selectedDate,
  setSelectedDate,
  analyticsRequest,
  isAnalyticsDataFetching,
  analyticsObject,
  selectedRepType,
  tabContentType,
}) => {
  const [subTabIndex, setSubTabIndex] = useState({ ln: 0, tw: 0, fb: 0 });
  const [filterBy, setFilterBy] = useState('em');
  const apiRequest = (repType) => {
    analyticsRequest({
      params: {
        repType,
      },
    });
  };
  useEffect(() => {
    let repType = 'user_activity_by_topic';
    analyticsRequest({
      params: {
        repType,
      },
    });
    onTabSelect(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTwitterTabSelect = (tabIndex) => {
    setSubTabIndex({ ...subTabIndex, tw: tabIndex, fb: 0, ln: 0 });
    let repType =
      tabIndex === 0 ? 'user_activity_by_topic' : 'user_activity_by_partner';
    apiRequest(repType);
  };

  const onFacebookTabSelect = (tabIndex) => {
    setSubTabIndex({ ...subTabIndex, fb: tabIndex, ln: 0, tw: 0 });
    let repType =
      tabIndex === 0 ? 'user_activity_by_topic' : 'user_activity_by_partner';
    apiRequest(repType);
  };

  const onLinkedInTabSelect = (tabIndex) => {
    setSubTabIndex({ ...subTabIndex, ln: tabIndex, fb: 0, tw: 0 });
    let repType =
      tabIndex === 0 ? 'user_activity_by_topic' : 'user_activity_by_partner';
    apiRequest(repType);
  };

  const onTabSelect = (tabIndex) => {
    const filterMapping = {
      0: 'em',
      1: 'ln',
      2: 'tw',
      3: 'fb',
    };
    setFilterBy(filterMapping[tabIndex]);
    if (tabIndex === 0) {
      apiRequest('user_activity_by_topic');
    }
    if (tabIndex === 1) {
      onLinkedInTabSelect(0);
    }
    if (tabIndex === 2) {
      onTwitterTabSelect(0);
    }
    if (tabIndex === 3) {
      onFacebookTabSelect(0);
    }
  };

  const dataByRepType = get(analyticsObject, `${selectedRepType}`);
  let csvData = filterDataByPlatform(get(dataByRepType, 'data', []), filterBy);
  csvData = csvData.filter((c) => c.total > 0 || c.value > 0);

  const emailTabCount = getActivityTabCount(
    get(analyticsObject, `user_activity_by_topic.total.em`),
    get(analyticsObject, `user_activity_by_topic.previousValue.em.mine`)
  );
  const linkedInTabCount =
    subTabIndex.ln === 1 || subTabIndex.tw === 1 || subTabIndex.fb === 1
      ? getActivityTabCount(
          get(analyticsObject, `user_activity_by_partner.total.ln`),
          get(analyticsObject, `user_activity_by_partner.previousValue.ln.mine`)
        )
      : getActivityTabCount(
          get(analyticsObject, `user_activity_by_topic.total.ln`),
          get(analyticsObject, `user_activity_by_topic.previousValue.ln.mine`)
        );
  const twitterTabCount =
    subTabIndex.ln === 1 || subTabIndex.tw === 1 || subTabIndex.fb === 1
      ? getActivityTabCount(
          get(analyticsObject, `user_activity_by_partner.total.tw`),
          get(analyticsObject, `user_activity_by_partner.previousValue.tw.mine`)
        )
      : getActivityTabCount(
          get(analyticsObject, `user_activity_by_topic.total.tw`),
          get(analyticsObject, `user_activity_by_topic.previousValue.tw.mine`)
        );
  const facebookTabCount =
    subTabIndex.ln === 1 || subTabIndex.tw === 1 || subTabIndex.fb === 1
      ? getActivityTabCount(
          get(analyticsObject, `user_activity_by_partner.total.fb`),
          get(analyticsObject, `user_activity_by_partner.previousValue.fb.mine`)
        )
      : getActivityTabCount(
          get(analyticsObject, `user_activity_by_topic.total.fb`),
          get(analyticsObject, `user_activity_by_topic.previousValue.fb.mine`)
        );
  const filteredData = filterDataByPlatform(
    get(dataByRepType, `data`),
    filterBy
  );
  let filteredGraphData = filteredData.filter((f) => f.value !== 0);
  filteredGraphData = filteredGraphData
    .sort((a, b) => b.value - a.value)
    .slice(0, 5);

  const onDownloadCSVClick = () => {
    const tabName = getTabNameFromRepType(filterBy);
    const { startDate, endDate } = analyticsObject || {};
    const fileName = `${tabName}_Actiity-${startDate}_${endDate}`;
    const parsedData = parseValueToCsv(csvData, selectedRepType, true);
    if (csvData.length > 0) {
      saveFile(parsedData, fileName);
    }
  };

  return (
    <VerticalTabs screenWidth={window.innerWidth} onSelect={onTabSelect}>
      <AnalyticsVerticalTabList screenWidth={window.innerWidth}>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faEnvelope} />
            <div style={{ padding: '2px' }}>
              <TabLabel fontSize="16px">Email</TabLabel>
            </div>
            <div style={{ padding: '2px' }}>
              <TabCount fontSize="28px">{emailTabCount.tabCount || 0}</TabCount>
            </div>
            <div style={{ padding: '2px' }}>
              {emailTabCount.tabCount !== 0 ? (
                <TabLabel fontSize="16px">
                  <NumbersWithArrows value={emailTabCount.trend} suffix="%" />
                </TabLabel>
              ) : null}
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faLinkedinIn} />
            <div style={{ padding: '2px' }}>
              <TabLabel fontSize="16px">LinkedIn</TabLabel>
            </div>
            <div style={{ padding: '2px' }}>
              <TabCount fontSize="28px">
                {linkedInTabCount.tabCount || 0}
              </TabCount>
            </div>
            <div style={{ padding: '2px' }}>
              {linkedInTabCount.tabCount !== 0 ? (
                <TabLabel fontSize="16px">
                  <NumbersWithArrows
                    value={linkedInTabCount.trend}
                    suffix="%"
                  />
                </TabLabel>
              ) : null}
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faTwitter} />
            <div style={{ padding: '2px' }}>
              <TabLabel fontSize="16px">Twitter</TabLabel>
            </div>
            <div style={{ padding: '2px' }}>
              <TabCount fontSize="28px">{twitterTabCount.tabCount}</TabCount>
            </div>
            <div style={{ padding: '2px' }}>
              {twitterTabCount.tabCount !== 0 ? (
                <TabLabel fontSize="16px">
                  <NumbersWithArrows value={twitterTabCount.trend} suffix="%" />
                </TabLabel>
              ) : null}
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faFacebook} />
            <div style={{ padding: '2px' }}>
              <TabLabel fontSize="16px">Facebook</TabLabel>
            </div>
            <div style={{ padding: '2px' }}>
              <TabCount fontSize="28px">{facebookTabCount.tabCount}</TabCount>
            </div>
            <div style={{ padding: '2px' }}>
              <TabLabel fontSize="16px">
                <NumbersWithArrows value={facebookTabCount.trend} suffix="%" />
              </TabLabel>
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
      </AnalyticsVerticalTabList>

      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Box width={1}>
          <CommonVerticalTabPanel>
            <StackedBarChart
              tabContentType={tabContentType}
              yKey={'Emails sent'}
              loading={isAnalyticsDataFetching}
              data={filteredGraphData}
            />
          </CommonVerticalTabPanel>
          <DownloadButtonFlex>
            <DownloadButton
              disabled={csvData.length === 0 ? true : false}
              onClick={onDownloadCSVClick}
              fontSize="18px"
            />
          </DownloadButtonFlex>
        </Box>
      </AnalyticsVerticalTabPanel>
      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Box width={1}>
          <Tabs onSelect={onLinkedInTabSelect}>
            <StyledTabListLeft>
              <DashboardAdminSubTab
                style={styles.adminSubTab}
                color="#49484a"
                padding="5px 23px 3px"
                type="analytics-tab"
                className={`${subTabIndex.ln === 0 ? 'active' : 'inactive'}`}
              >
                By Topic
              </DashboardAdminSubTab>
              <DashboardAdminSubTab
                style={styles.adminSubTab}
                color="#49484a"
                padding="5px 23px 3px"
                type="analytics-tab"
                className={`${subTabIndex.ln === 1 ? 'active' : 'inactive'}`}
              >
                By Publisher
              </DashboardAdminSubTab>
            </StyledTabListLeft>
            <CommonTabPanel>
              
              <StackedBarChart
                tabContentType={tabContentType}
                yKey={'Shares on Linkedin'}
                loading={isAnalyticsDataFetching}
                data={filteredGraphData}
              />
            </CommonTabPanel>
            <CommonTabPanel>
              
              <StackedBarChart
                tabContentType={tabContentType}
                yKey={'Shares on Linkedin'}
                loading={isAnalyticsDataFetching}
                data={filteredGraphData}
              />
            </CommonTabPanel>
            <DownloadButtonFlex>
              <DownloadButton
                disabled={csvData.length === 0 ? true : false}
                onClick={onDownloadCSVClick}
                fontSize="18px"
              />
            </DownloadButtonFlex>
          </Tabs>
        </Box>
      </AnalyticsVerticalTabPanel>
      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Box width={1}>
          <Tabs onSelect={onTwitterTabSelect}>
            <StyledTabListLeft>
              <DashboardAdminSubTab
                style={styles.adminSubTab}
                color="#49484a"
                padding="5px 23px 3px"
                type="analytics-tab"
                className={`${subTabIndex.tw === 0 ? 'active' : 'inactive'}`}
              >
                By Topic
              </DashboardAdminSubTab>
              <DashboardAdminSubTab
                style={styles.adminSubTab}
                color="#49484a"
                padding="5px 23px 3px"
                type="analytics-tab"
                className={`${subTabIndex.tw === 1 ? 'active' : 'inactive'}`}
              >
                By Publisher
              </DashboardAdminSubTab>
            </StyledTabListLeft>
            <CommonTabPanel>
              
              <StackedBarChart
                tabContentType={tabContentType}
                yKey={'Shares on Twitter'}
                loading={isAnalyticsDataFetching}
                data={filteredGraphData}
              />
            </CommonTabPanel>
            <CommonTabPanel>
              
              <StackedBarChart
                tabContentType={tabContentType}
                yKey={'Shares on Twitter'}
                loading={isAnalyticsDataFetching}
                data={filteredGraphData}
              />
            </CommonTabPanel>
            <DownloadButtonFlex>
              <DownloadButton
                disabled={csvData.length === 0 ? true : false}
                onClick={onDownloadCSVClick}
                fontSize="18px"
              />
            </DownloadButtonFlex>
          </Tabs>
        </Box>
      </AnalyticsVerticalTabPanel>
      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Box width={1}>
          <Tabs onSelect={onFacebookTabSelect}>
            <StyledTabListLeft>
              <DashboardAdminSubTab
                style={styles.adminSubTab}
                color="#49484a"
                padding="5px 23px 3px"
                type="analytics-tab"
                className={`${subTabIndex.fb === 0 ? 'active' : 'inactive'}`}
              >
                By Topic
              </DashboardAdminSubTab>
              <DashboardAdminSubTab
                style={styles.adminSubTab}
                color="#49484a"
                padding="5px 23px 3px"
                type="analytics-tab"
                className={`${subTabIndex.fb === 1 ? 'active' : 'inactive'}`}
              >
                By Publisher
              </DashboardAdminSubTab>
            </StyledTabListLeft>
            <CommonTabPanel>
              
              <StackedBarChart
                tabContentType={tabContentType}
                yKey={'Shares on Facebook'}
                loading={isAnalyticsDataFetching}
                data={filteredGraphData}
              />
            </CommonTabPanel>
            <CommonTabPanel>
              
              <StackedBarChart
                tabContentType={tabContentType}
                yKey={'Shares on Facebook'}
                loading={isAnalyticsDataFetching}
                data={filteredGraphData}
              />
            </CommonTabPanel>
            <DownloadButtonFlex>
              <DownloadButton
                disabled={csvData.length > 0 ? false : true}
                onClick={onDownloadCSVClick}
                fontSize="18px"
              />
            </DownloadButtonFlex>
          </Tabs>
        </Box>
      </AnalyticsVerticalTabPanel>
    </VerticalTabs>
  );
};

export default ActivityTab;
