import { Flex } from '@rebass/grid';
import React from 'react';

const AddContentLayout = ({ layout, setLayout, themesData, webinarData }) => {
    return (
        <Flex width="100%" alignItems="center" justifyContent="center">
            <Flex fontSize="14px" alignItems="center" pr="10px" color={themesData.black}>
                Select Layout
            </Flex>
            <Flex
                alignItems='center'
                width='60%'
            >
                {/* <p style={{fontSize: '14px'}}>Select Row Type: </p> */}
                <select
                    style={{
                        color: '#6351ed',
                        border: '1px solid #adadad',
                        fontSize: '14px',
                        padding: '4px',
                        width: '65%'
                    }}
                    name="row-type"
                    id="row_type"
                    value={layout}
                    defaultValue=''
                    onChange={e => setLayout(e.target.value)}
                >
                    <option value="none" disabled hidden>Select an Option</option>
                    <option value="one-column">One Column Article Block</option>
                    <option value='two-columns'>Two Column Article Block</option>
                    <option value="one-column-reverse">One Column Reverse Article Block</option>
                    <option value="landing-pages">Landing Pages</option>
                    {webinarData && webinarData.isWebinar && (
                        <option value="webinar-block">Webinar Block</option>
                    )}
                </select>
            </Flex>
        </Flex>
    );
};

export default AddContentLayout;