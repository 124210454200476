/* eslint-disable react-hooks/exhaustive-deps */
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import { get, isEmpty, map } from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonNoBorderBackground,
  FileUpload,
  LoaderWrapper,
  ResponsiveImg
} from '../../../components';
import { dataURLtoFile } from '../../../services/dashboard';
import { updateNewsletter } from '../../../services/newsletter';
import { themesData, toBase64 } from '../../../utils';
import { NewletterInput } from '../GetStartedTab/GetStartedTab.styles';
import {
  CustomizeWrapper,
  HeroImgError,
  ImagePreviewWrapper,
  TooltipMode,
} from './CustomizeEmailTab.styles';
import CreatableSelect from 'react-select/creatable';
import { ToggleButton } from '../../ConfigureContainer/screenConfigure.styles';
import { FrolaRichTextEditor } from '../../../components/RichTextEditor/FroalaRichTextEditor';

const CustomizeEmailTab = ({
  setSelectedIndex,
  newsletterSetState,
  isEditMode,
  customizeData,
  newsletterId,
  newsletterArticles,
  selectedContacts,
  ccContacts,
  setTouched,
  cloneDetails,
  emailTemplateSelected,
  newsletterName,
}) => {
  const [fileErrorMsg, setFileErrorMsg] = useState('');
  const [newsletterText, setNewsletterText] = useState(
    // customizeData && customizeData.message ? customizeData.message : `<p fr-original-style=\"\" style=\"font-family: Arial, sans-serif; color: black; box-sizing: border-box; margin: 0px;\"></p>`
    customizeData && customizeData.message ? customizeData.message : ''
  );
  const [newsletterTitle, setNewsletterTitle] = useState(
    `<p fr-original-style=\"\" style=\"font-family: Arial, sans-serif; color: black; font-size: 24px; box-sizing: border-box; margin: 0px;\">${get(customizeData, 'title', newsletterName)}</p>` 
  );

  const [newsletterSubject, setNewsletterSubject] = useState('');
  const [OGFormData, setOGFormData] = useState({});
  const [greetings, setGreetings] = useState('Hello');
  const [salutation, setSalutation] = useState({
    label: 'First Name',
    value: '<first_name>',
  });
  const [base64Logo, setBase64Logo] = useState('');
  const [loading, setLoading] = useState(false);
  const [showToggle, setShowToggle] = useState(get(customizeData, 'toggle', true));
  const salutationTextOptions = [
    { label: 'First Name', value: '<first_name>' },
    { label: 'Last Name', value: '<last_name>' },
    { label: 'Full Name', value: '<full_name>' },
  ];

  const styles = {
    container: (base) => ({
      ...base,
      paddingTop: '1px',
    }),
    control: (base) => ({
      ...base,
      minHeight: 14,
      width: '160px',
      marginLeft: '10px',
      background: 'transparent',
      fontSize: '12px',
      height: '39px',
    }),
    option: (base) => ({
      ...base,
      fontSize: '12px',
      width: '160px',
      textAlign: 'left',
    }),
    menu: (base) => ({
      ...base,
      width: '160px',
      marginLeft: '10px',
      zIndex: '3000',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      color: '#848484',
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  useEffect(() => {
    setOGFormData({
      subject: customizeData.subject ? customizeData.subject : '',
      salutation: {
        greeting: customizeData.salutation ? customizeData.salutation : 'Hello',
        name: customizeData.salutation_text
          ? customizeData.salutation_text
          : { label: 'First Name', value: '<first_name>' },
      },
      image: customizeData.media1 ? customizeData.media1 : '',
      message:
        // customizeData && customizeData.message ? customizeData.message : `<p fr-original-style=\"\" style=\"font-family: Arial, sans-serif; color: black; box-sizing: border-box; margin: 0px;\"></p>`,
        customizeData && customizeData.message ? customizeData.message : '',
    });
    if (!isEditMode && customizeData) {
      setGreetings(
        customizeData.salutation ? customizeData.salutation : 'Hello'
      );
      setSalutation(
        customizeData.salutation_text
          ? customizeData.salutation_text
          : { label: 'First Name', value: '<first_name>' }
      );
      setNewsletterSubject(customizeData.subject ? customizeData.subject : '');
      setBase64Logo(customizeData.media1 ? customizeData.media1 : '');
    } else if (isEditMode && customizeData) {
      setGreetings(
        customizeData.salutation ? customizeData.salutation : 'Hello'
      );
      setSalutation(
        customizeData.salutation_text
          ? customizeData.salutation_text
          : { label: 'First Name', value: '<first_name>' }
      );
      setNewsletterSubject(customizeData.subject ? customizeData.subject : '');
      setBase64Logo(customizeData.media1 ? customizeData.media1 : '');
      // setNewsletterTitle(customizeData.title ? customizeData.title : '');
    }
  }, []);

  const onFileUploadChange = (logofile, setFieldValue) => {
    setFileErrorMsg('');
    toBase64(logofile[0]).then((file) => {
      console.log('[FILE] - ', file);
      setBase64Logo(file);
      // setFieldValue('logoUpload', file);
      // setFieldValue('logoUploadFile', logofile);
      setTouched(true);
    });
  };

  const goToAddArticle = () => {
    if (emailTemplateSelected === 'no-content') {
      setSelectedIndex(1);
    } else {
      setSelectedIndex(2);
    }
  };

  const handleSubjectChange = (e) => {
    setNewsletterSubject(e.target.value);
    if (OGFormData.subject && OGFormData.subject !== e.target.value)
      setTouched(true);
  };

  const handleGreetingsChange = (e) => {
    setGreetings(e.target.value);
    if (
      OGFormData.salutation.greeting &&
      OGFormData.salutation.greeting !== e.target.value
    )
      setTouched(true);
  };

  const handleChange = (newValue, actionMeta) => {
    setSalutation(newValue ? newValue : { label: '', value: '' });
    if (
      newValue &&
      OGFormData.salutation.greeting &&
      OGFormData.salutation.greeting !== newValue
    )
      setTouched(true);
  };

  const handleNextClick = () => {
    const standardizedMessage = OGFormData.message.replace('\\', '');
    // console.log('COMPARISON - - - -', newsletterText!==standardizedMessage);
    // console.log('OGFORMDATA - - - -', );
    if (newsletterText !== standardizedMessage) setTouched(true);
    // else if(!touched)
    //    setTouched(false);
    setLoading(true);
    let logoFile;
    if (base64Logo && !base64Logo.includes('https://')) {
      logoFile = dataURLtoFile(base64Logo, 'filename.png');
    }

    let formattedNewsletterArticles = [];

    let customData = {
      subject: newsletterSubject,
      salutation: !showToggle ? '' : greetings,
      salutation_text: !showToggle ? '' : salutation.value,
      message: newsletterText,
      title: newsletterTitle,
      toggle: showToggle
    };

    let formattedSelectedContacts = map(selectedContacts, 'id');

    const params = {
      contactlists: JSON.stringify(formattedSelectedContacts),
      custom_data: JSON.stringify(customData),
      template: JSON.stringify(emailTemplateSelected),
    };

    if (ccContacts.length > 0) params.cc_email_ids = JSON.stringify(ccContacts);

    if (!isEmpty(newsletterArticles)) {
      formattedNewsletterArticles = newsletterArticles.map((item, index) => {
        let obj = {
          article_id: item.id,
        };

        if (item.themeId) {
          obj.theme_id = item.themeId;
        }
        return obj;
      });
      params.articles = JSON.stringify(formattedNewsletterArticles);
    }

    if (
      !customizeData.compliance_status &&
      (!cloneDetails || !cloneDetails.compliance_status)
    ) {
      updateNewsletter({ id: newsletterId, params: params, media1: logoFile })
        .then((res) => {
          if (res.result.success) {
            setLoading(false);
            newsletterSetState({
              customData: {
                ...customizeData,
                subject: newsletterSubject,
                salutation: !showToggle ? '' : greetings,
                salutation_text: !showToggle ? '' : salutation,
                message: newsletterText,
                media1: base64Logo,
                title: newsletterTitle,
                toggle: showToggle
              },
            });
            setSelectedIndex(4);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      newsletterSetState({
        customData: {
          ...customizeData,
          subject: newsletterSubject,
          salutation: !showToggle ? '' : greetings,
          salutation_text: !showToggle ? '' : salutation,
          message: newsletterText,
          media1: base64Logo,
          title: newsletterTitle,
          toggle: showToggle
        },
      });
      setLoading(false);
      setSelectedIndex(4);
    }
  };

  return (
    <Flex width={1} flexDirection="column" padding="60px 30px 0px">
      <CustomizeWrapper>
        <Flex width="100%" flexDirection="column">
          <Flex
            width="100%"
            justifyContent="flex-start"
            pt="25px"
            flexDirection="row"
          >
            <Flex
              fontSize="14px"
              color={themesData.charcoalGrey}
              style={{ fontWeight: 600 }}
            >
              Subject*
            </Flex>
            <TooltipMode width="200px">
              <div class="tooltip">
                <ResponsiveImg
                  src="/assets/images/information-grey.svg"
                  // title="Subject line for the newsletter email."
                  style={{
                    height: '57%',
                    paddingLeft: '5px',
                    paddingBottom: '1px',
                  }}
                />
                <span class="tooltiptext tooltip-right">
                  Subject line for the newsletter email.
                </span>
              </div>
            </TooltipMode>
          </Flex>
          <NewletterInput
            value={newsletterSubject}
            onChange={handleSubjectChange}
            placeholderTextAlign="left"
            width="100%"
            placeholder="Add a subject line"
          />
        </Flex>

        <Flex color="#848484" width="100%" flexDirection="column">
          <Flex
            width="100%"
            justifyContent="flex-start"
            pt="25px"
            flexDirection="row"
          >
            <Flex
              fontSize="14px"
              color={themesData.charcoalGrey}
              style={{ fontWeight: 600 }}
            >
              Title
            </Flex>
            <TooltipMode width="260px">
              <div class="tooltip">
                <ResponsiveImg
                  src="/assets/images/information-grey.svg"
                  // title="Optional. The title will appear above hero image."
                  style={{
                    height: '57%',
                    paddingLeft: '5px',
                    paddingBottom: '1px',
                  }}
                />
                <span class="tooltiptext tooltip-right">
                  Optional. The title will appear above hero image.
                </span>
              </div>
            </TooltipMode>
          </Flex>
          <div style={{ fontSize: '14px' }}>
            <FrolaRichTextEditor
              content={newsletterTitle}
              setContent={setNewsletterTitle}
              isTitle
            />
          </div>
        </Flex>

        <Flex width="100%">
          <Flex width="20%" flexDirection="column">
            <Flex
              width="100%"
              justifyContent="flex-start"
              pt="10px"
              flexDirection="row"
            >
              <Flex
                fontSize="14px"
                color={themesData.charcoalGrey}
                style={{ fontWeight: 600 }}
                pb="10px"
              >
                Greeting
              </Flex>
              <ToggleButton
                className="react-switch"
                onChange={() => setShowToggle(!showToggle)}
                checked={showToggle}
                onColor="#6351ed"
                offColor="#cccccc"
                offHandleColor="#fff"
                onHandleColor="#fff"
                handleDiameter={10}
                uncheckedIcon=""
                checkedIcon=""
                boxShadow=""
                activeBoxShadow=""
                type="autoPost"
                top="-7px"
                // left="5px"
              />
              <TooltipMode width="550px" center>
                <div class="tooltip">
                  <ResponsiveImg
                    src="/assets/images/information-grey.svg"
                    // title="Greeting that will appear in the email, e.g., “Hello Alex,”. This greeting can be toggled off if you do not want a greeting in your newsletter."
                    style={{
                      height: '45%',
                      paddingLeft: '10px',
                      paddingBottom: '1px',
                    }}
                  />
                  <span class="tooltiptext tooltip-right">
                    Greeting that will appear in the email, e.g., “Hello Alex,”.
                    This greeting can be toggled off if you do not want a
                    greeting in your newsletter.
                  </span>
                </div>
              </TooltipMode>
            </Flex>
            <NewletterInput
              value={greetings}
              onChange={handleGreetingsChange}
              placeholderTextAlign="left"
              width="100%"
              placeholder="Hello"
              readOnly={!showToggle ? true : false}
            />
          </Flex>

          <Flex width="40%" flexDirection="column">
            <label></label>
            <label></label>
            <CreatableSelect
              isClearable
              onChange={handleChange}
              options={salutationTextOptions}
              styles={styles}
              value={salutation}
              isDisabled={!showToggle ? true : false}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: themesData.purpleishBlue,
                },
              })}
            />
          </Flex>

          <Flex width="20%" flexDirection="column">
            {/* <label>Upload Image</label> */}
            <Flex
              width="100%"
              justifyContent="flex-start"
              pt="10px"
              flexDirection="row"
            >
              <Flex
                fontSize="14px"
                color={themesData.charcoalGrey}
                style={{ fontWeight: 600 }}
                pb="10px"
              >
                Hero Image
              </Flex>
              <TooltipMode>
                <div class="tooltip">
                  <ResponsiveImg
                    src="/assets/images/information-grey.svg"
                    // title="Optional. The hero image will appear above the Greeting."
                    style={{
                      height: '45%',
                      paddingLeft: '5px',
                      paddingBottom: '1px',
                    }}
                  />
                  <span class="tooltiptext tooltip-right">
                    Optional. The hero image will appear above the Greeting.
                  </span>
                </div>
              </TooltipMode>
            </Flex>
            <FileUpload
              asLabel
              margin="0px"
              name="logoUpload"
              id="logoUpload"
              borderRadius="0px"
              height="40px"
              width="145px"
              background="#fdfdfd"
              label={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '80%',
                    fontSize: '10px',
                  }}
                >
                  <img src="/assets/images/upload.svg" alt="upload" />
                  <div>Upload an image (jpg, png, bmp)</div>
                </div>
              }
              onChange={(file) => onFileUploadChange(file)}
              styles={{
                fontSize: '12px',
                backgroundColor: '#fdfdfd',
                border: 'solid 1px #a0a0a0',
              }}
              onError={(msg) => {
                setFileErrorMsg(msg);
              }}
              maxSizeMB={10}
              accept=".png,.jpeg,.jpg,.svg,.bmp"
              title="Maximum Image Size : 10MB"
            />
          </Flex>
          <Flex
            width="20%"
            justifyContent="center"
            alignItems="center"
            pt={base64Logo ? '33px' : '40px'}
          >
            <ImagePreviewWrapper
              height={base64Logo ? '54px' : ''}
              width={base64Logo ? '131px' : ''}
            >
              {base64Logo ? (
                <img
                  src={base64Logo}
                  alt=""
                  style={{ width: '100%', height: '100%' }}
                />
              ) : (
                'No Image Found'
              )}
            </ImagePreviewWrapper>
          </Flex>
        </Flex>
        <Flex
          alignItems={'center'}
          justifyContent={'flex-end'}
          width="105%"
          fontSize="0.4em"
        >
          <HeroImgError>{fileErrorMsg}</HeroImgError>
        </Flex>

        <Flex color="#848484" width="100%" flexDirection="column">
          {/* <label>Message</label>
           */}
          <Flex
            width="100%"
            justifyContent="flex-start"
            pt="10px"
            flexDirection="row"
          >
            <Flex
              fontSize="14px"
              color={themesData.charcoalGrey}
              style={{ fontWeight: 600 }}
              pb="10px"
            >
              Message
            </Flex>
            <TooltipMode width="515px">
              <div class="tooltip">
                <ResponsiveImg
                  src="/assets/images/information-grey.svg"
                  // title="Message that will appear in the email body. You can format this text, and add hyperlinks or images."
                  style={{
                    height: '45%',
                    paddingLeft: '5px',
                    paddingBottom: '1px',
                  }}
                />
                <span class="tooltiptext tooltip-right">
                  Message that will appear in the email body. You can format
                  this text, and add hyperlinks or images.
                </span>
              </div>
            </TooltipMode>
          </Flex>
          <div style={{ fontSize: '14px' }}>
            {/* <RichTextEditor
              placeHolder="Add Newsletter Body Text"
              name="newsletterText"
              value={newsletterText}
              setValue={setNewsletterText}
              fromNewsletter
            /> */}
            {/* <JoditRichTextEditor
              content={newsletterText}
              setContent={setNewsletterText}
            /> */}
            <FrolaRichTextEditor
              content={newsletterText}
              setContent={setNewsletterText}
            />
          </div>
        </Flex>
      </CustomizeWrapper>

      <Flex width="100%" justifyContent="flex-end" fontSize="12px" pt="25px">
        *Required
      </Flex>

      <Flex width="100%" justifyContent="space-between" pb="2px" pt="10px">
        <LoaderWrapper isLoading={loading}>
          <ButtonNoBorderBackground
            padding="5px 0px 0px"
            onClick={goToAddArticle}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &nbsp; Back
          </ButtonNoBorderBackground>

          <Button
            height="36px"
            width="146px"
            margin={
              !customizeData.compliance_status
                ? '10px 0px'
                : '10px 35px 0px 0px'
            }
            onClick={handleNextClick}
            disabled={isEmpty(newsletterSubject)}
            bgColor={isEmpty(newsletterSubject) ? '#AFAFAF' : ''}
          >
            Next
          </Button>

          <ButtonNoBorderBackground
            disabled={isEmpty(newsletterSubject)}
            padding="5px 0px 0px"
            onClick={
              !customizeData.compliance_status ? handleNextClick : () => {}
            }
          >
            {!customizeData.compliance_status ? 'Save' : ''}
          </ButtonNoBorderBackground>
        </LoaderWrapper>
      </Flex>
    </Flex>
  );
};

export default CustomizeEmailTab;
