import { Flex } from '@rebass/grid';
import React, { useState, useEffect } from 'react';
import { VerticalSeparator } from '../DripDashboardListItem/DripDashboardListItem.styles';
import moment from 'moment';
import { camelCase, startCase } from 'lodash';
import CampaignFeedbackPopup from '../CampaignFeedback/CampaignFeedbackPopup';
import CampaignPurpose from '../CampaignPurpose/CampaignPurpose';

export const statusColorCodes = {
  completed: '#33B21B',
  scheduled: '#00CCF2',
  draft: '#FFAA00',
  in_progress: '#326DE6',
  approval_pending: '#cc6ac0',
  approval_rejected: '#ff2323',
  compliance_pending: '#cc6ac0',
  compliance_rejected: '#ff2323',
  compliance_approved: '#00CCF2',
  partially_completed: '#33B21B', 
  active: '#6352ed',
  'Completed (cancelled)': '#33B21B',
};

const CampaignInfo = ({
  title,
  start_date,
  purpose,
  duration,
  frequency,
  target,
  status,
  type,
  campaignDetailsView,
  campaignDetailsRequest,
  setDetailsView,
  id,
  detailsHandler,
  mode = false,
  isadvMode,
  webinar,
  feedback,
  campaigns,
  engagement_status,
  approved_to_share,
  isEnterpriseAdvisor,
  campaign_cascade,
  is_cascaded,
  isSubCampaign,
  is_global_compliance,
  partially_completed
}) => {
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  // console.log('APPROVED TO SHARE [fromCampaignInfo] - - -- ', approved_to_share);
  // console.log('CAMPAIGN CASCADE [fromCampaignInfo] - - -- ', campaign_cascade);
  // console.log('IS ENTERPRISE ADVISOR [fromCampaignInfo] - - -- ', isEnterpriseAdvisor);

  const typeLabel = {
    series: { title: 'Series', img: '/assets/images/drip/content_copy.svg' },
    one_time: { title: 'One Time', img: '/assets/images/drip/card.svg' },
    automated: {
      title: 'Automated',
      img: '/assets/images/drip/content_copy.svg',
    },
  };
  //const detailsLabel = ['Start Date', 'Status', ...(!mode ? [''] : [])];
  const detailsLabel = [
    'Start Date',
    'Status',
    ...(!mode ? [''] : []),
    ...(webinar ? [''] : []),
  ];

  const details = {
    startDate: start_date ? moment(start_date).format('DD MMM YYYY') : '-',
    status: status && !partially_completed ? startCase(camelCase(status)) : status && partially_completed ? 'Partially Completed' : '-',
    type: type ? typeLabel[type]?.title : '-',
    ...(webinar
      ? {
          webinar: 'yes',
        }
      : {}),
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      marginLeft="15px"
      marginBottom="5px"
      width={
        campaignDetailsView
          ? '100%'
          : (approved_to_share === 'true' || is_cascaded === 'true') &&
            (engagement_status === 'paused' ||
              engagement_status === 'cancelled')
          ? '42%'
          : type === 'series' && !isSubCampaign
          ? '50%'
          : '36%'
      }
      // onClick={() => {
      //   campaignDetailsRequest({ id: id });
      //   setDetailsView(true);
      //   detailsHandler(id);
      // }}
      style={{
        paddingRight: '10px',
        wordBreak: 'break-word',
      }}
    >
      <span
        style={{
          fontSize: '18px',
          color: '#000000',
          fontWeight: '600',
          textAlign: 'left',
          wordWrap: 'break-word',
          width: '-webkit-fill-available',
        }}
        data-testid="campaign-title"
      >
        {/* {(status.charAt(0).toUpperCase() + status.slice(1)).replace('-', ' ')}{' '}
        Campaign -  */}
        {title}
      </span>
      <Flex justifyContent="center" alignItems="center" marginTop="5px">
        {Object.keys(details).map((ele, index) => (
          <>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              width={
                detailsLabel[index] === 'Status'
                  ? (approved_to_share === 'true' || is_cascaded === 'true') &&
                    status !== 'draft'
                    ? engagement_status === 'paused' ||
                      engagement_status === 'cancelled'
                      ? '271px'
                      : '190px'
                    : '180px'
                  : '120px'
              }
            >
              <span
                style={{
                  color: '#4E6780',
                  fontSize: '12px',
                  marginTop: detailsLabel[index] === '' ? '15px' : '',
                }}
              >
                {detailsLabel[index]}
              </span>
              <div
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {ele === 'type' && (
                  <img
                    alt=""
                    src={type && typeLabel[type]?.img}
                    style={{
                      margin: '-6px -6px -6px 10px',
                    }}
                  />
                )}
                {ele === 'webinar' && (
                  <img
                    alt=""
                    src="/assets/images/webinars.svg"
                    style={{
                      margin: '-6px -6px -6px 10px',
                    }}
                  />
                )}
                <span
                  data-testid="campaign-status"
                  style={{
                    color:
                      ele === 'status' && status && !partially_completed
                        ? statusColorCodes[status] : ele === 'status' && status && partially_completed
                        ? '#33B21B'
                        : '#000000',
                    fontSize: '13px',
                    fontWeight: '600',
                    marginLeft: ele === 'type' ? '15px' : '',
                  }}
                  id={`campaign-${ele}`}
                >
                  {ele === 'webinar' ? '' : details[ele]}
                  {details[ele] === 'In Progress' &&
                  engagement_status === 'paused'
                    ? ' (Paused)'
                    : ''}
                  {details[ele] === 'Completed' &&
                  engagement_status === 'cancelled'
                    ? ' (Cancelled)'
                    : ''}
                  {details[ele] === 'Approval Rejected' && isadvMode && (
                    <img
                      data-testid="campaign-rejectedPopup"
                      src="/assets/images/Icon-eye.svg"
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                        top: '1px',
                        left: '5px',
                        height: '10px',
                      }}
                      alt=""
                      onClick={() => {
                        setFeedbackPopup(true);
                      }}
                    />
                  )}
                </span>
                {detailsLabel[index] === 'Status' && is_global_compliance === 'false' &&
                  (approved_to_share === 'true' || is_cascaded === 'true') && (
                    <span
                      style={{
                        width: '50px',
                        backgroundColor: '#49484a',
                        color: '#fff',
                        borderRadius: '3px',
                        padding: '3px 6px',
                        fontSize: '10px',
                        marginLeft: '8px',
                      }}
                    >
                      Approved To Share
                    </span>
                  )}
                {
                  detailsLabel[index] === 'Status' && is_global_compliance === 'true' && (
                    <span
                      style={{
                        width: '50px',
                        backgroundColor: '#49484a',
                        color: '#fff',
                        borderRadius: '3px',
                        padding: '3px 6px',
                        fontSize: '10px',
                        marginLeft: '8px',
                      }}
                    >
                      Global Approved
                    </span>
                  )
                }
              </div>
            </Flex>
            {webinar
              ? index < detailsLabel.length - 2 && <VerticalSeparator />
              : index < detailsLabel.length - 1 && <VerticalSeparator />}
            {/* {index !== detailsLabel.length - 1 && <VerticalSeparator />} */}
          </>
        ))}
        {feedbackPopup && (
          <CampaignFeedbackPopup
            handleClose={() => {
              setFeedbackPopup(false);
            }}
            feedback={feedback}
            title={title}
            campaigns={campaigns}
          />
        )}
        {type === 'series' && !isSubCampaign && (
          <>
            <VerticalSeparator />
            <CampaignPurpose type={type} purpose={purpose} />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default CampaignInfo;
