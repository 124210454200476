import React, { useEffect, useState } from 'react';
import { Box } from '@rebass/grid';
import { Stepper } from '../../components/Stepper/Stepper';
import SelectTarget from './CampaignCreateSteps/SelectTarget';
import CustomizeCampaign from './CampaignCreateSteps/CustomizeCampaign';
import ActivateCampaign from './CampaignCreateSteps/ActivateCampaign';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { getAutomatedCampaignById } from '../../services/automated-campaigns';
import { getFirmSettings, getUserRole } from '../../redux/selector';

export const AUTOMATED_CAMPAIGN_ACTIONS = {
  WELCOME_CLIENTS: 'welcome_clients',
  NURTURE_PROSPECTS: 'nurture_prospects',
  EVENT: 'event',
  NEWSLETTER: 'newsletter',
};

const stepListLines = [
  {
    line1: 'Select',
    line2: 'Target',
  },
  {
    line1: 'Customize',
    line2: 'campaigns',
  },
  {
    line1: 'Activate',
    line2: 'campaign',
  },
];

const AUTOMATED_CAMPAIGN_STEP_LIST = stepListLines.map(({ line1, line2 }) => (
  <span>
    {line1}
    <br />
    {line2}
  </span>
));

const AutomatedCampaignsHome = ({
  currentFlow,
  campaignSetState,
  userData,
  automatedCampaignDetails,
  automatedCampaignTargetInfo,
  automatedCampaignId,
  campaignId,
  userRole,
  compliance,
  isEditMode,
  landingPagesData,
  segmentListData,
  isFirmLevelAdmin,
  firmSettings
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  console.log('CURRENT STEP INDEX - - -- - ', currentStepIndex);

  const getFormByIndex = (index) => {
    if (index === 0)
      return (
        <SelectTarget
          automatedCampaignDetails={automatedCampaignDetails}
          campaignSetState={campaignSetState}
          targetInfo={automatedCampaignTargetInfo}
          // campaignAction={AUTOMATED_CAMPAIGN_ACTIONS.EVENT}
          campaignAction={currentFlow}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          userData={userData}
          userRole={userRole}
          isEditMode={isEditMode}
        />
      );
    if (index === 1)
      return (
        <CustomizeCampaign
          campaignAction={currentFlow}
          campaignSetState={campaignSetState}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          automatedCampaignDetails={automatedCampaignDetails}
          // campaignId={campaignId}
          // setTemplateData={setTemplateData}
          // setTemplateBase={setTemplateBase}
          // defaultTemplates={defaultTemplates}
          // selectedEmailCreationIndex={selectedEmailCreationIndex}
        />
      );
    // if (index === 2) return <p>Step 3 Form</p>;
    // if (index === 3) return <p>Step 4 Form</p>;
    if (index === 2)
      return (
        <ActivateCampaign
          userRole={userRole}
          compliance={compliance}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          userData={userData}
          campaignSetState={campaignSetState}
          automatedCampaignDetails={automatedCampaignDetails}
          landingPagesData={landingPagesData}
          segmentListData={segmentListData}
          firmSettings={firmSettings}
          isFirmLevelAdmin={isFirmLevelAdmin}
        />
      );
    return <></>;
  };

  const fetchTargetInfoFromDB = async () => {
    try {
      const {
        data: {
          type,
          name,
          purpose,
          target_page,
          segment,
          start_date,
          event_date,
          cc_email_addresses,
          converted_tag,
          is_global_compliance,
          is_cascaded,
          approved_to_share,
          // subscription_type
        },
      } =
        (await getAutomatedCampaignById({
          campaignId: automatedCampaignDetails.campaignId,
        })) || {};

      const targetInfo = {
        type,
        name,
        purpose,
        target_page,
        segment,
        start_date,
        event_date,
        cc_email_addresses,
        converted_tag,
        is_global_compliance,
        is_cascaded,
        approved_to_share,
        // subscription_type
      };

      campaignSetState({
        automatedCampaigns: { ...automatedCampaignDetails, targetInfo },
      });
    } catch (error) {
      console.log('Error Fetching target info from DB ', error);
    }
  };

  useEffect(() => {
    console.log(
      'AUTOMATED CAMPAIGN DETAILS - - - -',
      automatedCampaignDetails,
      'ACTIVE STEP INDEX - - - -',
      get(automatedCampaignDetails, 'activeStepIndex', 'NOT FOUND')
    );
    if (get(automatedCampaignDetails, 'activeStepIndex', '') !== '')
      setCurrentStepIndex(get(automatedCampaignDetails, 'activeStepIndex', 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refetch when currentIndex changes to 0.
  useEffect(() => {
    if (automatedCampaignId && currentStepIndex === 0) fetchTargetInfoFromDB();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  return (
    <Box width={1} paddingBottom="100px">
      <Stepper
        stepperList={AUTOMATED_CAMPAIGN_STEP_LIST}
        selectedIndex={currentStepIndex} // TODO: Replace with REDUX state.
        style={{ marginTop: '50px' }}
      />

      {getFormByIndex(currentStepIndex)}
    </Box>
  );
};

const mapStateToProps = (state) => {
  const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', "false") === "true";
  const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', "false") === "true";
  const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });
  return {
    automatedCampaignDetails: get(state, 'campaign.automatedCampaigns', {}),
    automatedCampaignId: get(
      state,
      'campaign.automatedCampaigns.campaignId',
      0
    ),
    automatedCampaignTargetInfo: get(
      state,
      'campaign.automatedCampaigns.targetInfo',
      0
    ),
    userRole: getUserRole(state),
    compliance: get(state, 'user.data.compliance', ''),
    isEditMode: get(state, 'campaign.isEdit', false),
    landingPagesData: get(state, 'campaign.landingPages', []),
    segmentListData: get(state, 'campaign.segmentList', []),
    isFirmLevelAdmin,
    firmSettings: firmSettings,
    firmsArray: get(state, 'user.data.details.company.firms', [])
  };
};

export default connect(mapStateToProps, null)(AutomatedCampaignsHome);
