import styled from 'styled-components';
import { FileUpload } from '../../../components';
import Switch from 'react-switch';

export const CustomFileUpload = styled(FileUpload)`
  margin-top: 12px;
  button {
    background-color: #eee !important;
    border: 1px solid #a0a0a0 !important;
    color: #6351ed !important;
    padding: 5px 10px;
    font-size: 14px;
    width: 130px;
    margin-top: 10px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px;
  }
`;

export const ConnectMeWrapper = styled.div.attrs({
    className: 'connect-me-wrapper',
  })`
    position: ${({ position }) => position || ''};
    top: ${({ top }) => top || ''};
    right: ${({ right }) => right || ''};
    bottom: ${({ bottom }) => bottom || ''};
    height: ${({ height }) => height || '300px'};
    padding: ${({ padding }) => padding || '15px'};
    width: ${({ width }) => width || '235px'};
    background-color: #fff;
    border: ${({ border }) => border || `1px solid black`};
    .img-wrapper {
      height: 40%;
      width: 100%;
    }
      z-index: 1;
      width: 100%!important;
      height: fit-content;
      bottom: 0;
      right: 0;
      padding: 2%;
      border-top: 2px solid #6351ed ;

      position: static;
      margin-bottom: 10px;
      margin-top: 5px;
  `;
  
  export const ToggleButton = styled(Switch)`
  margin: ${({ margin }) => margin || '10px 0 0'};
  top: ${({ top }) => top || '4px'};
  left: ${({ left }) => left || '5px'};
  .react-switch-bg {
    width: ${({ type }) => type === 'autoPost' ? '24px !important' : '43px !important'};
    height: ${({ type }) => type === 'autoPost'? '15px !important' : '15px !important'};
    top: ${({ type }) => type === 'configure' ? '3px' : '0px'};
  }
  .react-switch-handle {
    top: ${({ type, switchHandleTop }) => switchHandleTop ? switchHandleTop : type === 'autoPost' ? '2.4px !important' : type === 'configure' ? '5px !important' : '1px !important'};
    left: ${({ type }) => type === 'autoPost' ? '-10px' : '-6px'}; 
    transform: ${({ type, checked }) => type === 'autoPost' ? checked ? 'translateX(21px) !important' : 'translateX(12px) !important' : ''};
  }
`;