import { get, isEmpty } from 'lodash';
import apiInstance from '../api';
import { objectToFormData } from '../helpers';

export const getGroupList = () => {
  return apiInstance().get(`/group/?`);
};

export const getMembersList = ({ groupId }) => {
  return apiInstance().get(`/group/${groupId}/member/?`);
};

export const addMemeberToGroup = ({ groupId, uids }) => {
  return apiInstance().post(
    `/group/${groupId}/member/add`,
    objectToFormData({ uids })
  );
};

export const removeMemeberFromGroup = ({ groupId, uids }) => {
  return apiInstance().post(
    `/group/${groupId}/member/remove`,
    objectToFormData({ uids })
  );
};

export const updateGroup = ({ groupId, ...rest }) => {
  const moreApis = [];
  const { addUids, removeUids } = rest;
  const addMember = () => {
    if (!isEmpty(addUids)) {
      return addMemeberToGroup({ groupId, uids: JSON.stringify(rest.addUids) });
    }
    return Promise.resolve();
  };

  const removeMember = () => {
    if (!isEmpty(removeUids)) {
      removeMemeberFromGroup({
        groupId,
        uids: JSON.stringify(rest.removeUids),
      });
    }
    return Promise.resolve();
  };

  moreApis.push(addMember());
  moreApis.push(removeMember());

  return Promise.all([
    apiInstance().post(`/group/${groupId}?`, objectToFormData(rest)),
    moreApis,
  ])
    .then((response) => {
      const updateGroupResponse = response[0];
      const addMemberResponse = response[1];
      // TODO: check for both to be success
      if (
        get(updateGroupResponse, 'result.success') ||
        get(addMemberResponse, 'result.success')
      ) {
        return {
          result: {
            success: true,
          },
        };
      }
      return {
        result: {
          success: false,
        },
      };
    })
    .catch((error) => {
      // TODO: Remove the catch block
      return {
        result: {
          success: true,
        },
      };
    });
};

export const createGroup = (payload) => {
  const { name, disclaimer } = payload;
  return apiInstance()
    .post(`/group/?`, objectToFormData({ name, disclaimer }))
    .then((response) => {
      const groupId = get(response, 'result.data.group_id', '');
      return updateGroup({
        groupId,
        ...payload,
      });
    });
};

export const getContactsGroups = () => {
  return apiInstance().get(`/contactlist`);
};

export const getContactsList = ({
  contactGroup,
  type,
  networkId,
  pagination,
  navigateTo,
  sync,
  sortOptions,
  searchString,
  for_compliance
}) => {
  console.log(pagination);
  let options;
  if (!sortOptions) {
    options = 'asc';
  } else {
    options = sortOptions;
  }
  if(for_compliance) {
    if (pagination && navigateTo)
      return apiInstance().get(
        `/contactlist/${contactGroup}/contact/?for_compliance=true&${navigateTo}=${pagination}&filter={"sort":"${options}"}`
      );
    else
      return apiInstance().get(
        `/contactlist/${contactGroup}/contact/?for_compliance=true&filter={"sort":"${options}"}`
      );
  } else if (type === 'social_media') {
    return apiInstance().get(
      `/contactlist/${contactGroup}/contact/${networkId}/?`
    );
  } else if (sync) {
    return apiInstance().get(
      `/contactlist/${contactGroup}/contact/?sync=${sync}`
    );
  } else if (searchString)
    return apiInstance().get(
      `/contactlist/${contactGroup}/contact/?filter={%22search%22:%22${searchString}%22}`
    );
  else {
    if (pagination && navigateTo)
      return apiInstance().get(
        `/contactlist/${contactGroup}/contact/?${navigateTo}=${pagination}&filter={"sort":"${options}"}`
      );
    else
      return apiInstance().get(
        `/contactlist/${contactGroup}/contact/?filter={"sort":"${options}"}`
      );
  }
};

export const syncContactList = (contactGroup, sync) => {
  return apiInstance().get(
    `/contactlist/${contactGroup}/contact/?sync=${sync}`
  );
};

export const deleteContacts = ({ contactGroup, emailList }) => {
  return apiInstance().delete(
    `/contactlist/${contactGroup}/contact/?emails=["${emailList.join('","')}"]`
  );
};

export const deleteContactGroup = ({ contactGroup }) => {
  return apiInstance().delete(`/contactlist/${contactGroup}/?`);
};

export const deleteMultipleContacts = ({ emailList }) => {
  let payLoad = {
    contactlist_ids: JSON.stringify(emailList),
  };
  return apiInstance().post(`/contactlist/delete`, objectToFormData(payLoad));
};

export const uploadContacts = async ({ contactGroup, name, media1 }) => {
  // const base64File = await toBase64(get(media1, 0));
  const fData = new FormData();
  fData.append('media1', get(media1, 0));
  console.log('from uploadContacts...[CONTACT GROUP] - ', contactGroup);
  return apiInstance()
    .post(`/contactlist/${contactGroup}/contact/?`, fData)
    .then(() => {
      return {
        result: {
          data: {
            id: contactGroup,
          },
        },
      };
    })
    .catch((err) => {
      return { ...err, contactGroup, name };
    });
};

export const createContactGroupfour = (payload) => {
  const fData = new FormData();
  fData.append('media1', get(payload, 0));
  // fData.append('media1', payload);
  return apiInstance().post(`/contactlist/contact`, fData);
};

export const createContactGroup = (payload) => {
  const {
    contactGroup,
    name,
    media1,
    emailRecipients,
    isModal,
    contactType,
    isDefault,
  } = payload;
  console.log('[PAYLOAD 2] - ', payload);
  const emailList = (emailRecipients || []).map(({ email }) => email);
  if (isModal && contactGroup) {
    return uploadContacts({
      contactGroup,
      name,
      media1,
      emailList,
    });
  }

  return apiInstance()
    .post(
      `/contactlist`,
      objectToFormData({
        name,
        description: 'first list',
        is_default: isDefault ? true : false,
        contact_type: contactType,
      })
    )
    .then((response) => {
      const contactGroupId = get(response, 'result.data.id', '');
      if (contactGroupId) {
        return uploadContacts({
          contactGroup: contactGroupId,
          name,
          media1,
          emailList,
        });
      } else {
        throw Error({ msg: 'No id created' });
      }
    });
};

export const emailPersonalize = ({
  themeColor,
  logoUpload,
  logoUploadFile,
  disclaimer,
  signature,
  image_width,
  image_height,
  use_logo,
}) => {
  console.log('logoUploadFile', typeof logoUploadFile);
  console.log(logoUploadFile);
  const fData = new FormData();
  /*if (logoUpload) {
    const logoBlob = new Blob([logoUpload], {
      type: 'image/png',
      encoding: 'utf-8',
    });
    const logoFile = new File([logoBlob], '', {
      type: 'image/png',
      lastModified: new Date(),
    });
    logo = logoUpload;
  }*/
  fData.append('banner_hex_color', themeColor);
  fData.append('footer_text', disclaimer);
  fData.append('signature', signature);
  // console.log('[logoUploadFile', logoUploadFile, '[LENGTH] - ', logoUploadFile.length);
  fData.append('media1', logoUploadFile);
  fData.append('enable', true);

  fData.append('image_width', image_width);
  fData.append('image_height', image_height);
  fData.append('use_logo', use_logo);
  // fData.append('footer_text', ' ');
  //console.log(logo);
  //console.log(fData);
  //return false;
  return apiInstance().post(`/user/email/personalize`, fData);
};

export const getThemeList = () => {
  // console.log('GET THEME LIST FIRED.....');
  return apiInstance().get(
    `/theme/?fields=theme_id,name,description,media_urls,created_time,updated_time`
  );
};

export const getMembers = () => {
  return apiInstance().get(`/company/users?filter={"type":"registered"}`);
};

export const updateProfile = (payload) => {
  const uid = payload.userId;
  const profileData = payload.data;
  let fData = objectToFormData(profileData);
  for (var key of fData.entries()) {
    console.log(key[0] + ', ' + key[1]);
  }
  return apiInstance().post(`/user/${uid}`, objectToFormData(profileData));
};

export const updatePassword = payload => {
  return apiInstance().post('/user/update_password', objectToFormData(payload))
}

export const submitTopic = ({
  suggestedTopic,
  parentTopic,
  subject = 'New Topic Suggestion!',
  emailBody,
}) => {
  let body = suggestedTopic ? `Suggested Topic : ${suggestedTopic}` : emailBody;
  if (parentTopic) {
    body += `, Parent Topic : ${parentTopic}`;
  }
  const toDetails = [
    { name: 'Deepak Singh', email: 'deepak@marketinsightout.com' },
    { name: 'Gaurav', email: 'gaurav@sociosquares.com' },
  ];

  return apiInstance().post(
    `/email`,
    objectToFormData({
      subject,
      body,
      to_json: JSON.stringify(toDetails),
    })
  );
};

export const getLinkedPages = () => {
  return apiInstance().get(`/social/account/?`);
};

export const subscribeLinkedInPage = ({ accountId, snId }) => {
  const data = objectToFormData({
    action: 'subscribe',
    account_ids: JSON.stringify([accountId]),
    snetwork_uid: snId,
  });
  return apiInstance().post(`/social/account/subscribe/`, data);
};

export const redTailLogin = (username, password) => {
  const data = objectToFormData({
    snetwork: 'rt',
    username: username,
    password: password,
  });
  return apiInstance().post(`/social/account/`, data);
};

export const updateContactList = (name, contactId) => {
  const data = objectToFormData({
    name: name,
    is_default: true,
  });
  return apiInstance().post(`/contactlist/${contactId}/?`, data);
};

export const updateComplianceOption = (compliance, advisorsList, companyId, marketing_pref_settings) => {
  console.log('[ADVISORSLIST] - ', advisorsList);
  const data = objectToFormData({
    compliance: compliance,
    ...(!isEmpty(marketing_pref_settings) && { marketing_pref_settings: marketing_pref_settings.marketing_pref_settings }),
    ...(!isEmpty(advisorsList) ? {user: JSON.stringify(advisorsList)} : {}),
  });
  console.log('[DATA] - ', data);
  return apiInstance().post(`/company/${companyId}/settings`, data);
};

export const updateMembersOption = (addMember, companyId) => {
  const data = objectToFormData({
    add_members: addMember,
  });
  return apiInstance().post(`/company/${companyId}/member_settings/`, data);
};
export const updateMemberSettings = (memberSettings, companyId) => {
  const data = objectToFormData({
    disabled_social_platforms: JSON.stringify(memberSettings),
  });
  return apiInstance().post(`/company/${companyId}/member_settings/`, data);
};

export const emailSmtpConnect = (payload) => {
  const data = objectToFormData(payload);
  return apiInstance().post(`/social/account/`, data);
};

export const updateAdvApproval = (companyId, payload) => {
  return apiInstance().post(
    `/company/${companyId}/settings`,
    objectToFormData(payload)
  );
};

export const uploadImageInRichTextEdior = (payload) => {
  const data = objectToFormData({
    media1: payload,
  });

  return apiInstance().post('/user/email/image', data);
};

export const getContactSublistForCrm = (snetwork, import_type) => {
  if(!isEmpty(import_type)) {
    return apiInstance().get(`contactlist/sublists/?crm_name=${snetwork}&sf_import_type=${import_type}`);
  } else {
    return apiInstance().get(`contactlist/sublists/?crm_name=${snetwork}`);
  }
};

export const updateContactSubListForCrm = (payload) => {
  const data = objectToFormData({
    crm_name: payload.crmName,
    list_ids: JSON.stringify(payload.listId),
    contact_types: JSON.stringify(payload.contact_types),
    ...(!isEmpty(payload.sf_import_type) ? {sf_import_type: payload.sf_import_type} : {})
  });

  return apiInstance().post(`contactlist/sublists`, data);
};

export const getComplianceSettingsAdmin = (companyId) => {
  return apiInstance().get(`company/${companyId}/advisors`);
};

export const postAddDomain = (payload) => {
  const data = objectToFormData(payload);
  return apiInstance().post(
    `social/account/emaildeliveryservice/add_domain`,
    data
  );
};

export const postValidateDomain = (payload) => {
  const data = objectToFormData(payload);
  return apiInstance().post(
    `social/account/emaildeliveryservice/validate_domain`,
    data
  );
};

export const updateConnectionStatus = (payload) => {
  const data = objectToFormData(payload);
  return apiInstance().post(`social/account/emaildeliveryservice/status`, data);
};

export const updateContacts = (contactListId, csvFile) => {
  console.log('CONTACTLISTID - ', contactListId, 'CSV FILE - ', csvFile);
  const fData = new FormData();
  fData.append('media1', get(csvFile, 0));
  // const data = objectToFormData(csvFile);
  return apiInstance().post(
    `/contactlist/${contactListId}/contact/update/`,
    fData
  );
};

export const getCRMContactList = () => {
  return apiInstance().get('/contactlist/themes');
};

export const updateUserThemesList = (themes, contactListId) => {
  const data = objectToFormData({
    theme_ids: JSON.stringify(themes),
  });
  return apiInstance().post(
    `/contactlist/${contactListId}/themes/update/`,
    data
  );
};

export const updateFirmSettings = ({payload, id}) => {
  return apiInstance().post(`/user/${id}`, objectToFormData(payload))
}

export const getAddedDomains = (companyId) => {
  return apiInstance().get(`/social/account/emaildeliveryservice/domains?company_id=${companyId}`);
}


