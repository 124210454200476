import { Flex } from '@rebass/grid';
import React from 'react';
import { includes } from 'lodash';

const CampaignPlatforms = ({ platforms, displayMode }) => {
  const socialIcons = [
    {
      name: 'email',
      active: '/assets/images/campaign-platform-email.svg',
      disabled: '/assets/images/drip/email_disabled.svg',
    },
    {
      name: 'facebook',
      active: '/assets/images/campaign-platform-facebook.png',
      disabled: '/assets/images/drip/fb_disabled.png',
    },
    {
      name: 'linkedin',
      active: '/assets/images/campaign-platform-linkedIn.png',
      disabled: '/assets/images/drip/linkedin_disabled.png',
    },
    {
      name: 'twitter',
      active: '/assets/images/campaign-platform-twitter.png',
      disabled: '/assets/images/drip/twitter_disabled.png',
    },
    {
      name: 'zoom',
      active: '/public/assets/images/Zoom.svg',
      },
  ];

  // console.log('platforms', platforms);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      marginLeft={displayMode === 'block' ? '' : '10px'}
      width={displayMode === 'block' ? '' : '12%'}
    >
      <span style={{ color: '#4e6780', fontSize: '12px' }}>Platforms</span>
      <Flex justifyContent="center" alignItems="center" marginTop="8px">
        {/* {platforms.map((platform) => (
          <img
            alt=""
            src={socialIcons[platform]}
            style={{
              marginRight: '10px',
            }}
          />
        ))} */}
        {socialIcons.map((platform) =>
          includes(platforms, platform.name) ? (
            <img
              data-testid={`campaign-platform-${platform.name}`}
              alt=""
              src={
                includes(platforms, platform.name)
                  ? platform.active
                  : platform.disabled
              }
              style={{
                marginRight: '10px',
              }}
            />
          ) : null
        )}
      </Flex>
    </Flex>
  );
};

export default CampaignPlatforms;
