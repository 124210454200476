/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Box } from '@rebass/grid';
import { isEmpty, get, without } from 'lodash';
import React, { useState, useEffect } from 'react';
import { LoaderWrapper, Button } from '../../../components';
import DripDashboardListItem from './DripDashboardListItem/DripDashboardListItem';
import DripDashboardBlockItem from './DripDashboardBlockItem/DripDashboardBlockItem';
import { connect } from 'react-redux';

import { getContactList } from '../../../services/dashboard';
import {
  FilterChips,
  RadioInputLabel,
  RadioInput,
} from './DripDashboardTable.styles';
import { actions as campaignActions } from '../../../redux/modules/campaignDetails.module';
import { Link } from 'react-router-dom';
import AutomatedCampignListItem from './AutomatedCampaigns';

export const typeObj = {
  all: '',
  series: 'Series',
  one_time: 'One Time',
  automated: 'automated',
};

const DripDashboardTable = ({
  data,
  paginationData,
  activeTab,
  isFetching,
  socialThemes,
  setCampaignDetailsView,
  campaignDetailsView,
  campaignsDetailsData,
  // contactGroups,
  socialNetwork,
  editCampaignHandler,
  editLoader,
  filters,
  setFilters,
  campaignRequest,
  campaignDetailsRequest,
  detailsHandler,
  setSelectedCampaignTab,
  campaignData,
  setCategory,
  category,
  selectedCampaignTab,
  campaignsData,
  automatedDashboard,
  addCampaignFlow,
  isadvMode,
  campaignSetState,
  onFilterTabChange,
  forAutomatedCampaigns,
  blockCreate,
  automatedCampaignDetails,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  firmsArray,
  memberAdvisorsArray,
  segmentList,
  seriesSubcampaigns,
  isEnterpriseAdvisor,
}) => {
  const [selectedViewTab, setSelectedViewTab] = useState(1);
  // const [campaignDetailsView, setCampaignDetailsView] = useState(false);
  const [contactGroups, setContactGroups] = useState([]);
  const [totalDataLength, setTotalDataLength] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  const [acPage, setAcPage] = useState(1);
  console.log('CURRENT PAGE - - - - -- ', currentPage);
  console.log('PAGINATED DATA- - - ', paginatedData);

  useEffect(() => {
    if (data) {
      if (selectedCampaignTab === 9) {
        console.log('DATA - - - - -', data);
        setTotalDataLength(get(campaignsData, 'details.approved_to_share', 0));
        setPaginatedData(data.slice(0, 10));
      } else {
        setPaginatedData(data);
        // setPageLimit(
        //   category === 'automated'
        //     ? get(campaignsData, 'automated_campaigns_total_records', 0)
        //     : 10
        // );
        setTotalDataLength(
          category === 'automated'
            ? get(campaignsData, 'automated_campaigns_total_records', 0)
            : get(campaignsData, 'details.total_records', 0)
        );
      }
      // if(activeTab)
      //   setTotalDataLength(campaignDetails[activeTab.tab]);
      // else if(!isEmpty(get(filters, 'status'))) {
      //   let totalCount = 0;
      //   filters.status.forEach(status => totalCount+=campaignDetails[status]);
      //   setTotalDataLength(totalCount);
      // }
    }
  }, [data]);

  useEffect(() => setCurrentPage(1), [activeTab]);

  useEffect(() => {
    getContactList()
      .then((res) => {
        const data = get(res, 'result.data', []).filter(
          (c) =>
            ![
              'GMAIL',
              'HUBSPOT',
              'OUTLOOK',
              'WEALTHBOX',
              'SALESFORCE',
              'REDTAIL',
            ].includes(get(c, 'name')) && get(c, 'contacts.count', 0) > 0
        );
        setContactGroups(data);
      })
      .catch((err) => {
        setContactGroups([]);
      });
  }, []);

  const viewMode = [
    {
      name: 'Block',
      img: {
        active: '/assets/images/drip/apps_white.svg',
        disabled: '/assets/images/drip/apps.svg',
      },
    },
    {
      name: 'List',
      img: {
        active: '/assets/images/drip/format_list_bulleted_white.svg',
        disabled: '/assets/images/drip/format_list_bulleted.svg',
      },
    },
  ];

  const handleFilterClick = (category, value) => {
    let fArray = without(filters[category], value);
    let modifiedFilters = filters;
    modifiedFilters[category] = fArray;

    if (
      category === 'all' ||
      (isEmpty(modifiedFilters?.status) &&
        isEmpty(modifiedFilters?.duration) &&
        isEmpty(modifiedFilters?.frequency) &&
        isEmpty(modifiedFilters?.contact_types) &&
        isEmpty(modifiedFilters?.platform) &&
        isEmpty(modifiedFilters?.type) &&
        isEmpty(modifiedFilters?.webinar))
    ) {
      setFilters();
      campaignRequest();
      setSelectedCampaignTab(0);
      return;
    } else {
      setFilters({ ...modifiedFilters });
    }
  };

  let statusObj = {
    draft: 'Drafts',
    scheduled: 'Scheduled',
    in_progress: 'In Progress',
    completed: 'Completed',
    approval_rejected: 'Approval Rejected',
    approval_pending: 'Approval Pending',
    compliance_rejected: 'Compliance Rejected',
    compliance_pending: 'Compliance Pending',
    approve_to_share: 'Approved To Share',
  };
  let durationObj = { '["4","weeks"]': '4 Weeks', '["8","weeks"]': '8 Weeks' };
  let frequencyObj = {
    '["every","week"]': 'Every week',
    '["other","week"]': 'Every other week',
  };
  let contactTypesObj = { client: 'Clients', prospect: 'Prospects' };
  let platformObj = {
    email: 'Email',
    facebook: 'Facebook',
    twitter: 'Twitter',
    linkedin: 'Linkedin',
  };

  let webinarObj = {
    yes: 'Yes Webinar',
  };

  const handlePagination = (mode) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    let td = data;
    let filteredData = [];
    console.log('ACTIVE TAB - - - - - ', activeTab);
    const tabFilter =
      activeTab && activeTab.id !== 0
        ? { status: [activeTab.tab] }
        : category
        ? { type: [category] }
        : {};

    if (mode === 'next') {
      if (selectedCampaignTab === 9) {
        filteredData = td.slice(
          currentPage * pageLimit,
          currentPage * pageLimit + pageLimit
        );
        setPaginatedData(filteredData);
      } else
        campaignRequest({
          next: paginationData.after,
          ...tabFilter,
          ...filters,
        });

      setCurrentPage(currentPage + 1);
    } else if (mode === 'prev') {
      if (selectedCampaignTab === 9) {
        filteredData = td.slice(
          (currentPage - 2) * pageLimit,
          (currentPage - 2) * pageLimit + pageLimit
        );
        setPaginatedData(filteredData);
      } else
        campaignRequest({
          prev: paginationData.before,
          ...tabFilter,
          ...filters,
        });

      setCurrentPage(currentPage - 1);
    }
  };

  const onCategoryChange = (type) => {
    let tab = '';
    setCurrentPage(1);

    if(type === '') {
      setAcPage(1);
    }

    if (selectedCampaignTab === 0) {
      tab = '';
    } else if (selectedCampaignTab === 1) {
      tab = 'draft';
    } else if (selectedCampaignTab === 2) {
      tab = 'approval_pending';
    } else if (selectedCampaignTab === 3) {
      tab = 'approval_rejected';
    } else if (selectedCampaignTab === 4) {
      tab = 'compliance_pending';
    } else if (selectedCampaignTab === 5) {
      tab = 'compliance_rejected';
    } else if (selectedCampaignTab === 6) {
      tab = 'scheduled';
    } else if (selectedCampaignTab === 7) {
      tab = 'in_progress';
    } else if (selectedCampaignTab === 8) {
      tab = 'completed';
    } else if (selectedCampaignTab === 9) {
      setCategory(type);
      if (type === 'automated') {
        return campaignRequest('ats_automated');
      } else return campaignRequest('approved_to_share');
    }

    setCategory(type);

    if (type === '' && tab === '') {
      campaignRequest();
      return;
    }

    if (tab === '' && type !== '') {
      if(type === 'automated') {
        campaignRequest({ 
          type: [type],
          limit: 10,
          offset: 1 
        });
      } else {
        campaignRequest({ type: [type] });
      }
    } else if (type === '' && tab !== '') {
      campaignRequest({ status: [tab] });
    } else {
      if(type === 'automated') {
        campaignRequest({ 
          type: [type],
          limit: 10,
          offset: 1
        });
      } else {
        campaignRequest({ type: [type], status: [tab] });
      }
    }
  };

  const handleACPagination = (type) => {
    let page = acPage;
    if(type === 'next') {
      page = page + 1;
    } else if(type === 'prev' && page > 1) {
      page = page - 1;
    }

    setAcPage(page);

    campaignRequest({ 
      type: ['automated'],
      limit: 10,
      offset: page 
    });
  }

  const getFilters = () => {
    if (filters) {
      return filters;
    } else {
      let tab = '';

      if (selectedCampaignTab === 0) {
        tab = '';
      } else if (selectedCampaignTab === 1) {
        tab = 'draft';
      } else if (selectedCampaignTab === 2) {
        tab = 'approval_pending';
      } else if (selectedCampaignTab === 3) {
        tab = 'approval_rejected';
      } else if (selectedCampaignTab === 4) {
        tab = 'compliance_pending';
      } else if (selectedCampaignTab === 5) {
        tab = 'compliance_rejected';
      } else if (selectedCampaignTab === 6) {
        tab = 'scheduled';
      } else if (selectedCampaignTab === 7) {
        tab = 'in_progress';
      } else if (selectedCampaignTab === 8) {
        tab = 'completed';
      }

      if (category === '' && tab === '') {
        return;
      }

      if (category === '' && tab !== '') {
        return { status: [tab] };
      } else if (tab === '' && category !== '') {
        return { type: [category] };
      } else {
        return { type: [category], status: [tab] };
      }
    }
  };

  return (
    <div style={{ padding: '0px 50px' }}>
      <LoaderWrapper isLoading={isFetching} loaderHeight="700px">
        <Flex display="flex" flexDirection="row">
          <Flex
            // flex="30%"
            // display="flex"
            width={campaignDetailsView ? '70%' : '100%'}
            flexDirection="column"
          >
            {selectedCampaignTab !== 9 && (
              <Link
                to="/campaignV2?type=create"
                style={{ alignSelf: 'flex-end' }}
              >
                <Button borderRadius="5px" padding="7px 50px">
                  Create
                </Button>
              </Link>
            )}
            <Flex
              justifyContent="space-between"
              alignItems="center"
              padding="20px 0px"
              // width={campaignDetailsView ? '38%' : ''}
              style={{ fontSize: '12px' }}
            >
              {/* {(!filters || campaignDetailsView) && (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="row"
                  display="flex"
                >
                  {campaignDetailsView && (
                    <>
                      <img
                        src="/assets/images/drip/chevron_left.svg"
                        alt=""
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setCampaignDetailsView(false);
                        }}
                      />
                    </>
                  )}

                  <span style={{ color: '#000000' }}>
                    {activeTab?.name ? activeTab?.name : 'Filters'}
                  </span>
                  {campaignDetailsView && (
                    <span
                      style={{
                        color: '#000000',
                        backgroundColor: '#ffffff',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        marginLeft: '5px',
                        fontSize: '14px',
                      }}
                    >
                      {data.length}
                    </span>
                  )}
                </Flex>
              )} */}
              {filters && !campaignDetailsView && (
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  flexWrap="wrap"
                  width="60vw"
                  data-testid="campaign_filterChips"
                >
                  {!isEmpty(filters.status) &&
                    filters.status.map((item, key) => {
                      return (
                        <FilterChips>
                          {statusObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('status', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}
                  {!isEmpty(filters.duration) &&
                    filters.duration.map((item, key) => {
                      return (
                        <FilterChips>
                          {durationObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('duration', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}
                  {!isEmpty(filters.frequency) &&
                    filters.frequency.map((item, key) => {
                      return (
                        <FilterChips>
                          {frequencyObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('frequency', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}

                  {!isEmpty(filters.contact_types) &&
                    filters.contact_types.map((item, key) => {
                      return (
                        <FilterChips>
                          {contactTypesObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('contact_types', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}
                  {!isEmpty(filters.platform) &&
                    filters.platform.map((item, key) => {
                      return (
                        <FilterChips>
                          {platformObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('platform', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}
                  {!isEmpty(filters.type) &&
                    filters.type.map((item, key) => {
                      return (
                        <FilterChips>
                          {typeObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('type', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}
                  {!isEmpty(filters.webinar) &&
                    filters.webinar.map((item, key) => {
                      return (
                        <FilterChips>
                          {webinarObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('webinar', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}
                  <FilterChips
                    onClick={() => {
                      handleFilterClick('all');
                    }}
                    bgColor="#6250ed"
                    color="#fff"
                    style={{ cursor: 'pointer' }}
                  >
                    Clear All
                  </FilterChips>
                </Flex>
              )}
              {isEmpty(filters) && (
                <Flex justifyContent="center" alignItems="center">
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    marginRight="5px"
                  >
                    <RadioInput
                      data-testid="radio-type-all"
                      type="radio"
                      id="fd_campaign_all"
                      name="fd_campaign"
                      onClick={() => {
                        onCategoryChange('');
                      }}
                      checked={category === ''}
                      style={{ cursor: 'pointer' }}
                    />
                    <RadioInputLabel
                      htmlFor="fd_campaign_all"
                      style={{ cursor: 'pointer' }}
                    >
                      {/* All */}
                      One Time & Series
                    </RadioInputLabel>
                  </Flex>
                  {/*                  
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    marginRight="5px"
                  >
                    <RadioInput
                      data-testid="radio-type-onetime"
                      type="radio"
                      id="fd_campaign_one_time"
                      name="fd_campaign"
                      onClick={() => {
                        onCategoryChange('one_time');
                      }}
                      checked={category === 'one_time'}
                      style={{ cursor: 'pointer' }}
                    />
                    <RadioInputLabel
                      htmlFor="fd_campaign_one_time"
                      style={{ cursor: 'pointer' }}
                    >
                      One Time
                    </RadioInputLabel>
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    marginRight="5px"
                  >
                    <RadioInput
                      data-testid="radio-type-series"
                      type="radio"
                      id="fd_campaign_series"
                      name="fd_campaign"
                      onClick={() => {
                        onCategoryChange('series');
                      }}
                      checked={category === 'series'}
                      style={{ cursor: 'pointer' }}
                    />
                    <RadioInputLabel
                      htmlFor="fd_campaign_series"
                      style={{ cursor: 'pointer' }}
                    >
                      Series
                    </RadioInputLabel>
                  </Flex> */}

                  {/* TODO: Comment Out, once API works for Automated Campaigns */}
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    marginRight="5px"
                  >
                    <RadioInput
                      data-testid="radio-type-automated"
                      type="radio"
                      id="fd_campaign_automated"
                      name="fd_campaign"
                      onClick={() => {
                        onCategoryChange(typeObj.automated);
                      }}
                      checked={category === typeObj.automated}
                      style={{ cursor: 'pointer' }}
                    />
                    <RadioInputLabel
                      htmlFor="fd_campaign_automated"
                      style={{ cursor: 'pointer' }}
                    >
                      Automated
                    </RadioInputLabel>
                  </Flex>
                </Flex>
              )}
              {/* <Flex justifyContent="center" alignItems="center">
                {statusArray.map((status, index) => (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      marginRight: '10px',
                      fontSize: '12px',
                      color: '#4E6780',
                    }}
                  >
                    <StatusBubble status={status} />
                    {status}
                  </Flex>
                ))}
                {!campaignDetailsView && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      marginRight: '10px',
                      fontSize: '12px',
                      color: '#4E6780',
                    }}
                  >
                    <Button
                      styles={{
                        border: '1px solid #4E6780',
                        borderRadius: '5px 0px 0px 5px',
                      }}
                      color="blue"
                      bgColor={selectedViewTab === 0 ? '#4E6780' : '#ffffff'}
                      margin="0px"
                      padding="4px 6px 0px 6px"
                      onClick={() => setSelectedViewTab(0)}
                    >
                      <img
                        src={
                          selectedViewTab === 0
                            ? viewMode[0].img.active
                            : viewMode[0].img.disabled
                        }
                        alt=""
                      />
                    </Button>
                    <Button
                      styles={{
                        border: '1px solid #4E6780',
                        borderRadius: '0px 5px 5px 0px',
                      }}
                      color="blue"
                      bgColor={selectedViewTab === 1 ? '#4E6780' : '#ffffff'}
                      margin="0px"
                      padding="4px 6px 0px 6px"
                      onClick={() => setSelectedViewTab(1)}
                    >
                      <img
                        src={
                          selectedViewTab === 1
                            ? viewMode[1].img.active
                            : viewMode[1].img.disabled
                        }
                        alt=""
                      />
                    </Button>
                  </Flex>
                )}
              </Flex> */}
            </Flex>
            {isEmpty(data) && !isFetching && category === '' && (
              <span>No Campaigns to display</span>
            )}

            {isEmpty(data) && !isFetching && category === 'automated' && (
              <span>No Automated Campaigns to display</span>
            )}

            {!isEmpty(data) &&
            selectedViewTab === 1 &&
            category !== typeObj.automated
              ? paginatedData.map((item, index) => {
                  return (
                    <DripDashboardListItem
                      {...item}
                      socialThemes={socialThemes}
                      isFirst={index === 0}
                      campaignDetailsView={campaignDetailsView}
                      setDetailsView={setCampaignDetailsView}
                      campaignDetailsRequest={campaignDetailsRequest}
                      socialNetworkData={socialNetwork.data}
                      detailsHandler={detailsHandler}
                      editLoader={editLoader}
                      campaignRequest={campaignRequest}
                      addCampaignFlow={addCampaignFlow}
                      filters={filters}
                      selectedCampaignTab={selectedCampaignTab}
                      category={category}
                      setFilters={setFilters}
                      setSelectedCampaignTab={setSelectedCampaignTab}
                      isadvMode={isadvMode}
                      campaignSetState={campaignSetState}
                      onFilterTabChange={onFilterTabChange}
                      blockCreate={blockCreate}
                      segmentList={segmentList}
                      seriesSubcampaigns={seriesSubcampaigns}
                      isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
                      isFirmLevelAdmin={isFirmLevelAdmin}
                      firmSettings={firmSettings}
                      firmsArray={firmsArray}
                      memberAdvisorsArray={memberAdvisorsArray}
                      item={item}
                      paginatedData={paginatedData}
                    />
                  );
                })
              : paginatedData.map((item, index) => (
                  <AutomatedCampignListItem
                    {...item}
                    isFirst={index === 0}
                    campaignRequest={campaignRequest}
                    category={category}
                    campaignSetState={campaignSetState}
                    automatedCampaignDetails={automatedCampaignDetails}
                    onFilterTabChange={onFilterTabChange}
                    blockCreate={blockCreate}
                    isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
                    isEnterpriseAdvisor={isEnterpriseAdvisor}
                    isFirmLevelAdmin={isFirmLevelAdmin}
                    firmSettings={firmSettings}
                    firmsArray={firmsArray}
                    memberAdvisorsArray={memberAdvisorsArray}
                    segmentList={segmentList}
                    filters={getFilters()}
                    selectedCampaignTab={selectedCampaignTab}
                    setSelectedCampaignTab={setSelectedCampaignTab}
                    detailsHandler={detailsHandler}
                  />
                ))}

            {selectedViewTab === 0 && (
              <Flex flexWrap="wrap" mx={-2}>
                {!isEmpty(data) &&
                  selectedViewTab === 0 &&
                  paginatedData.map((item, index) => (
                    <Box px={1} py={2} width={1 / 5}>
                      <DripDashboardBlockItem
                        {...item}
                        socialThemes={socialThemes}
                        isFirst={index === 0}
                        setSelectedViewTab={setSelectedViewTab}
                        setDetailsView={setCampaignDetailsView}
                        campaignDetailsRequest={campaignDetailsRequest}
                        socialNetworkData={socialNetwork.data}
                        detailsHandler={detailsHandler}
                        editLoader={editLoader}
                        campaignRequest={campaignRequest}
                        addCampaignFlow={addCampaignFlow}
                      />
                    </Box>
                  ))}
              </Flex>
            )}
            {data && data.length > 0 && (
              <Flex>
                {category === typeObj.automated &&
                activeTab.name === statusObj.approve_to_share ? (
                  ''
                ) : category === typeObj.automated ? (
                  <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  paddingBottom="10px"
                  width={'100%'}
                >
                  <Button
                    testId="campaign-pagination-prev"
                    onClick={() => handleACPagination('prev')}
                    // disabled={!get(paginationData, 'before', null)}
                    disabled={acPage === 1}
                  >
                    Previous
                  </Button>
                  <span style={{ paddingTop: '15px' }}>
                  {(acPage - 1) * pageLimit + 1} -{' '}
                      {acPage * pageLimit < totalDataLength
                        ? acPage * pageLimit
                        : totalDataLength}{' '}
                      of {totalDataLength}
                  </span>
                  <Button
                    testId="campaign-pagination-next"
                    onClick={() => handleACPagination('next')}
                    disabled={(acPage * pageLimit) >= totalDataLength}
                    // disabled={currentPage * pageLimit >= totalDataLength}
                  >
                    Next
                  </Button>
                </Flex>
                ) : (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    paddingBottom="10px"
                    width={'100%'}
                  >
                    <Button
                      testId="campaign-pagination-prev"
                      onClick={() => handlePagination('prev')}
                      // disabled={!get(paginationData, 'before', null)}
                      disabled={currentPage <= 1}
                    >
                      Previous
                    </Button>
                    <span style={{ paddingTop: '15px' }}>
                      {(currentPage - 1) * pageLimit + 1} -{' '}
                      {currentPage * pageLimit < totalDataLength
                        ? currentPage * pageLimit
                        : totalDataLength}{' '}
                      of {totalDataLength}
                    </span>
                    <Button
                      testId="campaign-pagination-next"
                      onClick={() => handlePagination('next')}
                      disabled={
                        selectedCampaignTab === 9
                          ? currentPage * pageLimit >= totalDataLength
                          : !get(paginationData, 'after', null)
                      }
                      // disabled={currentPage * pageLimit >= totalDataLength}
                    >
                      Next
                    </Button>
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
          {/* {campaignDetailsView && (
            <DripDashboardDetails
              info={campaignsDetailsData}
              contactGroups={contactGroups}
              socialNetwork={socialNetwork}
              socialThemes={socialThemes}
              campaignsDetailsData={campaignsDetailsData}
              editCampaignHandler={editCampaignHandler}
              editLoader={editLoader}
              detailsHandler={detailsHandler}
              campaignData={campaignData}
            />
          )} */}
        </Flex>
      </LoaderWrapper>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    campaignsDetailsData: state.campaignDetails,
    contactGroups: state.contacts.contactGroups,
    socialNetwork: state.socialNetwork,
    campaignData: state.campaign,
    automatedCampaignDetails: get(state, 'campaign.automatedCampaigns', {}),
    forAutomatedCampaigns: get(state, 'campaign.forAutomatedCampaigns', false),
  };
};

const mapDispatchToProps = {
  campaignDetailsRequest: campaignActions.api.campaignDetails.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(DripDashboardTable);
