import React from 'react';
import withAppLayout from '../../hoc/withAppLayout';

import {
  AdvisorConfigureContainer,
  AdminConfigureContainer,
} from '../../containers';

const AdvisorConfigureLayout = (props) => <AdvisorConfigureContainer {...props}/>;

const AdminConfigureLayout = () => <AdminConfigureContainer />;

export const AdvisorConfigure = withAppLayout(AdvisorConfigureLayout);

export const AdminConfigure = withAppLayout(AdminConfigureLayout);
