/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import { get, isEmpty, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NewsletterArticle } from '../../NewsletterContainer/NewsletterArticle';
import { ArticlesContainer } from './AddContent.styles';
import { CampaignTab } from '../../DripCampaignContainer/SetTopics/SetTopics.styles';
import { LoaderWrapper } from '../../../components';
import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import SelectedContent from './SelectedContent';
import { CreateContainer } from '../CreateCampaign/CreateCampaign.styles';
import { FooterBtnsWrapper } from '../DripCampaignContainerV2.styles';
import { useHistory } from 'react-router-dom';
import { getExploreArticleData } from '../../../utils';

const AddContent = ({
  themeNames,
  handleBack,
  handleNext,
  isPremiumAdvisor,
  campaignSetState,
  curFlow,
  endCreateFlow,
  selectedArticles,
  setSelectedArticles,
  bookmarkArticles,
  recommendedArticles,
  myContentArticles,
  bookmarkArticlesLoader,
  recommendedArticlesLoader,
  myArticlesLoader,
  recommendedFetchedData,
  conListRecArticlesLoader,
  setObjectivesTabData,
  predefinedArticle
}) => {
  const suggestedArticlesLabels = ['BOOKMARKED', 'RECOMMENDED', 'MY CONTENT'];
  const [activeSuggestedArticlesTab, setActiveSuggestedArticlesTab] =
    useState(0);
  const [loading, setLoading] = useState(false);
  const [newsletterArticles, setNewsletterArticles] = useState([]);
  const [dragId, setDragId] = useState('');
  const [isSelectContent, setIsSelectContent] = useState(true);
  const [tabLoader, setTabLoader] = useState(false);
  const history = useHistory();
  const [predefinedContent, setPredefinedContent] = useState(null)

  const filteredArticlesHandler = (articles) => {
    if (curFlow === 'emailOnly' && articles) {
      const filteredArticles = articles.filter((article) => {
        if (article.auxiliary) {
          if (article.auxiliary.source && article.auxiliary.source === 'img') {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });
      // console.log(filteredArticles);
      return filteredArticles;
    }
    return articles;
  };

  useEffect(() => {
    if(predefinedArticle){
      setPredefinedContent(predefinedArticle)
    }
    if (activeSuggestedArticlesTab === 0) {
      //     setLoading(true);
      //     getBookmarkedArticles()
      //       .then((res) => {
      //         setLoading(false);
      //         if (res.result && res.result.data && res.result.data.feed) {
      //           let allBookMarkedArticles = getExploreArticleData(res.result.data);
      //           if (allBookMarkedArticles.length > 0) {
      setNewsletterArticles(filteredArticlesHandler(bookmarkArticles) || []);
      //           } else {
      //             setNewsletterArticles([]);
      //           }
      //         }
      //       })
      //       .catch((err) => {
      //         setLoading(false);
      //         console.log(err);
      //       });
    } else if (activeSuggestedArticlesTab === 1) {
      //     setLoading(true);
      //     getRecommendedArticles()
      //       .then((res) => {
      //         setLoading(false);
      //         if (res.result && res.result.data && res.result.data.feed) {
      //           let allRecommendedArticles = getExploreArticleData(res.result.data);
      //           if (allRecommendedArticles.length > 0) {
      if (Object.keys(get(recommendedFetchedData, 'data', {})).length > 0) {
        const recommendedArticlesData = getExploreArticleData(
          get(recommendedFetchedData, 'data', {})
        );
        setNewsletterArticles(
          filteredArticlesHandler(recommendedArticlesData) || []
        );
      } else {
        setNewsletterArticles(
          filteredArticlesHandler(recommendedArticles) || []
        );
      }

      //           } else {
      //             setNewsletterArticles([]);
      //           }
      //         }
      //       })
      //       .catch((err) => {
      //         setLoading(false);
      //         console.log(err);
      //       });
    } else {
      //     if (isPremiumAdvisor) {
      //       setLoading(true);
      //       getPremiumAdvisorChannelId()
      //         .then((res) => {
      //           // console.log('RESPONSE ');
      //           if (res && res.result && res.result.data) {
      //             const channel_id =
      //               res.result.data[Object.keys(res.result.data)[0]][0].id;
      //             getContentLibrary(channel_id)
      //               .then((res) => {
      //                 setLoading(false);
      //                 if (
      //                   res.result &&
      //                   res.result.data &&
      //                   res.result.data.channel_content
      //                 ) {
      //                   console.log(res);
      //                   let allMyContentArticles = getContentLibArticleData(
      //                     res.result.data
      //                   );
      //                   if (allMyContentArticles.length > 0) {
      setNewsletterArticles(filteredArticlesHandler(myContentArticles) || []);
      //                   } else {
      //                     setNewsletterArticles([]);
      //                   }
      //                 }
      //               })
      //               .catch((err) => {
      //                 setLoading(false);
      //                 console.log(err);
      //               });
      //           }
      //         })
      //         .catch((err) => {
      //           setLoading(false);
      //           console.log('ERROR - - - - ', err);
      //         });
      //     }
    }
  }, [activeSuggestedArticlesTab]);

  useEffect(() => {
    if (activeSuggestedArticlesTab === 0)
      setNewsletterArticles(filteredArticlesHandler(bookmarkArticles) || []);
    else if (activeSuggestedArticlesTab === 1) {
      if (Object.keys(get(recommendedFetchedData, 'data', {})).length > 0) {
        const recommendedArticlesData = getExploreArticleData(
          get(recommendedFetchedData, 'data', {})
        );
        setNewsletterArticles(
          filteredArticlesHandler(recommendedArticlesData) || []
        );
      } else {
        setNewsletterArticles(
          filteredArticlesHandler(recommendedArticles) || []
        );
      }
    } else
      setNewsletterArticles(filteredArticlesHandler(myContentArticles) || []);
  }, [
    bookmarkArticles,
    recommendedArticles,
    myContentArticles,
    recommendedFetchedData,
  ]);

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    let dragBox = selectedArticles.find((article) => article.id === dragId);

    let dropBox = selectedArticles.find(
      (article) => article.id === ev.currentTarget.id
    );
    let dragBoxOrder = dragBox.order;
    let dropBoxOrder = dropBox.order;

    let newBoxState = selectedArticles.map((box) => {
      if (box.id === dragId) {
        box.order = dropBoxOrder;
      }
      if (box.id === ev.currentTarget.id) {
        box.order = dragBoxOrder;
      }
      return box;
    });

    setSelectedArticles([...newBoxState]);
  };

  const handleBucketArticleClick = (item) => {
    console.log('BUCKET ARTICLE CLICKED -');
    setIsSelectContent(true);
    if (isEmpty(selectedArticles)) {
      item.order = 1;
      setSelectedArticles([item]);
    } else if (
      !isEmpty(selectedArticles) &&
      selectedArticles.filter((n) => n.id === item.id).length > 0
    ) {
      const articles = selectedArticles.filter((n) => n.id !== item.id);
      setSelectedArticles([...articles]);
    } else {
      item.order = selectedArticles.length + 1;
      setSelectedArticles([...selectedArticles, item]);
    }
  };

  const handleBackButtonClick = () => {
    handleBack();
    // endCreateFlow();
  };

  const handleValidation = () => {
    return isEmpty(selectedArticles);
  };

  const handleSaveAndClose = () => {
    if (curFlow !== 'socialOnly') {
      campaignSetState({ selectedArticles: selectedArticles });
    }
    endCreateFlow();
    history.push('/campaignV2');
  };

  const timeout = () => {
    setTabLoader(true);
    setTimeout(function () {
      setTabLoader(false);
    }, 1000);
  };

  return (
    <Flex>
      <CreateContainer
        mt="0px"
        style={{ justifyContent: 'flex-start', position: 'relative' }}
      >
        <Flex width="750px" flexDirection="column">
          <Flex justifyContent="center">
            {suggestedArticlesLabels.map((label, idx) => (
              <CampaignTab
                color="#49484a"
                padding="5px 20px 6px"
                className={
                  activeSuggestedArticlesTab === idx ? 'active' : 'inactive'
                }
                width="25%"
                style={{ listStyle: 'none', fontWeight: '600' }}
                onClick={() => {
                  setActiveSuggestedArticlesTab(idx);
                  timeout();
                }}
              >
                {label}
              </CampaignTab>
            ))}
          </Flex>
          {/* <p
            style={{ color: '#c2c8cc', fontSize: '0.7rem', margin: '20px 0px' }}
          >
            You can drag and drop tiles to change the order that the content
            will appear in the newsletter.
          </p> */}
          <LoaderWrapper
            isLoading={
              loading ||
              tabLoader ||
              (activeSuggestedArticlesTab === 0 && bookmarkArticlesLoader) ||
              (activeSuggestedArticlesTab === 1 &&
                (recommendedArticlesLoader || conListRecArticlesLoader)) ||
              (activeSuggestedArticlesTab === 2 && myArticlesLoader)
            }
          >
            {console.log('ARTICLES - - - ', newsletterArticles)}
            {newsletterArticles && newsletterArticles.length !== 0 ? (
              <ArticlesContainer
                width="100%"
                justifyContent="flex-start"
                flexWrap="wrap"
                mt="40px"
              >
                {newsletterArticles.map((item, index) => (
                  <NewsletterArticle
                    key={index}
                    articleIndex={index}
                    margin="10px 23px 10px 0px"
                    {...item}
                    themeNames={themeNames}
                    // checkbox={
                    //   curFlow === 'socialOnly' && selectedArticles.length === 1
                    //     ? false
                    //     : true
                    // }
                    isFromCampaign={true}
                    article={item}
                    handleBucketArticleClick={handleBucketArticleClick}
                    newsletterArticles={selectedArticles}
                    deleteBlocked
                    isCampaign={true}
                    oneSelectMode={
                      curFlow === 'socialOnly' && selectedArticles.length === 1
                    }
                    activeSuggestedArticlesTab={activeSuggestedArticlesTab}
                  />
                ))}
                {newsletterArticles.length % 3 !== 0 && (
                  <i aria-hidden="true"></i>
                )}
              </ArticlesContainer>
            ) : (
              <div style={{ width: '100%', top: '50%', position: 'absolute' }}>
                No Content to show
              </div>
            )}
          </LoaderWrapper>
        </Flex>
      </CreateContainer>
      {/* {selectedArticles.length > 0 && isSelectContent && ( */}
      <CreateContainer
        mt="0px"
        ml="20px"
        style={{
          justifyContent: 'flex-start',
          padding: '0px',
          boxShadow: '2px 3px 10px rgb(0,0,0,0.2)',
          overflow: 'hidden',
        }}
      >
        <SelectedContent
          selectedArticles={selectedArticles}
          setIsSelectContent={setIsSelectContent}
          setSelectedArticles={setSelectedArticles}
          handleDrag={handleDrag}
          handleDrop={handleDrop}
          predefinedContent={predefinedContent}
        />
      </CreateContainer>
      {/* )} */}
      <FooterBtnsWrapper>
        <CampaignFooterButtons
          enableNext={!handleValidation()}
          // enableNext={true}
          nextHandler={() => handleNext()}
          backHandler={handleBackButtonClick}
          loading={loading}
          saveAndCloseHandler={handleSaveAndClose}
          padding="10px 0px"
        />
      </FooterBtnsWrapper>
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  themeNames: state.theme.themeNames,
  contentLibArticles: state.contentlib.data,
  bookmarkArticles: state.campaign.bookmarkArticles,
  recommendedArticles: state.campaign.recommendedArticles,
  myContentArticles: state.campaign.myContentArticles,
  recommendedFetchedData: state.campaign.recommendedFetchedData,
});

export default connect(mapStateToProps, null)(AddContent);
