/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Flex, Box } from '@rebass/grid';
import { get, isEmpty, find, map } from 'lodash';
import { parse } from 'json2csv';
import qs from 'querystring';
import {
  CustomizeEmailModal,
  ButtonNoBorderBackground,
  ErrorContainer,
  ErrorModal,
  LoaderWrapper,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ResponsiveImg,
} from '../../../components';
import CreateEmailListModal from '../../../components/CreateEmailListModal/V2/CreateEmailListModal';
import AddEmailRecipients from '../../../components/AddEmailRecipients/V2/AddEmailRecipients';
import {
  IconImage,
  Icon,
  InlineBlock,
} from '../../../components/DigitalPlatforms/DigitalPlatforms';
import EmailListTable from './EmailListTable';
import { HoverButton } from './EmailList.styles';
import {
  getContactsGroups,
  updateContactList,
  redTailLogin,
} from '../../../services/configure';
import { getSocialAccountURLs } from '../../../services/dashboard';
import RedTailModal from '../RedTailModal/RedTailModal';
import {
  faGoogle,
  faSalesforce,
  faHubspot,
} from '@fortawesome/free-brands-svg-icons';

import ContactListModal from '../ContactListModal';
import CreateEmailListModalV1 from '../../../components/CreateEmailListModal';
import SegmentContactListModal from '../../../components/SegmentModal/SegmentContactListModal/SegmentContactListModal';
//Segment Creation Imports
import {
  TabFour,
  TabsThree,
  TabListThree,
} from '../../../components/Tabs/TabsTypeThree';
import SegmentTable from './SegmentTable';

import {getSegments} from '../../../services/segment';
import DisconnectModal from './DisconnectModal';

const socialIcons = [
  { id: 'hs', platform: 'hs', icon: faHubspot, name: 'HubSpot' },

  { id: 'sf', platform: 'sf', icon: faSalesforce, name: 'Salesforce' },

  {
    id: 'wb',
    platform: 'wb',
    src: '/assets/images/wealthbox-logo-light.png',
    name: 'WealthBox',
  },
  {
    id: 'rt',
    platform: 'rt',
    src: '/assets/images/redtail_logo.png',
    name: 'RedTail',
  },
  {
    id: 'go',
    platform: 'em',
    emailPlatform: 'google',
    icon: faGoogle,
    name: 'Google',
  },
  {
    id: 'ol',
    platform: 'em',
    emailPlatform: 'outlook',
    src: '/assets/images/outlook-disconnected-32.png',
    name: 'Outlook',
  },
];

const EmailLIst = ({
  contactError,
  contactsList,
  contactGroupsList,
  contactListChange,
  onContactDelete,
  contactsCreate,
  setIsUpdateContact,
  isContactsFetching,
  selectedContactGroup,
  socialAccountURL,
  socialNetworkData,
  retrieveGroupsList,
  userData,
  userId,
  loginRequest,
  contactsRequest,
  paginationData,
  navigateAfter,
  socialNetworkStatusRequest,
  selectedContactGroupDefault,
  socialNetworkDataFetching,
  socialNetworkDelete,
  setSocialAccountURL,
  isAdmin,
  customContactGroupList,
  contactsCount,
  contactGroups,
}) => {
  const [openCustomizeEmail, setCustomizeEmailOpen] = useState(false);
  const [contactErrorMessage, setContactError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isContactCreate, setIsContactCreate] = useState(false);
  const [openEmailRecipientsModalOpen, setEmailRecipientsModalOpen] =
    useState(false);
  const [errorFromCustomeEmail, setErrorFromCustomeEmail] = useState('');
  const [isCreateListModalOpen, setIsCreateListModalOpen] = useState(false);
  const [selectedEmailListItem, setSelectedEmailListItem] = useState([]);
  const [multipleConnectionMessage, setMultipleConnectionMessage] =
    useState('');
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [contactOpen, setContactOpen] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [customSort, setCustomSort] = useState([]);
  const [hsSort, setHsSort] = useState([]);
  const [rtSort, setRtSort] = useState([]);
  const [wbSort, setWbSort] = useState([]);
  const [sfSort, setSfSort] = useState([]);
  const [gmSort, setGmSort] = useState([]);
  const [olSort, setOlSort] = useState([]);
  const [clickCheck, setClickCheck] = useState('');
  const [customFlag, setCustomFlag] = useState('');
  const [hsFlag, setHsFlag] = useState([]);
  const [rtFlag, setRtFlag] = useState([]);
  const [wbFlag, setWbFlag] = useState([]);
  const [sfFlag, setSfFlag] = useState([]);
  const [gmFlag, setGmFlag] = useState([]);
  const [olFlag, setOlFlag] = useState([]);
  const [sortFlag, setSortFlag] = useState('asc');
  const [openModal, setOpenModal] = useState(false);
  const [fromEdit, toggleFromEdit] = useState(false);
  const [openDisconnectModal, setDisconnectModal] = useState(false);

  //Segment Constants
  const [segmentTabActive, setSegmentTabActive] = useState(false);
  const [segmentList, setSegmentList] = useState([]);

  useEffect(() => {
    if (
      contactError &&
      contactError.message &&
      contactError.type === 'FieldValidationException'
    ) {
      setContactError('Please upload as per sample file.');
    } else if (
      contactError &&
      contactError.message &&
      contactError.type === 'ContactListException' &&
      contactError.code === 2903
    ) {
      setContactError('Add/Delete on Imported List is Not permitted');
      setIsDelete(false);
    } else if (
      contactError &&
      contactError.message &&
      contactError.type !== 'FieldValidationException'
    ) {
      setContactError(
        'List name is not valid, please try with the other list name'
      );
    } else {
      setContactError(false);
      setSuccessMessage('');
    }
    if (!contactError) {
      retrieveGroupsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactError]);

  useEffect(() => {
    getSocialAccountURLs()
      .then((res) => {
        setSocialAccountURL(res.result.data);
      })
      .catch((err) => {});
  }, [setSocialAccountURL]);

  useEffect(() => {
    if (isContactCreate) {
      setIsContactCreate(false);
      setUploadType('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContactsFetching]);

  useEffect(() => {
    if (!isEmpty(socialNetworkData)) {
      const { snetwork } = qs.parse(window.location.href);
      if (
        snetwork &&
        window.sessionStorage.getItem('snetwork') &&
        window.sessionStorage.getItem('snetwork') !== 'go' &&
        window.sessionStorage.getItem('snetwork') !== 'ol'
      ) {
        setSelectedNetwork(window.sessionStorage.getItem('snetwork'));
        setContactOpen(true);
      }
    }
  }, [socialNetworkData]);

  useEffect(() => {
    if (contactGroupsList && contactGroupsList.length > 0) {
      if (customSort && customSort.length > 0) {
        handleSort('custom', customFlag, true);
      } else if (rtSort && rtSort.length > 0) {
        handleSort('redtail', rtFlag, true);
      } else if (wbSort && wbSort.length > 0) {
        handleSort('wealthbox', hsFlag, true);
      } else if (hsSort && hsSort.length > 0) {
        handleSort('hubspot', sfFlag, true);
      } else if (sfSort && sfSort.length > 0) {
        handleSort('salesforce', sfFlag, true);
      } else if (gmSort && gmSort.length > 0) {
        handleSort('google', gmFlag, true);
      } else if (olSort && olSort.length > 0) {
        handleSort('outlook', olFlag, true);
      }
    }
  }, [contactGroupsList]);

  const [uploadType, setUploadType] = useState('');
  const [socialContactsGroups, setSocialContactGroups] = useState([]);
  const [redTailModal, setRedTailModal] = useState(false);
  const [redTailError, setRedTailError] = useState(false);

  useEffect(() => {
    loginRequest({ noRefresh: true });
  }, []);

  useEffect(() => {
    getContactsGroups()
      .then((response) => {
        setSocialContactGroups(get(response, 'result.data', []));
      })
      .catch(() => {
        setSocialContactGroups([]);
      });
    if (isAdmin) {
      window.sessionStorage.setItem('selectedIndex', 3);
    } else window.sessionStorage.setItem('selectedIndex', 2);
    // tiggerLoginRequest();
    getSegments().then((response) => {
      setSegmentList(response.result.data);
      console.log(response);
    }).catch(err => console.log(err));
  }, []);

  const handleEmailSort = (flag) => {
    setSortFlag(flag);
    contactsRequest({
      params: {
        contactGroup: selectedContactGroup,
        sortOptions: flag,
      },
    });
    if (page > 0) {
      setPage(0);
    }
  };

  const getEmailListCsv = (values) => {
    const csvData = parse(values, {
      fields: ['first_name', 'last_name', 'email'],
    });
    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const csvFile = new File([csvBlob], 'email_list.csv', {
      type: 'text/csv',
      lastModified: new Date(),
    });
    return csvFile;
  };

  const onEmailListSubmit = (values, isModal) => {
    setContactError(false);
    setSuccessMessage('');
    const csvFile = getEmailListCsv(values.emailRecipients);
    const isUpdate = get(values, 'emailList') !== 'new';
    setIsContactCreate(true);
    contactsCreate(
      {
        name: isUpdate
          ? get(values, 'emailList')
          : get(values, 'emailListName', ''),
        media1: [csvFile],
      },
      isUpdate,
      isModal
    );
  };

  const filteredContactList = (network) => {
    // console.log('filteredContactList', network, contactGroupsList);
    return (contactGroupsList || []).filter((c) => {
      return get(c, `type`) === network;
    });
  };

  const onModalClose = () => {
    setMultipleConnectionMessage('');
    setIsOpen(false);
    setUploadType('');
  };

  const handleSocialIconClick = (id, network) => {
    let link = get(socialAccountURL, `${id}.oauth_url.${network}`, '');
    window.sessionStorage.setItem('snetwork', id);
    if (!link) {
      link = get(socialAccountURL, `${id}.oauth_url`, '');
    }

    if (link) {
      if (link) window.location.href = link;
    }
  };

  const handleContactListChange = (value) => {
    console.log(value);
    setPage(0);
    setUploadType('');
    setSelectedEmailListItem([]);
    setClickCheck('');

    if (value === 'hs') {
      if (get(socialNetworkData, 'hs', []).length >= 1) {
        // setMultipleConnectionMessage('Please disconnect your Hubspot Account and try again connecting with new Account');
        // setIsOpen(true);
        setSelectedNetwork('hs');
        setContactOpen(true);
      } else {
        handleSocialIconClick('hs');
      }
    } else if (value === 'newLst') {
      setIsUpdateContact(true);
      setEmailRecipientsModalOpen(true);
    } else if (value === 'custom') {
      setUploadType('custom');
    } else if (value === 'sf') {
      if (get(socialNetworkData, 'sf', []).length >= 1) {
        // setMultipleConnectionMessage('Please disconnect your Salesforce Account and try again connecting with new Account');
        // setIsOpen(true);
        setSelectedNetwork('sf');
        setContactOpen(true);
      } else {
        handleSocialIconClick('sf');
      }
    } else if (value === 'wb') {
      if (get(socialNetworkData, 'wb', []).length >= 1) {
        // setMultipleConnectionMessage('Please disconnect your WealthBox Account and try again connecting with new Account');
        // setIsOpen(true);
        setSelectedNetwork('wb');
        setContactOpen(true);
      } else {
        handleSocialIconClick('wb');
      }
    } else if (value === 'rt') {
      if (get(socialNetworkData, 'rt', []).length >= 1) {
        // setMultipleConnectionMessage('Please disconnect your RedTail Account and try again connecting with new Account');
        // setIsOpen(true);
        setSelectedNetwork('rt');
        setContactOpen(true);
      } else {
        setRedTailModal(true);
      }
    } else if (value === 'static') {
    } else {
      setSortFlag('asc');
      contactListChange(value);
    }
  };

  const handleContactsDelete = () => {
    setIsDelete(false);
    console.log(selectedItem);

    if (selectedItem && Object.keys(selectedItem).length > 0) {
      onContactDelete({}, true, {}, selectedItem.id);
    } else {
      let selectedId = map(selectedEmailListItem, 'email');
      let isAllCheckSelected = selectedEmailListItem.length === contactsCount;
      console.log(isAllCheckSelected);
      onContactDelete(selectedId, isAllCheckSelected, {});
      setSelectedEmailListItem([]);
      setPage(0);
    }
    setSelectedItem({});
  };

  const closeDeleteModal = () => {
    setIsDelete(false);
    setSelectedItem({});
  };

  const handleSort = (platform, flag, open = false) => {
    let list = filteredContactList(platform);
    if (!open) {
      setClickCheck('open');
    }
    if (flag && flag === 'asc') {
      list = list.sort((a, b) =>
        a.name
          .toUpperCase()
          .localeCompare(b.name.toUpperCase(), 'en', { numeric: true })
      );
      // list = list.sort((a, b) => a.name.toUpperCase() !== b.name.toUpperCase() ? a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1 : 0);
    } else if (flag && flag === 'desc') {
      list = list.sort((a, b) =>
        b.name
          .toUpperCase()
          .localeCompare(a.name.toUpperCase(), 'en', { numeric: true })
      );
      // list = list.sort((a, b) => a.name.toUpperCase() !== b.name.toUpperCase() ? a.name.toUpperCase() > b.name.toUpperCase() ? -1 : 1 : 0);
    }

    if (platform === 'custom') {
      setCustomSort(list);
      setCustomFlag(flag);
    } else if (platform === 'hubspot') {
      setHsSort(list);
      setHsFlag(flag);
    } else if (platform === 'salesforce') {
      setSfSort(list);
      setSfFlag(flag);
    } else if (platform === 'wealthbox') {
      setWbSort(list);
      setWbFlag(flag);
    } else if (platform === 'redtail') {
      setRtSort(list);
      setRtFlag(flag);
    } else if (platform === 'google') {
      setGmSort(list);
      setGmFlag(flag);
    } else if (platform === 'outlook') {
      setOlSort(list);
      setOlFlag(flag);
    }
  };

  let getCustomContacts =
    customSort && customSort.length > 0
      ? customSort
      : filteredContactList('custom');
  let hsSortList =
    hsSort && hsSort.length > 0 ? hsSort : filteredContactList('hubspot');
  let sfSortList =
    sfSort && sfSort.length > 0 ? sfSort : filteredContactList('salesforce');
  let wbSortList =
    wbSort && wbSort.length > 0 ? wbSort : filteredContactList('wealthbox');
  let rtSortList =
    rtSort && rtSort.length > 0 ? rtSort : filteredContactList('redtail');
  let gmSortList =
    gmSort && gmSort.length > 0 ? gmSort : filteredContactList('gmail');
  let olSortList =
    olSort && olSort.length > 0 ? olSort : filteredContactList('outlook');

  const tiggerLoginRequest = () => {
    loginRequest({
      noRefresh: true,
    });
  };

  const handleEmailClose = (isSuccess, err) => {
    console.log(err);
    const message = !isSuccess
      ? err.result.error.message
      : 'Successfully customized email';
    setErrorFromCustomeEmail(message);
    if (isSuccess) {
      tiggerLoginRequest();
    }
  };

  const handleRedTailLogin = ({ username, password }) => {
    redTailLogin(username, password)
      .then((res) => {
        if (res.result.success) {
          setSelectedNetwork('rt');
          socialNetworkStatusRequest();
          setRedTailModal(false);
          setContactOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setRedTailError(true);
      });
  };

  const onCreateListClick = () => {
    setIsCreateListModalOpen(true);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleContactListUpdate = (name, selectedContactGrp) => {
    // const selectedContact = contactGroupsList.find(c => c.id === selectedContactGroup);
    // let name;
    if (selectedContactGrp !== undefined) {
      // name = selectedContact.name;
      updateContactList(name, selectedContactGrp)
        .then((res) => {
          if (res.result.success) {
            setPage(0);
            contactListChange(selectedContactGrp);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleClientsSocialIconClick = (
    id,
    isDiconnected,
    platform,
    emailPlatform
  ) => {
    let link = '',
      type = '';

    if (socialAccountURL && isDiconnected === false) {
      if (isAdmin) {
        window.sessionStorage.setItem('selectedIndex', 3);
      } else window.sessionStorage.setItem('selectedIndex', 2);
      window.sessionStorage.setItem('snetwork', id);
      switch (id) {
        case 'go':
          link = socialAccountURL.em.oauth_url.google;
          break;
        case 'ol':
          link = socialAccountURL.em.oauth_url.outlook;
          break;
        case 'wb':
          link = socialAccountURL.wb.oauth_url;
          break;
        case 'sf':
          link = socialAccountURL.sf.oauth_url;
          break;
        case 'em':
          link = '';
          break;
        case 'hs':
          link = socialAccountURL.hs.oauth_url;
          break;
        case 'rt':
          link = '';
          type = 'redTail';
        /* fallthrough */
        default:
          link = '';
      }
      if (link) window.location.href = link;
      else if (type && type === 'redTail') {
        setRedTailModal(true);
      }
    } else {
      if(platform === 'sf') {
        setDisconnectModal(true);
      } else {
        get(socialNetworkData, platform, []).forEach((item) => {
          if (
            (emailPlatform && emailPlatform === item.snetworkProvider) ||
            !emailPlatform
          ) {
            socialNetworkDelete({
              params: {
                id: `${platform}_${item.accountId}`,
              },
            });
          }
        });
      }
    }
  };

  const handleDisconnectFromModal = () => {
    get(socialNetworkData, 'sf', []).forEach((item) => {
      socialNetworkDelete({
        params: {
          id: `sf_${item.accountId}`,
        },
      });
    })
    setDisconnectModal(false);
  }

  const onNameListSubmit = ({ listName, contactType, isDefault = false }) => {
    const getSelectedEmailList = [];
    console.log(selectedEmailListItem);
    selectedEmailListItem.forEach((item) => {
      if (item.checked) {
        getSelectedEmailList.push({
          first_name:
            get(item, 'first.name', '').length > 0
              ? get(item, 'first.name')
              : get(item, 'name.first'),
          last_name:
            get(item, 'last.name', '').length > 0
              ? get(item, 'last.name')
              : get(item, 'name.last'),
          email: get(item, 'email'),
        });
      }
    });

    const csvFile = getEmailListCsv(getSelectedEmailList.reverse());
    console.log(getEmailListCsv(getSelectedEmailList.reverse()));

    setUploadType('');
    contactsCreate({
      name: listName,
      contactType: contactType,
      isDefault: isDefault,
      media1: [csvFile],
    });
    setSelectedEmailListItem([]);
    setPage(0);
  };

  const getBottomText = (platform, emailPlaftform) => {
    let platformDetails = get(socialNetworkData, platform, []);
    //const isPlatformConnectd = isEmpty(get(socialNetworkData, platform, []));
    if (emailPlaftform) {
      // console.log('platformDetails getBottomText', platform, socialNetworkData, platformDetails, emailPlaftform, find(platformDetails, {
      //   snetworkProvider: emailPlaftform,
      // }))
      platformDetails = find(platformDetails, {
        snetworkProvider: emailPlaftform,
      });
    }

    const text = isEmpty(platformDetails) ? 'Connect' : 'Disconnect';
    //if does not have data that meean we wil show Connect
    // console.log('platformDetails :: isEmpty(platformDetails))', platformDetails, emailPlaftform, isEmpty(platformDetails), text, (getCustomContacts.length === 0 && hsSortList.length === 0 && sfSortList.length === 0
    //   && wbSortList.length === 0 && rtSortList.length === 0 && gmSortList.length === 0 && olSortList.length === 0))
    return {
      bottomText: text,
      isDiconnected: text === 'Disconnect',
    };
  };

  const tabChange = (flag) => {
    if(flag) {
      getSegments().then((response) => {
        setSegmentList(response.result.data);
        console.log(response);
      }).catch(err => console.log(err));
    } 
    setSegmentTabActive(flag);
  }

  const onBottomTextClick = (
    e,
    id,
    platform,
    emailPlaftform,
    isDiconnected
  ) => {
    e.stopPropagation();
    if (isDiconnected) {
      get(socialNetworkData, platform, []).forEach((item) => {
        if (
          (emailPlaftform && emailPlaftform === item.snetworkProvider) ||
          !emailPlaftform
        ) {
          socialNetworkDelete({
            params: {
              id: `${platform}_${item.accountId}`,
            },
          });
        }
      });
    } else {
      handleClientsSocialIconClick(id, isDiconnected, platform, emailPlaftform);
    }
  };

  const closeRecipientsModalHandler = () => {
    setEmailRecipientsModalOpen(false);
    toggleFromEdit(false);
  };

  const reloadGroupList = (item) => {
    console.log('NAME-', item);
    let sNetwork;
    switch (item.toLowerCase()) {
      case 'hs':
        sNetwork = 'hs';
        break;
      case 'sf':
        sNetwork = 'sf';
        break;
      case 'wb':
        sNetwork = 'wb';
        break;
      case 'rt':
        sNetwork = 'rt';
        break;
      case 'go':
        sNetwork = 'gm';
        break;
      case 'ol':
        sNetwork = 'ol';
        break;
      default:
        break;
    }
    // console.log('SNETWORK - ', sNetwork);
    if (sNetwork) {
      setSelectedNetwork(sNetwork);
      if (item === 'hs' || item === 'sf' || item === 'wb' || item === 'rt')
        setContactOpen(true);
    }
  };

  const emailListTable = () => {
    return (
      <>
        {uploadType !== 'custom' && (
          <Flex width={1} flexDirection="column">
            <EmailListTable
              data={contactsList}
              onContactDelete={onContactDelete}
              isContactsFetching={isContactsFetching}
              selectedEmailListItem={selectedEmailListItem}
              setSelectedEmailListItem={setSelectedEmailListItem}
              contactGroupsList={contactGroupsList}
              contactsRequest={contactsRequest}
              selectedContactGroup={selectedContactGroup}
              paginationData={paginationData}
              navigateAfter={navigateAfter}
              page={page}
              setPage={setPage}
              contactsCount={contactsCount}
              isDelete={isDelete}
              setIsDelete={setIsDelete}
              setSelectedItem={setSelectedItem}
              uploadType={uploadType}
              handleEmailSort={handleEmailSort}
              sortFlag={sortFlag}
              customContacts={getCustomContacts}
              setModalOpen={setIsCreateListModalOpen}
              setAddEmailModal={setEmailRecipientsModalOpen}
              hsSort={hsSortList}
              sfSort={sfSortList}
              wbSort={wbSortList}
              rtSort={rtSortList}
              gmSort={gmSortList}
              olSort={olSortList}
              onCreateListClick={onCreateListClick}
              handleContactListChange={handleContactListChange}
              handleContactListUpdate={handleContactListUpdate}
              handleModalOpen={handleModalOpen}
              setCustomizeEmailOpen={setCustomizeEmailOpen}
              contactGroups={contactGroups}
              socialNetworkData={socialNetworkData}
              setSelectedNetwork={setSelectedNetwork}
              setContactOpen={setContactOpen}
              toggleFromEdit={toggleFromEdit}
              userData={userData}
            />
          </Flex>
        )}
      </>
    );
  };

  const segmentTable = () => {
    return (
      <>
        <Flex width={1} flexDirection="column">
          <SegmentTable
            segmentList={segmentList}
            userData={userData}
          />
        </Flex>
      </>
    );
  };

  return (
    <div>
      <Flex
        width={`${window.innerWidth < 768 ? 1 : '90%'}`}
        mr="5%"
        ml="5%"
        flexDirection="column"
      >
        <Flex
          width={`${window.innerWidth < 768 ? '95%' : '95%'}`}
          mx="auto"
          pt="10px"
        >
          <Box width={1} px={2} mt={2} mb={2}>
            <div data-testid="contacts-page">Import My Contacts</div>
            <LoaderWrapper isLoading={socialNetworkDataFetching}>
              <div>
                {socialIcons.map((icon, index) => {
                  const buttonDetails = getBottomText(
                    icon.platform,
                    icon.emailPlatform
                  );
                  const bottomText = buttonDetails.bottomText;
                  const isDiconnected = buttonDetails.isDiconnected;
                  // {console.log('platformDetails :: property', icon, icon.platform, bottomText)}
                  return (
                    <InlineBlock
                      key={icon.id}
                      id={icon.id}
                      isDiconnected={isDiconnected}
                      title={icon.name}
                      style={icon.id === 'ol' ? { marginRight: '6px' } : {}}
                    >
                      <div
                        onClick={() =>
                          handleClientsSocialIconClick(
                            icon.id,
                            isDiconnected,
                            icon.platform,
                            icon.emailPlatform
                          )
                        }
                      >
                        {icon.id !== 'ol' ? (
                          icon.icon ? (
                            <Icon
                              icon={icon.icon}
                              style={{
                                cursor: 'pointer',
                                backgroundColor: !isDiconnected
                                  ? '#D4D4D4'
                                  : null,
                              }}
                            />
                          ) : (
                            <IconImage
                              pointer
                              src={icon.src}
                              style={{
                                cursor: 'pointer',
                                backgroundColor: !isDiconnected
                                  ? '#D4D4D4'
                                  : null,
                              }}
                            />
                          )
                        ) : null}
                      </div>

                      {icon.id !== 'ol' ? (
                        <Flex flexDirection="column" alignItems="center">
                          <ButtonNoBorderBackground
                            style={{ fontFamily: 'Poppins' }}
                            onClick={(e) =>
                              onBottomTextClick(
                                e,
                                icon.id,
                                icon.platform,
                                icon.emailPlatform,
                                isDiconnected
                              )
                            }
                          >
                            {bottomText}
                          </ButtonNoBorderBackground>
                          {bottomText === 'Disconnect' && (
                            <ButtonNoBorderBackground
                              style={{ fontFamily: 'Poppins' }}
                              onClick={() => reloadGroupList(icon.id)}
                            >
                              {/* Sync{' '} */}
                              <ResponsiveImg
                                src="/assets/images/Sync-blue.svg"
                                style={{
                                  height: '16px',
                                  width: 'auto',
                                  marginTop: '0px',
                                  marginRight: '-4px',
                                  position: 'relative',
                                  top: '5px',
                                  cursor: 'pointer',
                                }}
                              />
                            </ButtonNoBorderBackground>
                          )}
                        </Flex>
                      ) : null}
                    </InlineBlock>
                  );
                })}
              </div>
            </LoaderWrapper>
          </Box>
        </Flex>

        {/* Segment and Email List */}
        <TabsThree>
          <TabListThree>
            <TabFour
              padding="5px 0px !important"
              onClick={() => tabChange(false)}
              style={
                !segmentTabActive
                  ? { backgroundColor: '#6453ec', color: '#fff' }
                  : {}
              }
            >
              Email Lists
            </TabFour>
            <TabFour
              padding="5px 0px !important"
              onClick={() => tabChange(true)}
              style={
                segmentTabActive
                  ? { backgroundColor: '#6453ec', color: '#fff' }
                  : {}
              }
            >
              Segments
            </TabFour>
          </TabListThree>
        </TabsThree>

        {segmentTabActive ? segmentTable() : emailListTable()}

        {/* End Segment and Email List */}
      </Flex>
      {openCustomizeEmail && (
        <CustomizeEmailModal
          isOpen={openCustomizeEmail}
          setCustomizeEmailOpen={setCustomizeEmailOpen}
          userData={userData}
          isContactsFetching={isContactsFetching}
          handleEmailClose={handleEmailClose}
          isAdmin={isAdmin}
        />
      )}
      {openEmailRecipientsModalOpen ? (
        <AddEmailRecipients
          selectedContactGroup={selectedContactGroup}
          isModalOpen={openEmailRecipientsModalOpen}
          onEmailListSubmit={onEmailListSubmit}
          contactsCreate={contactsCreate}
          contactGroups={contactGroups}
          contactError={contactError}
          selectedEmailListItem={selectedEmailListItem}
          setSelectedEmailListItem={setSelectedEmailListItem}
          fromEdit={fromEdit}
          contactsRequest={contactsRequest}
          page={page}
          setPage={setPage}
          handleClose={closeRecipientsModalHandler}
        />
      ) : null}

      {isContactCreate && (
        <ErrorContainer align="center">
          Email list created successfully!
        </ErrorContainer>
      )}
      {contactErrorMessage && (
        <ErrorContainer
          style={{ position: 'relative', left: '70px', top: '5px' }}
          align="center"
        >
          {contactErrorMessage}
        </ErrorContainer>
      )}
      {errorFromCustomeEmail && (
        <ErrorModal onClose={() => setErrorFromCustomeEmail('')}>
          {errorFromCustomeEmail}
        </ErrorModal>
      )}
      {isCreateListModalOpen && (
        // <CreateEmailListModal
        //   isModalOpen={isCreateListModalOpen}
        //   setModalOpen={setIsCreateListModalOpen}
        //   onNameListSubmit={onNameListSubmit}
        //   selectedEmailList={selectedEmailListItem}
        //   isContactsFetching={isContactsFetching}
        //   contactsCreate={contactsCreate}
        //   contactGroupsList={customContactGroupList}
        //   socialNetworkData={socialNetworkData}
        //   retrieveGroupsList={retrieveGroupsList}
        //   setContactError={setContactError}
        // />
        <SegmentContactListModal
          isModalOpen={isCreateListModalOpen}
          setModalOpen={setIsCreateListModalOpen}
          userData={userData}
          userId={userId}
          retrieveGroupsList={retrieveGroupsList}
        />
      )}

      {openModal && (
        <CreateEmailListModalV1
          isModalOpen={openModal}
          setModalOpen={setOpenModal}
          onNameListSubmit={onNameListSubmit}
          selectedEmailList={selectedEmailListItem}
          isContactsFetching={isContactsFetching}
          userData={userData}
          userId={userId}
        />
      )}

      <RedTailModal
        open={redTailModal}
        handleClose={() => setRedTailModal(false)}
        handleModalSubmit={handleRedTailLogin}
        modalError={redTailError}
      />

      {multipleConnectionMessage && (
        <Modal
          isOpen={isOpen}
          onRequestClose={onModalClose}
          shouldCloseOnOverlayClick
        >
          <ModalHeader fontSize="15px">
            <ModalCloseButton onClick={onModalClose} />
            {multipleConnectionMessage}
          </ModalHeader>
        </Modal>
      )}

      {/* {console.log('socialNetworkData', socialNetworkData)} */}
      {/*each(socialNetworkData, (item, key) => {
          console.log('snetworkProviderKey',key.em);
        })
      */}

      {/* <button
        onClick={() => {
          setContactOpen(true);
        }}
      >
        {' '}
        click
      </button> */}

      <ContactListModal
        contactOpen={contactOpen}
        setContactOpen={setContactOpen}
        selectedNetwork={selectedNetwork}
        setSelectedNetwork={setSelectedNetwork}
        setUploadType={setUploadType}
        selectedContactGroup={selectedContactGroup}
        contactsRequest={contactsRequest}
      />

      {openDisconnectModal && (<DisconnectModal
        isOpen={openDisconnectModal}
        closeModalHandler={() => setDisconnectModal(false)}
        handleDisconnect={handleDisconnectFromModal}
      />)}

      <Modal
        onRequestClose={closeDeleteModal}
        shouldCloseOnOverlayClick
        isOpen={isDelete}
        size="smail"
      >
        <ModalCloseButton onClick={closeDeleteModal} />
        <ModalBody>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#6351ed',
              fontFamily: 'Poppins',
              wordBreak: 'break-all',
            }}
          >
            Are you sure you want to delete{' '}
            {selectedItem.name
              ? selectedItem.name
              : contactGroupsList.find((c) => c.id === selectedContactGroup) &&
                selectedEmailListItem.length === contactsCount
              ? contactGroupsList.find((c) => c.id === selectedContactGroup)
                  .name
              : 'the emails'}{' '}
          </div>
          {/* {selectedItem && (
            <div style={{color: '#6351ed',marginTop: '8px'}}>{selectedItem.name ? selectedItem.name : contactGroupsList.find(c => c.id === selectedContactGroup) && contactGroupsList.find(c => c.id === selectedContactGroup).name}</div>
          )} */}
          <HoverButton
            margin="9px 10px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            color="#6351ed"
            borderColor="#fff"
            onClick={handleContactsDelete}
          >
            Yes
          </HoverButton>
          <HoverButton
            margin="9px 0px 0px 0px"
            padding="5px 28px"
            bgColor="#fff"
            color="#6351ed"
            hoverBackground="#6351ed"
            hoverColor="#fff"
            borderColor="#fff"
            onClick={closeDeleteModal}
          >
            No
          </HoverButton>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EmailLIst;
