import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const NoEmailsWrapper = styled(Flex)`
  color: #757575;
  button {
    font-size: 16px;
    text-decoration: underline;
  }
`;

export const CaretIcon = styled.span`
  border: solid #c95cfc;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  float: right;
  margin-top: ${({ marginTop }) => marginTop || '3px'};
`;

export const HoverButton = styled.button`
  font-size: 13px;
  padding: ${({ padding }) => padding || '7px 20px;'};
  font-family: 'Poppins';
  background-color: ${({ bgColor }) => bgColor || '#eeeeee'};
  color: ${({ color }) => color || '#6351ed'};
  border: ${({ borderColor }) => borderColor ? `1px solid ${borderColor}` : '1px solid #a0a0a0'};
  margin: ${({ margin }) => margin || '20px 0px 0px 0px'};
  cursor: pointer;
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || 'auto'};
  ${({ isSecondary }) =>
    isSecondary &&
    `
    background-color: #6351ed;
    border: 1px solid #6351ed;
    color: #fff;
  `}
  &:hover {
    color: ${({ hoverColor }) => hoverColor ? hoverColor : ''};
    border: 1px solid #6351ed;
    background-color: ${({ hoverBackground }) => hoverBackground ? hoverBackground : ''}
  }
`;

export const RadioInput = styled.input`
  border: 2.7px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : '')};

  &:checked {
    background-color: ${({ disabled }) => (!disabled ? '#6351ed' : '#ccc')};
    box-shadow: 0 0 0 1px #aaa;
  }
`;

export const RadioInputLabel = styled.label`
  color: #000;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : '')};
`;
