/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { MioDropdown } from '../';
import Header from './Header';
import {
  HeaderUl,
  HeaderLI,
  IconTitleContainer,
  ImpactTooltip,
  ImpactTitleContainer,
} from './Header.styles';
import { clearSeassionStorage, ROLE_TYPES } from '../../utils';
import { actions as contactActions } from '../../redux/modules/contacts.module';
import { getRoleType } from '../../redux/selector/login.selectors';
import { get } from 'lodash';
import { getMioScore } from '../../services/dashboard';
import SwitchProfileModal from './SwitchProfileModal';
import { actions as loginActions } from '../../redux/modules/login.module';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import { useOktaAuth } from '@okta/okta-react';

const AdminHeader = ({
  pathName,
  userName,
  handleModalOpen,
  type,
  roleType,
  setSelectedTabIndex,
  userDetails,
  complianceAdmin,
  bucketCount,
  setOpenDeleteModal,
  loginRequest,
  campaignSetState,
}) => {
  // console.log('USER DETAILS - -- - ', userDetails);
  const [mioScore, setMioScore] = useState({
    score: 0,
    totalSharedArticles: -1,
  });
  const [showToolTip, toggleToolTip] = useState(false);
  const [borderColor, setBorderColor] = useState('#6351ed');
  const [showSwitchProfile, setShowSwitchProfile] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  console.log('oktaState', oktaAuth, authState);
  // const shareScoreStyle = {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   width: '35px',
  //   marginBottom: mioScore.totalSharedArticles > 2 ? '2px' : ''
  // }
  const checkComplianceAdmin = get(userDetails, 'compliance_admin', '');
  const history = useHistory();
  const goToProfilePage = (index) => {
    window.sessionStorage.removeItem('selectedIndex');
    setSelectedTabIndex({ selectedTabIndex: index });
    history.push('/admin/configure');
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    var prevDate = new Date();
    prevDate.setDate(prevDate.getDate() - 6);
    prevDate = prevDate.toISOString().split('T')[0];
    getMioScore(currentDate, prevDate)
      .then((response) => {
        // console.log(response);
        const scoreArray =
          response && response.result && response.result.data
            ? Object.keys(response.result.data.analytics.score)
            : [];
        // let score = response.result.data.analytics.score[scoreArray[scoreArray.length-1]].mine;
        let score =
          scoreArray.length > 0
            ? response.result.data.analytics.score[
                scoreArray[scoreArray.length - 1]
              ].mine
            : 0;
        let totalSharedArticles =
          response && response.result && response.result.data
            ? response.result.data.analytics.total_shares
            : 0;
        let borCol =
          score <= 30
            ? '#6351ed #c5c5c9 #c5c5c9 #c5c5c9'
            : score <= 60
            ? '#6351ed #6351ed #c5c5c9 #c5c5c9'
            : score <= 85
            ? '#6351ed #6351ed #6351ed #c5c5c9'
            : '#6351ed';
        setMioScore({ score: score, totalSharedArticles: totalSharedArticles });
        setBorderColor(borCol);
        // setMioScore({score: score, totalSharedArticles: null});
        // setBorderColor('#6351ed');
      })
      .catch((err) => console.log(err));
  }, []);

  const displayScore = () => {
    let scoreStatus = [];
    // let borCol = mioScore.score <= 30 ? '#6351ed #c5c5c9 #c5c5c9 #c5c5c9' : (
    //     mioScore.score<=60 ? '#6351ed #6351ed #c5c5c9 #c5c5c9' : (
    //       mioScore.score<=85 ? '#6351ed #6351ed #6351ed #c5c5c9' : '#6351ed'
    //     )
    //   );
    // let style = {
    //   fontSize: '9px',
    //   border: '3px solid',
    //   borderColor: borCol,
    //   borderRadius: '13px',
    //   padding: mioScore.score < 12 ? '4px 7px 2px' : (mioScore.score < 100 ? '4px 4px 2px' : '3px 2px 2px')
    // };
    if (mioScore.totalSharedArticles < 3) {
      for (let i = 0; i < 3; i++) {
        scoreStatus.push(
          <i key={i} borderBottom="1px">
            <img
              alt="mio"
              src={
                i < mioScore.totalSharedArticles
                  ? '/assets/images/Forward_1-blue.svg'
                  : '/assets/images/Forward_1-grey.svg'
              }
            />
          </i>
        );
      }
    } else {
      // scoreStatus.push(
      //   <div style={style}>{mioScore.score}</div>
      // )
      scoreStatus.push(
        <div key="score" style={{ margin: '4px auto auto' }}>
          {mioScore.score}
        </div>
      );
    }
    return scoreStatus;
  };

  return (
    <Header 
      customLogoUrl={get(userDetails, 'company.extra_settings.logo', '')} 
      type={type} 
      logoUrl="/admin/dashboard"
    >
      <nav>
        <HeaderUl>
          <HeaderLI selected={'/admin/dashboard' === pathName}>
            <Link to="/admin/dashboard">
              <IconTitleContainer>
                <i className="icon dripicons-home" />
                <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                  HOME
                </span>
              </IconTitleContainer>
            </Link>
          </HeaderLI>
          {!complianceAdmin && (
            <MioDropdown
              background="#e4e4e4"
              hoverBackground="#6351ed"
              openOnHover
              width='11em'
              customStyle={{ right: '-33px' }}
              dropdownMenu={
                <ul>
                  <li
                    style={{
                      padding: '0px',
                      pointerEvents: 'auto',
                    }}
                  >
                    <Link
                      style={{
                        padding: '4px 5px',
                      }}
                      to="/campaignV2?type=create"
                      onClick={() => {
                        campaignSetState({
                          campaignId: '',
                          createFlow: true,
                          createMode: false,
                          curFlow: '',
                          selectedIndex: 0,
                          setObjectives: {},
                          setTopics: {},
                          contentWeeks: [],
                          planCampaign: {},
                          campaignArticles: [],
                          scheduleTimings: {},
                          selectedWeek: '',
                          selectedDays: '',
                          selectedArticles: [],
                          publishedContent: {},
                          defaultTemplates: [],
                          setTemplate: '',
                          savedTemplate: null,
                          scheduleTypes: {},
                          isEdit: false,
                          isFromAddCampaign: false,
                          summaries: null,
                          seriesId: '',
                          fromDashboard: false,
                          reviewCampaign:{}
                        });
                      }}
                    >
                      Create
                    </Link>
                  </li>
                  <li style={{ padding: '0px' }}>
                    <Link style={{ padding: '4px 5px' }} to="/campaignV2">
                      Dashboard
                    </Link>
                  </li>
                  <li style={{ padding: '0px' }}>
                    <Link
                      style={{ padding: '4px 5px' }}
                      to="/campaign/template"
                    >
                      Email Templates
                    </Link>
                  </li>
                  <li style={{ padding: '0px' }}>
                    <Link
                      style={{ padding: '4px 5px' }}
                      to="/campaign/custom-row"
                    >
                      Manage Rows
                    </Link>
                  </li>
                  <li style={{ padding: '0px' }}>
                    <Link
                      style={{ padding: '4px 5px' }}
                      to="/campaign/landing-pages"
                    >
                      Manage Page Templates
                    </Link>
                  </li>
                </ul>
              }
            >
              <HeaderLI id="campaign-li" selected={'/campaignV2' === pathName}>
                <Link to="/campaignV2">
                  <IconTitleContainer>
                    <i>
                      <img src="/assets/images/Megaphone.svg" alt="portfolio" />
                    </i>
                    <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                      CAMPAIGN
                    </span>
                  </IconTitleContainer>
                </Link>
              </HeaderLI>
            </MioDropdown>
          )}
          {(checkComplianceAdmin ||
            ROLE_TYPES.ADMIN_COMPLIANCE === roleType) && (
            <HeaderLI selected={'/admin/compliance' === pathName}>
              <Link to="/admin/compliance">
                <IconTitleContainer>
                  <i>
                    <img
                      src="/assets/images/tickicon.svg"
                      alt="tick"
                      style={{ marginLeft: '15px' }}
                    />
                  </i>
                  <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                    COMPLIANCE
                  </span>
                </IconTitleContainer>
              </Link>
            </HeaderLI>
          )}
          <MioDropdown
            background="#e4e4e4"
            hoverBackground="#6351ed"
            openOnHover
            dropdownMenu={
              <ul>
                <li style={{ padding: '0px' }}>
                  <Link
                    style={{ padding: '4px 5px' }}
                    to="/newsletter?type=create"
                  >
                    Create
                  </Link>
                </li>
                <li style={{ padding: '0px' }}>
                  <Link
                    style={{ padding: '4px 5px' }}
                    to="/newsletter?type=dashboard"
                  >
                    Dashboard
                  </Link>
                </li>
                <li
                  style={{
                    pointerEvents: bucketCount > 0 ? '' : 'none',
                    cursor: bucketCount > 0 ? 'pointer' : 'not-allowed',
                    backgroundColor: bucketCount > 0 ? '' : '#adadad',
                    color: bucketCount > 0 ? '' : '#ffffff',
                    padding: '0px',
                  }}
                >
                  <a
                    style={{
                      color: bucketCount > 0 ? '' : 'white',
                      padding: '4px 5px',
                    }}
                    onClick={() =>
                      bucketCount > 0
                        ? setOpenDeleteModal(true)
                        : setOpenDeleteModal(false)
                    }
                  >
                    Clear Articles
                  </a>
                </li>
              </ul>
            }
          >
            {/* <HeaderLI
              selected={'/newsletter' === pathName}
              style={{ position: 'relative' }}
            >
              <Link to="/newsletter">
                <IconTitleContainer>
                  <i>
                    <img
                      src="/assets/images/Newsletter-header.svg"
                      alt="newsletter"
                      style={{ marginLeft: '15px' }}
                    />
                  </i>
                  <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                    NEWSLETTER
                  </span>
                  <span
                    style={{
                      position: 'absolute',
                      fontSize: '10px',
                      fontWeight: 'bold',
                      right:
                        bucketCount <= 9
                          ? '10px'
                          : bucketCount >= 10 && bucketCount <= 99
                          ? '6px'
                          : '-1px',
                      top: '6px',
                    }}
                  >
                    {bucketCount > 99 ? '99+' : bucketCount}
                  </span>
                </IconTitleContainer>
              </Link>
            </HeaderLI> */}
          </MioDropdown>

          <HeaderLI selected={'/admin/analytics' === pathName}>
            <Link to="/admin/analytics">
              <ImpactTitleContainer
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  marginBottom:
                    mioScore.totalSharedArticles < 3 ? '1.3px' : '1px',
                }}
              >
                {/* <div style={shareScoreStyle} onMouseEnter={() => mioScore.totalSharedArticles < 3 ? toggleToolTip(true) : null} onMouseLeave={() => toggleToolTip(false)}>
                  {displayScore()}
                </div> */}
                {mioScore.totalSharedArticles !== -1 ? (
                  !mioScore.totalSharedArticles ||
                  mioScore.totalSharedArticles < 3 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '35px',
                      }}
                      onMouseEnter={() => toggleToolTip(true)}
                      onMouseLeave={() => toggleToolTip(false)}
                    >
                      {displayScore()}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: '9px',
                        height: '25px',
                        width: '25px',
                        textAlign: 'center',
                        border: '3px solid',
                        borderColor: borderColor,
                        borderRadius: '13px',
                        marginBottom: '2px',
                      }}
                    >
                      {displayScore()}
                    </div>
                  )
                ) : (
                  <div style={{ height: '25px', width: '25px' }}></div>
                )}
                <ImpactTooltip
                  style={{ boxShadow: 'none', fontStyle: 'normal' }}
                  className="Impact-tooltip-text"
                  hidden={!showToolTip}
                  isFromAdmin={true}
                >
                  Share 3 times to start seeing your Clout score here
                </ImpactTooltip>
                <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                  MY CLOUT
                </span>
              </ImpactTitleContainer>
            </Link>
          </HeaderLI>
          <MioDropdown
            testId="userhome-menu"
            background="#e4e4e4"
            hoverBackground="#6351ed"
            openOnHover
            dropdownMenu={
              <ul>
                <li>
                  <a onClick={() => goToProfilePage(0)}>Profile</a>
                </li>
                {get(userDetails, 'details.user.is_impersonator', 'false') ===
                  'true' && (
                  <li>
                    <a onClick={() => setShowSwitchProfile(true)}>
                      Switch Profile
                    </a>
                  </li>
                )}

                <li>
                  <a onClick={() => goToProfilePage(1)}>Channel</a>
                </li>
                <li>
                  <Link to="/theme">Topics</Link>
                </li>
                <li>
                  <Link to="/channel">Publishers</Link>
                </li>
                <li>
                  <a onClick={() => goToProfilePage(2)}>Platforms</a>
                </li>
                <li>
                  <a
                    data-testid="home-contacts"
                    onClick={() => goToProfilePage(3)}
                  >
                    Contacts
                  </a>
                </li>
                {!complianceAdmin && checkComplianceAdmin ? (
                  <li>
                    <a onClick={() => goToProfilePage(4)}>Settings</a>
                  </li>
                ) : null}
                <li>
                  <a href="#" onClick={() => {
                     oktaAuth.signOut('/');
                     oktaAuth.closeSession();
                     localStorage.clear();
                     sessionStorage.clear();
                     window.location.href = '/';
                  }}>
                    Logout
                  </a>
                </li>
              </ul>
            }
          >
            <HeaderLI selected={'/admin/configure' === pathName}>
              <a href="javascript:void(0);">
                <i className="icon icon dripicons-user" />
                <span style={{ fontSize: '14px', marginBottom: '1px' }}>
                  {get(userDetails, 'details.user.is_impersonator', '') ===
                  'true'
                    ? get(
                        userDetails,
                        'details.user.impersonate.first_name',
                        ''
                      )
                    : userName}
                </span>
              </a>
            </HeaderLI>
          </MioDropdown>
        </HeaderUl>
      </nav>
      {showSwitchProfile ? (
        <SwitchProfileModal
          handleModalClose={() => {
            setShowSwitchProfile(false);
          }}
          userData={userDetails}
          loginRequest={loginRequest}
          roleType={roleType}
        />
      ) : null}
    </Header>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const complianceAdmin = ROLE_TYPES.ADMIN_COMPLIANCE === roleType;
  const assetManagerAdmin = ROLE_TYPES.ADMIN_ASSET_MANAGER === roleType;
  return {
    userDetails: state.user.data,
    mioScore: state.analytics.mioScore,
    complianceAdmin,
    assetManagerAdmin,
    roleType,
  };
};
const mapDispatchToProps = {
  setSelectedTabIndex: contactActions.api.contacts.selectedtabindex,
  loginRequest: loginActions.api.login.request,
  campaignSetState: campaignActions.api.campaign.setstate,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
