import React, { useState, useEffect } from 'react';
import { Flex } from '@rebass/grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';
import { ErrorContainer } from '../../../components';
import {
  StyledSearchInput,
  SearchWrapper,
  SearchButton,
} from '../DashboardContainer.styles';
import AdminSearchFilterMenu from '../../../components/AdminSearchFilterMenu/AdminSearchFilterMenu';
import { getApprovedThemeList } from '../../../utils';
import { getPopularTheme } from '../../../services/dashboard';

const searchShema = Yup.object().shape({
  searchText: Yup.string().required('Please enter text'),
});

const Search = ({ onSearchClick, setSearchValue, setTabIndex, themesList, exploreSetState, isComplianceAdmin }) => {
  console.log(themesList);
  const [mostSubscribedData, setMostSubscribedData] = useState([]);
  const [topicSelected, setTopicSelected] = useState([]);

  useEffect(() => {
    exploreSetState({searchFilters: [...topicSelected]});
  }, [topicSelected]);

  useEffect(() => {
    getPopularTheme().then((response) => {
      setMostSubscribedData(get(response, 'result.data', []));
    });
  }, []);
  const approvedThemeList = themesList && themesList.length > 0 ? getApprovedThemeList(themesList) : [];

  const onSearch = () => {
    setTabIndex(6);

    let topic = [];
    if (topicSelected.includes('Your Topics')) {
      approvedThemeList.forEach(({ id }) => {
        topic.push(id);
      });
    }
    if (topicSelected.includes('Most Subscribed')) {
      mostSubscribedData.forEach(({ id }) => {
        topic.push(id);
      });
    }

    const inputParams = {
      topic
    };
    onSearchClick(inputParams);
  };

  const setTopicSelectedRequest = (data) => {
    setTabIndex(4);
    setTopicSelected(data);
    let newTopics = data;
    let topic = [];
    if (newTopics.includes('Your Topics')) {
      approvedThemeList.forEach(({ id }) => {
        topic.push(id);
      });
    }
    if (newTopics.includes('Most Subscribed')) {
      mostSubscribedData.forEach(({ id }) => {
        topic.push(id);
      });
    }
    const inputParams = {
      topic
    };
    onSearchClick(inputParams);
  };


  const onFormSubmit = () => {
    onSearch();
  };



  return (
    <div>
      <SearchWrapper width="100%" mt="3.7em" mb="1.2em">
        <Formik
          validateOnChange={false}
          initialValues={{ searchText: '' }}
          onSubmit={onFormSubmit}
          validationSchema={searchShema}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Flex width={1} as="form" flexDirection="column">
              <StyledSearchInput
                autoComplete="off"
                type="text"
                name="searchText"
                placeholder="What are you looking for?"
                value={values.searchText}
                onChange={(e) => {
                  handleChange(e);
                  setSearchValue(e.target.value);
                }}
              />
              <SearchButton type="submit" onClick={handleSubmit}>
                <FontAwesomeIcon icon={faSearch} />
              </SearchButton>
              <ErrorContainer align="center">
                {errors.searchText}
              </ErrorContainer>
              {!isComplianceAdmin && (
                <AdminSearchFilterMenu
                topics={approvedThemeList}
                topicSelected={topicSelected}
                setTopicSelected={setTopicSelectedRequest}
                exploreSetState={exploreSetState}
              />
              )}
            </Flex>
          )}
        </Formik>
      </SearchWrapper>
    </div>
  );
};

export default Search;
