import React from 'react';
import { Flex } from '@rebass/grid';
import {
    Button,
    Input,
    LoaderWrapper,
    ErrorContainer,
    ModalHeader,
    ModalCloseButton,
    ModalV2,
    ModalBody,
} from '../../../components'


export const SendTestMailModal = ({
    isOpen,
    handleModalClose,
    subject,
    handleChange,
    value,
    modalButtonOnClick,
    validation,
    sendTestErrorMsg,
    loading,
    detailsLoading
}) => {

    return (

        <ModalV2
            isOpen={isOpen}
            onRequestClose={handleModalClose}
            bgColor="#fff"
            style={{
                content: {
                    height: 'fit-content',
                    padding: '15px 35px',
                    width: '45%',
                    top: '20%',
                    left: '25%',
                    transform: 'translate(-50%, -50%)',
                },
            }}
            ariaHideApp={false}
        >

            <ModalHeader style={{ fontSize: '15px' }}>
                <p>Send a test email</p>
                <p>Send this email as a test to up to 5 recipients.</p>
                <ModalCloseButton onClick={handleModalClose} />
            </ModalHeader>

            <LoaderWrapper isLoading={detailsLoading}>
                <ModalBody>
                    <p style={{textAlign: 'left'}}>Subject: TEST - ({subject})</p>
                    <Input
                        id="email"
                        name="email"
                        placeholder="Type in email address(es), separated by commas"
                        onChange={handleChange}
                        value={value}
                    />

                    <LoaderWrapper isLoading={loading}>
                        <Flex
                            alignItems={'center'}
                            justifyContent="center"
                            style={{ margin: '20px 0' }}
                        >
                            <Button
                                borderRadius="4px"
                                onClick={modalButtonOnClick}
                                disabled={validation}
                                width="80px"
                                padding="5px 10px"
                                margin="20px 10px 0px 0px"
                                height="40px"
                            >
                                Send
                            </Button>
                        </Flex>
                    </LoaderWrapper>
                    {sendTestErrorMsg && <ErrorContainer align='center'>
                        {sendTestErrorMsg}
                    </ErrorContainer>}
                </ModalBody>

            </LoaderWrapper>
            
        </ModalV2>
    );
};
