import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Flex } from '@rebass/grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { SearchFilterMenu, ErrorContainer } from '../../../components';
import {
  getApprovedThemeList,
  extractChannelListForSearch,
} from '../../../utils';
import { getPopularTheme } from '../../../services/dashboard';

import {
  StyledSearchInput,
  SearchWrapper,
  SearchButton,
} from '../DashboardContainer.styles';
import { getChannelList, getAdminChannelData } from '../../../services/dashboard/index';

const searchShema = Yup.object().shape({
  searchText: Yup.string().required('Please enter text'),
});

const AdvisorSearch = ({
  onSearchClick,
  setSearchValue,
  setTabIndex,
  themesList,
  channelData,
  exploreSetState,
  isComplianceAdmin,
  searchValue,
  isAdmin
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [topicSelected, setTopicSelected] = useState([]);
  const [mostSubscribedData, setMostSubscribedData] = useState([]);
  const [selectedPublishers, setSelectedPublishers] = useState([]);
  const approvedThemeList = getApprovedThemeList(themesList);
  const [filteredChannelList, setFilteredChannelList] = useState([]);

  useEffect(() => {
    console.log('IS ADMIN - ', isAdmin);
    if(isAdmin) {
      getAdminChannelData()
      .then(response => {
        if(response && response.result && response.result.data) {
          let filteredData = Object.keys(response.result.data).map(key => {
            return {
              ...response.result.data[key][0],
              publisher_id: key
            };
          }).filter(channel => channel.is_hidden === 'false');
          setFilteredChannelList(filteredData);
        }
      })
      .catch(err => console.log('ERROR - ', err));
    }
    else {
      getChannelList()
      .then(response => {
        if(response && response.result && response.result.data) {
          let filteredData = Object.keys(response.result.data).map(key => {
            return {
              ...response.result.data[key][0],
              publisher_id: key
            };
          }).filter(channel => channel.is_hidden === 'false');
          setFilteredChannelList(filteredData);
        }
      })
      .catch(err => console.log('ERROR - ', err));
    }
  }, [])

  useEffect(() => {
    exploreSetState({searchFilters: [...selectedPublishers, ...topicSelected]})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicSelected, selectedPublishers]);

  const onSearch = () => {

    let topic = [];
    if (topicSelected.includes('Your Topics')) {
      approvedThemeList.forEach(({ id }) => {
        topic.push(id);
      });
    }
    if (topicSelected.includes('Most Subscribed')) {
      mostSubscribedData.forEach(({ id }) => {
        topic.push(id);
      });
    }

    const inputParams = {
      channel: selectedItems.map(({ id }) => id),
      topic,
    };
    onSearchClick(inputParams);
  };

  const setTopicSelectedRequest = (data) => {
    setTopicSelected(data);
    let newTopics = data;
    let topic = [];
    if (newTopics.includes('Your Topics')) {
      approvedThemeList.forEach(({ id }) => {
        topic.push(id);
      });
    }
    if (newTopics.includes('Most Subscribed')) {
      mostSubscribedData.forEach(({ id }) => {
        topic.push(id);
      });
    }
    const inputParams = {
      channel: selectedItems.map(({ id }) => id),
      topic,
    };
    onSearchClick(inputParams);
  };
  const setSelectedItemsRequest = (data) => {
    let filterTags = [];
    if(exploreSetState) {
      data.forEach(item => {
        if(item.name.includes('(')) {
          let name = item.name.split(/[()]/);
          filterTags.push(name[0].toUpperCase().trim());
          filterTags.push(name[1].toUpperCase().trim());
        } else {
        filterTags.push(item.name.toUpperCase().trim())
        }
      });
    }
    setSelectedPublishers(filterTags);
    setSelectedItems(data);
    let newSelectedItems = data;
    let topic = [];
    if (topicSelected.includes('Your Topics')) {
      approvedThemeList.forEach(({ id }) => {
        topic.push(id);
      });
    }
    if (topicSelected.includes('Most Subscribed')) {
      mostSubscribedData.forEach(({ id }) => {
        topic.push(id);
      });
    }
    const inputParams = {
      channel: newSelectedItems.map(({ id }) => id),
      topic,
    };
    onSearchClick(inputParams);
  };

  useEffect(() => {
    getPopularTheme().then((response) => {
      setMostSubscribedData(get(response, 'result.data', []));
    });
  }, []);

  const onFormSubmit = (ss) => {
    onSearch();
    
  };

  return (
    <div>
      <Formik
          validateOnChange={false}
          initialValues={{ searchText: '' }}
          onSubmit={onFormSubmit}
          validationSchema={searchShema}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <SearchWrapper width="100%" mt="3.7em" mb={errors.searchText && errors.searchText.length > 0 ? "1.2em" : "2.2em"}flexDirection="column" pl='3%' pr='3%'>
            <Flex width={1} as="form" flexDirection="column">
              <StyledSearchInput
                autoComplete="off"
                type="text"
                name="searchText"
                placeholder="What are you looking for?"
                value={searchValue}
                onChange={(e) => {
                  handleChange(e);
                  setSearchValue(e.target.value);
                }}
              />
              <SearchButton type="submit" onClick={handleSubmit}>
                <FontAwesomeIcon icon={faSearch} />
              </SearchButton>
              <ErrorContainer align="center">
                {errors.searchText}
              </ErrorContainer>
              {!isComplianceAdmin && (
                <SearchFilterMenu
                  topics={approvedThemeList}
                  channelData={filteredChannelList}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItemsRequest}
                  topicSelected={topicSelected}
                  setTopicSelected={setTopicSelectedRequest}
                  exploreSetState={exploreSetState}
                />
              )}
           
            </Flex>
            </SearchWrapper>
          )}
        </Formik>
    </div>
  );
};

export default AdvisorSearch;
