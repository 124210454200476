/* eslint-disable react-hooks/exhaustive-deps */
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import { get, isEmpty, camelCase, startCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  LoaderWrapper,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../components';
import { viewCampaignInfo } from '../../../services/campaign';
import { themesData } from '../../../utils';
import RescheduleModal from '../DripDashboardTable/CampaignMenu/RescheduleModal';
import EditScheduleModal from '../EditScheduleModal/EditScheduleModal';
import ResendCampaignModal from '../ResendCampaignModal/ResendCampaignModal';

const ViewInfoModal = ({
  isOpen,
  handleClose,
  campaignId,
  type,
  seriesId,
  title,
  status,
  toggleFromViewInfo,
  toggleEditScheduleModal,
  socialNetworkData,
  campaignRequest,
  toggleFromSendToMore,
  handleCheckboxClick,
  handleAlphabeticalSort,
  handleSelectAll,
  openResendModalHandler,
  resendModal,
  toggleResendModal,
  selectedEmailContacts,
  selectedFbContacts,
  selectedTwContacts,
  selectedLnContacts,
  selectedEmailContactsOG,
  selectedFbContactsOG,
  selectedTwContactsOG,
  selectedLnContactsOG,
  setEmailList,
  contactGroups,
  setContactGroups,
  emailList,
  campaignType,
  ascFbOrder,
  ascLnOrder,
  nextHandler,
  segment,
  start_date,
  segmentList,
  selectedSegments,
  setSelectedSegments
}) => {
  console.log('SERIES ID - - -- - ', seriesId);
  const [loading, setLoading] = useState(false);
  const [curPlatformCampaign, setCurPlatformCampaign] = useState([]);
  const [showEditSchedule, toggleEditSchedule] = useState(false);
  const [seriesIdLocal, setSeriesIdLocal] = useState('');
  // const [editScheduleModal, toggleEditScheduleModal] = useState(false);

  const platformMapper = {
    fb: 'Facebook',
    tw: 'Twitter',
    em: 'Email',
    ln: 'Linkedin',
  };


  const curCampaignDetailsHandler = (resObj) => {
    let campaign = {
      id: get(resObj, 'campaign_id', ''),
      campaign_id: get(resObj, 'campaign_id', ''),
      platform: startCase(camelCase(get(resObj, 'platform', ''))),
      status: get(resObj, 'status', ''),
      subTitle: get(resObj, 'campaign_name', ''),
      dateTime: get(resObj, 'datetime', ''),
      destination: get(resObj, 'destination', ''),
      leadCapture: get(resObj, 'lead_referral_capture', ''),
      totalFailed: get(resObj, 'total_failed', ''),
      totalRecipients: get(resObj, 'total_recipients', ''),
      totalContacts: get(resObj, 'total_contacts', ''),
      destination_id: get(resObj, 'destination_id', ''),
      destination_id_hash: get(resObj, 'destination_id_hash', ''),
      snetwork_act_id: get(resObj, 'snetwork_uid', ''),
      custom_text: get(resObj, 'custom_text', ''),
      isPartiallyCompleted: get(resObj, 'is_partially_completed', false)
    };
    return campaign;
  };

  const curCampaignDetailsHandlerSeries = (resObj) => {
    let campaigns = resObj.map((item) => {
      let keys = Object.keys(item);

      let obj = { name: item[keys[0]][0].campaign_name, data: item[keys[0]] };

      return obj;
    });

    return campaigns;
  };

  useEffect(() => {
    let resArray = [];
    if (campaignId) {
      setLoading(true);
      setSeriesIdLocal('');
      viewCampaignInfo(campaignId, 'campaign')
        .then((res) => {
          setLoading(false);
          resArray = get(res, 'result.data', []);
          let campaigns = resArray.map((campaign) => {
            if(campaign.status === 'scheduled' || campaign.status === 'approval_pending')
              toggleEditSchedule(true);
            return curCampaignDetailsHandler(campaign);
          });
          setCurPlatformCampaign([...campaigns]);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (seriesId) {
      setSeriesIdLocal(seriesId);
      setLoading(true);
      viewCampaignInfo(seriesId, type)
      .then((res) => {
        setLoading(false);
        resArray = get(res, 'result.data', []);
        let cmp = curCampaignDetailsHandlerSeries(resArray);
        // let campaigns = resArray.map((campaign) =>
        //   curCampaignDetailsHandler(campaign)
        // );
        setCurPlatformCampaign(cmp);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    }
  }, [campaignId, seriesId]);

  const getStatus = (campaign) => {
    console.log(campaign);
    if(get(campaign, 'status', '-') !== 'NA') {
      if(!get(campaign, 'isPartiallyCompleted', false)) {
        return startCase(camelCase(get(campaign, 'status', '-')));
      } else {
        return 'Partially Completed';
      }
    } else {
      return 'N/A';
    }
  }

  const getStatusForSeries = (campaign) => {
    if(get(campaign, 'status', '-') !== 'NA') {
      if(!get(campaign, 'is_partially_completed', false)) {
        return startCase(camelCase(get(campaign, 'status', '-')));
      } else {
        return 'Partially Completed';
      }
    } else {
      return 'N/A';
    }
  }


  return (
    <>
      <Modal size="medium75" isOpen={isOpen} onRequestClose={handleClose}>
        <ModalHeader>
          <div
            data-testid="campaign-viewInfo-modal"
            style={{
              fontSize: '18px',
              color: '#030303',
              fontWeight: 400,
              padding: '0px 86px',
            }}
          >
            {title}
          </div>
          <ModalCloseButton onClick={handleClose} />
        </ModalHeader>
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <LoaderWrapper isLoading={loading}>
            {console.log('CAMPAGIN ID - - --', campaignId)}
            {campaignId && (
              <Flex
                width="94%"
                justifyContent="center"
                style={{ margin: '0px 20px 0px 20px' }}
              >
                <Flex
                  width="25%"
                  justifyContent="center"
                  fontSize="12px"
                  color="#6351ed"
                >
                  Platforms
                </Flex>
                <Flex
                  width="25%"
                  justifyContent="center"
                  fontSize="12px"
                  color="#6351ed"
                >
                  Destination
                </Flex>
                <Flex
                  width="30%"
                  justifyContent="center"
                  fontSize="12px"
                  color="#6351ed"
                >
                  Date/Time
                </Flex>
                <Flex
                  width="25%"
                  justifyContent="center"
                  fontSize="12px"
                  color="#6351ed"
                >
                  Lead / Referral Capture?
                </Flex>
                <Flex
                  width="25%"
                  justifyContent="center"
                  fontSize="12px"
                  color="#6351ed"
                >
                  Email Sent / Total Contacts / Failed
                </Flex>
                <Flex
                  width="25%"
                  justifyContent="center"
                  fontSize="12px"
                  color="#6351ed"
                >
                  Status
                  <FontAwesomeIcon title="Please reach out to your TIFIN Clout client success partner for more details" style={{position: 'relative', cursor: 'pointer', top: '3px', left: '2px'}} icon={faQuestionCircle} />
                </Flex>
              </Flex>
            )}
            {!isEmpty(curPlatformCampaign) &&
              campaignId &&
              curPlatformCampaign.map((campaign, idx) => (
                <div
                  key={get(campaign, 'id', idx)}
                  data-testid="viewinfo_onetime"
                >
                  {/* {type === 'series' && (
                    <div
                      style={{
                        fontSize: '18px',
                        color: '#030303',
                        fontWeight: 500,
                        padding: '0px 10px',
                        textAlign: 'left',
                        margin: '10px 0px',
                      }}
                    >
                      {get(campaign, 'subTitle', '')}
                    </div>
                  )} */}

                  <Flex
                    flexDirection="column"
                    style={{
                      maxHeight: '300px',
                      overflowX: 'hidden',
                      overflowY: 'auto',
                    }}
                  >
                    <Flex width="100%" style={{ minHeight: '54px' }}>
                      <Flex
                        width="95%"
                        justifyContent="center"
                        style={{
                          backgroundColor: '#fff',
                          border: '1px solid #ececec',
                          margin: '7px 12.3px 7px 20px',
                          // height: '40.4px',
                          padding: '10px 9px 7px 0',
                        }}
                      >
                        <Flex
                          width="25%"
                          justifyContent="center"
                          fontSize="12px"
                          color={themesData.charcoalGrey}
                        >
                          {get(campaign, 'platform', '-')}
                        </Flex>
                        <Flex
                          width="25%"
                          justifyContent="center"
                          fontSize="12px"
                          color={themesData.charcoalGrey}
                        >
                          {get(campaign, 'destination', '-')}
                        </Flex>
                        <Flex
                          width="30%"
                          justifyContent="center"
                          fontSize="12px"
                          color={themesData.charcoalGrey}
                        >
                          {type === 'automated' ? '--' : campaign.dateTime
                            ? moment(new Date(campaign.dateTime)).format(
                                'Do MMMM YYYY, h:mm a'
                              )
                            : '-'}
                        </Flex>
                        <Flex
                          width="25%"
                          justifyContent="center"
                          fontSize="12px"
                          color={themesData.charcoalGrey}
                        >
                          {get(campaign, 'leadCapture', '-')}
                        </Flex>
                        <Flex
                          width="25%"
                          justifyContent="center"
                          fontSize="12px"
                          color={themesData.charcoalGrey}
                        >
                          {get(campaign, 'totalRecipients', '-')} /{' '}
                          {get(campaign, 'totalContacts', '-')} /{' '}
                          {get(campaign, 'totalFailed', '-')}
                        </Flex>
                        <Flex
                          width="25%"
                          justifyContent="center"
                          fontSize="12px"
                          color={themesData.charcoalGrey}
                        >
                          {getStatus(campaign)}
                          {/* {get(campaign, 'status', '-')} */}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </div>
              ))}

            {!isEmpty(curPlatformCampaign) &&
              seriesIdLocal &&
              curPlatformCampaign.map((seriesCampaign, idx) => {
                console.log('CUR PLATFORM CAMPAIGN ', curPlatformCampaign);
                return (
                  <div key={get(seriesCampaign, 'id', idx)}>
                    {type === 'series' && (
                      <div
                        style={{
                          fontSize: '18px',
                          color: '#030303',
                          fontWeight: 500,
                          padding: '0px 10px',
                          textAlign: 'left',
                          margin: '10px 0px',
                        }}
                      >
                        {get(seriesCampaign, 'name', '')}
                      </div>
                    )}
                    <Flex
                      width="94%"
                      justifyContent="center"
                      style={{ margin: '0px 20px 0px 20px' }}
                    >
                      <Flex
                        width="25%"
                        justifyContent="center"
                        fontSize="12px"
                        color="#6351ed"
                      >
                        Platforms
                      </Flex>
                      <Flex
                        width="25%"
                        justifyContent="center"
                        fontSize="12px"
                        color="#6351ed"
                      >
                        Destination
                      </Flex>
                      <Flex
                        width="30%"
                        justifyContent="center"
                        fontSize="12px"
                        color="#6351ed"
                      >
                        Date/Time
                      </Flex>
                      <Flex
                        width="25%"
                        justifyContent="center"
                        fontSize="12px"
                        color="#6351ed"
                      >
                        Lead / Referral Capture?
                      </Flex>
                      <Flex
                        width="25%"
                        justifyContent="center"
                        fontSize="12px"
                        color="#6351ed"
                      >
                        Email Sent / Failed
                      </Flex>
                      <Flex
                        width="25%"
                        justifyContent="center"
                        fontSize="12px"
                        color="#6351ed"
                      >
                        Status
                        <FontAwesomeIcon title="Please reach out to your TIFIN Clout client success partner for more details" style={{position: 'relative', cursor: 'pointer', top: '3px', left: '2px'}} icon={faQuestionCircle} />
                      </Flex>
                    </Flex>

                    {get(seriesCampaign, 'data', []).map((campaign, idx) => {
                      return (
                        <Flex
                          data-testid="viewinfo_series"
                          flexDirection="column"
                          style={{
                            maxHeight: '300px',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                          }}
                        >
                          <Flex width="100%" style={{ minHeight: '54px' }}>
                            <Flex
                              width="95%"
                              justifyContent="center"
                              style={{
                                backgroundColor: '#fff',
                                border: '1px solid #ececec',
                                margin: '7px 12.3px 7px 20px',
                                // height: '40.4px',
                                padding: '10px 9px 7px 0',
                              }}
                            >
                              <Flex
                                width="25%"
                                justifyContent="center"
                                fontSize="12px"
                                color={themesData.charcoalGrey}
                              >
                                {startCase(
                                  camelCase(get(campaign, 'platform', '-'))
                                )}
                              </Flex>
                              <Flex
                                width="25%"
                                justifyContent="center"
                                fontSize="12px"
                                color={themesData.charcoalGrey}
                              >
                                {get(campaign, 'destination', '-')}
                              </Flex>
                              <Flex
                                width="30%"
                                justifyContent="center"
                                fontSize="12px"
                                color={themesData.charcoalGrey}
                              >
                                {campaign.datetime
                                  ? moment(new Date(campaign.datetime)).format(
                                      'Do MMMM YYYY, h:mm a'
                                    )
                                  : '-'}
                              </Flex>
                              <Flex
                                width="25%"
                                justifyContent="center"
                                fontSize="12px"
                                color={themesData.charcoalGrey}
                              >
                                {get(campaign, 'lead_referral_capture', '-')}
                              </Flex>
                              <Flex
                                width="25%"
                                justifyContent="center"
                                fontSize="12px"
                                color={themesData.charcoalGrey}
                              >
                                {get(campaign, 'total_recipients', '-')} /{' '}
                                {get(campaign, 'total_failed', '-')}
                              </Flex>
                              <Flex
                                width="25%"
                                justifyContent="center"
                                fontSize="12px"
                                color={themesData.charcoalGrey}
                              >
                                {getStatusForSeries(campaign)}
                                {/* {get(campaign, 'status', '-')} */}
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </div>
                );
              })}
              {!seriesIdLocal && (
                <Flex 
                  width={showEditSchedule ? '25%' : '13%'} 
                  justifyContent='space-evenly' 
                  marginTop='10px'
                >
                  {console.log('STATUS - - -- - - ', status)}
                  {type !== 'automated' && showEditSchedule && (
                    <label 
                      style={{textDecoration: 'underline', cursor: 'pointer', color: '#6351ed'}}
                      onClick={() => {
                        // setOpenViewInfoModal(false);
                        toggleFromViewInfo(true);
                        toggleEditScheduleModal(true);
                      }}
                    >
                      Edit Schedule
                    </label>
                  )}
                  {type !== 'automated' && (status === 'scheduled' || status === 'in_progress' || status === 'completed' || status === 'approval_pending') && (
                    <label 
                      style={{textDecoration: 'underline', cursor: 'pointer', color: '#6351ed'}}
                      onClick={() => {
                        console.log('CUR PLATFORM CAMPAIGN - - - - -', curPlatformCampaign);
                        openResendModalHandler(curPlatformCampaign)
                      }}
                    >
                        Send to More
                    </label>
                  )}
                </Flex>
              )}
          </LoaderWrapper>
        </ModalBody>
      </Modal>
      {resendModal && (
        <ResendCampaignModal 
          close={() => toggleResendModal(false)} 
          campaignId={campaignId}
          socialNetworkData={socialNetworkData}
          contactGroups={contactGroups}
          campaignRequest={campaignRequest}
          toggleFromSendToMore={toggleFromSendToMore}
          // toggleEditScheduleModal={toggleEditScheduleModal}
          handleCheckboxClick={handleCheckboxClick}
          handleAlphabeticalSort={handleAlphabeticalSort}
          handleSelectAll={handleSelectAll}
          selectedEmailContacts={selectedEmailContacts}
          selectedFbContacts={selectedFbContacts}
          selectedTwContacts={selectedTwContacts}
          selectedLnContacts={selectedLnContacts}
          selectedEmailContactsOG={selectedEmailContactsOG}
          selectedFbContactsOG={selectedFbContactsOG}
          selectedTwContactsOG={selectedTwContactsOG}
          selectedLnContactsOG={selectedLnContactsOG}
          setEmailList={setEmailList}
          setContactGroups={setContactGroups}
          emailList={emailList}
          campaignType={campaignType}
          ascFbOrder={ascFbOrder}
          ascLnOrder={ascLnOrder}
          nextHandler={nextHandler}
          segment={segment}
          segmentList={segmentList}
          selectedSegments={selectedSegments}
          setSelectedSegments={setSelectedSegments}
        />
      )}
      
      {/* {editScheduleModal && (
        <EditScheduleModal close={() => toggleEditScheduleModal(false)} />
      )} */}
    </>
  );
};

export default ViewInfoModal;
