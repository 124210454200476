import { Flex } from '@rebass/grid';
import React from 'react';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '..';
import { themesData } from '../../utils';

const CustomTopicPopup = ({
  handleClose,
  setCustomTopicName,
  customTopicName,
  // TopicClick,
}) => {
  return (
    <Modal
      bgColor="white"
      height="40%"
      size="small"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      isOpen
    >
      {/* <ModalHeader
        id="header"
        style={{ textAlign: 'center', color: themesData.charcoalGrey }}
      >
        <Flex fontSize="0.9rem" color="black" style={{ fontWeight: '600' }}>
          Select Content
        </Flex>
        <ModalCloseButton onClick={handleClose} size="25px" />
        <hr style={{ width: '100%' }} />
      </ModalHeader> */}
      <ModalBody style={{ overflowY: 'hidden' }}>
        <Flex
          width="100%"
          justifyContent="space-between"
          style={{ fontSize: '1rem', color: 'black', fontWeight: '600' }}
          mt="15px"
          mb="10px"
          flexDirection="column"
        >
          Please Specify
          <Input
            placeholderColor="#667378"
            margin="25px 0px"
            style={{ width: '100%' }}
            type="text"
            name="customTopic"
            id="customTopic"
            placeholder="Enter here"
            value={customTopicName}
            onChange={(event) => {
              setCustomTopicName(event.target.value);
            }}
          />
        </Flex>

        <div
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            padding: '10px',
          }}
        >
          {/* <hr style={{ width: '100%' }} /> */}
          <Flex justifyContent="center" py="10px">
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#4E6780"
              margin="0px 10px"
              padding="7px 50px"
              borderColor="#4E6780"
              onClick={() => {
                setCustomTopicName('');
                // TopicClick();
                handleClose(false);
              }}
            >
              Cancel
            </Button>
            <Button
              testId="customTopic_save"
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#6351ed"
              margin="0px 10px"
              padding="7px 50px"
              borderColor="#6351ed"
              //   disabled={isEmpty(selectedContent)}
              onClick={() => handleClose(true)}
            >
              Save
            </Button>
          </Flex>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CustomTopicPopup;
