/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import CampaignFooterButtons from '../CampaignFooterButtons/CampaignFooterButtons';
import AddContent from '../AddContent/AddContent';
import { CreateContainer } from '../CreateCampaign/CreateCampaign.styles';
import SelectTemplate from '../SelectTemplate/SelectTemplate';
import SetObjectives from '../SetObjectives/SetObjectives';
import { Stepper } from '../Stepper/Stepper';
import { Flex } from '@rebass/grid';
// import { FooterBtnsWrapper } from '../DripCampaignContainerV2.styles';
import PreviewAndSchedule from '../Preview&Schedule/PreviewAndSchedule';
import { SocialPreview } from '../SocialPreview/SocialPreview';
import { useHistory } from 'react-router-dom';
import { getEmailTemplates } from '../../../services/campaign';
import { get } from 'lodash';

const AllPlatformsContainer = ({
  socialNetworkData,
  curFlowHandler,
  contactGroups,
  isPremiumAdvisor,
  userData,
  campaignSetState,
  campaignSelectedArticles,
  setObjectivesTabData,
  campaignCreateFlow,
  setTemplateData,
  setTemplateBase,
  campaignScheduleTimings,
  campaignScheduleTypes,
  // themeNames,
  createMode,
  endCreateFlow,
  campaignId,
  getAccountId,
  redirectionCampaign,
  // savedTemplate,
  defaultTemplates,
  selectedIndex,
  isEdit,
  isFromAddCampaign,
  series,
  campaignSummaries,
  curFlow,
  seriesId,
  isadvMode,
  isAdvisorApprovalMode,
  campaignStatus,
  userRole,
  campaignLeadReferralData,
  bookmarkArticlesLoader,
  recommendedArticlesLoader,
  myArticlesLoader,
  checkboxStatus,
  socialSelectedCampId,
  complianceApplicable,
  complianceApproved,
  emAdvApproval,
  socialAdvApproval,
  conListRecArticlesLoader,
  setConListRecArticlesLoader,
  fromDashboard,
  reviewCampaign,
  redirectCascadeAdvisor,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  firmsArray,
  isAdmin,
  isEnterpriseAdvisor,
  loginRequest,
  segmentList,
  setSegmentList,
  isGlobalCompliance,
  companyId
}) => {
  console.log('EMAIL ONLY CONTAINER');
  const [loading, setLoading] = useState(false);
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (campaignSelectedArticles) setSelectedArticles(campaignSelectedArticles);
   
  }, [campaignSelectedArticles]);

  useEffect(() => {
    // if (selectedIndex === 2) 
    //   history.push('/customize-email');
    if ((get(setObjectivesTabData, 'isCascaded', 'false') !== 'true' && selectedIndex === 2) || (get(setObjectivesTabData, 'isCascaded', 'false') === 'true' && selectedIndex === 1)) {
      history.push('/customize-email');
    }
  }, [selectedIndex]);

  // const handleNextClick = (skip) => {
  //   console.log('SET TEMPLATE DATA -- - ', setTemplateData);
  //   if (selectedIndex === 2)
  //     campaignSetState({ selectedArticles: [...selectedArticles] });
  //   if (selectedIndex < stepList.length - 1) {
  //     if (skip) campaignSetState({ selectedIndex: selectedIndex + 2 });
  //     else campaignSetState({ selectedIndex: selectedIndex + 1 });
  //   } else if (selectedIndex === stepList.length - 1) {
  //     if (createMode === 'series') curFlowHandler('');
  //     else endCreateFlow();
  //   }
  // };

  const handleCascadedNext = () => {
    if(selectedIndex === 0) {
      campaignSetState({selectedIndex: 1});
      history.push(`/customize-email`);
    }
  }

  const handleNextClick = (skip) => {
    const newStateObject = {};
    if (selectedIndex === 0) {
      const filterArray =
        userRole === 'admin' ? ['default', 'cascade'] : ['default', 'cascade'];
      getEmailTemplates(filterArray)
        .then((response) => {
          const fetchedTemplates = get(response, 'result.data.templates', []);
          newStateObject.defaultTemplates = fetchedTemplates;
          handleStepChange(skip, newStateObject);
        })
        .catch((err) => console.log('ERROR - - - -', err));
    } 
    // else if (selectedIndex === 2) {
    //   newStateObject.selectedArticles = [...selectedArticles];
    //   handleStepChange(skip, newStateObject);
    // } 
    else handleStepChange(skip, newStateObject);
  };

  const handleStepChange = (skip, payload) => {
    if (selectedIndex < stepList.length - 1) {
      if (skip)
        campaignSetState({ selectedIndex: selectedIndex + 2, ...payload });
      else campaignSetState({ selectedIndex: selectedIndex + 1, ...payload });
    } else if (selectedIndex === stepList.length - 1) {
      if (createMode === 'series') curFlowHandler('');
      else endCreateFlow();
    }
    setLoading(false);
  };

  const handleBackButtonClick = (skip) => {
    if (selectedIndex === 0) curFlowHandler('');
    else {
      if (skip) campaignSetState({ selectedIndex: selectedIndex - 2 });
      else campaignSetState({ selectedIndex: selectedIndex - 1 });
    }
  };

  const stepList = [
    <span>
      Select
      <br />
      Recipients
    </span>,
    <span>
      Select
      <br />
      Template
    </span>,
    // <span>
    //   Add
    //   <br />
    //   Content
    // </span>,
    <span>
      Customize
      <br />
      Email
    </span>,
    <span>
      Preview &
      <br />
      Send/Schedule
    </span>,
    <span>
      Customize &
      <br />
      Schedule
    </span>,
  ];

  const stepListATS = [
    <span>
      Select
      <br />
      Recipients
    </span>,
    <span>
      Personalize
      <br />
      Email
    </span>,
    <span>
      Preview &
      <br />
      Send/Schedule
    </span>,
    <span>
      Customize &
      <br />
      Schedule
    </span>
  ];

  return (
    <Flex display="flex" flexDirection="column">
      <Flex display="flex" flexDirection="column" marginBottom="30px" marginTop="80px">
        <div
          style={{
            margin: '4% 0%',
            // overflow: 'auto',
          }}
        >
          {selectedIndex === 0 && (
            <CreateContainer
              mt="0px"
              height={
                selectedIndex === 0
                  ? createMode === 'series'
                    ? '700px'
                    : 'auto'
                  : ''
              }
              style={{ justifyContent: 'flex-start' }}
            >
              <SetObjectives
                curFlow="both"
                createMode={createMode}
                contactGroups={contactGroups}
                socialNetworkData={socialNetworkData}
                handleBack={handleBackButtonClick}
                handleNext={get(setObjectivesTabData, 'isCascaded', 'false') === 'true' ? handleCascadedNext : handleNextClick}
                campaignSetState={campaignSetState}
                setObjectivesTabData={setObjectivesTabData}
                campaignCreateFlow={campaignCreateFlow}
                campaignId={campaignId}
                endCreateFlow={endCreateFlow}
                getAccountId={getAccountId}
                isEdit={isEdit}
                isFromAddCampaign={isFromAddCampaign}
                series={series}
                seriesId={seriesId}
                loading={loading}
                setLoading={setLoading}
                campaignStatus={campaignStatus}
                complianceApplicable={complianceApplicable}
                campaignScheduleTypes={campaignScheduleTypes}
                campaignScheduleTimings={campaignScheduleTimings}
                campaignLeadReferralData={campaignLeadReferralData}
                campaignSummaries={campaignSummaries}
                campaignSelectedArticles={campaignSelectedArticles}
                isAdvisorApprovalMode={isAdvisorApprovalMode}
                checkboxStatus={checkboxStatus}
                isadvMode={isadvMode}
                setConListRecArticlesLoader={setConListRecArticlesLoader}
                isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
                isFirmLevelAdmin={isFirmLevelAdmin}
                firmSettings={firmSettings}
                firmsArray={firmsArray}
                segmentList={segmentList}
                setSegmentList={setSegmentList}
                stepList={get(setObjectivesTabData, 'isCascaded', 'false') === 'true' ? stepListATS : stepList}
                selectedIndex={selectedIndex}
              />
            </CreateContainer>
          )}
          {get(setObjectivesTabData, 'isCascaded', 'false') !== 'true' && selectedIndex === 1 && (
            <CreateContainer
              mt="0px"
              style={{ justifyContent: 'flex-start', maxWidth: '91vw' }}
              height="70vh"
              padding="9px 40px"
            >
              <SelectTemplate
                handleBack={handleBackButtonClick}
                handleNext={handleNextClick}
                setTemplateData={setTemplateData}
                campaignSetState={campaignSetState}
                loading={loading}
                setLoading={setLoading}
                campaignId={campaignId}
                endCreateFlow={endCreateFlow}
                setObjectivesTabData={setObjectivesTabData}
                defaultTemplates={defaultTemplates}
                curFlow="both"
                stepList={get(setObjectivesTabData, 'isCascaded', 'false') === 'true' ? stepListATS : stepList}
                selectedIndex={selectedIndex}
              />
            </CreateContainer>
          )}
          {/* {selectedIndex === 2 && (
            <AddContent
              createMode={createMode}
              curFlow="both"
              handleBack={handleBackButtonClick}
              handleNext={handleNextClick}
              isPremiumAdvisor={isPremiumAdvisor}
              campaignSetState={campaignSetState}
              campaignId={campaignId}
              endCreateFlow={endCreateFlow}
              selectedArticles={selectedArticles}
              setSelectedArticles={setSelectedArticles}
              bookmarkArticlesLoader={bookmarkArticlesLoader}
              recommendedArticlesLoader={recommendedArticlesLoader}
              myArticlesLoader={myArticlesLoader}
              conListRecArticlesLoader={conListRecArticlesLoader}
            />
          )} */}
          {/* {selectedIndex === 3 && (
            <CreateContainer mt="0px" style={{ justifyContent: 'flex-start' }}>
              <CustomizeEmail
                handleBack={handleBackButtonClick}
                handleNext={handleNextClick}
                customizeData={{}}
              />
            </CreateContainer>
          )} */}
          {(get(setObjectivesTabData, 'isCascaded', 'false') === 'true' ? selectedIndex === 2 : selectedIndex === 3) && (
            <CreateContainer
              mt="0px"
              style={{ justifyContent: 'center' }}
              height='auto'
            >
              <PreviewAndSchedule
                handleBack={handleBackButtonClick}
                handleNext={handleNextClick}
                campaignSetState={campaignSetState}
                campaignScheduleTimings={campaignScheduleTimings}
                setObjectivesTabData={setObjectivesTabData}
                loader={loading}
                endCreateFlow={endCreateFlow}
                getAccountId={getAccountId}
                campaignId={campaignId}
                seriesId={seriesId}
                redirectionCampaign={redirectionCampaign}
                campaignScheduleTypes={campaignScheduleTypes}
                curFlow={curFlow}
                isadvMode={isadvMode}
                isAdvisorApprovalMode={isAdvisorApprovalMode}
                isEdit={isEdit}
                campaignStatus={campaignStatus}
                userData={userData}
                selectedArticles={campaignSelectedArticles || []}
                campaignLeadReferralData={campaignLeadReferralData}
                setTemplateBase={setTemplateBase}
                complianceApplicable={complianceApplicable}
                complianceApproved={complianceApproved}
                emAdvApproval={emAdvApproval}
                socialAdvApproval={socialAdvApproval}
                fromDashboard={fromDashboard}
                reviewCampaign={reviewCampaign}
                redirectCascadeAdvisor={redirectCascadeAdvisor}
                createMode={createMode}
                isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
                isFirmLevelAdmin={isFirmLevelAdmin}
                firmSettings={firmSettings}
                isAdmin={isAdmin}
                isEnterpriseAdvisor={isEnterpriseAdvisor}
                loginRequest={loginRequest}
                stepList={get(setObjectivesTabData, 'isCascaded', 'false') === 'true' ? stepListATS : stepList}
                selectedIndex={selectedIndex}
                isGlobalCompliance={isGlobalCompliance}
                companyId={companyId}
              />
            </CreateContainer>
          )}
          {(get(setObjectivesTabData, 'isCascaded', 'false') === 'true' ? selectedIndex === 3 : selectedIndex === 4) && (
            <SocialPreview
              createMode={createMode}
              setObjectivesTabData={setObjectivesTabData}
              campaignSelectedArticles={
                get(setObjectivesTabData, 'webinar.isWebinar', false)
                  ? [
                      get(setObjectivesTabData, 'webinar', {}),
                      ...selectedArticles,
                    ]
                  : campaignSelectedArticles
              }
              handleBack={handleBackButtonClick}
              handleNext={handleNextClick}
              loader={loading}
              getAccountId={getAccountId}
              campaignId={campaignId}
              seriesId={seriesId}
              redirectionCampaign={redirectionCampaign}
              campaignSummaries={campaignSummaries}
              campaignScheduleTimings={campaignScheduleTimings}
              campaignScheduleTypes={campaignScheduleTypes}
              isEdit={isEdit}
              curFlow={curFlow}
              endCreateFlow={endCreateFlow}
              userData={userData}
              campaignLeadReferralData={campaignLeadReferralData}
              isadvMode={isadvMode}
              isAdvisorApprovalMode={isAdvisorApprovalMode}
              campaignStatus={campaignStatus}
              checkboxStatus={checkboxStatus}
              socialSelectedCampId={socialSelectedCampId}
              complianceApplicable={complianceApplicable}
              fromDashboard={fromDashboard}
              reviewCampaign={reviewCampaign}
              redirectCascadeAdvisor={redirectCascadeAdvisor}
              isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
              isFirmLevelAdmin={isFirmLevelAdmin}
              firmSettings={firmSettings}
              isAdmin={isAdmin}
              isEnterpriseAdvisor={isEnterpriseAdvisor}
              loginRequest={loginRequest}
              stepList={get(setObjectivesTabData, 'isCascaded', 'false') === 'true' ? stepListATS : stepList}
              selectedIndex={selectedIndex}
              isGlobalCompliance={isGlobalCompliance}
              companyId={companyId}
            />
          )}
        </div>
      </Flex>
      {/* <FooterBtnsWrapper>
        <CampaignFooterButtons
          enableNext={!handleValidation()}
          nextHandler={handleNextClick}
          backHandler={handleBackButtonClick}
          loading={loading}
          saveAndCloseHandler={handleSaveAndClose}
          padding="10px 0px"
        />
      </FooterBtnsWrapper> */}
    </Flex>
  );
};

export default AllPlatformsContainer;
