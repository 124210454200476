/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Box, Flex } from '@rebass/grid';
import {
  faTwitter,
  faLinkedinIn,
  faGoogle,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import { get, isEmpty, find, cloneDeep } from 'lodash';
import {
  RadioInput,
} from './AdminConfigureContainer.styles';

import {
  SocialLoginButtons,
  EmailSettingsModal,
  LoaderWrapper,
  Button,
  SocialCheckBoxes,
  Tabs,
  TabList,
  TabTypeThree,
  ErrorContainer,
} from '../../components';
import ChannelSettings from './ChannelSettings';
import {
  getContactsGroups,
  redTailLogin,
  updateComplianceOption,
  updateMembersOption,
  updateMemberSettings,
  updateFirmSettings,
} from '../../services/configure';
import { actions as contactsActions } from '../../redux/modules/contacts.module';
import { actions as socialNetworkActions } from '../../redux/modules/socialNetwork.module';
import { actions as loginActions } from '../../redux/modules/login.module';
import {
  getRoleType,
  getUserDetails,
  getFirmSettings
} from '../../redux/selector/login.selectors';
import {
  getContactListForEmail,
  ROLE_TYPES,
  salesforceSettingsMapper,
  salesforceTogglesMapper,
} from '../../utils';

import { TabConfig } from './screenConfigure.styles';
import { getSocialAccountURLs } from '../../services/dashboard';
import { emailSmtpConnect } from '../../services/configure';
import EmailList from './EmailList';
import RedTailModal from './RedTailModal';
import SendgridModal from './SendgridModal/SendgridModal';
import AdminRadio from './AdminRadioButton';
import { BorderWhiteBox } from '../DashboardContainer/DashboardContainer.styles';
import { getComplianceSettingsAdmin } from '../../services/configure/index';
import MembersTable from './MembersTable/MembersTable';
import Profile from './Profile';
import AdvisorSettings from '../AdvisorSettings/AdvisorSettings';
import BccField from '../../components/BccField';
import { ToggleButton } from '../../containers/ConfigureContainer/screenConfigure.styles';
import "./MembersTable/MembersTable.css";
import { faWeight } from '@fortawesome/free-solid-svg-icons';
const ConfigureContainer = ({
  contactError,
  contactsList,
  contactsRequest,
  contactsDelete,
  contactsCreate,
  userData,
  isContactsFetching,
  socialNetworkData,
  socialNetworkDelete,
  socialNetworkDataFetching,
  loginRequest,
  contactGroupsList,
  selectedContactGroup,
  paginationData,
  navigateAfter,
  socialNetworkStatusRequest,
  selectedContactGroupDefault,
  userDetails,
  complianceAdmin,
  selectedTabIndex,
  setSelectedTabIndexRequest,
  disabledPlatforms,
  assetManagerAdmin,
  adminEnterprise,
  customContactGroupList,
  contactsCount,
  contactGroups,
  roleType,
  userId,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings
}) => {
  console.log('ASSET MANAGER ADMIN - - - ', assetManagerAdmin, 'COMPLIANCE ADMIN - - - - -', complianceAdmin);
  const complianceOptionMapper = {
    'always_on': 'on',
    'always_off': 'off',
    'optional': 'optional'
  }

  const getComplianceOption = (firmCompliance, companyId) => {
    const choice = get(firmCompliance, `${[companyId]}.compliance_is`, 'optional');
    return complianceOptionMapper[choice];
  }
  
  const toggleAll = {
    share_content: useRef(null),
    add_content: useRef(null),
    lead_capture: useRef(null),
  };
  const [socialAccountURL, setSocialAccountURL] = useState(null);
  const [isUpdateContact, setIsUpdateContact] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [redTailModal, setRedTailModal] = useState(false);
  const [sendGridModal, setSendGridModal] = useState(false);
  const [redTailError, setRedTailError] = useState(false);
  const [bccList, setBccList] = useState(
    !isEmpty(get(userData, 'bcc_emails', []))
      ? get(userData, 'bcc_emails', []).join()
      : ''
  );
  const [error, setError] = useState('');
  const [toggleHoldings, setToggleHoldings] = useState('true');
  const [toggleEditor, setToggleEditor] = useState('true');
  const [toggleClients, setToggleClients] = useState('true');
  const [toggleCascade, setToggleCascade] = useState('false');

  const[toggleNewsletters, setToggleNewsletters] = useState('true');
  const[toggleEcards, setToggleEcards] = useState('true');
  const[toggleEvents, setToggleEvents] = useState('true');
  const[toggleFirmUpdates, setToggleFirmUpdates] = useState('false');
  const[toggleFinancialNewsletters, setToggleFinancialNewsletters] = useState('true');
  const[toggleUnsubscribe, setToggleUnsubscribe] = useState('true');
  const [toggleGeneralMarketing, setToggleGeneralMarketing] = useState('false');
  // const [complianceOption, setComplianceOption] = useState('');
  //const [enterpriseOption, setEnterpriseOption] = useState('');
  const [enterpriseAlias, setEnterpriseAlias] = useState('enterprise');
  const [firmAlias, setFirmAlias] = useState('firm');
  const [firmArray, setFirmArray] = useState([]);
  const [tooltip , setTooltip] = useState('');
  const [compliance, setCompliance] = useState((isEnterpriseLevelAdmin || isFirmLevelAdmin) ? getComplianceOption(get(firmSettings, 'firm_compliance', {}), get(userDetails, 'details.company.id', '')) : get(userDetails, 'compliance', 'optional'));
  const [addMember, setAddMember] = useState(
    get(userData, 'member_settings.add_members', '')
  );
  const [loader, setLoader] = useState(false);
  const [selectedConfigureIndex, setSelectedConfigureIndex] =
    useState(selectedTabIndex);
  const [advisorsList, setAdvisorsList] = useState([]);
  const [generalSettingsLoading, toggleGeneralSettingsLoading] =
    useState(false);
  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([
    { platform: 'ln', checked: false, isDisconnected: true },
    { platform: 'tw', checked: false, isDisconnected: true },
    { platform: 'fb', checked: false, isDisconnected: true },
    { platform: 'em', checked: false, isDisconnected: true },
  ]);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [enterpriseLevelCompliance, setEnterpriseLevelCompliance] = useState(false);
  const [salesforceSettings, updateSalesforceSettings] = useState(
    !isEmpty(get(userData, 'salesforce_settings'))
      ? userData.salesforce_settings
      : [
          { 
            label: 'Email Sent', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          {
            label: 'Email Opened',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          {
            label: 'Email Clicked',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          { 
            label: 'Email Unsubscribed', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          {
            label: 'Content Viewed',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          { 
            label: 'Lead Generated', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          // { 
          //   label: 'Clout score changed', 
          //   Enabled: true, 
          //   // 'Re-sync': false 
          // },
          { 
            label: 'Webinar Registered', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          { 
            label: 'Webinar Attended', 
            Enabled: true, 
            // 'Re-sync': false 
          },
        ]
  );


  const retrieveGroupsList = () => {
    getContactsGroups().then((response) => {
      const data = getContactListForEmail(get(response, 'result.data', []));
      console.log(data);
      if (!isEmpty(data)) {
        const hasIsDefault = data.filter((d) => d.is_default);
        const firstGroup = isEmpty(hasIsDefault)
          ? get(data, `[0].id`, '')
          : hasIsDefault[0].id;
        console.log(firstGroup);
        contactsRequest({ params: { contactGroup: firstGroup } });
      } else {
        contactsRequest({ params: { contactGroup: '' } });
      }
    });
  };

  useEffect(() => {
    console.log('[USE EFFECT] fired [selected tab index]...', selectedTabIndex);
    // console.log('[COMPLIANCE] - ', compliance);
    if (selectedTabIndex === 4) {
      toggleGeneralSettingsLoading(true);
      const companyId = get(userData, 'company', '');
      getComplianceSettingsAdmin(companyId)
        .then((response) => {
          // console.log('[GENERAL SETTINGS RESPONSE] - ', response);
          setAdvisorsList(response.result.data);
          toggleGeneralSettingsLoading(false);
        })
        .catch((err) => {
          console.log('[ERROR] - ', err);
          toggleGeneralSettingsLoading(false);
        });
    }
    getSocialAccountURLs()
      .then((res) => {
        setSocialAccountURL(res.result.data);
      })
      .catch((err) => { });
    retrieveGroupsList();
    if (window.sessionStorage.getItem('selectedIndex')) {
      console.log(typeof window.sessionStorage.getItem('selectedIndex'));
      setSelectedConfigureIndex(
        +window.sessionStorage.getItem('selectedIndex')
      );
    } else {
      setSelectedConfigureIndex(selectedTabIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabIndex]);

  useEffect(() => {
    let allShare =
      !isEmpty(advisorsList) &&
      advisorsList.every((ele) => ele.settings.share_content === 'true');
    let allAdd =
      !isEmpty(advisorsList) &&
      advisorsList.every((ele) => ele.settings.add_content === 'true');
    let allLead =
      !isEmpty(advisorsList) &&
      advisorsList.every((ele) => ele.settings.lead_capture === 'true');
    // console.log('[ALL SHARE]', allShare);
    // console.log('[ALL ADD]', allAdd);
    // console.log('[TOGGLE ALL] after - ', toggleAll);
    if (
      toggleAll.add_content.current &&
      toggleAll.share_content.current &&
      toggleAll.lead_capture.current
    ) {
      if (allShare) toggleAll.share_content.current.checked = true;
      if (allAdd) toggleAll.add_content.current.checked = true;
      if (allLead) toggleAll.lead_capture.current.checked = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleAll]);

  useEffect(() => {
    if (disabledPlatforms === '') {
      selectedCheckBoxes.forEach((element) => {
        element.checked = true;
        element.isDisconnected = false;
      });
      setSelectedCheckBoxes([...selectedCheckBoxes]);
    } else if (
      userDetails &&
      userDetails.details &&
      userDetails.details.user &&
      userDetails.details.user.member_settings &&
      userDetails.details.user.member_settings.disabled_social_platforms
    ) {
      selectedCheckBoxes.forEach((element) => {
        if (
          userDetails.details.user.member_settings.disabled_social_platforms.includes(
            element.platform
          )
        ) {
          element.checked = false;
          element.isDisconnected = true;
        } else {
          element.checked = true;
          element.isDisconnected = false;
        }
      });

      setSelectedCheckBoxes([...selectedCheckBoxes]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  // useEffect(() => {
  //   const fetchedSfSettings = get(userData, 'extra_settings.salesforce_settings', {});
  //   const fetchedSfSettingsLabels = Object.keys(fetchedSfSettings);
  //   const localSfSettingsLabels = Object.keys(salesforceSettingsMapper);
  //   const localSfTogglesLabels = Object.keys(salesforceTogglesMapper);
  //   // const newSfSettings = {};
  //   if(!isEmpty(fetchedSfSettings)) {
  //     const newSfSettings = fetchedSfSettingsLabels.map(sfSetting => {
  //       const returnObj = {};
  //       const settingIndex = localSfSettingsLabels.findIndex(setting => salesforceSettingsMapper[setting] === sfSetting);
  //       returnObj.label = localSfSettingsLabels[settingIndex];
  //       const fetchedSfToggles = fetchedSfSettings[sfSetting];
  //       const fetchedSfTogglesLabels = Object.keys(fetchedSfToggles);
  //       fetchedSfTogglesLabels.forEach(sfToggle => {
  //         const toggleIndex = localSfTogglesLabels.findIndex(toggle => salesforceTogglesMapper[toggle] === sfToggle);
  //         returnObj[localSfTogglesLabels[toggleIndex]] = fetchedSfToggles[sfToggle] === 'true'
  //       });
  //       // newSfSettings[localSfSettingsLabels[settingIndex]] = newSfToggles;
  //       return returnObj;
  //     });
  //     console.log('UPDATED SETTINGS - - - - -', newSfSettings);
  //     updateSalesforceSettings(newSfSettings)
  //   }
  // }, []);

  // useEffect(() => {
  //   const sfSettings = get(userData, 'salesforce_settings', {});
  //   if(!isEmpty(sfSettings))
  //     updateSalesforceSettings(sfSettings);
  // }, []);

  useEffect(() => {
    const firmCompliance = get(firmSettings, 'firm_compliance', {});
    const firmGeneral = get(firmSettings, 'firm_general', {});
    console.log('ISENTERPRISE LEVEL ADMIN - - - -- ', isEnterpriseLevelAdmin);
    console.log('IS FIRM LEVEL ADMIN - - - - - ', isFirmLevelAdmin);
    if(isEnterpriseLevelAdmin) {
      console.log('USER DETAILS - - -- ', userDetails);
      console.log('USER DATA - - -- ', userData);
      const firms = get(userDetails, 'details.company.firms', []).map(firm => ({
        ...firm,
        complianceIs: get(firmCompliance, `${firm.id}.compliance_is`, 'optional'),
        complianceDone: get(firmCompliance, `${firm.id}.compliance_done`,'enterprise')
      }));
      if(!isEmpty(get(firmGeneral, 'enterprise_alias', '')))
        setEnterpriseAlias(get(firmGeneral, 'enterprise_alias', 'DEFAULT_ENTERPRISE'));
      if(!isEmpty(get(firmGeneral, 'firm_alias', '')))
        setFirmAlias(get(firmGeneral, 'firm_alias', 'DEFAULT_FIRM'));
      if(!isEmpty(get(firmGeneral, 'my_clients', '')))
        setToggleClients(get(firmGeneral, 'my_clients', 'true'));
      if(!isEmpty(get(firmGeneral, 'my_editor', '')))
        setToggleEditor(get(firmGeneral, 'my_editor', 'true'));
      if(!isEmpty(get(firmGeneral, 'my_holding', '')))
        setToggleHoldings(get(firmGeneral, 'my_holding', 'true'));
      if(!isEmpty(get(firmGeneral, 'advisor_cascade_campaign', '')))
        setToggleCascade(get(firmGeneral, 'advisor_cascade_campaign', 'true'));
      setEnterpriseLevelCompliance(get(userDetails, 'compliance', 'optional'));
      setFirmArray(firms);
      if ((get(userDetails, 'details.company.marketing_pref_settings.general_newsletters', ''))) {
        setToggleNewsletters(get(userDetails, 'details.company.marketing_pref_settings.general_newsletters', 'true'));
      }

      if ((get(userDetails, 'details.company.marketing_pref_settings.general_marketing', ''))) {
        setToggleGeneralMarketing(get(userDetails, 'details.company.marketing_pref_settings.general_marketing', 'true'));
      }

      if ((get(userDetails, 'details.company.marketing_pref_settings.ecards', ''))) {
        setToggleEcards(get(userDetails, 'details.company.marketing_pref_settings.ecards', 'true'));
      }

      if ((get(userDetails, 'details.company.marketing_pref_settings.events', ''))) {
        setToggleEvents(get(userDetails, 'details.company.marketing_pref_settings.events', 'true'));
      }

      if ((get(userDetails, 'details.company.marketing_pref_settings.firm_updates', ''))) {
        setToggleFirmUpdates(get(userDetails, 'details.company.marketing_pref_settings.firm_updates', 'false'));
      }

      if ((get(userDetails, 'details.company.marketing_pref_settings.financial_and_economic_newsletters', ''))) {
        setToggleFinancialNewsletters(get(userDetails, 'details.company.marketing_pref_settings.financial_and_economic_newsletters', 'true'));
      }
    }
  }, []);

  const handleSocialIconClick = (id, network, isDisconnected) => {
    if (isDisconnected) {
      window.sessionStorage.removeItem('platform');
      get(socialNetworkData, id, []).forEach((item) => {
        if (
          (network && network === item.snetworkProvider) ||
          network.length === 0
        ) {
          socialNetworkDelete({
            params: {
              id: `${id}_${item.accountId}`,
            },
          });
        }
      });
    } else {
      if (id === 'ln') {
        window.sessionStorage.setItem('platform', 'ln');
      }
      let link = get(socialAccountURL, `${id}.oauth_url.${network}`, '');
      if (!link) {
        link = get(socialAccountURL, `${id}.oauth_url`, '');
      }

      if (link) {
        window.sessionStorage.setItem('selectedIndex', 2);
        if (link) window.location.href = link;
      } else if (id === 'rt' && !complianceAdmin) {
        setRedTailModal(true);
      }
    }
  };

  const handleRedTailSubmit = ({ username, password }) => {
    redTailLogin(username, password)
      .then((res) => {
        if (res.result.success) {
          socialNetworkStatusRequest();
          setRedTailModal(false);
        }
      })
      .catch((err) => {
        setRedTailError(true);
      });
  };

  const contactListChange = (contactGroup) => {
    contactsRequest({ params: { contactGroup } });
  };

  const onContactDelete = (
    emailList,
    shouldDeleteGroup,
    data,
    dropdownId,
    shouldDeleteMultipleContactGroup
  ) => {
    if (shouldDeleteGroup) {
      contactsDelete({
        params: {
          contactGroup: '',
          emailList,
          shouldDeleteGroup,
          shouldDeleteMultipleContactGroup,
          selectedGroup: '',
        },
      });
    } else {
      contactsDelete({
        params: {
          contactGroup: selectedContactGroup,
          emailList,
          shouldDeleteGroup: false,
          selectedGroup: selectedContactGroup,
          shouldDeleteMultipleContactGroup,
        },
      });
    }
  };

  const checkComplianceAdmin = get(userDetails, 'compliance_admin', '');

  const onContactCreate = (values, isUpdate, isModal) => {
    contactsCreate({
      params: {
        ...values,
        contactGroup: isUpdate
          ? values.name
          : contactError &&
            contactError.contactGroup &&
            contactError.name === values.name
            ? contactError.contactGroup
            : '',
        isModal: isModal,
        // contactGroup: isUpdate ? selectedContactGroup : '',
      },
    });
  };

  const getSalesforcePayload = () => {
    const salesforcePayload = {};
    salesforceSettings.forEach((setting, index) => {
      const salesforceToggles = {};
      Object.keys(salesforceSettings[index])
        .slice(1)
        .forEach((toggle) => {
          salesforceToggles[salesforceTogglesMapper[toggle]] =
            salesforceSettings[index][toggle].toString();
        });
      console.log('SALESFORCE TOGGLE - - - ', salesforceToggles);
      salesforcePayload[salesforceSettingsMapper[setting.label]] =
        salesforceToggles;
    });
    console.log('SALESFORE PAYLOAD - - - -', salesforcePayload);
    return salesforcePayload;
  };

  const handleEmailModalSubmit = (payload) => {
    // console.log(payload);
    const data = {
      encryption: payload.port,
      name: payload.name,
      password: payload.password,
      port: payload.smtpPort,
      snetwork: 'em',
      snetwork_provider: 'smtp',
      url: payload.smtpHost,
      username: payload.email,
    };

    emailSmtpConnect(data)
      .then((res) => {
        if (res && res.result.success) {
          setEmailModal(false);
          socialNetworkStatusRequest();
        }
      })
      .catch((err) => {
        setEmailErrorMessage(err.message);
      });
  };

  const onBottomTextClick = (platform, emailPlaftform) => {
    if (getBottomText(platform).isDiconnected) {
      window.sessionStorage.removeItem('platform');
      get(socialNetworkData, platform, []).forEach((item) => {
        if (
          (emailPlaftform && emailPlaftform === item.snetworkProvider) ||
          !emailPlaftform
        ) {
          socialNetworkDelete({
            params: {
              id: `${platform}_${item.accountId}`,
            },
          });
        }
      });
    } else {
      handleSocialIconClick(
        platform,
        emailPlaftform,
        getBottomText(platform).isDiconnected
      );
    }
  };

  const getBottomText = (platform, emailPlaftform) => {
    let platformDetails = get(socialNetworkData, platform, []);
    if (emailPlaftform) {
      platformDetails = find(platformDetails, {
        snetworkProvider: emailPlaftform,
      });
    }
    const text = isEmpty(platformDetails) ? 'Connect' : 'Disconnect';
    let account = '';
    if (platform !== 'em' && platformDetails && platformDetails.length > 0) {
      if (platform === 'tw') {
        account = platformDetails[0].name.account;
      } else if (platform === 'ln' || platform === 'fb') {
        account =
          platformDetails[0].name.first + ' ' + platformDetails[0].name.last;
      }
    }
    if (
      platform === 'em' &&
      platformDetails &&
      (emailPlaftform === 'google' ||
        emailPlaftform === 'outlook' ||
        emailPlaftform === 'smtp')
    ) {
      account = platformDetails.email;
    }
    if (
      (platform === 'sg' || platform === 'zm') &&
      platformDetails &&
      platformDetails.length > 0
    ) {
      account = platformDetails[0].email;
    }
    return {
      bottomText: text,
      isDiconnected: text === 'Disconnect',
      account,
    };
  };

  const handleComplianceChange = () => {
    console.log('**** HANDLE COMPLIANCE CHANGE ****');
    setLoader(true);
    const companyId = get(userData, 'company', '');
    const advisorsData = advisorsList.map((advisor) => {
      return {
        uid: advisor.id,
        share_content: advisor.settings.share_content,
        add_content: advisor.settings.add_content,
        lead_capture: advisor.settings.lead_capture,
      };
    });

    const sfSettingsPayload = getSalesforcePayload();
    advisorsData.push({
      uid: userId,
      salesforce_settings: sfSettingsPayload,
      bcc_emails: bccList && !isEmpty(bccList.split(',')) ? bccList.split(',') : []
    });
   
    updateComplianceOption(compliance, advisorsData, companyId)
      .then((res) => {
        if (res.result.success) {
          loginRequest({ noRefresh: true });
          setLoader(false);
        }
      })
      .catch((err) => console.log('ERROR - ', err));
  };

  const firmSettingsSaveHandler = () => {
    setLoader(true);
    const advisorsData = advisorsList.map((advisor) => {
      return {
        uid: advisor.id,
        share_content: advisor.settings.share_content,
        add_content: advisor.settings.add_content,
        lead_capture: advisor.settings.lead_capture,
      };
    });

    const sfSettingsPayload = getSalesforcePayload();
    advisorsData.push({
      uid: userId,
      salesforce_settings: sfSettingsPayload,
    });
   
    const firmSettings = {
      firm_general: {
        enterprise_alias: enterpriseAlias,
        firm_alias: firmAlias,
        my_holding: toggleHoldings,
        my_editor: toggleEditor,
        my_clients: toggleClients,
        advisor_cascade_campaign: toggleCascade
      },
      firm_compliance: {},
      // firm_campaign_cascade: {
      //   settings: [
      //     { cascade_to: "admin/advisor",firms: firmArray.map(firm => firm.id) },
      //   ],
      // }
    }
    firmArray.forEach(firm => {
      firmSettings["firm_compliance"][firm.id] = {
        "compliance_is": firm.complianceIs,
        "compliance_done": firm.complianceDone,
      }
    })
    console.log('firmSettings', firmSettings);
    const payload = {
     extra_settings: JSON.stringify({firm_settings: firmSettings}),
    }
    let marketing_pref_settings = {
      marketing_pref_settings: JSON.stringify({
        general_newsletters: toggleNewsletters,
        ecards: toggleEcards,
        events: toggleEvents,
        firm_updates: toggleFirmUpdates,
        financial_and_economic_newsletters: toggleFinancialNewsletters,
       })
    }

    updateComplianceOption(enterpriseLevelCompliance, advisorsData, get(userData, 'company', ''), marketing_pref_settings)
    .then(res => {
      console.log('RES UPDATE COMPLIANCE OPTION - - - ', res);
      return updateFirmSettings({payload, id: userId});
    })
    .then(res => { 
      if (res.result.success) {
        loginRequest({ noRefresh: true });
        setLoader(false);
      }
      console.log('firmSettings', res);
    })
    .catch(error => {
      console.log("firmSettings", error);
      setLoader(false);
      setError('Some error occured');
    })
  }


  const handleMembersOption = () => {
    // console.log('canAddMembers')
    console.log('***** HANDLE MEMBERS OPTION *****');
    setLoader(true);
    const companyId = get(userData, 'company', '');
    updateMembersOption(addMember, companyId).then((res) => {
      if (res.result.success) {
        loginRequest({ noRefresh: true });
        setLoader(false);
      }
    });
  };

  const handleAttrChange = (checked, attr, index) => {
    const settingsArrayCopy = [...salesforceSettings];
    settingsArrayCopy[index][attr] = checked;
    updateSalesforceSettings(settingsArrayCopy);
  };

  const onEmailClick = (isDisconnected) => {
    if (!complianceAdmin) {
      if (isDisconnected) {
        get(socialNetworkData, 'em', []).forEach((item) => {
          if ('smtp' === item.snetworkProvider) {
            socialNetworkDelete({
              params: {
                id: `em_${item.accountId}`,
              },
            });
          }
        });
      } else {
        setEmailModal(true);
      }
    }
  };

  const onSendGridClick = (isDisconnected) => {
    if (isDisconnected) setSendGridModal(true);
  };

  const onTabSelect = (index) => {
    window.sessionStorage.removeItem('selectedIndex');
    setSelectedConfigureIndex(index);
    setSelectedTabIndexRequest({ selectedTabIndex: index });
  };

  const changeHandler = (event, id) => {
    console.log(
      '[CHECKED] - ',
      event.target.checked,
      '[NAME] - ',
      event.target.name,
      '[ID] - ',
      id
    );
    let allChecked = false;
    let users = [...advisorsList];
    users.every((user) => {
      if (user.id === id)
        user.settings[event.target.name] = event.target.checked.toString();
      return user.id !== id;
    });
    if (!event.target.checked) {
      console.log('[', event.target.name, '] - ', toggleAll[event.target.name]);
      toggleAll[event.target.name].current.checked = false;
    } else {
      allChecked = users.every(
        (user) => user.settings[event.target.name] === 'true'
      );
      console.log('[USERS] - ', users);
      console.log('[ALL CHECKED] - ', allChecked);
      if (allChecked) toggleAll[event.target.name].current.checked = true;
      else toggleAll[event.target.name].current.checked = false;
    }
    // console.log('[ALL CHECKED] - ', allChecked);
    // console.log('[ALL TOGGLE] - ', toggleAll);

    // console.log('[USERS] - ', users);
    setAdvisorsList(users);
    console.log('[ADVISORS] after setState - ', advisorsList);
  };

  const toggleAllHandler = (event) => {
    let users = [...advisorsList];
    users.forEach(
      (user) =>
        (user.settings[event.target.name] = event.target.checked.toString())
    );
    setAdvisorsList(users);
  };

  const checkedStatus = (platform) => {
    const platformDetails = selectedCheckBoxes.find(
      (c) => c.platform === platform
    );
    return {
      isDiconnected: platformDetails.isDisconnected,
      checked: platformDetails.checked,
    };
  };

  const onCheckBoxClick = (platform) => {
    selectedCheckBoxes.forEach((item, index) => {
      if (item.platform === platform) {
        item.checked = !item.checked;
        item.isDisconnected = !item.isDisconnected;
      }
    });
    setSelectedCheckBoxes([...selectedCheckBoxes]);
    checkedStatus(platform);
  };

  const pluck = (array, key) => {
    return array.map((o) => o[key]);
  };

  const handleMemberSettings = () => {
    console.log('***** HANDLE MEMBER SETTINGS *****');
    setLoader(true);
    const companyId = get(userData, 'company', '');
    const filteredData = selectedCheckBoxes.filter((s) => !s.checked);
    const disabledSocialNetworks = pluck(filteredData, 'platform');
    updateMemberSettings(disabledSocialNetworks, companyId).then((res) => {
      if (res.result.success) {
        loginRequest({ noRefresh: true });
        setLoader(false);
      }
    });
  };

  const saveSettings = () => {
    setError('');
    let msg = 'email is not valid';
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let valid = true;
    if (valid && !isEmpty(bccList)) {
      valid = bccList
        .split(',')
        .map((em) => em.trim())
        .every((em) => isEmailRegEx.test(em.toLowerCase()));
      if (bccList.split(',').length > 3 && valid) {
        valid = false;
        msg = 'Maximum 3 bcc emails can be added';
      }
    }
    if (!valid) {
      return setError(msg);
    }
    console.log('***** SAVE SETTINGS ***** ');
    handleComplianceChange();
    if (assetManagerAdmin) handleMembersOption();
  };

  const handleHoldingsToggle = (checked) => {
    if (checked)
      setToggleHoldings('true');
    else {
      setToggleHoldings('false');
    }
  };

  const handleEditorToggle = (checked) => {
    if (checked)
      setToggleEditor('true');
    else {
      setToggleEditor('false');
    }
  };

  const handleClientsToggle = (checked) => {
    if (checked)
      setToggleClients('true');
    else {
      setToggleClients('false');
    }
  };

  const handleCascadeToggle = (checked) => {
    if (checked)
      setToggleCascade('true');
    else {
      setToggleCascade('false');
    }
  };

  const handleNewslettersToggle = (checked) => {
    if (checked)
      setToggleNewsletters('true');
    else {
      setToggleNewsletters('false');
    }
  };

  const handleEcardsToggle = (checked) => {
    if (checked)
      setToggleEcards('true');
    else {
      setToggleEcards('false');
    }
  };

  const handleEventsToggle = (checked) => {
    if (checked)
      setToggleEvents('true');
    else {
      setToggleEvents('false');
    }
  };

  const handleFirmUpdatesToggle = (checked) => {
    if (checked)
      setToggleFirmUpdates('true');
    else {
      setToggleFirmUpdates('false');
    }
  };

  const handleFinancialNewslettersToggle = (checked) => {
    if (checked)
      setToggleFinancialNewsletters('true');
    else {
      setToggleFinancialNewsletters('false');
    }
  };

  const handleUnsubscribeToggle = (checked) => {
    if (checked)
      setToggleUnsubscribe('true');
    else {
      setToggleUnsubscribe('false');
    }
  };
  // console.log('[ADVISORS LIST] - ', advisorsList);
  return (
    <Box width={'105%'} pt="25px">
      <Flex width={1}>
        <Tabs onSelect={onTabSelect} selectedIndex={selectedConfigureIndex}>
          <BorderWhiteBox
            type="admin-tab"
            padding="5px 5px"
            border="1px solid #e4e4e4"
            borderTop={true}
          >
            <TabList height="35px">
              <TabTypeThree
                width="160px"
                currentTab={selectedConfigureIndex === 0}
                type="dashboard"
              >
                Profile
              </TabTypeThree>
              <TabTypeThree
                width="160px"
                currentTab={selectedConfigureIndex === 1}
                type="dashboard"
              >
                Channel Settings
              </TabTypeThree>
              <TabTypeThree
                width="160px"
                currentTab={selectedConfigureIndex === 2}
                type="dashboard"
              >
                Platforms
              </TabTypeThree>
              <TabTypeThree
                width="160px"
                currentTab={selectedConfigureIndex === 3}
                type="dashboard"
              >
                Contacts
              </TabTypeThree>
              {!complianceAdmin && checkComplianceAdmin ? (
                <TabTypeThree
                  width="160px"
                  currentTab={selectedConfigureIndex === 4}
                  type="dashboard"
                  data-testid="general-settings"
                >
                  General Settings
                </TabTypeThree>
              ) : (
                <TabTypeThree
                  width="160px"
                  currentTab={selectedConfigureIndex === 4}
                  style={{
                    visibility: 'hidden',
                    position: 'absolute',
                    zIndex: -1,
                  }}
                ></TabTypeThree>
              )}
            </TabList>
            <TabConfig noBorder={true}>
              <Profile
                userData={userData}
                socialAccountURL={socialAccountURL}
                setSocialAccountURL={setSocialAccountURL}
                roleType={roleType}
              />
            </TabConfig>
            <TabConfig noBorder={true}>
              <ChannelSettings />
            </TabConfig>
            <TabConfig noBorder={true}>
              <Flex width={1} flexDirection="column" pt="20px">
                <Box width={1}>Connect my digital platforms</Box>
                <Box mx="auto" pt="3%">
                  <LoaderWrapper isLoading={socialNetworkDataFetching}>
                    <SocialLoginButtons
                      onBottomTextClick={onBottomTextClick}
                      buttons={[
                        {
                          name: 'LinkedIn',
                          faIcon: faLinkedinIn,
                          bottomText: getBottomText('ln').bottomText,
                          onClick: () =>
                            handleSocialIconClick(
                              'ln',
                              '',
                              getBottomText('ln').isDiconnected
                            ),
                          isDiconnected: getBottomText('ln').isDiconnected,
                          platform: 'ln',
                          account: getBottomText('ln').account,
                        },
                        {
                          name: 'Twitter',
                          faIcon: faTwitter,
                          bottomText: getBottomText('tw').bottomText,
                          onClick: () =>
                            handleSocialIconClick(
                              'tw',
                              '',
                              getBottomText('tw').isDiconnected
                            ),
                          isDiconnected: getBottomText('tw').isDiconnected,
                          platform: 'tw',
                          account: getBottomText('tw').account,
                        },
                        {
                          name: 'Facebook',
                          faIcon: faFacebook,
                          bottomText: getBottomText('fb').bottomText,
                          onClick: () =>
                            handleSocialIconClick(
                              'fb',
                              '',
                              getBottomText('fb').isDiconnected
                            ),
                          isDiconnected: getBottomText('fb').isDiconnected,
                          platform: 'fb',
                          account: getBottomText('fb').account,
                        },
                        {
                          name: 'Google',
                          faIcon: faGoogle,
                          bottomText: getBottomText('em', 'google').bottomText,
                          onClick: () =>
                            handleSocialIconClick(
                              'em',
                              'google',
                              getBottomText('em', 'google').isDiconnected
                            ),
                          isDiconnected: getBottomText('em', 'google')
                            .isDiconnected,
                          platform: 'em',
                          emailPlaftform: 'google',
                          account: getBottomText('em', 'google').account,
                        },
                        {
                          name: 'Outlook',
                          imgUrl: '/assets/images/outlook-disconnected.png',
                          bottomText: getBottomText('em', 'outlook').bottomText,
                          onClick: () =>
                            handleSocialIconClick(
                              'em',
                              'outlook',
                              getBottomText('em', 'outlook').isDiconnected
                            ),
                          isDiconnected: getBottomText('em', 'outlook')
                            .isDiconnected,
                          iconHeight: 50,
                          platform: 'em',
                          emailPlaftform: 'outlook',
                          account: getBottomText('em', 'outlook').account,
                        },

                        {
                          name: 'Zoom',
                          imgUrl: getBottomText('zm').isDiconnected
                            ? '/assets/images/Zoom-connected.svg'
                            : '/assets/images/Zoom-disconnected.svg',
                          bottomText: getBottomText('zm').bottomText,
                          onClick: () =>
                            handleSocialIconClick(
                              'zm',
                              '',
                              getBottomText('zm').isDiconnected
                            ),
                          isDiconnected: getBottomText('zm').isDiconnected,
                          iconHeight: 50,
                          iconWidth: 100,
                          platform: 'zm',
                          account: getBottomText('zm').account,
                        },

                        {
                          name: 'Email',
                          imgUrl: getBottomText('em', 'smtp').isDiconnected
                            ? '/assets/images/email.svg'
                            : '/assets/images/envelope-gray.png',
                          bottomText: getBottomText('em', 'smtp').bottomText,
                          onClick: () =>
                            onEmailClick(
                              getBottomText('em', 'smtp').isDiconnected
                            ),
                          isDiconnected: getBottomText('em', 'smtp')
                            .isDiconnected,
                          platform: 'em',
                          emailPlaftform: 'smtp',
                          iconHeight: 50,
                          account: getBottomText('em', 'smtp').account,
                        },
                        {
                          name: 'Sendgrid',
                          imgUrl:
                            socialNetworkData &&
                            socialNetworkData['sg'] &&
                            socialNetworkData['sg'].length > 0 &&
                            socialNetworkData['sg'][0].verification_status ===
                              'verified' &&
                            socialNetworkData['sg'][0].status === 'active'
                              ? '/assets/images/SendGrid.svg'
                              : '/assets/images/SendGrid-grey.svg',
                          bottomText:
                            socialNetworkData &&
                            socialNetworkData['sg'] &&
                            socialNetworkData['sg'].length > 0 &&
                            socialNetworkData['sg'][0].verification_status ===
                              'verified' &&
                            socialNetworkData['sg'][0].status === 'active'
                              ? 'Disconnect'
                              : 'Connect',
                          onClick: () => onSendGridClick(true),
                          isDiconnected:
                            socialNetworkData &&
                            socialNetworkData['sg'] &&
                            socialNetworkData['sg'].length > 0 &&
                            socialNetworkData['sg'][0].verification_status ===
                              'verified' &&
                            socialNetworkData['sg'][0].status === 'active'
                              ? true
                              : false,
                          platform: 'sg',
                          iconHeight: 50,
                          account: getBottomText('sg').account,
                        },
                      ]}
                    />
                  </LoaderWrapper>
                </Box>
              </Flex>
              {(addMember === 'true' || adminEnterprise) && (
                <Flex width={1} flexDirection="column" pt="35px">
                  <Box width={1}>Allowed Member Platforms</Box>
                  <Box mx="auto" pt="3%">
                    <SocialCheckBoxes
                      onCheckBoxClick={onCheckBoxClick}
                      buttons={[
                        {
                          name: 'LinkedIn',
                          faIcon: faLinkedinIn,
                          bottomText: 'LinkedIn',
                          isDiconnected: checkedStatus('ln').isDiconnected,
                          platform: 'ln',
                          checked: checkedStatus('ln').checked,
                          bgColor: '#c95cfc',
                        },
                        {
                          name: 'Twitter',
                          faIcon: faTwitter,
                          bottomText: 'Twitter',
                          isDiconnected: checkedStatus('tw').isDiconnected,
                          platform: 'tw',
                          checked: checkedStatus('tw').checked,
                          bgColor: '#c95cfc',
                        },
                        {
                          name: 'Facebook',
                          faIcon: faFacebook,
                          bottomText: 'Facebook',
                          isDiconnected: checkedStatus('fb').isDiconnected,
                          platform: 'fb',
                          checked: checkedStatus('fb').checked,
                          bgColor: '#c95cfc',
                        },
                        {
                          name: 'Email',
                          imgUrl: checkedStatus('em').checked
                            ? '/assets/images/email.svg'
                            : '/assets/images/envelope-gray.png',
                          bottomText: 'Email',
                          iconHeight: 50,
                          isDiconnected: checkedStatus('em').isDiconnected,
                          platform: 'em',
                          checked: checkedStatus('em').checked,
                          bgColor: '#c95cfc',
                        },
                      ]}
                    />
                  </Box>
                  <Box pt="3%">
                    <LoaderWrapper isLoading={loader}>
                      <Button onClick={handleMemberSettings}>Save</Button>
                    </LoaderWrapper>
                  </Box>
                </Flex>
              )}
              <EmailSettingsModal
                open={emailModal}
                handleClose={() => setEmailModal(false)}
                handleModalSubmit={handleEmailModalSubmit}
                emailErrorMessage={emailErrorMessage}
              />
              <RedTailModal
                open={redTailModal}
                handleClose={() => setRedTailModal(false)}
                handleModalSubmit={handleRedTailSubmit}
                modalError={redTailError}
              />
              {sendGridModal ? (
                <SendgridModal
                  isOpen={sendGridModal}
                  adminModal={false}
                  handleClose={() => setSendGridModal(false)}
                />
              ) : null}
            </TabConfig>
            <TabConfig noBorder={true}>
              <EmailList
                contactError={contactError}
                contactsList={contactsList}
                contactGroups={contactGroups}
                contactGroupsList={contactGroupsList}
                contactListChange={contactListChange}
                onContactDelete={onContactDelete}
                contactsCreate={onContactCreate}
                setIsUpdateContact={setIsUpdateContact}
                isContactsFetching={isContactsFetching}
                selectedContactGroup={selectedContactGroup}
                socialAccountURL={socialAccountURL}
                socialNetworkData={socialNetworkData}
                userData={userData}
                userId={userId}
                loginRequest={loginRequest}
                retrieveGroupsList={retrieveGroupsList}
                contactsRequest={contactsRequest}
                paginationData={paginationData}
                navigateAfter={navigateAfter}
                socialNetworkStatusRequest={socialNetworkStatusRequest}
                selectedContactGroupDefault={selectedContactGroupDefault}
                socialNetworkDataFetching={socialNetworkDataFetching}
                socialNetworkDelete={socialNetworkDelete}
                setSocialAccountURL={setSocialAccountURL}
                isAdmin
                customContactGroupList={customContactGroupList}
                contactsCount={contactsCount}
              />
            </TabConfig>

            <TabConfig noBorder={true}>
              {isEnterpriseLevelAdmin ? (
                <>
                  <LoaderWrapper
                    styles={{ marginTop: '2rem' }}
                    isLoading={generalSettingsLoading}
                  >
                    {!isEmpty(advisorsList) && addMember === 'true' ? (
                      <MembersTable
                        isAssetAdmin={assetManagerAdmin}
                        advisorsList={advisorsList}
                        changeHandler={changeHandler}
                        toggleAllHandler={toggleAllHandler}
                        toggleAll={toggleAll}
                        userData={userData}
                        width='80%'
                      />
                    ) : null}
                  </LoaderWrapper>
                  <Flex
                    flexDirection="column"
                    width="80%"
                    justifyContent="center"
                    margin="30px auto auto"
                    paddingBottom="27px"
                    style={{
                      border: '1px solid #cacaca',
                      borderRadius: '5px',
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                        width: '100%',
                        marginBottom: '24px',
                        color: '#4C5C66',
                        fontWeight: '500',
                        fontSize: '18px',
                        paddingTop: '9px',
                        paddingBottom: '10px',
                        backgroundColor: '#F5F5F5',
                      }}
                    >
                      Enterprise Level Settings
                    </div>
                    <Flex
                      justifyContent="center"
                      fontSize="14px"
                      style={{ marginTop: '-3px' }}
                    >
                      <RadioInput
                        type="radio"
                        name={`enterprise-compliance-option`}
                        id={`enterprise_radio_on`}
                        checked={enterpriseLevelCompliance === 'on'}
                        onClick={() => setEnterpriseLevelCompliance('on')}
                        style={{ cursor: 'pointer' }}
                      />
                      <label
                        style={{
                          cursor: 'pointer',
                          margin: '0px 4px',
                          letterSpacing: '0.35px',
                        }}
                        htmlFor={`enterprise_radio_on`}
                      >
                        Always On
                      </label>
                      <RadioInput
                        type="radio"
                        name={`enterprise-compliance-option`}
                        id={`enterprise_radio_off`}
                        checked={enterpriseLevelCompliance === 'off'}
                        onClick={() => setEnterpriseLevelCompliance('off')}
                        style={{ cursor: 'pointer' }}
                      />
                      <label
                        style={{
                          cursor: 'pointer',
                          margin: '0px 4px',
                          letterSpacing: '0.35px',
                        }}
                        htmlFor={`enterprise_radio_off`}
                      >
                        Always Off
                      </label>
                      <RadioInput
                        type="radio"
                        name={`enterprise-compliance-option`}
                        id={`enterprise_radio_optional`}
                        checked={enterpriseLevelCompliance === 'optional'}
                        onClick={() => setEnterpriseLevelCompliance('optional')}
                        style={{ cursor: 'pointer' }}
                      />
                      <label
                        style={{
                          cursor: 'pointer',
                          margin: '0px 4px',
                          letterSpacing: '0.35px',
                        }}
                        htmlFor={`enterprise_radio_optional`}
                      >
                        Optional
                      </label>
                    </Flex>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    width="80%"
                    justifyContent="center"
                    margin="30px auto auto"
                    paddingBottom="27px"
                    style={{
                      border: '1px solid #cacaca',
                      borderRadius: '5px',
                    }}
                  >
                    <div
                      style={{
                        justifyContent: 'center',
                        marginBottom: '24px',
                        color: '#4C5C66',
                        fontWeight: '500',
                        fontSize: '18px',
                        paddingTop: '9px',
                        paddingBottom: '10px',
                        width: '100%',
                        display: 'flex',
                        backgroundColor: '#F5F5F5',
                      }}
                    >
                      Marketing Preference Center Settings
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          width: '55%',
                          alignItems: 'flex-start',
                          color: '#3A4C57',
                          paddingLeft: '20px',
                          marginLeft: '58px',
                          marginBottom: '10px',
                          textDecoration: 'underline',
                        }}
                      >
                        Campaign Type
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          width: '45%',
                          alignItems: 'flex-start',
                          color: '#3A4C57',
                          textDecoration: 'underline',
                        }}
                      >
                        On | Off
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          width: '55%',
                          alignItems: 'flex-start',
                          color: '#3A4C57',
                          paddingLeft: '20px',
                          marginBottom: '15px',
                        }}
                      >
                        <label
                          style={{ padding: '10px 16px', marginLeft: '5px' }}
                        >
                          General Newsletters
                        </label>
                        <label
                          style={{ padding: '10px 16px', whiteSpace: 'nowrap', marginLeft: '5px' }}
                        >
                          Financial and Economic Insight Newsletters
                        </label>
                        <label
                          style={{ padding: '10px 16px', marginLeft: '5px' }}
                        >
                          Firm Updates
                        </label>
                        <label
                          style={{ padding: '10px 16px', marginLeft: '5px' }}
                        >
                          Ecards
                        </label>
                        <label
                          style={{ padding: '10px 16px', marginLeft: '5px' }}
                        >
                          Events
                        </label>
                       
                        <label style={{ padding: '10px 16px', marginLeft: '5px' }}>
                          All Digital Marketing Communications
                        </label>
                        {/* <label style={{ padding: '10px 20px', marginLeft: '20px' }}>
                          Unsubscribe from All
                        </label> */}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '45%',
                          alignItems: 'flex-start',
                          paddingLeft: '10px',
                          marginBottom: '15px',
                        }}
                      >
                        <span
                          style={{ marginLeft: '6px', padding: '10px 20px' }}
                        >
                          <ToggleButton
                            className="react-switch"
                            onChange={(checked) =>
                              handleNewslettersToggle(checked)
                            }
                            checked={toggleNewsletters === 'true'}
                            onColor="#c95cfc"
                            offColor="#ccc"
                            offHandleColor="#fff"
                            onHandleColor="#fff"
                            handleDiameter={10}
                            uncheckedIcon=""
                            checkedIcon=""
                            boxShadow=""
                            activeBoxShadow=""
                            type="autoPost"
                            left="1px"
                            margin="-3px 5px 4px"
                            position="absolute"
                            top="7px"
                            opacity="0"
                          />
                        </span>
                        <span
                          style={{ marginLeft: '6px', padding: '10px 20px' }}
                        >
                          <ToggleButton
                            className="react-switch"
                            onChange={(checked) =>
                              handleFinancialNewslettersToggle(checked)
                            }
                            checked={toggleFinancialNewsletters === 'true'}
                            onColor="#c95cfc"
                            offColor="#ccc"
                            offHandleColor="#fff"
                            onHandleColor="#fff"
                            handleDiameter={10}
                            uncheckedIcon=""
                            checkedIcon=""
                            boxShadow=""
                            activeBoxShadow=""
                            type="autoPost"
                            left="1px"
                            margin="-3px 5px 4px"
                            position="absolute"
                            top="7px"
                            opacity="0"
                          />
                        </span>
                        <span
                          style={{ marginLeft: '6px', padding: '10px 20px' }}
                        >
                          <ToggleButton
                            className="react-switch"
                            onChange={(checked) =>
                              handleFirmUpdatesToggle(checked)
                            }
                            checked={toggleFirmUpdates === 'true'}
                            onColor="#c95cfc"
                            offColor="#ccc"
                            offHandleColor="#fff"
                            onHandleColor="#fff"
                            handleDiameter={10}
                            uncheckedIcon=""
                            checkedIcon=""
                            boxShadow=""
                            activeBoxShadow=""
                            type="autoPost"
                            left="1px"
                            margin="-3px 5px 4px"
                            position="absolute"
                            top="7px"
                            opacity="0"
                          />
                        </span>
                        <span
                          style={{ marginLeft: '6px', padding: '10px 20px' }}
                        >
                          <ToggleButton
                            className="react-switch"
                            onChange={(checked) => handleEcardsToggle(checked)}
                            checked={toggleEcards === 'true'}
                            onColor="#c95cfc"
                            offColor="#ccc"
                            offHandleColor="#fff"
                            onHandleColor="#fff"
                            handleDiameter={10}
                            uncheckedIcon=""
                            checkedIcon=""
                            boxShadow=""
                            activeBoxShadow=""
                            type="autoPost"
                            left="1px"
                            margin="-3px 5px 4px"
                            position="absolute"
                            top="7px"
                            opacity="0"
                          />
                        </span>
                        <span
                          style={{ marginLeft: '6px', padding: '10px 20px' }}
                        >
                          <ToggleButton
                            className="react-switch"
                            onChange={(checked) => handleEventsToggle(checked)}
                            checked={toggleEvents === 'true'}
                            onColor="#c95cfc"
                            offColor="#ccc"
                            offHandleColor="#fff"
                            onHandleColor="#fff"
                            handleDiameter={10}
                            uncheckedIcon=""
                            checkedIcon=""
                            boxShadow=""
                            activeBoxShadow=""
                            type="autoPost"
                            left="1px"
                            margin="-3px 5px 4px"
                            position="absolute"
                            top="7px"
                            opacity="0"
                          />
                        </span>
                        <span
                          style={{ marginLeft: '6px', padding: '10px 20px' }}
                        >
                          <ToggleButton
                            className="react-switch"
                            // onChange={(checked) =>
                            //   handleNewslettersToggle(checked)
                            // }
                            checked={toggleGeneralMarketing === 'true'}
                            disabled={true}
                            onColor="#c95cfc"
                            offColor="#ccc"
                            offHandleColor="#fff"
                            onHandleColor="#fff"
                            handleDiameter={10}
                            uncheckedIcon=""
                            checkedIcon=""
                            boxShadow=""
                            activeBoxShadow=""
                            type="autoPost"
                            left="1px"
                            margin="-3px 5px 4px"
                            position="absolute"
                            top="7px"
                            opacity="0"
                          />
                        </span>
                        {/* <span style={{ marginLeft: '6px', padding: '10px 20px' }}>
                          <ToggleButton
                            className="react-switch"
                            onChange={(checked) => handleUnsubscribeToggle(checked)}
                            checked={toggleUnsubscribe === 'true'}
                            onColor="#c95cfc"
                            offColor="#ccc"
                            offHandleColor="#fff"
                            onHandleColor="#fff"
                            handleDiameter={10}
                            uncheckedIcon=""
                            checkedIcon=""
                            boxShadow=""
                            activeBoxShadow=""
                            type="autoPost"
                            left="1px"
                            margin="-3px 5px 4px"
                            position="absolute"
                            top="7px"
                            opacity="0"
                            disabled={true}
                          />
                        </span> */}
                      </div>
                    </div>
                  </Flex>
                  <Flex
                    margin="30px auto auto"
                    flexDirection="column"
                    justifyContent="center"
                    width="90%"
                  >
                    {!isEmpty(get(socialNetworkData, 'sf', [])) && (
                      <>
                        <Box width={1} pt={'4%'}>
                          Salesforce settings
                        </Box>
                        <Box width={1} pt={'4%'}>
                          <AdvisorSettings
                            settingsArray={salesforceSettings}
                            changeHandler={handleAttrChange}
                          />
                        </Box>
                      </>
                    )}
                  </Flex>
                  <Flex
                    flexDirection="column"
                    width="80%"
                    justifyContent="center"
                    margin="30px auto auto"
                    paddingBottom="27px"
                    style={{
                      border: '1px solid #cacaca',
                      borderRadius: '5px',
                    }}
                  >
                    <div
                      style={{
                        justifyContent: 'center',
                        marginBottom: '24px',
                        color: '#4C5C66',
                        fontWeight: '500',
                        fontSize: '18px',
                        paddingTop: '9px',
                        paddingBottom: '10px',
                        width: '100%',
                        display: 'flex',
                        backgroundColor: '#F5F5F5',
                      }}
                    >
                      Firm Level Settings
                    </div>
                    <div>
                      <label
                        style={{
                          marginLeft: '-41px',
                          paddingRight: '10px',
                          fontSize: '15px',
                        }}
                      >
                        Enterprise-level is referred to as:
                      </label>
                      <span>
                        <input
                          style={{
                            marginLeft: '3px',
                            padding: '4px 0px 4px 5px',
                          }}
                          paddingTop="2px"
                          width="40%"
                          type="text"
                          id="name"
                          name="enterprise"
                          value={enterpriseAlias}
                          onChange={(e) => setEnterpriseAlias(e.target.value)}
                        />
                      </span>
                    </div>
                    <div style={{ marginTop: '22px' }}>
                      <label style={{ fontSize: '15px' }}>
                        Firm-level is referred to as:{' '}
                      </label>
                      <span>
                        <input
                          style={{
                            marginLeft: '10px',
                            padding: '4px 0px 4px 5px',
                          }}
                          width="40%"
                          type="text"
                          id="name"
                          name="Firm"
                          value={firmAlias}
                          onChange={(e) => setFirmAlias(e.target.value)}
                        />
                      </span>
                    </div>

                    <div style={{ marginTop: '15px', marginLeft: '-30px' }}>
                      <label style={{ marginLeft: '12px', fontSize: '15px' }}>
                        My Holdings:
                      </label>
                      <span style={{ marginLeft: '6px' }}>
                        <ToggleButton
                          className="react-switch"
                          onChange={(checked) => handleHoldingsToggle(checked)}
                          checked={toggleHoldings === 'true'}
                          onColor="#c95cfc"
                          offColor="#ccc"
                          offHandleColor="#fff"
                          onHandleColor="#fff"
                          handleDiameter={10}
                          uncheckedIcon=""
                          checkedIcon=""
                          boxShadow=""
                          activeBoxShadow=""
                          type="autoPost"
                          left="1px"
                          margin="-3px 5px 4px"
                          position="absolute"
                          top="7px"
                          opacity="0"
                        />
                      </span>
                    </div>
                    <div style={{ marginTop: '15px', marginLeft: '-8px' }}>
                      <label style={{ marginLeft: '12px', fontSize: '15px' }}>
                        My Editor:
                      </label>
                      <span style={{ marginLeft: '6px' }}>
                        <ToggleButton
                          className="react-switch"
                          onChange={(checked) => handleEditorToggle(checked)}
                          checked={toggleEditor === 'true'}
                          onColor="#c95cfc"
                          offColor="#ccc"
                          offHandleColor="#fff"
                          onHandleColor="#fff"
                          handleDiameter={10}
                          uncheckedIcon=""
                          checkedIcon=""
                          boxShadow=""
                          activeBoxShadow=""
                          type="autoPost"
                          left="1px"
                          margin="-3px 5px 4px"
                          position="absolute"
                          top="7px"
                          opacity="0"
                        />
                      </span>
                    </div>
                    <div style={{ marginTop: '15px', marginLeft: '-17px' }}>
                      <label style={{ marginLeft: '12px', fontSize: '15px' }}>
                        My Clients:
                      </label>
                      <span style={{ marginLeft: '6px' }}>
                        <ToggleButton
                          className="react-switch"
                          onChange={(checked) => handleClientsToggle(checked)}
                          checked={toggleClients === 'true'}
                          onColor="#c95cfc"
                          offColor="#ccc"
                          offHandleColor="#fff"
                          onHandleColor="#fff"
                          handleDiameter={10}
                          uncheckedIcon=""
                          checkedIcon=""
                          boxShadow=""
                          activeBoxShadow=""
                          type="autoPost"
                          left="1px"
                          margin="-3px 5px 4px"
                          position="absolute"
                          top="7px"
                          opacity="0"
                          marginBottom="4px"
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        marginTop: '15px',
                        marginRight: '227px',
                        marginBottom: '25px',
                      }}
                    >
                      <label style={{ marginLeft: '12px', fontSize: '15px' }}>
                        Firm admins can cascade campaigns:
                      </label>
                      <span style={{ marginLeft: '6px' }}>
                        <ToggleButton
                          className="react-switch"
                          onChange={(checked) => handleCascadeToggle(checked)}
                          checked={toggleCascade === 'true'}
                          onColor="#c95cfc"
                          offColor="#ccc"
                          offHandleColor="#fff"
                          onHandleColor="#fff"
                          handleDiameter={10}
                          uncheckedIcon=""
                          checkedIcon=""
                          boxShadow=""
                          activeBoxShadow=""
                          type="autoPost"
                          left="1px"
                          margin="-3px 5px 4px"
                          position="absolute"
                          top="7px"
                          opacity="0"
                          marginBottom="4px"
                        />
                      </span>
                    </div>
                  </Flex>
                  <Flex
                    style={{
                      width: '80%',
                      border: '1px solid #cacaca',
                      borderRadius: '5px',
                      margin: 'auto',
                      marginTop: '30px',
                      paddingBottom: '9px',
                    }}
                  >
                    <table
                      style={{
                        color: '#49484a',
                        width: '100%',
                      }}
                    >
                      <thead
                        style={{
                          backgroundColor: '#f5f5f5',
                          fontSize: '16px',
                          fontWeight: '400',
                          letterSpacing: '0.35px',
                          opacity: '1',
                          verticalAlign: 'top',
                        }}
                      >
                        <tr>
                          <th
                            style={{
                              color: 'rgb(82 81 83)',
                              padding: '6px',
                              width: '45%',
                            }}
                          >
                            Firm
                          </th>
                          <th
                            style={{
                              color: 'rgb(82 81 83)',
                              padding: '6px',
                              width: '55%',
                              paddingRight: '14px',
                            }}
                          >
                            Compliance is
                          </th>
                          {/* <th style={{ color: 'rgb(82 81 83)', padding: '6px', width: '32%', }}>Compliance is done at</th> */}
                        </tr>
                      </thead>

                      <tbody style={{ fontSize: '13px' }}>
                        {!isEmpty(firmArray) &&
                          firmArray.map((firm, index) => (
                            <tr style={{ color: '#808080' }}>
                              <td
                                style={{
                                  position: 'relative',
                                  width: '12%',
                                  paddingTop: '12px',
                                  letterSpacing: '0.35px',
                                  paddingBottom: '9px',
                                  cursor: 'pointer',
                                }}
                                onMouseEnter={() =>
                                  firm.name.length > 8
                                    ? setTooltip(firm.id)
                                    : {}
                                }
                                onMouseLeave={() => setTooltip('')}
                              >
                                {firm.name.length > 40
                                  ? firm.name.substring(0, 37) + '...'
                                  : firm.name}
                                {tooltip === firm.id && (
                                  <span
                                    style={{
                                      position: 'absolute',
                                      color: '#FFF',
                                      backgroundColor: '#000',
                                      borderRadius: '3px',
                                      padding: '2px 5px',
                                      fontSize: '10px',
                                      marginTop: '20px',
                                      marginLeft: '-100px',
                                    }}
                                  >
                                    {firm.name}
                                  </span>
                                )}
                              </td>
                              <td
                                style={{
                                  paddingTop: '8px',
                                  paddingLeft: '17px',
                                  paddingRight: '29px',
                                }}
                              >
                                <Flex style={{ marginTop: '-3px' }}>
                                  <RadioInput
                                    type="radio"
                                    name={`compliance-option-${index}`}
                                    id={`radio_on-${index}`}
                                    checked={firm.complianceIs === 'always_on'}
                                    onClick={() => {
                                      const firmArrayCopy =
                                        cloneDeep(firmArray);
                                      firmArrayCopy[index].complianceIs =
                                        'always_on';
                                      setFirmArray(firmArrayCopy);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  />
                                  <label
                                    style={{
                                      cursor: 'pointer',
                                      margin: '0px 4px',
                                      letterSpacing: '0.35px',
                                    }}
                                    // htmlFor={`radio_on-${index}`}
                                  >
                                    Always On
                                  </label>
                                  {/* <span style={{ marginRight: '2px', paddingRight: '4px', letterSpacing: '0.35px', cursor: 'pointer', }}>Always On</span> */}
                                  <RadioInput
                                    type="radio"
                                    name={`compliance-option-${index}`}
                                    id={`radio_off-${index}`}
                                    checked={firm.complianceIs === 'always_off'}
                                    onClick={() => {
                                      const firmArrayCopy =
                                        cloneDeep(firmArray);
                                      firmArrayCopy[index].complianceIs =
                                        'always_off';
                                      setFirmArray(firmArrayCopy);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  />
                                  <label
                                    style={{
                                      cursor: 'pointer',
                                      margin: '0px 4px',
                                      letterSpacing: '0.35px',
                                    }}
                                    // htmlFor={`radio_off-${index}`}
                                  >
                                    Always Off
                                  </label>
                                  <RadioInput
                                    type="radio"
                                    name={`compliance-option-${index}`}
                                    id={`radio_optional-${index}`}
                                    checked={firm.complianceIs === 'optional'}
                                    onClick={() => {
                                      const firmArrayCopy =
                                        cloneDeep(firmArray);
                                      firmArrayCopy[index].complianceIs =
                                        'optional';
                                      setFirmArray(firmArrayCopy);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  />
                                  <label
                                    style={{
                                      cursor: 'pointer',
                                      margin: '0px 4px',
                                      letterSpacing: '0.35px',
                                    }}
                                    // htmlFor={`radio_optional-${index}`}
                                  >
                                    Optional
                                  </label>
                                </Flex>
                              </td>
                              {/* <td style={{ paddingLeft: '25px', paddingTop: '6px', }}>
                              <Flex style={{ marginLeft: '-23px', }}>
                                <RadioInput
                                  type="radio"
                                  name={`enterprise-option-${index}`}
                                  id={`radio_enterprise-${index}`}
                                  checked={firm.complianceDone === 'enterprise'}
                                  onClick={() => {
                                    const firmArrayCopy = cloneDeep(firmArray);
                                    firmArrayCopy[index].complianceDone = "enterprise";
                                    setFirmArray(firmArrayCopy)
                                  }}
                                  style={{ cursor: 'pointer' }}
                                />
                                <label
                                  style={{ cursor: 'pointer', margin: '0px 4px', letterSpacing: '0.35px'}}
                                  htmlFor={`radio_enterprise-${index}`}
                                >Enterprise Level</label>
                                <RadioInput
                                  type="radio"
                                  name={`enterprise-option-${index}`}
                                  id={`radio_firm-${index}`}
                                  checked={firm.complianceDone === 'firm'}
                                  onClick={() => {
                                    const firmArrayCopy = cloneDeep(firmArray);
                                    firmArrayCopy[index].complianceDone = "firm";
                                    setFirmArray(firmArrayCopy)
                                  }}
                                  style={{ cursor: 'pointer' }}
                                />
                                <label
                                  style={{ cursor: 'pointer', margin: '0px 4px', letterSpacing: '0.35px' }}
                                  htmlFor={`radio_firm-${index}`}
                                >Firm Level</label>
                              </Flex>
                            </td>  */}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Flex>
                  <LoaderWrapper isLoading={loader}>
                    <Button
                      bgColor="#7dc4d0"
                      styles={{ border: 'none' }}
                      onClick={firmSettingsSaveHandler}
                    >
                      Save
                    </Button>
                  </LoaderWrapper>
                </>
              ) : (
                <>
                  {!complianceAdmin && checkComplianceAdmin && (
                    // <Formik
                    //   enableReinitialize={true}
                    //   initialValues={{
                    //     compliance: compliance
                    //   }}
                    //   onSubmit={saveSettings}
                    // >
                    //   {({
                    //     setFieldValue,
                    //     handleSubmit,
                    //     handleChange,
                    //     values,
                    //     errors = {},
                    //   }) => (
                    <>
                      <div>
                        {assetManagerAdmin ? (
                          <>
                            <Box width={1} pt={'4%'}>
                              Member settings
                            </Box>
                            <Box width={1} mx="auto" pt="3%">
                              <Flex width={1} justifyContent="center">
                                <AdminRadio
                                  label="Enable"
                                  id="members-on"
                                  name="assetadmin"
                                  value="on"
                                  defaultChecked={addMember === 'true'}
                                  onChange={() => setAddMember('true')}
                                  bgColor="#c95cfc"
                                />
                                <AdminRadio
                                  label="Disable"
                                  id="members-off"
                                  name="assetadmin"
                                  value="false"
                                  bgColor="#c95cfc"
                                  defaultChecked={addMember === 'false'}
                                  onChange={() => setAddMember('false')}
                                />
                              </Flex>
                            </Box>
                          </>
                        ) : null}
                        <LoaderWrapper
                          styles={{ marginTop: '2rem' }}
                          isLoading={generalSettingsLoading}
                        >
                          {!isEmpty(advisorsList) && addMember === 'true' ? (
                            <MembersTable
                              isAssetAdmin={assetManagerAdmin}
                              advisorsList={advisorsList}
                              changeHandler={changeHandler}
                              toggleAllHandler={toggleAllHandler}
                              toggleAll={toggleAll}
                              userData={userData}
                              width={advisorsList && advisorsList.length > 5 ? '88%' : '80%'}
                            />
                          ) : null}
                        </LoaderWrapper>
                      </div>
                      <form>
                        {!isEmpty(get(socialNetworkData, 'sf', [])) && (
                          <>
                            <Box width={1} pt={'4%'}>
                              Salesforce settings
                            </Box>
                            <Box width={1} pt={'4%'}>
                              <AdvisorSettings
                                settingsArray={salesforceSettings}
                                changeHandler={handleAttrChange}
                              />
                            </Box>
                          </>
                        )}
                        <Box
                          width={1}
                          pt={'4%'}
                          data-testid="compliance-settings"
                        >
                          Compliance settings
                        </Box>
                        <Box width={1 / 1.5} mx="auto" pt="3%">
                          <Flex width={1} justifyContent="center">
                            <AdminRadio
                              label="Always On"
                              id="compliance-on"
                              name="compliance"
                              value="on"
                              defaultChecked={compliance === 'on'}
                              onChange={() => {
                                console.log('[CLICKED] - ');
                                setCompliance('on');
                              }}
                              disabled={isFirmLevelAdmin}
                              pt="2px"
                              bgColor="#c95cfc"
                              data-testid="compliance-on"
                            />
                            {console.log(
                              'OPTION WHILE RENDER - -- - ',
                              isEnterpriseLevelAdmin,
                              isFirmLevelAdmin,
                              firmSettings,
                              compliance
                            )}

                            <AdminRadio
                              label="Always Off"
                              id="compliance-off"
                              name="compliance"
                              value="off"
                              defaultChecked={compliance === 'off'}
                              // onChange={() => setCompliance('off')}
                              onChange={() => {
                                console.log('[CLICKED] - ');
                                setCompliance('off');
                              }}
                              disabled={isFirmLevelAdmin}
                              pt="2px"
                              bgColor="#c95cfc"
                              data-testid="compliance-off"
                            />
                            <AdminRadio
                              label="Optional"
                              id="compliance-optional"
                              name="compliance"
                              value="optional"
                              defaultChecked={compliance === 'optional'}
                              disabled={isFirmLevelAdmin}
                              onChange={() => setCompliance('optional')}
                              pt="2px"
                              bgColor="#c95cfc"
                              data-testid="compliance-optional"
                            />
                          </Flex>
                        </Box>
                        <BccField value={bccList} setValue={setBccList} />
                        {error && (
                          <ErrorContainer align="center">
                            {error}
                          </ErrorContainer>
                        )}
                        <Box pt="3%">
                          <LoaderWrapper isLoading={loader}>
                            <Button
                              bgColor="#7dc4d0"
                              styles={{ border: 'none' }}
                              onClick={() => {
                                console.log(
                                  '***** CALLING SAVE SETTINGS *****'
                                );
                                saveSettings();
                              }}
                              testId="compliance-settings-save"
                            >
                              Save
                            </Button>
                          </LoaderWrapper>
                        </Box>
                      </form>
                    </>
                  )}
                  {/* </Formik>
                  )} */}
                </>
              )}
            </TabConfig>
          </BorderWhiteBox>
        </Tabs>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const complianceAdmin = ROLE_TYPES.ADMIN_COMPLIANCE === roleType;
  const disabledPlatforms = get(
    state.user,
    'data.details.user.member_settings.disabled_social_platforms'
  );
  const assetManagerAdmin = ROLE_TYPES.ADMIN_ASSET_MANAGER === roleType;
  const adminEnterprise = ROLE_TYPES.ADMIN_ENTERPRISE === roleType;
  // console.log('assetManagerAdmin');
  console.log(roleType);
  const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', "false") === "true";
  const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', "false") === "true";
  const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });
  console.log('FIRM SETTINGS - - -- -', firmSettings);
  console.log('STATE - - -- -', state);
  return {
    contactError: state.contacts.error,
    contactsList: state.contacts.data,
    contactGroupsList: state.contacts.groupData,
    contactGroups: state.contacts.contactGroups,
    selectedContactGroup: state.contacts.selectedContactGroup,
    userData: getUserDetails(state),
    isContactsFetching: state.contacts.isFetching || false,
    socialNetworkData: state.socialNetwork.data,
    socialNetworkDataFetching: state.socialNetwork.isFetching,
    paginationData: state.contacts.pagination,
    navigateAfter: state.contacts.afterData,
    selectedContactGroupDefault: state.contacts.selectedContactGroupDefault,
    userDetails: state.user.data,
    selectedTabIndex: state.contacts.selectedTabIndex,
    complianceAdmin,
    disabledPlatforms: disabledPlatforms && JSON.parse(disabledPlatforms),
    assetManagerAdmin,
    adminEnterprise,
    customContactGroupList: state.contacts.customContactGroupList,
    contactsCount: state.contacts.totalCount,
    userId: state.user.data && state.user.data.uid,
    isEnterpriseLevelAdmin,
    isFirmLevelAdmin,
    firmSettings: firmSettings
  };
};

const mapDispatchToProps = {
  contactsRequest: contactsActions.api.contacts.request,
  contactsDelete: contactsActions.api.contacts.delete,
  contactsCreate: contactsActions.api.contacts.create,
  socialNetworkDelete: socialNetworkActions.api.socialNetwork.delete,
  socialNetworkStatusRequest: socialNetworkActions.api.socialNetwork.request,
  loginRequest: loginActions.api.login.request,
  setSelectedTabIndexRequest: contactsActions.api.contacts.selectedtabindex,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureContainer);
