import React from 'react';

import { Switch, Route, useHistory } from 'react-router-dom';
import {
  AdminAnalytics,
  AdvisorAnalytics,
  AnalyticsSummary,
  Channel,
  Compliance,
  DashboardLayout,
  Login,
  Portfolio,
  AdvisorConfigure,
  AdminConfigure,
  Theme,
  Subscription,
  ImpactSummary,
  Newsletter,
  Content,
  DripCampaignLayoutV2,
  ProfessionalResearch,
  CampaignEmailTemplateLayout,
  EmailEditorLayout,
  CampaignContainerViewLayout,
  LandingPageEditorLayout,
  ComplianceApproveAllLayout
} from './layouts';

import BeeFreeEditorWrapper from './containers/DripCampaignContainerV2/BeeFreeEditorWrapper/BeeFreeEditorWrapper';
import AdvisorSettings from './containers/AdvisorSettings/AdvisorSettings';
import CustomRowsLayout from './layouts/CustomRowsLayout';
// import CustomRowsEditorContainer from './containers/CustomRowsEditorContainer/CustomRowsEditorContainer';
import CustomRowsEditorLayout from './layouts/CustomRowsEditorLayout';
import ResetPassword from './layouts/ResetPassword/ResetPassword';
import ComplianceApproveContainerLayout from './layouts/ComplianceApproveContainerLayout';
import ComplianceRejectContainerLayout from './layouts/ComplianceRejectContainerLayout';
import CreateEmailContainer from './containers/AutomatedCampaigns/Email/CreateEmailContainer/CreateEmailContainer';
import LandingPageLayout from './layouts/LandingPageLayout';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { oktaHelper } from './utils';
import Unsubscribe from './layouts/Unsubscribe';
import ComplianceRejectAllLayout from './layouts/ComplianceRejectAllLayout';

const Routes = () => {
  
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  return (
    <div style={{ height: '100%' }}>
      <Security oktaAuth={oktaHelper} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route exact path={['/', '/admin/login', '/login']} component={Login} />
          <Route exact path="/admin/dashboard" component={DashboardLayout} />
          <Route exact path="/dashboard" component={DashboardLayout} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/admin/analytics" component={AdminAnalytics} />
          <Route exact path="/analytics/summary" component={AnalyticsSummary} />
          <Route exact path="/channel" component={Channel} />
          <Route exact path="/analytics" component={AdvisorAnalytics} />
          <Route exact path="/compliance" component={Compliance} />
          <Route exact path="/admin/compliance" component={Compliance} />
          <Route
            exact
            path="/advisor/compliance"
            render={(props) => <Compliance {...props} />}
          />
          <Route exact path="/admin/configure" component={AdminConfigure} />
          <Route exact path="/configure" component={AdvisorConfigure} />
          <Route exact path="/theme" component={Theme} />
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/impact-summary-pdf" component={ImpactSummary} />
          <Route exact path="/newsletter" component={Newsletter} />
          <Route
            exact
            path="/content/:companyAccountId/:userId/:articleId"
            component={Content}
          />
          <Route exact path="/callback" component={ResetPassword} />
          <Route exact path="/campaignV2" component={DripCampaignLayoutV2} />
          <Route exact path="/customize-email" component={BeeFreeEditorWrapper} />
          <Route exact path="/create-email" component={CreateEmailContainer} />
          <Route
            exact
            path="/campaign/template"
            component={CampaignEmailTemplateLayout}
          />
          <Route
            exact
            path="/campaign/template/:id"
            component={EmailEditorLayout}
          />
          <Route
            exact
            path="/campaign/custom-row"
            component={CustomRowsLayout}
          />
          <Route
            exact
            path="/campaign/custom-row/:id"
            component={CustomRowsEditorLayout}
          />
          <Route exact path="/advisor-settings" component={AdvisorSettings} />
          <Route exact path="/survey" component={ProfessionalResearch} />
          <Route
            exact
            path="/campaign/view/:id"
            component={CampaignContainerViewLayout}
          />
          <Route
            exact
            path="/compliance/approve"
            component={ComplianceApproveContainerLayout}
          />
          <Route
            exact
            path="/compliance/approve_all"
            component={ComplianceApproveAllLayout}
          />
          <Route
            exact
            path="/compliance/reject"
            component={ComplianceRejectContainerLayout}
          />
           <Route
            exact
            path="/compliance/reject_all"
            component={ComplianceRejectAllLayout}
          />
          <Route
            exact
            path="/campaign/landing-pages"
            component={LandingPageLayout}
          />
          <Route
            exact
            path="/campaign/landing-pages/:pageId"
            component={LandingPageEditorLayout}
          />
          <Route 
            exact
            path="/unsubscribe"
            component={Unsubscribe}
          />
        </Switch>
      </Security>

    </div>
  );
  
};

export default Routes;
