import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { cascadeEnterpriseUsers, cascadeLandingPage, deleteComplianceLandingPages, deleteLandingPage, downloadFormDataCSV, getApproveToSharePages, getLandingPages, rejectComplianceLandingPage } from '../../services/bee-editor';
import { TemplateCard, TemplateCardWrapper, TemplateWrapper } from '../CampaignEmailTemplateContainer/CampaignEmailTemplateContainer.styles';
import { getRoleType, getUserRole } from '../../redux/selector';
import { LoaderWrapper, Button } from '../../components';
import { get, isEmpty } from 'lodash';
import CascadeToAdvisorsModal from '../../components/CascadeToAdvisorsModal/CascadeToAdvisorsModal';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import OverlayButtons from './OverlayButtons/OverlayButtons';
import ExportToPdf from '../DripCampaignContainerV2/DripDashboardTable/CampaignMenu/ExportToPdf';
import { parse } from 'json2csv';
import { ROLE_TYPES, saveFile } from '../../utils';
import CascadeEnterpriseModal from './CascadeEnterpriseModal';

const LandingPageContainer = ({ 
    isAdmin, 
    membersList, 
    isEnterpriseLevelAdmin,
    isFirmLevelAdmin,
    firmsArray,
    isAdvisorEnterprise
 }) => {

    const history = useHistory();

    const [pageList, setPageList] = useState([]);
    const [pageLoader, togglePageLoader] = useState(false);
    const [csvLoader, toggleCsvLoader] = useState(false);
    const [deleteLoader, toggleDeleteLoader] = useState(false);
    const [deleteModal, toggleDeleteModal] = useState({status: false, id: ''});
    const [downloadCsvModal, toggleDownloadCsvModal] = useState({status: false, id: ''})
    const [shareLoader, toggleShareLoader] = useState(false);
    const [showOverlayId, toggleOverlayId] = useState('');
    const [showCascadeModal, toggleCascadeModal] = useState({status: false, id: ''});
    const [modalMessage, setModalMessage] = useState('');
    const [openExportPdfModal, toggleExportToPdfModal] = useState({status: false, pageObj: {}});
    const [selectedFirms, setSelectedFirms] = useState([]);
    const [cascadeOption, setCascadeOption] = useState('advisors');
    const [OGCascadeOption, setOGCascadeOption] = useState('advisors');
    const [modalLoader, toggleModalLoader] = useState(false);
    const [OGSelectedAdvisors, setOGSelectedAdvisors] = useState([]);
    const [OGSelectedFirms, setOGSelectedFirms] = useState([]);
    const [fetchingCascadeInfo, toggleFetchingCascadeInfo] = useState(false);
    const [shareDisabled, toggleShareDisabled] = useState(false);
    const [selectedAdvisors, setSelectedAdvisors] = useState([]);
    const [cascadeModalOpen, setCascadeModalOpen] = useState({status: false, id: ''});
    const [selectedFirmAdvisors, setSelectedselectedFirmAdvisors] = useState([]);
    const [OGSelectedFirmAdvisors, setOGSelectedFirmAdvisors] = useState([]);
    const [complianceModal, setComplianceModal] = useState(false);
    const [selectedLandingPage, setSelectedLandingPage] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [approveToSharePages, setApproveToSharePages] = useState([]);

    const leadFormMapper = {
        'Option_0': '',
        'Option_1': 'I already work with a financial advisor but am interested to discuss this topic in more detail',
        'Option_2': 'I am not looking for a financial advisor but am interested to discuss this topic in more detail',
        'Option_3': 'I am looking for a financial advisor and am interested to discuss this topic in more detail',
    }

    const statusColorMapper = {
        'Compliance Pending': '#CC6AC0',
        'Compliance Rejected': '#FF2323',
        'Compliance Approved': '#33B21B',
        'Active': '#33B21B',
        'Inactive': '#4E6780'
    }

    useEffect(() => {
        const windowBodyClassList = document.body.classList;
        if (!windowBodyClassList.contains('campaign')) {
            windowBodyClassList.add('campaign');
        }

        return () => {
            windowBodyClassList.remove('campaign');
        };
    }, []);


    useEffect(() => {
        fetchLandingPages();
    }, []);

    const fetchLandingPages = () => {
        togglePageLoader(true);
        getLandingPages()
        .then(response => {
            console.log('RESPONSE fetchLandingPages - - - -', response);
            setPageList(get(response, 'data', []));
            togglePageLoader(false);
        })
        .catch(err => {
            console.log('ERROR - - - - -', err);
            togglePageLoader(false);
        })
    }

    const fetchApproveToSharePages = () => {
        togglePageLoader(true);
        getApproveToSharePages().then(res => {
            setApproveToSharePages(get(res, 'data', []));
            togglePageLoader(false);
        }).catch(err => {
            togglePageLoader(false);
        });
    }

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        if(tab === 0) {
            fetchLandingPages();
        } else {
            fetchApproveToSharePages();
        }
    }

    const rejectLandingPage = () => {
        toggleModalLoader(true);
        rejectComplianceLandingPage({
            id: selectedLandingPage._id,
            payload: {
                action: 'rejected',
                message: 'User has done'
            }
        }).then(res => {
            toggleModalLoader(false);
            history.push(`/campaign/landing-pages/${selectedLandingPage._id}`);
        }).catch(err => {
            console.log(err);
            toggleModalLoader(false);
        })
    }

    const handleEnterpriseLevelAdminShare = () => {
        toggleModalLoader(true);
        setModalMessage('');
        if (cascadeOption === 'onlyAdvisors') {
            onShareClick(selectedAdvisors);
        } else {
            const payload = {
                cascaded_to: cascadeOption === 'admins' ? selectedFirms : selectedFirmAdvisors,
                type: cascadeOption === 'admins' ? 'admin' : 'advisor',
            };
            cascadeEnterpriseUsers({
                payload,
                id: cascadeModalOpen.id
            })
                .then((res) => {
                    toggleModalLoader(false);
                    setModalMessage('Cascaded Successfully!');
                    setTimeout(() => {
                        setCascadeModalOpen({status: false, id: ''});
                        fetchLandingPages();
                        setModalMessage('');
                    }, 3000);
                })
                .catch((err) => {
                    console.log('ERROR - - -- ', err);
                    setModalMessage('Oops..Some error occurred! Please try again later.');
                    toggleModalLoader(false);
                });
        }
    }

    const shareValidationCheck = () => {
        let disableShare = true;
        if (selectedAdvisors.includes('selectAll'))
          disableShare =
            selectedAdvisors.filter((adv) => adv !== 'selectAll').length ===
            OGSelectedAdvisors.length;
        else if (selectedAdvisors.length !== OGSelectedAdvisors.length)
          disableShare = false;
        else
          disableShare = selectedAdvisors.every((adv) =>
            OGSelectedAdvisors.includes(adv)
          );
        return disableShare;
    };

    const selectMembersHandler = (e, selectedMember) => {
        let updatedAdvisorsList = [...selectedAdvisors];
        if (selectedMember.id === 'selectAll') {
            if (e.target.checked)
                updatedAdvisorsList = membersList.map((member) => member.id);
            else updatedAdvisorsList = [];
        } else if (selectedAdvisors.includes(selectedMember.id))
            updatedAdvisorsList = [...selectedAdvisors].filter(
                (advisorId) =>
                    advisorId !== selectedMember.id && advisorId !== 'selectAll'
            );
        else {
            updatedAdvisorsList = [...selectedAdvisors, selectedMember.id];
            if (updatedAdvisorsList.length === membersList.length - 1)
                updatedAdvisorsList.push(membersList[0].id);
        }
        setSelectedAdvisors(updatedAdvisorsList);
    }

    const handleComplianceEdit = (page) => {
        setComplianceModal(true);
        setSelectedLandingPage(page);
    }

    const closeCascadeModalHandler = () => {
        setCascadeModalOpen({status: false, id: ''});
    }

    const deleteHandler = () => {
        setModalMessage('');
        toggleDeleteLoader(true);
        deleteLandingPage({ id: deleteModal.id })
        .then(res => {
            if(!res.data.skip_compliance) {
                return deleteComplianceLandingPages({ id: res.data._id });
            } else {
                console.log('RES DELETE - - ', res);
                toggleDeleteLoader(false);
                toggleDeleteModal({status: false, id: ''});
                fetchLandingPages();
            }  
        })
        .then(res => {
                console.log('RES DELETE - - ', res);
                toggleDeleteLoader(false);
                toggleDeleteModal({status: false, id: ''});
                fetchLandingPages();
        })
        .catch(err => {
            toggleDeleteLoader(false);
            setModalMessage('Oops! An error occurred. Please try again later...')
            console.log('ERROR - - - -- ', err);
        })
    }

    const closeModalHandler = source => {
        setModalMessage('');
        const updateObj = {status: false, id: ''}
        if(source === 'delete') 
            toggleDeleteModal(updateObj);
        else if(source === 'cascade')
            toggleCascadeModal(updateObj);
        else if(source === 'csv')
            toggleDownloadCsvModal(updateObj);
    }

    const onShareClick = advArr => {
        setModalMessage('');
        toggleShareLoader(true);
        let advisorsArray = advArr;
        if(advisorsArray.includes('selectAll'))
            advisorsArray = advisorsArray.filter(id => id !== 'selectAll');
        cascadeLandingPage({ 
            id: showCascadeModal.id || cascadeModalOpen.id, 
            payload: { cascaded_to: JSON.stringify(advisorsArray)} 
        })
        .then(res => {
            console.log('RESPONSE -- - - ', res);
            setModalMessage('Success!');
            setTimeout(() => {
                setCascadeModalOpen({status: false, id: ''});
                setModalMessage('');
                toggleShareLoader(false);
                toggleModalLoader(false);
                toggleCascadeModal({status: false, id: ''});
                fetchLandingPages();
            }, 3000);
        })
        .catch(err => {
            console.log('ERROR - - --  -', err);
            setModalMessage('Oops..! An error occurred. Please try again...');
            toggleShareLoader(false);
            toggleModalLoader(false);
        });
    }

    const downloadCsvHandler = () => {
        console.log('CSV PAGE - -- - ', downloadCsvModal.id);
        toggleCsvLoader(true);
        setModalMessage('');
        downloadFormDataCSV({id: downloadCsvModal.id})
        // axios.get(`http://localhost:4000/download/csv/${downloadCsvModal.id}`)
        .then(res => {
            console.log('RESPONSE - -- - ', res);
            const formDataArray = get(res, 'formData', []).map(ele => ele.form_data);
            console.log('FORM DATA ARRAY - - - --  ', formDataArray);
            toggleCsvLoader(false);
            let csvData;
            if(formDataArray.length > 0) {
                if(formDataArray[0].form_type === 'event_form') {
                    csvData = parse(formDataArray, {
                        fields: ['first_name', 'last_name', 'email', 'phone_number', 'dropdown_1', 'dropdown_2', 'dropdown_3', 'dropdown_4', 'dropdown_5', 'privacy-policy'],
                    });
                } else if(formDataArray[0].form_type === 'lead_form') {
                    formDataArray.forEach(ele => {
                        if(ele.preferrence_drop_down) {
                            ele.preferrence_drop_down = leadFormMapper[ele.preferrence_drop_down] ? leadFormMapper[ele.preferrence_drop_down] : ele.preferrence_drop_down && ele.preferrence_drop_down > 0 ? ele.preferrence_drop_down[0] : ele.preferrence_drop_down;
                        }
                    })
                    csvData = parse(formDataArray, {
                        fields: ['first_name', 'last_name', 'email', 'preferrence_drop_down', 'privacy-policy'],
                    })
                } else {
                    csvData = parse(formDataArray, {
                        fields: ['first_name', 'last_name', 'email', 'privacy-policy'],
                    });
                }
              
                const clickedPage = pageList.find(page => page._id === downloadCsvModal.id);
                const pageName = clickedPage.name
                saveFile(csvData, `${pageName}_${new Date().getTime()}`, 'csv');
                toggleDownloadCsvModal({status: false, id: ''});
            }
            else 
                setModalMessage('No data found!');
        })
        .catch(err => {
            console.log('ERROR DOWNLOADING CSV - - - ', err);
            toggleCsvLoader(false);
            setModalMessage('Oops! Some error occurred...');
        });
        
    }

    return (
        <TemplateWrapper width="100%" flexDirection='column'>
            <Flex width='100%' justifyContent='space-between'>
                <Flex>
                    {isAdvisorEnterprise && (
                        <>
                            <Button
                                bgColor={selectedTab === 0 ? '#6351ed' : '#fff'}
                                color={selectedTab === 0 ? '#fff' : '#6351ed'}
                                margin='0'
                                onClick={() => handleTabChange(0)}
                            >
                                My Page Templates 
                            </Button>
                            <Button
                                bgColor={selectedTab === 1 ? '#6351ed' : '#fff'}
                                color={selectedTab === 1 ? '#fff' : '#6351ed'}
                                margin='0'
                                onClick={() => handleTabChange(1)}
                            >
                                Approved To Share
                            </Button>
                        </>
                    )}

                </Flex>
                <Flex>
                    <Button padding='7px 10px' width="100px" margin='0' onClick={selectedTab === 0 ? fetchLandingPages : fetchApproveToSharePages}>
                        <Flex justifyContent='space-between' alignItems='center'>
                            Refresh
                            <img alt="" height='20px' src='/assets/images/Sync-white.svg' />
                        </Flex>
                    </Button>
                </Flex>
            </Flex>
            {selectedTab === 0 ? (
                <TemplateCardWrapper>
                    <TemplateCard onClick={() => history.push('/campaign/landing-pages/new')} style={{ cursor: 'pointer' }}>
                        <Flex height="85%" alignItems="center" justifyContent="center">
                            <FontAwesomeIcon icon={faPlus} size="3x" color='#000000' />
                        </Flex>
                        <Flex justifyContent="center">
                            Create New Page Template
                        </Flex>
                    </TemplateCard>
                    <LoaderWrapper
                        isLoading={pageLoader}
                        styles={{ position: 'relative', top: '-70%', left: '2%' }}
                    >
                        {pageList && pageList.map(page => (
                            <TemplateCard
                                id={page._id}
                                style={{ cursor: 'pointer', position: 'relative' }}
                                onMouseLeave={() => toggleOverlayId('')}
                            >
                                <div
                                    style={{ height: '83%', width: '100%', paddingTop: '5px' }}
                                    onMouseEnter={() => toggleOverlayId(page._id)}
                                >
                                    {page.compliance_status && !page.is_global_compliance && (
                                        <span
                                            style={{
                                                fontSize: '12px',
                                                color: statusColorMapper[page.compliance_status],
                                                fontStyle: 'italic'
                                            }}
                                        >
                                            {page.compliance_status}
                                        </span>
                                    )}

                                    {page.is_global_compliance && (
                                        <span
                                            style={{
                                                width: '50px',
                                                backgroundColor: '#49484a',
                                                color: '#fff',
                                                borderRadius: '3px',
                                                padding: '3px 6px',
                                                fontSize: '10px',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            Global Approved
                                        </span>
                                    )}
                                    <img
                                        height='83%'
                                        width='100%'
                                        src={page.thumbnail_url}
                                        style={{ objectFit: 'contain' }}
                                        alt=""
                                    />
                                </div>
                                {/* {true && ( */}
                                {showOverlayId === page._id && (
                                    <OverlayButtons
                                        page={page}
                                        toggleExportToPdfModal={toggleExportToPdfModal}
                                        toggleDownloadCsvModal={toggleDownloadCsvModal}
                                        handleComplianceEdit={handleComplianceEdit}
                                        selectedTab={selectedTab}
                                    />
                                )}
                                <Flex
                                    padding='8px 15px'
                                    justifyContent='space-between'
                                    width='100%'
                                    alignItems='center'
                                    onMouseEnter={() => toggleOverlayId('')}
                                >
                                    <div
                                        style={{ width: isAdmin ? '83%' : '90%' }}
                                    // style={{ width: '92%'}}
                                    >
                                        {page?.name.length > 26 ? `${page.name.substring(26)}...` : page.name}
                                    </div>
                                    <Flex
                                        width={isAdmin ? '17%' : '10%'}
                                        // width='8%'
                                        justifyContent='space-between'
                                        alignItems='center'
                                    >
                                        {(isAdmin || isEmpty(page.cascaded_to)) && (
                                            <img
                                                alt="delete"
                                                src='/assets/images/delete.svg'
                                                onClick={() => toggleDeleteModal({ status: true, id: page._id })}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        )}
                                        {(isAdmin && (page.skip_compliance || page.compliance_status === 'Compliance Approved')) && (
                                            <img
                                                src='/assets/images/forward.svg'
                                                alt=""
                                                onClick={() => {
                                                    if (isEnterpriseLevelAdmin) {
                                                        let selectedPage = pageList.filter(p => p._id === page._id);
                                                        console.log(selectedPage);
                                                        setOGSelectedFirms(selectedPage[0].cascaded_to_admins.map(String));
                                                        setSelectedFirms(selectedPage[0].cascaded_to_admins.map(String));
                                                        setSelectedselectedFirmAdvisors(selectedPage[0].cascaded_to_advisors.map(String));
                                                        setOGSelectedFirmAdvisors(selectedPage[0].cascaded_to_advisors.map(String));
                                                        if(selectedPage[0]?.cascaded_to.length === membersList.length - 1) {
                                                            setSelectedAdvisors([...selectedPage[0].cascaded_to, 'selectAll']);
                                                            setOGSelectedAdvisors([...selectedPage[0].cascaded_to, 'selectAll']);
                                                        } else {
                                                            setSelectedAdvisors(selectedPage[0].cascaded_to);
                                                            setOGSelectedAdvisors(selectedPage[0].cascaded_to);
                                                        }
                                                        setCascadeModalOpen({ status: true, id: page._id });
                                                    } else {
                                                        toggleCascadeModal({ status: true, id: page._id })
                                                    }
                                                }}
                                                // onMouseEnter={() => toggleTooltip('')}
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '22px',
                                                }}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            </TemplateCard>
                        ))}
                    </LoaderWrapper>
                </TemplateCardWrapper>
            ) : (
                <TemplateCardWrapper>
                    <LoaderWrapper
                        isLoading={pageLoader}
                        styles={{ position: 'relative', top: '70%', left: '2%' }}
                    >
                        {approveToSharePages && approveToSharePages.map(page => (
                            <TemplateCard
                                id={page._id}
                                style={{ cursor: 'pointer', position: 'relative' }}
                                onMouseLeave={() => toggleOverlayId('')}
                            >
                                <div
                                    style={{ height: '83%', width: '100%', paddingTop: '5px' }}
                                    onMouseEnter={() => toggleOverlayId(page._id)}
                                >
                                    {page.compliance_status && !page.is_global_compliance && (
                                        <span
                                            style={{
                                                fontSize: '12px',
                                                color: statusColorMapper[page.compliance_status],
                                                fontStyle: 'italic'
                                            }}
                                        >
                                            {page.compliance_status}
                                        </span>
                                    )}

                                    {page.is_global_compliance && (
                                        <span
                                            style={{
                                                width: '50px',
                                                backgroundColor: '#49484a',
                                                color: '#fff',
                                                borderRadius: '3px',
                                                padding: '3px 6px',
                                                fontSize: '10px',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            Global Approved
                                        </span>
                                    )}
                                    <img
                                        height='83%'
                                        width='100%'
                                        src={page.thumbnail_url}
                                        style={{ objectFit: 'contain' }}
                                        alt=""
                                    />
                                </div>
                                {/* {true && ( */}
                                {showOverlayId === page._id && (
                                    <OverlayButtons
                                        page={page}
                                        toggleExportToPdfModal={toggleExportToPdfModal}
                                        toggleDownloadCsvModal={toggleDownloadCsvModal}
                                        handleComplianceEdit={handleComplianceEdit}
                                        selectedTab={selectedTab}
                                    />
                                )}
                                <Flex
                                    padding='8px 15px'
                                    justifyContent='space-between'
                                    width='100%'
                                    alignItems='center'
                                    onMouseEnter={() => toggleOverlayId('')}
                                >
                                    <div
                                        style={{ width: isAdmin ? '83%' : '90%' }}
                                    // style={{ width: '92%'}}
                                    >
                                        {page?.name.length > 26 ? `${page.name.substring(26)}...` : page.name}
                                    </div>
                                    <Flex
                                        width={isAdmin ? '17%' : '10%'}
                                        // width='8%'
                                        justifyContent='space-between'
                                        alignItems='center'
                                    >
                                        {(selectedTab !== 1 && (isAdmin || isEmpty(page.cascaded_to))) && (
                                            <img
                                                alt="delete"
                                                src='/assets/images/delete.svg'
                                                onClick={() => toggleDeleteModal({ status: true, id: page._id })}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        )}
                                        {(selectedTab !== 1 && isAdmin && (page.skip_compliance || page.compliance_status === 'Compliance Approved')) && (
                                            <img
                                                src='/assets/images/forward.svg'
                                                alt=""
                                                onClick={() => {
                                                    if (isEnterpriseLevelAdmin) {
                                                        let selectedPage = pageList.filter(p => p._id === page._id);
                                                        console.log(selectedPage);
                                                        setOGSelectedFirms(selectedPage[0].cascaded_to_admins.map(String));
                                                        setSelectedFirms(selectedPage[0].cascaded_to_admins.map(String));
                                                        setSelectedselectedFirmAdvisors(selectedPage[0].cascaded_to_advisors.map(String));
                                                        setOGSelectedFirmAdvisors(selectedPage[0].cascaded_to_advisors.map(String));
                                                        setSelectedAdvisors(selectedPage[0].cascaded_to);
                                                        setCascadeModalOpen({ status: true, id: page._id });
                                                    } else {
                                                        toggleCascadeModal({ status: true, id: page._id })
                                                    }
                                                }}
                                                // onMouseEnter={() => toggleTooltip('')}
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '22px',
                                                }}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            </TemplateCard>
                        ))}
                    </LoaderWrapper>
                </TemplateCardWrapper>
            )}

            {(deleteModal.status || downloadCsvModal.status) && (
                <ConfirmationModal 
                    modalTitle={
                        downloadCsvModal.status ? 
                        'Do you want to download CSV?' : 
                        'Do you want to delete this Page Template?'
                    }
                    modalWidth='38%'
                    closeHandler={() => closeModalHandler(downloadCsvModal.status ? 'csv' : 'delete')}
                    clickHandler={downloadCsvModal.status ? downloadCsvHandler : deleteHandler}
                    loader={downloadCsvModal.status ? csvLoader : deleteLoader}
                    message={modalMessage}
                />
            )}
            {
                cascadeModalOpen.status && isEnterpriseLevelAdmin && (
                    <CascadeEnterpriseModal
                        firmsArray={firmsArray}
                        memberAdvisorsArray={membersList}
                        closeHandler={closeCascadeModalHandler}
                        selectedFirms={selectedFirms}
                        setSelectedFirms={setSelectedFirms}
                        cascadeOption={cascadeOption}
                        OGCascadeOption={OGCascadeOption}
                        setOGCascadeOption={setOGCascadeOption}
                        setCascadeOption={setCascadeOption}
                        OGSelectedFirms={OGSelectedFirms}
                        loader={modalLoader}
                        cascadeHandler={handleEnterpriseLevelAdminShare}
                        fetchingInfo={fetchingCascadeInfo}
                        modalMessage={modalMessage}
                        membersList={membersList}
                        shareDisabled={shareDisabled}
                        shareValidationCheck={shareValidationCheck}
                        selectMembersHandler={selectMembersHandler}
                        selectedAdvisors={selectedAdvisors}
                        OGSelectedAdvisors={OGSelectedAdvisors}
                        selectedFirmAdvisors={selectedFirmAdvisors}
                        OGSelectedFirmAdvisors={OGSelectedFirmAdvisors}
                        setSelectedselectedFirmAdvisors={setSelectedselectedFirmAdvisors}
                        setOGSelectedFirmAdvisors={setOGSelectedFirmAdvisors}
                        modalTitle="Share Landing Page Template with the following advisors" 
                  />
                )
            }
            {showCascadeModal.status && !isEnterpriseLevelAdmin && (
                <CascadeToAdvisorsModal
                    isOpen={showCascadeModal.status}
                    closeHandler={() => closeModalHandler('cascade')}
                    shareHandler={onShareClick}
                    modalTitle='Share Landing Page Template with the following advisors'
                    modalWidth='32%'
                    loader={shareLoader}
                    membersList={membersList}
                    OGSelectedAdvisors={pageList.find(page => page._id === showCascadeModal.id)?.cascaded_to || []}
                />
            )}
            {openExportPdfModal.status && (
                <ExportToPdf 
                    pageId={openExportPdfModal.pageObj._id}
                    title={openExportPdfModal.pageObj.name}
                    complianceStatus={openExportPdfModal?.pageObj?.compliance_status}
                    handleClose={() => toggleExportToPdfModal({status: false, pageObj: {}})}
                    isOpen={openExportPdfModal.status}
                    isLandingPage={true}
                />
            )}
            {complianceModal && (
                <ConfirmationModal
                    modalTitle="Are you sure you want to edit the Campaign Page?"
                    closeHandler={() => {
                        setComplianceModal(false);
                        setSelectedLandingPage(null);
                    }}
                    clickHandler={rejectLandingPage}
                    loader={modalLoader}
                    modalWidth="40%"
                    btnMargin="0px"
                />
            )}
        </TemplateWrapper>
    );
};

const mapStateToProps = state => {
    const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', "false") === "true";
    const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', "false") === "true";
    const roleType = getRoleType(state);
    const isAdvisorEnterprise = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
    return {
        isAdmin: getUserRole(state) === 'admin',
        membersList: state.user.membersList ? [
            {
              id: 'selectAll',
              details: {
                name: { email: 'Select All', first: 'Select', last: 'All' },
              },
            },
            ...state.user.membersList,
        ] : [],
        isEnterpriseLevelAdmin,
        isFirmLevelAdmin,
        firmsArray: get(state, 'user.data.details.company.firms', []),
        isAdvisorEnterprise
    };
};

export default connect(mapStateToProps)(LandingPageContainer);