/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateContainer } from './CreateEmail.styles.jsx';
import SelectTemplate from '../SelectTemplate/SelectTemplate';
import EmailSubject from '../EmailSubject/EmailSubject';
import { Stepper } from '../../../DripCampaignContainerV2/Stepper/Stepper';
import { Flex } from '@rebass/grid';
import { getEmailTemplates } from '../../../../services/campaign';
import { get, isEmpty, uniqBy } from 'lodash';
import { connect } from 'react-redux';
import { actions as campaignActions } from '../../../../redux/modules/campaign.module';
import { approvedToShareArticlesData, getContentLibArticleData, getExploreArticleData, getMyContentArticles } from '../../../../utils/explore.helper.js';
import { getApproveToShareArticles, getBookmarkedArticles, getContentLibrary, getPremiumAdvisorChannelId, getRecommendedArticles } from '../../../../services/dashboard/index.js';
import { ROLE_TYPES } from '../../../../utils/constants.js';
import { getChannelId, getRoleType } from '../../../../redux/selector/login.selectors.js';
import { getLandingPages } from '../../../../services/bee-editor';

const CreateEmailContainer = ({
  campaignId,
  setTemplateBase,
  defaultTemplates,
  setTemplateData,
  campaignSetState,
  selectedIndex,
  selectedBlock,
  selectedBlockId,
  automatedFlowFromDashboard,
  isPremiumAdvisor,
  channelId,
  isEnterpriseAdvisor,
  bookmarkArticles,
  recommendedArticles,
  myContentArticles,
  myContentPendingQueue,
  landingPagesData,
  isEnterpriseAdmin,
  targetInfo
}) => {
  console.log('TARGET INFO [create email container] - - - ', targetInfo);
  const [loading, setLoading] = useState(false);
  

  const history = useHistory();

  useEffect(() => {
    if (selectedIndex === 2) {
      campaignSetState({
        forAutomatedCampaigns: true,
      });
      history.push('/customize-email');
    }
  }, [selectedIndex]);

  useEffect(() => {
    if(!selectedBlockId) {
      history.push('/campaignV2?type=create');
    }
    
    if(isEmpty(landingPagesData)) {
      getLandingPages('campaign').then(res => {
        campaignSetState({ landingPages: res.data })
      }).catch(err => console.log(err));
    }
  }, [])

  useEffect(() => {
    //  setLoading(true);
    if (isEmpty(bookmarkArticles)) {
      getBookmarkedArticles()
        .then((res) => {
          //  setLoading(false);
          if (res.result && res.result.data && res.result.data.feed) {
            let allBookMarkedArticles = getExploreArticleData(
              res.result.data,
              'campaign'
            );
            if (allBookMarkedArticles.length > 0) {
              campaignSetState({ bookmarkArticles: allBookMarkedArticles });
            }
          }
        })
        .catch((err) => {
          //  setLoading(false);
          console.log(err);
        });
    }

    //  setLoading(true);

    if (isEmpty(recommendedArticles)) {
      getRecommendedArticles()
        .then((res) => {
          //  setLoading(false);
          if (res.result && res.result.data && res.result.data.feed) {
            let allRecommendedArticles = getExploreArticleData(
              res.result.data,
              'campaign'
            );
            // console.log(
            //   'ALL RECOMMENDED ARTICLES - -- ',
            //   allRecommendedArticles
            // );
            if (allRecommendedArticles.length > 0) {
              //  console.log('SETTING RECOMMENDED ARTICLES- --  ')
              campaignSetState({ recommendedArticles: allRecommendedArticles });
            }
          }
        })
        .catch((err) => {
          //  setLoading(false);
          console.log(err);
        });
    }

    if (isEmpty(myContentArticles)) {
        // setMyArticlesLoader(true);
        getMyContentArticles({
          payload: {
            userType: isEnterpriseAdvisor ? 'isEnterpriseAdvisor' : isPremiumAdvisor ? 'isPremiumAdvisor' : 'isEnterpriseAdmin', 
            channelId: channelId, 
            beforeToken: null, 
            afterToken: null,
            pendingArticles: myContentPendingQueue,
          },
          localData: {
            currentArticles: [],
          },
          source: 'campaign',
        })
        .then(res => {
          // setMyArticlesLoader(false);
          const newState = get(res, 'newState', {})
          if(!isEmpty(newState))
            campaignSetState(newState);
        })
        .catch(err => {
          // setMyArticlesLoader(false);
          console.log('******* ERROR my-content-articles [CREATE CAMPAIGN] ******');
        });
    }
    // if (isEmpty(myContentArticles)) {
    //   if (isPremiumAdvisor) {
    //     console.log('object1');
    //     //  setLoading(true);
    //     getPremiumAdvisorChannelId()
    //       .then((res) => {
    //         // console.log('RESPONSE ');
    //         if (res && res.result && res.result.data) {
    //           const channel_id =
    //             res.result.data[Object.keys(res.result.data)[0]][0].id;
    //           getContentLibrary(channel_id)
    //             .then((res) => {
    //               //  setLoading(false);

    //               if (
    //                 res.result &&
    //                 res.result.data &&
    //                 res.result.data.channel_content
    //               ) {
    //                 // console.log(res);
    //                 let allMyContentArticles = getContentLibArticleData(
    //                   res.result.data,
    //                   'campaign'
    //                 );
    //                 if (allMyContentArticles.length > 0) {
    //                   campaignSetState({
    //                     myContentArticles: allMyContentArticles,
    //                   });
    //                 }
    //               }
    //             })
    //             .catch((err) => {
    //               //  setLoading(false);
    //               console.log(err);
    //             });
    //         }
    //       })
    //       .catch((err) => {
    //         //  setLoading(false);
    //         console.log('ERROR - - - - ', err);
    //       });
    //   } else {
    //     getContentLibrary(channelId)
    //       .then((res) => {
    //         // console.log('object2');
    //         //  setLoading(false);
    //         let allMyContentArticles = [];
    //         if (
    //           res.result &&
    //           res.result.data &&
    //           res.result.data.channel_content
    //         ) {
    //           // console.log(res);
    //           allMyContentArticles = getContentLibArticleData(
    //             res.result.data,
    //             'campaign'
    //           );
    //         }

    //         if (isEnterpriseAdvisor) {
    //           getApproveToShareArticles({ id: '', source: 'Approve To Share' }).then(
    //             (atsData) => {
    //               // console.log('data ats', atsData);

    //               let atsArticles =
    //                 atsData.result && atsData.result.data
    //                   ? atsData.result.data
    //                   : [];
    //               // console.log('atsArticles', atsArticles);
    //               // console.log('allMyContentArticles', allMyContentArticles);
    //               let sortedArticles = uniqBy(
    //                 [
    //                   ...allMyContentArticles,
    //                   ...approvedToShareArticlesData(atsArticles),
    //                 ].sort(
    //                   (d1, d2) =>
    //                     new Date(d2.created_time).getTime() -
    //                     new Date(d1.created_time).getTime()
    //                 ),
    //                 'id'
    //               );
    //               campaignSetState({
    //                 myContentArticles: sortedArticles,
    //               });
    //               // console.log('sortedArticles', sortedArticles);
    //             }
    //           );
    //         } else {
    //           if (allMyContentArticles.length > 0) {
    //             campaignSetState({
    //               myContentArticles: allMyContentArticles,
    //             });
    //           }
    //         }
    //       })
    //       .catch((err) => {
    //         //  setLoading(false);
    //         console.log(err);
    //       });
    //   }
    // }
  }, []);

  useEffect(() => {
    campaignSetState({
      bookmarkArticles: bookmarkArticles,
      recommendedArticles: recommendedArticles,
      myContentArticles: myContentArticles,
    });
  }, [bookmarkArticles, recommendedArticles, myContentArticles]);
  

  const handleNextClick = () => {
    if (selectedIndex === 0) {
      const filterArray = ['default', 'cascade'];
      setLoading(true);
      getEmailTemplates(filterArray)
        .then((response) => {
          const fetchedTemplates = get(response, 'result.data.templates', []);
          const payload = {
            defaultTemplates: fetchedTemplates,
          };
          const newIndex = get(targetInfo, 'is_cascaded', 'false') === 'true' ? selectedIndex + 2 : selectedIndex + 1
          // const newIndex = get(targetInfo, 'is_cascadedd', 'true') === 'true' ? selectedIndex + 2 : selectedIndex + 1
          setLoading(false);
          // if(get(targetInfo, 'is_cascaded', 'false') === 'true') {
          //   payload.setTemplate = 
          // }
          // setTemplate(pin):'no-content' => '5-5-5-5'
          // owner(pin):'default' => ''
          // selectedBlock: {
          //   ...selectedBlock,
          //   template: selectedTemplate.name,
          //   template_id: selectedTemplate.id,
          // }
          campaignSetState({ selectedIndex: newIndex, ...payload });
          // campaignSetState({ selectedIndex: newIndex });
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (selectedIndex === 1) {
      campaignSetState({ selectedIndex: selectedIndex + 1 });
    } else if (selectedIndex === 2) {
      alert('done...');
    }
  };

  const handleBackButtonClick = () => {
    if (selectedIndex > 0) {
      campaignSetState({ selectedIndex: selectedIndex - 1 });
    } else if(automatedFlowFromDashboard) {
      history.push('/campaignV2');
    }
    else{
        history.push('/campaignV2?type=create')
    }
  };

  const stepList = [
    <span>
      Email
      <br />
      Subject
    </span>,
    <span>
      Select
      <br />
      Template
    </span>,
    <span>
      Customize
      <br />
      Email
    </span>,
  ];

  return (
    <Flex display="flex" flexDirection="column">
      <Flex display="flex" flexDirection="column" marginBottom="30px">
        <Stepper
          stepperList={stepList}
          selectedIndex={selectedIndex}
          style={{ marginTop: '2%' }}
        />
        <div>
          {selectedIndex === 0 && (
            <CreateContainer
              mt="0px"
              height="auto"
              style={{ justifyContent: 'flex-start' }}
            >
              <EmailSubject
                loading={loading}
                setLoading={setLoading}
                handleBack={handleBackButtonClick}
                handleNext={handleNextClick}
                campaignSetState={campaignSetState}
                selectedBlock={selectedBlock}
              />
            </CreateContainer>
          )}
          {selectedIndex === 1 && (
            <CreateContainer
              id="select-template-container"
              mt="0px"
              mx="70px"
              style={{ justifyContent: 'flex-start', maxWidth: '91vw' }}
              height="70vh"
              padding="9px 40px"
            >
              <SelectTemplate
                handleBack={handleBackButtonClick}
                handleNext={handleNextClick}
                loading={loading}
                setLoading={setLoading}
                defaultTemplates={defaultTemplates}
                setTemplateData={setTemplateData}
                setTemplateBase={setTemplateBase}
                campaignId={campaignId}
                campaignSetState={campaignSetState}
                selectedBlock={selectedBlock}
                selectedBlockId={selectedBlockId}
              />
            </CreateContainer>
          )}
        </div>
      </Flex>
    </Flex>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  const isEnterpriseAdmin = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;

  return {
    campaignId: get(state, 'campaign.campaignId', 0),
    setTemplateBase: get(state, 'campaign.setBaseTemplate', 0),
    defaultTemplates: get(state, 'campaign.defaultTemplates', 0),
    setTemplateData: get(state, 'campaign.setTemplate', 0),
    selectedIndex: get(state, 'campaign.selectedIndex', 0),
    selectedBlock: get(state, 'campaign.selectedBlock', {}),
    selectedBlockId: get(state, 'campaign.selectedBlockId', ''),
    automatedFlowFromDashboard: get(state, 'campaign.automatedFlowFromDashboard', false),
    isPremiumAdvisor,
    channelId: getChannelId(state),
    isEnterpriseAdvisor,
    bookmarkArticles: state.campaign.bookmarkArticles,
    recommendedArticles: state.campaign.recommendedArticles,
    myContentArticles: state.campaign.myContentArticles,
    myContentPendingQueue: get(state, 'campaign.pendingArticles', []),
    landingPagesData: get(state, 'campaign.landingPages', []),
    isEnterpriseAdmin,
    targetInfo: get(state, 'campaign.automatedCampaigns.targetInfo', {})
  };
};

const mapDispatchToProps = {
  campaignSetState: campaignActions.api.campaign.setstate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEmailContainer);
