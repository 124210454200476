/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState } from 'react';
import { get, split, padStart, uniq } from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import {
  faLinkedinIn,
  faTwitter,
  faGooglePlusG,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Flex, Box } from '@rebass/grid';

import {
  ResponsiveImg,
  DripIconButton,
  FlipCard,
  Loader,
  MioDatePicker,
  Modal,
  ModalHeader,
  ShareContentModal,
} from '../';
import { onImageError, themesData } from '../../utils';

import {
  StyledArticle,
  FlipBackContent,
  BackContentRowData,
  IconBox,
} from './DashboardArticle.styles';
import { getMonthDate } from '../../utils';
import { resheduleArticle } from '../../services/dashboard';
import { actions as shareActions } from '../../redux/modules/share.module';
import RescheduledDetailsModal from './RescheduledDetailsModal';
import { useHistory } from 'react-router-dom';
import ScheduledNewsletterModal from '../../containers/NewsletterContainer/NewsletterModals/ScheduledNewsletterModal';
import { actions as newsletterActions } from '../../redux/modules/newsletter.module';
import { deleteNewsletterArticle } from '../../services/newsletter';
import { NewsletterCheckBoxDiv } from '../../containers/NewsletterContainer/Recipients/Recipients.styles';

const ScheduledArticle = ({
  url,
  topic,
  place,
  date,
  description,
  link,
  article,
  sharedChannels,
  deleteArticleRequest,
  id,
  scheduledDateTime,
  themeId,
  themeNames,
  exploreArticleDeleting,
  isGivingCompass,
  scheduledIds,
  exploreArticleRequest,
  index,
  totalArticles,
  setShowArticleLoader,
  imageCounter,
  setImageCounter,
  scheduleType,
  year,
  channelId,
  shareArticleSetState,
  summaries,
  contactList,
  scheduleDetails,
  socialNetworkData,
  isShareBlocked,
  auxiliary,
  source,
  sponsor,
  contactGroups,
  newsletterDeleteRequest,
  isExportPDF,
  handleArticleSelection,
  exportPDFArticles,
  userData
}) => {
  const [deleteId, setDeleteId] = useState('');
  const [flipToggle, setFlipToggle] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openScheduledModal, setOpenScheduledModal] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const trimmedTopic =
    (topic || '').length > 60 ? `${topic.substring(0, 60)}...` : topic;
  const trimmedDesc =
    (description || '').length > 100
      ? `${description.substring(0, 100)}...`
      : description;

  const onDeleteButtonClick = () => {
    setDeleteId(id);
    uniq(scheduledIds).forEach((element) => {
      deleteArticleRequest(element);
    });
  };

  const onAccept = () => {
    setOpenScheduledModal(false);
    deleteNewsletterArticle({
      id,
    }).then(() => {
      exploreArticleRequest({
        params: {
          source: 'scheduled',
          type: scheduleType,
        },
      });
    });
  };

  const splitDateTime = split(scheduledDateTime, 'T', 2);
  const sheduledOnDate = get(splitDateTime, '[0]');
  const dateTime = split(get(splitDateTime, '[1]'), ':', 2);
  const [sheduledOnMonth, sheduledOnDay, sheduledOnYear] = getMonthDate(
    sheduledOnDate,
    'scheduled'
  );
  const history = useHistory();
  const [isDateOpen, setDateOpen] = useState(false);
  const rescheduleClick = (date) => {
    scheduledIds.forEach((element) => {
      resheduleArticle(element, date).then((res) => {
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
          exploreArticleRequest({
            params: {
              source: 'scheduled',
              type: scheduleType,
            },
          });
        }, 2000);
        setDateOpen(false);
      });
    });
  };

  const onImageLoad = () => {
    setShowContent(true);
    let arr = imageCounter;
    if (totalArticles === arr.length + 1) {
      setTimeout(() => {
        setShowArticleLoader(false);
      }, 1000);
    } else {
      arr.push(index);
      setImageCounter(arr);
    }
  };

  const onSelectItem = (item) => {
    setSelectedItem(item);
    setDateOpen(true);
  };

  const handleInfo = (id, name) => {
    if (source === 'newsletter') {
      //history.push('/newsletter?type=dashboard&tab=scheduled');
      setOpenScheduledModal(true);
      setSelectedId(id);
      setSelectedTopic(name);
    } else {
      setOpenDetailsModal(true);
    }
  };

  const handleRedirection = () => {
    console.log('ARTICLE - - - ', article);
    if (source === 'img') {
      const urlObj =
        article.mediaUrl && article.mediaUrl[0] ? article.mediaUrl[0] : '';
      if (urlObj) {
        const imgUrl = urlObj.path + urlObj.name.system + '.' + urlObj.ext;
        console.log('IMAGE URL - - - - -', imgUrl);
        window.open(imgUrl, '_blank');
      }
    } else if (source === 'newsletter') {
      history.push('/newsletter?type=dashboard&tab=scheduled');
    } else {
      window.open(link, '_blank');
    }
  };
  return (
    <StyledArticle
      style={
        auxiliary &&
        auxiliary.scheduled &&
        auxiliary.scheduled.details &&
        auxiliary.scheduled.details[0] &&
        auxiliary.scheduled.details[0].status &&
        auxiliary.scheduled.details[0].status === 'failed'
          ? isExportPDF
            ? { width: '90%' }
            : { border: '1px solid #FF8E8E' }
          : {}
      }
    >
      {showMessage && (
        <Modal isOpen={showMessage}>
          <ModalHeader>Post Rescheduled Successfully</ModalHeader>
        </Modal>
      )}
      <FlipCard
        shouldFlip={flipToggle}
        flipFront={
          <React.Fragment>
            <a
              href={'javascript:void(0);'}
              onClick={isExportPDF ? () => {} : handleRedirection}
              rel="noopener noreferrer"
            >
              <div>
                <ResponsiveImg
                  src={url}
                  onError={onImageError}
                  onLoad={onImageLoad}
                />
              </div>
              <div className="details-wrapper">
                {isExportPDF && (
                  <div style={{ float: 'right' }}>
                    <NewsletterCheckBoxDiv>
                      <label
                        style={{ color: themesData.purpleishBlue }}
                        className="container"
                      >
                        <input
                          type="checkbox"
                          name="exportPDFArticles"
                          value={id}
                          checked={exportPDFArticles[id]}
                          onChange={(e) =>
                            handleArticleSelection(
                              id,
                              e.target.checked,
                              scheduledIds
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </NewsletterCheckBoxDiv>
                  </div>
                )}
                {source === 'newsletter' ? (
                  <div className="selected-topic">NEWSLETTER</div>
                ) : themeId && themeId.id ? (
                  <div className="selected-topic">
                    {source === 'img'
                      ? 'Image: ' + themeNames[themeId.id]
                      : themeNames[themeId.id]}
                    {sponsor && (
                      <span
                        style={{
                          backgroundColor: 'rgb(85, 85, 85)',
                          color: 'rgb(213, 213, 213)',
                          fontSize: '8px',
                          borderRadius: '3px',
                          padding: '0px 4px',
                          float: 'right',
                          textTransform: 'none',
                          fontWeight: '500',
                          height: '14px',
                        }}
                      >
                        Sponsored
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="selected-topic">
                    {themeNames[themeId]}
                    {sponsor && (
                      <span
                        style={{
                          backgroundColor: 'rgb(85, 85, 85)',
                          color: 'rgb(213, 213, 213)',
                          fontSize: '8px',
                          borderRadius: '3px',
                          padding: '0px 4px',
                          float: 'right',
                          textTransform: 'none',
                          fontWeight: '500',
                          height: '14px',
                        }}
                      >
                        Sponsored
                      </span>
                    )}
                  </div>
                )}
                <div className="place-date">{`${place}, ${date}`}</div>
                <div className="post-heading" title={topic}>
                  {trimmedTopic}
                </div>

                <div className="post-desc" title={description}>
                  {trimmedDesc}
                </div>
              </div>
            </a>
            {!flipToggle && !isExportPDF && (
              <div className="btn-wrapper">
                <div className={`delete-btn-wrapper`}>
                  {!exploreArticleDeleting && (
                    <DripIconButton
                      icon="trash"
                      onClick={onDeleteButtonClick}
                    />
                  )}
                  {deleteId === id && exploreArticleDeleting && <Loader />}
                </div>
                {isGivingCompass &&
                  !(
                    auxiliary &&
                    auxiliary.scheduled &&
                    auxiliary.scheduled.details &&
                    auxiliary.scheduled.details[0] &&
                    auxiliary.scheduled.details[0].status &&
                    auxiliary.scheduled.details[0].status === 'failed'
                  ) && (
                    <div className="power-by-wrapper">
                      <span>Powered By</span>{' '}
                      <span>
                        <ResponsiveImg src="/assets/images/giving-compass.png" />
                      </span>
                    </div>
                  )}
                <div style={{ display: 'flex' }}>
                  {auxiliary &&
                    auxiliary.scheduled &&
                    auxiliary.scheduled.details &&
                    auxiliary.scheduled.details[0] &&
                    auxiliary.scheduled.details[0].status &&
                    auxiliary.scheduled.details[0].status === 'failed' && (
                      <div style={{ paddingBottom: '3px', color: '#FF8E8E' }}>
                        <img
                          style={{
                            height: '10px',
                            width: '10px',
                            position: 'relative',
                            top: '1px',
                            right: '3px',
                          }}
                          src="/assets/images/ShareError.svg"
                          alt=""
                        />
                        Share error
                      </div>
                    )}
                  <DripIconButton
                    icon="information"
                    className="info-scheduled"
                    onClick={() => handleInfo(id, topic)}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        }
        flipBack={
          <FlipBackContent
            flexDirection="column"
            background={scheduledDateTime && true}
          >
            {auxiliary &&
              auxiliary.scheduled &&
              auxiliary.scheduled.details &&
              auxiliary.scheduled.details[0] &&
              auxiliary.scheduled.details[0].status &&
              auxiliary.scheduled.details[0].status === 'failed' && (
                <BackContentRowData>
                  <div style={{ color: '#FF8E8E', padding: '0 7px' }}>
                    The article could not be shared at the scheduled time,
                    please try to share/schedule again.
                  </div>
                </BackContentRowData>
              )}
            <BackContentRowData>
              <div>Date</div>
              <div>
                {sheduledOnMonth} {sheduledOnDay} {sheduledOnYear}
              </div>
            </BackContentRowData>
            <BackContentRowData>
              <div>Time</div>
              <div>
                {padStart(dateTime[0], 2, 0)}:{padStart(dateTime[1], 2, 0)}
              </div>
            </BackContentRowData>
            <BackContentRowData>
              <div>Scheduled On</div>
              <Flex width={1} justifyContent="center" alignItems="center">
                {sharedChannels.map((channel) => {
                  const icons = {
                    tw: faTwitter,
                    ln: faLinkedinIn,
                    em: faEnvelope,
                    go: faGooglePlusG,
                    fb: faFacebook,
                  };
                  return (
                    <IconBox
                      key={'icon_' + channel + id}
                      background="transparent"
                      color="white"
                    >
                      <FontAwesomeIcon
                        icon={icons[channel]}
                        style={{ color: '#fff' }}
                      />
                      &nbsp;&nbsp;
                    </IconBox>
                  );
                })}
              </Flex>
            </BackContentRowData>
            <BackContentRowData>
              <div>Reschedule</div>
              <FontAwesomeIcon
                icon={faCalendar}
                style={{ cursor: 'pointer' }}
                onClick={() => setDateOpen(true)}
              />
            </BackContentRowData>
            <BackContentRowData>
              <DripIconButton
                color="#fff"
                icon="reply"
                onClick={() => setFlipToggle(!flipToggle)}
              />
            </BackContentRowData>
          </FlipBackContent>
        }
      />
      {isDateOpen && (
        <ShareContentModal
          editable={selectedItem.is_complianced ? false : true}
          handleClose={() => {
            setDateOpen(false);
            setSelectedItem({});
            isDateOpen && shareArticleSetState({ data: {}, error: [] });
          }}
          open={isDateOpen}
          {...{
            id,
            link,
            topic,
            place,
            date,
            year,
            channelId,
            themeId,
            url,
            summaries,
            deleteArticleRequest,
            scheduledIds,
            scheduledDateTime,
            scheduleType,
            exploreArticleRequest,
            contactList,
            selectedItem,
            source
          }}
          isFromScheduledTab
        />
      )}

      {openDetailsModal && (
        <RescheduledDetailsModal
          open={openDetailsModal}
          setOpen={setOpenDetailsModal}
          topic={topic}
          scheduleDetails={scheduleDetails}
          socialData={socialNetworkData}
          onSelectItem={onSelectItem}
          id={id}
          scheduleType={scheduleType}
          exploreArticleRequest={exploreArticleRequest}
          setShowArticleLoader={setShowArticleLoader}
        />
      )}
      {openScheduledModal && (
        <ScheduledNewsletterModal
          open={openScheduledModal}
          setOpen={setOpenScheduledModal}
          selectedId={id}
          setSelectedId={setSelectedId}
          socialNetworkData={socialNetworkData}
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
          onAccept={onAccept}
          contactGroups={contactGroups}
          userData={userData}
        />
      )}
      {/* <MioDatePicker
        isOpen={isDateOpen}
        customInput={<div />}
        minDate={new Date()}
        onDateClose={() => setDateOpen(false)}
        onSubmit={rescheduleClick}
        type="resheduled"
        popperPlacement='center-start'
      /> */}
    </StyledArticle>
  );
};

const mapStateToProps = (state) => {
  return {
    socialNetworkData: state.socialNetwork.data,
    userData: state.user.data
  };
};

const mapDispatchToProps = {
  shareArticleSetState: shareActions.api.share.setstate,
  //newsletterDeleteRequest: newsletterActions.api.newsletter.delete,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledArticle);
