/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  Table,
  EmailListTableHeading,
  LoaderWrapper,
  Td,
  DownloadButton,
  Button,
  DisplayScore,
} from '../../../components';
import {
  EngagementTr,
  EngagementTh,
  LeadTableBodyTr,
  LeadTableBody,
  TotalCountSection,
  TopLeadWrapper,
  TopShareName,
  TopLeadEmail,
  SponsoredWrapper,
  OverviewBadge,
  TopPublisherName,
  OverviewShareTitle,
} from './EngagementTab.styles';
import moment from 'moment';
import ArticleList from '../../../components/ArticleList/ArticleList';
import { DownloadButtonFlex } from '../AnalyticsContainer.styles';
import {
  saveFile,
  getTabNameFromRepType,
  onImageError,
  getMonthDate,
} from '../../../utils';
import { parse } from 'json2csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import TablePagination from '../../../components/Pagination/tablePagination';
import { getDownloadCsvData } from '../../../services/analytics';

const EngagementShareTab = ({
  tableData,
  activeShareType,
  isAnalyticsDataFetching,
  handleRowClick,
  analyticsObject,
  selectedRepType,
  page,
  setPage,
  analyticsRequest,
  isAdmin,
  setPaginationToken,
}) => {
  console.log('RENDERING INSIGHTS TAB....');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tableShareData, setTableShareData] = useState([]);
  const [isImagesLoading, setIsImagesLoading] = useState(false);

  useEffect(() => {
    if (activeShareType) {
      setTableShareData(
        get(tableData, 'share', []).filter(
          (item) => activeShareType && item[activeShareType] > 0
        )
      );
      setPage(0);
    } else {
      setTableShareData(get(tableData, 'share', []));
    }
  }, [activeShareType, tableData]);

  useEffect(() => {
    console.log("FILTERPARAMS::", analyticsObject.filterParams)
    if (tableShareData.length) {
      if (page === 0) {
        setStartIndex(tableShareData.length === '0' ? 0 : 1);
        setEndIndex(tableShareData.length < 6 ? tableShareData.length : 6);
      } else {
        setStartIndex(page * 6 + 1);
        setEndIndex(
          (page + 1) * 6 >= tableShareData.length
            ? tableShareData.length
            : (page + 1) * 6
        );
      }
    } else {
      setStartIndex(0);
      setEndIndex(0);
    }
  }, [page, tableShareData]);

  const getEngagementMediaImage = (media) => {
    if (!isEmpty(media)) {
      console.log(`${media.path}${get(media, 'name.resized')}.${media.ext}`);
      return `${media.path}${get(media, 'name.resized')}.${media.ext}`;
    } else {
      return '';
    }
  };

  const onPaginationClick = (type) => {
    if (type === 'before') {
      setPaginationToken(tableData.pagination.before);
      analyticsRequest({
        params: {
          repType: selectedRepType,
          beforeData: tableData.pagination && tableData.pagination.before,
        },
      });
    } else {
      setPaginationToken(tableData.pagination.after);
      analyticsRequest({
        params: {
          repType: selectedRepType,
          afterData: tableData.pagination && tableData.pagination.after,
        },
      });
    }

    setPage(type === 'before' ? (page === 0 ? 0 : page - 1) : page + 1);
  };

  const onDownloadCSVClick = () => {
    setLoading(true);
    const tabName = getTabNameFromRepType(selectedRepType);
    const { startDate, endDate } = analyticsObject || {};
    const fileName = `${tabName}-${startDate}_${endDate}`;

    getDownloadCsvData({ repType: selectedRepType, startDate, endDate, filters: analyticsObject.filterParams })
      .then((res) => {
        saveFile(res, fileName);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleImagesLoading = () => {
    setIsImagesLoading(true);
    setTimeout(() => {
      setIsImagesLoading(false);
    }, 1000);
  };

  return (
    <LoaderWrapper isLoading={isAnalyticsDataFetching || isImagesLoading}>
      <Flex
        width="746px"
        style={{ height: '518px' }}
        pt="15px"
        pl="10px"
        pr="10px"
        flexWrap="wrap"
      >
        {tableShareData && tableShareData.length > 0 ? (
          tableShareData.slice(page * 6, (page + 1) * 6).map((item, index) => {
            const trimmedPublisherName =
              ((item && item.publisher && item.publisher.name) || '').length >
              15
                ? `${
                    item &&
                    item.publisher &&
                    item.publisher.name &&
                    item.publisher.name.substring(0, 15)
                  }...`
                : item &&
                  item.publisher &&
                  item.publisher.name &&
                  item.publisher.name;
            const trimmedTitle =
              (item?.title || '').length > 80
                ? `${item?.title.substring(0, 80)}...`
                : item?.title;
            const extractedDate = getMonthDate(item.date);
            const formatted_date = `${extractedDate[0]} ${extractedDate[1]}`;
            return (
              <TopLeadWrapper
                width="213px"
                height="246px"
                margin="14px 10px 0px 8.2px"
                padding="0px"
                style={{ cursor: 'pointer' }}
                onClick={() => handleRowClick(item.id, item)}
              >
                <Flex style={{ height: '99px', width: '100%' }}>
                  <img
                    alt="share"
                    loading="eager"
                    src={getEngagementMediaImage(item.media_urls)}
                    width="212px"
                    onError={onImageError}
                    style={{
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                      objectFit: 'cover',
                    }}
                  />
                </Flex>
                <Flex flexDirection="column" padding="8px 8px 10px 8px">
                  <Flex
                    width="100%"
                    justifyContent="space-between"
                    padding="0px 6px"
                  >
                    <Flex flexDirection="column" justifyContent="flex-start">
                      <TopShareName>
                        {item && item.topic && item.topic.name}
                      </TopShareName>
                      <TopPublisherName
                        title={
                          (
                            (item && item.publisher && item.publisher.name) ||
                            ''
                          ).length > 15
                            ? item && item.publisher && item.publisher.name
                            : ''
                        }
                      >
                        {trimmedPublisherName}{item.date ? `, ${formatted_date}` : ''}
                      </TopPublisherName>
                    </Flex>
                    <Flex>
                      <DisplayScore score={item.engagement_score > 0 ? item.engagement_score : item.social_engagement_score} />
                    </Flex>
                  </Flex>
                  <OverviewShareTitle title={item.title}>
                    {trimmedTitle}
                  </OverviewShareTitle>
                  {!isEmpty(item.suggested_investments)
                    ? item.suggested_investments
                        .slice(0, 1)
                        .map((item, index) => (
                          <SponsoredWrapper mt="10px">
                            <Flex
                              width="100%"
                              justifyContent="flex-start"
                              padding="2px 0px"
                              style={{ height: '13px' }}
                            >
                              <img
                                src={item.logo_path}
                                style={{
                                  objectFit: 'contain',
                                  objectPosition: 'left',
                                }}
                                alt="Company"
                              />
                            </Flex>
                            <Flex
                              width="100%"
                              fontSize="10px"
                              padding="2px 0px"
                              style={{
                                textAlign: 'left',
                                color: '#49484a',
                                fontWeight: '500',
                                whiteSpace: 'nowrap',
                                height: '15px',
                              }}
                            >
                              <span
                                title={item?.name.length > 32 ? item.name : ''}
                              >
                                {item?.name.length > 32
                                  ? item.name.substring(0, 32) + '...'
                                  : item.name}
                              </span>
                            </Flex>
                            <Flex
                              justifyContent="space-between"
                              style={{ paddingTop: '2px' }}
                            >
                              <Flex>
                                <span
                                  style={{
                                    color: '#49484a',
                                    fontWeight: 'bold',
                                    fontSize: '10px',
                                  }}
                                >
                                  {item.ticker}
                                </span>
                                &nbsp;
                                {item.matched_topics &&
                                  item.matched_topics.length > 0 &&
                                  item.matched_topics
                                    .slice(0, 1)
                                    .map((item, index) => (
                                      <span
                                        style={{
                                          color: '#49484a',
                                          fontSize: '8px',
                                          fontWeight: 500,
                                          border: '1px solid #49484a',
                                          borderRadius: '2px',
                                          marginRight: '4px',
                                          height: '11px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px 2px',
                                          marginTop: '2px',
                                        }}
                                      >
                                        <div
                                          className="tooltip-div tooltip-disclosure"
                                          style={{ paddingTop: 'unset' }}
                                        >
                                          <p
                                            style={{
                                              cursor:
                                                item.length > 25
                                                  ? 'pointer'
                                                  : 'default',
                                            }}
                                          >
                                            {item.length > 11
                                              ? item.substring(0, 11) + '...'
                                              : item}
                                          </p>
                                          {item.length > 11 && (
                                            <span
                                              className="tooltip-text tooltiptext-disclosure"
                                              style={{
                                                left: '130%',
                                                top: '2px',
                                                whiteSpace: 'nowrap',
                                                width: 'initial',
                                                backgroundColor: '#404040',
                                                color: '#ffffff',
                                              }}
                                            >
                                              {item}
                                            </span>
                                          )}
                                        </div>
                                      </span>
                                    ))}
                              </Flex>
                              {item.is_sponsored && (
                                <OverviewBadge>
                                  <span>Sponsored</span>
                                </OverviewBadge>
                              )}
                            </Flex>
                          </SponsoredWrapper>
                        ))
                    : null}
                </Flex>
              </TopLeadWrapper>
            );
          })
        ) : (
          <div style={{ margin: 'auto', fontSize: '12px' }}>
            No Records Found
          </div>
        )}
      </Flex>
      <Flex width="100%" mt="22px" padding="0px 18px 0px 24px">
        <Flex width="67%">
          {tableShareData && tableShareData.length > 0 && !isAnalyticsDataFetching && (
            <span
              style={{
                color: '#49484a',
                marginTop: '15px',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {startIndex}-{endIndex} of {tableShareData.length}
            </span>
          )}
        </Flex>
        <Flex width="33%" justifyContent="space-between" alignItems="flex-end">
          <Button
            margin="10px 0px 0px 0px"
            type="button"
            bgColor={page < 1 ? '#AFAFAF' : '#6351ed'}
            width="106px"
            onClick={() => setPage(page - 1)}
            disabled={page < 1 ? true : false}
          >
            Previous
          </Button>
          {/* <Button margin="10px 0px 0px 0px" type="button" bgColor={page < 1 ? '#AFAFAF' : '#6351ed'} width='106px' onClick={() => onPaginationClick('before')} disabled={page < 1 ? true : false}>Previous</Button> */}
          <Button
            margin="10px 0px 0px 0px"
            width="106px"
            bgColor={tableShareData.length === endIndex ? '#AFAFAF' : '#6351ed'}
            onClick={() => {
              setPage(page + 1);
              handleImagesLoading();
            }}
            type="button"
            disabled={tableShareData.length === endIndex}
          >
            Next
          </Button>
          {/* <Button margin="10px 0px 0px 0px" width='106px' bgColor={get(tableData, 'pagination.total_count', '0') === endIndex.toString() ? '#AFAFAF' : '#6351ed'} onClick={() => onPaginationClick('after')} type="button" disabled={get(tableData, 'pagination.total_count', '0') === endIndex.toString()}>Next</Button> */}
        </Flex>
      </Flex>
      <DownloadButtonFlex
        justifyContent="flex-end"
        style={{ paddingTop: '35px' }}
      >
        <LoaderWrapper isLoading={loading}>
          <DownloadButton
            disabled={tableShareData.length === 0}
            onClick={onDownloadCSVClick}
            fontSize="18px"
          />
        </LoaderWrapper>
      </DownloadButtonFlex>
    </LoaderWrapper>
  );
};

export default EngagementShareTab;
