import { Flex } from '@rebass/grid';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import {
  Button,
  DripIconButton,
  MioDatePicker,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../components';
import { themesData } from '../../../utils';
import { Input } from '../SocialPreview/SocialPreview.styles';
import {
  DateTimeText,
  DateTimeWrapper,
  EmailList,
} from './PreviewAndSchedule.styles';

const EmailDTListPopup = ({
  open,
  handleClose,
  users,
  dateTimeList,
  handleDateTimeListChange,
  handleValidation,
  setDateTimeList,
  userData,
  selectedSegments
}) => {
  return (
    <Modal
      bgColor="white"
      height="70%"
      size="medium-small"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
    >
      <ModalHeader
        id="header"
        style={{ textAlign: 'center', color: themesData.charcoalGrey }}
      >
        <Flex fontSize="0.9rem" color="black" style={{ fontWeight: '600' }}>
          Choose Date/Time
        </Flex>
        <ModalCloseButton onClick={handleClose} size="25px" />
        <hr style={{ width: '100%' }} />
      </ModalHeader>
      <ModalBody>
        <Flex
          width="100%"
          justifyContent="space-evenly"
          style={{ fontSize: '1rem', color: 'black', fontWeight: '600' }}
          mb="10px"
        >
          <Flex width="250px">Send To</Flex>
          <Flex width="250px" mr="20px">
            Date & Time
          </Flex>
          {/* <Flex width="200px">Time</Flex> */}
        </Flex>
        <EmailList width="100%">
          {!isEmpty(users) ?
            users.map((user) => (
              <Flex
                width="93%"
                justifyContent="space-evenly"
                alignItems="center"
                key={user.id}
                mb="15px"
              >
                <Flex width="45%">{user.name}</Flex>
                <Flex
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDirection="column"
                  mr="20px"
                >
                  <DateTimeWrapper width="200px">
                    <DateTimeText>
                      {dateTimeList[user.id]?.dateTime &&
                        moment(dateTimeList[user.id]?.dateTime).format(
                          'MM/DD/YY, h:mm a'
                        )}
                    </DateTimeText>
                    <DripIconButton
                      onClick={() =>
                        handleDateTimeListChange(user.id, 'isOpen', true)
                      }
                      icon="clock"
                      styles={{ marginTop: '5px' }}
                    />
                    <MioDatePicker
                      isOpen={dateTimeList[user.id]?.isOpen || false}
                      customInput={<div />}
                      minDate={new Date()}
                      onDateClose={() =>
                        handleDateTimeListChange(user.id, 'isOpen', false)
                      }
                      onSubmit={(dateTime) =>
                        handleDateTimeListChange(
                          user.id,
                          'dateTime',
                          dateTime,
                          true
                        )
                      }
                      scheduleValue={dateTimeList[user.id]?.dateTime}
                      isFromNewsletter
                      timeZone={get(userData, 'details.user.timezone', '')}
                    />
                  </DateTimeWrapper>
                </Flex>
              </Flex>
            )) : !isEmpty(selectedSegments) && selectedSegments.map((segment) => (
              <Flex
                width="93%"
                justifyContent="space-evenly"
                alignItems="center"
                key={segment.id}
                mb="15px"
              >
                <Flex width="45%">{segment.name}</Flex>
                <Flex
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDirection="column"
                  mr="20px"
                >
                  <DateTimeWrapper width="200px">
                    <DateTimeText>
                      {dateTimeList[segment.id]?.dateTime &&
                        moment(dateTimeList[segment.id]?.dateTime).format(
                          'MM/DD/YY, h:mm a'
                        )}
                    </DateTimeText>
                    <DripIconButton
                      onClick={() =>
                        handleDateTimeListChange(segment.id, 'isOpen', true)
                      }
                      icon="clock"
                      styles={{ marginTop: '5px' }}
                    />
                    <MioDatePicker
                      isOpen={dateTimeList[segment.id]?.isOpen || false}
                      customInput={<div />}
                      minDate={new Date()}
                      onDateClose={() =>
                        handleDateTimeListChange(segment.id, 'isOpen', false)
                      }
                      onSubmit={(dateTime) =>
                        handleDateTimeListChange(
                          segment.id,
                          'dateTime',
                          dateTime,
                          true
                        )
                      }
                      scheduleValue={dateTimeList[segment.id]?.dateTime}
                      isFromNewsletter
                      timeZone={get(userData, 'details.user.timezone', '')}
                    />
                  </DateTimeWrapper>
                </Flex>
              </Flex>
            ))}
        </EmailList>
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            padding: '10px',
          }}
        >
          <hr style={{ width: '100%' }} />
          <Flex justifyContent="flex-end" py="10px">
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#4E6780"
              margin="0px 10px"
              borderColor="#4E6780"
              onClick={() => {
                // setDateTimeList({});
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#33b21b"
              margin="0px 10px"
              borderColor="#33b21b"
              disabled={handleValidation()}
              onClick={handleClose}
            >
              Save
            </Button>
          </Flex>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EmailDTListPopup;
