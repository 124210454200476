import React, { useState, useEffect, useRef } from 'react';
import { Flex, Box } from '@rebass/grid';
import {
  Modal,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ErrorContainer,
  LoaderWrapper,
} from '../';
import { isEmpty, get } from 'lodash';
import {
  RadioInput,
  CheckboxInput,
  RadioInputLabel,
} from './V2/CreateEmailListModal.style';

const ListRenameModal = ({
  isModalOpen,
  setModalOpen,
  listName,
  updateEmailListHandler,
  renameModalLoader,
}) => {
  console.log('listName', listName);
  // item.type === 'custom'
  //   const didMountRef = useRef(null);
  const [emailListName, setEmailListName] = useState(
    get(listName, 'value', '')
  );
  const [createEmailListError, setCreateEmailListError] = useState('');
  let ct = get(listName, 'item.contact_type', 'prospect');
  const [listType, setListType] = useState(ct == 'default' ? 'prospect' : ct);
  const [defaultCheckbox, setDefaultCheckbox] = useState(false);
  let listCreationType = get(listName, 'item.type', '');

  useEffect(() => {
    console.log('listType', listType);
  }, [listType]);

  useEffect(() => {
    console.log('defaultCheckbox', defaultCheckbox);
  }, [defaultCheckbox]);

  useEffect(() => {
    console.log('listName', listName);
  }, [listName]);

  const onNameChange = (e) => {
    setEmailListName(e.target.value);
  };

  const onSubmitClick = () => {
    if (!emailListName) {
      setCreateEmailListError('Email List Name can not be blank');
      return;
    }

    // onNameListSubmit({
    //   listName: emailListName,
    // });
    updateEmailListHandler({
      updatedName: emailListName,
      contactType: listType,
    });
  };
  return (
    <Modal
      onRequestClose={() => setModalOpen(false)}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      size="smail"
    >
      <ModalHeader>List name</ModalHeader>
      <ModalCloseButton onClick={() => setModalOpen(false)} />
      <ModalBody>
        <Flex flexDirection="column">
          <Box>
            <Input
              type="text"
              name="createEmailListName"
              id="createEmailListName"
              onChange={onNameChange}
              value={emailListName}
              disabled={listCreationType !== 'custom'}
            />
          </Box>
          {listCreationType !== 'custom' && (
            <span
              style={{ marginTop: '15px', color: '#6351ed', fontSize: '12px' }}
            >
              *Name cannot be updated for imported lists
            </span>
          )}
          <Flex justifyContent="center" alignItems="center" mt="10px">
            <Flex
              alignItems="center"
              justifyContent="center"
              marginRight="5px"
              // ml="25px"
            >
              <RadioInput
                type="radio"
                name="list_type"
                id="list_type_prospect"
                onClick={() => {
                  setListType('prospect');
                }}
                checked={listType === 'prospect'}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="list_type_prospect"
                style={{ cursor: 'pointer' }}
              >
                Prospects
              </RadioInputLabel>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              marginRight="5px"
              ml="10px"
            >
              <RadioInput
                type="radio"
                name="list_type"
                id="list_type_client"
                onClick={() => {
                  setListType('client');
                }}
                checked={listType === 'client'}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="list_type_client"
                style={{ cursor: 'pointer' }}
              >
                Clients
              </RadioInputLabel>
            </Flex>
            {/* <Flex
              alignItems="center"
              justifyContent="center"
              marginRight="5px"
              ml="10px"
              mt="5px"
            >
              <CheckboxInput>
                <label class="container">
                  <input
                    type="checkbox"
                    onChange={() => setDefaultCheckbox(!defaultCheckbox)}
                    checked={defaultCheckbox}
                  />
                  <span class="checkmark"></span>
                  Default
                </label>
              </CheckboxInput>
            </Flex> */}
          </Flex>
          <Box>
            <LoaderWrapper isLoading={renameModalLoader}>
              <Button
                type="button"
                onClick={onSubmitClick}
                testId="list-rename-submit"
              >
                UPDATE
              </Button>
            </LoaderWrapper>
          </Box>
          {createEmailListError && (
            <ErrorContainer align="center">
              {createEmailListError}
            </ErrorContainer>
          )}
        </Flex>
      </ModalBody>
    </Modal>
  );
};

export default ListRenameModal;
