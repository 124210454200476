import { Box, Flex } from '@rebass/grid';
import React from 'react';
import { ActionsDropdown, StyledCampaignActionBox } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { useState } from 'react';
import moment from 'moment';
import { themesData } from '../../../utils';

const ActionBox = ({
  styles,
  actions = [],
  mainTitle,
  disableCutomization,
  blockObj,
  forWaitTimeEdit = false,
  eventDate,
  preConversionBlock,
  postConversionBlock,
  index,
  onConversionBlock,
  postConversionBlocks,
  forDashboardPreview
}) => {
  const [showActions, setShowActions] = useState(false);
  const calulateScheduleDate = () => {
    if(mainTitle.includes('prior')) {
      let currentDate = new Date(eventDate);
      currentDate = new Date(currentDate.getTime() + Math.abs(currentDate.getTimezoneOffset()*60000));
      let firstBlockDays = +mainTitle.split(' ')[0];
      currentDate.setDate(currentDate.getDate() - firstBlockDays);
      return moment(currentDate).format('MM/DD/YYYY');
    } else {
      let calculatedEventDate = new Date(eventDate);
      calculatedEventDate = new Date(calculatedEventDate.getTime() + Math.abs(calculatedEventDate.getTimezoneOffset()*60000));
      let firstBlockDays = +mainTitle.split(' ')[0];
      calculatedEventDate.setDate(calculatedEventDate.getDate() + firstBlockDays);
      return moment(calculatedEventDate).format('MM/DD/YYYY');
    }
  }

  const postConversionText = () => {
    if(index === postConversionBlocks.length - 2) {
      return 'Thank You Schedule';
    } else {
      return `Reminder ${index === 0 ? 1 : (index / 2) + 1} Schedule`;
    }
  }
  return (
    <StyledCampaignActionBox style={{ ...styles, position: onConversionBlock ? 'relative' : '' }} alignItems="center">
      {onConversionBlock && !forDashboardPreview && (
        <span style={{position: 'absolute', bottom: '105%', left: '14%', color: themesData.black }}>
          Registration Confirmation Email: Sent Immediately
        </span>
      )}
      <Flex width={1} alignItems="center" justifyContent={'space-between'}>
        <span>{mainTitle}</span>
        {!disableCutomization && (
          <Box className="actions__holder">
            <FontAwesomeIcon
              icon={faEllipsisV}
              color="blue"
              className="cursor-pointer"
              onClick={() => setShowActions(!showActions)}
            />

            {showActions && (
              <OutsideClickHandler
                onOutsideClick={() => setShowActions(!showActions)}
              >
                <ActionsDropdown
                  className="actions"
                  forWaitTimeEdit={forWaitTimeEdit}
                >
                  {React.Children.toArray(
                    actions.map((action, index) => (
                      <p
                        className="action-item cursor-pointer"
                        onClick={() => action.onClick(blockObj)}
                      >
                        {index === 0 && blockObj && blockObj.newsletter_id
                          ? 'Edit Email'
                          : action.title}
                      </p>
                    ))
                  )}
                </ActionsDropdown>
              </OutsideClickHandler>
            )}
          </Box>
        )}
      </Flex>
      {eventDate && !forDashboardPreview && (
        <span style={{position: 'absolute', left: postConversionBlock ? '0%' : '4%', color: themesData.black}}>{preConversionBlock ? `Email ${index === 0 ? 1 : (index / 2) + 1} Schedule` : postConversionText()}</span>
      )}

      {eventDate && !forDashboardPreview && (
        <span style={{position: 'absolute', right: '1%', color: themesData.black}}>Send on: {calulateScheduleDate()}</span>
      )}
      
    </StyledCampaignActionBox>
  );
};

export default ActionBox;
