import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const CreateContainer = styled(Flex)`
  border: 1px solid #cfd9e4;
  border-radius: 4px;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  ${'' /* width: 80%; */}
  padding: ${({ padding }) => padding || '9px'};
  padding-top: ${({ paddingTop }) => paddingTop || '9px'};
  height: ${({ height }) => height || '470px'};
  margin-top: ${({ marginTop }) => marginTop || '35px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '35px'};
  background-color: #f6f9fb;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
  ${'' /* margin-bottom: ${({ mb }) => mb || '25px'}; */}
`;

export const EmailSubjectInput = styled.input.attrs({
  className: 'set-objectives-input',
  type: 'text',
})`
  width: ${({ width }) => width || '100%'};
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ disabled }) => (disabled ? '#cccccc' : '#8493a6')};
  padding: 5px;
  height: ${({ height }) => height || ''};
  color: ${({ disabled }) => (disabled ? '#adadad' : '#000')};
  font-family: 'Poppins';
`;
