import { Flex } from '@rebass/grid';
import React from 'react';
import ReactModal from 'react-modal';
import { ModalCloseButton } from '../../../../components';
import CustomizeCampaign from '../../../AutomatedCampaigns/CampaignCreateSteps/CustomizeCampaign';

const PreviewModal = ({
  campaignId,
  isOpen,
  handleClose,
  campaignAction,
  campaignSetState,
  automatedCampaignDetails,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        content: {
          height: '600px',
          overflowY: 'scroll',
          padding: '15px',
          width: '500px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <Flex justifyContent={'center'}>
        <p>Preview</p>
        <ModalCloseButton onClick={handleClose} />
      </Flex>

      <CustomizeCampaign
        width="100%"
        forDashboardPreview={true}
        campaignAction={campaignAction}
        campaignSetState={campaignSetState}
        campaignId={campaignId}
        automatedCampaignDetails={automatedCampaignDetails}
      />
    </ReactModal>
  );
};

export default PreviewModal;
