import { Flex } from '@rebass/grid';
import React from 'react';
import { ToggleButton } from '../../containers/ConfigureContainer/screenConfigure.styles';

const AdvisorSettingsRow = ({ setting, firstRow, changeHandler, lastRow, index }) => {
    return (
        <Flex 
            alignItems='center' 
            width='100%' 
            style={{
                border: '1px solid #cacaca', 
                borderBottom: setting.label === 'Clout score changed' ? '1px solid #cacaca' : '0px',
                borderTopLeftRadius: firstRow ? '5px' : '',
                borderTopRightRadius: firstRow ? '5px' : '',
                borderBottomLeftRadius: lastRow ? '5px' : '',
                borderBottomRightRadius: lastRow ? '5px' : '',
                backgroundColor: '#fff'
            }}
        >
            {Object.keys(setting).map((settingAttr, ind) => (
                <Flex 
                    width='25%' 
                    flexDirection='column'
                    justifyContent='center'
                    style={settingAttr !== 'label' ? 
                        { 
                            borderLeft: settingAttr !== 'Skip duplicates' ? '1px solid #cacaca' : '0px', 
                            borderRight: settingAttr === 'Enabled' ? '1px solid #cacaca' : '0px',
                        } : {}
                    }
                    padding='8px 0px'
                >
                    <div style={{
                        fontSize: settingAttr === 'label' ? '0.7rem' : '13px', 
                        color: settingAttr === 'label' ? '#49484a' : '#6351ed',
                        fontWeight: settingAttr === 'label' ? '600' : '100' 
                    }}>
                        {settingAttr === 'label' ? setting[settingAttr] : settingAttr}
                    </div>
                    {settingAttr!=='label' && (
                        <div style={{width: '100%'}}>
                            <ToggleButton
                                className="react-switch"
                                onChange={(checked) => changeHandler(checked, settingAttr, index)}
                                checked={setting[settingAttr]}
                                // onColor="#6351ed"
                                onColor="#c95cfc"
                                offColor="#ccc"
                                offHandleColor="#fff"
                                onHandleColor="#fff"
                                handleDiameter={10}
                                uncheckedIcon=""
                                checkedIcon=""
                                boxShadow=""
                                activeBoxShadow=""
                                type="autoPost"
                                left='0px'
                                margin="-3px 5px 0px"
                            />
                        </div>
                    )}
                </Flex>
            ))}
        </Flex>
    );
}

export default AdvisorSettingsRow;