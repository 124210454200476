import { Flex, Box } from '@rebass/grid';
import { isEmpty, get, without, capitalize } from 'lodash';
import React, { useState, useEffect } from 'react';
import { LoaderWrapper, Button } from '../../../components';
import DripDashboardListItem from './DripDashboardListItem/DripDashboardListItem';
import DripDashboardBlockItem from './DripDashboardBlockItem/DripDashboardBlockItem';
import { StatusBubble } from './DripDashboardListItem/DripDashboardListItem.styles';
import { connect } from 'react-redux';
import DripDashboardDetails from './DripDashboardDetails/DripDashboardDetails';
import {
  getContactList,
  getContentLibrary,
  getPremiumAdvisorChannelId,
  getPopularTheme,
  getTrendingTopics,
} from '../../../services/dashboard';
import { FilterChips } from './DripDashboardTable.styles';
import { actions as campaignActions } from '../../../redux/modules/campaignDetails.module';
import { DripDetailsWrapper } from './DripDashboardDetails/DripDashboardDetails.styles';

const DripDashboardTable = ({
  data,
  activeTab,
  isFetching,
  socialThemes,
  setCampaignDetailsView,
  campaignDetailsView,
  campaignsDetailsData,
  // contactGroups,
  socialNetwork,
  editCampaignHandler,
  editLoader,
  filters,
  setFilters,
  campaignRequest,
  campaignDetailsRequest,
  detailsHandler,
  setSelectedCampaignTab,
  campaignData,
}) => {
  const statusArray = ['Completed', 'Scheduled', 'In Progress', 'Drafts'];
  const [selectedViewTab, setSelectedViewTab] = useState(1);
  // const [campaignDetailsView, setCampaignDetailsView] = useState(false);
  const [contactGroups, setContactGroups] = useState([]);
  const [totalDataLength, setTotalDataLength] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    if (data) {
      setPaginatedData(data && data.slice(0, pageLimit));
      setTotalDataLength(data && data.length);
      setCurrentPage(1);
    }
  }, [data]);

  useEffect(() => {
    getContactList()
      .then((res) => {
        const data = get(res, 'result.data', []).filter(
          (c) =>
            ![
              'GMAIL',
              'HUBSPOT',
              'OUTLOOK',
              'WEALTHBOX',
              'SALESFORCE',
              'REDTAIL',
            ].includes(get(c, 'name')) && get(c, 'contacts.count', 0) > 0
        );
        setContactGroups(data);
      })
      .catch((err) => {
        setContactGroups([]);
      });
  }, []);

  const viewMode = [
    {
      name: 'Block',
      img: {
        active: '/assets/images/drip/apps_white.svg',
        disabled: '/assets/images/drip/apps.svg',
      },
    },
    {
      name: 'List',
      img: {
        active: '/assets/images/drip/format_list_bulleted_white.svg',
        disabled: '/assets/images/drip/format_list_bulleted.svg',
      },
    },
  ];

  const handleFilterClick = (category, value) => {
    let fArray = without(filters[category], value);
    let modifiedFilters = filters;
    modifiedFilters[category] = fArray;

    if (
      category === 'all' ||
      (isEmpty(modifiedFilters?.status) &&
        isEmpty(modifiedFilters?.duration) &&
        isEmpty(modifiedFilters?.frequency) &&
        isEmpty(modifiedFilters?.target) &&
        isEmpty(modifiedFilters?.platform))
    ) {
      setFilters();
      campaignRequest();
      setSelectedCampaignTab(0);
      return;
    } else {
      setFilters({ ...modifiedFilters });
    }
  };

  let statusObj = {
    draft: 'Drafts',
    scheduled: 'Scheduled',
    'in_progress': 'In Progress',
    completed: 'Completed',
  };
  let durationObj = { '["4","weeks"]': '4 Weeks', '["8","weeks"]': '8 Weeks' };
  let frequencyObj = {
    '["every","week"]': 'Every week',
    '["other","week"]': 'Every other week',
  };
  let targetObj = { clients: 'Clients', prospects: 'Prospects' };
  let platformObj = {
    email: 'Email',
    facebook: 'Facebook',
    twitter: 'Twitter',
    linkedin: 'Linkedin',
  };

  const handlePagination = (mode) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    let td = data;
    let filteredData = [];

    if (mode === 'next') {
      filteredData = td.slice(
        currentPage * pageLimit,
        currentPage * pageLimit + pageLimit
      );
      setCurrentPage(currentPage + 1);
      setPaginatedData(filteredData);
    }

    if (mode === 'prev') {
      filteredData = td.slice(
        (currentPage - 2) * pageLimit,
        (currentPage - 2) * pageLimit + pageLimit
      );

      setCurrentPage(currentPage - 1);
      setPaginatedData(filteredData);
    }
  };

  return (
    <div style={{ padding: '0px 50px' }}>
      <LoaderWrapper isLoading={isFetching} loaderHeight="700px">
        <Flex display="flex" flexDirection="row">
          <Flex
            // flex="30%"
            // display="flex"
            width={campaignDetailsView ? '70%' : '100%'}
            flexDirection="column"
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              padding="20px 0px"
              // width={campaignDetailsView ? '38%' : ''}
              style={{ fontSize: '12px' }}
            >
              {(!filters || campaignDetailsView) && (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="row"
                  display="flex"
                >
                  {campaignDetailsView && (
                    <>
                      <img
                        src="/assets/images/drip/chevron_left.svg"
                        alt=""
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setCampaignDetailsView(false);
                        }}
                      />
                    </>
                  )}

                  <span style={{ color: '#000000' }}>
                    {activeTab?.name ? activeTab?.name : 'Filters'}
                  </span>
                  {campaignDetailsView && (
                    <span
                      style={{
                        color: '#000000',
                        backgroundColor: '#ffffff',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        marginLeft: '5px',
                        fontSize: '14px',
                      }}
                    >
                      {data.length}
                    </span>
                  )}
                </Flex>
              )}
              {filters && !campaignDetailsView && (
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  flexWrap="wrap"
                  width="60vw"
                >
                  {!isEmpty(filters.status) &&
                    filters.status.map((item, key) => {
                      return (
                        <FilterChips>
                          {statusObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('status', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}
                  {!isEmpty(filters.duration) &&
                    filters.duration.map((item, key) => {
                      return (
                        <FilterChips>
                          {durationObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('duration', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}
                  {!isEmpty(filters.frequency) &&
                    filters.frequency.map((item, key) => {
                      return (
                        <FilterChips>
                          {frequencyObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('frequency', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}
                  {!isEmpty(filters.target) &&
                    filters.target.map((item, key) => {
                      return (
                        <FilterChips>
                          {targetObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('target', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}
                  {!isEmpty(filters.platform) &&
                    filters.platform.map((item, key) => {
                      return (
                        <FilterChips>
                          {platformObj[item]}
                          <span
                            class="closebtn"
                            onClick={() => {
                              handleFilterClick('platform', item);
                            }}
                          >
                            &times;
                          </span>
                        </FilterChips>
                      );
                    })}
                  <FilterChips
                    onClick={() => {
                      handleFilterClick('all');
                    }}
                    bgColor="#6250ed"
                    color="#fff"
                    style={{ cursor: 'pointer' }}
                  >
                    Clear All
                  </FilterChips>
                </Flex>
              )}

              <Flex justifyContent="center" alignItems="center">
                {statusArray.map((status, index) => (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      marginRight: '10px',
                      fontSize: '12px',
                      color: '#4E6780',
                    }}
                  >
                    <StatusBubble status={status} />
                    {status}
                  </Flex>
                ))}
                {!campaignDetailsView && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      marginRight: '10px',
                      fontSize: '12px',
                      color: '#4E6780',
                    }}
                  >
                    <Button
                      styles={{
                        border: '1px solid #4E6780',
                        borderRadius: '5px 0px 0px 5px',
                      }}
                      color="blue"
                      bgColor={selectedViewTab === 0 ? '#4E6780' : '#ffffff'}
                      margin="0px"
                      padding="4px 6px 0px 6px"
                      onClick={() => setSelectedViewTab(0)}
                    >
                      <img
                        src={
                          selectedViewTab === 0
                            ? viewMode[0].img.active
                            : viewMode[0].img.disabled
                        }
                        alt=""
                      />
                    </Button>
                    <Button
                      styles={{
                        border: '1px solid #4E6780',
                        borderRadius: '0px 5px 5px 0px',
                      }}
                      color="blue"
                      bgColor={selectedViewTab === 1 ? '#4E6780' : '#ffffff'}
                      margin="0px"
                      padding="4px 6px 0px 6px"
                      onClick={() => setSelectedViewTab(1)}
                    >
                      <img
                        src={
                          selectedViewTab === 1
                            ? viewMode[1].img.active
                            : viewMode[1].img.disabled
                        }
                        alt=""
                      />
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Flex>
            {isEmpty(data) && !isFetching && (
              <span>No Campaigns to display</span>
            )}
            <DripDetailsWrapper
              style={
                campaignDetailsView
                  ? {
                      // height: '550px',
                      height: 'calc(100vh - 215px)',
                      overflowX: 'hidden',
                      overflowY: 'auto',
                    }
                  : {}
              }
              flexDirection="column"
              display="flex"
            >
              {!isEmpty(data) &&
                selectedViewTab === 1 &&
                paginatedData.map((item, index) => (
                  <DripDashboardListItem
                    {...item}
                    socialThemes={socialThemes}
                    isFirst={index === 0}
                    campaignDetailsView={campaignDetailsView}
                    setDetailsView={setCampaignDetailsView}
                    campaignDetailsRequest={campaignDetailsRequest}
                    socialNetworkData={socialNetwork.data}
                    detailsHandler={detailsHandler}
                  />
                ))}
            </DripDetailsWrapper>
            {selectedViewTab === 0 && (
              <Flex flexWrap="wrap" mx={-2}>
                {!isEmpty(data) &&
                  selectedViewTab === 0 &&
                  paginatedData.map((item, index) => (
                    <Box px={1} py={2} width={1 / 5}>
                      <DripDashboardBlockItem
                        {...item}
                        socialThemes={socialThemes}
                        isFirst={index === 0}
                        setSelectedViewTab={setSelectedViewTab}
                        setDetailsView={setCampaignDetailsView}
                        campaignDetailsRequest={campaignDetailsRequest}
                        socialNetworkData={socialNetwork.data}
                        detailsHandler={detailsHandler}
                      />
                    </Box>
                  ))}
              </Flex>
            )}
            {data && data.length > 0 && (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                paddingBottom="10px"
              >
                <Button
                  onClick={() => handlePagination('prev')}
                  disabled={currentPage <= 1}
                >
                  Previous
                </Button>
                <span style={{ paddingTop: '15px' }}>
                  {(currentPage - 1) * pageLimit + 1} -{' '}
                  {currentPage * pageLimit < totalDataLength
                    ? currentPage * pageLimit
                    : totalDataLength}{' '}
                  of {totalDataLength}
                </span>
                <Button
                  onClick={() => handlePagination('next')}
                  disabled={currentPage * pageLimit >= totalDataLength}
                >
                  Next
                </Button>
              </Flex>
            )}
          </Flex>
          {campaignDetailsView && (
            <DripDashboardDetails
              info={campaignsDetailsData}
              contactGroups={contactGroups}
              socialNetwork={socialNetwork}
              socialThemes={socialThemes}
              campaignsDetailsData={campaignsDetailsData}
              editCampaignHandler={editCampaignHandler}
              editLoader={editLoader}
              detailsHandler={detailsHandler}
              campaignData={campaignData}
            />
          )}
        </Flex>
      </LoaderWrapper>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    campaignsDetailsData: state.campaignDetails,
    contactGroups: state.contacts.contactGroups,
    socialNetwork: state.socialNetwork,
    campaignData: state.campaign,
  };
};

const mapDispatchToProps = {
  campaignDetailsRequest: campaignActions.api.campaignDetails.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(DripDashboardTable);
