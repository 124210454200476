import React, { useEffect, useState } from 'react';
import { Modal, ModalCloseButton, ModalBody } from '../../Modal';
import { Input, Button } from '../../'
import { parse } from 'json2csv';
import './AddEmailRecipients.css';
import { updateContacts } from '../../../services/configure/index';
import { LoaderWrapper } from '../../Loader';

const AddEmailRecipients = ({ 
    isModalOpen, 
    contactGroupsList, 
    selectedContactGroup, 
    contactGroups, 
    contactsCreate, 
    selectedEmailListItem,
    setSelectedEmailListItem, 
    fromEdit,
    handleClose,
    contactsRequest,
    page,
    setPage
}) => {
    console.log('[FROM EDIT] - ', fromEdit);
    const [recipients, setRecipients] = useState([
        { email: '', first_name: '', last_name: '', valid: false },
        { email: '', first_name: '', last_name: '', valid: false },
        { email: '', first_name: '', last_name: '', valid: false }
    ]);
    
    const [submitLoader, toggleSubmitLoader] = useState(false);

    useEffect(() => {
        if(selectedEmailListItem.length>0 && fromEdit) {
            // console.log('selectedEmailListItem ---------', selectedEmailListItem);
            const selectedRecipients = selectedEmailListItem.map(recipient => {
                return {
                    email: recipient.email,
                    first_name: recipient.name.first,
                    last_name: recipient.name.last,
                    current_email: recipient.email,
                    valid: true
                };
            });
            setRecipients(selectedRecipients);
        }
    }, [])

    const addRecipientRow = () => {
        const recipientsList = [...recipients];
        recipientsList.push({ email: '', first_name: '', last_name: '', valid: false });
        setRecipients(recipientsList);
    }

    const deleteRecipientRow = index => {
        const recipientsList = [...recipients];
        recipientsList.splice(index, 1);
        setRecipients(recipientsList);
    }

    const inputChangeHandler = (source, index) => {
        console.log('[source] - ', source.target.name, '---', index, '...', source.target.value);
        const recipientsList = [...recipients];
        recipientsList[index][source.target.name] = source.target.value;
        let contact = recipientsList[index]
        if (source.target.name == 'email') {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (pattern.test(contact.email))
                contact.valid = true;
            else
                contact.valid = false;
        }
        setRecipients(recipientsList);
    }

    const inputBlurHandler = (source, index) => {
        console.log('blur [source] - ', source.target.name, '---', index, '...', source.target.value);
        const recipientsList = [...recipients];
        let contact = recipientsList[index];
        if (source.target.name == 'email') {
         if(!contact.valid && contact.email)
         contact.hasError = true;
        else 
        contact.hasError = false;
        }
        setRecipients(recipientsList);
    }

    const submitHandler = () => {
        if(fromEdit)
            toggleSubmitLoader(true);
        const validRecipients = recipients.filter(recipient => recipient.valid);
        let csvFile;
        let selectedEmailList = contactGroups.find(contact => contact.id === selectedContactGroup).id;
        console.log('[SELECTED CONTACT GROUP] - ', selectedEmailList);
        if(validRecipients.length>0)
            csvFile = getEmailListCsv(validRecipients);
        console.log('[CSV FILE]] - ', csvFile);
        if(csvFile) {
            if(fromEdit) {
                updateContacts(selectedEmailList, [csvFile])
                .then(response => {
                    toggleSubmitLoader(false);
                    setSelectedEmailListItem([]);
                    contactsRequest({
                        params: {
                          contactGroup: selectedContactGroup
                        }
                    });
                    if (page > 0) {
                    setPage(0);
                    }
                    handleClose();
                    console.log('RESPONSE - ', response);
                })
                .catch(err => console.log('ERR - ', err))
            }
            else {
                contactsCreate({
                    name: selectedEmailList,
                    media1: [csvFile]
                },
                true, true);
                handleClose();
            }
        }
    }

    const getEmailListCsv = (values) => {
        let csvData;
        if(fromEdit) {
            csvData = parse(values, {
                fields: ['first_name', 'last_name', 'email', 'current_email'],
            });
        }
        else {
            csvData = parse(values, {
                fields: ['first_name', 'last_name', 'email'],
            });
        }
        console.log('[CSV DATA] - ', csvData);
        const csvBlob = new Blob([csvData], { type: 'text/csv' });
        const csvFile = new File([csvBlob], 'email_list.csv', {
            type: 'text/csv',
            lastModified: new Date(),
        });
        return csvFile;
    };

    return (
        <Modal
            onRequestClose={() => handleClose()}
            shouldCloseOnOverlayClick
            isOpen={isModalOpen}
            size="medium65" >
            <ModalCloseButton onClick={() => handleClose()} />
            <ModalBody>
                <div className='flex-container'>
                    <h3 style={{ marginRight: '7px' }}>{!fromEdit ? 'Add Email Recipients' : 'Edit Recipient Info'}</h3>
                    {!fromEdit ? (
                        <img style={{cursor: 'pointer'}} src='/assets/images/Add.svg' onClick={!fromEdit ? addRecipientRow : () => {}} />
                    ) : null}
                </div>
                <ul className='recipients-list-email'>
                    {recipients.map((recipient, index) => (
                        <li key={'recipient_'+index} style={{ width: '100%' }}>
                            <div className='recipient-row' style={{ width: '100%' }}>
                                <Input
                                    largeInput={true}
                                    placeholder='Email'
                                    name='email'
                                    value={recipient.email}
                                    onChange={evnt => inputChangeHandler(evnt, index)}
                                    onBlur={evnt => inputBlurHandler(evnt, index)}
                                    outerWidth='40%'
                                    marginRight='10px'
                                    width='100%' />
                                <Input
                                    largeInput={true}
                                    placeholder='First Name'
                                    name='first_name'
                                    value={recipient.first_name}
                                    onChange={evnt => inputChangeHandler(evnt, index)}
                                    onBlur={evnt => inputBlurHandler(evnt, index)}
                                    outerWidth='25%'
                                    marginRight='10px'
                                    width='100%' />
                                <Input
                                    largeInput={true}
                                    placeholder='Last Name'
                                    name='last_name'
                                    value={recipient.last_name}
                                    onChange={evnt => inputChangeHandler(evnt, index)}
                                    onBlur={evnt => inputBlurHandler(evnt, index)}
                                    outerWidth='25%'
                                    marginRight='10px'
                                    width='100%' />
                                {!fromEdit && index !== 0 ? (
                                    <div
                                        style={{
                                            border: index == 0 ? '1px solid #acacac' : '1px solid #6351ed',
                                            borderRadius: '12px',
                                            padding: '2px 5px 0px 4.3px',
                                            backgroundColor: 'transparent',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <img alt="" src='/assets/images/emailList-delete.svg' onClick={() => deleteRecipientRow(index)} />
                                    </div>
                                ) : null}
                            </div>
                            {
                                recipient.hasError && <div className="has-error">
                                Please enter a valid email address
                               </div>
                            }
                        </li>
                    ))}
                </ul>
                <LoaderWrapper isLoading={submitLoader}>
                    <Button className='submit-btn-add-email' 
                    disabled={recipients.some(recipient => recipient.hasError) || 
                    recipients.filter(recipient => recipient.valid).length === 0} onClick={submitHandler}>Submit</Button>
                </LoaderWrapper>
            </ModalBody>
        </Modal>

    );
}

export default AddEmailRecipients;