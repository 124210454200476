import React from 'react';
import {
  Button,
  LoaderWrapper,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalV2,
} from '../../../components';
import { Flex } from '@rebass/grid';
import { RadioInput } from '../MemberModal/MembersModal.styles';
import {
  CustomCloutScrollList,
  ScrollWrapper,
} from '../DripCampaignContainerV2.styles';
import { FilterRowChips } from '../../DripCampaignContainer/DripDashboardFilter/DripDashboardFilterPopup/DripDashboardFilterPopup.styles';
import { isEmpty, isEqual } from 'lodash';
import { MembersCheckBox } from '../../../components/ShareContentContainerV2/Elements/ShareCheckBoxes';

const CascadeModalEnterpriseAdmin = ({
  closeHandler,
  firmsArray,
  memberAdvisorsArray,
  handleAdvisorShare,
  selectedFirms,
  selectMembersHandler,
  setSelectedFirms,
  setOGCascadeOption,
  cascadeOption,
  shareDisabled,
  shareValidationCheck,
  selectedAdvisors,
  OGSelectedAdvisors,
  membersList,
  OGCascadeOption,
  setCascadeOption,
  OGSelectedFirms,
  loader,
  cascadeHandler,
  fetchingInfo,
  modalMessage,
  modalTitle,
  selectedFirmAdvisors=[],
  setSelectedFirmAdvisors,
  OGSelectedFirmAdvisors
}) => {
  console.log('OG CASCADED FIRMS - - - - -', OGSelectedFirms);
  console.log('FIRMS ARRAY - -- - - - ', firmsArray);
  console.log(selectedFirmAdvisors, selectedFirms);
  console.log(cascadeOption)
  console.log(OGSelectedFirmAdvisors);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    console.log('VALUE - -- - ', value)
    const updateAdvisors = cascadeOption === 'advisors' ? setSelectedFirmAdvisors : setSelectedFirms;
    const oldAdvisors = cascadeOption === 'advisors' ? selectedFirmAdvisors : selectedFirms;
    console.log('FIRMS ARRAY - - - - - -- ', firmsArray);
    if (checked) {
      if (value === 'selectAll')
        updateAdvisors(firmsArray.map((firm) => firm.id));
      else updateAdvisors([...oldAdvisors, value]);
    } else {
      if (value === 'selectAll') updateAdvisors([]);
      else updateAdvisors(oldAdvisors.filter((id) => id !== value));
    }
  };

  return (
    <ModalV2
      isOpen={true}
      onRequestClose={closeHandler}
      width="62%"
      bgColor="#fff"
    >
      <ModalHeader>
        <ModalCloseButton onClick={closeHandler} />
      </ModalHeader>
      <ModalBody>
        <LoaderWrapper isLoading={fetchingInfo}>
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            width="90%"
            margin="30px auto 16px"
            paddingBottom="12px"
            // height='350px'
            style={{
              border: '1px solid #cacaca',
              borderRadius: '5px',
              // paddingBottom: '12px',
              // position: 'relative'
            }}
          >
            <div
              style={{
                // justifyContent: 'center',
                marginBottom: '24px',
                color: '#000',
                fontWeight: '500',
                fontSize: '18px',
                padding: '10px',
                textAlign: 'center',
                // paddingTop: '9px',
                // paddingBottom: '10px',
                width: '100%',
                // display: 'flex',
                backgroundColor: '#F5F5F5',
              }}
            >
              {modalTitle || 'Campaign Cascade from Enterprise Admin'}
            </div>
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              style={{
                fontSize: '13px',
                letterSpacing: '0.35px',
                marginBottom: '20px',
              }}
            >
              <Flex justifyContent="center" alignItems="center" width="49%">
                <RadioInput
                  type="radio"
                  name="cascade-option"
                  id="radio_on"
                  checked={cascadeOption === 'advisors'}
                  onClick={() => {
                    setCascadeOption('advisors');
                  }}
                  style={{
                    cursor: 'pointer',
                    marginTop: '0px',
                    marginRight: '8px',
                  }}
                />
                <label
                  style={{
                    paddingRight: '2px',
                    color: '#49484a',
                    cursor: 'pointer',
                  }}
                  htmlFor="radio_on"
                >
                  To All Advisors of some/all firms
                </label>
              </Flex>
              <Flex justifyContent="center" alignItems="center" width="49%">
                <RadioInput
                  type="radio"
                  name="cascade-option"
                  id="radio_off"
                  checked={cascadeOption === 'admins'}
                  onClick={() => {
                    setCascadeOption('admins');
                  }}
                  style={{
                    cursor: 'pointer',
                    marginTop: '0px',
                    marginRight: '8px',
                  }}
                />
                <label
                  style={{
                    paddingRight: '2px',
                    color: '#49484a',
                    cursor: 'pointer',
                  }}
                  htmlFor="radio_off"
                >
                  To Admins of some/all firms
                </label>
              </Flex>
              <Flex justifyContent="center" alignItems="center" width="49%">
                <RadioInput
                  type="radio"
                  name="cascade-option"
                  id="radio_advisors"
                  checked={cascadeOption === 'onlyAdvisors'}
                  onClick={() => {
                    setCascadeOption('onlyAdvisors');
                  }}
                  style={{
                    cursor: 'pointer',
                    marginTop: '0px',
                    marginRight: '8px',
                  }}
                />
                <label
                  style={{
                    paddingRight: '2px',
                    color: '#49484a',
                    cursor: 'pointer',
                  }}
                  htmlFor="radio_advisors"
                >
                  To some/all of my Advisors
                </label>
              </Flex>
            </Flex>
            <ScrollWrapper
              position="relative"
              maxHeight="150px"
              width="94%"
              alignItems="center"
            >
              <CustomCloutScrollList paddingLeft="27%" width="80%">
                {cascadeOption !== 'onlyAdvisors' ? (
                  <div>
                    <li>
                      <FilterRowChips
                        style={{
                          padding: '8px',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <label className="checkbox-button">
                          <input
                            type="checkbox"
                            className="checkbox-button__input"
                            id="selectAll"
                            name="selectAll"
                            value="selectAll"
                            checked={
                              cascadeOption === 'advisors' ? 
                              selectedFirmAdvisors.length === firmsArray.length :
                              selectedFirms.length === firmsArray.length
                            }
                            onChange={handleCheckboxChange}
                          />
                          <span
                            className="checkbox-button__control"
                            style={{ marginRight: '8px' }}
                          ></span>
                          <label
                            htmlFor="selectAll"
                            style={{
                              fontSize: '12px',
                              color: '#6352EC',
                              cursor: 'pointer',
                            }}
                          >
                            Select All
                          </label>
                        </label>
                      </FilterRowChips>
                    </li>
                    {!isEmpty(firmsArray) && firmsArray.map((firm, index) => (
                      <li>
                        <FilterRowChips style={{ padding: '8px' }}>
                          <label className="checkbox-button">
                            <input
                              type="checkbox"
                              className="checkbox-button__input"
                              id={firm.id}
                              value={firm.id}
                              name={firm.name}
                              onChange={handleCheckboxChange}
                              checked={
                                cascadeOption === 'advisors' ? 
                                selectedFirmAdvisors.includes(firm.id) :
                                selectedFirms.includes(firm.id)
                              }
                            />
                            <span
                              style={{ marginRight: '8px' }}
                              className="checkbox-button__control"
                            ></span>
                            <label
                              htmlFor={firm.id}
                              style={{
                                fontSize: '12px',
                                color: '#6352EC',
                                cursor: 'pointer',
                              }}
                            >
                              {firm.name}
                            </label>
                          </label>
                        </FilterRowChips>
                      </li>
                    ))}
                  </div>
                ) : (
                  <ScrollWrapper maxHeight="200px" height="200px">
                    {!isEmpty(membersList) && (
                      <MembersCheckBox
                        membersList={membersList}
                        handleAdvisorChange={selectMembersHandler}
                        selectedMembers={selectedAdvisors}
                        ogSelectedMembers={OGSelectedAdvisors}
                        fontSize="12px"
                        fromCampaignTemplate={true}
                      />
                    )}
                  </ScrollWrapper>
                )}
              </CustomCloutScrollList>
            </ScrollWrapper>
            <LoaderWrapper isLoading={loader}>
              <Button
                // styles={{position: 'absolute', bottom: '20px'}}
                onClick={cascadeHandler}
                disabled={
                  cascadeOption === 'onlyAdvisors'
                    ? shareDisabled || shareValidationCheck()
                    : (
                        (cascadeOption === 'admins' && isEqual(selectedFirms, OGSelectedFirms)) || 
                        (cascadeOption === 'advisors' && isEqual(selectedFirmAdvisors, OGSelectedFirmAdvisors))
                      )
                }
              >
                Cascade
              </Button>
            </LoaderWrapper>
            {modalMessage && (
              <div style={{ fontSize: '12px', color: '#6351ed' }}>
                *{modalMessage}
              </div>
            )}
          </Flex>
        </LoaderWrapper>
      </ModalBody>
    </ModalV2>
  );
};

export default CascadeModalEnterpriseAdmin;
