import React from 'react';
import { FileUploadButton } from '@zhifez/react-file-uploader';

import { StyledFileUploadWrapper } from './FileUpload.styles';

const FileUpload = ({
  id,
  name,
  onChange,
  label,
  accept,
  asLabel,
  asButton,
  icon,
  asMioEditorButton,
  maxSizeMB,
  onError,
  title,
  border,
  color,
  background,
  borderRadius,
  fontSize,
  padding,
  fromInviteClients,
  margin,
  fromMioEditor,
  height,
  width,
  styles,
  wrapperStyles,
  fromImagePreviewTab,
  className
}) => {
  return (
    <StyledFileUploadWrapper 
      borderRadius={borderRadius}
      border={border} 
      background={background} 
      color={color} 
      title={title} 
      asLabel={asLabel} 
      asButton={asButton} 
      asMioEditorButton={asMioEditorButton}
      fontSize={fontSize}
      padding={padding}
      fromInviteClients={fromInviteClients}
      margin={margin}
      height={height}
      width={width}
      className={className}
      style={fromImagePreviewTab ? wrapperStyles : {}}
    >
      {fromMioEditor ? (
        <button style={{color: 'white', backgroundColor: '#6351ed', padding: '10px', border: '0px'}} onClick={onChange}>Upload Firm Logo</button>
      ) : (
        <FileUploadButton
        type="button"
        name={name}
        style={styles}
        onChange={onChange}
        background="#6351ed"
        id={id}
        label={label}
        accept={accept}
        className={icon}
        maxSizeMB={maxSizeMB}
        onError={onError}
      />
      )}
    </StyledFileUploadWrapper>
  );
};

export default FileUpload;

