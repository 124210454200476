import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import React from 'react';
import {
  Button,
  ButtonNoBorderBackground,
  LoaderWrapper,
} from '../../../../components';
import styled from 'styled-components';

export const DripFooterSection = styled.div.attrs({
    className: 'drip-footer-section',
  })`
    display: flex;
    background-color: #fff;
    height: 10%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    align-items: center;
    justify-content: right;
    width: 100%;
  `;

const CampaignFooterButtons = ({
  enableNext,
  nextHandler,
  backHandler,
  saveAndCloseHandler,
  loading,
  isPublished,
  enablePublish,
  publishHandler,
  padding,
  isLastStep = false,
  hideBackButton = false,
  isCompliance,
  enableBack,
  complianceStyle,
}) => {
  return isCompliance ? (
    <DripFooterSection style={complianceStyle}>
      <LoaderWrapper isLoading={loading}>
        <Flex>
          {!hideBackButton && (
            <ButtonNoBorderBackground
              color="#000000"
              onClick={backHandler}
              disabled={!enableBack}
              id="btn_compliance_back"
              style={{ fontSize: '10px' }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp; Back &nbsp; &nbsp;
            </ButtonNoBorderBackground>
          )}
          {!isLastStep && (
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#4E6780"
              borderColor="#4E6780"
              margin="0px 10px"
              onClick={nextHandler}
              disabled={!enableNext}
              id="btn_compliance_next"
              fontSize="10px"
            >
              Next
            </Button>
          )}
        </Flex>
      </LoaderWrapper>
    </DripFooterSection>
  ) : (
    <DripFooterSection style={{ padding: padding }}>
      <LoaderWrapper isLoading={loading}>
        <Flex>
          {!hideBackButton && (
            <ButtonNoBorderBackground color="#000000" onClick={backHandler}>
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp; Back &nbsp; &nbsp;
            </ButtonNoBorderBackground>
          )}
          {!isLastStep && (
            <Button
              styles={{ borderRadius: '4px' }}
              color="#fff"
              bgColor="#4E6780"
              margin="0px 10px"
              onClick={nextHandler}
              disabled={!enableNext}
              id="btn_campaign_next"
            >
              {isPublished ? 'Publish' : 'Next'}
            </Button>
          )}
        </Flex>
      </LoaderWrapper>
    </DripFooterSection>
  );
};

export default CampaignFooterButtons;
