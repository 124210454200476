import React, { useState, useEffect } from 'react';
import {
  CheckBox,
  CheckBoxSpan,
  CheckBoxLabel,
} from '../../../components/ShareContentContainer/ShareContentContainer.styles';
import {
  ModalHeader,
  ModalCloseButton,
  LoaderWrapper,
  MioRadio,
  MioRadioSpan,
  Button,
} from '../../../components';
import {
  getContactSublistForCrm,
  getSalesforceContactsByImportType,
  updateContactSubListForCrm,
} from '../../../services/configure';
import { get } from 'lodash';
import {
  ContactSubListWrapper,
  ContactListButton,
  RadioInput,
} from './ContactListModal.styles';

import {
  Table,
  Tr,
  EmailListTh,
  EmailListTableHeading,
  Modal,
  ModalBody,
} from '../../../components';
import { Flex } from '@rebass/grid';

const ContactListModal = ({
  contactOpen,
  setContactOpen,
  selectedNetwork,
  setSelectedNetwork,
  setUploadType,
  selectedContactGroup,
  contactsRequest,
}) => {
  const [subList, setSubList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [onSave, setOnSave] = useState(false);
  const [saleforceView, setSalesforceView] = useState(false);
  const [sfImportType, setSfImportType] = useState('account');
  
  const handleSubmit = () => {
    setOnSave(true);
    let finalSubList = {};
    let finalSubListTypes = {};
    const checkedSubList = subList.filter(
      (s) => s.id !== 'selectAll' && s.checked
    );
    for (let i = 0; i < checkedSubList.length; i++) {
      finalSubList[checkedSubList[i].id] = checkedSubList[i].name;
    }

    for (let i = 0; i < checkedSubList.length; i++) {
      finalSubListTypes[checkedSubList[i].id] = checkedSubList[i].type;
    }

    let payload = {
      crmName: selectedNetwork,
      listId: finalSubList,
      contact_types: finalSubListTypes,
    };

    if(payload.crmName === 'sf') {
      payload.sf_import_type = sfImportType;
    }
   
    updateContactSubListForCrm(payload)
      .then((res) => {
        console.log(res);
        if (res.result.success) {
          setTimeout(function () {
            setOnSave(false);
            handleModalClose();
            window.location.reload();
          }, 5000);
        } else {
          setOnSave(false);
          setMessage('Oops! Something went wrong');
        }
      })
      .catch((err) => {
        setOnSave(false);
        setMessage('Oops! Something went wrong');
      });
  };

  const handleTypeChange = (id, mode) => {
    const itemState = subList.find((obj) => {
      return obj.id === id;
    });
    itemState.type = mode;
    console.log('item', itemState);
    setSubList([...subList]);
  };

  const handleSaleforceAddClick = () => {
    setLoading(true);
    let subCrmLists = [],
    selectAll = [];
    getContactSublistForCrm('sf', sfImportType)
      .then((res) => {
        if (res.result.success) {
          const subLists = get(res.result.data[0], selectedNetwork, {});
          console.log('objectsubLists', subLists);
          if (Object.keys(subLists).length > 0) {
            Object.keys(subLists).forEach((id) => {
              subCrmLists.push({
                name: subLists[id].name,
                checked: subLists[id].in_system === 'true' ? true : false,
                id: id,
                type:
                  subLists[id].contact_type === 'prospect' ||
                    subLists[id].contact_type === 'client'
                    ? subLists[id].contact_type
                    : 'prospect',
              });
            });
            if (
              subCrmLists.filter((s) => s.checked).length ===
              subCrmLists.length
            ) {
              selectAll.push({
                id: 'selectAll',
                name: 'Select All',
                checked: true,
              });
            } else {
              selectAll.push({
                id: 'selectAll',
                name: 'Select All',
                checked: false,
              });
            }
            setLoading(false);
            setSubList([...selectAll, ...subCrmLists]);
            setSalesforceView(false);
          } else {
            setLoading(false);
            setSalesforceView(false);
            setMessage('No List Found');
          }
        } else {
          setLoading(false);
          setSalesforceView(false);
          setMessage('Unable to fetch records');
        }
      })
      .catch((err) => {
        setLoading(false);
        setSalesforceView(false);
        setMessage('Unable to fetch records');
      });
  }

  const handleChange = (e, data) => {
    if (data.name === 'Select All' && !data.checked) {
      subList.forEach((s) => {
        s.checked = true;
      });
      setSubList([...subList]);
    } else if (data.name === 'Select All' && data.checked) {
      subList.forEach((s) => {
        s.checked = false;
      });
      setSubList([...subList]);
    } else {
      if (data.checked) {
        subList.forEach((l) => {
          if (l.id === data.id) {
            l.checked = false;
          }
          if (l.id === 'selectAll' && l.checked) {
            l.checked = false;
          }
        });
        setSubList([...subList]);
      } else {
        subList.forEach((l) => {
          if (l.id === data.id) {
            l.checked = true;
          }
        });
        if (subList.filter((s) => s.checked).length === subList.length - 1) {
          subList.forEach((l) => {
            if (l.id === 'selectAll' && !l.checked) {
              l.checked = true;
            }
          });
        }
        setSubList([...subList]);
      }
    }
  };

  useEffect(() => {
    let subCrmLists = [],
      selectAll = [];
    if (selectedNetwork && selectedNetwork.length) {
      if(selectedNetwork === 'sf') {
        setSalesforceView(true);
      } else {
        setLoading(true);
        getContactSublistForCrm(selectedNetwork)
        .then((res) => {
          if (res.result.success) {
            const subLists = get(res.result.data[0], selectedNetwork, {});
            console.log('objectsubLists', subLists);
            if (Object.keys(subLists).length > 0) {
              Object.keys(subLists).forEach((id) => {
                subCrmLists.push({
                  name: subLists[id].name,
                  checked: subLists[id].in_system === 'true' ? true : false,
                  id: id,
                  type:
                    subLists[id].contact_type === 'prospect' ||
                    subLists[id].contact_type === 'client'
                      ? subLists[id].contact_type
                      : 'prospect',
                });
              });
              if (
                subCrmLists.filter((s) => s.checked).length ===
                subCrmLists.length
              ) {
                selectAll.push({
                  id: 'selectAll',
                  name: 'Select All',
                  checked: true,
                });
              } else {
                selectAll.push({
                  id: 'selectAll',
                  name: 'Select All',
                  checked: false,
                });
              }
              setLoading(false);
              setSubList([...selectAll, ...subCrmLists]);
            } else {
              setLoading(false);
              setMessage('No List Found');
            }
          } else {
            setLoading(false);
            setMessage('Unable to fetch records');
          }
        })
        .catch((err) => {
          setLoading(false);
          setMessage('Unable to fetch records');
        });
      }
    
    }
  }, [selectedNetwork]);

  const checkActive = (id) => {
    const itemState = subList.find((obj) => {
      return obj.id === id;
    }).checked;
    console.log('item', itemState);

    return itemState;
  };

  const membersCheckbox =
    subList &&
    subList.length > 1 &&
    subList.map((data) => (
      <CheckBoxSpan
        key={data.id}
        screenWidth={window.innerWidth}
        style={{
          border: '1px solid #ccc',
          background: 'rgb(239, 242, 245)',
          marginBottom: '4px',
          textAlign: 'left',
          paddingBottom: '27px',
          paddingTop: '7px',
        }}
      >
        <Flex>
          <Flex width="68%">
            <CheckBox
              id={data.id}
              checked={data.checked}
              onChange={(e) => handleChange(e, data)}
              screenWidth={window.innerWidth}
            />

            <CheckBoxLabel
              style={{ paddingLeft: '30px' }}
              htmlFor={data.id}
              color="#848484"
            >
              {data.name}
            </CheckBoxLabel>
          </Flex>
          {data && data.id !== 'selectAll' && (
            <Flex width="35%" justifyContent="space-between" pr="65px">
              <RadioInput
                type="radio"
                name={data.id + '_list_type'}
                id="list_type_prospect"
                onClick={() => {
                  handleTypeChange(data.id, 'prospect');
                }}
                checked={data.type === 'prospect'}
                defaultChecked
                style={{
                  cursor: subList && checkActive(data.id) ? 'pointer' : '',
                }}
                selected={subList && checkActive(data.id)}
                disabled={subList && !checkActive(data.id)}
              />
              <RadioInput
                type="radio"
                name={data.id + '_list_type'}
                id="list_type_client"
                onClick={() => {
                  handleTypeChange(data.id, 'client');
                }}
                checked={data.type === 'client'}
                style={{
                  cursor: subList && checkActive(data.id) ? 'pointer' : '',
                }}
                selected={subList && checkActive(data.id)}
                disabled={subList && !checkActive(data.id)}
              />
            </Flex>
          )}
        </Flex>
      </CheckBoxSpan>
    ));

  const handleModalClose = () => {
    setSelectedNetwork('');
    setSalesforceView(false);
    window.history.replaceState(
      '',
      '',
      window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname
    );
    setSubList([]);
    setUploadType('');
    setContactOpen(false);
    setMessage('');
    window.sessionStorage.removeItem('snetwork');
  };
  // console.log(loading);

  return (
    <>
      {
        <Modal
          size="medium"
          onRequestClose={handleModalClose}
          shouldCloseOnOverlayClick
          isOpen={contactOpen}
          bgColor="#fff"
        >
          <ModalHeader
            id="header"
            paddingBottom="0px"
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '18px',
              paddingTop: '20px',
            }}
          >
            <div> {saleforceView ? 'Select contact lists by the following type' : 'Select contact lists to import'}</div>
            <ModalCloseButton onClick={handleModalClose} />
          </ModalHeader>
          <ModalBody>
            {saleforceView ? (
              <Flex
                width="100%"
                pt="8px"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Flex justifyContent='space-between' width="57%">
                  <MioRadioSpan width="40%" noBorder>
                    <MioRadio
                      id='sf_account'
                      name="sf_account"
                      defaultChecked
                      onClick={() => setSfImportType('account')}
                      checked={sfImportType === 'account'}
                    />
                    <label htmlFor='sf_account'>Account Level</label>
                  </MioRadioSpan>
                  <MioRadioSpan width="40%" noBorder>
                    <MioRadio
                      id='sf_campaign'
                      name="sf_campaign"
                      onClick={() => setSfImportType('campaign')}
                      checked={sfImportType === 'campaign'}
                    />
                    <label htmlFor='sf_campaign'>Campaign Level</label>
                  </MioRadioSpan>
                </Flex>
                <Flex justifyContent="center">
                  <LoaderWrapper isLoading={loading}>
                    <Button onClick={handleSaleforceAddClick}>Add</Button>
                  </LoaderWrapper>
                </Flex>
              </Flex>
            ) : (
              <Flex
              width="100%"
              pt="8px"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <LoaderWrapper isLoading={loading}>
                <Table height="auto">
                  {message ? null : (
                    <EmailListTableHeading color="#fff">
                      <Tr border="none">
                        <EmailListTh
                          align="left"
                          style={{ paddingLeft: '0px' }}
                          bgColor="#fff"
                        ></EmailListTh>
                        <EmailListTh align="left" width="20%" bgColor="#fff">
                          Prospects
                        </EmailListTh>
                        <EmailListTh align="left" width="20%" bgColor="#fff">
                          Clients
                        </EmailListTh>
                      </Tr>
                    </EmailListTableHeading>
                  )}

                  <ContactSubListWrapper height="250px">
                    {/* {membersCheckbox} */}
                    {message ? (
                      <div style={{ fontSize: '14px' }}>{message}</div>
                    ) : (
                      membersCheckbox
                    )}
                  </ContactSubListWrapper>
                </Table>
              </LoaderWrapper>
              {message ? null : (
                <LoaderWrapper isLoading={onSave}>
                  <ContactListButton
                    style={{
                      borderRadius: '5px',
                      marginTop: '25px',
                      marginBottom: '10px',
                    }}
                    onClick={handleSubmit}
                    disabled={
                      subList.filter((s) => s.checked).length > 0 ? false : true
                    }
                  >
                    Add
                  </ContactListButton>
                </LoaderWrapper>
              )}
                </Flex>
            )}
            
          </ModalBody>
        </Modal>
      }
    </>
  );
};

export default ContactListModal;

