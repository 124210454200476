/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedinIn,
  faTwitter,
  faGooglePlusG,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Flex } from '@rebass/grid';
import {
  ResponsiveImg,
  DripIconButton,
  FlipCard,
  ShareContentModal,
} from '../';
import { onImageError, themesData } from '../../utils';
import CloneNLModal from '../../containers/NewsletterContainer/CloneNLModal/CloneNLModal';

import {
  StyledArticle,
  FlipBackContent,
  BackContentRowData,
  IconBox,
} from './DashboardArticle.styles';
import RecentDetailsModal from './RecentDetailsModal';
import SentNewsletterModal from '../../containers/NewsletterContainer/NewsletterModals/SentNewsletterModal';
import { LoaderWrapper } from '../Loader';
import { NewsletterCheckBoxDiv } from '../../containers/NewsletterContainer/Recipients/Recipients.styles';

const RecentArticle = ({
  id,
  url,
  topic,
  place,
  date,
  description,
  link,
  history,
  sharedChannels,
  networkDetails,
  themeNames,
  themeId,
  isGivingCompass,
  isAdmin,
  index,
  recentSharedChannel,
  recentReachCount,
  recentLinkCount,
  totalArticles,
  setShowArticleLoader,
  imageCounter,
  setImageCounter,
  sharedData,
  year,
  channelId,
  source,
  sponsor,
  contactGroups,
  articleId,
  auxiliary,
  article,
  isPremiumAdvisor,
  isExportPDF,
  handleArticleSelection,
  exportPDFArticles,
  recentSharedIds,
}) => {
  const [flipToggle, setFlipToggle] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openSentModal, setOpenSentModal] = useState(false);
  const [openNLCloneModal, toggleNLCloneModal] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const [cloneBtnLoader, toggleCloneBtnLoader] = useState(false);
  const [isCloning, toggleIsCloning] = useState(false);

  const trimmedTopic =
    (topic || '').length > 60 ? `${topic.substring(0, 60)}...` : topic;
  const trimmedDesc =
    (description || '').length > 100
      ? `${description.substring(0, 100)}...`
      : description;

  const onForwardClick = () => {
    if (isAdmin) {
      history.push('/admin/analytics');
    } else {
      history.push('/analytics');
    }
  };
  const onImageLoad = () => {
    setShowContent(true);
    let arr = imageCounter;
    // console.log(index, totalArticles, arr.length);
    if (totalArticles == arr.length + 1) {
      setTimeout(() => {
        setShowArticleLoader(false);
      }, 1000);
    } else {
      arr.push(index);
      setImageCounter(arr);
    }
  };

  const handleClick = (item) => {
    console.log('ITEM - -- - - ', item);
    setSelectedItem(item);
    setOpenShareModal(true);
  };

  const handleInfo = (articleId, name) => {
    if (source === 'newsletter') {
      //history.push('/newsletter?type=dashboard&tab=sent');
      setOpenSentModal(true);
      setSelectedId(articleId);
      setSelectedTopic(name);
    } else {
      setOpenModal(true);
    }
  };

  const handleRedirection = () => {
    if (source === 'img') {
      const urlObj =
        article.mediaUrl && article.mediaUrl[0] ? article.mediaUrl[0] : '';
      if (urlObj) {
        const imgUrl = urlObj.path + urlObj.name.system + '.' + urlObj.ext;
        window.open(imgUrl, '_blank');
      }
    } else if (source === 'newsletter') {
      history.push('/newsletter?type=dashboard&tab=sent');
    } else {
      window.open(link, '_blank');
    }
  };

  const cloneBtnHandler = (article) => {
    // console.log('AUXILLIARY - - -  -', auxiliary);
    if (source && source === 'newsletter') {
      console.log('NEWSLETTER ID', article.articleId);
      toggleNLCloneModal(true);
    } else {
      setSelectedItem(sharedData[0]);
      toggleIsCloning(true);
      // console.log('SHARED DATA', sharedData);
      setOpenShareModal(true);
    }
    // setSelectedId(article.id);
  };

  return (
    <StyledArticle index={index} style={{ width: isExportPDF && '90%' }}>
      <FlipCard
        shouldFlip={flipToggle}
        flipFront={
          <React.Fragment>
            {' '}
            <a
              href="javascript:void(0);"
              onClick={isExportPDF ? () => {} : handleRedirection}
              rel="noopener noreferrer"
            >
              <div>
                <ResponsiveImg
                  src={url}
                  onError={onImageError}
                  onLoad={onImageLoad}
                />
              </div>
              {showContent && (
                <div className="details-wrapper">
                  {isExportPDF && (
                    <div style={{ float: 'right' }}>
                      <NewsletterCheckBoxDiv>
                        <label
                          style={{ color: themesData.purpleishBlue }}
                          className="container"
                        >
                          <input
                            type="checkbox"
                            name="exportPDFArticles"
                            value={id}
                            checked={exportPDFArticles[id]}
                            onChange={(e) =>
                              handleArticleSelection(
                                id,
                                e.target.checked,
                                recentSharedIds
                              )
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </NewsletterCheckBoxDiv>
                    </div>
                  )}
                  <div className="selected-topic">
                    {source && source === 'newsletter'
                      ? 'NEWSLETTER'
                      : source === 'img'
                      ? `Image: ${themeNames[themeId]}`
                      : themeNames[themeId]}
                    {sponsor && (
                      <span
                        style={{
                          backgroundColor: 'rgb(85, 85, 85)',
                          color: 'rgb(213, 213, 213)',
                          fontSize: '8px',
                          borderRadius: '3px',
                          padding: '0px 4px',
                          float: 'right',
                          textTransform: 'none',
                          fontWeight: '500',
                          height: '14px',
                        }}
                      >
                        Sponsored
                      </span>
                    )}
                  </div>
                  <div className="place-date">{`${place}, ${date}`}</div>
                  <div
                    className="post-heading"
                    title={topic && topic.length > 60 ? topic : ''}
                  >
                    {trimmedTopic}
                  </div>

                  <div
                    className="post-desc"
                    title={
                      description && description.length > 150 ? description : ''
                    }
                  >
                    {trimmedDesc}
                  </div>
                </div>
              )}
            </a>
            {!flipToggle && !isExportPDF && (
              <div className="btn-wrapper">
                <Flex justifyContent="center" alignItems="baseline">
                  <DripIconButton
                    icon="information"
                    onClick={() => handleInfo(articleId, topic)}
                  />
                  {auxiliary && auxiliary.source !== 'img' && (
                    <LoaderWrapper isLoading={cloneBtnLoader}>
                      <img
                        src={'/assets/images/clone-solid.svg'}
                        onClick={() => cloneBtnHandler(article)}
                        style={{
                          width: '12px',
                          height: '12px',
                          cursor: 'pointer',
                          borderRadius: '0',
                        }}
                      />
                    </LoaderWrapper>
                  )}
                </Flex>
                {isGivingCompass && (
                  <div className="power-by-wrapper">
                    <span>Powered By</span>{' '}
                    <span>
                      <ResponsiveImg src="/assets/images/giving-compass.png" />
                    </span>
                  </div>
                )}
                <div>
                  <DripIconButton icon="graph-bar" onClick={onForwardClick} />
                </div>
              </div>
            )}
          </React.Fragment>
        }
        flipBack={
          <FlipBackContent flexDirection="column">
            <BackContentRowData>
              <div>Post Reach</div>
              <div>{networkDetails.reach || 0}</div>
            </BackContentRowData>
            <BackContentRowData>
              <div>Engagement</div>
              <div>{networkDetails.engagement}</div>
            </BackContentRowData>
            <BackContentRowData>
              <div>Platform Shared</div>
              <Flex width={1} justifyContent="center" alignItems="center">
                {recentSharedChannel.map((channel, index) => {
                  const icons = {
                    tw: faTwitter,
                    ln: faLinkedinIn,
                    fb: faFacebook,
                    em: faEnvelope,
                    go: faGooglePlusG,
                  };
                  return (
                    <IconBox
                      background="transparent"
                      color="white"
                      key={'channel_' + index}
                    >
                      <FontAwesomeIcon
                        icon={icons[channel]}
                        style={{
                          marginLeft: '3px',
                          marginRight: '4px',
                          marginTop: '2px',
                        }}
                      />
                      &nbsp;&nbsp;
                    </IconBox>
                  );
                })}
              </Flex>
            </BackContentRowData>
            <BackContentRowData>
              <DripIconButton
                icon="reply"
                onClick={() => setFlipToggle(!flipToggle)}
                color="white"
              />
            </BackContentRowData>
          </FlipBackContent>
        }
      />
      <RecentDetailsModal
        open={openModal}
        setOpen={setOpenModal}
        topic={topic}
        articles={sharedData}
        handleClick={handleClick}
        setSelectedItem={setSelectedItem}
        isPremiumAdvisor={isPremiumAdvisor}
      />
      {openShareModal && (
        <ShareContentModal
          editable={isCloning ? true : false}
          handleClose={() => {
            setOpenShareModal(false);
            setSelectedItem({});
            toggleIsCloning(false);
          }}
          open={openShareModal}
          {...{
            id,
            link,
            topic,
            place,
            date,
            year,
            channelId,
            themeId,
            url,
            selectedItem,
          }}
          isFromRecentTab
          isCloning={isCloning}
          article={article}
          toggleIsCloning={toggleIsCloning}
        />
      )}
      {openSentModal && (
        <SentNewsletterModal
          open={openSentModal}
          setOpen={setOpenSentModal}
          selectedId={articleId}
          setSelectedId={setSelectedId}
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
          contactGroups={contactGroups}
        />
      )}
      {openNLCloneModal && (
        <CloneNLModal
          show={openNLCloneModal}
          toggleShow={toggleNLCloneModal}
          newsletterId={article.articleId}
          newsletterName={topic}
          isFromRecentTab={true}
          toggleIsCloning={toggleIsCloning}
        />
      )}
    </StyledArticle>
  );
};

export default withRouter(RecentArticle);
