import { isEmpty } from 'lodash';
import apiInstance from '../api';
import { campaignViewEndpoints, objectToFormData } from '../helpers';

export const createCampaign = (payload) => {
  return apiInstance().post(`/campaign`, objectToFormData(payload));
};

export const getCampaigns = (payload) => {
  console.log('NEXT - - - -', payload?.next, 'PREV- - -- - ', payload?.prev);
  let paginationString = (payload?.next || payload?.prev) ? 
    `&${payload?.next ? `after=${payload?.next}` : `before=${payload?.prev}`}` : '';
  console.log('PAGINATION STRING - - - -- ', paginationString);
  if (payload && payload !== 'approved_to_share' && payload !== 'ats_automated') {
    const keys = Object.keys(payload);
    let tmpPd = { ...payload };
    if(tmpPd.next)
      delete tmpPd.next;
    else if(tmpPd.prev)
      delete tmpPd.prev;
    for (const key of keys) {
      if (isEmpty(tmpPd[key])) {
        delete tmpPd[key];
      }
    }

    if(tmpPd?.webinar){
        tmpPd.webinar = "yes";
    }

    if (!isEmpty(payload?.duration)) {
      let durationMold = payload?.duration.map((value, key) => {
        return JSON.parse(value);
      });
      tmpPd.duration = durationMold;
    }

    if (!isEmpty(payload?.frequency)) {
      let frequencyMold = payload?.frequency.map((value, key) => {
        return JSON.parse(value);
      });
      tmpPd.frequency = frequencyMold;
    }
    tmpPd.limit = 10;
    let stfy = JSON.stringify(tmpPd);

    return apiInstance().get(`/campaign?filter=${stfy}${paginationString}`);
  } 
  else 
    return apiInstance().get(`/campaign?filter=${JSON.stringify({limit: 10})}`);
};

export const getCampaignDetails = (id) => {
  return apiInstance().get(`/campaign/${id}`);
};

export const updateCampaign = ({ id, payload }) => {
  return apiInstance().post(`/campaign/${id}`, objectToFormData(payload));
};

export const updateStatusOfCampaign = ({ id, payload }) => {
  return apiInstance().post(
    `/campaign/${id}/update_status`,
    objectToFormData(payload)
  );
};

export const deleteCampaign = ({ id, type }) => {
  return apiInstance().delete(`/campaign/${id}?type=${type}`);
};

export const getEmailTemplates = (filterArray) => {
  return apiInstance().get(
    `/email/templates?filter={%22type%22:${JSON.stringify(filterArray)}}`
  );
};

export const postSelectedArticles = ({ payload }) => {
  return apiInstance().post(`campaign/bucket`, objectToFormData(payload));
};

export const getSelectedArticles = (campaignId) => {
  return apiInstance().get(`campaign/bucket/${campaignId}`);
};

export const publishCampaign = (payload) => {
  return apiInstance().post('/campaign/publish', objectToFormData(payload));
};

export const publishCampaignV2 = ({ id, payload }) => {
  return apiInstance().post(`/campaign/${id}/share`, objectToFormData(payload));
};

export const cloneOrSaveCampaign = (payload) => {
  return apiInstance().post(`/campaign/clone`, objectToFormData(payload));
};

export const postCampaignReview = ({ id, payload }) => {
  return apiInstance().post(
    `/campaign/${id}/review`,
    objectToFormData(payload)
  );
};

export const postEmailTemplates = (payload) => {
  return apiInstance().post('/email/templates', objectToFormData(payload));
};

export const nextCampaignReview = (id) => {
  return apiInstance().get(`/campaign/${id}/pending`);
};

export const getEmailTemplate = (id) => {
  return apiInstance().get(`/email/templates/${id}`);
};

// export const postCascadeEmailTemplate = (payload) => {
//   return apiInstance().post(
//     `/email/template/cascade`,
//     objectToFormData(payload)
//   );
// };

export const viewCampaignInfo = (recordId, recordType) => {
  return apiInstance().get(
    `/campaign/view_info?record_id=${recordId}&record_type=${recordType}`
  );
};

export const exportPdf = (payload) => {
  return apiInstance().post('/campaign/export', objectToFormData(payload));
};

export const postCascadeEmailTemplate = (payload) => {
  return apiInstance().post(`/email/template/cascade`, objectToFormData(payload));
};

export const postCampaignCascade = (payload, id) => {
  return apiInstance().post(`/campaign/cascade/${id}`, objectToFormData(payload));
};

export const postCampaignCascadeEnterpriseLevel = ({payload, id, campaignType}) => {
  return apiInstance().post(`/campaign/cascade_to_firm/${id}?campaign_type=${campaignType}`, objectToFormData(payload))
}

export const deleteEmailTemplate = ({ id }) => {
  return apiInstance().delete(`/email/templates/${id}`);
};

export const beeAuth = (type) => {
  if(type) {
    return apiInstance().post(`/editor/login?type=${type}`);
  } else {
    return apiInstance().post('/editor/login');
  }
};

export const getWebinarList = () => {
  return apiInstance().get('/user/webinars');
}

export const getWebinarDetails = ({ id }) => {
  return apiInstance().get(`/user/webinars/details?wid=${id}`);
}
export const postCascadeCampaign = ({ id, payload }) => {
  return apiInstance().post(`/campaign/cascade/${id}`, objectToFormData(payload));
}

export const getCascadedCampaigns = () => {
  return apiInstance().get(`/campaign/cascaded`);
}

export const postUseCascadedCampaign = ({ cascadeId, recordType }) => {
  if(recordType === 'series')
    return apiInstance().post(`/campaign/process_cascaded/${cascadeId}?record_type=series`);
  else
    return apiInstance().post(`/campaign/process_cascaded/${cascadeId}`);
}

export const postIgnoreCascade = ({ cascadeId, recordType }) => {
  if (recordType === 'series')
    return apiInstance().post(`/campaign/ignore_cascaded/${cascadeId}?record_type=series`);
  else
    return apiInstance().post(`/campaign/ignore_cascaded/${cascadeId}`);
}

export const getBasicCampaignViewDetails = (id) => {
  return apiInstance().get(`${campaignViewEndpoints.baseUrl}/details?campaign=${id}`);
}

export const getEmailSendData = (id) => {
  return apiInstance().get(`${campaignViewEndpoints.baseUrl}/sent?campaign=${id}`);
}

export const getEmailEngagementData = (id) => {
  return apiInstance().get(`${campaignViewEndpoints.baseUrl}/sent/details?campaign=${id}`);
}

export const getSocialSendData = (id) => {
  return apiInstance().get(`${campaignViewEndpoints.baseUrl}/social?campaign=${id}`);
}

export const getCascadeInfoEnterpriseLevelAdmin = ({payloadId, type}) => {
  return apiInstance().get(`/campaign/firm_cascaded/${payloadId}?campaign_type=${type}`);
}

export const getSeriesSubCampaigns = ({ id }) => {
  return apiInstance().get(`/series_campaigns?filter={"series_id":${id}}`);
}

export const getCanDuplicateSeries = ({ id }) => {
  return apiInstance().get(`/campaign_can_duplicate?is_series=true&series_id=${id}`)
}

export const sendTestEmailOneTime = (payload) => {
  return apiInstance().post('/campaign/send_test_email', objectToFormData(payload));
}

export const cascadedEmailTemplate = ({ id, payload }) => {
  return apiInstance().post(`email/cascade_to_firm/${id}`, objectToFormData(payload));
}

export const getCascadeEmailTemplate = (id) => {
  return apiInstance().get(`email/firms_cascaded/${id}`);
}
