import { Flex } from '@rebass/grid';
import React, { useState } from 'react';
import { CampaignFilterButton } from './DripDashboardFilter.styles';
import DripDashboardFilterPopup from './DripDashboardFilterPopup/DripDashboardFilterPopup';
import DripDashboardFilterTab from './DripDashboardFilterTab/DripDashboardFilterTab';
import { isEmpty } from 'lodash';

const DripDashboardFilter = ({
  createFlow,
  setCreateFlow,
  filterTabs,
  filterBtns,
  selectedCampaignTab,
  setSelectedCampaignTab,
  setCampaignDetailsView,
  campaignDetailsView,
  setFilters,
  filters,
  campaignRequest,
  changeCreateFlow,
}) => {
  const [filterModal, toggleFilterModal] = useState(false);
  return (
    <Flex
      width="100%"
      padding="20px 50px"
      justifyContent="space-between"
      backgroundColor="#E4E8EB"
    >
      {filterTabs.map((tab, index) => (
        <DripDashboardFilterTab
          tab={tab}
          selected={selectedCampaignTab === index}
          click={() => {
            setSelectedCampaignTab(index);
            setCampaignDetailsView(false);
          }}
        />
      ))}
      {filterBtns.map((btn, index) => (
        <CampaignFilterButton
          filterBtn={true}
          selected={index === 0 && !isEmpty(filters)}
          onClick={
            index === 1
              ? () => {
                console.log('TOGGLE CREATE FLOW - - -');
                changeCreateFlow(true);
              }
              : () => {
                  toggleFilterModal(true);
                }
          }
        >
          <img alt="" src={btn.img} style={{ marginRight: '7px' }} />
          {btn.name}
        </CampaignFilterButton>
      ))}
      {filterModal && (
        <DripDashboardFilterPopup
          handleModalClose={() => {
            toggleFilterModal(false);
          }}
          setFilters={setFilters}
          filters={filters}
          campaignRequest={campaignRequest}
          setSelectedCampaignTab={setSelectedCampaignTab}
        />
      )}
    </Flex>
  );
};

export default DripDashboardFilter;
