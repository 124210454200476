import React from 'react';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import './FroalaRichTextEditor.styles.css';

import FroalaEditor from 'react-froala-wysiwyg';

// Import all Froala Editor plugins;
import 'froala-editor/js/plugins.pkgd.min.js';

// Import a single Froala Editor plugin.
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/line_height.min.js';

// Import a language file.
import 'froala-editor/js/languages/de.js';

// Import a third-party plugin.
import 'froala-editor/js/third_party/image_tui.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';

import { apiDetails } from '../../services/helpers';
import { getMediaUrl, oktaHelper } from '../../utils';
import { get, isEmpty } from 'lodash';
import { specialChars } from '../../services/text-editor/specialChars';

export const FrolaRichTextEditor = ({ 
  content, 
  setContent, 
  isTitle,
  placeholder,
  showCharCounter,
  maxCharLimit,
  minHeight,
  maxHeight,
  imageEditButtons,
  disabled,
  zIndex 
}) => {
  const config = {
    key: process.env.REACT_APP_FLOARA_KEY,
    width: '100%',
    imageOutputSize: true,
    tooltips: false,
    fontFamilySelection: true,
    fontSizeSelection: true,
    fontSizeUnit: 'px',
    imageDefaultAlign: 'left',
    zIndex: zIndex || 2501,
    fontSize: [
      '8',
      '9',
      '10',
      '11',
      '12',
      '14',
      '16',
      '18',
      '20',
      '22',
      '24',
      '30',
      '36',
      '48',
      '60',
      '72',
      '96',
    ],
    fontFamily: {
      'Arial,sans-serif': 'Arial',
      'Helvetica,sans-serif': 'Helvetica',
      'Georgia,serif': 'Georgia',
      'Impact,Charcoal,sans-serif': 'Impact',
      'Tahoma,Geneva,sans-serif': 'Tahoma',
      "'Times New Roman',Times,serif": 'Times New Roman',
      'Verdana,Geneva,sans-serif': 'Verdana',
    },
    useClasses: false,
    lineHeights: {
      Default: '',
      Single: '1',
      1.15: '1.15',
      1.5: '1.5',
      Double: '2',
    },
    attribution: false,
    specialCharButtons: ['specialCharBack', '|'],
    specialCharactersSets: specialChars,
    imageUploadURL: `${apiDetails.baseUrl.trim()}/user/email/image`,
    requestHeaders: {
      Authorization: `Bearer ${window.sessionStorage.getItem('token') || oktaHelper.getAccessToken()}`,
    },
    imageMaxSize: 10 * 1024 * 1024,
    imageUploadParam: 'media',
    imageEditButtons: !isEmpty(imageEditButtons) ? imageEditButtons : [
      'imageReplace',
      'imageAlign',
      'imageRemove',
      '|',
      'linkRemove',
      '-',
      'imageDisplay',
      'imageAlt',
    ],
    placeholderText: placeholder || 'Type Something',
    inlineClasses: {
      'fr-class-highlighted': 'Highlighted',
      'fr-class-transparency': 'Transparent',
    },
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'subscript',
          'superscript',
          'textColor',
          'backgroundColor',
          'inlineClass',
          'inlineStyle',
          'clearFormatting',
          'fontFamily',
          'fontSize',
        ],
      },
      moreParagraph: {
        buttons: [
          'alignLeft',
          'alignCenter',
          'formatOLSimple',
          'alignRight',
          'alignJustify',
          'formatOL',
          'formatUL',
          'paragraphFormat',
          'paragraphStyle',
          'lineHeight',
          'outdent',
          'indent',
        ],
      },
      moreRich: {
        buttons: ['insertLink', 'insertImage', 'specialCharacters', 'insertHR'],
      },
      moreMisc: {
        buttons: ['undo', 'redo', 'fullscreen', 'html'],
        align: 'right',
        buttonsVisible: 2,
      },
    },
    pluginsEnabled: [
      'specialCharacters',
      'spell',
      'quote',
      'save',
      'quickInsert',
      'paragraphFormat',
      'paragraphStyle',
      'help',
      'draggable',
      'align',
      'link',
      'lists',
      'file',
      'image',
      'emoticons',
      'url',
      'embedly',
      'colors',
      'entities',
      'inlineClass',
      'inlineStyle',
      'imageTUI',
      'fontFamily',
      'fontSize',
      'lineHeight',
    ],
    charCounterCount: showCharCounter ? true : false,
    charCounterMax: maxCharLimit ? maxCharLimit : -1,
    heightMax: maxHeight ? maxHeight : '',
    heightMin: minHeight ? minHeight : '',
    events: {
      'image.uploaded': function (response) {
        // Do something here.
        // this is the editor instance.
        response = JSON.parse(response);
        let imageLink = getMediaUrl(
          get(response.result.data, 'media_urls'),
          '',
          'original'
        );
        this.image.insert(imageLink, false, null, this.image.get(), response);
        return false;
      },
      // 'commands.after': function (cmd, alignment) {
      //   if (cmd === 'imageAlign') {
      //     let img = this.image.get();
      //     img.attr('align', alignment === 'center' ? 'middle' : alignment);
      //     img.attr('hspace', 20);
      //     img.attr('vspace', 20);
      //   }
      // }
    },
    'froalaEditor.initialized' : function(e, editor) {
      if(disabled) {
        editor.edit.off();
      }
    },

  };
  return (
    <div
      style={{ width: '100%', fontFamily: 'Arial !important'}}
      id="fr-rte"
      className={isTitle ? 'fr-title-tag' : 'fr-normal'}
    >
      <FroalaEditor
        tag="textarea"
        model={content}
        onModelChange={setContent}
        config={config}
      />
    </div>
  );
};
