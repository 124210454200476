import React, { useRef } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Password } from '../';
import { ErrorBox } from './EditPassword.styles';
import { LoaderWrapper } from '../Loader';

const EditPassword = ({
  onBackClick,
  onSaveClick,
  errorSchema = {},
  pswrdErrorMsg,
  saveLoader
}) => {

  const ref = useRef(null);
  
  const onChangePwdSubmit = (values) => {
    onSaveClick(values);
  };

  // const validationCheck = (currentPassword, newPassword, reNewPassword)

  return (
    <Formik
      initialValues={{
        fAUserPasswordCh: '',
        fAUserPasswordChN: '',
        fAUserPasswordChNC: '',
      }}
      validationSchema={errorSchema}
      onSubmit={onChangePwdSubmit}
      innerRef={ref}
    >
      {({ handleChange, handleSubmit, errors, touched, values }) => (
        <Flex width={1} flexDirection="column">
          <Flex width={1} flexDirection="column">
            <Box width={1} mb="10px">
              <Password
                placeholder="Current Password"
                name="fAUserPasswordCh"
                id="fAUserPasswordCh"
                onChange={handleChange}
                isInputWithFloatingLabel={true}

              />
              {errors.fAUserPasswordCh && touched.fAUserPasswordCh ? (
                <ErrorBox>{errors.fAUserPasswordCh}</ErrorBox>
              ) : null}
            </Box>
            <Box width={1} mb="10px">
              <Password
                placeholder="New Password"
                name="fAUserPasswordChN"
                id="fAUserPasswordChN"
                onChange={handleChange}
                isInputWithFloatingLabel={true}
              />
              {errors.fAUserPasswordChN && touched.fAUserPasswordChN ? (
                <ErrorBox>{errors.fAUserPasswordChN}</ErrorBox>
              ) : null}
            </Box>
            <Box width={1} mb="10px">
              <Password
                placeholder="Confirm Password"
                name="fAUserPasswordChNC"
                id="fAUserPasswordChNC"
                onChange={handleChange}
                isInputWithFloatingLabel={true}
              />

              {errors.fAUserPasswordChNC && touched.fAUserPasswordChNC ? (
                <ErrorBox>{errors.fAUserPasswordChNC}</ErrorBox>
              ) : null}
            </Box>
          </Flex>
          <Flex width={1} justifyContent="center">
            <LoaderWrapper isLoading={saveLoader}>
              <Button onClick={onBackClick}>Back</Button>&nbsp;&nbsp;
              <Button 
                disabled={!(values.fAUserPasswordCh.length!==0 && values.fAUserPasswordChN.length!==0 && values.fAUserPasswordChN===values.fAUserPasswordChNC)} 
                onClick={handleSubmit}
              >
                  Save
                </Button>
            </LoaderWrapper>
          </Flex>
          <Flex
            padding={'10px'}
            justifyContent="center"
            alignItems={'center'}
            fontSize="13px"
          >
            {pswrdErrorMsg}
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};

export default EditPassword;
