import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Flex, Box } from '@rebass/grid';
import {
  get,
  keys,
  pickBy,
  isEmpty,
  map,
  each,
  find,
  remove,
  values,
  upperFirst,
} from 'lodash';
import './ShareContainerMyClients.css';
import _ from 'lodash';
import {
  Loader,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  MioSelect,
  Tabs,
} from '../';
import { OthersScheduler } from './Elements/Scheduler';
import {
  ShareModalDiv,
  ShareModalList,
  ShareModalListItem,
  CheckBoxForm,
  Container,
  CustomButton,
  ShareWithWrapper,
  ShareWithHeader,
  ShareWithBody,
  StyledShareWithHeader,
  CheckBoxWrapper,
  CheckBoxSpan2,
  CheckBoxLabel,
  CheckBox,
  StyledTooltip,
  ShareSummaryTab,
  ShareSummaryTabPanel,
  AutoPostCheckboxes,
  ShareSummaryTabList,
  StyledShareWithHeaderInside,
  StyledShareWithHeaderTitle,
} from './ShareContentContainer.styles';
import {
  getArticleSummary,
  getComplianceEmailData,
} from '../../services/dashboard';
import { actions as articleDetailsActions } from '../../redux/modules/article-detail.module';

import { MiniArticle } from '..';
import {
  USER_ROLE,
  ROLE_TYPES,
  getContactListForEmail,
  platforms,
  replaceSpanWithQuate,
  getQuotedText,
  themesData,
} from '../../utils';
import { getContactsGroups } from '../../services/configure';
import { actions as shareActions } from '../../redux/modules/share.module';
import { actions as newsletterActions } from '../../redux/modules/newsletter.module';
import { getChannelId, getUserRole, getRoleType } from '../../redux/selector';
import {
  getSummaryData,
  validator,
  getPlaceHolder,
  displayArticleSuccessMsgs,
  shareApiRequest,
} from './functions';
import FooterButtons from './Elements/FooterButton';
import { textBoxes } from './constants';
import ModalMessages from './Elements/ModalMessages';
import ComplianceReview from './Elements/ComplianceReview';
import SummaryFields from './Elements/SummaryFields';
import {
  CustomizeShareModal,
  MembersModal,
  RepostModal,
  SchedulerModal,
} from './Elements/Modals';
import { LoaderWrapper } from '../Loader';
import SummaryBox from './Elements/SummaryBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import SummaryTextFields from './NewElements/SummaryTextFields';
import { AdminScheduler } from './Elements/Scheduler';
import { ToggleButton } from '../../containers/ConfigureContainer/screenConfigure.styles';
import { Button } from '../Form';
import './ShareContentContainerV2.css';
import LeadCaptureOption from './Elements/LeadCaptureOption';
import { getEmailData } from '../../services/dashboard';

let timeOut = null;

const ShareContentContainer = ({
  hasFeedBack,
  summary,
  editable,
  header,
  imagesrc,
  title,
  time,
  contentLink,
  submitButtonText,
  handleSubmit,
  handleFeedback,
  handleClose,
  asModal,
  shareArticleRequest,
  shareData,
  shareError,
  isFromAddContent,
  showMio,
  socialNetworkData,
  isSharing,
  adminChannelId,
  history,
  userRole,
  showComplianceCheck,
  isFromCompliance,
  shareArticleSetState,
  shareArticleError,
  source,
  summaryDetails,
  summaryCheck,
  shareDetails,
  fetchedThemes,
  userData,
  membersList,
  isEnterpriseAdvisor,
  isPremiumAdvisor,
  isAssetAdvisor,
  disabledPlatforms,
  premiumAdvisorChannelId,
  pdfImage,
  isPdf,
  internalUse,
  fromMyClients,
  selectedDropdownOption,
  contactId,
  isFromEditorTab,
  pdfUrl,
  isFromScheduledTab,
  summaries,
  deleteArticleRequest,
  scheduledIds,
  scheduledDateTime,
  exploreArticleRequest,
  scheduleType,
  contactList,
  selectedItem,
  articleDetailRequest,
  approvedToShare,
  fromContentLibTab,
  complianceSetting,
  approvedArticles,
  positivlyContactsCount,
  positivlyName,
  isFromRecentTab,
  sponsoredFunds,
  selectedCRMContactList,
  isNewsLetter,
  complianceTab,
  newsLetter_id,
  articleData,
  isComplianceAdmin,
  engagementId,
  newsletterSetState,
  isAdminUser,
  isFromImageOnly,
  isCloning,
  article,
  mediaUrl,
  complianceCustomisedData,
  approvedCustomizedData,
  description,
  ...rest
}) => {
  const isComplianceOn = get(userData, 'compliance');
  const canAddMembers = get(
    userData,
    'details.user.member_settings.add_members'
  )
    ? get(userData, 'details.user.member_settings.add_members')
    : false;
  // console.log('SELECTED DROPDOWN OPTION - ', selectedDropdownOption);
  // CHECKBOX DATA
  const checkBoxObj = [
    { id: 'Twitter', platform: 'tw' },
    { id: 'LinkedIn', platform: 'ln' },
    { id: 'Facebook', platform: 'fb' },
    { id: 'Email', platform: 'em' },
  ];

  if (showMio) {
    checkBoxObj.push({ id: 'MIO' });
  }

  const [isAutoPostChecked, setAutoPostIsChecked] = useState(true);
  const [selectedLeadCaptureOption, setSelectedLeadCaptureOption] =
    useState(false);
  const [selectedReferralCaptureOption, setSelectedReferralCaptureOption] =
    useState(false);
  const [isChecked, setIsChecked] = useState({
    Twitter: false,
    LinkedIn: false,
    Email: false,
    Facebook: false,
    All: false,
  });
  const [isAdvisorChecked, setIsAdvisorChecked] = useState({
    Twitter: false,
    LinkedIn: false,
    Email: false,
    All: false,
  });

  const getBottomText = (platform, emailPlaftform) => {
    let platformDetails = get(socialNetworkData, platform, []),
      disconnected,
      complianceCheck;
    if (emailPlaftform) {
      platformDetails = find(platformDetails, {
        snetworkProvider: emailPlaftform,
      });
    }
    let text = isEmpty(platformDetails) ? 'Connect' : 'Disconnect';
    if (isEmpty(platform)) text = 'Disconnect';
    if (
      isEnterpriseAdvisor &&
      userData.details.user.member_settings &&
      userData.details.user.member_settings.disabled_social_platforms &&
      userData.details.user.member_settings.disabled_social_platforms.includes(
        platform
      )
    ) {
      disconnected = true;
    } else {
      disconnected = text === 'Connect';
    }
    if (isFromScheduledTab) {
      complianceCheck =
        selectedItem.is_complianced || !selectedItem.edit_summary;
      disconnected = complianceCheck ? complianceCheck : disconnected;
    }
    if ((isFromImageOnly || source === 'img') && platform == 'em') {
      console.log('[PLATFORM DETAILS] - ', platformDetails);
      disconnected = true;
    }
    return {
      bottomText: text,
      isDiconnected: disconnected,
    };
  };

  const getSummaryPlaceHolder = (platform, placeholerText) => {
    // let summaryText = 'Add a summary for ';
    let summaryText = 'Add share copy for ';
    switch (platform) {
      case 'fb':
        summaryText = summaryText + 'Facebook';
        break;
      case 'ln':
        summaryText = summaryText + 'LinkedIn';
        break;
      case 'tw':
        summaryText = summaryText + 'Twitter';
        break;
      case 'em':
        summaryText = summaryText + 'Email';
        break;
      default:
        break;
    }
    return summaryText;
    // return 'Share copy';
  };

  const [summaryData, setSummaryData] = useState({
    twSummary: '',
    lnSummary: '',
    emSummary: '',
    fbSummary: '',
    itSummary: '',
    twSummaryLength: 0,
    lnSummaryLength: 0,
    emSummaryLength: 0,
    fbSummaryLength: 0,
    itSummaryLength: 0,
  });

  const [mediumSummary, setMediumSummary] = useState('');

  const [platformObj, setPlatformObj] = useState({
    ln: 'LinkedIn',
    tw: 'Twitter',
    fb: 'Facebook',
    em: 'Email',
    LinkedIn: 'ln',
    Twitter: 'tw',
    Facebook: 'fb',
    Email: 'em',
    connected: [],
    nonConnected: [],
  });

  const didMountRef = useRef(null);
  const [NoFeedback, setNoFeedback] = useState(false);
  const [isFetchingSummary, setIsFetchingSummary] = useState(false);
  const [placeholerText, setPlaceholerText] = useState('');
  const [isDateOpen, setDateOpen] = useState(false);
  const [isAdvisorOpen, setIsAdvisorOpen] = useState(false);
  const [shareModalMsgs, setShareModalMsgs] = useState([]);
  const [warningMsg, setWarningMsg] = useState([]);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [advisorScheduleTime, setAdvisorScheduleTime] = useState(null);
  const [contactGroups, setContactGroups] = useState([]);
  const [selectedContactGroup, setSelectedContactGroup] = useState('');
  const [isCreatingContent, setIsCreatingContent] = useState(false);
  const [selectedComplianceCheck, setSelectedComplianceCheck] = useState('no');
  const [enableShare, setEnableShare] = useState(false);
  const [allSumarries, setAllSummaries] = useState(false);
  const [scheduleState, setScheduleState] = useState({
    clicked: false,
    scheduleIsOpen: false,
  });
  const [selectedFbPage, setSelectedFbPage] = useState('');
  const [selectedLnPage, setSelectedLnPage] = useState('');
  const [isAdvisorModalOpen, setIsAdvisorModalOpen] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(
    map(membersList, 'id')
  );
  const [emailTextData, setEmailTextData] = useState({
    subject: '',
    message: '',
    salutation: '',
    salutation_text: '',
  });
  const [isCustomizeModalOpen, setIsCustomizeModalOpen] = useState(false);
  const [openRepostModal, setRepostModal] = useState(false);
  const [repostOptions, setRepostOptions] = useState([]);
  const [summaryTabIndex, setSummaryTabIndex] = useState(0);
  const [isMyChecked, setIsMyChecked] = useState(false);
  const [isMemberChecked, setIsMemberChecked] = useState(false);
  const connectedEmailList = get(socialNetworkData, 'em', []);
  const hasMultipleEmail = connectedEmailList.length > 1;
  const [showCustomizeEmailTooltip, setCustomizeEmailTooltip] = useState(false);
  const [shareLoader, toggleShareLoader] = useState(false);
  const [referralMessage, setReferralMessage] = useState('');
  const [isOGArticleComplianced, setOGArticleComlianced] = useState(false);
  const isAdmin = userRole === 'admin';
  // console.log('REST = = = = =', rest);
  // WILL BE CALLED ONLY ONCE

  const [isContentChanged, setIsContentChanged] = useState(false);
  // const [isMessageChanged, setIsMessageChanged] = useState(false);
  // const [isSalutationChanged, setIsSalutationChanged] = useState(false);
  // const [isSalutationTextChanged, setIsSalutationTextChanged] = useState(false);

  const [emailShareData, setEmailShareData] = useState({
    subject: 'Generating subject...',
    message: '',
    salutation: 'Hi',
    salutation_text: '',
    placeholderText: 'Generating message...',
  });

  useEffect(() => {
    // console.log('useEffect Fired sHARECONTENTCONTAINER - ');
    window.clearTimeout(timeOut);
    // console.log('[SHARE ARTICLE ERROR] - ', shareArticleError);
    let autoPostChecked = rest.isFromAdmin ? false : true;
    setAutoPostIsChecked(autoPostChecked);
    localStorage.removeItem('emailData');

    !isFromCompliance &&
      !isFromScheduledTab &&
      !isFromRecentTab &&
      isPremiumAdvisor &&
      !isEmpty(socialNetworkData.fb) &&
      socialNetworkData.fb[0].page &&
      setSelectedFbPage(0);
    !isFromCompliance &&
      !isFromScheduledTab &&
      !isFromRecentTab &&
      !isEmpty(socialNetworkData.ln) &&
      socialNetworkData.ln[0].page &&
      setSelectedLnPage(0);
    getSummaryData(
      rest,
      setPlaceholerText,
      setIsFetchingSummary,
      getContactsGroups,
      setContactGroups,
      getContactListForEmail,
      shareDetails,
      getArticleSummary,
      setAllSummaries,
      setSummaryData,
      summaryData,
      isFromScheduledTab,
      summaries,
      selectedItem,
      isFromRecentTab,
      setMediumSummary,
      sponsoredFunds,
      adminChannelId,
      fetchedThemes,
      isFromAddContent
    );

    if (isFromScheduledTab) {
      const { destination } = selectedItem;
      if (destination.network) {
        switch (destination.network) {
          case 'tw':
            setSummaryTabIndex(0);
            break;
          case 'fb':
            setSummaryTabIndex(2);
            break;
          case 'ln':
            setSummaryTabIndex(1);
            break;
          case 'em':
            console.log('CASE 3: ----');
            if (
              selectedItem.destination.ids &&
              selectedItem.destination.ids[0].name &&
              selectedItem.destination.ids[0].name.includes('positivly_')
            )
              setSummaryTabIndex(0);
            else setSummaryTabIndex(3);
            break;
          default:
            setSummaryTabIndex(0);
            break;
        }
      }
      setIsChecked({
        ...isChecked,
        [platforms[destination.network]]: true,
      });
      if (destination.lead_capture && destination.lead_capture === 'true') {
        setSelectedLeadCaptureOption(true);
      } else if (destination.referral && destination.referral === 'true') {
        setSelectedReferralCaptureOption(true);
      }
      if (
        destination.network === 'em' &&
        destination.ids[0].type === 'contactlist'
      ) {
        console.log('INSIDE destination.network === em');
        if (destination.ids[0].id && destination.ids[0].name) {
          setSelectedContactGroup(destination.ids[0].id);
        } else {
          setSelectedContactGroup('');
        }
        let customizeData = get(selectedItem, 'email_customization', {});
        if (!isEmpty(customizeData)) {
          localStorage.setItem('emailData', JSON.stringify(customizeData));
        }
        setEmailTextData({ ...emailTextData, ...customizeData });
      }

      if (destination.network === 'fb') {
        setSelectedFbPage(destination.ids[0].id);
      } else if (isEnterpriseAdvisor && isPremiumAdvisor) {
        setSelectedFbPage(0);
      } else if (
        !isEmpty(socialNetworkData.fb) &&
        socialNetworkData.fb[0].page
      ) {
        each(socialNetworkData.fb[0].page, (item, key) => {
          setSelectedFbPage(key);
        });
      }

      if (destination.network === 'ln') {
        setSelectedLnPage(destination.ids[0].id);
      } else {
        setSelectedLnPage(0);
      }

      setScheduleTime(selectedItem.date.scheduled);
      // if (
      //   new Date().getTime() < new Date(selectedItem.date.scheduled).getTime()
      // ) {
      //   setScheduleTime(selectedItem.date.scheduled);
      // } else {
      //   let now = new Date();
      //   now.setMinutes(now.getMinutes() + 30);
      //   now = `${now.getFullYear()}-${_.padStart(
      //     now.getMonth() + 1,
      //     2,
      //     0
      //   )}-${_.padStart(
      //     now.getDate(),
      //     2,
      //     0
      //   )}T${now.getHours()}:${now.getMinutes()}:00`;
      //   console.log(now);
      //   setScheduleTime(now);
      // }
    }

    if (isFromRecentTab) {
      console.log('SELECTED ITEM - - - ', selectedItem);
      const { destination } = selectedItem;
      if (destination.network === 'em') {
        let customizeData = get(selectedItem, 'email_customization', {});
        if (!isEmpty(customizeData)) {
          localStorage.setItem('emailData', JSON.stringify(customizeData));
          setEmailTextData({ ...emailTextData, ...customizeData });
        }
      }
      if (isCloning && article.sharedData && article.sharedData[0]) {
        // console.log('ART');
        const isCheckedCopy = { ...isChecked };
        const summaryDataCopy = { ...summaryData };
        const isCompliancedArray =
          article.auxiliary &&
          article.auxiliary.shared &&
          article.auxiliary.shared.details
            ? article.auxiliary.shared.details
            : [];
        const isNotComplianced = isCompliancedArray.every(
          (item) => item && !item.is_complianced
        );
        setOGArticleComlianced(!isNotComplianced);
        setIsMyChecked(true);
        article.sharedData.forEach((item) => {
          const summaryKey = `${item.destination.network}Summary`;
          summaryDataCopy[summaryKey] = item.text;
          isCheckedCopy[platformObj[item.destination.network]] = true;
          if (
            item.destination.network === 'em' &&
            item.destination.ids &&
            item.destination.ids[0] &&
            item.destination.ids[0].id
          )
            setSelectedContactGroup(item.destination.ids[0].id);
          else if (
            item.destination.network === 'fb' &&
            item.destination.ids &&
            item.destination.ids[0] &&
            item.destination.ids[0].id
          )
            setSelectedFbPage(item.destination.ids[0].id);
          else if (
            item.destination.network === 'ln' &&
            item.destination.ids &&
            item.destination.ids[0] &&
            item.destination.ids[0].id
          )
            setSelectedLnPage(item.destination.ids[0].id);
        });
        const allChecked = Object.keys(isCheckedCopy)
          .filter((ele) => ele !== 'All')
          .every((key) => isCheckedCopy[key]);
        if (allChecked) isCheckedCopy['All'] = true;
        setIsChecked(isCheckedCopy);
        setSummaryData(summaryDataCopy);
      }
    }

    if (isFromCompliance && !isEmpty(engagementId)) {
      getComplianceEmailData(engagementId).then((res) => {
        console.log('res', res);
        if (res) {
          setEmailTextData({
            subject: res.data.subject,
            message: res.data.message,
            salutation: res.data.salutation,
          });
        }
      });
    }

    if (!isAdmin || isFromScheduledTab) {
      setAutoPostIsChecked(false);
    }

    if (approvedArticles) {
      let customizeData = {
        salutation: approvedCustomizedData && approvedCustomizedData.salutation,
        salutation_text:
          approvedCustomizedData && approvedCustomizedData.salutation_text,
        placeholderText:
          approvedCustomizedData && approvedCustomizedData.placeholderText,
        subject: approvedCustomizedData && approvedCustomizedData.subject,
        message: approvedCustomizedData && approvedCustomizedData.message,
      };

      localStorage.setItem('emailData', JSON.stringify(customizeData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !isFromCompliance &&
      !isFromScheduledTab &&
      !isFromRecentTab &&
      !isPremiumAdvisor &&
      !isEnterpriseAdvisor &&
      !isEmpty(socialNetworkData.fb) &&
      socialNetworkData.fb[0].page &&
      each(socialNetworkData.fb[0].page, (item, key) => {
        setSelectedFbPage(key);
      });
    !isFromCompliance &&
      !isFromScheduledTab &&
      !isFromRecentTab &&
      !isEmpty(socialNetworkData.ln) &&
      socialNetworkData.ln[0].page &&
      setSelectedLnPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialNetworkData]);

  useEffect(() => {
    console.log('SOURCE - -- - ', source);
    validator(
      summaryData,
      isAdvisorChecked,
      setShareModalMsgs,
      isChecked,
      selectedContactGroup,
      setEnableShare,
      platformObj,
      warningMsg,
      setWarningMsg,
      isFromScheduledTab,
      scheduleTime,
      selectedFbPage,
      setScheduleState,
      isFetchingSummary,
      isAdmin,
      canAddMembers,
      sponsoredFunds,
      source
    );
    if (fromMyClients && summaryData.emSummary.length > 0) {
      setEnableShare(true);
    }
    if (
      (isEnterpriseAdvisor || isAssetAdvisor) &&
      isChecked.Facebook === true &&
      !approvedArticles &&
      (selectedFbPage === 0 ||
        isEmpty(selectedFbPage) ||
        selectedFbPage === '0')
    ) {
      if (selectedComplianceCheck === 'yes' || userData.compliance === 'on') {
        setShareModalMsgs([
          'Cannot Post on Facebook News Feed with Compliance On',
        ]);
      }
    }

    if (selectedReferralCaptureOption) {
      if (!isChecked.Email) {
        setReferralMessage('Referral capture is available for email only');
      } else if (
        isChecked.Email &&
        (isChecked.Facebook || isChecked.LinkedIn || isChecked.Twitter)
      ) {
        setReferralMessage('Referral capture is available for email only');
      } else if (
        isChecked.Email &&
        !isChecked.Facebook &&
        !isChecked.LinkedIn &&
        !isChecked.Twitter
      ) {
        setReferralMessage('');
      }
    } else if (!selectedReferralCaptureOption) {
      setReferralMessage('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isChecked,
    isAdvisorChecked,
    summaryData,
    isAutoPostChecked,
    selectedContactGroup,
    scheduleTime,
    selectedComplianceCheck,
    selectedFbPage,
    selectedLeadCaptureOption,
    selectedReferralCaptureOption,
  ]);

  // WORKS WHEN USERS CLICKS ON SHARE AND DISPLAY MESSAGE BASED ON RESPONSE AND ALSO TRIGGERS SCHEDULE MODAL IF SUCCESSFUL
  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      if (!isSharing) {
        console.log('INSIDE ELSE - IF ....');
        console.log('SHARE MODAL MESSAGES - ', shareModalMsgs);
        displayArticleSuccessMsgs(
          shareError,
          platformObj,
          shareData,
          isComplianceOn,
          showComplianceCheck,
          selectedComplianceCheck,
          isAdmin,
          selectedFbPage,
          setIsCreatingContent,
          scheduleState,
          setScheduleState,
          setShareModalMsgs,
          closeModal,
          isFromScheduledTab,
          isEnterpriseAdvisor,
          approvedArticles,
          isCloning,
          isOGArticleComplianced,
          isContentChanged
        );
      }
    }

    if (
      !isSharing &&
      !isEmpty(shareError) &&
      shareError.filter((s) => s.type === 'SocialShareException').length > 0
    ) {
      let options = map(
        shareError.filter((s) => s.type === 'SocialShareException'),
        'platform'
      );
      setRepostOptions(options);
      setRepostModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareData, shareError, isSharing]);

  const showLeadCaptureOption =
    userData &&
    userData.details &&
    userData.details.user &&
    userData.details.user.enable_lead_capture === 'true' &&
    ((rest && rest.link && rest.link.includes('article_link')) ||
      isFromEditorTab ||
      (isFromAddContent && source === 'pdf'));
  const toggleScheduleModalOnConditions = () => {
    if (
      (values(isChecked).includes(true) && scheduleTime) ||
      (values(isAdvisorChecked).includes(true) && advisorScheduleTime) ||
      !isAdmin
    ) {
      setScheduleState({ ...scheduleState, clicked: true });
    } else {
      setScheduleState({ ...scheduleState, clicked: false });
    }
  };

  const closeModal = () => {
    let selectedPlatforms = keys(pickBy(isChecked)).length;
    if (values(isAdvisorChecked).includes(true)) selectedPlatforms += 1;
    const successfullPlatforms = keys(pickBy(shareData));
    if (
      shareError.length === 0 &&
      platformObj.nonConnected.length === 0 &&
      selectedPlatforms === successfullPlatforms.length &&
      !isChecked.Email &&
      !isFromScheduledTab
    ) {
      timeOut = window.setTimeout(() => {
        onModalClose();
      }, 2000);
    } else if (
      shareError.length === 0 &&
      platformObj.nonConnected.length === 0 &&
      isFromScheduledTab
    ) {
      articleDetailRequest({ id: rest.id });
      timeOut = window.setTimeout(() => {
        onModalClose();
      }, 2000);
    } else if (fromMyClients && shareError.length === 0) {
      timeOut = window.setTimeout(() => {
        onModalClose();
      }, 2000);
    }
  };

  // CLOSES MODAL AND RESETS SHARE DATA
  const onModalClose = () => {
    shareArticleSetState({ data: {}, error: [] });
    setAdvisorScheduleTime(null);
    setScheduleTime(null);
    localStorage.removeItem('emailData');
    if (scheduleState.clicked) {
      setScheduleState({ clicked: false, scheduleIsOpen: false });
    }
    setShareModalMsgs([]);
    // shareArticleError('');
    handleClose();
  };

  const onShareClick = (dateTime, isRepost = false) => {
    console.log('CRM CONTACT - ', selectedCRMContactList);
    shareArticleSetState({ data: {}, error: [] });
    let reqObj = [];
    let advisorReqObj = [];
    let reqData = {};
    if (fromMyClients) toggleShareLoader(true);
    const myConnectionKeys = _.keys(_.pickBy(isChecked)).filter(
      (k) => k !== 'All'
    );
    if (isEmpty(repostOptions)) {
      if (fromMyClients) {
        reqObj.push('em');
        // console.log(reqObj)
      } else
        myConnectionKeys.forEach((platform) =>
          reqObj.push(platformObj[platform])
        );
    } else {
      reqObj = repostOptions;
    }

    each(isAdvisorChecked, (option, key) => {
      if (isAdvisorChecked[key] && key !== 'All') {
        advisorReqObj.push(platformObj[key]);
      } else if (key === 'All' && isAdvisorChecked[key]) {
        advisorReqObj = ['tw', 'ln', 'em'];
        return;
      }
    });
    if (values(isAdvisorChecked).includes(true)) {
      setIsCreatingContent(true);
    }
    shareApiRequest({
      enableShare,
      isAdvisorChecked,
      reqData,
      reqObj,
      socialNetworkData,
      setPlatformObj,
      platformObj,
      isAdmin,
      dateTime,
      advisorReqObj,
      summaryData,
      rest,
      adminChannelId,
      fetchedThemes,
      selectedContactGroup,
      scheduleTime,
      userData,
      selectedComplianceCheck,
      hasMultipleEmail,
      selectedFbPage,
      isPremiumAdvisor,
      emailTextData,
      isAutoPostChecked,
      selectedMembers,
      shareArticleRequest,
      disabledPlatforms,
      premiumAdvisorChannelId,
      isFromAddContent,
      showComplianceCheck,
      fromMyClients,
      contactId,
      selectedDropdownOption,
      selectedCRMContactList,
      selectedLnPage,
      deleteArticleRequest,
      scheduledIds,
      isFromScheduledTab,
      selectedItem,
      approvedArticles,
      isRepost,
      allSumarries,
      selectedLeadCaptureOption,
      showLeadCaptureOption,
      selectedReferralCaptureOption,
      isContentChanged,
      fromContentLibTab,
      isAdminUser,
      isCloning,
      article,
      isOGArticleComplianced,
      isFromImageOnly,
      description,
    });
  };

  const onScheduleClick = (dateTime, type) => {
    console.log(type);
    console.log('[DATE TIME] - ', dateTime);
    setIsAdvisorOpen(false);
    setDateOpen(false);
    setScheduleState({ ...scheduleState, clicked: true });
    if (type === 'advisor') {
      console.log('advisor scheduleClick');
      setAdvisorScheduleTime(dateTime);
    } else setScheduleTime(dateTime);
    // if (!isAdmin && validateOnSchedule(isChecked, setShareModalMsgs, showComplianceCheck, selectedComplianceCheck, shareModalMsgs)) {
    //   onShareClick(dateTime);
    // }
  };

  const shareArticle = (isRepost = false) => {
    if (isChecked.Email) {
      if (selectedContactGroup) {
        toggleScheduleModalOnConditions();
      } else {
        setScheduleState({ ...scheduleState, clicked: false });
      }
    } else if (!isChecked.Email) {
      toggleScheduleModalOnConditions();
    }
    onShareClick({ scheduleTime, advisorScheduleTime }, isRepost);
  };

  const onClientShareClick = (e, isRepost = false) => {
    onShareClick(null, isRepost);
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    if (value === 'create') {
      if (isAdmin) {
        history.push('/admin/configure?to=email');
      } else {
        history.push('/configure?to=email');
      }
    } else {
      setSelectedContactGroup(value);
    }
  };

  // console.log(enableShare);
  // console.log(shareModalMsgs);
  const clearSchedule = (type) => {
    console.log('Indside clear schedule');
    if (type === 'my') {
      setScheduleTime(null);
    } else {
      setAdvisorScheduleTime(null);
    }

    setScheduleState({ clicked: false, scheduleIsOpen: false });
    setShareModalMsgs([]);
  };

  const onMyClientButtonClick = (e, type = '') => {
    e.preventDefault();
    setDateOpen(true);
  };

  const toggleAdvisorModal = () =>
    setIsAdvisorModalOpen(isAutoPostChecked ? !isAdvisorModalOpen : false);

  const toggleCustomizeModal = (e) => {
    e.preventDefault();
    if (fromMyClients) {
      setIsCustomizeModalOpen(!isCustomizeModalOpen);
    } else if (
      isChecked.Email ||
      isAdvisorChecked.Email ||
      isAdvisorChecked.All
    ) {
      setIsCustomizeModalOpen(!isCustomizeModalOpen);
    } else if (isFromRecentTab) {
      setIsCustomizeModalOpen(!isCustomizeModalOpen);
    }
  };

  const getAdvisorStatus = (platform) => {
    let disconnected;
    if (
      userData.details.user.member_settings &&
      userData.details.user.member_settings.disabled_social_platforms &&
      userData.details.user.member_settings.disabled_social_platforms.includes(
        platform
      )
    ) {
      disconnected = true;
    }
    if (
      !isAutoPostChecked &&
      values(isAdvisorChecked).filter((c) => c).length !== 0
    ) {
      disconnected = true;
    }
    if (platform === 'em' && (isFromImageOnly || source === 'img'))
      disconnected = true;
    return disconnected;
  };

  const channels =
    internalUse && internalUse === 'true'
      ? ['it']
      : rest.channel && !rest.channel.includes('mio')
      ? rest.channel
      : ['tw', 'fb', 'ln', 'em'];
  const complianceCheckBox = [];
  channels.forEach((platform) => {
    complianceCheckBox.push({ id: platformObj[platform], platform });
    if (rest.isFromAdmin) {
      if (channels.length > 1) {
        isChecked.MIO = true;
      } else {
        isChecked[platformObj[platform]] = true;
        isChecked.MIO = false;
      }
      return;
    }
  });

  const checkBoxVals = rest.isFromAdmin
    ? complianceCheckBox.length > 1
      ? [{ id: 'MIO', platform: 'mio' }]
      : complianceCheckBox
    : checkBoxObj;
  let adminConnections = {
    myConnections: [...checkBoxVals],
    advisorConnections: [...checkBoxObj],
  };

  if (isAdmin) {
    remove(adminConnections.advisorConnections, { id: 'MIO' });
    remove(adminConnections.advisorConnections, { id: 'Facebook' });
    remove(adminConnections.myConnections, { id: 'MIO' });
  }

  const adminShareOptions = [
    {
      title: 'My Connections',
      id: 'my',
      connections: adminConnections.myConnections,
      isVisible: true,
      scheduleValue: scheduleTime,
    },
    {
      title: 'Member Connections',
      id: 'advisor',
      connections: adminConnections.advisorConnections,
      isVisible: canAddMembers === 'true' && !isFromScheduledTab,
      scheduleValue: advisorScheduleTime,
    },
  ];

  const getPositivlyName = (name) => {
    let listName = name.split('_');
    return upperFirst(listName[1]);
  };

  const handleCheckChange = (
    id,
    platform = null,
    connectionType = null,
    e = null
  ) => {
    let disabledPlatformsCheck =
      userData &&
      userData.details &&
      userData.details.user &&
      userData.details.user.member_settings &&
      userData.details.user.member_settings.disabled_social_platforms &&
      !isEmpty(userData.details.user.member_settings.disabled_social_platforms);
    if (connectionType === 'advisor') {
      if (
        values(isAdvisorChecked).filter((c) => c).length === 1 &&
        isAdvisorChecked[id]
      ) {
        setAutoPostIsChecked(false);
        setIsAdvisorChecked({
          ...isAdvisorChecked,
          Email:
            (disabledPlatformsCheck &&
              userData.details.user.member_settings.disabled_social_platforms.includes(
                'em'
              )) ||
            isFromImageOnly ||
            source === 'img'
              ? false
              : true,
          Twitter:
            disabledPlatformsCheck &&
            userData.details.user.member_settings.disabled_social_platforms.includes(
              'tw'
            )
              ? false
              : true,
          LinkedIn:
            disabledPlatformsCheck &&
            userData.details.user.member_settings.disabled_social_platforms.includes(
              'ln'
            )
              ? false
              : true,
        });
      } else if (values(isAdvisorChecked).filter((c) => c).length === 0) {
        setAutoPostIsChecked(true);
        setIsAdvisorChecked({
          ...isAdvisorChecked,
          [id]: !isAdvisorChecked[id],
        });
      } else {
        if (isAdvisorChecked[id] && isAdvisorChecked.All) {
          setIsAdvisorChecked({
            ...isAdvisorChecked,
            [id]: !isAdvisorChecked[id],
            All: false,
          });
        } else {
          setIsAdvisorChecked({
            ...isAdvisorChecked,
            [id]: !isAdvisorChecked[id],
          });
        }
        console.log('SETTING isAdvisorChecked...');
      }
    } else {
      if (id === 'All' && !isChecked.All) {
        setIsChecked({
          ...isChecked,
          All: true,
          Facebook: isEmpty(get(socialNetworkData, 'fb', [])) ? false : true,
          Twitter: isEmpty(get(socialNetworkData, 'tw', [])) ? false : true,
          LinkedIn: isEmpty(get(socialNetworkData, 'ln', [])) ? false : true,
          Email:
            isFromImageOnly ||
            isEmpty(get(socialNetworkData, 'em', [])) ||
            source === 'img'
              ? false
              : true,
        });
      } else if (id === 'All' && isChecked.All) {
        setScheduleTime(null);
        setIsChecked({
          ...isChecked,
          Twitter: false,
          LinkedIn: false,
          Email: false,
          Facebook: false,
          All: false,
        });
      } else {
        if (isChecked[id] && isChecked.All) {
          setIsChecked({ ...isChecked, [id]: !isChecked[id], All: false });
        } else if (
          values(isChecked).filter((c) => c).length === 3 &&
          !isChecked[id] &&
          !isChecked.All
        ) {
          setIsChecked({ ...isChecked, [id]: !isChecked[id], All: true });
        } else if (
          values(isChecked).filter((c) => c).length === 1 &&
          isChecked[id]
        ) {
          setIsChecked({ ...isChecked, [id]: !isChecked[id] });
          setScheduleTime(null);
        } else {
          setIsChecked({ ...isChecked, [id]: !isChecked[id] });
        }
      }
      // console.log('isChecked SET...', isChecked);
    }
  };

  const onTabSelect = (index) => {
    setSummaryTabIndex(index);
  };

  const onClockButtonClick = (e, type = '') => {
    // console.log('[SELECTED FB PAGE] - ', selectedFbPage);
    // console.log('[SOCIAL NETWORK DATA] - ', socialNetworkData);
    // console.log()
    e.preventDefault();
    if (!isAdmin && isChecked.Facebook) {
      // setIsChecked({ ...isChecked, Facebook: false });
      if (selectedFbPage === 0) {
        console.log('[SELECTED FB PAGE === 0]');
        setShareModalMsgs([
          'You can not schedule Facebook post on your news feed.',
        ]);
        setDateOpen(false);
      } else {
        console.log('[SELECTED FB PAGE]', selectedFbPage);
        setShareModalMsgs([]);
        setDateOpen(true);
      }
      // setTimeout(() => {
      //   setWarningMsg([]);
      // }, 3000);
    } else {
      if (type === 'advisor') setIsAdvisorOpen(true);
      else setDateOpen(true);
    }
  };

  const autoPostChangeHandler = (checked) => {
    let disabledPlatformsCheck =
      userData &&
      userData.details &&
      userData.details.user &&
      userData.details.user.member_settings &&
      userData.details.user.member_settings.disabled_social_platforms &&
      !isEmpty(userData.details.user.member_settings.disabled_social_platforms);
    if (!checked) {
      setAdvisorScheduleTime(null);
      setIsAdvisorChecked({
        ...isAdvisorChecked,
        Email:
          disabledPlatformsCheck &&
          userData.details.user.member_settings.disabled_social_platforms.includes(
            'em'
          )
            ? false
            : true,
        Twitter:
          disabledPlatformsCheck &&
          userData.details.user.member_settings.disabled_social_platforms.includes(
            'tw'
          )
            ? false
            : true,
        LinkedIn:
          disabledPlatformsCheck &&
          userData.details.user.member_settings.disabled_social_platforms.includes(
            'ln'
          )
            ? false
            : true,
      });
    }
    setAutoPostIsChecked(checked);
  };

  const handleMyConnectionsChange = () => {
    if (!isMyChecked) {
      setIsChecked({
        ...isChecked,
        All: true,
        Facebook: isEmpty(get(socialNetworkData, 'fb', [])) ? false : true,
        Twitter: isEmpty(get(socialNetworkData, 'tw', [])) ? false : true,
        LinkedIn: isEmpty(get(socialNetworkData, 'ln', [])) ? false : true,
        Email:
          isFromImageOnly ||
          source === 'img' ||
          isEmpty(get(socialNetworkData, 'em', []))
            ? false
            : true,
      });
    } else {
      setIsChecked({
        ...isChecked,
        All: false,
        Facebook: false,
        Twitter: false,
        LinkedIn: false,
        Email: false,
      });
    }
    setIsMyChecked(!isMyChecked);
  };

  const handleMemberConnectionsChange = () => {
    let disabledPlatformsCheck =
      userData &&
      userData.details &&
      userData.details.user &&
      userData.details.user.member_settings &&
      userData.details.user.member_settings.disabled_social_platforms &&
      !isEmpty(userData.details.user.member_settings.disabled_social_platforms);
    if (!isMemberChecked) {
      setAutoPostIsChecked(true);
      setIsAdvisorChecked({
        ...isAdvisorChecked,
        Twitter:
          disabledPlatformsCheck &&
          userData.details.user.member_settings.disabled_social_platforms.includes(
            'tw'
          )
            ? false
            : true,
        LinkedIn:
          disabledPlatformsCheck &&
          userData.details.user.member_settings.disabled_social_platforms.includes(
            'ln'
          )
            ? false
            : true,
        Email:
          (disabledPlatformsCheck &&
            userData.details.user.member_settings.disabled_social_platforms.includes(
              'em'
            )) ||
          isFromImageOnly ||
          source === 'img'
            ? false
            : true,
        All: false,
      });
    } else {
      setAutoPostIsChecked(false);
      setIsAdvisorChecked({
        ...isAdvisorChecked,
        Twitter: false,
        LinkedIn: false,
        Email: false,
        All: false,
      });
    }
    setIsMemberChecked(!isMemberChecked);
  };

  const bodycontent =
    !isFromCompliance &&
    !fromMyClients &&
    (!isFromRecentTab || isCloning) &&
    (!isFromScheduledTab ||
      (isFromScheduledTab &&
        (selectedItem.destination.network !== 'em' ||
          (selectedItem.destination.network === 'em' &&
            selectedItem.destination.ids &&
            !selectedItem.destination.ids[0].name) ||
          (selectedItem.destination.network === 'em' &&
            selectedItem.destination.ids &&
            selectedItem.destination.ids[0].name &&
            !selectedItem.destination.ids[0].name.includes('positivly_'))))) ? (
      <>
        <ShareModalDiv>
          <ShareModalList screenWidth={window.innerWidth}>
            {/* {console.log('here 2', {source, title, time, imagesrc, contentLink, rest})} */}
            <ShareModalListItem
              screenWidth={window.innerWidth}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <MiniArticle
                source={source}
                topic={title}
                created={time}
                image={imagesrc}
                link={contentLink}
                pdfImage={pdfImage}
                isPdf={isPdf}
                isFromEditorTab={isFromEditorTab}
                pdfUrl={pdfUrl}
                mediaUrl={mediaUrl}
                isFromImageOnly={isFromImageOnly}
                {...rest}
              />
              {/* {!isFromAddContent && !isFromScheduledTab && ( */}
              {!isFromScheduledTab && (
                <>
                  {/* <SnippetBox /> */}
                  {console.log(
                    '******** FROM ADD CONTENT *******',
                    description
                  )}
                  {/* {(mediumSummary && mediumSummary.length > 0 || (description && description.length > 0)) ? ( */}
                  <SummaryBox
                    summary={description}
                    isFetchingSummary={isFetchingSummary}
                    isAdmin={isAdmin}
                  />
                  {/* ) : null} */}
                </>
              )}
            </ShareModalListItem>

            <ShareModalListItem
              style={{
                border: '1px solid #cacaca',
                padding: '10px 10px',
                borderRadius: '10px',
              }}
            >
              <Flex width={1} justifyContent="space-between">
                <Flex width="50%">
                  <Box width={1} style={{ marginRight: '13px' }}>
                    {/* <Flex
                      justifyContent="flex-end"
                      width={1}
                      alignItems="center"
                      pl="20px"
                    >
                      <MioRadioSpan noBorder width="100%">
                        <MioRadio
                          id={'settingsOne'}
                          name="settings"
                          onClick={() => setShareSettings('last-used')}
                          defaultChecked={shareSettings === 'last-used'}
                        />
                        <label htmlFor="settingsOne">Last Used</label>
                      </MioRadioSpan>
                      <MioRadioSpan
                        noBorder
                        defaultChecked={selectedComplianceCheck === 'custom'}
                        width="100%"
                      >
                        <MioRadio
                          id={'settingsTwo'}
                          name="settings"
                          onClick={() => setShareSettings('custom')}
                          defaultChecked={shareSettings === 'custom'}
                        />
                        <label htmlFor="settingsTwo">Custom</label>
                      </MioRadioSpan>
                    </Flex> */}
                  </Box>
                </Flex>
              </Flex>

              <ShareWithWrapper>
                <ShareWithHeader>
                  <StyledShareWithHeader></StyledShareWithHeader>
                  <StyledShareWithHeaderInside></StyledShareWithHeaderInside>
                  <StyledShareWithHeaderTitle isAdmin={isAdmin}>
                    Share With
                  </StyledShareWithHeaderTitle>
                </ShareWithHeader>
                <ShareWithBody
                  height={
                    isAdmin &&
                    !isFromScheduledTab &&
                    adminShareOptions[1].isVisible
                      ? '176px'
                      : '120px'
                  }
                >
                  {/* Connections for all users */}
                  {isAdmin && !isFromScheduledTab ? (
                    <Flex width={1} justifyContent="space-between">
                      <Flex width="40%" color="#49484a">
                        <CheckBoxWrapper
                          width="100%"
                          style={{ marginLeft: '-16px' }}
                        >
                          <CheckBoxSpan2
                            screenWidth={window.innerWidth}
                            checked={isMyChecked}
                          >
                            <CheckBox
                              id={'my-connection'}
                              checked={isMyChecked}
                              onChange={handleMyConnectionsChange}
                              screenWidth={window.innerWidth}
                              border="1px solid #c9c9c9"
                            />
                            <CheckBoxLabel
                              screenWidth={window.innerWidth}
                              color="#c9c9c9"
                              htmlFor={'my-connection'}
                              fontSize="12px"
                            >
                              My Network
                            </CheckBoxLabel>
                          </CheckBoxSpan2>
                        </CheckBoxWrapper>
                      </Flex>
                      <Flex
                        width="50%"
                        justifyContent="flex-end"
                        alignItems="center"
                        style={{ position: 'relative' }}
                      >
                        {!isEmpty(adminShareOptions[0].scheduleValue) &&
                          adminShareOptions[0].id === 'my' && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '-9px',
                                right: '1px',
                                color: 'red',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                clearSchedule(adminShareOptions[0].id)
                              }
                            >
                              x
                            </div>
                          )}
                        {adminShareOptions[0].id === 'my' && isMyChecked && (
                          <AdminScheduler
                            scheduleValue={adminShareOptions[0].scheduleValue}
                            connectionType={adminShareOptions[0].id}
                            disableScheduleClick={values(isChecked).includes(
                              true
                            )}
                            clearSchedule={clearSchedule}
                            onClockButtonClick={onClockButtonClick}
                            isAdvisorOpen={isAdvisorOpen}
                            isDateOpen={isDateOpen}
                            setIsAdvisorOpen={setIsAdvisorOpen}
                            setDateOpen={setDateOpen}
                            onScheduleClick={onScheduleClick}
                            isFromScheduledTab={isFromScheduledTab}
                            isFromAddContent={isFromAddContent}
                            timezone={get(
                              userData,
                              'details.user.timezone',
                              ''
                            )}
                          />
                        )}
                      </Flex>
                    </Flex>
                  ) : (
                    <Flex width={1} justifyContent="space-between">
                      <Flex width="40%" color="#49484a">
                        My Network
                      </Flex>
                      <Flex
                        width="50%"
                        justifyContent="flex-end"
                        alignItems="center"
                        style={{ position: 'relative' }}
                      >
                        {!isEmpty(adminShareOptions[0].scheduleValue) &&
                          adminShareOptions[0].id === 'my' && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '-9px',
                                right: '1px',
                                color: 'red',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                clearSchedule(adminShareOptions[0].id)
                              }
                            >
                              x
                            </div>
                          )}
                        {adminShareOptions[0].id === 'my' && (
                          <AdminScheduler
                            scheduleValue={adminShareOptions[0].scheduleValue}
                            connectionType={adminShareOptions[0].id}
                            disableScheduleClick={values(isChecked).includes(
                              true
                            )}
                            clearSchedule={clearSchedule}
                            onClockButtonClick={onClockButtonClick}
                            isAdvisorOpen={isAdvisorOpen}
                            isDateOpen={isDateOpen}
                            setIsAdvisorOpen={setIsAdvisorOpen}
                            setDateOpen={setDateOpen}
                            onScheduleClick={onScheduleClick}
                            isFromScheduledTab={isFromScheduledTab}
                            timezone={get(
                              userData,
                              'details.user.timezone',
                              ''
                            )}
                          />
                        )}
                      </Flex>
                    </Flex>
                  )}

                  {isMyChecked || !isAdmin || isFromScheduledTab ? (
                    <Flex pt="5px">
                      <CheckBoxWrapper width="16%">
                        <CheckBoxSpan2
                          screenWidth={window.innerWidth}
                          checked={isChecked.id}
                          className="tooltip"
                          disconnected={
                            getBottomText('tw').isDiconnected &&
                            getBottomText('ln').isDiconnected &&
                            getBottomText('fb').isDiconnected &&
                            getBottomText('em').isDiconnected
                          }
                        >
                          <CheckBox
                            id={'all'}
                            checked={isChecked['All']}
                            onChange={(e) =>
                              handleCheckChange(
                                'All',
                                '',
                                adminShareOptions[0].id,
                                e
                              )
                            }
                            disabled={
                              getBottomText('tw').isDiconnected &&
                              getBottomText('ln').isDiconnected &&
                              getBottomText('fb').isDiconnected &&
                              getBottomText('em').isDiconnected
                            }
                            screenWidth={window.innerWidth}
                            border="1px solid #c9c9c9"
                          />
                          <CheckBoxLabel
                            screenWidth={window.innerWidth}
                            color="#c9c9c9"
                            htmlFor={'all'}
                            disconnected={
                              getBottomText('tw').isDiconnected &&
                              getBottomText('ln').isDiconnected &&
                              getBottomText('fb').isDiconnected &&
                              getBottomText('em').isDiconnected
                            }
                          >
                            All
                          </CheckBoxLabel>
                        </CheckBoxSpan2>
                      </CheckBoxWrapper>
                      {adminShareOptions[0].connections.map(
                        ({ id, platform }, index) => (
                          <CheckBoxWrapper
                            key={'checkbox_' + index}
                            width={
                              id === 'Twitter'
                                ? '20%'
                                : id === 'Facebook' || id === 'LinkedIn'
                                ? '27%'
                                : '25%'
                            }
                          >
                            <CheckBoxSpan2
                              screenWidth={window.innerWidth}
                              checked={isChecked.id}
                              className="tooltip"
                              disconnected={
                                getBottomText(platform).isDiconnected
                              }
                              style={{
                                marginRight: id === 'Facebook' ? '10px' : '',
                                marginLeft:
                                  id === 'Email'
                                    ? '7px'
                                    : id === 'LinkedIn'
                                    ? '0px'
                                    : '',
                              }}
                            >
                              {getBottomText(platform).isDiconnected &&
                              !isChecked[id] ? (
                                <StyledTooltip
                                  className="tooltiptext"
                                  width="170px"
                                  isImage={
                                    (source === 'img' || isFromImageOnly) &&
                                    id === 'Email'
                                  }
                                >
                                  {(source === 'img' || isFromImageOnly) &&
                                  id === 'Email'
                                    ? 'Images cannot be shared directly by email'
                                    : 'Channel not activated'}
                                </StyledTooltip>
                              ) : null}
                              <CheckBox
                                id={id}
                                checked={isChecked[id]}
                                onChange={(e) =>
                                  handleCheckChange(
                                    id,
                                    platform,
                                    adminShareOptions[0].id,
                                    e
                                  )
                                }
                                screenWidth={window.innerWidth}
                                disabled={getBottomText(platform).isDiconnected}
                                border="1px solid #c9c9c9"
                              />
                              <CheckBoxLabel
                                screenWidth={window.innerWidth}
                                disconnected={
                                  getBottomText(platform).isDiconnected
                                }
                                color="#c9c9c9"
                                htmlFor={id}
                                fb={id === 'Facebook'}
                                ln={id === 'LinkedIn'}
                              >
                                {id}
                              </CheckBoxLabel>
                            </CheckBoxSpan2>
                          </CheckBoxWrapper>
                        )
                      )}
                      <div
                        className="tooltip-div-share-modal tooltip-div-self"
                        style={{
                          position: 'relative',
                          cursor: isChecked.Email ? 'pointer' : 'default',
                          paddingTop: '1px',
                        }}
                        onClick={(e) => toggleCustomizeModal(e)}
                      >
                        {isChecked.Email ? (
                          <img
                            src="/assets/images/CustmizeEmailIcon.svg"
                            alt="Customize Email"
                            width="16.9"
                            height="16.9"
                            style={{ position: 'relative', left: '-9px' }}
                          />
                        ) : (
                          <img
                            src="/assets/images/CustmizeEmailIconDisabled.svg"
                            alt="Customize Email"
                            width="16.9"
                            height="16.9"
                            style={{ position: 'relative', left: '-9px' }}
                          />
                        )}
                        <span className="tooltip-text-share-modal tooltip-text-self">
                          Customize Email
                        </span>
                      </div>
                    </Flex>
                  ) : (
                    <Flex style={{ height: '30px' }}></Flex>
                  )}
                  <Flex width="100%" justifyContent="space-between">
                    <Flex width="32%"></Flex>
                    <Flex width="25%">
                      {isChecked.LinkedIn && (
                        <MioSelect
                          value={selectedLnPage}
                          onChange={(e) => setSelectedLnPage(e.target.value)}
                          margin={'0px'}
                          height={'24px'}
                          padding={'1px 1px'}
                          width="90%"
                          fontSize="9px"
                          background="#ffffff"
                          textAlign="left"
                        >
                          <option value={0} selected="selected">
                            On News Feed
                          </option>
                          {socialNetworkData.ln &&
                            !isEmpty(socialNetworkData.ln) &&
                            socialNetworkData.ln[0].page &&
                            !isEmpty(socialNetworkData.ln[0].page) &&
                            map(socialNetworkData.ln[0].page, (item, key) => (
                              <option value={key}>{item.details.name}</option>
                            ))}
                        </MioSelect>
                      )}
                    </Flex>
                    <Flex width="25%">
                      {isChecked.Facebook && (
                        <MioSelect
                          value={selectedFbPage}
                          onChange={(e) => setSelectedFbPage(e.target.value)}
                          margin={'0px'}
                          height={'24px'}
                          padding={'1px 1px'}
                          width="90%"
                          fontSize="9px"
                          background="#ffffff"
                          textAlign="left"
                        >
                          {(isPremiumAdvisor ||
                            isEnterpriseAdvisor ||
                            isAssetAdvisor) &&
                          !isFromScheduledTab ? (
                            <option value={0} selected="selected">
                              On News Feed
                            </option>
                          ) : null}
                          {socialNetworkData.fb &&
                            socialNetworkData.fb[0].page &&
                            map(socialNetworkData.fb[0].page, (item, key) => (
                              <option key={'index_' + key} value={key}>
                                {item.details.name}
                              </option>
                            ))}
                        </MioSelect>
                      )}
                    </Flex>
                    <Flex width="25%">
                      {isChecked.Email && (
                        <MioSelect
                          value={selectedContactGroup}
                          onChange={(e) => handleSelectChange(e)}
                          margin={'0px'}
                          height={'24px'}
                          padding={'1px 1px'}
                          width="90%"
                          fontSize="9px"
                          background="#ffffff"
                          textAlign="left"
                        >
                          <option value="">Select Email</option>
                          {contactGroups.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                          <option value="create">Create New List</option>
                        </MioSelect>
                      )}
                    </Flex>
                  </Flex>
                  {/* Connections for advisors */}
                  {isAdmin &&
                    !isFromScheduledTab &&
                    adminShareOptions[1].isVisible &&
                    !isCloning && (
                      <>
                        <hr style={{ width: '100%' }} />
                        <Flex
                          width="100%"
                          alignItems="flex-start"
                          justifyContent="space-around"
                        >
                          <Flex width="33%" color="#49484a">
                            <CheckBoxWrapper
                              width="100%"
                              style={{ marginLeft: '-21px' }}
                            >
                              <CheckBoxSpan2
                                screenWidth={window.innerWidth}
                                checked={isMemberChecked}
                              >
                                <CheckBox
                                  id={'member-connection'}
                                  checked={isMemberChecked}
                                  onChange={handleMemberConnectionsChange}
                                  screenWidth={window.innerWidth}
                                  border="1px solid #c9c9c9"
                                />
                                <CheckBoxLabel
                                  screenWidth={window.innerWidth}
                                  color="#c9c9c9"
                                  htmlFor={'member-connection'}
                                  fontSize="12px"
                                >
                                  Member Network
                                </CheckBoxLabel>
                              </CheckBoxSpan2>
                            </CheckBoxWrapper>
                          </Flex>
                          <Flex width="40%" mt="-3px" ml="-8px">
                            {isAdmin &&
                              adminShareOptions[1].id === 'advisor' &&
                              !isFromCompliance &&
                              isMemberChecked && (
                                <Flex
                                  width="100%"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                  px="4px"
                                  pt="4px"
                                  style={{
                                    padding: 0,
                                    height: 6,
                                    marginBottom: 15,
                                  }}
                                >
                                  <AutoPostCheckboxes
                                    showAll={isAutoPostChecked}
                                    isMyChecked={isMyChecked}
                                    windowSize={window.innerWidth}
                                  >
                                    <Box
                                      mt={2}
                                      className="autoPostCheckboxesListsContainer"
                                      alignItems="baseline"
                                    >
                                      <div
                                        style={{
                                          marginTop: '10px',
                                          fontSize: '10px',
                                          color: isAutoPostChecked
                                            ? '#848484'
                                            : '#49484a',
                                        }}
                                        className="member-title"
                                        onMouseEnter={(e) =>
                                          (document.getElementById(
                                            'm-tooltip'
                                          ).style.visibility = 'visible')
                                        }
                                        onMouseLeave={(e) =>
                                          (document.getElementById(
                                            'm-tooltip'
                                          ).style.visibility = 'hidden')
                                        }
                                      >
                                        Member post
                                      </div>
                                      <div
                                        id="m-tooltip"
                                        className="member-tooltip"
                                      >
                                        Allow your members to share
                                      </div>
                                      &nbsp;
                                      <ToggleButton
                                        className="react-switch"
                                        onChange={autoPostChangeHandler}
                                        checked={isAutoPostChecked}
                                        onColor="#6351ed"
                                        offColor="#6351ed"
                                        offHandleColor="#fff"
                                        onHandleColor="#fff"
                                        handleDiameter={10}
                                        uncheckedIcon=""
                                        checkedIcon=""
                                        boxShadow=""
                                        activeBoxShadow=""
                                        type="autoPost"
                                        left="0px"
                                        disabled={isAdvisorChecked.All}
                                      />
                                      &nbsp;
                                      <div
                                        className="auto-title"
                                        style={{
                                          marginTop: '10px',
                                          fontSize: '10px',
                                          color: isAutoPostChecked
                                            ? '#49484a'
                                            : '#848484',
                                        }}
                                        onMouseEnter={(e) =>
                                          (document.getElementById(
                                            'a-tooltip'
                                          ).style.visibility = 'visible')
                                        }
                                        onMouseLeave={(e) =>
                                          (document.getElementById(
                                            'a-tooltip'
                                          ).style.visibility = 'hidden')
                                        }
                                      >
                                        Auto post
                                      </div>
                                      <div
                                        id="a-tooltip"
                                        className="auto-tooltip"
                                      >
                                        Automatically share on selected member
                                        platforms
                                      </div>
                                      <img
                                        src={
                                          isAutoPostChecked
                                            ? '/assets/images/advisor1.svg'
                                            : '/assets/images/advisor1-disabled.svg'
                                        }
                                        alt=""
                                        style={{
                                          margin: '10px 4px 0px',
                                          cursor: isAutoPostChecked
                                            ? 'pointer'
                                            : 'default',
                                        }}
                                        onClick={() => toggleAdvisorModal()}
                                      />
                                    </Box>
                                  </AutoPostCheckboxes>
                                </Flex>
                              )}
                          </Flex>
                          <Flex
                            width="20%"
                            justifyContent="flex-end"
                            alignItems="center"
                            mt="1px"
                            style={{ position: 'relative' }}
                          >
                            {adminShareOptions[1].scheduleValue &&
                              adminShareOptions[1].id === 'advisor' && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '-11px',
                                    right: '1px',
                                    color: 'red',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    clearSchedule(adminShareOptions[1].id)
                                  }
                                >
                                  x
                                </div>
                              )}
                            {adminShareOptions[1].id === 'advisor' &&
                              isMemberChecked && (
                                <AdminScheduler
                                  scheduleValue={
                                    adminShareOptions[1].scheduleValue
                                  }
                                  connectionType={adminShareOptions[1].id}
                                  disableScheduleClick={
                                    isAutoPostChecked &&
                                    values(isAdvisorChecked).includes(true)
                                  }
                                  clearSchedule={clearSchedule}
                                  onClockButtonClick={onClockButtonClick}
                                  isAdvisorOpen={isAdvisorOpen}
                                  isDateOpen={isDateOpen}
                                  setIsAdvisorOpen={setIsAdvisorOpen}
                                  setDateOpen={setDateOpen}
                                  onScheduleClick={onScheduleClick}
                                  isFromScheduledTab={isFromScheduledTab}
                                  timezone={get(
                                    userData,
                                    'details.user.timezone',
                                    ''
                                  )}
                                />
                              )}
                          </Flex>
                        </Flex>
                        <Flex pt="5px" justifyContent="center">
                          {isMemberChecked &&
                            adminShareOptions[1].connections.map(
                              ({ id, platform }, index) => (
                                <CheckBoxWrapper
                                  style={
                                    index === 1 ? { marginLeft: '-10px' } : {}
                                  }
                                >
                                  <CheckBoxSpan2
                                    screenWidth={window.innerWidth}
                                    checked={isAdvisorChecked.id}
                                    className="tooltip"
                                    disconnected={getAdvisorStatus(platform)}
                                  >
                                    {getAdvisorStatus(platform) &&
                                    !isAdvisorChecked[id] ? (
                                      <StyledTooltip
                                        className="tooltiptext"
                                        width="170px"
                                        isImage={
                                          (source === 'img' ||
                                            isFromImageOnly) &&
                                          id === 'Email'
                                        }
                                      >
                                        {(source === 'img' ||
                                          isFromImageOnly) &&
                                        id === 'Email'
                                          ? 'Images cannot be shared directly by email'
                                          : 'Channel not activated'}
                                      </StyledTooltip>
                                    ) : null}
                                    <CheckBox
                                      id={id + adminShareOptions[1].id}
                                      checked={isAdvisorChecked[id]}
                                      onChange={(e) =>
                                        handleCheckChange(
                                          id,
                                          platform,
                                          adminShareOptions[1].id,
                                          e
                                        )
                                      }
                                      screenWidth={window.innerWidth}
                                      border="1px solid #c9c9c9"
                                      disabled={getAdvisorStatus(platform)}
                                    />
                                    <CheckBoxLabel
                                      screenWidth={window.innerWidth}
                                      color="#c9c9c9"
                                      htmlFor={id + adminShareOptions[1].id}
                                      fb={id === 'Facebook'}
                                      ln={id === 'LinkedIn'}
                                      disconnected={getAdvisorStatus(platform)}
                                    >
                                      {id}
                                    </CheckBoxLabel>
                                  </CheckBoxSpan2>
                                </CheckBoxWrapper>
                              )
                            )}
                          {isMemberChecked ? (
                            <div
                              className="tooltip-div-share-modal tooltip-div-member"
                              style={{
                                position: 'relative',
                                cursor:
                                  isAdvisorChecked.Email || isAdvisorChecked.All
                                    ? 'pointer'
                                    : 'default',
                                paddingTop: '1px',
                              }}
                              onClick={(e) => toggleCustomizeModal(e)}
                            >
                              {isAdvisorChecked.Email ||
                              isAdvisorChecked.All ? (
                                <img
                                  src="/assets/images/CustmizeEmailIcon.svg"
                                  alt="Customize Email"
                                  width="16.9"
                                  height="16.9"
                                  style={{ position: 'relative', left: '-9px' }}
                                />
                              ) : (
                                <img
                                  src="/assets/images/CustmizeEmailIconDisabled.svg"
                                  alt="Customize Email"
                                  width="16.9"
                                  height="16.9"
                                  style={{ position: 'relative', left: '-9px' }}
                                />
                              )}
                              <span className="tooltip-text-share-modal tooltip-text-member">
                                Customize Email
                              </span>
                            </div>
                          ) : null}
                        </Flex>
                      </>
                    )}
                </ShareWithBody>
              </ShareWithWrapper>

              <ShareWithWrapper
                mt={
                  isAdmin &&
                  !isFromScheduledTab &&
                  adminShareOptions[1].isVisible
                    ? '6%'
                    : '0px'
                }
              >
                <ShareWithHeader>
                  <StyledShareWithHeader></StyledShareWithHeader>
                  <StyledShareWithHeaderInside></StyledShareWithHeaderInside>
                  <StyledShareWithHeaderTitle>
                    Message
                  </StyledShareWithHeaderTitle>
                  {/* <Flex width="75%"></Flex> */}
                </ShareWithHeader>
                <ShareWithBody>
                  <Tabs onSelect={onTabSelect} selectedIndex={summaryTabIndex}>
                    <ShareSummaryTabList>
                      <ShareSummaryTab
                        padding="0px !important"
                        className={`${
                          summaryTabIndex === 0 ? 'active' : 'inactive'
                        }`}
                        activeColor={textBoxes['tw'].color}
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                        <div style={{ paddingLeft: '6px' }}>Twitter</div>
                      </ShareSummaryTab>
                      <ShareSummaryTab
                        padding="5px 26px !important"
                        className={`${
                          summaryTabIndex === 1 ? 'active' : 'inactive'
                        }`}
                        activeColor={textBoxes['ln'].color}
                      >
                        <FontAwesomeIcon icon={faLinkedin} />
                        <div style={{ paddingLeft: '6px' }}>Linkedin</div>
                      </ShareSummaryTab>
                      <ShareSummaryTab
                        padding="5px 21px !important"
                        className={`${
                          summaryTabIndex === 2 ? 'active' : 'inactive'
                        }`}
                        activeColor={textBoxes['fb'].color}
                      >
                        <FontAwesomeIcon icon={faFacebook} />
                        <div style={{ paddingLeft: '6px' }}>Facebook</div>
                      </ShareSummaryTab>
                      <ShareSummaryTab
                        padding="5px 31px !important"
                        className={`${
                          summaryTabIndex === 3 ? 'active' : 'inactive'
                        }`}
                        activeColor={textBoxes['em'].color}
                      >
                        <FontAwesomeIcon icon={faEnvelopeSquare} />
                        <div style={{ paddingLeft: '6px' }}>Email</div>
                      </ShareSummaryTab>
                    </ShareSummaryTabList>
                    <ShareSummaryTabPanel borderColor={textBoxes['tw'].color}>
                      <SummaryTextFields
                        isFetchingSummary={isFetchingSummary}
                        platform="tw"
                        placeholder={
                          isFetchingSummary
                            ? 'Generating Summary ...'
                            : getSummaryPlaceHolder('tw')
                        }
                        maxLength={
                          source === 'img'
                            ? textBoxes['tw'].editableContent.maxLength
                            : textBoxes['tw'].editableContent.maxLength - 25
                        }
                        // maxLength={(source === 'img' || source === 'pdf') ? textBoxes['tw'].editableContent.maxLength : (textBoxes['tw'].editableContent.maxLength - 25)}
                        content={summaryData['twSummary']}
                        onChange={(html, text) => {
                          if (isCloning) setIsContentChanged(true);
                          setSummaryData({
                            ...summaryData,
                            [`twSummary`]: replaceSpanWithQuate(html, 'tw'),
                            [`twLength`]: text.length,
                          });
                        }}
                        sumarryOptions={getQuotedText(
                          get(
                            allSumarries,
                            textBoxes['tw'].editableContent.summaryOption,
                            []
                          ),
                          'tw'
                        )}
                        shortSummary={summaryData[`twSummary`]}
                        height="85px"
                        editable={
                          approvedArticles ||
                          (isFromScheduledTab && !selectedItem.edit_summary)
                            ? false
                            : editable
                        }
                        originalSummary={get(
                          allSumarries,
                          textBoxes['tw'].editableContent.summaryOption,
                          []
                        )}
                      />
                    </ShareSummaryTabPanel>
                    <ShareSummaryTabPanel borderColor={textBoxes['ln'].color}>
                      <SummaryTextFields
                        isFetchingSummary={isFetchingSummary}
                        placeholder={
                          isFetchingSummary
                            ? 'Generating Summary ...'
                            : getSummaryPlaceHolder('ln')
                        }
                        maxLength={textBoxes['ln'].editableContent.maxLength}
                        content={summaryData['lnSummary']}
                        onChange={(html, text) => {
                          if (isCloning) setIsContentChanged(true);
                          setSummaryData({
                            ...summaryData,
                            [`lnSummary`]: replaceSpanWithQuate(html, 'ln'),
                            [`lnLength`]: text.length,
                          });
                        }}
                        sumarryOptions={getQuotedText(
                          get(
                            allSumarries,
                            textBoxes['ln'].editableContent.summaryOption,
                            []
                          )
                        )}
                        shortSummary={summaryData[`lnSummary`]}
                        height="85px"
                        originalSummary={get(
                          allSumarries,
                          textBoxes['ln'].editableContent.summaryOption,
                          []
                        )}
                        editable={
                          approvedArticles ||
                          (isFromScheduledTab && !selectedItem.edit_summary)
                            ? false
                            : editable
                        }
                      />
                    </ShareSummaryTabPanel>
                    <ShareSummaryTabPanel borderColor={textBoxes['fb'].color}>
                      <SummaryTextFields
                        isFetchingSummary={isFetchingSummary}
                        placeholder={
                          isFetchingSummary
                            ? 'Generating Summary ...'
                            : getSummaryPlaceHolder('fb')
                        }
                        maxLength={textBoxes['fb'].editableContent.maxLength}
                        content={summaryData['fbSummary']}
                        onChange={(html, text) => {
                          if (isCloning) setIsContentChanged(true);
                          setSummaryData({
                            ...summaryData,
                            [`fbSummary`]: replaceSpanWithQuate(html, 'fb'),
                            [`fbLength`]: text.length,
                          });
                        }}
                        sumarryOptions={getQuotedText(
                          get(
                            allSumarries,
                            textBoxes['fb'].editableContent.summaryOption,
                            []
                          )
                        )}
                        shortSummary={summaryData[`fbSummary`]}
                        height="85px"
                        originalSummary={get(
                          allSumarries,
                          textBoxes['fb'].editableContent.summaryOption,
                          []
                        )}
                        editable={
                          approvedArticles ||
                          (isFromScheduledTab && !selectedItem.edit_summary)
                            ? false
                            : editable
                        }
                      />
                    </ShareSummaryTabPanel>
                    <ShareSummaryTabPanel borderColor={textBoxes['em'].color}>
                      <SummaryTextFields
                        isFetchingSummary={isFetchingSummary}
                        placeholder={
                          isFetchingSummary
                            ? 'Generating Summary ...'
                            : getSummaryPlaceHolder('em')
                        }
                        maxLength={textBoxes['em'].editableContent.maxLength}
                        content={summaryData['emSummary']}
                        onChange={(html, text) => {
                          if (isCloning) setIsContentChanged(true);
                          setSummaryData({
                            ...summaryData,
                            [`emSummary`]: replaceSpanWithQuate(html, 'em'),
                            [`emLength`]: text.length,
                          });
                        }}
                        sumarryOptions={getQuotedText(
                          get(
                            allSumarries,
                            textBoxes['em'].editableContent.summaryOption,
                            []
                          )
                        )}
                        shortSummary={summaryData[`emSummary`]}
                        height="85px"
                        originalSummary={get(
                          allSumarries,
                          textBoxes['em'].editableContent.summaryOption,
                          []
                        )}
                        editable={
                          approvedArticles ||
                          (isFromScheduledTab && !selectedItem.edit_summary)
                            ? false
                            : editable
                        }
                      />
                    </ShareSummaryTabPanel>
                  </Tabs>
                </ShareWithBody>
              </ShareWithWrapper>

              <hr style={{ width: '100%' }} />
              {showLeadCaptureOption && (
                <LeadCaptureOption
                  selectedLeadCaptureOption={selectedLeadCaptureOption}
                  setSelectedLeadCaptureOption={setSelectedLeadCaptureOption}
                  setSelectedReferralCaptureOption={
                    setSelectedReferralCaptureOption
                  }
                  selectecdReferralCaptureOption={selectedReferralCaptureOption}
                />
              )}

              {(!isCloning || !isOGArticleComplianced) && (
                <ComplianceReview
                  isFromCompliance={isFromCompliance}
                  showComplianceCheck={showComplianceCheck}
                  userData={userData}
                  setSelectedComplianceCheck={setSelectedComplianceCheck}
                  selectedComplianceCheck={selectedComplianceCheck}
                  isFromScheduledTab={isFromScheduledTab}
                  approvedArticles={approvedArticles}
                  paddingTop="5px"
                  jt="center"
                />
              )}

              <Flex width="100%" pt="5px" justifyContent="center">
                {(isCreatingContent || isSharing) && <Loader height="25px" />}
                {!isCreatingContent && !isSharing && (
                  <FooterButtons
                    isFromCompliance={isFromCompliance}
                    enableShare={enableShare && shareModalMsgs.length === 0}
                    isAdmin={isAdmin}
                    userData={userData}
                    rest={rest}
                    handleSubmit={handleSubmit}
                    summaryData={summaryData}
                    handleFeedback={handleFeedback}
                    setNoFeedback={setNoFeedback}
                    shareArticle={shareArticle}
                    onShareClick={onShareClick}
                    scheduleTime={scheduleTime}
                    onModalClose={onModalClose}
                    setWarningMsg={setWarningMsg}
                    internalUse={internalUse}
                    selectedComplianceCheck={selectedComplianceCheck}
                    isFromScheduledTab={isFromScheduledTab}
                    isEnterpriseAdvisor={isEnterpriseAdvisor}
                    isAssetAdvisor={isAssetAdvisor}
                    isChecked={isChecked}
                    approvedArticles={approvedArticles}
                    engagementId={engagementId}
                    isContentChanged={isContentChanged}
                    isCloning={isCloning}
                    article={article}
                    isOGArticleComplianced={isOGArticleComplianced}
                  />
                )}
              </Flex>
              {referralMessage && (
                <Flex
                  color={themesData.purpleishBlue}
                  justifyContent="center"
                  fontSize="12px"
                >
                  {referralMessage}
                </Flex>
              )}
              <ModalMessages
                userData={userData}
                isChecked={isChecked}
                selectedComplianceCheck={selectedComplianceCheck}
                isEnterpriseAdvisor={isEnterpriseAdvisor}
                shareError={shareError}
                shareModalMsgs={shareModalMsgs}
                isAdmin={isAdmin}
              />
            </ShareModalListItem>
          </ShareModalList>
        </ShareModalDiv>
      </>
    ) : isFromCompliance ? (
      <ShareModalDiv>
        <ShareModalList screenWidth={window.innerWidth}>
          {!isNewsLetter && (
            <ShareModalListItem screenWidth={window.innerWidth}>
              <MiniArticle
                source={source}
                topic={title}
                created={time}
                image={imagesrc}
                link={contentLink}
                pdfImage={pdfImage}
                isPdf={isPdf}
                isFromEditorTab={isFromEditorTab}
                pdfUrl={pdfUrl}
                isComplianceAdmin={isComplianceAdmin}
                mediaUrl={mediaUrl}
                complianceTab={complianceTab}
                isFromCompliance={isFromCompliance}
                {...rest}
              />
            </ShareModalListItem>
          )}
          <ShareModalListItem
            style={{
              // border: '1px solid #cacaca',
              // padding: '10px 10px',
              // borderRadius: '10px',
              width: isNewsLetter ? '90%' : '',
            }}
          >
            <SummaryFields
              isNewsLetter={isNewsLetter}
              newsLetter_id={newsLetter_id}
              complianceTab={complianceTab}
              channels={channels}
              rest={rest}
              editable={
                approvedArticles ||
                (isFromScheduledTab && !selectedItem.edit_summary)
                  ? false
                  : editable
              }
              allSumarries={allSumarries}
              getPlaceHolder={
                isFromAddContent ? getSummaryPlaceHolder : getPlaceHolder
              }
              placeholerText={placeholerText}
              summaryData={summaryData}
              setSummaryData={setSummaryData}
              isFetchingSummary={isFetchingSummary}
              isFromCompliance={isFromCompliance}
              hasFeedBack={hasFeedBack}
              platformObj={platformObj}
              isFromAddContent={isFromAddContent}
              emailTextData={emailTextData}
              articleData={{ image: rest.url, title: title }}
              image={imagesrc}
              isComplianceAdmin={isComplianceAdmin}
              setIsContentChanged={setIsContentChanged}
              engagementId={engagementId}
              isContentChanged={isContentChanged}
              setEmailShareData={setEmailShareData}
              emailShareData={emailShareData}
              selectedItem={selectedItem}
              complianceCustomisedData={complianceCustomisedData}
            />
            <CheckBoxForm>
              {(isCreatingContent || isSharing) && <Loader height="25px" />}
              {!isCreatingContent && !isSharing && (
                <div>
                  <Flex
                    width={1}
                    alignItems="center"
                    justifyContent="space-around"
                    pt={isFromCompliance && isNewsLetter ? '0px' : '10px'}
                    m={isFromCompliance && isNewsLetter && '10px'}
                    style={{
                      display: 'block',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: isFromCompliance && isNewsLetter && 'absolute',
                      bottom: isFromCompliance && isNewsLetter && '10px',
                      left: isFromCompliance && isNewsLetter && '0',
                      right: isFromCompliance && isNewsLetter && '0',
                    }}
                  >
                    &nbsp;&nbsp;
                    <FooterButtons
                      isFromCompliance={isFromCompliance}
                      enableShare={enableShare && shareModalMsgs.length === 0}
                      isAdmin={isAdmin}
                      userData={userData}
                      rest={rest}
                      handleSubmit={handleSubmit}
                      summaryData={summaryData}
                      handleFeedback={handleFeedback}
                      setNoFeedback={setNoFeedback}
                      shareArticle={shareArticle}
                      onShareClick={onShareClick}
                      scheduleTime={scheduleTime}
                      onModalClose={onModalClose}
                      setWarningMsg={setWarningMsg}
                      internalUse={internalUse}
                      selectedComplianceCheck={selectedComplianceCheck}
                      isFromScheduledTab={isFromScheduledTab}
                      isEnterpriseAdvisor={isEnterpriseAdvisor}
                      isAssetAdvisor={isAssetAdvisor}
                      isChecked={isChecked}
                      approvedArticles={approvedArticles}
                      isNewsLetter={isNewsLetter}
                      newsLetter_id={newsLetter_id}
                      engagementId={engagementId}
                      isContentChanged={isContentChanged}
                      platform={platformObj}
                      channels={channels}
                      setEmailShareData={setEmailShareData}
                      emailShareData={emailShareData}
                      newsletterSetState={newsletterSetState}
                    />
                  </Flex>
                  <div>
                    {NoFeedback ? (
                      <div
                        style={{
                          color: '#6351ed',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        Please share your feedback
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              <ModalMessages
                userData={userData}
                isChecked={isChecked}
                selectedComplianceCheck={selectedComplianceCheck}
                isEnterpriseAdvisor={isEnterpriseAdvisor}
                shareError={shareError}
                shareModalMsgs={shareModalMsgs}
                isAdmin={isAdmin}
              />
            </CheckBoxForm>
          </ShareModalListItem>
        </ShareModalList>
      </ShareModalDiv>
    ) : fromMyClients ||
      (isFromScheduledTab &&
        selectedItem.destination.ids &&
        selectedItem.destination.ids[0].name &&
        selectedItem.destination.ids[0].name.includes('positivly_')) ? (
      <>
        {console.log('positivly Contacts count - ', positivlyContactsCount)}
        <ShareModalDiv>
          <ShareModalList
            isFromAddContent={isFromAddContent}
            screenWidth={window.innerWidth}
          >
            <ShareModalListItem screenWidth={window.innerWidth}>
              <MiniArticle
                source={source}
                topic={title}
                created={time}
                image={imagesrc}
                link={contentLink}
                pdfImage={pdfImage}
                isPdf={isPdf}
                {...rest}
              />
            </ShareModalListItem>

            <ShareModalListItem
              style={{
                padding: '10px 10px',
                borderRadius: '10px',
                border: '1px solid #cacaca',
              }}
            >
              {isFromScheduledTab ||
              positivlyContactsCount > 0 ||
              selectedDropdownOption === 'crm' ? (
                <Flex
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #cacaca',
                    borderRadius: '6px',
                    padding: '0 8px',
                    backgroundColor: 'white',
                  }}
                >
                  {selectedDropdownOption === 'crm' ? (
                    <h4 style={{ color: 'black' }}>
                      Share with {selectedCRMContactList.name}.
                    </h4>
                  ) : (
                    <h4 style={{ color: 'black' }}>
                      Share with{' '}
                      <span style={{ color: '#6351ed' }}>
                        '
                        {isFromScheduledTab
                          ? getPositivlyName(
                              selectedItem.destination.ids[0].name
                            )
                          : positivlyName}
                        '
                      </span>{' '}
                      oriented clients
                    </h4>
                  )}
                  <Flex
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <img
                      src="/assets/images/CustmizeEmailIcon.svg"
                      alt="Customize Email"
                      width="16.9"
                      height="16.9"
                      style={{
                        position: 'relative',
                        left: '-9px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => toggleCustomizeModal(e)}
                      onMouseEnter={() => setCustomizeEmailTooltip(true)}
                      onMouseLeave={() => setCustomizeEmailTooltip(false)}
                    />
                    {/* <span style={{position: 'absolute', backgroundColor: '#49484a', color: 'white', borderRadius: '4px', fontSize: '10px', width: '97px', padding: '2px', top: '46px', right: '17%'}}>Customize Email</span> */}
                    {showCustomizeEmailTooltip ? (
                      <span
                        style={{
                          position: 'absolute',
                          backgroundColor: '#49484a',
                          color: 'white',
                          borderRadius: '4px',
                          fontSize: '10px',
                          width: '97px',
                          padding: '2px',
                          top: '25px',
                          right: '38%',
                        }}
                      >
                        Customize Email
                      </span>
                    ) : null}
                    {scheduleTime ? (
                      <div
                        style={{
                          position: 'absolute',
                          top: '-14px',
                          right: '1px',
                          color: 'red',
                          cursor: 'pointer',
                        }}
                        onClick={() => clearSchedule('my')}
                      >
                        x
                      </div>
                    ) : null}
                    <AdminScheduler
                      scheduleValue={scheduleTime}
                      connectionType={'admin'}
                      disableScheduleClick={true}
                      clearSchedule={clearSchedule}
                      onClockButtonClick={onClockButtonClick}
                      isDateOpen={isDateOpen}
                      setDateOpen={setDateOpen}
                      onScheduleClick={onScheduleClick}
                      isFromScheduledTab={isFromScheduledTab}
                      isFromAddContent={isFromAddContent}
                      fromMyClients={fromMyClients}
                      timezone={get(userData, 'details.user.timezone', '')}
                    />
                  </Flex>
                </Flex>
              ) : null}

              <ShareWithWrapper mt={'20px'}>
                <ShareWithHeader>
                  <StyledShareWithHeader
                    isFromAddContent={isFromAddContent}
                  ></StyledShareWithHeader>
                  {!isFromAddContent && (
                    <StyledShareWithHeaderInside top="0.6px"></StyledShareWithHeaderInside>
                  )}
                  <StyledShareWithHeaderTitle
                    style={{ width: !isFromAddContent ? '16%' : '14%' }}
                    isFromAddContent={isFromAddContent}
                  >
                    Message
                  </StyledShareWithHeaderTitle>

                  <Flex width="75%"></Flex>
                </ShareWithHeader>
                <ShareWithBody>
                  <Tabs onSelect={onTabSelect} selectedIndex={summaryTabIndex}>
                    <ShareSummaryTabList
                      style={{ justifyContent: 'flex-start' }}
                    >
                      <ShareSummaryTab
                        padding="5px 31px !important"
                        className="active"
                        activeColor={textBoxes['em'].color}
                      >
                        <FontAwesomeIcon icon={faEnvelopeSquare} />
                        <div style={{ paddingLeft: '6px' }}>Email</div>
                      </ShareSummaryTab>
                    </ShareSummaryTabList>
                    <ShareSummaryTabPanel
                      style={{ border: '1px solid #ea5946' }}
                      borderColor={'#ea5946'}
                    >
                      <SummaryTextFields
                        isFetchingSummary={isFetchingSummary}
                        placeholder={
                          isFetchingSummary
                            ? 'Generating Summary ...'
                            : getSummaryPlaceHolder('em')
                        }
                        maxLength={textBoxes['em'].editableContent.maxLength}
                        content={summaryData['emSummary']}
                        onChange={(html, text) =>
                          setSummaryData({
                            ...summaryData,
                            [`emSummary`]: replaceSpanWithQuate(html, 'em'),
                            [`emLength`]: text.length,
                          })
                        }
                        sumarryOptions={getQuotedText(
                          get(
                            allSumarries,
                            textBoxes['em'].editableContent.summaryOption,
                            []
                          )
                        )}
                        shortSummary={summaryData[`emSummary`]}
                        height="85px"
                        originalSummary={get(
                          allSumarries,
                          textBoxes['em'].editableContent.summaryOption,
                          []
                        )}
                        editable={
                          approvedArticles ||
                          (isFromScheduledTab && !selectedItem.edit_summary)
                            ? false
                            : editable
                        }
                      />
                    </ShareSummaryTabPanel>
                  </Tabs>
                </ShareWithBody>
              </ShareWithWrapper>
              <hr style={{ width: '100%' }} />

              <LeadCaptureOption
                selectedLeadCaptureOption={selectedLeadCaptureOption}
                setSelectedLeadCaptureOption={setSelectedLeadCaptureOption}
                fromClients
              />

              <LoaderWrapper
                styles={{ position: 'absolute', top: '213px', left: '17%' }}
                isLoading={isSharing}
              >
                {console.log('ENABLE SHARE - ', enableShare)}
                <Button
                  styles={{ marginTop: '5px' }}
                  disabled={
                    selectedDropdownOption === 'financial' &&
                    positivlyContactsCount === 0
                      ? true
                      : !enableShare
                  }
                  onClick={
                    ((selectedDropdownOption === 'financial' ||
                      selectedDropdownOption === '') &&
                      enableShare) ||
                    selectedDropdownOption === 'crm'
                      ? onClientShareClick
                      : () => {}
                  }
                >
                  Share
                </Button>
              </LoaderWrapper>
              {selectedDropdownOption !== 'crm' &&
              (shareModalMsgs.length > 0 || positivlyContactsCount == 0) ? (
                <div>
                  {shareModalMsgs[0] ? (
                    <p style={{ fontSize: '10px' }}>{shareModalMsgs[0]}</p>
                  ) : null}
                  {positivlyContactsCount == 0 ? (
                    <p style={{ fontSize: '10px' }}>
                      No clients available with "
                      <strong style={{ fontSize: '11px' }}>
                        {positivlyName}
                      </strong>
                      " dominant personality trait
                    </p>
                  ) : null}
                </div>
              ) : selectedDropdownOption === 'crm' &&
                shareModalMsgs &&
                shareModalMsgs.length > 0 &&
                shareModalMsgs[0] ? (
                <p style={{ fontSize: '10px' }}>{shareModalMsgs[0]}</p>
              ) : null}
            </ShareModalListItem>
          </ShareModalList>
        </ShareModalDiv>
      </>
    ) : isFromRecentTab && !isCloning ? (
      <>
        <ShareModalDiv>
          <ShareModalList
            isFromAddContent={isFromAddContent}
            screenWidth={window.innerWidth}
          >
            <ShareModalListItem screenWidth={window.innerWidth}>
              <MiniArticle
                source={source}
                topic={title}
                created={time}
                image={imagesrc}
                link={contentLink}
                pdfImage={pdfImage}
                isPdf={isPdf}
                isFromEditorTab={isFromEditorTab}
                pdfUrl={pdfUrl}
                {...rest}
              />
            </ShareModalListItem>

            <ShareModalListItem
              style={{
                border: '1px solid #cacaca',
                padding: '10px 10px',
                borderRadius: '10px',
              }}
            >
              <SummaryFields
                channels={channels}
                rest={rest}
                editable={
                  approvedArticles ||
                  (isFromScheduledTab && !selectedItem.edit_summary)
                    ? false
                    : editable
                }
                allSumarries={allSumarries}
                getPlaceHolder={
                  isFromAddContent ? getSummaryPlaceHolder : getPlaceHolder
                }
                placeholerText={placeholerText}
                summaryData={summaryData}
                setSummaryData={setSummaryData}
                isFetchingSummary={isFetchingSummary}
                isFromCompliance={isFromCompliance}
                hasFeedBack={hasFeedBack}
                platformObj={platformObj}
                isFromAddContent={isFromAddContent}
              />
              <CheckBoxForm>
                <Flex width="100%" justifyContent="center">
                  {selectedItem.email_customization && (
                    <CustomButton
                      onClick={(e) => toggleCustomizeModal(e)}
                      margin={'6px 0 6px 15px'}
                      width={'128px'}
                      height={'31px'}
                      padding={'5px 10px'}
                    >
                      Customize Email
                    </CustomButton>
                  )}
                </Flex>
              </CheckBoxForm>
            </ShareModalListItem>
          </ShareModalList>
        </ShareModalDiv>
      </>
    ) : null;

  return (
    <div>
      <Container asModal={asModal}>
        {(header || asModal) && (
          <ModalHeader
            style={{
              textAlign: 'center',
              color: isFromCompliance && '#859094',
            }}
          >
            {header || ' '}
            <ModalCloseButton onClick={onModalClose} />
          </ModalHeader>
        )}
        {asModal ? (
          <ModalBody
            style={isFromCompliance && isNewsLetter ? { height: '98%' } : {}}
            padding={isFromCompliance ? '5px 15px 15px' : ''}
          >
            {bodycontent}
          </ModalBody>
        ) : (
          bodycontent
        )}
      </Container>
      <SchedulerModal
        fromMyClients={fromMyClients}
        setScheduleState={setScheduleState}
        scheduleState={scheduleState}
        scheduleTime={scheduleTime}
        advisorScheduleTime={advisorScheduleTime}
        isChecked={isChecked}
        isAdvisorChecked={isAdvisorChecked}
      />
      <MembersModal
        toggleAdvisorModal={toggleAdvisorModal}
        isAdvisorModalOpen={isAdvisorModalOpen}
        setIsAdvisorModalOpen={setIsAdvisorModalOpen}
        isAdmin={isAdmin}
        membersList={membersList}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
      />
      <RepostModal
        open={openRepostModal}
        setOpen={setRepostModal}
        shareError={shareError}
        isAdmin={isAdmin}
        scheduleTime={scheduleTime}
        shareArticle={shareArticle}
        onShareClick={onShareClick}
        setRepostOptions={setRepostOptions}
        repostOptions={repostOptions}
        fromMyClients={fromMyClients}
        onClientShareClick={onClientShareClick}
        shareArticleSetState={shareArticleSetState}
      />

      <CustomizeShareModal
        toggleCustomizeModal={toggleCustomizeModal}
        setIsCustomizeModalOpen={setIsCustomizeModalOpen}
        isCustomizeModalOpen={isCustomizeModalOpen}
        source={source}
        title={title}
        time={time}
        imagesrc={imagesrc}
        contentLink={contentLink}
        pdfImage={pdfImage}
        rest={rest}
        summaryData={summaryData}
        setEmailTextData={setEmailTextData}
        isFromRecentTab={isFromRecentTab}
        isCloning={isCloning}
        article={article}
        style={{ background: '#ececec' }}
        isFromAddContent={isFromAddContent}
        isFromScheduledTab={isFromScheduledTab}
        selectedItem={selectedItem}
        setIsContentChanged={setIsContentChanged}
        isComplianceAdmin={isComplianceAdmin}
        isContentChanged={isContentChanged}
        setEmailShareData={setEmailShareData}
        complianceCustomisedData={complianceCustomisedData}
        approvedArticles={approvedArticles}
        approvedCustomizedData={approvedCustomizedData}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const userRole = getUserRole(state);
  const isAdminUser = userRole === USER_ROLE.admin;
  const roleType = getRoleType(state);
  const editable = ownProps.editable;
  // roleType === ROLE_TYPES.ADVISOR_ENTERPRISE ? false : ownProps.editable;
  let showMio = true;
  if (userRole !== USER_ROLE.admin) {
    showMio = false;
  }
  const isEnterpriseAdvisor = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  const showComplianceCheck = ROLE_TYPES.ADMIN_ENTERPRISE === roleType;
  const isComplianceAdmin = ROLE_TYPES.ADMIN_COMPLIANCE === roleType;
  const disabledPlatforms = get(
    state.user,
    'data.details.user.member_settings.disabled_social_platforms',
    ''
  );
  return {
    socialNetworkData: state.socialNetwork.data,
    shareError: state.share.error,
    shareData: state.share.data,
    isSharing: state.share.isFetching,
    adminChannelId: getChannelId(state),
    userRole,
    showMio,
    editable,
    showComplianceCheck,
    userData: state.user.data,
    isEnterpriseAdvisor,
    isPremiumAdvisor,
    disabledPlatforms: disabledPlatforms && JSON.parse(disabledPlatforms),
    membersList: state.user.membersList && [
      {
        id: 'selectAll',
        details: {
          name: { email: 'Select All', first: 'Select', last: 'All' },
        },
      },
      ...state.user.membersList,
    ],
    premiumAdvisorChannelId: state.channel.premiumAdvisorChannelId,
    isComplianceAdmin,
    isAdminUser: isAdminUser,
  };
};

const mapDispatchToProps = {
  shareArticleRequest: shareActions.api.share.request,
  shareArticleError: shareActions.api.share.error,
  shareArticleSetState: shareActions.api.share.setstate,
  articleDetailRequest: articleDetailsActions.api.articleDetails.request,
  newsletterSetState: newsletterActions.api.newsletter.setstate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShareContentContainer));
//https://www.feedforall.com/blog-feed.xml
