import { Flex } from "@rebass/grid";
import styled from "styled-components";
import { themesData } from "../../utils";

export const TemplateWrapper = styled(Flex).attrs({
    className: 'template-wrapper'
})`
    color: #000;
    padding: 20px 30px 0px;
    font-family: 'Poppins';
`;

export const TemplateCardWrapper = styled.div.attrs({
    className: 'template-card-wrapper'
})`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 15px 0px;
`;

export const TemplateCard = styled.div.attrs({
    className: 'template-card'
})`
    display: flex;
    flex-direction: column;
    height: 275px;
    width: 23%;
    margin: 10px;
    background-color: ${themesData.white};
    border-radius: 8px;
    border: 1px solid ${themesData.greyWhite};
`