/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import { get, isEmpty, cloneDeep } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Button, LoaderWrapper, MioDropdown } from '../../../../components';
import { RenameCampaignModal } from './RenameCampaignModal';
import { SaveDupCampaignModal } from './SaveDupCampaignModal';
import { DeleteCampaignModal } from './DeleteCampaignModal';
import PreviewModal from '../../Preview&ViewInfoModal/PreviewModal';
import ViewInfoModal from '../../Preview&ViewInfoModal/ViewInfoModal';
import ExportToPdf from './ExportToPdf';
import RescheduleModal from './RescheduleModal';
import { PauseCancelConfirmModal } from './PauseCancelConfirmModal';
import { getCampaignDetails, getCanDuplicateSeries, postIgnoreCascade, postUseCascadedCampaign, sendTestEmailOneTime } from '../../../../services/campaign';
import CascadeCampaignModal from './CascadeCampaignModal';
import IgnoreCascadeModal from './IgnoreCascadeModal';
import { useHistory } from 'react-router-dom';
import { SendTestMailModal } from '../../../AutomatedCampaigns/CampaignCreateSteps/SendTestMailModal';

const CampaignMenu = ({
  detailsHandler,
  id,
  editLoader,
  mode,
  type,
  setShowSeries,
  showSeries,
  title,
  campaignRequest,
  addCampaignFlow,
  status,
  platforms,
  seriesId,
  filters,
  selectedCampaignTab,
  category,
  setFilters,
  setSelectedCampaignTab,
  isadvMode,
  campaigns,
  testId,
  canDuplicate,
  userData,
  engagement_status,
  socialNetworkData,
  isEnterpriseAdvisor,
  onFilterTabChange,
  is_cascaded,
  blockCreate,
  is_member_only,
  approved_to_share,
  record_type,
  campaignSetState,
  is_email,
  is_twitter,
  is_facebook,
  is_linkedin,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  segmentList,
  segment,
  start_date,
  item,
  handleDropdown,
  seriesDropdownLoader,
  seriesSubcampaigns,
  fetchSubCampaigns,
  has_email_template
}) => {

  console.log('ITEM - --  -', is_email);
  const history = useHistory();
  // console.log('CONTACT GROUPS = = = = =', contactGroups);
  const [ldlocal, setLdlocal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSaveDupModal, setOpenSaveDupModal] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [currentFlow, toggleCurrentFlow] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openViewInfoModal, setOpenViewInfoModal] = useState(false);
  const [openExportPdfModal, setOpenExportPdfModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [fromViewInfo, toggleFromViewInfo] = useState(false);
  const [fromSendToMore, toggleFromSendToMore] = useState(false);
  const [selectedEmailContacts, setSelectedEmailContacts] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedLnContacts, setSelectedLnContacts] = useState([]);
  const [selectedFbContacts, setSelectedFbContacts] = useState([]);
  const [selectedTwContacts, setSelectedTwContacts] = useState([]);
  const [campaignType, setCampaignType] = useState('');
  const [selectedEmailContactsOG, setSelectedEmailContactsOG] = useState([]);
  const [selectedLnContactsOG, setSelectedLnContactsOG] = useState([]);
  const [selectedFbContactsOG, setSelectedFbContactsOG] = useState([]);
  const [selectedTwContactsOG, setSelectedTwContactsOG] = useState([]);
  const [contactGroups, setContactGroups] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [ascEmOrder, setAscEmOrder] = useState(false);
  const [ascFbOrder, setAscFbOrder] = useState(false);
  const [ascLnOrder, setAscLnOrder] = useState(false);
  const [resendModal, toggleResendModal] = useState(false);
  const [campaignsDataArray, setCampaignsDataArray] = useState([]);
  const [cascadeCampaignModal, toggleCascadeCampaignModal] = useState({status: false, id: '', campaigns: []});
  const [ignoreCascadeModal, toggleIgnoreCascadeModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [useCampaignLoader, toggleUseCampaignLoader] = useState(false);
  const [canDuplicateLoader, toggleCanDuplicateLoader] = useState(false);
  const [pauseResumeModal, setPauseResumeModal] = useState({
    title: '',
    state: false,
  });
  const [canDuplicateSeries, toggleCanDuplicateSeries] = useState(false);
  const [openSendTestModal, setOpenSendTestModal] = useState(false);
  const [testEmails, setTestEmails] = useState('');
  const [sendTestErrorMsg, setSendTestErrorMsg] = useState('');
  const [sendTestLoading, setSendTestLoading] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [detailsLoading, setDetailsLoading] = useState(false);

  useEffect(() => {
    if (is_email === "true") toggleCurrentFlow('emailOnly');
    else toggleCurrentFlow('socialOnly');
  }, []);


  const handleOneTimeModalOpen = (id) => {
    setDetailsLoading(true);
    setOpenSendTestModal(true);
    getCampaignDetails(id).then(res => {
      setDetailsLoading(false);
      setSelectedSubject(get(res, 'result.data.newsletter.customized_data.subject', []));
    }).catch(err => {
      setDetailsLoading(false);
    })
  }

  const handleSendTestValidation = () => {
    if (!testEmails) {
      return true;
    }
  };

  const handleSendClick = () => {
    console.log('clicked');
    // TO DO HANDLE EMAIL VALIDATION AND COUNT VALIDATION
    setSendTestErrorMsg('');
    let msg = 'Email is not valid';
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let valid = true;
    if (valid && !isEmpty(testEmails)) {
      valid = testEmails
        .split(',')
        .map((em) => em.trim())
        .every((em) => isEmailRegEx.test(em.toLowerCase()));
      if (testEmails.split(',').length > 5 && valid) {
        valid = false;
        msg = 'Maximum 5 emails can be added';
      }
    }
    if (!valid) {
      setSendTestErrorMsg(msg);
      return;
    };
    setSendTestLoading(true);
    const payload = {
      campaign_id: id,
      email_ids: JSON.stringify(testEmails.split(','))
    }
    sendTestEmailOneTime(payload)
      .then((res) => {
        console.log('send')
        if (get(res, 'result.success', false)) {
          setSendTestLoading(false);
          setSendTestErrorMsg(
            'Sent Successfully'
          );
          setTimeout(() => {
            setSendTestErrorMsg('');
            setSendTestLoading(false);
            setOpenSendTestModal(false);
            setSelectedSubject('');
          }, 3000);
        } else {
          setSendTestLoading(false);
          setSendTestErrorMsg(
            'OOPS!! Some error occurred'
          );
        }
        setTestEmails('');
      })
      .catch((error) => {
        console.log(error)
        setSendTestLoading(false);

      });
    setSendTestErrorMsg();
  };

  const clickHandler = (isSave) => {
    setIsSave(isSave);
    if(seriesId) {
      toggleCanDuplicateLoader(true);
      setLdlocal(true);
      getCanDuplicateSeries({ id: seriesId })
      .then(res => {
        const canDuplicate = get(res, 'result.data.can_duplicate', false);
        console.log('RESPONSE CAN DUPLICATE SERIES - - -- ', canDuplicate);
        if(canDuplicate)
          toggleCanDuplicateSeries(true);
        toggleCanDuplicateLoader(false);
        setLdlocal(false);
        setOpenSaveDupModal(true);
      })
      .catch(err => {
        console.log('ERROR - - - -- -', err);
      });
    }
    else
      setOpenSaveDupModal(true);
  };

  
  const redirectToView = () => {
      history.push(`/campaign/view/${id}`)
  };


  const handleExportPdfModalClose = () => {
    setOpenExportPdfModal(false);
  };

  const getFilters = () => {
    if (filters) {
      return filters;
    } else {
      let tab = '';

      if (selectedCampaignTab === 0) {
        tab = '';
      } else if (selectedCampaignTab === 1) {
        tab = 'draft';
      } else if (selectedCampaignTab === 2) {
        tab = 'approval_pending';
      } else if (selectedCampaignTab === 3) {
        tab = 'approval_rejected';
      } else if (selectedCampaignTab === 4) {
        tab = 'compliance_pending';
      } else if (selectedCampaignTab === 5) {
        tab = 'compliance_rejected';
      } else if (selectedCampaignTab === 6) {
        tab = 'scheduled';
      } else if (selectedCampaignTab === 7) {
        tab = 'in_progress';
      } else if (selectedCampaignTab === 8) {
        tab = 'completed';
      }

      if (category === '' && tab === '') {
        return;
      }

      if (category === '' && tab !== '') {
        return { status: [tab] };
      } else if (tab === '' && category !== '') {
        return { type: [category] };
      } else {
        return { type: [category], status: [tab] };
      }
    }
  };


  const openResendModalHandler = campaignData => {
    console.log('CAMPAIGN DATA - - -- ', campaignData, 'SEGMENT - - - - ', segment);
    const emList = !isEmpty(campaignData) ? campaignData.filter(campaign => campaign.platform === 'EMAIL' || campaign.platform === 'Email') : [];
    const fbList = !isEmpty(campaignData) ? campaignData.filter(campaign => campaign.platform === 'FACEBOOK' || campaign.platform === 'Facebook') : [];
    const twList = !isEmpty(campaignData) ? campaignData.filter(campaign => campaign.platform === 'TWITTER' || campaign.platform === 'Twitter') : [];
    const lnList = !isEmpty(campaignData) ? campaignData.filter(campaign => campaign.platform === 'LINKEDIN' || campaign.platform === 'Linkedin') : [];
      
    if (!isEmpty(emList)) {
        if (isEmpty(fbList) && isEmpty(twList) && isEmpty(lnList))
            setCampaignType('emailOnly');
        else
            setCampaignType('both');
    }
    else
        setCampaignType('socialOnly');

    if(segment && segment.length > 0) 
      setSelectedSegments(emList.map(em => ({
        ...em, 
        destination_id: em.destination_id_hash, 
        platform: em.platform.toUpperCase(),
        datetime: em.dateTime || em.datetime
      })));
    else {
      const processedEmList = emList.map(em => ({
        ...em, 
        destination_id: em.destination_id_hash, 
        platform: em.platform.toUpperCase(),
        datetime: em.dateTime || em.datetime
      }));
      setSelectedEmailContacts(processedEmList);
      setSelectedEmailContactsOG(processedEmList);
    }

    setSelectedFbContacts(fbList.map(fb => ({
      ...fb, 
      platform: fb.platform.toUpperCase(),
      datetime: fb.dateTime || fb.datetime
    })));
    setSelectedTwContacts(twList.map(tw => ({
      ...tw, 
      platform: tw.platform.toUpperCase(),
      datetime: tw.dateTime || tw.datetime
    })));
    setSelectedLnContacts(lnList.map(ln => ({
      ...ln, 
      platform: ln.platform.toUpperCase(),
      datetime: ln.dateTime || ln.datetime
    })));
    setSelectedFbContactsOG(fbList);
    setSelectedTwContactsOG(twList);
    setSelectedLnContactsOG(lnList);
    setCampaignsDataArray([...emList, ...fbList, ...twList, ...lnList]);
    toggleResendModal(true);
  }


  const handleCheckboxClick = (item, type) => {
    if (type === 'em') {
      if(segment && segment.length > 0) {
        if(!selectedSegments.find(em => em.destination_id === item.id))
          setSelectedSegments([...selectedSegments, {
            destination_id: item.id, 
            destination: item.name,
            snetwork_uid: get(socialNetworkData, 'em[0].accountId', ''),
            platform: 'EMAIL',
            datetime: ''
          }]);
        else
          setSelectedSegments(selectedSegments.filter(em => em.destination_id !== item.id));
      }
      else {
        console.log('SELECTED EMAIL CONTACTS - - - -- ', selectedEmailContacts, 'ITEM. - - -- - ', item)
        if(!selectedEmailContacts.find(em => em.destination_id === item.id)) {
          setSelectedEmailContacts([...selectedEmailContacts, {
            destination_id: item.id, 
            destination: item.name,
            snetwork_uid: get(socialNetworkData, 'em[0].accountId', ''),
            platform: 'EMAIL',
            datetime: ''
          }]);
        }
        else
          setSelectedEmailContacts(selectedEmailContacts.filter(em => em.destination_id !== item.id))
      }
    }
    else if (type === 'ln') { 
        if(!selectedLnContacts.find(ln => ln.destination_id === item))
            setSelectedLnContacts([...selectedLnContacts, {
              destination_id: item, 
              snetwork_uid: get(socialNetworkData, 'ln[0].accountId', ''),
              platform: 'LINKEDIN',
              custom_text: selectedLnContactsOG[0].custom_text,
              datetime: ''
            }]);
        else 
            setSelectedLnContacts(selectedLnContacts.filter(ln => ln.destination_id !== item))
    }
    else if (type === 'tw') {
        if(!selectedTwContacts.find(tw => tw.destination_id === item))
            setSelectedTwContacts([...selectedTwContacts, {
              destination_id: item, 
              snetwork_uid: get(socialNetworkData, 'tw[0].accountId', ''),
              platform: 'TWITTER',
              custom_text: selectedTwContactsOG[0].custom_text,
              datetime: ''
            }]);
        else
            setSelectedTwContacts(selectedTwContacts.filter(tw => tw.destination_id !== item));
    }
    else {
        if(!selectedFbContacts.find(fb => fb.destination_id === item))
            setSelectedFbContacts([...selectedFbContacts, {
              destination_id: item, 
              snetwork_uid: get(socialNetworkData, 'fb[0].accountId', ''),
              platform: 'FACEBOOK',
              custom_text: selectedFbContactsOG[0].custom_text,
              datetime: ''
            }]);
        else
            setSelectedFbContacts(selectedFbContacts.filter(fb => fb.destination_id !== item));
    }
};

const handleAlphabeticalSort = (platform) => {
  switch (platform) {
    case 'em':
      if(ascEmOrder)
        setEmailList([...emailList].sort((a, b) => a.name.localeCompare(b.name)));
      else
        setEmailList([...emailList].sort((a, b) => b.name.localeCompare(a.name)));

      setAscEmOrder(!ascEmOrder);
      break;

    case 'fb':
      if(ascFbOrder)
        setSelectedFbContacts([...selectedFbContacts].sort((a,b) => a.name.localeCompare(b.name)));
      else
        setSelectedFbContacts([...selectedFbContacts].sort((a,b) => b.name.localeCompare(a.name)));
      setAscFbOrder(!ascFbOrder);
      break;

    case 'ln':
      if(ascLnOrder)
        setSelectedFbContacts([...selectedLnContacts].sort((a,b) => a.name.localeCompare(b.name)));
      else
        setSelectedFbContacts([...selectedLnContacts].sort((a,b) => b.name.localeCompare(a.name)));
      setAscLnOrder(!ascLnOrder);
      break;

    default:
      break;
  }
};

const handleSelectAll = platform => {
  if (platform === 'em' && !isEmpty(get(socialNetworkData, 'em', []))) {
    if (selectedEmailContacts.length === emailList.length) {
      setSelectedEmailContacts(selectedEmailContactsOG.map(em => ({
        ...em, 
        destination_id: em.destination_id_hash, 
        platform: em.platform.toUpperCase(),
        datetime: em.dateTime || em.datetime
      })));
    } 
    else {
      setSelectedEmailContacts(emailList.map(em => ({
        destination_id: em.id, 
        destination: em.name,
        snetwork_uid: get(socialNetworkData, 'em[0].accountId', ''),
        platform: 'EMAIL',
        datetime: ''
      })));
    }
  }

  if (platform === 'fb' && !isEmpty(get(socialNetworkData, 'fb', []))) {
    if (
      Object.keys(get(socialNetworkData, 'fb[0].page', [])).length ===
      selectedFbContacts.length
    ) {
      setSelectedFbContacts(selectedFbContactsOG.map(fb => ({
        ...fb, 
        platform: fb.platform.toUpperCase(),
        datetime: fb.dateTime || fb.datetime
      })));
    } else {
      setSelectedFbContacts(Object.keys(get(socialNetworkData, 'fb[0].page', [])).map(fb => ({
        destination_id: fb, 
        snetwork_uid: get(socialNetworkData, 'fb[0].accountId', ''),
        platform: 'FACEBOOK',
        datetime: ''
      })));
    }
  }

  if (platform === 'ln' && !isEmpty(get(socialNetworkData, 'ln', []))) {
    if (
      Object.keys(get(socialNetworkData, 'ln[0].page', [])).length + 1 ===
      selectedLnContacts.length
    ) {
      setSelectedLnContacts(selectedLnContactsOG.map(ln => ({
        ...ln, 
        platform: ln.platform.toUpperCase(),
        datetime: ln.dateTime || ln.datetime
      })));
    } else {
      setSelectedLnContacts([
        {
          destination_id: '0', 
          snetwork_uid: get(socialNetworkData, 'ln[0].accountId', ''),
          platform: 'LINKEDIN',
          datetime: ''
        },
        ...Object.keys(get(socialNetworkData, 'ln[0].page', [])).map(ln => ({
          destination_id: ln, 
          snetwork_uid: get(socialNetworkData, 'ln[0].accountId', ''),
          platform: 'LINKEDIN',
          datetime: ''
        })),
      ]);
    }
  }
};

const useCascadedCampaign = () => {
  const cascadeId = record_type === 'series' ? seriesId : id;
  toggleUseCampaignLoader(true);
  setError('');
  postUseCascadedCampaign({ cascadeId, recordType: record_type })
  .then(res => {
    toggleUseCampaignLoader(false);
    if(res?.result?.success) {
      toggleCascadeCampaignModal({status: true, id: get(res, 'result.data.id', ''), campaigns: get(res, 'result.data.campaigns', [])});
      setSuccess(true);
      if(record_type === "series") {
        campaignSetState({
          reviewCampaign: {
            campaigns: res.result.data.campaigns,
            fromReview: true
          }
        })
      }
    }
  })
  .catch(err => {
    console.log('ERR = = = = = =', err);
    toggleUseCampaignLoader(false);
    setError('*Oops! Some error occurred. Please try again later.');
  });
}

const reviewCampaignHandler = () => {
  console.log('cascade campaign id - - - ', cascadeCampaignModal.id);
  try {
    toggleUseCampaignLoader(true);
    detailsHandler(record_type === "series" ? cascadeCampaignModal.campaigns[0] : cascadeCampaignModal.id);
  }
  catch (err) {
    console.log('ERROR redirecting to edit flow', err);
    toggleUseCampaignLoader(false);
    setError('ERROR redirecting to edit flow');
  }
}

const ignoreHandler = () => {
  toggleUseCampaignLoader(true);
  setError('');
  const cascadeId = record_type === 'series' ? seriesId : id
  postIgnoreCascade({ cascadeId, recordType: record_type })
  .then(response => {
    console.log('IGNORE RESPONSE - - - ', response);
    if(get(response, 'result', false)) {
      setSuccess(true);
      setTimeout(() => campaignRequest('approved_to_share'), 3000);
    }
  })
  .catch(err => {
    console.log('ERROR IGNORING CASCADE - - - ', err);
    toggleUseCampaignLoader(false);
    setError('*Oops! Some error occurred. Please try again later.');
  });
}

const clearSeriesSubcampaign = () => {
  let newState = cloneDeep(seriesSubcampaigns);
  console.log('NEW STATE - - - - -', newState);
  delete newState[seriesId];
  campaignSetState({ seriesSubcampaigns: newState });
  setShowSeries(!showSeries);
}

const nextHandler = () => {
  console.log('NEXT HANDLER -- - -', selectedEmailContacts, selectedSegments);
  setCampaignsDataArray([...selectedEmailContacts, ...selectedFbContacts, ...selectedLnContacts, ...selectedTwContacts, ...selectedSegments]);
  toggleFromSendToMore(true);
  setRescheduleModal(true);
  toggleResendModal(false);
}

  return (
    <Flex justifyContent="center" alignItems="center" marginRight={'15px'}>
      {((type === 'series' || record_type === 'series') && !mode) && (
        <LoaderWrapper styles={{ margin: '35px' }} isLoading={seriesDropdownLoader}>
          <Button
            testId="expand-collapse"
            styles={{
              border: '1px solid #DBE0E6',
              borderRadius: '5px',
            }}
            color="blue"
            bgColor={'#eeeeee'}
            margin="20px"
            padding="4px 6px 0px 6px"
            onClick={handleDropdown}
          >
            <img
              src={
                showSeries
                  ? '/assets/images/drip/expand_less.svg'
                  : '/assets/images/drip/expand_more.svg'
              }
              alt=""
            />
          </Button>
        </LoaderWrapper>
      )}
      <LoaderWrapper isLoading={ldlocal && (editLoader || canDuplicateLoader)}>
        <MioDropdown
          testId={testId}
          id="campaignMenu"
          style={{ display: 'none' }}
          type="campaignDashbaord"
          background="#fff"
          hoverBackground="#6351ed"
          width="150px"
          fullWidth="150px"
          dropdownMenu={isEnterpriseAdvisor && selectedCampaignTab === 9 ? (
            <ul>
              <li
                data-testid="use-as-campaign"
                onClick={() => toggleCascadeCampaignModal({status: true, id: ''})}
              >
                Start Campaign
              </li>
              {record_type !== 'series' && (
                <li
                  data-testid="preview"
                  onClick={() => setOpenPreviewModal(true)}
                >
                  Preview
                </li>
              )}
              <li
                data-testid="ignore"
                onClick={() => toggleIgnoreCascadeModal(true)}
              >
                Ignore
              </li>
            </ul>
          ) :
            (<ul>
              {((type === 'one_time' || type === 'automated') || mode) &&
                status !== 'completed' &&
                status !== 'in_progress' &&
                status !== 'compliance_pending' &&
                status !== 'failed' &&
                selectedCampaignTab !== 9 && (
                  <li
                    data-testid="campaign-edit"
                    onClick={() => {
                      setLdlocal(true);
                      if(seriesId) 
                        clearSeriesSubcampaign();
                      detailsHandler(id, type);
                    }}
                  >
                    Edit
                  </li>
                )}
              {is_cascaded !== 'true' && status !== 'compliance_pending' && selectedCampaignTab !== 9 && (
                <li
                  data-testid="campaign-rename"
                  onClick={() => setOpenModal(true)}
                >
                  Rename
                </li>
              )}
              {type === 'one_time' && selectedCampaignTab !== 9 
                ? status !== 'completed' &&
                  status !== 'in_progress' && (
                    <li
                      data-testid="campaign-delete"
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      Delete
                    </li>
                  )
                : status !== 'compliance_pending' &&
                  status !== 'completed' &&
                  status !== 'in_progress' &&
                  selectedCampaignTab !== 9 && (
                    <li
                      data-testid="campaign-delete"
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      Delete
                    </li>
                  )}
              {/* </>
              )} */}
              {type === 'series' &&
                mode !== 'one_time' &&
                status !== 'completed' && 
                is_cascaded !== 'true' &&
                approved_to_share !== 'true' &&
                (
                  <li
                    data-testid="campaign-add"
                    onClick={() => addCampaignFlow(seriesId)}
                  >
                    Add Campaign
                  </li>
                )}
              {/* <li>
              <Link style={{ padding: '4px 5px' }} to="/campaignV2">
                Dashboard
              </Link>
            </li> */}
              {/* <li>{id}</li> */}
              {is_cascaded !== 'true' && selectedCampaignTab !== 9 && 
              type !== 'automated' && 
              (
                <li
                  data-testid="campaign-duplicate"
                  onClick={() => clickHandler(false)}
                >
                  Duplicate
                </li>
              )}
              {status !== 'draft' &&
                ((type === 'one_time' || type === 'automated') || (mode && mode === 'one_time')) && (
                  <li
                    data-testid="campaign-preview"
                    onClick={() => setOpenPreviewModal(true)}
                  >
                    Preview
                  </li>
                )}
              {(type === 'one_time' || (mode && mode === 'one_time')) && ((status === 'in_progress') || (status === 'completed')) && sessionStorage.getItem("superAdminEmail") && (
                <li
                  data-testId="campaign-viewDetails"
                  onClick={() => redirectToView()}
                >
                  View Detail
                </li>
              )}  
              {status !== 'draft' && !is_member_only && selectedCampaignTab !== 9 && (
                <li
                  data-testid="campaign-viewInfo"
                  onClick={() => setOpenViewInfoModal(true)}
                >
                  View Info
                </li>
              )}
              {/* {status !== 'draft' && status !== 'approval_rejected' && ( */}
              {status !== 'draft' && selectedCampaignTab !== 9 && (
                <li
                  data-testid="campaign-exportPdf"
                  onClick={() => setOpenExportPdfModal(true)}
                >
                  Export To Pdf
                </li>
              )}
              {(type === 'one_time' || (mode && mode === 'one_time')) && is_email === 'true' && has_email_template === 'true' && selectedCampaignTab !== 9 && (
                <li
                  data-testid="campaign-sendtestemail"
                  onClick={() => handleOneTimeModalOpen(id)}
                >
                  Send Test Email
                </li>
              )}
              {status === 'in_progress' &&
                ((type === 'one_time' || type === 'automated') || (mode && mode === 'one_time')) && (
                  <>
                    <li
                      data-testid="campaign-pauseresume"
                      onClick={
                        engagement_status == null ||
                        engagement_status === 'resumed'
                          ? () =>
                              setPauseResumeModal({
                                title: 'Pause',
                                state: true,
                              })
                          : () => setRescheduleModal(true)
                      }
                    >
                      {engagement_status == null ||
                      engagement_status === 'resumed'
                        ? 'Pause'
                        : 'Resume'}
                    </li>
                    <li
                      data-testid="campaign-pauseresume"
                      onClick={() =>
                        setPauseResumeModal({
                          title: 'Cancel',
                          state: true,
                        })
                      }
                    >
                      Cancel
                    </li>
                  </>
                )}
            </ul>)
          }
        >
          <FontAwesomeIcon
            style={{ cursor: 'pointer', fontSize: '25px' }}
            icon={faEllipsisV}
          />
        </MioDropdown>
      </LoaderWrapper>
      {title && (
        <RenameCampaignModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          campName={title}
          campaignRequest={campaignRequest}
          campaignId={id}
          seriesId={seriesId}
          filters={getFilters()}
          type={type}
          mode={mode}
          clearSeriesSubcampaign={clearSeriesSubcampaign}
        />
      )}
      {(id || seriesId) && (
        <>
          <DeleteCampaignModal
            open={openDeleteModal}
            handleClose={() => setOpenDeleteModal(false)}
            campaignRequest={campaignRequest}
            campaignId={id}
            seriesId={seriesId}
            filters={getFilters()}
            type={type}
            mode={mode}
            clearSeriesSubcampaign={clearSeriesSubcampaign}
          />
          {openPreviewModal && (
            <PreviewModal
              isOpen={openPreviewModal}
              handleClose={() => setOpenPreviewModal(false)}
              campaignId={id}
              seriesId={seriesId}
              type={type}
              subCampaigns={seriesSubcampaigns[seriesId]}
            />
          )}
        </>
      )}
      {(id || seriesId) && (
        <>
          {openViewInfoModal && (
            <ViewInfoModal
              isOpen={openViewInfoModal}
              handleClose={() => setOpenViewInfoModal(false)}
              campaignId={id}
              type={type}
              seriesId={seriesId}
              title={title}
              status={status}
              toggleEditScheduleModal={setRescheduleModal}
              toggleFromViewInfo={toggleFromViewInfo}
              toggleFromSendToMore={toggleFromSendToMore}
              socialNetworkData={socialNetworkData}
              campaignRequest={campaignRequest}
              handleCheckboxClick={handleCheckboxClick}
              handleAlphabeticalSort={handleAlphabeticalSort}
              handleSelectAll={handleSelectAll}
              openResendModalHandler={openResendModalHandler}
              resendModal={resendModal}
              toggleResendModal={toggleResendModal}
              selectedEmailContacts={selectedEmailContacts}
              selectedFbContacts={selectedFbContacts}
              selectedTwContacts={selectedTwContacts}
              selectedLnContacts={selectedLnContacts}
              selectedEmailContactsOG={selectedEmailContactsOG}
              selectedFbContactsOG={selectedFbContactsOG}
              selectedTwContactsOG={selectedTwContactsOG}
              selectedLnContactsOG={selectedLnContactsOG}
              setEmailList={setEmailList}
              contactGroups={contactGroups}
              setContactGroups={setContactGroups}
              emailList={emailList}
              campaignType={campaignType}
              ascFbOrder={ascFbOrder}
              ascLnOrder={ascLnOrder}
              nextHandler={nextHandler}
              segment={segment}
              segmentList={segmentList}
              selectedSegments={selectedSegments}
              setSelectedSegments={setSelectedSegments}
            />
          )}
          {openSaveDupModal && (
            <SaveDupCampaignModal
              isOpen={openSaveDupModal}
              handleClose={() => setOpenSaveDupModal(false)}
              isSave={isSave}
              campaignId={id}
              seriesId={seriesId}
              campaignRequest={campaignRequest}
              setFilters={setFilters}
              setSelectedCampaignTab={setSelectedCampaignTab}
              canDuplicate={seriesId ? canDuplicateSeries.toString() : canDuplicate}
            />
          )}
          {openExportPdfModal && (
            <ExportToPdf
              campaignId={id}
              seriesId={seriesId}
              title={title}
              handleClose={handleExportPdfModalClose}
              isOpen={openExportPdfModal}
              subCampaigns={campaigns}
              seriesSubcampaigns={seriesSubcampaigns}
            />
          )}
          {rescheduleModal && (
            <RescheduleModal
              handleClose={() => {
                toggleFromViewInfo(false);
                toggleFromSendToMore(false);
                setRescheduleModal(false);
              }}
              fromViewInfo={fromViewInfo}
              fromSendToMore={fromSendToMore}
              campaignId={id}
              type={type}
              seriesId={seriesId}
              userData={userData}
              campaignRequest={campaignRequest}
              filters={getFilters()}
              campaignsDataArray={campaignsDataArray}
              segment={segment}
            />
          )}
          {pauseResumeModal.state && (
            <PauseCancelConfirmModal
              handleClose={() => {
                setPauseResumeModal({
                  title: '',
                  state: false,
                });
              }}
              title={pauseResumeModal.title}
              campaignId={id}
              type={type}
              seriesId={seriesId}
              campaignRequest={campaignRequest}
              filters={getFilters()}
              segment={segment}
            />
          )}
        </>
      )}
      {cascadeCampaignModal.status && (
        <CascadeCampaignModal 
          close={() => toggleCascadeCampaignModal({status: false, id: ''})}
          okHandler={() => {
            toggleCascadeCampaignModal({status: false, id: ''});
            onFilterTabChange(0);
          }}
          useCascadedCampaign={useCascadedCampaign}
          loader={useCampaignLoader}
          success={success}
          error={error}
          blockCreate={blockCreate}
          recordType={record_type}
          reviewHandler={reviewCampaignHandler}
        />
      )}
      {ignoreCascadeModal && (
        <IgnoreCascadeModal
          close={() => toggleIgnoreCascadeModal(false)}
          yesHandler={ignoreHandler}
          loader={useCampaignLoader}
          success={success}
          error={error}
        />
      )}
      {openSendTestModal && (
        <SendTestMailModal
          isOpen={openSendTestModal}
          handleModalClose={() => {
            setOpenSendTestModal(false);
            setSelectedSubject('');
            setSendTestLoading('');
            setSendTestErrorMsg('');

          }}
          subject={selectedSubject}
          handleChange={(e) => setTestEmails(e.target.value)}
          value={testEmails}
          modalButtonOnClick={handleSendClick}
          validation={handleSendTestValidation()}
          sendTestErrorMsg={sendTestErrorMsg}
          loading={sendTestLoading}
          detailsLoading={detailsLoading}
        />
      )}
    </Flex>
  );
};

export default CampaignMenu;
