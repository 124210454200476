import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const ModalArticlesContainer = styled(Flex)`
  border: ${({ border }) => border || '1px solid #cfd9e4'};
  border-radius: 4px;
  justify-content: flex-start;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  ${'' /* width: 80%; */}
  padding: ${({ padding }) => padding || '9px'};
  height: 40%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${({ bgColor }) => bgColor || '#f6f9fb'};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #e9f6e6 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #bccdd4 !important;
  }
  ${'' /* margin-bottom: ${({ mb }) => mb || '25px'}; */}
`;