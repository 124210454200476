import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faMousePointer, faUsers } from '@fortawesome/free-solid-svg-icons';

import {
  Tabs,
  StyledTabListLeft,
  TabTypeTwo,
  VerticalTabs,
  StackedBarChart,
  ReachIcon,
  NumbersWithArrows,
  DateDropdown,
  DownloadButton,
} from '../../../components';
import { AdminSubTab, DashboardAdminSubTab } from '../../DashboardContainer/DashboardContainer.styles';
import {
  getTabCount,
  getTabNameFromRepType,
  newTabCount,
  parseValueToCsv,
  saveFile,
} from '../../../utils';
import {
  AnalyticsVerticalTab,
  AnalyticsVerticalTabList,
  AnalyticsVerticalTabPanel,
  TabCount,
  TabLabel,
  TabContent,
  CommonTabPanel,
  DownloadButtonFlex,
  platformChartIcons,
} from '../AnalyticsContainer.styles';
import { Box } from '@rebass/grid';

const styles = {
  adminSubTab: { fontSize: '14px', marginBottom: '20px' }
};

const ImpactTab = ({
  selectedDate,
  setSelectedDate,
  analyticsRequest,
  isAnalyticsDataFetching,
  selectedRepType,
  analyticsObject,
  tabContentType,
  channelId
}) => {
  const [reachTabIndex, setReachTabIndex] = useState(0);
  const [clicksTabIndex, setClicksTabIndex] = useState(0);
  const [topAnalyticsSelectTabIndex, setTopAnalyticsSelectTabIndex] = useState(0);
  const [engagementTabIndex, setEngagementTabIndex] = useState(0);

  const apiRequest = (repType) => {
    if(channelId) {
      analyticsRequest(
        {
          params: {
            repType,
          },
        },
        repType
      );
    }
  };
  console.log(reachTabIndex);

  const refreshAllTabs = () => {
    onTabSelect(2);
    onTabSelect(1);
    onTabSelect(0);
  };

  const onReachTabSelect = (tabIndex) => {
    setReachTabIndex(tabIndex);
    let repType = tabIndex === 0 ? 'reach_by_topic' : 'reach_by_platform';
    apiRequest(repType);
  };

  const onClicksTabSelect = (tabIndex) => {
    setClicksTabIndex(tabIndex);
    let repType = tabIndex === 0 ? 'click_by_topic' : 'click_by_platform';
    apiRequest(repType);
  };

  const onTopAnalyticsSelect = (tabIndex) => {
    setTopAnalyticsSelectTabIndex(tabIndex);
    let repType = tabIndex === 0 ? 'topic_analysis' : 'most_shared';
    apiRequest(repType);
  };

  const onTabSelect = (tabIndex) => {
    if (tabIndex === 0) {
      onReachTabSelect(reachTabIndex);
    } else if (tabIndex === 1) {
      onClicksTabSelect(clicksTabIndex);
    } else if (tabIndex === 2) {
      onTopAnalyticsSelect(topAnalyticsSelectTabIndex);
    }
  };

  const legends = {
    tw: [
      {id: 'Likes', type: 'square', value: 'Likes', color: '#7DC4D0'},
      {id: 'Retweets', type: 'square', value: 'Retweets', color: '#C95CFC'},
      {id: 'Replies', type: 'square', value: 'Replies', color: '#8F84E3'},
    ],
    fb: [
      {id: 'Likes', type: 'square', value: 'Likes', color: '#7DC4D0'},
      {id: 'Comments', type: 'square', value: 'Comments', color: '#C95CFC'},
      {id: 'Reactions', type: 'square', value: 'Reactions', color: '#8F84E3'},
    ]
  }

  useEffect(() => {
    refreshAllTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, channelId]);
  const reachByTopic = get(analyticsObject, `reach_by_topic`);
  const reachByTopicData = get(reachByTopic, `slicedData`);
  const reachByTopicPreviousValue = get(reachByTopic, `previousValue.mine`, 1) === 0 ? 1 : get(reachByTopic, `previousValue.mine`, 1);
  const reachByPlatformData = get(
    analyticsObject,
    `reach_by_platform.slicedData`
  );

  const clickByTopic = get(analyticsObject, `click_by_topic`);
  const clickByTopicData = get(clickByTopic, `slicedData`);
  const clickByTopicPreviousValue = get(reachByTopic, `previousValue.mine`, 1) === 0 ? 1 : get(reachByTopic, `previousValue.mine`, 1);
  const clickByPlatformData = get(
    analyticsObject,
    `click_by_platform.slicedData`
  );

  const engagementPreviousValue = get(
    analyticsObject,
    'user_network_engagement.previousValue'
  );
  const engagementTabCountData = getTabCount(
    get(analyticsObject, `user_network_engagement.data`),
    engagementPreviousValue
  );

  const mostShared = get(analyticsObject, `most_shared`);
  let engagementPlatformData = {tw: [], fb: []};
  const mostSharedData = get(mostShared, `slicedData`);
  const mostSharedPreviousValue = get(mostShared, `previousValue`);
  const mostSubscribedData = get(analyticsObject, `topic_analysis.slicedData`);
  let csvData = get(analyticsObject, `${selectedRepType}.data`, []);
  csvData = csvData.filter(c => c.total > 0 || c.value > 0);
  const reactTabCountData = reachTabIndex === 0 ? newTabCount(
    get(reachByTopic, `total`),
    get(reachByTopic, 'previousValue'),
  ) : (
    newTabCount(
      get(analyticsObject, `reach_by_platform.total`),
      get(analyticsObject, 'reach_by_platform.previousValue'),
    )
  )
  const clicksTabCountData = clicksTabIndex === 0 ? newTabCount(
    get(clickByTopic, `total`),
    get(clickByTopic, `previousValue`)
  ) : (
    newTabCount(
      get(analyticsObject, `click_by_platform.total`),
      get(analyticsObject, 'click_by_platform.previousValue'),
    )
  )
  const topicAnalysisPreviousValue =  get(analyticsObject, 'topic_analysis.previousValue.mine', 1)  === 0 ? 1 :  get(analyticsObject, 'topic_analysis.previousValue.mine', 1);
  const topicAnalysisTabCountData = topAnalyticsSelectTabIndex === 0 ? getTabCount(
    get(analyticsObject, `topic_analysis.data`),
    topicAnalysisPreviousValue,
    true
  ) : (
    getTabCount(
      get(analyticsObject, `most_shared.data`),
      get(analyticsObject, `most_shared.previousValue`),
      true
    ) 
  )

  const onDownloadCSVClick = () => {
    const tabName = getTabNameFromRepType(selectedRepType);
    const { startDate, endDate } = analyticsObject || {};
    const fileName = `${tabName}-${startDate}_${endDate}`;
    
    const parsedData = parseValueToCsv(
      csvData,
      selectedRepType
    );
    if(csvData.length > 0) {
      saveFile(parsedData, fileName);
    }
  };

  const onEngagmentTabSelect = (tabIndex) => {
    setEngagementTabIndex(tabIndex);
    let repType = tabIndex === 0 ? 'twitter' : 'facebook';

  }

  return (
    <VerticalTabs customPadding='0px' screenWidth={window.innerWidth} onSelect={onTabSelect}>
      <AnalyticsVerticalTabList screenWidth={window.innerWidth}>
        <AnalyticsVerticalTab>
          <TabContent>
            {/* <ReachIcon /> */}
            <img src='/assets/images/reach.png' />
            <div style={{ padding: '2px'}}>
              <TabLabel fontSize='16px'>Reach</TabLabel>
            </div>
            <div style={{ padding: '2px'}}>
              <TabCount fontSize='28px'>{reactTabCountData.tabCount}</TabCount>
            </div>
            <div style={{ padding: '2px'}}>
              {reactTabCountData.tabCount !== 0 ? (
                <TabLabel fontSize='16px'>
                  <NumbersWithArrows
                    value={reactTabCountData.trend}
                    suffix="%"
                  />
                </TabLabel>
              ) : null}
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faComment} />
            <div style={{ padding: '2px'}}>
              <TabLabel fontSize='16px'>Clicks</TabLabel>
            </div>
            <div style={{ padding: '2px'}}>
              <TabCount fontSize='28px'>{clicksTabCountData.tabCount}</TabCount>
            </div>
            <div style={{ padding: '2px'}}>
              {clicksTabCountData.tabCount !== 0 ? (
                <TabLabel fontSize='16px'>
                  <NumbersWithArrows
                    value={clicksTabCountData.trend}
                    suffix="%"
                  />
                </TabLabel>
              ) : null}
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        {/* <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faMousePointer} />
            <div style={{ padding: '2px'}}>
              <TabLabel fontSize='16px'>Topic Analysis</TabLabel>
            </div>
            <div style={{ padding: '2px'}}>
              <TabCount fontSize='28px'>{topicAnalysisTabCountData.tabCount}</TabCount>
            </div>
            <div style={{ padding: '2px'}}>
              {topicAnalysisTabCountData.tabCount !== 0 ? (
                <TabLabel fontSize='16px'>
                  <NumbersWithArrows
                    value={topicAnalysisTabCountData.trend}
                    suffix="%"
                  />
                </TabLabel>
              ) : null}
            </div>
          </TabContent>
        </AnalyticsVerticalTab> */}
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faUsers} />
            <div style={{padding: '2px'}}>
              <TabLabel fontSize='16px'>Engagement</TabLabel>
            </div>
            <div style={{padding: '2px'}}>
              <TabCount fontSize='28px'>{engagementTabCountData.tabCount}</TabCount>
            </div>
            <div style={{padding: '2px'}}> 
              {
                engagementTabCountData.tabCount !== 0 ?
                  <TabLabel fontSize='16px'>
                    <NumbersWithArrows
                    value={engagementTabCountData.trend}
                    suffix="%"
                    />
                  </TabLabel>
                  :
                  null
              }
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
      </AnalyticsVerticalTabList>
      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Tabs selectedIndex={reachTabIndex} onSelect={onReachTabSelect}>
          <StyledTabListLeft marginLeft='0px' screenWidth={window.innerWidth}>
            <DashboardAdminSubTab style={styles.adminSubTab} color='#49484a' padding='5px 23px 3px' type='analytics-tab' className={`${reachTabIndex === 0 ? 'active' : 'inactive'}`}>By Topic</DashboardAdminSubTab>
            <DashboardAdminSubTab style={styles.adminSubTab} color='#49484a' padding='5px 23px 3px' type='analytics-tab' className={`${reachTabIndex === 1 ? 'active' : 'inactive'}`}>By Platform</DashboardAdminSubTab>
          </StyledTabListLeft>
          <CommonTabPanel>
            {/* <DateDropdown
              value={selectedDate}
              onDateChange={setSelectedDate}
              type="analytics"
            /> */}
            <StackedBarChart
              tabContentType={tabContentType}
              yKey={'Reach'}
              loading={isAnalyticsDataFetching}
              data={reachByTopicData}
            />
          </CommonTabPanel>
          <CommonTabPanel>
            {/* <DateDropdown
              // top="-20px"
              value={selectedDate}
              onDateChange={setSelectedDate}
              type='analytics'
            /> */}
            <StackedBarChart
              tabContentType={tabContentType}
              yKey={'Reach'}
              loading={isAnalyticsDataFetching}
              data={reachByPlatformData}
              xAxisIcons={platformChartIcons}
            />
          </CommonTabPanel>
          <DownloadButtonFlex>
            <DownloadButton disabled={csvData.length > 0 ? false : true} onClick={onDownloadCSVClick} fontSize='18px' />
          </DownloadButtonFlex>
        </Tabs>
      </AnalyticsVerticalTabPanel>
      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Tabs selectedIndex={clicksTabIndex} onSelect={onClicksTabSelect}>
          <StyledTabListLeft marginLeft='0'>
            <DashboardAdminSubTab style={styles.adminSubTab} color='#49484a' padding='5px 23px 3px' type='analytics-tab' className={`${clicksTabIndex === 0 ? 'active' : 'inactive'}`}>By Topic</DashboardAdminSubTab>
            <DashboardAdminSubTab style={styles.adminSubTab} color='#49484a' padding='5px 23px 3px' type='analytics-tab' className={`${clicksTabIndex === 1 ? 'active' : 'inactive'}`}>By Platform</DashboardAdminSubTab>
          </StyledTabListLeft>
          <CommonTabPanel>
            {/* <DateDropdown
              value={selectedDate}
              onDateChange={setSelectedDate}
              type="analytics"
            /> */}
            <StackedBarChart
              tabContentType={tabContentType}
              yKey={'Clicks'}
              loading={isAnalyticsDataFetching}
              data={clickByTopicData}
            />
          </CommonTabPanel>
          <CommonTabPanel>
            {/* <DateDropdown
              value={selectedDate}
              onDateChange={setSelectedDate}
              type="analytics"
            /> */}
            <StackedBarChart
              tabContentType={tabContentType}
              yKey={'Clicks'}
              loading={isAnalyticsDataFetching}
              data={clickByPlatformData}
              xAxisIcons={platformChartIcons}
            />
          </CommonTabPanel>
          <DownloadButtonFlex>
            <DownloadButton disabled={csvData.length > 0 ? false : true} onClick={onDownloadCSVClick} fontSize='18px'  />
          </DownloadButtonFlex>
        </Tabs>
      </AnalyticsVerticalTabPanel>
      {/* <AnalyticsVerticalTabPanel screenWidth={window.innerWidth}>
        <Tabs onSelect={onTopAnalyticsSelect}>
          <StyledTabListLeft marginLeft='0'>
            <DashboardAdminSubTab style={styles.adminSubTab} color='#49484a' padding='5px 23px 3px' type='analytics-tab' className={`${topAnalyticsSelectTabIndex === 0 ? 'active' : 'inactive'}`}>By Topic</DashboardAdminSubTab>
            <DashboardAdminSubTab style={styles.adminSubTab} color='#49484a' padding='5px 23px 3px' type='analytics-tab' className={`${topAnalyticsSelectTabIndex === 1 ? 'active' : 'inactive'}`}>By Platform</DashboardAdminSubTab>
          </StyledTabListLeft>
          <CommonTabPanel>
            <DateDropdown
              value={selectedDate}
              onDateChange={setSelectedDate}
              type="analytics"
            />
            <StackedBarChart
              tabContentType={tabContentType}
              yKey={'Clicks'}
              loading={isAnalyticsDataFetching}
              data={mostSubscribedData}
            />
          </CommonTabPanel>
          <CommonTabPanel>
            <DateDropdown
              value={selectedDate}
              onDateChange={setSelectedDate}
              type="analytics"
            />
            <StackedBarChart
              tabContentType={tabContentType}
              yKey={'Clicks'}
              loading={isAnalyticsDataFetching}
              data={mostSharedData}
            />
          </CommonTabPanel>
          <DownloadButtonFlex>
            <DownloadButton disabled={csvData.length > 0 ? false : true} onClick={onDownloadCSVClick} fontSize='18px'  />
          </DownloadButtonFlex>
        </Tabs>
      </AnalyticsVerticalTabPanel> */}
       <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth} >
        <Box width={1}>
          <Tabs onSelect={onEngagmentTabSelect}>
            <StyledTabListLeft>
              <DashboardAdminSubTab style={styles.adminSubTab} color='#49484a' padding='5px 23px 3px' type='analytics-tab' className={`${engagementTabIndex === 0 ? 'active' : 'inactive'}`}>Twitter</DashboardAdminSubTab>
              <DashboardAdminSubTab style={styles.adminSubTab} color='#49484a' padding='5px 23px 3px' type='analytics-tab' className={`${engagementTabIndex === 1 ? 'active' : 'inactive'}`}>Facebook</DashboardAdminSubTab>
            </StyledTabListLeft>
            {/* <CommonVerticalTabPanel>
              <DateDropdown value={selectedDate} onDateChange={setSelectedDate} type='analytics'/>
              <StackedBarChart
                yKey={''}
                loading={isAnalyticsDataFetching}
                data={engagementData}
                tabContentType={'Engagement'}
                xAxisIcons={platformChartIcons}
              />
            </CommonVerticalTabPanel> */}
            <CommonTabPanel>
              {/* <DateDropdown value={selectedDate} onDateChange={setSelectedDate} type='analytics'/> */}
              <StackedBarChart
                yKey={''}
                loading={isAnalyticsDataFetching}
                data={engagementPlatformData.tw}
                tabContentType={'twEngagement'}
                xAxisIcons={platformChartIcons}
                legends={legends.tw}
              />
            </CommonTabPanel>
            <CommonTabPanel>
              {/* <DateDropdown value={selectedDate} onDateChange={setSelectedDate} type='analytics'/> */}
              <StackedBarChart
                yKey={''}
                loading={isAnalyticsDataFetching}
                data={engagementPlatformData.fb}
                tabContentType={'fbEngagement'}
                xAxisIcons={platformChartIcons}
                legends={legends.fb}
              />
            </CommonTabPanel>
            <DownloadButtonFlex>
              <DownloadButton disabled={csvData.length > 0 ? false : true} onClick={onDownloadCSVClick} fontSize='18px' />
            </DownloadButtonFlex>
          </Tabs>
        </Box>
      </AnalyticsVerticalTabPanel>
    </VerticalTabs>
  );
};

export default ImpactTab;
