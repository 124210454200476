import { Flex } from '@rebass/grid';
import React, { Children, cloneElement } from 'react';
import { TemplateSelectorDiv } from './TemplateSelector.styles';

const TemplateSelector = ({
  children,
  selectedValue,
  setSelectedValue,
  className,
  fromNewsletter,
  styles,
  loneTemplate
}) => {
  const handleOnChange = (value) => {
    if (value !== selectedValue) {
      if(fromNewsletter)
        setSelectedValue(value);
      else 
        setSelectedValue({name: value.name, baseTemplate: value.baseTemplate, isCascade: value.isCascade ? value.isCascade : false, owner: value.owner, id: value.id});
    }
  };

  return fromNewsletter ? (
    <Flex justifyContent="space-between" style={{ cursor: 'pointer' }}>
      {Children.map(children, (child) => {
        return cloneElement(child, {
          selectedValue: selectedValue,
          onChange: handleOnChange,
        });
      })}
    </Flex>
  ) : (
    <TemplateSelectorDiv loneTemplate={loneTemplate} style={styles || {}}>
      {Children.map(children, (child) => {
        return cloneElement(child, {
          selectedValue: selectedValue,
          onChange: handleOnChange,
        });
      })}
    </TemplateSelectorDiv>
  );
};

export default TemplateSelector;
