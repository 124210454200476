import { isEmpty } from 'lodash';
import React from 'react';
import { Input, Button, LoaderWrapper, MioSelect } from '../../../../../components'
import './StepOneComponent.css';

const stepOneComponent = ({ loginDetails, changeHandler, loginHandler, isAdmin, verificationStatus, sgData, nextLoader, domainList }) => {
    return (
        <div style={{ margin: 'auto', width: '70%', textAlign: 'left' }}>
            <label style={{ color: '#49484a' }} htmlFor='from_name'>From Name:</label>
            <Input
                id='from_name'
                name='from_name'
                largeInput={true}
                onChange={changeHandler}
                value={loginDetails.from_name} />
            <label style={{ color: '#49484a' }} htmlFor='email'>Email:</label>
            <Input
                id='email'
                name='email'
                largeInput={true}
                onChange={changeHandler}
                value={loginDetails.email} />
            <label style={{ color: '#49484a' }} htmlFor='domain_name'>Domain Name:</label>
            {!isAdmin ? (
                <MioSelect name="domain_name" height="34px" width="60%" margin="0px 10px" value={loginDetails.domain_name} onChange={changeHandler}>
                    <option value="">Please Select</option>
                    {!isEmpty(domainList) && domainList.map((item) => (
                        <option value={item.domain}>{item.domain}</option>
                    ))}
                </MioSelect>
            ) : (
                <Input
                id='domain_name'
                name='domain_name'
                largeInput={true}
                bgColor={!isAdmin ? '#adadad' : ''} 
                placeholder={!isAdmin ? loginDetails.domain_name : ''}
                placeholderColor='#555'
                onChange={changeHandler}
                value={loginDetails.domain_name} />
            )}
            
            <div style={{ width: '100%', textAlign: 'center' }}>
                <LoaderWrapper isLoading={nextLoader}>
                    <Button margin="20px 0px" className='connect-btn-sendgrid' disabled={!loginDetails.valid} onClick={loginHandler}>{isAdmin ? 'Next' : 'Connect'}</Button>
                </LoaderWrapper>
            </div>
        </div>
    );
}

export default stepOneComponent;