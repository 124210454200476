import React from 'react';
import { Flex } from '@rebass/grid';

const ThankyouPage = () => {
    return (
        <Flex
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
            }}
        >
            <div style={{
                padding: '40px 20px',
            }}>
                <img
                    alt=""
                    src="/assets/images/thankyoutick.svg"
                />
            </div>
            <div
                style={{ padding: '10px', lineHeight: '25px', fontSize: '35px' }}
            >
                Thank You!!
            </div>
            <div style={{ padding: '20px', fontSize: '17px', color: '#1D2939' }}>
                Your mail preference has been saved successfully
            </div>
        </Flex>
    );
};

export default ThankyouPage;