import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid';
import { get } from 'lodash';
import React from 'react';
import { Button } from '..';
import { Stepper } from '../Stepper/Stepper';

const BeeHeader = ({
  beeEditorInstance,
  enable,
  addArticles,
  backHandler,
  setTemplateData,
  webinarData,
  selectedIndex,
  setObjectivesTabData,
  forAutomatedCampaigns,
  oldView,
}) => {

  const handleSaveAsDraftClick = () => {
    sessionStorage.setItem('saveAsDraft', true);
    handleSaveClick();
  };
  const handleSaveClick = () => {
    beeEditorInstance.save();
  };

  const handlePreview = () => {
    beeEditorInstance.preview();
  };

  const handleArticleInsert = () => {
    console.log(beeEditorInstance.instance);
    beeEditorInstance.instance.loadRows();
  };

  const stepList = [
    <span>
      Select
      <br />
      Recipients
    </span>,
    <span>
      Select
      <br />
      Template
    </span>,
    <span>
      Customize
      <br />
      Email
    </span>,
    <span>
      Preview &
      <br />
      Send/Schedule
    </span>,
  ];

  return (
    <div>
      {forAutomatedCampaigns || oldView ? (
        <Flex width="100%" flexDirection="column">
          <div
            style={{ height: '9vh', display: 'flex', justifyContent: 'center' }}
          >
            <Button
              id="bee-preview"
              margin="20px 10px"
              height="35px"
              onClick={backHandler}
            >
              Back
            </Button>
            <Button
              id="bee-preview"
              margin="20px 10px"
              disabled={!enable}
              height="35px"
              onClick={handlePreview}
            >
              Preview
            </Button>
            <Button
              id="bee-save"
              disabled={!enable}
              height="35px"
              onClick={handleSaveClick}
            >
              Save
            </Button>
            {addArticles && (
              <Button
                id="add-content"
                margin="20px 10px"
                disabled={!enable}
                height="35px"
                onClick={handleArticleInsert}
              >
                Add Content
              </Button>
            )}
          </div>
        </Flex>
      ) : (
        <Flex width="100%" flexDirection="column">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: '10px',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontWeight: 500,
                fontSize: '24px',
                color: '#101828',
              }}
            >
              {get(setObjectivesTabData, 'name', '')}
            </div>
            <Button
              id="bee-save-as-draft"
              margin="9px 10px"
              borderColor="rgba(16, 24, 40, 0.12)"
              borderRadius="4px"
              bgColor="#FFFFFF"
              color="#101828"
              disabled={!enable}
              height="35px"
              onClick={handleSaveAsDraftClick}
            >
              Save as Draft
            </Button>
          </div>
          <div
            style={{
              margin: '0 10px',
              borderRadius: '5px',
              border: '1px solid rgba(16, 24, 40, 0.12)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '75px'
            }}
          >
            <Button
              id="bee-preview"
              margin="15px 10px"
              height="35px"
              color="#816EF8"
              bgColor="#FFFFFF"
              borderRadius="4px"
              borderColor="rgba(16, 24, 40, 0.12)"
              onClick={backHandler}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp; Back &nbsp; &nbsp;
            </Button>
            <Stepper
              stepperList={stepList}
              selectedIndex={selectedIndex}
              style={{ marginTop: '-2%' }}
            />
            <Button
              id="bee-save"
              bgColor="#816EF8"
              borderRadius="4px"
              margin="15px 10px"
              disabled={!enable}
              height="35px"
              onClick={handleSaveClick}
            >
              &nbsp; Next &nbsp; &nbsp;
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
              marginLeft: '10px',
            }}
          >
            <Flex>
              {addArticles && (
                <Button
                  id="add-content"
                  margin="9px 10px"
                  disabled={!enable}
                  height="35px"
                  onClick={handleArticleInsert}
                  borderColor="rgba(16, 24, 40, 0.12);"
                  borderRadius="4px"
                  bgColor="#FFFFFF"
                  color="#101828"
                >
                  Add Content
                </Button>
              )}
              <Button
                id="bee-preview"
                margin="9px 10px"
                disabled={!enable}
                height="35px"
                onClick={handlePreview}
                borderColor="rgba(16, 24, 40, 0.12);"
                borderRadius="4px"
                bgColor="#FFFFFF"
                color="#101828"
              >
                Preview
              </Button>
            </Flex>
          </div>
        </Flex>
      )}
    </div>
  );
};

export default BeeHeader;
