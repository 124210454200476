import React, { useState, useEffect } from 'react';
import { Button } from '../../../../components';
import {
  FilterIconDiv,
  FilterLi,
} from './AnalyticsFilterPopup.styles';
import {
  AdminCheckBox,
  GrayCheckBox,
} from '../../../../components/SocialCheckBoxes/SocialCheckBoxes.styles';
import { isEmpty, filter } from 'lodash';
import CustomCloutCheckbox from '../../../../components/CustomCloutCheckbox/CustomCloutCheckbox';

const FilterContacts = ({
  contactGroup,
  handleClick,
  selectedContacts,
  type,
  isAdmin,
  top,
  left,
  height,
  dropdownLeft,
  dropdownRight,
  padding,
  bottom
}) => {
  const handleMouseEnter = () => {
    document.getElementById('engagement-filter').style.display = 'block';
  };
  const handleMouseLeave = () => {
    document.getElementById('engagement-filter').style.display = 'none';
  };

  const [emailList, setEmailList] = useState([]);

  useEffect(() => {
    let customContactsCopy = [];

    let defaultList = filter(contactGroup, function (o) {
      return o.contact_type === 'default';
    });
    console.log('defaultList', defaultList);

    if (!isEmpty(defaultList)) {
      let defaultIDs = defaultList.map((ids) => {
        return ids.id;
      });
      defaultList.unshift({
        description: 'group_header',
        external_uuid: '-1',
        id: '-1',
        name: 'Others',
        type: 'default',
        contact_type: 'default',
        checked: defaultIDs.every((ai) => selectedContacts.includes(ai)),
      });

      customContactsCopy = customContactsCopy.concat(defaultList);
    }

    let prospectList = filter(contactGroup, function (o) {
      return o.contact_type === 'prospect';
    });
    console.log('prospectList', prospectList);

    if (!isEmpty(prospectList)) {
      let prospectIDs = prospectList.map((ids) => {
        return ids.id;
      });
      prospectList.unshift({
        description: 'group_header',
        external_uuid: '-1',
        id: '-1',
        name: 'Prospects',
        type: 'prospect',
        contact_type: 'prospect',
        checked: prospectIDs.every((ai) => selectedContacts.includes(ai)),
      });

      customContactsCopy = customContactsCopy.concat(prospectList);
    }

    let clientList = filter(contactGroup, function (o) {
      return o.contact_type === 'client';
    });
    console.log('clientList', clientList);

    if (!isEmpty(clientList)) {
      let clientIDs = clientList.map((ids) => {
        return ids.id;
      });
      clientList.unshift({
        description: 'group_header',
        external_uuid: '-1',
        id: '-1',
        name: 'Clients',
        type: 'client',
        contact_type: 'client',
        checked: clientIDs.every((ai) => selectedContacts.includes(ai)),
      });

      customContactsCopy = customContactsCopy.concat(clientList);
    }
    console.log('customContactsCopy', customContactsCopy);
    setEmailList(customContactsCopy.filter((item) => item));
  }, [contactGroup, selectedContacts]);

  useEffect(() => {
    console.log('emailList', emailList);
  }, [emailList]);

  return (
    <FilterIconDiv
      type={type}
      top={top}
      left={left}
      isAdmin={isAdmin}
      bottom={bottom}
      dropdownLeft={dropdownLeft}
      dropdownRight={dropdownRight}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        margin="0"
        borderRadius="4px"
        bgColor="#fff"
        color="#666666"
        borderColor="#6351ed"
        padding= "5px 105px 5px 25px"
        width='200px'
      >
        <div
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span 
            style={{ 
              marginRight: '8px', 
              color: selectedContacts.length === 0 ? '#adadad' : '#666666' 
            }}
          >
            Email List
          </span>
        </div>
      </Button>
      {/* <ResponsiveImg
        src="/assets/images/Filter.svg"
        style={{ height: height || '', padding: padding || '' }}
      /> */}
      <ul
        onMouseLeave={handleMouseLeave}
        id="engagement-filter"
        className="engagement-filter-ul"
        style={{
          boxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
          marginTop: '25px',
        }}
      >
        {emailList &&
          emailList.length > 0 &&
          emailList.map((item, index) => (
            <>
              {item.description === 'group_header' ? (
                <FilterLi key={`contact_group_${index}`}>
                  {/* <GrayCheckBox borderRadius="0px" color="#7dc5d0">
                    <AdminCheckBox
                      id={'all'}
                      type="checkbox"
                      className={item && item.checked ? 'checked' : ''}
                      checked={item && item.checked}
                    />
                    <label style={{ marginLeft: '10px', cursor: 'pointer' }}>
                      {item.name}
                    </label>
                  </GrayCheckBox> */}
                  <CustomCloutCheckbox 
                    changeHandler={() => handleClick(item.contact_type)} 
                    inputId={item.contact_type}
                    inputValue={item.contact_type}
                    borderRadius='2px'
                    boxChecked={item && item.checked}
                  />
                  <label 
                    htmlFor={item.contact_type}
                    style={{ 
                        fontWeight: 'bold', 
                        color: '#000', 
                        marginLeft: '25px',
                        cursor: 'pointer'
                    }}
                  >
                    {item.name}
                  </label>
                </FilterLi>
              ) : (
                <FilterLi
                  key={'contact_group_' + index}
                  bgColor="#fff"
                >
                  {/* <GrayCheckBox borderRadius="0px" color="#7dc5d0">
                    <AdminCheckBox
                      id={item.id}
                      type="checkbox"
                      className={
                        selectedContacts.includes(item.id) ? 'checked' : ''
                      }
                      checked={selectedContacts.includes(item.id)}
                    />
                    <label style={{ marginLeft: '10px', cursor: 'pointer' }}>
                      {item.name}
                    </label>
                  </GrayCheckBox> */}
                  <CustomCloutCheckbox 
                    changeHandler={() => handleClick(item.id)} 
                    inputId={item.id}
                    inputValue={item.id}
                    borderRadius='2px'
                    boxChecked={selectedContacts.includes(item.id)}
                  />
                  <label 
                    htmlFor={item.id}
                    style={{ 
                        fontWeight: 'bold', 
                        color: '#000', 
                        marginLeft: '25px',
                        cursor: 'pointer'
                    }}
                  >
                    {item.name}
                  </label>
                </FilterLi>
              )}
            </>
          ))}
      </ul>
    </FilterIconDiv>
  );
};

export default FilterContacts;
