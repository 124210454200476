import { landingPagesEndpoints } from "../../../services/helpers";

export const leadCaptureForm = {
   "structure": {
      "fields": {
         "first_name": {
            "type": "text",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "First Name"
            }
         },
         "last_name": {
            "type": "text",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "Last Name"
            }
         },
         "email": {
            "type": "email",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "Email"
            }
         },
         "phone_number": {
            "type": "tel",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "required": true,
               "value": "",
               "placeholder": "Phone Number",
               "pattern": "[0-9]{10,15}$"
            }
         },
         "preferrence_drop_down": {
            "type": "select",
            "attributes": {
               "placeholder": "Select One (Optional)"
            },
            "options": [
               {
                  "type": "option",
                  "label": "Select One (Optional) ",
                  "value": "Option_0"
               },
               {
                  "type": "option",
                  "label": "I already work with a financial advisor but am interested to discuss this topic in more detail ",
                  "value": "Option_1"
               },
               {
                  "type": "option",
                  "label": "I am not looking for a financial advisor but am interested to discuss this topic in more detail",
                  "value": "Option_2"
               },
               {
                  "type": "option",
                  "label": "I am looking for a financial advisor and am interested to discuss this topic in more detail",
                  "value": "Option_3"
               }
            ]
         },
         "privacy_policy": {
            "type": "checkbox",
            "label": "I agree to the Privacy Policy",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "name": "privacy-policy",
               "required": true
            }
         },
         "Register": {
            "type": "submit",
            "label": "",
            "canBeRemovedFromLayout": true,
            "attributes": {
               "value": "Register",
               "id": "lead_button"
            }
         },
         "url": {
             "type": "hidden",
             "canBeRemovedFromLayout": false,
             "removeFromLayout": false,
             "attributes": {
                 "value": "",
                 "id": "registration_url"
             }
         },
         "form_type": {
             "type": "hidden",
             "canBeRemovedFromLayout": false,
             "attributes": {
                 "value": "lead_form"
             }
         }
      },
      "layout": [
         ["first_name","form_type"],
         ["last_name", "url"],
         ["email"],
         ["phone_number"],
         ["preferrence_drop_down"],
         ["privacy_policy"],
         ["Register"]
      ],
      "attributes": {
         "accept-charset": "UTF-8",
         "action": `${landingPagesEndpoints.baseUrl}/page-form`,
         "autocomplete": "on",
         "enctype": "multipart/form-data",
         "method": "post",
         "novalidate": false,
         "target": "_blank"
      },
      "title": "Lead form",
      "description": "A BEE test form"
   },
   "style": {
       "fields":{
          "color":"#000000",
          "backgroundColor":"#ffffff",
          "outlineColor":"#3AAEE0",
          "border-top":"1px solid #000000",
          "border-right":"1px solid #000000",
          "border-bottom":"1px solid #000000",
          "border-left":"1px solid #000000",
          "padding-top":"5px",
          "padding-right":"5px",
          "padding-bottom":"5px",
          "padding-left":"5px",
          "border-radius":"4px"
       }
   }
}