/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Flex } from '@rebass/grid';
import { get, isEmpty, map } from 'lodash';
import { Flipster, StyledHeading, Loader, LoaderWrapper, ResponsiveImg, Button } from '../../../components';
import { getPopularTheme, geYourTheme } from '../../../services/dashboard';
import { getSpecificThemeItem } from '../../../utils';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconWrapper } from '../../../components/ListItemTile/ListItemTile.styles';
import { unsubscribeTheme } from '../../../services/dashboard/index';

// const RenderTiles = ({children, toggleCarouselView, type, jump, transition}) => {
//   //console.log("toggleCarouselView : ", toggleCarouselView, type, jump, transition);
//   return (toggleCarouselView ? <Flipster type={type} transition={transition} jump={jump}>
//     {children}
//   </Flipster> : <>{children}</>);
// }

const PopularTopics = ({
  setTabIndex,
  setSelectedExploreTabIndex,
  setPopularTopicDetails,
  isAdmin,
  topicFromInvestment,
  themesList,
  roleType,
  onExploreTabClick,
  themeSetState,
  type,
  popularTopicDetails,
  popularTabIndex,
  exploreSetState
}) => {
  const [popularThemesFetching, setTopInsightsFetching] = useState(false);
  const [popularThemes, setPopularThemes] = useState([]);
  const history = useHistory();
  const [toggleCarouselView, setToggleCarouselView] = useState(true);
  const [showGhostBtn, toggleGhostBtn] = useState('');
  const [topicLoader, toggleLoader] = useState('');
  const [toggleEditView, setToggleEditView] = useState(false);
  const [toggleDeleteView, setToggleDeleteView] = useState(false);
  const [jump, setJump] = useState([]);
  const [transition, setTransition] = useState(true);
  const [selectedTiles, setSelectedTiles] = useState(new Set());
  //const [deletedTiles, setDeletedTiles] = useState(new Set());
  const [myTopics, setMyTopics] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [carouselSelected, setCarouselSelected] = useState({});
  const [selectedTilesId, setSelectedTileId] = useState(new Set());
  const [isSelected, setIsSelected] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [sponsoredThemeIds, setSponsoredThemeIds] = useState([]);
  const [initialIndex, setInitialIndex] = useState('');

  const themeFromInvestment = topicFromInvestment
    ? getSpecificThemeItem(themesList, topicFromInvestment)
    : [];

  useEffect(() => {
    setTopInsightsFetching(true);
    if (!isAdmin) {
      getPopularTheme().then((response) => {
        setTopInsightsFetching(false);
        setPopularThemes(get(response, 'result.data', []));
      })
        .catch(err => {
          setTopInsightsFetching(false);
        });
    } else {
      geYourTheme().then((response) => {
        setTopInsightsFetching(false);
        const yourThemeResponse = get(response, 'result.data', []).filter(
          (theme) =>
            get(theme, 'subscription.status.application_status') === 'approved'
        );
        const sponsoredThemes = get(response, 'result.data', []).filter(
          (theme) =>
            get(theme, 'sponsored') === true
        );
        setSponsoredThemeIds(map(sponsoredThemes, 'id'));
        setPopularThemes(yourThemeResponse);
      })
        .catch(err => {
          setTopInsightsFetching(false);
          console.log(err);
        })
    }
  }, []);

  useEffect(() => {
    if (popularTabIndex === 2) {
      setSelectedTiles(new Set())
      setIsSelected(false)
    }
  }, [popularTabIndex])

  // const deleteClick = () => {
  //   const allTiles = [...popularThemes, ...themeFromInvestment].map(t => t.name);
  //   // setSelectedTiles(new Set(allTiles));
  //   // setDeletedTiles(new Set(selectedTiles)); //For Individual topic delete
  //   setDeletedTiles(new Set(allTiles));
  // }

  // const unDeleteClick = () => {
  //   setDeletedTiles(new Set()); //For Individual topic undo delete as well
  //   // setSelectedTiles(new Set());
  //   // setPopularTopicDetails({
  //   //   id: '', topicName: ''
  //   // })
  // }

  useEffect(() => {
    if (toggleCarouselView && !topicFromInvestment) {
      defaultSelection(popularThemes);
    }
    if (topicFromInvestment) {
      setJump(popularThemes.length);
    }
    if ([...popularThemes, ...themeFromInvestment].length > 0 && themeSetState) {
      const themes = map([...popularThemes, ...themeFromInvestment], 'name');
      themeSetState({ yourTopics: themes.map(theme => theme.toUpperCase()) });
    }
  }, [topicFromInvestment, popularThemes]);

  const featuredTopic = {
    id:sponsoredThemeIds,
    name:'Featured Topics',
    description:'Featured Topics',
    media_url:'https://apicdn.myclout.com/theme/FeaturedTopics.svg'
  };

  const defaultSelection = (topics) => {
    let initialIndex;
    if(type !== 'most-subscribed') {
      initialIndex = Math.floor((topics.length + 1) / 2);
      setInitialIndex(initialIndex);
      topics.splice(initialIndex,0,featuredTopic);
    } else {
      initialIndex = Math.floor((topics.length - 1) / 2);
      setInitialIndex(initialIndex);
    }
    
    const { id, description } = get(topics, `[${initialIndex}]`, {});
    if(isAdmin) {
      setPopularTopicDetails({
        id: sponsoredThemeIds,
        topicName: description
      });
      exploreSetState({
        topicDetails: {
          id: sponsoredThemeIds,
          topicName: description
        }
      })   
    } else {
      setPopularTopicDetails({
        id,
        topicName: description
      });
    }
   
    setSelectedTiles(new Set(selectedTiles).add(description?.toLowerCase()));
    setSelectedTileId(selectedTilesId.add(id));
    setCarouselSelected({ id, topic: description?.toLowerCase() });
    onExploreTabClick(isAdmin ? sponsoredThemeIds : id);
  }

  const onItemClick = (id, topicName, addTiles = false) => {
    setIsSelected(false);
    setIsChecked(false);
    if (addTiles) {
      if (selectedTiles.has(topicName)) {
        const newSelectedTiles = new Set(selectedTiles);
        newSelectedTiles.delete(topicName)
        setSelectedTiles(newSelectedTiles);

        const newSelectedTilesId = new Set(selectedTilesId);
        newSelectedTilesId.delete(id)
        setSelectedTileId(newSelectedTilesId);

        setPopularTopicDetails({
          id: Array.from(selectedTilesId)?.pop() ?? '',
          topicName: Array.from(selectedTiles)?.pop() ?? ''
        });
      } else {
        setSelectedTiles(new Set(selectedTiles).add(topicName))
        setSelectedTileId(selectedTilesId.add(id));

        setPopularTopicDetails({
          id,
          topicName
        });
      }
    } else {
      setSelectedTiles(new Set().add(topicName))
      setSelectedTileId(new Set().add(id));
      setPopularTopicDetails({
        id,
        topicName
      })
      setCarouselSelected({ id, topic: topicName?.toLowerCase() })
      onExploreTabClick(id)
    }
    setTabIndex(0);
    if (type && type === 'most-subscribed') {
      setSelectedExploreTabIndex(1);
    } else {
      setSelectedExploreTabIndex(3);
      setTransition(false);
    }
    //onExploreTabClick(id);
  };

  useEffect(() => {
    if (selectedTiles.size === 0) {
      setIsSelected(false)
      setSelectedTileId(new Set());
      setPopularTopicDetails({
        id: '',
        topic: ''
      })
    }
  }, [selectedTiles])

  const shortenTitle = title => {
    if (title.length > 7)
      return title.substr(0, 7) + '...';
    else
      return title;
  };

  const unsubscribeHandler = topic => {
    setErrorMessage('');
    toggleLoader(topic.name);
    unsubscribeTheme(topic.id)
      .then(response => {
        toggleLoader('');
        let topics = popularThemes.filter(top => top.id !== topic.id);
        setPopularThemes(topics);
        if (selectedTilesId.has(topic.id)) {  
          const newSelectedTilesId = new Set(selectedTilesId);
          newSelectedTilesId.delete(topic.id)
          setSelectedTileId(newSelectedTilesId);
          if (newSelectedTilesId.size === 0){
            defaultSelection(topics);
          }
        }
      })
      .catch(err => {
        toggleLoader('');
        setErrorMessage('*An error Occurred...please try again.');
      });

  }

  const enableCarouselView = (isCarouselView) => {
    //setDeletedTiles(new Set());
    if (isCarouselView) {
      setSelectedTiles(new Set().add(carouselSelected.topic?.toLowerCase()))
      setSelectedTileId(new Set().add(carouselSelected.id))
      setPopularTopicDetails({
        id: carouselSelected.id,
        topicName: carouselSelected.topic
      })
    } else {
      if (selectedTiles.size > 0) {
        setIsSelected(true);
        setIsChecked(true);
      }
    }
    setToggleCarouselView(isCarouselView);
    setTabIndex(0);
    if (type && type === 'most-subscribed') {
      setSelectedExploreTabIndex(1);
    } else {
      setSelectedExploreTabIndex(3);
      setTransition(false);
    }
    onExploreTabClick(carouselSelected.id);
  }

  // console.log([...popularThemes, ...themeFromInvestment]);

  return (
    <>
      <div style={{ display: toggleCarouselView ? 'block' : 'none' }}>
        <Flipster type={type} transition={transition} jump={jump}>
          <LoaderWrapper isLoading={popularThemesFetching} styles={{ width: '30px', height: '30px', margin: '40px auto' }}>
            {[...popularThemes, ...themeFromInvestment].length > 0 ?
              [...popularThemes, ...themeFromInvestment].map((theme, index) => {
                const { id, description, media_urls } = theme;
                const mediaDetails = get(media_urls, '[0]', '');
                const url = `${mediaDetails.path}${mediaDetails.id}.${mediaDetails.ext}`;
                  return (
                    <li key={index} className='most_subscribed_topic'>
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                        width="fit-content"
                        mx="auto"
                      >
                        <a
                          href="javascript:void(0)"
                          onClick={() => onItemClick(id, description?.toLowerCase())}
                        >
                          <img src={index === initialIndex && isAdmin?theme.media_url:url} title={description && description.length > 24 ? description.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : ''} alt={description} />
                        </a>
                        <StyledHeading width={type === 'most-subscribed' ? 'auto' : '85px'} title={description && description.length > 24 ? description.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : ''} fontSize={description.split(' ')[0].length > 15 ? '9px' : '11px'} height='35px'>{description}</StyledHeading>
                      </Flex>
                    </li>
                  );
                })
              :
              <Flex
                justifyContent='center'
                alignItems='center'
                width="fit-content"
                mx="auto"
              >
                <h2 style={{ fontSize: '14px', wordSpacing: 'normal', cursor: 'pointer', marginTop: '12px' }} onClick={() => history.push('/theme')}>Please Select a Topic</h2>
              </Flex>
            }
          </LoaderWrapper>
        </Flipster>
      </div>
      {
        !toggleCarouselView && [...popularThemes, ...themeFromInvestment].length === 0 && type !== 'most-subscribed' && <Flex
          justifyContent='center'
          alignItems='center'
          width="fit-content"
          mx="auto"
        >
        <h2 style={{ fontSize: '14px', wordSpacing: 'normal', cursor: 'pointer', marginTop: '52px', marginLeft: '14px' }} onClick={() => history.push('/theme')}>Please Select a Topic</h2>
      </Flex>
      }
      {
        [...popularThemes, ...themeFromInvestment].length > 0 && type !== 'most-subscribed' && <div style={{ border: '0px', borderRadius: '10px', display: !toggleCarouselView ? 'block' : 'none' }}>
          <ul
            style={{
              display: 'inline-block',
              width: '110%',
              listStyleType: 'none',
              margin: '-20px -35px 0px -40px',
              textAlign: 'left',
              paddingLeft: '30px',
              height: '137px',
              marginTop: '12px'
            }}
          >
            {[...popularThemes, ...themeFromInvestment].length > 0 ? [...popularThemes, ...themeFromInvestment].map((topic, index) => {
                return (
                  <div
                    style={{
                      display: 'inline-block',
                      marginBottom: '10px',
                      position: 'relative'
                    }}
                    onMouseEnter={() => toggleGhostBtn(topic.name)}
                    onMouseLeave={() => toggleGhostBtn('')}
                    //onClick={() => deletedTiles.size === 0 ? onItemClick(topic.id, topic.name?.toLowerCase(), true) : null}
                    onClick={() => onItemClick(topic.id, topic.name?.toLowerCase(), true)}
                  >
                    <Flex
                      justifyContent='flex-start'
                      alignItems='center'
                      style={{
                        border: '0px',
                        borderRadius: '4px',
                        //backgroundColor: '#ececec',
                        //color: '#6351ed',
                        fontSize: '10px',
                        padding: '5px 10px 5px 1px',
                        margin: '0px 10px',
                        height: '35px',
                        width: '101px',
                        marginRight: '0',
                        marginLeft: '5px',
                        overflow: 'hidden',
                        backgroundColor: selectedTiles.has(topic.name?.toLowerCase()) ? '#7dc5d0' : 'rgb(236, 236, 236)',
                        //opacity: deletedTiles.has(topic.name) ? 0.6 : 1
                      }}
                    >
                      <img
                        alt=""
                        src={index === initialIndex && isAdmin?topic.media_url:topic.media_urls[0].path + topic.media_urls[0].id + '.' + topic.media_urls[0].ext}
                        style={{
                          width: '25px',
                          height: '25px',
                          borderRadius: '4px',
                          margin: '0px 4px'
                        }}
                      />
                      <LoaderWrapper isLoading={topicLoader === topic.name}>
                        <div className='tooltip-div tooltip-disclosure' style={{ position: 'unset' }}>
                          <p style={{ cursor: topic.name.length > 7 ? 'pointer' : '', color: selectedTiles.has(topic.name?.toLowerCase()) ? '#ffffff' : '#49484a' }}>{shortenTitle(topic.name)}</p>
                          {topic.name.length > 7 && <span className='tooltip-text tooltiptext-disclosure' style={{ left: '90px', top: '10px', whiteSpace: 'nowrap', width: 'initial', backgroundColor: '#404040', color: '#ffffff' }}>{topic.name}</span>}
                        </div>
                      </LoaderWrapper>
                    </Flex>
                    {/* {showGhostBtn === topic.name && deletedTiles.has(topic.name) && !topicLoader ? (
                  <div
                    style={{
                      position: 'absolute',
                      backgroundColor: '#fffc',
                      height: '35px',
                      width: '101px',
                      top: '0px',
                      left: '5px',
                      borderRadius: '4px',
                      fontSize: '10px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      paddingLeft: '9px'
                    }}
                    onClick={() => unsubscribeHandler(topic)}
                  >
                    <IconWrapper style={{ marginBottom: '9px', marginRight: '10px' }}>
                      <FontAwesomeIcon icon={faMinus} />
                    </IconWrapper>
                    <p>{topic.name}</p>
                  </div>
                ) : null} */}
                  </div>
                )
            }) : null}
          </ul>

          <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            //height="35px"
            onClick={() => history.push('/theme')}
            //width={window.innerWidth < 768 ? '82%' : '55px'}
            bgColor='#eeeeee' color='#7c7c7c'
            styles={{ border: 'none', background: 'none', position: 'relative', padding: '0', top: [...popularThemes, ...themeFromInvestment].length>=16&&'-32px' }}
            //disabled={deletedTiles.size}
          >
            <ResponsiveImg src="/assets/images/edit.svg" style={{ height: '23px', marginTop: '5px', marginLeft: '0' }} />
          </Button>

          {/* <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            height="35px"
            width={window.innerWidth < 768 ? '82%' : '55px'}
            bgColor='#eeeeee' color='#7c7c7c'
            styles={{ border: 'none', background: 'none', position: 'relative', padding: '0', marginLeft: '-22px' }}
            disabled={deletedTiles.size}
          >
            <ResponsiveImg src="/assets/images/delete1.svg"
              style={{ height: '23px', marginTop: '-5px', marginLeft: '-65px'  }} onClick={deleteClick} />
          </Button>

          <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            height="35px"
            width={window.innerWidth < 768 ? '82%' : '55px'}
            bgColor='#eeeeee' color='#7c7c7c'          
            styles={{ border: 'none', background: 'none', position: 'relative', padding: '0', marginLeft: '-22px' }}
            disabled={deletedTiles.size === 0}
          >
            <ResponsiveImg src="/assets/images/delete_d1.svg"
              style={{ height: '23px', marginTop: '-5px', marginLeft: '-65px'  }} onClick={unDeleteClick} />
          </Button> */}

          <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            height="35px"
            disabled={isChecked}
            width={window.innerWidth < 768 ? '82%' : '55px'}
            onClick={() => {
              // if (isSelected) {
              //   setIsSelected(false);
              //   setSelectedTiles(new Set());
              //   setSelectedTileId(new Set());
              //   setPopularTopicDetails({
              //     id: '',
              //     topic: ''
              //   })
              // } else {
                setIsSelected(true);
                onExploreTabClick(Array.from(selectedTilesId)?.toString()?.replaceAll(',', '%22%2C%22'))
              // }
            }}
            styles={{ border: 'none', background: 'none', position: 'relative',
            padding: '0px',
            marginLeft: '17px',
            backgroundColor: !isChecked ? '#7dc5d0': '#adadad',
            color: '#fff',
            height: '25px',
            borderRadius: '5px',
            fontSize: '12px',
            paddingLeft: '5px',
            paddingRight: '5px',
            width: '66px',
            top: [...popularThemes, ...themeFromInvestment].length>=16?'-40px':'-8px' }}
          >
            {/* <ResponsiveImg src={isSelected ? "/assets/images/check1.svg" : "/assets/images/check2.svg"} style={{ height: '23px', padding: '0', marginTop: '-5px', marginLeft: '-5px' }}
            onClick={() => {
                if (isSelected) {
                  setIsSelected(false);
                  setSelectedTiles(new Set());
                  setSelectedTileId(new Set());
                  setPopularTopicDetails({
                    id: '',
                    topic: ''
                  })
                } else {
                  setIsSelected(true);
                  onExploreTabClick(Array.from(selectedTilesId)?.toString()?.replaceAll(',', '%22%2C%22'))
                }
            }
            }
            /> */}
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;Apply
          </Button>
        </div>
      }
      {
        type !== 'most-subscribed' &&
        <>
          <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            height="35px"
            onClick={() => enableCarouselView(true)}
            width={window.innerWidth < 768 ? '82%' : 'auto'}
            bgColor='#eeeeee' color='#7c7c7c'
            styles={{ border: 'none', background: 'none', position: 'absolute', bottom: '4px', right: '50px', padding: '6px 0' }}
          >
            <ResponsiveImg src="/assets/images/Carousel-fill.svg" style={{ height: '23px', width: '28px' }} />
          </Button>
          <div style={{ borderLeft: '2px solid #867AF0', position: 'absolute', bottom: '9px', right: '38px', height: '25px' }}></div>
          <Button
            margin={`${window.innerWidth < 768 ? '10px' : '0px'}`}
            height="35px"
            onClick={() => enableCarouselView(false)}
            width={window.innerWidth < 768 ? '82%' : 'auto'}
            bgColor='#eeeeee' color='#7c7c7c'
            styles={{ border: 'none', background: 'none', position: 'absolute', bottom: '2px', right: '6px', padding: '0' }}
          >
            <ResponsiveImg src="/assets/images/gridview-fill.svg" style={{ height: '19px', width: '28px' }} />
          </Button>
        </>
      }

    </>
  );
};

export default PopularTopics;

