import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Box } from '@rebass/grid';

import Profile from './Profile';
import EmailList from './EmailList';
import { actions as contactsActions } from '../../redux/modules/contacts.module';
import { actions as loginActions } from '../../redux/modules/login.module';
import { TabConfig } from './screenConfigure.styles';
import { getContactsGroups } from '../../services/configure';
import { getUserDetails } from '../../redux/selector/login.selectors';
import {
  getContactListForEmail,
  ROLE_TYPES,
} from '../../utils';
import { actions as socialNetworkActions } from '../../redux/modules/socialNetwork.module';
import Platforms from './Platforms/Platforms';
import { BorderWhiteBox } from '../DashboardContainer/DashboardContainer.styles';
import { Tabs, TabList, TabTypeThree } from '../../components';
import { Flex } from '@rebass/grid';
import { getRoleType } from '../../redux/selector';
import { GeneralSettings } from './AdvisorGenSettings/GeneralSettings';

const ConfigureContainer = ({
  contactError,
  contactsList,
  contactsRequest,
  contactsDelete,
  contactsCreate,
  userData,
  isContactsFetching,
  socialNetworkData,
  contactGroupsList,
  selectedContactGroup,
  loginRequest,
  roleType,
  paginationData,
  navigateAfter,
  socialNetworkStatusRequest,
  selectedContactGroupDefault,
  selectedTabIndex,
  setSelectedTabIndexRequest,
  socialNetworkDataFetching,
  socialNetworkDelete,
  customContactGroupList,
  contactsCount,
  contactGroups,
  isAdvMode,
  userId,
  isPremiumAdvisor,
  isAdvisorEnterprise
}) => {
  const [isUpdateContact, setIsUpdateContact] = useState(false);
  const [socialAccountURL, setSocialAccountURL] = useState(null);
  const [bccList, setBccList] = useState(!isEmpty(get(userData, 'bcc_emails', [])) ? get(userData, 'bcc_emails', []).join() : '');
  const [selectedConfigureIndex, setSelectedConfigureIndex] =
    useState(selectedTabIndex);
  const [salesforceSettings, updateSalesforceSettings] = useState(
    !isEmpty(get(userData, 'salesforce_settings'))
      ? userData.salesforce_settings
      : [
          { 
            label: 'Email Sent', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          {
            label: 'Email Opened',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          {
            label: 'Email Clicked',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          { 
            label: 'Email Unsubscribed', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          {
            label: 'Content Viewed',
            Enabled: true,
            // 'Re-sync': false,
            'Skip duplicates': false,
          },
          { 
            label: 'Lead Generated', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          // { 
          //   label: 'Clout score changed', 
          //   Enabled: true, 
          //   // 'Re-sync': false 
          // },
          { 
            label: 'Webinar Registered', 
            Enabled: true, 
            // 'Re-sync': false 
          },
          { 
            label: 'Webinar Attended', 
            Enabled: true, 
            // 'Re-sync': false 
          },
        ]
  );

  const retrieveGroupsList = () => {
    getContactsGroups().then((response) => {
      const data = getContactListForEmail(get(response, 'result.data', []));
      // console.log(data);
      if (!isEmpty(data)) {
        const hasIsDefault = data.filter((d) => d.is_default);
        const firstGroup = isEmpty(hasIsDefault)
          ? get(data, `[0].id`, '')
          : hasIsDefault[0].id;
        contactsRequest({ params: { contactGroup: firstGroup } });
      } else {
        contactsRequest({ params: { contactGroup: '' } });
      }
    });
  };

  useEffect(() => {
    retrieveGroupsList();
    if (window.sessionStorage.getItem('selectedIndex')) {
      console.log(typeof window.sessionStorage.getItem('selectedIndex'));
      setSelectedConfigureIndex(
        +window.sessionStorage.getItem('selectedIndex')
      );
    } else {
      setSelectedConfigureIndex(selectedTabIndex);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabIndex]);

  // useEffect(() => {
  //   const fetchedSfSettings = get(userData, 'extra_settings.salesforce_settings', {});
  //   const fetchedSfSettingsLabels = Object.keys(fetchedSfSettings);
  //   const localSfSettingsLabels = Object.keys(salesforceSettingsMapper);
  //   const localSfTogglesLabels = Object.keys(salesforceTogglesMapper);
  //   // const newSfSettings = {};
  //   if(!isEmpty(fetchedSfSettings)) {
  //     const newSfSettings = fetchedSfSettingsLabels.map(sfSetting => {
  //       const returnObj = {};
  //       const settingIndex = localSfSettingsLabels.findIndex(setting => salesforceSettingsMapper[setting] === sfSetting);
  //       returnObj.label = localSfSettingsLabels[settingIndex];
  //       const fetchedSfToggles = fetchedSfSettings[sfSetting];
  //       const fetchedSfTogglesLabels = Object.keys(fetchedSfToggles);
  //       fetchedSfTogglesLabels.forEach(sfToggle => {
  //         const toggleIndex = localSfTogglesLabels.findIndex(toggle => salesforceTogglesMapper[toggle] === sfToggle);
  //         returnObj[localSfTogglesLabels[toggleIndex]] = fetchedSfToggles[sfToggle] === 'true';
  //       });
  //       // newSfSettings[localSfSettingsLabels[settingIndex]] = newSfToggles;
  //       return returnObj;
  //     });
  //     console.log('UPDATED SETTINGS - - - - -', newSfSettings);
  //     updateSalesforceSettings(newSfSettings);
  //   }
  // }, []);

  // useEffect(() => {
  //   const sfSettings = get(userData, 'salesforce_settings', {});
  //   if(!isEmpty(sfSettings))
  //     updateSalesforceSettings(sfSettings);
  // }, []);

  const contactListChange = (contactGroup, type, networkId) => {
    contactsRequest({ params: { contactGroup, type, networkId } });
  };

  const onContactDelete = (
    emailList,
    shouldDeleteGroup,
    data,
    dropdownId,
    shouldDeleteMultipleContactGroup
  ) => {
    if (shouldDeleteGroup) {
      contactsDelete({
        params: {
          contactGroup: '',
          emailList,
          shouldDeleteGroup,
          selectedGroup: '',
          shouldDeleteMultipleContactGroup,
        },
      });
    } else {
      contactsDelete({
        params: {
          contactGroup: selectedContactGroup,
          emailList,
          shouldDeleteGroup: false,
          selectedGroup: selectedContactGroup,
          shouldDeleteMultipleContactGroup,
        },
      });
    }
  };

  const onContactCreate = (values, isUpdate, isModal) => {
    console.log(values);
    contactsCreate({
      params: {
        ...values,
        contactGroup: isUpdate
          ? values.name
          : contactError &&
            contactError.contactGroup &&
            contactError.name === values.name
          ? contactError.contactGroup
          : '',
        isModal: isModal,
      },
    });
  };

  const onTabSelect = (index) => {
    window.sessionStorage.removeItem('selectedIndex');
    setSelectedConfigureIndex(index);
    setSelectedTabIndexRequest({ selectedTabIndex: index });
  };

  // console.log('[ADVISOR CONFIGURE CONTAINER] rendering...');
  return (
    <Box width={1} pt="25px">
      <Flex width={1}>
        <Tabs
          onSelect={onTabSelect}
          selectedIndex={selectedConfigureIndex}
          style={{
            border: '1px solid #e4e4e4',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
          }}
        >
          <BorderWhiteBox type="admin-tab" padding="5px 5px">
            <TabList>
              <TabTypeThree
                currentTab={selectedConfigureIndex === 0}
                type="dashboard"
              >
                Profile
              </TabTypeThree>
              <TabTypeThree
                currentTab={selectedConfigureIndex === 1}
                type="dashboard"
              >
                Platforms
              </TabTypeThree>
              <TabTypeThree
                currentTab={selectedConfigureIndex === 2}
                type="dashboard"
              >
                Contacts
              </TabTypeThree>
                <TabTypeThree
                  currentTab={selectedConfigureIndex === 3}
                  type="dashboard"
                  data-testid="general-settings"
                >
                  General Settings
                </TabTypeThree>
            </TabList>

            <TabConfig style={{ border: 'unset' }}>
              <Profile
                userData={userData}
                socialAccountURL={socialAccountURL}
                setSocialAccountURL={setSocialAccountURL}
                roleType={roleType}
              />
            </TabConfig>
            <TabConfig style={{ border: 'unset' }}>
              <Platforms
                userData={userData}
                socialAccountURL={socialAccountURL}
                setSocialAccountURL={setSocialAccountURL}
                roleType={roleType}
              />
            </TabConfig>
            <TabConfig style={{ border: 'unset' }}>
              <EmailList
                contactError={contactError}
                contactsList={contactsList}
                contactGroups={contactGroups}
                contactGroupsList={contactGroupsList}
                contactListChange={contactListChange}
                onContactDelete={onContactDelete}
                contactsCreate={onContactCreate}
                setIsUpdateContact={setIsUpdateContact}
                isContactsFetching={isContactsFetching}
                selectedContactGroup={selectedContactGroup}
                socialAccountURL={socialAccountURL}
                socialNetworkData={socialNetworkData}
                retrieveGroupsList={retrieveGroupsList}
                userData={userData}
                userId={userId}
                loginRequest={loginRequest}
                contactsRequest={contactsRequest}
                paginationData={paginationData}
                navigateAfter={navigateAfter}
                socialNetworkStatusRequest={socialNetworkStatusRequest}
                selectedContactGroupDefault={selectedContactGroupDefault}
                socialNetworkDataFetching={socialNetworkDataFetching}
                socialNetworkDelete={socialNetworkDelete}
                setSocialAccountURL={setSocialAccountURL}
                customContactGroupList={customContactGroupList}
                contactsCount={contactsCount}
              />
            </TabConfig>
            <TabConfig style={{ border: 'unset', minHeight: 'auto' }}>
              <GeneralSettings
                userId={userId}
                companyId={userData?.company}
                loginRequest={loginRequest}
                advisorApproval={userData?.extra_settings?.advisor_approval}
                advPartnerEmail={userData?.as_partner_email}
                turnkey={userData?.turnkey}
                salesforceSettings={salesforceSettings}
                updateSalesforceSettings={updateSalesforceSettings}
                socialNetworkData={socialNetworkData}
                isPremiumAdvisor={isPremiumAdvisor}
                isAdvisorEnterprise={isAdvisorEnterprise}
                bccList={bccList}
                setBccList={setBccList}
                userData={userData}
              />
            </TabConfig>
          </BorderWhiteBox>
        </Tabs>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  const isAdvisorEnterprise = roleType === ROLE_TYPES.ADVISOR_ENTERPRISE;
  const isTurnkey =
    state.user.data &&
    state.user.data.details &&
    state.user.data.details.user.turnkey &&
    state.user.data.details.user.turnkey === 'true';
  let advMode = false;
  if ((isPremiumAdvisor || isAdvisorEnterprise) && isTurnkey) advMode = true;
  return {
    userId: state.user.data && state.user.data.uid,
    contactError: state.contacts.error,
    contactsList: state.contacts.data,
    contactGroups: state.contacts.contactGroups,
    contactGroupsList: state.contacts.groupData,
    selectedTabIndex: state.contacts.selectedTabIndex,
    selectedContactGroup: state.contacts.selectedContactGroup,
    userData: getUserDetails(state),
    isContactsFetching: state.contacts.isFetching || false,
    socialNetworkData: state.socialNetwork.data,
    paginationData: state.contacts.pagination,
    navigateAfter: state.contacts.afterData,
    selectedContactGroupDefault: state.contacts.selectedContactGroupDefault,
    socialNetworkDataFetching: state.socialNetwork.isFetching,
    customContactGroupList: state.contacts.customContactGroupList,
    contactsCount: state.contacts.totalCount,
    isAdvMode: advMode,
    isPremiumAdvisor: isPremiumAdvisor,
    isAdvisorEnterprise: isAdvisorEnterprise
  };
};

const mapDispatchToProps = {
  contactsRequest: contactsActions.api.contacts.request,
  contactsDelete: contactsActions.api.contacts.delete,
  contactsCreate: contactsActions.api.contacts.create,
  loginRequest: loginActions.api.login.request,
  socialNetworkStatusRequest: socialNetworkActions.api.socialNetwork.request,
  setSelectedTabIndexRequest: contactsActions.api.contacts.selectedtabindex,
  socialNetworkDelete: socialNetworkActions.api.socialNetwork.delete,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureContainer);
