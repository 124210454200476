import React from 'react';
import { getMediaImage, onImageError } from '../../../../utils';
import { AutomatedCampaignImageGeneratorWrapper } from './styles';

const AutomatedCampaignImageGenerator = ({ media_urls }) => {
  return (
    <AutomatedCampaignImageGeneratorWrapper>
      <img
        alt=""
        // src={getMediaImage(media_urls)}
        src={getMediaImage('')}
        className="campaign__image"
        onError={onImageError}
      />
    </AutomatedCampaignImageGeneratorWrapper>
  );
};

export default AutomatedCampaignImageGenerator;
