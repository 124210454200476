/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  EmailSubjectInput,
} from '../CreateEmailContainer/CreateEmail.styles';
import { Flex } from '@rebass/grid';
import {Button, LoaderWrapper } from '../../../../components'
import { get, isEmpty } from 'lodash';

const EmailSubject = ({
    loading,
    setLoading,
    handleBack,
    handleNext,
    campaignSetState,
    selectedBlock
}) => {

  const [email, setEmail] = useState('Email 1');
  const [subject, setSubject] = useState('');
  const [purpose, setPurpose] = useState('');

  console.log(selectedBlock)


  useEffect(() => {
    if(selectedBlock) {
      setEmail(!isEmpty(get(selectedBlock, 'name', 'Email 1')) ? get(selectedBlock, 'name', 'Email 1') : 'Email 1');
      setSubject(get(selectedBlock, 'subject', ''));
      setPurpose(get(selectedBlock, 'purpose', ''));
    }
  }, [])
  

  const nextHandler = () => {
    campaignSetState({
      selectedBlock: {
        ...selectedBlock, 
        name: email,
        subject: subject,
        purpose: purpose
    }});
    handleNext();
  }


  return (
    <div>
      <Flex width={'900px'} padding="15px 10px" flexDirection="column">
        <Flex
          flexDirection="column"
          width="100%"
          fontSize="14px"
          color="#000000"
          mr="15px"
          style={{ padding: '0px 10px' }}
        >
          <Flex width="100%" style={{ fontWeight: '600' }}>
            Email Name
          </Flex>
          <Flex width="100%" pt="8px">
            <EmailSubjectInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="em_subject"
              maxLength="2000"
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          fontSize="14px"
          color="#000000"
          mr="15px"
          mt="10px"
          style={{ padding: '0px 10px' }}
        >
          <Flex width="100%" style={{ fontWeight: '600' }}>
            Subject
          </Flex>
          <Flex width="100%" pt="8px">
            <EmailSubjectInput
              placeholder="Type in Subject to be sent in emails"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              id="em_subject"
              maxLength="2000"
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          fontSize="14px"
          color="#000000"
          mr="15px"
          mt="10px"
          style={{ padding: '0px 10px' }}
        >
          <Flex width="100%" style={{ fontWeight: '600' }}>
            Purpose (optional)
          </Flex>
          <Flex width="100%" pt="8px">
            <EmailSubjectInput
              placeholder=""
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              id="em_purpose"
              maxLength="2000"
            />
          </Flex>
        </Flex>
        <hr style={{ width: '98%', margin: '20px auto' }} />
        <Flex justifyContent='right'>
          <LoaderWrapper isLoading={loading}>
            <Button onClick={handleBack} margin="20px 10px 0 0">Back</Button>
            <Button disabled={!subject || subject.length === 0} onClick={nextHandler}>Next</Button>
          </LoaderWrapper>
        </Flex>
      </Flex>
    </div>
  );
};

export default EmailSubject;
