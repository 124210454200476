import styled from 'styled-components';

export const GeneralSettingsWrapper = styled.div`
  // width: 90%;
  background-color: white;
  // margin: 20px auto;
  margin-top: 0px;
  padding: 0px 20px 20px;
`;

export const GeneralSettingsContainer = styled.div`
  // width: 90%;
  min-height: 60vh;
  // margin: 10px auto;
  // margin-bottom: 20px;
  background-color: #f5f6fa;
  border-radius: 5px;
  position: relative;
  padding: 20px;
`;

export const Info = styled.div`
  border-radius: 50%;
  border: 1px solid #889095;
  text-align: center;
  width: 14px;
  height: 14px;
  font-size: 0.6rem;
  margin-top: 20px;
  cursor: pointer;
  div {
    position: relative;
    top: -1px;
  }
`;

export const RadioWrapper = styled.div`
  background-color: white;
  padding: 10px;
  font-size: 0.7rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 10vw;
  font-weight: 700;
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.2);
`;
