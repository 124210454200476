import styled from "styled-components";

export const NumberRangeInput = styled.input.attrs({
    className: 'number-range-wrapper'
})`
    width: ${({ width }) => width || ''};
    background-color: #F6F9FB;
    border-radius: 8px;
    border: 1px solid #CFD9E4;
    height: 15px;
    margin: ${({ margin }) => margin || ''};
    -webkit-appearance: none;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 10px;
        height: 25px;
        background: #6250ED;
        cursor: pointer;
        border-radius: 4px;
    }

    &::-webkit-slider-thumb::before {
        content: ${({ sliderValue }) => sliderValue || 'test'};
        height: 10px;
    }
      
    &::-moz-range-thumb {
        width: 10px;
        height: 25px;
        background: #6250ED;
        cursor: pointer;
        border-radius: 4px;
    }

    &::-moz-range-thumb::before {
        content: ${({ sliderValue }) => sliderValue || 'test'};
    }
`;