/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { Flex, Box } from '@rebass/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import FileSaver from 'file-saver';
import qs from 'querystring';
import {
  TabThree,
  TabFour,
  TabsThree,
  TabListThree,
} from '../../components/Tabs/TabsTypeThree';
import {
  TopLevelTabPanel,
  DownloadButton,
  MioDropdown,
  ButtonNoBorderBackground,
  Loader,
  TabTypeThree,
} from '../../components';
import { AdminImpactTab } from './ImpactTab';
import { AdminActivityTab } from './ActivityTab';
import MembersTab from './MembersTab';

import { actions as inviteActions } from '../../redux/modules/invite.module';
import { actions as advisorGroupsActions } from '../../redux/modules/advisor-group.module';
import { actions as membersActions } from '../../redux/modules/members.module';
import { getChannelId, getFirmSettings } from '../../redux/selector';
import { downloadPdf, getCampaignAnalytics } from '../../services/analytics';
import {
  actions as analyticsActions,
  moreActions as analyticsMoreActions,
} from '../../redux/modules/analytics.module';
import { DownloadButtonFlex, LeadCaptureList, LeadCaptureListItem } from './AnalyticsContainer.styles';
import EnagagementTab from './EnagagementTab/EnagagementTab';
import LeadCaptureTab from './LeadCaptureTab/LeadCaptureTab';
import { getRoleType } from '../../redux/selector'
import { ROLE_TYPES } from '../../utils';

//AnalyticsFilter
import AnalyticsFilter from './AnalyticsFilter/AnalyticsFilter';
import { getContactsGroups } from '../../services/configure';

const styles = {
  tabTypeThree: {
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  buttonNoBorderBackground: { paddingTop: '3px' },
  tabListThree: { padding: '10px 10% 0 29%', border: '1px solid #e5e5e5', borderBottom: 'none' },
}

const AdminAnalyticsContainer = ({
  groupsList,
  inviteRequest,
  advisorGroupsRequest,
  selectedDate,
  setSelectedDate,
  analyticsRequest,
  analyticsData,
  isAnalyticsDataFetching,
  membersList,
  membersRequest,
  channelId,
  selectedRepType,
  analyticsObject,
  inviteSending,
  inviteError,
  location,
  userId,
  userData,
  isAdminEnterprise,
  setAnalyticsTabIndex,
  analyticsSetState,
  isAssetAdmin,
  contactParam,
  campaignsParam,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  inviteSetState
}) => {
  const [tabContentType, setTabContentType] = useState('Content');
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [showDropdown, toggleDropdown] = useState(false);
  const [leadCaptureOption, setLeadCaptureOption] = useState(0);
  const [contactGroup, setContactGroup] = useState([]);
  const [filters, setFilters] = useState();
  const [campaignAnalyticsData, setCampaignAnalyticsData] = useState([]);

  const canAddMembers = get(userData, 'details.user.member_settings.add_members');


  const onTabDropdownClick = (value) => {
    setTabContentType(value);
    onApiRequest({
      params: {
        repType: selectedRepType,
        tabContentType: value,
      },
    });
  };

  const onApiRequest = (params) => {
    analyticsRequest({
      params: {
        tabContentType,
        ...get(params, 'params', {}),
      },
    });
  };

  const onTabSelect = (index) => {
    // setTabContentType('Content');
    setTabIndex(index);
  };

  const onDownloadPdf = () => {
    setPdfLoading(true);
    if (pdfUrl) {
      FileSaver.saveAs(pdfUrl, 'analytics.pdf');
      setPdfLoading(false);
    } else {
      setPdfLoading(false);
    }
  };

  const leadCaptureOptionHandler = index => {
    setLeadCaptureOption(index);
    toggleDropdown(false);
  }

  useEffect(() => {
    const { startDate, endDate } = analyticsObject;
    if (tabIndex === 0 || tabIndex === 1) {
      downloadPdf({
        channelId,
        startDate,
        endDate,
        tabContentType,
        userId
      }).then((response) => {
        if (response.result.success && response.result.data && response.result.data.analytics && response.result.data.analytics.pdf.link) {
          setPdfUrl(response.result.data.analytics.pdf.link);
        }
      });
    }

    getContactsGroups()
      .then((res) => {
        if (res.result.success) {
          let contacts = res.result.data.filter((contact) => {
            return ![
              'GMAIL',
              'HUBSPOT',
              'OUTLOOK',
              'WEALTHBOX',
              'SALESFORCE',
              'REDTAIL',
            ].includes(get(contact, 'name')) && get(contact, 'contacts.count', 0) > 0;
          });
          setContactGroup(contacts);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }, [analyticsObject, tabIndex]);

  useEffect(() => {
    const { page } = qs.parse(get(location, 'search', '').replace('?', ''));
    if (page === 'members') {
      setTabIndex(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCampaignAnalytics()
    .then(res => {
      if(res.result.success)
        setCampaignAnalyticsData(res.result.data)
    })
    .catch(err => console.log('ERROR CAMPAIGN ANALYTICS - - - -', err));
  }, []);

  return (
    <TabsThree onSelect={onTabSelect} selectedIndex={tabIndex}>
      {tabIndex === 0 ? (
        <DownloadButtonFlex pb="10px">
          {pdfLoading ? (
            <Loader />
          ) : (
            <DownloadButton paddingTop='3px' text="Impact Summary" onClick={onDownloadPdf} fontSize='16px' />
          )}
        </DownloadButtonFlex>
      ) : (
        <DownloadButtonFlex pb="35px"></DownloadButtonFlex>
      )}
      <TabListThree style={styles.tabListThree}>
        <TabFour style={styles.tabTypeThree} currentTab={tabIndex === 0}>
          <Flex justifyContent="center">
            <Box width={1} style={{ fontSize: '13px', paddingTop: '10px', marginLeft: '10px' }}>Activity</Box>
            <Box ml="auto">
              <MioDropdown
                type='analytics'
                hoverBackground='#6351ed'
                left='-108px'
                dropdownMenu={
                  <ul>
                    <li onClick={() => onTabDropdownClick('Content')}>
                      <a href="javascript:void(0)">{tabContentType === 'Content' ? <b>My Content</b> : 'My Content'}</a>
                    </li>
                    {canAddMembers !== 'false' && (<li onClick={() => onTabDropdownClick('Members')}>
                      <a href="javascript:void(0)">{tabContentType === 'Members' ? <b>My Members</b> : 'My Members'}</a>
                    </li>)}

                    <li onClick={() => onTabDropdownClick('Impact')}>
                      <a href="javascript:void(0)">{tabContentType === 'Impact' ? <b>My Activity</b> : 'My Activity'}</a>
                    </li>
                  </ul>
                }
              >
                <ButtonNoBorderBackground fontSize='20px' color={tabIndex === 0 ? 'white' : '#49484a'} style={styles.buttonNoBorderBackground}>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </ButtonNoBorderBackground>
              </MioDropdown>
            </Box>
          </Flex>
        </TabFour>
        <TabFour style={styles.tabTypeThree} currentTab={tabIndex === 1}>
          <Flex justifyContent="center">
            <Box width={1} style={{ fontSize: '13px', paddingTop: '9px', marginLeft: '10px' }}>Impact</Box>
            <Box ml="auto">
              <MioDropdown
                type='analytics'
                hoverBackground='#6351ed'
                left='-109px'
                dropdownMenu={
                  <ul>
                    <li onClick={() => onTabDropdownClick('Content')}>
                      <a href="javascript:void(0)">{tabContentType === 'Content' ? <b>My Content</b> : 'My Content'}</a>
                    </li>
                    {canAddMembers !== 'false' && (<li onClick={() => onTabDropdownClick('Members')}>
                      <a href="javascript:void(0)">{tabContentType === 'Members' ? <b>My Members</b> : 'My Members'}</a>
                    </li>)}

                    <li onClick={() => onTabDropdownClick('Impact')}>
                      <a href="javascript:void(0)">{tabContentType === 'Impact' ? <b>My Impact</b> : 'My Impact'}</a>
                    </li>
                  </ul>
                }
              >
                <ButtonNoBorderBackground fontSize='20px' color={tabIndex === 1 ? 'white' : '#49484a'} style={styles.buttonNoBorderBackground}>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </ButtonNoBorderBackground>
              </MioDropdown>
            </Box>
          </Flex>
        </TabFour>
        <TabFour style={styles.tabTypeThree} currentTab={tabIndex === 2} type='analytics-tab'><div style={{ fontSize: '13px', paddingTop: '10px' }}>Engagement</div></TabFour>

        <TabFour style={styles.tabTypeThree} currentTab={tabIndex === 3}>
          <Flex justifyContent="center">
            <Box width={1} style={{ fontSize: '13px', paddingTop: '9px', marginLeft: '10px' }}>Leads</Box>
            <Box ml="auto">
              <MioDropdown
                type='analytics'
                hoverBackground='#6351ed'
                left='-109px'
                dropdownMenu={
                  <ul>
                    <li onClick={() => leadCaptureOptionHandler(0)} style={{ width: '12em' }}>
                      <a href="javascript:void(0)">{leadCaptureOption === 0 ? <b>My Leads</b> : 'My Leads'}</a>
                    </li>

                    <li onClick={() => leadCaptureOptionHandler(1)} style={{ width: '12em' }}>
                      <a href="javascript:void(0)">{leadCaptureOption === 1 ? <b>My Member Leads</b> : 'My Member Leads'}</a>
                    </li>
                  </ul>
                }
              >
                <ButtonNoBorderBackground fontSize='20px' color={tabIndex === 3 ? 'white' : '#49484a'} style={styles.buttonNoBorderBackground}>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </ButtonNoBorderBackground>
              </MioDropdown>
            </Box>
          </Flex>
        </TabFour>


        {/* <TabFour style={styles.tabTypeThree} currentTab={tabIndex === 3} type='analytics-tab'>
          <div style={{fontSize: '13px', display: 'flex', position: 'relative', justifyContent: 'flex-end', alignItems: 'baseline' }}>
            Leads
            <ButtonNoBorderBackground 
              fontSize='20px' 
              color={tabIndex === 3 ? 'white' : '#49484a'} 
              style={{...styles.buttonNoBorderBackground, marginLeft: '30px'}} 
            >
              <FontAwesomeIcon icon={faEllipsisV} onClick={() => toggleDropdown(!showDropdown)} />
            </ButtonNoBorderBackground>
            {showDropdown ? (
              <div style={{position: 'absolute', top: '40px', right: '0px', left: '-1px', display: 'block', zIndex: '1'}}>
                <LeadCaptureList>
                  <LeadCaptureListItem style={{borderBottom: '1px solid #ddd'}} onClick={() => leadCaptureOptionHandler(0)}>{leadCaptureOption === 0 ? <b>My Leads</b> : 'My Leads'}</LeadCaptureListItem>
                  <LeadCaptureListItem onClick={() => leadCaptureOptionHandler(1)}>{leadCaptureOption === 1 ? <b>My Member Leads</b> : 'My Member Leads'}</LeadCaptureListItem>
                </LeadCaptureList>
                {/* <ul 
                  style={{
                    color: '#6351ed', 
                    listStyle: 'none', 
                    boxShadow: '0px 1px 2px #ddd', 
                    fontSize: '16px', 
                    backgroundColor: '#fff',
                    padding: '8px 0px',
                    textAlign: 'center',
                    width: '173px'
                  }}
                >
                  <li style={{borderBottom: '1px solid #ddd'}} onClick={() => leadCaptureOptionHandler(0)}>My Leads</li>
                  <li onClick={() => leadCaptureOptionHandler(1)}>My Member Leads</li>
                </ul> */}
        {/*   </div>
            ) : null}
          </div>
          </TabFour>*/}



        {canAddMembers !== 'false' && (<TabFour style={styles.tabTypeThree} currentTab={tabIndex === 4} type='analytics-tab'><div style={{ fontSize: '13px', paddingTop: '10px' }}>Members</div></TabFour>)}
      </TabListThree>

      <TopLevelTabPanel>
        <AnalyticsFilter
          setFilters={setFilters}
          filters={filters}
          analyticsSetState={analyticsSetState}
          selectedDate={selectedDate}
          selectedRepType={selectedRepType}
          analyticsRequest={onApiRequest}
          contactGroup={contactGroup}
          isAdmin
          contactParam={contactParam}
          campaignsParam={campaignsParam}
          campaignData={campaignAnalyticsData}
        />
        <AdminActivityTab
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          analyticsRequest={onApiRequest}
          analyticsData={analyticsData}
          isAnalyticsDataFetching={isAnalyticsDataFetching}
          analyticsObject={analyticsObject}
          selectedRepType={selectedRepType}
          tabContentType={tabContentType}
        />
      </TopLevelTabPanel>
      <TopLevelTabPanel>
        <AnalyticsFilter
          setFilters={setFilters}
          filters={filters}
          analyticsSetState={analyticsSetState}
          selectedDate={selectedDate}
          selectedRepType={selectedRepType}
          analyticsRequest={onApiRequest}
          contactGroup={contactGroup}
          isAdmin
          contactParam={contactParam}
          campaignsParam={campaignsParam}
          campaignData={campaignAnalyticsData}
        />
        <AdminImpactTab
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          analyticsRequest={onApiRequest}
          analyticsData={analyticsData}
          isAnalyticsDataFetching={isAnalyticsDataFetching}
          analyticsObject={analyticsObject}
          selectedRepType={selectedRepType}
          tabContentType={tabContentType}
          channelId={channelId}
        />
      </TopLevelTabPanel>
      <TopLevelTabPanel>
        <AnalyticsFilter
          setFilters={setFilters}
          filters={filters}
          analyticsSetState={analyticsSetState}
          selectedDate={selectedDate}
          selectedRepType={selectedRepType}
          analyticsRequest={analyticsRequest}
          contactGroup={contactGroup}
          isAdmin
          contactParam={contactParam}
          campaignsParam={campaignsParam}
          campaignData={campaignAnalyticsData}
        />
        <EnagagementTab
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedRepType={selectedRepType}
          analyticsRequest={analyticsRequest}
          analyticsData={analyticsData}
          isAnalyticsDataFetching={isAnalyticsDataFetching}
          analyticsObject={analyticsObject}
          setAnalyticsTabIndex={setAnalyticsTabIndex}
          analyticsSetState={analyticsSetState}
          isAdmin
        />
      </TopLevelTabPanel>
      <TopLevelTabPanel>
        <AnalyticsFilter
          setFilters={setFilters}
          filters={filters}
          analyticsSetState={analyticsSetState}
          selectedDate={selectedDate}
          selectedRepType={selectedRepType}
          analyticsRequest={analyticsRequest}
          contactGroup={contactGroup}
          isAdmin
          contactParam={contactParam}
          campaignsParam={campaignsParam}
          campaignData={campaignAnalyticsData}
        />
        <LeadCaptureTab
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedRepType={selectedRepType}
          analyticsRequest={analyticsRequest}
          analyticsData={analyticsData}
          isAnalyticsDataFetching={isAnalyticsDataFetching}
          analyticsObject={analyticsObject}
          userData={userData}
          option={leadCaptureOption}
          isAssetAdmin={isAssetAdmin}
        />
      </TopLevelTabPanel>
      <TopLevelTabPanel style={{position: 'relative'}}>
        <MembersTab
          selectedDate={selectedDate}
          groupsList={groupsList}
          inviteRequest={inviteRequest}
          advisorGroupsRequest={advisorGroupsRequest}
          setSelectedDate={setSelectedDate}
          analyticsRequest={onApiRequest}
          analyticsData={analyticsData}
          isAnalyticsDataFetching={isAnalyticsDataFetching}
          membersList={membersList}
          membersRequest={membersRequest}
          channelId={channelId}
          analyticsObject={analyticsObject}
          selectedRepType={selectedRepType}
          inviteSending={inviteSending}
          inviteError={inviteError}
          inviteSetState={inviteSetState}
          isAdminEnterprise={isAdminEnterprise}
          firmSettings={firmSettings}
          isEnterpriseLevelAdmin={isEnterpriseLevelAdmin}
          isFirmLevelAdmin={isFirmLevelAdmin}
        />
      </TopLevelTabPanel>
    </TabsThree>
  );
};

const mapStateToProps = (state) => {
  const analyticsData = state.analytics.data || [];
  const roleType = getRoleType(state);
  const isEntAdm = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;
  const isAssetAdmin = roleType === ROLE_TYPES.ADMIN_ASSET_MANAGER;
  var contactArr = [];
  if (state.analytics.filterParams) {
    if (state.analytics.filterParams.contactParam) contactArr = state.analytics.filterParams.contactParam
  }
  const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', 'false') === 'true';
  const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', 'false') === 'true';
  const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });
  console.log('FIRM SETTINGS - - -- -', firmSettings);
  return {
    groupsList: state.advisorGroups.data,
    selectedDate: state.analytics.selectedDate,
    analyticsData,
    isAnalyticsDataFetching: state.analytics.isFetching,
    membersList: get(state, 'members.data.users', []),
    channelId: getChannelId(state),
    analyticsObject: state.analytics,
    selectedRepType: state.analytics.repType,
    inviteSending: state.invite.isFetching,
    inviteError: state.invite.isError,
    userId: get(state, 'user.data.uid', ''),
    userData: state.user.data,
    isAdminEnterprise: isEntAdm,
    isAssetAdmin,
    contactParam: contactArr,
    campaignsParam: get(state, 'analytics.filterParams.campaignsParam', []),
    isEnterpriseLevelAdmin,
    isFirmLevelAdmin,
    firmSettings
  };
};

const mapDispatchToProps = {
  inviteRequest: inviteActions.api.invite.request,
  inviteSetState: inviteActions.api.invite.setstate,
  advisorGroupsRequest: advisorGroupsActions.api.groups.request,
  setSelectedDate: analyticsMoreActions.custom.analytics.selectDate,
  analyticsRequest: analyticsActions.api.analytics.request,
  membersRequest: membersActions.api.members.request,
  setAnalyticsTabIndex: analyticsActions.api.analytics.selectedtabindex,
  analyticsSetState: analyticsActions.api.analytics.setstate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminAnalyticsContainer));
