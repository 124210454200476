import axios from 'axios';
import { get, isEmpty } from 'lodash';
import apiInstance from '../api';
import { objectToFormData } from '../helpers';

export const getPopularTheme = (payload) => {
  return apiInstance({ asAdvisor: true }).get(
    '/theme/popular/?fields=theme_id,name,description,media_urls,created_time,updated_time&limit=5'
  );
};

export const geYourTheme = (payload) => {
  // console.log('GET YOUT THEME FIRED.....');
  return apiInstance({ asAdvisor: true }).get(
    '/theme/?fields=theme_id,name,status,description,media_urls,created_time,updated_time'
  );
};

export const getSearchTheme = (searchString) => {
  return apiInstance({ asAdvisor: true }).get(
    `/theme/search?fields=theme_id,name,description,media_urls,created_time,updated_time&exact_match=false&text=${searchString}`
  );
};

export const getTrendingTopics = () => {
  return apiInstance().get(`/theme/trending/`);
};

export const subscribeTheme = (themeId) => {
  return apiInstance({ asAdvisor: true }).post(`theme/${themeId}/subscribe/`);
};

export const unsubscribeTheme = (themeId) => {
  return apiInstance({ asAdvisor: true }).post(`theme/${themeId}/unsubscribe/`);
};

export const geTrendingTopics = (payload) => {
  return apiInstance().get('/theme/trending');
};

export const getSearchInsights = (
  searchString,
  searchOptions,
  _,
  moreParams = {}
) => {
  const { deleteId, ...rest } = moreParams;
  const searchQuery = {
    search: searchString,
    ...rest,
  };

  // console.log('[SEARCH QUERY] - ', searchQuery);
  let filterQuery;
  const filteredSearchOptions =
    searchOptions && searchOptions.length > 0
      ? searchOptions.filter((s) => s.checked)
      : [];
  // console.log('[FILTERED SEARCH OPTIONS] - ', filteredSearchOptions);
  if (
    isEmpty(filteredSearchOptions) ||
    filteredSearchOptions.length === searchOptions.length
  ) {
    filterQuery = JSON.stringify(searchQuery);
  } else if (
    filteredSearchOptions.length === 1 &&
    filteredSearchOptions[0].platform === 'all'
  ) {
    filterQuery = JSON.stringify(searchQuery);
  } else if (
    filteredSearchOptions.length === 1 &&
    filteredSearchOptions[0].platform !== 'all'
  ) {
    filterQuery = `${JSON.stringify(searchQuery)}?filter={"type": ["${
      filteredSearchOptions[0].platform
    }"]}`;
  } else if (filteredSearchOptions.length === 2) {
    filterQuery = `${JSON.stringify(searchQuery)}?filter={"type": ["${
      filteredSearchOptions[0].platform
    }", "${filteredSearchOptions[1].platform}"]}`;
  }
  return apiInstance().get(`/user/search/content/${filterQuery}`);
};

export const getGroupsList = () => {
  return apiInstance().get('/group?');
};

export const getExploreArticles = (id) => {
  if (!isEmpty(id)) {
    if (Array.isArray(id)) {
      const ids = JSON.stringify(id);
      return apiInstance().get(`/user/content/${ids}`);
    } else return apiInstance().get(`/user/content/["${id}"]`);
  }
};

export const getRecommendedArticles = () => {
  return apiInstance().get(`/user/recommended_article/`);
};

export const getAdminRecommendedArticles = () => {
  return apiInstance({ asAdvisor: true }).get(`/user/recommended_article/`);
};

export const getTopInsights = () => {
  return apiInstance().get(`/user/topinsight/`);
};

export const getTrendingArticles = (tid, date) => {
  return apiInstance().get(`/user/trending/content/${tid}/?date=${date}`);
};

export const deleteArticle = (articleId) => {
  // console.log('[DELETE ID] - ', articleId);
  const form = new FormData();
  form.append('rate', '0');
  return apiInstance()({
    url: `/user/rate/content/${articleId}`,
    method: 'post',
    data: form,
  });
};

export const getTrendWordCloud = () => {
  return apiInstance().get(`/theme/trending/?`);
};

export const sendInvite = (formData) => {
  return apiInstance().post(`/invite/`, objectToFormData(formData));
};

export const getContentLibrary = (channleId, mode, token, type) => {
  // console.log(channleId, 'channelid');
  let filterString =
    type === undefined || type === 'all'
      ? `/channel/${channleId}/content/?filter={"limit": 8}`
      : type === 'Approve To Share'
      ? `/channel/${channleId}/content/?filter={"publish_status":"published", "internal_content" :"false", "limit": 8}`
      : type === 'Internal Use'
      ? `/channel/${channleId}/content/?filter={"internal_content":"true", "limit": 8}`
      : type === 'link'
      ? `/channel/${channleId}/content/?filter={"source":"link", "limit": 8}`
      : type === 'pdf'
      ? `/channel/${channleId}/content/?filter={"source":"pdf", "limit": 8}`
      : type === 'img'
      ? `/channel/${channleId}/content/?filter={"source":"img", "limit": 8}`
      : `/channel/${channleId}/content/?filter={"limit": 8}`;
  let reqString =
    mode === undefined || mode === null
      ? filterString
      : mode === 'before' && (type === undefined || type === 'all')
      ? `${filterString}&&before=${token}`
      : mode === 'before' && (type !== undefined || type !== 'all')
      ? `${filterString}&before=${token}`
      : mode === 'after' &&
        (type === undefined || type === 'all' || type === 'My Added Content')
      ? `${filterString}&&after=${token}`
      : `${filterString}&after=${token}`;
  console.log('FILTER STRING - -- - - ', filterString);
  return apiInstance().get(reqString);
};

export const deleteLibraryArticle = (articleId, myClout = false) => {
  // return deleteArticle(articleId);
  const form = new FormData();
  form.append('rate', '0');
  const postIdSplit = (articleId || '').split('_');
  const postId = postIdSplit[2];
  if (myClout) {
    return apiInstance().delete(`/user/mymio/content/${postId}`);
  } else {
    return apiInstance().delete(`/channel/content/${postId}`);
  }
};

export const getArticleSummary = ({ articleId, themeId }) => {
  return apiInstance({ asAdvisor: true }).get(
    `/summary/${articleId}?theme_id=${themeId}`
  );
};

export const postShareArticle = (payload) => {
  return apiInstance().post(`/share`, objectToFormData(payload));
};

export const postShareAnalytic = (payload) => {
  return apiInstance().post(
    `/share/${payload.id}`,
    objectToFormData(payload.data)
  );
};

//Admin dashboard API's
export const getMioScore = (currentDate, prevDate) => {
  return apiInstance().get(
    `/analytics/${prevDate}/${currentDate}?rep_type=user_insight_score&filter={}`
  );
};

export const postEditorSettings = (form) => {
  return apiInstance().post(`/user/corporateidentity`, objectToFormData(form));
};

export const getPdfLink = (id) => {
  return apiInstance().get(`/user/thirdparty/content/${id}`);
};

export const getPSTVScore = () => {
  return apiInstance().get(`/positivly/score/?filter={%22limit%22:10}`);
};

export const getAllPSTVScores = (beforeId, afterId, selectedContacts) => {
  let reqUrl;
  if (!beforeId && !afterId && !isEmpty(selectedContacts))
    reqUrl = `/positivly/score/?filter={%22limit%22:10, "contact_list":${JSON.stringify(
      selectedContacts
    )}}`;
  else if (!beforeId && !afterId && isEmpty(selectedContacts))
    reqUrl = `/positivly/score/?filter={%22limit%22:10}`;
  else if (beforeId) {
    if (!isEmpty(selectedContacts))
      reqUrl = `/positivly/score/?filter={%22limit%22:10, "contact_list":${JSON.stringify(
        selectedContacts
      )}}&before=${beforeId}`;
    else
      reqUrl = `/positivly/score/?filter={%22limit%22:10}&before=${beforeId}`;
  } else {
    if (!isEmpty(selectedContacts))
      reqUrl = `/positivly/score/?filter={%22limit%22:10, "contact_list":${JSON.stringify(
        selectedContacts
      )}}&after=${afterId}`;
    else reqUrl = `/positivly/score/?filter={%22limit%22:10}&after=${afterId}`;
  }
  return apiInstance().get(`${reqUrl}`);
};

export const getFilteredPSTVScore = (flag) => {
  if (flag === 'all') return getPSTVScore();
  else
    return apiInstance().get(
      `/positivly/score/?filter={%22limit%22:10,%22score%22:%22${flag}%22}`
    );
};

export const getAllFilteredPSTVScores = (
  flag,
  beforeId,
  afterId,
  selectedContacts
) => {
  if (flag === 'all')
    return getAllPSTVScores(beforeId, afterId, selectedContacts);
  else {
    if (!beforeId && !afterId && !isEmpty(selectedContacts))
      return apiInstance().get(
        `/positivly/score/?filter={%22limit%22:10,%22score%22:%22${flag}%22,"contact_list":${JSON.stringify(
          selectedContacts
        )}}`
      );
    else if (!beforeId && !afterId && isEmpty(selectedContacts))
      return apiInstance().get(
        `/positivly/score/?filter={%22limit%22:10,%22score%22:%22${flag}%22}`
      );
    else if (beforeId) {
      if (!isEmpty(selectedContacts))
        return apiInstance().get(
          `/positivly/score/?filter={%22limit%22:10,%22score%22:%22${flag}%22,"contact_list":${JSON.stringify(
            selectedContacts
          )}}&before=${beforeId}`
        );
      else
        return apiInstance().get(
          `/positivly/score/?filter={%22limit%22:10,%22score%22:%22${flag}%22}&before=${beforeId}`
        );
    } else {
      if (!isEmpty(selectedContacts))
        return apiInstance().get(
          `/positivly/score/?filter={%22limit%22:10,%22score%22:%22${flag}%22,"contact_list":${JSON.stringify(
            selectedContacts
          )}}&after=${afterId}`
        );
      else
        return apiInstance().get(
          `/positivly/score/?filter={%22limit%22:10,%22score%22:%22${flag}%22}&after=${afterId}`
        );
    }
  }
};

export const getContactList = () => {
  return apiInstance().get(`/contactlist`);
};

export const getAllContactsCSV = (contactListId) => {
  return apiInstance().get(
    `/contactlist/${contactListId}/contact/?download=true`
  );
};

export const postUpdateContactList = (contactListId, payload) => {
  // console.log('PAYLOAD - ', payload, 'contactlistid = ', contactListId);
  const data = objectToFormData(payload);
  return apiInstance().post(`/contactlist/${contactListId}`, data);
};

export const postSendInvite = (formData) => {
  return apiInstance().post(`/positivly/invite/`, objectToFormData(formData));
};

export const getAllSegmentsCsv = (segmentsId) => {
  return apiInstance().get(`/segments/${segmentsId}/contacts?download=true&include_unsubscribed=true`);
};

export const getMyClientsArticles = (id) => {
  let positivlyId = id ? id : 'SECURITY';
  // console.log('[GET MY CLIENTS ARTICLES] ID - ', id);
  return apiInstance().get(`/pstv/${positivlyId}`);
};

// Advisor dashboard API's
export const getPopularTabTopics = () => {
  return apiInstance().get(
    `/theme/popular/?fields=theme_id,name,description,media_urls,created_time,updated_time&limit=5`
  );
};

export const getInvetmentsTabList = () => {
  return apiInstance({ asAdvisor: true }).get(`/portfoliolist/?`);
};

export const getFundForInvestment = (investmentId) => {
  return apiInstance({ asAdvisor: true }).get(
    `/portfoliolist/${investmentId}/fund/?`
  );
};

export const putInvetmentsTabList = ({ id, tickers }) => {
  const transformedTickers = [];
  tickers.forEach((ticker) => {
    transformedTickers.push(`"${ticker}"`);
  });

  return apiInstance({ asAdvisor: true }).delete(
    `/portfoliolist/${id}/fund/?tickers=[${transformedTickers.join(',')}]`
  );
};

export const deleteInvetmentsTabList = (id) => {
  return apiInstance({ asAdvisor: true }).delete(`/portfoliolist/${id}/?`);
};

export const getInvetmentsArticles = (id, _, selectedTicker, portfolioId) => {
  if (id) {
    return apiInstance({ asAdvisor: true }).get(
      _ === 'stocks'
        ? `/user/portfoliolist/content/stock/${id}/?`
        : `/user/portfoliolist/content/${id}/?`
    );
  } else {
    return apiInstance({ asAdvisor: true }).get(
      `/user/ticker/content/${selectedTicker}/?`
    );
  }
};

export const getApproveToShareArticles = ({ id, source, mode, token }) => {
  const filterString =
    source === 'all'
      ? `/user/complianced/content/?`
      : source === 'Approve To Share'
      ? `/user/complianced/content/?filter={"internal_content" : "false"}`
      : `/user/complianced/content/?filter={"internal_content" : "true"}`;
  return apiInstance({ asAdvisor: true }).get(filterString);
};

export const getThirdPartyContent = (
  id,
  date,
  ticker,
  mode,
  token,
  thirdPartyThemeId
) => {
  let filterString =
    thirdPartyThemeId && thirdPartyThemeId.length > 0
      ? `/user/thirdparty/content/?filter={"themeId": "${thirdPartyThemeId}"}`
      : `/user/thirdparty/content/`;
  let reqString =
    mode === '' || !mode
      ? filterString
      : mode === 'before' && thirdPartyThemeId && thirdPartyThemeId.length > 0
      ? `${filterString}&before=${token}`
      : mode === 'before' && thirdPartyThemeId && thirdPartyThemeId.length === 0
      ? `${filterString}?before=${token}`
      : mode === 'after' && thirdPartyThemeId && thirdPartyThemeId.length > 0
      ? `${filterString}&after=${token}`
      : `/user/thirdparty/content/?after=${token}`;
  return apiInstance().get(reqString);
};

export const getSponsorList = () => {
  return apiInstance().get(`user/sponsored/content/?`);
};

export const getPortfolioList = () => {
  return apiInstance({ asAdvisor: true }).get(`/portfolio/`);
};

export const getPortfolioFund = (id) => {
  return apiInstance({ asAdvisor: true }).get(`/portfoliolist/${id}/fund/`);
};

export const postCSVPortolioFund = (id, payload) => {
  payload.funds = JSON.stringify(payload.funds);
  return apiInstance({ asAdvisor: true }).post(
    `/portfoliolist/${id}/fund/`,
    objectToFormData(payload)
  );
};

export const getUserPortfolioFund = (id) => {
  return apiInstance({ asAdvisor: true }).get(`/portfolio/${id}/fund/`);
};
export const postPortfolioFund = (id, payload) => {
  payload.funds = JSON.stringify(payload.funds);
  return apiInstance({ asAdvisor: true }).post(
    `/portfoliolist/${id}/fund/`,
    objectToFormData(payload)
  );
};

export const postUserPortfolio = (payload) => {
  return apiInstance({ asAdvisor: true }).post(
    `/portfoliolist/`,
    objectToFormData(payload)
  );
};

export const updatePortfolioList = (id) => {
  return apiInstance({ asAdvisor: true }).post(`/portfoliolist/${id}`);
};

export const getChannelList = () => {
  return apiInstance().get(
    `/channel/?fields=channel_id,name,description,created_time,updated_time,media_urls,subscription_type,is_hidden`
  );
};

export const getAdminChannelData = () => {
  return apiInstance({ asAdvisor: true }).get(
    `/channel/?fields=channel_id,name,description,created_time,updated_time,media_urls,subscription_type,is_hidden`
  );
};

export const getPremiumAdvisorChannelId = () => {
  return apiInstance().get(
    `/channel/publisher/?fields=channel_id,name,description,created_time,updated_time,media_urls,subscription_type,is_hidden`
  );
};

export const postUnsubscribeChannel = (channelId) => {
  return apiInstance({ asAdvisor: true }).post(
    `/channel/${channelId}/unsubscribe`
  );
};
export const postSubscribeChannel = (channelId) => {
  return apiInstance({ asAdvisor: true }).post(
    `/channel/${channelId}/subscribe`
  );
};

export const getMyMemberChannel = () => {
  // return list channels we need to show on page
  return apiInstance().get(
    `/channel/member/?fields=channel_id,name,description,created_time,updated_time,media_urls,subscription_type,is_hidden`
  );
};

export const postMyMemberChannel = (channelId, payload) => {
  // click on change add /remove
  return apiInstance().post(
    `/channel/${channelId}/member/`,
    objectToFormData(payload)
  );
};

export const postMyPublisherUnsubscribeChannel = (channelId) => {
  // click on change add /remove
  return apiInstance().post(`/channel/${channelId}/unsubscribe`);
};

export const postMyPublisherSubscribeChannel = (channelId) => {
  // click on change add /remove
  return apiInstance().post(`/channel/${channelId}/subscribe`);
};

export const postSuggestPartner = (payload) => {
  let formData = new FormData();
  formData.append('to_json', JSON.stringify(payload.to_json));
  formData.append('subject', payload.subject);
  formData.append('body', payload.body);
  formData.append('Partner Link', payload.sc_Link);
  return apiInstance().post(`/email`, formData);
};

export const getRecentSharedArticles = () => {
  return apiInstance().get(`/user/shared/content/?`);
};

export const getScheduledArticles = () => {
  return apiInstance().get(`/user/scheduled/content/?`);
};

export const deleteScheduledArticles = (id) => {
  return apiInstance().delete(`/user/scheduled/content/${id}?`);
};

export const getMyMioArticles = (id, source) => {
  let filterString =
    source === 'all'
      ? `/user/mymio/content/`
      : source === 'link'
      ? `/user/mymio/content/?filter={"source" : "link"}`
      : `/user/mymio/content/?filter={"source" : "pdf"}`;
  if (source === 'img') return;
  return apiInstance().get(filterString);
};

export const getComplianceArticles = (type, page, date) => {
  let reqString = '';
  if (date && date.startDate && page === undefined) {
    reqString = `compliance/?filter={"status":"${type}", "start_date" : "${date.startDate}", "end_date" : "${date.endDate}"}`;
  } else if (date && date.startDate && page === 'before') {
    reqString = `compliance/?filter={"status":"${type}", "start_date" : "${date.startDate}", "end_date" : "${date.endDate}"}&before=${page}`;
  } else if (date && date.startDate && page !== undefined && page !== 'before') {
    reqString = `compliance/?filter={"status":"${type}", "start_date" : "${date.startDate}", "end_date" : "${date.endDate}"}&after=${page}`;
  } else {
    reqString = `compliance/?filter={"status":"${type}"}`;
  }

  return apiInstance().get(reqString);
};

export const getComplianceTrail = (compliance_id) => {
  return apiInstance().get(`compliance/trail/${compliance_id}`);
};

export const resubmitArticle = (articleData) => {
  return apiInstance().post(
    `compliance/${articleData.compliance_id}/reshared`,
    objectToFormData(articleData)
  );
};

export const postComplianceRequest = (id, data) => {
  return apiInstance().post(`compliance/${id}`, objectToFormData(data));
};
export const getChannelContent = ({ channelId }) => {
  return apiInstance().get(`/channel/${channelId}/content/`);
};

export const getTopDetails = (dateTo, dateFrom, rep_type) => {
  return apiInstance().get(
    `/analytics/${dateFrom}/${dateTo}?rep_type=${rep_type}&filter={}`
  );
};

export const getHtmlText = (url) => {
  return axios.get(url).then((response) => {
    return get(response, 'responseText', '');
  });
};

export const getEmailData = (articleId) => {
  return apiInstance({ asAdvisor: true }).get(`/share/email/${articleId}`);
};

export const getComplianceEmailData = (articleId) => {
  return apiInstance().get(`/share/email_data/${articleId}`);
};

export const postCreateContent = ({
  channelId,
  link,
  source,
  title,
  attachment1,
  summary,
  skip_compliance,
  themes,
  message,
  publish_status,
  original_content_id,
  url,
  autoshare_on_subscribers_snetworks,
  autoshare_on_subscribers_snetworks_datetime,
  autoshare_on_subscriber_ids,
  email_data,
  lead_gen,
  referral,
}) => {
  let reqObj = {
    ...(link && { link }),
    ...(title && { title }),
    message: message || ' ',
    source,
    skip_compliance:
      typeof skip_compliance === 'boolean' ? skip_compliance : true,
    ...(!isEmpty(summary) && {
      summary: JSON.stringify(summary),
    }),
    ...(attachment1 && { attachment1 }),
    ...(themes && { themes }),
    ...(publish_status && { publish_status }),
    ...(original_content_id && { original_content_id }),
  };
  if (url) {
    const file = dataURLtoFile(url, 'filename.png');
    reqObj.media1 = file;
  }

  if (autoshare_on_subscribers_snetworks) {
    reqObj.autoshare_on_subscribers_snetworks = JSON.stringify(
      autoshare_on_subscribers_snetworks
    );
  }
  if (!!autoshare_on_subscribers_snetworks_datetime) {
    reqObj.autoshare_on_subscribers_snetworks_datetime =
      autoshare_on_subscribers_snetworks_datetime;
  }
  if (autoshare_on_subscriber_ids) {
    reqObj.autoshare_on_subscriber_ids = autoshare_on_subscriber_ids;
  }
  if (email_data) reqObj.email_data = email_data;

  if (lead_gen) {
    reqObj.lead_gen = lead_gen;
  }

  if (referral) {
    reqObj.referral = referral;
  }
  const data = objectToFormData(reqObj);
  return apiInstance().post(`/channel/${channelId}/content`, data);
};

export const postThemeFetch = (payload) => {
  const { text, media1, url } = payload;
  const data = objectToFormData({
    ...(url && { url }),
    ...(text && { text }),
    ...(media1 && { media1 }),
    return_text: true,
  });
  return apiInstance().post(`/theme/fetch`, data);
};

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const postUpdateContent = ({
  contentId,
  themes,
  isMioShare,
  summary,
  skip_compliance,
  message,
  title,
  user_summary,
  media1,
  autoshare_on_subscribers_snetworks,
  autoshare_on_subscriber_ids,
  autoshare_on_subscribers_snetworks_datetime,
  sharing,
  unpublished,
  internalUse,
  internalSharing,
  email_data,
  lead_gen,
  referral,
  fromEditModal,
}) => {
  // console.log('postUpdateContent : autoshare_on_subscribers_snetworks_datetime: ')
  // console.log(autoshare_on_subscribers_snetworks_datetime);
  // console.log('REQ OBJ MESSAGE - - - - - -', message);
  let reqObj = {
    ...(title && { title }),
    skip_compliance:
      typeof skip_compliance === 'boolean' ? skip_compliance : true,
    ...(isMioShare &&
      !isEmpty(summary) && {
        summary: JSON.stringify(summary),
      }),
    message: message || ' ',
    // user_summary: user_summary,
    ...(!isEmpty(themes) && { themes: JSON.stringify(themes) }),
  };

  if (!fromEditModal) {
    if (internalUse) {
      reqObj.internal_content = true;
    } else if (internalSharing) {
      reqObj.publish_status = 'published';
      reqObj.internal_content = true;
      reqObj.summary = JSON.stringify(summary);
    } else {
      reqObj.publish_status = sharing
        ? 'published'
        : unpublished
        ? 'unpublished'
        : 'draft';
      reqObj.internal_content = false;
      reqObj.approved_to_share = "true";
    }
  }

  if (autoshare_on_subscribers_snetworks_datetime) {
    reqObj.autoshare_on_subscribers_snetworks_datetime =
      autoshare_on_subscribers_snetworks_datetime;
  }

  if (media1) {
    const file = dataURLtoFile(media1, 'filename.png');
    reqObj.media1 = file;
  }

  if (autoshare_on_subscribers_snetworks) {
    reqObj.autoshare_on_subscribers_snetworks = JSON.stringify(
      autoshare_on_subscribers_snetworks
    );
  }
  if (autoshare_on_subscriber_ids) {
    reqObj.autoshare_on_subscriber_ids = autoshare_on_subscriber_ids;
  }
  if (email_data) reqObj.email_data = email_data;

  if (lead_gen) {
    reqObj.lead_gen = lead_gen;
  }

  if (referral) {
    reqObj.referral = referral;
  }
  const data = objectToFormData(reqObj);
  return apiInstance().post(`/channel/content/${contentId}`, data);
};

export const getSocialAccountStatus = () => {
  return apiInstance().get(`/social/account/status/`);
};

export const deleteAccountId = ({ id }) => {
  return apiInstance().delete(`/social/account/${id}`);
};

export const postUpdateContentEnterpriseAdmin = ({ id }) => {
  return apiInstance().post(`/channel/content/${id}/cascade`);
};

export const getSocialAccountURLs = () => {
  const extraHeader = {
    'X-MIO-RedirectURL': window.location.href,
    // 'X-MIO-RedirectURL': 'localhost:3000',
  };
  return apiInstance({ asAdvisor: false, extraHeader }).get(
    `/social/account/url`
  );
  // return apiInstance().get(`/social/account/url`);
};

export const resheduleArticle = ({ id, isScheduled, ...rest }) => {
  return apiInstance().post(
    `/user/scheduled/content/${id}/?`,
    objectToFormData(rest)
  );
};

export const mioEditorUploadImage = (payload) => {
  return apiInstance().post(
    '/user/corporateidentity',
    objectToFormData(payload)
  );
};

export const getThirdPartyThemes = () => {
  return apiInstance().get(
    '/theme/?fields=theme_id,name,status,description,media_urls,created_time,updated_time&third_party_themes_only=true'
  );
};

export const getScheduleArticleDetail = ({ id }) => {
  return apiInstance().get(`/user/scheduled/content/${id}?`);
};

export const getBookmarkedArticles = () => {
  return apiInstance().get('/bookmark/');
};

export const postAddToBookmarks = (articleIds, themeId) => {
  const stringifiedArray = JSON.stringify([articleIds]);
  const rawData = themeId
    ? { article_ids: stringifiedArray, theme_id: themeId }
    : { article_ids: stringifiedArray };
  return apiInstance().post(`/bookmark`, objectToFormData(rawData));
};

export const deleteFromBookmarks = (articleId) => {
  // console.log('data before api call - ', articleId);
  return apiInstance().delete(`/bookmark/${articleId}`);
};

export const postArticlesToNewsletterBucket = ({ articleId, themeId }) => {
  const payload = {
    article_ids: JSON.stringify([articleId]),
  };

  if (themeId) {
    payload.theme_id = themeId;
  }

  return apiInstance().post(`/newsletter/bucket/`, objectToFormData(payload));
};

export const clearAllBucketArticles = () => {
  return apiInstance().delete(`/newsletter/bucket/clear`);
};

export const getCRMArticles = (themes) => {
  // console.log('FINAL API CALL........');
  // const themesList = themes.join(', ');
  const themesList = JSON.stringify(themes);
  return apiInstance().get(
    `/contactlist/ZbjY00YZ/articles?theme_ids=${themesList}`
  );
};

export const postComplianceNotification = (payload) => {
  return apiInstance().post(
    '/compliance/notification/',
    objectToFormData(payload)
  );
};

export const getNewsletterData = (id) => {
  return apiInstance().get(`/share/email_data/${id}`);
};

export const getCompliancePDF = ({ startDate, endDate, status, emails }) => {
  let filter;

  filter = JSON.stringify({
    status: status,
    start_date: startDate,
    end_date: endDate,
  });
  let formData = new FormData();
  formData.append('email_ids', JSON.stringify(emails));

  return apiInstance().post(`compliance/export/?filter=${filter}`, formData);
};

// Dont Remove: for testing in local

// export const getSocialAccountURLs = () => {
//   const extraHeader = {
//     'X-MIO-RedirectURL': 'http://localhost:3000/login',
//   };
//   let authToken = window.sessionStorage.getItem('token');
//   return axios
//     .get('http://localhost:3999/contacturl', {
//       headers: {
//         Authorization: `Bearer ${authToken}`,
//         ...extraHeader,
//       },
//     })
//     .then((response) => {
//       return response.data;
//     });
//   // return apiInstance().get(`/social/account/url`);
// };

export const getAllUnsubscribes = () => {
  return apiInstance().get(`/contact/unsubscribed`);
};
