import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DigitalImage = styled.img`
  font-size: 28px;
  width: 50px !important;
  height: 50px;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  color: #ffffff;
  background: #6351ed;
  padding: 12px;
  margin: 10px;
`;

export const FontAwesome = styled(FontAwesomeIcon)`
  font-size: 28px;
  width: 50px !important;
  height: 50px;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  color: #ffffff;
  background: #6351ed;
  padding: 12px;
  margin: 10px;
`;

export const DisplayBlock = styled.div`
  display: inline-block;
  margin: 0;
`;

export const AutoShare = styled.div`
  margin-top: 10px;
`;
