import React from 'react';
import { Flex, Box } from '@rebass/grid';
import { Formik } from 'formik';
import {
  Modal,
  Input,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  MioAutoComplete,
} from '../';
import { ErrorContainer } from '../CommonStyles';
import { submitTopic } from '../../services/configure';

const validate = (values) => {
  const nameRegex = /^[a-zA-Z\s]*$/;
  if (!values.topicName) {
    return { formError: 'Please enter topic name' };
  }

  if (!nameRegex.test(values.topicName)) {
    return { formError: 'Please enter only alphabets for topic name' };
  }
};

const SubmitTopicModal = ({ isModalOpen, setModalOpen, themesList }) => {
  const topicSubmit = ({ parentTopicName, topicName }) => {
    submitTopic({
      suggestedTopic: topicName,
      parentTopic: parentTopicName,
    }).then(() => {
      setModalOpen(false);
    });
  };

  return (
    <Modal
      onRequestClose={() => setModalOpen(false)}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      size="medium"
    >
      <ModalHeader>
        Submit A Topic
        <ModalCloseButton onClick={() => setModalOpen(false)} />
      </ModalHeader>
      <ModalBody>
        <Formik
          validate={validate}
          validateOnChange={false}
          onSubmit={topicSubmit}
          initialValues={{
            topicName: '',
            topicType: 'parent',
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, errors, values }) => (
            <Flex width={1} flexDirection="column">
              <Box width={1}>
                <Input
                  id="topicName"
                  name="topicName"
                  placeholder="Topic Name"
                  onChange={handleChange}
                />
              </Box>
              <Box my="10px">
                <input
                  type="radio"
                  name="topicType"
                  id="topicType1"
                  onChange={() => setFieldValue('topicType', 'parent')}
                />
                <label for="topicType1">Parent</label>
                <input
                  type="radio"
                  name="topicType"
                  id="topicType2"
                  onChange={() => setFieldValue('topicType', 'child')}
                />
                <label for="topicType2">Child</label>
              </Box>
              {values.topicType === 'child' && (
                <Box>
                  <MioAutoComplete
                    options={themesList.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    }))}
                    onChange={({ label }) =>
                      setFieldValue('parentTopicName', label)
                    }
                  />
                </Box>
              )}
              <Box width={1}>
                <Button onClick={handleSubmit}>Submit</Button>
              </Box>
              <ErrorContainer align="center">{errors.formError}</ErrorContainer>
            </Flex>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default SubmitTopicModal;
