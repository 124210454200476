import { Flex } from '@rebass/grid';
import { isEmpty } from 'lodash';
import React from 'react';
import { Fragment } from 'react';
import { DripIconButton, Loader, ResponsiveImg } from '../../../components';
import {
  eventHandlers,
  ignoredFunds,
  onImageError,
  themesData,
} from '../../../utils';
import { NewsletterCheckBoxDiv } from '../Recipients/Recipients.styles';
import {
  DefaultLayout,
  NewsletterArticleWrapper,
  OneColumnLayout,
} from './NewsletterArticle.styles';

const NewsletterArticle = ({
  url,
  themeNames,
  themeId,
  date,
  topic,
  description,
  id,
  checkbox,
  place,
  year,
  article,
  handleBucketArticleClick,
  newsletterArticles,
  bucketId,
  handleDeleteArticle,
  draggable,
  deleteBlocked,
  handleDrag,
  handleDrop,
  deleteId,
  isDeleting,
  openUrl,
  link,
  width,
  imgHeight,
  imgWidth,
  maxImgWidth,
  height,
  margin,
  emailTemplateSelected,
  sponsoredFunds,
  userData,
  title,
  section,
  articleType,
  popularThemes,
  channelId,
  isCampaign,
  oneSelectMode,
  isFromCampaign,
  auxiliary,
  activeSuggestedArticlesTab,
  key,
  articleIndex,
}) => {
  console.log('ARTICLE - - - - ', newsletterArticles);
  let trimmedThemeName =
    (themeNames[themeId] || '').length > 20
      ? `${themeNames[themeId].substring(0, 20)}...`
      : themeNames[themeId];
  let trimmedPlace =
    (place || '').length > 20 ? `${place.substring(0, 20)}...` : place;

  let trimmedTopic = isCampaign
    ? (topic || '').length > 25
      ? `${topic.substring(0, 25)}...`
      : topic
    : (topic || '').length > 30
    ? `${topic.substring(0, 30)}...`
    : topic;

  let trimmedDesc = isCampaign
    ? (description || '').length > 90
      ? `${description.substring(0, 90)}...`
      : description
    : (description || '').length > 100
    ? `${description.substring(0, 100)}...`
    : description;

  const handleDragover = (e) => {
    if (draggable) {
      e.preventDefault();
    }
  };

  const onHandleDrag = (e) => {
    if (draggable) {
      handleDrag(e);
    }
  };

  const onDropDrag = (e) => {
    if (draggable) {
      handleDrop(e);
    }
  };

  const redirectToArticleUrl = () => {
    console.log(openUrl);
    if (openUrl) {
      window.open(link, '_blank');
    }
  };

  const redirectToSelectedArticleURL = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  const redirectToMagnifi = (key) => {
    if (!ignoredFunds.includes(key)) {
      const userEmail =
        userData &&
        userData.details &&
        userData.details.user &&
        userData.details.user.email;
      let url;
      if (key === 'LTG/AQDCT') {
        url =
          'https://www.wealthmanagement.com/investment/swift-chat-ariel-acuna-creator-aqueduct-strategy';
      } else if (key === 'Cult Wines') {
        url = 'https://www.cultwines.com/';
      } else {
        url = `https://magnifi.com/securities?query=${key}&utm_source=clout&utm_medium=clout_sponsored_articles&utm_campaign=clout_sponsored_funds&buy=${key}`;
      }
      window.open(url, '_blank');
      eventHandlers(
        'SPONSORED_TICKER_CLICKED',
        popularThemes,
        id,
        section,
        channelId,
        title,
        themeId,
        themeNames[themeId],
        topic,
        userEmail,
        key,
        url
      );
    }
  };

  return (
    <NewsletterArticleWrapper
      draggable={draggable}
      onDragOver={(e) => handleDragover(e)}
      onDragStart={onHandleDrag}
      onDrop={onDropDrag}
      id={isFromCampaign ? `campaign_article_${articleIndex}` : bucketId}
      style={{
        cursor: openUrl || isCampaign ? (oneSelectMode ? '' : 'pointer') : '',
        border:
          isCampaign && newsletterArticles.filter((n) => n.id === id).length > 0
            ? '1px solid'
            : '',
      }}
      onClick={!isCampaign ? redirectToArticleUrl : () => {}}
      width={
        emailTemplateSelected === 'one-column'
          ? '100%'
          : isCampaign
          ? '25%'
          : width
      }
      height={emailTemplateSelected === 'one-column' ? '140px' : height}
      margin={margin}
      flexDirection={emailTemplateSelected === 'one-column' ? 'row' : 'column'}
      imgHeight={imgHeight || emailTemplateSelected === 'one-column' ? '100%' : '45%'}
      imgWidth={imgWidth || emailTemplateSelected === 'one-column' ? '100%' : 'px'}
      maxImgWidth={maxImgWidth || emailTemplateSelected === 'one-column' ? '310px' : ''}
      rightImgBorder={emailTemplateSelected === 'one-column' ? '0px' : '8px'}
    >
      {emailTemplateSelected === 'one-column' ? (
        <div style={{ width: '310px' }}>
          <ResponsiveImg src={url} onError={onImageError} />
        </div>
      ) : (
        <ResponsiveImg
          src={url}
          onError={onImageError}
          onClick={
            isCampaign && oneSelectMode
              ? ''
              : () => handleBucketArticleClick(article)
          }
        />
      )}

      <div style={{ width: emailTemplateSelected === 'one-column' && '75%' }}>
        <div
          onClick={
            isCampaign && oneSelectMode
            ? ''
            : () => {}
            // : () => handleBucketArticleClick(article)
          }
        >
          <Flex
            padding={
              emailTemplateSelected === 'one-column'
                ? '5px 5px 2px'
                : '5px 10px 2px'
            }
            fontSize="12px"
            justifyContent="space-between"
            style={{
              minHeight: '25px',
              textAlign: 'left',
              display: isCampaign && '-webkit-box',
              '-webkit-line-clamp': isCampaign && '1',
              '-webkit-box-orient': isCampaign && 'vertical',
              overflow: isCampaign && 'hidden',
            }}
          >
            <div
              style={{
                textTransform: 'uppercase',
                color: themesData.purpleishBlue,
                textAlign: 'left',
                width: '89%'
              }}
              onClick={() => handleBucketArticleClick(article)}
            >
              {trimmedThemeName}
            </div>
            {checkbox && (
              <NewsletterCheckBoxDiv>
                <label class="container">
                  <input
                    type="checkbox"
                    onChange={() => handleBucketArticleClick(article)}
                    checked={
                      isCampaign
                        ? newsletterArticles.filter((n) => n.id === id).length >
                          0
                        : newsletterArticles.filter(
                            (n) => n.bucketId === bucketId
                          ).length > 0
                    }
                  />
                  <span class="checkmark"></span>
                </label>
              </NewsletterCheckBoxDiv>
            )}
          </Flex>

          <Flex
            padding={
              emailTemplateSelected === 'one-column' ? '0px 5px' : '0px 10px'
            }
            fontSize="10px"
            style={{ fontWeight: '600' }}
            color={themesData.charcoalGrey}
            onClick={() => handleBucketArticleClick(article)}
          >
          {
              !isCampaign ? 
              (articleType == 'my-content' ? 'PUBLISHED, ' + date : trimmedPlace + ', ' + date + ' ' + year) :
              trimmedPlace ? trimmedPlace + ', ' : '' + date
          }
          </Flex>

          {emailTemplateSelected === 'one-column' ? (
            <OneColumnLayout
              fontSize="12px"
              fontWeight="600"
              minHeight="30px"
              lineClamp="1"
              color={themesData.charcoalGrey}
              onClick={() => handleBucketArticleClick(article)}
            >
              {topic}
            </OneColumnLayout>
          ) : (
            <DefaultLayout
              padding="5px 10px 5px"
              fontSize="12px"
              fontWeight="600"
              minHeight="30px"
              color={themesData.charcoalGrey}
              onClick={() => handleBucketArticleClick(article)}
            >
              {trimmedTopic}
            </DefaultLayout>
          )}

          {emailTemplateSelected === 'one-column' ? (
            <OneColumnLayout
              fontSize="10px"
              minHeight="42px"
              lineClamp="2"
              color={themesData.charcoalGrey}
              onClick={() => handleBucketArticleClick(article)}
            >
              {description}
            </OneColumnLayout>
          ) : (
            <DefaultLayout
              padding="0px 10px 5px"
              fontSize="10px"
              minHeight="60px"
              color={themesData.charcoalGrey}
              onClick={() => handleBucketArticleClick(article)}
            >
              {trimmedDesc}
            </DefaultLayout>
          )}
        </div>

        <Flex
          padding="0px 10px 5px"
          fontSize="10px"
          style={{ textAlign: 'left' }}
          justifyContent={isCampaign && 'center'}
          color={themesData.charcoalGrey}
          width="100%"
          alignItems="center"
          mt={emailTemplateSelected === 'one-column' ? '7px' : ''}
        >
          {isCampaign &&
            (auxiliary
              ? auxiliary.source
                ? auxiliary.source != 'img'
                : true
              : true) && (
              <a
                onClick={redirectToSelectedArticleURL}
                style={{ cursor: 'pointer' }}
              >
                View Article
              </a>
            )}
          {!deleteBlocked && (
            <>
              {deleteId !== bucketId && (
                <DripIconButton
                  icon="trash"
                  onClick={() => handleDeleteArticle(bucketId)}
                  styles={{ position: 'relative', top: '1px', left: '-6px' }}
                />
              )}
              {isDeleting && deleteId === bucketId && <Loader height="25px" />}
            </>
          )}

          {!isCampaign && !isEmpty(sponsoredFunds) && (
            <strong
              style={{
                textAlign: 'center',
                width: deleteBlocked ? '100%' : '80%',
              }}
            >
              {Object.keys(sponsoredFunds).map((key, index, funds) => {
                return (
                  <span
                    style={{
                      cursor: ignoredFunds.includes(key)
                        ? 'default'
                        : 'pointer',
                      color: themesData.purpleishBlue,
                    }}
                    title={sponsoredFunds[key]}
                    onClick={() => redirectToMagnifi(key)}
                  >
                    {key}
                    {index < funds.length - 1 && ', '}
                  </span>
                );
              })}
            </strong>
          )}
        </Flex>
      </div>
    </NewsletterArticleWrapper>
  );
};

export default NewsletterArticle;
