/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { Flex } from '@rebass/grid';

import {
  ShareCard,
  Chips,
  InputLabel,
  CheckboxInput,
  RadioInput,
  RadioInputLabel,
  ApprovalCard,
} from './SocialPreview.styles';

import './style.css';
import { get, isEmpty } from 'lodash';
import { getArticleSummary } from '../../../services/dashboard';

export const ShareCopyCard = ({
  objectivesTabData,
  activePlatform,
  setActivePlatform,
  msgCopy,
  setMsgCopy,
  isMsgCopyAllPlatform,
  setIsMsgCopyAllPlatform,
  setIsApprovalRequired,
  isApprovalRequired,
  isadvMode,
  isAdvisorApprovalMode,
  isEdit,
  isFbSelected,
  isLnSelected,
  isTwSelected,
  campaignStatus,
  userData,
  skipCompliance,
  setSkipCompliance,
  disableEdit,
  isSummaryFetching,
  isEnterpriseLevelAdmin,
  isFirmLevelAdmin,
  firmSettings,
  isGlobalCompliance,
  companyId
}) => {

  console.log(isGlobalCompliance);
  const [showCompliance, toggleShowCompliance] = useState(false);

  useEffect(() => {
    let show = true;
    if (campaignStatus === 'compliance_rejected')
      show = false;
    if(show) {
      if(isFirmLevelAdmin && !isEmpty(firmSettings))
        show = get(firmSettings, `firm_compliance.${[companyId]}.compliance_is`, '') === 'optional';
      else
        show = get(userData, 'compliance', '') === 'optional'
    }
    toggleShowCompliance(show);
  }, []);
  

  const manageAllPlatformCheck = (checked) => {
    if (!checked) {
      setMsgCopy({
        ...msgCopy,
        fb: msgCopy[activePlatform].substring(0, 2950),
        tw: msgCopy[activePlatform].substring(0, 250),
        ln: msgCopy[activePlatform].substring(0, 2950),
      });
    } else {
      setMsgCopy({
        ...msgCopy,
        fb: activePlatform === 'fb' ? msgCopy[activePlatform].substring(0, 2950) : '',
        tw: activePlatform === 'tw' ? msgCopy[activePlatform].substring(0, 250) : '',
        ln: activePlatform === 'ln' ? msgCopy[activePlatform].substring(0, 2950) : '',
      });
    }

    setIsMsgCopyAllPlatform(!isMsgCopyAllPlatform);
  };


  const getMessageCopyMaxLength = () => {
    if (activePlatform === 'fb' || activePlatform === 'ln') return 2950;
    else if (activePlatform === 'tw') return 250;
    else return '';
  };

  return (
    <Flex display="flex" flexDirection="column">
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        style={{
          backgroundColor: '#eee',
          borderRadius: '5px',
          marginBottom: '10px',
          marginLeft: '25px',
          marginTop: '25px',
          width: 'fit-content',
        }}
      >
        {(!objectivesTabData.myNetwork || !isEmpty(get(objectivesTabData, 'fbList', []))) && (
          <Chips
            onClick={() => {
              setActivePlatform('fb');
            }}
            active={activePlatform === 'fb'}
          >
            <div className="chip">
              <img src="/assets/images/campaign-platform-facebook.png" alt="" />
              Facebook
            </div>
          </Chips>
        )}
        {(!objectivesTabData.myNetwork || !isEmpty(get(objectivesTabData, 'lnList', []))) && (
          <Chips
            onClick={() => {
              setActivePlatform('ln');
            }}
            active={activePlatform === 'ln'}
          >
            <div className="chip">
              <img src="/assets/images/campaign-platform-linkedIn.png" alt="" />
              LinkedIn
            </div>
          </Chips>
        )}
        {(!objectivesTabData.myNetwork || !isEmpty(get(objectivesTabData, 'twList', []))) && (
          <Chips
            onClick={() => {
              setActivePlatform('tw');
            }}
            active={activePlatform === 'tw'}
          >
            <div className="chip">
              <img src="/assets/images/campaign-platform-twitter.png" alt="" />
              Twitter
            </div>
          </Chips>
        )}
      </Flex>
      {(!get(objectivesTabData, 'myNetwork', false) || (isFbSelected + isLnSelected + isTwSelected > 1)) && (
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          alignSelf="flex-start"
          ml="25px"
        >
          <CheckboxInput
            type="checkbox"
            id="samePlatform"
            name="samePlatform"
            onClick={() => manageAllPlatformCheck(isMsgCopyAllPlatform)}
            checked={isMsgCopyAllPlatform}
            disabled={
              get(objectivesTabData, 'isCascaded', 'false') === 'true' ||
              isSummaryFetching || 
              (msgCopy.fb.length === 0 && msgCopy.ln.length === 0  && msgCopy.tw.length === 0 )
            }
          />
          <InputLabel
            fontSize="10px"
            fontWeight="400"
            color="#bbb"
            marginLeft="5px"
          >
            Use same copy for all platforms
          </InputLabel>
        </Flex>
      )}
      <ShareCard>
        <textarea
          maxLength={getMessageCopyMaxLength()}
          placeholder="Message Copy"
          name={`share_copy_${activePlatform}`}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '4px',
            padding: '10px 15px',
            color: '#4E6780',
            border: '1px solid #c9d0d8',
            resize: 'none',
            maxHeight: '260px',
            maxWidth: '760px',
          }}
          disabled={activePlatform === '' || disableEdit || isSummaryFetching}
          value={isSummaryFetching ? 'Generating Summary...' : msgCopy[activePlatform]}
          onChange={(e) => {
            if (isMsgCopyAllPlatform) {
              setMsgCopy({
                ...msgCopy,
                fb: e.target.value.substring(0, 2950),
                ln: e.target.value.substring(0, 2950),
                tw: e.target.value.substring(0, 250),
              });
            } else {
              setMsgCopy({ ...msgCopy, [activePlatform]: e.target.value });
            }
          }}
        />
      </ShareCard>
      {isadvMode &&
        isAdvisorApprovalMode &&
        (!isEdit || campaignStatus === 'draft') && (
          <ApprovalCard>
            <Flex
              alignItems="center"
              justifyContent="center"
              mr="50px"
              mt="3px"
            >
              <label
                style={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
                data-testid="advisor_approval_label"
              >
                Advisor Approval Required?
              </label>
            </Flex>
            <Flex alignItems="center" justifyContent="center" mr="5px">
              <RadioInput
                type="radio"
                id="advisor_approval_yes"
                name="advisor_approval"
                checked={isApprovalRequired}
                onClick={(e) => {
                  setIsApprovalRequired(true);
                }}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="advisor_approval_yes"
                style={{ cursor: 'pointer' }}
              >
                Yes
              </RadioInputLabel>
            </Flex>
            <Flex alignItems="center" justifyContent="center">
              <RadioInput
                type="radio"
                id="advisor_approval_no"
                name="advisor_approval"
                checked={!isApprovalRequired}
                onClick={(e) => {
                  setIsApprovalRequired(false);
                }}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="advisor_approval_no"
                style={{ cursor: 'pointer' }}
              >
                No
              </RadioInputLabel>
            </Flex>
          </ApprovalCard>
        )}
      {showCompliance && isGlobalCompliance !== 'true' && (
          <ApprovalCard>
            <Flex
              alignItems="center"
              justifyContent="center"
              mr="15px"
              mt="3px"
            >
              <label
                style={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
                data-testid="compliance_approval_label"
              >
                Compliance Approval Required?
              </label>
            </Flex>
            <Flex alignItems="center" justifyContent="center" mr="5px">
              <RadioInput
                type="radio"
                id="compliance_approval_yes"
                name="compliance_approval"
                checked={!skipCompliance}
                onClick={(e) => {
                  setSkipCompliance(false);
                }}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="compliance_approval_yes"
                style={{ cursor: 'pointer' }}
              >
                Yes
              </RadioInputLabel>
            </Flex>
            <Flex alignItems="center" justifyContent="center">
              <RadioInput
                type="radio"
                id="compliance_approval_no"
                name="compliance_approval"
                checked={skipCompliance}
                onClick={(e) => {
                  setSkipCompliance(true);
                }}
                style={{ cursor: 'pointer' }}
              />
              <RadioInputLabel
                htmlFor="compliance_approval_no"
                style={{ cursor: 'pointer' }}
              >
                No
              </RadioInputLabel>
            </Flex>
          </ApprovalCard>
        )}
    </Flex>
  );
};
