import { Flex } from '@rebass/grid';
import React from 'react';
import { CampaignFilterButton } from '../DripDashboardFilter.styles';

const DripDashboardFilterTab = ({ selected, tab, click }) => {
  return (
    <CampaignFilterButton selected={selected} onClick={click} name={tab.name}>
      <div style={{ width: '35%' }}>
        <img src={selected ? tab.img.active : tab.img.disabled} />
      </div>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        style={{ width: '65%' }}
      >
        <strong style={{ color: '#000000' }}>{tab.count}</strong>
        <span style={{ color: selected ? '#4e6780' : '#4E6780' }}>
          {tab.name}
        </span>
      </Flex>
    </CampaignFilterButton>
  );
};

export default DripDashboardFilterTab;
