import React, { useEffect } from 'react';
import { Flex } from '@rebass/grid';
import { Button, ErrorContainer, LoaderWrapper, MioRadio } from '../../../components';
import {
  GeneralSettingsWrapper,
  GeneralSettingsContainer,
  Info,
  RadioWrapper,
} from './GeneralSettings.styles';
import { useState } from 'react';
import { updateAdvApproval } from '../../../services/configure';
import { Tooltip } from '@material-ui/core';
import AdvisorSettings from '../../AdvisorSettings/AdvisorSettings';
import {
  salesforceSettingsMapper,
  salesforceTogglesMapper,
} from '../../../utils';
import { SetObjectivesInput } from '../../DripCampaignContainer/SetObjectives/SetObjectives.styles';
import { get, isEmpty } from 'lodash-es';
import BccField from '../../../components/BccField'

export const GeneralSettings = ({
  userId,
  companyId,
  loginRequest,
  advisorApproval,
  turnkey,
  salesforceSettings,
  updateSalesforceSettings,
  socialNetworkData,
  advPartnerEmail,
  isPremiumAdvisor,
  isAdvisorEnterprise,
  bccList,
  setBccList,
  userData
}) => {
  const [isRequired, setIsRequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [advSuccessEmail, setAdvSuccessEmail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (advisorApproval) {
      setIsRequired(true);
    }
    if (advPartnerEmail) {
      setAdvSuccessEmail(advPartnerEmail);
    }
  }, [advisorApproval, advPartnerEmail]);

  const getSalesforcePayload = () => {
    const salesforcePayload = {};
    salesforceSettings.forEach((setting, index) => {
      const salesforceToggles = {};
      Object.keys(salesforceSettings[index])
        .slice(1)
        .forEach((toggle) => {
          salesforceToggles[salesforceTogglesMapper[toggle]] =
            salesforceSettings[index][toggle].toString();
        });
      console.log('SALESFORCE TOGGLE - - - ', salesforceToggles);
      salesforcePayload[salesforceSettingsMapper[setting.label]] =
        salesforceToggles;
    });
    console.log('SALESFORE PAYLOAD - - - -', salesforcePayload);
    return salesforcePayload;
  };

  const saveHandler = () => {
    setError('');
    let msg = 'email is not valid';
    const isEmailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let valid = true;

    if (turnkey === 'true') {
      if (!advSuccessEmail.toLowerCase() === '') {
        valid = isEmailRegEx.test(advSuccessEmail.toLowerCase());
      }
    }

    if (valid && !isEmpty(bccList)) {
      valid = bccList
        .split(',')
        .map((em) => em.trim())
        .every((em) => isEmailRegEx.test(em.toLowerCase()));

      if (bccList.split(',').length > 3 && valid) {
        valid = false;
        msg = 'Maximum 3 bcc emails can be added';
      }
    }
    if (!valid) {
      return setError(msg);
    }
    const sfSettingsPayload = getSalesforcePayload();
    let payload = {
      user: JSON.stringify([
        {
          uid: userId,
          salesforce_settings: sfSettingsPayload,
          ...((turnkey === 'true' || isAdvisorEnterprise)
            ? {
              advisor_approval: isRequired,
              as_partner_email: advSuccessEmail,
              bcc_emails: bccList && !isEmpty(bccList.split(',')) ?  bccList.split(',') : []
            }
            : {})
        },
      ]),
    };
    setIsLoading(true);
    updateAdvApproval(companyId, payload)
      .then((res) => {
        if (res.result.success) {
          setIsLoading(false);
          loginRequest({ noRefresh: true });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleAttrChange = (checked, attr, index) => {
    const settingsArrayCopy = [...salesforceSettings];
    settingsArrayCopy[index][attr] = checked;
    updateSalesforceSettings(settingsArrayCopy);
  };

  return (
    <GeneralSettingsWrapper>
      <GeneralSettingsContainer>
        {turnkey === 'true' && (
          <>
            <Flex
              alignItems="center"
              justifyContent="center"
              style={{ color: '#889095' }}
            >
              <div style={{ marginTop: '20px', marginRight: '10px' }}>
                Advisor Approval Settings
              </div>
              <Tooltip
                title={
                  <div style={{ fontSize: '0.6rem' }}>
                    Is advisor approval required? If set to “Required”, you will
                    have the option to require advisor approval for every piece
                    of content. If set to “Not Required”, all content will go
                    out without advisor approval.
                  </div>
                }
                placement="right"
                arrow
              >
                <Info>
                  <div>i</div>
                </Info>
              </Tooltip>
            </Flex>
            <Flex alignItems="center" justifyContent="center" m="20px">
              <RadioWrapper style={{ marginRight: '20px' }}>
                <MioRadio
                  id="required"
                  name="required"
                  checked={isRequired}
                  onClick={() => setIsRequired(true)}
                />
                <label
                  htmlFor="required"
                  style={{ color: isRequired ? 'black' : '#b0b6b9' }}
                >
                  Required
                </label>
              </RadioWrapper>
              <RadioWrapper>
                <MioRadio
                  id="not-required"
                  name="required"
                  checked={!isRequired}
                  onClick={() => setIsRequired(false)}
                />
                <label
                  htmlFor="not-required"
                  style={{ color: !isRequired ? 'black' : '#b0b6b9' }}
                >
                  Not Required
                </label>
              </RadioWrapper>
            </Flex>
            <label htmlFor="advSuccessEmail" style={{ fontSize: '12px' }}>
              Advisor Success Partner’s Email
            </label>
            <Flex width="45%" pt="0px" m="0px auto">
              <SetObjectivesInput
                maxLength="100"
                placeholder=""
                value={advSuccessEmail}
                onChange={(e) => setAdvSuccessEmail(e.target.value)}
                required
                name="advSuccessEmail"
                id="advSuccessEmail"
              />
            </Flex>
          </>
        )}
        {!isEmpty(get(socialNetworkData, 'sf', [])) && (
          <>
            <div style={{ marginBottom: '20px' }}>Salesforce Settings</div>
            <AdvisorSettings
              settingsArray={salesforceSettings}
              changeHandler={handleAttrChange}
            />
          </>
        )}
        {(turnkey === "true" || isAdvisorEnterprise) && (
          <BccField
            isAdvisorEnterprise={isAdvisorEnterprise}
            value={bccList}
            setValue={setBccList}
            userData={userData}
          />
        )}
        {error && <ErrorContainer align="center">{error}</ErrorContainer>}
        <div>
          <LoaderWrapper isLoading={isLoading}>
            <Button
              fontSize="10px"
              padding="7px 50px"
              borderRadius="5px"
              onClick={saveHandler}
              testId={'gen-settings-save'}
            >
              Save
            </Button>
          </LoaderWrapper>
        </div>
      </GeneralSettingsContainer>
    </GeneralSettingsWrapper>
  );
};
