import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedinIn, faFacebook} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@rebass/grid';
import { get } from 'lodash';

import {
  VerticalTabs,
  StackedBarChart,
  NumbersWithArrows,
  DateDropdown,
  DownloadButton,
} from '../../../components';
import {
  getActivityTabCount,
  getTabCount,
  getTabNameFromRepType,
  parseValueToCsv,
  saveFile,
} from '../../../utils';
import {
  AnalyticsVerticalTab,
  AnalyticsVerticalTabList,
  AnalyticsVerticalTabPanel,
  TabCount,
  TabLabel,
  TabContent,
  CommonVerticalTabPanel,
  DownloadButtonFlex,
} from '../AnalyticsContainer.styles';
import { useEffect } from 'react';

const filterDataByPlatform = (data, filterBy) => {
  return (data || []).map((item) => {
    const total = get(item, `total.${filterBy}`, 0);
    const value = get(item, `value.${filterBy}`, 0);
    return {
      id: item.id,
      name: item.name,
      total,
      value,
    };
  });
};

const ActivityTab = ({
  selectedDate,
  setSelectedDate,
  analyticsRequest,
  isAnalyticsDataFetching,
  analyticsObject,
  selectedRepType,
  tabContentType,
}) => {
  const [filterBy, setFilterBy] = useState('em');
  const onTabSelect = (tabIndex) => {
    const filterMapping = {
      0: 'em',
      1: 'ln',
      2: 'tw',
      3: 'fb',
    };
    setFilterBy(filterMapping[tabIndex]);
  };

  useEffect(() => {
    let repType = 'content_activity_by_platform';
    analyticsRequest({
      params: {
        repType,
      },
    });
    onTabSelect(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataByRepType = get(analyticsObject, `${selectedRepType}`);
  const totalData = get(dataByRepType, `data`);
  const slicedData = filterDataByPlatform(
    get(dataByRepType, `data`),
    filterBy
  );
  let csvData = filterDataByPlatform(get(dataByRepType, 'data', []), filterBy);
  csvData = csvData.filter(c => c.total > 0 || c.value > 0);
  let graphData = slicedData.filter((f) => f.total > 0 || f.value > 0);
  graphData = graphData.sort((a, b) => b.value - a.value).slice(0,5);
  const previousValue = get(dataByRepType, 'previousValue');
  const emailTabCount = getActivityTabCount(
    get(dataByRepType, `total.mine.em`, 0),
    get(previousValue, 'em.mine', 1),
    true
  );
  const linkedInTabCount = getActivityTabCount(
    get(dataByRepType, `total.mine.ln`, 0),
    get(previousValue, 'ln.mine', 1),
    true
  );
  const twitterTabCount = getActivityTabCount(
    get(dataByRepType, `total.mine.tw`, 0),
    get(previousValue, 'tw.mine', 1),
    true
  );

  const facebookTabCount = getActivityTabCount(
    get(dataByRepType, `total.mine.fb`, 0),
    get(previousValue, 'fb.mine', 1),
    true
  );

  const onDownloadCSVClick = () => {
    const tabName = getTabNameFromRepType(filterBy);
    const { startDate, endDate } = analyticsObject || {};
    const fileName = `${tabName}_Actiity-${startDate}_${endDate}`;
    
    const parsedData = parseValueToCsv(
      csvData,
      selectedRepType
    );
    if(csvData.length > 0) {
      saveFile(parsedData, fileName);
    }
  };

  return (
    <VerticalTabs screenWidth={window.innerWidth} onSelect={onTabSelect}>
      <AnalyticsVerticalTabList screenWidth={window.innerWidth}>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faEnvelope} />
            <div style={{padding: '2px'}}>
              <TabLabel fontSize='16px'>Email</TabLabel>
            </div>
            <div style={{padding: '2px'}}>
              <TabCount fontSize='28px'>{emailTabCount.tabCount}</TabCount>
            </div>
            <div style={{padding: '2px'}}>
              {emailTabCount.tabCount !== 0 ?
                <TabLabel fontSize='16px'>
                  <NumbersWithArrows value={emailTabCount.trend} suffix="%" />
                </TabLabel>
                :
                null
              }
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faLinkedinIn} />
            <div style={{padding: '2px'}}>
              <TabLabel fontSize='16px'>LinkedIn</TabLabel>
            </div>
            <div style={{padding: '2px'}}>
              <TabCount fontSize='28px'>{linkedInTabCount.tabCount}</TabCount>
            </div>
            <div style={{padding: '2px'}}> 
              {linkedInTabCount.tabCount !== 0 ?
                 <TabLabel fontSize='16px'>
                  <NumbersWithArrows value={linkedInTabCount.trend} suffix="%" />
                 </TabLabel>
                 :
                 null
              }
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faTwitter} />
            <div style={{padding: '2px'}}>
              <TabLabel fontSize='16px'>Twitter</TabLabel>
            </div>
            <div style={{padding: '2px'}}>
              <TabCount fontSize='28px'>{twitterTabCount.tabCount}</TabCount>
            </div>
            <div style={{padding: '2px'}}>
              {twitterTabCount.tabCount !== 0 ?
                <TabLabel fontSize='16px'>
                  <NumbersWithArrows value={twitterTabCount.trend} suffix="%" />
                </TabLabel>
                :
                null
              }
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
        <AnalyticsVerticalTab>
          <TabContent>
            <FontAwesomeIcon icon={faFacebook} />
            <div>
              <TabLabel>Facebook</TabLabel>
            </div>
            <div>
              <TabCount>{facebookTabCount.tabCount}</TabCount>
            </div>
            <div>
              {facebookTabCount.tabCount !== 0 ?
                <TabLabel>
                  <NumbersWithArrows value={facebookTabCount.trend} suffix="%" />
                </TabLabel>
                :
                null
              }
            </div>
          </TabContent>
        </AnalyticsVerticalTab>
      </AnalyticsVerticalTabList>

      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Box width={1}>
          <CommonVerticalTabPanel>
            {/* <DateDropdown
              top='-31px'
              value={selectedDate}
              onDateChange={setSelectedDate}
              type='analytics'
            /> */}
            <StackedBarChart
              tabContentType={tabContentType}
              yKey={'Emails sent'}
              loading={isAnalyticsDataFetching}
              data={graphData}
            />
          </CommonVerticalTabPanel>
          <DownloadButtonFlex>
            <DownloadButton disabled={csvData.length > 0 ? false : true} onClick={onDownloadCSVClick} fontSize='18px'/>
          </DownloadButtonFlex>
        </Box>
      </AnalyticsVerticalTabPanel>
      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Box width={1}>
          <CommonVerticalTabPanel>
            {/* <DateDropdown
              top='-31px'
              value={selectedDate}
              onDateChange={setSelectedDate}
              type='analytics'
            /> */}
            <StackedBarChart
              tabContentType={tabContentType}
              yKey={'Shares on Linkedin'}
              loading={isAnalyticsDataFetching}
              data={graphData}
            />
          </CommonVerticalTabPanel>
          <DownloadButtonFlex>
            <DownloadButton disabled={csvData.length > 0 ? false : true} onClick={onDownloadCSVClick} fontSize='18px'/>
          </DownloadButtonFlex>
        </Box>
      </AnalyticsVerticalTabPanel>
      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Box width={1}>
          <CommonVerticalTabPanel>
            {/* <DateDropdown
              top='-31px'
              value={selectedDate}
              onDateChange={setSelectedDate}
              type='analytics'
            /> */}
            <StackedBarChart
              tabContentType={tabContentType}
              yKey={'Shares on Twitter'}
              loading={isAnalyticsDataFetching}
              data={graphData}
            />
          </CommonVerticalTabPanel>
          <DownloadButtonFlex>
            <DownloadButton disabled={csvData.length > 0 ? false : true} onClick={onDownloadCSVClick} fontSize='18px'/>
          </DownloadButtonFlex>
        </Box>
      </AnalyticsVerticalTabPanel>
      <AnalyticsVerticalTabPanel paddingTop='0' screenWidth={window.innerWidth}>
        <Box width={1}>
          <CommonVerticalTabPanel>
            {/* <DateDropdown
              top='-31px'
              value={selectedDate}
              onDateChange={setSelectedDate}
              type='analytics'
            /> */}
            <StackedBarChart
              tabContentType={tabContentType}
              yKey={'Shares on Facebook'}
              loading={isAnalyticsDataFetching}
              data={graphData}
            />
          </CommonVerticalTabPanel>
          <DownloadButtonFlex>
            <DownloadButton disabled={csvData.length > 0 ? false : true} onClick={onDownloadCSVClick} fontSize='18px' />
          </DownloadButtonFlex>
        </Box>
      </AnalyticsVerticalTabPanel>
    </VerticalTabs>
  );
};

export default ActivityTab;
