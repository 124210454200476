import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { AccordionItemButton } from 'react-accessible-accordion';

export const StyledAccordianButton = styled(AccordionItemButton)`
  border: 1px solid #a0a0a0;
  color: #6351ed !important;
  background-color: #fff;
  margin: 5px 0;
  padding: 10px 0;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
`;

export const MembersListWrapper = styled(Flex)`
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
`;
