/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { MioDropdown } from '../';
import Header from './Header';
import {
  HeaderUl,
  HeaderLI,
  IconTitleContainer,
  ImpactTooltip,
  ImpactTitleContainer,
} from './Header.styles';
import { clearSeassionStorage, ROLE_TYPES } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { actions as contactActions } from '../../redux/modules/contacts.module';
import { actions as analyticsActions } from '../../redux/modules/analytics.module';
import { actions as campaignActions } from '../../redux/modules/campaign.module';
import { getMioScore } from '../../services/dashboard';
import { getRoleType } from '../../redux/selector';
import { get, isEmpty } from 'lodash';
import SwitchProfileModal from './SwitchProfileModal';
import { actions as loginActions } from '../../redux/modules/login.module';
import { useOktaAuth } from '@okta/okta-react';

const AdvisorHeader = ({
  pathName,
  userName,
  roleType,
  handleMenuToggle,
  type,
  setSelectedTabIndex,
  setAnalyticsTabIndex,
  userData,
  handleModalOpen,
  user,
  createNewsLetter,
  bucketCount,
  setOpenDeleteModal,
  campaignSetState,
  userDetails,
  loginRequest,
  isAdvMode,
  socialNetworkData,
  isTurnkey,
}) => {
  const history = useHistory();
  const [mioScore, setMioScore] = useState({
    score: 0,
    totalSharedArticles: -1,
  });
  const [showToolTip, toggleToolTip] = useState(false);
  const [borderColor, setBorderColor] = useState('#6351ed');
  const [showSwitchProfile, setShowSwitchProfile] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();

  // const shareScoreStyle = {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   width: '35px',
  //   marginBottom: mioScore.totalSharedArticles < 3 ? '0px' : '1.7px'
  // }

  const goToProfilePage = (index) => {
    window.sessionStorage.removeItem('selectedIndex');
    // if (index === 1) {
    //   setSelectedTabIndex({ selectedTabIndex: 1 });
    // } else if (index === 2) {
    //   setSelectedTabIndex({ selectedTabIndex: 2 });
    // } else if (index === 3) {
    //   setSelectedTabIndex({ selectedTabIndex: 3 });
    // } else {
    //   setSelectedTabIndex({ selectedTabIndex: 0 });
    // }
    setSelectedTabIndex({ selectedTabIndex: index });

    history.push('/configure');
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    var prevDate = new Date();
    prevDate.setDate(prevDate.getDate() - 6);
    prevDate = prevDate.toISOString().split('T')[0];
    getMioScore(currentDate, prevDate)
      .then((response) => {
        // console.log('[MIO SCORE] - ', response);
        const scoreArray =
          response && response.result && response.result.data
            ? Object.keys(response.result.data.analytics.score)
            : [];
        // let score = response.result.data.analytics.score[scoreArray[scoreArray.length - 1]].mine;
        let score =
          scoreArray.length > 0
            ? response.result.data.analytics.score[
                scoreArray[scoreArray.length - 1]
              ].mine
            : 0;
        let totalSharedArticles =
          response && response.result && response.result.data
            ? response.result.data.analytics.total_shares
            : 0;
        let borCol =
          score <= 30
            ? '#6351ed #c5c5c9 #c5c5c9 #c5c5c9'
            : score <= 60
            ? '#6351ed #6351ed #c5c5c9 #c5c5c9'
            : score <= 85
            ? '#6351ed #6351ed #6351ed #c5c5c9'
            : '#6351ed';
        setMioScore({ score: score, totalSharedArticles: totalSharedArticles });
        setBorderColor(borCol);
        // setMioScore({score: 0, totalSharedArticles: 3});
      })
      .catch((err) => console.log(err));
  }, []);

  const displayScore = () => {
    let scoreStatus = [];
    // let borCol = mioScore.score <= 30 ? '#6351ed #c5c5c9 #c5c5c9 #c5c5c9' : (
    //   mioScore.score <= 60 ? '#6351ed #6351ed #c5c5c9 #c5c5c9' : (
    //     mioScore.score <= 85 ? '#6351ed #6351ed #6351ed #c5c5c9' : '#6351ed'
    //   )
    // );
    // let style = {
    //   fontSize: '9px',
    //   border: '3px solid',
    //   borderColor: borCol,
    //   borderRadius: '13px',
    //   padding: mioScore.score < 12 ? '4px 7px 2px' : (mioScore.score < 100 ? '4px 4px 2px' : '3px 2px 2px')
    // };
    if (mioScore.totalSharedArticles < 3) {
      for (let i = 0; i < 3; i++) {
        scoreStatus.push(
          <i key={i} borderBottom="1px">
            <img
              src={
                i < mioScore.totalSharedArticles
                  ? '/assets/images/Forward_1-blue.svg'
                  : '/assets/images/Forward_1-grey.svg'
              }
              alt="portfolio"
            />
          </i>
        );
      }
    } else {
      // scoreStatus.push(
      //   <div style={style}>{mioScore.score}</div>
      // )
      scoreStatus.push(
        <div key="mio_score" style={{ margin: '4px auto auto' }}>
          {mioScore.score}
        </div>
      );
    }
    return scoreStatus;
  };

  const goToAnalyticsPage = (index) => {
    setAnalyticsTabIndex({ selectedTabIndex: index });
    history.push('/analytics');
  };

  return (
    <Header 
      customLogoUrl={get(userDetails, 'company.extra_settings.logo', '')} 
      type={type} 
      logoUrl="/dashboard"
    >
      <nav>
        {window.innerWidth < 768 ? (
          <HeaderUl>
            <HeaderLI type="sidebar">
              <FontAwesomeIcon
                size="2x"
                icon={faBars}
                onClick={handleMenuToggle}
              />
            </HeaderLI>
          </HeaderUl>
        ) : (
          <HeaderUl>
            <HeaderLI selected={'/dashboard' === pathName}>
              <Link to="/dashboard">
                <IconTitleContainer>
                  <i className="icon dripicons-home" />
                  <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                    HOME
                  </span>
                </IconTitleContainer>
              </Link>
            </HeaderLI>
            <MioDropdown
              background="#e4e4e4"
              hoverBackground="#6351ed"
              openOnHover
              width='11em'
              customStyle={{ right: '-33px' }}
              dropdownMenu={
                <ul>
                  <li
                    style={{
                      padding: '0px',
                      pointerEvents: 'auto',
                    }}
                  >
                    <Link
                      style={{
                        padding: '4px 5px',
                      }}
                      to="/campaignV2?type=create"
                      onClick={() => {
                        campaignSetState({
                          campaignId: '',
                          createFlow: true,
                          createMode: false,
                          curFlow: '',
                          selectedIndex: 0,
                          setObjectives: {},
                          setTopics: {},
                          contentWeeks: [],
                          planCampaign: {},
                          campaignArticles: [],
                          scheduleTimings: {},
                          selectedWeek: '',
                          selectedDays: '',
                          selectedArticles: [],
                          publishedContent: {},
                          defaultTemplates: [],
                          setTemplate: '',
                          savedTemplate: null,
                          scheduleTypes: {},
                          isEdit: false,
                          isFromAddCampaign: false,
                          summaries: null,
                          seriesId: '',
                          leadGenReferralData: null,
                          fromDashboard: false,
                          reviewCampaign:{}
                        });
                      }}
                    >
                      Create
                    </Link>
                  </li>
                  <li style={{ padding: '0px' }}>
                    <Link style={{ padding: '4px 5px' }} to="/campaignV2">
                      Dashboard
                    </Link>
                  </li>
                  <li style={{ padding: '0px' }}>
                    <Link
                      style={{ padding: '4px 5px' }}
                      to="/campaign/template"
                    >
                      Email Templates
                    </Link>
                  </li>
                  <li style={{ padding: '0px' }}>
                    <Link
                      style={{ padding: '4px 5px' }}
                      to="/campaign/custom-row"
                    >
                      Manage Rows
                    </Link>
                  </li>
                  <li style={{ padding: '0px' }}>
                    <Link
                      style={{ padding: '4px 5px' }}
                      to="/campaign/landing-pages"
                    >
                      Manage Page Templates
                    </Link>
                  </li>
                </ul>
              }
            >
              <HeaderLI selected={'/campaignV2' === pathName}>
                <Link to="/campaignV2">
                  <IconTitleContainer>
                    <i>
                      <img src="/assets/images/Megaphone.svg" alt="portfolio" />
                    </i>
                    <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                      CAMPAIGN
                    </span>
                  </IconTitleContainer>
                </Link>
              </HeaderLI>
            </MioDropdown>
            {user &&
            user.data &&
            user.data.details &&
            user.data.details.user.extra_settings &&
            user.data.details.user.extra_settings.compliance_module ===
              'true' ? (
              // {roleType!='ADVISOR_PREMIUM' ? (
              <HeaderLI selected={'/advisor/compliance' === pathName}>
                <Link to="/advisor/compliance">
                  <IconTitleContainer>
                    <i>
                      <img
                        src="/assets/images/tickicon.svg"
                        alt="portfolio"
                        style={{ marginLeft: '15px' }}
                      />
                    </i>
                    <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                      COMPLIANCE
                    </span>
                  </IconTitleContainer>
                </Link>
              </HeaderLI>
            ) : null}

            <MioDropdown
              background="#e4e4e4"
              hoverBackground="#6351ed"
              openOnHover
              dropdownMenu={
                <ul>
                  <li
                    style={{
                      padding: '0px',
                      pointerEvents: createNewsLetter ? 'auto' : 'none',
                      color: createNewsLetter ? '' : '#adadad',
                    }}
                  >
                    <Link
                      style={{
                        padding: '4px 5px',
                        color: createNewsLetter ? '' : '#adadad',
                      }}
                      to="/newsletter?type=create"
                    >
                      Create
                    </Link>
                  </li>
                  <li style={{ padding: '0px' }}>
                    <Link
                      style={{ padding: '4px 5px' }}
                      to="/newsletter?type=dashboard"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li
                    style={{
                      cursor: bucketCount > 0 ? 'pointer' : 'not-allowed',
                      backgroundColor: bucketCount > 0 ? '' : '#adadad',
                      padding: '0px',
                    }}
                  >
                    <a
                      style={{
                        color: bucketCount > 0 ? '' : 'white',
                        padding: '4px 5px',
                      }}
                      onClick={() =>
                        bucketCount > 0
                          ? setOpenDeleteModal(true)
                          : setOpenDeleteModal(false)
                      }
                    >
                      Clear Articles
                    </a>
                  </li>
                </ul>
              }
            >
              {/* <HeaderLI
                selected={'/newsletter' === pathName}
                style={{ position: 'relative' }}
              >
                <Link to="/newsletter">
                  <IconTitleContainer>
                    <i>
                      <img
                        src="/assets/images/Newsletter-header.svg"
                        alt="newsletter"
                        style={{ marginLeft: '15px' }}
                      />
                    </i>
                    <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                      NEWSLETTER
                    </span>
                    <span
                      style={{
                        position: 'absolute',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        right:
                          bucketCount <= 9
                            ? '10px'
                            : bucketCount >= 10 && bucketCount <= 99
                            ? '6px'
                            : '-1px',
                        top: '6px',
                      }}
                    >
                      {bucketCount > 99 ? '99+' : bucketCount}
                    </span>
                  </IconTitleContainer>
                </Link>
              </HeaderLI> */}
            </MioDropdown>

            <HeaderLI>
              <MioDropdown
                background="#e4e4e4"
                openOnHover={!showToolTip}
                hoverBackground="#6351ed"
                customStyle={{
                  right: '-45px',
                  top: mioScore.totalSharedArticles < 3 ? '98%' : '95%',
                }}
                paddingStyle={{ paddingBottom: '5px' }}
                dropdownMenu={
                  <ul>
                    <li>
                      <Link to="/analytics/summary">Overview</Link>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => goToAnalyticsPage(0)}
                      >
                        Activity
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => goToAnalyticsPage(1)}
                      >
                        Impact
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => goToAnalyticsPage(2)}
                      >
                        Engagement
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => goToAnalyticsPage(3)}
                      >
                        Leads
                      </a>
                    </li>
                  </ul>
                }
              >
                <Link to="/analytics">
                  <ImpactTitleContainer
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      top: mioScore.totalSharedArticles < 3 ? '1px' : '0px',
                    }}
                  >
                    {/* <div style={shareScoreStyle} onMouseEnter={() => mioScore.totalSharedArticles < 3 ? toggleToolTip(true) : null} onMouseLeave={() => toggleToolTip(false)}>
                      {displayScore()}
                    </div> */}
                    {mioScore.totalSharedArticles !== -1 ? (
                      mioScore.totalSharedArticles < 3 ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '35px',
                          }}
                          onMouseEnter={() => toggleToolTip(true)}
                          onMouseLeave={() => toggleToolTip(false)}
                        >
                          {displayScore()}
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: '9px',
                            height: '25px',
                            width: '25px',
                            textAlign: 'center',
                            border: '3px solid',
                            borderColor: borderColor,
                            borderRadius: '13px',
                            marginBottom: '2px',
                          }}
                        >
                          {displayScore()}
                        </div>
                      )
                    ) : (
                      <div style={{ height: '25px', width: '25px' }}></div>
                    )}
                    <ImpactTooltip
                      style={{ boxShadow: 'none', fontStyle: 'normal' }}
                      className="Impact-tooltip-text"
                      hidden={!showToolTip}
                      isFromAdmin={false}
                    >
                      Share 3 times to start seeing your Clout score here
                    </ImpactTooltip>
                    <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                      MY CLOUT
                    </span>
                  </ImpactTitleContainer>
                </Link>
              </MioDropdown>
            </HeaderLI>
            <MioDropdown
              testId="userhome-menu"
              openOnHover
              type="login"
              background="#e4e4e4"
              hoverBackground="#6351ed"
              dropdownMenu={
                <ul>
                  <li>
                    <a onClick={() => goToProfilePage(0)}>Profile</a>
                  </li>
                  {get(userDetails, 'details.user.is_impersonator', 'false') ===
                    'true' && (
                    <li>
                      <a onClick={() => setShowSwitchProfile(true)}>
                        Switch Profile
                      </a>
                    </li>
                  )}
                  <li>
                    <Link to="/theme">Topics</Link>
                  </li>
                  <li>
                    <Link to="/channel">Publishers</Link>
                  </li>
                  {ROLE_TYPES.ADVISOR_FREE === roleType && (
                    <li>
                      <Link to="/subscription">Subscribe</Link>
                    </li>
                  )}
                  <li>
                    <a onClick={() => goToProfilePage(1)}>Platforms</a>
                  </li>
                  <li>
                    <a
                      data-testid="home-contacts"
                      onClick={() => goToProfilePage(2)}
                    >
                      Contacts
                    </a>
                  </li>
                  <li>
                      <a onClick={() => goToProfilePage(3)}>General Settings</a>
                    </li>
                  <li>
                    <a href="#" onClick={() => {
                       oktaAuth.signOut('/');
                       oktaAuth.closeSession();
                       localStorage.clear();
                       sessionStorage.clear();
                       window.location.href = '/';
                    }}>
                      Logout
                    </a>
                  </li>
                </ul>
              }
            >
              <HeaderLI selected={'/configure' === pathName}>
                <a href="javascript:void(0);">
                  <i className="icon icon dripicons-user" />
                  <span style={{ fontSize: '14px', marginBottom: '1px' }}>
                    {get(userDetails, 'details.user.is_impersonator', '') ===
                    'true'
                      ? get(
                          userDetails,
                          'details.user.impersonate.first_name',
                          ''
                        )
                      : userName}
                  </span>
                </a>
              </HeaderLI>
            </MioDropdown>
          </HeaderUl>
        )}
      </nav>
      {showSwitchProfile ? (
        <SwitchProfileModal
          handleModalClose={() => {
            setShowSwitchProfile(false);
          }}
          userData={userDetails}
          loginRequest={loginRequest}
          roleType={roleType}
        />
      ) : null}
    </Header>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isPremiumAdvisor = roleType === ROLE_TYPES.ADVISOR_PREMIUM;
  const isAdminEnterprise = roleType === ROLE_TYPES.ADMIN_ENTERPRISE;
  const isTurnkey =
    state.user.data &&
    state.user.data.details &&
    state.user.data.details.user.turnkey &&
    state.user.data.details.user.turnkey === 'true';
  let advMode = false;
  if ((isPremiumAdvisor || isAdminEnterprise) && isTurnkey) advMode = true;
  return {
    user: state.user,
    createNewsLetter: !isPremiumAdvisor
      ? state.user.data &&
        state.user.data.details &&
        state.user.data.details.user.extra_settings.share_content === 'true'
      : true,
    userDetails: state.user.data,
    isAdvMode: advMode,
    socialNetworkData: state.socialNetwork.data,
    isTurnkey: isTurnkey,
  };
};

const mapDispatchToProps = {
  setSelectedTabIndex: contactActions.api.contacts.selectedtabindex,
  setAnalyticsTabIndex: analyticsActions.api.analytics.selectedtabindex,
  campaignSetState: campaignActions.api.campaign.setstate,
  loginRequest: loginActions.api.login.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorHeader);
