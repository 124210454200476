/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { each, get, isEmpty } from 'lodash';
import { Flex, Box } from '@rebass/grid';
import {
  Tabs,
  TabList,
  TabTypeThree,
  LoaderWrapper,
  Loader,
} from '../../components';
import ExploreTab from './ExploreTab';
import TrendingTab from './TrendingTab';
import Personalized from './Personalized';
import Discover from './Discover';
import FeaturedPosts from './FeaturedPosts';
import MyClientsTab from '../DashboardContainer/MyClientsTab/MyClientsTab';
import RecentSharedTab from './RecentSharedTab';
import AddContent from './AddContent';
import ScheduledTab from './ScheduledTab';
import SearchResultsTab from './SearchResultsTab';
import InvestmentsTab from './InvestmentsTab';
import { useHistory } from 'react-router-dom';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getPopularTheme,
  getPremiumAdvisorChannelId,
  getSponsorList,
  geYourTheme,
} from '../../services/dashboard';
import { getContactsGroups } from '../../services/configure';
import {
  StyledTooltip,
  HoldingsIcon,
  HoldingsWrapper,
  StyledTabPanelTwo,
  BorderWhiteBox,
} from './DashboardContainer.styles';

import { actions as inviteActions } from '../../redux/modules/invite.module';
import {
  actions as exploreActions,
  moreActions as exploreMoreActions,
} from '../../redux/modules/explore-tab.module';
import { actions as advisorGroupsActions } from '../../redux/modules/advisor-group.module';
import {
  actions as contentlibActions,
  moreActions as contentlibMoreActions,
} from '../../redux/modules/content-lib.module';
import { actions as themeActions } from '../../redux/modules/theme.module';
import { actions as shareActions } from '../../redux/modules/share.module';
import { actions as channelActions } from '../../redux/modules/channel.module';
import { actions as loginActions } from '../../redux/modules/login.module';
import { moreActions as newsletterMoreActions } from '../../redux/modules/newsletter.module';
import {
  company,
  emptyMessages,
  getApprovedThemeList,
  getSpecificThemeItem,
  ROLE_TYPES,
  sponsoredArticleData,
} from '../../utils';

import { getChannelId, getFirmSettings, getUserRole } from '../../redux/selector';

import {
  StyledTabPanel,
  NoContentWrapper,
  DashboardAdminSubTab,
  AbsoluteLoader,
  ExploreRelativeDiv,
} from './DashboardContainer.styles';
import SponsoredModal from './SponsoredModal/SponsoredModal';
import useQuery from '../../customHooks/useQuery';
import ContentLibraryTab from './ContentLibraryTab';
import MioEditorTab from './MIOEditor/MioEditorTab';
import MioEditor from './MIOEditor/MioEditor';
import BookmarksTab from './BookmarksTab/BookmarksTab';
import { StyledTab } from '../../components/Tabs/Tabs.styles';
import RecommendedTab from './RecommendedTab';
import _ from 'lodash';
import { ExportPDFButton } from '../ExportPDFContainer/ExportPDF.styles';
import ExportPDF from '../ExportPDFContainer/ExportPDF';

const EnterpriseAdvisorDashboardContainer = ({
  exploreArticleRequest,
  exploreArticles,
  deleteArticleRequest,
  themesList,
  channelData,
  exploreArticlesFetching,
  searchResults,
  investmentResults,
  themeNames,
  exploreArticleDeleting,
  myMioData,
  selectedTickers,
  exploreSetState,
  roleType,
  searchFilters,
  tickerName,
  themeSetState,
  yourTopics,
  isAdmin,
  isInvEmpty,
  currentPortfolio,
  isComplianceAdmin,
  holdingsType,
  contentlibSetState,
  contentlibRequest,
  contentlibDeleteRequest,
  contentLibArticles,
  contentLibArticlesFetching,
  contentLibArticleDeleting,
  exploreArticleError,
  shareArticleSetState,
  channelId,
  isShareBlocked,
  isDeleteBlocked,
  userData,
  channelSetState,
  isChangeTheme,
  loginRequest,
  handleModalOpen,
  addToBucketRequest,
  removeFromNewsletterRequest,
  addToBookmarksRequest,
  removeFromBookmarksRequest,
  newsletterArticleFetching,
  bookmarksFetching,
  contentLibAddToBucketRequest,
  contentLibRemoveFromBucketRequest,
  contentLibNewsletterFetching,
  exploreNewsletterId,
  bookmarksId,
  contentLibAddToBookmarksRequest,
  contentLibRemoveFromBookmarksRequest,
  contentLibBookmarksFetching,
  contentLibBookmarksId,
  contentLibATSQueue,
  bucketCountRequest,
  contentLibBucketId,
  contentLibInNewsletter,
  contentLibBookMarkId,
  contentLibIsBookmark,
  shareContent,
  firmSettings,
  contentLibFilter
}) => {
  const [showInvestmentTab, setShowInvestmentTab] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [localContent, setLocalContent] = useState([]);
  const [moreSearchParams, setMoreSearchParams] = useState({});
  const [selectedTabIndex, setTabIndex] = useState(0);
  const [filteredSearchEmpty, setFilteredSearchEmpty] = useState(false);
  const [trendingTopicDetails, setTrendingTopicDetails] = useState({});
  const [popularTopicDetails, setPopularTopicDetails] = useState({});
  const [subscribedTopicDetails, setSubscribedTopicDetails] = useState({});
  const [investmentTopic, setInvestmentTopic] = useState('');
  const [selectedExploreTabIndex, setSelectedExploreTabIndex] = useState(3);
  const [selectedRecentTabIndex, setSelectedRecentTabIndex] = useState(0);
  const [selectedScheduledTabIndex, setSelectedScheduledTabIndex] = useState(0);
  const [isSearchFilters, setIsSearchFilters] = useState(true);
  const [mostSubscribed, setMostSubscribed] = useState([]);
  const [widgetTabIndes, setWdigetTabIndex] = useState(1);
  const [discoverTabIndex, setDiscoverTabIndex] = useState(0);
  const [isFilteringStocksByTicker, setIsFilteringStocksByTicker] =
    useState(false);
  const [curatedTopicType, setCuratedTopicType] = useState('all');
  const [clientShareBlocked, setClientShareBlocked] = useState(false);
  const [searchOptions, setSearchOptions] = useState([
    { platform: 'all', checked: true, isDisconnected: false },
    { platform: 'my_content', checked: true, isDisconnected: false },
    { platform: 'editor_content', checked: true, isDisconnected: false },
  ]);
  const [approvedThemeList, setApprovedThemeList] = useState([]);
  const [mostSubscribedThemes, setMostSubscribedThemes] = useState([]);
  const [openSponsoredModal, setOpenSponsoredModal] = useState(false);
  const [sponsoredArticles, setSponsoredArticles] = useState([]);
  const [popularThemes, setPopularThemes] = useState([]);
  const [showClientDropDown, setShowClientDropDown] = useState(true);
  const [selectedMyClientsDropdownOption, setSelectedMyClientsDropdownOption] =
    useState('');
  const [selectedCRMContactList, setSelectedCRMContactList] = useState({
    id: '',
    name: '',
    themes: [],
    recommendedThemes: [],
    userThemes: [],
    disabledThemes: [],
  });
  const [crmLoader, setCrmLoader] = useState(false);
  const [myClientsLocalArticles, setMyClientsLocalArticles] = useState([]);
  const [premiumAdvisorChannelId, setPremiumAdvisorChannelId] = useState('');
  const [contactList, setContactList] = useState([]);
  const [thirdPartyThemeId, setThirdPartyThemeId] = useState('');
  const [clearEditorArticles, setClearEditorArticles] = useState(false);
  const [mioContent, setMioContent] = useState([]);
  const [clientDetails, setClientDetails] = useState({
    id: 'SECURITY',
    contactName: 'positivly_security',
  });
  const [sponsoredThemeList, setSponsoredThemeList] = useState([]);
  const history = useHistory();
  const query = useQuery();
  const [exploreTabDetails, setExploreTabDetails] = useState({});
  const [isExportPdfOpen, setIsExportPdfOpen] = useState({
    isSharedArticle: false,
    isRecentArticle: false,
  });

  const exportPdfHandler = (name, isOpen) => {
    setIsExportPdfOpen({ ...isExportPdfOpen, [name]: isOpen });
  };

  const themeFromInvestment = investmentTopic
    ? getSpecificThemeItem(themesList, investmentTopic)
    : [];

  const onDiscoverTabChange = (index) => {
    // setTabIndex(0);
    //setPopularTopicDetails({});
    if (index === 0) {
      setTabIndex(0);
      // setPopularTopicDetails({});
      setSelectedExploreTabIndex(1);
      // onExploreTabSelect(1);
    } else if (index === 1) {
    } else if (index === 2 && discoverTabIndex !== index) {
      setTabIndex(0);
      setPopularTopicDetails({});
      setSelectedExploreTabIndex(1);
      exploreArticleRequest({
        params: {
          id: popularTopicDetails.id,
          source: 'explore',
        },
      });
    } else if (
      index === 2 &&
      discoverTabIndex === index &&
      !isEmpty(mostSubscribedThemes)
    ) {
      setTabIndex(0);
      setSelectedExploreTabIndex(1);
      const initialIndex = Math.floor(mostSubscribedThemes.length / 2);
      const { id, description } = get(
        mostSubscribedThemes,
        `[${initialIndex}]`,
        {}
      );
      setPopularTopicDetails({
        id,
        topicName: description,
      });
      exploreArticleRequest({
        params: {
          id: id,
          source: 'explore',
        },
      });
    }
    setDiscoverTabIndex(index);
    // setWdigetTabIndex(index);
  };

  const onWidgetTabChange = (index) => {
    if (index !== 3 && !showClientDropDown) {
      setShowClientDropDown(true);
    }
    setTabIndex(0);
    if (index === 1 && discoverTabIndex !== 2) {
      onExploreTabClick(1);
    } else if (
      index === 1 &&
      discoverTabIndex === 2 &&
      !isEmpty(popularThemes)
    ) {
      setSelectedExploreTabIndex(3);
      const initialIndex = Math.floor(popularThemes.length / 2);
      const { id, description } = get(popularThemes, `[${initialIndex}]`, {});
      setPopularTopicDetails({
        id,
        topicName: description,
      });
      exploreArticleRequest({
        params: {
          id: id,
          source: 'explore',
        },
      });
    } else if (index === 0) {
      setShowArticleLoader(true);
      setCuratedTopicType('all');
      // exploreArticleRequest({
      //   params: {
      //     id: popularTopicDetails.id,
      //     source: 'approveToShare',
      //     date: 'all',
      //   },
      // });
      setSelectedExploreTabIndex(2);
    } else if (index === 3) {
      if (
        userData.details &&
        userData.details.user.turnkey &&
        userData.details.user.turnkey === 'true'
      ) {
        setThirdPartyThemeId('');
        exploreSetState({data: {}});
        exploreArticleRequest({
          params: {
            id: popularTopicDetails.id,
            source: 'mioEditorResult',
            date: '',
            mode: '',
            token: '',
            holdingsType: '',
            thirdPartyThemeId: '',
          },
        });
        sessionStorage.removeItem('tab');
      } else {
        if (widgetTabIndes !== 3) {
          setShowArticleLoader(false);
          setSelectedMyClientsDropdownOption('');
          setShowClientDropDown(true);
        } else {
          if (selectedMyClientsDropdownOption === 'financial') {
            // setShowArticleLoader(false);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'myClients',
                id: clientDetails.id,
              },
            });
          } else if (selectedMyClientsDropdownOption === 'crm') {
            // setShowArticleLoader(false);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'crmClients',
                themesList:
                  selectedCRMContactList.themes.length > 0
                    ? selectedCRMContactList.themes.map(
                        (theme) => theme.id_hash
                      )
                    : [],
              },
            });
          } else {
            setShowArticleLoader(false);
          }
        }
        // setShowArticleLoader(true);
        // exploreArticleRequest({
        //   params: {
        //     id: 'SECURITY',
        //     source: 'myClients',
        //   },
        // });
      }
      setSelectedExploreTabIndex(5);
    } else if (index === 4) {
      if (
        userData.details &&
        userData.details.user.turnkey &&
        userData.details.user.turnkey === 'true'
      ) {
        if (widgetTabIndes !== 4) {
          setShowArticleLoader(false);
          setSelectedMyClientsDropdownOption('');
          setShowClientDropDown(true);
        } else {
          if (selectedMyClientsDropdownOption === 'financial') {
            // setShowArticleLoader(false);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'myClients',
                id: clientDetails.id,
              },
            });
          } else if (selectedMyClientsDropdownOption === 'crm') {
            // setShowArticleLoader(false);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'crmClients',
                themesList:
                  selectedCRMContactList.themes.length > 0
                    ? selectedCRMContactList.themes.map(
                        (theme) => theme.id_hash
                      )
                    : [],
              },
            });
          } else {
            setShowArticleLoader(false);
          }
        }
        // setShowArticleLoader(true);
        // exploreArticleRequest({
        //   params: {
        //     id: 'SECURITY',
        //     source: 'myClients',
        //   },
        // });
      } else {
        setSearchOptions([
          { platform: 'all', checked: true, isDisconnected: false },
          { platform: 'my_content', checked: true, isDisconnected: false },
          { platform: 'editor_content', checked: true, isDisconnected: false },
        ]);
        setSearchValue('');
        exploreArticleRequest({
          params: {
            source: 'search',
          },
        });
      }
      setSelectedExploreTabIndex(6);
    } else if (index === 5) {
      setSearchOptions([
        { platform: 'all', checked: true, isDisconnected: false },
        { platform: 'my_content', checked: true, isDisconnected: false },
        { platform: 'editor_content', checked: true, isDisconnected: false },
      ]);
      setSearchValue('');
      exploreArticleRequest({
        params: {
          source: 'search',
        },
      });
      setSelectedExploreTabIndex(7);
    } else {
      setInvestmentTopic('');
      setSelectedExploreTabIndex(4);
    }
    setWdigetTabIndex(index);
  };

  const checkedStatus = (platform) => {
    const platformDetails = searchOptions.find((c) => c.platform === platform);
    return {
      isDiconnected: platformDetails ? platformDetails.isDisconnected : false,
      checked: platformDetails ? platformDetails.checked : false,
    };
  };

  const onCheckBoxClick = (platform) => {
    let searchOptionsCopy = [...searchOptions];
    if (platform === 'all') {
      if (!searchOptionsCopy[0].checked)
        searchOptionsCopy.forEach((option) => (option.checked = true));
      else searchOptionsCopy.forEach((option) => (option.checked = false));
    } else {
      searchOptionsCopy.every((option) => {
        if (option.platform === platform) {
          if (option.checked) searchOptionsCopy[0].checked = false;
          option.checked = !option.checked;
        }
        return option.platform !== platform;
      });
    }
    // searchOptions.forEach((item, index) => {
    //   if (item.platform === platform) {
    //     item.checked = !item.checked;
    //   }
    // });
    // const checkAllOption = searchOptions.find(s => s.platform === "all").checked;
    // if (checkAllOption) {
    //   searchOptions.forEach((item, index) => {
    //     if (item.platform !== "all") {
    //       item.checked = false;
    //       item.isDisconnected = true;
    //     }
    //   });
    // } else {
    //   searchOptions.forEach((item, index) => {
    //     item.isDisconnected = false;
    //   });
    // }
    setSearchOptions([...searchOptions]);
    checkedStatus(platform);
  };
  const [showArticleLoader, setShowArticleLoader] = useState(true);

  useEffect(() => {
    if (!isEmpty(exploreArticleError)) {
      setShowArticleLoader(false);
      exploreSetState({ isFetching: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreArticleError]);

  useEffect(() => {
    const sponsoredList = themesList?.filter((l) => l.sponsored)?.slice(0, 5);
    setSponsoredThemeList(sponsoredList);
  }, [themesList]);

  useEffect(() => {
    console.log(query.get('tab'));
    const excludeEmailList = [
      'GMAIL',
      'HUBSPOT',
      'REDTAIL',
      'OUTLOOK',
      'WEALTHBOX',
      'SALESFORCE',
    ];
    if (query.get('tab') && query.get('tab') === 'holdings') {
      onWidgetTabChange(2);
    }
    exploreSetState({ holdingsType: 'themes' });
    if (!isEmpty(themesList)) {
      const retrieved = getApprovedThemeList(themesList);
      setApprovedThemeList(retrieved);
    }
    getPopularTheme().then((response) => {
      setMostSubscribedThemes(get(response, 'result.data', []));
      let themeResponse = get(response, 'result.data', []);
      themeResponse =
        themeResponse &&
        themeResponse.length > 0 &&
        themeResponse.map(({ name }) => name);
      setMostSubscribed(themeResponse);
    });
    getSponsorList().then((response) => {
      setSponsoredArticles(
        sponsoredArticleData(get(response, 'result.data', []))
      );
    });
    geYourTheme().then((response) => {
      const yourThemeResponse = get(response, 'result.data', []).filter(
        (theme) =>
          get(theme, 'subscription.status.application_status') === 'approved'
      );
      setPopularThemes(yourThemeResponse);
      themeSetState({ popularThemes: yourThemeResponse });
    });

    getContactsGroups()
      .then((res) => {
        const contact_list = res.result.data.filter(
          (contact) => !excludeEmailList.includes(contact.name)
        );
        setContactList(contact_list);
        let count = res.result.data.filter((contact) =>
          contact.name.includes('positivly_')
        );
        if (count.length === 0) {
          setClientShareBlocked(true);
        }
      })
      .catch((err) => console.log(err));

    getPremiumAdvisorChannelId().then(({ result }) => {
      let channelId = '';
      each(result.data, (value, key) => {
        channelId =
          result.data[key] && result.data[key].length > 0
            ? result.data[key][0].id
            : '';
      });
      setPremiumAdvisorChannelId(channelId);
      channelSetState({ premiumAdvisorChannelId: channelId });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (approvedThemeList || themeFromInvestment) &&
      (!yourTopics ||
        (yourTopics &&
          yourTopics.length !==
            [...approvedThemeList, ...themeFromInvestment].length))
    ) {
      const yourTopics = [...approvedThemeList, ...themeFromInvestment].map(
        ({ name }) => name
      );
      themeSetState({
        yourTopics: yourTopics.map((theme) => theme.toUpperCase()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeFromInvestment, approvedThemeList, yourTopics]);

  // useEffect(() => {
  //   if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
  //     if (!isEmpty(popularTopicDetails)) {
  //       onExploreTabClick();
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [popularTopicDetails]);

  useEffect(() => {
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 1) {
      setShowArticleLoader(true);
      if (!isEmpty(trendingTopicDetails)) {
        onTrendingTabClick();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendingTopicDetails]);

  useEffect(() => {
    if (popularThemes?.length === 0) {
      onExploreTabClick(1);
    }
  }, [popularThemes]);

  const onSearchClick = (moreParams) => {
    // setShowSearchTab(true);
    setTabIndex(0);
    if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true'
    )
      setSelectedExploreTabIndex(7);
    else setSelectedExploreTabIndex(6);
    setMoreSearchParams(moreParams);
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: searchValue,
        source: 'search',
        date: searchOptions,
        ...moreParams,
      },
    });
  };

  const onApproveToShare = () => {
    setShowArticleLoader(true);
    // setSelectedExploreTabIndex(0);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'approveToShare',
        date: curatedTopicType,
      },
    });

    onExploreTabSelect(selectedExploreTabIndex);
  };

  const onSubscribeTabClick = (topic) => {
    setShowArticleLoader(true);
    if (isComplianceAdmin) {
      setSelectedExploreTabIndex(1);
    }
    exploreArticleRequest({
      params: {
        id: topic && topic.length > 0 ? topic : subscribedTopicDetails.id,
        source: 'explore',
      },
    });
  };

  const onExploreTabClick = (topic) => {
    setShowArticleLoader(true);
    setSelectedExploreTabIndex(3);
    exploreArticleRequest({
      params: {
        id: topic?.id
          ? topic.id
          : topic && topic.length > 0
          ? topic
          : popularTopicDetails.id,
        source: 'explore',
      },
    });
  };

  const onTrendingTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: trendingTopicDetails.id,
        date: trendingTopicDetails.date,
        source: 'trending',
      },
    });
  };

  const onRecentTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'recent',
      },
    });
  };

  const onRecentLastmonthTabClick = () => {
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'recent',
        type: 'last_month',
      },
    });
  };

  const onScheduledTabClick = () => {
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(2);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'recommendation_engine',
      },
    });
  };

  const onScheduledByCompanyTabClick = () => {
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(1);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'admin',
      },
    });
  };

  const onScheduledBySelfTabClick = () => {
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(0);
    exploreArticleRequest({
      params: {
        id: popularTopicDetails.id,
        source: 'scheduled',
        type: 'user',
      },
    });
  };

  const onInvestmentClick = (id, holdingsType = '') => {
    // if (showSearchTab) {
    //   setTabIndex(4);
    // } else {
    //   setTabIndex(3);
    // }
    setIsFilteringStocksByTicker(false);
    setTabIndex(0);
    setSelectedExploreTabIndex(3);
    setShowArticleLoader(true);
    exploreArticleRequest({
      params: {
        id,
        source: 'investments',
        holdingsType,
      },
    });
    setShowInvestmentTab(true);
    onWidgetTabChange(2);
  };

  const onExploreTabSelect = (index, topic) => {
    setShowArticleLoader(true);
    console.log(index);
    if (index === 2) {
      setWdigetTabIndex(0);
      // exploreArticleRequest({
      //   params: {
      //     id: popularTopicDetails.id,
      //     source: 'approveToShare',
      //     date: curatedTopicType,
      //   },
      // });
      // onApproveToShare();
    } 
    else if (index === 0) {
      exploreArticleRequest({
        params: {
          source: 'recommended',
        },
      });
    } else if (index === 3) {
      onWidgetTabChange(1);
      onExploreTabClick(topic);
    } else if (index === 1) {
      onTrendingTabClick();
      onDiscoverTabChange(0);
    } else if (index === 4) {
      onInvestmentClick();
      setWdigetTabIndex(2);
    } else if (index === 5) {
      if (
        userData.details &&
        userData.details.user.turnkey &&
        userData.details.user.turnkey === 'true'
      ) {
        setThirdPartyThemeId('');
        setWdigetTabIndex(3);
        exploreSetState({data: {}});
        exploreArticleRequest({
          params: {
            id: popularTopicDetails.id,
            source: 'mioEditorResult',
            date: '',
            mode: '',
            token: '',
            holdingsType: '',
            thirdPartyThemeId: '',
          },
        });
        sessionStorage.removeItem('tab');
      } else {
        if (
          selectedExploreTabIndex !== 0 &&
          selectedExploreTabIndex !== 1 &&
          selectedExploreTabIndex !== 8
        ) {
          setShowArticleLoader(false);
          setSelectedMyClientsDropdownOption('');
          setShowClientDropDown(true);
        } else {
          if (selectedMyClientsDropdownOption === 'financial') {
            // setShowArticleLoader(false);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'myClients',
                id: clientDetails.id,
              },
            });
          } else if (selectedMyClientsDropdownOption === 'crm') {
            // setShowArticleLoader(false);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'crmClients',
                themesList:
                  selectedCRMContactList.themes.length > 0
                    ? selectedCRMContactList.themes.map(
                        (theme) => theme.id_hash
                      )
                    : [],
              },
            });
          } else {
            setShowArticleLoader(false);
          }
        }
        // exploreArticleRequest({
        //   params: {
        //     id: 'SECURITY',
        //     source: 'myClients',
        //   },
        // });
      }
      onWidgetTabChange(3);
    } else if (index === 6) {
      if (
        userData.details &&
        userData.details.user.turnkey &&
        userData.details.user.turnkey === 'true'
      ) {
        if (
          selectedExploreTabIndex !== 0 &&
          selectedExploreTabIndex !== 1 &&
          selectedExploreTabIndex !== 8
        ) {
          setShowArticleLoader(false);
          setSelectedMyClientsDropdownOption('');
          setShowClientDropDown(true);
        } else {
          if (selectedMyClientsDropdownOption === 'financial') {
            // setShowArticleLoader(false);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'myClients',
                id: clientDetails.id,
              },
            });
          } else if (selectedMyClientsDropdownOption === 'crm') {
            // setShowArticleLoader(false);
            setCrmLoader(false);
            exploreArticleRequest({
              params: {
                source: 'crmClients',
                themesList:
                  selectedCRMContactList.themes.length > 0
                    ? selectedCRMContactList.themes.map(
                        (theme) => theme.id_hash
                      )
                    : [],
              },
            });
          } else {
            setShowArticleLoader(false);
          }
        }

        // exploreArticleRequest({
        //   params: {
        //     id: 'SECURITY',
        //     source: 'myClients',
        //   },
        // });
        setShowArticleLoader(false);
      } else {
        setSearchOptions([
          { platform: 'all', checked: true, isDisconnected: false },
          { platform: 'my_content', checked: true, isDisconnected: false },
          { platform: 'editor_content', checked: true, isDisconnected: false },
        ]);
        setSearchValue('');
        exploreArticleRequest({
          params: {
            source: 'search',
          },
        });
      }
      onWidgetTabChange(4);
    } else if (index === 7) {
      if (
        userData.details &&
        userData.details.user.turnkey &&
        userData.details.user.turnkey === 'true'
      ) {
        setSearchOptions([
          { platform: 'all', checked: true, isDisconnected: false },
          { platform: 'my_content', checked: true, isDisconnected: false },
        ]);
        setWdigetTabIndex(5);
        setSearchValue('');
        if (!isEmpty(searchResults)) {
          exploreSetState({ search: {} });
        }
      } else {
        exploreArticleRequest({
          params: {
            source: 'bookmarks',
          },
        });
      }
    } else if (index === 8) {
      // console.log('index 7');
      exploreArticleRequest({
        params: {
          source: 'bookmarks',
        },
      });
    }
    setSelectedExploreTabIndex(index);
  };

  const onRecentTabSelect = (index) => {
    setShowArticleLoader(true);
    setSelectedRecentTabIndex(index);
  };

  const onScheduledTabSelect = (index) => {
    console.log('index - ', index);
    setShowArticleLoader(true);
    setSelectedScheduledTabIndex(index);
  };
  const onDeleteArticleClick = (id) => {
    let params = {};
    setShowArticleLoader(true);
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
      params = {
        deleteId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      params = {
        source: 'recommended',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 2) {
      params = {
        deleteId: id,
        id: popularTopicDetails.id,
        source: 'approveToShare',
        date: curatedTopicType,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 0
    ) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'trending',
        date: trendingTopicDetails.date,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 2
    ) {
      params = {
        deleteId: id,
        id: popularTopicDetails.id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 2 && selectedScheduledTabIndex === 2) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'recommendation_engine',
      };
    } else if (selectedScheduledTabIndex === 1 && selectedTabIndex === 2) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'admin',
      };
    } else if (selectedScheduledTabIndex === 0 && selectedTabIndex === 2) {
      params = {
        deleteId: id,
        id: trendingTopicDetails.id,
        source: 'scheduled',
        date: trendingTopicDetails.date,
        type: 'user',
      };
      // } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 5) {
      //   params = {
      //     deleteId: id,
      //     id: trendingTopicDetails.id,
      //     source: 'mymio',
      //     date: trendingTopicDetails.date,
      //   };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      params = {
        deleteId: id,
        id: searchValue,
        source: 'search',
        date: searchOptions,
        ...moreSearchParams,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      params = {
        deleteId: id,
        id: currentPortfolio,
        source: 'investments',
        date: trendingTopicDetails.date,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 6
    ) {
      params = {
        deleteId: id,
        id: clientDetails.id,
        source: 'myClients',
        contactName: clientDetails.contactName,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 5
    ) {
      params = {
        deleteId: id,
        id: clientDetails.id,
        source: 'myClients',
        contactName: clientDetails.contactName,
      };
    }
    deleteArticleRequest({ params });
  };

  const onNewsletterArticleClick = (id, themeId, source) => {
    let params = {};
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
      params = {
        articleId: id,
        themeId: themeId,
        id: popularTopicDetails.id,
        newsletterId: id,
        source: 'explore',
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      params = {
        source: 'recommended',
        articleId: id,
        themeId: themeId,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 0
    ) {
      params = {
        articleId: id,
        themeId: themeId,
        id: trendingTopicDetails.id,
        source: 'trending',
        newsletterId: id,
        date: trendingTopicDetails.date,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 1 &&
      discoverTabIndex === 2
    ) {
      params = {
        articleId: id,
        themeId: themeId,
        id: popularTopicDetails.id,
        newsletterId: id,
        source: 'explore',
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 7
    ) {
      params = {
        articleId: id,
        themeId: themeId,
        id: searchValue,
        source: 'search',
        newsletterId: id,
        date: searchOptions,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 6
    ) {
      params = {
        articleId: id,
        themeId: themeId,
        id: searchValue,
        source: 'search',
        newsletterId: id,
        date: searchOptions,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 4) {
      params = {
        articleId: id,
        themeId: themeId,
        id: currentPortfolio,
        source: 'investments',
        newsletterId: id,
        date: trendingTopicDetails.date,
        holdingsType: holdingsType,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 5
    ) {
      if (selectedMyClientsDropdownOption === 'financial') {
        params = {
          articleId: id,
          themeId: themeId,
          id: clientDetails.id,
          source: 'myClients',
          newsletterId: id,
          contactName: clientDetails.contactName,
        };
      } else {
        params = {
          articleId: id,
          themeId: themeId,
          id: clientDetails.id,
          source: 'crmClients',
          newsletterId: id,
          themesList: getCRMThemeIds(),
        };
      }
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 6
    ) {
      if (selectedMyClientsDropdownOption === 'financial') {
        params = {
          articleId: id,
          themeId: themeId,
          id: clientDetails.id,
          source: 'myClients',
          newsletterId: id,
          contactName: clientDetails.contactName,
        };
      } else {
        params = {
          articleId: id,
          themeId: themeId,
          id: clientDetails.id,
          source: 'crmClients',
          newsletterId: id,
          themesList: getCRMThemeIds(),
        };
      }
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 7 &&
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false'
    ) {
      params = {
        source: 'bookmarks',
        themeId: themeId,
        articleId: id,
        newsletterId: id,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 8) {
      params = {
        source: 'bookmarks',
        themeId: themeId,
        articleId: id,
        newsletterId: id,
      };
    }

    if (source === 'newsLetter') {
      console.log('SOURCE NEWSLETTER');
      addToBucketRequest({ params });
    } else {
      console.log('SOURCE BOOKMARKS');
      addToBookmarksRequest({ params });
    }
  };

  useEffect(() => {
    if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 3 &&
      !_.isEmpty(exploreArticles) &&
      !_.isEmpty(exploreArticles.details)
    ) {
      let exploreData = {};
      exploreArticles?.details?.theme &&
        Object.keys(exploreArticles?.details?.theme).forEach((themeId) => {
          exploreArticles.feed.forEach((feed) => {
            const topicName = exploreArticles?.details.theme[themeId].name;
            if (feed.ids?.theme[0] === themeId) {
              if (exploreData[topicName]) {
                exploreData[topicName].feed.push(feed);
              } else {
                exploreData[topicName] = {
                  details: exploreArticles.details,
                  feed: [feed],
                };
              }
            }
          });
        });

      setExploreTabDetails(exploreData);
      setShowArticleLoader(false);
    }
  }, [exploreArticles]);

  useEffect(() => {
    if (!popularTopicDetails.id) {
      setExploreTabDetails({});
    }
  }, [popularTopicDetails]);

  const onRemoveFromNewletterClick = (id, articleId, source) => {
    let params = {};
    if (selectedTabIndex === 0 && selectedExploreTabIndex === 2) {
      params = {
        articleId: id,
        id: popularTopicDetails.id,
        source: 'explore',
        newsletterId: articleId,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 0) {
      params = {
        source: 'recommended',
        articleId: id,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 0 &&
      discoverTabIndex === 0
    ) {
      params = {
        articleId: id,
        id: trendingTopicDetails.id,
        source: 'trending',
        date: trendingTopicDetails.date,
        newsletterId: articleId,
      };
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 0 &&
      discoverTabIndex === 2
    ) {
      params = {
        articleId: id,
        id: popularTopicDetails.id,
        source: 'explore',
        newsletterId: articleId,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 7
    ) {
      params = {
        articleId: id,
        id: searchValue,
        source: 'search',
        date: searchOptions,
        newsletterId: articleId,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 6
    ) {
      params = {
        articleId: id,
        id: searchValue,
        source: 'search',
        date: searchOptions,
        newsletterId: articleId,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 3) {
      params = {
        articleId: id,
        id: currentPortfolio,
        source: 'investments',
        date: trendingTopicDetails.date,
        newsletterId: articleId,
        holdingsType: holdingsType,
      };
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 5
    ) {
      if (selectedMyClientsDropdownOption === 'financial') {
        params = {
          articleId: id,
          id: clientDetails.id,
          source: 'myClients',
          contactName: clientDetails.contactName,
          newsletterId: articleId,
        };
      } else {
        params = {
          articleId: id,
          id: clientDetails.id,
          source: 'crmClients',
          themesList: getCRMThemeIds(),
          newsletterId: articleId,
        };
      }
    } else if (
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'true' &&
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 6
    ) {
      if (selectedMyClientsDropdownOption === 'financial') {
        params = {
          articleId: id,
          id: clientDetails.id,
          source: 'myClients',
          contactName: clientDetails.contactName,
          newsletterId: articleId,
        };
      } else {
        params = {
          articleId: id,
          id: clientDetails.id,
          source: 'crmClients',
          themesList: getCRMThemeIds(),
          newsletterId: articleId,
        };
      }
    } else if (
      selectedTabIndex === 0 &&
      selectedExploreTabIndex === 7 &&
      userData.details &&
      userData.details.user.turnkey &&
      userData.details.user.turnkey === 'false'
    ) {
      params = {
        source: 'bookmarks',
        articleId: id,
        newsletterId: articleId,
      };
    } else if (selectedTabIndex === 0 && selectedExploreTabIndex === 8) {
      params = {
        source: 'bookmarks',
        articleId: id,
        newsletterId: articleId,
      };
    }

    if (source === 'newsLetter') removeFromNewsletterRequest({ params });
    else removeFromBookmarksRequest({ params });
  };

  const onTabSelect = (index) => {
    if (selectedTabIndex === index) return false;
    if (index === 3) {
      setCuratedTopicType('all');
      contentlibSetState({ data: {} });
      exploreSetState({ data: {} });
    }
    setShowArticleLoader(true);
    setTabIndex(index);
  };

  const handleTickerChange = (ticker) => {
    // exploreArticleRequest({
    //   params: {
    //     selectedTicker: ticker,
    //     source: 'investments',
    //   },
    // });
    setIsFilteringStocksByTicker(holdingsType === 'stocks' ? true : false);
    exploreSetState({ ticker }); //filtering from list based on selected tickerName
  };

  const getCRMThemeIds = () => {
    let theme_ids;
    if (selectedCRMContactList.disabledThemes.length > 0)
      theme_ids = selectedCRMContactList.themes
        .filter(
          (o) => selectedCRMContactList.disabledThemes.indexOf(o.id_hash) === -1
        )
        .map((theme) => theme.id_hash);
    else
      theme_ids = selectedCRMContactList.themes.map((theme) => theme.id_hash);
    return theme_ids;
  };

  const exploreArticleFeed = get(exploreArticles, 'feed', []);
  const showTabLoader =
    !exploreArticleDeleting &&
    exploreArticlesFetching &&
    !newsletterArticleFetching &&
    !bookmarksFetching;
  // console.log('ENTERPRISE ADV RENDERING....', isShareBlocked);
  return (
    <Box width={1}>
      <Flex
        flexDirection={`${window.innerWidth < 768 ? 'column' : 'row'}`}
        justifyContent="space-between"
        width={1}
        my="25px"
      >
        <Discover
          setTabIndex={setTabIndex}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          setTrendingTopicDetails={setTrendingTopicDetails}
          themesList={themesList}
          channelData={channelData}
          roleType={roleType}
          exploreSetState={exploreSetState}
          setShowArticleLoader={setShowArticleLoader}
          discoverTabIndex={discoverTabIndex}
          onDiscoverTabChange={onDiscoverTabChange}
          onTrendingTabClick={onTrendingTabClick}
          setPopularTopicDetails={setPopularTopicDetails}
          onExploreTabClick={onSubscribeTabClick}
          shareArticleSetState={shareArticleSetState}
          bucketCountRequest={bucketCountRequest}
          isShareBlocked={
            userData &&
            userData.details &&
            userData.details.user &&
            userData.details.user.extra_settings &&
            userData.details.user.extra_settings.share_content
              ? userData.details.user.extra_settings.share_content !== 'true'
              : true
          }
        />
        <Personalized
          exploreSetState={exploreSetState}
          themesList={popularThemes}
          sponsoredThemeList={sponsoredThemeList}
          setTabIndex={setTabIndex}
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          setPopularTopicDetails={setPopularTopicDetails}
          setTrendingTopicDetails={setTrendingTopicDetails}
          trendingTopicDetails={trendingTopicDetails}
          onInvestmentClick={onInvestmentClick}
          topicFromInvestment={investmentTopic}
          setInvestmentTopic={setInvestmentTopic}
          roleType={roleType}
          tickerName={tickerName}
          tabIndex={selectedTabIndex}
          themeSetState={themeSetState}
          yourTopics={yourTopics}
          onWidgetTabChange={onWidgetTabChange}
          widgetTabIndes={widgetTabIndes}
          setWdigetTabIndex={setWdigetTabIndex}
          setShowArticleLoader={setShowArticleLoader}
          currentPortfolio={currentPortfolio}
          holdingsType={holdingsType}
          curatedTopicType={curatedTopicType}
          setCuratedTopicType={setCuratedTopicType}
          popularTopicDetails={popularTopicDetails}
          setExploreTabDetails={setExploreTabDetails}
          exploreArticleRequest={exploreArticleRequest}
          onSearchClick={onSearchClick}
          setSearchValue={setSearchValue}
          channelData={channelData}
          onCheckBoxClick={onCheckBoxClick}
          checkedStatus={checkedStatus}
          searchValue={searchValue}
          userData={userData}
          isPremiumAdvisor={false}
          isAdmin={false}
          contentlibSetState={contentlibSetState}
          isEnterpriseAdvisor={true}
          showClientDropDown={showClientDropDown}
          setShowClientDropDown={setShowClientDropDown}
          contactList={contactList}
          setThirdPartyThemeId={setThirdPartyThemeId}
          setClearEditorArticles={setClearEditorArticles}
          thirdPartyThemeId={thirdPartyThemeId}
          exploreArticles={exploreArticles}
          setClientDetails={setClientDetails}
          selectedDropdownOption={selectedMyClientsDropdownOption}
          setSelectedDropdownOption={setSelectedMyClientsDropdownOption}
          setSelectedCRMContactList={setSelectedCRMContactList}
          crmLoader={crmLoader}
          setCrmLoader={setCrmLoader}
          personalized_tabs={
            userData &&
            userData.details &&
            userData.details.user &&
            userData.details.user.personalized_tabs
          }
          onExploreTabClick={onExploreTabClick}
          popularTabIndex={discoverTabIndex}
          firmSettings={firmSettings}
        />
        {/* <Popular
          setTabIndex={() => setTabIndex(0)}
          setPopularTopicDetails={setSubscribedTopicDetails}
          isShareBlocked
          setSelectedExploreTabIndex={setSelectedExploreTabIndex}
          roleType={roleType}
          setShowArticleLoader={setShowArticleLoader} 
          onExploreTabClick={onSubscribeTabClick}
          popularTabIndex={popularTabIndex}
          onPopularTabChange={onPopularTabChange}
        /> */}
        <FeaturedPosts
          popularThemes={popularThemes}
          themeNames={themeNames}
          isShareBlocked={
            userData &&
            userData.details &&
            userData.details.user &&
            userData.details.user.extra_settings &&
            userData.details.user.extra_settings.share_content
              ? userData.details.user.extra_settings.share_content !== 'true'
              : true
          }
          setModalOpen={setOpenSponsoredModal}
          shareArticleSetState={shareArticleSetState}
          userData={userData}
          sponsoredArticles={sponsoredArticles}
          setSponsoredArticles={setSponsoredArticles}
          bucketCountRequest={bucketCountRequest}
        />
      </Flex>
      <SponsoredModal
        open={openSponsoredModal}
        popularThemes={popularThemes}
        setModalOpen={setOpenSponsoredModal}
        sponsoredArticles={sponsoredArticles}
        themeNames={themeNames}
        isDeleteBlocked={true}
        userData={userData}
        setSponsoredArticles={setSponsoredArticles}
        bucketCountRequest={bucketCountRequest}
        isShareBlocked={
          userData &&
          userData.details &&
          userData.details.user &&
          userData.details.user.extra_settings &&
          userData.details.user.extra_settings.share_content
            ? userData.details.user.extra_settings.share_content !== 'true'
            : true
        }
      />
      <Flex width={1}>
        <Tabs
          onSelect={onTabSelect}
          selectedIndex={selectedTabIndex}
          forceRenderTabPanel
        >
          <BorderWhiteBox type="admin-tab" padding="5px 5px">
            <TabList height="31px">
              <TabTypeThree
                type="dashboard"
                font={window.innerWidth}
                currentTab={selectedTabIndex === 0}
                onClick={() => onExploreTabSelect(selectedTabIndex)}
              >
                Explore
              </TabTypeThree>
              <TabTypeThree
                type="dashboard"
                font={window.innerWidth}
                currentTab={selectedTabIndex === 1}
                onClick={onRecentTabClick}
              >
                Recent
              </TabTypeThree>
              <TabTypeThree
                type="dashboard"
                font={window.innerWidth}
                currentTab={selectedTabIndex === 2}
                onClick={onScheduledBySelfTabClick}
              >
                Scheduled
              </TabTypeThree>
              {get(
                userData,
                'details.user.extra_settings.add_content',
                'false'
              ) === 'true' ? (
                <TabTypeThree
                  type="dashboard"
                  currentTab={selectedTabIndex === 3}
                >
                  Add Content
                </TabTypeThree>
              ) : null}
            </TabList>

            {selectedTabIndex === 0 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onExploreTabSelect} forceRenderTabPanel>
                  <TabList style={{ marginBottom: '10px' }}>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                    >
                      Recommended
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                    >
                      Popular
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 2 ? 'active' : 'inactive'
                      }`}
                    >
                      My Content
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      color="#49484a"
                      padding="5px 23px 3px"
                      className={`${
                        selectedExploreTabIndex === 3 ? 'active' : 'inactive'
                      }`}
                    >
                      My Topics
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      hide={
                        get(firmSettings, 'firm_general.my_holding', 'true') === 'false'
                      }
                      color="#49484a"
                      padding="5px 23px 3px"
                      font={window.innerWidth}
                      className={`${
                        selectedExploreTabIndex === 4 ? 'active' : 'inactive'
                      }`}
                      title="Investment Holdings"
                    >
                      My Holdings{' '}
                      {holdingsType && (
                        <HoldingsWrapper className="tooltip">
                          <HoldingsIcon
                            style={{
                              ...(holdingsType === 'stocks' && {
                                width: '9px',
                              }),
                            }}
                            src={`/assets/images/${holdingsType}.png`}
                            data-testid={`holding_img_${holdingsType}`}
                          />
                          <StyledTooltip
                            right={holdingsType === 'stocks' ? '38%' : '40%'}
                            className="tooltiptext"
                            width={holdingsType === 'stocks' ? '80px' : null}
                          >
                            {holdingsType === 'stocks'
                              ? 'STOCK SPECIFIC'
                              : 'THEMATIC'}
                          </StyledTooltip>
                        </HoldingsWrapper>
                      )}
                    </DashboardAdminSubTab>
                    {userData.details &&
                      userData.details.user.turnkey &&
                      userData.details.user.turnkey === 'true' ? (
                        <DashboardAdminSubTab
                          color="#49484a"
                          padding="5px 23px 3px"
                          className={`${
                            selectedExploreTabIndex === 5
                              ? 'active'
                              : 'inactive'
                          }`}
                          hide={get(firmSettings, 'firm_general.my_editor', 'true') === 'false'}
                        >
                          My Editor
                        </DashboardAdminSubTab>
                      ) : (
                        <DashboardAdminSubTab
                          color="#49484a"
                          padding="5px 23px 3px"
                          className={`${
                            selectedExploreTabIndex === 5
                              ? 'active'
                              : 'inactive'
                          }`}
                          hide={get(firmSettings, 'firm_general.my_editor', 'true') === 'false'}
                        >
                          My Editor
                        </DashboardAdminSubTab>
                      )}

                    {userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'true' ? (
                      <DashboardAdminSubTab
                        color="#49484a"
                        hide={
                          get(firmSettings, 'firm_general.my_clients', 'true') === 'false'
                        }
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 6 ? 'active' : 'inactive'
                        }`}
                      >
                        My Clients
                      </DashboardAdminSubTab>
                    ) : (
                      <DashboardAdminSubTab
                        color="#49484a"
                        hide={
                          get(firmSettings, 'firm_general.my_clients', 'true') === 'false'
                        }
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 5 ? 'active' : 'inactive'
                        }`}
                      >
                        My Clients
                      </DashboardAdminSubTab>
                    )}

                    {userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'true' ? (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 7 ? 'active' : 'inactive'
                        }`}
                      >
                        Search
                      </DashboardAdminSubTab>
                    ) : (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 6 ? 'active' : 'inactive'
                        }`}
                      >
                        Search
                      </DashboardAdminSubTab>
                    )}

                    {userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'true' ? (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 8 ? 'active' : 'inactive'
                        }`}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          Bookmarked{' '}
                          <img
                            alt=""
                            style={{ marginLeft: '2px' }}
                            src="/assets/images/bookmark-filled.svg"
                          />
                        </div>
                      </DashboardAdminSubTab>
                    ) : (
                      <DashboardAdminSubTab
                        color="#49484a"
                        padding="5px 23px 3px"
                        className={`${
                          selectedExploreTabIndex === 7 ? 'active' : 'inactive'
                        }`}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          Bookmarked{' '}
                          <img
                            alt=""
                            style={{ marginLeft: '2px' }}
                            src="/assets/images/bookmark-filled.svg"
                          />
                        </div>
                      </DashboardAdminSubTab>
                    )}
                  </TabList>
                  {selectedExploreTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(get(exploreArticles, 'feed', [])) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.mioEditorTab}
                          </NoContentWrapper>
                        )}
                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && (
                        <RecommendedTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          setShowArticleLoader={setShowArticleLoader}
                          popularThemes={popularThemes}
                          isShareBlocked={
                            userData &&
                            userData.details &&
                            userData.details.user &&
                            userData.details.user.extra_settings &&
                            userData.details.user.extra_settings.share_content
                              ? userData.details.user.extra_settings
                                  .share_content !== 'true'
                              : null
                          }
                          userData={userData}
                          onNewsletterArticleClick={onNewsletterArticleClick}
                          onRemoveFromNewletterClick={
                            onRemoveFromNewletterClick
                          }
                          newsletterArticleFetching={newsletterArticleFetching}
                          exploreNewsletterId={exploreNewsletterId}
                          bookmarksFetching={bookmarksFetching}
                          bookmarksId={bookmarksId}
                          isDeleteBlocked
                          hideDelete
                        />
                      )}
                    </StyledTabPanel>
                  )}

                  {selectedExploreTabIndex === 2 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {/* {!exploreArticlesFetching &&
                        !contentLibArticlesFetching &&
                        isEmpty(exploreArticleFeed) &&
                        isEmpty(
                          get(contentLibArticles, 'channel_content', [])
                        ) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                        )}
                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )} */}

                      {!showTabLoader && (
                        <ContentLibraryTab
                          setLocalContent={setLocalContent}
                          selectedTopic={popularTopicDetails.topicName}
                          contentlibRequest={contentlibRequest}
                          contentlibDeleteRequest={contentlibDeleteRequest}
                          contentlibSetState={contentlibSetState}
                          contentLibArticles={contentLibArticles}
                          contentLibArticlesFetching={
                            contentLibArticlesFetching
                          }
                          themeNames={themeNames}
                          contentLibArticleDeleting={contentLibArticleDeleting}
                          isShareBlocked={false}
                          isDeleteBlocked={false}
                          showTabLoader={showTabLoader}
                          setShowArticleLoader={setShowArticleLoader}
                          exploreArticles={exploreArticles}
                          curatedTopicType={curatedTopicType}
                          popularTopicDetails={popularTopicDetails}
                          exploreArticleRequest={exploreArticleRequest}
                          isEnterpriseAdvisor={true}
                          popularThemes={popularThemes}
                          userData={userData}
                          contentLibAddToBucketRequest={
                            contentLibAddToBucketRequest
                          }
                          contentLibRemoveFromBucketRequest={
                            contentLibRemoveFromBucketRequest
                          }
                          newsletterArticleFetching={
                            contentLibNewsletterFetching
                          }
                          bookmarksFetching={contentLibBookmarksFetching}
                          bookmarksId={contentLibBookmarksId}
                          contentLibAddToBookmarksRequest={
                            contentLibAddToBookmarksRequest
                          }
                          contentLibRemoveFromBookmarksRequest={
                            contentLibRemoveFromBookmarksRequest
                          }
                          contentLibBucketId={contentLibBucketId}
                          contentLibInNewsletter={contentLibInNewsletter}
                          contentLibBookMarkId={contentLibBookMarkId}
                          contentLibIsBookmark={contentLibIsBookmark}
                          channelId={channelId}
                          contentLibATSQueue={contentLibATSQueue}
                          contentLibFilter={contentLibFilter}
                        />
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedExploreTabIndex === 3 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                        )}
                      {/* {(showArticleLoader || exploreArticlesFetching) && !isEmpty(exploreArticleFeed) && ( */}
                      {(showArticleLoader || exploreArticlesFetching) && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!showTabLoader && !exploreArticlesFetching && (
                        <>
                          {!_.isEmpty(exploreTabDetails) && (
                            <ExploreTab
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={popularTopicDetails.topicName}
                              exploreArticles={exploreArticles}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              setShowArticleLoader={setShowArticleLoader}
                              popularThemes={popularThemes}
                              isShareBlocked={
                                userData &&
                                userData.details &&
                                userData.details.user &&
                                userData.details.user.extra_settings &&
                                userData.details.user.extra_settings
                                  .share_content
                                  ? userData.details.user.extra_settings
                                      .share_content !== 'true'
                                  : null
                              }
                              userData={userData}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                              // setCustomMargin
                            />
                          )}
                          {/* {_.isEmpty(exploreTabDetails) && (
                          <NoContentWrapper style={{ cursor: 'pointer' }}>{emptyMessages.exploreTab}</NoContentWrapper>
                        )} */}
                        </>
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedExploreTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.default}
                          </NoContentWrapper>
                        )}
                      {(showArticleLoader || exploreArticlesFetching) && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && !exploreArticlesFetching && (
                        <TrendingTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={trendingTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          setShowArticleLoader={setShowArticleLoader}
                          popularThemes={popularThemes}
                          isShareBlocked={
                            userData &&
                            userData.details &&
                            userData.details.user &&
                            userData.details.user.extra_settings &&
                            userData.details.user.extra_settings.share_content
                              ? userData.details.user.extra_settings
                                  .share_content !== 'true'
                              : null
                          }
                          userData={userData}
                          onNewsletterArticleClick={onNewsletterArticleClick}
                          onRemoveFromNewletterClick={
                            onRemoveFromNewletterClick
                          }
                          newsletterArticleFetching={newsletterArticleFetching}
                          exploreNewsletterId={exploreNewsletterId}
                          bookmarksFetching={bookmarksFetching}
                          bookmarksId={bookmarksId}
                        />
                      )}
                    </StyledTabPanel>
                  )}

                  {selectedExploreTabIndex === 5 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'true' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                       
                            {!exploreArticlesFetching &&
                              isEmpty(get(exploreArticles, 'feed', [])) && isEmpty(mioContent) && (
                                <NoContentWrapper data-testid="no-content-message">
                                  {emptyMessages.mioEditorTab}
                                </NoContentWrapper>
                              )}

                            {showArticleLoader && (
                              <ExploreRelativeDiv>
                                <AbsoluteLoader>
                                  <Loader height="25px" />
                                </AbsoluteLoader>
                              </ExploreRelativeDiv>
                            )}

                            <MioEditorTab
                              selectedTopic={popularTopicDetails.topicName}
                              exploreArticles={exploreArticles}
                              deleteArticleRequest={deleteArticleRequest}
                              exploreArticlesFetching={exploreArticlesFetching}
                              themeNames={themeNames}
                              exploreArticleDeleting={exploreArticleDeleting}
                              isShareBlocked={
                                userData &&
                                userData.details &&
                                userData.details.user &&
                                userData.details.user.extra_settings &&
                                userData.details.user.extra_settings
                                  .share_content
                                  ? userData.details.user.extra_settings
                                      .share_content !== 'true'
                                  : null
                              }
                              isDeleteBlocked={isDeleteBlocked}
                              exploreArticleRequest={exploreArticleRequest}
                              setMioContent={setMioContent}
                              imageUpload={window.localStorage.getItem(
                                'ImageUpload'
                              )}
                              setShowArticleLoader={setShowArticleLoader}
                              thirdPartyThemeId={thirdPartyThemeId}
                              clearEditorArticles={clearEditorArticles}
                              setClearEditorArticles={setClearEditorArticles}
                              isChangeTheme={isChangeTheme}
                              popularThemes={popularThemes}
                              userData={userData}
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              addToBucketRequest={addToBucketRequest}
                              removeFromNewsletterRequest={
                                removeFromNewsletterRequest
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              addToBookmarksRequest={addToBookmarksRequest}
                              removeFromBookmarksRequest={
                                removeFromBookmarksRequest
                              }
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                            />
                       
                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 5 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'false' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                        {((!exploreArticlesFetching &&
                          !crmLoader &&
                          isEmpty(get(exploreArticles, 'feed', []))) ||
                          selectedMyClientsDropdownOption === '') && (
                          // <NoContentWrapper>{selectedMyClientsDropdownOption === 'financial' || selectedCRMContactList.id ? emptyMessages.exploreTab : 'Please connect your CRM accounts to populate content'}</NoContentWrapper>
                          <NoContentWrapper>
                            {selectedMyClientsDropdownOption === ''
                              ? 'Select from available options'
                              : selectedMyClientsDropdownOption ===
                                  'financial' ||
                                (selectedCRMContactList.id &&
                                  selectedCRMContactList.id.length > 0)
                              ? emptyMessages.exploreTab
                              : 'Please connect your CRM accounts to populate content'}
                          </NoContentWrapper>
                        )}

                        {!exploreArticlesFetching &&
                          selectedMyClientsDropdownOption === 'crm' &&
                          isEmpty(myClientsLocalArticles) &&
                          !isEmpty(get(exploreArticles, 'feed', [])) && (
                            <NoContentWrapper>
                              Please enable atleast one topic
                            </NoContentWrapper>
                          )}

                        {(showArticleLoader || exploreArticlesFetching) && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}
                        {!showTabLoader &&
                          selectedMyClientsDropdownOption !== '' && (
                            <MyClientsTab
                              setLocalContent={setLocalContent}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              contentlibRequest={contentlibRequest}
                              contentlibDeleteRequest={contentlibDeleteRequest}
                              contentLibArticles={contentLibArticles}
                              contentLibArticlesFetching={
                                contentLibArticlesFetching
                              }
                              themeNames={themeNames}
                              contentLibArticleDeleting={
                                contentLibArticleDeleting
                              }
                              isShareBlocked={
                                shareContent
                                  ? selectedMyClientsDropdownOption ===
                                    'financial'
                                    ? clientShareBlocked
                                    : !selectedCRMContactList.id
                                  : true
                              }
                              isDeleteBlocked={clientShareBlocked}
                              showTabLoader={showTabLoader}
                              setShowArticleLoader={setShowArticleLoader}
                              curatedTopicType={curatedTopicType}
                              exploreArticles={exploreArticles}
                              popularThemes={popularThemes}
                              userData={userData}
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              selectedDropdownOption={
                                selectedMyClientsDropdownOption
                              }
                              selectedCRMContactList={selectedCRMContactList}
                              setMyClientsLocalArticles={
                                setMyClientsLocalArticles
                              }
                            />
                          )}
                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 6 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'true' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                        {((!exploreArticlesFetching &&
                          !crmLoader &&
                          isEmpty(get(exploreArticles, 'feed', []))) ||
                          selectedMyClientsDropdownOption === '') && (
                          // <NoContentWrapper>{selectedMyClientsDropdownOption === 'financial' || selectedCRMContactList.id ? emptyMessages.exploreTab : 'Please connect your CRM accounts to populate content'}</NoContentWrapper>
                          <NoContentWrapper>
                            {selectedMyClientsDropdownOption === ''
                              ? 'Select from available options'
                              : selectedMyClientsDropdownOption ===
                                  'financial' ||
                                (selectedCRMContactList.id &&
                                  selectedCRMContactList.id.length > 0)
                              ? emptyMessages.exploreTab
                              : 'Please connect your CRM accounts to populate content'}
                          </NoContentWrapper>
                        )}

                        {!exploreArticlesFetching &&
                          selectedMyClientsDropdownOption === 'crm' &&
                          isEmpty(myClientsLocalArticles) &&
                          !isEmpty(get(exploreArticles, 'feed', [])) && (
                            <NoContentWrapper>
                              Please enable atleast one topic
                            </NoContentWrapper>
                          )}

                        {(showArticleLoader || exploreArticlesFetching) && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}

                        {!showTabLoader &&
                          selectedMyClientsDropdownOption !== '' && (
                            <MyClientsTab
                              setLocalContent={setLocalContent}
                              selectedTopic={popularTopicDetails.topicName}
                              contentlibRequest={contentlibRequest}
                              contentlibDeleteRequest={contentlibDeleteRequest}
                              deleteArticleRequest={onDeleteArticleClick}
                              contentLibArticles={contentLibArticles}
                              contentLibArticlesFetching={
                                contentLibArticlesFetching
                              }
                              themeNames={themeNames}
                              contentLibArticleDeleting={
                                contentLibArticleDeleting
                              }
                              isShareBlocked={
                                shareContent
                                  ? selectedMyClientsDropdownOption ===
                                    'financial'
                                    ? clientShareBlocked
                                    : !selectedCRMContactList.id
                                  : true
                              }
                              isDeleteBlocked={clientShareBlocked}
                              showTabLoader={showTabLoader}
                              setShowArticleLoader={setShowArticleLoader}
                              curatedTopicType={curatedTopicType}
                              exploreArticles={exploreArticles}
                              popularThemes={popularThemes}
                              userData={userData}
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              selectedDropdownOption={
                                selectedMyClientsDropdownOption
                              }
                              selectedCRMContactList={selectedCRMContactList}
                              setMyClientsLocalArticles={
                                setMyClientsLocalArticles
                              }
                            />
                          )}
                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 6 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'false' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                        {!exploreArticlesFetching &&
                          (isEmpty(get(searchResults, 'feed')) ||
                            filteredSearchEmpty ||
                            Object.keys(searchResults).length === 0) && (
                            <NoContentWrapper>
                              {!isSearchFilters ||
                              filteredSearchEmpty ||
                              (searchValue &&
                                searchResults &&
                                searchResults.feed &&
                                searchResults.feed.length > 0 &&
                                searchFilters &&
                                searchFilters.length > 0) ? (
                                <Flex
                                  width={1}
                                  justifyContent="center"
                                  alignItems="center"
                                  data-testid="no-content-message"
                                >
                                  No articles found
                                </Flex>
                              ) : (
                                <div>Search Results</div>
                              )}
                            </NoContentWrapper>
                          )}
                        {/* {
                      (!exploreArticlesFetching && !isEmpty(get(searchResults, 'feed')) && !isSearchFilters) && (
                        <NoContentWrapper>
                          <div>
                            No Articles Found
                          </div>
                        </NoContentWrapper>
                      )
                    } */}
                        {showArticleLoader && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}
                        {!showTabLoader && (
                          <LoaderWrapper>
                            <SearchResultsTab
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={trendingTopicDetails.topicName}
                              exploreArticles={searchResults}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              searchFilters={searchFilters}
                              exploreSetState={exploreSetState}
                              setIsSearchFilters={setIsSearchFilters}
                              mostSubscribed={mostSubscribed}
                              yourTopics={yourTopics}
                              setShowArticleLoader={setShowArticleLoader}
                              setFilteredSearchEmpty={setFilteredSearchEmpty}
                              isShareBlocked={
                                userData &&
                                userData.details &&
                                userData.details.user &&
                                userData.details.user.extra_settings &&
                                userData.details.user.extra_settings
                                  .share_content
                                  ? userData.details.user.extra_settings
                                      .share_content !== 'true'
                                  : true
                              }
                              popularThemes={popularThemes}
                              userData={userData}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                            />
                          </LoaderWrapper>
                        )}
                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 7 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'true' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        padding="5px 5px"
                      >
                        {(isEmpty(get(searchResults, 'feed')) ||
                          filteredSearchEmpty ||
                          Object.keys(searchResults).length === 0) && (
                          <NoContentWrapper>
                            {isSearchFilters ? (
                              <div>Search Results</div>
                            ) : (
                              <div data-testid="no-content-message">No articles found</div>
                            )}
                          </NoContentWrapper>
                        )}
                        {showArticleLoader && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}

                        {!showTabLoader && (
                          <LoaderWrapper>
                            <SearchResultsTab
                              selectedTopic={trendingTopicDetails.topicName}
                              exploreArticles={searchResults}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              exploreArticleDeleting={exploreArticleDeleting}
                              isShareBlocked={
                                userData &&
                                userData.details &&
                                userData.details.user &&
                                userData.details.user.extra_settings &&
                                userData.details.user.extra_settings
                                  .share_content
                                  ? userData.details.user.extra_settings
                                      .share_content !== 'true'
                                  : true
                              }
                              isDeleteBlocked={isDeleteBlocked}
                              setIsSearchFilters={setIsSearchFilters}
                              mostSubscribed={mostSubscribed}
                              yourTopics={yourTopics}
                              setShowArticleLoader={setShowArticleLoader}
                              searchFilters={searchFilters}
                              setFilteredSearchEmpty={setFilteredSearchEmpty}
                              userData={userData}
                              onNewsletterArticleClick={
                                onNewsletterArticleClick
                              }
                              onRemoveFromNewletterClick={
                                onRemoveFromNewletterClick
                              }
                              newsletterArticleFetching={
                                newsletterArticleFetching
                              }
                              exploreNewsletterId={exploreNewsletterId}
                              bookmarksFetching={bookmarksFetching}
                              bookmarksId={bookmarksId}
                            />
                          </LoaderWrapper>
                        )}
                      </StyledTabPanel>
                    )}
                  {selectedExploreTabIndex === 7 &&
                    userData.details &&
                    userData.details.user.turnkey &&
                    userData.details.user.turnkey === 'false' && (
                      <StyledTabPanel
                        type="admin"
                        className="react-tabs__tab-panel--selected"
                        style={{ padding: '5px 5px' }}
                      >
                        {!exploreArticlesFetching &&
                          isEmpty(get(exploreArticles, 'feed', [])) && (
                            <NoContentWrapper data-testid="no-content-message">
                              {emptyMessages.mioEditorTab}
                            </NoContentWrapper>
                          )}

                        {exploreArticlesFetching && (
                          <ExploreRelativeDiv>
                            <AbsoluteLoader>
                              <Loader height="25px" />
                            </AbsoluteLoader>
                          </ExploreRelativeDiv>
                        )}

                        {!exploreArticlesFetching && (
                          <BookmarksTab
                            setLocalContent={setLocalContent}
                            selectedTopic={popularTopicDetails.topicName}
                            contentlibRequest={contentlibRequest}
                            contentlibDeleteRequest={contentlibDeleteRequest}
                            contentLibArticles={contentLibArticles}
                            contentLibArticlesFetching={
                              contentLibArticlesFetching
                            }
                            themeNames={themeNames}
                            contentLibArticleDeleting={
                              contentLibArticleDeleting
                            }
                            isShareBlocked={
                              userData &&
                              userData.details &&
                              userData.details.user &&
                              userData.details.user.extra_settings &&
                              userData.details.user.extra_settings.share_content
                                ? userData.details.user.extra_settings
                                    .share_content !== 'true'
                                : true
                            }
                            isDeleteBlocked={isDeleteBlocked}
                            showTabLoader={showTabLoader}
                            setShowArticleLoader={setShowArticleLoader}
                            curatedTopicType={curatedTopicType}
                            exploreArticles={exploreArticles}
                            popularThemes={popularThemes}
                            userData={userData}
                            deleteArticleRequest={deleteArticleRequest}
                            onNewsletterArticleClick={onNewsletterArticleClick}
                            onRemoveFromNewletterClick={
                              onRemoveFromNewletterClick
                            }
                            newsletterArticleFetching={
                              newsletterArticleFetching
                            }
                            bookmarksFetching={bookmarksFetching}
                            exploreNewsletterId={exploreNewsletterId}
                            bookmarksId={bookmarksId}
                          />
                        )}
                      </StyledTabPanel>
                    )}

                  {selectedExploreTabIndex === 8 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      style={{ padding: '5px 5px' }}
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(get(exploreArticles, 'feed', [])) && (
                          <NoContentWrapper data-testid="no-content-message">
                            {emptyMessages.mioEditorTab}
                          </NoContentWrapper>
                        )}

                      {exploreArticlesFetching && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!exploreArticlesFetching && (
                        <BookmarksTab
                          setLocalContent={setLocalContent}
                          selectedTopic={popularTopicDetails.topicName}
                          contentlibRequest={contentlibRequest}
                          contentlibDeleteRequest={contentlibDeleteRequest}
                          contentLibArticles={contentLibArticles}
                          contentLibArticlesFetching={
                            contentLibArticlesFetching
                          }
                          themeNames={themeNames}
                          contentLibArticleDeleting={contentLibArticleDeleting}
                          isShareBlocked={
                            userData &&
                            userData.details &&
                            userData.details.user &&
                            userData.details.user.extra_settings &&
                            userData.details.user.extra_settings.share_content
                              ? userData.details.user.extra_settings
                                  .share_content !== 'true'
                              : true
                          }
                          isDeleteBlocked={isDeleteBlocked}
                          showTabLoader={showTabLoader}
                          setShowArticleLoader={setShowArticleLoader}
                          curatedTopicType={curatedTopicType}
                          exploreArticles={exploreArticles}
                          popularThemes={popularThemes}
                          userData={userData}
                          deleteArticleRequest={deleteArticleRequest}
                          onNewsletterArticleClick={onNewsletterArticleClick}
                          onRemoveFromNewletterClick={
                            onRemoveFromNewletterClick
                          }
                          bookmarksFetching={bookmarksFetching}
                          exploreNewsletterId={exploreNewsletterId}
                          bookmarksId={bookmarksId}
                        />
                      )}
                    </StyledTabPanel>
                  )}

                  {selectedExploreTabIndex === 4 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}
                      {!exploreArticlesFetching &&
                        isEmpty(get(investmentResults, 'feed')) && (
                          <NoContentWrapper>
                            <Flex
                              width={1}
                              justifyContent="center"
                              alignItems="center"
                              data-testid="no-content-message"
                            >
                              No articles found
                              {/* <Box>
                                <AddInvetmentsButton
                                  src="/assets/images/briefcase.png"
                                  alt="add invetments"
                                  onClick={() => history.push('/portfolio')}
                                />
                                <StyledHeading onClick={() => history.push('/portfolio')}>
                                  Add Your Investments
                                </StyledHeading>
                              </Box> */}
                            </Flex>
                          </NoContentWrapper>
                        )}

                      <LoaderWrapper isLoading={showTabLoader}>
                        {selectedTickers && selectedTickers.length > 0 && (
                          <InvestmentsTab
                            exploreArticleDeleting={exploreArticleDeleting}
                            selectedTopic={trendingTopicDetails.topicName}
                            exploreArticles={investmentResults}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            handleTickerChange={handleTickerChange}
                            tickerName={tickerName}
                            selectedTickers={selectedTickers}
                            exploreSetState={exploreSetState}
                            setShowArticleLoader={setShowArticleLoader}
                            holdingsType={holdingsType}
                            isFilteringStocksByTicker={
                              isFilteringStocksByTicker
                            }
                            isShareBlocked={
                              userData &&
                              userData.details &&
                              userData.details.user &&
                              userData.details.user.extra_settings &&
                              userData.details.user.extra_settings.share_content
                                ? userData.details.user.extra_settings
                                    .share_content !== 'true'
                                : true
                            }
                            popularThemes={popularThemes}
                            userData={userData}
                            onNewsletterArticleClick={onNewsletterArticleClick}
                            onRemoveFromNewletterClick={
                              onRemoveFromNewletterClick
                            }
                            newsletterArticleFetching={
                              newsletterArticleFetching
                            }
                            exploreNewsletterId={exploreNewsletterId}
                            bookmarksFetching={bookmarksFetching}
                            bookmarksId={bookmarksId}
                          />
                        )}
                      </LoaderWrapper>
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 1 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onRecentTabSelect} forceRenderTabPanel>
                  <TabList style={{ marginBottom: '10px' }}>
                    <DashboardAdminSubTab
                      className={`${
                        selectedRecentTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                      onClick={onRecentTabClick}
                      data-testid="all_posts_recent"
                    >
                      All posts
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      className={`${
                        selectedRecentTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      onClick={onRecentLastmonthTabClick}
                      data-testid='this_week_recent'
                    >
                      This Week
                    </DashboardAdminSubTab>
                  </TabList>
                  {selectedRecentTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.recentTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <div>
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isRecentArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <RecentSharedTab
                            selectedTopic={popularTopicDetails.topicName}
                            exploreArticles={exploreArticles}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            setShowArticleLoader={setShowArticleLoader}
                          />
                          {isExportPdfOpen.isRecentArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isRecentArticle}
                              setOpen={setIsExportPdfOpen}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              setShowArticleLoader={setShowArticleLoader}
                              currentPopUp={'isRecentArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedRecentTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.recentTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <div>
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isRecentArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <RecentSharedTab
                            selectedTopic={popularTopicDetails.topicName}
                            exploreArticles={exploreArticles}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            setShowArticleLoader={setShowArticleLoader}
                          />
                          {isExportPdfOpen.isRecentArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isRecentArticle}
                              setOpen={setIsExportPdfOpen}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              setShowArticleLoader={setShowArticleLoader}
                              currentPopUp={'isRecentArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 2 && (
              <StyledTabPanelTwo>
                <Tabs onSelect={onScheduledTabSelect} forceRenderTabPanel>
                  <TabList style={{ marginBottom: '10px' }}>
                    <DashboardAdminSubTab
                      className={`${
                        selectedScheduledTabIndex === 0 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledBySelfTabClick}
                      data-testid="my_scheduled_content"
                    >
                      My Scheduled Content
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      className={`${
                        selectedScheduledTabIndex === 1 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledByCompanyTabClick}
                    >
                      By Company
                    </DashboardAdminSubTab>
                    <DashboardAdminSubTab
                      className={`${
                        selectedScheduledTabIndex === 2 ? 'active' : 'inactive'
                      }`}
                      onClick={onScheduledTabClick}
                      data-testid="recommended_by_company"
                    >
                      Recommended by {company.name}
                    </DashboardAdminSubTab>
                  </TabList>
                  {selectedScheduledTabIndex === 2 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledByMioTab}
                          </NoContentWrapper>
                        )}
                      {exploreArticlesFetching && showArticleLoader && (
                        <ExploreRelativeDiv>
                          <AbsoluteLoader>
                            <Loader height="25px" />
                          </AbsoluteLoader>
                        </ExploreRelativeDiv>
                      )}

                      {!showTabLoader && (
                        <div>
                          <ExportPDFButton
                            onClick={() =>
                              exportPdfHandler('isSharedArticle', true)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              color="#6351ed"
                              size="1x"
                            />
                            <div style={{ marginLeft: '5px' }}>
                              Export To PDF
                            </div>
                          </ExportPDFButton>
                          <ScheduledTab
                            exploreArticleDeleting={exploreArticleDeleting}
                            selectedTopic={popularTopicDetails.topicName}
                            exploreArticles={exploreArticles}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            exploreArticleRequest={exploreArticleRequest}
                            setShowArticleLoader={setShowArticleLoader}
                            isShareBlocked
                            scheduleType={'recommendation_engine'}
                          />
                          {isExportPdfOpen.isSharedArticle && (
                            <ExportPDF
                              open={isExportPdfOpen.isSharedArticle}
                              setOpen={setIsExportPdfOpen}
                              exploreArticleDeleting={exploreArticleDeleting}
                              selectedTopic={popularTopicDetails.topicName}
                              deleteArticleRequest={onDeleteArticleClick}
                              themeNames={themeNames}
                              exploreArticleRequest={exploreArticleRequest}
                              setShowArticleLoader={setShowArticleLoader}
                              isShareBlocked
                              scheduleType={'recommendation_engine'}
                              currentPopUp={'isSharedArticle'}
                            />
                          )}
                        </div>
                      )}
                    </StyledTabPanel>
                  )}
                  {selectedScheduledTabIndex === 1 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledByCompanyTab}
                          </NoContentWrapper>
                        )}
                      <LoaderWrapper isLoading={showTabLoader}>
                        <ExportPDFButton
                          onClick={() =>
                            exportPdfHandler('isSharedArticle', true)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faDownload}
                            color="#6351ed"
                            size="1x"
                          />
                          <div style={{ marginLeft: '5px' }}>Export To PDF</div>
                        </ExportPDFButton>
                        <ScheduledTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleRequest={exploreArticleRequest}
                          setShowArticleLoader={setShowArticleLoader}
                          isShareBlocked
                          scheduleType={'admin'}
                        />
                        {isExportPdfOpen.isSharedArticle && (
                          <ExportPDF
                            open={isExportPdfOpen.isSharedArticle}
                            setOpen={setIsExportPdfOpen}
                            exploreArticleDeleting={exploreArticleDeleting}
                            selectedTopic={popularTopicDetails.topicName}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            exploreArticleRequest={exploreArticleRequest}
                            setShowArticleLoader={setShowArticleLoader}
                            isShareBlocked
                            scheduleType={'admin'}
                            currentPopUp={'isSharedArticle'}
                          />
                        )}
                      </LoaderWrapper>
                    </StyledTabPanel>
                  )}
                  {selectedScheduledTabIndex === 0 && (
                    <StyledTabPanel
                      type="admin"
                      className="react-tabs__tab-panel--selected"
                      padding="5px 5px"
                    >
                      {!exploreArticlesFetching &&
                        isEmpty(exploreArticleFeed) && (
                          <NoContentWrapper>
                            {emptyMessages.scheduledTab}
                          </NoContentWrapper>
                        )}

                      <LoaderWrapper isLoading={showTabLoader}>
                        <ExportPDFButton
                          onClick={() =>
                            exportPdfHandler('isSharedArticle', true)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faDownload}
                            color="#6351ed"
                            size="1x"
                          />
                          <div style={{ marginLeft: '5px' }}>Export To PDF</div>
                        </ExportPDFButton>
                        <ScheduledTab
                          exploreArticleDeleting={exploreArticleDeleting}
                          selectedTopic={popularTopicDetails.topicName}
                          exploreArticles={exploreArticles}
                          deleteArticleRequest={onDeleteArticleClick}
                          themeNames={themeNames}
                          exploreArticleRequest={exploreArticleRequest}
                          setShowArticleLoader={setShowArticleLoader}
                          isShareBlocked
                          scheduleType={'user'}
                        />
                        {isExportPdfOpen.isSharedArticle && (
                          <ExportPDF
                            open={isExportPdfOpen.isSharedArticle}
                            setOpen={setIsExportPdfOpen}
                            exploreArticleDeleting={exploreArticleDeleting}
                            selectedTopic={popularTopicDetails.topicName}
                            deleteArticleRequest={onDeleteArticleClick}
                            themeNames={themeNames}
                            exploreArticleRequest={exploreArticleRequest}
                            setShowArticleLoader={setShowArticleLoader}
                            isShareBlocked
                            scheduleType={'user'}
                            currentPopUp={'isSharedArticle'}
                          />
                        )}
                      </LoaderWrapper>
                    </StyledTabPanel>
                  )}
                </Tabs>
              </StyledTabPanelTwo>
            )}
            {selectedTabIndex === 3 && (
              <StyledTabPanelTwo>
                <Tabs forceRenderTabPanel>
                  <TabList style={{ marginBottom: '10px' }}>
                    {/* <DashboardAdminSubTab className={`${selectedContentTabIndex === 0 ? 'active' : null}`}>Your Content</DashboardAdminSubTab> */}
                    <DashboardAdminSubTab
                      style={{ border: 'none' }}
                      disabled={
                        userData.details.user.extra_settings.share_content !==
                        'true'
                      }
                    >
                      &nbsp;
                    </DashboardAdminSubTab>
                  </TabList>
                  <StyledTabPanel
                    className="react-tabs__tab-panel--selected"
                    padding="0px"
                    type="admin"
                  >
                    <AddContent
                      channelId={premiumAdvisorChannelId}
                      themesList={themesList}
                      setTabIndex={setTabIndex}
                      contentlibRequest={contentlibRequest}
                      contentLibArticles={contentLibArticles}
                      userData={userData}
                      setSelectedExploreTabIndex={setSelectedExploreTabIndex}
                      isEnterpriseAdvisor
                      exploreArticleRequest={exploreArticleRequest}
                      popularTopicDetails={popularTopicDetails}
                      setCuratedTopicType={setCuratedTopicType}
                      setWdigetTabIndex={setWdigetTabIndex}
                    />
                  </StyledTabPanel>
                </Tabs>
              </StyledTabPanelTwo>
            )}
          </BorderWhiteBox>
        </Tabs>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  const subscriptionType = get(
    state,
    'user.data.details.user.billing.subscription_type'
  );
  const advisor_subscription =
    subscriptionType === 'platform_sponsored' ||
    subscriptionType === 'asset_manager_sponsored';
  const user_role = getUserRole(state);
  const isComplianceAdmin =
    get(ownProps, 'roleType') === ROLE_TYPES.ADMIN_COMPLIANCE;
  const isShareBlocked = user_role === 'general' && advisor_subscription;
  // console.log('advisor_subscription: ', advisor_subscription);
  // console.log('isShareBlocked:', isShareBlocked);
  const share_content =
    get(state, 'user.data.details.user.extra_settings.share_content') ===
    'true';
  const isEnterpriseLevelAdmin = get(state, 'user.data.details.company.enterprise', 'false') === 'true';
  const isFirmLevelAdmin = get(state, 'user.data.details.company.firm', 'false') === 'true';
  const firmSettings = getFirmSettings({ isEnterpriseLevelAdmin, isFirmLevelAdmin, state });
  console.log('FIRM SETTINGS - - -- -', firmSettings);
  
  return {
    groupsList: state.advisorGroups.data,
    exploreArticles: state.explore.data,
    contentLibArticles: state.contentlib.data,
    contentLibArticlesFetching: state.contentlib.isFetching,
    contentLibArticleDeleting: state.contentlib.isDeleting,
    themesList: state.theme.data || [],
    channelData: get(state, 'channel.data', []),
    exploreArticlesFetching: state.explore.isFetching,
    searchResults: get(state, 'explore.search', {}),
    investmentResults: get(state, 'explore.investments', {}),
    isShareBlocked: isShareBlocked,
    isDeleteBlocked: isShareBlocked,
    themeNames: get(state, 'theme.themeNames', {}),
    exploreArticleDeleting: state.explore.isDeleting,
    myMioData: get(state, 'explore.mymio', {}),
    selectedTickers: state.explore.selectedTickers,
    searchFilters: state.explore.searchFilters,
    isInvEmpty: state.explore.isInvEmpty,
    tickerName: state.explore.ticker,
    yourTopics: state.theme.yourTopics,
    isAdmin: state.user.data.details.user.role,
    userData: state.user.data,
    currentPortfolio: state.explore.currentPortfolio,
    holdingsType: state.explore.holdingsType,
    isComplianceAdmin: isComplianceAdmin,
    exploreArticleError: state.explore.error,
    channelId: getChannelId(state),
    isChangeTheme: state.explore.isChangeTheme,
    newsletterArticleFetching: state.explore.isNewsletterFetching,
    bookmarksFetching: state.explore.isBookmarksFetching,
    contentLibNewsletterFetching: state.contentlib.isNewsletterFetching,
    exploreNewsletterId: state.explore.newsletterId,
    bookmarksId: state.explore.bookmarkArticleId,
    contentLibBookmarksFetching: state.contentlib.isBookmarksFetching,
    contentLibBookmarksId: state.contentlib.bookmarkArticleId,
    contentLibBucketId: get(state, 'contentlib.bucketId', ''),
    contentLibInNewsletter: get(state, 'contentlib.inNewsletter', null),
    contentLibBookMarkId: get(state, 'contentlib.bookmark_article_id', ''),
    contentLibIsBookmark: get(state, 'contentlib.isBookMark', null),
    contentLibATSQueue: get(state, 'contentlib.pendingArticles', []),
    contentLibFilter: get(state, 'contentlib.filter', 'all'),
    shareContent: share_content,
    firmSettings: firmSettings
  };
};

const mapDispatchToProps = {
  exploreArticleRequest: exploreActions.api.explore.request,
  advisorGroupsRequest: advisorGroupsActions.api.groups.request,
  deleteArticleRequest: exploreActions.api.explore.delete,
  inviteRequest: inviteActions.api.invite.request,
  contentlibRequest: contentlibActions.api.contentlib.request,
  contentlibDeleteRequest: contentlibActions.api.contentlib.delete,
  exploreSetState: exploreActions.api.explore.setstate,
  themeSetState: themeActions.api.theme.setstate,
  contentlibSetState: contentlibActions.api.contentlib.setstate,
  shareArticleSetState: shareActions.api.share.setstate,
  channelSetState: channelActions.api.channel.setstate,
  loginRequest: loginActions.api.login.request,
  addToBucketRequest: exploreMoreActions.custom.explore.addToNewsletter,
  removeFromNewsletterRequest:
    exploreMoreActions.custom.explore.removeFromNewsletter,
  addToBookmarksRequest: exploreMoreActions.custom.explore.addToBookmarks,
  removeFromBookmarksRequest:
    exploreMoreActions.custom.explore.removeFromBookmarks,
  contentLibAddToBucketRequest:
    contentlibMoreActions.custom.contentlib.addToNewsletter,
  contentLibRemoveFromBucketRequest:
    contentlibMoreActions.custom.contentlib.removeFromNewsletter,
  contentLibAddToBookmarksRequest:
    contentlibMoreActions.custom.contentlib.addToBookmarks,
  contentLibRemoveFromBookmarksRequest:
    contentlibMoreActions.custom.contentlib.removeFromBookmarks,
  bucketCountRequest:
    newsletterMoreActions.custom.newsletter.bucketCountRequest,
};

EnterpriseAdvisorDashboardContainer.defaultProps = {
  groupsList: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterpriseAdvisorDashboardContainer);
