import { Box, Flex } from '@rebass/grid';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { AUTOMATED_CAMPAIGN_ACTIONS } from '..';
import { LineBreaker, StyledTextInput } from '../styles';
import ReactSelect from 'react-select';
import StepsFooter from './StepsFooter';
import { AutomatedCampaignFormWrapper } from '../styles';
import {
  createAutomatedCampaign,
  getTargetSegments,
  updateAutomatedCampaign,
} from '../../../services/automated-campaigns';
import { getLandingPages } from '../../../services/bee-editor';
import { DripIconButton, Loader, MioDatePicker } from '../../../components';
import moment from 'moment';
import { get } from 'lodash';
import styled from 'styled-components';
import StyledTooltip from '../../../components/Tooltip';
import { useHistory } from 'react-router-dom';

const DateTimeWrapper = styled(Flex)`
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  margin: 0 auto;
  padding: 2px 8px;
`;

const DateTimeText = styled.div`
  width: 85%;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getSelectStyles = () => ({
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    height: '32px',
    minHeight: '32px',
    border: '1px solid #8493A6',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#848484',
  }),
});

const NEWSLETTER_FREQUENCIES = [
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
];

const SelectTarget = ({
  automatedCampaignDetails,
  targetInfo,
  campaignSetState,
  campaignAction,
  currentStepIndex,
  setCurrentStepIndex,
  userData,
  userRole,
  isEditMode,
}) => {
  // Component Utils
  console.log('TARGET INFO - - - - ', targetInfo);
  const history = useHistory();
  const isEventType = campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT;
  const isProspectType =
    campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NURTURE_PROSPECTS;
  const isClientType =
    campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.WELCOME_CLIENTS;
  const isNewsletterType =
    campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NEWSLETTER;

  // States
  const [ccList, setCCList] = useState('');
  const [campaignSeriesName, setCampaignSeriesName] = useState('');
  const [targetSegment, setTargetSegment] = useState(null);
  const [targetPage, setTargetPage] = useState(null);
  const [campaignPurpose, setCampaignPurpose] = useState('');
  const [newsletterFrequency, setNewsletterFrequency] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [isCascaded, setIsCascaded] = useState(false);
  const [convertedTag, setConvertedTag] = useState(
    isEventType ? `registered_${campaignSeriesName}` : 'lead_captured'
  );
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [targetSegmentOptions, setTargetSegmentOptions] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [targetPageOptions, setTargetPageOptions] = useState([
    {
      label: '',
      value: '',
    },
  ]);

  const [requestLoading, setRequestLoading] = useState(false);
  const subscriptionTypeOptions = [
    {
      value: 'general_marketing',
      label: 'General Marketing',
    },
    {
      label: 'General Newsletters',
      value: 'general_newsletters',
    },
    {
      label: 'Ecards',
      value: 'ecards',
    },
    {
      label: 'Events',
      value: 'events',
    },
    {
      label: 'Firm Updates',
      value: 'firm_updates',
    },
    {
      label: 'Financial and Economic Newsletters',
      value: 'financial_and_economic_newsletters',
    }
  ];

  const mappingDropdownValues = {
    general_newsletters: 'General Newsletters',
    ecards: 'Ecards',
    events: 'Events',
    firm_updates: 'Firm Updates',
    financial_and_economic_newsletters: 'Financial and Economic Newsletters',
    general_marketing: 'General Marketing'
  }

  // const [selectSubscriptionType, setSelectSubscriptionType] = useState(
  //   isEventType ? {
  //     label: 'Events',
  //     value: 'events',
  //   } : {
  //     label: 'General Marketing',
  //     value: 'general_marketing',
  //   },
  // );

  const convertDateToRequiredFormat = ({ date, format = 'YYYY-MM-DD' }) =>

    moment(date).format(format);

  const getNewOptionValue = (value) =>
    value ? value : { label: '', value: '' };

  const shouldDisableNext = () =>
    !campaignSeriesName ||
    !targetSegment ||
    !campaignPurpose ||
    (!isEventType && !startDate) ||
    (isNewsletterType && !newsletterFrequency) ||
    (isEventType && !targetPage) ||
    (isProspectType && !targetPage) ||
    (isEventType && !eventDate);

  const getCampaignInfo = () => {
    if (isEventType)
      return {
        name: 'Event Promotion',
        purpose: 'Promote and invite contacts to your event',
        targetPagePlaceholder: 'Choose a campaign page for this event',
      };
    if (isProspectType)
      return {
        name: 'Nurture Prospects',
        purpose: 'Engage new prospects.',
        targetPagePlaceholder: 'My Campaign Page for Prospects',
      };
    if (isClientType)
      return {
        name: 'Welcome New Clients',
        purpose: 'Send a series of communications to new clients.',
        targetPagePlaceholder: 'Choose a campaign page',
      };
    if (isNewsletterType)
      return {
        name: 'Newsletter Series',
        purpose: 'Send newsletters to contacts',
        targetPagePlaceholder: 'Choose a frequency',
      };

    return { name: '', purpose: '' };
  };

  const fetchLandingPages = async () => {
    setRequestLoading(true);
    try {
      const { data = [] } = (await getLandingPages('campaign')) || {};
      campaignSetState({ landingPages: data })
      setTargetPageOptions(
        data.map((page) => ({
          label: page.name,
          value: page.id || page._id,
        }))
      );
    } catch (error) {
      console.log('Error fetching Landing pages ', error);
    } finally {
      setRequestLoading(false);
    }
  };

  const fetchTargetSegments = async () => {
    setRequestLoading(true);
    try {
      const {
        result: { data },
      } = await getTargetSegments();
      campaignSetState({ segmentList: data })
      setTargetSegmentOptions(
        data.map((page) => ({
          label: page.name,
          value: page.id,
        }))
      );
    } catch (error) {
      console.log('Error fetching target segments ', error);
    } finally {
      setRequestLoading(false);
    }
  };

  const prefillTargetPage = ({ target_page }) => {
    setRequestLoading(true);
    if (!target_page.name && targetPageOptions) {
      // Get the name from Existing Landing Pages fetched from API.
      console.log({ targetPageOptions });
      const filteredName =
        targetPageOptions.find((page) => page.value === target_page.id)
          ?.label || '';
      console.log({ filteredName });
      setTargetPage(
        getNewOptionValue({
          label: filteredName,
          value: target_page.id,
        })
      );
    } else {
      setTargetPage(
        getNewOptionValue({
          label: target_page.name,
          value: target_page.id,
        })
      );
    }
    setRequestLoading(false);
  };

  const brewTargetRequestBody = () => ({
    type: campaignAction,
    name: campaignSeriesName,
    purpose: campaignPurpose,
    target_page_id: (targetPage && targetPage.value) || '', // value -> ID. TODO: Find better way.
    segment_id: targetSegment.value, // value -> ID. TODO: Find better way.
    cc_email_addresses: ccList,
    converted_tag: convertedTag && targetPage ? convertedTag : '',
    event_date: eventDate
      ? convertDateToRequiredFormat({ date: eventDate })
      : null,
    start_date: startDate
      ? convertDateToRequiredFormat({ date: startDate })
      : null,
    is_cascaded: get(targetInfo, 'is_cascaded', 'false'),
    is_global_compliance: get(targetInfo, 'is_global_compliance', 'false'),
    // subscription_type: selectSubscriptionType.value

    // ...(isNewsletterType ? { newsletterFrequency } : {}),
    // ...(isEventType ? { event_date: eventDate } : { start_date: startDate }),
  });

  const submitTargetInfo = async ({ forSaveAndClose = false }) => {
    setRequestLoading(true);
    try {
      let response;
      if (targetInfo) {
        // Update API
        response = await updateAutomatedCampaign({
          campaignId: automatedCampaignDetails.campaignId,
          payload: brewTargetRequestBody(),
        });
      } else {
        // Create API
        response = await createAutomatedCampaign({
          payload: brewTargetRequestBody(),
        });
      }
      campaignSetState({
        automatedCampaigns: {
          ...automatedCampaignDetails,
          campaignId: response.data.ac_campaign_id,
          targetInfo: brewTargetRequestBody(),
        },
      });
      if (!forSaveAndClose) setCurrentStepIndex(currentStepIndex + 1);
    } catch (error) {
      console.error(
        'Something went wrong while creating a campaign for you. ',
        error
      );
    } finally {
      setRequestLoading(false);
    }
  };

  useEffect(() => {
    fetchLandingPages();
    if (!targetInfo) {
      fetchTargetSegments();
      const { name, purpose } = getCampaignInfo();
      setCampaignSeriesName(name);
      setCampaignPurpose(purpose);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEventType && campaignSeriesName && !targetInfo)
      setConvertedTag(`registered_${campaignSeriesName}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignSeriesName]);

  useEffect(() => {
    if (targetInfo && targetInfo?.target_page) {
      prefillTargetPage({ target_page: targetInfo.target_page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetPageOptions, targetInfo]);

  useEffect(() => {
    if (targetInfo) {
      // fetchLandingPages();
      const {
        name,
        purpose,
        segment,
        target_page,
        start_date,
        event_date,
        cc_email_addresses: ccList,
        converted_tag,
        // subscription_type
      } = targetInfo || {};
      setCampaignSeriesName(name);
      setCampaignPurpose(purpose);

      if (segment) {
        setTargetSegment(
          getNewOptionValue({ label: segment.name, value: segment.id })
        );
      }
      if (target_page) {
        prefillTargetPage({ target_page });
      }

      // if(subscription_type) {
      //   setSelectSubscriptionType({
      //     value: subscription_type,
      //     label: mappingDropdownValues[subscription_type]
      //   })
      // }

      if (start_date) setStartDate(start_date);
      if (event_date) setEventDate(event_date);
      if (converted_tag) setConvertedTag(converted_tag);
      if (ccList) setCCList(ccList);
      setIsCascaded(get(targetInfo, 'is_cascaded', 'false') === 'true');
      // setIsCascaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetInfo]);

  return !requestLoading ? (
    <Box>
      <AutomatedCampaignFormWrapper>
        <Flex width={'100%'}>
          <Box width="35%" fontSize="14px" mr="15px">
            <Flex className="field__title" width="100%" alignItems={'center'}>
              <span> Campaign Name</span>
              <StyledTooltip
                text={
                  'This campaign will be sent to the selected segment. Any time a contact is added to that segment, they will start this automated campaign.'
                }
              />
            </Flex>
            <Box width="100%" pt="8px">
              <StyledTextInput
                maxLength="100"
                placeholder="Campaign name"
                value={campaignSeriesName}
                onChange={(e) => setCampaignSeriesName(e.target.value)}
                name="campaign_series_name"
              />
            </Box>
          </Box>
          <Box width="65%" fontSize="14px">
            <Box width="100%" className="field__title">
              Purpose
            </Box>
            <Box width="100%" pt="8px">
              <StyledTextInput
                maxLength="1000"
                placeholder="Campaign Purpose"
                value={campaignPurpose}
                onChange={(e) => setCampaignPurpose(e.target.value)}
              />
            </Box>
          </Box>
        </Flex>
        <LineBreaker />
        <Flex
          fontSize="14px"
          justifyContent={
            campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.EVENT
              ? 'space-between'
              : 'flex-start'
          }
          alignItems="center"
        >
          {/* <Box width={'49%'}> */}
          <Box width={'35%'}>
            <Flex
              width="100%"
              className="field__title"
              justifyContent={'space-between'}
            >
              <span> Target Segment</span>
              <span
                onClick={() => {
                  window.sessionStorage.setItem('selectedIndex', 2);
                  history.push(
                    userRole === 'admin ' ? '/admin/configure' : '/configure'
                  );
                }}
                style={{ color: '#6351ed', cursor: 'pointer' }}
              >
                Create Target Segment
              </span>
            </Flex>
            <Box width="100%" pt="8px">
              <ReactSelect
                onChange={(newValue) => {
                  console.log({ newValue });
                  setTargetSegment(getNewOptionValue(newValue));
                }}
                options={targetSegmentOptions}
                placeholder="Choose a segment"
                styles={getSelectStyles()}
                value={targetSegment}
              />
            </Box>
          </Box>
          {campaignAction !== AUTOMATED_CAMPAIGN_ACTIONS.EVENT && (
            <Box width={'25%'} marginLeft="15px">
              {/* <Box width={'49%'}> */}
              <Box width={'100%'} className="field__title">
                <Box>Start Date</Box>
                <Box
                  width="100%"
                  mt="8px"
                  style={{
                    border: '1px solid #8493A6',
                    borderRadius: '4px',
                  }}
                >
                  {/* <input
                    type="date"
                    className="date__input"
                    min={new Date().toISOString().substring(0, 10)} // Disable Past Dates.
                    onChange={({ target: { value } }) => setStartDate(value)}
                  /> */}
                  <DateTimeWrapper>
                    <DateTimeText>
                      {startDate &&
                        convertDateToRequiredFormat({
                          date: startDate,
                          format: 'MM/DD/YYYY',
                        })}
                    </DateTimeText>
                    <DripIconButton
                      onClick={() => setIsDatepickerOpen(true)}
                      icon="clock"
                      color="#8493A6"
                      styles={{ marginTop: '5px' }}
                      forAutomatedCampaigns={true}
                    />
                    <MioDatePicker
                      isOpen={isDatepickerOpen}
                      customInput={<div />}
                      minDate={new Date()}
                      onDateClose={() => setIsDatepickerOpen(false)}
                      onSubmit={(date) => {
                        setStartDate(date);
                        setIsDatepickerOpen(false);
                      }}
                      timeZone={get(userData, 'details.user.timezone', '')}
                      scheduleValue={startDate}
                      isFromCampaign
                    />
                  </DateTimeWrapper>
                </Box>
              </Box>
            </Box>
          )}
        </Flex>
        <br />

        <Flex
          fontSize="14px"
          justifyContent={
            AUTOMATED_CAMPAIGN_ACTIONS.EVENT ? 'flex-start' : 'space-between'
          }
          alignItems="center"
        >
          {campaignAction === AUTOMATED_CAMPAIGN_ACTIONS.NEWSLETTER ? (
            <Box width="35%">
              {/* <Box width="49%"> */}
              <Box className="field__title">Newsletter Frequency</Box>
              <Box width="100%" pt="8px">
                <ReactSelect
                  onChange={(newValue) => {
                    console.log('NEW VALUE - - -- - ', newValue);
                    setNewsletterFrequency(getNewOptionValue(newValue));
                  }}
                  options={NEWSLETTER_FREQUENCIES}
                  placeholder={'Choose a frequency'}
                  styles={getSelectStyles()}
                  value={newsletterFrequency}
                />
              </Box>
            </Box>
          ) : (
            <Box width={'35%'}>
              <Flex
                width="100%"
                className="field__title"
                justifyContent={'space-between'}
              >
                <span>
                  {isEventType
                    ? 'Event Page'
                    : isProspectType
                    ? 'Target Page'
                    : 'Target Page (Optional)'}
                </span>
                {!isCascaded && (
                  <span
                    onClick={() => history.push('/campaign/landing-pages')}
                    style={{ color: '#6351ed', cursor: 'pointer' }}
                  >
                    Create {isEventType ? 'Event Page' : 'Target Page'}
                  </span>
                )}
              </Flex>
              <Box width="100%" pt="8px">
                <ReactSelect
                  onChange={(newValue) => {
                    console.log('NEW VALUE - - -- - ', newValue);
                    setTargetPage(getNewOptionValue(newValue));
                  }}
                  options={targetPageOptions}
                  placeholder={getCampaignInfo().targetPagePlaceholder}
                  styles={getSelectStyles()}
                  value={targetPage}
                  isDisabled={isCascaded}
                />
              </Box>
            </Box>
          )}

          {isEventType && (
            <Box width={'25%'} marginLeft="15px">
              <Box width={'100%'} className="field__title">
                <Box>Event Date</Box>
                <Box
                  width="100%"
                  mt="8px"
                  style={{
                    border: '1px solid #8493A6',
                    borderRadius: '4px',
                  }}
                >
                  {/* <input
                    type="date"
                    className="date__input"
                    min={new Date().toISOString().substring(0, 10)} // Disable Past Dates.
                    onChange={({ target: { value } }) => setEventDate(value)}
                  /> */}
                  <DateTimeWrapper>
                    <DateTimeText>
                      {eventDate &&
                        convertDateToRequiredFormat({
                          date: eventDate,
                          format: 'MM/DD/YYYY',
                        })}
                    </DateTimeText>
                    <DripIconButton
                      onClick={() => setIsDatepickerOpen(true)}
                      icon="clock"
                      color="#8493A6"
                      styles={{ marginTop: '5px' }}
                      forAutomatedCampaigns={true}
                    />
                    <MioDatePicker
                      isOpen={isDatepickerOpen}
                      customInput={<div />}
                      minDate={new Date()}
                      onDateClose={() => setIsDatepickerOpen(false)}
                      onSubmit={(date) => {
                        setEventDate(moment(date).format('YYYY/MM/DD'));
                        setIsDatepickerOpen(false);
                      }}
                      timeZone={get(userData, 'details.user.timezone', '')}
                      scheduleValue={eventDate}
                      isFromCampaign
                    />
                  </DateTimeWrapper>
                </Box>
              </Box>
            </Box>
          )}
        </Flex>
        {console.log(
          'IS EVENT TYPE. - - ',
          isEventType,
          'IS PROSPECTS TYPE - -- - - ',
          isProspectType
        )}
        {(isEventType || isProspectType) && (
          <Box width="35%" fontSize="14px" mt="20px">
            <Flex className="field__title" width="100%" alignItems={'center'}>
              <span>
                {isEventType ? 'Event Registration Tag' : 'Lead Capture Tag'}
              </span>
              <StyledTooltip
                text={
                  isEventType
                    ? 'This tag will be applied to contacts who fill out the event registration form on the Event Page.'
                    : 'This tag will be applied to contacts who fill out the lead capture form on the Target Page.'
                }
              />
            </Flex>
            <Box width="100%" pt="8px">
              <StyledTextInput
                maxLength="100"
                placeholder="Campaign series name"
                value={convertedTag}
                onChange={({ target: { value } }) => setConvertedTag(value)}
                name="converted_tag"
                disabled={!targetPage || isCascaded}
              />
            </Box>
          </Box>
        )}

        <LineBreaker />

        {/* <Flex
          flexDirection="row"
          display="flex"
          width="100%"
          fontSize="14px"
          color="#000000"
          mr="15px"
          style={{ position: 'relative', paddingTop: '10px', paddingLeft: '10px' }}
        >
          <Flex width="20%" style={{ fontWeight: '600', fontSize: '14px' }}>
            Subscription Type*
          </Flex>
          <Box width="40%" pt="8px" mb="10px" marginTop="-15px">
            <ReactSelect
              menuPortalTarget={document.body}
              menuPosition='fixed'
              onChange={(newValue) => {
                console.log("new value-", newValue);
                setSelectSubscriptionType(getNewOptionValue(newValue));

              }}
              options={subscriptionTypeOptions}
              placeholder={'Select a type'}
              styles={getSelectStyles()}
              value={isEventType ? subscriptionTypeOptions[3] : selectSubscriptionType}
              isDisabled={isEventType}
            />
            {console.log("value-", subscriptionTypeOptions)};
          </Box>
          
        </Flex> */}
        {/* <Flex
          width="100%"
          fontSize="14px"
          color="#667085"
          mr="15px"
          style={{ position: 'relative', paddingLeft: '10px', marginTop: "-20px" }}
        >
          Note: Select the correct subscription type to ensure a successful campaign
        </Flex> */}

        {/* CC */}
        {/* <Box fontSize="14px">
          <Box width="100%" className="field__title">
            CC (Optional)
          </Box>
          <Box width="100%" pt="8px">
            <StyledTextInput
              placeholder="Type in email address(es) to be cc'ed, separated by commas"
              value={ccList}
              onChange={(e) => setCCList(e.target.value)}
            />
          </Box>
        </Box> */}
      </AutomatedCampaignFormWrapper>

      <StepsFooter
        requestLoading={requestLoading}
        isEditMode={isEditMode}
        currentStepIndex={currentStepIndex}
        setCurrentStepIndex={setCurrentStepIndex}
        handleNext={() => submitTargetInfo({ forSaveAndClose: false })}
        disableNext={shouldDisableNext()}
        disableSaveAndClose={shouldDisableNext()}
        campaignSetState={campaignSetState}
        handleSaveAndClose={async () => {
          await submitTargetInfo({ forSaveAndClose: true });
          campaignSetState({automatedDashboard : true});
          history.push('/campaignV2');

        }}
      />
    </Box>
  ) : (
    <AutomatedCampaignFormWrapper>
      <Loader />
    </AutomatedCampaignFormWrapper>
  );
};

export default SelectTarget;
