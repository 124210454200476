import React, { useState, useEffect, useRef } from 'react';
import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Input,
  Button,
  MioDropdown,
} from '../..';
import './CreateSegmentModal.css';
import { LoaderWrapper } from '../../Loader';
import { ModalV2 } from '../../Modal';
import { ButtonNoBorderBackground, ErrorContainer } from '../../CommonStyles';
import { Flex } from '@rebass/grid';
import { createSegment, listAllTags } from '../../../services/segment';
import { objectToFormData } from '../../../services/helpers';
import { get, isEmpty } from 'lodash';

const CreateSegmentModal = ({ isModalOpen, setModalOpen, getSegmentList }) => {
  const [loader, setLoader] = useState(false);
  const [tagLoader, setTagLoader] = useState(false);
  const dropdownMenuRef = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [segmentName, setSegmentName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [searchResultsArr, setSearchResultsArr] = useState([]);

  const [selectedArr, setSelectedArr] = useState([]);

  const [includeRule, setIncludeRule] = useState('any');
  const [includeArr, setIncludeArr] = useState([]);

  const [excludeRule, setExcludeRule] = useState('any');
  const [excludeArr, setExcludeArr] = useState([]);

  const [segmentErrorVisible, setSegmentErrorVisible] = useState(false);
  const [segmentErrorMessage, setSegmentErrorMessage] = useState('');

  const [tagsArr, setTagsArr] = useState([]);

  const submitHandler = () => {
    setLoader(true);
    setSegmentErrorMessage('');
    setSegmentErrorVisible(false);

    var includeAnyArr = [],
      includeAllArr = [],
      excludeAnyArr = [],
      excludeAllArr = [];

    if (includeRule === 'any') includeAnyArr = includeArr;
    else includeAllArr = includeArr;

    if (excludeRule === 'any') excludeAnyArr = excludeArr;
    else excludeAllArr = excludeArr;

    const payload = {
      name: segmentName,
      search_rule: JSON.stringify({
        include: {
          tags: {
            any: includeAnyArr,
            all: includeAllArr,
          },
        },
        exclude: {
          tags: {
            any: excludeAnyArr,
            all: excludeAllArr,
          },
        },
      }),
    };

    createSegment(objectToFormData(payload))
      .then((response) => {
        setLoader(false);
        if (!response.result.success) {
          console.log('ERROR RESPONSE - - - -- ', response);
          setSegmentErrorVisible(true);
          setSegmentErrorMessage(response.result.error);
        } else {
          getSegmentList();
          setModalOpen(false);
        }
      })
      .catch((error) => {
        console.log(
          '*********** ERROR CREATING SEGMENT ********',
          error.response
        );
        setLoader(false);
        setSegmentErrorVisible(true);
        setSegmentErrorMessage(
          get(
            error,
            'response.data.result.error.message',
            'Error Creating Segment...Please try again later'
          )
        );
        // setTimeout(() => setSegmentErrorVisible(false), 5000);
      });
  };

  const handleClickOutside = (e) => {
    if (
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(e.target)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkValidation = () => {
    var isValid = false;
    if (segmentName !== '' && includeArr.length > 0) {
      isValid = true;
    } else {
      isValid = false;
    }
    return !isValid;
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearchQuery(debouncedSearchQuery), 1000);
    return () => clearTimeout(timer);
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (!loader) {
      setTagLoader(true);
      listAllTags()
        .then((response) => {
          setTagsArr(response.result.data);
          setTagLoader(false);
          console.log(response.result.data);
        })
        .catch((err) => {
          setTagLoader(false);
        });
    }
  }, [loader]);

  useEffect(() => {
    setSearchResultsArr([]);
    if (searchQuery !== '' && !isEmpty(tagsArr)) {
      setSearchResultsArr(
        tagsArr
          .filter((el) => el.toUpperCase().includes(searchQuery.toUpperCase()))
          .filter((el) => !selectedArr.includes(el))
          .filter((el) => !includeArr.includes(el))
          .filter((el) => !excludeArr.includes(el))
      );
    }
  }, [selectedArr, searchQuery, includeArr, excludeArr, tagsArr]);

  const addToArray = (element, arr, setArr) => {
    setArr([...arr, element].flat());
  };

  const removeFromArray = (element, arr, setArr) => {
    const newArr = arr.filter((item) => item !== element);
    setArr(newArr);
  };

  return (
    <ModalV2
      onRequestClose={() => setModalOpen(false)}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      bgColor="white"
      width="75%"
    >
      <ModalHeader>Create Segment</ModalHeader>
      <ModalCloseButton onClick={() => setModalOpen(false)} />
      <ModalBody>
        {/* Segment Name */}
        <div
          className="list-name"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
          }}
        >
          <Input
            id="new-list-name"
            largeInput={true}
            placeholder="Enter Segment Name"
            value={segmentName}
            onChange={(event) => setSegmentName(event.target.value)}
            width="400px"
          />
        </div>

        <div className="flex-container" style={{ justifyContent: 'left' }}>
          <h2 style={{ marginLeft: '1%' }}>
            Select and Search from Custom and Clout-automated Tags
          </h2>
        </div>

        {/* Search Bar */}
        <div style={{ position: 'relative' }} ref={dropdownMenuRef}>
          {/* <MioDropdown
            optionsWidth="96%"
            left="0"
            customStyle={{'maxHeight' : '285px' , 'overflow' : 'auto'}}
            dropdownMenu={
              <ul>
                {searchResultsArr.map((element) => (
                  <li
                    style={{
                      color: 'black',
                      border: 'black',
                      backgroundColor: 'white',
                      width: '100%',
                      marginRight: '50%',
                      textAlign: 'left',
                    }}
                    key={element}
                    onClick={() => {
                      addToArray(element, selectedArr, setSelectedArr);
                      removeFromArray(
                        element,
                        searchResultsArr,
                        setSearchResultsArr
                      );
                    }}
                  >
                    {element}
                  </li>
                ))}
              </ul>
            }
          >
            <Input
              largeInput={true}
              placeholder="Start typing your search tag"
              value={debouncedSearchQuery}
              onChange={(event) => setDebouncedSearchQuery(event.target.value)}
              width="96%"
              disabled={tagLoader}
            />
          </MioDropdown> */}

          <div
            style={dropdownOpen ? { display: 'block' } : { display: 'none' }}
            className="search-bar-dropdown"
          >
            <ul>
              {searchResultsArr.map((element) => (
                <li
                  key={element}
                  onClick={() => {
                    addToArray(element, selectedArr, setSelectedArr);
                    removeFromArray(
                      element,
                      searchResultsArr,
                      setSearchResultsArr
                    );
                    setDropdownOpen(false);
                  }}
                >
                  {element}
                </li>
              ))}
            </ul>
          </div>
          <Input
            largeInput={true}
            placeholder="Start typing your search tag"
            value={debouncedSearchQuery}
            onFocus={() => {
              setDropdownOpen(true);
            }}
            onChange={(event) => setDebouncedSearchQuery(event.target.value)}
            width="96%"
            disabled={tagLoader}
          />
        </div>

        {tagLoader && (
          <ErrorContainer align="center">Fetching Tags....</ErrorContainer>
        )}

        {/* Selected Row */}
        <div style={{ marginBottom: '3.5%', marginRight: '3%', display: 'flex',  }}>
          <h3 style={{ textAlign: 'left', width: '119px', }}>Selected Tags</h3>
          <div style={{ width: '60%', float: 'left', marginTop: '10px', }}>
            {selectedArr.length !== 0 ? (
              selectedArr.map((tagElement) => (
                <ButtonNoBorderBackground
                  background="#ececec"
                  margin="1% 2%"
                  padding="1%"
                  key={tagElement}
                >
                  {tagElement}
                  <img
                    alt=""
                    src="/assets/images/emailList-delete.svg"
                    style={{ marginLeft: '8px' }}
                    onClick={() =>
                      removeFromArray(tagElement, selectedArr, setSelectedArr)
                    }
                  />
                </ButtonNoBorderBackground>
              ))
            ) : (
              <p style={{ padding: '0' }}>No tags have been selected Yet</p>
            )}
          </div>
          <div style={{ width: '40%', float: 'right', marginTop: '10px', }}>
            <Flex
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                margin="0 10px 0 10px"
                padding="1% 20px"
                borderRadius="5px"
                bgColor={selectedArr.length === 0 && '#dad6ff'}
                borderColor={selectedArr.length === 0 && '#dad6ff'}
                cursor={selectedArr.length === 0 && 'not-allowed'}
                onClick={() => {
                  addToArray(selectedArr, includeArr, setIncludeArr);
                  setSelectedArr([]);
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <img
                    src="/assets/images/drip/done_white.svg"
                    alt=""
                    style={{ marginRight: '8px' }}
                  />
                  <span>Include</span>
                </div>
              </Button>

              <Button
                margin="0 10px 0 10px"
                borderRadius="5px"
                bgColor={selectedArr.length === 0 ? '#eee' : '#fff'}
                borderColor={selectedArr.length === 0 ? '#eee' : '#708892'}
                cursor={selectedArr.length === 0 && 'not-allowed'}
                color="#708892"
                padding="1% 20px"
                onClick={() => {
                  addToArray(selectedArr, excludeArr, setExcludeArr);
                  setSelectedArr([]);
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span style={{ marginRight: '8px' }}>Exclude</span>
                  <img src="/assets/images/drip/close_grey.svg" alt="" />
                </div>
              </Button>
            </Flex>
          </div>
        </div>

        {/* Include Row */}
        <div style={{ marginBottom: '3.5%', display: 'flex',  }}>
          <h3 style={{ textAlign: 'left', width: '131px', }}>Include Tags</h3>
          <div style={{ width: '84%', }}>
            <ul style={{ listStyle: 'none' }}>
              <li style={{ width: '100%' }}>
                <div style={{ width: '50%', float: 'left', marginRight: '50px' }}>
                  {includeArr.length !== 0 ? (
                    includeArr.map((tagElement) => (
                      <ButtonNoBorderBackground
                        background="#ececec"
                        margin="1% 2%"
                        padding="1%"
                        key={tagElement}
                      >
                        {tagElement}
                        <img
                          alt=""
                          src="/assets/images/emailList-delete.svg"
                          style={{ marginLeft: '8px' }}
                          onClick={() =>
                            removeFromArray(
                              tagElement,
                              includeArr,
                              setIncludeArr
                            )
                          }
                        />
                      </ButtonNoBorderBackground>
                    ))
                  ) : (
                    <p style={{ padding: '0' }}>
                      There are currently no tags chosen to be included
                    </p>
                  )}
                </div>
                <div style={{ width: '40%', float: 'right', marginTop: '1%', marginLeft: '9px' }}>
                  <MioDropdown
                    optionsMarginRight="37%"
                    background="#EFF0F2"
                    hoverBackground="#D7C3FF"
                    dropdownMenu={
                      <ul>
                        <li
                          style={{
                            color: 'black',
                            border: 'black',
                            backgroundColor: 'white',
                            width: '100%',
                            marginRight: '50%',
                          }}
                          onClick={() => setIncludeRule('any')}
                        >
                          Any
                        </li>
                        <li
                          style={{
                            color: 'black',
                            border: 'black',
                            backgroundColor: 'white',
                            width: '100%',
                            marginRight: '50%',
                          }}
                          onClick={() => setIncludeRule('all')}
                        >
                          All
                        </li>
                      </ul>
                    }
                  >
                    {includeRule === 'any' ? (
                      <div style={{ display: 'inline-block', width: '100%', marginLeft: '-48px',  marginTop: '-9px', }}>
                        <p style={{ display: 'inline' }}>
                          Include Contacts with{' '}
                        </p>
                        <Button
                          margin="0"
                          borderRadius="2px"
                          bgColor="#fff"
                          color="#708892"
                          borderColor="#708892"
                          padding="1%"
                          style={{ display: 'inline' }}
                          className="inline-btn-segment-rule"
                        >
                          Any
                        </Button>
                        <p style={{ display: 'inline' }}> of these Tags</p>
                      </div>
                    ) : (
                      <div style={{ display: 'inline-block', width: '100%' }}>
                        <p style={{ display: 'inline' }}>
                          Include Contacts with{' '}
                        </p>
                        <Button
                          margin="0"
                          borderRadius="2px"
                          bgColor="#fff"
                          color="#708892"
                          padding="1%"
                          style={{ display: 'inline' }}
                          className="inline-btn-segment-rule"
                        >
                          All
                        </Button>
                        <p style={{ display: 'inline' }}> of these Tags</p>
                      </div>
                    )}
                  </MioDropdown>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Exclude Row */}
        <div style={{ marginBottom: '2%', display: 'flex', }}>
          <h3 style={{ textAlign: 'left', width: '156px', }}>Exclude Tags</h3>
          <div style={{ marginBottom: '2.5%', width: '88%', }}>
            <ul style={{ listStyle: 'none' }}>
              <li style={{ width: '100%' }}>
                <div style={{ width: '50%', float: 'left', marginLeft: '-13px', }}>
                  {excludeArr.length !== 0 ? (
                    excludeArr.map((tagElement) => (
                      <ButtonNoBorderBackground
                        background="#ececec"
                        margin="1% 2%"
                        padding="1%"
                        key={tagElement}
                      >
                        {tagElement}
                        <img
                          alt=""
                          src="/assets/images/emailList-delete.svg"
                          style={{ marginLeft: '8px' }}
                          onClick={() =>
                            removeFromArray(
                              tagElement,
                              excludeArr,
                              setExcludeArr
                            )
                          }
                        />
                      </ButtonNoBorderBackground>
                    ))
                  ) : (
                    <p style={{ padding: '0' }}>
                      There are currently no tags chosen to be excluded
                    </p>
                  )}
                </div>
                <div style={{ width: '41%', float: 'right', marginRight: '5px' }}>
                  <MioDropdown
                    optionsMarginRight="37%"
                    background="#EFF0F2"
                    hoverBackground="#D7C3FF"
                    dropdownMenu={
                      <ul>
                        <li
                          style={{
                            color: 'black',
                            border: 'black',
                            backgroundColor: 'white',
                            width: '100%',
                            marginRight: '50%',
                          }}
                          onClick={() => setExcludeRule('any')}
                        >
                          Any
                        </li>
                        <li
                          style={{
                            color: 'black',
                            border: 'black',
                            backgroundColor: 'white',
                            width: '100%',
                            marginRight: '50%',
                          }}
                          onClick={() => setExcludeRule('all')}
                        >
                          All
                        </li>
                      </ul>
                    }
                  >
                    {excludeRule === 'any' ? (
                      <div style={{ display: 'inline-block', width: '100%', marginLeft: '-44px', }}>
                        <p style={{ display: 'inline' }}>
                          Exclude Contacts with{' '}
                        </p>
                        <Button
                          margin="0"
                          borderRadius="2px"
                          bgColor="#fff"
                          color="#708892"
                          borderColor="#708892"
                          padding="1%"
                          style={{ display: 'inline' }}
                          className="inline-btn-segment-rule"
                        >
                          Any
                        </Button>
                        <p style={{ display: 'inline' }}> of these Tags</p>
                      </div>
                    ) : (
                      <div style={{ display: 'inline-block', width: '100%', }}>
                        <p style={{ display: 'inline' }}>
                          Exclude Contacts with{' '}
                        </p>
                        <Button
                          margin="0"
                          borderRadius="2px"
                          bgColor="#fff"
                          color="#708892"
                          padding="1%"
                          style={{ display: 'inline' }}
                          className="inline-btn-segment-rule"
                        >
                          All
                        </Button>
                        <p style={{ display: 'inline' }}> of these Tags</p>
                      </div>
                    )}
                  </MioDropdown>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div>{segmentErrorVisible && <p>{segmentErrorMessage}</p>}</div>
        <LoaderWrapper isLoading={loader}>
          <Button
            testId="submit-segment-creation"
            className="submit-btn-create-list"
            disabled={checkValidation()}
            onClick={submitHandler}
          >
            Submit
          </Button>
        </LoaderWrapper>
      </ModalBody>
    </ModalV2>
  );
};

export default CreateSegmentModal;
