export const specialChars = [
  {
    title: 'Latin',
    char: '¡',
    list: [
      { char: '¡', desc: 'INVERTED EXCLAMATION MARK' },
      {
        char: '¢',
        desc: 'CENT SIGN',
      },
      {
        char: '£',
        desc: 'POUND SIGN',
      },
      {
        char: '¤',
        desc: 'CURRENCY SIGN',
      },
      {
        char: '¥',
        desc: 'YEN SIGN',
      },
      {
        char: '¦',
        desc: 'BROKEN BAR',
      },
      {
        char: '§',
        desc: 'SECTION SIGN',
      },
      {
        char: '¨',
        desc: 'DIAERESIS',
      },
      {
        char: '©',
        desc: 'COPYRIGHT SIGN',
      },
      {
        char: '™',
        desc: 'TRADEMARK SIGN',
      },
      {
        char: 'ª',
        desc: 'FEMININE ORDINAL INDICATOR',
      },
      {
        char: '«',
        desc: 'LEFT-POINTING DOUBLE ANGLE QUOTATION MARK',
      },
      {
        char: '¬',
        desc: 'NOT SIGN',
      },
      {
        char: '®',
        desc: 'REGISTERED SIGN',
      },
      {
        char: '¯',
        desc: 'MACRON',
      },
      {
        char: '°',
        desc: 'DEGREE SIGN',
      },
      {
        char: '±',
        desc: 'PLUS-MINUS SIGN',
      },
      {
        char: '²',
        desc: 'SUPERSCRIPT TWO',
      },
      {
        char: '³',
        desc: 'SUPERSCRIPT THREE',
      },
      {
        char: '´',
        desc: 'ACUTE ACCENT',
      },
      {
        char: 'µ',
        desc: 'MICRO SIGN',
      },
      {
        char: '¶',
        desc: 'PILCROW SIGN',
      },
      {
        char: '·',
        desc: 'MIDDLE DOT',
      },
      {
        char: '¸',
        desc: 'CEDILLA',
      },
      {
        char: '¹',
        desc: 'SUPERSCRIPT ONE',
      },
      {
        char: 'º',
        desc: 'MASCULINE ORDINAL INDICATOR',
      },
      {
        char: '»',
        desc: 'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      },
      {
        char: '¼',
        desc: 'VULGAR FRACTION ONE QUARTER',
      },
      {
        char: '½',
        desc: 'VULGAR FRACTION ONE HALF',
      },
      {
        char: '¾',
        desc: 'VULGAR FRACTION THREE QUARTERS',
      },
      {
        char: '¿',
        desc: 'INVERTED QUESTION MARK',
      },
      {
        char: 'À',
        desc: 'LATIN CAPITAL LETTER A WITH GRAVE',
      },
      {
        char: 'Á',
        desc: 'LATIN CAPITAL LETTER A WITH ACUTE',
      },
      {
        char: 'Â',
        desc: 'LATIN CAPITAL LETTER A WITH CIRCUMFLEX',
      },
      {
        char: 'Ã',
        desc: 'LATIN CAPITAL LETTER A WITH TILDE',
      },
      {
        char: 'Ä',
        desc: 'LATIN CAPITAL LETTER A WITH DIAERESIS ',
      },
      {
        char: 'Å',
        desc: 'LATIN CAPITAL LETTER A WITH RING ABOVE',
      },
      {
        char: 'Æ',
        desc: 'LATIN CAPITAL LETTER AE',
      },
      {
        char: 'Ç',
        desc: 'LATIN CAPITAL LETTER C WITH CEDILLA',
      },
      {
        char: 'È',
        desc: 'LATIN CAPITAL LETTER E WITH GRAVE',
      },
      {
        char: 'É',
        desc: 'LATIN CAPITAL LETTER E WITH ACUTE',
      },
      {
        char: 'Ê',
        desc: 'LATIN CAPITAL LETTER E WITH CIRCUMFLEX',
      },
      {
        char: 'Ë',
        desc: 'LATIN CAPITAL LETTER E WITH DIAERESIS',
      },
      {
        char: 'Ì',
        desc: 'LATIN CAPITAL LETTER I WITH GRAVE',
      },
      {
        char: 'Í',
        desc: 'LATIN CAPITAL LETTER I WITH ACUTE',
      },
      {
        char: 'Î',
        desc: 'LATIN CAPITAL LETTER I WITH CIRCUMFLEX',
      },
      {
        char: 'Ï',
        desc: 'LATIN CAPITAL LETTER I WITH DIAERESIS',
      },
      {
        char: 'Ð',
        desc: 'LATIN CAPITAL LETTER ETH',
      },
      {
        char: 'Ñ',
        desc: 'LATIN CAPITAL LETTER N WITH TILDE',
      },
      {
        char: 'Ò',
        desc: 'LATIN CAPITAL LETTER O WITH GRAVE',
      },
      {
        char: 'Ó',
        desc: 'LATIN CAPITAL LETTER O WITH ACUTE',
      },
      {
        char: 'Ô',
        desc: 'LATIN CAPITAL LETTER O WITH CIRCUMFLEX',
      },
      {
        char: 'Õ',
        desc: 'LATIN CAPITAL LETTER O WITH TILDE',
      },
      {
        char: 'Ö',
        desc: 'LATIN CAPITAL LETTER O WITH DIAERESIS',
      },
      {
        char: '×',
        desc: 'MULTIPLICATION SIGN',
      },
      {
        char: 'Ø',
        desc: 'LATIN CAPITAL LETTER O WITH STROKE',
      },
      {
        char: 'Ù',
        desc: 'LATIN CAPITAL LETTER U WITH GRAVE',
      },
      {
        char: 'Ú',
        desc: 'LATIN CAPITAL LETTER U WITH ACUTE',
      },
      {
        char: 'Û',
        desc: 'LATIN CAPITAL LETTER U WITH CIRCUMFLEX',
      },
      {
        char: 'Ü',
        desc: 'LATIN CAPITAL LETTER U WITH DIAERESIS',
      },
      {
        char: 'Ý',
        desc: 'LATIN CAPITAL LETTER Y WITH ACUTE',
      },
      {
        char: 'Þ',
        desc: 'LATIN CAPITAL LETTER THORN',
      },
      {
        char: 'ß',
        desc: 'LATIN SMALL LETTER SHARP S',
      },
      {
        char: 'à',
        desc: 'LATIN SMALL LETTER A WITH GRAVE',
      },
      {
        char: 'á',
        desc: 'LATIN SMALL LETTER A WITH ACUTE ',
      },
      {
        char: 'â',
        desc: 'LATIN SMALL LETTER A WITH CIRCUMFLEX',
      },
      {
        char: 'ã',
        desc: 'LATIN SMALL LETTER A WITH TILDE',
      },
      {
        char: 'ä',
        desc: 'LATIN SMALL LETTER A WITH DIAERESIS',
      },
      {
        char: 'å',
        desc: 'LATIN SMALL LETTER A WITH RING ABOVE',
      },
      {
        char: 'æ',
        desc: 'LATIN SMALL LETTER AE',
      },
      {
        char: 'ç',
        desc: 'LATIN SMALL LETTER C WITH CEDILLA',
      },
      {
        char: 'è',
        desc: 'LATIN SMALL LETTER E WITH GRAVE',
      },
      {
        char: 'é',
        desc: 'LATIN SMALL LETTER E WITH ACUTE',
      },
      {
        char: 'ê',
        desc: 'LATIN SMALL LETTER E WITH CIRCUMFLEX',
      },
      {
        char: 'ë',
        desc: 'LATIN SMALL LETTER E WITH DIAERESIS',
      },
      {
        char: 'ì',
        desc: 'LATIN SMALL LETTER I WITH GRAVE',
      },
      {
        char: 'í',
        desc: 'LATIN SMALL LETTER I WITH ACUTE',
      },
      {
        char: 'î',
        desc: 'LATIN SMALL LETTER I WITH CIRCUMFLEX',
      },
      {
        char: 'ï',
        desc: 'LATIN SMALL LETTER I WITH DIAERESIS',
      },
      {
        char: 'ð',
        desc: 'LATIN SMALL LETTER ETH',
      },
      {
        char: 'ñ',
        desc: 'LATIN SMALL LETTER N WITH TILDE',
      },
      {
        char: 'ò',
        desc: 'LATIN SMALL LETTER O WITH GRAVE',
      },
      {
        char: 'ó',
        desc: 'LATIN SMALL LETTER O WITH ACUTE',
      },
      {
        char: 'ô',
        desc: 'LATIN SMALL LETTER O WITH CIRCUMFLEX',
      },
      {
        char: 'õ',
        desc: 'LATIN SMALL LETTER O WITH TILDE',
      },
      {
        char: 'ö',
        desc: 'LATIN SMALL LETTER O WITH DIAERESIS',
      },
      {
        char: '÷',
        desc: 'DIVISION SIGN',
      },
      {
        char: 'ø',
        desc: 'LATIN SMALL LETTER O WITH STROKE',
      },
      {
        char: 'ù',
        desc: 'LATIN SMALL LETTER U WITH GRAVE',
      },
      {
        char: 'ú',
        desc: 'LATIN SMALL LETTER U WITH ACUTE',
      },
      {
        char: 'û',
        desc: 'LATIN SMALL LETTER U WITH CIRCUMFLEX',
      },
      {
        char: 'ü',
        desc: 'LATIN SMALL LETTER U WITH DIAERESIS',
      },
      {
        char: 'ý',
        desc: 'LATIN SMALL LETTER Y WITH ACUTE',
      },
      {
        char: 'þ',
        desc: 'LATIN SMALL LETTER THORN',
      },
      {
        char: 'ÿ',
        desc: 'LATIN SMALL LETTER Y WITH DIAERESIS',
      },
    ],
  },
  {
    title: 'Greek',
    char: 'Α',
    list: [
      {
        char: 'Α',
        desc: 'GREEK CAPITAL LETTER ALPHA',
      },
      {
        char: 'Β',
        desc: 'GREEK CAPITAL LETTER BETA',
      },
      {
        char: 'Γ',
        desc: 'GREEK CAPITAL LETTER GAMMA',
      },
      {
        char: 'Δ',
        desc: 'GREEK CAPITAL LETTER DELTA',
      },
      {
        char: 'Ε',
        desc: 'GREEK CAPITAL LETTER EPSILON',
      },
      {
        char: 'Ζ',
        desc: 'GREEK CAPITAL LETTER ZETA',
      },
      {
        char: 'Η',
        desc: 'GREEK CAPITAL LETTER ETA',
      },
      {
        char: 'Θ',
        desc: 'GREEK CAPITAL LETTER THETA',
      },
      {
        char: 'Ι',
        desc: 'GREEK CAPITAL LETTER IOTA',
      },
      {
        char: 'Κ',
        desc: 'GREEK CAPITAL LETTER KAPPA',
      },
      {
        char: 'Λ',
        desc: 'GREEK CAPITAL LETTER LAMBDA',
      },
      {
        char: 'Μ',
        desc: 'GREEK CAPITAL LETTER MU',
      },
      {
        char: 'Ν',
        desc: 'GREEK CAPITAL LETTER NU',
      },
      {
        char: 'Ξ',
        desc: 'GREEK CAPITAL LETTER XI',
      },
      {
        char: 'Ο',
        desc: 'GREEK CAPITAL LETTER OMICRON',
      },
      {
        char: 'Π',
        desc: 'GREEK CAPITAL LETTER PI',
      },
      {
        char: 'Ρ',
        desc: 'GREEK CAPITAL LETTER RHO',
      },
      {
        char: 'Σ',
        desc: 'GREEK CAPITAL LETTER SIGMA',
      },
      {
        char: 'Τ',
        desc: 'GREEK CAPITAL LETTER TAU',
      },
      {
        char: 'Υ',
        desc: 'GREEK CAPITAL LETTER UPSILON',
      },
      {
        char: 'Φ',
        desc: 'GREEK CAPITAL LETTER PHI',
      },
      {
        char: 'Χ',
        desc: 'GREEK CAPITAL LETTER CHI',
      },
      {
        char: 'Ψ',
        desc: 'GREEK CAPITAL LETTER PSI',
      },
      {
        char: 'Ω',
        desc: 'GREEK CAPITAL LETTER OMEGA',
      },
      {
        char: 'α',
        desc: 'GREEK SMALL LETTER ALPHA',
      },
      {
        char: 'β',
        desc: 'GREEK SMALL LETTER BETA',
      },
      {
        char: 'γ',
        desc: 'GREEK SMALL LETTER GAMMA',
      },
      {
        char: 'δ',
        desc: 'GREEK SMALL LETTER DELTA',
      },
      {
        char: 'ε',
        desc: 'GREEK SMALL LETTER EPSILON',
      },
      {
        char: 'ζ',
        desc: 'GREEK SMALL LETTER ZETA',
      },
      {
        char: 'η',
        desc: 'GREEK SMALL LETTER ETA',
      },
      {
        char: 'θ',
        desc: 'GREEK SMALL LETTER THETA',
      },
      {
        char: 'ι',
        desc: 'GREEK SMALL LETTER IOTA',
      },
      {
        char: 'κ',
        desc: 'GREEK SMALL LETTER KAPPA',
      },
      {
        char: 'λ',
        desc: 'GREEK SMALL LETTER LAMBDA',
      },
      {
        char: 'μ',
        desc: 'GREEK SMALL LETTER MU',
      },
      {
        char: 'ν',
        desc: 'GREEK SMALL LETTER NU',
      },
      {
        char: 'ξ',
        desc: 'GREEK SMALL LETTER XI',
      },
      {
        char: 'ο',
        desc: 'GREEK SMALL LETTER OMICRON',
      },
      {
        char: 'π',
        desc: 'GREEK SMALL LETTER PI',
      },
      {
        char: 'ρ',
        desc: 'GREEK SMALL LETTER RHO',
      },
      {
        char: 'ς',
        desc: 'GREEK SMALL LETTER FINAL SIGMA',
      },
      {
        char: 'σ',
        desc: 'GREEK SMALL LETTER SIGMA',
      },
      {
        char: 'τ',
        desc: 'GREEK SMALL LETTER TAU',
      },
      {
        char: 'υ',
        desc: 'GREEK SMALL LETTER UPSILON',
      },
      {
        char: 'φ',
        desc: 'GREEK SMALL LETTER PHI',
      },
      {
        char: 'χ',
        desc: 'GREEK SMALL LETTER CHI',
      },
      {
        char: 'ψ',
        desc: 'GREEK SMALL LETTER PSI',
      },
      {
        char: 'ω',
        desc: 'GREEK SMALL LETTER OMEGA',
      },
      {
        char: 'ϑ',
        desc: 'GREEK THETA SYMBOL',
      },
      {
        char: 'ϒ',
        desc: 'GREEK UPSILON WITH HOOK SYMBOL',
      },
      {
        char: 'ϕ',
        desc: 'GREEK PHI SYMBOL',
      },
      {
        char: 'ϖ',
        desc: 'GREEK PI SYMBOL',
      },
      {
        char: 'Ϝ',
        desc: 'GREEK LETTER DIGAMMA',
      },
      {
        char: 'ϝ',
        desc: 'GREEK SMALL LETTER DIGAMMA',
      },
      {
        char: 'ϰ',
        desc: 'GREEK KAPPA SYMBOL',
      },
      {
        char: 'ϱ',
        desc: 'GREEK RHO SYMBOL',
      },
      {
        char: 'ϵ',
        desc: 'GREEK LUNATE EPSILON SYMBOL',
      },
      {
        char: '϶',
        desc: 'GREEK REVERSED LUNATE EPSILON SYMBOL',
      },
    ],
  },
  {
    title: 'Cyrillic',
    char: 'Ѐ',
    list: [
      { char: 'Ѐ', desc: 'CYRILLIC CAPITAL LETTER IE WITH GRAVE' },
      {
        char: 'Ё',
        desc: 'CYRILLIC CAPITAL LETTER IO',
      },
      {
        char: 'Ђ',
        desc: 'CYRILLIC CAPITAL LETTER DJE',
      },
      {
        char: 'Ѓ',
        desc: 'CYRILLIC CAPITAL LETTER GJE',
      },
      {
        char: 'Є',
        desc: 'CYRILLIC CAPITAL LETTER UKRAINIAN IE',
      },
      {
        char: 'Ѕ',
        desc: 'CYRILLIC CAPITAL LETTER DZE',
      },
      {
        char: 'І',
        desc: 'CYRILLIC CAPITAL LETTER BYELORUSSIAN-UKRAINIAN I',
      },
      {
        char: 'Ї',
        desc: 'CYRILLIC CAPITAL LETTER YI',
      },
      {
        char: 'Ј',
        desc: 'CYRILLIC CAPITAL LETTER JE',
      },
      {
        char: 'Љ',
        desc: 'CYRILLIC CAPITAL LETTER LJE',
      },
      {
        char: 'Њ',
        desc: 'CYRILLIC CAPITAL LETTER NJE',
      },
      {
        char: 'Ћ',
        desc: 'CYRILLIC CAPITAL LETTER TSHE',
      },
      {
        char: 'Ќ',
        desc: 'CYRILLIC CAPITAL LETTER KJE',
      },
      {
        char: 'Ѝ',
        desc: 'CYRILLIC CAPITAL LETTER I WITH GRAVE',
      },
      {
        char: 'Ў',
        desc: 'CYRILLIC CAPITAL LETTER SHORT U',
      },
      {
        char: 'Џ',
        desc: 'CYRILLIC CAPITAL LETTER DZHE',
      },
      {
        char: 'А',
        desc: 'CYRILLIC CAPITAL LETTER A',
      },
      {
        char: 'Б',
        desc: 'CYRILLIC CAPITAL LETTER BE',
      },
      {
        char: 'В',
        desc: 'CYRILLIC CAPITAL LETTER VE',
      },
      {
        char: 'Г',
        desc: 'CYRILLIC CAPITAL LETTER GHE',
      },
      {
        char: 'Д',
        desc: 'CYRILLIC CAPITAL LETTER DE',
      },
      {
        char: 'Е',
        desc: 'CYRILLIC CAPITAL LETTER IE',
      },
      {
        char: 'Ж',
        desc: 'CYRILLIC CAPITAL LETTER ZHE',
      },
      {
        char: 'З',
        desc: 'CYRILLIC CAPITAL LETTER ZE',
      },
      {
        char: 'И',
        desc: 'CYRILLIC CAPITAL LETTER I',
      },
      {
        char: 'Й',
        desc: 'CYRILLIC CAPITAL LETTER SHORT I',
      },
      {
        char: 'К',
        desc: 'CYRILLIC CAPITAL LETTER KA',
      },
      {
        char: 'Л',
        desc: 'CYRILLIC CAPITAL LETTER EL',
      },
      {
        char: 'М',
        desc: 'CYRILLIC CAPITAL LETTER EM',
      },
      {
        char: 'Н',
        desc: 'CYRILLIC CAPITAL LETTER EN',
      },
      {
        char: 'О',
        desc: 'CYRILLIC CAPITAL LETTER O',
      },
      {
        char: 'П',
        desc: 'CYRILLIC CAPITAL LETTER PE',
      },
      {
        char: 'Р',
        desc: 'CYRILLIC CAPITAL LETTER ER',
      },
      {
        char: 'С',
        desc: 'CYRILLIC CAPITAL LETTER ES',
      },
      {
        char: 'Т',
        desc: 'CYRILLIC CAPITAL LETTER TE',
      },
      {
        char: 'У',
        desc: 'CYRILLIC CAPITAL LETTER U',
      },
      {
        char: 'Ф',
        desc: 'CYRILLIC CAPITAL LETTER EF',
      },
      {
        char: 'Х',
        desc: 'CYRILLIC CAPITAL LETTER HA',
      },
      {
        char: 'Ц',
        desc: 'CYRILLIC CAPITAL LETTER TSE',
      },
      {
        char: 'Ч',
        desc: 'CYRILLIC CAPITAL LETTER CHE',
      },
      {
        char: 'Ш',
        desc: 'CYRILLIC CAPITAL LETTER SHA',
      },
      {
        char: 'Щ',
        desc: 'CYRILLIC CAPITAL LETTER SHCHA',
      },
      {
        char: 'Ъ',
        desc: 'CYRILLIC CAPITAL LETTER HARD SIGN',
      },
      {
        char: 'Ы',
        desc: 'CYRILLIC CAPITAL LETTER YERU',
      },
      {
        char: 'Ь',
        desc: 'CYRILLIC CAPITAL LETTER SOFT SIGN',
      },
      {
        char: 'Э',
        desc: 'CYRILLIC CAPITAL LETTER E',
      },
      {
        char: 'Ю',
        desc: 'CYRILLIC CAPITAL LETTER YU',
      },
      {
        char: 'Я',
        desc: 'CYRILLIC CAPITAL LETTER YA',
      },
      {
        char: 'а',
        desc: 'CYRILLIC SMALL LETTER A',
      },
      {
        char: 'б',
        desc: 'CYRILLIC SMALL LETTER BE',
      },
      {
        char: 'в',
        desc: 'CYRILLIC SMALL LETTER VE',
      },
      {
        char: 'г',
        desc: 'CYRILLIC SMALL LETTER GHE',
      },
      {
        char: 'д',
        desc: 'CYRILLIC SMALL LETTER DE',
      },
      {
        char: 'е',
        desc: 'CYRILLIC SMALL LETTER IE',
      },
      {
        char: 'ж',
        desc: 'CYRILLIC SMALL LETTER ZHE',
      },
      {
        char: 'з',
        desc: 'CYRILLIC SMALL LETTER ZE',
      },
      {
        char: 'и',
        desc: 'CYRILLIC SMALL LETTER I',
      },
      {
        char: 'й',
        desc: 'CYRILLIC SMALL LETTER SHORT I',
      },
      {
        char: 'к',
        desc: 'CYRILLIC SMALL LETTER KA',
      },
      {
        char: 'л',
        desc: 'CYRILLIC SMALL LETTER EL',
      },
      {
        char: 'м',
        desc: 'CYRILLIC SMALL LETTER EM',
      },
      {
        char: 'н',
        desc: 'CYRILLIC SMALL LETTER EN',
      },
      {
        char: 'о',
        desc: 'CYRILLIC SMALL LETTER O',
      },
      {
        char: 'п',
        desc: 'CYRILLIC SMALL LETTER PE',
      },
      {
        char: 'р',
        desc: 'CYRILLIC SMALL LETTER ER',
      },
      {
        char: 'с',
        desc: 'CYRILLIC SMALL LETTER ES',
      },
      {
        char: 'т',
        desc: 'CYRILLIC SMALL LETTER TE',
      },
      {
        char: 'у',
        desc: 'CYRILLIC SMALL LETTER U',
      },
      {
        char: 'ф',
        desc: 'CYRILLIC SMALL LETTER EF',
      },
      {
        char: 'х',
        desc: 'CYRILLIC SMALL LETTER HA',
      },
      {
        char: 'ц',
        desc: 'CYRILLIC SMALL LETTER TSE',
      },
      {
        char: 'ч',
        desc: 'CYRILLIC SMALL LETTER CHE',
      },
      {
        char: 'ш',
        desc: 'CYRILLIC SMALL LETTER SHA',
      },
      {
        char: 'щ',
        desc: 'CYRILLIC SMALL LETTER SHCHA',
      },
      {
        char: 'ъ',
        desc: 'CYRILLIC SMALL LETTER HARD SIGN',
      },
      {
        char: 'ы',
        desc: 'CYRILLIC SMALL LETTER YERU',
      },
      {
        char: 'ь',
        desc: 'CYRILLIC SMALL LETTER SOFT SIGN',
      },
      {
        char: 'э',
        desc: 'CYRILLIC SMALL LETTER E',
      },
      {
        char: 'ю',
        desc: 'CYRILLIC SMALL LETTER YU',
      },
      {
        char: 'я',
        desc: 'CYRILLIC SMALL LETTER YA',
      },
      {
        char: 'ѐ',
        desc: 'CYRILLIC SMALL LETTER IE WITH GRAVE',
      },
      {
        char: 'ё',
        desc: 'CYRILLIC SMALL LETTER IO',
      },
      {
        char: 'ђ',
        desc: 'CYRILLIC SMALL LETTER DJE',
      },
      {
        char: 'ѓ',
        desc: 'CYRILLIC SMALL LETTER GJE',
      },
      {
        char: 'є',
        desc: 'CYRILLIC SMALL LETTER UKRAINIAN IE',
      },
      {
        char: 'ѕ',
        desc: 'CYRILLIC SMALL LETTER DZE',
      },
      {
        char: 'і',
        desc: 'CYRILLIC SMALL LETTER BYELORUSSIAN-UKRAINIAN I',
      },
      {
        char: 'ї',
        desc: 'CYRILLIC SMALL LETTER YI',
      },
      {
        char: 'ј',
        desc: 'CYRILLIC SMALL LETTER JE',
      },
      {
        char: 'љ',
        desc: 'CYRILLIC SMALL LETTER LJE',
      },
      {
        char: 'њ',
        desc: 'CYRILLIC SMALL LETTER NJE',
      },
      {
        char: 'ћ',
        desc: 'CYRILLIC SMALL LETTER TSHE',
      },
      {
        char: 'ќ',
        desc: 'CYRILLIC SMALL LETTER KJE',
      },
      {
        char: 'ѝ',
        desc: 'CYRILLIC SMALL LETTER I WITH GRAVE',
      },
      {
        char: 'ў',
        desc: 'CYRILLIC SMALL LETTER SHORT U',
      },
      {
        char: 'џ',
        desc: 'CYRILLIC SMALL LETTER DZHE',
      },
    ],
  },
  {
    title: 'Punctuation',
    char: '–',
    list: [
      { char: '–', desc: 'EN DASH' },
      {
        char: '—',
        desc: 'EM DASH',
      },
      {
        char: '‘',
        desc: 'LEFT SINGLE QUOTATION MARK',
      },
      {
        char: '’',
        desc: 'RIGHT SINGLE QUOTATION MARK',
      },
      {
        char: '‚',
        desc: 'SINGLE LOW-9 QUOTATION MARK',
      },
      {
        char: '“',
        desc: 'LEFT DOUBLE QUOTATION MARK',
      },
      {
        char: '”',
        desc: 'RIGHT DOUBLE QUOTATION MARK',
      },
      {
        char: '„',
        desc: 'DOUBLE LOW-9 QUOTATION MARK',
      },
      {
        char: '†',
        desc: 'DAGGER',
      },
      {
        char: '‡',
        desc: 'DOUBLE DAGGER',
      },
      {
        char: '•',
        desc: 'BULLET',
      },
      {
        char: '…',
        desc: 'HORIZONTAL ELLIPSIS',
      },
      {
        char: '‰',
        desc: 'PER MILLE SIGN',
      },
      {
        char: '′',
        desc: 'PRIME',
      },
      {
        char: '″',
        desc: 'DOUBLE PRIME',
      },
      {
        char: '‹',
        desc: 'SINGLE LEFT-POINTING ANGLE QUOTATION MARK',
      },
      {
        char: '›',
        desc: 'SINGLE RIGHT-POINTING ANGLE QUOTATION MARK',
      },
      {
        char: '‾',
        desc: 'OVERLINE',
      },
      {
        char: '⁄',
        desc: 'FRACTION SLASH',
      },
    ],
  },
  {
    title: 'Currency',
    char: '₠',
    list: [
      { char: '₠', desc: 'EURO-CURRENCY SIGN' },
      {
        char: '₡',
        desc: 'COLON SIGN',
      },
      {
        char: '₢',
        desc: 'CRUZEIRO SIGN',
      },
      {
        char: '₣',
        desc: 'FRENCH FRANC SIGN',
      },
      {
        char: '₤',
        desc: 'LIRA SIGN',
      },
      {
        char: '₥',
        desc: 'MILL SIGN',
      },
      {
        char: '₦',
        desc: 'NAIRA SIGN',
      },
      {
        char: '₧',
        desc: 'PESETA SIGN',
      },
      {
        char: '₨',
        desc: 'RUPEE SIGN',
      },
      {
        char: '₩',
        desc: 'WON SIGN',
      },
      {
        char: '₪',
        desc: 'NEW SHEQEL SIGN',
      },
      {
        char: '₫',
        desc: 'DONG SIGN',
      },
      {
        char: '€',
        desc: 'EURO SIGN',
      },
      {
        char: '₭',
        desc: 'KIP SIGN',
      },
      {
        char: '₮',
        desc: 'TUGRIK SIGN',
      },
      {
        char: '₯',
        desc: 'DRACHMA SIGN',
      },
      {
        char: '₰',
        desc: 'GERMAN PENNY SYMBOL',
      },
      {
        char: '₱',
        desc: 'PESO SIGN',
      },
      {
        char: '₲',
        desc: 'GUARANI SIGN',
      },
      {
        char: '₳',
        desc: 'AUSTRAL SIGN',
      },
      {
        char: '₴',
        desc: 'HRYVNIA SIGN',
      },
      {
        char: '₵',
        desc: 'CEDI SIGN',
      },
      {
        char: '₶',
        desc: 'LIVRE TOURNOIS SIGN',
      },
      {
        char: '₷',
        desc: 'SPESMILO SIGN',
      },
      {
        char: '₸',
        desc: 'TENGE SIGN',
      },
      {
        char: '₹',
        desc: 'INDIAN RUPEE SIGN',
      },
    ],
  },
  {
    title: 'ARROWS',
    char: '←',
    list: [
      { char: '←', desc: 'LEFTWARDS ARROW' },
      {
        char: '↑',
        desc: 'UPWARDS ARROW',
      },
      {
        char: '→',
        desc: 'RIGHTWARDS ARROW',
      },
      {
        char: '↓',
        desc: 'DOWNWARDS ARROW',
      },
      {
        char: '↔',
        desc: 'LEFT RIGHT ARROW',
      },
      {
        char: '↕',
        desc: 'UP DOWN ARROW',
      },
      {
        char: '↖',
        desc: 'NORTH WEST ARROW',
      },
      {
        char: '↗',
        desc: 'NORTH EAST ARROW',
      },
      {
        char: '↘',
        desc: 'SOUTH EAST ARROW',
      },
      {
        char: '↙',
        desc: 'SOUTH WEST ARROW',
      },
      {
        char: '↚',
        desc: 'LEFTWARDS ARROW WITH STROKE',
      },
      {
        char: '↛',
        desc: 'RIGHTWARDS ARROW WITH STROKE',
      },
      {
        char: '↜',
        desc: 'LEFTWARDS WAVE ARROW',
      },
      {
        char: '↝',
        desc: 'RIGHTWARDS WAVE ARROW',
      },
      {
        char: '↞',
        desc: 'LEFTWARDS TWO HEADED ARROW',
      },
      {
        char: '↟',
        desc: 'UPWARDS TWO HEADED ARROW',
      },
      {
        char: '↠',
        desc: 'RIGHTWARDS TWO HEADED ARROW',
      },
      {
        char: '↡',
        desc: 'DOWNWARDS TWO HEADED ARROW',
      },
      {
        char: '↢',
        desc: 'LEFTWARDS ARROW WITH TAIL',
      },
      {
        char: '↣',
        desc: 'RIGHTWARDS ARROW WITH TAIL',
      },
      {
        char: '↤',
        desc: 'LEFTWARDS ARROW FROM BAR',
      },
      {
        char: '↥',
        desc: 'UPWARDS ARROW FROM BAR',
      },
      {
        char: '↦',
        desc: 'RIGHTWARDS ARROW FROM BAR',
      },
      {
        char: '↧',
        desc: 'DOWNWARDS ARROW FROM BAR',
      },
      {
        char: '↨',
        desc: 'UP DOWN ARROW WITH BASE',
      },
      {
        char: '↩',
        desc: 'LEFTWARDS ARROW WITH HOOK',
      },
      {
        char: '↪',
        desc: 'RIGHTWARDS ARROW WITH HOOK',
      },
      {
        char: '↫',
        desc: 'LEFTWARDS ARROW WITH LOOP',
      },
      {
        char: '↬',
        desc: 'RIGHTWARDS ARROW WITH LOOP',
      },
      {
        char: '↭',
        desc: 'LEFT RIGHT WAVE ARROW',
      },
      {
        char: '↮',
        desc: 'LEFT RIGHT ARROW WITH STROKE',
      },
      {
        char: '↯',
        desc: 'DOWNWARDS ZIGZAG ARROW',
      },
      {
        char: '↰',
        desc: 'UPWARDS ARROW WITH TIP LEFTWARDS',
      },
      {
        char: '↱',
        desc: 'UPWARDS ARROW WITH TIP RIGHTWARDS',
      },
      {
        char: '↲',
        desc: 'DOWNWARDS ARROW WITH TIP LEFTWARDS',
      },
      {
        char: '↳',
        desc: 'DOWNWARDS ARROW WITH TIP RIGHTWARDS',
      },
      {
        char: '↴',
        desc: 'RIGHTWARDS ARROW WITH CORNER DOWNWARDS',
      },
      {
        char: '↵',
        desc: 'DOWNWARDS ARROW WITH CORNER LEFTWARDS',
      },
      {
        char: '↶',
        desc: 'ANTICLOCKWISE TOP SEMICIRCLE ARROW',
      },
      {
        char: '↷',
        desc: 'CLOCKWISE TOP SEMICIRCLE ARROW',
      },
      {
        char: '↸',
        desc: 'NORTH WEST ARROW TO LONG BAR',
      },
      {
        char: '↹',
        desc: 'LEFTWARDS ARROW TO BAR OVER RIGHTWARDS ARROW TO BAR',
      },
      {
        char: '↺',
        desc: 'ANTICLOCKWISE OPEN CIRCLE ARROW',
      },
      {
        char: '↻',
        desc: 'CLOCKWISE OPEN CIRCLE ARROW',
      },
      {
        char: '↼',
        desc: 'LEFTWARDS HARPOON WITH BARB UPWARDS',
      },
      {
        char: '↽',
        desc: 'LEFTWARDS HARPOON WITH BARB DOWNWARDS',
      },
      {
        char: '↾',
        desc: 'UPWARDS HARPOON WITH BARB RIGHTWARDS',
      },
      {
        char: '↿',
        desc: 'UPWARDS HARPOON WITH BARB LEFTWARDS',
      },
      {
        char: '⇀',
        desc: 'RIGHTWARDS HARPOON WITH BARB UPWARDS',
      },
      {
        char: '⇁',
        desc: 'RIGHTWARDS HARPOON WITH BARB DOWNWARDS',
      },
      {
        char: '⇂',
        desc: 'DOWNWARDS HARPOON WITH BARB RIGHTWARDS',
      },
      {
        char: '⇃',
        desc: 'DOWNWARDS HARPOON WITH BARB LEFTWARDS',
      },
      {
        char: '⇄',
        desc: 'RIGHTWARDS ARROW OVER LEFTWARDS ARROW',
      },
      {
        char: '⇅',
        desc: 'UPWARDS ARROW LEFTWARDS OF DOWNWARDS ARROW',
      },
      {
        char: '⇆',
        desc: 'LEFTWARDS ARROW OVER RIGHTWARDS ARROW',
      },
      {
        char: '⇇',
        desc: 'LEFTWARDS PAIRED ARROWS',
      },
      {
        char: '⇈',
        desc: 'UPWARDS PAIRED ARROWS',
      },
      {
        char: '⇉',
        desc: 'RIGHTWARDS PAIRED ARROWS',
      },
      {
        char: '⇊',
        desc: 'DOWNWARDS PAIRED ARROWS',
      },
      {
        char: '⇋',
        desc: 'LEFTWARDS HARPOON OVER RIGHTWARDS HARPOON',
      },
      {
        char: '⇌',
        desc: 'RIGHTWARDS HARPOON OVER LEFTWARDS HARPOON',
      },
      {
        char: '⇍',
        desc: 'LEFTWARDS DOUBLE ARROW WITH STROKE',
      },
      {
        char: '⇎',
        desc: 'LEFT RIGHT DOUBLE ARROW WITH STROKE',
      },
      {
        char: '⇏',
        desc: 'RIGHTWARDS DOUBLE ARROW WITH STROKE',
      },
      {
        char: '⇐',
        desc: 'LEFTWARDS DOUBLE ARROW',
      },
      {
        char: '⇑',
        desc: 'UPWARDS DOUBLE ARROW',
      },
      {
        char: '⇒',
        desc: 'RIGHTWARDS DOUBLE ARROW',
      },
      {
        char: '⇓',
        desc: 'DOWNWARDS DOUBLE ARROW',
      },
      {
        char: '⇔',
        desc: 'LEFT RIGHT DOUBLE ARROW',
      },
      {
        char: '⇕',
        desc: 'UP DOWN DOUBLE ARROW',
      },
      {
        char: '⇖',
        desc: 'NORTH WEST DOUBLE ARROW',
      },
      {
        char: '⇗',
        desc: 'NORTH EAST DOUBLE ARROW',
      },
      {
        char: '⇘',
        desc: 'SOUTH EAST DOUBLE ARROW',
      },
      {
        char: '⇙',
        desc: 'SOUTH WEST DOUBLE ARROW',
      },
      {
        char: '⇚',
        desc: 'LEFTWARDS TRIPLE ARROW',
      },
      {
        char: '⇛',
        desc: 'RIGHTWARDS TRIPLE ARROW',
      },
      {
        char: '⇜',
        desc: 'LEFTWARDS SQUIGGLE ARROW',
      },
      {
        char: '⇝',
        desc: 'RIGHTWARDS SQUIGGLE ARROW',
      },
      {
        char: '⇞',
        desc: 'UPWARDS ARROW WITH DOUBLE STROKE',
      },
      {
        char: '⇟',
        desc: 'DOWNWARDS ARROW WITH DOUBLE STROKE',
      },
      {
        char: '⇠',
        desc: 'LEFTWARDS DASHED ARROW',
      },
      {
        char: '⇡',
        desc: 'UPWARDS DASHED ARROW',
      },
      {
        char: '⇢',
        desc: 'RIGHTWARDS DASHED ARROW',
      },
      {
        char: '⇣',
        desc: 'DOWNWARDS DASHED ARROW',
      },
      {
        char: '⇤',
        desc: 'LEFTWARDS ARROW TO BAR',
      },
      {
        char: '⇥',
        desc: 'RIGHTWARDS ARROW TO BAR',
      },
      {
        char: '⇦',
        desc: 'LEFTWARDS WHITE ARROW',
      },
      {
        char: '⇧',
        desc: 'UPWARDS WHITE ARROW',
      },
      {
        char: '⇨',
        desc: 'RIGHTWARDS WHITE ARROW',
      },
      {
        char: '⇩',
        desc: 'DOWNWARDS WHITE ARROW',
      },
      {
        char: '⇪',
        desc: 'UPWARDS WHITE ARROW FROM BAR',
      },
      {
        char: '⇫',
        desc: 'UPWARDS WHITE ARROW ON PEDESTAL',
      },
      {
        char: '⇬',
        desc: 'UPWARDS WHITE ARROW ON PEDESTAL WITH HORIZONTAL BAR',
      },
      {
        char: '⇭',
        desc: 'UPWARDS WHITE ARROW ON PEDESTAL WITH VERTICAL BAR',
      },
      {
        char: '⇮',
        desc: 'UPWARDS WHITE DOUBLE ARROW',
      },
      {
        char: '⇯',
        desc: 'UPWARDS WHITE DOUBLE ARROW ON PEDESTAL',
      },
      {
        char: '⇰',
        desc: 'RIGHTWARDS WHITE ARROW FROM WALL',
      },
      {
        char: '⇱',
        desc: 'NORTH WEST ARROW TO CORNER',
      },
      {
        char: '⇲',
        desc: 'SOUTH EAST ARROW TO CORNER',
      },
      {
        char: '⇳',
        desc: 'UP DOWN WHITE ARROW',
      },
      {
        char: '⇴',
        desc: 'RIGHT ARROW WITH SMALL CIRCLE',
      },
      {
        char: '⇵',
        desc: 'DOWNWARDS ARROW LEFTWARDS OF UPWARDS ARROW',
      },
      {
        char: '⇶',
        desc: 'THREE RIGHTWARDS ARROWS',
      },
      {
        char: '⇷',
        desc: 'LEFTWARDS ARROW WITH VERTICAL STROKE',
      },
      {
        char: '⇸',
        desc: 'RIGHTWARDS ARROW WITH VERTICAL STROKE',
      },
      {
        char: '⇹',
        desc: 'LEFT RIGHT ARROW WITH VERTICAL STROKE',
      },
      {
        char: '⇺',
        desc: 'LEFTWARDS ARROW WITH DOUBLE VERTICAL STROKE',
      },
      {
        char: '⇻',
        desc: 'RIGHTWARDS ARROW WITH DOUBLE VERTICAL STROKE',
      },
      {
        char: '⇼',
        desc: 'LEFT RIGHT ARROW WITH DOUBLE VERTICAL STROKE',
      },
      {
        char: '⇽',
        desc: 'LEFTWARDS OPEN-HEADED ARROW',
      },
      {
        char: '⇾',
        desc: 'RIGHTWARDS OPEN-HEADED ARROW',
      },
      {
        char: '⇿',
        desc: 'LEFT RIGHT OPEN-HEADED ARROW',
      },
    ],
  },
  {
    title: 'Math',
    char: '∀',
    list: [
      { char: '∀', desc: 'FOR ALL' },
      {
        char: '∂',
        desc: 'PARTIAL DIFFERENTIAL',
      },
      {
        char: '∃',
        desc: 'THERE EXISTS',
      },
      {
        char: '∅',
        desc: 'EMPTY SET',
      },
      {
        char: '∇',
        desc: 'NABLA',
      },
      {
        char: '∈',
        desc: 'ELEMENT OF',
      },
      {
        char: '∉',
        desc: 'NOT AN ELEMENT OF',
      },
      {
        char: '∋',
        desc: 'CONTAINS AS MEMBER',
      },
      {
        char: '∏',
        desc: 'N-ARY PRODUCT',
      },
      {
        char: '∑',
        desc: 'N-ARY SUMMATION',
      },
      {
        char: '−',
        desc: 'MINUS SIGN',
      },
      {
        char: '∗',
        desc: 'ASTERISK OPERATOR',
      },
      {
        char: '√',
        desc: 'SQUARE ROOT',
      },
      {
        char: '∝',
        desc: 'PROPORTIONAL TO',
      },
      {
        char: '∞',
        desc: 'INFINITY',
      },
      {
        char: '∠',
        desc: 'ANGLE',
      },
      {
        char: '∧',
        desc: 'LOGICAL AND',
      },
      {
        char: '∨',
        desc: 'LOGICAL OR',
      },
      {
        char: '∩',
        desc: 'INTERSECTION',
      },
      {
        char: '∪',
        desc: 'UNION',
      },
      {
        char: '∫',
        desc: 'INTEGRAL',
      },
      {
        char: '∴',
        desc: 'THEREFORE',
      },
      {
        char: '∼',
        desc: 'TILDE OPERATOR',
      },
      {
        char: '≅',
        desc: 'APPROXIMATELY EQUAL TO',
      },
      {
        char: '≈',
        desc: 'ALMOST EQUAL TO',
      },
      {
        char: '≠',
        desc: 'NOT EQUAL TO',
      },
      {
        char: '≡',
        desc: 'IDENTICAL TO',
      },
      {
        char: '≤',
        desc: 'LESS-THAN OR EQUAL TO',
      },
      {
        char: '≥',
        desc: 'GREATER-THAN OR EQUAL TO',
      },
      {
        char: '⊂',
        desc: 'SUBSET OF',
      },
      {
        char: '⊃',
        desc: 'SUPERSET OF',
      },
      {
        char: '⊄',
        desc: 'NOT A SUBSET OF',
      },
      {
        char: '⊆',
        desc: 'SUBSET OF OR EQUAL TO',
      },
      {
        char: '⊇',
        desc: 'SUPERSET OF OR EQUAL TO',
      },
      {
        char: '⊕',
        desc: 'CIRCLED PLUS',
      },
      {
        char: '⊗',
        desc: 'CIRCLED TIMES',
      },
      {
        char: '⊥',
        desc: 'UP TACK',
      },
    ],
  },
  {
    title: 'Misc',
    char: '♠',
    list: [
      {
        char: '♠',
        desc: 'BLACK SPADE SUIT',
      },
      {
        char: '♣',
        desc: 'BLACK CLUB SUIT',
      },
      {
        char: '♥',
        desc: 'BLACK HEART SUIT',
      },
      {
        char: '♦',
        desc: 'BLACK DIAMOND SUIT',
      },
      {
        char: '♩',
        desc: 'QUARTER NOTE',
      },
      {
        char: '♪',
        desc: 'EIGHTH NOTE',
      },
      {
        char: '♫',
        desc: 'BEAMED EIGHTH NOTES',
      },
      {
        char: '♬',
        desc: 'BEAMED SIXTEENTH NOTES',
      },
      {
        char: '♭',
        desc: 'MUSIC FLAT SIGN',
      },
      {
        char: '♮',
        desc: 'MUSIC NATURAL SIGN',
      },
      {
        char: '☀',
        desc: 'BLACK SUN WITH RAYS',
      },
      {
        char: '☁',
        desc: 'CLOUD',
      },
      {
        char: '☂',
        desc: 'UMBRELLA',
      },
      {
        char: '☃',
        desc: 'SNOWMAN',
      },
      {
        char: '☕',
        desc: 'HOT BEVERAGE',
      },
      {
        char: '☘',
        desc: 'SHAMROCK',
      },
      {
        char: '☯',
        desc: 'YIN YANG',
      },
      {
        char: '✔',
        desc: 'HEAVY CHECK MARK',
      },
      {
        char: '✖',
        desc: 'HEAVY MULTIPLICATION X',
      },
      {
        char: '❄',
        desc: 'SNOWFLAKE',
      },
      {
        char: '❛',
        desc: 'HEAVY SINGLE TURNED COMMA QUOTATION MARK ORNAMENT',
      },
      {
        char: '❜',
        desc: 'HEAVY SINGLE COMMA QUOTATION MARK ORNAMENT',
      },
      {
        char: '❝',
        desc: 'HEAVY DOUBLE TURNED COMMA QUOTATION MARK ORNAMENT',
      },
      {
        char: '❞',
        desc: 'HEAVY DOUBLE COMMA QUOTATION MARK ORNAMENT',
      },
      {
        char: '❤',
        desc: 'HEAVY BLACK HEART',
      },
    ],
  },
];
