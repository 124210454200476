import React, { useState, useRef, useEffect } from 'react'
import { EditableContentWrapper, SummayContent } from '../../EditableContent/EditableContent.styles';
import { get, isEmpty } from 'lodash'
import Popover from 'react-popover';

const SummaryTextFields = ({
  isFetchingSummary,
  style,
  placeholder,
  height,
  maxLength,
  content,
  onChange,
  sumarryOptions,
  shortSummary,
  editable,
  originalSummary
}) => {
  // console.log('[FROM summary text fields - ', content, '.....editable - ', editable)
  const domRef = React.createRef();
  let lastHtml = null;
  const dynamicSummaryRef = useRef(null);
  const [intialContent, setIntialContent] = useState(null);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [selectedSummaryIndex, setSelectedSummaryIndex] = useState(0);

  useEffect(() => {
    // console.log('useeffect summary text field....');
    if (showPlaceholder && content) {
      setShowPlaceholder(false);
      setIntialContent((content.length > 700 && shortSummary) ? shortSummary : content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);
  
  const onKeyPress = (e) => {
    let allowedKeys = false;
    if (e.type === 'keydown') {
      allowedKeys = (
        e.which === 8 ||  /* BACKSPACE */
        e.which === 35 || /* END */
        e.which === 36 || /* HOME */
        e.which === 37 || /* LEFT */
        e.which === 38 || /* UP */
        e.which === 39 || /* RIGHT*/
        e.which === 40 || /* DOWN */
        e.which === 46 || /* DEL*/
        (e.ctrlKey === true && e.which === 65) || /* CTRL + A */
        (e.ctrlKey === true && e.which === 88) || /* CTRL + X */
        (e.ctrlKey === true && e.which === 67) || /* CTRL + C */
        (e.ctrlKey === true && e.which === 86) || /* CTRL + V */
        (e.ctrlKey === true && e.which === 90)    /* CTRL + Z */
      )
  }
    if(maxLength > 0 && !allowedKeys && domRef.current.innerText.length > maxLength - 1){
        e.preventDefault();
        return false;
    }else{
      return true;
    }
  }

  const contentChange = () => {
    if (domRef.current) {
      const html = domRef.current.innerHTML;
      const text = domRef.current.innerText;
      if (html !== lastHtml) {
        onChange(html, text);
      }
      lastHtml = html;
    }
  };

  const onContentBlur = () => {
    if (!content) {
      setShowPlaceholder(false);
    }
  };

  const onContentFocus = (e) => {
    setShowPlaceholder(false);
    domRef.current.focus();
  };

  const onPaste = (e) => {
    const innerTextLength = domRef.current.innerText.length;
    let content;
    e.preventDefault();
    if( e.clipboardData ){
      content = (maxLength && maxLength !== innerTextLength) && e.clipboardData.getData('text/plain').substring(0, maxLength - innerTextLength);
      if(content.length <= maxLength){
        document.execCommand('insertText', false, content);
        return false;
      }      
    }
    // else if( window.clipboardData ){
    //   content = (maxLength && maxLength !== innerTextLength) ? window.clipboardData.getData('Text').substring(0, maxLength - innerTextLength) : window.clipboardData.getData('Text');
    //   if (window.getSelection)
    //     window.getSelection().getRangeAt(0).insertNode( document.createTextNode(content) );
    // }
  };

  const appendSpanToTheSummary = (summary = '') => {
    if (!summary) {
      return '';
    }

    if(originalSummary && !isEmpty(originalSummary) && !isEmpty(originalSummary[0].summary) && originalSummary[0].summary.length !== summary.length) {
      return '';
    }
    
    const firstQuoteIndex = summary.indexOf('-');
    if (!firstQuoteIndex === -1) {
      return summary.replace(/&quot;/g, '"');
    }
    const lastQuoteIndex = summary.lastIndexOf('-');
    const subSet1 = summary.substring(0, firstQuoteIndex);

    const subSet3 = summary.substring(lastQuoteIndex + 1, summary.length);

    
    if (sumarryOptions.length > 1) {
      return (
        <div>
          {subSet1}
          <Popover
            isOpen={showPopover}
            place="left"
            preferPlace="left"
            style={{
              zIndex: '1000',
            }}
            tipSize={10}
            onOuterAction={() => setShowPopover(false)}
            body={
              <SummayContent width={1} flexDirection="column">
                {sumarryOptions.map((text, index) => {
                  if (index !== selectedSummaryIndex) {
                    return (
                      <a
                        key={index}
                        href="javascript:void(0);"
                        onClick={() => setSelectedSummaryIndex(index)}
                      >
                        {text}
                      </a>
                    );
                  }
                  return null;
                })}
              </SummayContent>
            }
          >
            <span
              key="summay-highlight"
              ref={dynamicSummaryRef}
              className="summay-highlight"
              onClick={() => setShowPopover(true)}
            >
              {sumarryOptions[selectedSummaryIndex]}
            </span>
          </Popover>
          {subSet3}
        </div>
      );
    }
    return get(sumarryOptions, selectedSummaryIndex);
  };

  return (
    <>
      {editable ? (
         <EditableContentWrapper
          ref={domRef}
          onInput={contentChange}
          onKeyDown={onKeyPress}
          onPaste={onPaste}
          contentEditable={!isFetchingSummary}
          styles={style}
          onFocus={onContentFocus}
          onBlur={onContentBlur}
          height={height}
          placeholder={placeholder}
          style={{whiteSpace: 'pre-wrap'}}
        >
          {!showPlaceholder && !appendSpanToTheSummary(intialContent) ? intialContent : appendSpanToTheSummary(intialContent)}
        </EditableContentWrapper>
      ) : 
        <div style={{display: 'flex', height: '85px', maxHeight: '85px', overflowX: 'hidden', overflowY: 'auto', textAlign: 'left'}}>
          {!showPlaceholder && intialContent}
        </div>
      }
      
    </>
  )
}

export default SummaryTextFields;
