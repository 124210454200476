import React, { useState } from 'react';
import { Formik } from 'formik';
import { Flex } from '@rebass/grid';
import {
  EmailModalBody,
  EmailModalBox,
  EmailModalHeader,
  EmailModalInput,
  RadioButtonList,
  EmailSettingModal,
} from './EmailSettingsModal.styles';
import Radio from '../RadioButton/RadioButton';
import { ModalButton, ModalFooter, ModalCloseButton } from '../Modal';
import { ErrorContainer } from '../CommonStyles';

const EmailSettingsModal = ({ open, handleClose, handleModalSubmit, emailErrorMessage }) => {
  const [portSmtp, setPort] = useState('');
  
  const handlePortChange = (port) => {
    console.log(port)
    setPort(port);
  }

  const handleSubmit = (payload) => {
    payload.port = portSmtp;
    console.log(portSmtp);
    handleModalSubmit(payload);
  }
  return (
    <EmailSettingModal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
      style={{
        overlay: {
          backgroundColor: '#000000d1',
        },
      }}
    >
      <EmailModalHeader>Email Settings </EmailModalHeader>
      <EmailModalBody>
        <Formik
          initialValues={{
            name: '',
            email: '',
            username: '',
            password: '',
            smtpHost: '',
            smtpPort: ''
          }}
          onSubmit={handleSubmit}
          // validationSchema={loginShema}
        >
          {({
            setFieldValue,
            handleSubmit,
            handleChange,
            values,
            errors = {},
          }) => (
            <form name="emailsettings">
              <Flex width={1} justifyContent="center">
                <EmailModalBox>
                  <EmailModalInput
                    largeInput
                    name="name"
                    id="name"
                    type="text"
                    placeholder="From name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name}
                  />
                  <EmailModalInput
                    largeInput
                    name="username"
                    id="username"
                    type="text"
                    placeholder="Username"
                    onChange={handleChange}
                    value={values.username}
                    error={errors.username}
                  />
                  <EmailModalInput
                    largeInput
                    name="smtpHost"
                    id="smptpHost"
                    type="text"
                    placeholder="SMTP Host"
                    onChange={handleChange}
                    value={values.smtpHost}
                    error={errors.smtpHost}
                  />
                </EmailModalBox>
                <EmailModalBox>
                  <EmailModalInput
                    largeInput
                    name="email"
                    id="email"
                    type="text"
                    placeholder="From Email"
                    onChange={handleChange}
                    value={values.email}
                    error={errors.email}
                  />
                  <EmailModalInput
                    largeInput
                    name="password"
                    id="password"
                    type="password"
                    placeholder="Password"
                    onChange={handleChange}
                    value={values.password}
                    error={errors.password}
                  />
                  <Flex justifyContent="space-between" alignItems="center">
                    <EmailModalInput
                      largeInput
                      name="smtpPort"
                      id="smtpPort"
                      type="text"
                      placeholder="smtpPort"
                      onChange={handleChange}
                      value={values.smtpPort}
                      error={errors.smtpPort}
                    />
                    <RadioButtonList>
                      <Radio
                        label="SSL"
                        id="SSL"
                        name="port"
                        value="SSL"
                        checked={values.port === 'ssl'}
                        onChange={() => handlePortChange('ssl')}
                      />
                      <Radio
                        label="TLS"
                        id="TLS"
                        name="port"
                        value="TLS"
                        checked={values.port === 'tls'}
                        onChange={() => handlePortChange('tls')}
                      />
                      <Radio
                        label="None"
                        id="None"
                        name="port"
                        value="NONE"
                        checked={values.port === 'None'}
                        onChange={() => handlePortChange('None')}
                      />
                    </RadioButtonList>
                  </Flex>
                </EmailModalBox>
              </Flex>
              <ModalButton type="submit" onClick={handleSubmit} upperCase>
                Save Settings
              </ModalButton>
            </form>
          )}
        </Formik>
      </EmailModalBody>
      <ModalFooter>
        <ModalCloseButton onClick={handleClose} />
        {emailErrorMessage && emailErrorMessage.length > 0 && <ErrorContainer style={{textAlign: 'center'}}>{emailErrorMessage}</ErrorContainer>}
      </ModalFooter>
    </EmailSettingModal>
  );
};

export default EmailSettingsModal;
