/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Flex } from '@rebass/grid';
import { Button } from '../../components/index';
import moment from 'moment';
import _, { get, toLower, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { DateDropdown } from '../../components/';
import {
  SelectContainer,
  SelectBox,
} from '../../components/SortTable/SortTable.styles';
import Slider from 'react-slick';
import {
  ComplianceSliderWrapper,
  AdminSubTab,
  DashboardAdminSubTab,
} from '../DashboardContainer/DashboardContainer.styles';
import {
  TabsThree,
  TabListThree,
  TabPanel,
} from '../../components/Tabs/TabsTypeThree';
import {
  ArticleContainer,
  ArticleContent,
} from '../../components/ArticleBox/ArticleBox';
import ArticleList from '../../components/ArticleList/ArticleList';
import { Image } from '../../components/ArticleList/ArticleList.styles';

import ComplianceSortTable from '../../components/SortTable/ComplianceSortTable';
import { actions as complianceActions } from '../../redux/modules/compliance.module';
import {
  getComplianceArticles,
  postComplianceNotification,
} from '../../services/dashboard';
import { List } from '../ChannelContainer/Channel.styles';
import { getRoleType } from '../../redux/selector';
import {
  ROLE_TYPES,
  getMediaUrl,
  getAuxilaryChannelId,
  formatMomentIsoDate,
} from '../../utils';
import { useHistory } from 'react-router-dom';
import { ToggleButton } from '../ConfigureContainer/screenConfigure.styles';
import { actions as loginActions } from '../../redux/modules/login.module';
import {
  ComplianceTooltip,
  DownloadPDFContainer,
  ExportPDFButton,
} from './screenContainer.styles';

import { actions as complianceTrailActions } from '../../redux/modules/compliance-trail.module';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ExportModal from '../ComplianceContainer/exportModal';



const Articles = {
  REVIEW: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

const styles = {
  tabTypeThree: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  adminSubTab: {
    fontSize: '14px',
  },
  tabsThree: {
    margin: '0 0 20px',
  },
};

const ComplianceContainer = ({
  complianceRequest,
  showComplianceActions,
  showAdminActions,
  adminPermission,
  userDetails,
  loginRequest,
  isPremiumAdvisor,
  complianceTrailRequest,
  complianceTrailDetails,
}) => {
  const [articles, setArticles] = useState(null);
  const [exportModal, setExportModal] = useState(false);
  const [themeArticles, setThemeArticles] = useState(null);
  const [publisherArticles, setPublisherArticles] = useState(null);

  const [currTab, setCurrTab] = useState('pending');
  const [customDate, setCustomDate] = useState({ selectedDate: 'Last 7 days' });
  const [themes, setThemes] = useState(null);
  const [publishers, setPublishers] = useState(null);
  const [selectedSubTab, setSelectedSubTab] = useState(0);
  const [pagination, setPagination] = useState(null);
  const [compliance, setCompliance] = useState(
    get(userDetails, 'compliance', '')
  );
  const [emailNotification, setEmailNotification] = useState(false);

  const complianceApiDate = (selectedDate, fromDate, toDate, source) => {
    const currentMomentDate = moment();
    const currentFormattedDate = formatMomentIsoDate(moment());
    let startDate = '';
    let endDate = '';

    if (selectedDate) {
      if (toLower(selectedDate) === 'last 30 days') {
        startDate = formatMomentIsoDate(currentMomentDate.subtract(29, 'd'));
        endDate = currentFormattedDate;
      } else if (toLower(selectedDate) === 'last 7 days') {
        startDate = formatMomentIsoDate(currentMomentDate.subtract(6, 'd'));
        endDate = currentFormattedDate;
      }
    }

    if (source === 'export' && fromDate && toDate) {
      startDate = fromDate;
      endDate = toDate;
    }
    return { startDate: startDate, endDate: endDate };
  };

  const handleDateChange = (val) => {
    setCustomDate(val);
    setDetails(currTab, undefined, val);
  };

  const handleSubTabChange = (index) => setSelectedSubTab(index);

  const handleEmailNotification = (checked) => {
    setEmailNotification(checked);
    postComplianceNotification({ active: checked ? 'true' : 'false' })
      .then((res) => {
        loginRequest({ noRefresh: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setDetails = (type, page, customDate) => {
    setArticles(null);
    setThemes(null);
    setPublishers(null);

    let complianceDate =
      customDate == null
        ? undefined
        : customDate && !customDate.fromDate
        ? complianceApiDate(customDate.selectedDate)
        : { startDate: customDate.fromDate, endDate: customDate.toDate };
    console.log(complianceDate, customDate);
    getComplianceArticles(type, page, complianceDate)
      .then((res) => {
        console.log(res);
        for (let key of Object.entries(res.result.data.details.theme)) {
          _.assign(res.result.data.details.theme[key[0]], { id: key[0] });
        }
        for (let key of Object.entries(res.result.data.details.account)) {
          _.assign(res.result.data.details.account[key[0]], { id: key[0] });
        }


        setThemes(_.values(res.result.data.details.theme));
        let publishers = _.values(res.result.data.details.account);
        setPublishers(publishers.filter((p) => p.type === 'channel'));
        // setMembers([]);
        setPagination(res.result.data.pagination);
        setArticles(res.result.data);
      })
      .catch((err) => console.log(err));
  };

  const handleTabChange = (value) => {
    setThemeArticles(null);
    setPublisherArticles(null);

    let type = '';
    type =
      value === 1
        ? Articles.APPROVED
        : value === 2
        ? Articles.REJECTED
        : Articles.REVIEW;

    setCurrTab(type);
    setDetails(type, undefined, customDate);
  };

  const handlePageChange = (page) => {
    setThemeArticles(null);
    setPublisherArticles(null);

    setDetails(currTab, pagination.page);
  };

  const handleThemeClick = (theme) => {
    let filteredArticles = { ...articles };
    console.log('filtered Articles', articles);
    filteredArticles.compliance_content = _.filter(
      [...articles.compliance_content],
      (article) => {
        return (
          article.ids.theme[0].id === theme.id ||
          article.ids.theme[0] === theme.id
        );
      }
    );
    setThemeArticles({ theme, articles: filteredArticles });
  };

  const handlePublisherClick = (publisher) => {
    let filteredArticles = { ...articles };
    filteredArticles.compliance_content = _.filter(
      [...articles.compliance_content],
      (article) => {
        return getAuxilaryChannelId(article) === publisher.id;
      }
    );
    setPublisherArticles({ publisher, articles: filteredArticles });
  };

  const clearThemeFilter = () => {
    setThemeArticles(null);
  };

  const clearPublisherFilter = () => {
    setPublisherArticles(null);
  };
 

  useEffect(() => {
    handleTabChange(0);
    if (
      userDetails &&
      userDetails.details &&
      userDetails.details.user &&
      userDetails.details.user.extra_settings &&
      userDetails.details.user.extra_settings.notification_email === 'true'
    ) {
      setEmailNotification(true);
    }
  }, []);

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };
  const history = useHistory();
  const goToProfilePage = (index) => {
    window.sessionStorage.removeItem('selectedIndex');
    history.push('/admin/configure');
    window.sessionStorage.setItem('selectedIndex', index);
  };


  const TabContent = (
    hasActions,
    hasAdmin,
    tabType,
    hasAdminPermission,
    openArticle,
    currentTab
  ) => {
    return (
      <>
        <ArticleContainer
          style={{
            background: '#f5f7fb',
            margin: '5px',
          }}
        >
          <TabsThree onSelect={handleSubTabChange} style={styles.tabsThree}>
            <TabListThree
              style={{
                background: '#f5f7fb',
              }}
            >
              <DownloadPDFContainer>
                {currTab === 'pending' && (
                  <ExportPDFButton
                    onClick={() => {
                      if (articles?.dataCount && articles?.dataCount > 0)
                        setExportModal(true);
                    }}
                    disabled={articles?.dataCount && articles?.dataCount > 0}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      color="#6351ed"
                      size="1x"
                    />
                    <div style={{ marginLeft: '5px' }}>Export To PDF</div>
                  </ExportPDFButton>
                )}
              </DownloadPDFContainer>
              <SelectContainer>
                <SelectBox>
                  <DateDropdown
                    value={
                      !isEmpty(customDate) && customDate.selectedDate
                        ? customDate.selectedDate
                        : ''
                    }
                    position="static"
                    type="complaince"
                    selectedDate={customDate}
                    onDateChange={handleDateChange}
                  />
                </SelectBox>
              </SelectContainer>
            </TabListThree>

            <TabPanel>
              <ComplianceSortTable
                tabType={tabType}
                data={!!themeArticles ? themeArticles.articles : articles}
                handlePageChange={handlePageChange}
                hasActions={hasActions}
                handleActions={handleTabChange}
                hasAdmin={hasAdmin}
                hasAdminPermission={hasAdminPermission}
                openArticle={openArticle}
                showComplianceActions={showComplianceActions}
                currentTab={currentTab}
                date={customDate}
                setDetails={setDetails}
                complianceTrailRequest={complianceTrailRequest}
                complianceTrailDetails={complianceTrailDetails}
              />
              {currTab === 'pending' && showComplianceActions && (
                <Flex justifyContent="center" alignItems="center">
                  <ComplianceTooltip
                    style={{ paddingTop: '8px', fontSize: '12px' }}
                  >
                    Notification Emails{' '}
                    <span className="compliance-tooltip-text">
                      Turn off/on daily notification emails for pending Review
                      items
                    </span>
                  </ComplianceTooltip>
                  <ToggleButton
                    className="react-switch"
                    onChange={handleEmailNotification}
                    checked={emailNotification}
                    onColor="#6351ed"
                    offColor="#ccc"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    handleDiameter={10}
                    uncheckedIcon=""
                    checkedIcon=""
                    boxShadow=""
                    activeBoxShadow=""
                    type="autoPost"
                    left="0px"
                    margin="0px 5px 0px"
                  />
                </Flex>
              )}
            </TabPanel>
            <TabPanel>
              <ComplianceSortTable
                data={
                  !!publisherArticles ? publisherArticles.articles : articles
                }
                tabType={tabType}
                handlePageChange={handlePageChange}
                hasActions={hasActions}
                hasAdmin={hasAdmin}
                hasAdminPermission={hasAdminPermission}
                openArticle={openArticle}
                showComplianceActions={showComplianceActions}
                currentTab={currentTab}
                date={customDate}
                setDetails={setDetails}
                complianceTrailRequest={complianceTrailRequest}
                complianceTrailDetails={complianceTrailDetails}
              />
              {currTab === 'pending' && showComplianceActions && (
                <Flex justifyContent="center" alignItems="center">
                  <ComplianceTooltip
                    style={{ paddingTop: '8px', fontSize: '12px' }}
                  >
                    Notification Emails
                    <span className="compliance-tooltip-text">
                      Turn off/on daily notification emails for pending Review
                      items
                    </span>
                  </ComplianceTooltip>
                  <ToggleButton
                    className="react-switch"
                    onChange={handleEmailNotification}
                    checked={emailNotification}
                    onColor="#6351ed"
                    offColor="#ccc"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    handleDiameter={10}
                    uncheckedIcon=""
                    checkedIcon=""
                    boxShadow=""
                    activeBoxShadow=""
                    type="autoPost"
                    left="0px"
                    margin="0px 5px 0px"
                  />
                </Flex>
              )}
             
            </TabPanel>
          
          </TabsThree>
        </ArticleContainer>
      </>
    );
  };
  return !showComplianceActions && compliance === 'off' ? (
    <Flex
      alignItems="center"
      justifyContent="center"
      style={{ width: '100%', background: '#f5f7fb', height: '50vh' }}
    >
      <Button onClick={() => goToProfilePage(4)}>
        Enable Compliance Feature
      </Button>
    </Flex>
  ) : (
    <>
      <TabsThree
        onSelect={handleTabChange}
        style={{
          background: '#f5f7fb',
          border: '2px solid #e4e4e4',
          borderRadius: '6px',
        }}
      >
        <TabListThree
          style={{
            background: '#f5f7fb',
          }}
        >
          <DashboardAdminSubTab
            color="#49484a"
            padding="10px 0px"
            margin="0px 14px"
            className={`${currTab === 'pending' ? 'active' : ''}`}
            style={{
              fontWeight: '600',
              fontSize: '13px',
              top: '1px',
              position: 'relative',
            }}
            data-testid="pending-review"
          >
            PENDING REVIEW
          </DashboardAdminSubTab>
          <DashboardAdminSubTab
            color="#49484a"
            padding="10px 0px"
            margin="0px 14px"
            className={`${currTab === 'approved' ? 'active' : ''}`}
            style={{
              fontWeight: '600',
              fontSize: '13px',
              top: '1px',
              position: 'relative',
            }}
            data-testid="approved"
          >
            APPROVED
          </DashboardAdminSubTab>
          <DashboardAdminSubTab
            color="#49484a"
            padding="10px 0px"
            margin="0px 14px"
            className={`${currTab === 'rejected' ? 'active' : ''}`}
            style={{
              fontWeight: '600',
              fontSize: '13px',
              top: '1px',
              position: 'relative',
            }}
            data-testid="rejected"
          >
            REJECTED
          </DashboardAdminSubTab>
        </TabListThree>
        <div
          style={{
            background: '#f5f7fb',
          }}
        >
          <hr
            style={{
              margin: '0px auto',
              width: '90%',
              borderTop: '1px solid#d1dae5',
            }}
          />
        </div>
        <TabPanel
          style={{
            background: '#f5f7fb',
          }}
        >
          {TabContent(
            showComplianceActions,
            null,
            null,
            false,
            adminPermission,
            currTab
          )}
        </TabPanel>
        <TabPanel
          style={{
            background: '#f5f7fb',
          }}
        >
          {TabContent(null, null, null, false, true, currTab)}
        </TabPanel>
        <TabPanel
          style={{
            background: '#f5f7fb',
          }}
        >
          {TabContent(
            null,
            showAdminActions,
            'rejected',
            adminPermission,
            showComplianceActions,
            currTab
          )}
        </TabPanel>
      </TabsThree>
      {exportModal ? (
        <ExportModal
          handleModalClose={() => {
            setExportModal(false);
          }}
          dates={complianceApiDate(
            customDate.selectedDate,
            customDate.fromDate,
            customDate.toDate,
            'export'
          )}
          status={currTab}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const roleType = getRoleType(state);
  const isPremiumAdvisor = getRoleType(state) === ROLE_TYPES.ADVISOR_PREMIUM;
  const showComplianceActions = ROLE_TYPES.ADMIN_COMPLIANCE === roleType;
  const showAdminActions =
    ROLE_TYPES.ADMIN_ENTERPRISE === roleType ||
    ROLE_TYPES.ADMIN_COMPLIANCE === roleType;

  console.log('showAdminActions: ', showAdminActions);
  console.log('showComplianceActions: ', showComplianceActions);
  return {
    complianceData: state.compliance.data,
    showComplianceActions,
    showAdminActions,
    adminPermission: get(state, 'user.data.permissions.name', '') === 'Admin',
    userDetails: state.user.data,
    isPremiumAdvisor: isPremiumAdvisor,
    complianceTrailDetails: state.complianceTrail,
  };
};

const mapDispatchToProps = {
  complianceRequest: complianceActions.api.compliance.request,
  loginRequest: loginActions.api.login.request,
  complianceTrailRequest: complianceTrailActions.api.complianceTrail.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplianceContainer);
