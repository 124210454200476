import React from 'react';
import { Flex, Box } from '@rebass/grid';
import { isEmpty, toLower } from 'lodash';
import { ButtonNoBorderBackground, ResponsiveImg } from '../';

import { FontAwesome, ImageSpan } from './SocialLoginButtons.styles';

const SocialLoginButtons = ({ buttons, small, pointer, onBottomTextClick }) => {
  const onBackButtonClick = (e, platform, emailPlaftform) => {
    e.stopPropagation();
    onBottomTextClick(platform, emailPlaftform);
  };

  if (!isEmpty(buttons)) {
    return (
      <Flex
        width={1}
        justifyContent={small ? 'center' : 'space-between'}
        //flexWrap="wrap"
        //width="80%"
        //marginLeft="auto"
        //marginRight="auto"
      >
        {buttons.map((button) => {
          return (
            <Box key={button.name} width={1} is="a">
              <ButtonNoBorderBackground onClick={button.onClick} type="button" style={{cursor: 'unset'}}>
                {button.faIcon && (
                  <FontAwesome
                    icon={button.faIcon}
                    small={small}
                    pointer={pointer}
                    isDiconnected={!button.isDiconnected}
                    title={button.name}
                    style={{width: '1.5rem'}}
                  />
                )}
                {button.imgUrl && (
                  <ImageSpan
                    iconHeight={button.iconHeight}
                    
                    isDiconnected={!button.isDiconnected}
                  >
                    <ResponsiveImg src={button.imgUrl} small={small} style={{cursor: 'pointer', width: button.iconWidth ? `${button.iconWidth}%`: ''}} />
                  </ImageSpan>
                )}
                {button.bottomText && (
                  <div>
                    <ButtonNoBorderBackground
                      style={{ fontFamily: 'Poppins', position: 'relative' }}
                      title={button.name}
                      // onClick={(e) =>
                      //   onBackButtonClick(
                      //     e,
                      //     button.platform,
                      //     button.emailPlaftform
                      //   )
                      // }
                      onClick={button.onClick}
                    >
                      {button.bottomText}
                    </ButtonNoBorderBackground>
                  </div>
                )}
                <div class="tool-tip" style={{ padding: '0px 6px' }}>
                  <div
                    style={{
                      marginTop: '5px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      maxWidth: '63px',
                    }}
                  >
                    {button.account}
                  </div>
                  <div>
                    {!isEmpty(button.account) && button.account.length > 10 ? (
                      <span className="tooltip-text">{button.account}</span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </ButtonNoBorderBackground>
            </Box>
          );
        })}
      </Flex>
    );
  }
  return null;
};

export default SocialLoginButtons;
