import { Flex } from "@rebass/grid";
import styled from "styled-components";

export const LoginOktaWrapper = styled.div`
    .auth-container {
        margin: 0 auto !important;
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
        font-family: Poppins, sans-serif !important;
        z-index: 2;
        
        .okta-sign-in-header,
        .okta-form-title {
            display: none;
        }

        input[type='text'],
        input[type='password'] {
          font-family: Poppins !important;
        }

        .focused-input,
              input[type='text']:focus,
              input[type='password']:focus,
              input[type='submit']:focus {
                border: 1px solid #6351ed !important;
                box-shadow: unset !important;
              }
              
        .focused-input,
            input[type='checkbox']:focus {
                border: unset !important;
                box-shadow: unset !important;
            }
        
        label {
            color: #6351ed;
        }

        .o-form-label {
            text-align: left;
        }

        .custom-checkbox label {
            text-align: left;
        }
        
        .button,
        input[type='submit'] {
            border-radius: 0.25rem !important;
            font-family: inherit !important;
            font-weight: 500 !important;
            line-height: 1.75 !important;
            height: unset !important;
            padding: 6px 1rem !important;
            background: #6351ed !important;
            box-shadow: none !important;
            border: 1px solid #6351ed !important;

            &.email-button {
               margin-top: 2rem !important;
            }
        }
    }
`;


export const LoginBanner = styled(Flex)`
    font-size: 14px;
    background-color: #6351ed;
    color: #fff;
    width: 100%;
    padding: 10px;
    justify-content: center;
`;