/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@rebass/grid';
import React, {useState, useEffect} from 'react';
import NoContentLayout from '../../../../components/EmailTemplates/NoContentTemplate';
import TemplateSelector from '../../../../components/TemplateSelector';
import { FooterBtnsWrapper } from '../../../DripCampaignContainerV2/DripCampaignContainerV2.styles';
import CampaignFooterButtons from './CampaignFooterButtons';
import { get, isEmpty } from 'lodash';
import { updateCampaign } from '../../../../services/campaign'
import CustomUserTemplate from '../../../../components/EmailTemplates/CustomUserTemplate/CustomUserTemplate';
import { Button, Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../../components';

const SelectTemplate = ({
  handleNext,
  handleBack,
  loading,
  setLoading,
  defaultTemplates,
  setTemplateData,
  setTemplateBase,
  campaignId,
  campaignSetState,
  selectedBlock,
  selectedBlockId
}) => {
  const [selectedValue, setSelectedValue] = useState({
    name: '',
    baseTemplate: '',
    isCascade: false,
    owner: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const loneTemplate =
    !defaultTemplates ||
    defaultTemplates
      .filter(
        (temp) =>
          temp.name !== 'no_content' &&
          temp.name !== 'content' &&
          temp.original_template === 'no-content'
      )
      .sort((a, b) => new Date(b.created_time) - new Date(a.created_time))
      .length === 0;

  const handleValidation = () => {
    return selectedValue.name.length > 0;
  };

  console.log(selectedValue);

  useEffect(() => {
    if (setTemplateData) {
      if (
        setTemplateData === 'no-content' ||
        setTemplateData === 'one-column' ||
        setTemplateData === 'two-columns'
      )
        setSelectedValue({
          name: setTemplateData,
          baseTemplate: setTemplateData,
          isCascade: false,
          owner: 'default',
          id: 1
        });
      else {
        const selectedTemplate = defaultTemplates.find(temp => temp.name === setTemplateData);

        const base_template = selectedTemplate ? selectedTemplate.original_template : '';
        const is_cascade = selectedTemplate ? selectedTemplate.is_cascade : '';
        const owner = selectedTemplate ? selectedTemplate.owner : '';
        setSelectedValue({
          name: selectedTemplate ? setTemplateData : '', 
          baseTemplate: base_template, 
          isCascade: is_cascade,
          owner: owner,
          id: selectedTemplate.id
        });
      }
    } else if(selectedBlock && selectedBlock.template_id) {
      console.log(selectedBlock);
      let selectedTemplate;
      if(selectedBlock.template_id === 1) {
        selectedTemplate = defaultTemplates.find(temp => temp.name === 'no_content');
      } else {
        selectedTemplate = defaultTemplates.find(temp => temp.id === selectedBlock.template_id);
      } 
      console.log(selectedTemplate);
      const base_template = selectedTemplate && selectedTemplate.name === 'no_content' ? 'no-content' : selectedTemplate && selectedTemplate.original_template ? selectedTemplate.original_template : '';
      const is_cascade = selectedTemplate ? selectedTemplate.is_cascade : '';
      const owner = selectedTemplate ? selectedTemplate.owner : '';
      setSelectedValue({
        name: selectedTemplate && selectedTemplate.name === 'no_content' ? 'no-content' : selectedTemplate.name, 
        baseTemplate: base_template, 
        isCascade: is_cascade,
        owner: owner,
        id: selectedTemplate.id
      });
    }
  }, []);


  const handleNextClick = () => {
    setLoading(true);
    console.log('selectedValue',selectedValue);
    console.log(selectedBlock);
    if((selectedBlock && selectedBlock.template_id === selectedValue.id) || !selectedBlock.template_id) {
      const selectedTemplate = defaultTemplates.find(template => {
        if(selectedValue.name === 'no-content')
          return template.name === 'no_content'
        else if(selectedValue.name === 'one-column' || selectedValue.name === 'two-columns')
          return template.name === 'content';
        else 
          return template.name === selectedValue.name;
      });
  
      if (setTemplateData !== selectedValue.name) {
        campaignSetState({
          savedTemplate: null,
          setTemplate: selectedValue.name,
          setBaseTemplate: selectedValue.baseTemplate,
          setIsCascade: selectedValue.isCascade,
          owner: selectedValue.owner,
          selectedBlock: {
            ...selectedBlock,
            template: selectedTemplate.name,
            template_id: selectedTemplate.id,
          }
        });
      } 
      else
        campaignSetState({
          setTemplate: selectedValue.name,
          setBaseTemplate: selectedValue.baseTemplate,
          setIsCascade: selectedValue.isCascade,
          owner: selectedValue.owner,
          selectedBlock: {
            ...selectedBlock,
            template: selectedTemplate.name,
            template_id: selectedTemplate.id,
          },
        });
  
      // const templateId = selectedTemplate.id;
      // const payloadObj = {
      //   default_template: selectedValue.name,
      //   template_id: templateId
      // };
  
      // if(selectedTemplate.is_cascade)
      //   payloadObj.cascade_template_ref = selectedTemplate.cascade_template_ref
  
      // const baseTemplate = defaultTemplates
      //   .filter(temp => temp.created_by === 'user')
      //   .find(temp => temp.name === selectedValue).original_template;
  
      // const payload = {
      //   content: JSON.stringify(payloadObj),
      // };
  
      handleNext();
    } else {
      setOpenModal(true);
    }

    
  };

  const closeModal = () => {
    setOpenModal(false);
  }

  const handleChangeTemplate = () => {
    campaignSetState({
      setTemplate: selectedValue.name,
      setBaseTemplate: selectedValue.baseTemplate,
      setIsCascade: selectedValue.isCascade,
      owner: selectedValue.owner,
      selectedBlock: {
        ...selectedBlock,
        template: selectedValue.name,
        template_id: selectedValue.id,
        template_json: null,
        template_html: ''
      }
    });
    handleNext();
  }

  console.log('selected value', selectedValue);
  return (
    <Flex width="100%" padding="10px" flexDirection="column">
      <Flex
        width="100%"
        fontSize="14px"
        color="#969fa4"
        style={{ fontWeight: 600, fontSize: '1rem' }}
        justifyContent="center"
        mb="10px"
      >
        Choose A Template For This Newsletter
      </Flex>
      <Flex justifyContent="flex-start" padding="10px 0px">
        <TemplateSelector
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          loneTemplate={loneTemplate}
        >
          <NoContentLayout
            value={{
              name: 'no-content',
              baseTemplate: 'no-content',
              owner: 'default',
              id: 1
            }}
            isCampaign={true}
            id="no_template"
            loneTemplate={loneTemplate}
          />

          {defaultTemplates &&
            defaultTemplates
              .filter(
                (temp) => temp.name !== 'no_content' && temp.name !== 'content'
              )
              .sort(
                (a, b) => new Date(b.created_time) - new Date(a.created_time)
              )
              .map((template) => (
                <CustomUserTemplate
                  template={template}
                  value={{
                    name: get(template, 'name', ''),
                    baseTemplate: get(template, 'original_template', ''),
                    isCascade: get(template, 'is_cascade', false),
                    owner: get(template, 'owner', ''),
                    id: get(template, 'id', '')
                  }}
                  isCampaign={true}
                  owner={get(template, 'owner', '')}
                />
              ))}
        </TemplateSelector>
      </Flex>
      {openModal && (
        <Modal size='flexible' isOpen={openModal} onRequestClose={closeModal}>
          <ModalHeader>
            <span style={{ margin: '30px' }}>Changes will be lost!! Do you want to proceed?</span>
            <ModalCloseButton onClick={closeModal} />
          </ModalHeader>
          <ModalBody>
            <Flex width="100%" justifyContent="center">
              <Button
                onClick={handleChangeTemplate}
                width="75px"
                borderRadius="4px"
                margin="7px 12px 0px 0px"
              >
                Yes
              </Button>
              <Button
                onClick={closeModal}
                width="75px"
                borderRadius="4px"
                margin="7px 12px 0px 0px"
              >
                No
              </Button>
            </Flex>
          </ModalBody>
        </Modal>
      )}
      <FooterBtnsWrapper>
        <CampaignFooterButtons
          enableNext={handleValidation()}
          nextHandler={handleNextClick}
          backHandler={handleBack}
          padding="10px 0px"
        />
      </FooterBtnsWrapper>
    </Flex>
  );
};
export default SelectTemplate;
