import styled from 'styled-components';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
import Switch from 'react-switch';

export const TabThree = styled(Tab)`
  width: 50% !important;
  font-weight: normal !important;
  left: 0 !important;
  display: inline-block !important;
  border-bottom: 0px solid #efefef !important;
  border-right: 0px solid #efefef;
  font-family: 'Poppins';
  cursor: pointer;
  float: left;
  position: relative;
  font-size: 14px !important;
  color: #6351ed !important;
  padding: 8px 0px !important;
  border-top-left-radius: 6px;
  font-weight: ${({ type }) =>
    type === 'admin' ? '600 !important' : 'normal'};

  &:nth-child(even) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: #e4e4e5;
  }
  &:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 6px;
    background-color: #e4e4e5;
  }

  &.react-tabs__tab--selected:after {
    color: ${({ theme }) => (theme === 'light' ? '#6351ed' : '#fff')};
  }
  &.react-tabs__tab--selected {
    background-color: ${({ theme }) =>
      theme === 'light' ? '#d5cce6' : '#fff'};
  }
`;

export const ToggleButton = styled(Switch)`
  margin: ${({ margin }) => margin || '10px 0 0'};
  top: ${({ top }) => top || '4px'};
  left: ${({ left }) => left || '5px'};
  .react-switch-bg {
    width: ${({ type }) => type === 'autoPost' ? '24px !important' : '43px !important'};
    height: ${({ type }) => type === 'autoPost'? '15px !important' : '15px !important'};
    top: ${({ type }) => type === 'configure' ? '3px' : '0px'};
  }
  .react-switch-handle {
    top: ${({ type, switchHandleTop }) => switchHandleTop ? switchHandleTop : type === 'autoPost' ? '2.4px !important' : type === 'configure' ? '5px !important' : '1px !important'};
    left: ${({ type }) => type === 'autoPost' ? '-10px' : '-6px'}; 
    transform: ${({ type, checked }) => type === 'autoPost' ? checked ? 'translateX(21px) !important' : 'translateX(12px) !important' : ''};
  }
`;

export const TabConfig = styled(TabPanel)`
  background: ${({ background }) => background || `linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 48%,
    rgba(228, 228, 228, 1) 100%)`}; 
  border: ${({ noBorder }) => noBorder ? 'none' : '1px solid #e4e4e4'};
  border-top: 0;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-height: ${({ minHeight }) => minHeight || '600px'};
  display: none;
  &.react-tabs__tab-panel--selected {
    display: block;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;

export const AdminListItem = styled.li`
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
  list-style-type: none;
  border-radius: 7px;
  border: 1px solid #959595;
  height: 30px;
  text-align: left;
  padding: 2px 14px 0px 4px;
  background: #e4e4e4;
`;

export const AdminRadioButton = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  left: -9999px;
  &:not(:checked) + label:before,
  &:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #b1b1b1;
    border-radius: 100%;
    background: #fff;
    margin: 0;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: ${({ bgColor }) => bgColor || '#6351ed'};
    position: absolute;
    top: 5px;
    transform: scale(0);
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;
export const AdminRadioLabel = styled.label`
  position: relative;
  padding-left: 23px;
  line-height: 20px;
  display: inline-block;
  color: #6351ed;
  font-family: 'Poppins';
  font-weight: 200;
  font-size: 13px;
`;

export const AdminRadioButtonList = styled.ul`
  display: flex;
  margin: 0;
  padding-left: 5px;
  padding-bottom: 12px;
`;
