import styled from 'styled-components';

export const ListItem = styled.li`
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
  list-style-type: none;
`;

export const RadioButton = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  left: -9999px;
  &:not(:checked) + label:before,
  &:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #b1b1b1;
    border-radius: 100%;
    background: #fff;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #6351ed;
    position: absolute;
    top: 5px;
    transform: scale(0);
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;
export const RadioLabel = styled.label`
  position: relative;
  padding-left: 23px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  font-weight: 700;
  font-size: 13px;
  font-family: 'Poppins';
`;
