/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@rebass/grid';
import React,{useEffect} from 'react';

import {
  Content,
  Disclaimer,
  Salutation,
  Template,
  TemplateHeader,
  TemplateSubTitle,
  TemplateTitle,
} from '../EmailTemplates.styles';

const NoContentLayout = ({ value, selectedValue, onChange, isCampaign, loneTemplate, fromNewsletter,id , flag}) => {
    console.log(selectedValue)
    useEffect(() => {
        if(flag){
            onChange(value);
        }
    },[]);

    return (
  <Box 
    width={fromNewsletter ? '30%' : '216px'} 
    marginBottom={!fromNewsletter ? '22px' : ''} 
    marginLeft={!fromNewsletter && !loneTemplate ? '22px' : ''}
    // style={fromNewsletter ? {maxWidth: '240px'} : {}}
  > 
    <div>
      <TemplateTitle>No Additional Content</TemplateTitle>
      <TemplateSubTitle>
        Send a newsletter with no additional selected content.
      </TemplateSubTitle>
    </div>
    <Template
      onClick={() => onChange(value)}
      border={
        ((fromNewsletter && value === selectedValue) || (!fromNewsletter && value.name === selectedValue.name)) ? '2px solid #6351ed' : '2px solid #adadad'
      }
      style={{
        backgroundColor: 'white',
        boxShadow:
          !fromNewsletter && value.name === selectedValue.name
            ? '2px 3px 10px rgb(0,0,0,0.2)'
            : 'none',
        borderRadius: '5px',
      }}
      id={id}
    >
      <TemplateHeader />
      <Salutation>Hi First_name,</Salutation>
      <div>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud
      </div>
      <Content height="80px">
        Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum
      </Content>
      <div>Signature</div>
      <Disclaimer>Disclaimer</Disclaimer>
    </Template>
  </Box>
)};

export default NoContentLayout;
