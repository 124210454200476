import { Flex } from '@rebass/grid';
import React, { useState } from 'react';

import { intersectionWith, isEmpty, isEqual } from 'lodash';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalV2,
} from '../../../../components';
import {
  HorizontalSeparatorSolid,
  FilterRow,
  FilterRowTitle,
  FilterRowChips,
} from './AnalyticsFilterPopup.styles';
import DateDropdown from './DateDropdown'
import { map, filter } from 'lodash';
import FilterContacts from './FilterContacts';
import CampaignAnalyticsDropdown from './CampaignAnalyticsDropDown/CampaignAnalyticsDropdown';
export default function AnalyticsFilterPopup({
  handleModalClose,
  setFilters,
  filters,
  analyticsSetState,
  selectedDate,
  selectedRepType,
  analyticsRequest,
  contactGroup,
  isAdmin,
  contactParam,
  campaignsParam,
  isApiRequest,
  leadType,
  campaignData
}) {
  // console.log('CAMPAIGNS PARAM (<filterPopup>) - -- -- ', campaignsParam);

  let platformArray = ['email', 'facebook', 'twitter', 'linkedin'];
  let frequencyArray = ['series', 'one_time'];
  let contactTypesArray = ['prospect', 'client'];
  let typeArray = ['regular', 'event']

  const [selectedContacts, setSelectedContacts] = useState(contactParam);
  const [selectedCampaigns, setSelectedCampaigns] = useState(campaignsParam);

  const filterByContactList = (id) => {
    console.log('id', id);

    if (id === 'default') {
      let defaultList = filter(contactGroup, function (o) {
        return o.contact_type === 'default';
      });

      let defaultIDs = defaultList.map((ids) => {
        return ids.id;
      });
      if (defaultIDs.every((ai) => selectedContacts.includes(ai))) {
        let repArray = selectedContacts.filter(
          (el) => !defaultIDs.includes(el)
        );

        setSelectedContacts([...repArray]);
      } else {
        setSelectedContacts([...selectedContacts, ...defaultIDs]);
      }
    } else if (id === 'client') {
      let clientList = filter(contactGroup, function (o) {
        return o.contact_type === 'client';
      });

      let clientIDs = clientList.map((ids) => {
        return ids.id;
      });

      if (clientIDs.every((ai) => selectedContacts.includes(ai))) {
        let repArray = selectedContacts.filter((el) => !clientIDs.includes(el));

        setSelectedContacts([...repArray]);
      } else {
        setSelectedContacts([...selectedContacts, ...clientIDs]);
      }
    } else if (id === 'prospect') {
      let prospectList = filter(contactGroup, function (o) {
        return o.contact_type === 'prospect';
      });

      let prospectIDs = prospectList.map((ids) => {
        return ids.id;
      });

      if (prospectIDs.every((ai) => selectedContacts.includes(ai))) {
        let repArray = selectedContacts.filter(
          (el) => !prospectIDs.includes(el)
        );

        setSelectedContacts([...repArray]);
      } else {
        setSelectedContacts([...selectedContacts, ...prospectIDs]);
      }
    } else if (
      id === 'all' &&
      contactGroup.length === selectedContacts.length
    ) {
      setSelectedContacts([]);
    } else if (
      id === 'all' &&
      contactGroup.length !== selectedContacts.length
    ) {
      setSelectedContacts(map(contactGroup, 'id'));
    } else if (!selectedContacts.includes(id)) {
      setSelectedContacts([...selectedContacts, id]);
    }
    else {
      const filterContacts = selectedContacts.filter((item) => item !== id);
      setSelectedContacts([...filterContacts]);
    }
    console.log("SELECTED::", selectedContacts)

  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    console.log('data', data);

    var object = [];
    data.forEach((value, key) => object.push(key));

    console.log('object', object);

    let frequency = intersectionWith(frequencyArray, object, isEqual);

    let target = intersectionWith(contactTypesArray, object, isEqual);

    let platform = intersectionWith(platformArray, object, isEqual);

    let type = intersectionWith(typeArray, object, isEqual);

    var contactGroup = selectedContacts

    setSelectedContacts(contactGroup)

    if (
      isEmpty(frequency) &&
      isEmpty(target) &&
      isEmpty(platform) &&
      isEmpty(type) &&
      contactGroup === []
    ) {
      setFilters();
      analyticsSetState();
    } else {
      setFilters({
        frequency: frequency,
        target: target,
        platform: platform,
        type: type
      });
      analyticsSetState(
        {
          filterParams: {
            frequencyParam: frequency,
            targetParam: target,
            platformParam: platform,
            typeParam: type,
            contactParam: selectedContacts,
            campaignsParam: selectedCampaigns
          }
        })
    }

    //Analytics Request
    const repType = selectedRepType
    analyticsRequest({
      params: {
        repType,
        ...(repType === 'leadgen_data' && {leadType: leadType})
      },
    });

    handleModalClose();
  };

  const deselectFilterHandler = () => {
    const repType = selectedRepType
    console.log('CONTACTS PARAM - - - ', contactParam, 'CAMPAIGNS PARAM - - - ', campaignsParam);
    if(!isEmpty(contactParam) || !isEmpty(campaignsParam)) {
      analyticsSetState({
        filterParams: { 
          contactParam: [], 
          campaignsParam: [] 
        }
      });
      analyticsRequest({
        params: {
          repType,
          ...(repType === 'leadgen_data' && {leadType: leadType})
        },
      });
    }
    handleModalClose();
  }

  return (
    <ModalV2
      // onRequestClose={handleModalClose}
      shouldCloseOnOverlayClick
      isOpen
      size="flexible"
      bgColor="#fff"
      padding="0px"
      width="40%"
    >
      <form onSubmit={handleSubmit}>
        <ModalHeader fontSize="16px" fontColor="#000">
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex>
              <span>Filter</span>
            </Flex>
            <Flex>
              <ModalCloseButton
                onClick={handleModalClose}
                color="#000"
                size={'17px'}
              />
            </Flex>
          </Flex>
        </ModalHeader>
        <HorizontalSeparatorSolid />
        <ModalBody>
          <Flex display="flex" flexDirection="column">
            {/* Email List */}
            <FilterRow display="flex" flexDirection="row">
              <FilterRowTitle width="25%">Campaign</FilterRowTitle>
              <FilterRowChips style={{padding: '0px 15px'}} width="75%">
                <CampaignAnalyticsDropdown 
                  campaignData={campaignData} 
                  selectedCampaigns={selectedCampaigns}
                  setSelectedCampaigns={setSelectedCampaigns}
                />
              </FilterRowChips>
            </FilterRow>

            {/* Date */}
            <FilterRow display="flex" flexDirection="row">
              <FilterRowTitle width="25%">Date</FilterRowTitle>
              <FilterRowChips width="75%" style={{postion: 'relative'}}>
                <DateDropdown
                  value={selectedDate}
                  analyticsSetState={analyticsSetState}
                  bottom="18%"
                  left="5% !important"
                  zoom="1.3"
                  noIcon 
                  dpRight="7%"
                />
              </FilterRowChips>

            </FilterRow>

            <FilterRow display="flex" flexDirection="row">
              <FilterRowTitle width="25%">Email List</FilterRowTitle>
              <FilterRowChips width="75%">
                <FilterContacts
                  isAdmin={isAdmin}
                  left="5%"
                  handleClick={filterByContactList}
                  contactGroup={contactGroup}
                  selectedContacts={selectedContacts}
                  bottom="20%"
                />
              </FilterRowChips>

            </FilterRow>
          </Flex>
        </ModalBody>
        <HorizontalSeparatorSolid />
        <Flex
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            testId="filterModal-cancel"
            onClick={handleModalClose}
            // onClick={deselectFilterHandler}
            margin="10px"
            borderRadius="5px"
            bgColor="#fff"
            color="#708892"
            borderColor="#708892"
          >
            <div
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span  style={{ marginRight: '8px' }}>Cancel</span>
              <img src="/assets/images/drip/close_grey.svg" alt="" />
            </div>
          </Button>
          <Button
            testId="filterModal-apply"
            margin="10px"
            borderRadius="5px"
            type="submit"
          >
            <div
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <img
                src="/assets/images/drip/done_white.svg"
                alt=""
                style={{ marginRight: '8px' }}
              />
              <span>Apply</span>
            </div>
          </Button>
        </Flex>
      </form>
    </ModalV2>
  );
}
