/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalCloseButton, ModalV2 } from '../../../components';
import StepStatus from './StepStatus/StepStatus';
import MainContentComponent from './MainContentComponent/MainContentComponent';
import { getAddedDomains, postAddDomain, postValidateDomain, updateConnectionStatus } from '../../../services/configure/index';
import {actions as socialNetworkActions} from '../../../redux/modules/socialNetwork.module';
import { get, isEmpty } from 'lodash';

const SendgridModal = ({ adminModal, isOpen, handleClose, sgData, socialNetworkReuest, companyId }) => {
    const [stepCompleted, updateStepCompleted] = useState(1);
    const [loginDetails, setLoginDetails] = useState({});
    const [cNamesList, setCNamesList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [nextLoader, toggleNextLoader] = useState(false);
    const [verifyLoader, toggleVerifyLoader] = useState(false);
    const [disconnectLoader, toggleDisconnectLoader] = useState(false);
    const [connectLoader, toggleConnectLoader] = useState(false);
    const [deleteLoader, toggleDeleteLoader] = useState(false);
    const [verificationStatus, updateVerificationStatus] = useState(null);
    const [status, updateStatus] = useState(null);
    const [showDisconnected, toggleDisconnect] = useState(false);
    const [showDelete, toggleDelete] = useState(false);
    const [domainList, setDomainList] = useState([]);

    useEffect(() => {
        getAddedDomains(companyId).then(res => {
            setDomainList(get(res, 'result.data', []));
            if(isEmpty(get(res, 'result.data', []))) {
                setErrorMessage('No Domains Found');
            } else {
                setErrorMessage('');
            }
        }).catch(err => {
            setErrorMessage('Error While Fetching Domain');
        });
        const stts = sgData.length !== 0 ? sgData[0].status : null;
        const verStatus = sgData.length !== 0 ? sgData[0].verification_status : null;
        const userDetails = sgData.length !== 0 ? { email: sgData[0].email, domain_name: sgData[0].domain, from_name: sgData[0].from_name, valid: true } : { email: '', domain_name: '', from_name: '', valid: false };
        const step = adminModal && verStatus && verStatus === 'verification_pending' ? 2 : 1;
        const cNames = sgData.length !== 0 ? getCNamesList(sgData[0].cnames) : [];
        let err = '';
        updateVerificationStatus(verStatus);
        // updateVerificationStatus('verified');
        updateStatus(stts);
        // updateStatus('active');
        setLoginDetails(userDetails);
        setCNamesList(cNames);
        updateStepCompleted(step);
        // updateStepCompleted(1);
        setErrorMessage(err);
    }, []);

    const getCNamesList = cnamesObj => {
        const cnamesList = [{}, {}, {}];
        cnamesObj && Object.keys(cnamesObj).map(cname => {
            if (cname.includes('1'))
               return cnamesList[0][cname.slice(0, cname.length - 1)] = cnamesObj[cname];
            else if (cname.includes('2'))
               return cnamesList[1][cname.slice(0, cname.length - 1)] = cnamesObj[cname];
            else
               return cnamesList[2][cname.slice(0, cname.length - 1)] = cnamesObj[cname];
        });
        return cnamesList;
    }

    const inputChangeHandler = source => {
        const loginDetailsCopy = { ...loginDetails };
        loginDetailsCopy[source.target.name] = source.target.value;
        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (pattern.test(loginDetailsCopy.email) && loginDetailsCopy.domain_name.length > 0)
            loginDetailsCopy.valid = true;
        else
            loginDetailsCopy.valid = false;
        setLoginDetails(loginDetailsCopy);
    }

    const loginHandler = () => {
        if (loginDetails.email.includes(loginDetails.domain_name)) {
            setErrorMessage('');
            toggleNextLoader(true);
            const form = {
                snetwork: 'sg',
                email: loginDetails.email,
                domain: loginDetails.domain_name,
                from_name: loginDetails.from_name,
                automatic_security: true
            };
            postAddDomain(form)
                .then(response => {
                    console.log('postAddDomain [RESPONSE] - ', response);
                    if(response.result.success === 'true') {
                        toggleNextLoader(false);
                        if(adminModal) {
                            setCNamesList(getCNamesList(response.result.data));
                            updateStepCompleted(2);
                        }
                        else
                            closeHandler();
                    }
                })
                .catch(err => {
                    toggleNextLoader(false);
                    setErrorMessage('There was some error logging in..please try again later..');
                });
        }
        else
            setErrorMessage('You can only add email from the same domain');
    }

    const verifyHandler = () => {
        setErrorMessage('');
        toggleVerifyLoader(true);
        const form = {
            snetwork: 'sg',
            email: loginDetails.email,
            domain: loginDetails.domain_name
        };
        postValidateDomain(form)
            .then(response => {
                console.log('[RESPONSE] - ', response);
                toggleVerifyLoader(false);
                if (response.result.success === 'true'){
                    updateStepCompleted(3);
                }
                else
                    setErrorMessage('Verification Failed, please check if the entries are properly added to your DNS.');
            })
            .catch(err => {
                console.log('[ERROR] - ', err);
                toggleVerifyLoader(false);
                setErrorMessage('Verification Failed, please check if the entries are properly added to your DNS.');
            });
        // updateStepCompleted(3);
    }
    
    const closeHandler = () => {
        handleClose();
        socialNetworkReuest();
    }

    const updateConnectionHandler = option => {
        setErrorMessage('');
        if(option === 'disconnect')
            toggleDisconnectLoader(true);
        else if(option === 'delete')
            toggleDeleteLoader(true);
        else
            toggleConnectLoader(true)
        const form = {
            snetwork: 'sg',
            email: loginDetails.email,
            domain: loginDetails.domain_name,
            status: option
        }
        updateConnectionStatus(form)
        .then(response => {
            console.log('[DISCONNECT] response - ', response);
            if(option === 'disconnect') {
                toggleDisconnectLoader(false);
                toggleDisconnect(true);
                // updateStatus('disconnected');
            }
            else if(option === 'delete') {
                toggleDeleteLoader(false);
                toggleDelete(false);
                closeHandler();
            }
            else {
                toggleConnectLoader(false);
                closeHandler();
            }
        })
        .catch(err => {
            console.log('[ERROR] disconnect connection - ', err);
            if(option === 'disconnect')
                toggleDisconnectLoader(false);
            else if(option === 'delete')
                toggleDeleteLoader(false);
            else
                toggleConnectLoader(false);
            setErrorMessage('Error processing request...');
        });
        // toggleDisconnectLoader(false);
        // toggleDisconnect(true);
    }

    return (
        <ModalV2 isOpen={isOpen} onRequestClose={handleClose} width={(!verificationStatus || verificationStatus === 'verification_pending') ? (stepCompleted === 2 ? '58%' : '45%') : !adminModal && sgData[0].email === '' ? '45%' : '60%'}>
            <ModalHeader>
                <ModalCloseButton onClick={handleClose} />
            </ModalHeader>
            <ModalBody>
                <div style={{ textAlign: 'center', margin: 'auto', backgroundColor: '#6351ed', borderRadius: '26px', width: '51px', padding: '7px 10px 6px 10px' }}>
                    <img alt="" src='/assets/images/SendGrid.svg' />
                </div>
                <div>
                    <h3 style={stepCompleted === 3 || verificationStatus === 'verified' ? { marginBottom: '0' } : {}}>
                        {(adminModal && (!verificationStatus || verificationStatus === 'verification_pending')) || (!adminModal && (!sgData[0] || sgData[0].email === '')) ? (
                            stepCompleted === 1 ? 'Connect with SendGrid' : stepCompleted === 2 ? `Add these records to ${loginDetails.domain_name} DNS section` : showDelete ? 'Are you sure you want to delete this connection' : 'Verification Successful!'
                         ) : showDisconnected && !showDelete ? 'Connection Disconnected' : showDelete ? 'Are you sure you want to delete this connection' : 'Would you like to'}
                    </h3>
                    {(adminModal && (!verificationStatus || verificationStatus === 'verification_pending')) ? (
                        stepCompleted === 3 ? showDelete ? <p style={{ color: '#49484a', fontSize: '10px', marginTop: '5px' }}>To connect back you have to add and verify the SendGrid connections again</p> : (<p style={{ color: '#49484a', fontSize: '10px', marginTop: '5px' }}>You can start sending mails to your SendGrid Connections</p>) : null
                    ) : showDisconnected && !showDelete ? <p style={{ color: '#49484a', fontSize: '10px', marginTop: '5px' }}>You can connect back when required</p> : showDelete ? <p style={{ color: '#49484a', fontSize: '10px', marginTop: '5px' }}>To connect back you have to add and verify the SendGrid connections again</p> : null}
                </div>
                {adminModal && (sgData.length === 0 || verificationStatus === 'verification_pending') && !showDelete ? (
                    <StepStatus stepCompleted={stepCompleted} />
                ) : null}
                <MainContentComponent
                    stepCompleted={stepCompleted}
                    loginDetails={loginDetails}
                    changeHandler={inputChangeHandler}
                    loginHandler={loginHandler}
                    verifyHandler={verifyHandler}
                    isAdmin={adminModal}
                    list={cNamesList}
                    sgData={sgData}
                    verifyLoader={verifyLoader}
                    nextLoader={nextLoader}
                    close={closeHandler}
                    verificationStatus={verificationStatus}
                    status={status}
                    showDisconnected={showDisconnected}
                    updateConnection={updateConnectionHandler}
                    showDelete={showDelete}
                    toggleDelete={toggleDelete}
                    disconnectLoader={disconnectLoader}
                    deleteLoader={deleteLoader}
                    connectLoader={connectLoader}
                    setErrorMessage={setErrorMessage}
                    updateVerificationStatus={updateVerificationStatus}
                    toggleDeleteLoader={toggleDeleteLoader}
                    domainList={domainList}
                />

                {errorMessage ? (
                    <div style={{ margin: 'auto', textAlign: 'center', fontSize: '10px' }}>
                        <p>*{errorMessage}</p>
                    </div>
                ) : null}
            </ModalBody>
        </ModalV2>
    );
}

const mapStateToProps = state => {
    console.log('[STATE] - ', state);
    return {
        sgData: state.socialNetwork.data.sg,
        companyId: get(state, 'user.data.details.user.company', '')
    }
}

const mapDispatchToProps = dispatch => {
    return {
        socialNetworkReuest: () => dispatch(socialNetworkActions.api.socialNetwork.request()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendgridModal);