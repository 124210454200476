import React, { useState, useEffect } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Formik } from 'formik';
import { isEmpty, get, toLower, difference, find } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Modal,
  Input,
  Button,
  ErrorContainer,
  MioTextArea,
  SearchInput,
  ListItemTile,
  ErrorModal,
  PremiumModalContent,
} from '../';
import { getMembersList } from '../../services/configure';
import { getMediaUrl } from '../../utils';

import {
  StyledAccordianButton,
  MembersListWrapper,
} from './CreateTopicModal.styles';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { AdminCheckBox, GrayCheckBox } from '../SocialCheckBoxes/SocialCheckBoxes.styles';
import { ButtonNoBorderBackground } from '../';

const validate = (values) => {
  if (!values.groupName) {
    return { formError: 'Please enter group name' };
  }

  if (!values.groupDisclaimerText) {
    return { formError: 'Please enter disclaimer text' };
  }
};

const CreateTopicModal = ({
  createGroupRequest,
  updateGroupRequest,
  isModalOpen,
  editGroupDetails,
  onModalClose,
  membersList,
  themesList,
}) => {
  const defaultThemeIds = get(editGroupDetails, 'default_themes', []);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [removedMembers, setRemovedMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState(membersList);
  const [topicList, setTopicList] = useState([]);
  const [selectedTopicIdList, setSelectedTopicIdList] = useState(
    defaultThemeIds
  );
  const [selectedTopicItems, setSelectedTopicItems] = useState(
    defaultThemeIds && defaultThemeIds.map((themeId) => {
      return find(themesList, {
        id: themeId,
      });
    })
  );
  const [existingMembers, setExistingMembers] = useState([]);

  const isEditFlow = !isEmpty(editGroupDetails);
  const modalHeader = isEditFlow ? 'Edit Group' : 'Create Group';
  const initialValues = isEditFlow
    ? {
        groupName: editGroupDetails.name,
        groupDisclaimerText: editGroupDetails.disclaimer,
      }
    : {
        groupName: '',
        groupDisclaimerText: '',
      };

  const onCreateGroupSubmit = (values) => {
    if (!isEditFlow) {
      createGroupRequest({
        params: {
          name: values.groupName,
          disclaimer: values.groupDisclaimerText,
          addUids: selectedMembers,
          default_themes: JSON.stringify({
            add: selectedTopicIdList,
            remove: [],
          }),
        },
      });
    } else {
      updateGroupRequest({
        params: {
          groupId: editGroupDetails.id,
          name: values.groupName,
          disclaimer: values.groupDisclaimerText,
          addUids: difference(selectedMembers, removedMembers),
          removeUids: removedMembers,
          default_themes: JSON.stringify({
            add: selectedTopicIdList,
            remove: difference(defaultThemeIds, selectedTopicIdList),
          }),
        },
      });
    }
    onModalClose();
  };

  const onMemberSearch = (value) => {
    setFilteredMembers(
      membersList.filter(
        (member) =>
          (value &&
            toLower(
              `${get(member, 'details.name.first')} ${get(
                member,
                'details.name.last'
              )}`
            ).indexOf(toLower(value)) !== -1) ||
          value === ''
      )
    );
  };

  const onTopicSearch = (value) => {
    setTopicList(
      themesList.filter(
        (theme) =>
          toLower(get(theme, 'name', '')).indexOf(toLower(value)) !== -1
      )
    );
  };

  useEffect(() => {
    setFilteredMembers(membersList);
  }, [membersList]);

  useEffect(() => {
    if (isEditFlow) {
      getMembersList({ groupId: editGroupDetails.id }).then((response) => {
        const isSuccess = get(response, 'result.success', false);
        if (isSuccess) {
          const uids = get(response, 'result.data', []).map(({ uid }) => uid);
          setExistingMembers(uids);
          setSelectedMembers(uids);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleYourTopicsClick = (id, topic) => {
    if (selectedTopicIdList.length >= 3) {
      setIsErrorModalOpen(true);
      return;
    }

    if (!selectedTopicIdList.includes(id)) {
      setSelectedTopicIdList([...selectedTopicIdList, id]);
      setSelectedTopicItems([...selectedTopicItems, topic]);
    }
  };

  const handleYourTopicsRemove = (id) => {
    setSelectedTopicIdList(selectedTopicIdList.filter((item) => item !== id));
    setSelectedTopicItems(selectedTopicItems.filter((item) => item.id !== id));
  };

  const onMemberSelect = (memberId) => {
    if (isEditFlow) {
      if (
        selectedMembers.includes(memberId) &&
        existingMembers.includes(memberId)
      ) {
        setRemovedMembers([...removedMembers, memberId]);
        setSelectedMembers(selectedMembers.filter((id) => id !== memberId));
      } else if (!selectedMembers.includes(memberId)) {
        if (
          existingMembers.includes(memberId) &&
          removedMembers.includes(memberId)
        ) {
          setSelectedMembers([...selectedMembers, memberId]);
          setRemovedMembers(removedMembers.filter((id) => id !== memberId));
        } else {
          setSelectedMembers([...selectedMembers, memberId]);
        }
      }
    } else {
      if (selectedMembers.includes(memberId)) {
        setSelectedMembers(selectedMembers.filter((id) => id !== memberId));
      } else {
        setSelectedMembers([...selectedMembers, memberId]);
      }
    }
  };
  const angle = -45;
  const scaleUnit = 1;

  const styles = {
    checkbox: {
      "&::after": {
        transform: `rotate(${angle}deg) scale(${scaleUnit})`
      }
    }
  }

  return (
    <Modal
      onRequestClose={onModalClose}
      shouldCloseOnOverlayClick
      isOpen={isModalOpen}
      size="medium"
    >
      <ModalHeader>
        {modalHeader}
        <ModalCloseButton onClick={onModalClose} />
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onCreateGroupSubmit}
        >
          {({ handleSubmit, handleChange, errors, values }) => (
            <Flex width={1} flexDirection="column">
              <Flex width={1}>
                <Box width={1} pr="2%">
                  <Input
                    name="groupName"
                    placeholder="New Group Name"
                    onChange={handleChange}
                    value={values.groupName}
                  />
                </Box>
                <Box width={1} pl="2%">
                  <MioTextArea
                    name="groupDisclaimerText"
                    placeholder="Group Disclaimer Text"
                    rows="3"
                    onChange={handleChange}
                    value={values.groupDisclaimerText}
                  ></MioTextArea>
                </Box>
              </Flex>
              <Flex width={1} flexDirection="column">
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <StyledAccordianButton>
                        <Flex width={1} justifyContent="center">
                          <Box style={{color: '#6351ed', fontFamily: 'Poppins'}} width={0.95}>Select Members</Box>
                          <Box w={0.05}>
                            <FontAwesomeIcon style={{color: '#6351ed'}} icon={faChevronDown} />
                          </Box>
                        </Flex>
                      </StyledAccordianButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Flex width={1} flexDirection="column">
                        <SearchInput
                          placeholder="Search"
                          onSearchClick={onMemberSearch}
                        />
                      </Flex>
                      <MembersListWrapper
                        flexWrap="wrap"
                        alignItems="center"
                        py="5px"
                      >
                        {filteredMembers.map((member) => {
                          const memberId = get(member, 'id');
                          return (
                            <Flex
                              width={1 / 2}
                              padding="5px"
                              alignItems="center"
                            >
                              <Box title={'abc'} key={'abc'} width={1} is="a">
                                {/* <div>
                                <ButtonNoBorderBackground>
                                  <GrayCheckBox>
                                    <AdminCheckBox
                                    id={memberId}
                                    type="checkbox"
                                    className={selectedMembers.includes(memberId) ? 'checked' : ''}
                                    checked={selectedMembers.includes(memberId)}
                                    onChange={() => onMemberSelect(memberId)}
                                  />
                                  </GrayCheckBox>
                                  </ButtonNoBorderBackground>
                                </div> */}
                                
                                <input
                                  id={memberId}
                                  type="checkbox"
                                  onClick={() => onMemberSelect(memberId)}
                                  checked={selectedMembers.includes(memberId)}
                                />
                              </Box>
                              <Box>
                                <label  style={{fontFamily: 'Poppins'}} for={memberId}>
                                  {get(member, 'details.name.first')}{' '}
                                  {get(member, 'details.name.last')}
                                </label>
                              </Box>
                            </Flex>
                          );
                        })}
                      </MembersListWrapper>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <StyledAccordianButton>
                        <Flex width={1} justifyContent="center">
                          <Box style={{color: '#6351ed', fontFamily: 'Poppins'}} width={0.95}>Select Default Topic</Box>
                          <Box w={0.05}>
                            <FontAwesomeIcon style={{color: '#6351ed'}} icon={faChevronDown} />
                          </Box>
                        </Flex>
                      </StyledAccordianButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Flex width={1} flexDirection="column">
                        <SearchInput
                          placeholder="Search for a Topic (For example : “Technology”)"
                          onSearchClick={onTopicSearch}
                        />
                      </Flex>
                      <MembersListWrapper
                        alignItems="center"
                        py="5px"
                        flexDirection="column"
                      >
                        <Flex width={1} flexWrap="wrap">
                          {topicList.map((topic) => (
                            <Box width={1 / 5} height="45px" mb="5px">
                              <ListItemTile
                                handleClick={() =>
                                  handleYourTopicsClick(get(topic, 'id'), topic)
                                }
                                id={get(topic, 'id')}
                                url={getMediaUrl(get(topic, 'media_urls'))}
                                topic={get(topic, 'name')}
                                type="add"
                              />
                            </Box>
                          ))}
                        </Flex>
                      </MembersListWrapper>
                      <Flex justifyContent="flex-start">
                        <h3>Selected Topics</h3>
                      </Flex>
                      <MembersListWrapper
                        alignItems="center"
                        py="5px"
                        flexDirection="column"
                      >
                        <Flex width={1} flexWrap="wrap">
                          {selectedTopicItems && selectedTopicItems.map((topic) => (
                            <Box width={1 / 5} height="45px" mb="5px">
                              <ListItemTile
                                handleClick={() =>
                                  handleYourTopicsRemove(get(topic, 'id'))
                                }
                                id={get(topic, 'id')}
                                url={getMediaUrl(get(topic, 'media_urls'))}
                                topic={get(topic, 'name')}
                                type="remove"
                              />
                            </Box>
                          ))}
                        </Flex>
                      </MembersListWrapper>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>
              <div>
                <Button onClick={handleSubmit}>Submit</Button>
                <ErrorContainer align="center">
                  {errors.formError}
                </ErrorContainer>
              </div>
            </Flex>
          )}
        </Formik>
        {isErrorModalOpen && (
          <ErrorModal onClose={() => setIsErrorModalOpen(false)}>
            <ErrorContainer align="center">
              <PremiumModalContent>
                You can't select more than 3 topics
              </PremiumModalContent>
            </ErrorContainer>
          </ErrorModal>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CreateTopicModal;
