/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { DripGrid } from '../components/CommonStyles';
import { AdminHeader, AdvisorHeader, Loader, AdvisorSidebar } from '../components';
import {
  getChannelId,
  getUserDetails,
  getUserRole,
  getRoleType,
} from '../redux/selector';
import { actions as loginActions } from '../redux/modules/login.module';
import { actions as channelActions } from '../redux/modules/channel.module';
import { actions as themeActions } from '../redux/modules/theme.module';
import { actions as socialNetworkActions } from '../redux/modules/socialNetwork.module';
import { actions as contactsActions } from '../redux/modules/contacts.module';
import { actions as inviteActions } from '../redux/modules/invite.module';
import { actions as contentlibActions } from '../redux/modules/content-lib.module';
import { moreActions as newsletterMoreActions } from '../redux/modules/newsletter.module';
import MioImageUpload from '../containers/DashboardContainer/MIOEditor/MioImageUpload';
import MioSuccessModal from '../containers/DashboardContainer/MIOEditor/MioSuccessModal';
import { clearAllBucketArticles, mioEditorUploadImage } from '../services/dashboard';
import { actions as campaignActions } from '../redux/modules/campaign.module';
import { DeleteBucketArticlesModal } from '../containers/NewsletterContainer/NewsletterModals/NewsletterModals';
import { getUsersList } from '../services/analytics';
import { USER_ROLE } from '../utils';

const withDripCampaignLayout = (Component) => {
  const ComposedComponent = ({
    loginRequest,
    history,
    userName,
    userRole,
    channelRequest,
    channelData,
    themesList,
    themesRequest,
    isFetching,
    roleType,
    socialNetworkRequest,
    contactsRequest,
    contactsList,
    socialNetworkData,
    inviteRequest,
    channelId,
    inviteSending,
    contentlibSetState,
    user,
    contactsFetching,
    bucketCountRequest,
    bucketCount,
    campaignSetState,
    isAdmin,
    loginSetState
  }) => {
    const [menuToggle, setMenuToggle] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [imageUpload, setImageUpload] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    useEffect(() => {
        // console.log(userName);
      if (!userName) {
        loginRequest();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (isAdmin) {
        getUsersList('all').then((data) => {
          const membersList = get(data, 'result.data.users', []);
          loginSetState({ membersList });
        });
      }
    }, [isAdmin])
    
    

    useEffect(() => {
      contentlibSetState({data: {}});
      bucketCountRequest();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (isEmpty(channelData)) {
        channelRequest();
      }
      if (isEmpty(themesList)) {
        themesRequest();
      }
      if (isEmpty(socialNetworkData)) {
        socialNetworkRequest();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole]);

    // useEffect(() => {
    //   if (!isEmpty(socialNetworkData)) {
    //     const { snetwork } = qs.parse(window.location.href);

    //     // if (snetwork && (snetwork === 'hs' || snetwork.includes('hs'))) {
    //     //   setIsHubSpotModalOpen(true);
    //     // }
    //   }
    // }, [socialNetworkData]);

    const HeaderComponent = userRole === 'admin' ? AdminHeader : AdvisorHeader;

    const handleMenuToggle = () => {
      setMenuToggle(!menuToggle);
    }

    const handleModalOpen = () => {
      setOpenUploadModal(true);
    }

    const onRequestImageClose = fromSave => {
        setOpenUploadModal(false)
    };

    const handleFileUploadChange = (file) => {
      let payload = {
        media1: new File([file[0]],`${file[0].name}`),
        enable: true
      }
      mioEditorUploadImage(payload).then((res) => {
        if(res && res.result.success) {
          setOpenUploadModal(false);
          setOpenSuccessModal(true);
        }
      });
    }

    const onRequestSuccessClose = () => {
      setOpenSuccessModal(false);
      setImageUpload(true);
      window.localStorage.setItem('ImageUploadFirst', true)
      window.localStorage.setItem('ImageUpload', true)
      loginRequest();
    }  

    if (isFetching) {
      return <Loader height="100vh" />;
    }

    const setTabIndex = (index) => {
      setSelectedTabIndex(index);
    }

    const onAccept = () => {
      setDeleteLoading(true);
      clearAllBucketArticles().then(res => {
        if(res.result.success) {
          setDeleteLoading(false);
          window.location.reload();
        }
      }).catch(err => {
        setDeleteLoading(false);
      })
    }

    const onReject = () => {
      setOpenDeleteModal(false);
    }
    return (
      <div style={{height: '100%'}}>
        <HeaderComponent
          userName={userName}
          pathName={history.location.pathname}
          roleType={roleType}
          handleMenuToggle={handleMenuToggle}
          handleModalOpen={handleModalOpen}
          handleTabIndex={setTabIndex}
          userData={user}
          bucketCount={bucketCount}
          setOpenDeleteModal={setOpenDeleteModal}
          type="campaign"
        />
        <DripGrid>
          {window.innerWidth < 768 ? 
            <AdvisorSidebar toggle={menuToggle} userName={userName} roleType={roleType}></AdvisorSidebar>
            :
            null
          }
          <Component roleType={roleType} imageUpload={imageUpload} selectedTabIndex={selectedTabIndex} handleModalOpen={handleModalOpen} onRequestClose={onRequestImageClose} />
          {/* <Feedback /> */}
          <MioImageUpload 
            openModal={openUploadModal} 
            onRequestClose={onRequestImageClose} 
            onFileUploadChange={handleFileUploadChange}
            userData={user}
          />
          <MioSuccessModal openModal={openSuccessModal} onRequestClose={onRequestSuccessClose}/>
          <DeleteBucketArticlesModal 
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            onAccept={onAccept}
            onReject={onReject}
            deleteLoading={deleteLoading}
          />
        </DripGrid>
      </div>
    );
  };

  const mapStateToProps = (state) => {
    const { first: firstName } = get(getUserDetails(state), 'name', {});
    const isAdmin = getUserRole(state) === USER_ROLE.admin;
    return {
      userName: firstName,
      userRole: getUserRole(state),
      channelData: get(state, 'channel.data', []),
      themesList: state.theme.data || [],
      isFetching:
        state.user.isFetching ||
        state.theme.isFetching ||
        state.socialNetwork.isFetching,
      roleType: getRoleType(state),
      contactsList: state.contacts.data || [],
      socialNetworkData: state.socialNetwork.data,
      channelId: getChannelId(state),
      inviteSending: state.invite.isFetching,
      user: state.user.data,
      contactsFetching: state.contacts.isFetching,
      bucketCount: get(state, 'newsletter.bucketCount', 0),
      isAdmin: isAdmin
    };
  };
  const mapDispatchToProps = {
    loginRequest: loginActions.api.login.request,
    channelRequest: channelActions.api.channel.request,
    themesRequest: themeActions.api.theme.request,
    socialNetworkRequest: socialNetworkActions.api.socialNetwork.request,
    contactsRequest: contactsActions.api.contacts.request,
    inviteRequest: inviteActions.api.invite.request,
    contentlibSetState: contentlibActions.api.contentlib.setstate,
    bucketCountRequest: newsletterMoreActions.custom.newsletter.bucketCountRequest,
    campaignSetState: campaignActions.api.campaign.setstate,
    loginSetState: loginActions.api.login.setstate,
  };
  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);
};

export default withDripCampaignLayout;
